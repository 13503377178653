import React, { Component, Fragment } from "react";
import {
    List,
    Button,
    Divider,
    Avatar,
    Input,
    Empty,
    Icon,
    Tooltip,
} from "antd";
import moment from "moment";
import { observer } from "mobx-react";
import InboxChainPost from "./InboxChainPost";
import InboxOverview from "./InboxOverview";

const { TextArea } = Input;

class InboxChain extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedChain: this.props.selectedChain };
        this.scrollIntoView();
    }
    componentDidMount() {
        this.parseInfo();
    }
    componentDidUpdate(prevProps) {
        // console.info("componentDidUpdate this.props", this.props);
        if (prevProps.selectedChainKey !== this.props.selectedChainKey) {
            this.setState({
                selectedChainKey: this.props.selectedChainKey,
                chainProcessing: false,
                directMessageReply: "",
            });
            this.parseInfo();
        }
        this.scrollIntoView();
    }

    scrollIntoView = () => {
        if (
            this.state.selectedChainKey &&
            this.state.selectedChainKey.indexOf("|") === -1
        ) {
            setTimeout(function () {
                const postToChainTextarea =
                    document.getElementById("postToChain");
                postToChainTextarea &&
                    postToChainTextarea.scrollIntoView({ behavior: "smooth" }); // Boolean parameter
            }, 100);
        }
    };

    parseInfo = async () => {
        // console.log("parseInfo");
        if (
            this.props.selectedChainKey &&
            this.props.selectedChainKey.indexOf("|") !== -1
        ) {
            const [_prefix, _key] = this.props.selectedChainKey.split("|");
            this.setState({
                prefix: _prefix,
                key: _key,
            });

            const allChains = this.props.store.getChains;
            // console.info("allChains", allChains);
            let selected_chains = null;

            switch (_prefix) {
                case "account":
                    selected_chains = allChains.filter(
                        (chain) => chain.ourUser && chain.ourUser.key === _key // && chain.read === false
                    );
                    break;
                case "brand":
                    selected_chains = allChains.filter(
                        (chain) => chain.ourUser && chain.ourUser.brand === _key // && chain.read === false
                    );
                    break;
            }
            // console.info("selected_chains", selected_chains);
            // filter only the not read posts
            let unread_posts = [];
            selected_chains.map((chain) => {
                let found = false;
                let curChain = chain.chain;
                let tempChain = Object.assign({}, chain);
                delete tempChain.chain;

                Object.keys(curChain)
                    .sort()
                    .reverse()
                    .forEach((key) => {
                        // console.log("key=" + key);
                        let post = curChain[key];
                        // console.log("BODY> " + post.body);
                        if (found === false) {
                            if (!post.readStamp) {
                                // console.log("Winner !!!");
                                let mainChain = Object.assign({}, chain);
                                delete mainChain.chain;
                                if (!tempChain.unread_posts)
                                    tempChain.unread_posts = [];
                                tempChain.unread_posts.push(post);
                                //unread_posts.push({post, chain: mainChain});
                            } else {
                                found = true; // Break out
                            }
                        }
                    });

                if (
                    tempChain.unread_posts &&
                    tempChain.unread_posts.length > 0
                ) {
                    unread_posts.push(tempChain);
                }
            });
            // console.info("unread_posts", unread_posts);
            this.setState({ unread_posts, selected_chains });
        }
    };

    postToChain = async (_message, _chain, _post_id) => {
        const selectedChain = !_chain
            ? this.props.store.getChains.filter(
                  (chain) => chain.key === this.props.selectedChainKey
              )[0]
            : _chain;
        this.setState({ chainProcessing: true });
        console.info(
            "postToChain _message",
            _message,
            "selectedChain",
            selectedChain,
            "_post_id",
            _post_id
        );

        // postComment = () => {
        //     this.setState({ posting: true });

        //     //console.info("this.props", this.props);

        //     this.props.store
        //         .publishCommentOnChannelPost(
        //             this.props.accountId,
        //             this.props.provider,
        //             this.props.channel_id,
        //             this.props.post.id,
        //             this.state.new_comment
        //         )
        //         .then((postResults) => {
        //             //console.info("postResults", postResults);
        //             // update the comments

        //             this.parseComments(postResults.comments.data);

        //             this.setState({ posting: false, new_comment: "" });
        //         })
        //         .catch((error) => {
        //             console.warn("ERROR !!!!");
        //             social_notification(
        //                 "publish_comment_on_channel_post",
        //                 "error",
        //                 {
        //                     description: error,
        //                 }
        //             );
        //         });
        // };

        let dataObj = {};
        if (
            selectedChain.platform === "twitter" &&
            selectedChain.type === "direct_message"
        ) {
            dataObj = {
                key: selectedChain.ourUser.key,
                type: selectedChain.type,
                platform: selectedChain.platform,
                recipient_user_id: selectedChain.oppositeUser.id,
                message: _message, //this.state.directMessageReply,
            };
        }
        if (
            selectedChain.platform === "twitter" &&
            selectedChain.type === "post_comment"
        ) {
            dataObj = {
                key: selectedChain.ourUser.key,
                type: selectedChain.type,
                platform: selectedChain.platform,
                target_id: selectedChain.connection_key.split("_")[1],
                message: _message, //this.state.directMessageReply,
            };
        }
        if (
            selectedChain.platform === "facebook" &&
            selectedChain.type === "direct_message"
        ) {
            const [foo, facebook_page_id] =
                selectedChain.connection_key.split("_");
            console.log(foo);

            dataObj = {
                key: selectedChain.ourUser.key,
                type: selectedChain.type,
                facebook_page_id: facebook_page_id,
                platform: selectedChain.platform,
                recipient_user_id: selectedChain.oppositeUser.id,
                message: _message, //this.state.directMessageReply,
            };
        }

        if (
            selectedChain.platform === "facebook" &&
            selectedChain.type === "post_comment"
        ) {
            // const [foo, facebook_page_id] = selectedChain.connection_key.split(
            //     "_"
            // );

            dataObj = {
                key: selectedChain.extra.page.account_key,
                type: selectedChain.type,
                post_id: selectedChain.extra.post.id,
                platform: selectedChain.platform,
                message: _message,
                facebook_page_id: selectedChain.extra.page.id,
            };
        }

        if (
            selectedChain.platform === "instagram" &&
            selectedChain.type === "post_comment"
        ) {
            const [foo, instagram_media_id] =
                selectedChain.connection_key.split("_");
            console.log(foo);

            dataObj = {
                key: selectedChain.ourUser.key,
                type: selectedChain.type,
                media_id: instagram_media_id,
                platform: selectedChain.platform,
                message: _message,
            };
        }

        console.info("postToChain dataObj", dataObj);

        // return;

        this.props.store
            .postToChain(dataObj)
            .then(async (result) => {
                console.info("result", result);
                this.setState({
                    directMessageReply: "",
                    chainProcessing: false,
                });

                // Mark as read
                if (_post_id) {
                    // if (this.props.handleMarkChainAsRead) {
                    //     this.props.handleMarkChainAsRead(selectedChain.key, true); // no timeout
                    // }
                    // await this.props.store
                    // .markChainAsRead(selectedChain.key, _post_id)
                    // .then((result) => {
                    //     console.info("result", result);
                    //     this.parseInfo();
                    // })
                    // .catch((err) => {
                    //     console.info("err", err);
                    // });
                }

                if (this.props.handleMarkChainAsRead) {
                    this.props.handleMarkChainAsRead(selectedChain.key, true); // no timeout
                }
            })
            .catch((err) => {
                console.error(err);
                this.setState({ chainProcessing: false });
            });
    };

    markChainAsRead = async (_chain_key, _e) => {
        console.info("markChainAsRead _chain_key", _chain_key);
        // await this.props.store
        // .markChainAsRead(_chain_key)
        // .then((result) => {
        //     console.info("result", result);
        //     this.parseInfo();
        // })
        // .catch((err) => {
        //     console.info("err", err);
        // });
        if (this.props.handleMarkChainAsRead) {
            this.props.handleMarkChainAsRead(_chain_key, true); // no timeout
        }
    };

    clickOnChain = async (_chain_key, _e) => {
        console.info("clickOnChain _chain_key", _chain_key);
        if (this.props.handleClickOnPost) {
            this.props.handleClickOnPost(_chain_key, _e);
        }
    };

    handleUpdateOverviewFilter = (_overviewFilter) => {
        if (this.props.handleUpdateOverviewFilter) {
            this.props.handleUpdateOverviewFilter(_overviewFilter);
        }
    };

    render() {
        //console.info("twitterChains", twitterChains);
        const selectedChain = this.props.selectedChainKey
            ? this.props.store.getChains.filter(
                  (chain) => chain.key === this.props.selectedChainKey
              )[0]
            : null;

        let body = (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No mathching data"
            />
        );

        //const { selectedChain } = this.state;
        if (
            selectedChain &&
            this.props.selectedChainKey.toString().indexOf("|") === -1
        ) {
            if (
                selectedChain.platform === "twitter" &&
                selectedChain.type === "direct_message"
            ) {
                body = (
                    <Fragment>
                        <div className={"social_ChainContainer"}>
                            {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                            <List
                                itemLayout="horizontal"
                                dataSource={
                                    selectedChain && selectedChain.chain
                                }
                                renderItem={(item) => {
                                    const attachment =
                                        item.payload &&
                                        item.payload.direct_message_events &&
                                        item.payload.direct_message_events[0] &&
                                        item.payload.direct_message_events[0]
                                            .message_create &&
                                        item.payload.direct_message_events[0]
                                            .message_create.message_data &&
                                        item.payload.direct_message_events[0]
                                            .message_create.message_data
                                            .attachment &&
                                        item.payload.direct_message_events[0]
                                            .message_create.message_data
                                            .attachment
                                            ? item.payload
                                                  .direct_message_events[0]
                                                  .message_create.message_data
                                                  .attachment.media
                                            : null;
                                    //const attachment = null;
                                    //console.info("attachment", attachment);
                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        src={item.from.photoURL}
                                                        style={{
                                                            float: "left",
                                                            marginRight: 10,
                                                        }}
                                                    />
                                                }
                                                title={
                                                    <a href="https://ant.design">
                                                        {item.from.name}
                                                    </a>
                                                }
                                                description={
                                                    <div
                                                        style={{
                                                            marginRight: 120,
                                                        }}
                                                    >
                                                        {item.body}
                                                        {attachment ? (
                                                            <div
                                                                style={{
                                                                    marginLeft: 17,
                                                                    marginTop: 6,
                                                                    // width:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .w,
                                                                    // height:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .h,
                                                                    // // borderRadius: 10,
                                                                    // // border:
                                                                    // //     "1px solid",
                                                                    // backgroundImage: `url(${attachment.media_url.replace(
                                                                    //     "https://ton.twitter.com/1.1/",
                                                                    //     "https://ton.twitter.com/i/"
                                                                    // )})`,
                                                                }}
                                                            >
                                                                <img
                                                                    alt="attachment"
                                                                    src={
                                                                        attachment.media_url.replace(
                                                                            "https://ton.twitter.com/1.1/",
                                                                            "https://ton.twitter.com/i/"
                                                                        ) +
                                                                        ":small"
                                                                    }
                                                                    style={{
                                                                        width: attachment
                                                                            .sizes
                                                                            .small
                                                                            .w,
                                                                        height: attachment
                                                                            .sizes
                                                                            .small
                                                                            .h,
                                                                        borderRadius: 10,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                }
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {moment(item.timestamp)
                                                    .local()
                                                    .format("lll")}{" "}
                                                <br />
                                                {moment(item.timestamp)
                                                    .local()
                                                    .fromNow()}
                                            </div>
                                            {item.readStamp ? (
                                                <Tooltip
                                                    placement="leftTop"
                                                    title={
                                                        "Read at: " +
                                                        moment(
                                                            item.readStamp
                                                                .datetime
                                                        )
                                                            .local()
                                                            .format("lll") +
                                                        "\n" +
                                                        "By: " +
                                                        item.readStamp.user
                                                            .email
                                                    }
                                                >
                                                    <Icon type="check-circle" />
                                                </Tooltip>
                                            ) : null}
                                        </List.Item>
                                    );
                                }}
                            />
                            <Divider />
                            <label id="postToChain">
                                Post on behalf of{" "}
                                <Avatar
                                    size={20}
                                    src={
                                        selectedChain.ourUser.photoURL
                                            ? selectedChain.ourUser.photoURL
                                            : null
                                    }
                                    style={{ marginTop: -4 }}
                                />
                                @{selectedChain.ourUser.name}
                            </label>
                            <TextArea
                                rows={4}
                                style={{ marginTop: 10 }}
                                value={this.state.directMessageReply}
                                onChange={(e) =>
                                    this.setState({
                                        directMessageReply: e.target.value,
                                    })
                                }
                            />
                            <Button
                                type="primary"
                                style={{
                                    float: "right",
                                    marginTop: 10,
                                }}
                                onClick={(e) => {
                                    typeof this.postToChain === "function" &&
                                        this.postToChain(
                                            this.state.directMessageReply
                                        );
                                }}
                                loading={this.state.chainProcessing}
                            >
                                Send
                            </Button>
                        </div>
                    </Fragment>
                );
            }

            if (
                selectedChain.platform === "twitter" &&
                selectedChain.type === "post_comment"
            ) {
                const chain = selectedChain.chain;
                // const extra = selectedChain.extra;

                body = (
                    <Fragment>
                        <div className={"social_ChainContainer"}>
                            <InboxChainPost chain={selectedChain} />

                            <br />

                            <List
                                itemLayout="horizontal"
                                dataSource={chain && chain}
                                renderItem={(item) => {
                                    const attachment =
                                        item.payload &&
                                        item.payload.direct_message_events &&
                                        item.payload.direct_message_events[0] &&
                                        item.payload.direct_message_events[0]
                                            .message_create &&
                                        item.payload.direct_message_events[0]
                                            .message_create.message_data &&
                                        item.payload.direct_message_events[0]
                                            .message_create.message_data
                                            .attachment &&
                                        item.payload.direct_message_events[0]
                                            .message_create.message_data
                                            .attachment
                                            ? item.payload
                                                  .direct_message_events[0]
                                                  .message_create.message_data
                                                  .attachment.media
                                            : null;
                                    //const attachment = null;
                                    //console.info("attachment", attachment);
                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        src={item.from.photoURL}
                                                        style={{
                                                            float: "left",
                                                            marginRight: 10,
                                                        }}
                                                    />
                                                }
                                                title={
                                                    <a href="https://ant.design">
                                                        {item.from.name}
                                                    </a>
                                                }
                                                description={
                                                    <div
                                                        style={{
                                                            marginRight: 120,
                                                        }}
                                                    >
                                                        {item.body}
                                                        {attachment ? (
                                                            <div
                                                                style={{
                                                                    marginLeft: 17,
                                                                    marginTop: 6,
                                                                    // width:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .w,
                                                                    // height:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .h,
                                                                    // // borderRadius: 10,
                                                                    // // border:
                                                                    // //     "1px solid",
                                                                    // backgroundImage: `url(${attachment.media_url.replace(
                                                                    //     "https://ton.twitter.com/1.1/",
                                                                    //     "https://ton.twitter.com/i/"
                                                                    // )})`,
                                                                }}
                                                            >
                                                                <img
                                                                    alt="attachment"
                                                                    src={
                                                                        attachment.media_url.replace(
                                                                            "https://ton.twitter.com/1.1/",
                                                                            "https://ton.twitter.com/i/"
                                                                        ) +
                                                                        ":small"
                                                                    }
                                                                    style={{
                                                                        width: attachment
                                                                            .sizes
                                                                            .small
                                                                            .w,
                                                                        height: attachment
                                                                            .sizes
                                                                            .small
                                                                            .h,
                                                                        borderRadius: 10,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                }
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {moment(item.timestamp)
                                                    .local()
                                                    .format("lll")}{" "}
                                                <br />
                                                {moment(item.timestamp)
                                                    .local()
                                                    .fromNow()}
                                            </div>
                                            {item.readStamp ? (
                                                <Tooltip
                                                    placement="leftTop"
                                                    title={
                                                        "Read at: " +
                                                        moment(
                                                            item.readStamp
                                                                .datetime
                                                        )
                                                            .local()
                                                            .format("lll") +
                                                        "\n" +
                                                        "By: " +
                                                        item.readStamp.user
                                                            .email
                                                    }
                                                >
                                                    <Icon type="check-circle" />
                                                </Tooltip>
                                            ) : null}
                                        </List.Item>
                                    );
                                }}
                            />
                        </div>
                        <Divider />
                        <label id="postToChain">
                            Post on behalf of{" "}
                            <strong>
                                @{selectedChain.ourUser.screen_name}
                            </strong>
                        </label>
                        <TextArea
                            rows={4}
                            style={{ marginTop: 10 }}
                            value={this.state.directMessageReply}
                            onChange={(e) =>
                                this.setState({
                                    directMessageReply: e.target.value,
                                })
                            }
                        />
                        <Button
                            type="primary"
                            style={{
                                float: "right",
                                marginTop: 10,
                            }}
                            onClick={(e) => {
                                typeof this.postToChain === "function" &&
                                    this.postToChain(
                                        this.state.directMessageReply
                                    );
                            }}
                            loading={this.state.chainProcessing}
                        >
                            Send
                        </Button>
                        {/* <pre>{JSON.stringify(selectedChain, null, 2)}</pre> */}
                    </Fragment>
                );
            }

            if (
                selectedChain.platform === "facebook" &&
                selectedChain.type === "direct_message"
            ) {
                body = (
                    <Fragment>
                        <div className={"social_ChainContainer"}>
                            {/* <pre>{JSON.stringify(selectedChain, null, 2)}</pre> */}
                            <List
                                itemLayout="horizontal"
                                dataSource={
                                    selectedChain && selectedChain.chain
                                }
                                renderItem={(item) => {
                                    // const attachment =
                                    //     item.payload &&
                                    //     item.payload.direct_message_events &&
                                    //     item.payload.direct_message_events[0] &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data
                                    //         .attachment &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data
                                    //         .attachment
                                    //         ? item.payload
                                    //               .direct_message_events[0]
                                    //               .message_create.message_data
                                    //               .attachment.media
                                    //         : null;
                                    const attachment = null;
                                    //console.info("attachment", attachment);
                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        src={item.from.photoURL}
                                                        style={{
                                                            float: "left",
                                                            marginRight: 10,
                                                            opacity: item.from
                                                                .photoURL
                                                                ? 1
                                                                : 0.3,
                                                        }}
                                                    />
                                                }
                                                title={
                                                    item.from.name
                                                        ? item.from.name
                                                        : item.from.id
                                                }
                                                description={
                                                    <div
                                                        style={{
                                                            marginRight: 120,
                                                        }}
                                                    >
                                                        {item.body}
                                                        {attachment ? (
                                                            <div
                                                                style={{
                                                                    marginLeft: 17,
                                                                    marginTop: 6,
                                                                    // width:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .w,
                                                                    // height:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .h,
                                                                    // // borderRadius: 10,
                                                                    // // border:
                                                                    // //     "1px solid",
                                                                    // backgroundImage: `url(${attachment.media_url.replace(
                                                                    //     "https://ton.twitter.com/1.1/",
                                                                    //     "https://ton.twitter.com/i/"
                                                                    // )})`,
                                                                }}
                                                            >
                                                                <img
                                                                    alt="attachment"
                                                                    src={
                                                                        attachment.media_url.replace(
                                                                            "https://ton.twitter.com/1.1/",
                                                                            "https://ton.twitter.com/i/"
                                                                        ) +
                                                                        ":small"
                                                                    }
                                                                    style={{
                                                                        width: attachment
                                                                            .sizes
                                                                            .small
                                                                            .w,
                                                                        height: attachment
                                                                            .sizes
                                                                            .small
                                                                            .h,
                                                                        borderRadius: 10,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                }
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {moment(item.timestamp)
                                                    .local()
                                                    .format("lll")}{" "}
                                                <br />
                                                {moment(item.timestamp)
                                                    .local()
                                                    .fromNow()}
                                            </div>
                                            {item.readStamp ? (
                                                <Tooltip
                                                    placement="leftTop"
                                                    title={
                                                        "Read at: " +
                                                        moment(
                                                            item.readStamp
                                                                .datetime
                                                        )
                                                            .local()
                                                            .format("lll") +
                                                        "\n" +
                                                        "By: " +
                                                        item.readStamp.user
                                                            .email
                                                    }
                                                >
                                                    <Icon type="check-circle" />
                                                </Tooltip>
                                            ) : null}
                                        </List.Item>
                                    );
                                }}
                            />
                            <Divider />
                            <label id="postToChain">
                                Post on behalf of {selectedChain.ourUser.name}
                            </label>
                            <TextArea
                                rows={4}
                                style={{ marginTop: 10 }}
                                value={this.state.directMessageReply}
                                onChange={(e) =>
                                    this.setState({
                                        directMessageReply: e.target.value,
                                    })
                                }
                            />
                            <Button
                                type="primary"
                                style={{
                                    float: "right",
                                    marginTop: 10,
                                }}
                                onClick={(e) => {
                                    typeof this.postToChain === "function" &&
                                        this.postToChain(
                                            this.state.directMessageReply
                                        );
                                }}
                                loading={this.state.chainProcessing}
                            >
                                Send
                            </Button>
                        </div>
                    </Fragment>
                );
            }

            if (
                selectedChain.platform === "facebook" &&
                selectedChain.type === "post_comment"
            ) {
                body = (
                    <Fragment>
                        <div className={"social_ChainContainer"}>
                            {/* <pre>
                                {JSON.stringify(selectedChain.extra, null, 2)}
                            </pre> */}
                            <InboxChainPost chain={selectedChain} />

                            <br />
                            <List
                                itemLayout="horizontal"
                                dataSource={
                                    selectedChain && selectedChain.chain
                                }
                                renderItem={(item) => {
                                    // const attachment =
                                    //     item.payload &&
                                    //     item.payload.direct_message_events &&
                                    //     item.payload.direct_message_events[0] &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data
                                    //         .attachment &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data
                                    //         .attachment
                                    //         ? item.payload
                                    //               .direct_message_events[0]
                                    //               .message_create.message_data
                                    //               .attachment.media
                                    //         : null;
                                    const attachment = null;
                                    //console.info("attachment", attachment);
                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        src={item.from.photoURL}
                                                        style={{
                                                            float: "left",
                                                            marginRight: 10,
                                                            opacity: item.from
                                                                .photoURL
                                                                ? 1
                                                                : 0.3,
                                                        }}
                                                    />
                                                }
                                                title={
                                                    item.from.name
                                                        ? item.from.name
                                                        : item.from.id
                                                }
                                                description={
                                                    <div
                                                        style={{
                                                            marginRight: 120,
                                                        }}
                                                    >
                                                        {item.body}
                                                        {attachment ? (
                                                            <div
                                                                style={{
                                                                    marginLeft: 17,
                                                                    marginTop: 6,
                                                                    // width:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .w,
                                                                    // height:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .h,
                                                                    // // borderRadius: 10,
                                                                    // // border:
                                                                    // //     "1px solid",
                                                                    // backgroundImage: `url(${attachment.media_url.replace(
                                                                    //     "https://ton.twitter.com/1.1/",
                                                                    //     "https://ton.twitter.com/i/"
                                                                    // )})`,
                                                                }}
                                                            >
                                                                <img
                                                                    alt="attachment"
                                                                    src={
                                                                        attachment.media_url.replace(
                                                                            "https://ton.twitter.com/1.1/",
                                                                            "https://ton.twitter.com/i/"
                                                                        ) +
                                                                        ":small"
                                                                    }
                                                                    style={{
                                                                        width: attachment
                                                                            .sizes
                                                                            .small
                                                                            .w,
                                                                        height: attachment
                                                                            .sizes
                                                                            .small
                                                                            .h,
                                                                        borderRadius: 10,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                }
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {moment(item.timestamp)
                                                    .local()
                                                    .format("lll")}{" "}
                                                <br />
                                                {moment(item.timestamp)
                                                    .local()
                                                    .fromNow()}
                                            </div>
                                            {item.readStamp ? (
                                                <Tooltip
                                                    placement="leftTop"
                                                    title={
                                                        "Read at: " +
                                                        moment(
                                                            item.readStamp
                                                                .datetime
                                                        )
                                                            .local()
                                                            .format("lll") +
                                                        "\n" +
                                                        "By: " +
                                                        item.readStamp.user
                                                            .email
                                                    }
                                                >
                                                    <Icon type="check-circle" />
                                                </Tooltip>
                                            ) : null}
                                        </List.Item>
                                    );
                                }}
                            />
                            <Divider />
                            <label id="postToChain">
                                Post on behalf of{" "}
                                <strong>{selectedChain.extra.page.name}</strong>
                            </label>
                            <TextArea
                                rows={4}
                                style={{ marginTop: 10 }}
                                value={this.state.directMessageReply}
                                onChange={(e) =>
                                    this.setState({
                                        directMessageReply: e.target.value,
                                    })
                                }
                            />
                            <Button
                                type="primary"
                                style={{
                                    float: "right",
                                    marginTop: 10,
                                }}
                                onClick={(e) => {
                                    typeof this.postToChain === "function" &&
                                        this.postToChain(
                                            this.state.directMessageReply
                                        );
                                }}
                                loading={this.state.chainProcessing}
                            >
                                Send
                            </Button>
                        </div>
                    </Fragment>
                );
            }

            if (
                selectedChain.platform === "instagram" &&
                selectedChain.type === "post_comment"
            ) {
                body = (
                    <Fragment>
                        <div className={"social_ChainContainer"}>
                            {/* <pre>
                                {JSON.stringify(selectedChain.extra, null, 2)}
                            </pre> */}
                            <InboxChainPost chain={selectedChain} />

                            <br />

                            <List
                                itemLayout="horizontal"
                                dataSource={
                                    selectedChain && selectedChain.chain
                                }
                                renderItem={(item) => {
                                    // const attachment =
                                    //     item.payload &&
                                    //     item.payload.direct_message_events &&
                                    //     item.payload.direct_message_events[0] &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data
                                    //         .attachment &&
                                    //     item.payload.direct_message_events[0]
                                    //         .message_create.message_data
                                    //         .attachment
                                    //         ? item.payload
                                    //               .direct_message_events[0]
                                    //               .message_create.message_data
                                    //               .attachment.media
                                    //         : null;
                                    const attachment = null;
                                    //console.info("attachment", attachment);
                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        src={item.from.photoURL}
                                                        style={{
                                                            float: "left",
                                                            marginRight: 10,
                                                            opacity: item.from
                                                                .photoURL
                                                                ? 1
                                                                : 0.3,
                                                        }}
                                                    />
                                                }
                                                title={
                                                    item.from.username
                                                        ? item.from.username
                                                        : item.from.id
                                                }
                                                description={
                                                    <div
                                                        style={{
                                                            marginRight: 120,
                                                        }}
                                                    >
                                                        {item.body}
                                                        {attachment ? (
                                                            <div
                                                                style={{
                                                                    marginLeft: 17,
                                                                    marginTop: 6,
                                                                    // width:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .w,
                                                                    // height:
                                                                    //     attachment
                                                                    //         .sizes
                                                                    //         .small
                                                                    //         .h,
                                                                    // // borderRadius: 10,
                                                                    // // border:
                                                                    // //     "1px solid",
                                                                    // backgroundImage: `url(${attachment.media_url.replace(
                                                                    //     "https://ton.twitter.com/1.1/",
                                                                    //     "https://ton.twitter.com/i/"
                                                                    // )})`,
                                                                }}
                                                            >
                                                                <img
                                                                    alt="attachment"
                                                                    src={
                                                                        attachment.media_url.replace(
                                                                            "https://ton.twitter.com/1.1/",
                                                                            "https://ton.twitter.com/i/"
                                                                        ) +
                                                                        ":small"
                                                                    }
                                                                    style={{
                                                                        width: attachment
                                                                            .sizes
                                                                            .small
                                                                            .w,
                                                                        height: attachment
                                                                            .sizes
                                                                            .small
                                                                            .h,
                                                                        borderRadius: 10,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                }
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: 0,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {moment(item.timestamp)
                                                    .local()
                                                    .format("lll")}{" "}
                                                <br />
                                                {moment(item.timestamp)
                                                    .local()
                                                    .fromNow()}
                                            </div>
                                            {item.readStamp ? (
                                                <Tooltip
                                                    placement="leftTop"
                                                    title={
                                                        "Read at: " +
                                                        moment(
                                                            item.readStamp
                                                                .datetime
                                                        )
                                                            .local()
                                                            .format("lll") +
                                                        "\n" +
                                                        "By: " +
                                                        item.readStamp.user
                                                            .email
                                                    }
                                                >
                                                    <Icon type="check-circle" />
                                                </Tooltip>
                                            ) : null}
                                        </List.Item>
                                    );
                                }}
                            />
                            <Divider />
                            <label id="postToChain">
                                Post on behalf of{" "}
                                <Avatar
                                    size={20}
                                    src={
                                        selectedChain.ourUser.instagram
                                            ? selectedChain.ourUser.instagram
                                                  .profile_picture_url
                                            : null
                                    }
                                    style={{ marginTop: -4, marginRight: 5 }}
                                />
                                <strong>
                                    @{selectedChain.ourUser.instagram.username}{" "}
                                    ({selectedChain.ourUser.instagram.name})
                                </strong>
                            </label>
                            <TextArea
                                rows={4}
                                style={{ marginTop: 10 }}
                                value={this.state.directMessageReply}
                                onChange={(e) =>
                                    this.setState({
                                        directMessageReply: e.target.value,
                                    })
                                }
                            />
                            <Button
                                type="primary"
                                style={{
                                    float: "right",
                                    marginTop: 10,
                                }}
                                onClick={(e) => {
                                    typeof this.postToChain === "function" &&
                                        this.postToChain(
                                            this.state.directMessageReply
                                        );
                                }}
                                loading={this.state.chainProcessing}
                            >
                                Send
                            </Button>
                        </div>
                    </Fragment>
                );
            }
        } else {
            // specific overall view
            body = (
                <Fragment>
                    <InboxOverview
                        // unread_posts={this.state.unread_posts}
                        store={this.props.store}
                        postToChain={this.postToChain}
                        clickOnChain={this.clickOnChain}
                        markChainAsRead={this.markChainAsRead}
                        chains={this.state.selected_chains}
                        overview_filter={this.props.overviewFilter}
                        handleUpdateOverviewFilter={
                            this.handleUpdateOverviewFilter
                        }
                    />
                </Fragment>
            );
        }
        return body;
    }
}

export default observer(InboxChain);
