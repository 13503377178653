import React, { useState, Component } from "react";
import moment from "moment";
import { Button } from "antd";

const boxStyle = {
    // border: "1px dotted #ccc",
    padding: 0,
    cursor: "pointer",
};

const InboxChainSingleChain = (props) => {
    let textareaAttribute = React.createRef();

    const { chain } = props;

    const [replyVisible, setReplyVisible] = useState(false);

    return (
        <div style={boxStyle} className="social_InboxOverviewMessage">
            <div className="social_InboxOverviewMessage_channel">
                <div
                    className={
                        chain.platform === "facebook"
                            ? "social_iconFacebook"
                            : chain.platform === "twitter"
                            ? "social_iconTwitter"
                            : chain.platform === "instagram"
                            ? "social_iconInstagram"
                            : chain.platform === "linkedin"
                            ? "social_iconLinkedin"
                            : null
                    }
                    style={{
                        backgroundColor: props.store.getTagColorForType(
                            chain.platform
                        ),
                        borderRadius: "100%",
                        width: 17,
                        height: 17,
                        backgroundSize: 13,
                        float: "left",
                    }}
                />
                Channel:{" "}
                <strong>
                    {chain.ourUser.name}{" "}
                    {chain.ourUser.brand
                        ? "(" + chain.ourUser.brand + ")"
                        : null}{" "}
                    - {chain.oppositeUser.name}
                </strong>
            </div>
            {chain.unread_posts.map((post) => (
                <div
                    key={post.id}
                    className="social_InboxOverviewMessage_channelBody"
                >
                    <div className="social_InboxOverviewMessage_channelHeader">
                        <div>
                            From
                            <b>{post.from.name}</b>
                            {/* => <b>{post.to.name}</b> */}
                        </div>

                        <div>
                            {moment(chain.updated).local().format("lll")}
                            <span>
                                {moment(chain.updated).local().fromNow()}
                            </span>
                        </div>
                    </div>
                    <span className="social_InboxOverviewMessage_channelText">
                        {post.body}
                    </span>
                </div>
            ))}
            <Button
                onClick={(e) => setReplyVisible(true)}
                disabled={replyVisible}
            >
                Reply to channel
            </Button>
            &nbsp;&nbsp;
            <Button onClick={(e) => props.clickOnChain && props.clickOnChain()}>
                Open
            </Button>
            &nbsp;&nbsp;
            <Button
                onClick={(e) =>
                    props.markChainAsRead && props.markChainAsRead()
                }
            >
                Mark channel as read
            </Button>
            {replyVisible ? (
                <div style={{ margin: "10px 0" }}>
                    <br />
                    <br />
                    <textarea
                        ref={textareaAttribute}
                        style={{ width: "100%" }}
                    ></textarea>
                    <br />
                    <Button
                        onClick={(e) =>
                            props.postToChain &&
                            props.postToChain(
                                textareaAttribute.current.value,
                                chain,
                                chain.id
                            )
                        }
                    >
                        Post
                    </Button>
                </div>
            ) : null}
            {/* <pre>{JSON.stringify(chain, null, 2)}</pre> */}
        </div>
    );
};

export default InboxChainSingleChain;
