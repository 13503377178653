import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ScheduleContainer from "./ScheduleContainer";
import ScheduleList from "./ScheduleList";

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.store.setCurrentSection("schedule");
        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Calendar" }]);
    }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/dashboard/schedule/month"
                    render={(props) => (
                        <ScheduleContainer
                            store={this.props.store}
                            month
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path="/dashboard/schedule/list"
                    render={(props) => (
                        <ScheduleList store={this.props.store} {...props} />
                    )}
                />
                <Route
                    exact
                    path="/dashboard/schedule/:date"
                    render={(props) => (
                        <ScheduleContainer
                            store={this.props.store}
                            date
                            {...props}
                        />
                    )}
                />
                <Route
                    render={(props) => (
                        <ScheduleContainer
                            store={this.props.store}
                            {...props}
                        />
                    )}
                />
            </Switch>
        );
    }
}

export default Schedule;
