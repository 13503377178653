import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import TagsList from "./TagsList";
import TagsReport from "./TagsReport";

import { observer } from "mobx-react";

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("tags");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Tags" }]);

        //this.setState({ tags: this.props.store.getAllTags() });
    }

    render() {
        return (
            <Switch>
                <Route
                    path="/dashboard/tags/:id/report"
                    render={props => (
                        <TagsReport store={this.props.store} {...props} />
                    )}
                />
                <Route
                    render={props => (
                        <TagsList store={this.props.store} {...props} />
                    )}
                />
            </Switch>
        );
    }
}

export default observer(Tags);
