import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Icon,
    Checkbox,
    Divider,
    Input,
    notification,
    Tree,
    Avatar,
    Card,
    Radio,
} from "antd";
import moment from "moment";
import { observer } from "mobx-react";
import InboxChain from "./InboxChain";

import { social_notification } from "../../functions";

const { Search } = Input;
const { TreeNode } = Tree;

const MARKCHAINASREAD_TIMEOUT = 3000;

const InboxBackArrow = (props) => {
    return (
        <Fragment>
            <Icon
                type={"arrow-left"}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 10,
                    cursor: "pointer",
                    padding: 10,
                    // height: this.state.hideLeftTree
                    //     ? "calc(100vh - 210px)"
                    //     : null,
                }}
                title={props.chain_key}
                onClick={(e) => props.clickOnIcon([props.chain_key])}
            />
        </Fragment>
    );
};

class Inbox extends Component {
    markChainAsReadTimeout = null;

    constructor(props) {
        super(props);
        this.state = {
            chainProcessing: false,
            treeFilterVisible: false,
            treeFilterShowTwitterDirectMessages: true,
            treeFilterShowTwitterPostComments: true,
            treeFilterShowFacebookDirectMessages: true,
            treeFilterShowFacebookPostComments: true,
            treeFilterShowInstagramPostComments: true,
            treeFilterSortBy: "brand",
            hideLeftTree: false,
            overviewFilter: {},
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("inbox");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Inbox" }]);
    }

    componentWillUnmount() {
        //base.removeBinding(this.infoRef);
        this.props.store.unregisterModalListener("inbox_modal");
    }

    getNotifications = () => {
        const notifications = this.props.store.getAllNotifications.filter(
            (n) => {
                //if (n.status == "new") return n;
                return n;
            }
        );
        console.info("getNotifications notifications", notifications);
        return notifications;
    };

    getTagColorForNotificationType = (_notification_type) => {
        let answer = "blue";
        switch (_notification_type) {
            case "error":
                answer = this.props.store.getTagColorForType("error");
                break;
            case "message":
                answer = this.props.store.getTagColorForType("default");
                break;
            case "approval":
                answer = this.props.store.getTagColorForType("warning");
                break;
            default:
                answer = this.props.store.getTagColorForType("default");
                break;
        }
        return answer;
    };

    onCheckChange = (_key, _evt) => {
        console.log(`onCheckChange checked = ${_evt.target.checked}`);
        console.info(`onCheckChange _key`, _key);

        let selected = this.state.selected_notifications;
        if (_evt.target.checked) {
            // add to the selected
            selected.push(_key);
        } else {
            // remove
            selected = selected.filter((s) => s !== _key);
        }
        this.setState({ selected_notifications: selected });
    };

    handleSelectAll = () => {
        const notifications = this.getNotifications();
        let selected_notifications = [];
        notifications.map((n) => {
            selected_notifications.push(n.key);
            return false;
        });
        this.setState({ selected_notifications });
    };

    handleDiscardSelection = () => {
        this.setState({ selected_notifications: [] });
    };

    handleMenuClick = (_evt, _notification_key) => {
        console.log(
            "handleMenuClick _evt",
            _evt,
            "_notification_key",
            _notification_key
        );
        if (_evt.key === "select_all") this.handleSelectAll();
        if (_evt.key === "discard_selection") this.handleDiscardSelection();
        if (_evt.key === "delete") this.handleDelete(_notification_key);
        if (_evt.key === "mark_as_read")
            this.handleChangeStatusTo(_notification_key, "read");
        if (_evt.key === "mark_as_new")
            this.handleChangeStatusTo(_notification_key, "new");
        if (_evt.key === "edit") this.handleEdit(_notification_key);
        if (_evt.key === "delete_selection") this.handleDeleteSelection();
    };

    handleDeleteSelection = async () => {
        console.info(
            "handleDeleteSelection selected_notifications",
            this.state.selected_notifications
        );
        const keysToDelete = JSON.parse(
            JSON.stringify(this.state.selected_notifications)
        );
        console.info("handleDeleteSelection keysToDelete", keysToDelete);

        const key = "progress_notification";

        social_notification({
            type: "info",
            key,
            duration: 0,
            title: "Deleting ...",
            description: "Please wait while we delete the notifications",
            icon: <Icon type="loading" />,
        });

        await keysToDelete.reduce(async (previousPromise, nextKey) => {
            await previousPromise;
            return this.props.store.deleteNotification(nextKey);
        }, Promise.resolve());

        // Close the notification
        notification.close(key);

        this.setState({ selected_notifications: [], selected: null });
    };

    handleDelete = async (_key) => {
        console.info("handleDelete _key", _key);
        await this.props.store.deleteNotification(_key);
        this.setState({ selected: null });
    };
    handleEdit = (_key) => {
        console.info("handleEdit _key", _key);

        let curNotification = this.props.store.getAllNotifications.filter(
            (n) => {
                if (n.key === _key) return n;
                return false;
            }
        )[0];

        if (curNotification.related_to) {
            console.log("WE HAVE RELATED NOTIFICATION");
            this.props.store
                .getNotificationRelatedElement(curNotification.key)
                .then((relatedInformation) => {
                    curNotification.related_element =
                        relatedInformation.related_object;
                    this.setState({ edit_data: curNotification });
                    this.showEdit();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.info(
                "NOOOOO RELATED NOTIFICATION curNotification",
                curNotification
            );
            this.setState({ edit_data: curNotification });
            this.showEdit();
        }
    };

    handleChangeStatusTo = (_key, _new_status) => {
        let curNotification = this.props.store.getAllNotifications.filter(
            (n) => {
                if (n.key === _key) return n;
                return false;
            }
        )[0];
        curNotification.status = _new_status;
        curNotification.status_changed_on = moment().utc();
        this.props.store.updateNotification(_key, curNotification);
    };

    handleClickOnNotifications = (_notification) => {
        console.info("handleClickOnNotifications _notification", _notification);
        // get related information
        if (
            _notification.related_to &&
            this.state.selected_notifications.length < 2
        ) {
            this.props.store
                .getNotificationRelatedElement(_notification.key)
                .then((relatedInformation) => {
                    console.info("relatedInformation", relatedInformation);
                    let selected = this.state.selected;
                    selected.related_element =
                        relatedInformation.related_object;
                    this.setState({ selected });

                    // return this.props.store.addNoteNotification(
                    //     _notification.key
                    // );
                })
                .then((notificationNotes) => {
                    // something
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        // ANything else
        this.setState({ selected: _notification });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    showEdit = () => {
        console.log("showEdit");
        this.setState({
            //edit_visible: true,
            //edit_confirmLoading: false
        });

        this.props.store.showModalByKey("inbox_modal");
    };
    handleEditCancel = () => {
        this.setState({ edit_visible: false });
    };
    handleAddNote = () => {
        console.info(
            "handleAddNote this.state.addNoteText",
            this.state.addNoteText
        );
        if (this.state.addNoteText.length > 0) {
            this.props.store
                .addNoteNotification(
                    this.state.selected.key,
                    this.state.addNoteText
                )
                .then((updatedNotificationData) => {
                    // something
                    console.info(
                        "updatedNotificationData",
                        updatedNotificationData
                    );
                    this.setState({
                        selected: updatedNotificationData,
                        addNoteText: "",
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };
    handleApprove = (item) => {
        console.info("handleApprove item", item);

        if (this.state.selected.type === "approval") {
            this.props.store
                .approveAccountQueuePost(
                    this.state.selected.related_to.account_key,
                    this.state.selected.related_to.queue_index
                )
                .then((result) => {
                    console.info("result", result);
                    notification.success({
                        message: "Approved",
                        description:
                            "The post was approved and will be published accordingly.",
                    });
                });
        }
    };

    onInboxSearchChange = (_filter) => {
        //console.info("onAccountsSearchChange _filter", _filter);
        this.setState({ filter: _filter });
    };

    renderSingleChain = (_chain) => {
        let result;
        const ELEMENT_HEIGHT = 63;
        // Read class
        const readClass =
            _chain.read && _chain.read === true
                ? ""
                : "social_InboxChainElement_Unread";

        if (_chain.platform === "twitter" && _chain.type === "direct_message") {
            result = (
                <TreeNode
                    title={
                        <div
                            className={"social_InboxChainElement " + readClass}
                        >
                            {/* <Avatar
                                size={17}
                                style={{
                                    marginTop: -1,
                                    marginRight: 5,
                                }}
                                src={_chain.oppositeUser.photoURL}
                            /> */}
                            {_chain.ourUser.name}
                            <br />
                            <span
                                className={
                                    "social_InboxChainElement_secondLine"
                                }
                            >
                                <Avatar
                                    size={35}
                                    shape="square"
                                    className={"social_iconTwitterDM"}
                                    style={{
                                        backgroundColor: "#E3E3E3",
                                        marginRight: 5,
                                        float: "left",
                                        backgroundSize: 27,
                                        backgroundPosition: "center 5px",
                                    }}
                                    // src={_chain.ourPost.post_file.url}
                                />
                                <strong>with</strong>&nbsp;
                                {_chain.oppositeUser.name}
                            </span>
                            <div className={"social_InboxChainElement_date"}>
                                {moment(_chain.updated).format("lll")}
                            </div>
                        </div>
                    }
                    key={_chain.key}
                    icon={
                        <Icon
                            type="twitter"
                            style={{
                                color: "white",
                                background:
                                    this.props.store.getTagColorForType(
                                        "twitter"
                                    ),
                                borderRadius: "100%",
                                padding: 3,
                                fontSize: 11,
                            }}
                        />
                    }
                    style={{ height: ELEMENT_HEIGHT }}
                />
            );
        }

        if (_chain.platform === "twitter" && _chain.type === "post_comment") {
            result = (
                <TreeNode
                    title={
                        <div
                            className={"social_InboxChainElement " + readClass}
                            style={{
                                whiteSpace: "break-spaces",
                                height: ELEMENT_HEIGHT,
                            }}
                        >
                            {/* <Icon
                                type="picture"
                                // theme="filled"
                                className="social_InboxChainElement_TypeIcon"
                            /> */}
                            {_chain.ourUser.screen_name}
                            <br />
                            <span
                                className={
                                    "social_InboxChainElement_secondLine"
                                }
                            >
                                <Avatar
                                    size={35}
                                    shape="square"
                                    style={{
                                        marginRight: 5,
                                        // borderRadius: 0,
                                        float: "left",
                                        color: "white",
                                        backgroundColor: "#E3E3E3",
                                        fontSize: 12,
                                        fontWeight: 900,
                                        letterSpacing: "-0.05rem",
                                    }}
                                    //src={_chain.ourPost.post_file.url}
                                >
                                    tweet
                                </Avatar>
                                {_chain.extra && _chain.extra.data[0].text}
                            </span>
                            <div className={"social_InboxChainElement_date"}>
                                {moment(_chain.updated).format("lll")}
                            </div>
                        </div>
                    }
                    key={_chain.key}
                    icon={
                        <Icon
                            type="twitter"
                            style={{
                                color: "white",
                                background:
                                    this.props.store.getTagColorForType(
                                        "twitter"
                                    ),
                                borderRadius: "100%",
                                padding: 3,
                                fontSize: 11,
                            }}
                        />
                    }
                    style={{ height: ELEMENT_HEIGHT }}
                />
            );
        }

        if (
            _chain.platform === "facebook" &&
            _chain.type === "direct_message"
        ) {
            result = (
                <TreeNode
                    title={
                        <div
                            className={"social_InboxChainElement " + readClass}
                        >
                            {/* <Icon
                                type="message"
                                // theme="filled"
                                className="social_InboxChainElement_TypeIcon"
                            /> */}
                            {/* <Avatar
                                size={17}
                                style={{
                                    marginTop: -1,
                                    marginRight: 5,
                                }}
                                src={_chain.oppositeUser.photoURL}
                            /> */}
                            {_chain.ourUser.name}
                            <br />
                            <span
                                className={
                                    "social_InboxChainElement_secondLine"
                                }
                            >
                                <Avatar
                                    size={35}
                                    className={"social_iconFacebookDM"}
                                    shape="square"
                                    style={{
                                        backgroundColor: "#E3E3E3",
                                        marginRight: 5,
                                        // borderRadius: 0,
                                        float: "left",
                                        backgroundSize: 27,
                                        backgroundPosition: "center 5px",
                                    }}
                                    // src={_chain.ourPost.post_file.url}
                                />
                                <strong>with</strong>&nbsp;
                                {_chain.oppositeUser.name}
                            </span>
                            <div className={"social_InboxChainElement_date"}>
                                {moment(_chain.updated).format("lll")}
                            </div>
                        </div>
                    }
                    key={_chain.key}
                    icon={
                        <div
                            className="social_iconFacebook"
                            style={{
                                backgroundColor:
                                    this.props.store.getTagColorForType(
                                        "facebook"
                                    ),
                                borderRadius: "100%",
                                width: 17,
                                height: 17,
                                backgroundSize: 13,
                                marginTop: 4,
                                marginLeft: 4,
                            }}
                        />
                    }
                    style={{ height: ELEMENT_HEIGHT }}
                />
            );
        }

        if (_chain.platform === "facebook" && _chain.type === "post_comment") {
            result = (
                <TreeNode
                    title={
                        <div
                            className={"social_InboxChainElement " + readClass}
                            style={{
                                whiteSpace: "break-spaces",
                                height: ELEMENT_HEIGHT,
                            }}
                        >
                            {/* <Icon
                                type="picture"
                                // theme="filled"
                                className="social_InboxChainElement_TypeIcon"
                            /> */}
                            {/* <Avatar
                                size={17}
                                style={{
                                    marginTop: -1,
                                    marginRight: 5,
                                }}
                                src={_chain.oppositeUser.photoURL}
                            /> */}
                            {_chain.extra.page
                                ? _chain.extra.page.name
                                : _chain.extra.account_name}
                            <br />
                            <span
                                className={
                                    "social_InboxChainElement_secondLine"
                                }
                            >
                                {/* {_chain.chain[_chain.chain.length - 1].body} */}
                                {_chain.ourPost ? (
                                    <Fragment>
                                        {_chain.ourPost &&
                                        _chain.ourPost.post_file &&
                                        _chain.ourPost.post_file.url ? (
                                            <Avatar
                                                size={35}
                                                shape="square"
                                                style={{
                                                    marginRight: 5,
                                                    // borderRadius: 0,
                                                    float: "left",
                                                }}
                                                src={
                                                    _chain.ourPost.post_file.url
                                                }
                                            />
                                        ) : null}

                                        {_chain.ourPost &&
                                        _chain.ourPost.post_text
                                            ? _chain.ourPost.post_text
                                            : _chain.chain[
                                                  _chain.chain.length - 1
                                              ].body}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {_chain.extra &&
                                        _chain.extra.post_attachments_result &&
                                        _chain.extra.post_attachments_result
                                            .data &&
                                        _chain.extra.post_attachments_result
                                            .data[0] &&
                                        _chain.extra.post_attachments_result
                                            .data[0].media &&
                                        _chain.extra.post_attachments_result
                                            .data[0].media.image ? (
                                            <Avatar
                                                size={35}
                                                shape="square"
                                                style={{
                                                    marginRight: 5,
                                                    // borderRadius: 0,
                                                    float: "left",
                                                }}
                                                src={
                                                    _chain.extra
                                                        .post_attachments_result
                                                        .data[0].media.image.src
                                                }
                                            />
                                        ) : null}
                                        {_chain.extra &&
                                        _chain.extra.post_result
                                            ? _chain.extra.post_result.message
                                            : _chain.chain[
                                                  _chain.chain.length - 1
                                              ].body}
                                    </Fragment>
                                )}
                            </span>
                            <div className={"social_InboxChainElement_date"}>
                                {moment(_chain.updated).format("lll")}
                            </div>
                        </div>
                    }
                    key={_chain.key}
                    icon={
                        <div
                            className="social_iconFacebook"
                            style={{
                                backgroundColor:
                                    this.props.store.getTagColorForType(
                                        "facebook"
                                    ),
                                borderRadius: "100%",
                                width: 17,
                                height: 17,
                                backgroundSize: 13,
                                marginTop: 4,
                                marginLeft: 4,
                            }}
                        />
                    }
                    style={{ height: ELEMENT_HEIGHT }}
                />
            );
        }

        if (_chain.platform === "instagram" && _chain.type === "post_comment") {
            result = (
                <TreeNode
                    title={
                        <div
                            className={"social_InboxChainElement " + readClass}
                            style={{
                                whiteSpace: "break-spaces",
                                height: ELEMENT_HEIGHT,
                            }}
                        >
                            {/* <Icon
                                type="picture"
                                // theme="filled"
                                className="social_InboxChainElement_TypeIcon"
                            /> */}
                            {/* <Avatar
                                size={17}
                                style={{
                                    marginTop: -1,
                                    marginRight: 5,
                                }}
                                src={
                                    _chain.ourUser.instagram.profile_picture_url
                                }
                            /> */}
                            {_chain.ourUser.instagram
                                ? _chain.ourUser.instagram.name
                                : _chain.ourUser.name}
                            <br />
                            <span
                                className={
                                    "social_InboxChainElement_secondLine"
                                }
                            >
                                <Avatar
                                    size={35}
                                    shape="square"
                                    style={{
                                        marginRight: 5,
                                        // borderRadius: 0,
                                        float: "left",
                                    }}
                                    src={_chain.extra.media.media_url}
                                />
                                {_chain.extra.comment.text}
                            </span>
                            <div className={"social_InboxChainElement_date"}>
                                {moment(_chain.updated).format("lll")}
                            </div>
                        </div>
                    }
                    key={_chain.key}
                    icon={
                        <Icon
                            type="instagram"
                            theme="filled"
                            style={{
                                color: "white",
                                background:
                                    this.props.store.getTagColorForType(
                                        "instagram"
                                    ),
                                borderRadius: "100%",
                                padding: 3,
                                fontSize: 11,
                            }}
                        />
                    }
                    style={{ height: ELEMENT_HEIGHT }}
                />
            );
        }

        return result;
    };
    renderChainsTree = (chains) => {
        //console.info("chains", chains);
        const chainsOurUsersKeys = chains.map(
            (chain) => chain.ourUser && chain.ourUser.key
        );
        //console.info("chainsOurUsersKeys", chainsOurUsersKeys);
        let chainsBrands =
            this.props.store.getBrandsForAccountKeys(chainsOurUsersKeys);
        //chainsBrands.push(undefined);
        //console.info("chainsBrands", chainsBrands);

        const filter =
            this.state.treeFilter && this.state.treeFilter.length > 2
                ? this.state.treeFilter.toUpperCase()
                : null;
        //console.log("renderChainsTree filter = " + filter);
        // collect chains in brands/undefined
        Object.keys(chainsBrands).map((chainBrandKey) => {
            //console.info("chainchainBrandKeyBrand", chainBrandKey);
            chainsBrands[chainBrandKey].map((brandAccount) => {
                brandAccount.chains = chains.filter(
                    (chain) =>
                        chain.ourUser && chain.ourUser.key === brandAccount.key
                );

                // sort the chains
                brandAccount.chains.sort(
                    (a, b) => parseInt(b.updated) - parseInt(a.updated)
                );

                brandAccount.updated = Math.max(
                    ...brandAccount.chains.map((chain) => chain.updated)
                );

                console.log("brandAccount.updated=" + brandAccount.updated);
                return true;
            });

            // chainsBrands[chainBrandKey].updated = Math.max(
            //     ...chainsBrands[chainBrandKey].map(
            //         (brandAccount) => brandAccount.updated
            //     )
            // );
            // console.log(
            //     "chainsBrands[chainBrandKey].updated=" +
            //         chainsBrands[chainBrandKey].updated
            // );

            return true;
        });

        if (this.state.treeFilterSortBy === "brand") {
            // filter
            if (filter) {
                Object.keys(chainsBrands).map((brand) => {
                    const curBrand = chainsBrands[brand];
                    curBrand.map((brandAccount) => {
                        if (
                            brandAccount.name.toUpperCase().indexOf(filter) !==
                            -1
                        )
                            brandAccount.visible = true;
                        // chains
                        brandAccount.chains.map((chain) => {
                            if (
                                chain.platform === "twitter" &&
                                chain.type === "direct_message" &&
                                this.state
                                    .treeFilterShowTwitterDirectMessages ===
                                    true
                            ) {
                                chain.chain.map((msg) => {
                                    if (
                                        msg.body
                                            .toUpperCase()
                                            .indexOf(filter) !== -1
                                    ) {
                                        msg.visible = true;
                                        chain.visible = true;
                                        brandAccount.visible = true;
                                    }
                                    return true;
                                });
                            }
                            if (
                                chain.platform === "twitter" &&
                                chain.type === "post_comment" &&
                                this.state.treeFilterShowTwitterPostComments ===
                                    true
                            ) {
                                if (
                                    chain.extra.data[0].text
                                        .toUpperCase()
                                        .indexOf(filter) !== -1
                                ) {
                                    chain.visible = true;
                                    brandAccount.visible = true;
                                }

                                chain.chain.map((msg) => {
                                    if (
                                        msg.body
                                            .toUpperCase()
                                            .indexOf(filter) !== -1
                                    ) {
                                        msg.visible = true;
                                        chain.visible = true;
                                        brandAccount.visible = true;
                                    }
                                    return true;
                                });
                            }
                            if (
                                chain.platform === "facebook" &&
                                chain.type === "direct_message" &&
                                this.state
                                    .treeFilterShowFacebookDirectMessages ===
                                    true
                            ) {
                                chain.chain.map((msg) => {
                                    if (
                                        msg.body
                                            .toUpperCase()
                                            .indexOf(filter) !== -1
                                    ) {
                                        msg.visible = true;
                                        chain.visible = true;
                                        brandAccount.visible = true;
                                    }
                                    return true;
                                });
                            }

                            if (
                                chain.platform === "facebook" &&
                                chain.type === "post_comment" &&
                                this.state
                                    .treeFilterShowFacebookPostComments === true
                            ) {
                                chain.chain.map((msg) => {
                                    if (
                                        msg.body
                                            .toUpperCase()
                                            .indexOf(filter) !== -1
                                    ) {
                                        msg.visible = true;
                                        chain.visible = true;
                                        brandAccount.visible = true;
                                    }
                                    return true;
                                });
                            }

                            if (
                                chain.platform === "instagram" &&
                                chain.type === "post_comment" &&
                                this.state
                                    .treeFilterShowInstagramPostComments ===
                                    true
                            ) {
                                chain.chain.map((msg) => {
                                    if (
                                        msg.body
                                            .toUpperCase()
                                            .indexOf(filter) !== -1
                                    ) {
                                        msg.visible = true;
                                        chain.visible = true;
                                        brandAccount.visible = true;
                                    }
                                    return true;
                                });
                            }
                            return true;
                        });

                        brandAccount.updated = Math.max(
                            ...brandAccount.chains.map((chain) => chain.updated)
                        );

                        return true;
                    });

                    curBrand.updated = Math.max(
                        ...curBrand.map((brandAccount) => brandAccount.updated)
                    );

                    return true;
                });
            } else {
                console.log("AFTER FILTER no filter");
                // no filter => everything is visible
                Object.keys(chainsBrands).map((brand) => {
                    const curBrand = chainsBrands[brand];

                    curBrand.map((brandAccount) => {
                        brandAccount.visible = true;
                        // chains
                        brandAccount.chains.map((chain) => {
                            delete chain.visible;
                            if (
                                chain.platform === "twitter" &&
                                chain.type === "direct_message" &&
                                this.state
                                    .treeFilterShowTwitterDirectMessages ===
                                    true
                            ) {
                                chain.visible = true;
                            }
                            if (
                                chain.platform === "twitter" &&
                                chain.type === "post_comment" &&
                                this.state.treeFilterShowTwitterPostComments ===
                                    true
                            ) {
                                chain.visible = true;
                            }
                            if (
                                chain.platform === "facebook" &&
                                chain.type === "direct_message" &&
                                Boolean(
                                    this.state
                                        .treeFilterShowFacebookDirectMessages
                                ) === true
                            ) {
                                chain.visible = true;
                            }
                            if (
                                chain.platform === "facebook" &&
                                chain.type === "post_comment" &&
                                Boolean(
                                    this.state
                                        .treeFilterShowFacebookPostComments
                                ) === true
                            ) {
                                chain.visible = true;
                            }
                            if (
                                chain.platform === "instagram" &&
                                chain.type === "post_comment" &&
                                Boolean(
                                    this.state
                                        .treeFilterShowInstagramPostComments
                                ) === true
                            ) {
                                chain.visible = true;
                            }
                            return true;
                        });

                        brandAccount.updated = Math.max(
                            ...brandAccount.chains.map((chain) => chain.updated)
                        );

                        return true;
                    });

                    curBrand.updated = Math.max(
                        ...curBrand.map((brandAccount) => brandAccount.updated)
                    );

                    return true;
                });
            }

            console.info(
                "AFTER FILTER renderChainsTree chainsBrands",
                chainsBrands
            );

            const chainsBrandsArray = Object.keys(chainsBrands).map((key) => ({
                updated: chainsBrands[key].updated,
                brand: chainsBrands[key],
                key,
            }));
            chainsBrandsArray.sort(
                (a, b) => parseInt(b.updated) - parseInt(a.updated)
            );

            console.info(
                "AFTER FILTER renderChainsTree chainsBrandsArray",
                chainsBrandsArray
            );
            // const twitterChains = chains.filter(
            //     (chain) => chain.platform === "twitter"
            // );
            //let unbrandedChains =

            // console.info("renderChainsTree chainsBrands", chainsBrands);
            return (
                chainsBrandsArray &&
                chainsBrandsArray.map((brand) => {
                    // const curBrand = chainsBrands[brand];
                    const curBrand = brand;
                    //console.log("brand.toString() = " + brand.toString());
                    if (
                        curBrand.key.toString() === "undefined" ||
                        curBrand.key.toString() === ""
                    ) {
                        // return <TreeNode title="No Brands" key={"nobrands???"} />;

                        return curBrand.brand.map((brandAccount) => {
                            // console.info("NO BRANDS brandAccount", brandAccount);

                            let element = (
                                <TreeNode
                                    // title={
                                    //     brandAccount.name +
                                    //     " - " +
                                    //     brandAccount.updated
                                    // }
                                    title={brandAccount.name}
                                    key={"account|" + brandAccount.key}
                                    selectable={true}
                                >
                                    {brandAccount.chains.map((chain) => {
                                        return chain.visible
                                            ? this.renderSingleChain(chain)
                                            : null;
                                    })}
                                </TreeNode>
                            );

                            return brandAccount.visible ? element : null;
                        });
                    } else {
                        // let curBrandUpdated = Math.max(
                        //     ...curBrand.map((chain) => chain.updated)
                        // );
                        // curBrand.updated

                        console.info("curBrand", curBrand);
                        let childCount = 0;
                        let parentNode = (
                            <TreeNode
                                // title={curBrand.key + " | " + curBrand.updated}
                                title={curBrand.key}
                                key={"brand|" + curBrand.key}
                                selectable={true}
                            >
                                {curBrand.brand.map((brandAccount) => {
                                    let node = (
                                        <TreeNode
                                            // title={
                                            //     brandAccount.name +
                                            //     "-" +
                                            //     brandAccount.updated
                                            // }
                                            title={brandAccount.name}
                                            key={"account|" + brandAccount.key}
                                            selectable={true}
                                        >
                                            {brandAccount.chains.map(
                                                (chain) => {
                                                    return chain.visible
                                                        ? this.renderSingleChain(
                                                              chain
                                                          )
                                                        : null;
                                                }
                                            )}
                                        </TreeNode>
                                    );
                                    if (brandAccount.visible) {
                                        childCount++;
                                        return node;
                                    } else {
                                        return null;
                                    }
                                })}
                            </TreeNode>
                        );
                        return childCount > 0 ? parentNode : null;
                    }
                })
            );
        } // end of sort by brands

        if (this.state.treeFilterSortBy === "platform") {
            // collect the platforms
            let treeInfo = [];
            const accounts = this.props.store.getAllAccounts;
            let platforms = chains
                .map((chain) => chain.platform + "|" + chain.type)
                .filter((x, i, a) => a.indexOf(x) === i);

            console.info("platforms", platforms);
            console.info("chains", chains);

            platforms.map((platform) => {
                let platform_name = "";
                switch (platform) {
                    case "twitter|direct_message":
                        platform_name = "Twitter DMs";
                        break;
                    case "twitter|post_comment":
                        platform_name = "Twitter Replies";
                        break;
                    case "facebook|direct_message":
                        platform_name = "Facebook Messenger";
                        break;
                    case "facebook|post_comment":
                        platform_name = "Facebook Post Comments";
                        break;
                    case "instagram|post_comment":
                        platform_name = "Instagram Post Comments";
                        break;
                    default:
                        break;
                }
                console.log(
                    "platform_name = " +
                        platform_name +
                        ", length=" +
                        platform_name.length
                );

                const [curPlatform, curType] = platform.split("|");

                let platform_chains = chains.filter(
                    (chain) =>
                        chain.platform === curPlatform && chain.type === curType
                );
                console.info("platform_chains", platform_chains);

                let platform_accounts = platform_chains
                    .map((chain) => chain.ourUser && chain.ourUser.key)
                    .filter((x, i, a) => a.indexOf(x) === i)
                    // remove missing
                    .filter((account_key) => {
                        const account = accounts.filter(
                            (account) => account.key === account_key
                        );
                        return account && account[0] && account[0].name;
                    })
                    .map((account_key) => {
                        const account = accounts.filter(
                            (account) => account.key === account_key
                        );
                        let chains = platform_chains.filter(
                            (chain) =>
                                chain.ourUser &&
                                chain.ourUser.key === account_key
                        );

                        // sort the chains
                        chains.sort(
                            (a, b) => parseInt(b.updated) - parseInt(a.updated)
                        );

                        return {
                            key: account_key,
                            name:
                                account && account[0] ? account[0].name : "N/A",
                            chains: chains,
                            updated: Math.max(
                                ...chains.map((chain) => chain.updated)
                            ),
                        };
                    });

                if (platform_name !== "" && platform_accounts.length > 0) {
                    treeInfo.push({
                        title: platform_name,
                        key: platform,
                        accounts: platform_accounts,
                        updated: Math.max(
                            platform_accounts.map((pa) => pa.updated)
                        ),
                    });
                }
                return true;
            });

            console.info("treeInfo", treeInfo);

            const result = treeInfo.map((platform) => {
                return (
                    <TreeNode
                        title={platform.title}
                        key={"platform|" + platform.key}
                        selectable={true}
                    >
                        {platform.accounts.map(
                            (platformAccount, platformIndex) => (
                                <TreeNode
                                    // title={
                                    //     platformAccount.name +
                                    //     " - " +
                                    //     platformAccount.updated
                                    // }
                                    title={platformAccount.name}
                                    key={platformIndex}
                                    selectable={false}
                                    blockNode={true}
                                >
                                    {platformAccount.chains.map((chain) =>
                                        this.renderSingleChain(chain)
                                    )}
                                </TreeNode>
                            )
                        )}
                    </TreeNode>
                );
            });

            return result;
        }
    };
    clickOnTree = (_selectedKeys, _e) => {
        console.info("clickOnTree _selectedKeys", _selectedKeys, "_e", _e);
        const selectedKey = _selectedKeys[0];
        // const selectedChain = this.props.store.getChains.filter(
        //     (chain) => chain.key === selectedKey
        // )[0];

        this.setState({
            selectedChainKey: selectedKey,
        });

        if (selectedKey && selectedKey.indexOf("|") !== -1) {
            this.setState({ lastOverviewChainKey: selectedKey });
            return;
        }
        // mark read if it is a chain
        this.markChainAsRead(selectedKey);

        //console.info("clickOnTree selectedKey=" + selectedKey);
    };

    markChainAsRead = (_selectedKey, _skipTimeout) => {
        clearTimeout(this.markChainAsReadTimeout);
        let that = this;
        this.markChainAsReadTimeout = setTimeout(
            function () {
                console.info("markChainAsRead selectedKey=" + _selectedKey);
                that.props.store
                    .markChainAsRead(_selectedKey)
                    .then((result) => {
                        // console.info("result", result);
                        // const oldselectedChainKey = that.state.selectedChainKey;
                        // that.setState({ selectedChainKey: null });
                        // setTimeout(function () {
                        //     that.setState({
                        //         selectedChainKey: oldselectedChainKey,
                        //     });
                        // }, 100);
                    })
                    .catch((err) => {
                        console.info("err", err);
                    });
            },
            !_skipTimeout ? MARKCHAINASREAD_TIMEOUT : 1
        );
    };

    toggleLeftTree = (_e) => {
        this.setState({ hideLeftTree: !this.state.hideLeftTree });
    };

    handleClickOnPost = (_chain_key, _e) => {
        console.log("handleClickOnPost _chain_key=" + _chain_key);
        this.clickOnTree([_chain_key], _e);
    };

    handleUpdateOverviewFilter = (_overviewFilter) => {
        console.log(
            "handleUpdateOverviewFilter _overviewFilter",
            _overviewFilter
        );

        let { overviewFilter } = this.state;
        overviewFilter = { ...overviewFilter, ..._overviewFilter };
        console.log(
            "handleUpdateOverviewFilter FINAL overviewFilter",
            overviewFilter
        );
        this.setState({ overviewFilter });
    };

    render() {
        // const loggedUser = this.props.store.getLoggedUser;
        //console.info("loggedUser", loggedUser);

        const chainsTree = this.renderChainsTree(this.props.store.getChains);

        const treeElement = (
            <Tree
                showIcon
                defaultExpandAll
                selectedKeys={[this.state.selectedChainKey]}
                switcherIcon={<Icon type="down" />}
                onSelect={this.clickOnTree}
                className={"social_InboxTree"}
            >
                {/* <TreeNode
                    title="All Conversations"
                    key="1-0"
                    selectable={false}
                >
                    
                </TreeNode> */}
                {chainsTree}
            </Tree>
        );

        const filterElement = (
            <Fragment>
                {/* selectedChainKey: {this.state.selectedChainKey} */}
                <Row>
                    {!this.state.hideLeftTree ? (
                        <Col className={"social_InboxFilterContainer"}>
                            <Search
                                placeholder="filter"
                                onSearch={(value) =>
                                    //this.setManualFilter(tIndex, value)
                                    this.setState({ treeFilter: value })
                                }
                                onChange={(e) =>
                                    //this.setManualFilter(tIndex, e.target.value)
                                    this.setState({
                                        treeFilter: e.target.value,
                                    })
                                }
                                allowClear={true}
                            />
                        </Col>
                    ) : null}
                    <Icon
                        type={
                            !this.state.hideLeftTree
                                ? "arrow-left"
                                : "arrow-right"
                        }
                        style={{
                            position: "absolute",
                            top: 0,
                            right: -10,
                            cursor: "pointer",
                            padding: 10,
                            height: this.state.hideLeftTree
                                ? "calc(100vh - 210px)"
                                : null,
                        }}
                        onClick={this.toggleLeftTree}
                    />
                </Row>
                {!this.state.hideLeftTree ? (
                    <div className="social_InboxFilterSettings">
                        <div
                            className="social_InboxFilterSettings_Title"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={(e) =>
                                this.setState({
                                    treeFilterVisible:
                                        !this.state.treeFilterVisible,
                                })
                            }
                        >
                            <Icon
                                type={
                                    this.state.treeFilterVisible === true
                                        ? "down"
                                        : "right"
                                }
                            />
                            Sort / Show
                        </div>
                        {this.state.treeFilterVisible === true &&
                        !this.state.hideLeftTree ? (
                            <Card className="social_InboxFilterPanel">
                                {/* <Divider orientation="left">Sort</Divider> */}
                                <Radio.Group
                                    onChange={(e) =>
                                        this.setState({
                                            treeFilterSortBy: e.target.value,
                                        })
                                    }
                                    value={this.state.treeFilterSortBy}
                                    buttonStyle="solid"
                                    size="small"
                                >
                                    <Radio.Button value={"brand"}>
                                        by Brand
                                    </Radio.Button>
                                    <Radio.Button value={"platform"}>
                                        by Platform
                                    </Radio.Button>
                                    <Radio.Button value={"campaign"} disabled>
                                        by Campaign
                                    </Radio.Button>
                                </Radio.Group>
                                <br />
                                <br />
                                {/* <Divider orientation="left">Show</Divider> */}
                                <Checkbox
                                    checked={
                                        this.state
                                            .treeFilterShowFacebookDirectMessages
                                    }
                                    onChange={(e) =>
                                        this.setState({
                                            treeFilterShowFacebookDirectMessages:
                                                e.target.checked,
                                        })
                                    }
                                >
                                    Facebook Messenger replies
                                </Checkbox>
                                <Checkbox
                                    checked={
                                        this.state
                                            .treeFilterShowFacebookPostComments
                                    }
                                    onChange={(e) =>
                                        this.setState({
                                            treeFilterShowFacebookPostComments:
                                                e.target.checked,
                                        })
                                    }
                                >
                                    Facebook Post comments
                                </Checkbox>
                                <Checkbox
                                    checked={
                                        this.state
                                            .treeFilterShowTwitterDirectMessages
                                    }
                                    onChange={(e) =>
                                        this.setState({
                                            treeFilterShowTwitterDirectMessages:
                                                e.target.checked,
                                        })
                                    }
                                >
                                    Twitter Direct messages
                                </Checkbox>
                                <Checkbox
                                    checked={
                                        this.state
                                            .treeFilterShowTwitterPostComments
                                    }
                                    onChange={(e) =>
                                        this.setState({
                                            treeFilterShowTwitterPostComments:
                                                e.target.checked,
                                        })
                                    }
                                >
                                    Twitter Tweet replies
                                </Checkbox>
                                <Checkbox
                                    checked={
                                        this.state
                                            .treeFilterShowInstagramPostComments
                                    }
                                    onChange={(e) =>
                                        this.setState({
                                            treeFilterShowInstagramPostComments:
                                                e.target.checked,
                                        })
                                    }
                                >
                                    Instagram Post comments
                                </Checkbox>
                            </Card>
                        ) : null}
                    </div>
                ) : null}
            </Fragment>
        );

        const content = (
            <Fragment>
                {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                <Row gutter={44}>
                    <Col
                        span={8}
                        style={{
                            textAlign: "left",
                            height: "calc(100vh - 210px)",
                            overflowY: "auto",
                            paddingTop: 0,
                            paddingLeft: 0,
                            paddingBottom: 0,
                            paddingRight: 15,
                            width: !this.state.hideLeftTree ? null : 30,
                        }}
                    >
                        {filterElement}
                        {!this.state.hideLeftTree ? treeElement : null}
                    </Col>
                    <Col
                        span={!this.state.hideLeftTree ? 16 : 23}
                        style={{
                            textAlign: "left",
                            height: "calc(100vh - 210px)",
                            borderLeft: "1px solid #ccc",
                            overflowY: "auto",
                            paddingLeft: 44,
                        }}
                    >
                        {this.state.selectedChainKey ? (
                            <div>
                                {this.state.selectedChainKey
                                    .toString()
                                    .indexOf("|") === -1 ? (
                                    <InboxBackArrow
                                        chain_key={
                                            this.state.lastOverviewChainKey
                                        }
                                        clickOnIcon={this.clickOnTree}
                                    />
                                ) : null}

                                <InboxChain
                                    store={this.props.store}
                                    selectedChainKey={
                                        this.state.selectedChainKey
                                    }
                                    handleClickOnPost={this.handleClickOnPost}
                                    handleMarkChainAsRead={this.markChainAsRead}
                                    handleUpdateOverviewFilter={
                                        this.handleUpdateOverviewFilter
                                    }
                                    overviewFilter={this.state.overviewFilter}
                                />
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Fragment>
        );

        return content;
    }
}

export default observer(Inbox);
