import React, { Component, Fragment } from "react";

import { Form, Select, Spin, Checkbox } from "antd";

const { Option } = Select;

class LinkedinTargeting extends Component {
    state = {};
    timer = null;
    audienceCountTimer = null;

    constructor(props) {
        super(props);

        this.state = {
            // countries: [],
            // regions: [],
            // cities: [],
            // zips: [],
            // languages: [],
            // interests: [],
            // custom_audiences: [],
            // //
            // selected_gender: "all",
            // selected_age: [30, 50],
            // selected_country: [],
            // selected_region: [],
            // selected_city: [],
            // selected_zip: [],
            // selected_language: [],
            // selected_interest: [],

            linkedin_newad_type: "TEXT_AD",
            linkedin_targeting: {},
        };
    }

    componentDidMount = async () => {
        console.info("componentDidMount this.props", this.props);
        if (this.props.targeting)
            this.setState({ linkedin_targeting: this.props.targeting });
    };

    fetchLinkedinFacet = (_facet, _skipDelay) => {
        console.log("fetchLinkedinFacet _facet", _facet);
        console.info("fetchLinkedinFacet this.props", this.props);

        const { linkedin_targeting } = this.state;
        if (
            !linkedin_targeting[_facet] ||
            (linkedin_targeting[_facet] &&
                linkedin_targeting[_facet].length === 0)
        ) {
            linkedin_targeting[_facet] = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(
                function () {
                    that.setState({ linkedin_targeting, fetching: true });
                    that.props.store
                        .getLinkedinFacet(that.props.account_key, _facet)
                        .then((data) => {
                            const { linkedin_targeting } = that.state;
                            linkedin_targeting[_facet] = data;
                            that.setState({
                                linkedin_targeting,
                                fetching: false,
                            });
                        })
                        .catch((error) => {
                            console.info("WE HAVE ERROR", error);
                        });
                },
                !_skipDelay ? 750 : 0
            );
        }
    };

    selectLinkedinTargetParam = (_param, _value) => {
        console.info(
            "selectLinkedinTargetParam _param",
            _param,
            "_value",
            _value
        );
        let { linkedin_targeting } = this.state;
        linkedin_targeting["selected_" + _param] = _value;
        this.setState({ linkedin_targeting, changed: true });

        // update the audienceCount
        this.getLinkedinAudienceCount(_param);
        this.buble_Targeting();
    };

    getLinkedinAudienceCount = (_criteria, _skipDelay) => {
        //console.log("getLinkedinAudienceCount _criteria", _criteria);

        const { linkedin_targeting } = this.state;
        // console.log(
        //     "getLinkedinAudienceCount linkedin_targeting",
        //     linkedin_targeting
        // );

        let resultCriteria = {};
        Object.keys(linkedin_targeting).forEach((element) => {
            if (element.indexOf("selected") !== -1) {
                console.log(element);
                resultCriteria[element] = linkedin_targeting[element];
            }
        });
        // console.info(
        //     "getLinkedinAudienceCount resultCriteria",
        //     resultCriteria
        // );

        //linkedin_targeting[_facet] = [];
        const that = this;
        if (this.audienceCountTimer) clearTimeout(this.audienceCountTimer);
        this.audienceCountTimer = setTimeout(
            function () {
                that.setState({ fetching: true });
                that.props.store
                    .getLinkedinAudienceCount(
                        that.props.account_key,
                        resultCriteria
                    )
                    .then((data) => {
                        console.info("data", data);
                        //alert("total:" + data.total);
                        const { linkedin_targeting } = that.state;
                        linkedin_targeting["audienceCount"] = data.total;
                        that.setState({
                            linkedin_targeting,
                            fetching: false,
                        });
                    })
                    .catch((error) => {
                        console.info("WE HAVE ERROR", error);
                    });
            },
            !_skipDelay ? 1500 : 0
        );
    };

    buble_Targeting = () => {
        console.info("buble_Targeting");
        clearTimeout(this.timer);
        this.timer = setTimeout(
            function (that) {
                if (
                    that.props.updateTargeting &&
                    typeof that.props.updateTargeting === "function"
                ) {
                    const linkedin_targeting = Object.assign(
                        {},
                        that.state.linkedin_targeting
                    );

                    Object.keys(linkedin_targeting).map((key) => {
                        if (key.indexOf("selected_") === -1)
                            delete linkedin_targeting[key];
                        return true;
                    });

                    that.props.updateTargeting(
                        linkedin_targeting
                        // {
                        // gender: curState.selected_gender,
                        // age: curState.selected_age,
                        // country: curState.selected_country,
                        // region: curState.selected_region,
                        // city: curState.selected_city,
                        // zip: curState.selected_zip,
                        // language: curState.selected_language,
                        // interest: curState.selected_interest,
                        // }
                    );
                }
            },
            1000,
            this
        );
    };

    render() {
        const { fetching } = this.state;

        const body = (
            <Fragment>
                <div style={{ padding: 20 }}>
                    <Form layout="horizontal">
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_companyCategory
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_companyCategory",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Company Category
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select company category"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("companyCategory")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet(
                                        "companyCategory",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "companyCategory",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_companyCategory
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_companyCategory
                                }
                            >
                                {this.state.linkedin_targeting
                                    .companyCategory &&
                                    this.state.linkedin_targeting.companyCategory.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_memberBehaviors
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_memberBehaviors",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Member Behaviors
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select member behaviors"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("memberBehaviors")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet(
                                        "memberBehaviors",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "memberBehaviors",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_memberBehaviors
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_memberBehaviors
                                }
                            >
                                {this.state.linkedin_targeting
                                    .memberBehaviors &&
                                    this.state.linkedin_targeting.memberBehaviors.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_growthRate
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_growthRate",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Growth Rate
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select growth rate"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("growthRate")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet("growthRate", true)
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "growthRate",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_growthRate
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_growthRate
                                }
                            >
                                {this.state.linkedin_targeting.growthRate &&
                                    this.state.linkedin_targeting.growthRate.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_interfaceLocales
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_interfaceLocales",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Interface Locales
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select interface locales"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("interfaceLocales")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet(
                                        "interfaceLocales",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "interfaceLocales",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_interfaceLocales
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_interfaceLocales
                                }
                            >
                                {this.state.linkedin_targeting
                                    .interfaceLocales &&
                                    this.state.linkedin_targeting.interfaceLocales.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_seniorities
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_seniorities",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Seniorities
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select seniorities"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("seniorities")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet("seniorities", true)
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "seniorities",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_seniorities
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_seniorities
                                }
                            >
                                {this.state.linkedin_targeting.seniorities &&
                                    this.state.linkedin_targeting.seniorities.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_locations
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_locations",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Locations
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select locations"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("locations")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet("locations", true)
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "locations",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_locations
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_locations
                                }
                            >
                                {this.state.linkedin_targeting.locations &&
                                    this.state.linkedin_targeting.locations.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_staffCountRanges
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_staffCountRanges",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Staff Count Ranges
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select staff count ranges"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("staffCountRanges")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet(
                                        "staffCountRanges",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "staffCountRanges",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_staffCountRanges
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_staffCountRanges
                                }
                            >
                                {this.state.linkedin_targeting
                                    .staffCountRanges &&
                                    this.state.linkedin_targeting.staffCountRanges.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_fieldsOfStudy
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_fieldsOfStudy",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Fields of study
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select fields of study"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("fieldsOfStudy")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet(
                                        "fieldsOfStudy",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "fieldsOfStudy",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_fieldsOfStudy
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_fieldsOfStudy
                                }
                            >
                                {this.state.linkedin_targeting.fieldsOfStudy &&
                                    this.state.linkedin_targeting.fieldsOfStudy.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_degrees
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_degrees",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Degrees
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select degrees"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("degrees")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet("degrees", true)
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "degrees",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_degrees
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_degrees
                                }
                            >
                                {this.state.linkedin_targeting.degrees &&
                                    this.state.linkedin_targeting.degrees.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_yearsOfExperienceRanges
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_yearsOfExperienceRanges",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Years of experience ranges
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select years of experience ranges"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet(
                                        "yearsOfExperienceRanges"
                                    )
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet(
                                        "yearsOfExperienceRanges",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "yearsOfExperienceRanges",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_yearsOfExperienceRanges
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_yearsOfExperienceRanges
                                }
                            >
                                {this.state.linkedin_targeting
                                    .yearsOfExperienceRanges &&
                                    this.state.linkedin_targeting.yearsOfExperienceRanges.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_jobFunctions
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_jobFunctions",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Job functions
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select job functions"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("jobFunctions")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet(
                                        "jobFunctions",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "jobFunctions",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_jobFunctions
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_jobFunctions
                                }
                            >
                                {this.state.linkedin_targeting.jobFunctions &&
                                    this.state.linkedin_targeting.jobFunctions.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_ageRanges
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_ageRanges",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Age ranges
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select age ranges"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("ageRanges")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet("ageRanges", true)
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "ageRanges",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_ageRanges
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_ageRanges
                                }
                            >
                                {this.state.linkedin_targeting.ageRanges &&
                                    this.state.linkedin_targeting.ageRanges.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_profileLocations
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_profileLocations",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Profile locations
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select profile locations"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("profileLocations")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet(
                                        "profileLocations",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "profileLocations",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_profileLocations
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_profileLocations
                                }
                            >
                                {this.state.linkedin_targeting
                                    .profileLocations &&
                                    this.state.linkedin_targeting.profileLocations.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_genders
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_genders",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Genders
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select genders"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("genders")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet("genders", true)
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "genders",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_genders
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_genders
                                }
                            >
                                {this.state.linkedin_targeting.genders &&
                                    this.state.linkedin_targeting.genders.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.linkedin_targeting
                                            .selected_overwrite_industries
                                    }
                                    onChange={(e) =>
                                        this.selectLinkedinTargetParam(
                                            "overwrite_industries",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Industries
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select industries"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchLinkedinFacet("industries")
                                }
                                onFocus={(e) =>
                                    this.fetchLinkedinFacet("industries", true)
                                }
                                onChange={(e) => {
                                    this.selectLinkedinTargetParam(
                                        "industries",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.linkedin_targeting
                                        .selected_overwrite_industries
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.linkedin_targeting
                                        .selected_industries
                                }
                            >
                                {this.state.linkedin_targeting.industries &&
                                    this.state.linkedin_targeting.industries.map(
                                        (e) => (
                                            <Option key={e.key}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
            </Fragment>
        );

        //   let body = (
        //       <Fragment>
        //           Linkedin
        //           <Form layout="horizontal">
        //               <Form.Item label="Gender">
        //                   <Radio.Group
        //                       value={this.state.selected_gender}
        //                       onChange={(e) =>
        //                           this.selectFacebookTargetParam(
        //                               "gender",
        //                               e.target.value
        //                           )
        //                       }
        //                   >
        //                       <Radio.Button value="all">All</Radio.Button>
        //                       <Radio.Button value="1">Male</Radio.Button>
        //                       <Radio.Button value="2">Female</Radio.Button>
        //                   </Radio.Group>
        //               </Form.Item>
        //               <Form.Item
        //                   label={
        //                       "Age: " +
        //                       this.state.selected_age[0] +
        //                       " - " +
        //                       this.state.selected_age[1]
        //                   }
        //               >
        //                   <Slider
        //                       range
        //                       value={[
        //                           this.state.selected_age[0],
        //                           this.state.selected_age[1],
        //                       ]}
        //                       min={13}
        //                       max={100}
        //                       tooltipVisible={false}
        //                       tooltipPlacement="bottom"
        //                       onChange={(e) =>
        //                           this.selectFacebookTargetParam("age", e)
        //                       }
        //                   />
        //               </Form.Item>
        //               <Form.Item label="Country">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select countries"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchCountry}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("country", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_country}
        //                   >
        //                       {this.state.countries.map((c) => (
        //                           <Option key={c.key}>{c.name}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="Region">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select regions"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchRegion}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("region", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_region}
        //                   >
        //                       {this.state.regions.map((r) => (
        //                           <Option key={r.key}>{r.name}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="City">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select cities"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchCity}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("city", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_city}
        //                   >
        //                       {this.state.cities.map((c) => (
        //                           <Option key={c.key}>{c.name}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="ZIP">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select zip codes"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchZip}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("zip", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_zip}
        //                   >
        //                       {this.state.zips.map((z) => (
        //                           <Option key={z.key}>
        //                               {z.country_name} / {z.region} /{" "}
        //                               {z.primary_city} - {z.name}
        //                           </Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="Language">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select languages"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchLanguage}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("language", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_language}
        //                   >
        //                       {this.state.languages.map((l) => (
        //                           <Option key={l.key}>{l.name}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="Interest">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select interests"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchInterest}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("interest", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_interest}
        //                   >
        //                       {this.state.interests.map((i) => (
        //                           <Option key={i.id}>{i.path.join("/")}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //           </Form>
        //           {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
        //       </Fragment>
        //   );

        return body;
    }
}

export default LinkedinTargeting;
