import React, { Component } from "react";
// import { Button } from "antd";
import SocialLogin from "react-social-login";
// import PropTypes from "prop-types";

// const socButton = ({ children, triggerLogin, ...props }) => (
//     <Button
//         onClick={triggerLogin}
//         {...props}
//         style={
//             props.style
//                 ? props.style
//                 : { background: "#4267b2", borderColor: "#4267b2" }
//         }
//         type="primary"
//         icon={props.provider}
//     >
//         {children}
//     </Button>
// );

class socButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // const { triggerLogin } = this.props;

        return (
            <button
                onClick={this.props.triggerLogin}
                {...this.props}
                // style={
                //     this.props.style
                //         ? this.props.style
                //         : { background: "#4267b2", borderColor: "#4267b2" }
                // }
                // type="primary"
                // icon={this.props.provider}
            >
                {this.props.children}
            </button>
        );
    }
}

export default SocialLogin(socButton);
