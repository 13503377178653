import React, { Component, Fragment } from "react";

const youtubePost_container = {
    // border: "1px solid red",
    padding: 10,
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #eee",
    paddingBottom: 20,
    marginBottom: 10,
};
const youtubePost_thumbnail = {
    width: 120,
    height: 90,
    marginRight: 10,
    flexShrink: "0",
};
const youtubePost_title = {
    fontWeight: 600,
    display: "block",
};
const youtubePost_description = {
    // color: "red",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "3" /* number of lines to show */,
    "-webkit-box-orient": "vertical",
};

const SocialYoutubePost = (props, children) => {
    // if (typeof props === "undefined") return null;
    const { post } = props;
    const { snippet } = post;
    const thumbnailUrl = snippet.thumbnails.default.url;
    return (
        <div style={youtubePost_container}>
            {/* <pre>{JSON.stringify(post, null, 2)}</pre> */}
            <div
                style={{
                    ...youtubePost_thumbnail,
                    ...{
                        backgroundImage: `url(${thumbnailUrl})`,
                    },
                }}
            ></div>
            <div>
                <span style={youtubePost_title}>{snippet.title}</span>
                <span style={youtubePost_description}>
                    {snippet.description}
                </span>
            </div>
        </div>
    );
};

export default SocialYoutubePost;
