import React, { Component, Fragment } from "react";
import { Typography, Statistic, Icon, Tooltip, Skeleton, Spin } from "antd";

import {
    LineChart,
    PieChart,
    AreaChart,
    BarChart,
    ColumnChart,
} from "react-chartkick";
import "chart.js";

import scss_variables from "./../../_variables.scss";

const { Title } = Typography;

let styleSum = {
    fontSize: "2rem",
    fontWeight: 500,
    paddingLeft: 25,
};
let styleTitle = {
    paddingLeft: 25,
    color: scss_variables.scss_color_dataPresentorTitle,
    margin: 0,
};

const LineAreaColors = [
    "#517ada",
    "#d66253",
    "#fdad00",
    "#39a909",
    "#e1306c",
    "#00b8f1",
];

const ReportElementDescription = {
    color: "#999",
    fontSize: 11,
    lineHeight: "0.9rem",
    height: "3.6rem",
    paddingLeft: 25,
};

class ReportGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myValue: false,
        };
    }

    parseValue = (props, _value) => {
        let last_x = null;
        let last_y = null;
        let sum_y = 0;

        let content = !_value ? (
            <Fragment>
                <Spin
                    tip="Loading..."
                    indicator={
                        <Icon type="loading" style={{ fontSize: 24 }} spin />
                    }
                >
                    <Skeleton
                        active
                        paragraph={{ rows: 3 }}
                        title={{ width: "50%" }}
                    />
                </Spin>
            </Fragment>
        ) : null;

        // Number
        if (typeof _value === "number") {
            content = (
                <Fragment>
                    {props.style && props.style === "circle" ? (
                        <div></div>
                    ) : null}

                    <Statistic
                        value={_value}
                        style={{ textAlign: "center", fontSize: "2rem" }}
                    />
                </Fragment>
            );
        }

        if (Array.isArray(_value)) {
            // calculate data
            let data = [];
            let data_object = {};

            if (
                this.props.type !== "BarChart" &&
                this.props.type !== "PieChart" &&
                this.props.type !== "ColumnChart" &&
                this.props.type !== "LineChart"
            ) {
                _value.map((v) => {
                    let x = new Date(v[props.key_name]);
                    let y = v[props.value_name];
                    data.push({
                        x,
                        y,
                    });
                    last_x = x;
                    last_y = y;
                    sum_y += y;
                    data_object[x] = y;
                    return true;
                });
            } else {
                // BarChart || PieChart || ColumnChart || LineChart
                _value.map((v) => {
                    let x = v[props.key_name];
                    let y = v[props.value_name];
                    sum_y += y;
                    data.push([x, y]);
                    return true;
                });
            }

            if (this.props.dontParseData) {
                data_object = _value;
            }

            //console.info("data_object - ", data_object);

            content = (
                <Fragment>
                    {!this.props.type || this.props.type === "LineChart" ? (
                        <LineChart
                            // width="800px"
                            height={!props.height ? "200px" : props.height}
                            data={data_object}
                            colors={
                                props.color ? [props.color] : LineAreaColors
                            }
                            // curve={false}
                            download={true}
                            messages={{ empty: "No data" }}
                            dataset={{ borderWidth: 3, pointRadius: 1 }}
                        />
                    ) : null}
                    {this.props.type === "AreaChart" ? (
                        <AreaChart
                            // width="800px"
                            height={!props.height ? "200px" : props.height}
                            data={data_object}
                            colors={[
                                props.color
                                    ? props.color
                                    : scss_variables.scss_color_dataPresentorFacebookStroke,
                            ]}
                            // curve={false}
                            download={true}
                            messages={{ empty: "No data" }}
                            dataset={{ borderWidth: 3, pointRadius: 1 }}
                        />
                    ) : null}
                    {this.props.type === "BarChart" ? (
                        <Fragment>
                            {/* <pre>data={JSON.stringify(data, null, 2)}</pre> */}
                            <BarChart
                                // width="800px"
                                height={data.length < 5 ? "200px" : "420px"}
                                data={data}
                                colors={[
                                    props.color
                                        ? props.color
                                        : scss_variables.scss_color_dataPresentorFacebookStroke,
                                ]}
                                // curve={false}
                                download={true}
                                messages={{ empty: "No data" }}
                                dataset={{ borderWidth: 3, pointRadius: 1 }}
                            />
                        </Fragment>
                    ) : null}
                    {this.props.type === "ColumnChart" ? (
                        <Fragment>
                            {/* <pre>data={JSON.stringify(data, null, 2)}</pre> */}
                            <ColumnChart
                                // width="800px"
                                height={!props.height ? "420px" : props.height}
                                data={data}
                                colors={[
                                    props.color
                                        ? props.color
                                        : scss_variables.scss_color_dataPresentorFacebookStroke,
                                ]}
                                // curve={false}
                                download={true}
                                messages={{ empty: "No data" }}
                                // dataset={{ borderWidth: 3, pointRadius: 1 }}
                            />
                        </Fragment>
                    ) : null}
                    {this.props.type === "PieChart" ? (
                        <Fragment>
                            {/* <pre>data={JSON.stringify(data, null, 2)}</pre> */}
                            <PieChart
                                width="300px"
                                height={!props.height ? "300px" : props.height}
                                data={data}
                                colors={[
                                    props.color
                                        ? props.color
                                        : scss_variables.scss_color_dataPresentorFacebookStroke,
                                ]}
                                donut={true}
                                library={{ legend: { display: false } }}
                                download={true}
                                messages={{ empty: "No data" }}
                                dataset={{ borderWidth: 3, pointRadius: 1 }}
                            />
                        </Fragment>
                    ) : null}
                </Fragment>
            );
        }

        return { content, last_x, last_y, sum_y };
    };

    render() {
        const { content, last_x, last_y, sum_y } = this.parseValue(
            this.props,
            this.props.value
        );

        //   console.info("typeof this.props.values = " + typeof this.props.values);
        //   console.info(
        //       "render() last_x = " +
        //           last_x +
        //           ", last_y = " +
        //           last_y +
        //           ", sum_y = " +
        //           sum_y
        //   );

        return (
            <Fragment>
                <div
                    style={{
                        cursor: "default",
                        // height: 280
                        ...this.props.style,
                    }}
                >
                    <Title level={4} style={styleTitle}>
                        {this.props.title}{" "}
                        {!this.props.hideTotal ? <span>- {sum_y}</span> : null}
                    </Title>
                    {this.props.description ? (
                        <div style={ReportElementDescription}>
                            {this.props.description}
                        </div>
                    ) : null}

                    <div style={{ ...this.props.chartStyle }}>{content}</div>
                </div>
                {this.props.metric_name ? (
                    <b
                        style={{
                            color: "#999",
                            marginLeft: 30,
                            marginBottom: 30,
                        }}
                    >
                        metric={this.props.metric_name}
                    </b>
                ) : null}
                {/* <pre>{JSON.stringify(this.props.value, null, 2)}</pre> */}
            </Fragment>
        );
    }
}

export default ReportGraph;
