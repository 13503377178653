import React, { Fragment } from "react";
import moment from "moment";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Table,
    Button,
    Typography,
} from "antd";

import scss_variables from "./../../_variables.scss";
import SocialDataPresentor from "../SocialDataPresentor";
import ReportSingleValue from "./ReportSingleValue";
import ReportGraph from "./ReportGraph";

const { Panel } = Collapse;
const { Text, Title } = Typography;

const GRID_GUTTER = 40;

const ReportResultChannelMonthlyInstagram = (props) => {
    const activityOverviewPanel_ImpressionsDiff =
        props.combined_results.impressions &&
        props.combined_prev_results.impressions
            ? (
                  (props.combined_results.impressions.sum /
                      props.combined_prev_results.impressions.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;

    const activityOverviewPanel_ImpressionsOld =
        props.combined_prev_results &&
        props.combined_prev_results.impressions.sum;
    // -----------------------------------------------------
    const activityOverviewPanel_ReachDiff =
        props.combined_results.reach && props.combined_prev_results.reach
            ? (
                  (props.combined_results.reach.sum /
                      props.combined_prev_results.reach.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    const activityOverviewPanel_ReachOld =
        props.combined_prev_results && props.combined_prev_results.reach.sum;
    // -----------------------------------------------------
    const activityOverviewPanel_FollowerCountDiff =
        props.combined_results.follower_count &&
        props.combined_prev_results.follower_count
            ? (
                  (props.combined_results.follower_count.sum /
                      props.combined_prev_results.follower_count.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    const activityOverviewPanel_FollowerCountOld =
        props.combined_prev_results &&
        props.combined_prev_results.follower_count &&
        props.combined_prev_results.follower_count.sum;
    // -----------------------------------------------------
    const activityOverviewPanel = (
        <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
            <Col span={8}>
                <ReportSingleValue
                    title="Impressions"
                    value={
                        props.combined_results.impressions &&
                        props.combined_results.impressions.sum
                    }
                    // prefix={<Icon type="eye" />}
                    description={
                        props.combined_results.impressions &&
                        props.combined_results.impressions.description
                    }
                    diff={activityOverviewPanel_ImpressionsDiff}
                    oldValue={activityOverviewPanel_ImpressionsOld}
                />
            </Col>
            <Col span={8}>
                <ReportSingleValue
                    title="Reach"
                    value={
                        props.combined_results.reach &&
                        props.combined_results.reach.sum
                    }
                    // prefix={<Icon type="eye" />}
                    description={
                        props.combined_results.reach &&
                        props.combined_results.reach.description
                    }
                    diff={activityOverviewPanel_ReachDiff}
                    oldValue={activityOverviewPanel_ReachOld}
                />
            </Col>
            <Col span={8}>
                <ReportSingleValue
                    title="New Followers"
                    value={
                        props.combined_results.follower_count &&
                        props.combined_results.follower_count.sum
                    }
                    // prefix={<Icon type="eye" />}
                    description={
                        props.combined_results.follower_count &&
                        props.combined_results.follower_count.description
                    }
                    diff={activityOverviewPanel_FollowerCountDiff}
                    oldValue={activityOverviewPanel_FollowerCountOld}
                />
            </Col>
        </Row>
    );

    // -----------------------------------------

    const audienceGrowthPanel = (
        <Row gutter={GRID_GUTTER}>
            <Col span={18}>
                {props.combined_results.follower_count ? (
                    <ReportGraph
                        title={
                            // (props.combined_results.follower_count &&
                            //     props.combined_results.follower_count.title) ||
                            // "page_fan_adds"
                            "New Followers"
                        }
                        description={
                            props.combined_results.follower_count &&
                            props.combined_results.follower_count.description
                        }
                        value={
                            props.combined_results.follower_count &&
                            props.combined_results.follower_count.values.map(
                                (val) => ({
                                    value: val.value,
                                    end_time: moment(val.end_time).format(
                                        "MM/DD"
                                    ),
                                })
                            )
                        }
                        color={scss_variables.scss_color_dataPresentorInstagram}
                        value_name="value"
                        key_name="end_time"
                        hideTotal
                        //   metric_name="page_fan_adds"
                        type="ColumnChart"
                    />
                ) : // <SocialDataPresentor
                //     title={
                //         (props.combined_results.follower_count &&
                //             props.combined_results.follower_count.title) ||
                //         "page_fan_adds"
                //     }
                //     description={
                //         props.combined_results.follower_count &&
                //         props.combined_results.follower_count.description
                //     }
                //     value={
                //         props.combined_results.follower_count &&
                //         props.combined_results.follower_count.values
                //     }
                //     value_name="value"
                //     key_name="end_time"
                //     //   metric_name="page_fan_adds"
                //     link="http://www.cnn.com"
                //     type="AreaChart"
                // />
                null}
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Total New Followers"
                    value={
                        props.combined_results.follower_count &&
                        props.combined_results.follower_count.sum
                    }
                    // prefix={<Icon type="eye" />}
                    // description={
                    //     props.combined_results.follower_count &&
                    //     props.combined_results.follower_count.description
                    // }
                    shallowDescription
                    diff={activityOverviewPanel_FollowerCountDiff}
                    oldValue={activityOverviewPanel_FollowerCountOld}
                />
            </Col>
        </Row>
    );

    // -----------------------------------

    const postsPanel_pagePostsSimplifiedTotalCountDiff =
        props.combined_results.page_posts_simplified_summary_totalCount &&
        props.combined_prev_results.page_posts_simplified_summary_totalCount
            ? (
                  (props.combined_results
                      .page_posts_simplified_summary_totalCount /
                      props.combined_prev_results
                          .page_posts_simplified_summary_totalCount) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    const postsPanel_pagePostsSimplifiedTotalCountOld =
        props.combined_prev_results &&
        props.combined_prev_results.page_posts_simplified_summary_totalCount;
    // -----------------------------------------------------

    const postsPanel = (
        <Row gutter={GRID_GUTTER}>
            <Col span={18}>
                {props.combined_results.posts_simplified ? (
                    <ReportGraph
                        title={
                            // (props.combined_results.follower_count &&
                            //     props.combined_results.follower_count.title) ||
                            // "page_fan_adds"
                            "New Posts"
                        }
                        description={
                            "Published Instagram Posts during the selected period"
                        }
                        value={
                            props.combined_results &&
                            props.combined_results.posts_simplified.map(
                                (val) => ({
                                    value: val.value,
                                    end_time: moment(val.date).format("MM/DD"),
                                })
                            )
                        }
                        color={scss_variables.scss_color_dataPresentorInstagram}
                        value_name="value"
                        key_name="end_time"
                        hideTotal
                        //   metric_name="page_fan_adds"
                        type="ColumnChart"
                    />
                ) : null}
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Total New Posts"
                    value={
                        props.combined_results.posts_simplified &&
                        props.combined_results.posts_simplified.length
                    }
                    // prefix={<Icon type="eye" />}
                    // description={
                    //     props.combined_results.follower_count &&
                    //     props.combined_results.follower_count.description
                    // }
                    shallowDescription
                    diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                    oldValue={postsPanel_pagePostsSimplifiedTotalCountOld}
                />
            </Col>
        </Row>
    );

    // --------------------------------

    const impressionsPanel_ImpressionsDiff =
        props.combined_results.impressions &&
        props.combined_prev_results.impressions
            ? (
                  (props.combined_results.impressions.sum /
                      props.combined_prev_results.impressions.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    const impressionsPanel_ImpressionsOld =
        props.combined_prev_results &&
        props.combined_prev_results.impressions.sum;
    // -----------------------------------------------------

    const impressionsPanel = (
        <Row gutter={GRID_GUTTER}>
            <Col span={18}>
                {props.combined_results.impressions ? (
                    <ReportGraph
                        title={
                            (props.combined_results.impressions &&
                                props.combined_results.impressions.title) ||
                            "impressions"
                        }
                        description={
                            props.combined_results.impressions &&
                            props.combined_results.impressions.description
                        }
                        value={
                            props.combined_results.impressions &&
                            props.combined_results.impressions.values.map(
                                (val) => ({
                                    value: val.value,
                                    end_time: moment(val.end_time).format(
                                        "MM/DD"
                                    ),
                                })
                            )
                        }
                        color={scss_variables.scss_color_dataPresentorInstagram}
                        value_name="value"
                        key_name="end_time"
                        hideTotal
                        //   metric_name="page_fan_adds"
                        type="ColumnChart"
                    />
                ) : null}
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Total Impressions"
                    value={
                        props.combined_results.impressions &&
                        props.combined_results.impressions.sum
                    }
                    // prefix={<Icon type="eye" />}
                    // description={
                    //     props.combined_results.follower_count &&
                    //     props.combined_results.follower_count.description
                    // }
                    shallowDescription
                    diff={impressionsPanel_ImpressionsDiff}
                    oldValue={impressionsPanel_ImpressionsOld}
                />
            </Col>
        </Row>
    );

    // -------------------------------

    const reachPanel_ReachDiff =
        props.combined_results.reach && props.combined_prev_results.reach
            ? (
                  (props.combined_results.reach.sum /
                      props.combined_prev_results.reach.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    console.log("reachPanel_ReachDiff = " + reachPanel_ReachDiff);
    const reachPanel_ReachOld =
        props.combined_prev_results && props.combined_prev_results.reach.sum;
    // -----------------------------------------------------

    const reachPanel = (
        <Row gutter={GRID_GUTTER}>
            <Col span={18}>
                {props.combined_results.reach ? (
                    <ReportGraph
                        title={
                            (props.combined_results.reach &&
                                props.combined_results.reach.title) ||
                            "reach"
                        }
                        description={
                            props.combined_results.reach &&
                            props.combined_results.reach.description
                        }
                        value={
                            props.combined_results.reach &&
                            props.combined_results.reach.values.map((val) => ({
                                value: val.value,
                                end_time: moment(val.end_time).format("MM/DD"),
                            }))
                        }
                        color={scss_variables.scss_color_dataPresentorInstagram}
                        value_name="value"
                        key_name="end_time"
                        hideTotal
                        //   metric_name="page_fan_adds"
                        type="ColumnChart"
                    />
                ) : // <SocialDataPresentor
                //     title={
                //         (props.combined_results.reach &&
                //             props.combined_results.reach.title) ||
                //         "reach"
                //     }
                //     description={
                //         props.combined_results.reach &&
                //         props.combined_results.reach.description
                //     }
                //     value={
                //         props.combined_results.reach &&
                //         props.combined_results.reach.values
                //     }
                //     value_name="value"
                //     key_name="end_time"
                //     // metric_name="page_post_engagements"
                //     link="http://www.cnn.com"
                //     type="AreaChart"
                // />
                null}
                {/* <Statistic
                    title={"Reach Change"}
                    value={reachPanel_ReachDiff}
                    precision={2}
                    valueStyle={{
                        color: reachPanel_ReachDiff > 0 ? "#3f8600" : "#cf1322",
                        // fontSize: "1.5rem"
                    }}
                    prefix={
                        <Icon
                            type={
                                reachPanel_ReachDiff > 0
                                    ? "arrow-up"
                                    : "arrow-down"
                            }
                        />
                    }
                    suffix="%"
                /> */}
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Total Impressions"
                    value={
                        props.combined_results.reach &&
                        props.combined_results.reach.sum
                    }
                    // prefix={<Icon type="eye" />}
                    // description={
                    //     props.combined_results.follower_count &&
                    //     props.combined_results.follower_count.description
                    // }
                    shallowDescription
                    diff={reachPanel_ReachDiff}
                    oldValue={reachPanel_ReachOld}
                />
            </Col>
        </Row>
    );

    // ---------------------------

    let audience_locale = [],
        audience_gender_age = [],
        audience_country = [],
        audience_city = [];

    const dataSource = props.results_separated
        ? props.results_separated.map((r, rIndex) => {
              const impressionsData =
                  r.igRes && r.igRes.data
                      ? r.igRes.data.filter((d) => d.name === "impressions")[0]
                      : null;

              console.info("impressionsData", impressionsData);

              const impressions = impressionsData
                  ? impressionsData.values.reduce(
                        (acc, elm) => acc + elm.value,
                        0
                    )
                  : 0;

              const new_followersData =
                  r.igRes && r.igRes.data
                      ? r.igRes.data.filter(
                            (d) => d.name === "follower_count"
                        )[0]
                      : null;
              const new_followes = new_followersData
                  ? new_followersData.values.reduce(
                        (acc, elm) => acc + elm.value,
                        0
                    )
                  : 0;

              const reachData =
                  r.igRes && r.igRes.data
                      ? r.igRes.data.filter((d) => d.name === "reach")[0]
                      : null;

              //console.info("reachData", reachData);

              const reach = reachData
                  ? reachData.values.reduce((acc, elm) => acc + elm.value, 0)
                  : 0;

              const audience_localeData =
                  r.audience && r.audience.data && r.audience.data.length > 0
                      ? r.audience.data.filter(
                            (d) => d.name === "audience_locale"
                        )[0].values[0].value
                      : null;

              const audience_gender_ageData =
                  r.audience && r.audience.data && r.audience.data.length > 0
                      ? r.audience.data.filter(
                            (d) => d.name === "audience_gender_age"
                        )[0].values[0].value
                      : null;
              const audience_countryData =
                  r.audience && r.audience.data && r.audience.data.length > 0
                      ? r.audience.data.filter(
                            (d) => d.name === "audience_country"
                        )[0].values[0].value
                      : null;
              const audience_cityData =
                  r.audience && r.audience.data && r.audience.data.length > 0
                      ? r.audience.data.filter(
                            (d) => d.name === "audience_city"
                        )[0].values[0].value
                      : null;

              audience_locale =
                  audience_localeData &&
                  Object.keys(audience_localeData)
                      .map(function (key) {
                          return { key, value: audience_localeData[key] };
                      })
                      .sort(function (a, b) {
                          return b.value - a.value;
                      });

              audience_gender_age =
                  audience_gender_ageData &&
                  Object.keys(audience_gender_ageData)
                      .map(function (key) {
                          return { key, value: audience_gender_ageData[key] };
                      })
                      .sort(function (a, b) {
                          return b.value - a.value;
                      });

              audience_country =
                  audience_countryData &&
                  Object.keys(audience_countryData)
                      .map(function (key) {
                          return { key, value: audience_countryData[key] };
                      })
                      .sort(function (a, b) {
                          return b.value - a.value;
                      });

              audience_city =
                  audience_cityData &&
                  Object.keys(audience_cityData)
                      .map(function (key) {
                          return { key, value: audience_cityData[key] };
                      })
                      .sort(function (a, b) {
                          return b.value - a.value;
                      });

              console.info("audience_localeData", audience_localeData);
              console.info("audience_gender_ageData", audience_gender_ageData);
              console.info("audience_countryData", audience_countryData);
              console.info("audience_cityData", audience_cityData);

              //   const impressions = 0,
              //       new_followes = 0,
              //       reach = 0;
              //console.info("r", r);
              return {
                  key: rIndex,
                  name: (
                      <Fragment>
                          {r.snapshot.name}
                          <br />
                          <Icon
                              type={r.type}
                              theme="filled"
                              style={{
                                  color: props.store.getTagColorForType(r.type),
                                  marginRight: 5,
                              }}
                          />
                          <strong>{r.name}</strong>
                      </Fragment>
                  ),
                  //   page_fan_adds: r.fbRes.data.filter(d => {
                  //       if (d.name === "page_fan_adds") return d;
                  //   })[0].sum,
                  page_impressions: impressions,

                  page_posts: r.postsSimplified.summary.total_count,
                  impressions_per_post:
                      r.postsSimplified.summary.total_count > 0
                          ? (
                                impressions /
                                r.postsSimplified.summary.total_count
                            ).toFixed(2)
                          : "N/A",
                  follower_count: new_followes,
                  reach: reach,
              };
          })
        : [];

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "New followers",
            dataIndex: "follower_count",
            key: "follower_count",
        },
        {
            title: "Impressions",
            dataIndex: "page_impressions",
            key: "page_impressions",
        },
        {
            title: "Reach",
            dataIndex: "reach",
            key: "Reach",
        },
        {
            title: "Posts",
            dataIndex: "page_posts",
            key: "page_posts",
        },
        {
            title: "Impr./Post",
            dataIndex: "impressions_per_post",
            key: "impressions_per_post",
        },
    ];

    const pagestatsPanel = (
        <Table dataSource={dataSource} columns={columns} pagination={false} />
    );

    // -------------------------

    //     const postsPanel_pagePostsSimplifiedTotalCountDiff =
    //     props.combined_results.page_posts_simplified_summary_totalCount &&
    //     props.combined_prev_results.page_posts_simplified_summary_totalCount
    //         ? (
    //               (props.combined_results
    //                   .page_posts_simplified_summary_totalCount /
    //                   props.combined_prev_results
    //                       .page_posts_simplified_summary_totalCount) *
    //                   100 -
    //               100
    //           ).toFixed(2)
    //         : 0;
    // const postsPanel_pagePostsSimplifiedTotalCountOld =
    //     props.combined_prev_results &&
    //     props.combined_prev_results.page_posts_simplified_summary_totalCount;
    // -----------------------------------------------------

    console.info(
        "audience_locale",
        audience_locale,
        "audience_gender_age",
        audience_gender_age,
        "audience_country",
        audience_country,
        "audience_city",
        audience_city
    );
    const audiencePanel = (
        <Row gutter={GRID_GUTTER}>
            <Col span={4}>
                <Title level={3}>Locale</Title>
                {audience_locale &&
                    audience_locale.slice(0, 15).map((locale, localeIndex) => (
                        <Fragment key={localeIndex}>
                            <Row>
                                <Col span={14}>{locale.key}</Col>
                                <Col span={4} style={{ textAlign: "center" }}>
                                    <Icon type="arrow-right" />
                                </Col>
                                <Col span={6}>
                                    <strong>{locale.value}</strong>
                                </Col>
                            </Row>
                        </Fragment>
                    ))}
            </Col>
            <Col span={6}>
                <Title level={3}>Gender/Age</Title>
                {audience_gender_age &&
                    audience_gender_age
                        .slice(0, 15)
                        .map((gender, genderIndex) => (
                            <Fragment key={genderIndex}>
                                <Row>
                                    <Col span={14}>{gender.key}</Col>
                                    <Col
                                        span={4}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Icon type="arrow-right" />
                                    </Col>
                                    <Col span={6}>
                                        <strong>{gender.value}</strong>
                                    </Col>
                                </Row>
                            </Fragment>
                        ))}
            </Col>
            <Col span={6}>
                <Title level={3}>Country</Title>
                {audience_country &&
                    audience_country
                        .slice(0, 15)
                        .map((country, countryIndex) => (
                            <Fragment key={countryIndex}>
                                <Row>
                                    <Col span={14}>{country.key}</Col>
                                    <Col
                                        span={4}
                                        style={{ textAlign: "center" }}
                                    >
                                        <Icon type="arrow-right" />
                                    </Col>
                                    <Col span={6}>
                                        <strong>{country.value}</strong>
                                    </Col>
                                </Row>
                            </Fragment>
                        ))}
            </Col>
            <Col span={8}>
                <Title level={3}>Cities</Title>
                {audience_city &&
                    audience_city.slice(0, 15).map((city, cityIndex) => (
                        <Fragment key={cityIndex}>
                            <Row>
                                <Col span={14}>{city.key}</Col>
                                <Col span={4} style={{ textAlign: "center" }}>
                                    <Icon type="arrow-right" />
                                </Col>
                                <Col span={6}>
                                    <strong>{city.value}</strong>
                                </Col>
                            </Row>
                        </Fragment>
                    ))}
            </Col>
        </Row>
    );

    let topposts = [];
    props.results_separated &&
        props.results_separated[0].postsSimplified.data.map((post) => {
            topposts.push({
                created_time: moment(post.created_time).valueOf(),
                message: post.caption,
                id: post.ig_id,
                preview: post.media_url,
                likes: post.like_count ? post.like_count : 0,
                comments: post.comments_count ? post.comments_count : 0,
            });
        });

    let topposts_columns = [
        {
            title: "Date/Time",
            dataIndex: "created_time",
            key: "created_time",
            width: 180,
            sorter: (a, b) => a.created_time - b.created_time,
            render: (created_time) => moment(created_time).format("lll"),
        },
        {
            title: "Preview",
            dataIndex: "preview",
            key: "preview",
            width: 100,
            render: (preview_url) => (
                <img width="100" alt="preview" src={preview_url} />
            ),
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Likes",
            dataIndex: "likes",
            key: "likes",
            sorter: (a, b) => a.likes - b.likes,
        },
        {
            title: "Comments",
            dataIndex: "comments",
            key: "comments",
            sorter: (a, b) => a.comments - b.comments,
        },
    ];
    topposts = topposts.sort((a, b) => Number(b.likes) - Number(a.likes));
    console.info("topposts", topposts);
    const toppostsPanel = (
        <div>
            <Table
                dataSource={topposts}
                columns={topposts_columns}
                size="small"
                pagination={false}
            />
            {/* <pre>{JSON.stringify(topposts, null, 2)}</pre> */}
        </div>
    );

    // -------------------------

    const body = props.loading ? (
        <Fragment>
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            Instagram
            {/* <br />
            custom_sections ={" "}
            <pre>{JSON.stringify(props.custom_sections, null, 2)}</pre> */}
            <Collapse
                bordered={false}
                defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8", "9"]}
            >
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("channels") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Included in this Report
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "channels"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "channels",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="1"
                    >
                        {props.custom_sections.indexOf("channels") !== -1 &&
                            props.results_channels &&
                            props.results_channels.map((rc, rcIndex) => {
                                const resultChannel = rc.split("|");
                                return (
                                    <div key={rcIndex}>
                                        <Icon
                                            type={resultChannel[1]}
                                            theme="filled"
                                            style={{
                                                color: props.store.getTagColorForType(
                                                    resultChannel[1]
                                                ),
                                                marginRight: 10,
                                            }}
                                        />
                                        {resultChannel[3]} (
                                        <strong>{resultChannel[4]}</strong>)
                                    </div>
                                );
                            })}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("activity_overview") !==
                        -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Activity Overview
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "activity_overview"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "activity_overview",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="2"
                    >
                        {props.custom_sections.indexOf("activity_overview") !==
                            -1 && activityOverviewPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("audience_growth") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Audience Growth
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "audience_growth"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "audience_growth",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="3"
                    >
                        {props.custom_sections.indexOf("audience_growth") !==
                            -1 && audienceGrowthPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("messages") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Messages
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "messages"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "messages",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="4"
                    >
                        {props.custom_sections.indexOf("messages") !== -1 &&
                            postsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("impressions") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Impressions
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "impressions"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "impressions",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="5"
                    >
                        {props.custom_sections.indexOf("impressions") !== -1 &&
                            impressionsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("reach") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Reach
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "reach"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "reach",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="6"
                    >
                        {props.custom_sections.indexOf("reach") !== -1 &&
                            reachPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("audience") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Audience
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "audience"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "audience",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="7"
                    >
                        {props.custom_sections.indexOf("audience") !== -1 &&
                            audiencePanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("statsbypage") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Stats by Profile/Page
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "statsbypage"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "statsbypage",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="8"
                    >
                        {props.custom_sections.indexOf("statsbypage") !== -1 &&
                            pagestatsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("topposts") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Top Posts
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "topposts"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "topposts",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="9"
                    >
                        {props.custom_sections.indexOf("topposts") !== -1 &&
                            toppostsPanel}
                    </Panel>
                ) : null}
            </Collapse>
        </Fragment>
    );

    return body;
};

export default ReportResultChannelMonthlyInstagram;
