import React, { Fragment } from "react";
// import ReactJson from "react-json-view";
import { Typography, Divider } from "antd";

const { Title, Text } = Typography;

const AccountsChannelInfoLinkedin = ({ channel_info }) => {
    return (
        <Fragment>
            {/* <ReactJson src={channel_info} /> */}
            {/* <Divider /> */}
            <Text>Name</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.name}
            </Title>

            <Text>Headline</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.headline}
            </Title>

            <Text>ID</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.id}
            </Title>

            <Text>Organization Type</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.organizationType?.replace(/_/g, " ")}
            </Title>

            <Text>Organization Staff Size</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.staffCountRange?.replace(/_/g, " ")}
            </Title>

            <Text>Website</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                <Fragment>
                    <a
                        href={channel_info.localizedWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {channel_info.localizedWebsite}
                    </a>
                </Fragment>
            </Title>
        </Fragment>
    );
};

export default AccountsChannelInfoLinkedin;
