import React, { Component, Fragment } from "react";
import {
    Typography,
    List,
    Icon,
    Tag,
    Menu,
    Dropdown,
    Collapse,
    Tooltip,
} from "antd";
import { firebase } from "../../base";
import scss_variables from "./../../_variables.scss";
import { social_notification } from "./../../functions";

import AccountsQueueModal from "./AccountsQueueModal";
import AccountsQueueEdit from "./AccountsQueueEdit";
import AccountsQueueSendnow from "./AccountsQueueSendnow";
import AdsListModal from "./../Ads/AdsListModal";
import SocialChannel from "./../SocialChannel";
import SocialTag from "./../SocialTag";

import { observer } from "mobx-react";

import moment from "moment";

const { Title, Text } = Typography;
const { Panel } = Collapse;

class AccountQueue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: null,
            account: null,
            modal_visible: false,
            queue: {},
            edit_visible: false,
            edit_confirmLoading: false,
            sendnow_visible: false,
            sendnow_confirmLoading: false,
            ad_post: {},
        };
    }

    UNSAFE_componentWillMount() {
        //this.props.store.setCurrentSection("settings");
        //var path = this.props.params.splat;
        //console.info("AccountsReport splat/path (passed parameter) = ", path);
        console.info("AccountQueue this.props ", this.props);
        this.setState({ accountId: this.props.match.params.id });
        console.info("AccountQueue accountId ", this.props.match.params.id);

        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            { route: `/dashboard/accounts`, title: "Accounts" },
            { title: "Queue" },
        ]);
    }

    componentWillUnmount() {
        this.props.store.unregisterModalListener("accounts_queue_modal");
        this.props.store.unregisterModalListener("accounts_queue_edit");
        this.props.store.unregisterModalListener("ads_modal");
    }
    async componentDidMount() {
        await this.reloadAccount();
    }

    async reloadAccount() {
        console.info("reloadAccount");

        await this.props.store
            .getAccount(this.props.match.params.id)
            .then(async (_account) => {
                if (_account.queue) {
                    _account.queue.map((q, qIndex) => {
                        q.original_index = qIndex;
                        return false;
                    });
                } else {
                    _account.queue = [];
                }

                const channels = await this.props.store.getAccountChannels(
                    this.props.match.params.id
                );
                console.info("reloadAccount channels", channels);
                //getAccountChannels

                this.setState({
                    account: _account,
                    channels,
                });
            });
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    showModal = async () => {
        this.props.store.showModalByKey("accounts_queue_modal");
    };
    showEdit = () => {
        console.log("showEdit");

        this.props.store.showModalByKey("accounts_queue_edit");
        // this.props.store.registerModalListener(
        //     "accounts_queue_edit",
        //     (_action) => {
        //         console.info("handleModalListener _action", _action);
        //         if (_action === "hide") this.reloadAccount();
        //     }
        // );
    };
    showSendnow = () => {
        console.log("showSendnow");
        // this.setState({
        //     sendnow_visible: true,
        //     sendnow_confirmLoading: false
        // });
        this.props.store.showModalByKey("accounts_queue_sendnow");
        // this.props.store.registerModalListener(
        //     "accounts_queue_sendnow",
        //     (_action) => {
        //         console.info("handleModalListener _action", _action);
        //         if (_action === "hide") this.reloadAccount();
        //     }
        // );
    };

    handleEditCancel = () => {
        this.setState({ edit_visible: false });
    };
    handleSendnowCancel = () => {
        this.setState({ sendnow_visible: false });
        // reload after closing
        this.reloadAccount();
    };
    handleEditEdit = () => {
        //this.setState({ edit_visible: false });
        this.setState({ edit_confirmLoading: true });

        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }
            console.log(
                "Received values of this.state.post: ",
                this.state.post
            );

            if (
                typeof values.post_file != "undefined" &&
                values.changed_post_file &&
                !values.post_file.library_file
            ) {
                console.log("NOT A LIBRARY FILE");
            } else {
                console.log("DO WE HAVE A LIBRARY FILE?");
                if (this.state.post.library_file) {
                    console.log("WE DOOO!!!!");
                } else {
                    console.log("WE DON'T :(");
                }
            }

            if (
                typeof values.post_file != "undefined" &&
                values.changed_post_file &&
                !values.post_file.library_file
            ) {
                const file = values.post_file.file.originFileObj
                    ? values.post_file.file.originFileObj
                    : values.post_file.file;
                console.info("FILE", file);
                const storageRef = firebase.storage().ref();
                console.info("storageRef", storageRef);

                let now = Date.now();
                let fileparts = file.name.split(".");
                let fileext = fileparts.pop();
                const mainImage = storageRef.child(
                    fileparts.join(".") + "_" + now.valueOf() + "." + fileext
                );
                console.info("mainImage", mainImage);
                let uploadTask = mainImage.put(file);
                let that = this;

                uploadTask.on(
                    "state_changed",
                    function (snapshot) {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        var progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100;
                        console.log("Upload is " + progress + "% done");
                        that.setState({
                            upload_percentage: Math.round(progress),
                        });
                    },
                    function (error) {
                        // Handle unsuccessful uploads
                        console.error("unsuccessful upload: " + error);
                    },
                    function () {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(function (downloadURL) {
                                console.log("File available at", downloadURL);

                                let post_file = {
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    url: downloadURL,
                                    meta: uploadTask.snapshot.metadata,
                                };

                                // update the post
                                that.actualUpdate(values.post_text, post_file);
                            });
                    }
                );
            } else {
                let post_file = null;

                // Overwrite the library file
                if (this.state.post.library_file) {
                    post_file = {
                        name: this.state.post.library_file.file.name,
                        size: this.state.post.library_file.file.size,
                        type: this.state.post.library_file.file.type,
                        url: this.state.post.library_file.downloadURL,
                        library_file: true,
                        library_key: this.state.post.library_file.key,
                    };

                    // Lock the library file
                    this.props.store.toggleLibraryItemLock(
                        { key: this.state.post.library_file.key },
                        true
                    );

                    delete this.state.post.library_file;
                }

                this.actualUpdate(
                    values.post_text,
                    post_file,
                    values.delete_post_file
                );
            }
        });

        // setTimeout(() => {
        //     this.setState({
        //         edit_visible: false,
        //         edit_confirmLoading: false
        //     });
        // }, 2000);
    };

    actualUpdate = (_post_text, _post_file, _delete_post_file) => {
        console.info(
            "actualUpdate",
            "_post_text",
            _post_text,
            "_post_file",
            _post_file,
            "_delete_post_file",
            _delete_post_file
        );
        console.info(
            "actualUpdate _account_key",
            this.state.account.key,
            "_queue_index",
            this.state.queue_index
        );

        // update the post
        this.props.store
            .updateAccountQueuePost(
                this.state.account.key,
                this.state.queue_index,
                _post_text,
                _post_file,
                _delete_post_file
            )
            .then((updateData) => {
                console.info("updateData", updateData);

                // reload after closing
                this.reloadAccount();

                this.closeAfterEdit();
            })
            .catch((err) => {
                console.error(err);
                social_notification("update_account_queue_post", "error", {
                    description: err,
                });

                this.setState({
                    edit_visible: false,
                    edit_confirmLoading: false,
                    upload_percentage: 0,
                });
            });
    };

    closeAfterEdit = () => {
        const { form } = this.formRef.props;

        social_notification("update_account_queue_post", "success");

        form.resetFields();
        this.setState({
            edit_visible: false,
            edit_confirmLoading: false,
            upload_percentage: 0,
        });
    };

    handleMenuClick(e, _queue_item, _queue_index, _result, _resultIndex) {
        console.info(
            "handleMenuClick",
            e,
            "_queue_item",
            _queue_item,
            "_queue_index",
            _queue_index,
            "_result",
            _result,
            "_resultIndex",
            _resultIndex
        );
        // _queue_item = Object.assign({}, _queue_item);
        // _queue_index = Number(_queue_index);

        // console.info(
        //     "handleMenuClick AFTER",

        //     "_queue_item",
        //     _queue_item,
        //     "_queue_index",
        //     _queue_index
        // );

        switch (e.key) {
            case "open":
                this.setState({ post: _queue_item, queue_index: _queue_index });
                setTimeout(
                    function (that) {
                        that.showModal();
                    },
                    1,
                    this
                );
                break;
            case "edit":
                this.setState({ post: _queue_item, queue_index: _queue_index });
                setTimeout(
                    function (that) {
                        that.showEdit();
                    },
                    1,
                    this
                );
                break;
            case "sendnow":
                this.setState({ post: _queue_item, queue_index: _queue_index });
                setTimeout(
                    function (that) {
                        that.showSendnow();
                    },
                    1,
                    this
                );
                break;
            case "approve":
                let that = this;
                this.props.store
                    .approveAccountQueuePost(
                        this.state.account.key,
                        _queue_index
                    )
                    .then((result) => {
                        console.info("result", result);
                        setTimeout(function () {
                            social_notification(
                                "approve_account_queue_post",
                                "success"
                            );
                            that.reloadAccount();
                        }, 500);
                    });
                break;
            case "posted_open_channel":
                this.showChannel(
                    null,
                    this.state.account,
                    _result.type,
                    _result.channel_id
                );
                break;
            case "posted_link":
                window.open(_result.link);
                break;
            case "posted_boost_post":
                console.info(
                    "posted_boost_post _result",
                    _result,
                    "_queue_item",
                    _queue_item
                );
                this.setState({
                    ad_post: {
                        post_id: _result.post_id,
                        post_text: _queue_item.post_text,
                    },
                });
                this.props.store.showModalByKey("ads_modal");
                break;
            default:
                break;
        }
    }

    showChannel = (_evt, _account, _provider, _channel_id) => {
        console.info(
            "showChannel _evt",
            _evt,
            "_account",
            _account,
            "_provider",
            _provider,
            "_channel_id",
            _channel_id
        );

        this.props.history.push(
            `/dashboard/accounts/${_account.key}/channel/${_provider}/${_channel_id}`
        );
    };

    render() {
        console.info(
            "AccountQueue render this.state.account",
            this.state.account
        );

        return this.state.account ? (
            <Fragment>
                <Title level={2} type="secondary" style={{ marginBottom: 40 }}>
                    Queue for {this.state.account && this.state.account.name}
                </Title>

                {/* <SocialSeparator>Statistics</SocialSeparator>

                <Row
                    gutter={16}
                    type="flex"
                    justify="space-around"
                    align="middle"
                    style={{ margin: "40px 10%" }}
                >
                    <Col span={12} style={{ textAlign: "center" }}>
                        <Statistic
                            title="Feedback"
                            value={1128}
                            prefix={<Icon type="like" />}
                        />
                    </Col>
                    <Col span={12} style={{ textAlign: "center" }}>
                        <Statistic title="Unmerged" value={93} suffix="/ 100" />
                    </Col>
                </Row> */}

                <Collapse defaultActiveKey={["2", "3"]}>
                    <Panel
                        header={
                            "Past (" +
                            (this.state.account &&
                                this.state.account.queue.filter((q) => {
                                    if (q.status !== "upcoming") return q;
                                    return false;
                                }).length) +
                            ")"
                        }
                        key="1"
                    >
                        <List
                            size="small"
                            //header={<div>Header</div>}
                            //footer={<div>Footer</div>}
                            //bordered
                            className="AccountsQueueList"
                            dataSource={
                                this.state.account &&
                                this.state.account.queue.filter((q) => {
                                    if (
                                        q.status !== "upcoming" &&
                                        q.status !== "aproval"
                                    )
                                        return q;
                                    return false;
                                })
                            }
                            renderItem={(item, itemIndex) => {
                                const channel_to_select = item.channel_id
                                    ? item.channel_id
                                    : item.channels_ids
                                    ? item.channels_ids[0]
                                    : null;
                                const channel = this.state.channels.filter(
                                    (channel) =>
                                        channel.id === channel_to_select
                                )[0];
                                return (
                                    <List.Item
                                        key={itemIndex}
                                        className={
                                            "social_AccountQueuePastContainer"
                                        }
                                    >
                                        <div>
                                            {item.post_text}
                                            <br />
                                            {item.edited_at ? (
                                                <Tag
                                                    color={this.props.store.getTagColorForType(
                                                        "default"
                                                    )}
                                                    style={{ marginTop: 5 }}
                                                >
                                                    Edited
                                                </Tag>
                                            ) : null}
                                        </div>
                                        <div
                                            style={{
                                                paddingLeft: 10,
                                            }}
                                        >
                                            {channel ? (
                                                <Fragment key={channel.id}>
                                                    {/* <Tag
                                                        color={this.props.store.getTagColorForType(
                                                            channel.type
                                                        )}
                                                    >
                                                        <Icon
                                                            type={channel.type}
                                                            theme={
                                                                channel.type ===
                                                                    "facebook" ||
                                                                channel.type ===
                                                                    "instagram" ||
                                                                channel.type ===
                                                                    "linkedin"
                                                                    ? "filled"
                                                                    : null
                                                            }
                                                            style={{
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                        {channel.name}
                                                    </Tag> */}
                                                    <SocialChannel
                                                        store={this.props.store}
                                                        //type="filled"
                                                        icon
                                                        channel={channel}
                                                        extra={" "}
                                                    />
                                                    <br />
                                                </Fragment>
                                            ) : null}
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "left",
                                                paddingLeft: 10,
                                            }}
                                        >
                                            {/* <Tag
                                            style={{ marginTop: 5 }}
                                            color={this.props.store.getTagColorForType(
                                                item.status
                                            )}
                                        >
                                            {item.status}
                                        </Tag> */}
                                            <Icon
                                                type="clock-circle"
                                                style={{ marginRight: 5 }}
                                            />
                                            {moment
                                                .utc(item.posted_on)
                                                .local()
                                                .format("LLLL")}
                                            <br />
                                        </div>
                                        <div style={{ textAlign: "right" }}>
                                            {/* <pre>
                                            {JSON.stringify(item, null, 2)}
                                        </pre> */}

                                            {/* {item.results &&
                                                item.results.map(
                                                    (r, rIndex) => {
                                                        return (
                                                            <Fragment
                                                                key={rIndex}
                                                            >
                                                                <div
                                                                    className={
                                                                        "social_QueuePastChannel"
                                                                    }
                                                                >
                                                                    <div>
                                                                        <Tag
                                                                            color={this.props.store.getTagColorForType(
                                                                                r.type
                                                                            )}
                                                                        >
                                                                            <Icon
                                                                                type={
                                                                                    r.type
                                                                                }
                                                                                theme={
                                                                                    r.type ===
                                                                                        "facebook" ||
                                                                                    r.type ===
                                                                                        "instagram" ||
                                                                                    r.type ===
                                                                                        "linkedin"
                                                                                        ? "filled"
                                                                                        : null
                                                                                }
                                                                                style={{
                                                                                    marginRight: 5,
                                                                                }}
                                                                            />
                                                                            {
                                                                                r.channel_name
                                                                            }
                                                                        </Tag>
                                                                    </div>
                                                                    <div>
                                                                        <Dropdown
                                                                            overlay={
                                                                                <Menu
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.handleMenuClick(
                                                                                            e,
                                                                                            item,
                                                                                            item.original_index,
                                                                                            r,
                                                                                            rIndex
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Menu.Item key="posted_open_channel">
                                                                                        <Icon type="container" />
                                                                                        Open
                                                                                        Channel
                                                                                    </Menu.Item>

                                                                                    <Menu.Item key="posted_link">
                                                                                        <Icon type="edit" />
                                                                                        Link
                                                                                    </Menu.Item>

                                                                                    <Menu.Item key="posted_boost_post">
                                                                                        <Icon type="facebook" />
                                                                                        Boost
                                                                                        Post
                                                                                    </Menu.Item>
                                                                                </Menu>
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                type="more"
                                                                                style={{
                                                                                    float:
                                                                                        "right",
                                                                                    padding: 3,
                                                                                }}
                                                                            />
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        );
                                                    }
                                                )} */}

                                            {item.errors &&
                                            item.errors.length > 0 ? (
                                                <Tooltip
                                                    title={item.errors.join(
                                                        "\n"
                                                    )}
                                                >
                                                    <Tag
                                                        color={
                                                            scss_variables.scss_color_pallete_error
                                                        }
                                                    >
                                                        <Icon
                                                            style={{
                                                                color: "white",
                                                                marginRight: 5,
                                                            }}
                                                            type="exclamation-circle"
                                                        />{" "}
                                                        Errors
                                                    </Tag>
                                                    {/* {item.errors.map(
                                                        (err, errIndex) => {
                                                            return (
                                                                <Fragment
                                                                    key={
                                                                        errIndex
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            marginTop: 5,
                                                                            color:
                                                                                scss_variables.scss_color_pallete_error,
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            style={{
                                                                                color:
                                                                                    scss_variables.scss_color_pallete_error,
                                                                                marginRight: 5,
                                                                            }}
                                                                            type="exclamation-circle"
                                                                        />
                                                                        {err}
                                                                    </div>
                                                                </Fragment>
                                                            );
                                                        }
                                                    )} */}
                                                </Tooltip>
                                            ) : null}
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                    </Panel>
                    <Panel
                        header={
                            "Upcoming (" +
                            (this.state.account &&
                                this.state.account.queue.filter((q) => {
                                    if (q.status === "upcoming") return q;
                                    return false;
                                }).length) +
                            ")"
                        }
                        key="2"
                    >
                        <List
                            size="small"
                            className="AccountsQueueList"
                            //header={<div>Header</div>}
                            //footer={<div>Footer</div>}
                            //bordered
                            dataSource={
                                this.state.account &&
                                this.state.account.queue.filter((q) => {
                                    if (q.status === "upcoming") return q;
                                    return false;
                                })
                            }
                            renderItem={(item, itemIndex) => {
                                const channel_to_select = item.channel_id
                                    ? item.channel_id
                                    : item.channels_ids
                                    ? item.channels_ids[0]
                                    : null;
                                const channel = this.state.channels.filter(
                                    (channel) =>
                                        channel.id === channel_to_select
                                )[0];
                                return (
                                    <List.Item>
                                        <div>
                                            {/* {item.target_shortid} */}
                                            {item.post_text}
                                            {item.edited_at ? (
                                                <Fragment>
                                                    <br />
                                                    <Tag
                                                        color={this.props.store.getTagColorForType(
                                                            "default"
                                                        )}
                                                    >
                                                        edited
                                                    </Tag>
                                                </Fragment>
                                            ) : null}
                                            {/* <pre>
                                            {JSON.stringify(
                                                item.channels_ids,
                                                null,
                                                2
                                            )}
                                        </pre> */}
                                        </div>
                                        <div
                                            style={{
                                                paddingLeft: 10,
                                            }}
                                        >
                                            {channel ? (
                                                <Fragment key={channel.id}>
                                                    {/* <Tag
                                                        color={this.props.store.getTagColorForType(
                                                            channel.type
                                                        )}
                                                    >
                                                        <Icon
                                                            type={channel.type}
                                                            theme={
                                                                channel.type ===
                                                                    "facebook" ||
                                                                channel.type ===
                                                                    "instagram" ||
                                                                channel.type ===
                                                                    "linkedin"
                                                                    ? "filled"
                                                                    : null
                                                            }
                                                            style={{
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                        {channel.name}
                                                    </Tag> */}
                                                    <SocialChannel
                                                        store={this.props.store}
                                                        //type="filled"
                                                        icon
                                                        channel={channel}
                                                        extra={" "}
                                                    />
                                                    <br />
                                                </Fragment>
                                            ) : null}
                                        </div>
                                        <div>
                                            {moment
                                                .utc(item.schedule_datetime)
                                                .local()
                                                .format("LLLL")}

                                            <div>
                                                {item.location_datetime !==
                                                item.schedule_datetime ? (
                                                    <Tag>
                                                        <Icon
                                                            type="environment"
                                                            style={{
                                                                marginTop: 5,
                                                            }}
                                                        />
                                                        <Text
                                                            type="secondary"
                                                            style={{
                                                                marginLeft: 5,
                                                                fontSize:
                                                                    "0.75rem",
                                                            }}
                                                        >
                                                            Local time
                                                        </Text>
                                                    </Tag>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            span={6}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Dropdown
                                                overlay={
                                                    <Menu
                                                        onClick={(e) =>
                                                            this.handleMenuClick(
                                                                e,
                                                                item,
                                                                item.original_index
                                                            )
                                                        }
                                                    >
                                                        <Menu.Item key="open">
                                                            <Icon type="container" />
                                                            Open
                                                        </Menu.Item>
                                                        {/* {item.status ===
                                                    "upcoming" ? (
                                                        <Menu.Item key="edit">
                                                            <Icon type="edit" />
                                                            Edit
                                                        </Menu.Item>
                                                    ) : null} */}
                                                        <Menu.Item key="sendnow">
                                                            <Icon type="arrow-right" />
                                                            Send Now
                                                        </Menu.Item>
                                                        {/* <Menu.Item key="pause">
                                                            <Icon type="pause" />
                                                            Pause
                                                        </Menu.Item> */}
                                                    </Menu>
                                                }
                                            >
                                                <Icon
                                                    type="more"
                                                    style={{
                                                        float: "right",
                                                        padding: 3,
                                                    }}
                                                />
                                            </Dropdown>
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                    </Panel>
                    <Panel
                        header={
                            "Approval (" +
                            (this.state.account &&
                                this.state.account.queue.filter((q) => {
                                    if (q.status === "approval") return q;
                                    return false;
                                }).length) +
                            ")"
                        }
                        key="3"
                    >
                        <List
                            size="small"
                            className="AccountsQueueList AccountsQueueListApprove"
                            //header={<div>Header</div>}
                            //footer={<div>Footer</div>}
                            //bordered
                            dataSource={
                                this.state.account &&
                                this.state.account.queue.filter((q) => {
                                    if (q.status === "approval") return q;
                                    return false;
                                })
                            }
                            renderItem={(item, itemIndex) => {
                                const channel_to_select = item.channel_id
                                    ? item.channel_id
                                    : item.channels_ids
                                    ? item.channels_ids[0]
                                    : null;
                                const channel = this.state.channels.filter(
                                    (channel) =>
                                        channel.id === channel_to_select
                                )[0];
                                return (
                                    <List.Item>
                                        <div>
                                            {/* {item.target_shortid} */}
                                            {item.post_text}
                                            <br />
                                            <br />
                                            <SocialTag
                                                store={this.props.store}
                                                color={this.props.store.getTagColorForType(
                                                    item.status
                                                )}
                                            >
                                                {item.status}
                                            </SocialTag>

                                            {item.edited_at ? (
                                                <SocialTag
                                                    store={this.props.store}
                                                    color={this.props.store.getTagColorForType(
                                                        "default"
                                                    )}
                                                >
                                                    edited
                                                </SocialTag>
                                            ) : null}
                                        </div>
                                        <div
                                            style={{
                                                paddingLeft: 10,
                                            }}
                                        >
                                            {channel ? (
                                                <Fragment key={channel.id}>
                                                    {/* <Tag
                                                            color={this.props.store.getTagColorForType(
                                                                channel.type
                                                            )}
                                                        >
                                                            <Icon
                                                                type={
                                                                    channel.type
                                                                }
                                                                theme={
                                                                    channel.type ===
                                                                        "facebook" ||
                                                                    channel.type ===
                                                                        "instagram" ||
                                                                    channel.type ===
                                                                        "linkedin"
                                                                        ? "filled"
                                                                        : null
                                                                }
                                                                style={{
                                                                    marginRight: 5,
                                                                }}
                                                            />
                                                            {channel.name}
                                                        </Tag> */}
                                                    <SocialChannel
                                                        store={this.props.store}
                                                        //type="filled"
                                                        icon
                                                        channel={channel}
                                                        extra={" "}
                                                    />
                                                    <br />
                                                </Fragment>
                                            ) : null}
                                        </div>
                                        <div>
                                            {moment
                                                .utc(item.schedule_datetime)
                                                .local()
                                                .format("LLLL")}

                                            <div>
                                                {item.location_datetime !==
                                                item.schedule_datetime ? (
                                                    <Tag>
                                                        <Icon
                                                            type="environment"
                                                            style={{
                                                                marginTop: 5,
                                                            }}
                                                        />
                                                        <Text
                                                            type="secondary"
                                                            style={{
                                                                marginLeft: 5,
                                                                fontSize:
                                                                    "0.75rem",
                                                            }}
                                                        >
                                                            Local time
                                                        </Text>
                                                    </Tag>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ textAlign: "right" }}>
                                            <Dropdown
                                                overlay={
                                                    <Menu
                                                        onClick={(e) =>
                                                            this.handleMenuClick(
                                                                e,
                                                                item,
                                                                item.original_index
                                                            )
                                                        }
                                                    >
                                                        <Menu.Item key="open">
                                                            <Icon type="container" />
                                                            Open
                                                        </Menu.Item>
                                                        {item.status ===
                                                            "upcoming" ||
                                                        item.status ===
                                                            "approval" ? (
                                                            <Menu.Item key="edit">
                                                                <Icon type="edit" />
                                                                Edit
                                                            </Menu.Item>
                                                        ) : null}
                                                        {item.status ===
                                                        "approval" ? (
                                                            <Menu.Item key="approve">
                                                                <Icon type="check" />
                                                                Approve
                                                            </Menu.Item>
                                                        ) : null}
                                                        <Menu.Item key="sendnow">
                                                            <Icon type="arrow-right" />
                                                            Send Now
                                                        </Menu.Item>
                                                        {/* <Menu.Item key="pause">
                                                                <Icon type="pause" />
                                                                Pause
                                                            </Menu.Item> */}
                                                    </Menu>
                                                }
                                            >
                                                <Icon
                                                    type="more"
                                                    style={{
                                                        float: "right",
                                                        padding: 3,
                                                    }}
                                                />
                                            </Dropdown>
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                    </Panel>
                </Collapse>

                <AccountsQueueModal
                    store={this.props.store}
                    post={this.state.post}
                    account={this.state.account}
                />

                <AccountsQueueEdit
                    store={this.props.store}
                    post={this.state.post}
                    queue_index={this.state.queue_index}
                    account={this.state.account}
                />

                <AccountsQueueSendnow
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.sendnow_visible}
                    onCancel={this.handleSendnowCancel}
                    // onCreate={this.handleModalCreate}
                    onEdit={this.handleEditEdit}
                    store={this.props.store}
                    post={this.state.post}
                    account={this.state.account}
                    confirmLoading={this.state.sendnow_confirmLoading}
                    queue_index={this.state.queue_index}
                    // locations={this.props.store.getLocations()}
                />

                <AdsListModal
                    data={this.state.ad_post}
                    store={this.props.store}
                />
            </Fragment>
        ) : (
            <h1>Loading</h1>
        );
    }
}

export default observer(AccountQueue);
