import React, { Component, Fragment } from "react";
import { Button, Form, Modal, Row, Col, Tree, Alert, Input, Icon } from "antd";
import { observer } from "mobx-react";
import EditableTagGroup from "./../EditableTagGroup";
import { social_notification } from "./../../functions";
import names from "./../../names";

const { Search } = Input;
const { TreeNode } = Tree;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                checkedKeys: [],
                filter: "",
            };
        }

        componentDidMount() {
            // To disable submit button at the beginning.
            // this.props.form.validateFields();

            let selected_masterposts =
                this.props.data && this.props.data.masterposts
                    ? this.props.data.masterposts.map((mp) => mp.key)
                    : [];
            // console.info(
            //     "componentDidMount selected_masterposts",
            //     selected_masterposts
            // );
            // console.info("componentDidMount this.props.data", this.props.data);

            this.setState({
                masterposts: selected_masterposts,
                categories:
                    this.props.data && this.props.data.category
                        ? [this.props.data.category]
                        : [],
            });
        }

        handleSubmit = (e) => {
            e.preventDefault();
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    console.log("Received values of form: ", values);
                }
            });
        };

        handleCategoriesChange = (_categories) => {
            console.info("handleCategoriesChange", _categories);
            //this.props.data = _categories;
            this.setState({ categories: _categories });
            this.props.form.setFieldsValue({ categories: _categories });
        };

        onmasterpostsSearchChange = (_filter) => {
            console.info("onmasterpostsSearchChange _filter", _filter);
            this.setState({ filter: _filter });
        };

        handleAddAllMasterposts = () => {
            console.log(`handleAddAllMasterposts`);
            let onlyKeys = [];
            this.props.store.getAllMasterposts.map((a) => {
                onlyKeys.push(a.key);
                return false;
            });
            console.info(`handleAddAllMasterposts onlyKeys`, onlyKeys);

            //this.props.data.accounts = onlyKeys;

            this.props.form.setFieldsValue({
                masterposts: onlyKeys,
            });

            this.setState({ masterposts: onlyKeys });
        };

        handlemasterpostsChange = (value) => {
            console.log(`handlemasterpostsChange ${value}`);

            this.props.form.setFieldsValue({ masterposts: value });
        };

        renderTreeNodes = (data) => {
            console.log("renderTreeNodes filter=" + this.state.filter);
            let nodes = data.map((item) => {
                if (this.state.filter) {
                    console.log("we have filter");
                    if (
                        item.name
                            .toUpperCase()
                            .indexOf(this.state.filter.toUpperCase()) !== -1
                    ) {
                        return <TreeNode key={item.key} title={item.name} />;
                    }
                } else {
                    return <TreeNode key={item.key} title={item.name} />;
                }
                return false;
            });
            nodes = nodes.filter((n) => (typeof n !== "undefined" ? n : false));
            console.info("renderTreeNodes nodes", nodes);

            return nodes.length > 0 ? nodes : [];
        };

        onCheck = (checkedKeys) => {
            //console.log("onCheck", checkedKeys);
            this.setState({ masterposts: checkedKeys });

            this.props.form.setFieldsValue({
                masterposts: checkedKeys,
            });
        };

        render() {
            const {
                getFieldDecorator,
                // getFieldsError,
                // getFieldError,
                // isFieldTouched
            } = this.props.form;
            const { data } = this.props;
            // Only show error after a field is touched.
            // const usernameError =
            //     isFieldTouched("username") && getFieldError("username");
            // const passwordError =
            //     isFieldTouched("password") && getFieldError("password");

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            let availableMasterposts =
                this.props.store.getAllMasterposts.filter(
                    (mp) => typeof mp.scheduled_date !== "undefined"
                );

            const filtered_treeNodes =
                availableMasterposts &&
                this.renderTreeNodes(availableMasterposts);

            return (
                <Form {...formItemLayout}>
                    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                    <Form.Item label="Categories">
                        {getFieldDecorator("categories", {
                            //getValueFromEvent: handleGetTags
                            rules: [
                                {
                                    required: true,
                                    // message:
                                    //     "Please input the categories to be applied to the masterposts!",
                                },
                            ],
                            initialValue:
                                data && data.category ? [data.category] : [],
                            onChange: this.handleCategoriesChange,
                        })(
                            <EditableTagGroup
                                element_title="Category"
                                tag_color={this.props.store.getTagColorForType(
                                    "category"
                                )}
                                options={this.props.store.getAllCategories.map(
                                    (category) => category.category
                                )}
                            />
                        )}
                    </Form.Item>
                    {this.state.categories &&
                    this.state.categories.length > 0 ? (
                        <Form.Item label={names.get("MASTERPOST", true, true)}>
                            {getFieldDecorator("masterposts", {
                                rules: [
                                    {
                                        required: true,
                                        message: `Please select at least one ${names.get(
                                            "MASTERPOST"
                                        )} the categories to be applied to!`,
                                    },
                                ],
                            })(
                                <div>
                                    <Row type="flex" justify="space-between">
                                        <Col>
                                            <Search
                                                placeholder="filter"
                                                onSearch={
                                                    this
                                                        .onmasterpostsSearchChange
                                                }
                                                onChange={(e) =>
                                                    this.onmasterpostsSearchChange(
                                                        e.target.value
                                                    )
                                                }
                                                style={{ width: 200 }}
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                onClick={
                                                    this.handleAddAllMasterposts
                                                }
                                            >
                                                Check all
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Tree
                                        checkable
                                        selectable={false}
                                        // onExpand={this.onExpand}
                                        // expandedKeys={this.state.expandedKeys}
                                        // autoExpandParent={this.state.autoExpandParent}

                                        onCheck={this.onCheck}
                                        checkedKeys={this.state.masterposts}
                                        // onSelect={this.onSelect}
                                        // selectedKeys={this.state.selectedKeys}
                                        style={{ marginLeft: -27 }}
                                    >
                                        {filtered_treeNodes}
                                    </Tree>
                                    {filtered_treeNodes.length === 0 ? (
                                        <Alert
                                            message="- No masterposts -"
                                            type="warning"
                                            style={{ width: 200, fontSize: 12 }}
                                        />
                                    ) : null}
                                </div>
                            )}
                        </Form.Item>
                    ) : null}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class CategoriesListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "categories_modal_add",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("categories_modal_add");
    };

    onModalApply = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                social_notification("validation_error", "error");
                return;
            }

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            console.log("Received values: ", values);

            this.props.store.applyCategoriesToMasterposts({
                ...values,
                //...this.state.edit_data
            });

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "categories_modal_add",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={
                            !this.props.data ||
                            (this.props.data && !this.props.data.category)
                                ? "Create new category"
                                : "Apply new category"
                        }
                        okText={
                            !this.props.data ||
                            (this.props.data && !this.props.data.category)
                                ? "Create"
                                : "Apply"
                        }
                        onCancel={this.onModalCancel}
                        onOk={this.onModalApply} //onApply
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={this.props.data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(CategoriesListModal);
