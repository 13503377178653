import React, { Component } from "react";
import { Empty } from "antd";

class CategoriesReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryToReport: null
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("categories");

        console.info("CategoriesReport this.props ", this.props);
        this.setState({ categoryToReport: this.props.match.params.id });
        //console.info("CategoriesReport this.props ", this.props);

        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            { route: `/dashboard/categories`, title: "Categories" },
            { title: "Report" }
        ]);
    }

    render() {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`No Category to Report for categoryToReport: ${this.state.categoryToReport}`}
            />
        );
    }
}

export default CategoriesReport;
