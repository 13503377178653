import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import UsersList from "./UsersList";

import { observer } from "mobx-react";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("users");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Users" }]);

        //this.setState({ tags: this.props.store.getAllTags() });
    }

    render() {
        return (
            <Switch>
                {/* <Route
                    path="/dashboard/tags/:id/report"
                    render={props => (
                        <TagsReport store={this.props.store} {...props} />
                    )}
                /> */}
                <Route
                    render={props => (
                        <UsersList store={this.props.store} {...props} />
                    )}
                />
            </Switch>
        );
    }
}

export default observer(Users);
