import React, { Component, Fragment } from "react";
import {
    Tooltip,
    Icon,
    Upload,
    Button,
    Row,
    Col,
    Checkbox,
    Alert,
    Modal,
    Spin,
} from "antd";

import LibraryPickup from "./Library/LibraryPickup";

import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const style_Holder = {
    // border: "1px solid red",
    // padding: 40,
};

const EMPTY_INFO = {
    width: 0, // width (if image)
    height: 0, // height (if image)
    type: "", // MIME type of the file
    size: 0, // size of the image in bytes
};

class CropModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            src: props.image_data,
            crop: {
                aspect: props.aspect,
            },
        };
        console.info("props.crop", props.crop);
    }

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });

            // calling the callback
            if (typeof this.props.handleCroppingImage === "function")
                this.props.handleCroppingImage(croppedImageUrl);
        }
    }

    onImageLoaded = (image) => {
        this.imageRef = image;
        return false;
    };

    // onImageLoaded = image => {
    //     this.imageRef = image;
    //     //this.setState({ crop: { width: image.width, height: image.height } });
    //     return false; // Return false when setting crop state in here.
    //   };

    // async test() {
    //     const croppedImg = await this.getCroppedImg(image, crop, fileName);
    // }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    render = () => {
        const { crop, src } = this.state;

        return (
            <div style={{ margin: -24 }}>
                {/* <pre>{JSON.stringify(crop, null, 2)}</pre> */}
                {src && (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        keepSelection
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                )}
                {/* {croppedImageUrl && (
                    <img
                        alt="Crop"
                        style={{ maxWidth: "100%" }}
                        src={croppedImageUrl}
                    />
                )} */}
            </div>
        );
    };
}

/* Pick Files */
class FilePicker extends Component {
    temp_croppedImageUrl;

    constructor(props) {
        super(props);
        this.state = {
            changed: false, // whether there was a change on the choice
            previewURL: "", // the previewURL of the whatever image was selected
            type: null, // the type of the choice (file or library)
            restrictions: this.props.restrictions
                ? this.props.restrictions
                : {}, // whatever restrictions are to the choice
            restriction_message: null,
            library: null,
            file: null,
            fileList: null,
            existing: false, // shows whether the file is an existing one for changing/deleting or not (new file),
            info: EMPTY_INFO,
            crop_modal_visible: false,
            image_data: null,
            croppedImageUrl: null,
            cropped: false,
            aspect: null,
            processing: false,
            noncropped_image_data: null,
            multiple: this.props.multiple ? this.props.multiple : false,
        };
    }

    componentDidMount() {
        console.info("FilePickler componentDidMount props", this.props);
        if (!this.props.selected) return;
        let { selected } = this.props;
        selected = JSON.parse(JSON.stringify(selected));
        console.info("FilePickler componentDidMount selected", selected);
        if (selected.type === "library") {
            this.setState({
                type: selected.type,
                image_data: selected.image_data,
                previewURL: selected.previewURL,
                info: selected.info,
                library: selected.library,
            });
        }
    }

    handleUpdateInfo = (_info) => {
        console.info("handleUpdateInfo _info", _info);
        let { info } = this.state;
        info = { ...info, ..._info };
        this.setState({ info, processing: false });

        // recalcualte restrictions (only if with or height is updated)
        if (_info.width || _info.height) {
            this.recalculateRestrictions();
            // Call onChange callback
            this.callOnChangeHandler();
        }
    };

    handleUpload = async (_evt) => {
        console.info("handleUpload _evt", _evt);
        this.setState({
            file: _evt.file,
            fileList: _evt.fileList,
            changed: true,
            type: "file",
            previewURL: "",
            restriction_message: null,
            image_data: null,
            croppedImageUrl: null,
            cropped: false,
            processing: false,
            noncropped_image_data: null,
        });

        if (_evt.fileList.length === 1) {
            let reader = new FileReader();
            let preview_file = _evt.file;
            this.handleUpdateInfo({
                type: _evt.file.type,
                size: _evt.file.size,
            });
            console.info("handleUpload preview_file", preview_file);
            reader.onloadend = async () => {
                this.setState({
                    previewURL: reader.result,
                    image_data: reader.result,
                });
                let that = this;
                var img = new Image();
                img.onload = function () {
                    that.handleUpdateInfo({
                        width: this.width,
                        height: this.height,
                        type: _evt.file.type,
                        size: _evt.file.size,
                    });
                };
                this.setState({ processing: true });
                img.src = reader.result;
            };
            this.setState({ processing: true });
            await reader.readAsDataURL(preview_file);
        } else {
            // clear the current file
            this.setState({
                file: null,
                fileList: null,
                changed: false,
                type: null,
                info: EMPTY_INFO,
                image_data: null,
                restriction_message: null,
                croppedImageUrl: null,
                cropped: false,
                processing: false,
                noncropped_image_data: null,
            });

            // // Call onChange callback
            this.callOnChangeHandler();
        }
    };

    readRemoteURLasDataURL = (_url) => {
        console.log("readRemoteURLasDataURL _url=" + _url);
        var request = new XMLHttpRequest();
        request.open("GET", _url, true);
        request.responseType = "blob";
        let that = this;
        request.onload = function () {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload = function (e) {
                console.log("DataURL:", e.target.result);
                that.setState({ image_data: e.target.result });

                // Call onChange callback
                that.callOnChangeHandler();
            };
        };
        request.send();
    };

    handleChangeLibrarySelected = (_evt) => {
        console.info("handleChangeLibrarySelected _evt", _evt);
        if (_evt.length === 0) {
            this.setState({
                changed: false,
                type: null,
                library: null,
                previewURL: "",
                info: EMPTY_INFO,
                restriction_message: null,
                image_data: null,
                croppedImageUrl: null,
                cropped: false,
                processing: false,
                noncropped_image_data: null,
            });

            // Call onChange callback
            this.callOnChangeHandler();

            return;
        }

        const library_element = _evt[0]; // TODO: Fix for multiple
        this.setState({
            changed: true,
            type: "library",
            library: library_element,
            previewURL: library_element.downloadURL,
            croppedImageUrl: null,
            cropped: false,
            restriction_message: null,
            noncropped_image_data: null,
        });

        this.handleUpdateInfo({
            type: library_element.file.type,
            size: library_element.file.size,
        });

        let that = this;
        var img = new Image();
        img.onload = function () {
            that.handleUpdateInfo({
                type: library_element.file.type,
                size: library_element.file.size,
                width: this.width,
                height: this.height,
            });
        };
        this.setState({ processing: true });
        img.src = library_element.downloadURL;

        // read data url
        this.readRemoteURLasDataURL(library_element.downloadURL);

        // // Call onChange callback
        // this.callOnChangeHandler();
    };

    // handleRemoveFile = (_evt) => {
    //     console.info("handleRemoveFile _evt", _evt);
    // };

    // savePreviewRef = (preview) => (this.preview = preview);

    recalculateRestrictions = (_evt) => {
        console.info(
            "recalculateRestrictions this.state.restrictions",
            this.state.restrictions
        );

        let { restrictions, restriction_message } = this.state;
        restriction_message = null;

        // calculate restrictions aspect ration
        const restriction_aspect = Number(
            restrictions.width / restrictions.height
        ).toFixed(2);
        const info_apsect = Number(
            this.state.info.width / this.state.info.height
        ).toFixed(2);

        console.info(
            "recalculateRestrictions restriction_aspect",
            restriction_aspect,
            "info_apsect",
            info_apsect
        );

        if (
            // if(Math.abs(lineNumber - startLineNumber) <= 2)
            restriction_aspect !== info_apsect
        ) {
            restriction_message =
                "Wrong proportion. Please crop with the button";
        }

        this.setState({ restriction_message, aspect: restriction_aspect });
    };

    handleCropOpen = (_evt) => {
        console.info("handleCropOpen");

        const noncropped_image_data = this.state.noncropped_image_data
            ? this.state.noncropped_image_data
            : this.state.image_data;

        this.setState({
            crop_modal_visible: true,
            crop: {
                width: this.state.restrictions.width,
                height: this.state.restrictions.height,
            },
            noncropped_image_data,
        });
    };
    handleCropClose = (_evt) => {
        console.info("handleCropOpen");
        this.setState({ crop_modal_visible: false });
    };

    handleCropApply = (_evt) => {
        console.info(
            "handleCropApply this.temp_croppedImageUrl=" +
                this.temp_croppedImageUrl
        );

        this.setState({
            cropped: true,
            crop_modal_visible: false,
            croppedImageUrl: this.temp_croppedImageUrl,
        });

        let that = this;
        var img = new Image();
        img.onload = function () {
            that.handleUpdateInfo({
                width: this.width,
                height: this.height,
            });
        };
        this.setState({ processing: true });
        img.src = this.temp_croppedImageUrl;

        //read data url
        this.readRemoteURLasDataURL(this.temp_croppedImageUrl);

        // // Call onChange callback
        // this.callOnChangeHandler();
    };

    handleCroppingImage = (_cropped_image) => {
        //console.info("handleCroppingImage _cropped_image", _cropped_image);
        this.temp_croppedImageUrl = _cropped_image;
        //this.setState({ temp_croppedImageUrl: _cropped_image });
    };

    callOnChangeHandler = () => {
        if (typeof this.props.onChange === "function") {
            let that = this;
            setTimeout(function () {
                const {
                    changed,
                    file,
                    fileList,
                    type,
                    library,
                    previewURL,
                    info,
                    image_data,
                } = that.state;
                const info_obj = JSON.parse(
                    JSON.stringify({
                        changed,
                        file,
                        fileList,
                        type,
                        library: library ? { key: library.key } : null,
                        previewURL,
                        info,
                        image_data,
                    })
                );
                that.props.onChange(info_obj);
            }, 200);
        }
    };

    render() {
        return (
            <div style={style_Holder}>
                {/* <h3>FilePicker</h3>
                <br /> */}
                <Spin
                    tip="Processing..."
                    indicator={antIcon}
                    spinning={this.state.processing}
                >
                    <Row
                        type="flex"
                        align="top"
                        style={{ alignItems: "stretch" }}
                    >
                        <Col style={{ flexGrow: 1 }}>
                            <Row>
                                <Col span={12}>
                                    <Upload
                                        name="template_file"
                                        beforeUpload={(file) => false}
                                        showUploadList={true}
                                        onChange={this.handleUpload}
                                        multiple={this.state.multiple}
                                        accept="image/jpeg,image/png,image/gif,video/mp4"
                                        disabled={this.state.type === "library"}
                                        // onRemove={this.handleRemoveFile}
                                    >
                                        <Tooltip title="Upload a file from the computer">
                                            <Button
                                                disabled={
                                                    (this.state.type ===
                                                        "file" &&
                                                        this.state.fileList
                                                            .length > 0) ||
                                                    this.state.type ===
                                                        "library"
                                                }
                                                style={{
                                                    width: 160,
                                                    textAlign: "left",
                                                }}
                                            >
                                                <Icon
                                                    type="upload"
                                                    style={{
                                                        marginRight: 8,
                                                    }}
                                                />{" "}
                                                Upload File ...
                                            </Button>
                                        </Tooltip>
                                    </Upload>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <LibraryPickup
                                        store={this.props.store}
                                        disabled={this.state.type === "file"}
                                        multiple={
                                            this.state.multiple
                                                ? this.state.multiple
                                                : null
                                        }
                                        onSelectedChange={
                                            this.handleChangeLibrarySelected
                                        }
                                        selected={
                                            this.state.library
                                                ? [this.state.library]
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.type === "file" &&
                                    this.state.existing ? (
                                        <Row>
                                            <Col>
                                                <Checkbox
                                                //defaultChecked={this.state.newad_delete_image}
                                                >
                                                    Delete file
                                                </Checkbox>
                                            </Col>
                                        </Row>
                                    ) : null}

                                    {!this.props.nopreview &&
                                    this.state.previewURL.length > 0 ? (
                                        <Col
                                            style={{
                                                width: 200,
                                                // textAlign: "right",
                                            }}
                                        >
                                            <Fragment>
                                                {/* <SocialSeparator>Preview</SocialSeparator> */}
                                                <Tooltip title="Preview of the image">
                                                    <img
                                                        // ref={this.savePreviewRef}
                                                        src={
                                                            !this.state.cropped
                                                                ? this.state
                                                                      .previewURL
                                                                : this.state
                                                                      .croppedImageUrl
                                                        }
                                                        style={{
                                                            height: 100,
                                                            padding: 5,
                                                            border: "1px solid #ccc",
                                                            borderRadius: 5,
                                                        }}
                                                        alt={""}
                                                    />
                                                </Tooltip>
                                            </Fragment>
                                        </Col>
                                    ) : null}

                                    {this.state.previewURL.length > 0 &&
                                    this.state.type !== null ? (
                                        <Row type="flex">
                                            <Col
                                                style={{
                                                    flexGrow: 1,
                                                    marginTop: 5,
                                                }}
                                            >
                                                <Alert
                                                    type="info"
                                                    banner
                                                    style={{ fontSize: 11 }}
                                                    message={
                                                        "Image size:" +
                                                        this.state.info.width +
                                                        " x " +
                                                        this.state.info.height +
                                                        " | Type: " +
                                                        this.state.info.type +
                                                        " | Cropped: " +
                                                        this.state.cropped
                                                    }
                                                />

                                                {this.state
                                                    .restriction_message !==
                                                null ? (
                                                    <Alert
                                                        type="warning"
                                                        style={{
                                                            marginTop: 5,
                                                            fontSize: 11,
                                                            display:
                                                                "inline-block",
                                                        }}
                                                        message={
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    justifyContent:
                                                                        "space-between",
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .restriction_message
                                                                }
                                                            </div>
                                                        }
                                                        banner
                                                    />
                                                ) : null}

                                                {this.state.previewURL !==
                                                "" ? (
                                                    <Tooltip title="Crop the image">
                                                        <Button
                                                            size={"small"}
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                                fontSize: 11,
                                                                bottom: 4,
                                                                right: 4,
                                                            }}
                                                            onClick={
                                                                this
                                                                    .handleCropOpen
                                                            }
                                                        >
                                                            Crop
                                                        </Button>
                                                    </Tooltip>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Spin>

                <Fragment>
                    {this.state.crop_modal_visible ? (
                        <Modal
                            visible={true}
                            width={"50vw"}
                            centered
                            icon="form"
                            title={"Crop"}
                            okText={"OK"}
                            onCancel={this.handleCropClose}
                            onOk={this.handleCropApply} //onApply
                            maskClosable={false}
                            // width="80vw"
                        >
                            <CropModal
                                store={this.props.store}
                                aspect={this.state.aspect}
                                image_data={
                                    this.state.noncropped_image_data
                                        ? this.state.noncropped_image_data
                                        : this.state.image_data
                                }
                                handleCroppingImage={this.handleCroppingImage}
                            />
                        </Modal>
                    ) : null}
                </Fragment>
                {/* 
                <pre>{JSON.stringify(this.state, null, 2)}</pre>
                <pre>{JSON.stringify(this.preview, null, 2)}</pre> */}
            </div>
        );
    }
}

export default FilePicker;
