import React, { Fragment } from "react";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Button,
    Divider,
    Typography,
    Card,
    Tag,
    Breadcrumb,
} from "antd";

import scss_variables from "./../../_variables.scss";
import moment from "moment";
import names from "./../../names";

const { Panel } = Collapse;
const { Title, Text } = Typography;

const GRID_GUTTER = 40;

const ReportResultMasterpostMonthlyBasic = (props) => {
    // -------------------------

    const masterpost = props.results_separated;

    //if (!masterpost) return null;
    console.info("ReportResult_MasterpostMonthlyBasic masterpost", masterpost);

    const categories =
        masterpost &&
        masterpost.categories &&
        masterpost.categories.map((category) => {
            return (
                <Tag
                    key={category}
                    color={props.store.getTagColorForType("category")}
                >
                    {category}
                </Tag>
            );
        });

    const masterpostProgress = masterpost
        ? (
              masterpost.posts.filter((p) => p.status === "posted").length /
              masterpost.posts.length
          ).toFixed(2)
        : 0;

    const progressPanel = (
        <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
            <Col span={8}>
                <Statistic
                    title={"Progress"}
                    value={masterpostProgress * 100}
                    suffix={"%"}
                />
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
                <Statistic
                    title={"Posted"}
                    value={
                        masterpost &&
                        masterpost.posts.filter((p) => p.status === "posted")
                            .length
                    }
                    suffix={"posts"}
                />
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
                <Statistic
                    title={"Upcoming"}
                    value={
                        masterpost &&
                        masterpost.posts.filter((p) => p.status === "upcoming")
                            .length
                    }
                    suffix={"posts"}
                />
            </Col>
        </Row>
    );

    const postsPanel =
        masterpost &&
        masterpost.posts.map((post, postIndex) => (
            <Fragment key={postIndex}>
                <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                    <Col span={8}>
                        <strong>{post.account.name}</strong>
                        <br />
                        {post.location_info ? (
                            <Fragment>
                                <Icon
                                    type="home"
                                    style={{
                                        marginRight: 5,
                                        float: "left",
                                        marginTop: 3,
                                    }}
                                />
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <span>{post.location_info.city}</span>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <span>
                                            {post.location_info.state_ansi}
                                        </span>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Fragment>
                        ) : null}
                    </Col>
                    <Col span={8} style={{ textAlign: "left" }}>
                        <strong>
                            {moment(post.posted_on)
                                .utc()
                                .local()
                                .format("llll")}
                        </strong>
                    </Col>
                    <Col span={8} style={{ textAlign: "left" }}>
                        {post.results &&
                            post.results.map((result, resultIndex) => {
                                const comments_length =
                                    result.insights && result.insights.comments
                                        ? result.insights.comments.data.length
                                        : 0;

                                const likes =
                                    result.insights &&
                                    result.insights.insights.data.filter(
                                        (d) =>
                                            d.name ===
                                            "post_reactions_like_total"
                                    );
                                const likes_length =
                                    likes && likes[0]
                                        ? likes[0].values[0].value
                                        : 0;

                                const impressions =
                                    result.insights &&
                                    result.insights.insights.data.filter(
                                        (d) => d.name === "post_impressions"
                                    );
                                const impressions_length =
                                    impressions && impressions[0]
                                        ? impressions[0].values[0].value
                                        : 0;

                                return (
                                    <div
                                        key={resultIndex}
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            <Icon
                                                type={result.type}
                                                theme="filled"
                                                style={{
                                                    fontSize: 15,
                                                    color: props.store.getTagColorForType(
                                                        result.type
                                                    ),
                                                    marginRight: 5,
                                                    display: "inline-block",
                                                }}
                                            />
                                            <Text>{result.channel_name}</Text>
                                        </div>
                                        <div
                                            style={{
                                                float: "right",
                                                marginLeft: 5,
                                                marginTop: 3,
                                                // border: "1px solid red",
                                                display: "flex",
                                                alignSelf: "flex-end",
                                                flexGrow: 1,
                                                justifyContent: "flex-end",
                                                cursor: "default",
                                            }}
                                        >
                                            <div
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                <Tooltip title="Comments">
                                                    <Icon
                                                        type="unordered-list"
                                                        style={{
                                                            fontSize: 15,
                                                            marginLeft: 10,
                                                            marginRight: 2,
                                                        }}
                                                    />
                                                    {comments_length}
                                                </Tooltip>
                                            </div>

                                            <div
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                <Tooltip title="Likes">
                                                    <Icon
                                                        type="like"
                                                        style={{
                                                            fontSize: 15,
                                                            marginLeft: 10,
                                                            marginRight: 2,
                                                        }}
                                                    />
                                                    {likes_length}
                                                </Tooltip>
                                            </div>
                                            <div
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                <Tooltip title="Impressions">
                                                    <Icon
                                                        type="eye"
                                                        style={{
                                                            fontSize: 15,
                                                            marginLeft: 10,
                                                            marginRight: 2,
                                                        }}
                                                    />
                                                    {impressions_length}
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </Col>
                </Row>
                <Divider style={{ marginTop: 15, marginBottom: 10 }} />
            </Fragment>
        ));

    // --------------------------------------

    const body = props.loading ? (
        <Fragment>
            <div style={{ clear: "both", paddingTop: 20 }}></div>
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
            <div style={{ clear: "both", paddingTop: 20 }}></div>
            <Row>
                <Col span={8}>
                    Name:
                    <Title level={4} style={{ marginBottom: 0 }}>
                        {masterpost.name}
                    </Title>
                </Col>
                <Col span={8}>
                    Scheduled date:
                    <Title level={4} style={{ marginBottom: 0 }}>
                        {moment(masterpost.scheduled_date).utc().format("llll")}
                    </Title>
                </Col>
                <Col span={8}>
                    Categories:
                    <br />
                    {categories}
                </Col>
            </Row>
            <br />
            <Divider />
            <br />
            <Collapse
                bordered={false}
                defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
            >
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("targets") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Targets in this{" "}
                                {names.get("MASTERPOST", false, true)}
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                // marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "targets"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "targets",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        style={{ background: "white" }}
                        key="1"
                    >
                        <div style={{ padding: "10px 20px 10px 40px" }}>
                            {props.custom_sections.indexOf("targets") !== -1 &&
                                masterpost &&
                                masterpost.targets &&
                                masterpost.targets.map(
                                    (target, targetIndex) => {
                                        //   const resultChannel = rc.split("|");
                                        return (
                                            <Card
                                                key={targetIndex}
                                                title={
                                                    <Fragment>
                                                        <Row>
                                                            <Col
                                                                span={16}
                                                                style={{
                                                                    overflow:
                                                                        "hidden",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                }}
                                                            >
                                                                {target.name}
                                                            </Col>
                                                            <Col
                                                                span={8}
                                                                style={{
                                                                    textAlign:
                                                                        "right",
                                                                }}
                                                            >
                                                                {target.social_type_facebook && (
                                                                    <Icon
                                                                        type="facebook"
                                                                        theme="filled"
                                                                        style={{
                                                                            fontSize: 20,
                                                                            color: props.store.getTagColorForType(
                                                                                "facebook"
                                                                            ),
                                                                            marginLeft: 5,
                                                                        }}
                                                                    />
                                                                )}
                                                                {target.social_type_instagram && (
                                                                    <Icon
                                                                        type="instagram"
                                                                        theme="filled"
                                                                        style={{
                                                                            fontSize: 20,
                                                                            color: props.store.getTagColorForType(
                                                                                "instagram"
                                                                            ),
                                                                            marginLeft: 5,
                                                                        }}
                                                                    />
                                                                )}
                                                                {target.social_type_twitter && (
                                                                    <Icon
                                                                        type="twitter"
                                                                        style={{
                                                                            fontSize: 19,
                                                                            color: props.store.getTagColorForType(
                                                                                "twitter"
                                                                            ),
                                                                            marginLeft: 5,
                                                                        }}
                                                                    />
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Fragment>
                                                }
                                                bordered={true}
                                                cover={true}
                                                style={{
                                                    width: 200,
                                                    float: "left",
                                                    marginRight: 10,
                                                    marginBottom: 10,
                                                    // height: 320,
                                                    borderRadius: 5,
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        marginTop: -23,
                                                        marginLeft: -24,
                                                    }}
                                                >
                                                    {target.template_file &&
                                                    target.template_file.url ? (
                                                        <img
                                                            src={
                                                                target
                                                                    .template_file
                                                                    .url
                                                            }
                                                            style={{
                                                                width: 199,
                                                                marginBottom: 10,
                                                            }}
                                                            alt=""
                                                        />
                                                    ) : null}
                                                    <br />
                                                    <span
                                                        style={{ padding: 10 }}
                                                    >
                                                        {target.template_text}
                                                    </span>
                                                </p>
                                            </Card>
                                        );
                                    }
                                )}
                        </div>
                    </Panel>
                ) : null}
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("progress") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Progress
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                // marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "progress"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "progress",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        style={{ background: "white" }}
                        key="2"
                    >
                        <div style={{ padding: "10px 20px 10px 40px" }}>
                            {progressPanel}
                        </div>
                    </Panel>
                ) : null}
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("posts") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Posts
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                // marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "posts"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "posts",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        style={{ background: "white" }}
                        key="3"
                    >
                        <div style={{ padding: "10px 20px 10px 40px" }}>
                            {postsPanel}
                        </div>
                    </Panel>
                ) : null}
            </Collapse>
        </Fragment>
    );

    return body;
};

export default ReportResultMasterpostMonthlyBasic;
