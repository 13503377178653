import React, { Component, Fragment } from "react";
import { Tag, Tabs, Modal, Row, Col, Form, Icon, Alert } from "antd";
import { observer } from "mobx-react";
import SocialPreview from "./../SocialPreview";

import moment from "moment";

const { TabPane } = Tabs;

/* Wrapper component */
class AccountsQueueModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    async componentDidMount() {
        this.props.store.registerModalListener(
            "accounts_queue_modal",
            (_action) => {
                this.loadInformation();
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    }

    loadInformation = async () => {
        console.info("async loadInformation this.props", this.props);
        this.setState({ post: this.props.post });

        await this.props.store
            .getTargetByQueuePost(this.props.post)
            .then((target_data) => {
                this.setState({
                    target: target_data,
                    account: this.props.account,
                });
                // Chain the promise
                return this.props.store.getParsedPost(
                    this.props.account.key,
                    this.props.post.post_text,
                    this.props.post.channel_id
                );
            })
            .then((parsedPost) => {
                var statePost = { ...this.state.post };
                statePost.post_text_parsed = parsedPost.post_text_parsed;
                this.setState({ post: statePost });
            })
            .then(async () => {
                const channels = await this.props.store.getAccountChannels(
                    this.props.account.key
                );
                console.info("reloadAccount channels", channels);
                this.setState({ channels });
            })
            .catch((err) => console.error(err));
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("accounts_queue_modal");
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "accounts_queue_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    tabsCallback(key) {
        console.info("tabsCallback key", key);
    }

    render() {
        if (this.state.modal_visible === true) {
            // load the information
        }

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Post Information"}
                        onCancel={this.onModalCancel}
                        maskClosable={false}
                        footer={null}
                    >
                        {/* <pre>
                            {JSON.stringify(this.state.channels, null, 2)}
                        </pre> */}
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <Row>
                            <Col span={10}>
                                <Form.Item
                                    label={"Status"}
                                    style={{ padding: 0 }}
                                >
                                    <div
                                        style={{
                                            lineHeight: 1.6,
                                        }}
                                    >
                                        <Tag
                                            color={this.props.store.getTagColorForType(
                                                "neutral"
                                            )}
                                        >
                                            {this.state.post &&
                                                this.state.post.status}
                                        </Tag>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label={"Channels"}
                                    style={{
                                        padding: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            lineHeight: 1.6,
                                        }}
                                    >
                                        {this.state.post.channels_ids &&
                                            this.state.post.channels_ids &&
                                            this.state.post.channels_ids.map(
                                                (channel_id) => {
                                                    const channel =
                                                        this.state.channels &&
                                                        this.state.channels.filter(
                                                            (channel) =>
                                                                channel.id ===
                                                                channel_id
                                                        )[0];
                                                    return channel ? (
                                                        <Fragment
                                                            key={channel_id}
                                                        >
                                                            <Tag
                                                                color={this.props.store.getTagColorForType(
                                                                    channel.type
                                                                )}
                                                            >
                                                                <Icon
                                                                    type={
                                                                        channel.type
                                                                    }
                                                                    theme="filled"
                                                                    style={{
                                                                        marginRight: 5,
                                                                    }}
                                                                />
                                                                {channel.name}
                                                            </Tag>
                                                            <br />
                                                        </Fragment>
                                                    ) : null;
                                                }
                                            )}
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                {this.state.post &&
                                this.state.post.edited_at ? (
                                    <Tag
                                        color={this.props.store.getTagColorForType(
                                            "neutral"
                                        )}
                                    >
                                        Edited at{" "}
                                        {this.state.post &&
                                            moment(this.state.post.edited_at)
                                                .local()
                                                .format("LLLL")}
                                    </Tag>
                                ) : null}
                            </Col>
                        </Row>

                        <br />
                        <Tabs defaultActiveKey="1" onChange={this.tabsCallback}>
                            <TabPane tab="Preview" key="1">
                                <SocialPreview
                                    body={
                                        this.state.post &&
                                        this.state.post.post_text_parsed
                                    }
                                    image_url={
                                        this.state.post &&
                                        this.state.post.post_file &&
                                        this.state.post.post_file.url
                                    }
                                    hideTabs={true}
                                    defaultTab={"facebook"}
                                ></SocialPreview>
                            </TabPane>
                            <TabPane
                                tab="Insights"
                                key="2"
                                disabled={
                                    this.state.post &&
                                    this.state.post.status === "upcoming"
                                }
                            >
                                In development ...
                            </TabPane>
                        </Tabs>
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AccountsQueueModal);
