import React, { Fragment } from "react";
import { Icon, Breadcrumb } from "antd";

const SocialChannel = (props) => {
    const { channel, extra } = props;

    const general_style = {
        // maxWidth: 130,
        textOverflow: "ellipsis",
        overflow: "hidden",
        // fontSize: 13,
        fontWeight: 100,
        padding: "0px 5px 0px 3px",
        borderRadius: 3,
        whiteSpace: "nowrap",
        marginBottom: -4,
        cursor: "pointer",
        display: "grid",
        alignItems: "center",
        marginTop: -1,
        gridTemplateRows: "auto",
        gridTemplateColumns: "19px auto",
        "&:hover": {
            background: "#efefef",
        },
    };

    const name_style = {
        fontWeight: 500,
        paddingTop: 1,
    };
    const location_style = {
        paddingLeft: 18,
        display: "block",
        gridColumn: "1 / 3",
    };
    const filled_style = {
        ...{
            backgroundColor: props.store.getTagColorForType(channel.type),
            color: "white",
            fontSize: 12,
        },
        ...general_style,
    };
    const normal_style = {
        ...{
            // color: props.store.getTagColorForType(channel.type),
            color: "#666",
        },
        ...general_style,
    };

    const icon =
        props.icon && props.icon !== false ? (
            <div
                className={`social_iconCircle_${channel.type}`}
                style={{
                    marginRight: 5,
                    width: 13,
                    height: 13,
                    display: "inline-block",
                    marginBottom: -2,
                }}
            />
        ) : null;

    const style = {
        ...(props.type === "filled" ? filled_style : normal_style),
        ...props.style,
    };
    // patch for links
    if (channel.type === "twitter" && !channel.link)
        channel.link = `https://twitter.com/intent/user?user_id=${channel.id}`;

    if (channel.type === "linkedin" && !channel.link)
        channel.link = `http://www.linkedin.com/in/${
            channel.profile &&
            channel.profile.vanityName &&
            channel.profile.vanityName
        }`;
    console.log("SocialChannel extra", extra);
    // if (channel.type === "linkedin") console.info("CHANNEL", channel);
    return (
        <a
            style={style}
            href={channel.link}
            target="_blank"
            rel="noopener noreferrer"
            className={"social_Channel"}
        >
            {icon}
            <span style={name_style}>{channel.name}</span>
            {channel.location || extra ? (
                <span style={location_style}>
                    {extra ? extra : channel.location.join(", ")}
                </span>
            ) : null}
        </a>
    );
};

export default SocialChannel;
