import React, { Component, Fragment } from "react";
import {
    Button,
    Form,
    Modal,
    Tooltip,
    Row,
    Col,
    Input,
    Upload,
    Icon,
    Progress,
    Checkbox,
    Select,
    DatePicker,
    Radio,
    Cascader,
    Badge,
    Tree,
    Tabs,
    Divider,
    message,
    Result,
    AutoComplete,
    Dropdown,
    Menu,
    Alert,
    Tag,
    Typography,
} from "antd";

import SocialPreview from "../SocialPreview";
import EditableTagGroup from "../EditableTagGroup";
import LibraryPickup from "../Library/LibraryPickup";
import FilePicker from "../FilePicker";
import MasterpostsListModalInstagramTagging from "./MasterpostsListModalInstagramTagging";
import MasterpostsListModalVideoThumbnail from "./MasterpostListModalVideoThumbnail";
import {
    social_batchCreateMasterpostWithTargets,
    social_batchUpdateMasterpostWithTargets,
    social_notification,
    social_checkMasterpostValidity,
    social_sleep,
} from "../../functions";

import moment from "moment";
import { observer } from "mobx-react";
import shortid from "shortid";
import { base, firebase } from "../../base";
import names from "../../names";

const { TextArea, Search } = Input;
const { Option } = Select;
const { TreeNode } = Tree;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Text } = Typography;

/* masterpostsListModal Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                channels_affected: [],
                library_file: null,
                checkedKeys: [],
                selectedKeys: [],
                rightTabsOpenKey: "preview",
                masterpost_campaigns: [],
                masterpost_name: "",
                masterpost_note: "",
                masterpost_publish_type: "immediately",
                masterpost_publish_local_time: false,
                masterpost_scheduled_date: moment(),
                masterpost_categories: [],
                masterpost_brand: "",
                // -----------------
                targets: [
                    {
                        selection_type: "manual",
                        rules_custom: true,
                        name: "Target 1",
                        template_text: "Post Text",
                        social_type_facebook: true,
                        social_type_instagram: true,
                        social_type_twitter: true,
                        social_type_linkedin: true,
                        social_type_youtube: true,
                        location: [],
                    },
                ],
                selected_target_index: "targets_0",
            };
        }

        componentDidMount = async () => {
            // console.info(
            //     "MasterpostsListModalNew -> componentDidMount this.props.data",
            //     this.props.data
            // );

            // Process data
            this.parsePropsData();
        };

        parsePropsData = async () => {
            if (this.props.data) {
                let new_state = Object.assign(this.state, this.props.data);
                new_state["key"] = this.props.data.key;
                new_state["masterpost_campaigns"] = this.props.data.campaigns;
                new_state["masterpost_name"] = this.props.data.name;
                new_state["masterpost_note"] = this.props.data.note;
                if (this.props.data.publish_type) {
                    new_state["masterpost_publish_type"] =
                        this.props.data.publish_type;
                }
                new_state["masterpost_publish_local_time"] =
                    this.props.data.publish_local_time;
                new_state["masterpost_categories"] = this.props.data.categories;
                new_state["masterpost_brand"] = this.props.data.brand;
                if (this.props.data.scheduled_date) {
                    new_state["masterpost_scheduled_date"] =
                        this.props.data.scheduled_date;
                }

                // Go over the targets
                new_state.targets.map((target, tIndex) => {
                    // Uploaded image
                    if (
                        target.template_file &&
                        target.template_file.url &&
                        !target.template_file.library_file
                    ) {
                        target.previewURL = target.template_file.url;
                        target.changed_template_file = true;
                        target.template_fileList = [
                            // JSON.parse(JSON.stringify(target.template_file)),
                        ];
                    }

                    console.log(
                        "Array.isArray(target.template_file) = " +
                            Array.isArray(target.template_file)
                    );
                    console.log("target.template_file", target.template_file);

                    // Library file
                    if (
                        target.template_file &&
                        target.template_file.library_file
                    ) {
                        if (Array.isArray(target.template_file) === false) {
                            target.library_file =
                                this.props.store.getAllLibrary.filter(
                                    (l) =>
                                        l.key ===
                                        target.template_file.library_key
                                )[0];
                            target.previewURL = target.library_file.downloadURL;
                            target.changed_template_file = true;
                        }
                    }

                    if (
                        target.template_file &&
                        Array.isArray(target.template_file)
                    ) {
                        // Array
                        let lfArray = [];
                        target.template_file.map((tf) => {
                            let tlf = this.props.store.getAllLibrary.filter(
                                (l) => l.key === tf.library_key
                            )[0];

                            lfArray.push(tlf);
                        });
                        target.library_file = lfArray;
                        target.previewURL = lfArray[0].downloadURL;
                        target.changed_template_file = true;
                    }

                    console.log(
                        "PREVIEW URL = target.previewURL > " + target.previewURL
                    );

                    // Custom selection
                    target.selection_type = "manual"; // general
                    if (target.custom && target.custom.length > 0) {
                        console.log(
                            "MasterpostsListModalNew WE HAVE CUSTOM FOR TARGET"
                        );
                        target.rules_custom = true;
                        target.selection_type = "manual";
                        target.checkedKeys = [];
                        target.custom.map((c) => {
                            console.log("MasterpostsListModalNew CUSTOM=" + c);
                            target.checkedKeys.push(c.toString());
                            return false;
                        });
                    }

                    // Rules selection
                    if (
                        (target.rules_location &&
                            target.rules_location === true) ||
                        (target.rules_tags && target.rules_tags === true) ||
                        (target.include && target.include.length > 0)
                    ) {
                        target.selection_type = "rules";
                        target.location = [];
                    }

                    // Recalculate the target
                    // Recalculate Accounts Affected
                    //this.recalculateChannelsAffected(tIndex);
                    return false;
                });

                // console.info(
                //     "MasterpostsListModalNew -> componentDidUpdate new_state",
                //     new_state
                // );

                if (this.props.payload && this.props.payload.target_index) {
                    new_state["selected_target_index"] =
                        "targets_" + this.props.payload.target_index;
                }

                // console.info("new state", new_state);
                this.setState(new_state);

                setTimeout(
                    function (that) {
                        console.info(
                            "MasterpostsListModalNew THAT STATE",
                            that.state
                        );
                        that.state.targets.map((t, tIndex) => {
                            that.recalculateChannelsAffected(tIndex);
                            return false;
                        });
                    },
                    200,
                    this
                );
            }

            const all_channels = await this.props.store.getAllChannels();
            console.info("all_channels", all_channels);
            this.setState({
                all_channels,
            });
        };

        // componentDidUpdate(prevProps, prevState) {
        //     if (
        //         Object.keys(prevProps.data).length !==
        //         Object.keys(this.props.data).length
        //     ) {
        //         console.info(
        //             "MasterpostsListModalNew -> componentDidUpdate prevProps.data",
        //             prevProps.data,
        //             "this.props.data",
        //             this.props.data
        //         );

        //         let new_state = Object.assign(this.state, this.props.data);
        //         new_state["masterpost_campaigns"] = this.props.data.campaigns;
        //         new_state["masterpost_name"] = this.props.data.name;
        //         new_state["masterpost_note"] = this.props.data.note;
        //         new_state[
        //             "masterpost_publish_type"
        //         ] = this.props.data.publish_type;
        //         new_state[
        //             "masterpost_publish_local_time"
        //         ] = this.props.data.publish_local_time;
        //         new_state["masterpost_categories"] = this.props.data.categories;
        //         new_state["masterpost_brand"] = this.props.data.brand;
        //         console.info(
        //             "MasterpostsListModalNew -> componentDidUpdate new_state",
        //             new_state
        //         );
        //         this.setState(new_state);
        //     }
        // }

        async componentDidUpdate(prevProps, prevState) {
            //console.log("MASTERPOSTS LIST MODAL NEW componentDidUpdate");
            if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
                //console.log("componentDidUpdate");
                await this.checkMasterpostValidity();
            }
        }

        async checkMasterpostValidity() {
            console.log("checkMasterpostValidity ---- ");
            const valid = await social_checkMasterpostValidity({
                name: this.state.masterpost_name,
                targets: this.state.targets,
            });
            console.info("componentDidUpdate - valid = ", valid);
            if (typeof this.props.valid_callback === "function")
                this.props.valid_callback(valid);
        }

        handleCategoriesChange = (_categories) => {
            this.setState({ masterpost_categories: _categories });
        };
        handleCampaignsChange = (_campaigns) => {
            this.setState({ masterpost_campaigns: [_campaigns] });
        };
        handleBrandChange = (_brand) => {
            this.setState({ masterpost_brand: _brand });
        };

        handleEveryNumberChange = (_value) => {
            this.setState({ masterpost_every_number: _value });
        };

        handleEveryDropdownChange = (_value) => {
            this.setState({ masterpost_every_dropdown: _value });
        };

        handleScheduledDateChange = (_value) => {
            this.setState({ masterpost_scheduled_date: _value });
        };

        handlePublishLocalTimeChange = (_evt) => {
            this.setState({
                masterpost_publish_local_time: _evt.target.checked,
            });
        };

        onClose = (e) => {
            console.log(e, "I was closed.");
        };

        handleTagsChange = (_tIndex, _tags) => {
            console.info("handleTagsChange", _tags);

            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            curTarget.tags = _tags;

            this.setState({ targets });

            // Recalculate Channels Affected
            this.recalculateChannelsAffected(_tIndex);
        };

        handleRuleEnable = (_evt, _extra) => {
            console.info("handleRuleEnable", _evt, "_extra", _extra);
            //this.props.data = tags;
            let fieldname = `rules_${_extra}`;
            console.info("handleRuleEnable fieldname", fieldname);
            this.props.form.setFieldsValue({ fieldname: _evt.target.checked });
            //this.props.data["fieldname"] = _evt.target.checked;

            // Recalculate Channels Affected
            this.recalculateChannelsAffected();
        };
        handleAddLocation = async (_evt, _tIndex) => {
            console.info("handleAddLocation _evt", _evt, "_tIndex", _tIndex);

            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            if (!curTarget.location) curTarget.location = [];
            let curLocation = curTarget.location;
            const newLocation = ["New York", "New York"];
            curLocation.push(newLocation);
            //this.setState({ target_location: curLocation });
            curTarget.location = curLocation;
            curTarget.location_stringified = JSON.stringify(curLocation);

            this.setState({ targets });

            // Force a render with a simulated state change
            //this.setState({ state: this.state });
            // Recalculate Channels Affected
            this.recalculateChannelsAffected(_tIndex);

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        handleLocationChange = async (_location, _tIndex, _lIndex) => {
            console.info(
                "handleLocationChange _location",
                _location,
                "_tIndex",
                _tIndex,
                "_lIndex",
                _lIndex
            );
            //this.props.data = tags;

            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            let curLocation = curTarget.location;

            curLocation[_lIndex] = _location;
            curTarget.location_stringified = JSON.stringify(curLocation);

            this.setState({ targets });

            // Recalculate Channels Affected
            this.recalculateChannelsAffected(_tIndex);

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };
        handleLocationDelete = async (evt, _tIndex, _lIndex) => {
            console.log("handleLocationDelete _lIndex=" + _lIndex);

            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            let curLocation = curTarget.location;

            curLocation.splice(_lIndex, 1);
            curTarget.location_stringified = JSON.stringify(curLocation);

            this.setState({ targets });

            // Recalculate Channels Affected
            this.recalculateChannelsAffected(_tIndex);

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        handleUpload = async (_evt, _tIndex) => {
            console.info("handleUpload _evt", _evt, "_tIndex", _tIndex);
            //console.info("handleUpload this.props", this.props);

            let { targets } = this.state;
            let curTarget = targets[_tIndex];
            //console.info('handleTargetChange curTarget',curTarget);
            curTarget.template_file = _evt.file;
            curTarget.template_fileList = _evt.fileList;
            curTarget.changed_template_file = true;
            curTarget.previewURL = "";
            delete curTarget.temp_media_info;

            if (_evt.fileList.length === 1) {
                let reader = new FileReader();
                let preview_file = _evt.file;
                console.info("handleUpload preview_file", preview_file);
                reader.onloadend = async () => {
                    curTarget.previewURL = reader.result;
                    this.setState({ targets });
                    await social_sleep(100);
                    await this.checkMasterpostValidity();
                };
                reader.readAsDataURL(preview_file);
            } else {
                // clear the current file
                delete curTarget.template_file;
                delete curTarget.template_fileList;
                curTarget.changed_template_file = false;
                await social_sleep(100);
                await this.checkMasterpostValidity();
            }

            console.info("handleUpload curTarget", curTarget);
            this.setState({ targets });

            // await social_sleep(100);
            // await this.checkMasterpostValidity();
        };

        handleTemplateTextChange = (evt) => {
            // console.info(
            //     "handleTemplateTextChange evt.target",
            //     evt.target.value
            // );
            this.props.data.template_text = evt.target.value;
        };

        recalculateChannelsAffected = async (_tIndex, _extra) => {
            console.info(
                "MasterpostsListModalNew recalculateChannelsAffected _tIndex",
                _tIndex,
                "_extra",
                _extra
            );

            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            let rules = {
                social_type_facebook: curTarget.social_type_facebook,
                social_type_instagram: curTarget.social_type_instagram,
                social_type_twitter: curTarget.social_type_twitter,
                social_type_linkedin: curTarget.social_type_linkedin,
                social_type_youtube: curTarget.social_type_youtube,
                rules_location: curTarget.rules_location,
                rules_tags: curTarget.rules_tags,
                rules_custom: curTarget.rules_custom,
                tags: curTarget.tags,
                location: curTarget.location,
                exclude: curTarget.exclude,
                include: curTarget.include,
                custom:
                    _extra && _extra.custom
                        ? _extra.custom
                        : curTarget.custom
                        ? curTarget.custom
                        : [],
            };

            console.info(
                "MasterpostsListModalNew recalculateChannelsAffected rules",
                rules
            );
            // let [
            //     filtered_channels,
            //     filtered_accounts,
            // ] = await this.props.store.getChannelsByRules(rules);
            let results = await this.props.store.getChannelsByRules(rules);
            console.info(
                "MasterpostsListModalNew recalculateChannelsAffected results",
                results
            );

            curTarget.channels_affected = results;
            // curTarget.checkedKeys = filtered_accounts.map((fa) => {
            //     return fa.key;
            // });

            // Save the targets
            this.setState({ targets });
        };

        handleExcludeChange = async (_tIndex, _exclude) => {
            console.info("handleExcludeChange", _exclude);

            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            curTarget.exclude = _exclude;

            this.setState({ targets });

            // Recalculate Channels Affected
            this.recalculateChannelsAffected(_tIndex);

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        handleIncludeChange = async (_tIndex, _include) => {
            console.info("handleIncludeChange", _include);

            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            curTarget.include = _include;

            this.setState({ targets });

            // Recalculate Channels Affected
            this.recalculateChannelsAffected(_tIndex);

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        handleChangeLibrarySelected = async (_tIndex, _selected) => {
            console.info(
                "handleChangeLibrarySelected _tIndex",
                _tIndex,
                "_selected",
                _selected
            );

            let { targets } = this.state;
            let curTarget = targets[_tIndex];
            //console.info('handleTargetChange curTarget',curTarget);
            if (_selected.length === 1) {
                curTarget.library_file = _selected[0];
                curTarget.previewURL = _selected[0]
                    ? _selected[0].downloadURL
                    : "";
            } else if (_selected.length > 1) {
                curTarget.library_file = _selected;
                curTarget.previewURL = _selected[0]
                    ? _selected[0].downloadURL
                    : "";
            } else {
                curTarget.previewURL = "";
                delete curTarget.library_file;
                delete curTarget.template_file;
            }

            curTarget.changed_template_file = true;

            this.setState({ targets });

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        deleteTarget = async (_evt, _tIndex) => {
            if (_evt.preventDefault) _evt.preventDefault();
            if (_evt.stopPropagation) _evt.stopPropagation();

            const that = this;

            if (this.state.targets.length === 1) {
                Modal.warning({
                    title: "You should have at least one target",
                    content:
                        "Add additional targest and remove this one if you want",
                });
            } else {
                confirm({
                    title: "Do you want to delete this target?",
                    content: "This will destroy the target permanently",
                    type: "error",
                    onOk() {
                        let { targets } = that.state;
                        targets.splice(_tIndex, 1);
                        that.setState({ targets });
                    },
                    onCancel() {},
                });
            }

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        duplicateTarget = async (_evt, _tIndex) => {
            if (_evt.preventDefault) _evt.preventDefault();
            if (_evt.stopPropagation) _evt.stopPropagation();

            let { targets } = this.state;

            //const newTarget = Object.assign({}, targets[_tIndex]);
            const newTarget = JSON.parse(JSON.stringify(targets[_tIndex]));

            console.log(
                "duplicateTarget > typeof targets[_tIndex].template_file=" +
                    typeof targets[_tIndex].template_file
            );
            // copy the template_file
            if (typeof targets[_tIndex].template_file !== "undefined") {
                // copy the file object
                const originalTemplateFile = new File(
                    [targets[_tIndex].template_file],
                    targets[_tIndex].template_file.name
                );
                console.info("originalTemplateFile", originalTemplateFile);
                newTarget.template_file = originalTemplateFile;
            }
            //overwrite the shortid
            newTarget.shortid = shortid.generate();

            console.info("newTarget", newTarget);
            targets.push(newTarget);
            this.setState({
                targets,
                selected_target_index: "targets_" + Number(targets.length - 1),
            });

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        addTarget = async (_evt) => {
            console.info("addTarget _evt", _evt);
            _evt.stopPropagation();

            let { targets } = this.state;
            targets.push({
                name: "New Target",
                template_text: "Post Text",
                social_type_facebook: false,
                social_type_instagram: false,
                social_type_twitter: false,
                social_type_linkedin: false,
                social_type_youtube: false,
                selection_type: "rules",
                rules_custom: false,
                location: [],
                shortid: shortid.generate(),
            });

            this.setState({
                targets,
                selected_target_index: "targets_" + Number(targets.length - 1),
            });

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        renderTreeNodes = (data, _target) => {
            // remove nullish values
            data = data.filter((e) => e != null && e);
            //console.info("_target", _target);
            //console.info("renderTreeNodes data", data);
            //const all_types = ["facebook", "instagram", "twitter", "linkedin"];
            return data.map((item) => {
                if (item.children) {
                    let item_types = item.children
                        .map((child) => child.type)
                        .filter((value, index, self) => {
                            return self.indexOf(value) === index;
                        });
                    let item_checked_types = item.children
                        .map((child) =>
                            _target.custom && _target.custom.includes(child.key)
                                ? child.type
                                : null
                        )
                        .filter((value, index, self) => {
                            return (
                                self.indexOf(value) === index && value !== null
                            );
                        });
                    //console.info("item_checked_types", item_checked_types);
                    return (
                        <TreeNode
                            title={
                                <Fragment>
                                    {item.title}
                                    {item_types.map((type, typeIndex) => {
                                        return (
                                            <Icon
                                                key={typeIndex}
                                                type={type}
                                                theme={
                                                    type === "facebook" ||
                                                    type === "instagram" ||
                                                    type === "linkedin" ||
                                                    type === "youtube"
                                                        ? "filled"
                                                        : null
                                                }
                                                style={{
                                                    marginLeft: 5,
                                                    float: "right",
                                                    color: item_checked_types.includes(
                                                        type
                                                    )
                                                        ? this.props.store.getTagColorForType(
                                                              type
                                                          )
                                                        : "#ccc",
                                                }}
                                            />
                                        );
                                    })}
                                </Fragment>
                            }
                            key={item.key}
                            dataRef={item}
                            checkable={true}
                            className={"social_ChannelsTree"}
                        >
                            {this.renderTreeNodes(item.children, _target)}
                        </TreeNode>
                    );
                } else {
                    return (
                        <TreeNode
                            title={
                                <Fragment>
                                    <Icon
                                        type={item.type}
                                        theme={
                                            item.type === "facebook" ||
                                            item.type === "instagram" ||
                                            item.type === "linkedin" ||
                                            item.type === "youtube"
                                                ? "filled"
                                                : null
                                        }
                                        style={{
                                            marginRight: 5,
                                            color: this.props.store.getTagColorForType(
                                                item.type
                                            ),
                                        }}
                                    />
                                    {item.title}
                                </Fragment>
                            }
                            key={item.key}
                            checkable={true}
                            dataRef={item}
                        ></TreeNode>
                    );
                }
                //return <TreeNode key={item.key} {...item} />;
            });
        };

        onCheck = (t, tIndex, checkedKeys) => {
            console.log(
                "onCheck",
                checkedKeys,
                "t.rules_custom",
                t.rules_custom
            );
            // filter checkedKeys
            checkedKeys = checkedKeys.filter((key) => key.indexOf("|") !== -1);
            console.log("onCheck filtered checkedKeys", checkedKeys);
            if (t.rules_custom) {
                this.setState({ checkedKeys });
                t.custom = checkedKeys;
                //this.props.form.setFieldsValue({ custom: checkedKeys });

                this.recalculateChannelsAffected(tIndex, {
                    custom: checkedKeys,
                });
            } else {
                message.warning(
                    "You have to check the Custom selection checkbox first"
                );
            }
        };

        rightTabsCallback = (key) => {
            console.log(key);
            this.setState({ rightTabsOpenKey: key });
        };

        toggleChannel = async (_tIndex, channel) => {
            console.info("toggleChannel _tIndex", _tIndex, "channel", channel);

            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            switch (channel) {
                case "facebook":
                    curTarget.social_type_facebook =
                        !curTarget.social_type_facebook;
                    break;
                case "instagram":
                    curTarget.social_type_instagram =
                        !curTarget.social_type_instagram;
                    break;
                case "twitter":
                    curTarget.social_type_twitter =
                        !curTarget.social_type_twitter;
                    break;
                case "linkedin":
                    curTarget.social_type_linkedin =
                        !curTarget.social_type_linkedin;
                    break;
                case "youtube":
                    curTarget.social_type_youtube =
                        !curTarget.social_type_youtube;
                    break;
                default:
                    break;
            }

            this.recalculateChannelsAffected(_tIndex);

            // force the rerender
            this.setState({ targets });

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        collapseChange = (openKeys) => {
            console.info("collapseChange openKeys", openKeys);

            if (openKeys.length === 1 && openKeys[0] === "post") {
                // post
                this.setState({ rightTabsOpenKey: "preview" });
            }
            if (openKeys.length === 1 && openKeys[0] === "target") {
                // target
                this.setState({ rightTabsOpenKey: "accounts" });
            }
        };

        handleTargetChange = async (_evt, _tIndex, _field) => {
            // console.info(
            //     "handleTargetChange _evt",
            //     _evt,
            //     "_tIndex",
            //     _tIndex,
            //     "_field",
            //     _field
            // );
            let { targets } = this.state;
            let curTarget = targets[_tIndex];
            //console.info("handleTargetChange curTarget", curTarget);
            curTarget[_field] = _evt.target.value;

            if (_field === "rules_location") {
                curTarget.rules_location = _evt.target.checked;
            }
            if (_field === "rules_tags") {
                curTarget.rules_tags = _evt.target.checked;
            }

            if (_field === "selection_type" && _evt.target.checked) {
                curTarget.rules_custom = _evt.target.checked;
                curTarget.custom = [];
                curTarget.social_type_facebook = false;
                curTarget.social_type_instagram = false;
                curTarget.social_type_twitter = false;
                curTarget.social_type_linkedin = false;
                curTarget.social_type_youtube = false;
                if (_evt.target.value === "rules")
                    curTarget.rules_custom = false;
            }

            if (_field !== "template_text") {
                this.recalculateChannelsAffected(_tIndex);
            }
            //console.info("handleTargetChange curTarget", curTarget);
            this.setState({ targets });

            await social_sleep(100);
            await this.checkMasterpostValidity();
        };

        handleTargetTabChange = (_target_key) => {
            this.setState({ selected_target_index: _target_key });
        };

        handleOnCreate = (_evt) => {
            console.info("handleOnCreate _evt", _evt);
            return JSON.parse(JSON.stringify(this.state));
        };

        setManualFilter = (_tIndex, _value) => {
            let { targets } = this.state;
            let curTarget = targets[_tIndex];
            curTarget.manual_filter = _value;
            this.setState({ targets });
        };

        setChannelsAffectedFilter = (_tIndex, _value) => {
            console.info(
                "setChannelsAffectedFilter _tIndex",
                _tIndex,
                "_value",
                _value
            );
            let { targets } = this.state;
            let curTarget = targets[_tIndex];
            curTarget.channels_affected_filter = _value;
            this.setState({ targets });
        };

        openInstagramTagging = (_tIndex) => {
            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            curTarget.instagram_tags = curTarget.instagram_tags
                ? curTarget.instagram_tags
                : [{ x: 0, y: 0, username: "newUsername" }];

            this.props.store.showModalByKey(
                "masterposts_modal_instagramtagging",
                { curTarget, _tIndex }
            );
        };

        openVideoThumbnail = (_tIndex) => {
            let { targets } = this.state;
            let curTarget = targets[_tIndex];

            curTarget.video_thumbnail = curTarget.video_thumbnail
                ? curTarget.video_thumbnail
                : null;

            this.props.store.showModalByKey(
                "masterposts_modal_videothumbnail",
                { curTarget, _tIndex }
            );
        };

        handleTaggingUpdate = (_info) => {
            console.info("handleTaggingUpdate _info", _info);

            let { targets } = this.state;
            let curTarget = targets[_info.tIndex];
            curTarget.instagram_tags = _info.instagram_tags;
            this.setState({ targets });
        };

        handleVideoCoverUpdate = (_info) => {
            console.info("handleVideoCoverUpdate _info", _info);

            let { targets } = this.state;
            let curTarget = targets[_info.tIndex];
            curTarget.video_thumbnail = {
                thumbnail_library: _info.thumbnail_library,
                thumbnail_offset: _info.thumbnail_offset,
            };
            this.setState({ targets });
        };

        render() {
            const { form, processing, upload_percentage, store } = this.props;
            const { getFieldDecorator } = form;

            const tags = store.getAllTags;
            const locations = store.getLocations();

            //console.info('targets', data.targets);

            const tagsChildren = [];
            tags.map((i) => {
                tagsChildren.push(<Option key={i.tag}>{i.tag}</Option>);
                return false;
            });

            let excludeChildren = [],
                includeChildren = [];
            this.state.all_channels &&
                this.state.all_channels.map((channel) => {
                    excludeChildren.push(
                        <Option key={channel.account_key + "|" + channel.id}>
                            {channel.account_name} ({channel.name})
                        </Option>
                    );
                    includeChildren.push(
                        <Option key={channel.account_key + "|" + channel.id}>
                            {channel.account_name} ({channel.name})
                        </Option>
                    );
                    return false;
                });

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };

            const getAllAccounts = (_target) =>
                store.getAllAccounts.map((a) => {
                    //console.info("getAllAccounts _target", _target);
                    let accountData = {
                        title: `${a.name}`,
                        key: a.key,
                        name: a.name,
                        email: a.email,
                        children: [],
                    };
                    // Facebook
                    if (
                        _target.social_type_facebook &&
                        a.facebook &&
                        a.facebook.data &&
                        a.facebook.data.length > 0
                    ) {
                        accountData.children = accountData.children.concat(
                            a.facebook.data.map((d) => {
                                return {
                                    type: "facebook",
                                    title: d.name,
                                    key: a.key + "|" + d.id,
                                };
                            })
                        );
                    }

                    // Instagram
                    if (
                        _target.social_type_instagram &&
                        a.instagram &&
                        a.instagram.data &&
                        a.instagram.data.length > 0
                    ) {
                        accountData.children = accountData.children.concat(
                            a.instagram.data.map((d) => {
                                return {
                                    type: "instagram",
                                    title: d.name,
                                    key: a.key + "|" + d.id,
                                };
                            })
                        );
                    }

                    // Twitter
                    if (
                        _target.social_type_twitter &&
                        a.twitter &&
                        a.twitter.auth
                    ) {
                        accountData.children = accountData.children.concat({
                            type: "twitter",
                            title: a.twitter.auth.screen_name,
                            key: a.key + "|" + a.twitter.auth.user_id,
                        });
                    }

                    // Linkedin
                    if (
                        _target.social_type_linkedin &&
                        a.linkedin &&
                        a.linkedin.profile
                    ) {
                        accountData.children = accountData.children.concat({
                            type: "linkedin",
                            title:
                                a.linkedin.profile.localizedFirstName +
                                " " +
                                a.linkedin.profile.localizedLastName,
                            key: a.key + "|" + a.linkedin.profile.id,
                        });

                        if (a.linkedin.data && a.linkedin.data.length > 0) {
                            accountData.children = accountData.children.concat(
                                a.linkedin.data &&
                                    a.linkedin.data.map((d) => {
                                        return {
                                            type: "linkedin",
                                            title: d.localizedName,
                                            key: a.key + "|" + d.id,
                                        };
                                    })
                            );
                        }
                    }

                    // Youtube
                    if (
                        _target.social_type_youtube &&
                        a.google &&
                        a.google.data &&
                        a.google.data.length > 0
                    ) {
                        accountData.children = accountData.children.concat(
                            a.google.data.map((d) => {
                                return {
                                    type: "youtube",
                                    title: d.snippet.title,
                                    key: a.key + "|" + d.id,
                                };
                            })
                        );
                    }

                    // check for filtering
                    if (
                        _target.manual_filter &&
                        _target.manual_filter.length > 0
                    ) {
                        if (
                            accountData.title
                                .toUpperCase()
                                .indexOf(
                                    _target.manual_filter.toUpperCase()
                                ) !== -1 ||
                            accountData.children.filter(
                                (child) =>
                                    child.title
                                        .toUpperCase()
                                        .indexOf(
                                            _target.manual_filter.toUpperCase()
                                        ) !== -1
                            ).length > 0
                        ) {
                            // accountData.children = accountData.children.filter(
                            //     (child) =>
                            //         child.title
                            //             .toUpperCase()
                            //             .indexOf(
                            //                 _target.manual_filter.toUpperCase()
                            //             ) !== -1
                            // );
                            return accountData;
                        }
                    } else {
                        return accountData;
                    }
                    return false;
                });
            //console.info("getAllAccounts", getAllAccounts);

            const masterpostPanel = (
                <Fragment>
                    {/* <Alert
                        message="Informational Notes"
                        type="info"
                        style={{ border: 0 }}
                        showIcon
                    />
                    <br /> */}
                    <Row style={{ margin: 0 }}>
                        <Col span={12} style={{ paddingRight: 20 }}>
                            {/* <Form.Item label="Campaign">
                            {getFieldDecorator("masterpost_campaign", {
                                initialValue: this.state.masterpost_campaign,
                                onChange: value => {
                                    this.setState({
                                        masterpost_campaign: value
                                    });
                                }
                            })(
                                <AutoComplete
                                    dataSource={this.props.store.getAllCampaigns.map(
                                        campaign => campaign.name
                                    )}
                                    // style={{ width: 200 }}
                                    placeholder="input here"
                                />
                            )}
                        </Form.Item> */}
                            <Form.Item label="Name">
                                {getFieldDecorator("masterpost_name", {
                                    rules: [
                                        {
                                            required: true,
                                            message: `Please input the name of the ${names.get(
                                                "MASTERPOST"
                                            )}!`,
                                            type: "string",
                                        },
                                    ],
                                    initialValue: this.state.masterpost_name,
                                    onChange: (e) => {
                                        this.setState({
                                            masterpost_name: e.target.value,
                                        });
                                    },
                                })(
                                    <Input
                                        placeholder={`Name of ${names.get(
                                            "MASTERPOST"
                                        )}`}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                extra="*Not publically visible"
                            >
                                {getFieldDecorator("masterpost_note", {
                                    initialValue: this.state.masterpost_note,
                                    onChange: (e) => {
                                        this.setState({
                                            masterpost_note: e.target.value,
                                        });
                                    },
                                })(
                                    <Input
                                        placeholder={`Description of ${names.get(
                                            "MASTERPOST"
                                        )}`}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Brand">
                                {getFieldDecorator("brand", {
                                    initialValue: this.state.masterpost_brand,
                                })(
                                    <AutoComplete
                                        dataSource={
                                            this.props.store.getAllBrands
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Brand name"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Campaign">
                                {getFieldDecorator("masterpost_campaigns", {
                                    initialValue: this.state
                                        .masterpost_campaigns
                                        ? this.state.masterpost_campaigns[0]
                                        : "",
                                    onChange: this.handleCampaignsChange,
                                })(
                                    // <EditableTagGroup
                                    //     element_title="Campaigns"
                                    //     tag_color={this.props.store.getTagColorForType(
                                    //         "campaign"
                                    //     )}
                                    //     options={this.props.store.getAllCampaigns.map(
                                    //         (campaign) => campaign.campaign
                                    //     )}
                                    // />
                                    <AutoComplete
                                        dataSource={this.props.store.getAllCampaigns.map(
                                            (campaign) => campaign.campaign
                                        )}
                                        style={{ width: "100%" }}
                                        placeholder="Campaign name"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 20 }}>
                            <Form.Item
                                className="collection-create-form_last-form-item"
                                label="Publish"
                            >
                                {getFieldDecorator("masterpost_publish_type", {
                                    initialValue: this.state
                                        .masterpost_publish_type
                                        ? this.state.masterpost_publish_type
                                        : "immediately",
                                    onChange: (e) => {
                                        this.setState({
                                            masterpost_publish_type:
                                                e.target.value,
                                        });
                                    },
                                })(
                                    <Radio.Group
                                        style={{ paddingTop: 5, lineHeight: 2 }}
                                    >
                                        <Radio value="immediately">
                                            Immediately
                                        </Radio>
                                        <br />
                                        <Radio value="scheduled">
                                            Scheduled
                                        </Radio>

                                        {getFieldDecorator(
                                            "masterpost_scheduled_date",
                                            {
                                                initialValue: moment(
                                                    this.state
                                                        .masterpost_scheduled_date
                                                ),
                                            }
                                        )(
                                            <DatePicker
                                                disabled={
                                                    form.getFieldValue(
                                                        "masterpost_publish_type"
                                                    ) !== "scheduled"
                                                }
                                                showTime={true}
                                                onChange={
                                                    this
                                                        .handleScheduledDateChange
                                                }
                                                disabledDate={(current) =>
                                                    current &&
                                                    current <
                                                        moment().subtract(
                                                            1,
                                                            "days"
                                                        )
                                                }
                                            />
                                        )}
                                    </Radio.Group>
                                )}
                                <br />
                                {getFieldDecorator(
                                    "masterpost_publish_local_time",
                                    {
                                        initialValue:
                                            this.state
                                                .masterpost_publish_local_time,
                                    }
                                )(
                                    <Checkbox
                                        onChange={
                                            this.handlePublishLocalTimeChange
                                        }
                                        checked={
                                            this.state
                                                .masterpost_publish_local_time
                                        }
                                    >
                                        Publish in local time
                                        <span
                                            style={{
                                                fontSize: 13,
                                                lineHeight: 1,
                                                display: "block",
                                                color: "rgba(0, 0, 0, 0.45)",
                                            }}
                                        >
                                            Publish at the scheduled time in
                                            each channel`s local time zone
                                        </span>
                                    </Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item
                                label="Categories"
                                extra={`Add a Category for this ${names.get(
                                    "MASTERPOST"
                                )} (it applies to all Audience Sets)`}
                            >
                                {getFieldDecorator("masterpost_categories", {
                                    initialValue: this.state
                                        .masterpost_categories
                                        ? this.state.masterpost_categories
                                        : [],
                                    onChange: this.handleCategoriesChange,
                                })(
                                    <EditableTagGroup
                                        element_title="Category"
                                        tag_color={this.props.store.getTagColorForType(
                                            "category"
                                        )}
                                        options={this.props.store.getAllCategories.map(
                                            (category) => category.category
                                        )}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Fragment>
            );

            const ChannelsCustomSelection = (t, tIndex) => (
                <Fragment>
                    <Row>
                        <Col className={"social_filterChannelContainer"}>
                            <Search
                                placeholder="Filter by keyword"
                                onSearch={(value) =>
                                    this.setManualFilter(tIndex, value)
                                }
                                onChange={(e) =>
                                    this.setManualFilter(tIndex, e.target.value)
                                }
                            />
                            <div
                                style={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                }}
                                onClick={(e) =>
                                    this.toggleChannel(tIndex, "facebook")
                                }
                            >
                                <div
                                    className="social_iconCircle_facebook"
                                    style={{
                                        width: 18,
                                        height: 15,
                                        margin: 5,
                                        opacity: t.social_type_facebook
                                            ? 1
                                            : 0.3,
                                    }}
                                />
                                {/* <Icon
                                    type="facebook"
                                    theme="filled"
                                    style={{
                                        fontSize: 25,
                                        color: t.social_type_facebook
                                            ? store.getTagColorForType(
                                                  "facebook"
                                              )
                                            : "#ccc",
                                    }}
                                /> */}
                            </div>
                            <div
                                style={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                }}
                                onClick={(e) =>
                                    this.toggleChannel(tIndex, "instagram")
                                }
                            >
                                <div
                                    className="social_iconCircle_instagram"
                                    style={{
                                        width: 18,
                                        height: 15,
                                        margin: 5,
                                        opacity: t.social_type_instagram
                                            ? 1
                                            : 0.3,
                                    }}
                                />

                                {/* <Icon
                                    type="instagram"
                                    theme="filled"
                                    style={{
                                        fontSize: 25,
                                        color: t.social_type_instagram
                                            ? store.getTagColorForType(
                                                  "instagram"
                                              )
                                            : "#ccc",
                                        marginLeft: 5,
                                    }}
                                /> */}
                            </div>
                            <div
                                style={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                }}
                                onClick={(e) =>
                                    this.toggleChannel(tIndex, "twitter")
                                }
                            >
                                <div
                                    className="social_iconCircle_twitter"
                                    style={{
                                        width: 18,
                                        height: 15,
                                        margin: 5,
                                        opacity: t.social_type_twitter
                                            ? 1
                                            : 0.3,
                                    }}
                                />

                                {/* <Icon
                                    type="twitter"
                                    style={{
                                        fontSize: 25,
                                        color: t.social_type_twitter
                                            ? store.getTagColorForType(
                                                  "twitter"
                                              )
                                            : "#ccc",
                                        marginLeft: 5,
                                    }}
                                /> */}
                            </div>
                            <div
                                style={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                }}
                                onClick={(e) =>
                                    this.toggleChannel(tIndex, "linkedin")
                                }
                            >
                                <div
                                    className="social_iconCircle_linkedin"
                                    style={{
                                        width: 18,
                                        height: 15,
                                        margin: 5,
                                        opacity: t.social_type_linkedin
                                            ? 1
                                            : 0.3,
                                    }}
                                />

                                {/* <Icon
                                    type="linkedin"
                                    theme="filled"
                                    style={{
                                        fontSize: 25,
                                        color: t.social_type_linkedin
                                            ? store.getTagColorForType(
                                                  "linkedin"
                                              )
                                            : "#ccc",
                                        marginLeft: 5,
                                    }}
                                /> */}
                            </div>
                            <div
                                style={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                }}
                                onClick={(e) =>
                                    this.toggleChannel(tIndex, "youtube")
                                }
                            >
                                <div
                                    className="social_iconCircle_youtube"
                                    style={{
                                        width: 18,
                                        height: 15,
                                        margin: 5,
                                        opacity: t.social_type_youtube
                                            ? 1
                                            : 0.3,
                                    }}
                                />

                                {/* <Icon
                                    type="youtube"
                                    theme="filled"
                                    style={{
                                        fontSize: 25,
                                        color: t.social_type_youtube
                                            ? store.getTagColorForType(
                                                  "youtube"
                                              )
                                            : "#ccc",
                                        marginLeft: 5,
                                    }}
                                /> */}
                            </div>
                        </Col>
                    </Row>
                    {/* <pre>{JSON.stringify(getAllAccounts(t), null, 2)}</pre> */}
                    <Tree
                        checkable
                        autoExpandParent={true}
                        // onExpand={this.onExpand}
                        // expandedKeys={this.state.expandedKeys}
                        // autoExpandParent={this.state.autoExpandParent}
                        onCheck={(checkedKeys) =>
                            this.onCheck(t, tIndex, checkedKeys)
                        }
                        checkedKeys={t.custom}
                        style={{
                            // marginTop: -15,
                            height: 200,
                            overflowY: "auto",
                        }}
                    >
                        {this.renderTreeNodes(getAllAccounts(t), t)}
                    </Tree>
                </Fragment>
            );

            const TargetsPanel = this.state.targets.map((t, tIndex) => {
                return (
                    <TabPane
                        tab={
                            <Fragment key={tIndex}>
                                {tIndex + 1}
                                <Dropdown
                                    overlay={
                                        <Menu
                                            onClick={(e) => {
                                                switch (e.key) {
                                                    case "duplicate":
                                                        this.duplicateTarget(
                                                            e,
                                                            tIndex
                                                        );
                                                        break;
                                                    case "delete":
                                                        this.deleteTarget(
                                                            e,
                                                            tIndex
                                                        );
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <Menu.Item key="duplicate">
                                                <Icon type="copy" /> Duplicate
                                            </Menu.Item>
                                            <Menu.Item key="delete">
                                                <Icon type="delete" /> Delete
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    <Icon type="more" />
                                </Dropdown>
                            </Fragment>
                        }
                        key={"targets_" + tIndex}
                        // className="social_NewMasterpostTargetsContainer"
                    >
                        <Row gutter={20}>
                            <Col
                                span={12}
                                style={{
                                    paddingRight: 20,
                                    // borderRight: "1px solid #eee",
                                }}
                            >
                                {/* <pre>{JSON.stringify(t, null, 2)}</pre> */}
                                <Form.Item
                                    label="Name"
                                    extra="Name your Audience Set"
                                >
                                    <Input
                                        value={t.name}
                                        onChange={(e) => {
                                            return this.handleTargetChange(
                                                e,
                                                tIndex,
                                                "name"
                                            );
                                        }}
                                        placeholder="Name of Audience Set"
                                    />
                                    {/* {getFieldDecorator(
                                        "target_name",
                                        {
                                            rules: [
                                                {required: true,
                                                    message:
                                                    "Please input the name of the target",
                                                type: "string"}
                                            ],
                                            // initialValue:
                                            //     t.name,
                                            // onChange: e => {
                                            //     return this.handleTargetChange(e, tIndex, 'name');
                                            // }
                                        }
                                    )()} */}
                                </Form.Item>
                                <Form.Item
                                    label="Message"
                                    extra="Write the message to be sent to this Audience Set"
                                >
                                    <Tooltip
                                        title={`
                                        [*name] - the name of the Account;
                                        [*email] - the website of the Account;
                                        [*phone] - the phone of the Account;
                                        [*website] - the website of the Account;
                                        [*location] - the location of the Account;
                                        [*appendix] - the appendix of the Account;`}
                                    >
                                        <TextArea
                                            value={t.template_text}
                                            onChange={(e) =>
                                                this.handleTargetChange(
                                                    e,
                                                    tIndex,
                                                    "template_text"
                                                )
                                            }
                                            placeholder="Message text"
                                        />
                                        {/* {getFieldDecorator(
                                        "target_template_text",
                                        {
                                            // initialValue:
                                            //     data.template_text,
                                            // onChange: this
                                            //     .handleTemplateTextChange


                                                initialValue:
                                                t.template_text,


                                        }
                                    )()} */}
                                    </Tooltip>
                                </Form.Item>
                                <Form.Item
                                    label="Image/Video"
                                    extra="Add image or video file to be shown to this Audience Set"
                                >
                                    <Row>
                                        <Col span={12}>
                                            {getFieldDecorator(
                                                "template_file",
                                                {
                                                    initialValue:
                                                        t.template_file,
                                                }
                                            )(
                                                <Upload
                                                    name="template_file"
                                                    beforeUpload={(file) =>
                                                        false
                                                    }
                                                    showUploadList={true}
                                                    onChange={(e) =>
                                                        this.handleUpload(
                                                            e,
                                                            tIndex
                                                        )
                                                    }
                                                    fileList={
                                                        t.template_fileList
                                                            ? t.template_fileList
                                                            : null
                                                    }
                                                    multiple={false}
                                                    accept="image/jpeg,image/png,image/gif,video/mp4"
                                                    disabled={
                                                        t.library_file
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <Button
                                                        disabled={
                                                            (typeof t.template_file !=
                                                                "undefined" &&
                                                                t.template_file
                                                                    .name &&
                                                                t.changed_template_file) ||
                                                            (t.library_file
                                                                ? true
                                                                : false)
                                                        }
                                                        style={{
                                                            width: 190,
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <Icon
                                                            type="upload"
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                        />{" "}
                                                        Upload File ...
                                                    </Button>
                                                </Upload>
                                            )}
                                        </Col>
                                        <Col span={12}>
                                            <Progress
                                                className={
                                                    !processing ? "hidden" : ""
                                                }
                                                percent={upload_percentage}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {typeof t.template_file !=
                                                "undefined" &&
                                            t.template_file.name &&
                                            !t.changed_template_file ? (
                                                <div>
                                                    <span>
                                                        {t.template_file.name}
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            {typeof t.template_file !=
                                                "undefined" &&
                                            t.template_file.name &&
                                            t.changed_template_file ? (
                                                <div>
                                                    {t.previewURL &&
                                                    t.previewURL.length > 0 ? (
                                                        <img
                                                            src={t.previewURL}
                                                            style={{
                                                                height: 100,
                                                                padding: 5,
                                                                marginTop: 5,
                                                                border: "1px solid #ccc",
                                                                borderRadius: 5,
                                                            }}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {typeof t.template_file !=
                                                "undefined" &&
                                            t.template_file.name &&
                                            !t.changed_template_file
                                                ? getFieldDecorator(
                                                      "delete_template_file",
                                                      {
                                                          initialValue:
                                                              t.delete_template_file,
                                                      }
                                                  )(
                                                      <Checkbox
                                                          defaultChecked={
                                                              t.delete_template_file
                                                          }
                                                      >
                                                          Delete file
                                                      </Checkbox>
                                                  )
                                                : ""}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <LibraryPickup
                                                store={store}
                                                disabled={
                                                    typeof t.template_file !=
                                                        "undefined" &&
                                                    t.template_file.name
                                                }
                                                multiple
                                                onSelectedChange={(selected) =>
                                                    this.handleChangeLibrarySelected(
                                                        tIndex,
                                                        selected
                                                    )
                                                }
                                                selected={
                                                    t.library_file
                                                        ? !Array.isArray(
                                                              t.library_file
                                                          )
                                                            ? [t.library_file]
                                                            : t.library_file
                                                        : []
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>

                                {(t.library_file &&
                                    t.library_file.file &&
                                    t.library_file.file.type.indexOf(
                                        "video"
                                    ) !== -1) ||
                                (t.template_fileList &&
                                    t.template_fileList.length > 0 &&
                                    t.template_fileList[0].type.indexOf(
                                        "video"
                                    ) !== -1) ||
                                t.video_thumbnail ? (
                                    <Form.Item label="Video Cover">
                                        <Button
                                            onClick={(e) =>
                                                this.openVideoThumbnail(tIndex)
                                            }
                                        >
                                            Choose Cover ...
                                        </Button>
                                        <hr size="1" noshade="noshade" />
                                    </Form.Item>
                                ) : null}
                                {t.social_type_instagram === true ? (
                                    <Form.Item
                                        label="Tags"
                                        extra="Instagram only: Image tags"
                                    >
                                        <Button
                                            onClick={(e) =>
                                                this.openInstagramTagging(
                                                    tIndex
                                                )
                                            }
                                        >
                                            Tags{" "}
                                            {t.instagram_tags
                                                ? "(" +
                                                  t.instagram_tags.length +
                                                  ")"
                                                : null}
                                            ...
                                        </Button>
                                    </Form.Item>
                                ) : null}

                                {t.temp_media_info ? (
                                    <Form.Item label="Media Info">
                                        <pre style={{ lineHeight: 1.2 }}>
                                            {JSON.stringify(
                                                t.temp_media_info,
                                                null,
                                                2
                                            )}
                                        </pre>
                                    </Form.Item>
                                ) : null}

                                <Form.Item
                                    className="collection-create-form_last-form-item"
                                    label="Channels"
                                    extra={
                                        <Fragment>
                                            Choose the channels to be invluded
                                            in this Audinece Set.
                                            <br />
                                            You can add channels manually, or
                                            you can add channels according to
                                            predefined rules. These rules are
                                            based on location and/or tags
                                            corresponding to the attributes of
                                            each channel.
                                        </Fragment>
                                    }
                                >
                                    <Radio.Group
                                        value={t.selection_type}
                                        style={{
                                            paddingTop: 5,
                                            lineHeight: 2,
                                        }}
                                        onChange={(e) => {
                                            return this.handleTargetChange(
                                                e,
                                                tIndex,
                                                "selection_type"
                                            );
                                        }}
                                    >
                                        <Radio value="manual">
                                            Manually Select Chanels To Target
                                        </Radio>
                                        <Radio value="rules">
                                            Define Target Groups By Rules
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {t.selection_type === "rules" ? (
                                    <Form.Item label="Filter social networks">
                                        <div
                                            style={{
                                                display: "inline-block",
                                                cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                                this.toggleChannel(
                                                    tIndex,
                                                    "facebook"
                                                )
                                            }
                                        >
                                            <Icon
                                                type="facebook"
                                                theme="filled"
                                                style={{
                                                    fontSize: 25,
                                                    color: t.social_type_facebook
                                                        ? store.getTagColorForType(
                                                              "facebook"
                                                          )
                                                        : "#ccc",
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "inline-block",
                                                cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                                this.toggleChannel(
                                                    tIndex,
                                                    "instagram"
                                                )
                                            }
                                        >
                                            <Icon
                                                type="instagram"
                                                theme="filled"
                                                style={{
                                                    fontSize: 25,
                                                    color: t.social_type_instagram
                                                        ? store.getTagColorForType(
                                                              "instagram"
                                                          )
                                                        : "#ccc",
                                                    marginLeft: 5,
                                                }}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "inline-block",
                                                cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                                this.toggleChannel(
                                                    tIndex,
                                                    "twitter"
                                                )
                                            }
                                        >
                                            <Icon
                                                type="twitter"
                                                style={{
                                                    fontSize: 25,
                                                    color: t.social_type_twitter
                                                        ? store.getTagColorForType(
                                                              "twitter"
                                                          )
                                                        : "#ccc",
                                                    marginLeft: 5,
                                                }}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "inline-block",
                                                cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                                this.toggleChannel(
                                                    tIndex,
                                                    "linkedin"
                                                )
                                            }
                                        >
                                            <Icon
                                                type="linkedin"
                                                theme="filled"
                                                style={{
                                                    fontSize: 25,
                                                    color: t.social_type_linkedin
                                                        ? store.getTagColorForType(
                                                              "linkedin"
                                                          )
                                                        : "#ccc",
                                                    marginLeft: 5,
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "inline-block",
                                                cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                                this.toggleChannel(
                                                    tIndex,
                                                    "youtube"
                                                )
                                            }
                                        >
                                            <Icon
                                                type="youtube"
                                                theme="filled"
                                                style={{
                                                    fontSize: 25,
                                                    color: t.social_type_youtube
                                                        ? store.getTagColorForType(
                                                              "youtube"
                                                          )
                                                        : "#ccc",
                                                    marginLeft: 5,
                                                }}
                                            />
                                        </div>
                                    </Form.Item>
                                ) : null}
                                {t.selection_type === "rules" ? (
                                    <Fragment>
                                        <Form.Item label="Location">
                                            {getFieldDecorator(
                                                "target_rules_location",
                                                {
                                                    initialValue:
                                                        t.rules_location,
                                                    onChange: (e) =>
                                                        this.handleTargetChange(
                                                            e,
                                                            tIndex,
                                                            "rules_location"
                                                        ),
                                                    // onChange: e => {
                                                    // t.rules_location =
                                                    //     e.target.checked;
                                                    // this.recalculateChannelsAffected(tIndex);
                                                }
                                            )(
                                                <Checkbox
                                                    defaultChecked={
                                                        t.rules_location
                                                    }
                                                >
                                                    Enable
                                                </Checkbox>
                                            )}
                                            {t.rules_location &&
                                                t.location &&
                                                t.location.map(
                                                    (location, lIndex) => {
                                                        return getFieldDecorator(
                                                            "location",
                                                            {
                                                                initialValue:
                                                                    location,
                                                                onChange: (e) =>
                                                                    this.handleLocationChange(
                                                                        e,
                                                                        tIndex,
                                                                        lIndex
                                                                    ),
                                                                defaultValue:
                                                                    location,
                                                            }
                                                        )(
                                                            <Row key={lIndex}>
                                                                <Col span={22}>
                                                                    <Cascader
                                                                        key={
                                                                            lIndex
                                                                        }
                                                                        options={
                                                                            locations
                                                                        }
                                                                        placeholder="Please select location"
                                                                        changeOnSelect
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleLocationChange(
                                                                                e,
                                                                                tIndex,
                                                                                lIndex
                                                                            )
                                                                        }
                                                                        value={
                                                                            location
                                                                        }
                                                                        defaultValue={
                                                                            location
                                                                        }
                                                                        style={{
                                                                            paddingLeft: 0,
                                                                            paddingRight: 0,
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col span={2}>
                                                                    <Button
                                                                        key={
                                                                            lIndex
                                                                        }
                                                                        type="danger"
                                                                        shape="circle"
                                                                        icon="minus"
                                                                        size="small"
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.handleLocationDelete(
                                                                                e,
                                                                                tIndex,
                                                                                lIndex
                                                                            )
                                                                        }
                                                                        style={{
                                                                            marginLeft: 10,
                                                                            transform:
                                                                                "scale(0.8)",
                                                                            fontSize:
                                                                                "1rem",
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        );
                                                    }
                                                )}
                                            {t.rules_location ? (
                                                <div
                                                    style={{ display: "block" }}
                                                >
                                                    <Button
                                                        type="normal"
                                                        icon="plus"
                                                        size="small"
                                                        onClick={(e) =>
                                                            this.handleAddLocation(
                                                                e,
                                                                tIndex
                                                            )
                                                        }
                                                    >
                                                        Add New
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </Form.Item>
                                        <Form.Item label="Tags">
                                            {getFieldDecorator(
                                                "target_rules_tags",
                                                {
                                                    initialValue: t.rules_tags,
                                                    onChange: (e) =>
                                                        this.handleTargetChange(
                                                            e,
                                                            tIndex,
                                                            "rules_tags"
                                                        ),
                                                }
                                            )(
                                                <Checkbox
                                                    defaultChecked={
                                                        t.rules_tags
                                                    }
                                                >
                                                    Enable
                                                </Checkbox>
                                            )}
                                            {t.rules_tags
                                                ? getFieldDecorator(
                                                      "target_tags",
                                                      {
                                                          //getValueFromEvent: handleGetTags
                                                          initialValue: t.tags
                                                              ? t.tags
                                                              : [],
                                                      }
                                                  )(
                                                      <div>
                                                          <Select
                                                              mode="multiple"
                                                              style={{
                                                                  width: "100%",
                                                              }}
                                                              placeholder="Please select a tag"
                                                              onChange={(
                                                                  tags
                                                              ) =>
                                                                  this.handleTagsChange(
                                                                      tIndex,
                                                                      tags
                                                                  )
                                                              }
                                                              defaultValue={
                                                                  t.tags
                                                                      ? t.tags
                                                                      : []
                                                              }
                                                          >
                                                              {tagsChildren}
                                                          </Select>
                                                      </div>
                                                  )
                                                : null}
                                        </Form.Item>
                                        <Divider orientation="left">
                                            <Row
                                                type="flex"
                                                justify="space-between"
                                            >
                                                <Col>Channels Affected </Col>
                                                <Col
                                                    style={{
                                                        flexGrow: 1,
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Search
                                                        placeholder="filter affected channels"
                                                        onSearch={(value) =>
                                                            this.setChannelsAffectedFilter(
                                                                tIndex,
                                                                value
                                                            )
                                                        }
                                                        onChange={(e) =>
                                                            this.setChannelsAffectedFilter(
                                                                tIndex,
                                                                e.target.value
                                                            )
                                                        }
                                                        defaultValue={
                                                            t.channels_affected_filter
                                                        }
                                                        size="small"
                                                        style={{ width: 200 }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Badge
                                                        count={
                                                            t.channels_affected
                                                                ? t
                                                                      .channels_affected
                                                                      .length
                                                                : 0
                                                        }
                                                        showZero
                                                        style={{
                                                            backgroundColor:
                                                                "#1890ff",
                                                            marginLeft: 10,
                                                        }}
                                                    ></Badge>
                                                </Col>
                                            </Row>
                                        </Divider>

                                        <div
                                            style={{
                                                marginTop: -10,
                                                marginBottom: 10,
                                            }}
                                        >
                                            {t.channels_affected
                                                ? t.channels_affected
                                                      .filter((c) => {
                                                          if (
                                                              t.channels_affected_filter &&
                                                              t
                                                                  .channels_affected_filter
                                                                  .length > 0
                                                          ) {
                                                              return (
                                                                  c.name
                                                                      .toUpperCase()
                                                                      .indexOf(
                                                                          t.channels_affected_filter.toUpperCase()
                                                                      ) !==
                                                                      -1 ||
                                                                  c.account_name
                                                                      .toUpperCase()
                                                                      .indexOf(
                                                                          t.channels_affected_filter.toUpperCase()
                                                                      ) !== -1
                                                              );
                                                          } else {
                                                              return true;
                                                          }
                                                      })
                                                      .map((sc, scIndex) => {
                                                          return (
                                                              <div
                                                                  key={scIndex}
                                                                  style={{
                                                                      display:
                                                                          "block",
                                                                      lineHeight: 1.5,
                                                                  }}
                                                              >
                                                                  <Icon
                                                                      type={
                                                                          sc.type
                                                                      }
                                                                      theme={
                                                                          sc.type ===
                                                                              "facebook" ||
                                                                          sc.type ===
                                                                              "instagram"
                                                                              ? "filled"
                                                                              : null
                                                                      }
                                                                      style={{
                                                                          marginRight: 5,
                                                                          color: this.props.store.getTagColorForType(
                                                                              sc.type
                                                                          ),
                                                                      }}
                                                                  />
                                                                  <strong>
                                                                      {sc.name}
                                                                  </strong>{" "}
                                                                  |{" "}
                                                                  {
                                                                      sc.account_name
                                                                  }
                                                              </div>
                                                          );
                                                      })
                                                : "N/A"}
                                            {/* <pre>
                                        {JSON.stringify(
                                            t.channels_affected,
                                            null,
                                            2
                                        )}
                                    </pre> */}
                                        </div>

                                        <Form.Item label="Exclude Channels">
                                            {getFieldDecorator(
                                                "target_exclude",
                                                {
                                                    //getValueFromEvent: handleGetTags
                                                    initialValue: t.exclude
                                                        ? t.exclude
                                                        : [],
                                                    onChange: (e) =>
                                                        this.handleExcludeChange(
                                                            tIndex,
                                                            e
                                                        ),
                                                }
                                            )(
                                                <div>
                                                    <Select
                                                        mode="multiple"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        placeholder="Please select channels to exclude"
                                                        onChange={(e) =>
                                                            this.handleExcludeChange(
                                                                tIndex,
                                                                e
                                                            )
                                                        }
                                                        defaultValue={
                                                            t.exclude
                                                                ? t.exclude
                                                                : []
                                                        }
                                                    >
                                                        {excludeChildren}
                                                    </Select>
                                                </div>
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Include Channels">
                                            {getFieldDecorator(
                                                "target_include",
                                                {
                                                    //getValueFromEvent: handleGetTags
                                                    initialValue: t.include
                                                        ? t.include
                                                        : [],
                                                    onChange: (e) =>
                                                        this.handleIncludeChange(
                                                            tIndex,
                                                            e
                                                        ),
                                                }
                                            )(
                                                <div>
                                                    <Select
                                                        mode="multiple"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        placeholder="Please select channel to include"
                                                        onChange={(e) =>
                                                            this.handleIncludeChange(
                                                                tIndex,
                                                                e
                                                            )
                                                        }
                                                        defaultValue={
                                                            t.include
                                                                ? t.include
                                                                : []
                                                        }
                                                    >
                                                        {includeChildren}
                                                    </Select>
                                                </div>
                                            )}
                                        </Form.Item>
                                    </Fragment>
                                ) : null}
                                {t.selection_type === "manual" ? (
                                    <Fragment>
                                        <Form.Item
                                            label="Channels list"
                                            extra={
                                                <Fragment>
                                                    Click on social channel icon
                                                    to add or remove the channel
                                                    {/* <br />
                                                    (affects all accounts in
                                                    this Audience Set) */}
                                                </Fragment>
                                            }
                                        >
                                            {ChannelsCustomSelection(t, tIndex)}
                                        </Form.Item>
                                        <Divider orientation="left">
                                            Channels Affected{" "}
                                            <Badge
                                                count={
                                                    t.channels_affected
                                                        ? t.channels_affected
                                                              .length
                                                        : 0
                                                }
                                                showZero
                                                style={{
                                                    backgroundColor: "#1890ff",
                                                    marginLeft: 10,
                                                }}
                                            ></Badge>
                                        </Divider>
                                    </Fragment>
                                ) : null}
                            </Col>
                            <Col span={12} style={{ paddingLeft: 20 }}>
                                <SocialPreview
                                    body={
                                        this.state.targets[tIndex].template_text
                                    }
                                    image_url={
                                        this.state.targets[tIndex]
                                            .changed_template_file
                                            ? this.state.targets[tIndex]
                                                  .previewURL
                                            : null
                                    }
                                    includes={[
                                        t.social_type_facebook
                                            ? "facebook"
                                            : null,
                                        t.social_type_instagram
                                            ? "instagram"
                                            : null,
                                        t.social_type_twitter
                                            ? "twitter"
                                            : null,
                                        t.social_type_linkedin
                                            ? "linkedin"
                                            : null,
                                        t.social_type_youtube
                                            ? "youtube"
                                            : null,
                                    ]}
                                ></SocialPreview>
                            </Col>
                        </Row>
                    </TabPane>
                );
            });

            return (
                <Fragment>
                    {/* <pre>{JSON.stringify(this.props, null, 2)}</pre>  */}
                    <Form>
                        {/* <ReactJson src={this.state} displayDataTypes={false} /> */}
                        {/* <pre>
                            {JSON.stringify(this.state.all_channels, null, 2)}
                        </pre> */}
                        {/* <Steps
                            current={this.props.step}
                            style={{ marginBottom: 50 }}
                        >
                            <Step title="Basic" />
                            {/* <Step title="Targeting" />
                            <Step title="Generate" />
                        </Steps> */}
                        {this.props.step === 0 ? (
                            <div
                                style={{
                                    minHeight: 610,
                                    // maxHeight: "calc(100vh - 300px)",
                                    // overflowY: "auto",
                                }}
                            >
                                {masterpostPanel}
                                {/* <Divider orientation="left">
                                    Targets (groups)
                                </Divider> */}
                                {/* {TargetsPanel2} */}

                                <Divider
                                    orientation="left"
                                    id="social_TargetsTags"
                                >
                                    <span
                                        style={{
                                            textAlign: "left",
                                            textTransform: "none",
                                        }}
                                    >
                                        AUDIENCE SETS
                                        <span
                                            style={{
                                                color: "rgba(0, 0, 0, 0.45)",
                                                fontSize: 14,
                                                lineHeight: 1.1,
                                                display: "block",
                                            }}
                                        >
                                            Define one or more Audience Sets.
                                        </span>
                                    </span>
                                    <Button
                                        size="small"
                                        onClick={this.addTarget}
                                        className={
                                            "social_TabsBubbles_NewButton"
                                        }
                                        style={{
                                            position: "absolute",
                                            right: 20,
                                            zIndex: 1,
                                            left: Number(
                                                this.state.targets.length * 57
                                            ),
                                        }}
                                    >
                                        <Icon type="plus" /> New Target
                                    </Button>
                                </Divider>

                                <Tabs
                                    className={"social_TabsBubbles"}
                                    activeKey={this.state.selected_target_index}
                                    onChange={this.handleTargetTabChange}
                                >
                                    {TargetsPanel}
                                    {/* <div
                                        tab={"+"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            alert(e);
                                        }}
                                        className={"ant-tabs-tab"}
                                        style={{ float: "left", color: "red" }}
                                    ></div> */}
                                </Tabs>
                            </div>
                        ) : null}
                        {/* {this.props.step === 1 ? (
                            <Row gutter={20} style={{ minHeight: 610 }}>
                                <Col>
                                    <Alert
                                        message="Informational Notes"
                                        type="info"
                                        style={{ border: 0 }}
                                        showIcon
                                    />
                                    <br />
                                    <Tabs>{TargetsPanel}</Tabs>
                                    <Button
                                        size="small"
                                        style={{
                                            position: "absolute",
                                            right: 10,
                                            top: 70,
                                        }}
                                        onClick={this.addTarget}
                                    >
                                        <Icon type="plus" /> New Target
                                    </Button>
                                </Col>
                            </Row>
                        ) : null} */}

                        {this.props.step === 1 ? (
                            <Result
                                icon={this.props.resultIcon}
                                status={this.props.resultStatus}
                                title={this.props.resultText}
                            />
                        ) : null}
                        {/* <Tabs defaultActiveKey="1">
                            <TabPane
                                tab="Basic"
                                key="1"
                                style={{ minHeight: 610 }}
                            >

                            </TabPane>
                            <TabPane
                                tab="Targets"
                                key="2"
                                style={{ minHeight: 610 }}
                            >

                            </TabPane>
                        </Tabs> */}
                    </Form>
                    <MasterpostsListModalInstagramTagging
                        store={this.props.store}
                        handleTaggingUpdate={this.handleTaggingUpdate}
                    />
                    <MasterpostsListModalVideoThumbnail
                        store={this.props.store}
                        handleVideoCoverUpdate={this.handleVideoCoverUpdate}
                    />
                </Fragment>
            );
        }
    }
);

/* Wrapper component */
class MasterpostsListModalNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            confirmLoading: false,
            data: {},
            step: 0,
            resultIcon: <Icon type="loading" />,
            resultStatus: "info",
            resultText: "Processing now ..., please wait.",
            invalid_warnings: [],
        };
        this.modalFormRef = null;
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "masterposts_modal_new",
            (_action, _payload) => {
                console.info("masterposts_modal_new _payload", _payload);
                if (_payload) {
                    console.log("masterposts_modal_new WE HAVE PAYLOAD");
                    this.setState({ data: _payload });
                    // scroll to targets
                    if (_payload && _action === "show") {
                        setTimeout(function () {
                            var targetElm =
                                document.getElementById("social_TargetsTags");
                            targetElm.scrollIntoView();
                        }, 400);
                    }
                } else {
                    this.setState({ data: {} });
                }
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("masterposts_modal_new");
    };

    validateMasterpostData = (data) => {
        console.info("handleModalNewCreate validateMasterpostData data", data);

        let scheduled_date = data.masterpost_scheduled_date
            ? //? moment.local(data.masterpost_scheduled_date).utc().valueOf()
              moment
                  .utc(
                      moment(data.masterpost_scheduled_date)
                          .utc()
                          .format("YYYY-MM-DD HH:mm:ssZ")
                  )
                  .valueOf()
            : data.masterpost_publish_type === "immediately"
            ? moment().utc().valueOf()
            : null;
        console.info("handleModalNewCreate scheduled_date", scheduled_date);

        // Patch
        if (data.masterpost_publish_local_time) {
            console.info(
                "handleModalNewCreate data.masterpost_scheduled_date",
                data.masterpost_scheduled_date
            );

            const copy_of_scheduled_data = moment(
                data.masterpost_scheduled_date
            );
            //2021-06-08T13:10:00
            const scheduled_data_fullFormat = copy_of_scheduled_data.format();
            console.log(
                "handleModalNewCreate scheduled_data_fullFormat = " +
                    scheduled_data_fullFormat
            );

            const offset = moment
                .parseZone(scheduled_data_fullFormat)
                .utcOffset(); // -780 ("-13:00" in total minutes)
            console.log("handleModalNewCreate offset = " + offset);

            const offset_scheduled_data = copy_of_scheduled_data.add(
                offset,
                "minutes"
            );
            const offset_scheduled_data_format =
                offset_scheduled_data.format("YYYY-MM-DD HH:mm");

            console.info(
                "handleModalNewCreate offset_scheduled_data_format",
                offset_scheduled_data_format
            );
            const new_scheduled_date = moment(
                offset_scheduled_data_format,
                "YYYY-MM-DD HH:mm"
            ).utc();
            console.info(
                "handleModalNewCreate new_scheduled_date",
                new_scheduled_date
            );
            scheduled_date = moment(new_scheduled_date).valueOf();

            console.info("handleModalNewCreate scheduled_date", scheduled_date);
        }

        // Creating the masterpost
        let masterpost_data = {
            categories: data.masterpost_categories,
            //every_dropdown: _data.masterpost_every_dropdown,
            //every_number: values.masterpost_every_number,
            campaigns: data.masterpost_campaigns,
            name: data.masterpost_name,
            note: data.masterpost_note,
            publish_local_time: data.masterpost_publish_local_time,
            publish_type: data.masterpost_publish_type,
            scheduled_date: scheduled_date,
            targets: data.targets.map((target) => {
                return {
                    selection_type: target.selection_type,
                    template_text: target.template_text,
                    template_file: target.template_file,
                    library_file: target.library_file,
                    rules_location: target.rules_location,
                    rules_tags: target.rules_tags,
                    rules_custom: target.rules_custom,
                    tags: target.tags,
                    location: target.location,
                    name: target.name,
                    note: "Target Note",
                    exclude: target.exclude,
                    include: target.include,
                    custom: target.custom,
                    social_type_facebook: target.social_type_facebook,
                    social_type_instagram: target.social_type_instagram,
                    social_type_twitter: target.social_type_twitter,
                    social_type_linkedin: target.social_type_linkedin,
                    social_type_youtube: target.social_type_youtube,
                    changed_template_file: target.changed_template_file,
                    shortid: target.shortid,
                    instagram_tags: target.instagram_tags,
                    video_thumbnail: target.video_thumbnail
                        ? target.video_thumbnail
                        : null,
                };
            }),
        };

        console.info("handleModalNewCreate data", data);
        console.info("handleModalNewCreate masterpost_data", masterpost_data);
        console.info(
            "handleModalNewCreate masterpost_data.scheduled_date",
            masterpost_data.scheduled_date,
            moment(masterpost_data.scheduled_date).format("llll")
        );
        //return false;

        if (!data.masterpost_name) {
            social_notification("validation_error", "error", {
                type: "warning",
                description: `You cannot create a ${names.get(
                    "MASTERPOST"
                )} without a name.`,
            });

            this.setState({
                confirmLoading: false,
                resultStatus: "warning",
                resultIcon: null,
                resultText: `You cannot create a ${names.get(
                    "MASTERPOST"
                )} without a name`,
            });

            return false;
        }

        // check whether all targets have at least one channel activated
        let valid_channels = true;
        data.targets.map((target) => {
            if (
                !target.social_type_facebook &&
                !target.social_type_instagram &&
                !target.social_type_twitter &&
                !target.social_type_linkedin &&
                !target.social_type_youtube
            ) {
                valid_channels = false;
            }
            return false;
        });
        //console.log("valid_channels=" + valid_channels);
        if (!valid_channels) {
            social_notification("validation_error", "error", {
                type: "warning",
                description:
                    "All Targets should have at least one social channel selected.",
            });

            this.setState({
                confirmLoading: false,
                resultStatus: "warning",
                resultIcon: null,
                resultText:
                    "All Targets should have at least one social channel selected",
            });

            return false;
        }

        let invalid_rules = [];
        data.targets.map((target) => {
            if (
                target.selection_type === "manual" &&
                target.rules_custom === true &&
                !target.custom
            ) {
                invalid_rules.push(
                    "You have to select at least one account from the list."
                );
            }

            if (
                target.selection_type === "rules" &&
                target.rules_tags &&
                !target.tags
            ) {
                invalid_rules.push("You have to select at least one tag.");
            }

            if (
                target.selection_type === "rules" &&
                target.rules_location &&
                target.location.length === 0
            ) {
                invalid_rules.push("You have to select at least one location.");
            }

            if (target.channels_affected.length === 0) {
                invalid_rules.push(
                    `The target '${target.name}' has no channels affected. Please correct the rules or add channels manually.`
                );
            }

            if (
                target.library_file &&
                target.library_file.length > 1 &&
                (target.social_type_instagram === true ||
                    target.social_type_twitter === true)
            ) {
                invalid_rules.push(
                    `The target '${target.name}' has multiple images selected along with Instagram or Twitter channels which is not allowed. Please either unselect the Instagram/Twitter channels or choose only one image.`
                );
            }

            return false;
        });
        if (invalid_rules.length > 0) {
            social_notification("validation_error", "error", {
                type: "warning",
                description: invalid_rules.join("\n"),
            });

            this.setState({
                confirmLoading: false,
                resultStatus: "warning",
                resultIcon: null,
                resultText:
                    "Please verify the rules. You are missing some settings in them.",
            });

            return false;
        }

        if (data.key) masterpost_data.key = data.key;

        return masterpost_data;
    };

    onModalCreate = () => {
        const modalFormRefState = this.formRef.state;
        console.info("modalFormRefState", modalFormRefState);

        this.setState({
            //confirmLoading: true,
            resultIcon: <Icon type="loading" />,
            resultStatus: "info",
            resultText: "Processing now ..., please wait.",
        });

        const masterpost_data = this.validateMasterpostData(modalFormRefState);
        if (!masterpost_data) return false;

        console.info("masterpost_data", masterpost_data);

        // return;

        this.setState({ confirmLoading: true });
        const key = "progress_notification";

        social_notification({
            type: "info",
            key,
            duration: 0,
            title: "Creating masterpost ...",
            description: "Please wait while we create the new masterpost",
        });

        // notification.info({
        //     key,
        //     duration: 0,
        //     message: "Creating masterpost ...",
        //     description: "Please wait while we create the new masterpost",
        // });

        social_batchCreateMasterpostWithTargets(
            this.props.store,
            shortid,
            base,
            firebase,
            masterpost_data
        )
            .then(async (result) => {
                console.info("result = ", result);

                // reschedule
                await this.props.store.rescheduleMasterpost(result.key);

                social_notification(
                    "batch_create_masterpost_with_targets",
                    "success",
                    {
                        key: key,
                    }
                );

                this.setState({
                    confirmLoading: false,
                    resultStatus: "success",
                    resultIcon: null,
                    resultText:
                        "The masterpost and the targets are created successfully",
                });

                //this.props.store.hideModalByKey("masterposts_modal_new");
                this.onModalCancel(true);
            })
            .catch((error) => {
                console.error(error);
                social_notification({
                    type: "error",
                    key,
                    title: error ? error.message : "Error",
                });
                //notification.error({ key, message: error.message });

                this.setState({
                    resultStatus: "error",
                    resultIcon: null,
                    resultText: error.message,
                });
            });
    };

    onModalApply = () => {
        //alert("Apply");
        const modalFormRefState = this.formRef.state;
        console.info("modalFormRefState", modalFormRefState);
        const masterpost_data = this.validateMasterpostData(modalFormRefState);
        if (!masterpost_data) return false;

        console.info("STEP1 masterpost_data", masterpost_data);
        //this.setState({ modal_visible: false });
        // this.props.store.hideModalByKey("masterposts_modal_new");
        // this.setState({ confirmLoading: false });
        //this.props.store.updateMasterpost(this.props.data.key, values);
        return;

        this.setState({ confirmLoading: true });
        const key = "progress_notification";

        social_notification({
            type: "info",
            key,
            duration: 0,
            title: "Updating masterpost ...",
            description: "Please wait while we update the new masterpost",
        });

        social_batchUpdateMasterpostWithTargets(
            this.props.store,
            shortid,
            base,
            firebase,
            masterpost_data
        )
            .then(async (result) => {
                console.info("result = ", result);

                // reschedule
                await this.props.store.rescheduleMasterpost(result.key);

                social_notification(
                    "batch_update_masterpost_with_targets",
                    "success",
                    {
                        key: key,
                    }
                );

                this.setState({
                    confirmLoading: false,
                    resultStatus: "success",
                    resultIcon: null,
                    resultText:
                        "The masterpost and the targets are updated successfully",
                });

                //this.props.store.hideModalByKey("masterposts_modal_new");
                this.onModalCancel(true); // skip the confirmation
            })
            .catch((error) => {
                console.error(error);
                social_notification({
                    type: "error",
                    key,
                    title: error ? error.message : "Error",
                });
                //notification.error({ key, message: error.message });

                this.setState({
                    resultStatus: "error",
                    resultIcon: null,
                    resultText: error.message,
                });
            });
    };

    onModalCancel = (_dontConfirm) => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "masterposts_modal_new",
            _dontConfirm
                ? false
                : this.formRef
                ? this.formRef.props.form.isFieldsTouched()
                : false
        );
        this.setState({ confirmLoading: false });
    };

    onModalBack = () => {
        let { step } = this.state;
        step = step > 0 ? step - 1 : 0;
        this.setState({ step });
    };
    onModalNext = () => {
        let { step } = this.state;
        step = step < 2 ? step + 1 : 2;
        this.setState({ step });

        if (step === 2) {
            //this.setState({ confirmLoading: true });
            this.onModalCreate();
        }
    };

    valid_callback = (_valid) => {
        // console.info("valid_callback _valid", _valid);
        this.setState({ invalid_warnings: _valid });
    };

    displayWarnings = () => {
        console.info("displayWarnings");
        Modal.warning({
            title: "Warnings List",
            content: (
                <div>
                    {this.state.invalid_warnings &&
                        this.state.invalid_warnings.map(
                            (warning, warningIndex) => (
                                <p key={warningIndex} style={{ margin: 0 }}>
                                    - {warning.text}
                                </p>
                            )
                        )}
                </div>
            ),
            onOk() {},
        });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { store } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        centered
                        width={"70vw"}
                        icon="form"
                        title={
                            this.props.data && this.props.data.key
                                ? `Edit ${names.get("MASTERPOST")}`
                                : `Create new ${names.get("MASTERPOST")}`
                        }
                        okText={
                            this.props.data && this.props.data.key
                                ? "Save"
                                : "Create"
                        }
                        cancelText={"Cancel"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalCreate} //onApply
                        maskClosable={false}
                        confirmLoading={this.state.confirmLoading}
                        footer={[
                            <Button key="back" onClick={this.onModalCancel}>
                                Close
                            </Button>,
                            <div
                                key="submit"
                                style={{
                                    display: "flex",
                                    float: "right",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {this.state.invalid_warnings &&
                                this.state.invalid_warnings.length === 0 ? (
                                    <Tag
                                        color="green"
                                        style={{
                                            marginRight: 15,
                                            border: "none",
                                        }}
                                    >
                                        <Icon
                                            type="check-circle" //<Icon type="check-circle" exclamation-circle />
                                            style={{
                                                marginRight: 7,
                                                color: "#b7eb8f",
                                            }}
                                        />
                                        Everything is valid
                                    </Tag>
                                ) : (
                                    <Tag
                                        color="red"
                                        style={{
                                            marginRight: 15,
                                            border: "none",
                                            cursor: "pointer",
                                            paddingTop: 4,
                                            paddingBottom: 3,
                                            lineHeight: 1.1,
                                        }}
                                        onClick={(e) => this.displayWarnings()}
                                    >
                                        <Icon
                                            type="exclamation-circle" //<Icon type="check-circle" exclamation-circle />
                                            style={{
                                                marginRight: 7,
                                                color: "#f5222d",
                                            }}
                                        />
                                        {this.state.invalid_warnings &&
                                        this.state.invalid_warnings.length !==
                                            1 ? (
                                            <span>
                                                There are{" "}
                                                {
                                                    this.state.invalid_warnings
                                                        .length
                                                }{" "}
                                                issues with this{" "}
                                                {names.get("MASTERPOST")}.
                                            </span>
                                        ) : (
                                            <span>
                                                There is one issue with this{" "}
                                                {names.get("MASTERPOST")}.
                                            </span>
                                        )}
                                        <br />
                                        Click to review
                                    </Tag>
                                )}

                                <Button
                                    type="primary"
                                    loading={this.state.confirmLoading}
                                    onClick={
                                        this.props.data && this.props.data.key
                                            ? this.onModalApply
                                            : this.onModalCreate
                                    }
                                    // disabled={
                                    //     this.state.invalid_warnings &&
                                    //     this.state.invalid_warnings.length > 0
                                    // }
                                >
                                    {/* {this.state.step > 0 ? "Generate" : "Next"} */}
                                    {this.props.data && this.props.data.key
                                        ? "Save"
                                        : "Create"}
                                </Button>
                            </div>,
                        ]}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                                style={{ width: "calc(50% - 20px)" }}
                            />
                        ) : null}

                        <ModalForm
                            store={store}
                            step={this.state.step}
                            resultIcon={this.state.resultIcon}
                            resultStatus={this.state.resultStatus}
                            resultText={this.state.resultText}
                            wrappedComponentRef={this.saveFormRef}
                            wrappedref={this.modalFormRef}
                            data={
                                this.props.data
                                    ? this.props.data
                                    : this.state.data
                                    ? this.state.data
                                    : null
                            }
                            payload={this.state.data}
                            valid_callback={this.valid_callback}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(MasterpostsListModalNew);
