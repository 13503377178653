import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { computed, observable, action, decorate } from "mobx";
import { computedFn } from "mobx-utils";
import config from "./config";
import Api from "./Api";
import myCities from "./US_States_and_Cities.json";
import scss_variables from "./_variables.scss";
import {
    Breadcrumb,
    notification,
    Progress,
    Typography,
    Icon,
    Modal,
} from "antd";

import { social_load_css } from "./functions";

import shortid from "shortid";
import moment from "moment";
import { base, firebase, firebase_messaging } from "./base";

const { Text } = Typography;
const { confirm } = Modal;

/*
}
*/

/*
class Account {
    type;
    email;
    accountId;
    auth;

    constructor(_account) {
        this.type = _account.type;
        this.email = _account.email;
        this.accountId = _account.accountId;
        this.auth = null;
    }

    // calculate the birthday in months from the current date for the current year
    /*
	get timeToBirthdayInMonths() {
		const thisYearBirthday = Moment()
			.month(Moment(this.birthday).month())
			.date(Moment(this.birthday).date());

		return thisYearBirthday.diff(Moment(), "months");
	}
    */

// Overwrite the console log function

class AppStore {
    // make appApi instance of the provided API
    appApi = new Api({
        prefix: config.api.URLprefix,
        unauthorized_callback: (e) => {
            //console.info("unauthorized_callback e", e);
            if (e === 403) {
                Modal.error({
                    title: "Your access has expired",
                    content: <div>Please login again in order to continue</div>,
                    onOk() {
                        window.location.href = config.api.unauthorizedURL;
                    },
                });
            }
        },
    });

    flag_bindAccountsCollection = null;
    flag_bindMasterpostsCollection = null;
    flag_bindLibraryCollection = null;
    flag_bindScheduleCollection = null;
    flag_bindNotificationsCollection = null;
    flag_bindCompetitorsCollection = null;
    flag_bindUsersCollection = null;
    flag_bindAdsCollection = null;
    flag_bindMasteradsCollection = null;
    flag_bindSubscriptionsCollection = null;
    flag_bindChainsCollection = null;
    flag_bindAuditorsCollection = null;
    allLocations = [];

    flag_allLoaded = false; // flag to show whether the intial data was loaded
    loggedUser = {};
    accounts = [];
    tags = []; // tags in masterposts
    masterposts = [];
    categories = []; // categories in masterposts
    campaigns = []; // campaigns,
    ads = []; // ads
    masterads = []; // masterads
    subscriptions = []; // subscriptions
    chains = []; // chains
    auditors = []; // auditors

    currentSection = ""; // nothing is selected by default (used to be "accounts")

    breadcrumbs = [];

    library = [];
    upload = [];

    schedule = []; // schedule

    notifications = []; // notifications

    // modals
    openModals = [];
    modalListeners = [];

    // competitors
    competitors = [];

    // users
    users = [];

    // reports
    reports = [
        {
            key: "channel_monthly_facebook",
            name: "Facebook Channel Report (Monthly)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: ["facebook"],
            fields: [
                {
                    name: "date_range",
                    type: "daterange",
                    title: "Date Range",
                    description: "Date Range Description",
                    required: true,
                    validation_message: "Date Range is required",
                },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "channels",
                    type: "channels",
                    title: "Channels",
                    description: "Channels Description",
                    required: true,
                    validation_message: "Select at least one channel",
                },
            ],
            renderKey: "channel_monthly_facebook",
            custom_sections: [
                "channels",
                "activity_overview",
                "audience_growth",
                "audience_info",
                "messages",
                "impressions",
                "engagement",
                "statsbypage",
                "comments",
                "additional",
                "topposts",
            ],
        },
        {
            key: "channel_monthly_instagram",
            name: "Instagram Channel Report (Monthly)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: ["instagram"],
            fields: [
                {
                    name: "date_range",
                    type: "daterange",
                    title: "Date Range",
                    description: "Date Range Description",
                    required: true,
                    validation_message: "Date Range is required",
                },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "channels",
                    type: "channels",
                    title: "Channels",
                    description: "Channels Description",
                    required: true,
                    validation_message: "Select at least one channel",
                },
            ],
            renderKey: "channel_monthly_instagram",
            custom_sections: [
                "channels",
                "activity_overview",
                "audience_growth",
                "messages",
                "impressions",
                "reach",
                "audience",
                "statsbypage",
                "topposts",
            ],
        },
        {
            key: "masterpost_basic",
            name: "Primary Post Report (basic)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: ["facebook"],
            fields: [
                // {
                //     name: "date_range",
                //     type: "daterange",
                //     title: "Date Range",
                //     description: "Date Range Description"
                // },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "masterposts",
                    type: "masterposts",
                    title: "Primary Post",
                    description: "Primary Post Description",
                    required: true,
                    validation_message: "Primary Post is required",
                },
            ],
            renderKey: "masterpost_basic_facebook",
            custom_sections: [
                "targets",
                "progress",
                "posts",
                // "audience_info",
                // "messages",
                // "impressions",
                // "engagement",
                // "statsbypage"
            ],
        },
        {
            key: "ad_facebook_monthly",
            name: "Facebook Ad Report (Monthly)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: ["facebook"],
            fields: [
                // {
                //     name: "date_range",
                //     type: "daterange",
                //     title: "Date Range",
                //     description: "Date Range Description",
                //     required: true,
                //     validation_message: "Date Range is required",
                // },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "ads",
                    type: "ads",
                    title: "Ads",
                    description: "Ads Description",
                    required: true,
                    validation_message: "Select at least one ad",
                },
            ],
            renderKey: "ad_facebook_monthly",
            custom_sections: [
                //"channels",
                "ads_overview",
                "targeting",
                "ads_basic",
                "ads_cpc",
                "ads_costs",
                "ads_unique",
                "ads_allvalues",
                // "messages",
                // "impressions",
                // "engagement",
                // "statsbypage"
            ],
        },
        {
            key: "ad_linkedin_monthly",
            name: "Linkedin Ad Report (Monthly)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: ["linkedin"],
            fields: [
                // {
                //     name: "date_range",
                //     type: "daterange",
                //     title: "Date Range",
                //     description: "Date Range Description",
                //     required: true,
                //     validation_message: "Date Range is required",
                // },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "ads",
                    type: "ads",
                    title: "Ads",
                    description: "Ads Description",
                    required: true,
                    validation_message: "Select at least one ad",
                },
            ],
            renderKey: "ad_linkedin_monthly",
            custom_sections: [
                //"channels",
                "ads_overview",
                "targeting",
                "ads_basic",
                "ads_clicks",
                "ads_video",
                "ads_viral",
                "ads_allvalues",
                // "messages",
                // "impressions",
                // "engagement",
                // "statsbypage"
            ],
        },
        {
            key: "channel_monthly_twitter",
            name: "Twitter Channel Report (Monthly)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: ["twitter"],
            fields: [
                {
                    name: "date_range",
                    type: "daterange",
                    title: "Date Range",
                    description: "Date Range Description",
                    required: true,
                    validation_message: "Date Range is required",
                },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "channels",
                    type: "channels",
                    title: "Channels",
                    description: "Channels Description",
                    required: true,
                    validation_message: "Select at least one channel",
                },
            ],
            renderKey: "channel_monthly_twitter",
            custom_sections: [
                "channels",
                "summary_overview",
                "impressions",
                "likes",
                "media",
                "table_info",
                // "audience_growth",
                // "audience_info",
                // "messages",
                // "impressions",
                // "engagement",
                // "statsbypage",
            ],
        },
        {
            key: "channel_monthly_linkedin",
            name: "Linkedin Channel Report (Monthly)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: ["linkedin"],
            fields: [
                {
                    name: "date_range",
                    type: "daterange",
                    title: "Date Range",
                    description: "Date Range Description",
                    required: true,
                    validation_message: "Date Range is required",
                },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "channels",
                    type: "channels",
                    title: "Channels",
                    description: "Channels Description",
                    required: true,
                    validation_message: "Select at least one channel",
                },
            ],
            renderKey: "channel_monthly_linkedin",
            custom_sections: [
                "channels",
                "summary_overview",
                "pageviews",
                // "audience_growth",
                // "audience_info",
                // "messages",
                // "impressions",
                // "engagement",
                // "statsbypage",
            ],
        },
        {
            key: "brand_basic",
            name: "Brand Report (basic)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: [],
            fields: [
                {
                    name: "date_range",
                    type: "daterange",
                    title: "Date Range",
                    description: "Date Range Description",
                },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "brand",
                    type: "brand",
                    title: "Brand",
                    description: "Brand Description",
                    required: true,
                    validation_message: "Brand is required",
                },
            ],
            renderKey: "brand_basic",
            custom_sections: ["channels_overview", "competitors", "comparison"],
        },
        {
            key: "channel_monthly_youtube",
            name: "Youtube Channel Report (Monthly)",
            description:
                "This is the description of the report that we can setup later",
            channels_filter: ["youtube"],
            fields: [
                {
                    name: "date_range",
                    type: "daterange",
                    title: "Date Range",
                    description: "Date Range Description",
                    required: true,
                    validation_message: "Date Range is required",
                },
                // { name: "date_from", type: "date", title: "Date From", description: "Date From" },
                // { name: "date_to", type: "date", title: "Date To", description: "Date To" },
                // { name: "mynumber", type: "number", title: "My Number", min: 1, max: 10, description: "mynumber Description" },
                {
                    name: "channels",
                    type: "channels",
                    title: "Channels",
                    description: "Channels Description",
                    required: true,
                    validation_message: "Select at least one channel",
                },
            ],
            renderKey: "channel_monthly_youtube",
            custom_sections: ["channels", "activity_overview", "videos"],
        },
    ];

    // callbacks
    callbacks = [];

    // push messaging
    push_notification_token = null;
    push_notification_subscribed = false;

    print_to_console = {
        print:
            process.env.REACT_APP_STAGE === "development" ||
            process.env.REACT_APP_STAGE === "dev"
                ? true
                : false,
        //print: true,
        prefix: "AppStore >",
        style: "background: #1890ff; color: white; padding: 2px 5px; border-radius: 2px",
    };

    temp_data = [];

    constructor() {
        this._log("store constructor");
        this._info("config.api.URLprefix = ", config.api.URLprefix);

        if (!this.loggedUser.id) {
            return;
        } else {
            this.init();
        }
    }

    // Initial Loading
    init() {
        this._log("INIT");
        // load the accounts from firebase
        this.loadAllAccounts().then((accountsData) => {
            // Accounts are loaded
            this.updateAllAccounts(accountsData);
            this.updateAllTags(); // make the tags array out of the accounts array

            // callbacks
            this.fireCallback("accounts_updated");
        });

        this.bindAccountsCollection();

        // load the masterposts from firebase
        this.loadAllMasterposts().then((masterpostsData) => {
            // masterposts are loaded
            this.updateAllMasterposts(masterpostsData);
            this.updateAllCategories(); // make the categories array out of the masterposts array
            this.updateAllCampaigns(); // make the categories array out of the masterposts array
        });

        this.bindMasterpostsCollection();

        // load the library from firebase
        this.loadAllLibrary().then((libraryData) => {
            // Library are loaded
            this.updateAllLibrary(libraryData);
        });

        this.bindLibraryCollection();

        this.parseLocations();

        this.initBreadcrumbs();

        // load schedule
        this.loadAllSchedule().then((scheduleData) => {
            // Schedule are loaded
            this.updateAllSchedule(scheduleData);
        });
        this.bindScheduleCollection();

        // load notifications
        this.loadAllNotifications().then((notificationsData) => {
            // Notifications are loaded
            this.updateAllNotifications(notificationsData);
        });
        this.bindNotificationsCollection();

        // load competitors
        this.loadAllCompetitors().then((competitorsData) => {
            // Competitors are loaded
            this.updateAllCompetitors(competitorsData);
            //this.updateAllTags(); // make the tags array out of the accounts array
        });

        this.bindCompetitorsCollection();

        // load users
        this.loadAllUsers().then((usersData) => {
            //users are loaded
            this.updateAllUsers(usersData);

            // initiate push notifications
            if (firebase.messaging.isSupported()) {
                this.initPushMessaging();
            }
        });
        this.bindUsersCollection();

        // load ads
        this.loadAllAds().then((adsData) => {
            // Ads are loaded
            this.updateAllAds(adsData);
            //this.updateAllTags(); // make the tags array out of the accounts array
        });

        this.bindAdsCollection();

        // load masterads
        this.loadAllMasterads().then((masteradsData) => {
            // Ads are loaded
            this.updateAllMasterads(masteradsData);
            //this.updateAllTags(); // make the tags array out of the accounts array
        });

        this.bindMasteradsCollection();

        // load subscriptions
        this.loadSubscriptions().then((subscriptionsData) => {
            // Subscriptions are loaded
            this.updateSubscriptions(subscriptionsData);
        });

        // load chains
        this.bindChainsCollection();
        this.loadChains().then((chainsData) => {
            // Chains are loaded
            this.updateChains(chainsData);
        });

        // load auditors
        this.bindAuditorsCollection();
        this.loadAllAuditors().then((auditorsData) => {
            // Auditors are loaded
            // this.updateAuditors(auditorsData);
        });
    }

    removeAllListeners() {
        this.unbindAccountsCollection();
        this.unbindMasterpostsCollection();
        this.unbindCompetitorsCollection();
        this.unbindLibraryCollection();
        this.unbindScheduleCollection();
        this.unbindNotificationsCollection();
        this.unbindAdsCollection();
        this.unbindUsersCollection();
        this.unbindMasteradsCollection();
        this.unbindChainsCollection();
        this.unbindAuditorsCollection();
    }

    // Overwrite the loggin function
    _log = (() => {
        var context = `%c${this.print_to_console.prefix}%s`;
        if (this.print_to_console.print) {
            return Function.prototype.bind.call(
                console.log,
                console,
                context,
                this.print_to_console.style
            );
        } else {
            return () => {};
        }
    })();

    _info = (() => {
        var context = `%c${this.print_to_console.prefix}%s`;
        if (this.print_to_console.print) {
            return Function.prototype.bind.call(
                console.info,
                console,
                context,
                this.print_to_console.style
            );
        } else {
            return () => {};
        }
    })();

    setTempData = (_data) => {
        console.info("----------- setTempData _data", _data);
        this.temp_data.push(_data);
    };
    removeTempData = (_data) => {
        this.temp_data = this.temp_data.filter(
            (data) => JSON.stringify(data) !== JSON.stringify(_data)
        );
    };
    getTempData() {
        return this.temp_data;
    }

    // Callbacks
    fireCallback = (_callback_event, _callback_payload) => {
        this._log(`fireCallback - ${_callback_event} - ${_callback_payload}`);
        const callbacks = this.callbacks.filter(
            (callback) => callback.event === _callback_event
        );
        if (callbacks.length > 0) {
            callbacks.map((callback) => {
                if (typeof callback.function === "function")
                    callback.function(_callback_payload);
                return false;
            });
        }
    };
    // Locations
    parseLocations() {
        let store = this;
        Object.keys(myCities).forEach(function (state) {
            store.allLocations.push({
                value: state,
                label: state,
                children: myCities[state].map((city) => {
                    return {
                        value: city,
                        label: city,
                    };
                }),
            });
        });
        this._info("parseCities allLocations", this.allLocations);
    }
    getLocations() {
        return this.allLocations;
    }

    // Logged user
    get getLoggedUser() {
        return this.loggedUser;
    }
    saveLoggedUser(_user) {
        this._info("saveLoggedUser _user", _user);
        this.loggedUser = _user;
        this.init();
    }
    resetLoggedUser() {
        this.loggedUser = {};
        this.removeAllListeners();
    }

    // Sections
    get getCurrentSection() {
        return this.currentSection;
    }
    get getCurrentSectionTranslated() {
        let result = this.currentSection;
        // translation
        if (this.currentSection === "schedule") result = "calendar";

        return result;
    }
    setCurrentSection(_section) {
        this.currentSection = _section;
    }

    // --- Accounts ------------------------------------------------------------------------
    // loadAllAccounts(_successCallback) {
    //     base.fetch("accounts", {
    //         context: this,
    //         asArray: true,
    //         then(accountsData) {
    //             if (typeof _successCallback === "function")
    //                 _successCallback(accountsData);
    //         }
    //     });
    // }

    loadAllAccounts() {
        return new Promise(async (resolve, reject) => {
            base.fetch("accounts", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: this.loggedUser.world
                        ? this.loggedUser.world.key
                        : null,
                },
            })
                .then((accountsData) => {
                    accountsData = this.stripAccountsData(accountsData);
                    resolve(accountsData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    bindAccountsCollection() {
        this.flag_bindAccountsCollection = base.listenTo("accounts", {
            context: this,
            asArray: true,
            queries: {
                orderByChild: "world_key",
                equalTo: this.loggedUser.world.key,
            },
            then(accountsData) {
                //console.info("bind accountsData", accountsData);
                accountsData = this.stripAccountsData(accountsData);
                this.updateAllAccounts(accountsData);
            },
        });
    }

    stripAccountsData(_data) {
        // strip the other accounts if not admin
        if (this.loggedUser.level === "user" && this.loggedUser.key) {
            _data = _data.filter(
                (account) => account.email === this.loggedUser.email
            );
        }
        return _data;
    }

    unbindAccountsCollection() {
        if (typeof this.flag_bindAccountsCollection === "function")
            this.flag_bindAccountsCollection();
    }

    addAccount(_data) {
        let store = this;

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/addAccount",
                _data,
                (data) => {
                    console.info("addAccount data", data);
                    store.updateAllTags();
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });

        // return new Promise(async (resolve, reject) => {
        //     this._info("addAccount _data", _data);
        //     this._info(
        //         "addAccount JSONED _data",
        //         JSON.parse(JSON.stringify(_data))
        //     );

        //     // Check whether it does existing and returns the existing account
        //     let existingAccountByEmail = this.accounts.filter((acc) => {
        //         if (acc.email === _data.email) return acc;
        //         return false;
        //     });
        //     if (existingAccountByEmail.length > 0) {
        //         resolve(existingAccountByEmail[0]);
        //         return false;
        //     }

        //     // Update firebase
        //     let immediatelyAvailableReference = base
        //         .push("accounts", {
        //             data: JSON.parse(JSON.stringify(_data)),
        //         })
        //         .then((newLocation) => {
        //             // update the tags
        //             store.updateAllTags();
        //             resolve(newLocation);
        //         })
        //         .catch((error) => {
        //             reject(error);
        //         });

        //     var generatedKey = immediatelyAvailableReference.key;
        //     this._info("generatedKey", generatedKey);
        // });
    }

    deleteAccount(_account) {
        let store = this;
        return new Promise(async (resolve, reject) => {
            base.remove(`accounts/${_account.key}`)
                .then(() => {
                    // update the tags
                    store.updateAllTags();
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    }

    updateAccount(_key, _data) {
        let store = this;

        let data = JSON.parse(JSON.stringify(_data));
        // delete key before update
        delete data.key;

        return new Promise(async (resolve, reject) => {
            base.update(`accounts/${_key}`, {
                data: data,
            })
                .then(async () => {
                    await store.updateAllTags(); // make the tags array out of the accounts array
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    updateAccountChannel(_key, _provider, _channel_id, _data) {
        console.info(
            "updateAccountChannel _key",
            _key,
            "_provider",
            _provider,
            "_channel_id",
            _channel_id,
            "_data",
            _data
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/updateChannel",
                {
                    key: _key,
                    provider: _provider,
                    channel_id: _channel_id,
                    data: _data,
                },
                (data) => {
                    this.updateAllTags();
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    preAuthAccount(_provider, _key, _authInfo) {
        this._info(
            "store.preAuthAccount _provider",
            _provider,
            "_key",
            _key,
            "_authInfo",
            _authInfo
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/getFacebookLongAccessToken",
                {
                    short_access_token: _authInfo._token.accessToken,
                },
                (data) => {
                    resolve(data.fbRes);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    authAccount(_provider, _key, _authInfo) {
        this._info(
            "store.authAccount _provider",
            _provider,
            "_key",
            _key,
            "_authInfo",
            _authInfo
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/authAccount",
                {
                    key: _key,
                    provider: _provider,
                    authInfo: _authInfo,
                },
                (data) => {
                    console.info("data", data);
                    //resolve(data.fbRes);
                    resolve(true);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });

        // let store = this;

        // return new Promise(async (resolve, reject) => {
        //     // let accountToAuth = store.accounts.filter(a => {
        //     //     if (a.key === _key) return a;
        //     //     return false;
        //     // })[0];
        //     let accountToAuth = await store.getAccount(_key);

        //     this._info("store.authAccount accountToAuth", accountToAuth);

        //     accountToAuth[_provider] = { auth: _authInfo };

        //     // save the updated information to the database
        //     store
        //         .updateAccount(_key, accountToAuth)
        //         .then(() => {
        //             resolve();
        //         })
        //         .catch((error) => reject(error));
        // });
    }

    saveAccountTargeting(_key, _provider, _level, _targeting, _channel_id) {
        this._info(
            "store.authAccount _provider",
            _provider,
            "_key",
            _key,
            "_level",
            _level,
            "_targeting",
            _targeting,
            "_channel_id",
            _channel_id
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/setAccountTargeting",
                {
                    key: _key,
                    provider: _provider,
                    level: _level,
                    targeting: _targeting,
                    channel_id: _channel_id,
                },
                (data) => {
                    console.info("data", data);
                    //resolve(data.fbRes);
                    resolve(true);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });

        // let store = this;

        // return new Promise(async (resolve, reject) => {
        //     // let accountToAuth = store.accounts.filter(a => {
        //     //     if (a.key === _key) return a;
        //     //     return false;
        //     // })[0];
        //     let accountToAuth = await store.getAccount(_key);

        //     this._info("store.authAccount accountToAuth", accountToAuth);

        //     accountToAuth[_provider] = { auth: _authInfo };

        //     // save the updated information to the database
        //     store
        //         .updateAccount(_key, accountToAuth)
        //         .then(() => {
        //             resolve();
        //         })
        //         .catch((error) => reject(error));
        // });
    }

    saveAuthDataAccount(_provider, _key, _authData) {
        this._info(
            "store.saveAuthDataAccount _provider",
            _provider,
            "_key",
            _key,
            "_authData",
            _authData
        );
        this._info("store.saveAuthDataAccount this.accounts", this.accounts);

        // let store = this;

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/addAuthDataAccount",
                {
                    key: _key,
                    provider: _provider,
                    authData: _authData,
                },
                (data) => {
                    console.info("data", data);
                    resolve(data.account);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });

        // return new Promise(async (resolve, reject) => {
        //     let accountToAuth = store.accounts.filter((a) => {
        //         if (a.key === _key) return a;
        //         return false;
        //     })[0];

        //     this._info(
        //         "store.saveAuthDataAccount accountToAuth",
        //         accountToAuth
        //     );

        //     if (typeof accountToAuth[_provider] == "undefined")
        //         accountToAuth[_provider] = {};
        //     accountToAuth[_provider].data = _authData;

        //     // save the updated information to the database
        //     store
        //         .updateAccount(_key, accountToAuth)
        //         .then((updatedAccount) => {
        //             resolve(updatedAccount);
        //         })
        //         .catch((error) => reject(error));
        // });
    }

    authLinkedinAccount(_key, _code) {
        this._info("store.authLinkedinAccount _key", _key, "_code", _code);

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/authLinkedinAccount",
                {
                    key: _key,
                    code: _code,
                },
                (data) => {
                    //console.info("data", data);
                    //resolve(data.fbRes);
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });

        // return new Promise(async (resolve, reject) => {
        //     this.appApi.post(
        //         "https://www.linkedin.com/oauth/v2/accessToken",
        //         {
        //             grant_type: "authorization_code",
        //             code: _code,
        //             redirect_uri: window.location.origin + "/linkedinhandler",
        //             client_id: _linkedin_client_id,
        //             client_secret: _linkedin_client_secret

        //         },
        //         (data) => {
        //             resolve(data);
        //         },
        //         (error) => {
        //             // Notification
        //             this.displayErrorNotification(error);
        //             reject(error);
        //         },
        //         {
        //             "Content-Type": "application/x-www-form-urlencoded"
        //         }
        //     );
        // });
    }

    getLinkedinPagesForAccount(_key) {
        this._info("store.getLinkedinPagesForAccount _key", _key);

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "linkedin/getorganization",
                {
                    key: _key,
                },
                (data) => {
                    //console.info("data", data);
                    //resolve(data.fbRes);
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    addLinkedinOrganizationAccount(_key, _org) {
        this._info(
            "store.addLinkedinOrganizationAccount _key",
            _key,
            "_org",
            _org
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/addLinkedinOrgAccount",
                {
                    key: _key,
                    org: _org,
                },
                (data) => {
                    //console.info("data", data);
                    //resolve(data.fbRes);
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    unlinkAccountSocial(_key, _provider, _id_to_remove) {
        this._info(
            "store.unlinkAccountSocial _key",
            _key,
            "_provider",
            _provider,
            "_id_to_remove",
            _id_to_remove
        );
        let store = this;

        return new Promise(async (resolve, reject) => {
            let accountToModify = store.accounts.filter((a) => {
                if (a.key === _key) return a;
                return false;
            })[0];

            switch (_provider) {
                case "facebook":
                    accountToModify.facebook.data =
                        accountToModify.facebook.data.filter((fbd) => {
                            return Number(fbd.id) !== Number(_id_to_remove);
                        });
                    break;
                case "twitter":
                    accountToModify.twitter = null;
                    break;
                case "linkedin":
                    accountToModify.linkedin = null;
                    break;
                case "instagram":
                    accountToModify.instagram = null;
                    break;
                case "youtube":
                    accountToModify.google.data =
                        accountToModify.google.data.filter((ytd) => {
                            return ytd.id !== _id_to_remove;
                        });
                    break;
                default:
                    break;
            }

            // save the updated information to the database
            store
                .updateAccount(_key, accountToModify)
                .then((updatedAccount) => {
                    resolve(updatedAccount);
                })
                .catch((error) => reject(error));
        });
    }

    testAccountChannel(
        _key,
        _provider,
        _channel_id,
        _type,
        _message,
        _caption,
        _url,
        _description,
        _file_url
    ) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/testChannel",
                {
                    key: _key,
                    provider: _provider,
                    channel_id: _channel_id,
                    type: _type,
                    message: _message,
                    caption: _caption,
                    url: _url,
                    description: _description,
                    file_url: _file_url,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getFacebookChannels(_profile_id, _profile_access_token) {
        this._info(
            "getFacebookChannels _profile_id",
            _profile_id,
            "_profile_access_token",
            _profile_access_token
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "getFacebookChannels",
                {
                    profile_id: _profile_id,
                    profile_access_token: _profile_access_token,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getAccountChannelContents(_key, _provider, _channel_id) {
        this._info(
            "getAccountChannelContents _key",
            _key,
            "_provider",
            _provider,
            "_channel_id",
            _channel_id
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/getChannelContents",
                {
                    key: _key,
                    provider: _provider,
                    channel_id: _channel_id,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    console.info("ERROR error", error);
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getAccountChannelPosts(_key, _provider, _channel_id) {
        this._info(
            "getAccountChannelPosts _key",
            _key,
            "_provider",
            _provider,
            "_channel_id",
            _channel_id
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/getChannelPosts",
                {
                    key: _key,
                    provider: _provider,
                    channel_id: _channel_id,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getAccountChannelInsights(_key, _provider, _channel_id, _since, _until) {
        this._info(
            "getAccountChannelInsights _key",
            _key,
            "_provider",
            _provider,
            "_channel_id",
            _channel_id,
            "_since",
            _since,
            "_until",
            _until
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/getChannelInsights",
                {
                    key: _key,
                    provider: _provider,
                    channel_id: _channel_id,
                    since: _since,
                    until: _until,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getAccountAuthData(_key, _provider) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/getAuthData",
                {
                    key: _key,
                    provider: _provider,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getAccountCustomAudience(_key, _provider) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/getAccountCustomAudiences",
                {
                    key: _key,
                    provider: _provider,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getAccountYoutubeChannels(_key) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/getYoutubeChannels",
                {
                    key: _key,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    pullAccountYoutubeChannels(_key) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/pullYoutubeChannels",
                {
                    key: _key,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    accountExtendYoutubeToken(_key, _id_token) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/extendYoutubeAccessToken",
                {
                    key: _key,
                    id_token: _id_token,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    accountParseGoogleCode(_key, _google_code) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/parseGoogleCode",
                {
                    key: _key,
                    code: _google_code,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getParsedPost(_key, _post_text, _channel_id) {
        this._info(
            "getParsedPost _key",
            _key,
            "_post_text",
            _post_text,
            "_channel_id",
            _channel_id
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "parsePost",
                {
                    key: _key,
                    post_text: _post_text,
                    channel_id: _channel_id,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    updateAllAccounts(_data) {
        this.accounts = _data;
    }
    get getAllAccounts() {
        return this.accounts;
    }
    get getAllAccountsLength() {
        return this.accounts.length;
    }
    getAccount(_accountKey) {
        //this._log("getAccount _accountKey=" + _accountKey);
        return new Promise(async (resolve, reject) => {
            if (this.accounts.length > 0) {
                resolve(this.accounts.filter((a) => a.key === _accountKey)[0]);
            } else {
                this.loadAllAccounts().then((accountsData) => {
                    // Accounts are loaded
                    this.updateAllAccounts(accountsData);
                    this.updateAllTags(); // make the tags array out of the accounts array

                    // All accounts loaded
                    resolve(
                        this.accounts.filter((a) => a.key === _accountKey)[0]
                    );
                });
            }
        });
    }

    getAccountByEmail = async (_email) => {
        this._info("getAccountByEmail _email", _email);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/getAccountByEmail",
                {
                    email: _email,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    if (error.code !== "NO_EXISTING_ACCOUNT") {
                        this.displayErrorNotification(error);
                    }
                    reject(error);
                }
            );
        });
    };

    getAccountChannels(_accountKey) {
        //let store = this;
        let channels = [];
        return new Promise(async (resolve, reject) => {
            this.getAccount(_accountKey)
                .then((account) => {
                    if (!account || typeof account === "undefined") {
                        resolve([]);
                        return;
                    }

                    store._info(
                        `getAccountChannelsLength KEY = ${_accountKey} > account`,
                        account
                    );
                    if (typeof account.facebook !== "undefined") {
                        if (typeof account.facebook.data !== "undefined") {
                            account.facebook.data.map((fd) => {
                                fd.type = "facebook";
                                fd.link = `https://www.facebook.com/${fd.id}`;

                                if (
                                    fd.instagram_account_id &&
                                    account.instagram &&
                                    account.instagram.data
                                ) {
                                    const found_instagram_channel =
                                        account.instagram.data.filter((igd) => {
                                            if (
                                                igd.instagram_account_id ===
                                                fd.instagram_account_id
                                            )
                                                return igd;
                                            return false;
                                        })[0];

                                    fd.linked_to = {
                                        type: "instagram",
                                        name: found_instagram_channel.meta.name,
                                    };
                                }
                                if (fd.tags) return false;
                                return true;
                            });
                            channels = channels.concat(account.facebook.data);
                        }
                    }

                    if (typeof account.instagram !== "undefined") {
                        if (typeof account.instagram.data !== "undefined") {
                            account.instagram.data.map((igd) => {
                                igd.type = "instagram";
                                igd.link = `https://www.instagram.com/${igd.meta.username}`;
                                igd.name = igd.meta.name;
                                igd.id = igd.instagram_account_id;
                                igd.linked_to = {
                                    type: "facebook",
                                    name: igd.facebook_page_name,
                                };
                                igd.account_name = account.name;
                                igd.account_key = account.key;
                                igd.targeting = igd.targeting
                                    ? igd.targeting
                                    : null;
                                return false;
                            });
                            channels = channels.concat(account.instagram.data);
                        }
                    }

                    if (typeof account.twitter !== "undefined") {
                        if (typeof account.twitter.auth !== "undefined") {
                            const channel = {
                                type: "twitter",
                                name: account.twitter.auth.screen_name,
                                id: account.twitter.auth.user_id,
                                account_name: account.name,
                                account_key: account.key,
                                tags: account.twitter.tags
                                    ? account.twitter.tags
                                    : null,
                                targeting: account.twitter.targeting
                                    ? account.twitter.targeting
                                    : null,
                                ad_account: account.twitter.ad_account
                                    ? account.twitter.ad_account
                                    : null,
                                ad_funding_instrument: account.twitter
                                    .ad_funding_instrument
                                    ? account.twitter.ad_funding_instrument
                                    : null,
                                location: account.twitter.location
                                    ? account.twitter.location
                                    : null,
                            };
                            channels = channels.concat(channel);
                        }
                    }

                    if (typeof account.linkedin !== "undefined") {
                        if (typeof account.linkedin.auth !== "undefined") {
                            const channel = {
                                type: "linkedin",
                                name: `${account.linkedin.profile.localizedFirstName} ${account.linkedin.profile.localizedLastName}`,
                                //link: `https://www.instagram.com/${igd.meta.username}`;
                                id: account.linkedin.profile.id,
                                account_name: account.name,
                                account_key: account.key,
                                tags: account.linkedin.tags
                                    ? account.linkedin.tags
                                    : null,
                                targeting: account.linkedin.targeting
                                    ? account.linkedin.targeting
                                    : null,
                                location: account.linkedin.profile.location
                                    ? account.linkedin.profile.location
                                    : null,
                            };
                            channels = channels.concat(channel);
                            // channels = channels.concat({
                            //     ...account.linkedin.profile,
                            //     ...channel,
                            // });

                            // Adding Linkedin pages
                            account.linkedin.data &&
                                account.linkedin.data.map((ld) => {
                                    channels = channels.concat({
                                        type: "linkedin",
                                        name: ld.localizedName,
                                        id: ld.id,
                                        link: `https://www.linkedin.com/company/${ld.id}/`,
                                        location: ld.location
                                            ? ld.location
                                            : null,
                                    });
                                    return true;
                                });
                        }
                    }
                    // Youtube
                    if (typeof account.google !== "undefined") {
                        if (typeof account.google.auth !== "undefined") {
                            if (typeof account.google.data !== "undefined") {
                                account.google.data.map((yt) => {
                                    const channel = {
                                        type: "youtube",
                                        name: yt.snippet.title,
                                        id: yt.id,
                                        link: `https://www.youtube.com/channel/${yt.id}`,
                                        account_name:
                                            account.google.auth.info.email,
                                        account_key: account.key,
                                        tags: yt.tags ? yt.tags : null,
                                        targeting: yt.targeting
                                            ? yt.targeting
                                            : null,
                                        ad_account: yt.ad_account
                                            ? yt.ad_account
                                            : null,
                                        location: yt.location
                                            ? yt.location
                                            : null,
                                    };
                                    channels = channels.concat(channel);
                                });
                            }
                        }
                    }

                    resolve(channels);
                })
                .catch((error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                });
        });
    }

    getAccountChannelsFromAccountData = (_accountData) => {
        //store._info("getAccountChannelsLength account", account);
        let channels = [];
        if (typeof _accountData.facebook !== "undefined") {
            if (typeof _accountData.facebook.data !== "undefined") {
                _accountData.facebook.data.map((fd) => {
                    fd.type = "facebook";
                    fd.link = `https://www.facebook.com/${fd.id}`;

                    if (
                        fd.instagram_account_id &&
                        _accountData.instagram &&
                        _accountData.instagram.data
                    ) {
                        const found_instagram_channel =
                            _accountData.instagram.data.filter((igd) => {
                                if (
                                    igd.instagram_account_id ===
                                    fd.instagram_account_id
                                )
                                    return igd;
                                return false;
                            })[0];

                        fd.linked_to = {
                            type: "instagram",
                            name: found_instagram_channel.meta.name,
                        };
                    }
                    return false;
                });
                channels = channels.concat(_accountData.facebook.data);
            }
        }

        if (typeof _accountData.instagram !== "undefined") {
            if (typeof _accountData.instagram.data !== "undefined") {
                _accountData.instagram.data.map((igd) => {
                    igd.type = "instagram";
                    igd.link = `https://www.instagram.com/${igd.meta.username}`;
                    igd.name = igd.meta.name;
                    igd.id = igd.instagram_account_id;
                    igd.linked_to = {
                        type: "facebook",
                        name: igd.facebook_page_name,
                    };
                    return false;
                });
                channels = channels.concat(_accountData.instagram.data);
            }
        }

        if (typeof _accountData.twitter !== "undefined") {
            if (typeof _accountData.twitter.auth !== "undefined") {
                const channel = {
                    type: "twitter",
                    name: _accountData.twitter.auth.screen_name,
                    id: _accountData.twitter.auth.user_id,
                    account_name: _accountData.name,
                    account_key: _accountData.key,
                    tags: _accountData.twitter.tags
                        ? _accountData.twitter.tags
                        : null,
                };
                channels = channels.concat(channel);
            }
        }

        if (typeof _accountData.linkedin !== "undefined") {
            if (typeof _accountData.linkedin.auth !== "undefined") {
                const channel = {
                    type: "linkedin",
                    name: `${_accountData.linkedin.profile.localizedFirstName} ${_accountData.linkedin.profile.localizedLastName}`,
                    //link: `https://www.instagram.com/${igd.meta.username}`;
                    id: _accountData.linkedin.profile.id,
                    account_name: _accountData.name,
                    account_key: _accountData.key,
                    tags: _accountData.linkedin.tags
                        ? _accountData.linkedin.tags
                        : null,
                };
                channels = channels.concat(channel);

                // Adding Linkedin pages
                _accountData.linkedin.data &&
                    _accountData.linkedin.data.map((ld) => {
                        channels = channels.concat({
                            type: "linkedin",
                            name: ld.localizedName,
                            id: ld.id,
                            account_name: _accountData.name,
                            account_key: _accountData.key,
                            link: `https://www.linkedin.com/company/${ld.id}/`,
                            tags: ld.tags ? ld.tags : null,
                        });
                        return true;
                    });
            }
        }

        return channels;
    };

    getAccountsByRules(_rules) {
        this._info("getAccountsByRules _rules", _rules);

        if (typeof _rules.tags === "undefined") _rules.tags = [];

        let have_selected_tags = false;
        let have_selected_location = false;
        let not_excluded = true;
        let is_included = false;
        let is_custom = false;

        let filtered_accounts = this.accounts.filter((a) => {
            //let valid_by_tags = false;
            if (_rules.rules_tags) {
                have_selected_tags =
                    _rules.tags.length > 0 && a.tags
                        ? _rules.tags.every((element) =>
                              a.tags.includes(element)
                          )
                        : false;
                // console.info(
                //     "a.name" + a.name + ", a.tags=",
                //     a.tags,
                //     ", have_selected_tags = " + have_selected_tags
                // );
            }
            if (_rules.rules_location && _rules.location) {
                // this._log(
                //     "getAccountsByRules _rules.rules_location=" +
                //         _rules.rules_location
                // );
                _rules.location = JSON.parse(JSON.stringify(_rules.location));
                // this._info(
                //     "getAccountsByRules _rules.location=",
                //     _rules.location,
                //     "typeof ",
                //     typeof _rules.location
                // );
                have_selected_location =
                    _rules.location.length > 0
                        ? _rules.location.filter((element) => {
                              //   this._log(
                              //       "getAccountsByRules a.location = " +
                              //           JSON.stringify(a.location) +
                              //           " <==> " +
                              //           JSON.stringify(element)
                              //   );
                              return (
                                  JSON.stringify(a.location) ===
                                  JSON.stringify(element)
                              );
                          }).length > 0
                        : false;
                // this._info(
                //     "getAccountsByRules a.name" + a.name + ", a.location=",
                //     JSON.stringify(a.location),
                //     ", have_selected_location = " + have_selected_location
                // );
            }
            if (_rules.exclude && _rules.exclude.length > 0) {
                // console.log("_rules.rules_location=" + _rules.rules_location);
                // console.info(
                //     "_rules.location=" + _rules.location,
                //     "typeof ",
                //     typeof _rules.location
                // );
                not_excluded =
                    _rules.exclude.length > 0
                        ? _rules.exclude.filter((element) => {
                              this._log(
                                  "a.email = " +
                                      JSON.stringify(a.key) +
                                      " <==> " +
                                      JSON.stringify(element)
                              );
                              if (
                                  JSON.stringify(a.key) ===
                                  JSON.stringify(element)
                              )
                                  return true;
                              return false;
                          }).length > 0
                            ? false
                            : true
                        : true;
                // console.info(
                //     "a.name=",
                //     a.name,
                //     "a.email=",
                //     a.email,
                //     "not_exlcluded = ",
                //     not_excluded
                // );
            }

            if (_rules.include && _rules.include.length > 0) {
                is_included =
                    _rules.include.length > 0
                        ? _rules.include.filter((element) => {
                              //   console.log(
                              //       "a.email = " +
                              //           JSON.stringify(a.key) +
                              //           " <==> " +
                              //           JSON.stringify(element)
                              //   );
                              if (
                                  JSON.stringify(a.key) ===
                                  JSON.stringify(element)
                              )
                                  return true;
                              return false;
                          }).length > 0
                        : false;
                // console.info(
                //     "a.name=",
                //     a.name,
                //     "a.email=",
                //     a.email,
                //     "is_inlcluded = ",
                //     is_included
                // );
            }

            if (_rules.rules_custom) {
                if (_rules.custom && _rules.custom.length > 0) {
                    is_custom =
                        _rules.custom.length > 0
                            ? _rules.custom.filter((element) => {
                                  if (
                                      element.indexOf("|") === -1 &&
                                      JSON.stringify(a.key) ===
                                          JSON.stringify(element)
                                  )
                                      return true;
                                  return false;
                              }).length > 0
                            : false;
                    // console.info(
                    //     "a.name=",
                    //     a.name,
                    //     "a.email=",
                    //     a.email,
                    //     "is_custom = ",
                    //     is_custom
                    // );
                }
            }

            // this._log(
            //     a.email +
            //         " > have_selected_tags=" +
            //         have_selected_tags +
            //         ",have_selected_location=" +
            //         have_selected_location +
            //         ",is_included=" +
            //         is_included +
            //         ",is_custom=" +
            //         is_custom +
            //         ",not_excluded=" +
            //         not_excluded
            // );
            let result = false;
            if (
                have_selected_tags ||
                have_selected_location ||
                is_included ||
                is_custom
            ) {
                if (not_excluded) {
                    result = a;
                } else {
                    result = false;
                }
            } else {
                result = false;
            }
            return result;

            // if (
            //     _rules.rules_tags &&
            //     !_rules.rules_location &&
            //     (!_rules.exclude || _rules.exclude.length == 0) &&
            //     (!_rules.include || _rules.include.length == 0)
            // ) {
            //     return have_selected_tags ? a : false;
            // }
            // if (
            //     !_rules.rules_tags &&
            //     _rules.rules_location &&
            //     (!_rules.exclude || _rules.exclude.length == 0) &&
            //     (!_rules.include || _rules.include.length == 0)
            // ) {
            //     return have_selected_location ? a : false;
            // }
            // if (
            //     _rules.rules_tags &&
            //     _rules.rules_location &&
            //     (!_rules.exclude || _rules.exclude.length == 0) &&
            //     (!_rules.include || _rules.include.length == 0)
            // ) {
            //     return have_selected_tags && have_selected_location ? a : false;
            // }
            // if (
            //     _rules.exclude &&
            //     _rules.exclude.length > 0 &&
            //     (!_rules.include || _rules.include.length == 0)
            // ) {
            //     console.log("we have exclude is_excluded=" + is_excluded);
            //     return !is_excluded ? a : false;
            // }
            // if (
            //     _rules.include &&
            //     _rules.include.length > 0 &&
            //     (!_rules.exclude || _rules.exclude.length == 0)
            // ) {
            //     console.log("we have include is_included=" + is_included);
            //     return is_included ? a : false;
            // }
            // if (
            //     _rules.rules_custom &&
            //     _rules.custom &&
            //     _rules.custom.length > 0
            // ) {
            //     console.log("we have include is_custom=" + is_custom);
            //     return is_custom ? a : false;
            // }
        });

        return filtered_accounts;
    }

    getAllChannels = async () => {
        this._info("getAllChannels");

        return new Promise(async (resolve, reject) => {
            let all_channels = await Promise.all(
                this.accounts.map(async (account) => {
                    let channels = await this.getAccountChannels(account.key);
                    channels.map((channel) => {
                        channel.account_key = account.key;
                        channel.account_name = account.name;
                        return false;
                    });
                    return channels;
                })
            );
            //console.info("all_channels NOT FLAT", all_channels);
            // flatten the channels
            all_channels = all_channels.flat();
            resolve(all_channels);
        });
    };

    async getChannelsByRules(_rules) {
        this._info("getChannelsByRules _rules", _rules);

        return new Promise(async (resolve, reject) => {
            if (typeof _rules.tags === "undefined") _rules.tags = [];

            let have_selected_tags = false;
            let have_selected_location = false;
            let not_excluded = true;
            let is_included = false;
            let is_custom = false;

            let filtered_channels = [];

            let all_channels = await Promise.all(
                this.accounts.map(async (account) => {
                    let channels = await this.getAccountChannels(account.key);
                    channels.map((channel) => {
                        channel.account_key = account.key;
                        channel.account_name = account.name;
                        return false;
                    });
                    return channels;
                })
            );
            console.info(
                "getChannelsByRules all_channels NOT FLAT",
                all_channels
            );
            // flatten the channels
            all_channels = all_channels.flat();

            // let all_channels = await Promise.all(
            //     this.accounts.map(async (account) => {
            //         const channels = await this.getAccountChannels(account.key);
            //         console.info(`${account.name} => channels`, channels);
            //         if (channels.length) {
            //             filtered_channels = filtered_channels.concat(channels);
            //         }
            //     })
            // );

            console.info("all_channels", all_channels);

            filtered_channels = all_channels.filter((channel) => {
                //by tags
                if (_rules.rules_tags) {
                    have_selected_tags = false;
                    if (_rules.tags.length > 0 && channel.tags) {
                        // console.info(_rules.tags, "in", channel.tags);
                        // _rules.tags.every((element) => {
                        //     if (channel.tags.includes(element))
                        //         have_selected_tags = true;
                        // });
                        have_selected_tags = _rules.tags.some(
                            (r) => channel.tags.indexOf(r) >= 0
                        );
                    }
                }

                // by location
                if (_rules.rules_location && _rules.location) {
                    _rules.location = JSON.parse(
                        JSON.stringify(_rules.location)
                    );
                    have_selected_location =
                        _rules.location.length > 0
                            ? _rules.location.filter((element) => {
                                  //   this._log(
                                  //       "getAccountsByRules a.location = " +
                                  //           JSON.stringify(a.location) +
                                  //           " <==> " +
                                  //           JSON.stringify(element)
                                  //   );
                                  return (
                                      JSON.stringify(channel.location) ===
                                      JSON.stringify(element)
                                  );
                              }).length > 0
                            : false;
                }

                // exclude
                if (_rules.exclude && _rules.exclude.length > 0) {
                    not_excluded =
                        _rules.exclude.length > 0
                            ? _rules.exclude.filter((element) => {
                                  //   this._log(
                                  //       "a.email = " +
                                  //           JSON.stringify(channel.key) +
                                  //           " <==> " +
                                  //           JSON.stringify(element)
                                  //   );
                                  if (
                                      channel.account_key + "|" + channel.id ===
                                      element
                                  )
                                      return true;
                                  return false;
                              }).length > 0
                                ? false
                                : true
                            : true;
                }

                //include
                if (_rules.include && _rules.include.length > 0) {
                    is_included =
                        _rules.include.length > 0
                            ? _rules.include.filter((element) => {
                                  //   console.log(
                                  //       "a.email = " +
                                  //           JSON.stringify(a.key) +
                                  //           " <==> " +
                                  //           JSON.stringify(element)
                                  //   );
                                  if (
                                      channel.account_key + "|" + channel.id ===
                                      element
                                  )
                                      return true;
                                  return false;
                              }).length > 0
                            : false;
                    // console.info(
                    //     "a.name=",
                    //     a.name,
                    //     "a.email=",
                    //     a.email,
                    //     "is_inlcluded = ",
                    //     is_included
                    // );
                }

                // Custom
                if (_rules.rules_custom) {
                    if (_rules.custom && _rules.custom.length > 0) {
                        is_custom =
                            _rules.custom.length > 0
                                ? _rules.custom.filter((element) => {
                                      if (
                                          element.indexOf("|") !== -1 &&
                                          `${channel.account_key}|${channel.id}` ===
                                              element
                                      )
                                          return true;
                                      return false;
                                  }).length > 0
                                : false;
                        // console.info(
                        //     "channel.account_name=",
                        //     channel.account_name,
                        //     "channel.name=",
                        //     channel.name,
                        //     "is_custom = ",
                        //     is_custom
                        // );
                    }
                }

                // this._log(
                //     channel.account_name +
                //         "(" +
                //         channel.name +
                //         "|type=" +
                //         channel.type +
                //         ")" +
                //         " > have_selected_tags=" +
                //         have_selected_tags +
                //         ",have_selected_location=" +
                //         have_selected_location +
                //         ",is_included=" +
                //         is_included +
                //         ",is_custom=" +
                //         is_custom +
                //         ",not_excluded=" +
                //         not_excluded
                // );

                let result = false;
                if (
                    have_selected_tags ||
                    have_selected_location ||
                    is_included ||
                    is_custom
                ) {
                    if (not_excluded) {
                        result = true;
                    } else {
                        result = false;
                    }
                } else {
                    result = false;
                }

                // Only if not manual selection
                if (
                    !_rules.rules_custom &&
                    !_rules.rules_location &&
                    !_rules.rules_tags
                ) {
                    if (
                        _rules.social_type_facebook &&
                        channel.type === "facebook" &&
                        not_excluded
                    )
                        result = true;
                    if (
                        _rules.social_type_instagram &&
                        channel.type === "instagram" &&
                        not_excluded
                    )
                        result = true;
                    if (
                        _rules.social_type_twitter &&
                        channel.type === "twitter" &&
                        not_excluded
                    )
                        result = true;
                    if (
                        _rules.social_type_linkedin &&
                        channel.type === "linkedin" &&
                        not_excluded
                    )
                        result = true;
                }

                return result;
            });

            //console.info("filtered_channels", filtered_channels);

            resolve(filtered_channels);
        });
    }

    getTargetByQueuePost(_queue_item) {
        this._info("getTargetByQueuePost _queue_item=", _queue_item);
        return new Promise(async (resolve, reject) => {
            base.fetch(`masterposts/${_queue_item.masterpost_key}/targets`, {
                context: this,
                //asArray: true
            })
                .then((data) => {
                    //console.log(data);
                    let result = data.filter
                        ? data.filter((t) => {
                              if (t.shortid === _queue_item.target_shortid)
                                  return t;
                              return false;
                          })[0]
                        : data;
                    resolve(result);
                })
                .catch((error) => {
                    //handle error
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                });
        });
    }

    updateAccountQueuePost(
        _account_key,
        _queue_index,
        _post_text,
        _post_file,
        _delete_post_file
    ) {
        let store = this;

        // let data = JSON.parse(JSON.stringify(_account));
        // delete key before update
        // delete data.key;

        this._info(
            "updateAccountQueuePost _account_key",
            _account_key,
            "_queue_index",
            _queue_index,
            "_post_text",
            _post_text,
            "_post_file",
            _post_file
        );

        return new Promise(async (resolve, reject) => {
            // Getting the account
            store
                .getAccount(_account_key)
                .then((accountData) => {
                    // rollover queue
                    accountData.queue.map((qitem, qindex) => {
                        if (qindex === _queue_index) {
                            qitem.post_text = _post_text;
                            qitem["edited_at"] = moment().utc().valueOf();
                            if (_post_file) {
                                if (_post_file.meta) {
                                    // clear _post_file meta undefined fields
                                    Object.keys(_post_file.meta).forEach(
                                        (key) =>
                                            _post_file.meta[key] ===
                                                undefined &&
                                            delete _post_file.meta[key]
                                    );
                                }
                                qitem["post_file"] = _post_file;
                            }

                            if (qitem.changed_template_file)
                                delete qitem.changed_template_file;
                            if (qitem.original_index)
                                delete qitem.original_index;

                            // remove the file if it has to be deleted
                            if (_delete_post_file) {
                                delete qitem.post_file;
                            }
                        }
                        return false;
                    });

                    this._info("accountData", accountData);

                    //Update the account
                    //delete accountData.key;

                    base.update(`accounts/${_account_key}`, {
                        data: JSON.parse(JSON.stringify(accountData)),
                    })
                        .then(() => {
                            resolve(accountData);
                        })
                        .catch((error) => {
                            // Notification
                            this.displayErrorNotification(error);
                            reject(error);
                        });

                    //console.info("updated accountData", accountData);
                })
                .catch((error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                });
        });
    }

    approveAccountQueuePost(_account_key, _queue_index) {
        this._info(
            "approveAccountQueuePost _account_key",
            _account_key,
            "_queue_index",
            _queue_index
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/approveQueuePost",
                {
                    key: _account_key,
                    queue_index: _queue_index,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    postQueuePostByIdAndChannelIds(_accountKey, _queue_index, _channel_ids) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/postQueueItemOnChannels",
                {
                    key: _accountKey,
                    queue_index: _queue_index,
                    channel_ids: _channel_ids,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    publishCommentOnChannelPost(
        _accountKey,
        _provider,
        _channel_id,
        _object_id,
        _comment
    ) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "accounts/publishPostComment",
                {
                    key: _accountKey,
                    provider: _provider,
                    channel_id: _channel_id,
                    object_id: _object_id,
                    comment: _comment,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getBrandsForAccountKeys = (_keys) => {
        let result = {
            undefined: [],
        };

        this.accounts
            .filter((account) => _keys.includes(account.key))
            .map((account) => {
                const accountObj = { key: account.key, name: account.name };
                if (account.brand && account.brand.length === 0) {
                    // no brand
                    result["undefined"].push(accountObj);
                } else {
                    if (result[account.brand]) {
                        result[account.brand].push(accountObj);
                    } else {
                        result[account.brand] = [accountObj];
                    }
                }
                return true;
            });
        return result;
    };
    // --- Tags -------------------------------------------------
    async updateAllTags() {
        this._log("updateAllTags");
        let tags = [];
        await Promise.all(
            this.accounts.map(async (account) => {
                let channels = await this.getAccountChannels(account.key);
                channels.map((channel) => {
                    channel.account_key = account.key;
                    channel.account_name = account.name;
                    return false;
                });
                channels = channels.filter((channel) => channel.tags);
                channels.map((channel) => {
                    tags = tags.concat(channel.tags);
                    return false;
                });
                return false;
            })
        );

        let result = [];
        tags.map((tag) => {
            let count = tags.filter((ctag) => {
                return ctag === tag;
            }).length;
            //console.info("updateAllTags tag", tag, "count", count);
            let found = result.filter((r) => {
                if (r.tag === tag) return r;
                return false;
            }).length;
            //console.info("updateAllTags tag", tag, "found", found);
            if (!found && tag) result.push({ tag, count });
            return false;
        });

        tags = tags.filter((item, pos) => {
            return tags.indexOf(item) === pos && item;
        });
        //console.info("updateAllTags result", result);
        this._info("updateAllTags result", result);
        this.tags = result;
    }

    applyTagsToChannels = async (_data) => {
        this._info("applyTagsToChannels _data", _data);
        this._log(
            "applyTagsToChannels this.accounts.length=" + this.accounts.length
        );

        for (let a in this.accounts) {
            let tempAccount = this.accounts[a];
            console.info("tempAccount", tempAccount);
            let channels = this.getAccountChannelsFromAccountData(tempAccount);
            console.info("channels", channels);
            for (let cIndex in channels) {
                let channel = channels[cIndex];
                let toBeUpdated = false;
                if (_data.channels.includes(channel.id)) {
                    channel.tags = channel.tags
                        ? channel.tags.concat(_data.tags)
                        : _data.tags;

                    channel.tags = channel.tags.filter(function (item, pos) {
                        return channel.tags.indexOf(item) === pos;
                    });

                    toBeUpdated = true;
                } else {
                    // removing tags if existing
                    if (channel.tags) {
                        channel.tags = channel.tags.filter(
                            (el) => !_data.tags.includes(el)
                        );
                    }
                }

                // remove temp fields
                delete channel.account_name;
                delete channel.account_key;

                console.info(
                    "toBeUpdated=" + toBeUpdated + ", channel",
                    channel
                );

                if (toBeUpdated === true) {
                    await this.updateAccountChannel(
                        tempAccount.key,
                        channel.type,
                        channel.id,
                        { tags: channel.tags }
                    );
                }
            }
        }

        console.log("ALL IS UPDATED");
        this.updateAllTags();

        //     return false;
        // await this.accounts.map(async (a) => {
        //     console.log("a.name = " + a.name);
        //     let tempAccount = a;
        //     let channels = this.getAccountChannelsFromAccountData(tempAccount);
        //     console.info("channels", channels);

        //     await channels.map(async (channel) => {
        //         let toBeUpdated = false;
        //         if (_data.channels.includes(channel.id)) {
        //             channel.tags = channel.tags
        //                 ? channel.tags.concat(_data.tags)
        //                 : _data.tags;

        //             channel.tags = channel.tags.filter(function (item, pos) {
        //                 return channel.tags.indexOf(item) === pos;
        //             });

        //             toBeUpdated = true;
        //         } else {
        //             // removing tags if existing
        //             if (channel.tags) {
        //                 channel.tags = channel.tags.filter(
        //                     (el) => !_data.tags.includes(el)
        //                 );
        //             }
        //         }

        //         // remove temp fields
        //         delete channel.account_name;
        //         delete channel.account_key;

        //         console.info(
        //             "toBeUpdated=" + toBeUpdated + ", channel",
        //             channel
        //         );
        //         if (toBeUpdated === true) {
        //             await this.updateAccountChannel(
        //                 a.key,
        //                 channel.type,
        //                 channel.id,
        //                 { tags: channel.tags }
        //             );
        //         }

        //         return false;
        //     });

        //     return false;
        // });

        // this.updateAllTags();
    };

    asyncDeleteTag = async (_account_key, _account) => {
        this._info("asyncDeleteTag _account_key", _account_key);
        return await this.updateAccount(_account_key, _account);
    };
    asyncEditTag = async (_account_key, _account) => {
        this._info("asyncEditTag _account_key", _account_key);
        return await this.updateAccount(_account_key, _account);
    };
    async deleteTag(_tag) {
        //this._info("deleteTag _tag", _tag);
        let store = this;
        return await new Promise(async (resolve, reject) => {
            //this._log("DELETE TAG INSIDE PROMISE");
            for (let aIndex in this.accounts) {
                let tempAccount = this.accounts[aIndex];
                let channels =
                    this.getAccountChannelsFromAccountData(tempAccount);
                if (channels && channels.length > 0) {
                    for (let chIndex in channels) {
                        let channel = channels[chIndex];
                        let have_to_update = false;
                        if (channel.tags) {
                            const original_tags_length = channel.tags.length;

                            channel.tags = channel.tags.filter(
                                (t) => t !== _tag
                            );

                            if (channel.tags.length !== original_tags_length) {
                                have_to_update = true;
                            }
                        }
                        this._info(
                            "deleteTag have_to_update",
                            have_to_update,
                            "channel",
                            channel
                        );
                        if (have_to_update === true) {
                            await this.updateAccountChannel(
                                tempAccount.key,
                                channel.type,
                                channel.id,
                                { tags: channel.tags }
                            );

                            // await this.asyncDeleteTag(a.key, a);
                        }
                    }
                }
            }
            //this._log("DELETE TAG AFTER FOR");

            store.updateAllTags();
            resolve(true);
        });
    }
    async editTag(_old_tag, _new_tag) {
        //this._info("editTag _old_tag", _old_tag, "_new_tag", _new_tag);
        let store = this;
        return await Promise.all(
            this.accounts.map(async (a) => {
                let tempAccount = a;
                let channels =
                    this.getAccountChannelsFromAccountData(tempAccount);

                let have_to_update = false;

                (await channels) &&
                    channels.map(async (channel) => {
                        if (channel.tags) {
                            const original_tags_length = channel.tags.length;

                            channel.tags = channel.tags.map((item) => {
                                return item === _old_tag ? _new_tag : item;
                            });

                            if (channel.tags.length !== original_tags_length) {
                                have_to_update = true;
                            }
                        }
                    });

                if (have_to_update === true) {
                    await this.asyncEditTag(a.key, a);
                }

                return false;
            })
        )
            .then(function () {
                store.updateAllTags();
            })
            .catch((error) => this._warn(`Error in promises ${error}`));
    }

    get getAllTags() {
        return this.tags;
    }
    get getAllTagsLength() {
        return this.tags.length;
    }

    // --- Masterposts --------------------------------------------
    loadAllMasterposts(_successCallback) {
        return new Promise(async (resolve, reject) => {
            base.fetch("masterposts", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: this.loggedUser.world.key,
                },
            })
                .then((masterpostsData) => {
                    masterpostsData =
                        this.stripMasterpostsData(masterpostsData);
                    resolve(masterpostsData);
                })
                .catch((error) => reject(error));
        });
    }

    bindMasterpostsCollection() {
        this.flag_bindMasterpostsCollection = base.listenTo("masterposts", {
            context: this,
            asArray: true,
            queries: {
                orderByChild: "world_key",
                equalTo: this.loggedUser.world.key,
            },
            then(masterpostsData) {
                masterpostsData = this.stripMasterpostsData(masterpostsData);
                //console.info("bind masterpostsData", masterpostsData);
                this.updateAllMasterposts(masterpostsData);
            },
        });
    }

    stripMasterpostsData(_data) {
        // strip the other accounts if not admin
        if (this.loggedUser.level === "user" && this.loggedUser.key) {
            // console.info("this.loggedUser", this.loggedUser);
            _data = _data.filter((masterpost) => {
                masterpost.targets =
                    masterpost.targets &&
                    masterpost.targets.filter((target) => {
                        if (target.rules_custom === true) {
                            // custom selection
                            // console.info(
                            //     "user.key=" + this.loggedUser.key + " | custom = ",
                            //     target.custom
                            // );
                            const foundCustoms = target.custom.filter(
                                (custom) =>
                                    custom.indexOf(this.loggedUser.key) !== -1
                            );
                            // console.log(
                            //     "custom = foundCustoms.length=" +
                            //         foundCustoms.length
                            // );
                            return Number(foundCustoms.length) > 0;
                        } else {
                            return false;
                        }
                    });
                // console.log(
                //     "custom = masterpost.targets.length=" +
                //         masterpost.targets.length
                // );
                return masterpost.targets && masterpost.targets.length > 0;
            });
        }
        // console.info("custom = _data", _data);
        return _data;
    }

    unbindMasterpostsCollection() {
        if (typeof this.flag_bindMasterpostsCollection === "function")
            this.flag_bindMasterpostsCollection();
    }

    updateAllMasterposts(_data) {
        this.masterposts = _data;
    }

    addMasterpost(_data) {
        this._info("addMasterpost _data", _data);
        this._info(
            "addMasterpost JSONED _data",
            JSON.parse(JSON.stringify(_data))
        );
        let store = this;

        // Adding the world_key parameter
        if (this.loggedUser.world && this.loggedUser.world.key) {
            _data["world_key"] = this.loggedUser.world.key;
        }

        // Update firebase
        return new Promise(async (resolve, reject) => {
            let immediatelyAvailableReference = base
                .push("masterposts", {
                    data: JSON.parse(JSON.stringify(_data)),
                })
                .then((newLocation) => {
                    // update the categories
                    store.updateAllCategories();
                    resolve(newLocation);
                })
                .catch((error) => {
                    reject(error);
                });

            var generatedKey = immediatelyAvailableReference.key;
            this._info("generatedKey", generatedKey);
        });
    }

    deleteMasterpost(_masterpost) {
        return new Promise(async (resolve, reject) => {
            base.remove(`masterposts/${_masterpost.key}`)
                .then(() => {
                    // update the categories
                    store.updateAllCategories();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    updateMasterpost(_key, _masterpost) {
        let store = this;

        this._info("updateMasterpost _key", _key, "_masterpost", _masterpost);

        let data = JSON.parse(JSON.stringify(_masterpost));
        const original_data_key = data.key;
        // delete key before update
        delete data.key;

        return new Promise(async (resolve, reject) => {
            base.update(`masterposts/${_key}`, {
                data: data,
            })
                .then(() => {
                    // update the categories
                    store.updateAllCategories();
                    store.updateAllCampaigns();
                    data.key = original_data_key;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    rescheduleMasterpost(_key) {
        this._info("rescheduleMasterpost _key", _key);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "masterposts/reschedule",
                {
                    key: _key,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getMasterpostStatus = computedFn(function getMasterpostStatusById(_key) {
        let masterpost = Object.assign(
            {},
            this.masterposts.filter((masterpost) => masterpost.key === _key)[0]
        );

        let allPosts = [];
        this.accounts.map((account) => {
            if (account.queue) {
                const affectedPosts = account.queue.filter(
                    (queueItem, queueIndex) => {
                        if (queueItem.masterpost_key === _key) {
                            queueItem["account"] = {
                                name: account.name,
                                email: account.email,
                                location: account.location,
                                key: account.key,
                            };
                            queueItem["queue_index"] = queueIndex;
                            return queueItem;
                        }
                        return false;
                    }
                );
                allPosts = allPosts.concat(affectedPosts);
            }
            return false;
        });
        if (masterpost) masterpost["posts"] = allPosts;

        return masterpost;
    });

    getFacebookPostsInsights(_fb_posts) {
        this._info("getFacebookPostsInsights _fb_posts", _fb_posts);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "getFacebookPostsInsights",
                {
                    posts: _fb_posts,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getAllPostsForMasterpost = (_masterpost) => {
        let results = [];
        this.accounts.map((account) => {
            account.queue &&
                account.queue.map((queue) => {
                    if (queue.masterpost_key === _masterpost.key) {
                        let queue_element = JSON.parse(JSON.stringify(queue));
                        queue_element.account = JSON.parse(
                            JSON.stringify(account)
                        );
                        //queue_element.account_name = account.name;
                        //queue_element.account_email = account.email;
                        queue_element.masterpost = JSON.parse(
                            JSON.stringify(_masterpost)
                        );
                        results.push(queue_element);
                    }
                    return false;
                });
            return false;
        });
        return results;
    };

    get getLatestMasterposts() {
        return this.masterposts;
    }

    get getAllMasterposts() {
        const allAds = this.getAllAds;
        let masterposts = JSON.parse(JSON.stringify(this.masterposts));
        masterposts.map((mp) => {
            // --- check whether deletable ---
            let deletable = true;
            const usedinads = allAds.filter(
                (ad) => ad.masterpost_key === mp.key
            );
            if (usedinads.length > 0) deletable = false;
            mp.deletable = deletable;
            return false;
        });

        return masterposts;
    }
    get getAllMasterpostsLength() {
        return this.masterposts.length;
    }

    // --- Categories -------------------------------------------------
    updateAllCategories() {
        let categories = [];
        this.masterposts.map((c) => {
            categories = categories.concat(c.categories);
            return false;
        });

        //this._info("updateAllCategories categories", categories);

        let result = [];
        categories.map((category) => {
            let count = categories.filter((ccategory) => {
                return ccategory === category;
            }).length;
            //this._info("updateAllCategories category", category, "count", count);
            let found = result.filter((r) => {
                if (r.category === category) return r;
                return false;
            }).length;
            //cthis._info("updateAllCategories category", category, "found", found);
            if (!found && category) result.push({ category, count });
            return false;
        });

        categories = categories.filter((item, pos) => {
            return categories.indexOf(item) === pos && item;
        });
        this._info("updateAllCategories result", result);
        this.categories = result;
    }

    applyCategoriesToMasterposts = async (_data) => {
        this._info("applyCategoriesToMasterposts _data", _data);

        await this.masterposts.map(async (c) => {
            if (_data.masterposts.indexOf(c.key) !== -1) {
                let tempmasterpost = c;
                if (tempmasterpost.categories) {
                    tempmasterpost.categories =
                        tempmasterpost.categories.concat(_data.categories);
                } else {
                    tempmasterpost.categories = _data.categories;
                }

                tempmasterpost.categories = tempmasterpost.categories.filter(
                    function (item, pos) {
                        return tempmasterpost.categories.indexOf(item) === pos;
                    }
                );

                this.updateMasterpost(c.key, tempmasterpost);
            } else {
                // the masterpost should not have any of these categories
                let tempmasterpost = c;
                if (tempmasterpost.categories) {
                    tempmasterpost.categories =
                        tempmasterpost.categories.filter(function (el) {
                            return _data.categories.indexOf(el) < 0;
                        });
                }

                this.updateMasterpost(c.key, tempmasterpost);
            }
        });

        this.updateAllCategories();
    };

    asyncDeleteCategory = async (_category_key, _category) => {
        return await this.updateMasterpost(_category_key, _category);
    };
    asyncEditCategory = async (_category_key, _category) => {
        return await this.updateMasterpost(_category_key, _category);
    };
    async deleteCategory(_category) {
        this._info("deleteCategory _category", _category);
        let store = this;
        return await Promise.all(
            this.masterposts.map((c) => {
                c.categories = c.categories
                    ? c.categories.filter((c) => c !== _category)
                    : null;
                this.asyncDeleteCategory(c.key, c);
                return false;
            })
        )
            .then(function () {
                store.updateAllCategories();
            })
            .catch((error) => this._warn(`Error in promises ${error}`));
    }
    async editCategory(_old_category, _new_category) {
        this._info(
            "editCategory _old_category",
            _old_category,
            "_new_category",
            _new_category
        );
        let store = this;
        return await Promise.all(
            this.masterposts.map((c) => {
                c.categories = c.categories.map((item) => {
                    return item === _old_category ? _new_category : item;
                });
                //c.categories = c.categories.map(c => c.replace(_old_category, _new_category));
                this.asyncEditCategory(c.key, c);
                return false;
            })
        )
            .then(function () {
                store.updateAllCategories();
            })
            .catch((error) => this._warn(`Error in promises ${error}`));
    }

    get getAllCategories() {
        return this.categories;
    }
    get getAllCategoriesLength() {
        return this.categories.length;
    }

    // --- Campaigns -------------------------------------------------
    updateAllCampaigns() {
        console.log("updateAllCampaigns");
        let campaigns = [];
        this.masterposts.map((m) => {
            if (m.campaigns) {
                campaigns = campaigns.concat(m.campaigns);
            }
            return false;
        });

        //this._info("updateAllCampaigns campaigns", campaigns);

        let result = [];
        campaigns.map((campaign) => {
            let count = campaigns.filter((ccampaign) => {
                return ccampaign === campaign;
            }).length;
            //this._info("updateAllCategories category", category, "count", count);
            let found = result.filter((r) => {
                if (r.campaign === campaign) return r;
                return false;
            }).length;
            //cthis._info("updateAllCategories category", category, "found", found);
            if (!found && campaign) result.push({ campaign, count });
            return false;
        });

        campaigns = campaigns.filter((item, pos) => {
            return campaigns.indexOf(item) === pos && item;
        });
        this._info("updateAllCampaigns result", result);
        this.campaigns = result;
    }

    applyCampaignsToMasterposts = async (_data) => {
        this._info("applyCampaignsToMasterposts _data", _data);
        let that = this;
        return new Promise(async (resolve, reject) => {
            if (_data.masterposts.length > 0) {
                await Promise.all(
                    that.masterposts.map(async (c) => {
                        if (_data.masterposts.indexOf(c.key) !== -1) {
                            let tempmasterpost = Object.assign({}, c);
                            if (tempmasterpost.campaigns) {
                                tempmasterpost.campaigns =
                                    tempmasterpost.campaigns.concat(
                                        _data.campaigns
                                    );
                            } else {
                                tempmasterpost.campaigns = _data.campaigns;
                            }

                            tempmasterpost.campaigns.filter(function (
                                item,
                                pos
                            ) {
                                return (
                                    tempmasterpost.campaigns.indexOf(item) ===
                                    pos
                                );
                            });

                            await that.updateMasterpost(c.key, tempmasterpost);
                        }
                    })
                )
                    .then(function () {
                        that.updateAllCampaigns();
                        resolve(true);
                    })
                    .catch((error) => {
                        that._warn(`Error in promises ${error}`);
                        reject(error);
                    });
            } else {
                resolve(true);
            }
        });
    };
    removeCampaignsToMasterposts = async (_data) => {
        this._info("removeCampaignsToMasterposts _data", _data);
        let that = this;
        return new Promise(async (resolve, reject) => {
            if (_data.masterposts.length > 0) {
                await Promise.all(
                    that.masterposts.map(async (c) => {
                        if (_data.masterposts.indexOf(c.key) !== -1) {
                            let tempmasterpost = Object.assign({}, c);
                            if (tempmasterpost.campaigns) {
                                tempmasterpost.campaigns =
                                    tempmasterpost.campaigns.filter(function (
                                        el
                                    ) {
                                        return _data.campaigns.indexOf(el) < 0;
                                    });
                            }

                            tempmasterpost.campaigns.filter(function (
                                item,
                                pos
                            ) {
                                return (
                                    tempmasterpost.campaigns.indexOf(item) ===
                                    pos
                                );
                            });

                            await that.updateMasterpost(c.key, tempmasterpost);
                        }
                    })
                )
                    .then(function () {
                        that.updateAllCampaigns();
                        resolve(true);
                    })
                    .catch((error) => {
                        that._warn(`Error in promises ${error}`);
                        reject(error);
                    });
            } else {
                resolve(true);
            }
        });
    };

    applyCampaignsToMasterads = async (_data) => {
        this._info("applyCampaignsToMasterads _data", _data);
        let that = this;
        return new Promise(async (resolve, reject) => {
            if (_data.masterads.length > 0) {
                await Promise.all(
                    that.masterads.map(async (c) => {
                        if (_data.masterads.indexOf(c.key) !== -1) {
                            let tempmasterad = Object.assign({}, c);
                            if (tempmasterad.campaigns) {
                                tempmasterad.campaigns =
                                    tempmasterad.campaigns.concat(
                                        _data.campaigns
                                    );
                            } else {
                                tempmasterad.campaigns = _data.campaigns;
                            }

                            tempmasterad.campaigns.filter(function (item, pos) {
                                return (
                                    tempmasterad.campaigns.indexOf(item) === pos
                                );
                            });

                            await that.updateMasterad(c.key, tempmasterad);
                        }
                    })
                )
                    .then(function () {
                        resolve(true);
                    })
                    .catch((error) => {
                        that._warn(`Error in promises ${error}`);
                        reject(error);
                    });
            } else {
                resolve(true);
            }
        });
    };
    removeCampaignsToMasterads = async (_data) => {
        this._info("removeCampaignsToMasterads _data", _data);
        let that = this;
        return new Promise(async (resolve, reject) => {
            if (_data.masterads.length > 0) {
                await Promise.all(
                    that.masterads.map(async (c) => {
                        if (_data.masterads.indexOf(c.key) !== -1) {
                            let tempmasterad = Object.assign({}, c);
                            if (tempmasterad.campaigns) {
                                tempmasterad.campaigns =
                                    tempmasterad.campaigns.filter(function (
                                        el
                                    ) {
                                        return _data.campaigns.indexOf(el) < 0;
                                    });
                            }

                            tempmasterad.campaigns.filter(function (item, pos) {
                                return (
                                    tempmasterad.campaigns.indexOf(item) === pos
                                );
                            });

                            await that.updateMasterad(c.key, tempmasterad);
                        }
                    })
                )
                    .then(function () {
                        resolve(true);
                    })
                    .catch((error) => {
                        that._warn(`Error in promises ${error}`);
                        reject(error);
                    });
            } else {
                resolve(true);
            }
        });
    };

    asyncDeleteCampaign = async (_campaign_key, _campaign) => {
        return await this.updateMasterpost(_campaign_key, _campaign);
    };
    asyncEditCampaign = async (_campaign_key, _campaign) => {
        return await this.updateMasterpost(_campaign_key, _campaign);
    };
    async deleteCampaign(_campaign) {
        this._info("deleteCampaign _campaign", _campaign);
        let store = this;
        return await Promise.all(
            this.masterposts.map((c) => {
                if (c.campaigns) {
                    c.campaigns = c.campaigns.filter((c) => c !== _campaign);
                    this.asyncDeleteCampaign(c.key, c);
                }
                return false;
            })
        )
            .then(function () {
                store.updateAllCampaigns();
            })
            .catch((error) => this._warn(`Error in promises ${error}`));
    }
    async editCampaign(_old_campaign, _new_campaign) {
        this._info(
            "editCampaign _old_campaign",
            _old_campaign,
            "_new_campaign",
            _new_campaign
        );
        let store = this;
        return await Promise.all(
            this.masterposts.map((c) => {
                if (c.campaigns) {
                    c.campaigns = c.campaigns.map((item) => {
                        return item === _old_campaign ? _new_campaign : item;
                    });
                }
                //c.categories = c.categories.map(c => c.replace(_old_category, _new_category));
                this.asyncEditCampaign(c.key, c);
                return false;
            })
        )
            .then(function () {
                store.updateAllCampaigns();
            })
            .catch((error) => this._warn(`Error in promises ${error}`));
    }

    get getAllCampaigns() {
        return this.campaigns;
    }
    get getAllCampaignsLength() {
        return this.campaigns.length;
    }

    // --- Breadcrumbs functions
    initBreadcrumbs() {
        this.breadcrumbs = [
            {
                path: "index",
                breadcrumbName: "First-level Menu",
            },
            {
                path: "first",
                breadcrumbName: "Second-level Menu",
            },
            {
                path: "second",
                breadcrumbName: "Third-level Menu",
            },
        ];
        this._info("initBreadcrumbs this.breadchrumbs", this.breadcrumbs);
    }

    setBreadcrumbs(_breadcrumbs) {
        this.breadcrumbs = _breadcrumbs;
    }
    get getBreadcrumbs() {
        this._info("getBreadcrumbs this.breadchrumbs", this.breadcrumbs);
        return this.breadcrumbs;
    }
    get getBreadcrumbsParsed() {
        this._info("getBreadcrumbsParsed this.breadchrumbs", this.breadcrumbs);
        return (
            <Breadcrumb>
                <Breadcrumb.Item key="dashboard">
                    <Link to={"/dashboard"}>Dashboard</Link>
                </Breadcrumb.Item>
                {this.breadcrumbs.map((d, dindex) => {
                    return d.route ? (
                        <Breadcrumb.Item key={dindex}>
                            <Link to={d.route}>{d.title}</Link>
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item key={dindex}>
                            {d.title}
                        </Breadcrumb.Item>
                    );
                })}

                {/* <Breadcrumb.Item>An Application</Breadcrumb.Item> */}
            </Breadcrumb>
        );
    }

    // --- Competitors functions ------------------------------------
    loadAllCompetitors() {
        return new Promise(async (resolve, reject) => {
            base.fetch("competitors", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: this.loggedUser.world.key,
                },
            })
                .then((competitorsData) => {
                    resolve(competitorsData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    bindCompetitorsCollection() {
        this.flag_bindCompetitorsCollection = base.listenTo("competitors", {
            context: this,
            asArray: true,
            queries: {
                orderByChild: "world_key",
                equalTo: this.loggedUser.world.key,
            },
            then(competitorsData) {
                // console.info(
                //     "bindCompetitorsCollection competitorsData",
                //     competitorsData
                // );
                this.updateAllCompetitors(competitorsData);
            },
        });
    }

    unbindCompetitorsCollection() {
        if (typeof this.flag_bindCompetitorsCollection === "function")
            this.flag_bindCompetitorsCollection();
    }

    addCompetitor(_data) {
        //let store = this;

        // Adding the world_key parameter
        if (this.loggedUser.world && this.loggedUser.world.key) {
            _data["world_key"] = this.loggedUser.world.key;
        }

        return new Promise(async (resolve, reject) => {
            this._info("addCompetitor _data", _data);
            this._info(
                "addCompetitor JSONED _data",
                JSON.parse(JSON.stringify(_data))
            );

            // Check whether it does existing and returns the existing account
            let existingCompetitorByName = this.competitors.filter((comp) => {
                if (comp.name === _data.name) return comp;
                return false;
            });
            if (existingCompetitorByName.length > 0) {
                resolve(existingCompetitorByName[0]);
                return false;
            }

            // Update firebase
            let immediatelyAvailableReference = base
                .push("competitors", {
                    data: JSON.parse(JSON.stringify(_data)),
                })
                .then((newLocation) => {
                    // update the tags
                    //store.updateAllCompetitors();
                    resolve(newLocation);
                })
                .catch((error) => {
                    reject(error);
                });

            var generatedKey = immediatelyAvailableReference.key;
            this._info("generatedKey", generatedKey);
        });
    }

    deleteCompetitor(_competitor) {
        //let store = this;
        return new Promise(async (resolve, reject) => {
            base.remove(`competitors/${_competitor.key}`)
                .then(() => {
                    // update the tags
                    //store.updateAllCompetitors();
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    }

    updateCompetitor(_key, _competitor) {
        //let store = this;

        let data = JSON.parse(JSON.stringify(_competitor));
        // delete key before update
        delete data.key;

        return new Promise(async (resolve, reject) => {
            base.update(`competitors/${_key}`, {
                data: data,
            })
                .then(() => {
                    //store.updateAllCompetitors(data); // make the tags array out of the accounts array
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    checkCompetitorSocialHandle(_type, _handle) {
        this._info(
            "checkCompetitorSocialHandle _type",
            _type,
            "_handle",
            _handle
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "test",
                {
                    q: _handle,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    updateAllCompetitors(_data) {
        this.competitors = _data;
    }
    get getAllCompetitors() {
        return this.competitors;
    }
    get getAllCompetitorsLength() {
        return this.competitors.length;
    }

    // --- Library functions ----------------------------------------

    loadAllLibrary() {
        return new Promise(async (resolve, reject) => {
            base.fetch("library", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: this.loggedUser.world.key,
                },
            })
                .then((libraryData) => {
                    resolve(libraryData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    bindLibraryCollection() {
        this.flag_bindLibraryCollection = base.listenTo("library", {
            context: this,
            asArray: true,
            queries: {
                orderByChild: "world_key",
                equalTo: this.loggedUser.world.key,
            },
            then(libraryData) {
                //console.info("bind libraryData", libraryData);
                this.updateAllLibrary(libraryData);
            },
        });
    }

    unbindLibraryCollection() {
        if (typeof this.flag_bindLibraryCollection === "function")
            this.flag_bindLibraryCollection();
    }
    async updateAllLibrary(_data) {
        // patch for thumbnails
        await Promise.all(
            _data.map(async (d) => {
                if (d.thumbnail && d.thumbnail.thumbFilePath) {
                    // console.log("thumbnail not undefined");
                    const downloadURL = await firebase
                        .storage()
                        .ref("library/" + d.thumbnail.thumbFilePath)
                        .getDownloadURL();
                    d.thumbnailURL = downloadURL;
                    // console.log("downloadURL=" + downloadURL);
                }
            })
        );
        // End of the patch

        this.library = _data;
    }
    get getAllLibrary() {
        return this.library;
    }
    get getAllLibraryLength() {
        return this.library.length;
    }

    addLibraryFile(_data) {
        let store = this;

        // Adding the world_key parameter
        if (this.loggedUser.world && this.loggedUser.world.key) {
            _data["world_key"] = this.loggedUser.world.key;
        }

        return new Promise(async (resolve, reject) => {
            //let that = this;
            this._info("addLibraryFile _data", _data);
            const key = shortid.generate();
            this._info("addLibraryFile store.upload", store.upload);
            const loggedUser = store.getLoggedUser;
            store.upload.push({
                key,
                file: _data.file ? _data.file : null,
                visible: true,
                progress: 0,
                transferred_bytes: 0,
                // interval: setInterval(
                //     function(_key) {
                //         console.info("key", _key);
                //         increaseProgress(_key);
                //     },
                //     1000,
                //     key
                // ),
                labels: _data.labels,
                remote_url: _data.remote_url ? _data.remote_url : null,
                author: loggedUser,
            });

            let increaseProgress = (_key, _bytesTransferred, _bytesTotal) => {
                // console.info(
                //     "increaseProgress _key",
                //     _key,
                //     "_bytesTransferred",
                //     _bytesTransferred,
                //     "_bytesTotal",
                //     _bytesTotal
                // );
                let progress = Math.round(
                    (_bytesTransferred / _bytesTotal) * 100
                );
                let targetUpload = store.upload.filter(
                    (u) => u.key === _key
                )[0];
                targetUpload.progress = progress;
                targetUpload.transferred_bytes = _bytesTransferred;
                store.updateLibraryNotifications();
            };

            let saveUploadElementToLibrary = (
                _key,
                _downloadURL,
                _snapshot_ref
            ) => {
                // console.info(
                //     "addLibraryFile saveUploadElementToLibrary _key=",
                //     _key,
                //     "_snapshot_ref",
                //     _snapshot_ref
                // );
                let targetUpload = store.upload.filter(
                    (u) => u.key === _key
                )[0];
                targetUpload.visible = false; // close the notification
                store.updateLibraryNotifications();

                console.info(
                    "addLibraryFile saveUploadElementToLibrary targetUpload",
                    targetUpload
                );

                let libraryElement = {
                    key: _key,
                    original_filename: targetUpload.file.name,
                    downloadURL: _downloadURL,
                    file: {
                        name: targetUpload.file.name,
                        size: targetUpload.file.size,
                        type: targetUpload.file.type,
                    },
                    storage: {
                        bucket: _snapshot_ref.bucket,
                        location: _snapshot_ref.location,
                        name: _snapshot_ref.name,
                    },
                    labels: targetUpload.labels
                        ? JSON.parse(JSON.stringify(targetUpload.labels))
                        : [],
                    remote_url: targetUpload.remote_url,
                    author: targetUpload.author,
                    world_key: _data.world_key ? _data.world_key : null,
                };
                // console.info(
                //     "addLibraryFile saveUploadElementToLibrary libraryElement",
                //     libraryElement
                // );

                // // Adding it to the library
                // let store = this;

                // Update firebase

                let immediatelyAvailableReference = base
                    .push("library", {
                        data: JSON.parse(JSON.stringify(libraryElement)),
                    })
                    .then((newLocation) => {
                        resolve(newLocation);
                    })
                    .catch((error) => {
                        reject(error);
                    });

                var generatedKey = immediatelyAvailableReference.key;
                this._info("generatedKey", generatedKey);
            };
            // -----------------------------------------

            let upload_element = store.upload[store.upload.length - 1];
            store._info("addLibraryFile upload_element", upload_element);

            const file = upload_element.file.originFileObj
                ? upload_element.file.originFileObj
                : upload_element.file;

            store._info("addLibraryFile file", file);

            const storageRef = firebase.storage().ref("library");
            let now = Date.now();
            let fileparts = file.name.split(".");
            let fileext = fileparts.pop();
            const mainFile = storageRef.child(
                "library_" +
                    fileparts.join(".") +
                    "_" +
                    now.valueOf() +
                    "." +
                    fileext
            );

            //console.info("mainFile", mainFile);
            let uploadTask = mainFile.put(file);

            uploadTask.on(
                "state_changed",
                function (snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded

                    //console.log("Upload is progress % done");
                    increaseProgress(
                        key,
                        snapshot.bytesTransferred,
                        snapshot.totalBytes
                    );
                },
                function (error) {
                    // Handle unsuccessful uploads
                    console.error("unsuccessful upload: " + error);
                },
                function () {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    const snapshot_ref = uploadTask.snapshot.ref;
                    uploadTask.snapshot.ref
                        .getDownloadURL()
                        .then(function (downloadURL) {
                            //console.log("File available at", downloadURL);
                            saveUploadElementToLibrary(
                                key,
                                downloadURL,
                                snapshot_ref
                            );
                        });
                }
            );

            // open the notification of the last element in the upload list
            store._info(
                "addLibraryFile notification_object",
                upload_element.notification_object
            );

            store.updateLibraryNotifications();

            //resolve("OK");
            // // Update firebase
            // let immediatelyAvailableReference = base
            //     .push("accounts", {
            //         data: JSON.parse(JSON.stringify(_data))
            //     })
            //     .then(newLocation => {
            //         // update the tags
            //         store.updateAllTags();
            //         resolve(newLocation);
            //     })
            //     .catch(error => {
            //         reject(error);
            //     });

            // var generatedKey = immediatelyAvailableReference.key;
            // this._info("generatedKey", generatedKey);
        });
    }

    updateLibraryFile(_key, _labels) {
        //let store = this;

        this._info("updateLibraryFile _key", _key, "_labels", _labels);

        return new Promise(async (resolve, reject) => {
            base.update(`library/${_key}`, {
                data: { labels: _labels },
            })
                .then(() => {
                    // update the categories
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    deleteLibraryItem(_item) {
        return new Promise(async (resolve, reject) => {
            // Checked if the library item is locked
            if (_item.locked) {
                reject("Library item is locked.");
                return;
            }

            base.remove(`library/${_item.key}`)
                .then(() => {
                    // delete the file

                    if (!_item.locked) {
                        const storageRef = firebase.storage().ref("library");
                        // Create a reference to the file to delete
                        var desertRef = storageRef.child(_item.storage.name);

                        // Delete the file
                        desertRef
                            .delete()
                            .then(function () {
                                // File deleted successfully

                                // try to delete the thumb file
                                var thumbdesertRef = storageRef.child(
                                    "thumb_" + _item.storage.name
                                );
                                console.info("thumbdesertRef", thumbdesertRef);
                                if (thumbdesertRef) {
                                    try {
                                        thumbdesertRef.delete();
                                    } catch (error) {
                                        //reject('problem deleting the thumbnails')
                                    }
                                }
                                return true;
                            })
                            .then(function () {
                                resolve();
                            })
                            .catch(function (error) {
                                // Uh-oh, an error occurred!
                                reject(error);
                            });
                    } else {
                        resolve();
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    toggleLibraryItemLock(_item, _forced_state) {
        //let store = this;

        this._info(
            "toggleLibraryItemLock _item",
            _item,
            "_forced_state",
            _forced_state
        );
        if (_item.key) {
            return new Promise(async (resolve, reject) => {
                base.update(`library/${_item.key}`, {
                    data: {
                        locked: _forced_state
                            ? _forced_state
                            : _item.locked
                            ? null
                            : true,
                    },
                })
                    .then(() => {
                        // update the categories
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }

    updateLibraryNotifications = () => {
        this.upload.map((u) => {
            this._info("updateLibraryNotifications u", u);
            let notification_object = {
                key: u.key,
                duration: 0,
                placement: "bottomRight",
                message: (
                    <div
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: 260,
                            paddingRight: 0,
                        }}
                    >
                        Uploading <strong>{u.file.name}</strong> ...
                    </div>
                ),
                description: (
                    <Fragment>
                        <Progress
                            percent={u.progress}
                            status={u.progress !== 100 ? "active" : null}
                        />
                        <Text>
                            {store.humanFileSize(u.transferred_bytes)} /{" "}
                            {store.humanFileSize(u.file.size)}
                        </Text>
                    </Fragment>
                ),
                icon: (
                    <Icon
                        type="cloud-upload"
                        style={{ color: scss_variables.scss_color_primary }}
                    />
                ),
            };
            if (u.visible) {
                notification.open(notification_object);
            } else {
                notification.close(u.key);
            }
            return false;
        });
    };

    // --- Schedule --------------------------------------------
    loadAllSchedule(_successCallback) {
        return new Promise(async (resolve, reject) => {
            base.fetch("schedule", {
                context: this,
                asArray: true,
            })
                .then((scheduleData) => {
                    resolve(scheduleData);
                })
                .catch((error) => reject(error));
        });
    }

    bindScheduleCollection() {
        this.flag_bindScheduleCollection = base.listenTo("schedule", {
            context: this,
            asArray: true,
            then(scheduleData) {
                //console.info("bind scheduleData", scheduleData);
                this.updateAllSchedule(scheduleData);
            },
        });
    }

    unbindScheduleCollection() {
        if (typeof this.flag_bindScheduleCollection === "function")
            this.flag_bindScheduleCollection();
    }

    updateAllSchedule(_data) {
        this.schedule = _data;
    }

    get getAllSchedule() {
        return this.schedule;
    }
    get getAllScheduleLength() {
        return this.schedule.length;
    }

    // --- Notifications --------------------------------------------
    loadAllNotifications(_successCallback) {
        let store = this;
        return new Promise(async (resolve, reject) => {
            base.fetch("notifications", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: this.loggedUser.world.key,
                },
            })
                .then((notificationsData) => {
                    let loggedUser = store.getLoggedUser;

                    //filter the notifications
                    notificationsData = notificationsData.filter(
                        (notification) => {
                            let includeFlag = false;
                            if (typeof notification.recipients === "string") {
                                if (
                                    notification.recipients === "all" ||
                                    notification.recipients === "@all"
                                )
                                    includeFlag = true;
                                if (
                                    (notification.recipients === "admin" ||
                                        notification.recipients === "@admin") &&
                                    loggedUser.level === "admin"
                                )
                                    includeFlag = true;
                                if (
                                    notification.recipients !== "all" &&
                                    notification.recipients !== "@all" &&
                                    notification.recipients !== "admin" &&
                                    notification.recipients !== "@admin" &&
                                    notification.recipients === loggedUser.uid
                                )
                                    includeFlag = true;
                            } else if (
                                typeof notification.recipients === "object"
                            ) {
                                // array of recepients
                                notification.recipients.map((nr) => {
                                    if (nr === "all" || nr === "@all")
                                        includeFlag = true;
                                    if (
                                        (nr === "admin" || nr === "@admin") &&
                                        loggedUser.level === "admin"
                                    )
                                        includeFlag = true;
                                    if (
                                        nr !== "all" &&
                                        nr !== "@all" &&
                                        nr !== "admin" &&
                                        nr !== "@admin" &&
                                        nr === loggedUser.uid
                                    )
                                        includeFlag = true;
                                    return false;
                                });
                            }
                            // console.info(
                            //     "notification",
                            //     notification,
                            //     "includeFlag",
                            //     includeFlag
                            // );
                            if (includeFlag) return notification;
                            return false;
                        }
                    );

                    resolve(notificationsData);
                })
                .catch((error) => reject(error));
        });
    }

    bindNotificationsCollection() {
        this.flag_bindNotificationsCollection = base.listenTo("notifications", {
            context: this,
            asArray: true,
            queries: {
                orderByChild: "world_key",
                equalTo: this.loggedUser.world.key,
            },
            then(notificationsData) {
                //console.info("bind notificationsData", notificationsData);
                this.updateAllNotifications(notificationsData);
            },
        });
    }
    unbindNotificationsCollection() {
        if (typeof this.flag_bindNotificationsCollection === "function")
            this.flag_bindNotificationsCollection();
    }
    updateAllNotifications(_data) {
        this.notifications = _data;
    }
    addNotification(_data) {
        this._info("addNotification _data", _data);
        this._info(
            "addNotification JSONED _data",
            JSON.parse(JSON.stringify(_data))
        );
        let store = this;

        // Adding the world_key parameter
        if (this.loggedUser.world && this.loggedUser.world.key) {
            _data["world_key"] = this.loggedUser.world.key;
        }

        // Update firebase
        return new Promise(async (resolve, reject) => {
            let immediatelyAvailableReference = base
                .push("notifications", {
                    data: JSON.parse(JSON.stringify(_data)),
                })
                .then((newLocation) => {
                    // update the notifications
                    store.updateAllNotifications();
                    resolve(newLocation);
                })
                .catch((error) => {
                    reject(error);
                });

            var generatedKey = immediatelyAvailableReference.key;
            this._info("generatedKey", generatedKey);
        });
    }
    deleteNotification(_notification_key) {
        return new Promise(async (resolve, reject) => {
            base.remove(`notifications/${_notification_key}`)
                .then(() => {
                    // update the notifications
                    //store.updateAllNotifications();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    addNoteNotification(_key, _note) {
        return new Promise(async (resolve, reject) => {
            base.fetch(`notifications/${_key}`, {
                context: this,
                assArray: false,
            })
                .then((notificationSnapshot) => {
                    // update the notifications
                    let data = JSON.parse(JSON.stringify(notificationSnapshot));
                    //console.info("notificationNOTE data", data);

                    const noteObj = {
                        author: this.loggedUser,
                        note: _note,
                        datetime: moment().utc(),
                    };

                    // Adding the note
                    data.notes
                        ? data.notes.push(noteObj)
                        : (data["notes"] = [noteObj]);

                    return this.updateNotification(_key, data);
                })
                .then((updatedNotificationData) => {
                    resolve(updatedNotificationData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    updateNotification(_key, _notification) {
        //let store = this;

        this._info(
            "updateNotification _key",
            _key,
            "_notification",
            _notification
        );

        let data = JSON.parse(JSON.stringify(_notification));
        // delete key before update
        delete data.key;

        return new Promise(async (resolve, reject) => {
            base.update(`notifications/${_key}`, {
                data: data,
            })
                .then((err) => {
                    // update the notifications
                    //store.updateAllNotifications();
                    if (err) {
                        reject(err);
                        return;
                    }

                    let updatedData = data;
                    updatedData["key"] = _key;
                    resolve(updatedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    get getAllNotifications() {
        return this.notifications;
    }
    get getAllNotificationsLength() {
        return this.notifications.length;
    }
    getNotificationRelatedElement(_notification_key) {
        this._info(
            "getNotificationRelatedElement _notification_key",
            _notification_key
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "notifications/getNotificationRelatedElement",
                {
                    key: _notification_key,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    // --- Reports --------------------------------------------------
    get getAllReports() {
        return this.reports;
    }
    get getAllReportsLength() {
        return this.reports.length;
    }

    addCustomReport(_customreport) {
        this._info("addCustomReport _customreport", _customreport);

        // Adding the world_key parameter
        if (this.loggedUser.world && this.loggedUser.world.key) {
            _customreport["world_key"] = this.loggedUser.world.key;
        }

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "reports/addCustomRerpot",
                _customreport,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    deleteCustomReport(_customreport_key) {
        return new Promise(async (resolve, reject) => {
            base.remove(`customreports/${_customreport_key}`)
                .then(() => {
                    // update the notifications
                    //store.updateAllNotifications();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getAllCustomReports() {
        return new Promise(async (resolve, reject) => {
            base.fetch("customreports", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: this.loggedUser.world
                        ? this.loggedUser.world.key
                        : null,
                },
            })
                .then((customreportsData) => {
                    resolve(customreportsData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    // --- Ads --------------------------------------------
    loadAllAds(_successCallback) {
        return new Promise(async (resolve, reject) => {
            base.fetch("ads", {
                context: this,
                asArray: true,
            })
                .then((adsData) => {
                    resolve(adsData);
                })
                .catch((error) => reject(error));
        });
    }
    bindAdsCollection() {
        this.flag_bindAdsCollection = base.listenTo("ads", {
            context: this,
            asArray: true,
            then(adsData) {
                //console.info("bind adsData", adsData);
                this.updateAllAds(adsData);
            },
        });
    }
    unbindAdsCollection() {
        if (typeof this.flag_bindAdsCollection === "function")
            this.flag_bindAdsCollection();
    }
    updateAllAds(_data) {
        this.ads = _data;
    }
    addAd(_data) {
        this._info("addAd _data", _data);
        this._info("addAd JSONED _data", JSON.parse(JSON.stringify(_data)));

        return new Promise(async (resolve, reject) => {
            let url =
                _data.network === "facebook"
                    ? _data.ad_scope === "ad"
                        ? "mushrooms/facebook_createad"
                        : "mushrooms/facebook_boostpost"
                    : null;

            if (_data.network === "linkedin") {
                url = "mushrooms/linkedin_createad";
            }

            if (_data.network === "twitter") {
                url = "mushrooms/twitter_createad";
            }

            console.log("addAd url=" + url);
            this.appApi.post(
                url,
                _data,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getAdInsights(_key, _platform) {
        this._info("getAdInsights _key", _key, "_platform", _platform);
        return new Promise(async (resolve, reject) => {
            let url =
                !_platform || _platform === "facebook"
                    ? "mushrooms/facebook_getAdInsights"
                    : null;
            if (_platform && _platform === "linkedin")
                url = "mushrooms/linkedin_getAdInsights";

            this.appApi.post(
                url,
                { key: _key },
                (data) => {
                    resolve(data.results.insights_results);
                },
                (error) => {
                    console.info("ERROR error", error);
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    deleteAd(_key) {
        this._info("deleteAd _key", _key);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "mushrooms/deleteAd",
                { key: _key },
                (data) => {
                    resolve(data.results.delete_results);
                },
                (error) => {
                    console.info("ERROR error", error);
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    // updateAd(_key, _ad) {
    //     //let store = this;

    //     this._info("updateAd _key", _key, "_ad", _ad);
    //     return;
    //     let data = JSON.parse(JSON.stringify(_ad));
    //     // delete key before update
    //     delete data.key;

    //     return new Promise(async (resolve, reject) => {
    //         base.update(`ads/${_key}`, {
    //             data: data,
    //         })
    //             .then((err) => {
    //                 // update the notifications
    //                 //store.updateAllAds();
    //                 if (err) {
    //                     reject(err);
    //                     return;
    //                 }

    //                 let updatedData = data;
    //                 updatedData["key"] = _key;
    //                 resolve(updatedData);
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //             });
    //     });
    // }
    updateAd(_key, _updated_data) {
        this._info("updateAd _key", _key, "_updated_data", _updated_data);
        delete _updated_data.masterad;
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "mushrooms/update_ad",
                { key: _key, ad: _updated_data },
                (data) => {
                    resolve(data.results);
                },
                (error) => {
                    console.info("ERROR error", error);
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    updateAdStatus(_key, _status) {
        this._info("updateAdStatus _key", _key, "_status", _status);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "mushrooms/facebook_updateAdStatus",
                { key: _key, status: _status },
                (data) => {
                    resolve(data.results);
                },
                (error) => {
                    console.info("ERROR error", error);
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    get getAllAds() {
        return this.ads;
    }
    get getAllAdsLength() {
        return this.ads.length;
    }

    // --- Masterads --------------------------------------------
    loadAllMasterads(_successCallback) {
        return new Promise(async (resolve, reject) => {
            base.fetch("masterads", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: this.loggedUser.world.key,
                },
            })
                .then((masteradsData) => {
                    console.info("masteradsData", masteradsData);
                    resolve(masteradsData);
                })
                .catch((error) => reject(error));
        });
    }
    bindMasteradsCollection() {
        this.flag_bindMasteradsCollection = base.listenTo("masterads", {
            context: this,
            asArray: true,
            queries: {
                orderByChild: "world_key",
                equalTo: this.loggedUser.world.key,
            },
            then(masteradsData) {
                //console.info("bind masteradsData", masteradsData);
                this.updateAllMasterads(masteradsData);
            },
        });
    }
    unbindMasteradsCollection() {
        if (typeof this.flag_bindMasteradsCollection === "function")
            this.flag_bindMasteradsCollection();
    }
    updateAllMasterads(_data) {
        this.masterads = _data;

        // callbacks
        this.fireCallback("masterads_updated");
    }

    addMasterad(_masterad) {
        this._info("addMasterad _masterad", _masterad);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "mushrooms/createMasterad",
                _masterad,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    deleteMasterad(_masterad_key) {
        return new Promise(async (resolve, reject) => {
            base.remove(`masterads/${_masterad_key}`)
                .then(() => {
                    // update the notifications
                    //store.updateAllAds();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    updateMasterad(_key, _ad) {
        //let store = this;

        this._info("updateMasterad _key", _key, "_ad", _ad);

        let data = JSON.parse(JSON.stringify(_ad));
        // delete key before update
        delete data.key;

        return new Promise(async (resolve, reject) => {
            base.update(`masterads/${_key}`, {
                data: data,
            })
                .then((err) => {
                    // update the notifications
                    //store.updateAllAds();
                    if (err) {
                        reject(err);
                        return;
                    }

                    let updatedData = data;
                    updatedData["key"] = _key;
                    resolve(updatedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    updateMasteradStatus(_key, _status) {
        this._info("updateMasteradStatus _key", _key, "_status", _status);
        // return new Promise(async (resolve, reject) => {
        //     this.appApi.post(
        //         "mushrooms/facebook_updateAdStatus",
        //         { key: _key, status: _status },
        //         data => {
        //             resolve(data.results);
        //         },
        //         error => {
        //             console.info("ERROR error", error);
        //             // Notification
        //             this.displayErrorNotification(error);
        //             reject(error);
        //         }
        //     );
        // });
    }
    get getAllMasterads() {
        this._info("getAllMasterads this.masterads", this.masterads);
        return this.masterads;
    }
    get getAllMasteradsLength() {
        return this.masterads.length;
    }

    // --- Plans --------------------------------------------
    loadPlans(_world_key) {
        this._info("loadPlans _world_key", _world_key);
        return new Promise(async (resolve, reject) => {
            this.appApi.get(
                "plans",
                { world_key: _world_key },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    // --- Subscriptions --------------------------------------------
    loadSubscriptions(_overwrite_world_key) {
        console.info(
            "loadSubscriptions _overwrite_world_key=" + _overwrite_world_key
        );
        return new Promise(async (resolve, reject) => {
            base.fetch("subscriptions", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: _overwrite_world_key
                        ? _overwrite_world_key
                        : this.loggedUser.world
                        ? this.loggedUser.world.key
                        : null,
                },
            })
                .then((subscriptionsData) => {
                    resolve(subscriptionsData);
                })
                .catch((error) => reject(error));
        });
    }

    paySubscription(_payment) {
        this._info("paySubscription _info", _payment);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "subscriptions/pay",
                _payment,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    payWorldSubscription(_payment) {
        this._info("payWorldSubscription _info", _payment);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "subscriptions/worldpay",
                _payment,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    updateSubscription(_info) {
        this._info("updateSubscription _info", _info);
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "subscriptions/update",
                _info,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    unsubscribeSubscription(_subscription_id) {
        this._info(
            "unsubscribeSubscription _subscription_id",
            _subscription_id
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "subscriptions/unsubscribe",
                { subscription_id: _subscription_id },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    updateSubscriptions(_data) {
        this.subscriptions = _data;
    }

    get getSubscriptions() {
        return this.subscriptions;
    }

    // --- Chains --------------------------------------------
    loadChains(_overwrite_world_key) {
        return new Promise(async (resolve, reject) => {
            base.fetch("chains", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: _overwrite_world_key
                        ? _overwrite_world_key
                        : this.loggedUser.world
                        ? this.loggedUser.world.key
                        : null,
                },
            })
                .then((chainsData) => {
                    resolve(chainsData);
                })
                .catch((error) => reject(error));
        });
    }
    bindChainsCollection() {
        this.flag_bindChainsCollection = base.listenTo("chains", {
            context: this,
            asArray: true,
            then(chainsData) {
                //console.info("bind chainsData", chainsData);
                this.updateChains(chainsData);
            },
        });
    }
    unbindChainsCollection() {
        if (typeof this.flag_bindChainsCollection === "function")
            this.flag_bindChainsCollection();
    }

    updateChains(_data) {
        console.log("updateChains _data", _data);
        this.chains = _data;
    }

    postToChain(_data) {
        console.log("postToChain _data", _data);
        return new Promise(async (resolve, reject) => {
            const url =
                _data.platform === "twitter" && _data.type === "direct_message"
                    ? "twitter/directmessage"
                    : _data.platform === "twitter" &&
                      _data.type === "post_comment"
                    ? "twitter/postcomment"
                    : _data.platform === "facebook" &&
                      _data.type === "direct_message"
                    ? "facebook/directmessage"
                    : _data.platform === "facebook" &&
                      _data.type === "post_comment"
                    ? "facebook/postcomment"
                    : _data.platform === "instagram" &&
                      _data.type === "post_comment"
                    ? "instagram/postcomment"
                    : null;

            const payloadObj =
                _data.platform === "twitter" && _data.type === "direct_message"
                    ? {
                          key: _data.key,
                          recipient_user_id: _data.recipient_user_id,
                          message: _data.message,
                      }
                    : _data.platform === "twitter" &&
                      _data.type === "post_comment"
                    ? {
                          key: _data.key,
                          reply_to_status_id: _data.target_id,
                          message: _data.message,
                      }
                    : _data.platform === "facebook" &&
                      _data.type === "direct_message"
                    ? {
                          key: _data.key,
                          recipient_user_id: _data.recipient_user_id,
                          message: _data.message,
                          type: _data.type,
                          platform: _data.platform,
                          facebook_page_id: _data.facebook_page_id,
                      }
                    : _data.platform === "facebook" &&
                      _data.type === "post_comment"
                    ? {
                          key: _data.key,
                          type: _data.type,
                          post_id: _data.post_id,
                          message: _data.message,
                          platform: _data.platform,
                          facebook_page_id: _data.facebook_page_id,
                      }
                    : _data.platform === "instagram" &&
                      _data.type === "post_comment"
                    ? {
                          key: _data.key,
                          type: _data.type,
                          media_id: _data.media_id,
                          message: _data.message,
                          platform: _data.platform,
                      }
                    : null;

            this.appApi.post(
                url,
                payloadObj,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    markChainAsRead(_key, _post_id) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "chains/markasread",
                {
                    key: _key,
                    post_id: _post_id,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    get getChains() {
        let initialChains =
            this.chains.length > 0
                ? JSON.parse(JSON.stringify(this.chains))
                : [];
        console.info("chains initialChains", initialChains);
        initialChains &&
            initialChains.map &&
            initialChains.map((chain) => {
                // console.log("chains chain.key=" + chain.key);
                // console.log(
                //     "chains chain.connection_key=" + chain.connection_key
                // );
                if (
                    chain.platform === "twitter" &&
                    chain.type === "direct_message"
                ) {
                    let ourUserId = null,
                        ourUserKey = null,
                        ourUserBrand = null;
                    const user_ids = chain.connection_key.split("_");
                    user_ids.map((user_id) => {
                        //console.log("chains user_id=" + user_id);
                        this.accounts.map((account) => {
                            // console.info(
                            //     "chains account.twitter",
                            //     account.twitter
                            // );
                            if (
                                account.twitter &&
                                account.twitter.auth &&
                                user_ids.includes(account.twitter.auth.user_id)
                            ) {
                                ourUserId = user_id;
                                ourUserKey = account.key;
                                ourUserBrand = account.brand;
                            }
                            return true;
                        });
                        return true;
                    });
                    console.log("chains ourUserId=" + ourUserId);
                    if (ourUserId) {
                        const oppositeUserId = user_ids.filter(
                            (uid) => uid !== ourUserId
                        )[0];
                        console.log("chains oppositeUserId=" + oppositeUserId);
                        chain.oppositeUser =
                            chain.chain[0].from.id &&
                            chain.chain[0].from.id === oppositeUserId
                                ? JSON.parse(
                                      JSON.stringify(chain.chain[0].from)
                                  )
                                : JSON.parse(JSON.stringify(chain.chain[0].to));
                        chain.ourUser =
                            chain.chain[0].from.id &&
                            chain.chain[0].from.id === ourUserId
                                ? JSON.parse(
                                      JSON.stringify(chain.chain[0].from)
                                  )
                                : JSON.parse(JSON.stringify(chain.chain[0].to));
                        chain.ourUser.key = ourUserKey;
                        chain.ourUser.brand = this.accounts.filter(
                            (a) => a.key === ourUserKey
                        )[0].brand;
                    }
                }

                if (
                    chain.platform === "twitter" &&
                    chain.type === "post_comment"
                ) {
                    const ourUserId = chain.chain[0].to.id;
                    const ourUserKey = chain.chain[0].to.key;

                    chain.oppositeUser = JSON.parse(
                        JSON.stringify(chain.chain[0].from)
                    );

                    this.accounts.map((account) => {
                        if (
                            account.twitter &&
                            account.twitter.auth &&
                            account.twitter.auth.user_id === ourUserId &&
                            account.key === ourUserKey
                        ) {
                            chain.ourUser = {
                                id: ourUserId,
                                key: ourUserKey,
                                name: account.name,
                                screen_name: account.twitter.auth.screen_name,
                                brand: account.brand,
                            };
                            // JSON.parse(
                            //     JSON.stringify(account)
                            // );
                        }
                        return true;
                    });
                }

                if (
                    chain.platform === "facebook" &&
                    chain.type === "direct_message"
                ) {
                    const [foo, facebook_page_id] =
                        chain.connection_key.split("_");
                    console.log(foo);
                    let facebook_page = {};
                    console.info("chains facebook_page_id=", facebook_page_id);
                    this.accounts.map((account) => {
                        if (account.facebook && account.facebook.data) {
                            account.facebook.data.map((fbChannel) => {
                                if (fbChannel.id === facebook_page_id)
                                    facebook_page = JSON.parse(
                                        JSON.stringify(fbChannel)
                                    );
                                return true;
                            });
                        }
                        return true;
                    });
                    chain.chain.map((chainElement) => {
                        if (chainElement.from.id === facebook_page_id) {
                            chainElement.from.name = facebook_page.name;
                            chain.oppositeUser = {
                                id: chainElement.to.id,
                                name: chainElement.to.name,
                                photoURL: chainElement.to.photoURL,
                            };
                        }
                        if (chainElement.to.id === facebook_page_id) {
                            chainElement.to.name = facebook_page.name;
                            chain.oppositeUser = {
                                id: chainElement.from.id,
                                name: chainElement.from.name,
                                photoURL: chainElement.from.photoURL,
                            };
                        }
                        return true;
                    });
                    console.info("chains facebook_page=", facebook_page);

                    chain.ourUser = {
                        key: facebook_page.account_key,
                        name: facebook_page.name,
                        brand:
                            this.accounts.filter(
                                (a) => a.key === facebook_page.account_key
                            ).length > 0
                                ? this.accounts.filter(
                                      (a) => a.key === facebook_page.account_key
                                  )[0].brand
                                : "N/A",
                    };
                }

                if (
                    chain.platform === "facebook" &&
                    chain.type === "post_comment"
                ) {
                    const [foo, facebook_post_id1, facebook_post_id2] =
                        chain.connection_key.split("_");
                    const facebook_post_id = `${facebook_post_id1}_${facebook_post_id2}`;
                    // let facebook_post = {};
                    console.log(foo);

                    console.info("CHAINS facebook_post_id=", facebook_post_id);

                    let ourQueueItem = null;

                    if (
                        chain.extra.our_post &&
                        this.accounts.filter(
                            (account) =>
                                account.key === chain.extra.page.account_key
                        ).length > 0
                    ) {
                        ourQueueItem =
                            chain.extra.our_post &&
                            this.accounts.filter(
                                (account) =>
                                    account.key === chain.extra.page.account_key
                            )[0].queue[chain.extra.our_post.queue_index];

                        console.info("CHAINS ourQueueItem", ourQueueItem);

                        chain.oppositeUser = chain.chain[0].from;
                        chain.ourUser = {
                            key: chain.chain[0].to.account_key
                                ? chain.chain[0].to.account_key
                                : null,
                            name: chain.chain[0].to.account_name
                                ? chain.chain[0].to.account_name
                                : null,
                            page: chain.chain[0].to.page
                                ? chain.chain[0].to.page
                                : null,
                            brand: this.accounts.filter(
                                (a) => a.key === chain.chain[0].to.account_key
                            )[0].brand,
                        };
                        chain.ourPost = ourQueueItem;
                    }
                }

                if (
                    chain.platform === "instagram" &&
                    chain.type === "post_comment"
                ) {
                    const media_owner_id = chain.extra.media.owner.id;

                    console.info("CHAINS media_owner_id=", media_owner_id);

                    let ourAccount = null;
                    ourAccount =
                        media_owner_id &&
                        this.accounts.filter(
                            (account) =>
                                account.instagram &&
                                account.instagram.data &&
                                account.instagram.data[0] &&
                                account.instagram.data[0]
                                    .instagram_account_id === media_owner_id
                        )[0];
                    console.info("CHAINS ourAccount", ourAccount);

                    chain.oppositeUser = {
                        id: chain.extra.comment.user.id,
                        name: chain.extra.comment.username,
                    };
                    if (ourAccount) {
                        chain.ourUser = {
                            key: ourAccount.key,
                            name: ourAccount.name,
                            instagram: JSON.parse(
                                JSON.stringify(
                                    ourAccount.instagram.data[0].meta
                                )
                            ),
                            brand: ourAccount.brand,
                        };
                    } else {
                        chain.ourUser = {};
                    }

                    //chain.ourPost = ourQueueItem;
                }

                return true;
            });
        return initialChains;
    }

    // --- Auditors --------------------------------------------
    loadAllAuditors(_overwrite_world_key) {
        return new Promise(async (resolve, reject) => {
            base.fetch("auditors", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: _overwrite_world_key
                        ? _overwrite_world_key
                        : this.loggedUser.world
                        ? this.loggedUser.world.key
                        : null,
                },
            })
                .then((auditorsData) => {
                    resolve(auditorsData);
                })
                .catch((error) => reject(error));
        });
    }
    bindAuditorsCollection() {
        this.flag_bindAuditorsCollection = base.listenTo("auditors", {
            context: this,
            asArray: true,
            queries: {
                orderByChild: "world_key",
                equalTo: this.loggedUser.world.key,
            },
            then(auditorsData) {
                //console.info("bind auditorsData", auditorsData);
                this.updateAuditors(auditorsData);
            },
        });
    }
    unbindAuditorsCollection() {
        if (typeof this.flag_bindAuditorsCollection === "function")
            this.flag_bindAuditorsCollection();
    }

    updateAuditors(_data) {
        console.log("updateAuditors _data", _data);
        this.auditors = _data;
    }
    addAuditor(_data) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "auditors/add",
                _data,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    updateAuditor(_key, _data) {
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "auditors/update",
                { key: _key, ..._data },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    pullAuditor(_key) {
        console.log("pullAuditor");
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "auditors/pull",
                { key: _key },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    overviewAuditor(_key, _filter) {
        console.log("overviewAuditor");
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "auditors/overview",
                {
                    key: _key,
                    filter: _filter,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    deleteAuditor(_auditor) {
        //let store = this;
        return new Promise(async (resolve, reject) => {
            base.remove(`auditors/${_auditor.key}`)
                .then(() => {
                    // update the tags
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    }
    get getAllAuditors() {
        return this.auditors;
    }
    get getAllAuditorsLength() {
        return this.auditors.length;
    }

    // --- Modal functions ------------------------------------------
    showModalByKey = (_key, _payload) => {
        //console.log("showModalByKey(" + _key + ")");
        if (!this.openModals.includes(_key)) {
            this.openModals.push(_key);
        }
        //console.info("showModalByKey this.openModals", this.openModals);

        //console.info("showModalByKey this.modalListeners", this.modalListeners);
        this.modalListeners.map((listener) => {
            if (
                listener.key === _key &&
                typeof listener.listener === "function"
            )
                listener.listener("show", _payload);
            return false;
        });
    };
    hideModalByKey = (_key, _confirm) => {
        //console.log("hideModalByKey(" + _key + ")");
        const actualHide = () => {
            if (this.openModals.includes(_key)) {
                this.openModals.splice(this.openModals.indexOf(_key), 1);
            }
            //console.info("hideModalByKey this.openModals", this.openModals);

            //this._info("hideModalByKey this.modalListeners", this.modalListeners);
            this.modalListeners.map((listener) => {
                if (
                    listener.key === _key &&
                    typeof listener.listener === "function"
                ) {
                    listener.listener("hide");
                    // callbacks
                    this.fireCallback("hide_modal", _key);
                }

                return false;
            });
        };

        if (_confirm && _confirm === true) {
            confirm({
                title: (
                    <Fragment>
                        There are data in the form.
                        <br />
                        Do you want to close the popup?
                    </Fragment>
                ),
                content:
                    "If you close the popup you will lose the information already entered in it",
                okText: "Close",
                cancelText: "Cancel",
                type: "warning",
                onOk() {
                    actualHide();
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        } else {
            actualHide();
        }
    };
    get getAllModals() {
        //console.log("getAllModals");
        return this.openModals;
    }
    registerModalListener(_key, _listener) {
        //this._info("registerModalListener _key", _key, "_listener", _listener);
        //if (this.modalListeners.filter(l => l.key === _key).length === 0) {

        // remove modal listeners if already existing
        const filteredListeners = this.modalListeners.filter(
            (listener) => listener.key !== _key
        );
        this.modalListeners = filteredListeners;

        this.modalListeners.push({
            key: _key,
            listener: _listener,
        });
        //}
    }
    unregisterModalListener(_key) {
        //console.info("unregisterModalListener _key", _key);
        const filteredListeners = this.modalListeners.filter(
            (listener) => listener.key !== _key
        );
        this.modalListeners = filteredListeners;
    }
    // getAllModals = computedFn(function getModals(_key) {
    //     const results = JSON.parse(JSON.stringify(this.modals));
    //     console.info("getAllModals results", results);
    //     return results;
    // });

    // --- Brands functions -----------------------------------------
    get getAllBrands() {
        let brands = [];
        this.accounts.map((account) => {
            if (account.brand && account.brand.length > 0)
                brands.push(account.brand.toString());
            return false;
        });
        this.masterposts.map((masterpost) => {
            if (masterpost.brand && masterpost.brand.length > 0)
                brands.push(masterpost.brand.toString());
            return false;
        });

        return brands.filter(function (item, pos) {
            return brands.indexOf(item) === pos;
        });
    }
    getBrandInsights(_brand, _date_range) {
        this._info(
            "getBrandInsights _brand",
            _brand,
            "_date_range",
            _date_range
        );
        return new Promise(async (resolve, reject) => {
            //resolve(true);

            this.appApi.post(
                "brands/getInsights",
                {
                    brand: _brand,
                    date_from: _date_range[0].format("YYYY-MM-DD"),
                    date_to: _date_range[1].format("YYYY-MM-DD"),
                },
                (data) => {
                    resolve(data);
                    //resolve(data.results.insights_results);
                },
                (error) => {
                    console.info("ERROR error", error);
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    // --- Users functions ------------------------------------
    loadAllUsers() {
        return new Promise(async (resolve, reject) => {
            base.fetch("users", {
                context: this,
                asArray: true,
                queries: {
                    orderByChild: "world_key",
                    equalTo: this.loggedUser.world.key,
                },
            })
                .then((usersData) => {
                    resolve(usersData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    bindUsersCollection() {
        this.flag_bindUsersCollection = base.listenTo("users", {
            context: this,
            asArray: true,
            queries: {
                orderByChild: "world_key",
                equalTo: this.loggedUser.world.key,
            },
            then(usersData) {
                console.info("bindUsersCollection usersData", usersData);
                this.updateAllUsers(usersData);
            },
        });
    }

    unbindUsersCollection() {
        if (typeof this.flag_bindUsersCollection === "function")
            this.flag_bindUsersCollection();
    }

    addUser(_data) {
        //let store = this;

        // Adding the world_key parameter
        if (this.loggedUser.world && this.loggedUser.world.key) {
            _data["world_key"] = this.loggedUser.world.key;
        }

        return new Promise(async (resolve, reject) => {
            this._info("addUser _data", _data);
            this._info(
                "addUser JSONED _data",
                JSON.parse(JSON.stringify(_data))
            );

            // Check whether it does existing and returns the existing account
            let existingUserByEmail = this.users.filter((comp) => {
                if (comp.email === _data.email) return comp;
                return false;
            });
            if (existingUserByEmail.length > 0) {
                resolve(existingUserByEmail[0]);
                return false;
            }

            // Update firebase
            let immediatelyAvailableReference = base
                .push("users", {
                    data: JSON.parse(JSON.stringify(_data)),
                })
                .then((newLocation) => {
                    // update the tags
                    //store.updateAllCompetitors();
                    resolve(newLocation);
                })
                .catch((error) => {
                    reject(error);
                });

            var generatedKey = immediatelyAvailableReference.key;
            this._info("generatedKey", generatedKey);
        });
    }

    deleteUser(_user) {
        //let store = this;
        return new Promise(async (resolve, reject) => {
            base.remove(`users/${_user.key}`)
                .then(() => {
                    // update the tags
                    //store.updateAllCompetitors();
                    resolve();
                })
                .catch((error) => {
                    reject();
                });
        });
    }

    updateUser(_key, _user) {
        //let store = this;

        let data = JSON.parse(JSON.stringify(_user));
        // delete key before update
        delete data.key;

        return new Promise(async (resolve, reject) => {
            base.update(`users/${_key}`, {
                data: data,
            })
                .then(() => {
                    //store.updateAllCompetitors(data); // make the tags array out of the accounts array
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    setUserLevel(_email, _level) {
        this._info("store.setUserLevel _email", _email, "_level", _level);

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "users/setUserLevel",
                {
                    email: _email,
                    level: _level,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    setUserWorld(_email, _world_key) {
        this._info(
            "store.setUserWorld _email",
            _email,
            "_world_key",
            _world_key
        );
        console.info(
            "store.setUserWorld _email",
            _email,
            "_world_key",
            _world_key
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "users/setUserWorld",
                {
                    email: _email,
                    world_key: _world_key,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    setUserDisabled(_email, _disabled) {
        this._info(
            "store.setUserDisabled _email",
            _email,
            "_disabled",
            _disabled
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "users/setUserDisabled",
                {
                    email: _email,
                    disabled: _disabled,
                },
                (data) => {
                    resolve(data.fbRes);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    createUser(_email, _password, _name, _level) {
        this._info(
            "store.createUser _email",
            _email,
            "_password",
            _password,
            "_name",
            _name,
            "_level",
            _level
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "users/createUser",
                {
                    email: _email,
                    password: _password,
                    name: _name,
                    level: _level,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }

    getUserDataByUid(_uid) {
        this._info("store.getUserDataByUid _uid", _uid);

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "users/getUserDataByUid",
                {
                    uid: _uid,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    }
    getUser(_key) {
        this._info("getUser _key=", _key);
        return new Promise(async (resolve, reject) => {
            base.fetch(`users/${_key}`, {
                context: this,
                //asArray: true
            })
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    //handle error
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                });
        });
    }

    applyUserSettings() {
        return new Promise(async (resolve, reject) => {
            const loggedUser = this.getLoggedUser;
            // getting the user
            const userData = await this.getUserDataByUid(loggedUser.uid);

            if (userData.collData && userData.collData.skin) {
                console.info("userData", userData);
                social_load_css(`themes/${userData.collData.skin}.css`);
            } else {
                // load the default theme
                social_load_css("themes/default.css");
            }

            resolve(true);
        });
    }

    updateAllUsers(_data) {
        this.users = _data;
    }
    get getAllUsers() {
        return this.users;
    }
    get getAllUsersLength() {
        return this.users.length;
    }

    // --- Messaging functions --------------------------------------
    // push_notification_token = null;
    // push_notification_subscribed = false;

    initPushMessaging = () => {
        console.log("initPushMessaging");
        try {
            let that = this;
            firebase_messaging
                .requestPermission()
                .then(function () {
                    console.log("We have permission");
                    return firebase_messaging.getToken();
                })
                .then((token) => {
                    // mark them as subscribed
                    that.push_notification_token = token;

                    console.log("Messaging token: " + token);
                    const loggedUser = that.getLoggedUser;
                    console.info("Messaging loggedUser", loggedUser);
                    const user = that.getAllUsers.filter(
                        (u) => u.email === loggedUser.email
                    )[0];
                    console.info("Messaging user", user);
                    if (!user.push_notifications) {
                        that.pushMessagingSubscribe(user, token);
                    } else if (
                        user.push_notifications &&
                        !user.push_notifications.unsubscribed
                    ) {
                        that.pushMessagingSubscribe(user, token);
                    }
                })
                .catch((error) => {
                    console.warn(error);
                    this.push_notification_subscribed = false;
                });

            firebase_messaging.onMessage((payload) => {
                console.info("onMessage payload", payload);

                notification.info({
                    message: payload.data.title,
                    description: payload.data.body,
                    duration: null,
                });
            });
            console.info("firebase_messaging", firebase_messaging);
        } catch (error) {
            console.error(error);
        }
    };

    pushMessagingSubscribe(_user, _token) {
        const token =
            typeof _token !== "undefined"
                ? _token
                : this.push_notification_token;

        let data = JSON.parse(JSON.stringify(_user));
        if (!data.push_notifications) {
            data.push_notifications = {
                tokens: [token],
            };
        } else if (typeof data.push_notifications.tokens === "undefined") {
            data.push_notifications.tokens = [token];
        } else {
            if (data.push_notifications.tokens.indexOf(token) === -1) {
                data.push_notifications.tokens.push(token);
            }
        }
        // remove potential unsubscribe flag
        data.push_notifications.unsubscribed = null;

        console.info("pushMessagingSubscribe data", data);
        this.updateUser(_user.key, data);
        this.push_notification_subscribed = true;
    }

    pushMessagingUnsubscribe(_user) {
        let data = JSON.parse(JSON.stringify(_user));

        if (
            data.push_notifications &&
            typeof data.push_notifications.tokens !== "undefined"
        ) {
            if (
                data.push_notifications.tokens.indexOf(
                    this.push_notification_token
                ) !== -1
            ) {
                data.push_notifications.tokens =
                    data.push_notifications.tokens.filter(
                        (t) => t !== this.push_notification_token
                    );
            }
        }
        console.info(
            "pushMessagingUnsubscribe data",
            data,
            "this.push_notification_token",
            this.push_notification_token
        );
        // mark that the user intentionally wanted to be unsubscribed (so no subscription on next start)
        data.push_notifications.unsubscribed = true;
        // data.push_notifications.tokens = null;
        this.updateUser(_user.key, data);
        this.push_notification_subscribed = false;
    }

    get isPushMessagingSubscribed() {
        return this.push_notification_subscribed;
    }

    // --- Worlds functions ----------------------------------------
    worldsCheckByNameAndEmail = (_name, _email) => {
        this._info(
            "store.worldsCheckByNameAndEmail _name=" +
                _name +
                ", _email=" +
                _email
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "worlds/check",
                {
                    name: _name,
                    email: _email,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    };
    worldsRegisterNew = (_data) => {
        this._info("store.worldsRegisterNew _data", _data);

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "worlds/register",
                _data,
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    };
    worldsGet = async (_key) => {
        this._info("store.worldsGet _key", _key);

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "worlds/get",
                {
                    key: _key,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    };
    // --- General functions ----------------------------------------

    getFacebookTarget = (_q, _type, _params) => {
        this._info(
            "store.getFacebookTarget _q",
            _q,
            "_type",
            _type,
            "_params",
            _params
        );

        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "searchFacebookTarget",
                {
                    q: _q,
                    type: _type,
                    params: _params,
                },
                (data) => {
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    };

    getLinkedinFacet = (_account_key, _facet) => {
        this._info(
            "store.getLinkedinFacet _account_key",
            _account_key,
            "_facet",
            _facet
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "mushrooms/linkedin_facet_options",
                {
                    key: _account_key,
                    facet: _facet,
                },
                (data) => {
                    //this._info("store.getLinkedinFacet data", data);
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    };

    getLinkedinAudienceCount = (_account_key, _criteria) => {
        this._info(
            "store.getLinkedinAudienceCount _account_key",
            _account_key,
            "_criteria",
            _criteria
        );
        return new Promise(async (resolve, reject) => {
            this._info(
                "store.getLinkedinAudienceCount INSIDE Promise _criteria",
                _criteria
            );
            this.appApi.post(
                "mushrooms/linkedin_getaudience_count",
                {
                    key: _account_key,
                    criteria: _criteria,
                },
                (data) => {
                    //this._info("store.getLinkedinFacet data", data);
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    };

    getTwitterTargetOptions = (_account_key, _criteria) => {
        this._info(
            "store.getTwitterTargetOptions _account_key",
            _account_key,
            "_criteria",
            _criteria
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "mushrooms/twitter_target_options",
                {
                    key: _account_key,
                    criteria: _criteria,
                },
                (data) => {
                    //this._info("store.getLinkedinFacet data", data);
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    };

    getTwitterFundingInstruments = (_account_key, _adaccount) => {
        this._info(
            "store.getTwitterFundingInstruments _account_key",
            _account_key,
            "_adaccount",
            _adaccount
        );
        return new Promise(async (resolve, reject) => {
            this.appApi.post(
                "twitter/getfundinginstruments",
                {
                    key: _account_key,
                    adaccount: _adaccount,
                },
                (data) => {
                    //this._info("store.getLinkedinFacet data", data);
                    resolve(data);
                },
                (error) => {
                    // Notification
                    this.displayErrorNotification(error);
                    reject(error);
                }
            );
        });
    };

    // ------------------------
    async getUrgentMessages() {
        if (this.getAllAccountsLength === 0) return [];

        let uMesssages = [];
        let allAccounts = this.getAllAccounts;

        // filter if only user
        if (this.loggedUser.level === "user" && this.loggedUser.key) {
            allAccounts = allAccounts.filter(
                (account) => account.email === this.loggedUser.email
            );
        }

        console.info("urgentMessages allAccounts", allAccounts);
        // return new Promise(function(resolve, reject) {

        // });

        await allAccounts.map(async (account) => {
            const channels = await this.getAccountChannels(account.key);
            console.info("urgentMessages channels", channels);
            channels.map((channel) => {
                // facebook
                if (
                    channel.type === "facebook" &&
                    (!channel.ad_account || channel.ad_account === "")
                ) {
                    uMesssages.push({
                        type: "warning",
                        text: (
                            <Fragment>
                                <b>
                                    <Icon
                                        type="facebook"
                                        theme="filled"
                                        style={{
                                            marginRight: 2,
                                            color: this.getTagColorForType(
                                                "facebook"
                                            ),
                                        }}
                                    />
                                    {channel.name}
                                </b>
                                <br /> has missing Ad account information.
                            </Fragment>
                        ),
                        action: {
                            type: "open_channel",
                            payload: {
                                type: "facebook",
                                account_key: account.key,
                                channel_id: channel.id,
                            },
                        },
                    });
                }

                // linkedin
                if (
                    channel.type === "linkedin" &&
                    (!channel.ad_account || channel.ad_account === "")
                ) {
                    uMesssages.push({
                        type: "warning",
                        text: (
                            <Fragment>
                                The channel{" "}
                                <b>
                                    <Icon
                                        type="linkedin"
                                        theme="filled"
                                        style={{
                                            marginRight: 2,
                                            color: this.getTagColorForType(
                                                "linkedin"
                                            ),
                                        }}
                                    />
                                    {channel.name}
                                </b>{" "}
                                has missing Ad account information.
                            </Fragment>
                        ),
                        action: {
                            type: "open_channel",
                            payload: {
                                type: "linkedin",
                                account_key: account.key,
                                channel_id: channel.id,
                            },
                        },
                    });
                }

                return false;
            });
        });

        return uMesssages;
    }

    displayErrorNotification = (_error) => {
        // Notification
        const message = _error && _error.title ? _error.title : "Error";
        const description = (
            <Fragment>
                {_error && _error.message
                    ? _error.message.toString()
                    : "Something went wrong."}
                {_error && _error.explanation ? (
                    <i>
                        {_error.explanation.split("<br>").map((l, lIndex) => (
                            <Fragment key={lIndex}>
                                {l}
                                <br />
                            </Fragment>
                        ))}
                    </i>
                ) : null}
            </Fragment>
        );

        notification.error({
            duration: 0, // Never close error notifications
            message,
            description,
        });
    };

    displaySuccessNotification = (_message, _description) => {
        notification.success({
            message: _message,
            description: _description,
        });
    };

    getTagColorForType = (_type) => {
        let color = "#000";

        switch (_type) {
            case "facebook":
                color = "#4267b2";
                break;
            case "twitter":
                color = "#1da1f2";
                break;
            case "instagram":
                color = "#e4405f";
                break;
            case "linkedin":
                color = "#0073b1";
                break;
            case "youtube":
                color = "#ff0100";
                break;
            case "authenticated":
                color = scss_variables.scss_color_pallete_neutral;
                break;
            case "immediately":
            case "regularly":
            case "scheduled":
                color = scss_variables.scss_color_pallete_neutral;
                break;
            case "upcoming":
                color = scss_variables.scss_color_pallete_default;
                break;
            case "processing":
                color = scss_variables.scss_color_pallete_neutral;
                break;
            case "posted":
                color = scss_variables.scss_color_pallete_default;
                break;
            case "error":
                color = scss_variables.scss_color_pallete_error;
                break;
            case "approval":
                color = scss_variables.scss_color_pallete_neutral;
                break;
            case "primary":
                color = scss_variables.scss_color_pallete_primary;
                break;
            case "campaign":
                color = scss_variables.scss_color_pallete_campaign;
                break;
            case "category":
                color = scss_variables.scss_color_pallete_category;
                break;
            case "tag":
                color = scss_variables.scss_color_pallete_tag;
                break;
            case "neutral":
                color = scss_variables.scss_color_pallete_neutral;
                break;
            case "success":
                color = scss_variables.scss_color_pallete_success;
                break;
            case "default":
                color = scss_variables.scss_color_pallete_default;
                break;
            case "warning":
                color = scss_variables.scss_color_pallete_warning;
                break;
            case "info":
                color = scss_variables.scss_color_pallete_info;
                break;
            default:
                color = "#000";
                break;
        }
        return color;
    };

    humanFileSize = (bytes) => {
        var thresh = 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + " B";
        }
        var units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + " " + units[u];
    };

    // TODO: Sync tag colors with scss variables and config file
}
/*
Declaring the Person class with id, first_name, last_name, birthday, relationship and birthdayInMonths
*/

/*
class Person {
	id;
	first_name;
	last_name;
	birthday;
	relationship;
	birthdayInMonths;

	constructor(_person) {
		this.id = parseInt(_person.id, 10);
		this.first_name = _person.first_name;
		this.last_name = _person.last_name;
		this.birthday = _person.birthday;
		this.relationship = _person.relationship;
		this.birthdayInMonths = this.timeToBirthdayInMonths; // compute this during creation with the next function
	}

	// calculate the birthday in months from the current date for the current year
	get timeToBirthdayInMonths() {
		const thisYearBirthday = Moment()
			.month(Moment(this.birthday).month())
			.date(Moment(this.birthday).date());

		return thisYearBirthday.diff(Moment(), "months");
	}
}

/*
Declaring the Alert class with id, show, variant, title and text
*/

decorate(AppStore, {
    flag_allLoaded: observable,
    loggedUser: observable,
    accounts: observable,
    tags: observable,
    masterposts: observable,
    categories: observable,
    campaigns: observable,
    ads: observable,
    competitors: observable,
    users: observable,
    currentSection: observable,
    breadcrumbs: observable,
    library: observable,
    upload: observable,
    schedule: observable,
    notifications: observable,
    masterads: observable,
    subscriptions: observable,
    temp_data: observable,
    chains: observable,
    auditors: observable,

    setTempData: action,
    removeTempData: action,
    getTempData: action,

    getLoggedUser: computed,
    saveLoggedUser: action,
    resetLoggedUser: action,

    getCurrentSection: computed,
    setCurrentSection: action,

    loadAllAccounts: action,
    bindAccountsCollection: action,
    unbindAccountsCollection: action,
    addAccount: action,
    deleteAccount: action,
    updateAccount: action,
    preAuthAccount: action,
    authAccount: action,
    saveAuthDataAccount: action,
    getLinkedinPagesForAccount: action,
    addLinkedinOrganizationAccount: action,
    unlinkAccountSocial: action,
    testAccountChannel: action,
    getAccountChannelContents: action,
    getAccountChannelPosts: action,
    getAccountChannelInsights: action,
    getAccountAuthData: action,
    getFacebookChannels: action,
    getParsedPost: action,
    updateAllAccounts: action,
    getAllAccounts: computed,
    getAllAccountsLength: computed,
    getAccount: action,
    getAccountChannels: action,
    getAccountsByRules: action,
    getTargetByQueuePost: action,
    updateAccountQueuePost: action,
    approveAccountQueuePost: action,
    getAccountCustomAudience: action,
    postQueuePostByIdAndChannelsChannels: action,
    publishCommentOnChannelPost: action,

    updateAllTags: action,
    applyTagsToChannels: action,
    deleteTag: action,
    editTag: action,
    getAllTags: computed,
    getAllTagsLength: computed,

    loadAllMasterposts: action,
    bindMasterpostsCollection: action,
    unbindMasterpostsCollection: action,
    updateAllMasterposts: action,
    addMasterpost: action,
    deleteMasterpost: action,
    updateMasterpost: action,
    rescheduleMasterpost: action,
    //getMasterpostStatus: computed,
    getLatestMasterposts: computed,
    getAllMasterposts: computed,
    getAllMasterpostsLength: computed,

    updateAllCategories: action,
    applyCategoriesToMasterposts: action,
    deleteCategory: action,
    editCategory: action,
    getAllCategories: computed,
    getAllCategoriesLength: computed,

    updateAllCampaigns: action,
    applyCampaignsToMasterposts: action,
    removeCampaignsToMasterposts: action,
    applyCampaignsToMasterads: action,
    removeCampaignsToMasterads: action,
    deleteCampaign: action,
    editCampaign: action,
    getAllCampaigns: computed,
    getAllCampaignsLength: computed,

    loadAllAds: action,
    bindAdsCollection: action,
    unbindAdsCollection: action,
    updateAllAds: action,
    addAd: action,
    deleteAd: action,
    updateAd: action,
    getAdInsights: action,
    updateAdStatus: action,
    getAllAds: computed,
    getAllAdsLength: computed,

    loadAllMasterads: action,
    bindMasteradsCollection: action,
    unbindMasteradsCollection: action,
    updateAllMasterads: action,
    addMasterad: action,
    deleteMasterad: action,
    updateMasterad: action,
    getAllMasterads: computed,
    getAllMasteradsLength: computed,

    loadAllAuditors: action,
    bindAuditorsCollection: action,
    unbindAuditorsCollection: action,
    updateAllAuditors: action,
    addAuditor: action,
    updateAuditor: action,
    deleteAuditor: action,
    getAllAuditors: computed,
    getAllAuditorsLength: computed,

    loadSubscriptions: action,
    updateSubscriptions: action,
    paySubscription: action,
    payWorldSubscription: action,
    updateSubscription: action,
    getSubscriptions: computed,

    loadChains: action,
    updateChains: action,
    postToChain: action,
    markChainAsRead: action,
    getChains: computed,

    initBreadcrumbs: action,
    setBreadcrumbs: action,
    getBreadcrumbs: computed,
    getBreadcrumbsParsed: computed,

    loadAllLibrary: action,
    bindLibraryCollection: action,
    unbindLibraryCollection: action,
    updateAllLibrary: action,
    getAllLibrary: computed,
    getAllLibraryLength: computed,
    addLibraryFile: action,
    updateLibraryFile: action,
    deleteLibraryItem: action,
    toggleLibraryItemLock: action,
    updateLibraryNotifications: action,

    loadAllSchedule: action,
    bindScheduleCollection: action,
    unbindScheduleCollection: action,
    updateAllSchedule: action,
    getAllSchedule: computed,
    getAllScheduleLength: computed,

    loadAllNotifications: action,
    bindNotificationsCollection: action,
    unbindNotificationsCollection: action,
    updateAllNotifications: action,
    addNotification: action,
    deleteNotification: action,
    updateNotification: action,
    getAllNotifications: computed,
    getAllNotificationsLength: computed,
    getNotificationRelatedElement: action,

    showModalByKey: action,
    hideModalByKey: action,
    getAllModals: computed,

    getAllReports: computed,
    addCustomReport: action,
    deleteCustomReport: action,
    getAllCustomReports: action,

    getAllBrands: computed,

    loadAllCompetitors: action,
    bindCompetitorsCollection: action,
    unbindCompetitorsCollection: action,
    addCompetitor: action,
    deleteCompetitor: action,
    updateCompetitor: action,
    checkCompetitorSocialHandle: action,
    updateAllCompetitors: action,
    getAllCompetitors: computed,
    getAllCompetitorsLength: computed,

    updateAllUsers: action,
    addUser: action,
    deleteUser: action,
    updateUser: action,
    setUserDisabled: action,
    setUserLevel: action,
    applyUserSettings: action,
    getAllUsers: computed,
    getAllUsersLength: computed,

    initPushMessaging: action,
    pushMessagingSubscribe: action,
    pushMessagingUnsubscribe: action,
    isPushMessagingSubscribed: computed,

    getUrgentMessages: action,

    // -----
    worldsCheckByName: action,
    worldsRegisterNew: action,
    worldsGet: action,
});

// declare a clone in window.store so we can monitor the store from the console
var store = (window.store = new AppStore());

export default store;
