import React, { Component, Fragment } from "react";
import config from "../../config";
import tooltips from "../../tooltips";

import { observer } from "mobx-react";
import {
    social_notification,
    social_sleep,
    social_setCookie,
} from "./../../functions";

import AccountsChannelsTwitterModal from "./AccountsChannelsTwitterModal";
import AccountsChannelsLinkedinModal from "./AccountsChannelsLinkedinModal";
import AccountsChannelsGoogleModal from "./AccountsChannelsGoogleModal";
import AccountsChannelsModal from "./AccountsChannelsModal";
import scss_variables from "./../../_variables.scss";
import {
    Empty,
    Button,
    List,
    Icon,
    Tag,
    Typography,
    Input,
    Row,
    Col,
    Badge,
    Menu,
    Dropdown,
    Breadcrumb,
    Tooltip,
    Modal,
} from "antd";
import { firebase, googleProvider } from "./../../base";
import SocialSeparator from "./../SocialSeparator";
import SocialButton from "./../SocialButton";
import ReportsModal from "./../Reports/ReportsModal";
import SocialChannel from "./../SocialChannel";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class AccountChannelsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: null,
            account: null,
            channels: [],
            filter: "",
            facebook_authloading: false,
            testing_channel: false,
            testing_channel_id: null,
            //
            instagram_authloading: false,
            //
            report_modal_visible: false,
            //
            sortby: "name",
            sortasc: true,
        };
    }

    UNSAFE_componentWillMount() {
        //this.props.store.setCurrentSection("settings");
        //var path = this.props.params.splat;
        //console.info("AccountsReport splat/path (passed parameter) = ", path);
        console.info("AccountChannelsList this.props ", this.props);
        this.setState({ accountId: this.props.match.params.id });

        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            { route: `/dashboard/accounts`, title: "Accounts" },
            { title: "Channels" },
        ]);
    }

    async componentDidMount() {
        //Loading the account
        await this.reloadChannels();
    }

    componentWillUnmount() {
        this.props.store.unregisterModalListener("reports_modal");
    }

    async reloadChannels() {
        console.info("reloadChannels");
        this.props.store
            .getAccount(this.state.accountId)
            .then((accountData) => {
                this.setState({
                    account: accountData,
                });

                return this.props.store.getAccountChannels(
                    this.state.accountId
                );
            })
            .then((channels) => {
                console.info("AccountsChannels channels", channels);
                this.setState({
                    channels,
                });
            })
            .catch((error) => {
                social_notification("account_reload_channels", "error", {
                    description: error,
                });
            });
    }

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    handleMenuClick = (_evt) => {
        console.log("click", _evt);
    };

    handleSocialLogin = async (user) => {
        console.info("handleSocialLogin user", user);
        console.info(
            "handleSocialLogin this.state.accountId",
            this.state.accountId
        );

        if (user._provider === "google") {
            await this.proceedGoogleAuth(user);
            return;
        } else {
            // make the button spinning
            this.setState({ facebook_authloading: true });
        }

        this.props.store
            .preAuthAccount(user._provider, this.state.accountId, user)
            .then((fbRes) => {
                // update the user token
                user._token = fbRes;

                return this.props.store.authAccount(
                    user._provider,
                    this.state.accountId,
                    user
                );
            })
            .then(() => {
                return this.props.store.getAccountAuthData(
                    this.state.accountId,
                    user._provider
                );
            })
            .then(async (authData) => {
                console.info("getAccountAuthData > authData", authData);

                if (authData.igAccounts.length > 0) {
                    // we have instagram data
                    await this.props.store.saveAuthDataAccount(
                        "instagram",
                        this.state.accountId,
                        authData.igAccounts
                    );
                }

                if (authData.fbPages.data.length > 0) {
                    return this.props.store.saveAuthDataAccount(
                        user._provider,
                        this.state.accountId,
                        authData.fbPages.data
                    );
                }
            })
            .then((updatedUserData) => {
                console.info("updatedUserData", updatedUserData);
                //updatedUserData.key = this.state.accountId.toString();
                this.setState({
                    facebook_authloading: false,
                });
                this.reloadChannels();
            })
            .catch((error) => {
                console.warn(error);
                social_notification("account_get_channels", "error", {
                    description: error,
                });
            });
    };

    proceedGoogleAuth = async (user) => {
        console.info("proceedGoogleAuth user", user);
        await this.props.store
            .authAccount(user._provider, this.state.accountId, user)
            .then(async (result) => {
                console.info("result", result);
                // this.reloadChannels();
                return await this.props.store.pullAccountYoutubeChannels(
                    this.state.accountId
                );
            })
            .then(async (ytChannels) => {
                return await this.props.store.accountExtendYoutubeToken(
                    this.state.accountId,
                    user._token.idToken
                );
                // return new Promise((resolve, reject) => {
                //     console.log("proceedGoogleAuth -> authAccount user", user);
                //     resolve(ytChannels);
                // });
            })
            .then(async (ytChannels) => {
                console.info("ytChannels", ytChannels);
            });
    };

    handleSocialLoginFailure = (err) => {
        console.error(err);
    };

    handleSocialLoginClick = (_evt) => {
        console.info("handleSocialLoginClick _evt", _evt);
        this.setState({ facebook_authloading: true });
    };

    handleSocialUnlink = (_provider, _element_id) => {
        console.info(
            "handleSocialUnlink _provider",
            _provider,
            "_element_id",
            _element_id
        );
        // console.info(
        //     "handleSocialUnlink this.state.accountId",
        //     this.state.accountId
        // );
        let that = this;
        confirm({
            title: "Are you sure unlink this channel?",
            okType: "danger",
            onOk() {
                if (that.state.accountId) {
                    that.props.store
                        .unlinkAccountSocial(
                            that.state.accountId,
                            _provider,
                            _element_id
                        )
                        .then((editData) => {
                            that.reloadChannels();
                        })
                        .catch((error) => {
                            console.warn(error);
                            social_notification(
                                "account_unlink_channels",
                                "error",
                                {
                                    description: error,
                                }
                            );
                        });
                }
            },
        });
    };

    handleSocialTest = (_provider, _channel_id, _type) => {
        console.info(
            "handleSocialTest _provider",
            _provider,
            "_channel_id",
            _channel_id,
            "_type",
            _type
        );

        this.setState({
            testing_provider: _provider,
            testing_channel: true,
            testing_channel_id: _channel_id,
        });

        const rand = Math.random() * (10000 - 1) + 1;

        let _message = `Sample Welcome Message ${rand}`; //`Sample Welcome Message (${rand})`;
        let _caption = "";
        let _url = "";
        let _description = "";
        let _file_url = "";

        if (_type === "text") {
            _message = `Sample Welcome Message ${rand}`; //`Sample Welcome Message (${rand})`;
            _caption = "";
            _url = "";
            _description = "";
            _file_url = "";
        }

        if (_type === "photo") {
            _message = "";
            _caption = `Sample Photo Caption ${rand}`; //`Sample Photo Caption (${rand})`;
            // _url =
            //     "https://cdn.pixabay.com/photo/2017/12/29/18/47/nature-3048299_960_720.jpg";
            _url =
                "https://photographycourse.net/wp-content/uploads/2014/11/Landscape-Photography-steps.jpg";
            _description = "";
            _file_url = "";
        }

        if (_type === "video") {
            _message = "";
            _caption = "";
            _url = "";
            _description = `Sample Video Description ${rand}`;
            _file_url =
                "https://firebasestorage.googleapis.com/v0/b/social-6ce6c.appspot.com/o/1%20OCTOBER%2016%20VIDEO%20need%20to%20edit_1_try2_constant_30.mp4?alt=media&token=4654f82e-1496-4aff-a8e3-c04a6631cda9";
        }

        if (_type === "multiplephotos") {
            _message = "";
            _caption = "";
            _url = "";
            _description = `Multiple Photos Description ${rand}`;
            _file_url = [
                "https://firebasestorage.googleapis.com/v0/b/social-6ce6c.appspot.com/o/testing%2Fimage1.jpg?alt=media&token=41192e67-579f-4fc5-bf39-68a98c84cd9a",
                "https://firebasestorage.googleapis.com/v0/b/social-6ce6c.appspot.com/o/testing%2Fimage2.jpg?alt=media&token=dee0aa4f-0a39-4595-bf23-35638832247a",
                "https://firebasestorage.googleapis.com/v0/b/social-6ce6c.appspot.com/o/testing%2Fimage3.jpg?alt=media&token=6e92591e-fae3-4a9b-879b-368c7fe0f859",
            ];
        }

        // cancel if for linkedin
        if (_type === "multiplephotos" && _provider === "linkedin")
            return false;

        if (this.state.accountId) {
            this.props.store
                .testAccountChannel(
                    this.state.accountId,
                    _provider,
                    _channel_id,
                    _type,
                    _message,
                    _caption,
                    _url,
                    _description,
                    _file_url
                )
                .then((testData) => {
                    console.info("testData", testData);
                    this.setState({
                        testing_channel: false,
                        testing_channel_id: null,
                        testing_provider: null,
                    });
                    let resultLink = "#";

                    if (_provider === "facebook") {
                        resultLink =
                            "https://www.facebook.com/" + testData.fbRes.id;
                    }
                    if (_provider === "twitter") {
                        resultLink = `https://twitter.com/${testData.user.screen_name}/status/${testData.id_str}`;
                    }
                    if (_provider === "instagram") {
                        resultLink = testData.permalink;
                    }
                    console.log("resultLink = " + resultLink);
                    social_notification("account_test_channel", "success", {
                        description: (
                            <Fragment>
                                Click{" "}
                                <a
                                    href={resultLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </a>{" "}
                                to open the test post
                            </Fragment>
                        ),
                    });
                })
                .catch((error) => {
                    console.warn(error);

                    this.setState({
                        testing_channel: false,
                        testing_channel_id: null,
                        testing_provider: null,
                    });

                    return false;
                });
        } else {
            var editData = this.state.edit_data;
            editData.facebook = null;
            this.setState({ edit_data: editData });
            console.info(
                "handleUnauth this.state.edit_data",
                this.state.edit_data
            );
        }
    };

    handleSocialReport = (_evt, _report_key, _item) => {
        console.info(
            "handleSocialReport _report_key,",
            _report_key,
            "_item",
            _item
        );
        let allReports = this.props.store.getAllReports;
        const report = allReports.filter(
            (r) => r.key === _report_key + "_" + _item.type
        )[0];
        console.info("report", report);

        this.setState({
            //report_modal_visible: true,
            selected_report: report,
            selected_channel: _item,
        });

        this.props.store.showModalByKey("reports_modal");
    };
    handleReportModalCancel = (_evt) => {
        this.setState({
            //report_modal_visible: false,
            selected_report: null,
            selected_channel: null,
        });
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        this.setState({ sortby: _sorting, sortasc });
    };

    saveReportFormRef = (formRef) => {
        this.formRef = formRef;
    };

    showChannel = (_evt, _account, _provider, _channel_id) => {
        console.info(
            "showChannel _evt",
            _evt,
            "_account",
            _account,
            "_provider",
            _provider,
            "_channel_id",
            _channel_id
        );

        this.props.history.push(
            `/dashboard/accounts/${_account.key}/channel/${_provider}/${_channel_id}`
        );
    };

    showTwitterPopup = () => {
        console.log("showTwitterPopup");
        this.props.store.showModalByKey("accounts_channels_twitter_modal");
    };

    showEditModal = (_evt, _account, _channel) => {
        console.log("showEditModal _channel", _channel);
        this.setState({ selected_channel: _channel });
        this.props.store.showModalByKey("accounts_channels_modal");
    };
    showLinkedinPopup = () => {
        console.log("showLinkedinPopup");
        //let loggedUser = this.props.store.getLoggedUser;

        social_setCookie("link_linkedin_account_key", this.state.accountId, 7);
        //social_setCookie("link_linkedin_bearer", loggedUser.idToken, 7);
        //this.props.store.showModalByKey("accounts_channels_linkedin_modal");
        const left = Number(window.screen.availWidth) / 2 - 250;
        let win = window.open(
            window.location.origin + `/linkedinhandler`,
            "LinkedinPopup",
            "width=500,height=600,status=no,resizable=no,location=no,top=200,left=" +
                left
        );
        let that = this;
        win.onbeforeunload = async () => {
            console.log("reload on close");
            await that.reloadChannels();
        };
    };

    showGooglePopup = () => {
        console.log("showGooglePopup");
        this.props.store.showModalByKey("accounts_channels_google_modal");
    };

    // showGooglePopup = () => {
    //     const provider = googleProvider;
    //     provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    //     firebase
    //         .auth()
    //         .signInWithPopup(provider)
    //         .then(function (result) {
    //             // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
    //             // You can use these server side with your app's credentials to access the Twitter API.
    //             //var token = result.credential.accessToken;
    //             //var secret = result.credential.secret;
    //             // The signed-in user info.
    //             //var user = result.user;
    //             // ...
    //             console.info("result", result);
    //         })
    //         .catch(function (error) {
    //             // Handle Errors here.
    //             //var errorCode = error.code;
    //             //var errorMessage = error.message;
    //             // The email of the user's account used.
    //             //var email = error.email;
    //             // The firebase.auth.AuthCredential type that was used.
    //             //var credential = error.credential;
    //             // ...
    //             console.error(error);
    //         });
    // };

    handleHideTwitterPopup = async () => {
        //async reloadChannels
        console.info("handleHideTwitterPopup");
        await social_sleep(100);
        console.info("handleHideTwitterPopup AFTER");
        await this.reloadChannels();
    };
    handleHideLinkedinPopup = async () => {
        //async reloadChannels
        console.info("handleHideLinkedinPopup");
        await social_sleep(100);
        console.info("handleHideLinkedinPopup AFTER");
        await this.reloadChannels();
    };
    handleHideGooglePopup = async () => {
        //async reloadChannels
        console.info("handleHideGooglePopup");
        await social_sleep(100);
        console.info("handleHideGooglePopup AFTER");
        await this.reloadChannels();
    };

    callbackLinkedIn = (error, code, redirectUri) => {
        if (error) {
            // signin failed
        } else {
            // Obtain authorization token from linkedin api
            // see https://developer.linkedin.com/docs/oauth2 for more info
        }
    };

    onModalCallback = async () => {
        console.info("onModalCallback");
        await this.reloadChannels();
    };

    render() {
        const loggedUser = this.props.store.getLoggedUser;
        console.info("AccountsChannels loggedUser", loggedUser);

        let channels = this.state.channels.filter((a) => {
            return (
                a.name
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1
            );
        });

        // --- sorting ---
        channels.sort((a, b) => {
            // console.info(
            //     "channels.sort (this.state.sortby)",
            //     this.state.sortby
            // );

            var nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();

            switch (this.state.sortby) {
                case "name":
                    if (this.state.sortasc === true) {
                        if (nameA < nameB) return -1;
                    } else {
                        if (nameA > nameB) return 1;
                    }
                    break;

                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting
        const header = (
            <SocialListHeader
                title={
                    <Fragment>
                        {this.state.account != null && this.state.account.name}{" "}
                        / Channels
                    </Fragment>
                }
                // newButtonClickHandle={this.showModal}
                // newButtonText="New tag"
                // newButtonTooltip={tooltips.TAGS.NEWBUTTON}
                // bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );

        // let header = (
        //     <Row
        //         type="flex"
        //         justify="space-between"
        //         style={{ marginBottom: 10 }}
        //     >
        //         <Col>
        //             <Title level={3} style={{ float: "left", paddingRight: 5 }}>
        //                 {this.state.account != null && this.state.account.name}{" "}
        //                 / Channels
        //             </Title>

        //             <Badge
        //                 count={this.state.channels.length}
        //                 showZero
        //                 style={{ backgroundColor: "#1890ff" }}
        //             />
        //         </Col>
        //     </Row>
        // );

        let footer = (
            <Row style={{ marginTop: 70 }}>
                <Col>
                    <SocialSeparator>Available Channels</SocialSeparator>
                    <SocialButton
                        provider="facebook"
                        icon="facebook"
                        // scope="manage_pages,publish_pages,read_insights,instagram_basic,pages_show_list,instagram_manage_insights,ads_management"
                        scope={
                            config.facebook.scope
                        } /*"manage_pages,publish_pages,read_insights,instagram_basic,instagram_manage_comments,pages_show_list,ads_management,pages_read_engagement,pages_messaging"*/
                        appId={config.facebook.client_id}
                        onLoginSuccess={this.handleSocialLogin}
                        onLoginFailure={this.handleSocialLoginFailure}
                        loading={
                            this.state.facebook_authloading
                                ? this.state.facebook_authloading
                                : undefined
                        }
                        style={{
                            padding: "6px 10px",
                            width: 250,
                            color: "white",
                            background: "#4267b2",
                            fontWeight: 500,
                            border: "none",
                            borderRadius: 4,
                            marginBottom: 10,
                        }}
                    >
                        Link Facebook/Instagram Pages
                    </SocialButton>
                    {/* <div className={"FacebookButton"}>
                        <Tooltip
                            title={
                                tooltips.ACCOUNTS.CHANNELS_LINKBUTTON_FACEBOOK
                            }
                            mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                        ></Tooltip>
                    </div> */}
                    <div
                        className={"TwitterButton"}

                        // scope="manage_pages,publish_pages,read_insights,instagram_basic,pages_show_list,instagram_manage_insights,ads_management"
                        // appId={config.facebook.client_id}
                        // onLoginSuccess={this.handleSocialLogin}
                        // onLoginFailure={this.handleSocialLoginFailure}
                        // loading={this.state.facebook_authloading}
                    >
                        <Tooltip
                            title={
                                tooltips.ACCOUNTS.CHANNELS_LINKBUTTON_TWITTER
                            }
                            mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                        >
                            <button
                                style={{ padding: "6px 0", width: 250 }}
                                onClick={this.showTwitterPopup}
                            >
                                Link Twitter profile
                            </button>
                        </Tooltip>
                    </div>
                    <div
                        className={"LinkedinButton"}

                        // scope="manage_pages,publish_pages,read_insights,instagram_basic,pages_show_list,instagram_manage_insights,ads_management"
                        // appId={config.facebook.client_id}
                        // onLoginSuccess={this.handleSocialLogin}
                        // onLoginFailure={this.handleSocialLoginFailure}
                        // loading={this.state.facebook_authloading}
                    >
                        <Tooltip
                            title={
                                tooltips.ACCOUNTS.CHANNELS_LINKBUTTON_LINKEDIN
                            }
                            mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                        >
                            <button
                                style={{ padding: "6px 0", width: 250 }}
                                onClick={this.showLinkedinPopup}
                            >
                                Link Linkedin profile
                            </button>
                        </Tooltip>
                    </div>
                    <div
                        className={"YoutubeButton"}

                        // scope="manage_pages,publish_pages,read_insights,instagram_basic,pages_show_list,instagram_manage_insights,ads_management"
                        // appId={config.facebook.client_id}
                        // onLoginSuccess={this.handleSocialLogin}
                        // onLoginFailure={this.handleSocialLoginFailure}
                        // loading={this.state.facebook_authloading}
                    >
                        <Tooltip
                            title={
                                tooltips.ACCOUNTS.CHANNELS_LINKBUTTON_YOUTUBE
                            }
                            mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                        >
                            <button
                                style={{ padding: "6px 0", width: 250 }}
                                onClick={this.showGooglePopup}
                            >
                                Link Google/Youtube profile
                            </button>
                        </Tooltip>
                    </div>

                    {/* <Button
                        style={{
                            background: "#e95950",
                            borderColor: "#e95950",
                            color: "white",
                            marginLeft: 20,
                            opacity: 0.3
                        }}
                        disabled
                        icon="instagram"
                    >
                        Link Instagram Profile
                    </Button> */}
                </Col>
            </Row>
        );
        let body =
            channels.length === 0 ? (
                <Empty
                    description={"No Channels Yet"}
                    imageStyle={{ margin: 30 }}
                />
            ) : (
                <List
                    size="small"
                    className="AccountsChannelsList"
                    header={
                        <Fragment>
                            {/* <Row type="flex" justify="end">
                                <Col>
                                    <Search
                                        placeholder="input search text"
                                        onSearch={this.onSearchChange}
                                        defaultValue={this.state.filter}
                                        style={{ width: 200 }}
                                    />
                                </Col>
                            </Row> */}
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Channel name
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1 }}>Options</Col>
                                <Col style={{ width: 210 }}>Tools</Col>
                            </Row>
                        </Fragment>
                    }
                    bordered
                    dataSource={channels}
                    renderItem={(item) => (
                        <List.Item
                            key={item.id}
                            actions={[
                                <Tooltip
                                    key="1"
                                    title={tooltips.ACCOUNTS.CHANNELS_LIST_OPEN}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showChannel(
                                                _evt,
                                                this.state.account,
                                                item.type,
                                                item.id
                                            );
                                        }}
                                    >
                                        <Icon type="profile" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="2"
                                    title={tooltips.ACCOUNTS.CHANNELS_LIST_EDIT}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showEditModal(
                                                _evt,
                                                this.state.account,
                                                item
                                            );
                                        }}
                                    >
                                        <Icon type="edit" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="3"
                                    title={
                                        tooltips.ACCOUNTS.CHANNELS_LIST_REPORT
                                    }
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.handleSocialReport(
                                                _evt,
                                                "channel_monthly",
                                                item
                                            );
                                        }}
                                    >
                                        <Icon type="pie-chart" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="4"
                                    title={
                                        tooltips.ACCOUNTS.CHANNELS_LIST_UNLINK
                                    }
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    {/* <Text type="danger">unlink</Text> */}
                                    <Icon
                                        type="delete"
                                        onClick={(evt) => {
                                            this.handleSocialUnlink(
                                                item.type,
                                                item.id
                                            );
                                        }}
                                        style={{
                                            color: scss_variables.scss_color_pallete_error,
                                        }}
                                    />
                                </Tooltip>,
                                // <Tooltip
                                //     key="5"
                                //     title={tooltips.ACCOUNTS.CHANNELS_LIST_TEST}
                                //     mouseEnterDelay={
                                //         tooltips.SETTINGS.mouseEnterDelay
                                //     }
                                // >
                                //     <Dropdown
                                //         overlay={
                                //             <Menu
                                //                 onClick={(evt) => {
                                //                     this.handleSocialTest(
                                //                         item.type,
                                //                         item.id,
                                //                         evt.key
                                //                     );
                                //                 }}
                                //             >
                                //                 <Menu.Item
                                //                     key="text"
                                //                     disabled={
                                //                         item.type ===
                                //                         "instagram"
                                //                     }
                                //                 >
                                //                     Welcome Text Message
                                //                 </Menu.Item>
                                //                 <Menu.Item key="photo">
                                //                     Sample Photo
                                //                 </Menu.Item>
                                //                 <Menu.Item
                                //                     key="multiplephotos"
                                //                     disabled={
                                //                         item.type ===
                                //                             "linkedin" ||
                                //                         item.type ===
                                //                             "instagram"
                                //                     }
                                //                 >
                                //                     Sample Multiple Photos
                                //                 </Menu.Item>
                                //                 <Menu.Item
                                //                     key="video"
                                //                     disabled={
                                //                         item.type ===
                                //                         "instagram"
                                //                     }
                                //                 >
                                //                     Sample Video
                                //                 </Menu.Item>
                                //             </Menu>
                                //         }
                                //         // disabled={item.type === "instagram"}
                                //     >
                                //         <Button
                                //             type="link"
                                //             className="ant-dropdown-link"
                                //             style={{
                                //                 display: "inline-block",
                                //                 padding: 0,
                                //             }}
                                //         >
                                //             {this.state.testing_channel_id !==
                                //             item.id ? (
                                //                 <Icon type="carry-out" />
                                //             ) : (
                                //                 ""
                                //             )}
                                //             <Icon
                                //                 style={{ marginLeft: 5 }}
                                //                 type={
                                //                     this.state
                                //                         .testing_channel_id !==
                                //                         item.id &&
                                //                     this.state
                                //                         .testing_provider !==
                                //                         item.type
                                //                         ? "down"
                                //                         : "loading"
                                //                 }
                                //             />
                                //         </Button>
                                //     </Dropdown>
                                // </Tooltip>,
                            ]}
                        >
                            <div>
                                <SocialChannel
                                    store={this.props.store}
                                    //type="filled"
                                    icon
                                    channel={item}
                                    extra={" "}
                                    style={{ marginTop: -5 }}
                                />
                            </div>

                            <div>
                                <Fragment>
                                    {item.location &&
                                    item.location.length > 0 ? (
                                        <div style={{ marginBottom: 5 }}>
                                            <Icon
                                                type="environment"
                                                style={{
                                                    float: "left",
                                                    margin: "3px 5px 5px 0",
                                                }}
                                            />
                                            <Breadcrumb
                                                style={{
                                                    color: this.props.store.getTagColorForType(
                                                        "primary"
                                                    ),
                                                }}
                                            >
                                                {item.location.map((loc) => (
                                                    <Breadcrumb.Item key={loc}>
                                                        <span>{loc}</span>
                                                    </Breadcrumb.Item>
                                                ))}
                                            </Breadcrumb>
                                        </div>
                                    ) : null}

                                    {/* TODO: Add Twitter here
                                     */}
                                </Fragment>
                                {/* {item.tags &&
                                item.tags.length > 0 &&
                                item.location &&
                                item.location.length > 0 ? (
                                    <br />
                                ) : null} */}
                                {item.tags &&
                                    item.tags.map((tag) => (
                                        <Tag
                                            color={this.props.store.getTagColorForType(
                                                "tag"
                                            )}
                                            key={tag}
                                        >
                                            {tag}
                                        </Tag>
                                    ))}
                            </div>
                        </List.Item>
                    )}
                />
            );

        let reportModal = (
            <ReportsModal
                report={this.state.selected_report}
                report_channel={this.state.selected_channel}
                store={this.props.store}
            />
        );

        let twitterModal = (
            <AccountsChannelsTwitterModal
                store={this.props.store}
                account={this.state.account}
                callback={this.handleHideTwitterPopup}
            />
        );
        let linkedinModal = (
            <AccountsChannelsLinkedinModal
                store={this.props.store}
                account={this.state.account}
                callback={this.handleHideLinkedinPopup}
            />
        );
        let googleModal = (
            <AccountsChannelsGoogleModal
                store={this.props.store}
                account={this.state.account}
                callback={this.handleHideGooglePopup}
            />
        );

        let channelModal = (
            <AccountsChannelsModal
                store={this.props.store}
                account={this.state.account}
                data={this.state.selected_channel}
                closeCallback={this.onModalCallback}
            />
        );

        return (
            <Fragment>
                {header}
                {body}
                {footer}
                {reportModal}
                {twitterModal}
                {linkedinModal}
                {googleModal}
                {channelModal}
            </Fragment>
        );
    }
}

export default observer(AccountChannelsList);
