import React, { Fragment } from "react";
import moment from "moment";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Table,
    Button,
    Empty,
    Divider,
} from "antd";

import scss_variables from "./../../_variables.scss";
import ReportSingleValue from "./ReportSingleValue";
import ReportGraph from "./ReportGraph";

const { Panel } = Collapse;

const GRID_GUTTER = 40;

const ReportResultChannelMonthlyTwitter = (props) => {
    console.info("ReportResultChannelMonthlyTwitter props", props);

    const res = props.results_separated && props.results_separated[0];

    // Prepare users object for easy access
    const users = {};
    res &&
        res.includes &&
        res.includes.users &&
        res.includes.users.map((user) => {
            users[user.id] = {
                name: user.name,
                username: user.username,
                avatar: user.profile_image_url,
                followers: user.public_metrics.followers_count,
                tweets: user.public_metrics.tweet_count,
            };
            return false;
        });

    console.info("ReportResultChannelMonthlyTwitter users", users);

    let impressions = [],
        likes = [],
        media_keys_dates = {};
    res &&
        res.data &&
        res.data.map((data) => {
            impressions.push({
                date: data.created_at,
                value: data.organic_metrics.impression_count,
            });
            likes.push({
                date: data.created_at,
                value: data.organic_metrics.like_count,
            });
            data.attachments &&
                data.attachments.media_keys &&
                data.attachments.media_keys.map((media_key) => {
                    media_keys_dates[media_key] = { date: data.created_at };
                });
        });

    let media_videos_views_total = 0,
        media_videos_playback_0_count = 0,
        media_videos_playback_25_count = 0,
        media_videos_playback_50_count = 0,
        media_videos_playback_75_count = 0,
        media_videos_playback_100_count = 0,
        media_images_count = 0;

    res &&
        res.includes &&
        res.includes.media &&
        res.includes.media.map((media) => {
            console.info("media", media);
            media_keys_dates[media.media_key]["media"] = media;
            // if (media.type === "photo") {
            //     media_images.push(media);
            // }
            if (media.type === "video") {
                media_videos_views_total += media.public_metrics.view_count;
                media_videos_playback_0_count +=
                    media.organic_metrics.playback_0_count;
                media_videos_playback_25_count +=
                    media.organic_metrics.playback_25_count;
                media_videos_playback_50_count +=
                    media.organic_metrics.playback_50_count;
                media_videos_playback_75_count +=
                    media.organic_metrics.playback_75_count;
                media_videos_playback_100_count +=
                    media.organic_metrics.playback_100_count;
            }

            if (media.type === "photo") {
                media_images_count += 1;
            }

            // impressions.push({
            //     date: data.created_at,
            //     value: data.organic_metrics.impression_count,
            // });
            // likes.push({
            //     date: data.created_at,
            //     value: data.organic_metrics.like_count,
            // });
        });

    // --------------------------------
    console.info("res OBJECT", res);
    console.info("impressions", impressions);
    console.info("likes", likes);

    console.info("media_keys_dates", media_keys_dates);
    console.info("media_videos_views_total", media_videos_views_total);

    const summaryOverviewPanel = res ? (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Impressions"
                        value={res.sum && res.sum.sumImpressions}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of impressions on any of your content"
                        }
                        // diff={activityOverviewPanel_likesDiff}
                        // oldValue={activityOverviewPanel_prevlikes}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="User Profile Clicks"
                        value={res.sum && res.sum.sumUserProfileClicks}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of user profile clicks on any of your content"
                        }
                        // diff={activityOverviewPanel_likesDiff}
                        // oldValue={activityOverviewPanel_prevlikes}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Replies"
                        value={res.sum && res.sum.sumReply}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of replies on any of your content"
                        }
                        // diff={activityOverviewPanel_likesDiff}
                        // oldValue={activityOverviewPanel_prevlikes}
                    />
                </Col>
            </Row>
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Retweets"
                        value={res.sum && res.sum.sumRetweet}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of retweets on any of your content"
                        }
                        // diff={activityOverviewPanel_likesDiff}
                        // oldValue={activityOverviewPanel_prevlikes}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Likes"
                        value={res.sum && res.sum.sumLike}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of likes on any of your content"
                        }
                        // diff={activityOverviewPanel_likesDiff}
                        // oldValue={activityOverviewPanel_prevlikes}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Quotes"
                        value={res.sum && res.sum.sumQuote}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of quotes on any of your content"
                        }
                        // diff={activityOverviewPanel_likesDiff}
                        // oldValue={activityOverviewPanel_prevlikes}
                    />
                </Col>
            </Row>
        </Fragment>
    ) : null;

    // // -----------------------------------------

    const impressionsPanel = res ? (
        <Fragment>
            <Row gutter={GRID_GUTTER}>
                <Col span={18}>
                    {impressions ? (
                        <ReportGraph
                            title={
                                "Impressions"
                                // (props.combined_results.page_fan_adds &&
                                //     props.combined_results.page_fan_adds
                                //         .title) ||
                                // "page_fan_adds"
                            }
                            description={
                                // props.combined_results.page_fan_adds &&
                                // props.combined_results.page_fan_adds.description
                                "Impressions per days"
                            }
                            value={
                                impressions &&
                                impressions.map((val) => ({
                                    value: val.value,
                                    end_time: moment(val.date).format("MM/DD"),
                                }))
                            }
                            color={
                                scss_variables.scss_color_dataPresentorTwitter
                            }
                            value_name="value"
                            key_name="end_time"
                            hideTotal
                            //   metric_name="page_fan_adds"
                            type="ColumnChart"
                        />
                    ) : null}
                </Col>
                <Col span={6}>
                    <ReportSingleValue
                        title="Total Impressions"
                        shallowDescription={true}
                        value={res && res.sum && res.sum.sumImpressions}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                </Col>
            </Row>
        </Fragment>
    ) : null;

    // // -----------------------------------------

    const likesPanel = res ? (
        <Fragment>
            <Row gutter={GRID_GUTTER}>
                <Col span={18}>
                    {likes ? (
                        <ReportGraph
                            title={
                                "Likes"
                                // (props.combined_results.page_fan_adds &&
                                //     props.combined_results.page_fan_adds
                                //         .title) ||
                                // "page_fan_adds"
                            }
                            description={
                                // props.combined_results.page_fan_adds &&
                                // props.combined_results.page_fan_adds.description
                                "Likes per days"
                            }
                            value={
                                likes &&
                                likes.map((val) => ({
                                    value: val.value,
                                    end_time: moment(val.date).format("MM/DD"),
                                }))
                            }
                            color={
                                scss_variables.scss_color_dataPresentorTwitter
                            }
                            value_name="value"
                            key_name="end_time"
                            hideTotal
                            //   metric_name="page_fan_adds"
                            type="ColumnChart"
                        />
                    ) : null}
                </Col>
                <Col span={6}>
                    <ReportSingleValue
                        title="Total Likes"
                        shallowDescription={true}
                        value={res && res.sum && res.sum.sumLikes}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                </Col>
            </Row>
        </Fragment>
    ) : null;

    // ---------------------------

    const mediaPanel = res ? (
        <Fragment>
            <Row gutter={GRID_GUTTER}>
                <Col span={18}>
                    {Object.keys(media_keys_dates).length > 0 ? (
                        <ReportGraph
                            title={
                                "Media (Videos)"
                                // (props.combined_results.page_fan_adds &&
                                //     props.combined_results.page_fan_adds
                                //         .title) ||
                                // "page_fan_adds"
                            }
                            description={
                                // props.combined_results.page_fan_adds &&
                                // props.combined_results.page_fan_adds.description
                                "Likes per days"
                            }
                            value={
                                media_keys_dates &&
                                Object.keys(media_keys_dates)
                                    .filter(
                                        (media_key) =>
                                            media_key.indexOf("7_") !== -1 // get only video starting with media key = 7_ ...
                                    )
                                    .reverse()
                                    .map((media_key) => ({
                                        value: media_keys_dates[media_key].media
                                            .public_metrics.view_count,
                                        end_time: moment(
                                            media_keys_dates[media_key].date
                                        ).format("MM/DD"),
                                    }))
                            }
                            color={
                                scss_variables.scss_color_dataPresentorTwitter
                            }
                            value_name="value"
                            key_name="end_time"
                            hideTotal
                            //   metric_name="page_fan_adds"
                            type="ColumnChart"
                        />
                    ) : null}
                </Col>
                <Col span={6}>
                    <ReportSingleValue
                        title="Total Video Views"
                        shallowDescription={true}
                        value={media_videos_views_total}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                    <ReportSingleValue
                        title="Up to 25%"
                        shallowDescription={true}
                        value={media_videos_playback_0_count}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                    <ReportSingleValue
                        title="25%"
                        shallowDescription={true}
                        value={media_videos_playback_25_count}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                    <ReportSingleValue
                        title="50%"
                        shallowDescription={true}
                        value={media_videos_playback_50_count}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                    <ReportSingleValue
                        title="75%"
                        shallowDescription={true}
                        value={media_videos_playback_75_count}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                    <ReportSingleValue
                        title="Completed"
                        shallowDescription={true}
                        value={media_videos_playback_100_count}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                    <Divider></Divider>
                    <ReportSingleValue
                        title="Total Images posted"
                        shallowDescription={true}
                        value={media_images_count}
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                </Col>
            </Row>
        </Fragment>
    ) : null;

    // ---------------------------
    const dataSource =
        res && res.data
            ? res.data.map((r, rIndex) => {
                  let preview_url = null;

                  if (r.attachments && r.attachments.media_keys) {
                      const media =
                          media_keys_dates[r.attachments.media_keys[0]].media;
                      if (media.type === "video") {
                          preview_url = media.preview_image_url;
                      }
                      if (media.type === "photo") {
                          preview_url = media.url;
                      }
                  }
                  return {
                      key: rIndex,
                      author: (
                          <div style={{ whiteSpace: "nowrap" }}>
                              <Icon
                                  type={"twitter"}
                                  style={{
                                      color: props.store.getTagColorForType(
                                          "twitter"
                                      ),
                                      marginRight: 5,
                                      float: "left",
                                  }}
                              />
                              {users[r.author_id].name}
                              {/* <strong>{users[r.author_id].followers}</strong> */}
                          </div>
                      ),
                      datetime: moment(r.created_at).valueOf(),
                      text: (
                          <Row gutter={5}>
                              <Col span={preview_url ? 16 : 24}>{r.text}</Col>
                              {preview_url ? (
                                  <Col span={8}>
                                      <img
                                          style={{ width: "100%" }}
                                          src={preview_url}
                                      />
                                  </Col>
                              ) : null}
                          </Row>
                      ),
                      impression_count: r.organic_metrics.impression_count,
                      user_profile_clicks:
                          r.organic_metrics.user_profile_clicks,
                      reply_count: r.organic_metrics.reply_count,
                      like_count: r.organic_metrics.like_count,
                      retweet_count: r.organic_metrics.retweet_count,
                  };
              })
            : [];

    const columns = [
        {
            title: "Author",
            dataIndex: "author",
            key: "author",
        },
        {
            title: "Date/Time",
            dataIndex: "datetime",
            key: "datetime",
            sorter: (a, b) => a.datetime - b.datetime,
            render: (datetime) => moment(datetime).format("lll"),
        },
        {
            title: "",
            dataIndex: "text",
            key: "text",
        },
        {
            title: "Impr.",
            dataIndex: "impression_count",
            key: "impression_count",
            sorter: (a, b) => a.impression_count - b.impression_count,
        },
        {
            title: "Profile clk.",
            dataIndex: "user_profile_clicks",
            key: "user_profile_clicks",
            sorter: (a, b) => a.user_profile_clicks - b.user_profile_clicks,
        },
        {
            title: "Replies",
            dataIndex: "reply_count",
            key: "reply_count",
            sorter: (a, b) => a.reply_count - b.reply_count,
        },
        {
            title: "Likes",
            dataIndex: "like_count",
            key: "like_count",
            sorter: (a, b) => a.like_count - b.like_count,
        },
        {
            title: "Retweets",
            dataIndex: "retweet_count",
            key: "retweet_count",
            sorter: (a, b) => a.retweet_count - b.retweet_count,
        },
    ];

    const tableInfoPanel = (
        <Table dataSource={dataSource} columns={columns} pagination={false} />
    );

    // -------------------------

    const body = props.loading ? (
        <Fragment>
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            {/* <pre>{JSON.stringify(props.results_separated[0], null, 2)}</pre> */}

            {props.results_separated &&
            props.results_separated[0].length === 0 ? (
                <Empty
                    description={
                        <span>
                            Missing tweets information.
                            <br />
                            Probably there were no tweets posted during the
                            period?
                        </span>
                    }
                />
            ) : props.results_separated ? (
                <Collapse
                    bordered={false}
                    defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
                >
                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("channels") !== -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Included in this Report
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "channels"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "channels",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="1"
                        >
                            {props.custom_sections.indexOf("channels") !== -1 &&
                                props.results_separated[0] &&
                                props.results_separated[0].includes &&
                                props.results_separated[0].includes.users.map(
                                    (rc, rcIndex) => {
                                        return (
                                            <div key={rcIndex}>
                                                <div
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        display: "inline-block",
                                                        marginRight: 10,
                                                        marginBottom: -4,
                                                        borderRadius: 20,
                                                        backgroundSize: "cover",
                                                        backgroundImage: `url(${rc.profile_image_url})`,
                                                    }}
                                                ></div>
                                                {rc.username} / Followers:{" "}
                                                <strong>
                                                    {
                                                        rc.public_metrics
                                                            .followers_count
                                                    }
                                                </strong>
                                            </div>
                                        );
                                    }
                                )}
                        </Panel>
                    ) : null}

                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("summary_overview") !==
                            -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Summary Overview
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "summary_overview"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "summary_overview",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="2"
                        >
                            {props.custom_sections.indexOf(
                                "summary_overview"
                            ) !== -1 && summaryOverviewPanel}
                        </Panel>
                    ) : null}

                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("impressions") !== -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Impressions
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "impressions"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "impressions",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="3"
                        >
                            {props.custom_sections.indexOf("impressions") !==
                                -1 && impressionsPanel}
                        </Panel>
                    ) : null}

                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("likes") !== -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Likes
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "likes"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "likes",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="4"
                        >
                            {props.custom_sections.indexOf("likes") !== -1 &&
                                likesPanel}
                        </Panel>
                    ) : null}

                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("media") !== -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Media
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "media"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "media",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="5"
                        >
                            {props.custom_sections.indexOf("media") !== -1 &&
                                mediaPanel}
                        </Panel>
                    ) : null}

                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("table_info") !== -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Tweets List
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "table_info"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "table_info",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="6"
                        >
                            {props.custom_sections.indexOf("table_info") !==
                                -1 && tableInfoPanel}
                        </Panel>
                    ) : null}
                </Collapse>
            ) : (
                <Empty
                    description={
                        <span>Errors in pulling the information.</span>
                    }
                />
            )}
        </Fragment>
    );

    return body;
};

export default ReportResultChannelMonthlyTwitter;
