import React, { Component, Fragment } from "react";
import {
    Badge,
    Row,
    Col,
    Typography,
    Input,
    Button,
    Tooltip,
    Icon,
} from "antd";
import moment from "moment";
import { social_notification } from "./../functions";

import scss_variables from "./../_variables.scss";

import iconFacebookReactionLikeSvg from "./../assets/images/facebook_reaction_like.svg";
import iconFacebookReactionLoveSvg from "./../assets/images/facebook_reaction_love.svg";
import iconFacebookReactionHahaSvg from "./../assets/images/facebook_reaction_haha.svg";
import iconFacebookReactionWowSvg from "./../assets/images/facebook_reaction_wow.svg";
import iconFacebookReactionSorrySvg from "./../assets/images/facebook_reaction_sorry.svg";
import iconFacebookReactionAngerSvg from "./../assets/images/facebook_reaction_anger.svg";

const { Title, Text } = Typography;
const { TextArea } = Input;

const facebookStyles = {
    background: "#e9ebee",
    padding: 10,
};
const facebookStylesCard = {
    background: "#fff",
    border: "1px solid #dddfe2",
    borderRadius: 3,
    padding: 12,
    position: "relative",
    maxWidth: 500,
};

const facebookStylesAuthor = {
    color: "#3b5a99",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: 1.38,
    marginLeft: 50,
};
const facebookStylesDate = {
    color: "rgb(97, 103, 112)",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16.0833px",
    marginLeft: 50,
};
const facebookStylesBody = {
    color: "rgb(29, 33, 41)",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19.3167px",
    overflowWrap: "break-word",
    marginTop: 12,
    marginBottom: 5,
    borderBottom: "1px solid #dddfe2",
    paddingBottom: 10,
};
const facebookStylesReactionIcon = {
    width: 30,
    height: 30,
};
const facebookStylesLinkContainer = {
    background: "#f2f3f5",
    marginLeft: -12,
    width: "calc(100% + 24px)",
    borderTop: "1px solid #dddfe2",
    borderBottom: "1px solid #dddfe2",
    padding: 12,
    marginTop: -6,
    marginBottom: 10,
};
const facebookStylesLinkContainerTitle = {
    color: "#1c1e21",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
    margin: 0,
    marginBottom: 5,
};
const facebookStylesLinkContainerDescription = {
    color: "#606770",
    fontSize: "14px",
    wordBreak: "break-word",
};
const facebookStylesReactionsContainer = {
    paddingTop: 15,
};
const facebookStylesReactionsImpressions = {
    color: "#9c9d9e",
    marginTop: -2,
    lineHeight: 0,
};
const facebookStylesCommentsContainer = {
    paddingTop: 8,
    borderTop: "1px solid #dddfe2",
    marginTop: 10,
    color: "#9c9d9e",
    textAlign: "left",
};
const facebookStylesCommentsAuthor = {
    color: "#3b5a99",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: 1.38,
    display: "block",
    float: "left",
};
const facebookStylesCommentsDatetime = {
    color: "rgb(97, 103, 112)",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "21px",
    float: "right",
    display: "block",
};
const facebookStylesCommentsMessage = {
    color: "#333",
    clear: "both",
    marginBottom: 10,
};
// Inspiring crates delivered every month for kids of all
//                     ages✨. Get 50% off your first month with promo code:
//                     Weekend50

class SocialFacebookPost extends Component {
    constructor(props) {
        super(props);

        this.state = {
            post: null,
            author: "",
            avatar: "",
            insights: null,
            comments: [],
            posting: false,
        };
    }

    componentDidMount() {
        this.setState({
            post: this.props.post,
            author: this.props.author,
            avatar: this.props.avatar,
        });
        this.parseInsights();
        this.parseComments();
    }

    parseInsights = () => {
        let insights = {};
        this.props.post.insights.data.map((d) => {
            //value
            let value = 0;
            d.values.map((v) => {
                if (typeof v.value !== "undefined") value += v.value;
                return false;
            });
            insights[d.name] = value;
            return false;
        });
        //console.info("parseInsights insights", insights);
        this.setState({ insights });
    };

    parseComments = (_new_comments) => {
        let comments = [];

        const comments_source = _new_comments
            ? _new_comments
            : this.props.post.comments.data;

        const sorted_comments = comments_source
            .sort((a, b) => {
                return (
                    new Date(b.created_time).getTime() -
                    new Date(a.created_time).getTime()
                );
            })
            .reverse();

        sorted_comments.map((c) => {
            comments.push({
                created_time: c.created_time,
                from: c.from.name,
                message: c.message,
            });
            return false;
        });
        //console.info("parseComments comments", comments);
        this.setState({ comments });
    };

    parseContent = () => {
        let retObject = null;

        // Video
        if (this.state.post && this.state.post.status_type === "added_video") {
            retObject = (
                <img
                    src={this.state.post.full_picture}
                    style={{
                        width: "calc(100% + 24px)",
                        borderTop: "1px solid #c4c8ce",
                        // borderBottom: "1px solid #c4c8ce",
                        marginLeft: -12,
                        marginTop: -7,
                        marginBottom: 10,
                    }}
                    alt=""
                />
            );
        }
        // Link
        if (this.state.post && this.state.post.status_type === "shared_story") {
            retObject = (
                <div style={facebookStylesLinkContainer}>
                    <Title style={facebookStylesLinkContainerTitle}>
                        {this.state.post.attachments.data[0].title}
                    </Title>
                    <Text style={facebookStylesLinkContainerDescription}>
                        {this.state.post.attachments.data[0].description}
                    </Text>
                </div>
            );
        }

        // Photos
        if (this.state.post && this.state.post.status_type === "added_photos") {
            retObject = (
                <img
                    src={this.state.post.full_picture}
                    style={{
                        width: "calc(100% + 24px)",
                        borderTop: "1px solid #c4c8ce",
                        // borderBottom: "1px solid #c4c8ce",
                        marginLeft: -12,
                        marginTop: -7,
                        marginBottom: 10,
                    }}
                    alt=""
                />
            );
        }

        return retObject;
    };

    postComment = () => {
        this.setState({ posting: true });

        //console.info("this.props", this.props);

        this.props.store
            .publishCommentOnChannelPost(
                this.props.accountId,
                this.props.provider,
                this.props.channel_id,
                this.props.post.id,
                this.state.new_comment
            )
            .then((postResults) => {
                //console.info("postResults", postResults);
                // update the comments

                this.parseComments(postResults.comments.data);

                this.setState({ posting: false, new_comment: "" });
            })
            .catch((error) => {
                console.warn("ERROR !!!!");
                social_notification(
                    "publish_comment_on_channel_post",
                    "error",
                    {
                        description: error,
                    }
                );
            });
    };

    render() {
        const content = this.parseContent();

        return (
            <Fragment>
                {/* {this.state.post ? (
                    <pre>
                        this.state.post =
                        {JSON.stringify(this.state.post, null, 2)}
                    </pre>
                ) : null} */}

                <div style={facebookStyles}>
                    <div style={facebookStylesCard}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                                this.state.post && this.state.post.permalink_url
                            }
                            style={{ float: "right" }}
                        >
                            <Tooltip title="Open in Facebook">
                                <Icon
                                    type="link"
                                    style={{
                                        fontSize: 14,
                                    }}
                                />
                            </Tooltip>
                        </a>
                        <div
                            style={{
                                width: 40,
                                height: 40,
                                position: "absolute",
                                backgroundColor: "#ddd",
                                borderRadius: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundImage: `url(${this.state.avatar})`,
                            }}
                        />
                        <div style={facebookStylesAuthor}>
                            {this.state.author}
                        </div>
                        <div style={facebookStylesDate}>
                            {this.state.post
                                ? moment(this.state.post.updated_time).format(
                                      "MMMM D, YYYY"
                                  ) +
                                  ` at ` +
                                  moment(this.state.post.updated_time).format(
                                      "h:mm a"
                                  )
                                : null}
                        </div>
                        <div style={facebookStylesBody}>
                            {this.state.post && this.state.post.message
                                ? this.state.post.message
                                : this.state.post && this.state.post.story
                                ? this.state.post.story
                                : ""}
                        </div>
                        {content}
                        {this.props.children}
                        <Row style={facebookStylesReactionsContainer}>
                            <Col
                                span={6}
                                style={facebookStylesReactionsImpressions}
                            >
                                Impressions
                                <Title
                                    level={4}
                                    style={{
                                        marginBottom: 0,
                                        marginTop: 5,
                                        letterSpacing: "-0.1rem",
                                    }}
                                >
                                    {this.state.insights &&
                                        this.state.insights.post_impressions}
                                </Title>
                            </Col>
                            <Col span={3} style={{ textAlign: "center" }}>
                                <Badge
                                    count={
                                        this.state.insights &&
                                        this.state.insights
                                            .post_reactions_like_total
                                    }
                                    showZero
                                    style={{
                                        backgroundColor:
                                            scss_variables.scss_color_dataPresentorFacebookStroke,
                                        color: "#ffffff",
                                    }}
                                >
                                    <img
                                        src={iconFacebookReactionLikeSvg}
                                        style={facebookStylesReactionIcon}
                                        alt=""
                                    />
                                </Badge>
                            </Col>
                            <Col span={3} style={{ textAlign: "center" }}>
                                <Badge
                                    count={
                                        this.state.insights &&
                                        this.state.insights
                                            .post_reactions_love_total
                                    }
                                    showZero
                                    style={{
                                        backgroundColor:
                                            scss_variables.scss_color_dataPresentorFacebookStroke,
                                        color: "#ffffff",
                                    }}
                                >
                                    <img
                                        src={iconFacebookReactionLoveSvg}
                                        style={facebookStylesReactionIcon}
                                        alt=""
                                    />
                                </Badge>
                            </Col>
                            <Col span={3} style={{ textAlign: "center" }}>
                                <Badge
                                    count={
                                        this.state.insights &&
                                        this.state.insights
                                            .post_reactions_haha_total
                                    }
                                    showZero
                                    style={{
                                        backgroundColor:
                                            scss_variables.scss_color_dataPresentorFacebookStroke,
                                        color: "#ffffff",
                                    }}
                                >
                                    <img
                                        src={iconFacebookReactionHahaSvg}
                                        style={facebookStylesReactionIcon}
                                        alt=""
                                    />
                                </Badge>
                            </Col>
                            <Col span={3} style={{ textAlign: "center" }}>
                                <Badge
                                    count={
                                        this.state.insights &&
                                        this.state.insights
                                            .post_reactions_wow_total
                                    }
                                    showZero
                                    style={{
                                        backgroundColor:
                                            scss_variables.scss_color_dataPresentorFacebookStroke,
                                        color: "#ffffff",
                                    }}
                                >
                                    <img
                                        src={iconFacebookReactionWowSvg}
                                        style={facebookStylesReactionIcon}
                                        alt=""
                                    />
                                </Badge>
                            </Col>
                            <Col span={3} style={{ textAlign: "center" }}>
                                <Badge
                                    count={
                                        this.state.insights &&
                                        this.state.insights
                                            .post_reactions_sorry_total
                                    }
                                    showZero
                                    style={{
                                        backgroundColor:
                                            scss_variables.scss_color_dataPresentorFacebookStroke,
                                        color: "#ffffff",
                                    }}
                                >
                                    <img
                                        src={iconFacebookReactionSorrySvg}
                                        style={facebookStylesReactionIcon}
                                        alt=""
                                    />
                                </Badge>
                            </Col>
                            <Col span={3} style={{ textAlign: "center" }}>
                                <Badge
                                    count={
                                        this.state.insights &&
                                        this.state.insights
                                            .post_reactions_anger_total
                                    }
                                    showZero
                                    style={{
                                        backgroundColor:
                                            scss_variables.scss_color_dataPresentorFacebookStroke,
                                        color: "#ffffff",
                                    }}
                                >
                                    <img
                                        src={iconFacebookReactionAngerSvg}
                                        style={facebookStylesReactionIcon}
                                        alt=""
                                    />
                                </Badge>
                            </Col>
                        </Row>
                        {this.state.comments.length > 0 ? (
                            <Row style={facebookStylesCommentsContainer}>
                                <Col>
                                    {this.state.comments.map((c, cIndex) => {
                                        return (
                                            <Fragment key={cIndex}>
                                                <div
                                                    style={
                                                        facebookStylesCommentsAuthor
                                                    }
                                                >
                                                    {c.from}
                                                </div>
                                                <div
                                                    style={
                                                        facebookStylesCommentsDatetime
                                                    }
                                                >
                                                    {moment(
                                                        c.created_time
                                                    ).format("MMMM D, YYYY") +
                                                        ` at ` +
                                                        moment(
                                                            c.created_time
                                                        ).format("h:mm a")}
                                                </div>
                                                <div
                                                    style={
                                                        facebookStylesCommentsMessage
                                                    }
                                                >
                                                    {c.message}
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                </Col>
                            </Row>
                        ) : null}
                        <Row
                            type="flex"
                            justify="space-around"
                            style={{
                                borderTop: "1px solid #eee",
                                marginTop: 10,
                                paddingTop: 10,
                            }}
                        >
                            <Col span={19}>
                                <TextArea
                                    disabled={this.state.posting}
                                    placeholder="Your comment"
                                    value={this.state.new_comment}
                                    autoSize
                                    onChange={(e) =>
                                        this.setState({
                                            new_comment: e.target.value,
                                        })
                                    }
                                />
                            </Col>
                            <Col span={5} style={{ textAlign: "right" }}>
                                <Button
                                    onClick={this.postComment}
                                    loading={this.state.posting}
                                    disabled={this.state.posting}
                                >
                                    Post
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SocialFacebookPost;
