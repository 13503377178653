import React, { Component, Fragment } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";

import {
    Form,
    Modal,
    Button,
    Row,
    Col,
    Checkbox,
    Tabs,
    Divider,
    Icon,
    Alert,
} from "antd";
import { social_notification } from "./../../functions";

import { observer } from "mobx-react";
const { TabPane } = Tabs;

/* Add/Edit Form */
const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                src: props.data.downloadURL,
                crop: {
                    //   aspect: props.aspect,
                },
            };
        }

        componentDidMount = () => {
            console.info("LibraryModalEffects ModalForm props", this.props);
            console.info("LibraryModalEffects ModalForm state", this.state);
        };
        onCropComplete = (crop) => {
            this.makeClientCrop(crop);
        };

        onCropChange = (crop, percentCrop) => {
            // You could also use percentCrop:
            // this.setState({ crop: percentCrop });
            this.setState({ crop });
        };

        async makeClientCrop(crop) {
            if (this.imageRef && crop.width && crop.height) {
                console.info("LibraryModalEffects makeClientCrop");
                const [croppedImageUrl, croppedImageBlob] =
                    await this.getCroppedImg(
                        this.imageRef,
                        crop,
                        "newFile.jpeg"
                    );
                this.setState({ croppedImageUrl, croppedImageBlob });
                console.info(
                    "LibraryModalEffects croppedImageUrl",
                    croppedImageUrl,
                    "croppedImageBlob",
                    croppedImageBlob
                );
                // calling the callback
                if (typeof this.props.handleCroppingImage === "function")
                    this.props.handleCroppingImage(
                        croppedImageUrl,
                        croppedImageBlob
                    );
            }
        }

        onImageLoaded = (image) => {
            this.imageRef = image;
            this.imageRef.setAttribute("crossorigin", "anonymous"); // works for me
            return false;
        };

        getCroppedImg(image, crop, fileName) {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            return new Promise((resolve, reject) => {
                canvas.toBlob((blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error("Canvas is empty");
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve([this.fileUrl, blob]);
                }, "image/jpeg");
            });
        }

        changeAspectRatio = (_evt, _axis) => {
            const value = _evt.target.value;
            console.info("LibraryModalEffects changeAspectRatio value", value);
            let { crop } = this.state;

            if (_axis === "width") crop.width = value;
            if (_axis === "height") crop.height = value;
            const aspect_ratio =
                this.state.crop_width && this.state.crop_height
                    ? this.state.crop_width / this.state.crop_height
                    : null;
            console.info(
                "LibraryModalEffects changeAspectRatio aspect_ratio",
                aspect_ratio
            );
            if (aspect_ratio) {
                crop.aspect = aspect_ratio;
                this.setState({ crop });
            }
        };

        render() {
            //const { form } = this.props;

            const { crop, src, croppedImageUrl } = this.state;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            const cropImage = src ? (
                <Fragment>
                    <ReactCrop
                        src={src}
                        crop={crop}
                        keepSelection
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        crossorigin="anonymous"
                    />
                    {croppedImageUrl && (
                        <Fragment>
                            <Divider orientation="left">Result image</Divider>
                            <img
                                alt="Crop"
                                style={{
                                    maxWidth: "100%",
                                    border: "5px solid #ddd",
                                }}
                                src={croppedImageUrl}
                            />
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                <h5>no src</h5>
            );
            const cropPanel = (
                <Fragment>
                    <Row>
                        <Col>
                            {/* <span style={{ float: "left", marginTop: 5 }}>
                                Crop size:{" "}
                            </span>
                            <h2
                                style={{
                                    float: "left",
                                    display: "inline-block",
                                    letterSpacing: "-0.05rem",
                                }}
                            >
                                {this.state.crop.width} x{" "}
                                {this.state.crop.height}
                            </h2> */}
                            {/* <Form.Item label="Crop size">
                                <h5>
                                    {this.state.crop.width} x{" "}
                                    {this.state.crop.height}
                                </h5>
                                <Input
                                    placeholder={"width"}
                                    style={{
                                        float: "left",
                                        width: 80,
                                        marginRight: 5,
                                    }}
                                    value={this.state.crop.width}
                                    onChange={(e) =>
                                        this.changeAspectRatio(e, "width")
                                    }
                                />
                                <Input
                                    placeholder={"height"}
                                    style={{ float: "left", width: 80 }}
                                    value={this.state.crop.height}
                                    onChange={(e) =>
                                        this.changeAspectRatio(e, "height")
                                    }
                                />
                            </Form.Item> */}
                        </Col>
                    </Row>
                    <Divider orientation="left">
                        Preview
                        {this.state.crop.width && this.state.crop.height ? (
                            <Fragment>
                                {" "}
                                / {this.state.crop.width} x{" "}
                                {this.state.crop.height}
                            </Fragment>
                        ) : null}
                    </Divider>
                    {cropImage}
                </Fragment>
            );

            return (
                <Form {...formItemLayout}>
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                    <Tabs
                        defaultActiveKey="crop_resize"
                        style={{ marginBottom: 30 }}
                        animated={false}
                    >
                        <TabPane tab={"Crop/Resize"} key="crop_resize">
                            {cropPanel}
                        </TabPane>
                        {/* <TabPane tab={"Resize"} key="resize">
                            Resize
                        </TabPane> */}
                    </Tabs>

                    {/* <Form.Item label="Extras">
                              {getFieldDecorator("allow_access_to_app", {
                                  initialValue: data.allow_access_to_app
                              })(
                                  <Checkbox
                                      onChange={this.handleAllowAccessToAppChange}
                                      checked={data.allow_access_to_app}
                                  >
                                      Allow access to the app
                                  </Checkbox>
                              )}
                          </Form.Item> */}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class LibraryEffects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            remove_original: false,
            data: props.data,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "library_modal_effects",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("library_modal_effects");
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            //Perform some operation here
            this.setState({ data: this.props.data });
        }
    }

    onModalAdd = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            form.resetFields();

            let files = values.fileList.fileList;
            files.map((file) => {
                this.props.store
                    .addLibraryFile({ file, ...values })
                    .then((result) => {
                        console.info("result", result);
                        this.onModalCancel();
                    })
                    .catch((error) => {
                        console.warn(error);
                        social_notification("add_library_file", "error", {
                            description: error,
                        });
                    });
                return false;
            });
        });
    };
    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            console.log("Received values this.props.data: ", this.props.data);

            if (err) {
                return;
            }

            this.props.store
                .updateLibraryFile(this.props.data.key, values.labels)
                .then((result) => {
                    form.resetFields();
                    this.onModalCancel();
                })
                .catch((error) => {
                    social_notification("update_library_file", "error", {
                        description: error,
                    });
                });
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "library_modal_effects",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    onCheckboxChange = (_evt) => {
        this.setState({ remove_original: !this.state.remove_original });
    };

    handleCroppingImage = async (_croppedImageUrl, _croppedImageBlob) => {
        console.info(
            "LibraryModalEffects handleCroppingImage _croppedImageUrl",
            _croppedImageUrl,
            "_croppedImageBlob",
            _croppedImageBlob
        );
        this.setState({ croppedImageBlob: _croppedImageBlob });
        //   const DataURL = await this.readRemoteURLasDataURL(_croppedImageBlob);
        //   console.info(
        //       "LibraryModalEffects handleCroppingImage DataURL",
        //       DataURL
        //   );
    };

    readRemoteURLasDataURL = (_url) => {
        console.log("readRemoteURLasDataURL _url=" + _url);
        return new Promise((resolve, reject) => {
            var request = new XMLHttpRequest();
            request.open("GET", _url, true);
            request.responseType = "blob";
            //let that = this;
            request.onload = function () {
                var reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload = function (e) {
                    console.log("DataURL:", e.target.result);
                    //that.setState({ image_data: e.target.result });
                    resolve(e.target.result);
                    // Call onChange callback
                    //that.callOnChangeHandler();
                };
            };
            request.send();
        });
    };

    applyEffect = () => {
        console.info(
            "LibraryModalEffects applyEffect this.state.croppedImageBlob",
            this.state.croppedImageBlob
        );

        // const originalFilename = this.state.data.original_filename
        //     .split(".")
        //     .slice(0, -1)
        //     .join(".");
        // const originalFileext = this.state.data.original_filename
        //     .split(".")
        //     .pop();
        //const newFilename = `library/${originalFilename}_Cropped.${originalFileext}`;
        //alert("newFilename=" + newFilename);

        const file = this.state.croppedImageBlob;
        const labels = this.state.data.labels;

        console.info(
            "LibraryModalEffects applyEffect file",
            file,
            "labels",
            labels
        );
        let that = this;

        this.props.store
            .addLibraryFile({ file, labels })
            .then((result) => {
                console.info(
                    "LibraryModalEffects addLibraryFile result",
                    result
                );

                if (that.state.remove_original) {
                    // Removing original
                    console.info("remove original item data", that.state.data);
                    that.props.store.deleteLibraryItem(that.state.data);
                }
                this.onModalCancel();
            })
            .catch((error) => {
                console.warn(error);
                social_notification("add_library_file", "error", {
                    description: error,
                });
            });
        return false;

        //   return false;
        //   // Something
        //   // Create a root reference
        //   var storageRef = firebase.storage().ref();

        //   // Create a reference to 'mountains.jpg'
        //   var mountainsRef = storageRef.child("MYNEWLIBRARYFILE.jpg");

        //   // Create a reference to 'images/mountains.jpg'
        //   //var mountainImagesRef = storageRef.child('images/mountains.jpg');

        //   var file = this.state.croppedImageBlob; // use the Blob or File API
        //   mountainsRef
        //       .put(file)
        //       .then(function (snapshot) {
        //           console.info(
        //               "LibraryModalEffects Uploaded a blob or file! snapshot",
        //               snapshot
        //           );
        //       })
        //       .catch((error) => {
        //           console.info(
        //               "LibraryModalEffects ERROR Uploaded a blob or file! error",
        //               error
        //           );
        //       });

        //   // let formState = Object.assign({}, this.formRef.state);
        //   // console.info("formState", formState);

        //   // const imageData = await this.readRemoteURLasDataURL(this.state.croppedImageUrl);
        //   // console.info('LibraryModalEffects applyEffect imageData', imageData);
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Apply effects to an item"}
                        okText={"Apply"}
                        onCancel={this.onModalCancel}
                        onOk={!data.labels ? this.onModalAdd : this.onModalEdit} //onApply
                        maskClosable={false}
                        footer={[
                            <Row key="footerKey">
                                <Col span={12} style={{ textAlign: "left" }}>
                                    <Button
                                        key="close"
                                        onClick={this.onModalCancel}
                                    >
                                        Close
                                    </Button>

                                    <Checkbox
                                        onChange={this.onCheckboxChange}
                                        checked={
                                            this.state.remove_original === false
                                        }
                                        style={{ marginTop: 5, marginLeft: 20 }}
                                    >
                                        {/* Remove original item */}
                                        Save as a new item
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        icon="picture"
                                        disabled={!this.state.croppedImageBlob}
                                        onClick={(e) => this.applyEffect(e)}
                                    >
                                        Apply
                                    </Button>
                                </Col>
                            </Row>,
                            // <Fragment key="temp">
                            //     Step: {this.state.step}&nbsp;&nbsp;&nbsp;
                            // </Fragment>,
                        ]}
                    >
                        {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            data={data}
                            wrappedComponentRef={this.saveFormRef}
                            handleCroppingImage={this.handleCroppingImage}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(LibraryEffects);
