import React, { Fragment } from "react";
import { Tooltip, Icon, Modal } from "antd";

const SocialErrorSymbol = ({ title, errors, store }) => {
    if (!errors) errors = [];

    errors = errors.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
    });
    const displayMessage = title ? errors.join(", ") : null;
    return (
        <Tooltip
            title={
                errors && errors.length > 0 ? (
                    <Fragment>
                        Errors:
                        <hr size={1} />
                        {errors.map((err, errIndex) => (
                            <div key={errIndex}>
                                {err}
                                <br />
                            </div>
                        ))}
                    </Fragment>
                ) : null
            }
        >
            <div
                style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    alignItems: "center",
                }}
                onClick={(e) => {
                    if (errors.length > 0) {
                        Modal.error({
                            title: "Errors",
                            content: (
                                <Fragment>
                                    {errors.map((err) => (
                                        <div>
                                            {err}
                                            <br />
                                        </div>
                                    ))}
                                </Fragment>
                            ),
                        });
                    }
                }}
            >
                <Icon
                    type="warning"
                    style={{
                        marginRight: 5,
                        color: store.getTagColorForType("error"),
                    }}
                />
                {title ? (
                    <span
                        style={{
                            color: store.getTagColorForType("error"),
                            marginRight: 5,
                        }}
                    >
                        {displayMessage}
                    </span>
                ) : null}
            </div>
        </Tooltip>
    );
};

export default SocialErrorSymbol;
