import React, { Component, Fragment } from "react";
import { Modal } from "antd";
import { observer } from "mobx-react";
import { social_setCookie } from "../../functions";

/* Wrapper component */
class AccountsChannelsLinkedinModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    async componentDidMount() {
        this.props.store.registerModalListener(
            "accounts_channels_linkedin_modal",
            (_action) => {
                console.log(
                    "accounts_channels_twitter_modal _action=" + _action
                );
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
                if (_action === "show") {
                    console.info("this.props.account", this.props.account);
                    //   this.props.store.setTempData({
                    //       link_twitter_account_key: this.props.account.key,
                    //   });
                    social_setCookie(
                        "link_linkedin_account_key",
                        this.props.account.key,
                        7
                    );
                }
                if (_action === "hide") {
                    if (typeof this.props.callback !== "undefined") {
                        this.props.callback();
                    }
                }
            }
        );
    }

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener(
            "accounts_channels_linkedin_modal"
        );
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "accounts_channels_linkedin_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    render() {
        const { account } = this.props;
        console.info("account", account);
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Link Linkedin profile"}
                        onCancel={this.onModalCancel}
                        maskClosable={false}
                        footer={null}
                        centered={true}
                    >
                        <iframe
                            title="linkedinhandler"
                            frameBorder={0}
                            width="100%"
                            height="500"
                            src={window.location.origin + "/linkedinhandler"}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AccountsChannelsLinkedinModal);
