import React, { Component, Fragment } from "react";

import config from "./../../config";
import tooltips from "../../tooltips";
import { social_sleep } from "./../../functions";
import moment from "moment";
import { observer } from "mobx-react";
import SocialErrorSymbol from "./../SocialErrorSymbol";
import names from "./../../names";

import {
    Empty,
    List,
    Button,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Modal,
    Icon,
    Checkbox,
} from "antd";

// import CompetitorsListModal from "./CompetitorsListModal";
import Bulk from "./../Bulk";
import scss_variables from "./../../_variables.scss";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class ScheduleList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            //edit_data: {},
            pagination_currentPage: 1,
            sortby: "schedule_datetime",
            sortasc: true,
            schedule_data: [],
            ready: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("schedule");

        // Breadcrumbs
        // this.props.store.setBreadcrumbs([
        //     { route: `/dashboard/competitors`, title: "Competitors" },
        // ]);
    }
    async componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);

        await social_sleep(100);
        this.loadInformation();
    }

    loadInformation = async () => {
        const fillScheduleData = this.fillScheduleData(
            this.props.store.getAllSchedule
        );
        console.info("fillScheduleData", fillScheduleData);
        this.setState({
            schedule_data: fillScheduleData,
            ready: true,
        });

        // setTimeout(
        //     function (that) {
        //         const fillScheduleData = that.fillScheduleData(
        //             that.props.store.getAllSchedule
        //         );
        //         console.info("fillScheduleData", fillScheduleData);
        //         that.setState({ schedule_data: fillScheduleData, ready: true });
        //     },
        //     100,
        //     this
        // );
    };

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("competitors_modal_add");
    }

    showModal = () => {
        console.log("showModal");
        this.setState({
            edit_data: {},
        });

        this.props.store.showModalByKey("competitors_modal_add");
    };
    showModalForEdit = (_evt, _competitor) => {
        console.info("showModalForEdit _evt", _evt, "_competitor", _competitor);

        this.setState({
            edit_data: _competitor,
        });

        this.props.store.showModalByKey("competitors_modal_add");
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        console.info("sortby", sortby, "sortasc", sortasc);
        this.setState({ sortby: _sorting, sortasc });
    };

    openMasterpost = (_masterpost_name) => {
        // /dashboard/masterposts/filter/:filter
        this.props.history.push(
            `/dashboard/masterposts/filter/${_masterpost_name}`
        );
    };

    handleDeleteCompetitor = (_evt, _item) => {
        console.info("handleDeleteCompetitor _evt", _evt, "_item", _item);

        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk() {
                that.props.store.deleteCompetitor(_item);
            },
        });
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    getListingDataSource = () => {
        let results = this.state.schedule_data.filter((c) => {
            return (
                c.account_name
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1 ||
                c.masterpost_name
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1
            );
        });

        // --- sorting ---
        results.sort((a, b) => {
            //console.info("results.sort (this.state.sortby)", this.state.sortby);

            var account_nameA = a.account_name.toLowerCase(),
                account_nameB = b.account_name.toLowerCase();
            var masterpost_nameA = a.masterpost_name
                    ? a.masterpost_name.toLowerCase()
                    : "",
                masterpost_nameB = b.masterpost_name
                    ? b.masterpost_name.toLowerCase()
                    : "";
            var schedule_datetimeA = Number(a.schedule_datetime),
                schedule_datetimeB = Number(b.schedule_datetime);

            switch (this.state.sortby) {
                case "account_name":
                    if (this.state.sortasc === true) {
                        if (account_nameA <= account_nameB) return -1;
                        if (account_nameA > account_nameB) return 1;
                    } else {
                        if (account_nameA <= account_nameB) return 1;
                        if (account_nameA > account_nameB) return -1;
                    }
                    break;
                case "masterpost_name":
                    if (this.state.sortasc === true) {
                        if (masterpost_nameA <= masterpost_nameB) return -1;
                        if (masterpost_nameA > masterpost_nameB) return 1;
                    } else {
                        if (masterpost_nameA <= masterpost_nameB) return 1;
                        if (masterpost_nameA > masterpost_nameB) return -1;
                    }
                    break;
                case "schedule_datetime":
                    if (this.state.sortasc === true) {
                        if (schedule_datetimeA <= schedule_datetimeB) return -1;
                        if (schedule_datetimeA > schedule_datetimeB) return 1;
                    } else {
                        if (schedule_datetimeA <= schedule_datetimeB) return 1;
                        if (schedule_datetimeA > schedule_datetimeB) return -1;
                    }
                    break;
                default:
                    return 0;
            }
            //return 0;
        });
        // --- end of sorting

        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    fillScheduleData = (_scheduleData) => {
        const allAccounts = this.props.store.getAllAccounts;
        //console.info("allAccounts", allAccounts);
        const allMasterposts = this.props.store.getAllMasterposts;
        //console.info("allMasterposts", allMasterposts);

        _scheduleData.map((sd) => {
            const account = allAccounts.filter(
                (ac) => ac.key === sd.account_key
            )[0];
            const accountData = account
                ? JSON.parse(JSON.stringify(account))
                : null;
            const channels = accountData
                ? this.props.store.getAccountChannelsFromAccountData(
                      accountData
                  )
                : null;
            const channel = channels
                ? channels.filter((c) => c.id === sd.channel_id)[0]
                : null;
            const masterpost = allMasterposts.filter(
                (mp) => mp.key === sd.masterpost_key
            )[0];

            // console.info("sd", sd, "channel", channel);
            sd.account_name = accountData ? accountData.name : "N/A";
            sd.channel_name = channel ? channel.name : "N/A";
            sd.channel_type = !sd.channel_type
                ? channel
                    ? channel.type
                    : "N/A"
                : "N/A";
            sd.channel_link = channel ? channel.link : "N/A";
            // account_key: (...)
            // channel_id: (...)
            // filter_key: (...)
            // key: (...)
            // masterpost_key: (...)
            // schedule_datetime: (...)
            // schedule_datetime_formated: (...)
            // status: (...)
            // target_shortid: (...)
            sd.masterpost_name = masterpost ? masterpost.name : "N/A";
        });

        // remove wrong data
        _scheduleData = _scheduleData.filter(
            (scheduled_rec) =>
                scheduled_rec.schedule_datetime &&
                scheduled_rec.account_name !== "N/A" &&
                scheduled_rec.channel_name !== "N/A"
        );

        return _scheduleData;
    };

    render() {
        const scheduleLength = this.state.schedule_data.length;

        const pagesCount = scheduleLength / config.PAGINATION_PERPAGE;
        //console.log("pagesCount = " + pagesCount);

        //let scheduleData = this.props.store.getAllSchedule;
        //scheduleData = this.fillScheduleData(scheduleData);
        //console.info("Filled scheduleData", scheduleData);

        const header = (
            <SocialListHeader
                title="Schedule list"
                // newButtonClickHandle={this.showModal}
                // newButtonText="New competitor"
                // newButtonTooltip={tooltips.COMPETITORS.NEWBUTTON}
                // bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );

        // let header = (
        //     <Row
        //         type="flex"
        //         justify="space-between"
        //         style={{ marginBottom: 10 }}
        //     >
        //         <Col>
        //             <Title level={3} style={{ float: "left", paddingRight: 5 }}>
        //                 Schedule list
        //             </Title>
        //             {/* <Badge
        //                 count={competitorsLength}
        //                 style={{ backgroundColor: "#1890ff" }}
        //             /> */}
        //         </Col>
        //         {/* <Col>
        //             {
        //                 <Tooltip
        //                     title={tooltips.SCHEDULE_LIST.NEWBUTTON}
        //                     mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
        //                 >
        //                     <Button
        //                         type="primary"
        //                         // icon="plus"
        //                         size="small"
        //                         onClick={this.showModal}
        //                         style={{
        //                             marginLeft: 20,
        //                             marginTop: 5,
        //                             fontSize: 12,
        //                         }}
        //                     >
        //                         New schedule
        //                     </Button>
        //                 </Tooltip>
        //             }
        //         </Col> */}
        //     </Row>
        // );

        let body =
            scheduleLength === 0 ? (
                <Empty />
            ) : (
                <List
                    size="small"
                    className="ScheduleList"
                    header={
                        <Fragment>
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                {/* <Col style={{ width: 25, lineHeight: 1.2 }}>
                                    <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                    />  
                                </Col>*/}
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="account_name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Account&nbsp;/&nbsp;Channel
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="masterpost_name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        {names.get("MASTERPOST", false, true)}
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="schedule_datetime"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Date/Time
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="status"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Status
                                    </SocialListColumn>
                                </Col>
                                {/* <Col style={{ width: 90 }}>Tools</Col> */}
                            </Row>
                        </Fragment>
                    }
                    footer={
                        pagesCount > 1 && this.state.filter.length === 0 ? (
                            <div style={{ textAlign: "right" }}>
                                <Pagination
                                    size="small"
                                    defaultCurrent={1}
                                    total={scheduleLength}
                                    pageSize={config.PAGINATION_PERPAGE}
                                    current={this.state.pagination_currentPage}
                                    onChange={this.paginationSetPage}
                                />
                            </div>
                        ) : null
                    }
                    bordered
                    dataSource={this.getListingDataSource()}
                    renderItem={(item, itemIndex) => (
                        <List.Item
                            key={itemIndex}
                            className={
                                this.listBulk &&
                                this.listBulk.isChecked(item.key)
                                    ? this.listBulk.getCheckedLength() === 1
                                        ? "social_Selected_withTools"
                                        : "social_Selected"
                                    : null
                            }
                            actions={
                                [
                                    //   <Tooltip
                                    //       key="edit"
                                    //       title={tooltips.SCHEDULE_LIST.LIST_EDIT}
                                    //       mouseEnterDelay={
                                    //           tooltips.SETTINGS.mouseEnterDelay
                                    //       }
                                    //   >
                                    //       <Button
                                    //           type="link"
                                    //           style={{ padding: 0 }}
                                    //           onClick={(_evt) => {
                                    //               this.showModalForEdit(_evt, item);
                                    //           }}
                                    //       >
                                    //           <Icon type="edit" />
                                    //       </Button>
                                    //   </Tooltip>,
                                    //   <Tooltip
                                    //       key="delete"
                                    //       title={tooltips.SCHEDULE_LIST.LIST_DELETE}
                                    //       mouseEnterDelay={
                                    //           tooltips.SETTINGS.mouseEnterDelay
                                    //       }
                                    //   >
                                    //       <Icon
                                    //           type="delete"
                                    //           onClick={(evt) => {
                                    //               this.handleDeleteCompetitor(
                                    //                   evt,
                                    //                   item
                                    //               );
                                    //           }}
                                    //           style={{
                                    //               color: scss_variables.scss_color_pallete_error,
                                    //           }}
                                    //       />
                                    //   </Tooltip>,
                                ]
                            }
                        >
                            <List.Item.Meta
                                title={
                                    <div key={item.name}>
                                        {/* <Checkbox
                                            onChange={(e) =>
                                                this.listBulk.handleCheck(
                                                    item.key
                                                )
                                            }
                                            checked={
                                                this.listBulk
                                                    ? this.listBulk.isChecked(
                                                          item.key
                                                      )
                                                    : false
                                            }
                                            style={{ marginRight: 10 }}
                                        /> */}
                                        {item.account_name}
                                        <br />
                                        {/* <Icon
                                            type="arrow-right"
                                            style={{ marginRight: 5 }}
                                        /> */}
                                        <Icon
                                            type={item.channel_type}
                                            theme={
                                                item.channel_type !== "twitter"
                                                    ? "filled"
                                                    : null
                                            }
                                            style={{
                                                color: this.props.store.getTagColorForType(
                                                    item.channel_type
                                                ),
                                                marginRight: 5,
                                                marginBottom: 8,
                                                float: "left",
                                            }}
                                        />
                                        <a
                                            rel="noreferrer"
                                            href={item.channel_link}
                                            target="_blank"
                                            style={{
                                                color: this.props.store.getTagColorForType(
                                                    item.channel_type
                                                ),
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {item.channel_name}
                                        </a>
                                    </div>
                                }
                            />
                            <div
                                style={{
                                    textAling: "left",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                                onClick={(e) =>
                                    this.openMasterpost(item.masterpost_name)
                                }
                            >
                                {item.masterpost_name}
                            </div>
                            <div style={{ textAling: "left" }}>
                                {/* {item.schedule_datetime} */}
                                {moment(item.schedule_datetime).format("lll")}
                            </div>
                            <div style={{ textAling: "left" }}>
                                {item.status === "upcoming" ? (
                                    <Icon
                                        type="clock-circle"
                                        title="Upcoming"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    />
                                ) : null}
                                {item.status === "posted" ? (
                                    <Icon
                                        type="check"
                                        title="Posted"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    />
                                ) : null}
                                {item.status === "error" ? (
                                    <SocialErrorSymbol
                                        errors={item.errors}
                                        store={this.props.store}
                                    />
                                ) : null}
                            </div>
                        </List.Item>
                    )}
                />
            );

        return this.state.ready === false ? (
            <div
                style={{
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Icon
                    type="loading"
                    style={{ fontSize: "3rem", margin: 10 }}
                    spin
                />
                Processing ...
            </div>
        ) : (
            <Fragment>
                {header}
                {body}
                {/* <CompetitorsListModal
                        data={this.state.edit_data}
                        store={this.props.store}`~`~
                    /> */}
            </Fragment>
        );
    }
}

export default observer(ScheduleList);
