import React, { Fragment } from "react";
import { Row, Col, Table, Divider } from "antd";
import ReportSingleValue from "../Reports/ReportSingleValue";
import ReactJson from "react-json-view";

const GRID_GUTTER = 40;

const AccountsChannelInsightsTwitter = ({ channel_insights }) => {
    const totalStatistics = {
        like_count: 0,
        user_profile_clicks: 0,
        retweet_count: 0,
        impression_count: 0,
        reply_count: 0,
        quote_count: 0,
        possibly_sensitive: 0,
        includes_media_count: 0,
        includes_users_count: 0,
    };

    // Total Statistics
    channel_insights &&
        channel_insights.data &&
        channel_insights.data.map((el) => {
            totalStatistics.like_count += el.organic_metrics?.like_count;
            totalStatistics.user_profile_clicks +=
                el.organic_metrics?.user_profile_clicks;
            totalStatistics.retweet_count += el.organic_metrics?.retweet_count;
            totalStatistics.impression_count +=
                el.organic_metrics?.impression_count;
            totalStatistics.reply_count += el.organic_metrics?.reply_count;
            totalStatistics.quote_count += el.public_metrics?.quote_count;
            totalStatistics.possibly_sensitive +=
                el.possibly_sensitive === true ? 1 : 0;
        });

    if (
        channel_insights &&
        channel_insights.includes &&
        channel_insights.includes.media
    ) {
        totalStatistics.includes_media_count =
            channel_insights.includes.media.length;
    }

    if (
        channel_insights &&
        channel_insights.includes &&
        channel_insights.includes.users
    ) {
        totalStatistics.includes_users_count =
            channel_insights.includes.users.length;
    }

    const sharesStatsPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Likes"
                        value={totalStatistics && totalStatistics.like_count}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="User Profile clicks"
                        value={
                            totalStatistics &&
                            totalStatistics.user_profile_clicks
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Retweets"
                        value={totalStatistics && totalStatistics.retweet_count}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Impressions"
                        value={
                            totalStatistics && totalStatistics.impression_count
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Replies"
                        value={totalStatistics && totalStatistics.reply_count}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Quotes"
                        value={totalStatistics && totalStatistics.quote_count}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Possibly sensitive"
                        value={
                            totalStatistics &&
                            totalStatistics.possibly_sensitive
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Included Media"
                        value={
                            totalStatistics &&
                            totalStatistics.includes_media_count
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Included Users"
                        value={
                            totalStatistics &&
                            totalStatistics.includes_users_count
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
        </Fragment>
    );

    return (
        <Fragment>
            {sharesStatsPanel}
            {/* <pre>{JSON.stringify(totalShareStatistics, null, 2)}</pre> */}
            <Divider />

            {/* <ReactJson src={totalStatistics} />
            <ReactJson src={channel_insights} /> */}
            {/* <Divider /> */}
            {/* <pre>{JSON.stringify(stats, null, 2)}</pre> */}
        </Fragment>
    );
};

export default AccountsChannelInsightsTwitter;
