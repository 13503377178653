import React, { Component, Fragment } from "react";
import { Form, Modal, Icon, AutoComplete, Tabs, Input, Alert } from "antd";

import EditableTagGroup from "../EditableTagGroup";
// import FilePicker from "../FilePicker";
// import AdsPreview from "./AdsPreview";
// import config from "../../config";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";

const { TabPane } = Tabs;
// const { TextArea } = Input;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        typeTimer = null;

        constructor(props) {
            super(props);

            // grab the data props and store it as a state
            this.state = props.data;
        }

        componentDidMount() {
            // get the targeting info

            let ads_networks = this.props.data.ads
                .map((ad) => ad.network)
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
            console.info("ads_networks", ads_networks);
            this.setState({ ads_networks });
        }

        handleCampaignsChange = (_campaigns) => {
            this.setState({ campaigns: _campaigns });
        };

        handleFilePickerChange = (_info) => {
            console.info("handleFilePickerChange _info", _info);
            const image_bytes = _info.image_data
                ? _info.image_data.split(",")[1]
                : _info.previewURL;
            let { ad_model } = this.state;
            ad_model.image_bytes = image_bytes;
            this.setState({ ad_model });
        };

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            // const { facebook_targeting, fetching } = this.state;

            // const loadingIcon = fetching ? (
            //     <Icon type="loading" style={{ fontSize: 24 }} spin />
            // ) : null;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };

            const allAccounts = this.props.store.getAllAccounts.map((a) => {
                let accountData = {
                    title: `${a.name}`,
                    key: a.key,
                };
                if (
                    a.facebook &&
                    a.facebook.data &&
                    a.facebook.data.length > 0
                ) {
                    accountData.children = a.facebook.data.map((d) => {
                        return {
                            title: d.name,
                            key: a.key + "|" + d.id,
                        };
                    });
                }
                return accountData;
            });
            console.info(
                "allAccounts",
                allAccounts,
                "this.state.ad",
                this.state.ad
            );

            // const singleImageAdFields = (
            //     <Fragment>
            //         <Form.Item label="Title">
            //             {getFieldDecorator("ad_title", {
            //                 initialValue:
            //                     this.state.ad && this.state.ad.title
            //                         ? this.state.ad.title
            //                         : null,
            //                 onChange: (e) => {
            //                     let { ad } = this.state;
            //                     ad.title = e.target.value;
            //                     this.setState({
            //                         ad,
            //                     });
            //                 },
            //             })(<Input maxLength={25} />)}
            //         </Form.Item>
            //         <Form.Item label="Body">
            //             {getFieldDecorator("ad_body", {
            //                 initialValue:
            //                     this.state.ad && this.state.ad.body
            //                         ? this.state.ad.body
            //                         : null,
            //                 onChange: (e) => {
            //                     let { ad } = this.state;
            //                     ad.body = e.target.value;
            //                     this.setState({
            //                         ad,
            //                     });
            //                 },
            //             })(<TextArea rows={2} />)}
            //         </Form.Item>
            //         <Form.Item label="URL">
            //             {getFieldDecorator("ad_url", {
            //                 initialValue:
            //                     this.state.ad && this.state.ad.url
            //                         ? this.state.ad.url
            //                         : null,
            //                 onChange: (e) => {
            //                     let { ad } = this.state;
            //                     ad.url = e.target.value;
            //                     this.setState({
            //                         ad,
            //                     });
            //                 },
            //             })(<Input maxLength={1024} />)}
            //         </Form.Item>
            //         <Form.Item label="Call To Action">
            //             {getFieldDecorator("newad_calltoactiontype", {
            //                 initialValue:
            //                     this.state.ad && this.state.ad.calltoactiontype
            //                         ? this.state.ad.calltoactiontype
            //                         : null,
            //                 onChange: (e) => {
            //                     let { ad } = this.state;
            //                     ad.calltoactiontype = e;
            //                     this.setState({
            //                         ad,
            //                     });
            //                 },
            //             })(
            //                 <Select>
            //                     {config.FACEBOOK_ADS_CALLTOACTION_TYPES.map(
            //                         (option, optionIndex) => {
            //                             return (
            //                                 <Option
            //                                     key={optionIndex}
            //                                     value={option}
            //                                 >
            //                                     {option}
            //                                 </Option>
            //                             );
            //                         }
            //                     )}
            //                 </Select>
            //             )}
            //         </Form.Item>
            //         <Form.Item label="Ad Image">
            //             <FilePicker
            //                 store={this.props.store}
            //                 restrictions={{ width: 110, height: 90 }}
            //                 // nopreview
            //                 onChange={this.handleFilePickerChange}
            //                 selected={this.state.ad && this.state.ad.image}
            //             />
            //         </Form.Item>
            //     </Fragment>
            // );

            // const carouselAdFields = (_index) => {
            //     return (
            //         <Fragment>
            //             <Form.Item label="Title">
            //                 {getFieldDecorator(
            //                     "carousel_" + _index + "_title",
            //                     {
            //                         initialValue: this.state.ad.carousel[_index]
            //                             .title
            //                             ? this.state.ad.carousel[_index].title
            //                             : null,
            //                         onChange: (e) => {
            //                             let { ad } = this.state;
            //                             ad.carousel[_index].title =
            //                                 e.target.value;
            //                             this.setState({
            //                                 ad,
            //                             });
            //                         },
            //                     }
            //                 )(<Input maxLength={25} />)}
            //             </Form.Item>
            //             <Form.Item label="Body">
            //                 {getFieldDecorator("carousel_" + _index + "_body", {
            //                     initialValue: this.state.ad.carousel[_index]
            //                         .body
            //                         ? this.state.ad.carousel[_index].body
            //                         : null,
            //                     onChange: (e) => {
            //                         let { ad } = this.state;
            //                         ad.carousel[_index].body = e.target.value;
            //                         this.setState({
            //                             ad,
            //                         });
            //                     },
            //                 })(<TextArea rows={2} />)}
            //             </Form.Item>
            //             <Form.Item label="URL">
            //                 {getFieldDecorator("carousel_" + _index + "_url", {
            //                     initialValue: this.state.ad.carousel[_index].url
            //                         ? this.state.ad.carousel[_index].url
            //                         : null,
            //                     onChange: (e) => {
            //                         let { ad } = this.state;
            //                         ad.carousel[_index].url = e.target.value;
            //                         this.setState({
            //                             ad,
            //                         });
            //                     },
            //                 })(<Input maxLength={1024} />)}
            //             </Form.Item>
            //             <Form.Item label="Ad Image">
            //                 <FilePicker
            //                     store={this.props.store}
            //                     restrictions={{ width: 110, height: 90 }}
            //                     // nopreview
            //                     onChange={(file) =>
            //                         this.handleFilePickerChange(file, _index)
            //                     }
            //                     selected={
            //                         this.state.ad &&
            //                         this.state.ad.carousel[_index].image
            //                     }
            //                 />
            //             </Form.Item>
            //         </Fragment>
            //     );
            // };

            const infoPanel = (
                <Fragment>
                    <Form.Item label="Master Ad Name">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the name of the master ad!",
                                    type: "string",
                                },
                            ],
                            initialValue: this.state.name,
                            onChange: (e) =>
                                this.setState({ name: e.target.value }),
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Campaigns">
                        {getFieldDecorator("campaigns", {
                            initialValue: this.state.campaigns
                                ? this.state.campaigns
                                : [],
                            onChange: this.handleCampaignsChange,
                        })(
                            <EditableTagGroup
                                element_title="Campaigns"
                                tag_color={this.props.store.getTagColorForType(
                                    "campaign"
                                )}
                                options={this.props.store.getAllCampaigns.map(
                                    (campaign) => campaign.campaign
                                )}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Brand">
                        {getFieldDecorator("brand", {
                            initialValue: this.state.brand,
                            onChange: (e) => this.setState({ brand: e }),
                        })(
                            <AutoComplete
                                dataSource={this.props.store.getAllBrands}
                                // style={{ width: 200 }}
                                placeholder="input here"
                                filterOption={true}
                            />
                        )}
                    </Form.Item>
                </Fragment>
            );
            // const adModelPanel = (
            //     <Fragment>
            //         <div style={{ minHeight: 352 }}>
            //             <Row gutter={20}>
            //                 <Col span={12}>
            //                     <Form.Item label="Title">
            //                         {getFieldDecorator("title", {
            //                             rules: [
            //                                 {
            //                                     required: true,
            //                                     message:
            //                                         "Please input the title of the master ad!",
            //                                     type: "string",
            //                                 },
            //                             ],
            //                             initialValue: this.state.ad_model.title,
            //                             onChange: (e) => {
            //                                 let { ad_model } = this.state;
            //                                 ad_model.title = e.target.value;
            //                                 this.setState({ ad_model });
            //                             },
            //                         })(<Input />)}
            //                     </Form.Item>
            //                     <Form.Item label="Body">
            //                         {getFieldDecorator("body", {
            //                             rules: [
            //                                 {
            //                                     required: true,
            //                                     message:
            //                                         "Please input the body of the master ad!",
            //                                     type: "string",
            //                                 },
            //                             ],
            //                             initialValue: this.state.ad_model.body,
            //                             onChange: (e) => {
            //                                 let { ad_model } = this.state;
            //                                 ad_model.body = e.target.value;
            //                                 this.setState({ ad_model });
            //                             },
            //                         })(<TextArea />)}
            //                     </Form.Item>
            //                     <Form.Item label="URL">
            //                         {getFieldDecorator("url", {
            //                             rules: [
            //                                 {
            //                                     required: true,
            //                                     message:
            //                                         "Please input the url of the master ad!",
            //                                     type: "string",
            //                                 },
            //                             ],
            //                             initialValue: this.state.ad_model.url,
            //                             onChange: (e) => {
            //                                 let { ad_model } = this.state;
            //                                 ad_model.url = e.target.value;
            //                                 this.setState({ ad_model });
            //                             },
            //                         })(<Input />)}
            //                     </Form.Item>
            //                     <Form.Item label="Image">
            //                         <FilePicker
            //                             store={this.props.store}
            //                             // restrictions={{ width: 110, height: 90 }}
            //                             // nopreview
            //                             onChange={this.handleFilePickerChange}
            //                             selected={
            //                                 this.state.ad_model &&
            //                                 this.state.ad_model.image
            //                             }
            //                         />
            //                     </Form.Item>
            //                 </Col>
            //                 <Col span={12} style={{ paddingLeft: 50 }}>
            //                     <AdsPreview
            //                         networks={{
            //                             facebook:
            //                                 this.state.ads_networks &&
            //                                 this.state.ads_networks.indexOf(
            //                                     "facebook"
            //                                 ) !== -1,
            //                             linkedin:
            //                                 this.state.ads_networks &&
            //                                 this.state.ads_networks.indexOf(
            //                                     "linkedin"
            //                                 ) !== -1,
            //                         }}
            //                         store={this.props.store}
            //                         link_url={this.state.ad_model.url}
            //                         title={this.state.ad_model.title}
            //                         description={this.state.ad_model.body}
            //                         image_url={
            //                             this.state.ad_model.image_bytes
            //                                 ? "data:image/jpeg;base64," +
            //                                   this.state.ad_model.image_bytes
            //                                 : null
            //                         }
            //                         defaultTab="facebook"
            //                         type={"singleimagead"}
            //                         cta_type={"SHOP_NOW"}
            //                         linkedin_newad_type={"SPONSORED_UPDATES"}
            //                     />
            //                 </Col>
            //             </Row>
            //         </div>
            //     </Fragment>
            // );

            return (
                <Form>
                    <div
                        style={{
                            height: "50vh",
                            maxHeight: "50vh",
                            overflow: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        <Tabs defaultActiveKey="info">
                            <TabPane tab={"Info"} key="info">
                                <div
                                    style={{
                                        paddingTop: 20,
                                        maxWidth: 560,
                                        margin: "0 auto",
                                    }}
                                >
                                    <Fragment>{infoPanel}</Fragment>
                                </div>
                            </TabPane>

                            {/* <TabPane tab={"Ad Model"} key="ad">
                              <div style={{ paddingTop: 20 }}>
                                    <Fragment>{adModelPanel}</Fragment>
                              </div>
                        </TabPane> */}
                        </Tabs>
                    </div>
                    {/* <pre>{JSON.stringify(this.state.ad_model, null, 2)}</pre> */}
                    {/* <hr />
                    <pre>{JSON.stringify(this.props.data, null, 2)}</pre> */}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class AdsListModalMasterEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "ads_modal_master_edit",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("ads_modal_master_edit");
    };

    onModalCreate = async () => {
        //   const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("formState", formState);

        if (typeof formState.brand === "undefined") formState.brand = "";
        if (typeof formState.campaigns === "undefined")
            formState.campaigns = [];

        let ads = [];

        // targeting
        let targeting = {};
        let targeting_info = {};

        if (formState.facebook_targeting.selected_overwrite_gender) {
            targeting.gender = formState.facebook_targeting.selected_gender;
            targeting_info.gender =
                formState.facebook_targeting.selected_gender;
        }
        if (formState.facebook_targeting.selected_overwrite_age) {
            targeting.age = formState.facebook_targeting.selected_age;
            targeting_info.age = formState.facebook_targeting.selected_age;
        }
        if (formState.facebook_targeting.selected_overwrite_country) {
            targeting.country = formState.facebook_targeting.selected_country.map(
                (country) => country.key
            );
            targeting_info.country =
                formState.facebook_targeting.selected_country;
        }
        if (formState.facebook_targeting.selected_overwrite_region) {
            targeting.region = formState.facebook_targeting.selected_region.map(
                (region) => region.key
            );
            targeting_info.region =
                formState.facebook_targeting.selected_region;
        }
        if (formState.facebook_targeting.selected_overwrite_city) {
            targeting.city = formState.facebook_targeting.selected_city.map(
                (city) => city.key
            );
            targeting_info.city = formState.facebook_targeting.selected_city;
        }
        if (formState.facebook_targeting.selected_overwrite_zip) {
            targeting.zip = formState.facebook_targeting.selected_zip.map(
                (zip) => zip.key
            );
            targeting_info.zip = formState.facebook_targeting.selected_zip;
        }
        if (formState.facebook_targeting.selected_overwrite_language) {
            targeting.language = formState.facebook_targeting.selected_language.map(
                (language) => language.key
            );
            targeting_info.language =
                formState.facebook_targeting.selected_language;
        }
        if (formState.facebook_targeting.selected_overwrite_interest) {
            targeting.interest = formState.facebook_targeting.selected_interest;
            targeting_info.interest =
                formState.facebook_targeting.selected_interest;
        }
        console.info("targeting", targeting, "targeting_info", targeting_info);

        const all_masterposts = this.formRef.getTreeData();
        if (!all_masterposts) return;
        console.info("all_masterposts", all_masterposts);
        all_masterposts.map((masterpost) => {
            masterpost.children.map((child) => {
                if (formState.masterposts.indexOf(child.key) !== -1) {
                    let ad_object = {
                        account_key: child.account_key,
                        post_id: child.key,
                        masterpost_key: masterpost.key,
                        campaign_name: formState.ad_name,
                        adset_name: formState.ad_name,
                        ad_name: formState.ad_name,
                        objective: formState.facebook_details.objective,
                        status: formState.facebook_details.status,
                        optimization_goal:
                            formState.facebook_details.optimization_goal,
                        billing_event: formState.facebook_details.billing_event,
                        bid_amount: formState.facebook_details.bid_amount,
                        daily_budget: formState.facebook_details.daily_budget,
                        start_time: formState.facebook_details.start_time,
                        end_time: formState.facebook_details.end_time,
                        brand: formState.brand,
                        campaigns: formState.campaigns,
                        targeting: targeting,
                        targeting_info: targeting_info,
                    };
                    ads.push(ad_object);
                }
                return false;
            });

            return false;
        });

        console.info("ads", ads);

        this.setState({ processing: true });

        try {
            let result = await Promise.all(
                ads.map((ad) => this.props.store.addAd(ad))
            );
            console.info("result", result);
            social_notification("publish_ad", "success");

            //form.resetFields();
            this.onModalCancel();
        } catch (err) {
            console.error(err);
            social_notification("publish_ad", "success", {
                description: err,
            });
        }
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.props.data.key);

            this.props.store.updateCompetitor(this.props.data.key, values);

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        this.setState({ processing: false });
        this.props.store.hideModalByKey(
            "ads_modal_master_edit",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        width={800} // 1200
                        centered
                        icon="form"
                        title={"Master Ad Edit"}
                        okText={"Save"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalCancel} //onApply
                        maskClosable={false}
                        // footer={
                        //     <Fragment>
                        //         <Button
                        //             type="primary"
                        //             onClick={this.onModalCancel}
                        //         >
                        //             Close
                        //         </Button>
                        //     </Fragment>
                        // }
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AdsListModalMasterEdit);
