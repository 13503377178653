import React, { Component, Fragment } from "react";
import {
    Empty,
    Card,
    Button,
    Icon,
    Divider,
    Row,
    Col,
    Badge,
    Tooltip,
    Typography,
    Modal,
} from "antd";
import { observer } from "mobx-react";
import scss_variables from "./../../_variables.scss";
import ReportsModal from "./ReportsModal";

const { Title } = Typography;
const { confirm } = Modal;

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_report: null,
            custom_reports: [],
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("reports");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Reports" }]);
    }

    componentDidMount() {
        this.loadInformation();
    }

    componentWillUnmount() {
        this.props.store.unregisterModalListener("reports_modal");
    }

    loadInformation = async () => {
        let allReports = this.props.store.getAllReports;
        this.setState({ reports: allReports });

        this.props.store
            .getAllCustomReports()
            .then((customReports) => {
                console.info("customReports", customReports);
                this.setState({ custom_reports: customReports });
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    handleModalClick = (_evt, _report) => {
        console.info("handleModalClick _evt", _evt, "_report", _report);
        this.setState({ selected_report: _report });

        this.props.store.showModalByKey("reports_modal");
        // this.props.store.registerModalListener("reports_modal", (_action) => {
        //     console.info("handleModalListener _action", _action);
        //     if (_action === "hide") this.loadInformation();
        // });
    };

    handleDeleteCustomReport = async (_custom_report) => {
        const that = this;
        confirm({
            title: "Do you Want to delete this report?",
            content: "The custom report will be deleted permanently",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                console.log("OK");
                that.props.store
                    .deleteCustomReport(_custom_report.key)
                    .then(() => {
                        that.loadInformation(); // refresh the list
                    })
                    .catch((error) =>
                        that.props.store.displayErrorNotification({
                            title: "Something went wrong",
                            description: "Please try again later.",
                        })
                    );
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    render() {
        const loggedUser = this.props.store.getLoggedUser;

        let header = (
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 10 }}
            >
                <Col>
                    <Title level={3} style={{ float: "left", paddingRight: 5 }}>
                        Reports
                    </Title>
                    {/* <Badge
                        count={this.state.reports && this.state.reports.length}
                        style={{ backgroundColor: "#1890ff" }}
                    /> */}
                    {/* <Search
                        placeholder="input search text"
                        onSearch={this.onSearchChange}
                        style={{ width: 200, marginLeft: 20 }}
                    /> */}
                </Col>
                {/* <Col>
                    {
                        <Tooltip title="Setup new custom report">
                            <Button
                                type="ghost"
                                icon="plus"
                                size="small"
                                onClick={this.showModal}
                                style={{
                                    marginLeft: 20,
                                    marginTop: 5,
                                    fontSize: 12,
                                }}
                            >
                                New custom report
                            </Button>
                        </Tooltip>
                    }
                </Col> */}
            </Row>
        );

        const body =
            !this.state.reports ||
            (this.state.reports && this.state.reports.length === 0) ? (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Reports Data"
                />
            ) : (
                <Fragment>
                    {/* <pre>
                        custom_reports ={" "}
                        {JSON.stringify(this.state.custom_reports, null, 2)}
                    </pre> */}
                    <div className="ReportsList">
                        {this.state.reports &&
                            this.state.reports.map((report, reportIndex) => {
                                return (
                                    <Card
                                        key={reportIndex}
                                        title={
                                            <Fragment>{report.name}</Fragment>
                                        }
                                        hoverable
                                        style={{
                                            width: 320,
                                            height: 200,
                                            borderRadius: 3,
                                            display: "inline-block",
                                            // float: "left",
                                            marginRight: 20,
                                            marginBottom: 20,
                                        }}
                                        onClick={(e) =>
                                            this.handleModalClick(e, report)
                                        }
                                    >
                                        <p style={{ marginBottom: 20 }}>
                                            {report.description}
                                        </p>
                                        {report.channels_filter.indexOf(
                                            "facebook"
                                        ) !== -1 ? (
                                            <Icon
                                                type="facebook"
                                                theme="filled"
                                                style={{
                                                    margin: 5,
                                                    fontSize: "1.5rem",
                                                    float: "left",
                                                    color: "#ccc",
                                                }}
                                            />
                                        ) : null}
                                        {report.channels_filter.indexOf(
                                            "instagram"
                                        ) !== -1 ? (
                                            <Icon
                                                type="instagram"
                                                theme="filled"
                                                style={{
                                                    margin: 5,
                                                    fontSize: "1.5rem",
                                                    float: "left",
                                                    color: "#ccc",
                                                }}
                                            />
                                        ) : null}
                                        {report.channels_filter.indexOf(
                                            "twitter"
                                        ) !== -1 ? (
                                            <Icon
                                                type="twitter-square"
                                                theme="filled"
                                                style={{
                                                    margin: 5,
                                                    fontSize: "1.5rem",
                                                    float: "left",
                                                    color: "#ccc",
                                                }}
                                            />
                                        ) : null}
                                        {report.channels_filter.indexOf(
                                            "linkedin"
                                        ) !== -1 ? (
                                            <Icon
                                                type="linkedin"
                                                theme="filled"
                                                style={{
                                                    margin: 5,
                                                    fontSize: "1.5rem",
                                                    float: "left",
                                                    color: "#ccc",
                                                }}
                                            />
                                        ) : null}
                                        <Button
                                            type="primary"
                                            style={{ float: "right" }}
                                            onClick={(e) =>
                                                this.handleModalClick(e, report)
                                            }
                                        >
                                            Generate
                                        </Button>
                                    </Card>
                                );
                            })}
                        {this.state.custom_reports.length > 0 ? (
                            // <Divider orientation="left">Custom Reports</Divider>
                            <Title level={3}>Custom Reports</Title>
                        ) : null}
                        {this.state.custom_reports &&
                            this.state.custom_reports.map(
                                (report, reportIndex) => {
                                    return (
                                        <Card
                                            key={reportIndex}
                                            title={
                                                <Fragment>
                                                    <Row>
                                                        <Col
                                                            span={22}
                                                            style={{
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                        >
                                                            {report.name}
                                                        </Col>
                                                        <Col
                                                            span={2}
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        >
                                                            {loggedUser &&
                                                            report.user &&
                                                            loggedUser.uid ===
                                                                report.user
                                                                    .uid ? (
                                                                <Tooltip title="Delete the report">
                                                                    <Icon
                                                                        type="delete"
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.handleDeleteCustomReport(
                                                                                report
                                                                            )
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            }
                                            hoverable
                                            style={{
                                                width: 350,
                                                height: 200,
                                                borderRadius: 3,
                                                display: "inline-block",
                                                // float: "left",
                                                marginRight: 20,
                                                marginBottom: 20,
                                                backgroundColor:
                                                    scss_variables.scss_color_customReport,
                                            }}
                                            onClick={(e) =>
                                                this.handleModalClick(e, report)
                                            }
                                        >
                                            <p style={{ marginBottom: 20 }}>
                                                Based on:{" "}
                                                {report.original_report.name}
                                            </p>
                                            {report.original_report.channels_filter.indexOf(
                                                "facebook"
                                            ) !== -1 ? (
                                                <Icon
                                                    type="facebook"
                                                    theme="filled"
                                                    style={{
                                                        margin: 5,
                                                        fontSize: "1.5rem",
                                                        float: "left",
                                                        color: "#ccc",
                                                    }}
                                                />
                                            ) : null}
                                            {report.original_report.channels_filter.indexOf(
                                                "instagram"
                                            ) !== -1 ? (
                                                <Icon
                                                    type="instagram"
                                                    theme="filled"
                                                    style={{
                                                        margin: 5,
                                                        fontSize: "1.5rem",
                                                        float: "left",
                                                        color: "#ccc",
                                                    }}
                                                />
                                            ) : null}
                                            {report.original_report.channels_filter.indexOf(
                                                "twitter"
                                            ) !== -1 ? (
                                                <Icon
                                                    type="twitter-square"
                                                    theme="filled"
                                                    style={{
                                                        margin: 5,
                                                        fontSize: "1.5rem",
                                                        float: "left",
                                                        color: "#ccc",
                                                    }}
                                                />
                                            ) : null}
                                            <Button
                                                type="primary"
                                                style={{ float: "right" }}
                                                onClick={(e) =>
                                                    this.handleModalClick(
                                                        e,
                                                        report
                                                    )
                                                }
                                            >
                                                Generate
                                            </Button>
                                        </Card>
                                    );
                                }
                            )}

                        <ReportsModal
                            report={this.state.selected_report}
                            store={this.props.store}
                        />
                    </div>
                </Fragment>
            );
        return (
            <Fragment>
                {header}
                {body}
            </Fragment>
        );
    }
}

export default observer(Reports);
