import React, { Component } from "react";
import { Popover, Badge, Avatar, Button, Menu, Dropdown, Icon } from "antd";
import { observer } from "mobx-react";

import Compose from "./../Compose";
import WarningMessagesList from "./WarningMessagesList";

class ExtraDashboardHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_notifications: 0,
        };
    }

    componentDidMount() {
        // get new notifications
    }

    handleBadgeClick = () => {
        if (this.props.history) this.props.history.push(`/dashboard/inbox`);
    };

    handleMenuClick = (e) => {
        console.log("handleMenuClick", e, "this.props", this.props);
        switch (e.key) {
            case "settings":
                if (this.props && typeof this.props.onSettings === "function")
                    this.props.onSettings(e);
                break;
            case "logout":
                if (this.props && typeof this.props.onLogout === "function")
                    this.props.onLogout(e);
                break;
            default:
                break;
        }
    };

    render() {
        const loggedUser = this.props.store.getLoggedUser;

        const newNotifications = this.props.store.getAllNotifications.filter(
            (n) => {
                if (n.status === "new") return n;
                return false;
            }
        );

        const missingAccountForLoggedUser =
            this.props.store.getAllAccounts.filter(
                (account) => account.email === this.props.loggedUser.email
            ).length === 0 && this.props.loggedUser.level === "user"
                ? true
                : false;

        const menu = (
            <Menu
                onClick={this.handleMenuClick}
                mode="vertical"
                style={{ border: 0 }}
                className="social_ProfileMenu"
                selectable={false}
            >
                <Menu.Item key="settings">
                    <Icon type="setting" />
                    Settings
                </Menu.Item>
                <Menu.Item key="logout">
                    <Icon type="logout" />
                    Logout
                </Menu.Item>
            </Menu>
        );

        // const content = (
        //     <Button type="primary" onClick={this.props.onLogout}>
        //         Logout
        //     </Button>
        // );

        return (
            <div className={"social_extra_header"}>
                <div>
                    <span className="social_extra_header_worldName">
                        {loggedUser.world && loggedUser.world.name}
                    </span>
                </div>

                <div>
                    {!missingAccountForLoggedUser ? (
                        <Compose store={this.props.store} />
                    ) : null}
                    <WarningMessagesList store={this.props.store} />
                    <Popover
                        placement="bottom"
                        // content={content}
                        content={menu}
                        title={
                            this.props.loggedUser.displayName
                            // " | level:" +
                            // this.props.loggedUser.level
                        }
                    >
                        <Badge
                            count={newNotifications.length}
                            onClick={this.handleBadgeClick}
                            style={{
                                transform: "scale(0.8)",
                                right: -11,
                            }}
                        >
                            <Avatar
                                icon="user"
                                style={{ clear: "both" }}
                                src={this.props.loggedUser.photoURL}
                            />
                        </Badge>
                    </Popover>
                    {/* <Dropdown overlay={menu}>
                        <Badge
                            count={newNotifications.length}
                            onClick={this.handleBadgeClick}
                            style={{
                                transform: "scale(0.8)",
                                right: -11,
                            }}
                        >
                            <Avatar
                                icon="user"
                                style={{ clear: "both" }}
                                src={this.props.loggedUser.photoURL}
                            />
                        </Badge>
                    </Dropdown> */}
                </div>
            </div>
        );
    }
}

export default observer(ExtraDashboardHeader);
