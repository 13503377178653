import React, { Fragment } from "react";
import { Row, Col, Table, Divider } from "antd";
import ReportSingleValue from "./../Reports/ReportSingleValue";
import ReactJson from "react-json-view";

import scss_variables from "./../../_variables.scss";
import SocialDataPresentor from "./../SocialDataPresentor";

const GRID_GUTTER = 40;

const AccountsChannelInsightsInstagram = ({ channel_insights }) => {
    if (Object.keys(channel_insights).length === 0) return null;

    const getInsightsData = (_data, _key) => {
        return (
            _data &&
            _data.filter &&
            _data.filter((d) => {
                if (d.name === _key) return d;
                return false;
            })
        );
    };

    // TOTAL FANS
    const impressions = getInsightsData(channel_insights, "impressions")[0];
    // Website Clicks
    const website_clicks = getInsightsData(
        channel_insights,
        "website_clicks"
    )[0];
    // Text Message Clicks
    const text_message_clicks = getInsightsData(
        channel_insights,
        "text_message_clicks"
    )[0];
    // Reach
    const reach = getInsightsData(channel_insights, "reach")[0];
    // Profile Views
    const profile_views = getInsightsData(channel_insights, "profile_views")[0];

    // Phone Call Clicks
    const phone_call_clicks = getInsightsData(
        channel_insights,
        "phone_call_clicks"
    )[0];

    // Online Followers
    const online_followers = getInsightsData(
        channel_insights,
        "online_followers"
    )[0];

    // Get Directions Clicks
    const get_directions_clicks = getInsightsData(
        channel_insights,
        "get_directions_clicks"
    )[0];

    // Followers Count
    //     const follower_count = getInsightsData(
    //         channel_insights,
    //         "follower_count"
    //     )[0];

    // Email Contacts
    const email_contacts = getInsightsData(
        channel_insights,
        "email_contacts"
    )[0];

    // Audience Locale
    const audience_locale = getInsightsData(
        channel_insights,
        "audience_locale"
    )[0];

    // Audience Gender Age
    const audience_gender_age = getInsightsData(
        channel_insights,
        "audience_gender_age"
    )[0];

    // Audience Country
    const audience_country = getInsightsData(
        channel_insights,
        "audience_country"
    )[0];

    // Audience City
    const audience_city = getInsightsData(channel_insights, "audience_city")[0];

    const sharesStatsPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER}>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (impressions && impressions.title) || "IMPRESSIONS"
                        }
                        description={impressions && impressions.description}
                        value={impressions && impressions.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="impressions"
                        link="http://www.cnn.com"
                    />
                </Col>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (website_clicks && website_clicks.title) ||
                            "Website Clicks"
                        }
                        description={
                            website_clicks && website_clicks.description
                        }
                        value={website_clicks && website_clicks.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="website_clicks"
                        link="http://www.cnn.com"
                    />
                </Col>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (text_message_clicks &&
                                text_message_clicks.title) ||
                            "Text Message Clicks"
                        }
                        description={
                            text_message_clicks &&
                            text_message_clicks.description
                        }
                        value={
                            text_message_clicks && text_message_clicks.values
                        }
                        value_name="value"
                        key_name="end_time"
                        // metric_name="text_message_clicks"
                        link="http://www.cnn.com"
                    />
                </Col>
            </Row>
            <Divider dashed />
            <Row gutter={GRID_GUTTER}>
                <Col span={8}>
                    <SocialDataPresentor
                        title={(reach && reach.title) || "REACH"}
                        description={reach && reach.description}
                        value={reach && reach.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="reach"
                        link="http://www.cnn.com"
                    />
                    {/* profile_views */}
                </Col>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (profile_views && profile_views.title) ||
                            "PROFILE VIEWS"
                        }
                        description={profile_views && profile_views.description}
                        value={profile_views && profile_views.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="profile_views"
                        link="http://www.cnn.com"
                    />
                </Col>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (phone_call_clicks && phone_call_clicks.title) ||
                            "PHONE CALL CLICKS"
                        }
                        description={
                            phone_call_clicks && phone_call_clicks.description
                        }
                        value={phone_call_clicks && phone_call_clicks.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="phone_call_clicks"
                        link="http://www.cnn.com"
                    />
                </Col>
            </Row>
            <Divider dashed />
            <Row gutter={GRID_GUTTER}>
                <Col span={12}>
                    <SocialDataPresentor
                        title={
                            (get_directions_clicks &&
                                get_directions_clicks.title) ||
                            "GET DIRECTIONS CLICKS"
                        }
                        description={
                            get_directions_clicks &&
                            get_directions_clicks.description
                        }
                        value={
                            get_directions_clicks &&
                            get_directions_clicks.values
                        }
                        value_name="value"
                        key_name="end_time"
                        // metric_name="get_directions_clicks"
                        link="http://www.cnn.com"
                    />
                </Col>
                {/* <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (follower_count && follower_count.title) ||
                            "FOLLOWER COUNT"
                        }
                        description={
                            follower_count && follower_count.description
                        }
                        value={follower_count && follower_count.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="follower_count"
                        link="http://www.cnn.com"
                    />
                </Col> */}
                <Col span={12}>
                    <SocialDataPresentor
                        title={
                            (email_contacts && email_contacts.title) ||
                            "EMAIL CONTACTS"
                        }
                        description={
                            email_contacts && email_contacts.description
                        }
                        value={email_contacts && email_contacts.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="email_contacts"
                        link="http://www.cnn.com"
                    />
                </Col>
            </Row>
            <Divider dashed />
        </Fragment>
    );

    return (
        <Fragment>
            {sharesStatsPanel}
            {/* <pre>{JSON.stringify(totalShareStatistics, null, 2)}</pre> */}
            <Divider />

            {/* <ReactJson src={totalStatistics} /> */}
        </Fragment>
    );
};

export default AccountsChannelInsightsInstagram;
