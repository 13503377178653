const names = {
    // basic names
    NAMES: {
        MASTERPOST: { plural: "primary posts", singular: "primary post" },
    },
    get(_term, _plural, _capitalCaseFlag, _eachWord) {
        if (this.NAMES[_term]) {
            const result = _plural
                ? this.NAMES[_term].plural
                : this.NAMES[_term].singular;
            return !_capitalCaseFlag
                ? result
                : !_eachWord
                ? result.charAt(0).toUpperCase() + result.slice(1)
                : this.capitalizeEachWord(result);
        }
    },
    capitalizeEachWord(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    },
};

export default names;
