import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";

import {
    Form,
    Radio,
    Button,
    Switch,
    Icon,
    Row,
    Col,
    Divider,
    Alert,
} from "antd";

class RegisterFormStripe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account_email: "",
            subscriptions: [],
            selected_subscription: null,
            additional_feature_conversion_tracking: false,
            additional_feature_conversion_tracking_amount: 20,
            // additional_feature_ad_budget: false,
            // additional_feature_ad_budget_amount: 60,
        };
    }

    async componentDidMount() {
        //this.setState(this.props.data);
        console.info("PaymentForm props", this.props);
        this.setState({
            stripe: this.props.stripe,
            account_email: this.props.data.account_email,
            socialData: this.props.data.socialData,
            world_key: this.props.world_key,
        });
        // load subscriptions
        let that = this;
        console.info("BEFORE loadPlans");
        await this.props.store
            .loadPlans(this.props.world_key)
            .then((plansData) => {
                console.info("plansData", plansData);
                that.setState({
                    subscriptions: plansData,
                    selected_subscription: plansData[0],
                    otal: Number(plansData[0].amount),
                });

                setTimeout(function () {
                    that.handleCalculation();
                }, 100);
            })
            .catch((err) => {
                console.warn(err);
            });
    }

    handleSubscriptionSelection = (e) => {
        console.info("handleSubscriptionSelection", e);
        let foundSubscription = this.state.subscriptions.filter(
            (plan) => plan.key === e.target.value
        )[0];
        this.setState({ selected_subscription: foundSubscription });
        console.info("foundSubscription", foundSubscription);

        // recalculate
        let that = this;
        setTimeout(function () {
            that.handleCalculation();
        }, 100);

        if (typeof this.props.handleUpdate === "function")
            this.props.handleUpdate(this.state);
    };

    handleAdditionalFeature = (_type, _e) => {
        console.info("handleAdditionalFeature _type", _type, "_e", _e);
        switch (_type) {
            case "conversion_tracking":
                this.setState({ additional_feature_conversion_tracking: _e });
                break;
            case "ad_budget":
                this.setState({ additional_feature_ad_budget: _e });
                break;
            default:
                break;
        }
        let that = this;
        setTimeout(function () {
            that.handleCalculation();
        }, 100);
    };

    handleCalculation = () => {
        console.info("handleCalculation this.state", this.state);
        let total = 0,
            channels_amount = 0;

        if (this.state.selected_subscription) {
            console.log("WE HAVE this.state.selected_subscription");
            total = total + Number(this.state.selected_subscription.amount);
        }

        // channels
        if (this.state.selected_subscription.free_channels) {
            console.log(
                "WE HAVE this.state.selected_subscription.free_channels"
            );
            const channelsLength =
                this.state.socialData.channels.fbChannels.length +
                this.state.socialData.channels.igAccounts.length;

            channels_amount = Number(
                (channelsLength -
                    this.state.selected_subscription.free_channels) *
                    this.state.selected_subscription.amount_per_channel
            );
            total = total + Number(channels_amount);
            console.log("WE HAVE channels_amount=" + channels_amount);
        }

        if (this.state.additional_feature_conversion_tracking === true) {
            console.log(
                "WE HAVE this.state.additional_feature_conversion_tracking"
            );
            const channelsLength =
                this.state.socialData.channels.fbChannels.length +
                this.state.socialData.channels.igAccounts.length;
            total =
                total +
                Number(
                    this.state.additional_feature_conversion_tracking_amount *
                        channelsLength
                );
        }

        if (total < 0) total = 0;
        if (channels_amount < 0) channels_amount = 0;

        // if (this.state.additional_feature_ad_budget === true) {
        //     total =
        //         total + Number(this.state.additional_feature_ad_budget_amount);
        // }
        console.log("total = " + total);

        this.setState({ total, channels_amount });
        return total;
    };

    render() {
        return (
            <Form
                // {...formItemLayout}
                style={{ width: "80%", margin: "0 auto" }}
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Form.Item label="Account Email">
                    <h2 style={{ marginTop: -15 }}>
                        {this.state.account_email}
                    </h2>
                </Form.Item>

                <Form.Item label="Account Channels">
                    {this.state.socialData &&
                        this.state.socialData.channels.fbChannels.map(
                            (fbChannel, fbChannelIndex) => {
                                return (
                                    <div
                                        key={fbChannelIndex}
                                        style={{
                                            display: "block",
                                            lineHeight: 1.5,
                                        }}
                                    >
                                        <Icon
                                            type="facebook"
                                            theme="filled"
                                            style={{
                                                color: this.props.store.getTagColorForType(
                                                    "facebook"
                                                ),
                                                marginRight: 10,
                                                fontSize: 17,
                                            }}
                                        />
                                        {fbChannel.name}
                                    </div>
                                );
                            }
                        )}
                    {this.state.socialData &&
                        this.state.socialData.channels.igAccounts.map(
                            (igAccount, igAccountIndex) => {
                                return (
                                    <div
                                        key={igAccountIndex}
                                        style={{
                                            display: "block",
                                            lineHeight: 1.5,
                                        }}
                                    >
                                        <Icon
                                            type="instagram"
                                            theme="filled"
                                            style={{
                                                color: this.props.store.getTagColorForType(
                                                    "instagram"
                                                ),
                                                marginRight: 10,
                                                fontSize: 17,
                                            }}
                                        />
                                        {igAccount.meta.name} (
                                        {igAccount.meta.username})
                                    </div>
                                );
                            }
                        )}
                </Form.Item>

                <br />
                {/* <Form.Item label="Subscription">
                    <Alert
                        type="info"
                        showIcon
                        description="Select the plan options you'd like to sbscribe below. Your card will be automatically charged each month.
                        You can cancel at any time and your subscription will expire at the end of the billing period."
                    />
                    <br />
                    <Radio.Group
                        value={
                            this.state.selected_subscription &&
                            this.state.selected_subscription.key
                        }
                        onChange={this.handleSubscriptionSelection}
                        style={{ marginBottom: 10, marginTop: -20 }}
                    >
                        {this.state.subscriptions.map((plan) => {
                            return (
                                <Radio.Button value={plan.key} key={plan.key}>
                                    {plan.name}
                                </Radio.Button>
                            );
                        })}
                    </Radio.Group>
                    <br />

                    <div style={{ lineHeight: 1.5 }}>
                        Amount: $
                        <strong>
                            {this.state.selected_subscription &&
                                this.state.selected_subscription.amount}
                        </strong>
                        <br />
                        Desciption:{" "}
                        <strong>
                            {this.state.selected_subscription &&
                                this.state.selected_subscription.description}
                        </strong>
                        <br />
                        Details:{" "}
                        <strong>
                            {this.state.selected_subscription &&
                                this.state.selected_subscription.details}
                        </strong>
                        <br />
                        Free channels:{" "}
                        <strong>
                            {this.state.selected_subscription &&
                                this.state.selected_subscription.free_channels}
                        </strong>
                    </div>

                    <Alert
                        type="info"
                        message="You can register additional social media channels at any time from within your account."
                        style={{
                            border: "none",
                            marginTop: 20,
                            marginBottom: 40,
                        }}
                    />
                    <Form.Item label="Features">
                        <Divider style={{ margin: "10px 0" }} />
                        <Row
                            type="flex"
                            justify="center"
                            align="top"
                            style={{ lineHeight: 1.8 }}
                        >
                            <Col span={12}>
                                <strong>Channels</strong>
                            </Col>

                            <Col
                                span={8}
                                style={{
                                    padding: "3px 11px",
                                }}
                            >
                                ${this.state.channels_amount}
                            </Col>
                            <Col
                                span={4}
                                style={{
                                    textAlign: "right",
                                }}
                            ></Col>
                        </Row>
                        <Divider style={{ margin: "10px 0" }} />
                        <Row
                            type="flex"
                            justify="center"
                            align="top"
                            style={{ lineHeight: 1.8 }}
                        >
                            <Col span={12}>
                                <strong>Conversion Tracking</strong>
                            </Col>

                            <Col
                                span={8}
                                style={{
                                    padding: "3px 11px",
                                }}
                            >
                                $20{" "}
                                <span style={{ fontSize: 10 }}>
                                    (per channel)
                                </span>
                            </Col>
                            <Col
                                span={4}
                                style={{
                                    textAlign: "right",
                                }}
                            >
                                <Switch
                                    checkedChildren={<Icon type="check" />}
                                    unCheckedChildren={<Icon type="close" />}
                                    onChange={(e) =>
                                        this.handleAdditionalFeature(
                                            "conversion_tracking",
                                            e
                                        )
                                    }
                                    checked={
                                        this.state
                                            .additional_feature_conversion_tracking
                                    }
                                    style={{
                                        display: "inline-block",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Divider style={{ margin: "10px 0" }} />

                        
                        <Row>
                            <Col style={{ textAlign: "right" }}>
                                Total: <strong>${this.state.total}</strong>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form.Item>
                <br />
                <br />
                <Form.Item label="Card">
                    <CardElement />
                </Form.Item>
                <br />
                <br /> */}
                {/* <pre>{JSON.stringify(this.state.socialData, null, 2)}</pre> */}

                <Button
                    onClick={(e) => {
                        if (typeof this.props.handleSubmit === "function") {
                            this.props.handleSubmit(this.state);
                        }
                    }}
                    type="primary"
                    className="register-form-button"
                    style={{
                        display: "block",
                        margin: "0 auto",
                        // width: 200,
                    }}
                >
                    Confirm
                </Button>
            </Form>
        );
    }
}

export default injectStripe(RegisterFormStripe);
