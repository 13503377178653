import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Empty } from "antd";

import AuditorsList from "./AuditorsList";

import { observer } from "mobx-react";

class Auditors extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("listening");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Listening" }]);
    }

    render() {
        return (
            // <Empty
            //     image={Empty.PRESENTED_IMAGE_SIMPLE}
            //     description="No Listening Data"
            // />
            <Switch>
                {/* <Route
                    path="/dashboard/competitors/:id/report"
                    //   render={props => (
                    //       // <CompetitorsReport
                    //       //     store={this.props.store}
                    //       //     {...props}
                    //       // />
                    //   )}
                /> */}
                <Route
                    render={props => (
                        <AuditorsList store={this.props.store} {...props} />
                    )}
                />
            </Switch>
        );
    }
}

export default observer(Auditors);
