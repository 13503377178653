import React, { Fragment } from "react";
import { Tag, Icon, Button } from "antd";
import moment from "moment";
import SocialSeparator from "./../SocialSeparator";
import SocialPreview from "./../SocialPreview";
import SocialTag from "./../SocialTag";
import names from "./../../names";

const style_notHeader = {
    display: "flex",
};
const style_notTitle = {
    fontWeight: "bold",
    fontSize: 16,
    flexGrow: 1,
};
const style_readStyle = {
    fontSize: 12,
    color: "#999",
};

const style_notDescription = {
    fontSize: 14,
    color: "#666",
    marginTop: 10,
};
const style_relatedElementContainer = {
    backgroundColor: "#f5f5f5",
    padding: 20,
};
// const style_buttonsContainer = {
//     width: "100%",
//     display: "flex",
// };

const NotificationInfo = (props) => {
    const { notification } = props;
    const titleStyle = {
        ...style_notTitle,
        ...{
            color: props.tag_color,
        },
    };

    return notification ? (
        <Fragment>
            <div style={style_notHeader}>
                {/* <Tag
                    color={props.tag_color}
                    style={{ margin: 0, marginRight: 10 }}
                >
                    {notification.type}
                </Tag> */}
                <SocialTag
                    key={notification.type}
                    store={props.store}
                    // color={props.tag_color}
                    color={notification.type}
                >
                    {notification.type}
                </SocialTag>

                <div style={titleStyle}>{notification.title}</div>
                {notification.status_changed_on ? (
                    <div style={style_readStyle}>
                        <Icon
                            type="check-circle"
                            style={{
                                marginRight: 5,
                            }}
                        />
                        {moment(notification.status_changed_on)
                            .local()
                            .format("lll")}
                    </div>
                ) : null}
            </div>
            {notification.description ? (
                <div style={style_notDescription}>
                    {notification.description}
                </div>
            ) : null}
            {notification.related_element ? (
                <Fragment>
                    <SocialSeparator
                        style={{
                            marginTop: 30,
                            clear: "both",
                        }}
                        centered
                    >
                        Related to
                    </SocialSeparator>
                    <div style={style_relatedElementContainer}>
                        <SocialPreview
                            body={
                                notification.related_element
                                    ? notification.related_element.post_text
                                    : null
                            }
                            image_url={
                                notification.related_element &&
                                notification.related_element.post_file &&
                                notification.related_element.post_file.url
                            }
                            hideTabs={true}
                            defaultTab={"facebook"}
                        ></SocialPreview>
                        <Button
                            style={{ marginTop: 20 }}
                            onClick={(e) =>
                                props.handleOpenRelated(
                                    notification.related_element.masterpost_key
                                )
                            }
                        >
                            Open {names.get("MASTERPOST")}
                        </Button>
                    </div>
                </Fragment>
            ) : null}
        </Fragment>
    ) : (
        <h3>No data</h3>
    );
};

export default NotificationInfo;
