import React, { Component, Fragment } from "react";
import moment from "moment";
import tooltips from "./../../tooltips";

import MasterpostsListModalNew from "./../Masterposts/MasterpostsListModalNew";
import AccountsListModal from "./../Accounts/AccountsListModal";
import CampaignsListModalNew from "./../Campaigns/CampaignsListModalNew";
import AdsListModal from "./../Ads/AdsListModal";
import SocialTag from "./../SocialTag";

// import GridLayout from "react-grid-layout";
import RGL, { WidthProvider } from "react-grid-layout";

import { Empty, Row, Col, List, Icon, Tooltip, Badge } from "antd";

import {
    social_getFromLocalStorage,
    social_saveToLocalStorage,
} from "./../../functions";
import names from "./../../names";
const ReactGridLayout = WidthProvider(RGL);

const resetLayout = [
    { i: "today", x: 0, y: 0, w: 9, h: 6 },
    { i: "graph", x: 0, y: 6, w: 9, h: 5 },
    {
        i: "actions",
        x: 9,
        y: 0,
        w: 3,
        h: 11,
        // minW: 3,
        // maxW: 3,
        // minH: 14,
        // maxH: 14,
    },
    {
        i: "upcoming_masterposts",
        x: 0,
        y: 12,
        w: 6,
        h: 5,
        // minW: 2,
        // maxW: 4,
    },
    { i: "notifications", x: 6, y: 12, w: 6, h: 5 },
];
const originalLayout = social_getFromLocalStorage("layout") || resetLayout;
console.log("originalLayout", originalLayout);

class EmptyDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_account_data: {
                auth: null, // empty auth data
            },
            modal_ad_data: {},
            layout: originalLayout, // already loaded
        };

        this.accountModal = React.createRef();
        this.campaignsModal = React.createRef();
        this.masterpostsModal = React.createRef();
        this.adsModal = React.createRef();
    }

    componentDidMount() {
        // reset the section
        this.props.store.setCurrentSection("");
        this.props.store.setBreadcrumbs([]); // no extra paths
    }

    getNotifications = () => {
        const notifications = this.props.store.getAllNotifications.filter(
            (n) => {
                //if (n.status == "new") return n;
                return n;
            }
        );

        return notifications;
    };
    getTagColorForNotificationType = (_notification_type) => {
        let answer = "blue";
        switch (_notification_type) {
            case "error":
                answer = this.props.store.getTagColorForType("error");
                break;
            case "message":
                answer = this.props.store.getTagColorForType("default");
                break;
            case "approval":
                answer = this.props.store.getTagColorForType("warning");
                break;
            default:
                answer = this.props.store.getTagColorForType("default");
                break;
        }
        return answer;
    };
    clickOnRightSection = (_type) => {
        console.info("clickOnRightSection _type", _type);
        switch (_type) {
            case "create_account":
                this.accountModal.current.registerModal();
                this.props.store.showModalByKey("accounts_modal");
                break;
            case "create_campaign":
                this.campaignsModal.current.registerModal();
                this.props.store.showModalByKey("campaigns_modal_new");
                break;
            case "create_masterpost":
                this.masterpostsModal.current.registerModal();
                this.props.store.showModalByKey("masterposts_modal_new");
                break;
            case "create_masterad":
                this.adsModal.current.registerModal();
                this.props.store.showModalByKey("ads_modal");
                break;
            case "create_report":
                this.props.history.push(`/dashboard/reports`);
                break;
            default:
                break;
        }
    };

    handleClickOnMasterpost = (_masterpost) => {
        console.info("handleClickOnMasterpost _masterpost", _masterpost);
        this.props.history.push(
            `/dashboard/masterposts/filter/${_masterpost.name}`
        );
    };
    handleClickOnNotifications = (_notification) => {
        console.info("handleClickOnNotifications _notification", _notification);
        this.props.history.push(`/dashboard/notifications`);
    };

    getLatestMasterposts = () => {
        const CRON_INSIGHTS_WEEKS_INTERVAL = 3;
        const now = moment().utc();
        const now_minus_interval = moment(now.valueOf()).subtract(
            CRON_INSIGHTS_WEEKS_INTERVAL, //the interval back
            "weeks"
        );

        let now_format = now.format("x");
        let now_minus_cron_interval_format = now_minus_interval.format("x");

        // console.log(
        //     "latestMasterposts -> " +
        //         now.format("lll") +
        //         " < -- > " +
        //         now_minus_interval.format("lll")
        // );

        const latestMasterposts = this.props.store.getLatestMasterposts.filter(
            (masterpost) => {
                return (
                    masterpost.scheduled_date < now_format &&
                    masterpost.scheduled_date > now_minus_cron_interval_format
                );
            }
        );
        latestMasterposts.map((masterpost) => {
            const AllPosts = this.props.store.getAllPostsForMasterpost({
                key: masterpost.key,
            });
            let total_posts_impressions = 0,
                total_posts_clicks = 0,
                total_posts_likes = 0;
            AllPosts.map((post) => {
                //console.log("latestMasterposts -> post", post);
                post.results &&
                    post.results.map((presult) => {
                        if (presult.insights && presult.insights.data) {
                            // console.log(
                            //     "latestMasterposts -> post.insights.data",
                            //     presult.insights.data
                            // );
                            presult.insights.data.map((data) => {
                                // console.log(
                                //     "latestMasterposts -> data.name = ",
                                //     data.name
                                // );
                                if (data.values && data.values.length === 1) {
                                    // console.log(
                                    //     "latestMasterposts -> post_impressions data",
                                    //     data
                                    // );
                                    if (data.name === "post_impressions") {
                                        total_posts_impressions =
                                            total_posts_impressions +
                                            Number(data.values[0].value);
                                    }

                                    if (data.name === "post_clicks") {
                                        total_posts_clicks =
                                            total_posts_clicks +
                                            Number(data.values[0].value);
                                    }
                                    if (
                                        data.name ===
                                        "post_reactions_like_total"
                                    ) {
                                        total_posts_likes =
                                            total_posts_likes +
                                            Number(data.values[0].value);
                                    }
                                }
                                return false;
                            });
                        }
                        return false;
                    });
                return false;
            });
            masterpost.total_data = {
                posts_impressions: total_posts_impressions,
                posts_clicks: total_posts_clicks,
                posts_likes: total_posts_likes,
            };
            return false;
        });
        //console.info("latestMasterposts", latestMasterposts);
        return latestMasterposts;
    };

    onLayoutChange = (layout) => {
        console.log("Layout change", layout);
        /*eslint no-console: 0*/
        social_saveToLocalStorage("layout", layout);
        this.setState({ layout });
    };

    resetLayout = (e) => {
        const layout = resetLayout;
        social_saveToLocalStorage("layout", layout);
        this.setState({ layout });
    };

    render() {
        const loggedUser = this.props.store.getLoggedUser;
        // const notifications = this.getNotifications()
        //     .reverse()
        //     .slice(0, 5)
        //     .filter(
        //         (n) =>
        //             n.recipients &&
        //             (n.recipients.indexOf("all") !== -1 ||
        //                 n.recipients === loggedUser.uid)
        //     )
        //     .sort((a, b) => (a.datetime < b.datetime ? 1 : -1));
        //console.info("notifications", notifications);

        const allNotifications = this.getNotifications()
            .reverse()
            .filter(
                (n) =>
                    n.recipients &&
                    (n.recipients.indexOf("all") !== -1 ||
                        n.recipients === loggedUser.uid)
            )
            .sort((a, b) => (a.datetime < b.datetime ? 1 : -1));

        const upcomingMasterposts = this.props.store.getAllMasterposts
            .sort((a, b) => (a.scheduled_date < b.scheduled_date ? 1 : -1))
            .filter((mp) =>
                moment(mp.scheduled_date).isBefore(new Date(), "day")
            )
            .slice(0, 5);

        const todaysMasterposts = this.props.store.getAllMasterposts
            .sort((a, b) => (a.scheduled_date < b.scheduled_date ? 1 : -1))
            .filter((mp) => moment(mp.scheduled_date).isSame(new Date(), "day"))
            .slice(0, 5);

        //console.info("upcomingMasterposts", upcomingMasterposts);
        //console.info("todaysMasterposts", todaysMasterposts);

        // Latest masterposts
        const latestMasterposts = this.getLatestMasterposts();
        //console.info("latestMasterposts", latestMasterposts);

        const graphData =
            latestMasterposts.length === 0 ? (
                <Empty className={"social_Empty"} />
            ) : (
                <Tooltip
                    title={tooltips.DASHBOARD.TOP_GRAPHDATA}
                    mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                >
                    <div className={"social_Dashboard_Box"}>
                        <List
                            size="small"
                            bordered
                            dataSource={latestMasterposts}
                            header={
                                <Fragment>
                                    <Row>
                                        <Col>Name</Col>
                                        <Col>Impr.</Col>
                                        <Col>Clicks</Col>
                                        <Col>Likes</Col>
                                    </Row>
                                </Fragment>
                            }
                            renderItem={(item) => (
                                <List.Item
                                // onClick={(e) =>
                                //     this.handleClickOnMasterpost(
                                //         item
                                //     )
                                // }
                                >
                                    <div>{item.name}</div>
                                    <div>
                                        {item.total_data.posts_impressions}
                                    </div>
                                    <div>{item.total_data.posts_clicks}</div>
                                    <div>{item.total_data.posts_likes}</div>
                                </List.Item>
                            )}
                        />
                    </div>
                </Tooltip>
            );

        const todaySection = (
            <Row className={"social_Dashboard_TodaySection"}>
                <Col sm={24} lg={12}>
                    <Badge
                        count={todaysMasterposts.length}
                        style={{
                            backgroundColor:
                                this.props.store.getTagColorForType("campaign"),
                        }}
                    >
                        <h4 style={{ marginRight: 15 }}>
                            Today's Publications
                        </h4>
                    </Badge>
                    <Tooltip
                        title={tooltips.DASHBOARD.TOP_TODAYSMASTERPOSTS}
                        mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                    >
                        <div className={"social_Dashboard_Box"}>
                            <List
                                size="small"
                                bordered
                                dataSource={todaysMasterposts}
                                renderItem={(item) => (
                                    <List.Item
                                        onClick={(e) =>
                                            this.handleClickOnMasterpost(item)
                                        }
                                    >
                                        {item.name}
                                        <div
                                            style={{
                                                float: "right",
                                            }}
                                        >
                                            {item.campaigns &&
                                                item.campaigns.map(
                                                    (
                                                        campaign,
                                                        campaignIndex
                                                    ) => {
                                                        return (
                                                            <SocialTag
                                                                key={
                                                                    campaignIndex
                                                                }
                                                                store={
                                                                    this.props
                                                                        .store
                                                                }
                                                                color="campaign"
                                                            >
                                                                {campaign}
                                                            </SocialTag>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Tooltip>
                </Col>
            </Row>
        );

        const graphSection = (
            <Row className={"social_Dashboard_GraphSection"}>
                <Col sm={24} lg={8}>
                    <p>
                        Epudaessed mod maximaxima nam rentur, coreribus pra
                        nobiti con pa dolut dest, corum hilit qui a alitis asit
                        et ad quiatur- iscit aut volupta quis in nossundit
                        ventus et ipsa cus es.
                    </p>
                </Col>
                <Col sm={24} lg={16}>
                    <h4>Graph Data</h4>
                    {graphData}
                </Col>
            </Row>
        );
        const actionsSection = (
            <div className="social_Dashboard_RightSection">
                <Tooltip
                    placement="left"
                    title={tooltips.DASHBOARD.RIGHT_CREATEACCOUNT}
                    mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                >
                    <div
                        className={"social_Dashboard_Box"}
                        onClick={(e) =>
                            this.clickOnRightSection("create_account")
                        }
                    >
                        Create
                        <br />
                        account
                    </div>
                </Tooltip>
                <Tooltip
                    placement="left"
                    title={tooltips.DASHBOARD.RIGHT_CREATECAMPAIGN}
                    mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                >
                    <div
                        className={"social_Dashboard_Box"}
                        onClick={(e) =>
                            this.clickOnRightSection("create_campaign")
                        }
                    >
                        Create
                        <br />
                        campaign
                    </div>
                </Tooltip>
                <Tooltip
                    placement="left"
                    title={tooltips.DASHBOARD.RIGHT_CREATEMASTERPOST}
                    mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                >
                    <div
                        className={"social_Dashboard_Box"}
                        onClick={(e) =>
                            this.clickOnRightSection("create_masterpost")
                        }
                    >
                        Create
                        <br />
                        {names.get("MASTERPOST")}
                    </div>
                </Tooltip>

                <Tooltip
                    placement="left"
                    title={tooltips.DASHBOARD.RIGHT_CREATEMASTERPOST}
                    mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                >
                    <div
                        className={"social_Dashboard_Box"}
                        onClick={(e) =>
                            this.clickOnRightSection("create_masterad")
                        }
                    >
                        Create
                        <br />
                        master ad
                    </div>
                </Tooltip>
                <Tooltip
                    placement="left"
                    title={tooltips.DASHBOARD.RIGHT_CREATEREPORT}
                    mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                >
                    <div
                        className={"social_Dashboard_Box"}
                        onClick={(e) =>
                            this.clickOnRightSection("create_report")
                        }
                    >
                        Create
                        <br />
                        report
                    </div>
                </Tooltip>
            </div>
        );

        const upcomingMasterpostsSection = (
            <div className="social_Dashboard_UpcomingMasterpostsSection">
                <Badge
                    count={upcomingMasterposts.length}
                    style={{
                        backgroundColor:
                            this.props.store.getTagColorForType("campaign"),
                    }}
                >
                    <h4 style={{ marginRight: 15 }}>
                        Upcoming {names.get("MASTERPOST", true, true, true)}
                    </h4>
                </Badge>
                <Tooltip
                    title={tooltips.DASHBOARD.BOTTOM_UPCOMING}
                    mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                >
                    <div className={"social_Dashboard_Box"}>
                        <List
                            size="small"
                            bordered
                            dataSource={upcomingMasterposts}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={(e) =>
                                        this.handleClickOnMasterpost(item)
                                    }
                                >
                                    <strong>{item.name}</strong>
                                    <br />
                                    <span>
                                        {moment(item.scheduled_date)
                                            .utc()
                                            .format("lll")}
                                    </span>
                                    {item.campaigns &&
                                        item.campaigns.map(
                                            (campaign, campaignIndex) => {
                                                return (
                                                    <SocialTag
                                                        key={campaignIndex}
                                                        store={this.props.store}
                                                        color="campaign"
                                                    >
                                                        {campaign}
                                                    </SocialTag>
                                                );
                                            }
                                        )}
                                </List.Item>
                            )}
                        />
                    </div>
                </Tooltip>
            </div>
        );

        const notificationsSection = (
            <div className="social_Dashboard_NotificationsSection">
                <Badge
                    count={allNotifications.length}
                    style={{
                        backgroundColor:
                            this.props.store.getTagColorForType("campaign"),
                    }}
                >
                    <h4 style={{ marginRight: 20 }}>Latest Notifications</h4>
                </Badge>
                <Tooltip
                    title={tooltips.DASHBOARD.BOTTOM_INBOX}
                    mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                >
                    <div className={"social_Dashboard_Box"}>
                        <List
                            size="small"
                            bordered
                            dataSource={allNotifications}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={(e) =>
                                        this.handleClickOnNotifications(item)
                                    }
                                >
                                    {item.title}

                                    <div
                                        style={{
                                            display: "inline-flex !important",
                                        }}
                                    >
                                        <SocialTag
                                            key={item.type}
                                            store={this.props.store}
                                            color={item.type}
                                        >
                                            {item.type}
                                        </SocialTag>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                </Tooltip>
            </div>
        );

        // const bottomSection = (
        //     <Row gutter={20} className={"social_Dashboard_BottomSection"}>
        //         <Col sm={24} lg={12}>
        //             {upcomingMasterpostsSection}
        //         </Col>
        //         <Col sm={24} lg={12}>
        //             {notificationsSection}
        //         </Col>
        //     </Row>
        // );

        return (
            <div className={"social_Dashboard"}>
                <div className={"social_Dashboard"}>
                    <Tooltip title="Reset Layout">
                        <Icon
                            type="appstore"
                            className="social_Dashboard_resetLayout"
                            onClick={this.resetLayout}
                        />
                    </Tooltip>
                    <ReactGridLayout
                        className="social_Dashboard_Layout layout"
                        layout={this.state.layout}
                        cols={12}
                        rowHeight={50}
                        margin={[10, 0]}
                        containerPadding={[0, 1]}
                        width={1000}
                        onLayoutChange={this.onLayoutChange}
                    >
                        <div key="today">{todaySection}</div>
                        <div key="graph">{graphSection}</div>
                        <div key="upcoming_masterposts">
                            {upcomingMasterpostsSection}
                        </div>
                        <div key="notifications">{notificationsSection}</div>
                        <div key="actions">{actionsSection}</div>
                    </ReactGridLayout>

                    {/* <Row gutter={20} className={"social_Dashboard_MainSection"}>
                        <Col>
                            {todaySection}
                            {graphSection}
                        </Col>
                        <Col className={"social_Dashboard_RightSection"}>
                            <h4>&nbsp;</h4>
                            <Row>
                                <Col>{actionsSection}</Col>
                            </Row>
                        </Col>
                    </Row>

                    {bottomSection} */}

                    <AccountsListModal
                        store={this.props.store}
                        data={this.state.modal_account_data}
                        ref={this.accountModal}
                    />

                    <CampaignsListModalNew
                        store={this.props.store}
                        ref={this.campaignsModal}
                    />

                    <MasterpostsListModalNew
                        store={this.props.store}
                        ref={this.masterpostsModal}
                    />
                    <AdsListModal
                        data={this.state.modal_ad_data}
                        store={this.props.store}
                        ref={this.adsModal}
                    />
                </div>
            </div>
        );
    }
}

export default EmptyDashboard;
