import config from "./config";
import { notification } from "antd";
import MP4Box from "mp4box";
import moment from "moment";

const social_filterList = (_list, _filter, _options) => {
    // console.info(
    //     "social_filterList _list",
    //     _list,
    //     "_filter",
    //     _filter,
    //     "_options",
    //     _options
    // );

    const filtered_results = _list.filter((e) => {
        // Patches
        if (_options.type === "accounts") {
            if (typeof e.tags === "undefined") e.tags = [];
        }

        let flags = [];
        _options.fields.map((f) => {
            let element_data = "";
            if (f.name.indexOf(".") !== -1) {
                if (typeof e[f.name] !== "undefined") {
                    element_data =
                        e[f.name.split(".")[0]][f.name.split(".")[1]];
                }
            } else {
                element_data = e[f.name];
            }

            // console.log(
            //     "social_filterList f.type=" + f.type + ", f.name=" + f.name,
            //     ", e[f.name]=",
            //     element_data
            // );

            if (element_data) {
                switch (f.type) {
                    case "string":
                        if (
                            element_data
                                .toUpperCase()
                                .indexOf(_filter.toUpperCase()) !== -1
                        )
                            flags.push(true);

                        break;

                    case "array":
                        if (
                            element_data.filter((t) => {
                                return (
                                    t
                                        .toUpperCase()
                                        .indexOf(_filter.toUpperCase()) !== -1
                                );
                            }).length > 0
                        )
                            flags.push(true);

                        break;

                    case "arrayOfObjects":
                        //console.info("arrayOfObjects = ", element_data);
                        if (
                            element_data.filter((t) => {
                                return (
                                    t[f.arrayFieldName]
                                        .toUpperCase()
                                        .indexOf(_filter.toUpperCase()) !== -1
                                );
                            }).length > 0
                        )
                            flags.push(true);
                        break;
                    default:
                        break;
                }
            }

            // Special functionality
            if (_options.type === "accounts") {
                if (_filter === "facebook" && e.facebook) flags.push(true);
                if (_filter === "instagram" && e.instagram) flags.push(true);
                // TODO: To put Twitter as a case here
            }

            // Special functionlity
            if (_options.type === "masterposts") {
                if (
                    _filter === "facebook" &&
                    e.targets.filter((t) => t.social_type_facebook).length > 0
                )
                    flags.push(true);
                if (
                    _filter === "instagram" &&
                    e.targets.filter((t) => t.social_type_instagram).length > 0
                )
                    flags.push(true);
                if (
                    _filter === "twitter" &&
                    e.targets.filter((t) => t.social_type_twitter).length > 0
                )
                    flags.push(true);
            }

            return false;
        });
        //console.info("social_filterList flags", flags);
        return flags.indexOf(false) === -1 && flags.indexOf(true) !== -1;
    });

    //console.info("social_filterList filtered_results", filtered_results);
    return filtered_results;
};
const social_batchCreateMasterpostWithTargets = (
    _store,
    _shortid,
    _base,
    _firebase,
    _data
) => {
    return new Promise(async (resolve, reject) => {
        console.info(
            "social_batchCreateMasterpostWithTargets _store",
            _store,
            "_shortid",
            _shortid,
            "_data",
            _data
        );

        let masterpost_data = {
            categories: _data.categories,
            every_dropdown: _data.every_dropdown,
            every_number: _data.every_number,
            name: _data.name,
            note: _data.note,
            campaigns: _data.campaigns,
            publish_local_time: _data.publish_local_time,
            publish_type: _data.publish_type,
            scheduled_date: _data.scheduled_date ? _data.scheduled_date : null,
        };

        let targets_data = _data.targets;

        targets_data = targets_data.map((t) => {
            delete t.temp_media_info;
            return t;
        });

        console.info("masterpost_data", masterpost_data);
        console.info("targets_data", targets_data);

        // Creating the masterpost
        //let that = this;
        _store
            .addMasterpost(masterpost_data)
            .then((newmasterpostObj) => {
                console.info(
                    "addMasterpost.then newmasterpostObj",
                    newmasterpostObj
                );

                _base
                    .fetch(`masterposts/${newmasterpostObj.key}`, {
                        context: this,
                        // asArray: true
                    })
                    .then(async (newmasterpost) => {
                        console.info("newmasterpost", newmasterpost);

                        // Anything to upload
                        newmasterpost.key = newmasterpostObj.key;

                        // adding the targets
                        return await Promise.all(
                            targets_data.map((t) => {
                                //a.tags = a.tags.filter(t => t !== _tag);
                                //this.asyncDeleteTag(a.key, a);
                                console.info("-= target =-", t);

                                // adding the target's shortid
                                t["shortid"] = _shortid.generate();

                                if (
                                    (t.location && t.location.length === 0) ||
                                    !t.location
                                ) {
                                    delete t["location"];
                                }

                                // adding the target after filtering
                                return internal_asyncUpdateTarget(
                                    t,
                                    _store,
                                    _firebase
                                );
                            })
                        )
                            .then(() => {
                                console.log(
                                    "--- All the targets have been prepared ---"
                                );

                                newmasterpost.targets = targets_data;
                                _store.updateMasterpost(
                                    newmasterpost.key,
                                    newmasterpost
                                );

                                //store.updateAllTags();
                                resolve(newmasterpost);
                            })
                            .catch((error) =>
                                this._warn(`Error in promises ${error}`)
                            );
                    })
                    .catch((error) => {
                        reject(error);
                    });

                //resolve(newmasterpostObj);
            })
            .catch((error) => {
                // notification.error({
                //     message: "Publish Progress",
                //     description: error
                // });
                reject(error);
            });
    });
};

const social_batchUpdateMasterpostWithTargets = (
    _store,
    _shortid,
    _base,
    _firebase,
    _data
) => {
    return new Promise(async (resolve, reject) => {
        console.info(
            "social_batchUpdateMasterpostWithTargets _store",
            _store,
            "_shortid",
            _shortid,
            "_data",
            _data
        );

        let masterpost_data = {
            categories: _data.categories,
            every_dropdown: _data.every_dropdown,
            every_number: _data.every_number,
            name: _data.name,
            note: _data.note,
            campaigns: _data.campaigns,
            publish_local_time: _data.publish_local_time,
            publish_type: _data.publish_type,
            scheduled_date: _data.scheduled_date ? _data.scheduled_date : null,
        };

        let targets_data = _data.targets;

        targets_data = targets_data.map((t) => {
            delete t.temp_media_info;
            return t;
        });

        console.info("masterpost_data", masterpost_data);
        const original_masterpost_key = JSON.parse(JSON.stringify(_data.key));
        console.info("original_masterpost_key", masterpost_data);
        // Creating the masterpost
        //let that = this;
        _store
            .updateMasterpost(original_masterpost_key, masterpost_data)
            .then((updatedmasterpostObj) => {
                updatedmasterpostObj.key = original_masterpost_key;
                console.info(
                    "updateMasterpost.then updatedmasterpostObj",
                    updatedmasterpostObj
                );

                _base
                    .fetch(`masterposts/${updatedmasterpostObj.key}`, {
                        context: this,
                        // asArray: true
                    })
                    .then(async (updatedmasterpost) => {
                        // Anything to upload
                        updatedmasterpost.key = updatedmasterpostObj.key;
                        console.info("updatedmasterpost", updatedmasterpost);

                        // adding the targets
                        return await Promise.all(
                            targets_data.map((t) => {
                                //a.tags = a.tags.filter(t => t !== _tag);
                                //this.asyncDeleteTag(a.key, a);
                                console.info("-= target =-", t);

                                // adding the target's shortid
                                //t["shortid"] = _shortid.generate();  !!!! DONT CHANGE THE SHORTID

                                if (
                                    (t.location && t.location.length === 0) ||
                                    !t.location
                                ) {
                                    delete t["location"];
                                }

                                // adding the target after filtering
                                return internal_asyncUpdateTarget(
                                    t,
                                    _store,
                                    _firebase
                                );
                            })
                        )
                            .then(() => {
                                console.log(
                                    "--- All the targets have been prepared ---"
                                );

                                updatedmasterpost.targets = targets_data;
                                _store.updateMasterpost(
                                    updatedmasterpost.key,
                                    updatedmasterpost
                                );

                                //store.updateAllTags();
                                resolve(updatedmasterpost);
                            })
                            .catch((error) =>
                                this._warn(`Error in promises ${error}`)
                            );
                    })
                    .catch((error) => {
                        reject(error);
                    });

                //resolve(newmasterpostObj);
            })
            .catch((error) => {
                // notification.error({
                //     message: "Publish Progress",
                //     description: error
                // });
                reject(error);
            });
    });
};

const internal_asyncUpdateTarget = async (t, _store, _firebase) => {
    return new Promise(async (resolve, reject) => {
        console.info("internal_asyncUpdateTarget t", t);
        //resolve(true);
        if (
            typeof t.template_file != "undefined" &&
            t.changed_template_file &&
            !t.library_file
        ) {
            // Getting the file
            const file = t.template_file.originFileObj
                ? t.template_file.originFileObj
                : t.template_file;
            console.info("FILE", file);

            const storageRef = _firebase.storage().ref();
            console.info("storageRef", storageRef);

            let now = Date.now();
            let fileparts = file.name.split(".");
            let fileext = fileparts.pop();

            const mainImage = storageRef.child(
                fileparts.join(".") + "_" + now.valueOf() + "." + fileext
            );
            console.info("mainImage", mainImage);

            let uploadTask = mainImage.put(file);
            //let other = this;

            await uploadTask.on(
                "state_changed",
                function (snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    // that.setState({
                    //     upload_percentage: Math.round(
                    //         progress
                    //     )
                    // });
                },
                function (error) {
                    // Handle unsuccessful uploads
                    console.error("unsuccessful upload: " + error);
                },
                function () {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref
                        .getDownloadURL()
                        .then(async function (downloadURL) {
                            console.log("File available at", downloadURL);

                            t.template_file = {
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                url: downloadURL,
                                meta: uploadTask.snapshot.metadata,
                            };

                            resolve(true);
                            // newmasterpost.targets = [
                            //     target_data
                            // ];
                            // console.info(
                            //     "that.props",
                            //     that.props
                            // );
                            // that.props.store.updateMasterpost(
                            //     newmasterpost.key,
                            //     newmasterpost
                            // );
                        });
                }
            );
        } else {
            // actual create // and use the library file
            // Overwrite the library file
            if (t.library_file) {
                if (!Array.isArray(t.library_file)) {
                    t.template_file = {
                        name: t.library_file.file.name,
                        size: t.library_file.file.size,
                        type: t.library_file.file.type,
                        url: t.library_file.downloadURL,
                        library_file: true,
                        library_key: t.library_file.key,
                    };

                    // Lock the library file
                    _store.toggleLibraryItemLock(
                        { key: t.library_file.key },
                        true
                    );

                    delete t.library_file;
                } else {
                    // Array of library files
                    t.template_file = [];

                    await Promise.all(
                        t.library_file.map(async (lf, lfIndex) => {
                            t.template_file.push({
                                name: lf.file.name,
                                size: lf.file.size,
                                type: lf.file.type,
                                url: lf.downloadURL,
                                library_file: true,
                                library_key: lf.key,
                            });

                            await _store.toggleLibraryItemLock(
                                { key: lf.key },
                                true
                            );
                        })
                    );
                }
            }

            resolve(true);
        }
    });
};

const social_checkMasterpostValidity = async (_masterpost) => {
    // console.info("social_checkMasterpostValidity _masterpost", _masterpost);
    return new Promise(async (resolve, reject) => {
        let invalidMessages = [];

        // masterpost name
        if (!_masterpost.name || _masterpost.name.length === 0)
            invalidMessages.push({ text: "Materpost should have a name" });

        // targets texts
        await Promise.all(
            _masterpost.targets.map(async (target, targetIndex) => {
                // exisintg text
                if (
                    !target.template_text ||
                    target.template_text.length === 0
                ) {
                    invalidMessages.push({
                        text: "Missing post text for target: " + target.name,
                    });
                }

                // exisintg text
                if (
                    target.template_text &&
                    target.social_type_twitter &&
                    target.social_type_twitter === true &&
                    target.template_text.length > 280
                ) {
                    invalidMessages.push({
                        text: "Twitter does not allow message longer than 280 characters.",
                    });
                }

                // library file image size
                /*
                if (target.library_file) {
                    const imageSize = await social_getRemoteImageSize(
                        target.library_file.downloadURL
                    );
                    const aspectRatio = imageSize.width / imageSize.height;
                    console.info(
                        "imageSize",
                        imageSize,
                        "aspectRatio",
                        aspectRatio
                    );

                    // Instagram
                    if (
                        target.social_type_instagram &&
                        target.social_type_instagram === true
                    ) {
                        if (
                            aspectRatio !== 1 &&
                            aspectRatio !== 1.91 &&
                            aspectRatio !== 4 / 5 &&
                            aspectRatio !== 9 / 16
                        ) {
                            console.log("Wrong aspect ratio");
                            invalidMessages.push({
                                text:
                                    "Image aspect ratio (" +
                                    aspectRatio +
                                    ") is not allowed by Instagram. It should be 1:1, 1.91:1, 4:5 or 9:16.",
                            });
                        }
                    }
                }
                */
                // End of check for library file

                // Check file if any
                if (target.template_file) {
                    const file = target.template_file;
                    //console.info("target.template_file", file);
                    // Video File
                    if (file.type.toString().indexOf("video") !== -1) {
                        //Video file
                        const video_info = await social_checkVideoFile(file);

                        const video_width =
                            video_info.videoTracks[0].video.width;
                        const video_height =
                            video_info.videoTracks[0].video.height;
                        const video_seconds =
                            video_info.videoTracks[0].movie_duration /
                            video_info.videoTracks[0].movie_timescale;
                        const video_filesize = video_info.videoTracks[0].size;
                        const video_codec = video_info.videoTracks[0].codec;
                        const video_aspectRatio = Number(
                            video_width / video_height
                        ).toFixed(2);

                        console.info("video_info", video_info);
                        console.log("video_width = " + video_width);
                        console.log("video_height = " + video_height);
                        console.log("video_seconds = " + video_seconds);
                        console.log("video_filesize = " + video_filesize);
                        console.log("video_codec = " + video_codec);
                        console.log("video_aspectRatio = " + video_aspectRatio);

                        // Saving temp information
                        target.temp_media_info = {
                            type: "video",
                            width: video_width,
                            height: video_height,
                            seconds: video_seconds,
                            filesize: video_filesize,
                            codec: video_codec,
                            aspectRatio: video_aspectRatio,
                        };

                        // check for Facebook -------------------
                        if (
                            target.social_type_facebook &&
                            target.social_type_facebook === true
                        ) {
                            //
                            // Recommended video dimensions 1280 x 720 for Landscape and Portrait.
                            // Minimum width is 1200 pixels (length depends on aspect ratio) for Landscape and Portrait.
                            // Landscape aspect ratio is 16:9.
                            // Portrait aspect ratio is 9:16 (if video includes link, aspect ratio is 16:9).
                            // Mobile renders both video types to aspect ratio 2:3.
                            // Max file size is 4GB (1.75 GB maximum in Sprout).
                            // Recommended video formats are .MP4 and .MOV.
                            // Video length max is 240 minutes (45 minutes if uploading in Sprout).
                            // Video max frames 30fps.
                            //

                            if (video_width < 720 || video_height > 1280) {
                                invalidMessages.push({
                                    text: "Facebook: Width of the video should be smaller than 720px and height should be larger than 1280 px",
                                });
                            }
                            // 2:3
                            if (video_aspectRatio !== 0.66) {
                                invalidMessages.push({
                                    text: "Facebook: Video aspect ratio should be 2:3",
                                });
                            }
                            // Video length < 45 minutes
                            if (video_seconds > 2700) {
                                invalidMessages.push({
                                    text: "Facebook: Video should be shorter than 45 minutes",
                                });
                            }
                        }
                        // ------------ End of Facebook -----------

                        // check for Instagram -------------------
                        if (
                            target.social_type_instagram &&
                            target.social_type_instagram === true
                        ) {
                            //
                            // Minimum resolution for all formats is 1080×1080
                            // Multiple aspect ratios are supported: Landscape aspect ratio is 16:9, square aspect ratio is 1:1, vertical aspect ratio is 4:5.
                            // Max file size for all formats is 4GB (*100MB maximum for Sprout Direct Publishing and 512MB maximum for Sprout Mobile App Flow Publishing).
                            // Recommended video formats are .MP4 and .MOV.
                            // Video length max is 2 minutes.
                            // Recommended bitrate for posting through Sprout: 5 Mbps
                            //

                            if (video_width < 1080 || video_height < 1080) {
                                invalidMessages.push({
                                    text: "Instagram: Video should be larger than 1080 x 1080",
                                });
                            }
                            // 1:1
                            if (video_aspectRatio !== 1) {
                                invalidMessages.push({
                                    text: "Instagram: Video aspect ratio should be 1:1",
                                });
                            }
                            // Video length < 2 minutes
                            if (video_seconds > 120) {
                                invalidMessages.push({
                                    text: "Instagram: Video should be shorter than 2 minutes",
                                });
                            }
                        }
                        // ------------ End of Instagram -----------

                        // check for Twitter -------------------
                        if (
                            target.social_type_twitter &&
                            target.social_type_twitter === true
                        ) {
                            //
                            // Recommended resolutions are 1280×720 (landscape), 720×1280 (portrait), 720×720 (square).
                            // Aspect ratios recommended at 16:9 (landscape or portrait), 1:1 (square). 1:1 is recommended as the best route for rendering across devices with the best output.
                            // Max file size is 512MB.
                            // Recommended video formats are .MP4 for web and .MOV for mobile.
                            // Video length max is 140 seconds.
                            // Recommended frame rates are 30 or 60 fps.
                            //

                            if (video_width < 720 || video_height < 720) {
                                invalidMessages.push({
                                    text: "Twitter: Video should be larger than 720 x 720",
                                });
                            }
                            // 1:1
                            // if (video_aspectRatio !== 1) {
                            //     invalidMessages.push({
                            //         text: "Twitter: Video aspect ratio should be 1:1",
                            //     });
                            // }
                            // Video length < 2 minutes
                            if (video_seconds > 140) {
                                invalidMessages.push({
                                    text: "Twitter: Video should be shorter than 2 minutes",
                                });
                            }
                        }
                        // ------------ End of Twitter -----------

                        // check for Youtube ---------------------
                        /*
                        Videos can be no longer than 10 minutes.
                        Videos can be no larger than 100 megabytes.
                        One account can have no more than 1000 videos.
                        */
                        if (
                            target.social_type_youtube &&
                            target.social_type_youtube === true
                        ) {
                            // Video length < 10 minutes
                            if (video_seconds > 60 * 10) {
                                invalidMessages.push({
                                    text: "YouTube: Video should be shorter than 10 minutes",
                                });
                            }
                            // Filesize < 100MB
                            if (video_filesize > 100 * 1024 * 1024) {
                                invalidMessages.push({
                                    text: "YouTube: Video file size should be less than 100 MB",
                                });
                            }
                        }
                        // ------------
                    }

                    // Image File
                    if (file.type.toString().indexOf("image") !== -1) {
                        console.info("Image file");
                        const image_info = await social_checkImageFile(file);
                        console.info("image_info", image_info);
                        const image_width = image_info.width;
                        const image_height = image_info.height;
                        const image_filesize = file.size;
                        const image_aspectRatio = Number(
                            image_width / image_height
                        ).toFixed(2);

                        console.log("image_width = " + image_width);
                        console.log("image_height = " + image_height);
                        console.log("image_filesize = " + image_filesize);
                        console.log("image_aspectRatio = " + image_aspectRatio);

                        // Saving temp information
                        target.temp_media_info = {
                            type: "image",
                            width: image_width,
                            height: image_height,
                            filesize: image_filesize,
                            aspectRatio: image_aspectRatio,
                        };

                        // check for Instagram -------------------
                        if (
                            target.social_type_instagram &&
                            target.social_type_instagram === true
                        ) {
                            // Supported aspect ratios: Anywhere between 1.91:1 and 4:5
                            if (
                                image_aspectRatio < 0.8 ||
                                image_aspectRatio > 1.91
                            ) {
                                invalidMessages.push({
                                    text: "Instagram: Aspect Ratio should be between 1.91:1 and 4:5",
                                });
                            }
                        }
                        // ------------ End of Instagram -----------

                        // check for Twitter -------------------
                        if (
                            target.social_type_twitter &&
                            target.social_type_twitter === true
                        ) {
                            // Minimum size: 600 by 335 pixels
                            if (image_width < 600 || image_height < 335) {
                                invalidMessages.push({
                                    text: "Twitter: Minimum image size is 600 by 335 pixels",
                                });
                            }
                            if (image_filesize > 15000000) {
                                invalidMessages.push({
                                    text: "Twitter: Maximum filesize is 15MB",
                                });
                            }

                            // Recommended aspect ratio: any aspect between 2:1 and 1:1 on desktop; 2:1, 3:4 and 16:9 on mobile
                            if (
                                image_aspectRatio < 0.5 ||
                                image_aspectRatio > 1
                            ) {
                                invalidMessages.push({
                                    text: "Twitter: Aspect Ratio should be between 2:1 and 1:1",
                                });
                            }
                        }
                        // ------------ End of Twitter -----------

                        // check for Facebook -------------------
                        if (
                            target.social_type_facebook &&
                            target.social_type_facebook === true
                        ) {
                            // Minimum size: 600 by 335 pixels
                            if (image_width < 600 || image_height < 315) {
                                invalidMessages.push({
                                    text: "Facebook: Minimum image size is 600 by 315 pixels",
                                });
                            }
                            if (image_filesize > 30000000) {
                                invalidMessages.push({
                                    text: "Facebook: Maximum filesize is 30MB",
                                });
                            }

                            // Ratio 1.91:1 to 1:1.
                            if (
                                image_aspectRatio < 1.91 ||
                                image_aspectRatio >= 1
                            ) {
                                invalidMessages.push({
                                    text: "Facebook: Aspect Ratio should be between 1.91:1 and 1:1",
                                });
                            }
                        }
                        // ------------ End of Facebook -----------

                        // check for Linkedin -------------------
                        if (
                            target.social_type_linkedin &&
                            target.social_type_linkedin === true
                        ) {
                            // Minimum size: 200px wide
                            if (image_width < 200) {
                                invalidMessages.push({
                                    text: "Linkedin: Minimum image width is 200 pixels",
                                });
                            }
                            // Ratio 1.91:1
                            if (image_aspectRatio === 1.91) {
                                invalidMessages.push({
                                    text: "Linkedin: Aspect Ratio should be 1.91:1",
                                });
                            }
                        }
                        // ------------ End of Linkedin -----------
                    }
                }

                if (target.library_file && target.library_file.length > 1) {
                    if (
                        (target.social_type_instagram &&
                            target.social_type_instagram === true) ||
                        (target.social_type_twitter &&
                            target.social_type_twitter === true)
                    ) {
                        invalidMessages.push({
                            text: "Multiple Images: Cannot use multiple images on Instagram and Twitter",
                        });
                    }
                }
                // ----------------- End of Multiple Images for IG and TW ---------------
            })
        );

        resolve(invalidMessages);
    });
};

const social_getMediaInfo = async (_file) => {
    return new Promise(async (resolve, reject) => {
        resolve(true);
    });
};
const social_checkVideoFile = async (_file) => {
    return new Promise(async (resolve, reject) => {
        var reader = new FileReader();
        var mp4boxfile = MP4Box.createFile();
        mp4boxfile.onError = function (e) {
            console.error("mp4boxfile.onError e", e);
            reject(e);
        };
        mp4boxfile.onReady = function (info) {
            console.info("mp4boxfile.onReady info", info);
            resolve(info);
        };
        mp4boxfile.onMoovStart = function (info) {
            console.log(info);
            reader.abort();
        };

        var filepos = 0;
        var append_data_to_mp4box = function (event) {
            //console.log(event);
            var arraybuffer = event.target.result;
            if (arraybuffer) {
                arraybuffer.fileStart = filepos; // adding fileStart property
                var ret = mp4boxfile.appendBuffer(arraybuffer);
                console.log(ret);
                filepos = event.loaded;
            }
        };

        reader.onloadstart = append_data_to_mp4box;
        reader.onprogress = append_data_to_mp4box;
        reader.onloadend = append_data_to_mp4box;
        if (_file) {
            reader.readAsArrayBuffer(_file);
        }
    });
};

const social_checkImageFile = async (_file) => {
    return new Promise(async (resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function (theFile) {
            var image = new Image();
            image.src = theFile.target.result;

            image.onload = function () {
                // access image size here
                console.log(this.width);
                resolve({ width: this.width, height: this.height });
            };
            image.onerror = function (e) {
                reject(e);
            };
        };
        reader.readAsDataURL(_file);
    });
};

// temp
const add = (a, b) => a + b;
const total = (shipping, subTotal) => {
    return "$" + add(shipping, subTotal);
};

const social_reportsMergeValues = (arrayOfValues) => {
    let sum = [];
    arrayOfValues.map((v) => {
        let objProps = Object.keys(v);
        objProps.map((key) => {
            let found = sum.filter((s) => s.key === key);
            if (found.length === 0) {
                // new entry
                sum.push({ key: key, value: v[key] });
            } else {
                found[0].value = found[0].value + v[key];
            }
            return false;
        });
        return false;
    });

    // Sorting
    sum.sort((a, b) => b.value - a.value);

    return sum;
};

const social_LANGUAGE_BY_LOCALE = {
    af_NA: "Afrikaans (Namibia)",
    af_ZA: "Afrikaans (South Africa)",
    af: "Afrikaans",
    ak_GH: "Akan (Ghana)",
    ak: "Akan",
    sq_AL: "Albanian (Albania)",
    sq: "Albanian",
    am_ET: "Amharic (Ethiopia)",
    am: "Amharic",
    ar_DZ: "Arabic (Algeria)",
    ar_BH: "Arabic (Bahrain)",
    ar_EG: "Arabic (Egypt)",
    ar_IQ: "Arabic (Iraq)",
    ar_JO: "Arabic (Jordan)",
    ar_KW: "Arabic (Kuwait)",
    ar_LB: "Arabic (Lebanon)",
    ar_LY: "Arabic (Libya)",
    ar_MA: "Arabic (Morocco)",
    ar_OM: "Arabic (Oman)",
    ar_QA: "Arabic (Qatar)",
    ar_SA: "Arabic (Saudi Arabia)",
    ar_SD: "Arabic (Sudan)",
    ar_SY: "Arabic (Syria)",
    ar_TN: "Arabic (Tunisia)",
    ar_AE: "Arabic (United Arab Emirates)",
    ar_YE: "Arabic (Yemen)",
    ar_AR: "Arabic",
    ar: "Arabic",
    hy_AM: "Armenian (Armenia)",
    hy: "Armenian",
    as_IN: "Assamese (India)",
    as: "Assamese",
    asa_TZ: "Asu (Tanzania)",
    asa: "Asu",
    az_Cyrl: "Azerbaijani (Cyrillic)",
    az_Cyrl_AZ: "Azerbaijani (Cyrillic, Azerbaijan)",
    az_Latn: "Azerbaijani (Latin)",
    az_Latn_AZ: "Azerbaijani (Latin, Azerbaijan)",
    az: "Azerbaijani",
    bm_ML: "Bambara (Mali)",
    bm: "Bambara",
    eu_ES: "Basque (Spain)",
    eu: "Basque",
    be_BY: "Belarusian (Belarus)",
    be: "Belarusian",
    bem_ZM: "Bemba (Zambia)",
    bem: "Bemba",
    bez_TZ: "Bena (Tanzania)",
    bez: "Bena",
    bn_BD: "Bengali (Bangladesh)",
    bn_IN: "Bengali (India)",
    bn: "Bengali",
    bs_BA: "Bosnian (Bosnia and Herzegovina)",
    bs: "Bosnian",
    bg_BG: "Bulgarian (Bulgaria)",
    bg: "Bulgarian",
    my_MM: "Burmese (Myanmar [Burma])",
    my: "Burmese",
    yue_Hant_HK: "Cantonese (Traditional, Hong Kong SAR China)",
    ca_ES: "Catalan (Spain)",
    ca: "Catalan",
    tzm_Latn: "Central Morocco Tamazight (Latin)",
    tzm_Latn_MA: "Central Morocco Tamazight (Latin, Morocco)",
    tzm: "Central Morocco Tamazight",
    chr_US: "Cherokee (United States)",
    chr: "Cherokee",
    cgg_UG: "Chiga (Uganda)",
    cgg: "Chiga",
    zh_Hans: "Chinese (Simplified Han)",
    zh_Hans_CN: "Chinese (Simplified Han, China)",
    zh_Hans_HK: "Chinese (Simplified Han, Hong Kong SAR China)",
    zh_Hans_MO: "Chinese (Simplified Han, Macau SAR China)",
    zh_Hans_SG: "Chinese (Simplified Han, Singapore)",
    zh_Hant: "Chinese (Traditional Han)",
    zh_Hant_HK: "Chinese (Traditional Han, Hong Kong SAR China)",
    zh_Hant_MO: "Chinese (Traditional Han, Macau SAR China)",
    zh_Hant_TW: "Chinese (Traditional Han, Taiwan)",
    zh: "Chinese",
    kw_GB: "Cornish (United Kingdom)",
    kw: "Cornish",
    hr_HR: "Croatian (Croatia)",
    hr: "Croatian",
    cs_CZ: "Czech (Czech Republic)",
    cs: "Czech",
    da_DK: "Danish (Denmark)",
    da: "Danish",
    nl_BE: "Dutch (Belgium)",
    nl_NL: "Dutch (Netherlands)",
    nl: "Dutch",
    ebu_KE: "Embu (Kenya)",
    ebu: "Embu",
    en_AS: "English (American Samoa)",
    en_AU: "English (Australia)",
    en_BE: "English (Belgium)",
    en_BZ: "English (Belize)",
    en_BW: "English (Botswana)",
    en_CA: "English (Canada)",
    en_GU: "English (Guam)",
    en_HK: "English (Hong Kong SAR China)",
    en_IN: "English (India)",
    en_IE: "English (Ireland)",
    en_IL: "English (Israel)",
    en_JM: "English (Jamaica)",
    en_MT: "English (Malta)",
    en_MH: "English (Marshall Islands)",
    en_MU: "English (Mauritius)",
    en_NA: "English (Namibia)",
    en_NZ: "English (New Zealand)",
    en_MP: "English (Northern Mariana Islands)",
    en_PK: "English (Pakistan)",
    en_PH: "English (Philippines)",
    en_SG: "English (Singapore)",
    en_ZA: "English (South Africa)",
    en_TT: "English (Trinidad and Tobago)",
    en_UM: "English (U.S. Minor Outlying Islands)",
    en_VI: "English (U.S. Virgin Islands)",
    en_GB: "English (United Kingdom)",
    en_US: "English (United States)",
    en_ZW: "English (Zimbabwe)",
    en: "English",
    eo: "Esperanto",
    et_EE: "Estonian (Estonia)",
    et: "Estonian",
    ee_GH: "Ewe (Ghana)",
    ee_TG: "Ewe (Togo)",
    ee: "Ewe",
    fo_FO: "Faroese (Faroe Islands)",
    fo: "Faroese",
    fil_PH: "Filipino (Philippines)",
    fil: "Filipino",
    fi_FI: "Finnish (Finland)",
    fi: "Finnish",
    fr_BE: "French (Belgium)",
    fr_BJ: "French (Benin)",
    fr_BF: "French (Burkina Faso)",
    fr_BI: "French (Burundi)",
    fr_CM: "French (Cameroon)",
    fr_CA: "French (Canada)",
    fr_CF: "French (Central African Republic)",
    fr_TD: "French (Chad)",
    fr_KM: "French (Comoros)",
    fr_CG: "French (Congo - Brazzaville)",
    fr_CD: "French (Congo - Kinshasa)",
    fr_CI: "French (Côte d’Ivoire)",
    fr_DJ: "French (Djibouti)",
    fr_GQ: "French (Equatorial Guinea)",
    fr_FR: "French (France)",
    fr_GA: "French (Gabon)",
    fr_GP: "French (Guadeloupe)",
    fr_GN: "French (Guinea)",
    fr_LU: "French (Luxembourg)",
    fr_MG: "French (Madagascar)",
    fr_ML: "French (Mali)",
    fr_MQ: "French (Martinique)",
    fr_MC: "French (Monaco)",
    fr_NE: "French (Niger)",
    fr_RW: "French (Rwanda)",
    fr_RE: "French (Réunion)",
    fr_BL: "French (Saint Barthélemy)",
    fr_MF: "French (Saint Martin)",
    fr_SN: "French (Senegal)",
    fr_CH: "French (Switzerland)",
    fr_TG: "French (Togo)",
    fr: "French",
    ff_SN: "Fulah (Senegal)",
    ff: "Fulah",
    gl_ES: "Galician (Spain)",
    gl: "Galician",
    lg_UG: "Ganda (Uganda)",
    lg: "Ganda",
    ka_GE: "Georgian (Georgia)",
    ka: "Georgian",
    de_AT: "German (Austria)",
    de_BE: "German (Belgium)",
    de_DE: "German (Germany)",
    de_LI: "German (Liechtenstein)",
    de_LU: "German (Luxembourg)",
    de_CH: "German (Switzerland)",
    de: "German",
    el_CY: "Greek (Cyprus)",
    el_GR: "Greek (Greece)",
    el: "Greek",
    gu_IN: "Gujarati (India)",
    gu: "Gujarati",
    guz_KE: "Gusii (Kenya)",
    guz: "Gusii",
    ha_Latn: "Hausa (Latin)",
    ha_Latn_GH: "Hausa (Latin, Ghana)",
    ha_Latn_NE: "Hausa (Latin, Niger)",
    ha_Latn_NG: "Hausa (Latin, Nigeria)",
    ha: "Hausa",
    haw_US: "Hawaiian (United States)",
    haw: "Hawaiian",
    he_IL: "Hebrew (Israel)",
    he: "Hebrew",
    hi_IN: "Hindi (India)",
    hi: "Hindi",
    hu_HU: "Hungarian (Hungary)",
    hu: "Hungarian",
    is_IS: "Icelandic (Iceland)",
    is: "Icelandic",
    ig_NG: "Igbo (Nigeria)",
    ig: "Igbo",
    id_ID: "Indonesian (Indonesia)",
    id: "Indonesian",
    ga_IE: "Irish (Ireland)",
    ga: "Irish",
    it_IT: "Italian (Italy)",
    it_CH: "Italian (Switzerland)",
    it: "Italian",
    ja_JP: "Japanese (Japan)",
    ja: "Japanese",
    kea_CV: "Kabuverdianu (Cape Verde)",
    kea: "Kabuverdianu",
    kab_DZ: "Kabyle (Algeria)",
    kab: "Kabyle",
    kl_GL: "Kalaallisut (Greenland)",
    kl: "Kalaallisut",
    kln_KE: "Kalenjin (Kenya)",
    kln: "Kalenjin",
    kam_KE: "Kamba (Kenya)",
    kam: "Kamba",
    kn_IN: "Kannada (India)",
    kn: "Kannada",
    kk_Cyrl: "Kazakh (Cyrillic)",
    kk_Cyrl_KZ: "Kazakh (Cyrillic, Kazakhstan)",
    kk: "Kazakh",
    km_KH: "Khmer (Cambodia)",
    km: "Khmer",
    ki_KE: "Kikuyu (Kenya)",
    ki: "Kikuyu",
    rw_RW: "Kinyarwanda (Rwanda)",
    rw: "Kinyarwanda",
    kok_IN: "Konkani (India)",
    kok: "Konkani",
    ko_KR: "Korean (South Korea)",
    ko: "Korean",
    khq_ML: "Koyra Chiini (Mali)",
    khq: "Koyra Chiini",
    ses_ML: "Koyraboro Senni (Mali)",
    ses: "Koyraboro Senni",
    lag_TZ: "Langi (Tanzania)",
    lag: "Langi",
    lv_LV: "Latvian (Latvia)",
    lv: "Latvian",
    lt_LT: "Lithuanian (Lithuania)",
    lt: "Lithuanian",
    luo_KE: "Luo (Kenya)",
    luo: "Luo",
    luy_KE: "Luyia (Kenya)",
    luy: "Luyia",
    mk_MK: "Macedonian (Macedonia)",
    mk: "Macedonian",
    jmc_TZ: "Machame (Tanzania)",
    jmc: "Machame",
    kde_TZ: "Makonde (Tanzania)",
    kde: "Makonde",
    mg_MG: "Malagasy (Madagascar)",
    mg: "Malagasy",
    ms_BN: "Malay (Brunei)",
    ms_MY: "Malay (Malaysia)",
    ms: "Malay",
    ml_IN: "Malayalam (India)",
    ml: "Malayalam",
    mt_MT: "Maltese (Malta)",
    mt: "Maltese",
    gv_GB: "Manx (United Kingdom)",
    gv: "Manx",
    mr_IN: "Marathi (India)",
    mr: "Marathi",
    mas_KE: "Masai (Kenya)",
    mas_TZ: "Masai (Tanzania)",
    mas: "Masai",
    mer_KE: "Meru (Kenya)",
    mer: "Meru",
    mfe_MU: "Morisyen (Mauritius)",
    mfe: "Morisyen",
    naq_NA: "Nama (Namibia)",
    naq: "Nama",
    ne_IN: "Nepali (India)",
    ne_NP: "Nepali (Nepal)",
    ne: "Nepali",
    nd_ZW: "North Ndebele (Zimbabwe)",
    nd: "North Ndebele",
    nb_NO: "Norwegian Bokmål (Norway)",
    nb: "Norwegian Bokmål",
    nn_NO: "Norwegian Nynorsk (Norway)",
    nn: "Norwegian Nynorsk",
    nyn_UG: "Nyankole (Uganda)",
    nyn: "Nyankole",
    or_IN: "Oriya (India)",
    or: "Oriya",
    om_ET: "Oromo (Ethiopia)",
    om_KE: "Oromo (Kenya)",
    om: "Oromo",
    ps_AF: "Pashto (Afghanistan)",
    ps: "Pashto",
    fa_AF: "Persian (Afghanistan)",
    fa_IR: "Persian (Iran)",
    fa: "Persian",
    pl_PL: "Polish (Poland)",
    pl: "Polish",
    pt_BR: "Portuguese (Brazil)",
    pt_GW: "Portuguese (Guinea-Bissau)",
    pt_MZ: "Portuguese (Mozambique)",
    pt_PT: "Portuguese (Portugal)",
    pt: "Portuguese",
    pa_Arab: "Punjabi (Arabic)",
    pa_Arab_PK: "Punjabi (Arabic, Pakistan)",
    pa_Guru: "Punjabi (Gurmukhi)",
    pa_Guru_IN: "Punjabi (Gurmukhi, India)",
    pa: "Punjabi",
    ro_MD: "Romanian (Moldova)",
    ro_RO: "Romanian (Romania)",
    ro: "Romanian",
    rm_CH: "Romansh (Switzerland)",
    rm: "Romansh",
    rof_TZ: "Rombo (Tanzania)",
    rof: "Rombo",
    ru_MD: "Russian (Moldova)",
    ru_RU: "Russian (Russia)",
    ru_UA: "Russian (Ukraine)",
    ru: "Russian",
    rwk_TZ: "Rwa (Tanzania)",
    rwk: "Rwa",
    saq_KE: "Samburu (Kenya)",
    saq: "Samburu",
    sg_CF: "Sango (Central African Republic)",
    sg: "Sango",
    seh_MZ: "Sena (Mozambique)",
    seh: "Sena",
    sr_Cyrl: "Serbian (Cyrillic)",
    sr_Cyrl_BA: "Serbian (Cyrillic, Bosnia and Herzegovina)",
    sr_Cyrl_ME: "Serbian (Cyrillic, Montenegro)",
    sr_Cyrl_RS: "Serbian (Cyrillic, Serbia)",
    sr_Latn: "Serbian (Latin)",
    sr_Latn_BA: "Serbian (Latin, Bosnia and Herzegovina)",
    sr_Latn_ME: "Serbian (Latin, Montenegro)",
    sr_Latn_RS: "Serbian (Latin, Serbia)",
    sr: "Serbian",
    sn_ZW: "Shona (Zimbabwe)",
    sn: "Shona",
    ii_CN: "Sichuan Yi (China)",
    ii: "Sichuan Yi",
    si_LK: "Sinhala (Sri Lanka)",
    si: "Sinhala",
    sk_SK: "Slovak (Slovakia)",
    sk: "Slovak",
    sl_SI: "Slovenian (Slovenia)",
    sl: "Slovenian",
    xog_UG: "Soga (Uganda)",
    xog: "Soga",
    so_DJ: "Somali (Djibouti)",
    so_ET: "Somali (Ethiopia)",
    so_KE: "Somali (Kenya)",
    so_SO: "Somali (Somalia)",
    so: "Somali",
    es_AR: "Spanish (Argentina)",
    es_BO: "Spanish (Bolivia)",
    es_CL: "Spanish (Chile)",
    es_CO: "Spanish (Colombia)",
    es_CR: "Spanish (Costa Rica)",
    es_DO: "Spanish (Dominican Republic)",
    es_EC: "Spanish (Ecuador)",
    es_SV: "Spanish (El Salvador)",
    es_GQ: "Spanish (Equatorial Guinea)",
    es_GT: "Spanish (Guatemala)",
    es_HN: "Spanish (Honduras)",
    es_419: "Spanish (Latin America)",
    es_MX: "Spanish (Mexico)",
    es_NI: "Spanish (Nicaragua)",
    es_PA: "Spanish (Panama)",
    es_PY: "Spanish (Paraguay)",
    es_PE: "Spanish (Peru)",
    es_PR: "Spanish (Puerto Rico)",
    es_ES: "Spanish (Spain)",
    es_US: "Spanish (United States)",
    es_UY: "Spanish (Uruguay)",
    es_VE: "Spanish (Venezuela)",
    es_LA: "Spanish (Latin America)",
    es: "Spanish",
    sw_KE: "Swahili (Kenya)",
    sw_TZ: "Swahili (Tanzania)",
    sw: "Swahili",
    sv_FI: "Swedish (Finland)",
    sv_SE: "Swedish (Sweden)",
    sv: "Swedish",
    gsw_CH: "Swiss German (Switzerland)",
    gsw: "Swiss German",
    shi_Latn: "Tachelhit (Latin)",
    shi_Latn_MA: "Tachelhit (Latin, Morocco)",
    shi_Tfng: "Tachelhit (Tifinagh)",
    shi_Tfng_MA: "Tachelhit (Tifinagh, Morocco)",
    shi: "Tachelhit",
    dav_KE: "Taita (Kenya)",
    dav: "Taita",
    ta_IN: "Tamil (India)",
    ta_LK: "Tamil (Sri Lanka)",
    ta: "Tamil",
    te_IN: "Telugu (India)",
    te: "Telugu",
    teo_KE: "Teso (Kenya)",
    teo_UG: "Teso (Uganda)",
    teo: "Teso",
    th_TH: "Thai (Thailand)",
    th: "Thai",
    bo_CN: "Tibetan (China)",
    bo_IN: "Tibetan (India)",
    bo: "Tibetan",
    ti_ER: "Tigrinya (Eritrea)",
    ti_ET: "Tigrinya (Ethiopia)",
    ti: "Tigrinya",
    to_TO: "Tonga (Tonga)",
    to: "Tonga",
    tr_TR: "Turkish (Turkey)",
    tr: "Turkish",
    uk_UA: "Ukrainian (Ukraine)",
    uk: "Ukrainian",
    ur_IN: "Urdu (India)",
    ur_PK: "Urdu (Pakistan)",
    ur: "Urdu",
    uz_Arab: "Uzbek (Arabic)",
    uz_Arab_AF: "Uzbek (Arabic, Afghanistan)",
    uz_Cyrl: "Uzbek (Cyrillic)",
    uz_Cyrl_UZ: "Uzbek (Cyrillic, Uzbekistan)",
    uz_Latn: "Uzbek (Latin)",
    uz_Latn_UZ: "Uzbek (Latin, Uzbekistan)",
    uz: "Uzbek",
    vi_VN: "Vietnamese (Vietnam)",
    vi: "Vietnamese",
    vun_TZ: "Vunjo (Tanzania)",
    vun: "Vunjo",
    cy_GB: "Welsh (United Kingdom)",
    cy: "Welsh",
    yo_NG: "Yoruba (Nigeria)",
    yo: "Yoruba",
    zu_ZA: "Zulu (South Africa)",
    zu: "Zulu",
};

// ---- browser Detection sections
// Opera 8.0+
const social_isOpera =
    !!window.opr || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

// Firefox 1.0+
const social_isFirefox = typeof InstallTrigger !== "undefined";

// Safari 3.0+ "[object HTMLElementConstructor]"
const social_isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"] || typeof safari !== "undefined");

// Internet Explorer 6-11
const social_isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
const social_isEdge = !social_isIE && !!window.StyleMedia;

// Chrome 1 - 71
const social_isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
const social_isBlink = (social_isChrome || social_isOpera) && !!window.CSS;

function social_notification(_name, _type, _overwrite) {
    console.info("social_notification arguments", arguments);
    const notification_events = config.notifications.events;
    //console.info("notification_events", notification_events);
    const found_event = notification_events.filter(
        (ne) => ne.name === _name && ne.type === _type
    )[0];
    let type,
        title,
        description,
        key,
        duration = null;

    console.info("found_event", found_event);
    if (found_event) {
        type = found_event.type;
        title = found_event.title;
        description = found_event.description;

        if (typeof _overwrite !== "undefined") {
            if (typeof _overwrite.type !== "undefined") type = _overwrite.type;
            if (typeof _overwrite.title !== "undefined")
                title = _overwrite.title;
            if (typeof _overwrite.description !== "undefined")
                description = _overwrite.description;
            if (typeof _overwrite.key !== "undefined") key = _overwrite.key;
            if (typeof _overwrite.duration !== "undefined")
                duration = _overwrite.duration;
        }
    } else {
        // check for overwrite
        if (arguments.length === 1 && Object.keys(arguments[0]).length > 0) {
            console.log("we have single overwrite object");

            if (typeof arguments[0] !== "undefined") {
                if (typeof arguments[0].type !== "undefined")
                    type = arguments[0].type;
                if (typeof arguments[0].title !== "undefined")
                    title = arguments[0].title;
                if (typeof arguments[0].description !== "undefined")
                    description = arguments[0].description;
                if (typeof arguments[0].key !== "undefined")
                    key = arguments[0].key;
                if (typeof arguments[0].duration !== "undefined")
                    duration = arguments[0].duration;
            }
        }
    }

    let notObj = {
        message: title,
        description: description,
        placement: "bottomRight",
        style: { whiteSpace: "pre-line" },
    };
    if (key !== null) notObj.key = key;
    if (duration !== null) notObj.duration = duration;

    console.info("notObj", notObj);
    notification[type](notObj);
}

function social_setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function social_getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }
    return null;
}
function social_eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
}

function social_getFromLocalStorage(key) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("social")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function social_saveToLocalStorage(key, value) {
    if (global.localStorage) {
        global.localStorage.setItem(
            "social",
            JSON.stringify({
                [key]: value,
            })
        );
    }
}

function social_sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function social_reset_css() {
    // remove old
    let stylesToRemove = document.getElementById("social_theme");
    if (stylesToRemove) {
        stylesToRemove.disabled = true;
        stylesToRemove.parentNode.removeChild(stylesToRemove);
    }
}
function social_load_css(_url) {
    // remove theme css
    social_reset_css();

    var sheet = document.createElement("link");
    sheet.rel = "stylesheet";
    sheet.href = _url;
    sheet.type = "text/css";
    sheet.id = "social_theme";
    document.head.appendChild(sheet);
    var _timer;

    // TODO: handle failure
    return new Promise(function (resolve) {
        sheet.onload = resolve;
        sheet.addEventListener("load", resolve);
        sheet.onreadystatechange = function () {
            if (
                sheet.readyState === "loaded" ||
                sheet.readyState === "complete"
            ) {
                resolve();
            }
        };

        _timer = setInterval(function () {
            try {
                for (var i = 0; i < document.styleSheets.length; i++) {
                    if (document.styleSheets[i].href === sheet.href) resolve();
                }
            } catch (e) {
                /* the stylesheet wasn't loaded */
            }
        }, 250);
    }).then(function () {
        clearInterval(_timer);
        return _url;
    });
}

const social_getRemoteImageSize = async (_url) => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.addEventListener("load", function () {
            resolve({ width: this.naturalWidth, height: this.naturalHeight });
            //alert( this.naturalWidth +' '+ this.naturalHeight );
        });
        img.addEventListener("error", function (err) {
            reject(err);
        });
        img.src = _url;
    });
};

const social_getChannelDataForGraph = (
    _channel_type,
    _channel_data,
    _value
) => {
    console.log(
        "social_getChannelDataForGraph _channel_type=" +
            _channel_type +
            ",_channel_data=" +
            _channel_data +
            ", _value=" +
            _value
    );
    let data = [];

    if (_channel_type === "facebook") {
        let _data = _channel_data.filter((r) => r.name === _value);
        _data &&
            _data[0].values.map((record) => {
                data.push({
                    value: record.value,
                    date: moment(record.end_time).format("MM/DD"), // record.end_time.substring(0, 10)
                });
            });
    }
    if (_channel_type === "twitter") {
        console.info("_channel_data", _channel_data);
        _channel_data.map((record) => {
            // let val = 0;
            const separated_values =
                _value.indexOf(",") === -1 ? [_value] : _value.split(",");
            let accumulated_value = 0;

            separated_values.map((_val) => {
                switch (_val) {
                    case "organic_metrics.impression_count":
                        accumulated_value +=
                            record.organic_metrics.impression_count;
                        break;
                    case "public_metrics.like_count":
                        accumulated_value += record.public_metrics.like_count;
                        break;
                    case "public_metrics.quote_count":
                        accumulated_value += record.public_metrics.quote_count;
                        break;
                    case "public_metrics.reply_count":
                        accumulated_value += record.public_metrics.reply_count;
                        break;
                    case "public_metrics.retweet_count":
                        accumulated_value +=
                            record.public_metrics.retweet_count;
                        break;
                    default:
                        break;
                }

                // accumulated_value += record[_val] ? record[_val] : 0;
            });
            const dataStamp = moment(record.created_at).format("MM/DD");

            let existingDataRecord = data.filter((d) => d.date === dataStamp);
            // console.info(
            //     "dataStamp () => " + dataStamp + ", existingDataRecord",
            //     existingDataRecord
            // );

            if (existingDataRecord.length > 0) {
                existingDataRecord[0].value += accumulated_value;
            } else {
                data.push({
                    value: accumulated_value,
                    date: dataStamp, // record.end_time.substring(0, 10)
                });
            }
        });
        data = data.sort((a, b) =>
            moment(a.date).valueOf() < moment(b.date).valueOf() ? -1 : 1
        );
    }
    if (_channel_type === "instagram") {
        console.info("INSTAGRAM _channel_data", _channel_data);
        _channel_data.map((record) => {
            const separated_values =
                _value.indexOf(",") === -1 ? [_value] : _value.split(",");
            let accumulated_value = 0;
            separated_values.map((_val) => {
                accumulated_value += record[_val] ? record[_val] : 0;
            });
            data.push({
                value: accumulated_value,
                date: moment(record.timestamp).format("MM/DD"),
            });
        });
        data = data.sort((a, b) =>
            moment(a.date).valueOf() < moment(b.date).valueOf() ? -1 : 1
        );
    }

    return data;
};

const social_getChannelDataForMedia = (_channel_type, _channel_data) => {
    let mediaInfo = {
        image: {
            count: 0,
            engagement: 0,
            likes: 0,
        },
        animated_gif: {
            count: 0,
            engagement: 0,
            likes: 0,
        },
        video: {
            count: 0,
            engagement: 0,
            likes: 0,
        },
    };

    if (_channel_type === "twitter") {
        console.info("TWITTER _channel_data", _channel_data);
        _channel_data &&
            _channel_data.includes &&
            _channel_data.includes.media &&
            _channel_data.includes.media.map((media) => {
                const post = _channel_data.data.filter(
                    (p) =>
                        p.attachments &&
                        p.attachments.media_keys.includes(media.media_key)
                )[0];

                switch (media.type) {
                    case "image":
                        mediaInfo.image.count++;
                        mediaInfo.image.engagement =
                            post.organic_metrics.like_count +
                            post.organic_metrics.reply_count +
                            post.organic_metrics.retweet_count;
                        mediaInfo.image.likes = post.organic_metrics.like_count;
                        break;
                    case "animated_gif":
                        mediaInfo.animated_gif.count++;
                        mediaInfo.animated_gif.engagement =
                            post.organic_metrics.like_count +
                            post.organic_metrics.reply_count +
                            post.organic_metrics.retweet_count;
                        mediaInfo.animated_gif.likes =
                            post.organic_metrics.like_count;
                        break;
                    case "video":
                        mediaInfo.video.count++;
                        mediaInfo.video.engagement =
                            media.organic_metrics.playback_0_count;
                        mediaInfo.video.likes = post.organic_metrics.like_count;
                        break;
                }
            });
    }

    if (_channel_type === "facebook") {
        console.info(
            "social_getChannelDataForMedia FACEBOOK _channel_data",
            _channel_data
        );

        _channel_data &&
            _channel_data.postsSimplified &&
            _channel_data.postsSimplified.data &&
            _channel_data.postsSimplified.data.map((post) => {
                post.attachments &&
                    post.attachments.data.map((attachment) => {
                        switch (attachment.type) {
                            case "video_inline":
                                mediaInfo.video.count++;
                                mediaInfo.video.engagement = post.likes
                                    ? post.likes.data.length
                                    : 0 + post.shares
                                    ? post.shares.count
                                    : 0 + post.comments
                                    ? post.comments.data.length
                                    : 0;
                                mediaInfo.video.likes = post.likes
                                    ? post.likes.data.length
                                    : 0;
                                break;
                            case "photo":
                                mediaInfo.image.count++;
                                mediaInfo.image.engagement = post.likes
                                    ? post.likes.data.length
                                    : 0 + post.shares
                                    ? post.shares.count
                                    : 0 + post.comments
                                    ? post.comments.data.length
                                    : 0;
                                mediaInfo.image.likes = post.likes
                                    ? post.likes.data.length
                                    : 0;
                                break;
                        }
                    });
            });
    }

    if (_channel_type === "instagram") {
        console.info(
            "social_getChannelDataForMedia INSTAGRAM _channel_data",
            _channel_data
        );

        _channel_data &&
            _channel_data.postsSimplified &&
            _channel_data.postsSimplified.data &&
            _channel_data.postsSimplified.data.map((post) => {
                //console.info("instagram post", post);
                switch (post.media_type) {
                    case "IMAGE":
                        mediaInfo.image.count++;
                        mediaInfo.image.engagement =
                            post.like_count + post.comments_count;
                        mediaInfo.image.likes = post.like_count;
                        break;
                    case "VIDEO":
                        mediaInfo.video.count++;
                        mediaInfo.video.engagement =
                            post.like_count + post.comments_count;

                        mediaInfo.video.likes = post.like_count;
                        break;
                }
            });
    }

    return mediaInfo;
};

const social_calculateChannelInsights = (_channel_type, _channel_insights) => {
    console.info(
        "social_calculateChannelInsights _channel_type=" + _channel_type,
        _channel_insights
    );

    let total_impressions = 0,
        total_engagements = 0,
        total_audience = 0,
        total_activity = 0,
        total_prev_impressions = 0,
        total_prev_engagements = 0,
        total_prev_audience = 0,
        total_prev_activity = 0;

    switch (_channel_type) {
        case "facebook":
            // Impressions
            total_impressions =
                _channel_insights &&
                _channel_insights.fbRes.data &&
                _channel_insights.fbRes.data
                    .filter((d) => d.name === "page_impressions")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_impressions
                    );

            total_prev_impressions =
                _channel_insights &&
                _channel_insights.prevPeriod &&
                _channel_insights.prevPeriod.data &&
                _channel_insights.prevPeriod.data
                    .filter((d) => d.name === "page_impressions")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_prev_impressions
                    );

            // Engagements
            total_engagements =
                _channel_insights &&
                _channel_insights.fbRes &&
                _channel_insights.fbRes.data &&
                _channel_insights.fbRes.data
                    .filter((d) => d.name === "page_post_engagements")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_engagements
                    );

            total_prev_engagements =
                _channel_insights &&
                _channel_insights.prevPeriod.data &&
                _channel_insights.prevPeriod.data
                    .filter((d) => d.name === "page_post_engagements")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_prev_engagements
                    );

            // Audience
            total_audience = 0;
            let total_audience_data =
                _channel_insights &&
                _channel_insights.fbRes.data &&
                _channel_insights.fbRes.data.filter(
                    (d) => d.name === "page_fans"
                );
            if (total_audience_data.length > 0) {
                total_audience = total_audience_data[0].values.reduce(
                    (accumulator, currentValue) =>
                        accumulator + currentValue.value,
                    total_audience
                );
            }

            total_prev_audience = 0;
            let total_prev_audience_data =
                _channel_insights &&
                _channel_insights.prevPeriod &&
                _channel_insights.prevPeriod.data &&
                _channel_insights.prevPeriod.data.filter(
                    (d) => d.name === "page_fans"
                );
            if (total_prev_audience_data.length > 0) {
                total_prev_audience = total_prev_audience_data[0].values.reduce(
                    (accumulator, currentValue) =>
                        accumulator + currentValue.value,
                    total_prev_audience
                );
            }

            // Activity
            total_activity =
                _channel_insights &&
                _channel_insights.fbRes.data &&
                _channel_insights.fbRes.data
                    .filter((d) => d.name === "page_content_activity")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_activity
                    );

            total_prev_activity =
                _channel_insights &&
                _channel_insights.prevPeriod &&
                _channel_insights.prevPeriod.data &&
                _channel_insights.prevPeriod.data
                    .filter((d) => d.name === "page_content_activity")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_prev_activity
                    );

            break;
        case "instagram":
            // Impressions
            total_impressions =
                _channel_insights.igRes.data &&
                _channel_insights.igRes.data
                    .filter((d) => d.name === "impressions")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_impressions
                    );

            total_prev_impressions =
                _channel_insights.prevPeriod.data &&
                _channel_insights.prevPeriod.data
                    .filter((d) => d.name === "impressions")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_prev_impressions
                    );

            // Engagements
            total_engagements =
                _channel_insights.igRes.data &&
                _channel_insights.igRes.data
                    .filter(
                        (d) =>
                            d.name === "website_clicks" ||
                            d.name === "text_message_clicks"
                    )[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_engagements
                    );

            total_prev_engagements =
                _channel_insights.prevPeriod.data &&
                _channel_insights.prevPeriod.data
                    .filter(
                        (d) =>
                            d.name === "website_clicks" ||
                            d.name === "text_message_clicks"
                    )[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_prev_engagements
                    );

            // Audience
            total_audience =
                _channel_insights.igRes.data &&
                _channel_insights.igRes.data
                    .filter((d) => d.name === "reach")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_audience
                    );

            total_prev_audience =
                _channel_insights.prevPeriod.data &&
                _channel_insights.prevPeriod.data
                    .filter((d) => d.name === "reach")[0]
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_prev_audience
                    );

            // Activity
            total_activity =
                _channel_insights.igRes.data &&
                _channel_insights.igRes.data
                    .filter((d) => d.name === "profile_views")[0] // TODO: This is not correct !!!
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_activity
                    );
            total_prev_activity =
                _channel_insights.prevPeriod.data &&
                _channel_insights.prevPeriod.data
                    .filter((d) => d.name === "profile_views")[0] // TODO: This is not correct !!!
                    .values.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue.value,
                        total_prev_activity
                    );
            break;
        case "twitter":
            // Impressions
            total_impressions =
                _channel_insights.data &&
                _channel_insights.data.reduce(
                    (accumulator, currentValue) =>
                        accumulator +
                        currentValue.organic_metrics.impression_count,
                    total_impressions
                );

            // Engagements
            total_engagements =
                _channel_insights.data &&
                _channel_insights.data.reduce(
                    (accumulator, currentValue) =>
                        accumulator +
                        currentValue.public_metrics.like_count +
                        currentValue.public_metrics.reply_count +
                        currentValue.public_metrics.retweet_count +
                        currentValue.public_metrics.quote_count,
                    total_engagements
                );

            // Audience
            total_audience =
                _channel_insights.includes &&
                _channel_insights.includes.users &&
                _channel_insights.includes.users[0].public_metrics
                    .followers_count;

            // Activity
            total_activity =
                _channel_insights.data && _channel_insights.data.length;

            break;
        // TODO: Linkedin
        default:
            break;
    }

    return {
        total_impressions,
        total_engagements,
        total_audience,
        total_activity,
        total_prev_impressions,
        total_prev_engagements,
        total_prev_audience,
        total_prev_activity,
    };
};

const social_getChannelPosts = (_channel_type, _channel_data) => {
    console.log("social_getChannelPosts _channel_type=" + _channel_type);
    let posts = {};
    if (_channel_type === "facebook") {
        console.info("FACEBOOK _channel_data", _channel_data);
        _channel_data.insights.postsSimplified.data.map((post) => {
            let post_date = moment(post.created_time).format("MM/DD");
            if (posts[post_date]) {
                posts[post_date].posts += 1;
                posts[post_date].comments += post.comments
                    ? post.comments.data.length
                    : 0;
                posts[post_date].likes += post.likes
                    ? post.likes.data.length
                    : 0;
                posts[post_date].shares += post.shares ? post.shares.count : 0;
            } else {
                posts[post_date] = {
                    posts: 1,
                    comments: post.comments ? post.comments.data.length : 0,
                    likes: post.likes ? post.likes.data.length : 0,
                    shares: post.shares ? post.shares.count : 0,
                };
            }
        });
    }
    if (_channel_type === "twitter") {
        console.info("TWITTER _channel_data", _channel_data);
        _channel_data.insights &&
            _channel_data.insights.data &&
            _channel_data.insights.data.map((post) => {
                let post_date = moment(post.created_at).format("MM/DD");
                if (posts[post_date]) {
                    posts[post_date].posts += 1;
                    posts[post_date].comments += post.comments
                        ? post.comments.length
                        : 0;
                    posts[post_date].likes += post.organic_metrics
                        ? post.organic_metrics.like_count
                        : 0;
                    posts[post_date].shares += post.organic_metrics
                        ? post.organic_metrics.retweet_count
                        : 0;
                } else {
                    posts[post_date] = {
                        posts: 1,
                        comments: post.comments ? post.comments.length : 0,
                        likes: post.organic_metrics
                            ? post.organic_metrics.like_count
                            : 0,
                        shares: post.organic_metrics
                            ? post.organic_metrics.retweet_count
                            : 0,
                    };
                }
            });
    }
    if (_channel_type === "instagram") {
        console.info("INSTAGRAM _channel_data", _channel_data);
        _channel_data.insights &&
            _channel_data.insights.postsSimplified &&
            _channel_data.insights.postsSimplified.data.map((post) => {
                let post_date = moment(post.timestamp).format("MM/DD");
                if (posts[post_date]) {
                    posts[post_date].posts += 1;
                    posts[post_date].comments += post.comments_count
                        ? post.comments_count
                        : 0;
                    posts[post_date].likes += post.like_count
                        ? post.like_count
                        : 0;
                    posts[post_date].shares = 0;
                } else {
                    posts[post_date] = {
                        posts: 1,
                        comments: post.comments_count ? post.comments_count : 0,
                        likes: post.like_count ? post.like_count : 0,
                        shares: 0,
                    };
                }
            });
    }
    return posts;
};

const social_capitalizeWords = (_str) => {
    return _str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export {
    social_filterList,
    social_batchCreateMasterpostWithTargets,
    social_batchUpdateMasterpostWithTargets,
    social_reportsMergeValues,
    social_checkMasterpostValidity,
    social_getRemoteImageSize,
    social_LANGUAGE_BY_LOCALE,
    social_calculateChannelInsights,
    social_getChannelDataForGraph,
    social_getChannelDataForMedia,
    social_getChannelPosts,
    add,
    total,
    social_isOpera,
    social_isFirefox,
    social_isSafari,
    social_isIE,
    social_isEdge,
    social_isChrome,
    social_isBlink,
    social_notification,
    social_setCookie,
    social_getCookie,
    social_eraseCookie,
    social_getFromLocalStorage,
    social_saveToLocalStorage,
    social_sleep,
    social_load_css,
    social_reset_css,
    social_capitalizeWords
};

export default {
    social_filterList,
};
