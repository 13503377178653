import React, { Fragment } from "react";
import ReactJson from "react-json-view";
import { Typography, Divider } from "antd";

const { Title, Text } = Typography;

const AccountsChannelInfoFacebook = ({ channel_info }) => {
    return (
        <Fragment>
            {/* <ReactJson src={channel_info} /> */}
            {/* <Divider /> */}
            <Text>Name</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.name}
            </Title>

            <Text>About</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.about}
            </Title>

            <Text>Category</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.category}
            </Title>

            <Text>Emails</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.emails && channel_info.emails.length > 0
                    ? channel_info.emails && channel_info.emails.join(", ")
                    : "N/A"}
            </Title>

            <Text>Link</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                <Fragment>
                    <a
                        href={channel_info.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {channel_info.link}
                    </a>
                </Fragment>
            </Title>

            <Text>Phone</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.phone && channel_info.phone.length > 0
                    ? channel_info.phone
                    : "N/A"}
            </Title>

            <Text>Talking About count</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.talking_about_count}
            </Title>

            <Text>Website</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.website && channel_info.website.length > 0 ? (
                    <Fragment>
                        <a
                            href={channel_info.website}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {channel_info.website}
                        </a>
                    </Fragment>
                ) : (
                    "N/A"
                )}
            </Title>
        </Fragment>
    );
};

export default AccountsChannelInfoFacebook;
