import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";

import {
    Form,
    Modal,
    Cascader,
    Icon,
    Divider,
    Alert,
    Tag,
    Input,
    Button,
    Row,
    Col,
    Tabs,
    InputNumber,
    Select,
    AutoComplete,
} from "antd";

import EditableTagGroup from "./../EditableTagGroup";
//import AccountsListModalPlan from "./AccountsListModalPlan";
const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

const tagStyle = {
    fontSize: 18,
    padding: "7px 15px 6px 15px",
};
/* Add/Edit Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                checkedKeys: [],
                filter: "",
                extra_keywords: [],
                funding_instruments: [],
                extras_linkedin_organization_loading: false,
            };
        }

        componentDidMount = () => {
            this.setState({
                extra_keywords: this.props.data.extra_keywords
                    ? this.props.data.extra_keywords
                    : [],
            });

            if (this.props.account.twitter && this.props.data.ad_account) {
                // check for twitter funding instruments
                this.props.store
                    .getTwitterFundingInstruments(
                        this.props.account.key,
                        this.props.data.ad_account
                    )
                    .then((data) => {
                        console.info("data", data.data);
                        this.setState({ funding_instruments: data.data });
                    });
            }
        };

        handleTagsChange = (_tags) => {
            console.info("handleTagsChange", _tags);
            //this.props.data = tags;
            this.props.form.setFieldsValue({ tags: _tags });
        };

        handleChange = (value) => {
            console.log(`selected ${value}`);
        };

        fillInAccount = () => {
            console.log("fillInAccount");
            this.props.form.setFieldsValue({
                template_name: this.props.account.name,
                template_email: this.props.account.email,
                template_phone: this.props.account.phone,
            });
        };

        addExtraKeyword = () => {
            let { extra_keywords } = this.state;
            extra_keywords.push({ from: "", to: "" });
            this.setState({ extra_keywords });
        };
        removeExtraKeyword = (e, _index) => {
            let { extra_keywords } = this.state;
            extra_keywords.splice(_index, 1);
            this.setState({ extra_keywords });
        };
        setExtraKeyword = (e, _index, _type, _value) => {
            let { extra_keywords } = this.state;
            extra_keywords[_index][_type] = _value;
            this.setState({ extra_keywords });
        };

        handleExtrasLinkedinSearch = async (e) => {
            console.info("handleExtrasLinkedinSearch e", e);
        };

        render() {
            const { form, data } = this.props;
            const { getFieldDecorator } = form;

            const locations = this.props.store.getLocations();

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            const basicPanel = (
                <Fragment>
                    <Form.Item label="Location">
                        {getFieldDecorator("location", {
                            initialValue: data.location,
                        })(
                            <Cascader
                                options={locations}
                                placeholder="Please select"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Tags">
                        {getFieldDecorator("tags", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.tags ? data.tags : [],
                            onChange: this.handleTagsChange,
                        })(
                            <EditableTagGroup
                                options={this.props.store.getAllTags.map(
                                    (tag) => tag.tag
                                )}
                            />
                        )}
                    </Form.Item>
                </Fragment>
            );

            const templatePanel = (
                <Fragment>
                    <Alert
                        message={
                            <Fragment>
                                Click this button in order to fill the basic
                                fields with the account's information.
                                <br />
                                <Button
                                    //type="ghost"

                                    size="small"
                                    onClick={this.fillInAccount}
                                    style={{
                                        fontSize: 12,
                                        marginTop: 5,
                                    }}
                                >
                                    Fill in account
                                </Button>
                            </Fragment>
                        }
                        type={"info"}
                        banner
                        showIcon={false}
                        style={{ marginBottom: 20 }}
                    />
                    {/* <Form.Item label="*name">
                        {getFieldDecorator("template_name", {
                            initialValue: data.template_name,
                        })(<Input />)}
                    </Form.Item> */}
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Input
                                value="*name"
                                style={{
                                    width: "calc(100% - 10px)",
                                }}
                                disabled
                            />
                        </Col>
                        <Col span={12}>
                            {getFieldDecorator("template_name", {
                                initialValue: data.template_name,
                            })(<Input />)}
                        </Col>
                    </Row>
                    {/* <Form.Item label="*email">
                        {getFieldDecorator("template_email", {
                            initialValue: data.template_email,
                        })(<Input />)}
                    </Form.Item> */}
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Input
                                value="*email"
                                style={{
                                    width: "calc(100% - 10px)",
                                }}
                                disabled
                            />
                        </Col>
                        <Col span={12}>
                            {getFieldDecorator("template_email", {
                                initialValue: data.template_email,
                            })(<Input />)}
                        </Col>
                    </Row>
                    {/* <Form.Item label="*phone">
                        {getFieldDecorator("template_phone", {
                            initialValue: data.template_phone,
                        })(<Input />)}
                    </Form.Item> */}
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Input
                                value="*phone"
                                style={{
                                    width: "calc(100% - 10px)",
                                }}
                                disabled
                            />
                        </Col>
                        <Col span={12}>
                            {getFieldDecorator("template_phone", {
                                initialValue: data.template_phone,
                            })(<Input />)}
                        </Col>
                    </Row>
                    {/* <Form.Item label="*website">
                        {getFieldDecorator("template_website", {
                            initialValue: data.template_website,
                        })(<Input />)}
                    </Form.Item> */}
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Input
                                value="*website"
                                style={{
                                    width: "calc(100% - 10px)",
                                }}
                                disabled
                            />
                        </Col>
                        <Col span={12}>
                            {getFieldDecorator("template_website", {
                                initialValue: data.template_website,
                            })(<Input />)}
                        </Col>
                    </Row>
                    {/* <Form.Item label="*location">
                        {getFieldDecorator("template_location", {
                            initialValue: data.template_location,
                        })(<Input />)}
                    </Form.Item> */}
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Input
                                value="*location"
                                style={{
                                    width: "calc(100% - 10px)",
                                }}
                                disabled
                            />
                        </Col>
                        <Col span={12}>
                            {getFieldDecorator("template_location", {
                                initialValue: data.template_location,
                            })(<Input />)}
                        </Col>
                    </Row>
                    {/* <Form.Item label="*appendix">
                        {getFieldDecorator("template_appendix", {
                            initialValue: data.template_appendix,
                        })(<Input />)}
                    </Form.Item> */}
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Input
                                value="*appendix"
                                style={{
                                    width: "calc(100% - 10px)",
                                }}
                                disabled
                            />
                        </Col>
                        <Col span={12}>
                            {getFieldDecorator("template_appendix", {
                                initialValue: data.template_appendix,
                            })(<Input />)}
                        </Col>
                    </Row>
                    {/* <Form.Item label="*firstcomment">
                        {getFieldDecorator("template_firstcomment", {
                            initialValue: data.template_firstcomment,
                        })(<Input />)}
                    </Form.Item> */}
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Input
                                value="*firstcomment"
                                style={{
                                    width: "calc(100% - 10px)",
                                }}
                                disabled
                            />
                        </Col>
                        <Col span={12}>
                            {getFieldDecorator("template_firstcomment", {
                                initialValue: data.template_firstcomment,
                            })(<Input />)}
                        </Col>
                    </Row>

                    {/* Extra templeting */}
                    <Divider>
                        &nbsp;&nbsp;Additional Templating
                        <Button
                            type="ghost"
                            // icon="plus"
                            size="small"
                            onClick={(e) => this.addExtraKeyword(e)}
                            style={{
                                fontSize: 12,
                                float: "right",
                                margin: "0 15px",
                            }}
                        >
                            New keyword
                        </Button>
                    </Divider>

                    {this.state.extra_keywords &&
                        this.state.extra_keywords.map((keyword, kIndex) => {
                            return (
                                <Row gutter={[10, 10]} key={kIndex}>
                                    <Col span={12}>
                                        *{" "}
                                        <Input
                                            placeholder="from"
                                            value={keyword.from}
                                            style={{
                                                width: "calc(100% - 20px)",
                                            }}
                                            onChange={(e) =>
                                                this.setExtraKeyword(
                                                    e,
                                                    kIndex,
                                                    "from",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col span={10}>
                                        <Input
                                            placeholder="to"
                                            value={keyword.to}
                                            onChange={(e) =>
                                                this.setExtraKeyword(
                                                    e,
                                                    kIndex,
                                                    "to",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col span={2}>
                                        <Button
                                            shape="circle"
                                            icon="minus-circle"
                                            type="link"
                                            onClick={(e) =>
                                                this.removeExtraKeyword(
                                                    e,
                                                    kIndex
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            );
                        })}
                </Fragment>
            );

            const adsPanels = (
                <Fragment>
                    <Form.Item label="Ad Account">
                        {getFieldDecorator("ad_account", {
                            initialValue: data.ad_account,
                        })(<Input />)}
                    </Form.Item>

                    {data.type === "twitter" ? (
                        <Form.Item label="Funding instrument">
                            {getFieldDecorator("ad_funding_instrument", {
                                initialValue: data.ad_funding_instrument,
                            })(
                                <Select
                                    // showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select a funding instrument"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    // onSearch={onSearch}
                                >
                                    {this.state.funding_instruments.map(
                                        (fi) => (
                                            <Option key={fi.id} value={fi.id}>
                                                {fi.description}
                                            </Option>
                                        )
                                    )}
                                </Select>
                            )}
                        </Form.Item>
                    ) : null}

                    <Form.Item label="Daily Ad/Boost cost">
                        {getFieldDecorator("ad_dailycost", {
                            initialValue: data.ad_dailycost,
                        })(
                            <InputNumber
                                //defaultValue={data.ad_dailycost}
                                min={0}
                                // max={100}
                                formatter={(value) => `$ ${value / 100}`}
                                parser={(value) =>
                                    Number(value.replace("$", "")) * 100
                                }
                                onChange={(e) => {
                                    data.ad_dailycost = e;
                                    console.info("e", e);
                                }}
                            />
                        )}
                    </Form.Item>
                </Fragment>
            );

            const extrasPanel =
                data.type === "linkedin" ? (
                    <Fragment>
                        <Form.Item label="Organization">
                            {getFieldDecorator("organization", {
                                //getValueFromEvent: handleGetTags
                                initialValue: data.organization
                                    ? data.organization
                                    : [],
                                // onChange: this.handleTagsChange,
                            })(
                                <AutoComplete
                                    dataSource={[
                                        { value: "one", text: "1" },
                                        { value: "two", text: "2" },
                                    ]}
                                    // onSelect={onSelect}
                                    // onSearch={this.handleSearch}
                                    placeholder="input here"
                                    optionLabelProp="text"
                                >
                                    <Search
                                        placeholder="input search"
                                        loading={
                                            this.state
                                                .extras_linkedin_organization_loading
                                        }
                                        enterButton
                                        onSearch={
                                            this.handleExtrasLinkedinSearch
                                        }
                                    />
                                </AutoComplete>
                            )}
                        </Form.Item>
                    </Fragment>
                ) : null;

            return (
                <Form {...formItemLayout}>
                    {/* <pre>{JSON.stringify(this.props.account, null, 2)}</pre>
                    <pre>{JSON.stringify(data, null, 2)}</pre> */}

                    <Fragment>
                        <a
                            href={data.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {data.type === "facebook" && (
                                <Tag
                                    style={tagStyle}
                                    color={this.props.store.getTagColorForType(
                                        "facebook"
                                    )}
                                >
                                    <Icon
                                        type="facebook"
                                        theme="filled"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    />
                                    {data.name}
                                </Tag>
                            )}
                            {data.type === "instagram" && (
                                <Tag
                                    style={tagStyle}
                                    color={this.props.store.getTagColorForType(
                                        "instagram"
                                    )}
                                >
                                    <Icon
                                        type="instagram"
                                        theme="filled"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    />
                                    {data.name}
                                </Tag>
                            )}

                            {data.linked_to && data.type === "instagram" ? (
                                <Fragment>
                                    <Icon
                                        type="link"
                                        style={{ margin: "0 10px" }}
                                    />
                                    <Tag
                                        style={{ ...tagStyle, marginLeft: 5 }}
                                        color={this.props.store.getTagColorForType(
                                            data.linked_to.type
                                        )}
                                    >
                                        <Icon
                                            type={data.linked_to.type}
                                            theme="filled"
                                            style={{
                                                marginRight: 5,
                                            }}
                                        />
                                        {data.linked_to.name}
                                    </Tag>
                                </Fragment>
                            ) : null}

                            {data.type === "twitter" && (
                                <Tag
                                    style={tagStyle}
                                    color={this.props.store.getTagColorForType(
                                        "twitter"
                                    )}
                                >
                                    <Icon
                                        type="twitter"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    />
                                    {data.name}
                                </Tag>
                            )}

                            {data.type === "linkedin" && (
                                <Tag
                                    style={tagStyle}
                                    color={this.props.store.getTagColorForType(
                                        "linkedin"
                                    )}
                                >
                                    <Icon
                                        type="linkedin"
                                        theme="filled"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    />
                                    {data.name}
                                </Tag>
                            )}
                        </a>
                        {/* <Badge
                                            count={2}
                                            style={{
                                                marginLeft: 5
                                            }}
                                        /> */}
                    </Fragment>

                    <Tabs
                        defaultActiveKey={this.state.ad_scope}
                        onChange={(e) => this.setState({ ad_scope: e })}
                        style={{ height: "100%", marginTop: 10 }}
                    >
                        <TabPane tab={"Basic"} key="basic">
                            {basicPanel}
                        </TabPane>
                        <TabPane tab={"Template"} key="template">
                            {templatePanel}
                        </TabPane>
                        {/* <TabPane tab={"Targeting"} key="targeting"></TabPane> */}
                        <TabPane tab={"Ads"} key="ads">
                            {adsPanels}
                        </TabPane>
                        {/* <TabPane tab={"Extras"} key="extras">
                            {extrasPanel}
                        </TabPane> */}
                    </Tabs>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class AccountsChannelsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            errors_text: "",
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "accounts_channels_modal",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("accounts_channels_modal");
    };

    onModalEdit = async () => {
        const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("formState", formState);

        form.validateFields(async (err, values) => {
            if (err) {
                social_notification("account_created", "error");
                return;
            }
            const { data, account } = this.props;

            const account_key = account.key;
            const channel_id = data.id;
            const provider = data.type;
            console.info("values", values, "data", data, "account", account);
            // // Patch the location
            // values["location_string"] = `${values["location"][0]}/${values["location"][1]}`;
            // // Patch the tags
            // values["tags_string"] = values["tags"].join('/');

            // console.log("Received values of form: ", values);
            console.log("account_key=", account_key);
            console.log("provider=", provider);
            console.log("channel_id=", channel_id);

            // ExtraKeywords
            if (
                (formState.extra_keywords &&
                    formState.extra_keywords.length > 0 &&
                    !data.extra_keywords) ||
                (formState.extra_keywords && data.extra_keywords)
            ) {
                values["extra_keywords"] = formState.extra_keywords;
            }
            console.info("values", values);

            // return;
            //this.props.store.updateAccount(this.props.data.key, values);
            await this.props.store.updateAccountChannel(
                account_key,
                provider,
                channel_id,
                values
            );

            form.resetFields();

            if (
                this.props.closeCallback &&
                typeof this.props.closeCallback === "function"
            ) {
                this.props.closeCallback();
            }

            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "accounts_channels_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data, account } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Fragment>
                        <Modal
                            visible={true}
                            title={"Edit a channel"}
                            okText={"Save"}
                            onCancel={this.onModalCancel}
                            onOk={this.onModalEdit}
                            maskClosable={false}
                            width={"50vw"}
                        >
                            <Icon
                                type="info-circle"
                                className={"social_modalHintToggle"}
                                style={{
                                    color: this.state.showHint
                                        ? this.props.store.getTagColorForType(
                                              "campaign"
                                          )
                                        : null,
                                }}
                                onClick={(e) =>
                                    this.setState({
                                        showHint: !this.state.showHint,
                                    })
                                }
                            />
                            {this.state.showHint ? (
                                <Alert
                                    type="info"
                                    message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                    banner
                                    //closable
                                    showIcon={false}
                                    onClose={(e) =>
                                        this.setState({ showHint: false })
                                    }
                                    className={"social_modalHint"}
                                />
                            ) : null}
                            <ModalForm
                                store={this.props.store}
                                wrappedComponentRef={this.saveFormRef}
                                data={data}
                                account={account}
                            />
                            {this.state.errors_text.length > 0 ? (
                                <Alert
                                    message="Errors"
                                    description={this.state.errors_text}
                                    type="error"
                                    showIcon
                                    style={{
                                        border: 0,
                                        whiteSpace: "pre-line",
                                    }}
                                />
                            ) : null}
                        </Modal>
                    </Fragment>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AccountsChannelsModal);
