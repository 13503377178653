import React, { Component, Fragment } from "react";
import {
    Empty,
    Button,
    Typography,
    Row,
    Col,
    List,
    Tag,
    Icon,
    Breadcrumb,
    Menu,
    Dropdown,
    Badge,
} from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import AccountsQueueModal from "./../Accounts/AccountsQueueModal";
import AccountsQueueEdit from "./../Accounts/AccountsQueueEdit";
import AccountsQueueSendnow from "./../Accounts/AccountsQueueSendnow";
import names from "./../../names";

const { Title, Text } = Typography;

class MasterpostsStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idToStatus: null,
            filter_status: "all",
            filter_sort: "datetime",
            filter_sort_label: "Date/Time",
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("masterposts");

        console.info("masterpostsStatus this.props ", this.props);
        this.setState({ idToStatus: this.props.match.params.id });
        //console.info("masterpostsStatus this.props ", this.props);

        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            {
                route: `/dashboard/masterposts`,
                title: names.get("MASTERPOST", true, true),
            },
            { title: "Status" },
        ]);
    }

    handleFilterMenuClick(e) {
        console.info("handleFilterMenuClick", e);
        this.setState({ filter_status: e.key });
    }
    handleSortMenuClick(e) {
        console.info("handleSortMenuClick", e);
        let filter_sort_label = "Date/Time";
        switch (e.key) {
            case "datetime":
                filter_sort_label = "Date/Time";
                break;
            case "status":
                filter_sort_label = "Status";
                break;
            case "account_name":
                filter_sort_label = "Account name";
                break;
            case "account_location":
                filter_sort_label = "Account location";
                break;
            default:
                break;
        }
        this.setState({ filter_sort: e.key, filter_sort_label });
    }

    handleMenuClick(_e, _post) {
        console.info("handleMenuClick _e", _e, "_post", _post);
        const account = this.props.store.getAccount(_post.account.key);
        this.setState({ account });

        switch (_e.key) {
            case "open":
                this.setState({ post: _post });
                setTimeout(
                    function (that) {
                        that.showModal();
                    },
                    100,
                    this
                );
                break;
            case "openqueue":
                this.props.history.push(
                    `/dashboard/accounts/${_post.account.key}/queue`
                );
                break;
            default:
                break;
        }
    }
    showModal = async () => {
        this.props.store.showModalByKey("accounts_queue_modal");
    };

    getFilteredList = (_masterpostStatus) => {
        let result = _masterpostStatus.posts.filter((post) => {
            if (this.state.filter_status !== "all") {
                if (post.status === this.state.filter_status) return post;
            } else {
                return post;
            }
            return false;
        });

        // sort
        switch (this.state.filter_sort) {
            case "datetime":
                result = result.sort((a, b) =>
                    a.scheduled_date > b.scheduled_date ? 1 : -1
                );
                break;
            case "status":
                result = result.sort((a, b) => (a.status > b.status ? 1 : -1));
                break;
            case "account_name":
                result = result.sort((a, b) =>
                    a.account.name > b.account.name ? 1 : -1
                );
                break;
            case "account_location":
                result = result.sort((a, b) =>
                    a.account.location[0] > b.account.location[0] ? 1 : -1
                );
                break;
            default:
                break;
        }

        return result;
    };

    render() {
        const masterpostStatus = this.props.store.getMasterpostStatus(
            this.state.idToStatus
        );
        console.info("masterpostStatus", masterpostStatus);

        const orderMenu = (
            <Menu onClick={(e) => this.handleSortMenuClick(e)}>
                <Menu.Item key={"datetime"}>Date/Time</Menu.Item>
                <Menu.Item key={"status"}>Status</Menu.Item>
                <Menu.Item key={"account_name"}>Account name</Menu.Item>
                <Menu.Item key={"account_location"}>Account location</Menu.Item>
            </Menu>
        );

        const includeMenu = (
            <Menu onClick={(e) => this.handleFilterMenuClick(e)}>
                <Menu.Item key={"all"}>All</Menu.Item>
                <Menu.Item key={"upcoming"}>Upcoming</Menu.Item>
                <Menu.Item key={"processing"}>Processing</Menu.Item>
                <Menu.Item key={"posted"}>Posted</Menu.Item>
            </Menu>
        );

        let header = (
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 10 }}
            >
                <Col>
                    <Title
                        level={4}
                        style={{
                            float: "left",
                            marginBottom: 0,
                            marginRight: 10,
                        }}
                    >
                        Posts
                    </Title>
                    <Badge
                        count={masterpostStatus.posts.length}
                        style={{ backgroundColor: "#1890ff" }}
                    />
                </Col>
                <Col>
                    <Dropdown overlay={includeMenu}>
                        <Button
                            type="link"
                            className="ant-dropdown-link"
                            style={{ marginRight: 20 }}
                        >
                            {this.state.filter_status
                                .toString()
                                .charAt(0)
                                .toUpperCase() +
                                this.state.filter_status
                                    .toString()
                                    .slice(1)}{" "}
                            <Icon type="down" />
                        </Button>
                    </Dropdown>
                    <Dropdown overlay={orderMenu}>
                        <Button type="link" className="ant-dropdown-link">
                            {this.state.filter_sort_label} <Icon type="down" />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        );

        return (
            <Fragment>
                {masterpostStatus ? (
                    <Fragment>
                        <Row>
                            <Col span={12}>
                                <Text type="secondary">
                                    {names.get("MASTERPOST")} name:
                                </Text>
                                <Title style={{ marginTop: 0 }} level={3}>
                                    {masterpostStatus.name}
                                </Title>
                            </Col>
                            <Col>
                                <Text type="secondary">
                                    {names.get("MASTERPOST")} time:
                                </Text>
                                <Title style={{ marginTop: 0 }} level={3}>
                                    {moment(masterpostStatus.scheduled_date)
                                        .local()
                                        .format("llll")}
                                </Title>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        {/* <Divider orientation="left">Posts</Divider> */}
                        {/* <pre>
                            {JSON.stringify(masterpostStatus.posts, null, 2)}
                        </pre> */}
                        <List
                            size="small"
                            className="social_MasterpostsStatus"
                            header={header}
                            footer={null}
                            bordered
                            dataSource={this.getFilteredList(masterpostStatus)}
                            renderItem={(item, itemIndex) => (
                                <List.Item
                                    key={itemIndex}
                                    style={{ position: "relative" }}
                                    actions={[]}
                                >
                                    <List.Item.Meta
                                        title={
                                            <div
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                {item.account.name}
                                            </div>
                                        }
                                        description={
                                            <div>
                                                {item.account.location &&
                                                item.account.location.length >
                                                    0 ? (
                                                    <div
                                                        style={{
                                                            marginBottom: 5,
                                                        }}
                                                    >
                                                        <Breadcrumb>
                                                            {item.account.location.map(
                                                                (loc) => (
                                                                    <Breadcrumb.Item
                                                                        key={
                                                                            loc
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                loc
                                                                            }
                                                                        </span>
                                                                    </Breadcrumb.Item>
                                                                )
                                                            )}
                                                        </Breadcrumb>
                                                    </div>
                                                ) : null}
                                                {item.social_type_facebook ? (
                                                    <Fragment>
                                                        <Icon
                                                            type="facebook"
                                                            theme="filled"
                                                            style={{
                                                                fontSize: 15,
                                                                color: this.props.store.getTagColorForType(
                                                                    "facebook"
                                                                ),
                                                                marginRight: 5,
                                                                float: "left",
                                                            }}
                                                        />
                                                    </Fragment>
                                                ) : null}
                                                {item.social_type_instagram ? (
                                                    <Fragment>
                                                        <Icon
                                                            type="instagram"
                                                            theme="filled"
                                                            style={{
                                                                fontSize: 15,
                                                                color: this.props.store.getTagColorForType(
                                                                    "instagram"
                                                                ),
                                                                marginRight: 5,
                                                                float: "left",
                                                            }}
                                                        />
                                                    </Fragment>
                                                ) : null}
                                                {item.social_type_twitter ? (
                                                    <Fragment>
                                                        <Icon
                                                            type="twitter"
                                                            style={{
                                                                fontSize: 15,
                                                                color: this.props.store.getTagColorForType(
                                                                    "twitter"
                                                                ),
                                                                marginRight: 5,
                                                                float: "left",
                                                            }}
                                                        />
                                                    </Fragment>
                                                ) : null}
                                            </div>
                                        }
                                    />
                                    <div
                                        style={{
                                            // flexGrow: 1,
                                            textAling: "left",
                                        }}
                                    >
                                        {item.post_text}
                                    </div>
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            textAling: "right",
                                        }}
                                    >
                                        <Tag
                                            color={this.props.store.getTagColorForType(
                                                item.status
                                            )}
                                            key={item.status}
                                            style={{
                                                position: "absolute",
                                                top: 15,
                                                right: 10,
                                            }}
                                        >
                                            {item.status}
                                        </Tag>
                                        <Dropdown
                                            overlay={
                                                <Menu
                                                    onClick={(e) =>
                                                        this.handleMenuClick(
                                                            e,
                                                            item
                                                        )
                                                    }
                                                >
                                                    <Menu.Item key="open">
                                                        <Icon type="container" />
                                                        Open
                                                    </Menu.Item>

                                                    <Menu.Item key="openqueue">
                                                        <Icon type="arrow-right" />
                                                        Open account queue
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                        >
                                            <Icon
                                                type="more"
                                                style={{
                                                    position: "absolute",
                                                    bottom: 10,
                                                    right: 10,
                                                }}
                                            />
                                        </Dropdown>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Fragment>
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={`No ${names.get(
                            "MASTERPOST"
                        )} to Status for idToStatus: ${this.state.idToStatus}`}
                    />
                )}
                <AccountsQueueModal
                    store={this.props.store}
                    post={this.state.post}
                    account={this.state.account}
                />
                <AccountsQueueEdit
                    store={this.props.store}
                    post={this.state.post}
                    queue_index={this.state.queue_index}
                    account={this.state.account}
                />
                <AccountsQueueSendnow
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.sendnow_visible}
                    onCancel={this.handleSendnowCancel}
                    // onCreate={this.handleModalCreate}
                    onEdit={this.handleEditEdit}
                    store={this.props.store}
                    post={this.state.post}
                    account={this.state.account}
                    confirmLoading={this.state.sendnow_confirmLoading}
                    queue_index={this.state.queue_index}
                    // locations={this.props.store.getLocations()}
                />
            </Fragment>
        );
    }
}

export default observer(MasterpostsStatus);
