import React, { Fragment } from "react";
import { Row, Col, Table, Divider } from "antd";
import ReportSingleValue from "../Reports/ReportSingleValue";
// import ReactJson from "react-json-view";

const GRID_GUTTER = 40;

const AccontsChannelInsightsLinkedin = ({ channel_insights }) => {
    const { shares_stats: sharesstatsarray, stats } = channel_insights;
    const shares_stats_obj = sharesstatsarray && sharesstatsarray[0];
    if (typeof shares_stats_obj == "undefined") return null;
    const { totalShareStatistics } = shares_stats_obj;
    const stats_data = stats[0];

    const parseStatsElements = (_stats_data, _elements) => {
        let dataSourceResult = [];
        _elements.forEach(({ field, nameKey }) => {
            if (stats_data[field]) {
                stats_data[field].map((rec) => {
                    if (rec.pageStatistics.views) {
                        let views = rec.pageStatistics.views;
                        let obj = {
                            key: rec[nameKey],
                            name: rec[nameKey],
                        };
                        let extra_fields = {};
                        let extra_object_keys = Object.keys(views).map(
                            (key) => {
                                let obj = {};
                                obj[key] = views[key].pageViews;
                                return obj;
                            }
                        );
                        extra_object_keys.map((objKey) => {
                            // console.info("objKey", objKey);
                            let keyName = Object.keys(objKey)[0];
                            extra_fields[keyName] = objKey[keyName];
                        });
                        dataSourceResult.push({
                            ...obj,
                            ...extra_fields,
                        });
                    }
                });
            }
        });
        return dataSourceResult;
    };

    const dataSource = parseStatsElements(stats_data, [
        { field: "pageStatisticsBySeniority", nameKey: "seniority" },
        { field: "pageStatisticsByCountry", nameKey: "country" },
        { field: "pageStatisticsByIndustry", nameKey: "industry" },
        {
            field: "pageStatisticsByStaffCountRange",
            nameKey: "staffCountRange",
        },
        { field: "pageStatisticsByRegion", nameKey: "region" },
        { field: "pageStatisticsByFunction", nameKey: "function" },
    ]);

    // if (stats_data.pageStatisticsBySeniority) {
    //     stats_data.pageStatisticsBySeniority.map((rec) => {
    //         if (rec.pageStatistics.views) {
    //             let views = rec.pageStatistics.views;
    //             let obj = {
    //                 key: rec.seniority,
    //                 name: rec.seniority,
    //             };
    //             let extra_fields = {};
    //             let extra_object_keys = Object.keys(views).map((key) => {
    //                 let obj = {};
    //                 obj[key] = views[key].pageViews;
    //                 return obj;
    //             });
    //             extra_object_keys.map((objKey) => {
    //                 console.info("objKey", objKey);
    //                 let keyName = Object.keys(objKey)[0];
    //                 extra_fields[keyName] = objKey[keyName];
    //             });
    //             dataSource.push({
    //                 ...obj,
    //                 ...extra_fields,
    //             });
    //         }
    //     });
    // }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Insights",
            dataIndex: "insightsPageViews",
            key: "insightsPageViews",
        },
        {
            title: "Jobs",
            dataIndex: "jobsPageViews",
            key: "jobsPageViews",
        },
        {
            title: "Products",
            dataIndex: "productsPageViews",
            key: "productsPageViews",
        },
        {
            title: "People",
            dataIndex: "peoplePageViews",
            key: "peoplePageViews",
        },
        {
            title: "Overview",
            dataIndex: "overviewPageViews",
            key: "overviewPageViews",
        },
        {
            title: "Life At",
            dataIndex: "lifeAtPageViews",
            key: "lifeAtPageViews",
        },
        {
            title: "Careers",
            dataIndex: "careersPageViews",
            key: "careersPageViews",
        },
        {
            title: "About",
            dataIndex: "aboutPageViews",
            key: "aboutPageViews",
        },
    ];

    const sharesStatsPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Unique Impressions"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.uniqueImpressionsCount
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Shares"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.shareCount
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Mentions"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.shareMentionsCount
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Engagement"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.engagement
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Clicks"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.clickCount
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Likes"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.likeCount
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Impressios"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.impressionCount
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Mentions"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.commentMentionsCount
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Comments"
                        value={
                            totalShareStatistics &&
                            totalShareStatistics.commentCount
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
        </Fragment>
    );

    const pageViewsTable = (
        <Fragment>
            <h3>Page Views</h3>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                size="small"
            />
        </Fragment>
    );

    return (
        <Fragment>
            {sharesStatsPanel}
            {/* <pre>{JSON.stringify(totalShareStatistics, null, 2)}</pre> */}
            <Divider />
            {pageViewsTable}
            {/* <ReactJson src={dataSource} />
            <Divider />
            <ReactJson src={stats_data} /> */}
            {/* <pre>{JSON.stringify(stats, null, 2)}</pre> */}
        </Fragment>
    );
};

export default AccontsChannelInsightsLinkedin;
