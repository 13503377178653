import React, { Component, Fragment } from "react";
import { base, firebase } from "../../base";
import config from "../../config";
import tooltips from "../../tooltips";

import {
    social_filterList,
    social_batchCreateMasterpostWithTargets,
    social_notification,
    social_sleep,
} from "../../functions";
import moment from "moment";
import shortid from "shortid";
import scss_variables from "./../../_variables.scss";
import { observer } from "mobx-react";
import names from "./../../names";

import {
    Empty,
    List,
    Button,
    Icon,
    Tag,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Modal,
    notification,
    Checkbox,
} from "antd";

import MasterpostsListModal from "./MasterpostsListModal";
import MasterpostsListModalNew from "./MasterpostsListModalNew";
import MasterpostsListModalPerformance from "./MasterpostsListModalPerformance";
import MasterpostsListModalInfo from "./MasterpostsListModalInfo";
import ReportsModal from "../Reports/ReportsModal";
import AdsListModal from "../Ads/AdsListModal";
import SocialTag from "./../SocialTag";
import SocialErrorSymbol from "./../SocialErrorSymbol";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";

import Bulk from "./../Bulk";

const { Text, Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class MasterpostsList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            modal_visible: false,
            edit_data: {},
            filter: "",
            pagination_currentPage: 1,
            modalnew_visible: false,
            new_data: {
                targets: [
                    {
                        name: "Target 1",
                    },
                ],
            },
            sortby: "name",
            sortasc: true,
            ready: false,
        };

        this.adsModal = React.createRef();
        this.masterpostsModal = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("masterposts");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            { title: names.get("MASTERPOST", true, true) },
        ]);

        console.info("MasterpostsList this.props ", this.props);
        if (this.props.match.params.filter) {
            this.setState({ filter: this.props.match.params.filter });
        }
    }

    async componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);

        // Bulk listing
        this.listBulk = new Bulk({
            name: "masterpostsList",
            list: this.props.store.getAllMasterposts.map(
                (element) => element.key
            ),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                        await this.props.store.deleteMasterpost({ key: _key });
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                },
            ],
            callback: () => {
                console.log("READY");
            },
        });

        await social_sleep(100);
        this.loadInformation();
    }

    loadInformation = async () => {
        this.setState({
            ready: true,
        });
    };

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("masterposts_modal");
        this.props.store.unregisterModalListener("masterposts_modal_new");
        this.props.store.unregisterModalListener("reports_modal");
    }

    showModal = () => {
        console.log("showModal");
        this.setState({
            //modal_visible: true,
            edit_data: {},
        });

        console.log("showModal");
        this.props.store.showModalByKey("masterposts_modal_new");
    };

    showModalForEdit = (_evt, _masterpost) => {
        console.info("showModalForEdit _evt", _evt, "_masterpost", _masterpost);

        this.setState({
            //modal_visible: true,
            edit_data: _masterpost,
        });

        this.props.store.showModalByKey("masterposts_modal");
    };

    // handleModalCancel = () => {
    //     //this.setState({ modal_visible: false });
    //     //this.props.store.showModalByKey("masterposts_modal");
    // };

    handleModalCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            this.props.store.addMasterpost({
                ...values,
                ...this.state.edit_data,
            });

            form.resetFields();
            this.setState({ modal_visible: false });
        });
    };

    handleModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.state.edit_data.key);

            this.props.store.updateMasterpost(this.state.edit_data.key, values);

            form.resetFields();
            this.setState({ modal_visible: false });
        });
    };

    handleDeleteMasterpost = (_evt, _masterpost) => {
        console.info(
            "handleDeleteMasterpost _evt",
            _evt,
            "_masterpost",
            _masterpost
        );
        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk() {
                that.props.store.deleteMasterpost(_masterpost);
            },
        });
    };

    showReport = (_evt, _item) => {
        console.info("showReport _evt", _evt, "_item", _item);

        this.setState({ report_masterpost: _item });
        //this.props.history.push(`/dashboard/masterposts/${_item.key}/report`);
        this.props.store.showModalByKey("reports_modal");
    };

    showStatus = (_evt, _item) => {
        console.info("showStatus _evt", _evt, "_item", _item);

        this.props.history.push(`/dashboard/masterposts/${_item.key}/status`);
    };

    showTargets = (_evt, _item) => {
        console.info("showTargets _evt", _evt, "_item", _item);

        this.props.history.push(`/dashboard/masterposts/${_item.key}/targets`);
    };
    showAdsModal = (_evt, _item) => {
        console.info("showAdsModal _evt", _evt, "_item", _item);
        this.adsModal.current.registerModal();
        this.props.store.showModalByKey("ads_modal", {
            type: "fb_boost",
            masterpost_key: _item.key,
        });
    };

    onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
    };

    onSearchChange = (_search) => {
        // console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        this.setState({ sortby: _sorting, sortasc });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    getListingDataSource = () => {
        let masterpostsList = this.props.store.getAllMasterposts.filter(
            (mp) => typeof mp.scheduled_date !== "undefined"
        );
        //console.info("masterpostsList", masterpostsList);

        let results = social_filterList(masterpostsList, this.state.filter, {
            type: "masterposts",
            fields: [
                { name: "name", type: "string" },
                { name: "note", type: "string" },
                { name: "categories", type: "array" },
                { name: "campaigns", type: "array" },
                { name: "publish_type", type: "string" },
                {
                    name: "targets",
                    type: "arrayOfObjects",
                    arrayFieldName: "name",
                },
                {
                    name: "targets.location",
                    type: "array",
                },
            ],
        });

        // --- sorting ---
        results.sort((a, b) => {
            // console.info(
            //     "results.sort (this.state.sortby)",
            //     this.state.sortby,
            //     "this.state.sortasc",
            //     this.state.sortasc
            // );

            var nameA = a.name && a.name.toLowerCase(),
                nameB = b.name && b.name.toLowerCase();
            var scheduled_dateA =
                    a.scheduled_date && typeof a.scheduled_date === "number"
                        ? a.scheduled_date
                        : moment(a.scheduled_date).valueOf(),
                scheduled_dateB =
                    b.scheduled_date && typeof b.scheduled_date === "number"
                        ? b.scheduled_date
                        : moment(b.scheduled_date).valueOf();

            //console.info("results.sort (scheduled_dateA)", scheduled_dateA);

            switch (this.state.sortby) {
                case "name":
                    if (this.state.sortasc === true) {
                        if (nameA <= nameB) return -1;
                        if (nameA > nameB) return 1;
                    } else {
                        if (nameA <= nameB) return 1;
                        if (nameA > nameB) return -1;
                    }
                    break;

                case "scheduled_date":
                    if (this.state.sortasc === true) {
                        if (scheduled_dateA < scheduled_dateB) {
                            return -1;
                        }
                        if (scheduled_dateA >= scheduled_dateB) {
                            return 1;
                        }
                    } else {
                        if (scheduled_dateA <= scheduled_dateB) {
                            return 1;
                        }
                        if (scheduled_dateA > scheduled_dateB) {
                            return -1;
                        }
                    }
                    break;

                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting

        //--- status ---
        results.map((m) => {
            const status = this.props.store.getMasterpostStatus(m.key);
            //console.info("status.posts", status.posts);
            m.errors = [];
            const subStatuses = status.posts
                .map((post) => {
                    if (post.errors) m.errors = [...m.errors, ...post.errors];
                    return !post.errors ? post.status : ["error", post.status];
                })
                .flat()
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });

            m.errors = m.errors.filter(function (el) {
                return el != null && el != "";
            });

            m.status = subStatuses;
            //console.info("subStatuses", subStatuses);
            return false;
        });
        //--- end of status ---

        //console.info("results", results);
        // --- end of check whether deletable ----

        // --- check whether editable ----
        // results.map((mp) => {
        //     console.info("mp", mp);
        //     mp.editable =
        //         moment(mp.scheduled_date).valueOf() < moment().valueOf();
        //     return false;
        // });
        // console.info("results", results);
        // --- end of check whether editable ----

        // const results = this.props.store.getAllmasterposts.filter(a => {
        //     return (
        //         a.name
        //             .toUpperCase()
        //             .indexOf(this.state.filter.toUpperCase()) !== -1 ||
        //         a.note
        //             .toUpperCase()
        //             .indexOf(this.state.filter.toUpperCase()) !== -1 ||
        //         a.tags.filter(t => {
        //             return (
        //                 t
        //                     .toUpperCase()
        //                     .indexOf(this.state.filter.toUpperCase()) != -1
        //             );
        //         }).length > 0 ||
        //         a.publish_type
        //             .toUpperCase()
        //             .indexOf(this.state.filter.toUpperCase()) != -1
        //     );
        // });

        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    showModalNewEdit = (_evt, _masterpost) => {
        console.info(
            "MasterpostsListModalNew showModalNewEdit _evt",
            _evt,
            "_masterpost",
            _masterpost
        );

        this.setState({
            //modal_visible: true,
            edit_data: _masterpost,
        });

        this.props.store.showModalByKey("masterposts_modal_new");
    };
    showModalNew = () => {
        console.log("showModalNew");
        this.setState({
            //modalnew_visible: true,
            edit_data: null,
        });

        this.masterpostsModal.current.registerModal();
        this.props.store.showModalByKey("masterposts_modal_new");
    };

    showModalPerformance = (_evt, _masterpost) => {
        console.info(
            "MasterpostsList showModalPerformance _evt",
            _evt,
            "_masterpost",
            _masterpost
        );

        this.setState({
            //modal_visible: true,
            edit_data: _masterpost,
        });

        this.props.store.showModalByKey("masterposts_modal_performance");
    };

    showModalInfo = (_evt, _masterpost) => {
        console.info(
            "MasterpostsList showModalInfo _evt",
            _evt,
            "_masterpost",
            _masterpost
        );

        this.setState({
            //modal_visible: true,
            edit_data: _masterpost,
        });

        this.props.store.showModalByKey("masterposts_modal_info");
    };

    handleModalNewCancel = () => {
        this.setState({ modalnew_visible: false });
    };
    handleModalNewCreate = (_data) => {
        console.info("handleModalNewCreate _data", _data);
        this.setState({ modalnew_visible: false });

        const key = "progress_notification";
        notification.info({
            key,
            duration: 0,
            message: "Creating masterpost ...",
            description: "Please wait while we create the new masterpost",
        });

        // Creating the masterpost
        let masterpost_data = {
            categories: _data.masterpost_categories,
            //every_dropdown: _data.masterpost_every_dropdown,
            //every_number: values.masterpost_every_number,
            name: _data.masterpost_name,
            note: _data.masterpost_note,
            publish_local_time: _data.masterpost_publish_local_time,
            publish_type: _data.masterpost_publish_type,
            scheduled_date: _data.masterpost_scheduled_date
                ? moment(_data.masterpost_scheduled_date).valueOf()
                : null,
            targets: _data.targets.map((target) => {
                return {
                    template_text: target.template_text,
                    template_file: target.template_file,
                    library_file: target.library_file,
                    rules_location: target.rules_location,
                    rules_tags: target.rules_tags,
                    rules_custom: target.rules_custom,
                    tags: target.tags,
                    location: target.location,
                    name: target.name,
                    note: "Target Note",
                    exclude: target.exclude,
                    include: target.include,
                    custom: target.custom,
                    social_type_facebook: target.social_type_facebook,
                    social_type_instagram: target.social_type_instagram,
                    social_type_twitter: target.social_type_twitter,
                    changed_template_file: target.changed_template_file,
                };
            }),
        };

        console.info("handleModalNewCreate masterpost_data", masterpost_data);

        social_batchCreateMasterpostWithTargets(
            this.props.store,
            shortid,
            base,
            firebase,
            masterpost_data
        )
            .then((result) => {
                console.info("result = ", result);
                social_notification(
                    "batch_create_masterpost_with_targets",
                    "success"
                );
            })
            .catch((error) => {
                console.error(error);
                social_notification(
                    "batch_create_masterpost_with_targets",
                    "error",
                    {
                        description: error.message,
                    }
                );
            });
    };
    handleModalNewAddTarget = () => {
        let { new_data } = this.state;
        new_data.targets.push({ name: "New Target" });
        this.setState({ new_data });
    };
    handleModalNewDeleteTarget = (_targetIndex) => {
        let { new_data } = this.state;
        new_data.targets.splice(_targetIndex, 1);
        this.setState({ new_data });
    };
    handleTargetChange = (_evt, _info) => {
        console.info("handleTargetChange _evt", _evt, "_info", _info);
    };

    render() {
        //console.info("masterpostsList Render", this.state.tags);

        const pagesCount =
            this.props.store.getAllMasterpostsLength /
            config.PAGINATION_PERPAGE;
        //console.log("pagesCount = " + pagesCount);

        const header = (
            <SocialListHeader
                title="Primary posts"
                newButtonClickHandle={this.showModal}
                newButtonText="New primary post"
                newButtonTooltip={tooltips.MASTERPOSTS.NEWBUTTON}
                bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );

        let body =
            this.props.store.getAllMasterpostsLength === 0 ? (
                <Empty />
            ) : (
                <List
                    size="small"
                    className="MasterpostsList"
                    header={
                        <Fragment>
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Col style={{ width: 25, lineHeight: 1.2 }}>
                                    {/* <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                    /> */}
                                </Col>
                                <Col style={{ flex: "1 1", marginRight: -60 }}>
                                    <SocialListColumn
                                        sortkey="name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Name
                                    </SocialListColumn>
                                </Col>
                                <Col
                                    style={{
                                        flexGrow: 1,
                                        paddingLeft: 70,
                                    }}
                                >
                                    <SocialListColumn
                                        sortkey="scheduled_date"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Publish
                                    </SocialListColumn>
                                </Col>
                                <Col
                                    style={{
                                        flexGrow: 1,
                                        paddingLeft: 70,
                                    }}
                                >
                                    Status
                                </Col>
                                <Col style={{ width: 285 }}>Tools</Col>
                            </Row>
                        </Fragment>
                    }
                    footer={
                        pagesCount > 1 && this.state.filter.length === 0 ? (
                            <div style={{ textAlign: "right" }}>
                                <Pagination
                                    size="small"
                                    defaultCurrent={1}
                                    total={
                                        this.props.store.getAllMasterpostsLength
                                    }
                                    pageSize={config.PAGINATION_PERPAGE}
                                    current={this.state.pagination_currentPage}
                                    onChange={this.paginationSetPage}
                                />
                            </div>
                        ) : null
                    }
                    bordered
                    dataSource={this.getListingDataSource()}
                    renderItem={(item) => (
                        <List.Item
                            key={item.name}
                            className={
                                this.listBulk &&
                                this.listBulk.isChecked(item.key)
                                    ? this.listBulk.getCheckedLength() === 1
                                        ? "social_Selected_withTools"
                                        : "social_Selected"
                                    : null
                            }
                            actions={[
                                <Tooltip
                                    key="1"
                                    title={tooltips.MASTERPOSTS.LIST_EDIT}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showModalNewEdit(_evt, item);
                                        }}
                                        // disabled={
                                        //     moment(
                                        //         item.scheduled_date
                                        //     ).valueOf() < moment().valueOf()
                                        // }
                                    >
                                        <Icon type="edit" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="2"
                                    title={
                                        item.deletable === true
                                            ? tooltips.MASTERPOSTS.LIST_DELETE
                                            : tooltips.MASTERPOSTS
                                                  .LIST_CANNOT_DELETE
                                    }
                                >
                                    <Icon
                                        type="delete"
                                        onClick={(evt) => {
                                            this.handleDeleteMasterpost(
                                                evt,
                                                item
                                            );
                                        }}
                                        style={
                                            item.deletable === false
                                                ? {
                                                      textDecoration:
                                                          "line-through",
                                                      opacity: 0.5,
                                                      pointerEvents: "none",
                                                      color: scss_variables.scss_color_pallete_error,
                                                  }
                                                : {
                                                      color: scss_variables.scss_color_pallete_error,
                                                  }
                                        }
                                    />
                                </Tooltip>,
                                <Tooltip
                                    key="3"
                                    title={tooltips.MASTERPOSTS.LIST_TARGETS}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showTargets(_evt, item);
                                        }}
                                    >
                                        <Icon type="deployment-unit" />

                                        {/* (
                                    {typeof item.targets != "undefined" &&
                                    item.targets.length === 0
                                        ? "0"
                                        : typeof item.targets != "undefined" &&
                                          item.targets.length !== 0
                                        ? item.targets.length
                                        : "0"}
                                    ) */}
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="4"
                                    title={tooltips.MASTERPOSTS.LIST_REPORT}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showReport(_evt, item);
                                        }}
                                    >
                                        <Icon type="pie-chart" />
                                    </Button>
                                </Tooltip>,
                                // <Button
                                //     key="5"
                                //     type="link"
                                //     style={{ padding: 0 }}
                                //     onClick={(_evt) => {
                                //         this.showStatus(_evt, item);
                                //     }}
                                // >
                                //     status
                                // </Button>,
                                // <Button
                                //     key="6"
                                //     type="link"
                                //     style={{ padding: 0 }}
                                //     onClick={(_evt) => {
                                //         this.showModalPerformance(_evt, item);
                                //     }}
                                // >
                                //     performance
                                // </Button>,
                                <Tooltip
                                    key="7"
                                    title={tooltips.MASTERPOSTS.LIST_INFO}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showModalInfo(_evt, item);
                                        }}
                                    >
                                        <Icon type="info-circle" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="8"
                                    title={tooltips.MASTERPOSTS.LIST_BOOST}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showAdsModal(_evt, item);
                                        }}
                                    >
                                        <Icon type="line-chart" />
                                    </Button>
                                </Tooltip>,
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <div key={item.name}>
                                        <Checkbox
                                            onChange={(e) =>
                                                this.listBulk.handleCheck(
                                                    item.key
                                                )
                                            }
                                            checked={
                                                this.listBulk
                                                    ? this.listBulk.isChecked(
                                                          item.key
                                                      )
                                                    : false
                                            }
                                            style={{ marginRight: 10 }}
                                        />
                                        {item.name}
                                        {item.brand
                                            ? `&nbsp;(${item.brand})`
                                            : null}
                                    </div>
                                }
                                description={
                                    item.note || item.categories?.length > 0 ? (
                                        <Fragment>
                                            {item.note}
                                            {item.categories?.length > 0 ? (
                                                <div style={{ marginTop: 10 }}>
                                                    {item.categories
                                                        ? item.categories.map(
                                                              (
                                                                  category,
                                                                  cIndex
                                                              ) => (
                                                                  <SocialTag
                                                                      key={
                                                                          cIndex
                                                                      }
                                                                      store={
                                                                          this
                                                                              .props
                                                                              .store
                                                                      }
                                                                      color="category"
                                                                  >
                                                                      {category}
                                                                  </SocialTag>
                                                              )
                                                          )
                                                        : ""}
                                                </div>
                                            ) : null}
                                        </Fragment>
                                    ) : null
                                }
                            />
                            <div>
                                {/* <Tag
                                    color={this.props.store.getTagColorForType(
                                        item.publish_type
                                    )}
                                    key={item.publish_type}
                                > */}
                                {item.scheduled_date
                                    ? moment(
                                          moment
                                              .utc(item.scheduled_date)
                                              .toDate()
                                      )
                                          .local()
                                          .format("lll")
                                    : "N/A"}
                                {/* </Tag> */}
                                {item.publish_local_time === true ? (
                                    <Fragment>
                                        <br />
                                        <Tag
                                            style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: 5,
                                            }}
                                        >
                                            <Icon type="environment" />
                                            <Text
                                                type="secondary"
                                                style={{
                                                    marginLeft: 5,
                                                    fontSize: "0.75rem",
                                                }}
                                            >
                                                Local time
                                            </Text>
                                        </Tag>
                                    </Fragment>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div>
                                {item.status
                                    ? item.status
                                          .sort()
                                          .map((status, sIndex) => {
                                              let ret = null;

                                              if (status === "upcoming") {
                                                  ret = (
                                                      <Icon
                                                          type="clock-circle"
                                                          title="Upcoming"
                                                          style={{
                                                              marginRight: 5,
                                                          }}
                                                          key={sIndex}
                                                      />
                                                  );
                                              }

                                              if (status === "posted") {
                                                  ret = (
                                                      <Icon
                                                          type="check"
                                                          title="Posted"
                                                          style={{
                                                              marginRight: 5,
                                                          }}
                                                          key={sIndex}
                                                      />
                                                  );
                                              }

                                              if (status === "error") {
                                                  ret = (
                                                      <SocialErrorSymbol
                                                          key={sIndex}
                                                          errors={item.errors}
                                                          store={
                                                              this.props.store
                                                          }
                                                      />
                                                  );
                                              }

                                              return ret;
                                              //   <Tag
                                              //       color={this.props.store.getTagColorForType(
                                              //           status
                                              //       )}
                                              //       key={sIndex}
                                              //   >
                                              //       {status}
                                              //   </Tag>
                                          })
                                    : ""}
                            </div>
                        </List.Item>
                    )}
                />
            );

        return this.state.ready === false ? (
            <div
                style={{
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Icon
                    type="loading"
                    style={{ fontSize: "3rem", margin: 10 }}
                    spin
                />
                Processing ...
            </div>
        ) : (
            <Fragment>
                {header}
                {body}
                <MasterpostsListModal
                    data={this.state.edit_data}
                    store={this.props.store}
                />

                <MasterpostsListModalNew
                    data={this.state.edit_data}
                    store={this.props.store}
                    ref={this.masterpostsModal}
                    //onCancel={this.handleModalNewCancel}
                    //onCreate={this.handleModalNewCreate}
                    //onAddTarget={this.handleModalNewAddTarget}
                    //onDeleteTarget={this.handleModalNewDeleteTarget}
                    //handleTargetChange={this.handleTargetChange}
                />

                <MasterpostsListModalPerformance
                    data={this.state.edit_data}
                    store={this.props.store}
                />
                <MasterpostsListModalInfo
                    data={this.state.edit_data}
                    store={this.props.store}
                    history={this.props.history}
                />
                <AdsListModal
                    // data={this.state.modal_ad_data}
                    store={this.props.store}
                    ref={this.adsModal}
                />
                <ReportsModal
                    report={
                        this.props.store.getAllReports.filter(
                            (report) => report.key === "masterpost_basic"
                        )[0]
                    }
                    report_masterpost={this.state.report_masterpost}
                    store={this.props.store}
                />
            </Fragment>
        );
    }
}

export default observer(MasterpostsList);
