import React from "react";
import { Tag, Icon } from "antd";

const SocialTag = ({ store, color, children }) => {
    return (
        <Tag className="social_Tag">
            <Icon
                type="tag"
                theme="filled"
                style={{
                    color: store.getTagColorForType(color),
                }}
            />
            {children}
        </Tag>
    );
};

export default SocialTag;
