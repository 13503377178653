import React, { Component, Fragment } from "react";
// import { BrowserRouter, Redirect } from "react-router-dom";

// import lorem from "../lorem-lorem.svg";
import PrivacyModal from "./PrivacyModal";
import TermsModal from "./TermsModal";

import { app } from "../base";

import { Button, Divider } from "antd";

class NonExistingAccount extends Component {
    state = {};
    typeTimer = null;

    constructor(props) {
        super(props);
        //   this.authWithFacebook = this.authWithFacebook.bind(this);

        this.state = {
            redirect: false,
            valid: null,
        };
    }

    handleLogin = () => {
        app.auth()
            .signOut()
            .then((user) => {
                this.props.store.resetLoggedUser();
                //this.setState({ redirect: "/login" });
                //this.props.history.push("/login");
                window.location = "/login";
            });
    };

    render() {
        // if (this.state.redirect !== false) {
        //     return (
        //         <BrowserRouter>
        //             <Redirect to={this.state.redirect} />
        //         </BrowserRouter>
        //     );
        // }
        //console.info("app.auth().currentUser", app.auth().currentUser);

        //const loggedUser = this.props.store.getLoggedUser;
        // console.info("loggedUser", loggedUser);
        //this.state.authenticated

        let body = (
            <Fragment>
                <div className={"Register"}>
                    <div
                        className={"Header"}
                        style={{ gridTemplateColumns: "auto" }}
                    >
                        <div className={"Limiter"} style={{ width: "100%" }}>
                            <div
                                className={"Logo"}
                                style={{ float: "left" }}
                            ></div>

                            <Button
                                className="login-form-button"
                                onClick={() => {
                                    this.handleLogin();
                                }}
                            >
                                Login
                            </Button>
                        </div>
                    </div>

                    <div className={"Container"}>
                        <div className={"Limiter"}>
                            <div className={"Box"}>
                                <h1>No Existing Account</h1>
                                Please register your account first.
                                <Divider />
                            </div>
                        </div>
                    </div>

                    <div className={"Footer"}>
                        <div className={"Limiter"}>
                            <PrivacyModal store={this.props.store} />
                            <TermsModal store={this.props.store} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        return body;
    }
}

export default NonExistingAccount;
