import React, { Fragment } from "react";
import {
    Form,
    Checkbox,
    Select,
    Divider,
    Icon,
    Tree,
    InputNumber,
    DatePicker,
    Alert,
} from "antd";
import moment from "moment";
import TwitterTargeting from "./../Accounts/TwitterTargeting";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TreeNode } = Tree;

const disableTable = {
    APP_ENGAGEMENTS: [],
    APP_INSTALLS: [],
    AWARENESS: ["PUBLISHER_NETWORK"],
    FOLLOWERS: ["PUBLISHER_NETWORK"],
    ENGAGEMENTS: ["PUBLISHER_NETWORK"],
    REACH: [],
    VIDEO_VIEWS: ["PUBLISHER_NETWORK"],
    PREROLL_VIEWS: ["PUBLISHER_NETWORK"],
    WEBSITE_CLICKS: [],
};

const AdsListModalFormPanelTwitter = (props, context) => {
    const { form, state, store } = props;
    const { getFieldDecorator } = form;
    // const { fetching } = state;

    const allTwitterAccounts = store.getAllAccounts
        .filter((a) => {
            return a.twitter ? true : false;
        })
        .map((a) => ({
            title: `${a.twitter.auth.screen_name}`,
            key: a.key,
        }));

    const renderAccountsTreeNodesForTwitter = (data) =>
        data.map((item) => {
            return (
                <TreeNode
                    title={
                        <Fragment>
                            <Icon
                                type="twitter"
                                style={{
                                    marginRight: 5,
                                    color: store.getTagColorForType("twitter"),
                                }}
                            />
                            {item.title}
                        </Fragment>
                    }
                    key={item.key}
                    dataRef={item}
                ></TreeNode>
            );
            //return <TreeNode key={item.key} {...item} />;
        });

    return (
        <Fragment>
            <Divider
                orientation="left"
                style={{
                    marginLeft: 20,
                }}
            >
                Accounts
            </Divider>

            <Tree
                checkable
                selectable={false}
                // onExpand={this.onExpand}
                // expandedKeys={state.expandedKeys}
                // autoExpandParent={state.autoExpandParent}
                onCheck={props.ononNewAdTwitterCheck}
                checkedKeys={state.newad_twitter_accounts}
                // onSelect={this.onSelect}
                // selectedKeys={state.selectedKeys}
                style={{
                    marginTop: 2,
                    marginLeft: -26,
                }}
            >
                {renderAccountsTreeNodesForTwitter(allTwitterAccounts)}
            </Tree>

            {state.newad_twitter_accounts &&
            state.newad_twitter_accounts.length > 0 ? (
                <Fragment>
                    <Form.Item label={"Start/End date"}>
                        <RangePicker
                            format="MM/DD YYYY HH:mm"
                            defaultValue={[
                                moment(state.twitter_newad_start_time),
                                moment(state.twitter_newad_end_time),
                            ]}
                            onChange={(e) => {
                                console.info("e", e);
                                const twitter_newad_start_time = e[0].format();
                                const twitter_newad_end_time = e[1].format();
                                props.onSetState({
                                    twitter_newad_start_time,
                                    twitter_newad_end_time,
                                });
                            }}
                            disabledDate={(current) =>
                                current && current < moment().endOf("day")
                            }
                            showTime={{
                                hideDisabledOptions: true,
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    state.twitter_newad_overwrite_dailybudget
                                }
                                onChange={(e) =>
                                    props.onSetState({
                                        twitter_newad_overwrite_dailybudget:
                                            e.target.checked,
                                    })
                                }
                            >
                                Daily budget
                            </Checkbox>
                        }
                    >
                        <InputNumber
                            disabled={
                                !state.twitter_newad_overwrite_dailybudget
                            }
                            defaultValue={state.twitter_newad_dailybudget}
                            min={0}
                            // max={100}
                            formatter={(value) => `$ ${value / 100}`}
                            parser={(value) =>
                                Number(value.replace("$", "")) * 100
                            }
                            onChange={(e) => {
                                props.onSetState({
                                    twitter_newad_dailybudget: e,
                                });
                                console.info("e", e);
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Objective">
                        {getFieldDecorator("twitter_newad_objectivetype", {
                            initialValue: state.twitter_newad_objectivetype
                                ? state.twitter_newad_objectivetype
                                : null,
                            onChange: (e) =>
                                props.onSetState({
                                    twitter_newad_objectivetype: e,
                                }),
                        })(
                            <Select style={{ width: 200 }}>
                                <Option value="FOLLOWERS">FOLLOWERS</Option>
                                <Option value="ENGAGEMENTS">ENGAGEMENTS</Option>
                                <Option value="REACH">REACH</Option>
                                <Option value="VIDEO_VIEWS">VIDEO_VIEWS</Option>
                                <Option value="AWARENESS">AWARENESS</Option>
                                <Option value="PREROLL_VIEWS">
                                    PREROLL_VIEWS
                                </Option>
                                <Option value="WEBSITE_CLICKS">
                                    WEBSITE_CLICKS
                                </Option>
                                <Option value="APP_ENGAGEMENTS">
                                    APP_ENGAGEMENTS
                                </Option>
                                <Option value="APP_INSTALLS">
                                    APP_INSTALLS
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="placements">
                        {getFieldDecorator("twitter_newad_placements", {
                            initialValue: state.twitter_newad_placements
                                ? state.twitter_newad_placements
                                : null,
                            onChange: (e) =>
                                props.onSetState({
                                    twitter_newad_placements: e,
                                }),
                        })(
                            <Select style={{ width: 200 }}>
                                <Option
                                    value="ALL_ON_TWITTER"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("ALL_ON_TWITTER")}
                                >
                                    ALL_ON_TWITTER
                                </Option>
                                <Option
                                    value="PUBLISHER_NETWORK"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("PUBLISHER_NETWORK")}
                                >
                                    PUBLISHER_NETWORK
                                </Option>
                                <Option
                                    value="TWITTER_PROFILE"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("TWITTER_PROFILE")}
                                >
                                    TWITTER_PROFILE
                                </Option>
                                <Option
                                    value="TWITTER_SEARCH"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("TWITTER_SEARCH")}
                                >
                                    TWITTER_SEARCH
                                </Option>
                                <Option
                                    value="TWITTER_TIMELINE"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("TWITTER_TIMELINE")}
                                >
                                    TWITTER_TIMELINE
                                </Option>
                                <Option
                                    value="TAP_BANNER"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("TAP_BANNER")}
                                >
                                    TAP_BANNER
                                </Option>
                                <Option
                                    value="TAP_FULL"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("TAP_FULL")}
                                >
                                    TAP_FULL
                                </Option>
                                <Option
                                    value="TAP_FULL_LANDSCAPE"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("TAP_FULL_LANDSCAPE")}
                                >
                                    TAP_FULL_LANDSCAPE
                                </Option>
                                <Option
                                    value="TAP_NATIVE"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("TAP_NATIVE")}
                                >
                                    TAP_NATIVE
                                </Option>
                                <Option
                                    value="TAP_MRECT"
                                    disabled={disableTable[
                                        state.twitter_newad_objectivetype
                                    ].includes("TAP_MRECT")}
                                >
                                    TAP_MRECT
                                </Option>
                            </Select>
                        )}
                    </Form.Item>
                    {/* <Form.Item label="productType">
                        {getFieldDecorator("twitter_newad_producttype", {
                            initialValue: state.twitter_newad_producttype
                                ? state.twitter_newad_producttype
                                : null,
                            onChange: (e) =>
                                props.onSetState({
                                    twitter_newad_producttype: e,
                                }),
                        })(
                            <Select style={{ width: 200 }}>
                                <Option value="PROMOTED_TWEETS">
                                    PROMOTED_TWEETS
                                </Option>
                                <Option value="...">...</Option>
                            </Select>
                        )}
                    </Form.Item> */}
                    {/* <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    state.twitter_newad_overwrite_bidamount
                                }
                                onChange={(e) =>
                                    props.onSetState({
                                        twitter_newad_overwrite_bidamount:
                                            e.target.checked,
                                    })
                                }
                            >
                                Bid Amount
                            </Checkbox>
                        }
                    >
                        <InputNumber
                            disabled={!state.twitter_newad_overwrite_bidamount}
                            defaultValue={state.twitter_newad_bidamount}
                            min={0}
                            // max={100}
                            formatter={(value) => `$ ${value / 100}`}
                            parser={(value) =>
                                Number(value.replace("$", "")) * 100
                            }
                            onChange={(e) => {
                                props.onSetState({
                                    twitter_newad_bidamount: e,
                                });
                                console.info("e", e);
                            }}
                        />
                    </Form.Item> */}
                    <TwitterTargeting
                        account_key={state.newad_twitter_accounts[0]}
                        store={store}
                        updateTargeting={props.onupdateTwitterTargeting}
                    />
                </Fragment>
            ) : (
                <Alert
                    type="info"
                    message="Please select at least one account"
                    style={{ border: 0 }}
                />
            )}
        </Fragment>
    );
};

export default AdsListModalFormPanelTwitter;
