import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import SocialSeparator from "../components/SocialSeparator";
import LinkedIn from "linkedin-login-for-react";
import config from "../config";
import { social_getCookie } from "../functions";
import { Checkbox, Icon } from 'antd';

class LinkedinHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            step:0,
            organizations: [
                
                    // {
                    //     checked: true,
                    //     vanityName: "uhub-app",
                    //     localizedName: "Uhub.app",
                    //     website: {
                    //         localized: {
                    //             en_US: "https://www.uhub.app"
                    //         },
                    //         preferredLocale: {
                    //             country: "US",
                    //             language: "en"
                    //         }
                    //     },
                    //     groups: [],
                    //     versionTag: "3917231791",
                    //     organizationType: "PRIVATELY_HELD",
                    //     defaultLocale: {
                    //         country: "US",
                    //         language: "en"
                    //     },
                    //     alternativeNames: [],
                    //     specialties: [],
                    //     staffCountRange: "SIZE_2_TO_10",
                    //     localizedSpecialties: [],
                    //     industries: [
                    //         "urn:li:industry:6"
                    //     ],
                    //     name: {
                    //         localized: {
                    //             en_US: "Uhub.app"
                    //         },
                    //         preferredLocale: {
                    //             country: "US",
                    //             language: "en"
                    //         }
                    //     },
                    //     primaryOrganizationType: "NONE",
                    //     locations: [],
                    //     id: 65675606,
                    //     $URN: "urn:li:organization:65675606",
                    //     localizedWebsite: "https://www.uhub.app",
                    //     logoV2: {
                    //         cropped: "urn:li:digitalmediaAsset:C4D0BAQH_au_wUJykHQ",
                    //         original: "urn:li:digitalmediaAsset:C4D0BAQH_au_wUJykHQ",
                    //         cropInfo: {
                    //             x: 0,
                    //             width: 0,
                    //             y: 0,
                    //             height: 0
                    //         }
                    //     }
                    // },
                    // {
                    //     checked: true,
                    //     vanityName: "uhub-app",
                    //     localizedName: "Uhub.app",
                    //     website: {
                    //         localized: {
                    //             en_US: "https://www.uhub.app"
                    //         },
                    //         preferredLocale: {
                    //             country: "US",
                    //             language: "en"
                    //         }
                    //     },
                    //     groups: [],
                    //     versionTag: "3917231791",
                    //     organizationType: "PRIVATELY_HELD",
                    //     defaultLocale: {
                    //         country: "US",
                    //         language: "en"
                    //     },
                    //     alternativeNames: [],
                    //     specialties: [],
                    //     staffCountRange: "SIZE_2_TO_10",
                    //     localizedSpecialties: [],
                    //     industries: [
                    //         "urn:li:industry:6"
                    //     ],
                    //     name: {
                    //         localized: {
                    //             en_US: "Uhub.app"
                    //         },
                    //         preferredLocale: {
                    //             country: "US",
                    //             language: "en"
                    //         }
                    //     },
                    //     primaryOrganizationType: "NONE",
                    //     locations: [],
                    //     id: 65675606,
                    //     $URN: "urn:li:organization:65675606",
                    //     localizedWebsite: "https://www.uhub.app",
                    //     logoV2: {
                    //         cropped: "urn:li:digitalmediaAsset:C4D0BAQH_au_wUJykHQ",
                    //         original: "urn:li:digitalmediaAsset:C4D0BAQH_au_wUJykHQ",
                    //         cropInfo: {
                    //             x: 0,
                    //             width: 0,
                    //             y: 0,
                    //             height: 0
                    //         }
                    //     }
                    // },
                    // {
                    //     checked: true,
                    //     vanityName: "uhub-app",
                    //     localizedName: "Uhub.app",
                    //     website: {
                    //         localized: {
                    //             en_US: "https://www.uhub.app"
                    //         },
                    //         preferredLocale: {
                    //             country: "US",
                    //             language: "en"
                    //         }
                    //     },
                    //     groups: [],
                    //     versionTag: "3917231791",
                    //     organizationType: "PRIVATELY_HELD",
                    //     defaultLocale: {
                    //         country: "US",
                    //         language: "en"
                    //     },
                    //     alternativeNames: [],
                    //     specialties: [],
                    //     staffCountRange: "SIZE_2_TO_10",
                    //     localizedSpecialties: [],
                    //     industries: [
                    //         "urn:li:industry:6"
                    //     ],
                    //     name: {
                    //         localized: {
                    //             en_US: "Uhub.app"
                    //         },
                    //         preferredLocale: {
                    //             country: "US",
                    //             language: "en"
                    //         }
                    //     },
                    //     primaryOrganizationType: "NONE",
                    //     locations: [],
                    //     id: 65675606,
                    //     $URN: "urn:li:organization:65675606",
                    //     localizedWebsite: "https://www.uhub.app",
                    //     logoV2: {
                    //         cropped: "urn:li:digitalmediaAsset:C4D0BAQH_au_wUJykHQ",
                    //         original: "urn:li:digitalmediaAsset:C4D0BAQH_au_wUJykHQ",
                    //         cropInfo: {
                    //             x: 0,
                    //             width: 0,
                    //             y: 0,
                    //             height: 0
                    //         }
                    //     }
                    // }
                    
            ]
        };
    }

    callbackLinkedIn = async (error, code, redirectUri) => {
        console.info(
            "callbackLinkedIn error",
            error,
            "code",
            code,
            "redirectUri",
            redirectUri
        );
        if (error) {
            // signin failed
        } else {
            // Obtain authorization token from linkedin api
            // see https://developer.linkedin.com/docs/oauth2 for more info

            const account_key = social_getCookie("link_linkedin_account_key");
            //const bearer = social_getCookie("link_linkedin_bearer");

            // set API headers
            // this.props.store.appApi.setHeaders({
            //     Authorization: `Bearer ${bearer}`,
            // });

            const linkeinAuth = await this.props.store.authLinkedinAccount(
                account_key,
                code
            );
            console.info("linkeinAuth", linkeinAuth);

            if (linkeinAuth.profileInfo) {
                console.log("We have answer");
                this.setState({ authenticated: true, step: 1 });
            }

            // load the organizations
            const linkeinOrganizations = await this.props.store.getLinkedinPagesForAccount(
                account_key
            );
            linkeinOrganizations.map(org => {org.checked = true;});
            console.info("linkeinOrganizations", linkeinOrganizations);
            this.setState({ organizations: linkeinOrganizations});
        }
    };

    onChange = (e, orgIndex) => {
        console.log(`checked = ${e.target.checked}`);
        let { organizations } = this.state;
        organizations[orgIndex].checked = e.target.checked;
        this.setState({organizations});
    }

    onSaveOrganizations = async (e) => {
        let { organizations } = this.state;

        const account_key = social_getCookie("link_linkedin_account_key");

        await Promise.all(organizations.map(async (org) => {
            await this.props.store.addLinkedinOrganizationAccount(account_key, {
                id: org.id,
                vanityName: org.vanityName,
                localizedName: org.localizedName,
                localizedWebsite: org.localizedWebsite, 
                organizationType: org.organizationType,
                staffCountRange: org.staffCountRange, 
                URN: org.$URN
            });
        }));

        this.setState({step: 2});
    }

    render() {
        const account_key = social_getCookie("link_linkedin_account_key");
        console.log("account_key=" + account_key);

        return (
            <Fragment>
                <div style={{ padding: 30 }}>
                    <h3>Link Linkedin profile for LinkedinHandler</h3>
                    <div>
                        Some explanation about how to link the LinkedIn profile
                        with the current account. <br />
                        <br />
                        <SocialSeparator />
                    </div>
                    {!this.state.authenticated && this.state.step === 0 ? (
                        <Fragment>
                            Please click the button below in order to authorize
                            Uhub to access the LinkedIn profile.
                            <br />
                            <br />
                            <br />
                            <div
                                className={"LinkedinButton"}
                                style={{ textAlign: "center" }}
                            >
                                <LinkedIn
                                    clientId={config.linkedin.clientId}
                                    callback={this.callbackLinkedIn}
                                    //className={styles.linkedin}
                                    scope={config.linkedin.scopes}
                                    text="Login With LinkedIn"
                                />
                            </div>
                        </Fragment>
                    ):null}

                    {this.state.authenticated && this.state.step === 1 ? (
                        <Fragment>
                            <h3>Please select the pages you want to include:</h3>
                            {this.state.organizations && this.state.organizations.map((org, orgIndex) => (
                               
                                <Checkbox key={org.id} onChange={(e) => this.onChange(e, orgIndex)} checked={org.checked} style={{display: "flex", padding:"8px 8px 6px 8px", margin: 0, alignItems: "flex-start",
                                lineHeight: 1.2, backgroundColor: orgIndex % 2 === 0 ? "#f6f6f6": null}}>
                                    <div style={{display: "flex"}}>
                                        <div className={"social_iconCircle_linkedin"} style={{                  
                                            width: 18,
                                            height: 18,
                                            marginRight: 5,
                                            marginTop: -2,
                                            
                                            }} />
                                        <div>
                                            <strong>{org.localizedName && org.localizedName}</strong><br />
                                            {org.localizedWebsite && org.localizedWebsite}
                                        </div>
                                    </div>
                                </Checkbox>
                          
                            ))}
                            {this.state.organizations.length === 0 ? (
                                <Fragment>
                                    <Icon type="loading" style={{marginRight: 10}}/> Processing ...
                                </Fragment>
                            ):null}
                            <br />
                            <br />
                            <br />
                            <div
                                className={"LinkedinButton"}
                                style={{ textAlign: "center", opacity: this.state.organizations.filter(o=>o.checked===true).length===0?0.5:1}}
                            >
                                <button onClick={this.onSaveOrganizations} disabled={this.state.organizations.filter(o=>o.checked===true).length===0}>Save Checked</button>
                            </div>
                            <br />
                            <br />
                            <br />
                            <SocialSeparator>Note</SocialSeparator>
                            <p>You can close this window without adding pages.<br/>You will still be able to post using your personal profile</p>
                        </Fragment>
                    ):null}

                    {this.state.authenticated && this.state.step === 2 ? (
                        <Fragment>
                            <h3>Thank you!</h3>
                            <h2>Your authentication went successfully.</h2>
                            <p>You may close this window/tab now.</p>
                        </Fragment>
                    ):null}

                   
                </div>
            </Fragment>
        );
    }
}

export default observer(LinkedinHandler);
