const tooltips = {
    // SETTINGS
    SETTINGS: {
        mouseEnterDelay: 0.1,
    },

    // DASHBOARD
    DASHBOARD: {
        RIGHT_CREATEACCOUNT: `Account is the profile where the social channels it owns are located.  It contains all the presets to be utilized to customize a Primary Post or a Master Ad for such profile.  \
                                    
      Click here to create new Account.`,
        RIGHT_CREATECAMPAIGN: `Campaign is a collection of one or more Primary Posts or Master Ads.  It helps organize or schedule your posts based on common purpose or period.  
                                    
      Click here to create new Campaign.`,
        RIGHT_CREATEMASTERPOST: `Primary Post is a template on which one or more individual account posts will be based. Each individual account post will be personalized in accordance with the presets found in the in each account's settings.
                                        
      Click here to create new Primary Post.`,
        RIGHT_CREATEMASTERAD: `Master Ad is a template on which one or more individual account paid posts (ads, boost, etc.) will be based. Each individual account paid post will be personalized in accordance with the presets found in the in each account's settings.
                                    
      Click here to create new Master Ad.`,
        RIGHT_CREATEREPORT: `Report will show you the performance of a Campaign, Primary Post or a Master Ad, or a certain combinations of these, for a specific time period.
                                    
      Click here to generate new Report.`,
        TOP_GRAPHDATA: "Showing the graph data",
        TOP_TODAYSMASTERPOSTS: "Click to open the primary post",
        BOTTOM_UPCOMING: "Click to open the primary post",
        BOTTOM_INBOX: "Click on the notification to open Inbox",
    },
    // ACCOUNTS
    ACCOUNTS: {
        NEWBUTTON: "Add new account",
        LIST_EDIT: "Edit account",
        LIST_DELETE: "Delete account",
        LIST_CHANNELS: "Channels",
        LIST_REPORT: "Run a report on the account",
        LIST_QUEUE: "Queue of the account",
        CHANNELS_LIST_OPEN: "Open channel",
        CHANNELS_LIST_EDIT: "Edit channel",
        CHANNELS_LIST_REPORT: "Run a report on the channel",
        CHANNELS_LIST_UNLINK: "Unlink the channel",
        CHANNELS_LIST_TEST: "Test the channel",
        CHANNELS_LINKBUTTON_FACEBOOK: "Link Facebook/Instagram pages",
        CHANNELS_LINKBUTTON_TWITTER: "Link Twitter account",
        CHANNELS_LINKBUTTON_LINKEDIN: "Link Linkedin account",
        CHANNELS_LINKBUTTON_YOUTUBE: "Link Youtube account",
        CHANNEL_REFRESHBUTTON: "Reload channel data",
    },
    // TAGS
    TAGS: {
        NEWBUTTON: "Add new tag to account",
        LIST_EDIT: "Edit tag",
        LIST_DELETE: "Delete tag",
    },
    // MASTERPOSTS
    MASTERPOSTS: {
        NEWBUTTON: "Add new primary post",
        LIST_EDIT: "Edit primary post",
        LIST_DELETE: "Delete primary post",
        LIST_CANNOT_DELETE:
            "This primary post is used and therefore cannot be deleted",
        LIST_TARGETS: "Targets in primary post",
        LIST_REPORT: "Run report on primary post",
        LIST_INFO: "Information",
        LIST_BOOST: "Boost post",
        TARGETS_NEWBUTTON: "Add new target to the primary post",
        TARGETS_LIST_EDIT: "Edit target",
        TARGETS_LIST_DELETE: "Delete target",
        TARGETS_LIST_CUSTOM: "There is some custom selection on the rules",
        TARGETS_LIST_EXCLUDE: "There is some exclusion set on the rules",
        TARGETS_LIST_INCLUDE: "There is some inclusion set on the rules",
    },
    // CATEGORIES
    CATEGORIES: {
        NEWBUTTON: "Add new category",
        LIST_EDIT: "Edit category",
        LIST_DELETE: "Delete category",
        LIST_MASTERPOSTS: "Show primary posts",
    },
    // CAMPAIGNS
    CAMPAIGNS: {
        NEWBUTTON: "Add new campaign",
        LIST_EDIT: "Edit campaign",
        LIST_DELETE: "Delete campaign",
        LIST_PERFORMANCE: "Show performance",
    },
    // COMPETITORS
    COMPETITORS: {
        NEWBUTTON: "Add new competitor",
        LIST_EDIT: "Edit competitor",
        LIST_DELETE: "Delete competitor",
    },
    // ADS
    ADS: {
        NEWBUTTON: "Add new master ad/boost",
    },
    // SCHEDULE
    SCHEDULE: {
        FILTER_SOCIAL: "Filter social networks",
        FILTER_TAGS: "Filter tags",
        FILTER_MASTERPOSTS: "Filter primary posts",
        FILTER_CATEGORIES: "Filter categories",
    },
    // SCHEDULE_LIST
    SCHEDULE_LIST: {
        NEWBUTTON: "Add new schedule",
        LIST_EDIT: "Edit chedule",
        LIST_DELETE: "Delete schedule",
    },
    // LIBRARY
    LIBRARY: {
        NEWBUTTON: "Add new library item",
    },
    // INSIGHTS
    INSIGHTS: {
        LIST_POSTS: "See posts for this primary post",
        LIST_BOOST: "Boost this primary post",
    },
    // USERS
    USERS: {
        NEWBUTTON: "Add new user",
        PROVIDERS_EMAIL: "Provider email/password",
        PROVIDERS_FACEBOOK: "Provider Facebook",
    },
    // AUDITORS
    AUDITORS: {
        NEWBUTTON: "Add new listener",
        LIST_MONITOR: "Monitor listener",
        LIST_EDIT: "Edit listener",
        LIST_DELETE: "Delete listener",
    },
};

export default tooltips;
