import React, { Component, Fragment } from "react";
import moment from "moment";
import { observer } from "mobx-react";
import { Form, Modal, Icon, Input, Tabs } from "antd";
import LibraryPickup from "../Library/LibraryPickup";
import names from "./../../names";

const { TabPane } = Tabs;

/* Modal Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: false,
                thumbnail_library:
                    props.data.video_thumbnail &&
                    props.data.video_thumbnail.thumbnail_library
                        ? props.data.video_thumbnail.thumbnail_library
                        : null,
                thumbnail_offset:
                    props.data.video_thumbnail &&
                    props.data.video_thumbnail.thumbnail_offset
                        ? props.data.video_thumbnail.thumbnail_offset
                        : null,
            };
        }

        componentDidMount = async () => {
            console.info("componentDidMount this.props", this.props);
        };

        handleTabsChange = (key) => {
            console.info("handleTabsChange key", key);
        };

        handleChangeLibrarySelected = (_selected) => {
            console.info("handleChangeLibrarySelected _selected", _selected);
            this.setState({
                thumbnail_library:
                    _selected && _selected[0] ? _selected[0] : null,
            });
        };

        render() {
            const { data } = this.props;

            const antIcon = (
                <Icon type="loading" style={{ fontSize: 24 }} spin />
            );

            return this.state.loading ? (
                antIcon
            ) : (
                <Fragment>
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}

                    <Tabs defaultActiveKey="1" onChange={this.handleTabsChange}>
                        <TabPane tab="Image (Facebook)" key="1">
                            {/* thumbnail_library = {typeof this.state.thumbnail_library}
                            <br /> */}
                            <LibraryPickup
                                store={this.props.store}
                                // disabled={
                                //     typeof t.template_file !=
                                //         "undefined" &&
                                //     t.template_file.name
                                // }
                                // multiple
                                onSelectedChange={(selected) =>
                                    this.handleChangeLibrarySelected(selected)
                                }
                                selected={
                                    this.state.thumbnail_library
                                        ? !Array.isArray(
                                              this.state.thumbnail_library
                                          )
                                            ? [this.state.thumbnail_library]
                                            : this.state.thumbnail_library
                                        : []
                                }
                            />
                        </TabPane>
                        <TabPane tab="Frame from the Video (Instagram)" key="2">
                            <Form.Item label="Seconds in the video">
                                <Input
                                    value={this.state.thumbnail_offset}
                                    onChange={(e) => {
                                        this.setState({
                                            thumbnail_offset: e.target.value,
                                        });
                                    }}
                                    style={{ width: 100 }}
                                    placeholder="seconds in the video"
                                />
                            </Form.Item>
                        </TabPane>
                    </Tabs>
                </Fragment>
            );
        }
    }
);

/* Wrapper component */
class MasterpostsListModalVideoThumbnail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "masterposts_modal_videothumbnail",
            (_action, _payload) => {
                if (_action === "show") {
                    console.info("_payload", _payload);
                    const { curTarget, _tIndex } = _payload;
                    console.info("curTarget", curTarget, "_tIndex", _tIndex);
                    this.setState({ target: curTarget, tIndex: _tIndex });
                }
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener(
            "masterposts_modal_videothumbnail"
        );
    };

    onModalEdit = () => {
        console.log("onModalCreate this.onModalEdit", this.formRef);

        if (this.formRef) {
            let formState = Object.assign({}, this.formRef.state);
            console.log("formState", formState);

            formState.tIndex = this.state.tIndex;

            if (
                this.props.handleVideoCoverUpdate &&
                typeof this.props.handleVideoCoverUpdate === "function"
            ) {
                this.props.handleVideoCoverUpdate(formState);
                this.onModalCancel(); // close the popup
            }
        }

        //   const { form } = this.formRef.props;
        //   form.validateFields((err, values) => {
        //       if (err) {
        //           return;
        //       }
        //       if (values.scheduled_date)
        //           values.scheduled_date = moment(values.scheduled_date).valueOf();
        //       console.log("Received values of form: ", values);
        //       console.log("masterpost key: ", this.props.data.key);
        //       this.props.store.updateMasterpost(this.props.data.key, values);
        //       form.resetFields();
        //       this.setState({ modal_visible: false });
        //   });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "masterposts_modal_videothumbnail",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data, store } = this.props;
        const { target } = this.state;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={
                            names.get("MASTERPOST", false, true) +
                            " Video Thumbnail"
                        }
                        okText={"Save"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalEdit} //onApply
                        maskClosable={false}
                        // footer={[
                        //     <Button
                        //         key="submit"
                        //         type="primary"
                        //         onClick={this.onModalCancel}
                        //     >
                        //         Close
                        //     </Button>,
                        // ]}
                    >
                        <ModalForm
                            store={store}
                            data={target}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(MasterpostsListModalVideoThumbnail);
