import React, { Component, Fragment } from "react";

import config from "./../../config";
import tooltips from "../../tooltips";

import { observer } from "mobx-react";
import prompt from "./../antd-prompt";

import {
    Empty,
    List,
    Button,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Switch,
    Icon,
    Checkbox,
} from "antd";

import UsersListModal from "./UsersListModal";
import Bulk from "./../Bulk";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";

const { Title } = Typography;
const { Search } = Input;

class UsersList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            //edit_data: {},
            pagination_currentPage: 1,
            processingKey: null,
            disablingKey: null,
            sortby: "name",
            sortasc: true,
        };
        this.switchRef = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("users");
    }

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("users_modal");
    }

    componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);
        // Bulk listing
        this.listBulk = new Bulk({
            name: "usersList",
            list: this.props.store.getAllUsers.map((element) => element.key),
            component: this,
            actions: [
                {
                    key: "enable",
                    title: "Enable",
                    icon: <Icon type="check" />,
                    function: async (_key) => {
                        console.log("Enable _key=" + _key);
                        //await this.props.store.deleteCompetitor({ key: _key });
                    },
                    confirm: "Do you want to enable the selected elements?",
                },
                {
                    key: "disable",
                    title: "Disable Selected",
                    icon: <Icon type="stop" />,
                    function: async (_key) => {
                        console.log("Disable _key=" + _key);
                        //await this.props.store.deleteCompetitor({ key: _key });
                    },
                    confirm: "Do you want to disable the selected elements?",
                },
                {
                    key: "makeadmin",
                    title: "Upgrade to Admin",
                    icon: <Icon type="usergroup-add" />,
                    function: async (_key) => {
                        console.log("makeadmin _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                    confirm:
                        "Do you want to upgrade the selected users to admins?",
                    //combined: true
                },
                {
                    key: "makeuser",
                    title: "Downgrade to User",
                    icon: <Icon type="usergroup-delete" />,
                    function: async (_key) => {
                        console.log("makeuser _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                    confirm:
                        "Do you want to downgrade the selected admins to users?",
                    //combined: true
                },
            ],
            callback: () => {
                console.log("READY");
            },
        });
    }

    showModal = () => {
        console.log("showModal");

        this.props.store.showModalByKey("users_modal");
    };

    handleDeleteUser = (_evt, _item) => {
        console.info("handleDeleteUser _evt", _evt, "_item", _item);

        //   this.props.store.deleteTag(_item.tag).then(() => {
        //       this.setState({ tags: this.props.store.getAllTags });
        //   });
    };

    handleEdit = async (_evt, _tag) => {
        console.info("_tag", _tag);

        const old_tag = _tag.tag;

        try {
            const new_tag = await prompt({
                title: "Edit Tag",
                placeholder: "Tag name",
                rules: [
                    // check this link for more help: https://ant.design/components/form/#Validation-Rules
                    {
                        required: true,
                        message: "You must enter name",
                    },
                ],
                initialValue: _tag.tag,
                modalProps: {
                    width: "50%",
                },
            });
            this.props.store
                .editTag(old_tag, new_tag)
                .then(() => {
                    // update the tags in this state
                    this.setState({
                        tags: this.props.store.getAllTags,
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
            //alert("old_category=" + old_category + ",new_name=" + new_name);
        } catch (e) {
            console.warn(e);
            //message.error("Please enter name");
        }
    };

    showReport = (_evt, _item) => {
        console.info("showReport _evt", _evt, "_item", _item);

        this.props.history.push(`/dashboard/tags/${_item.tag}/report`);
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        console.info("sortby", sortby, "sortasc", sortasc);
        this.setState({ sortby: _sorting, sortasc });
    };

    getListingDataSource = () => {
        console.info(
            "this.props.store.getAllUsers",
            this.props.store.getAllUsers
        );
        let results = this.props.store.getAllUsers.filter((u) => {
            return (
                (u.displayName &&
                    u.displayName
                        .toUpperCase()
                        .indexOf(this.state.filter.toUpperCase()) !== -1) ||
                u.email
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1 ||
                u.level
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1
            );
        });

        // --- sorting ---
        results.sort((a, b) => {
            //console.info("results.sort (this.state.sortby)", this.state.sortby);

            if (
                typeof a.displayName === "undefined" ||
                typeof b.displayName === "undefined"
            )
                return 0;

            var nameA = a.displayName.toLowerCase(),
                nameB = b.displayName.toLowerCase();
            var levelA = a.level.toLowerCase(),
                levelB = b.level.toLowerCase();
            console.log("levelA = " + levelA);
            switch (this.state.sortby) {
                case "name":
                    if (this.state.sortasc === true) {
                        if (nameA <= nameB) return -1;
                        if (nameA > nameB) return 1;
                    } else {
                        if (nameA <= nameB) return 1;
                        if (nameA > nameB) return -1;
                    }
                    break;
                case "level":
                    if (this.state.sortasc === true) {
                        if (levelA <= levelB) return -1;
                        if (levelA > levelB) return 1;
                    } else {
                        if (levelA <= levelB) return 1;
                        if (levelA > levelB) return -1;
                    }
                    break;

                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting

        // // sort by access
        // results.sort(function (a, b) {
        //     const textA = a.level.toUpperCase();
        //     const textB = b.level.toUpperCase();
        //     return textA < textB ? -1 : textA > textB ? 1 : 0;
        // });

        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    handleLevelChange = (checked, e, item) => {
        console.info(
            "handleLevelChange checked",
            checked,
            "e",
            e,
            "item",
            item
        );

        this.setState({ processingKey: item.key });
        this.props.store
            .setUserLevel(item.email, checked ? "admin" : "user")
            .then((result) => {
                console.info("result", result);
                this.setState({ processingKey: null });
            })
            .catch((error) => {
                //console.warn(error);
                this.setState({}); // force rerendering
                this.setState({ processingKey: null });
            });
    };

    handleDisabledChange = (checked, e, item) => {
        console.info(
            "handleDisabledChange checked",
            checked,
            "e",
            e,
            "item",
            item
        );

        this.setState({ disablingKey: item.key });
        this.props.store
            .setUserDisabled(item.email, checked ? false : true)
            .then((result) => {
                console.info("result", result);
                this.setState({ disablingKey: null });
            })
            .catch((error) => {
                //console.warn(error);
                this.setState({}); // force rerendering
                this.setState({ disablingKey: null });
            });
    };

    render() {
        //console.info("UsersList Render", this.props.store.getAllTags);

        const pagesCount =
            this.props.store.getAllUsers.length / config.PAGINATION_PERPAGE;
        // console.log("pagesCount = " + pagesCount);

        const header = (
            <SocialListHeader
                title="Users"
                newButtonClickHandle={this.showModal}
                newButtonText="New user"
                newButtonTooltip={tooltips.USERS.NEWBUTTON}
                bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );

        // let header = (
        //     <Row
        //         type="flex"
        //         justify="space-between"
        //         style={{ marginBottom: 10 }}
        //     >
        //         <Col>
        //             <Title level={3} style={{ float: "left", paddingRight: 5 }}>
        //                 Users
        //             </Title>
        //             {/* <Badge
        //                 count={this.props.store.getAllUsers.length}
        //                 style={{ backgroundColor: "#1890ff" }}
        //             /> */}
        //         </Col>
        //         <Col>
        //             {
        //                 <Tooltip
        //                     title={tooltips.USERS.NEWBUTTON}
        //                     mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
        //                 >
        //                     <Button
        //                         type="primary"
        //                         // icon="plus"
        //                         size="small"
        //                         onClick={this.showModal}
        //                         style={{
        //                             marginLeft: 20,
        //                             marginTop: 5,
        //                             fontSize: 12,
        //                         }}
        //                     >
        //                         New user
        //                     </Button>
        //                 </Tooltip>
        //             }
        //         </Col>
        //     </Row>
        // );

        let body =
            this.props.store.getAllUsers.length === 0 ? (
                <Empty />
            ) : (
                <List
                    size="small"
                    className="UsersList"
                    header={
                        <Fragment>
                            {/* <Row type="flex" justify="space-between">
                                <Col
                                    style={{
                                        minWidth: 25,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                        style={{ marginRight: 10 }}
                                    />
                                    {this.listBulk &&
                                        this.listBulk.renderOptionsAsList()}
                                </Col>
                                <Col>
                                    <Search
                                        placeholder="filter"
                                        onSearch={this.onSearchChange}
                                        onChange={(e) =>
                                            this.onSearchChange(e.target.value)
                                        }
                                        style={{ width: 200 }}
                                    />
                                </Col>
                            </Row> */}
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Col style={{ width: 25, lineHeight: 1.2 }}>
                                    {/* <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                    /> */}
                                </Col>
                                <Col style={{ flex: "1 1" }}>
                                    <SocialListColumn
                                        sortkey="name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        User name
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1, marginRight: 90 }}>
                                    {/* <SocialListColumn
                                        sortkey="level"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Level
                                    </SocialListColumn> */}
                                    Level
                                </Col>
                                <Col>Tools</Col>
                            </Row>
                        </Fragment>
                    }
                    footer={
                        pagesCount > 1 && this.state.filter.length === 0 ? (
                            <div style={{ textAlign: "right" }}>
                                <Pagination
                                    size="small"
                                    defaultCurrent={1}
                                    total={this.props.store.getAllUsers.length}
                                    pageSize={config.PAGINATION_PERPAGE}
                                    current={this.state.pagination_currentPage}
                                    onChange={this.paginationSetPage}
                                />
                            </div>
                        ) : null
                    }
                    bordered
                    dataSource={this.getListingDataSource()}
                    renderItem={(item, itemIndex) => (
                        <List.Item
                            key={itemIndex}
                            className={
                                this.listBulk &&
                                this.listBulk.isChecked(item.key)
                                    ? this.listBulk.getCheckedLength() === 1
                                        ? "social_Selected_withTools"
                                        : "social_Selected"
                                    : null
                            }
                        >
                            <List.Item.Meta
                                title={
                                    <Fragment>
                                        <Checkbox
                                            onChange={(e) =>
                                                this.listBulk.handleCheck(
                                                    item.key
                                                )
                                            }
                                            checked={
                                                this.listBulk
                                                    ? this.listBulk.isChecked(
                                                          item.key
                                                      )
                                                    : false
                                            }
                                            style={{ marginRight: 10 }}
                                        />
                                        {item.providerData.provider ===
                                        "password" ? (
                                            <Tooltip
                                                title={
                                                    tooltips.USERS
                                                        .PROVIDERS_EMAIL
                                                }
                                                mouseEnterDelay={
                                                    tooltips.SETTINGS
                                                        .mouseEnterDelay
                                                }
                                            >
                                                <Icon
                                                    type="unlock"
                                                    theme="filled"
                                                    style={{ marginRight: 10 }}
                                                />
                                            </Tooltip>
                                        ) : null}
                                        {item.providerData.provider ===
                                        "facebook.com" ? (
                                            <Tooltip
                                                title={
                                                    tooltips.USERS
                                                        .PROVIDERS_FACEBOOK
                                                }
                                                mouseEnterDelay={
                                                    tooltips.SETTINGS
                                                        .mouseEnterDelay
                                                }
                                            >
                                                <Icon
                                                    type="facebook"
                                                    theme="filled"
                                                    style={{
                                                        marginRight: 10,
                                                        color: this.props.store.getTagColorForType(
                                                            "facebook"
                                                        ),
                                                    }}
                                                />
                                            </Tooltip>
                                        ) : null}
                                        {item.displayName
                                            ? item.displayName +
                                              "\n(" +
                                              item.email +
                                              ")"
                                            : item.email}
                                    </Fragment>
                                }
                            />
                            <div style={{ flexGrow: 1, textAling: "left" }}>
                                <Switch
                                    checkedChildren="Admin"
                                    unCheckedChildren="User"
                                    checked={item.level === "admin"}
                                    style={{ width: 70 }}
                                    loading={
                                        this.state.processingKey === item.key
                                    }
                                    onChange={(checked, e) =>
                                        this.handleLevelChange(checked, e, item)
                                    }
                                />
                            </div>
                            <div style={{ flexGrow: 0, textAling: "left" }}>
                                <Switch
                                    checkedChildren="Enabled"
                                    unCheckedChildren="Disabled"
                                    checked={item.disabled === false}
                                    style={{ width: 85 }}
                                    loading={
                                        this.state.disablingKey === item.key
                                    }
                                    onChange={(checked, e) =>
                                        this.handleDisabledChange(
                                            checked,
                                            e,
                                            item
                                        )
                                    }
                                />
                            </div>
                        </List.Item>
                    )}
                />
            );

        return (
            <Fragment>
                {header}
                {body}
                <UsersListModal store={this.props.store} />
            </Fragment>
        );
    }
}

export default observer(UsersList);
