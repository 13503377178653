import React, { Fragment } from "react";
import { Spin, Icon, Collapse, Row, Col, Table, Button } from "antd";

import scss_variables from "./../../_variables.scss";
// import SocialDataPresentor from "../SocialDataPresentor";
import moment from "moment";

// import { social_LANGUAGE_BY_LOCALE } from "../../functions";

const { Panel } = Collapse;

const GRID_GUTTER = 40;

const ReportResultAdMonthlyLinkedin = (props) => {
    if (!props.report_ad) return <Fragment>Processing ...</Fragment>;

    let ad = Object.assign({}, props.report_ad);
    console.info("ad before", ad);

    ad.targeting_text = [];
    // targeting text

    // if (ad.targeting_info.age) {
    //     ad.targeting_text.push(
    //         "Age: " + ad.targeting_info.age[0] + "-" + ad.targeting_info.age[1]
    //     );
    // }
    // if (ad.targeting_info.country) {
    //     ad.targeting_text.push(
    //         "Countries: " +
    //             ad.targeting_info.country
    //                 .map((country) => country.label)
    //                 .join(",")
    //     );
    // }
    // if (ad.targeting_info.gender) {
    //     ad.targeting_text.push(
    //         "Gender: " + ad.targeting_info.gender === "all"
    //             ? "All"
    //             : ad.targeting_info.gender === 1
    //             ? "Gender: Men"
    //             : "Gender: Women"
    //     );
    // }
    // if (ad.targeting_info.region) {
    //     ad.targeting_text.push(
    //         "Region: " +
    //             ad.targeting_info.region.map((region) => region.label).join(",")
    //     );
    // }
    // if (ad.targeting_info.city) {
    //     ad.targeting_text.push(
    //         "City: " +
    //             ad.targeting_info.city.map((city) => city.label).join(",")
    //     );
    // }
    // if (ad.targeting_info.language) {
    //     ad.targeting_text.push(
    //         "Languages: " +
    //             props.report_ad.targeting_info.language
    //                 .map((language) => language.label)
    //                 .join(",")
    //     );
    // }

    // Insights
    ad.insights = props.results_separated && props.results_separated.insights;

    const adsOverviewPanel_platform = ad.platform;

    const adsOverviewPanel_name = ad.name;

    const adsOverviewPanel_starttime = moment(ad.start_time).format("l");
    const adsOverviewPanel_endtime = moment(ad.end_time).format("l");

    console.info("ad after", ad);
    console.info("ADMONTHLY props", props);

    const adsOverviewPanel = (
        <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
            <Col span={6}>
                <label>Platform</label>
                <h3>
                    <Icon
                        type={adsOverviewPanel_platform}
                        style={{ marginRight: 5 }}
                    />
                    {adsOverviewPanel_platform}
                </h3>
            </Col>
            <Col span={6}>
                <label>Name</label>
                <h3>{adsOverviewPanel_name}</h3>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
                <label>Start date</label>
                <h3>{adsOverviewPanel_starttime}</h3>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
                <label>End date</label>
                <h3>{adsOverviewPanel_endtime}</h3>
            </Col>
        </Row>
    );

    // -----------------------------------------

    const targetingPanel_text = ad.targeting_text && ad.targeting_text;

    const targetingPanel = (
        <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
            <Col span={24}>
                {targetingPanel_text.map((targetLine, targetLineIndex) => {
                    return (
                        <Fragment key={targetLineIndex}>
                            {targetLine}
                            <br />
                        </Fragment>
                    );
                })}
            </Col>
        </Row>
    );

    // -----------------------------------------

    const adsBasicPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={5}>
                    <label>Impressions</label>
                    <h3>{ad.insights && ad.insights.impressions}</h3>
                </Col>
                <Col span={5}>
                    <label>Total Engagements</label>
                    <h3>{ad.insights && ad.insights.totalEngagements}</h3>
                </Col>
                <Col span={5}>
                    <label>Clicks</label>
                    <h3>{ad.insights && ad.insights.clicks}</h3>
                </Col>
                <Col span={4}>
                    <label>Spend</label>
                    <h3>
                        &#36;{" "}
                        {ad.insights &&
                            Number(ad.insights.costInUsd).toFixed(2)}
                    </h3>
                </Col>
                <Col span={5}>
                    <label>Company Page Clicks</label>
                    <h3>{ad.insights && ad.insights.companyPageClicks}</h3>
                </Col>
            </Row>
        </Fragment>
    );

    const adsClicksPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={4}>
                    <label>actionClicks</label>
                    <h3>{ad.insights && ad.insights.actionClicks}</h3>
                </Col>
                <Col span={4}>
                    <label>adUnitClicks</label>
                    <h3>{ad.insights && ad.insights.adUnitClicks}</h3>
                </Col>
                <Col span={4}>
                    <label>clicks</label>
                    <h3>{ad.insights && ad.insights.clicks}</h3>
                </Col>
                <Col span={4}>
                    <label>companyPageClicks</label>
                    <h3>{ad.insights && ad.insights.companyPageClicks}</h3>
                </Col>
                <Col span={4}>
                    <label>landingPageClicks</label>
                    <h3>{ad.insights && ad.insights.landingPageClicks}</h3>
                </Col>
                <Col span={4}>
                    <label>textUrlClicks</label>
                    <h3>{ad.insights && ad.insights.textUrlClicks}</h3>
                </Col>
            </Row>
        </Fragment>
    );

    const adsVideoPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={4}>
                    <label>videoCompletions</label>
                    <h3>{ad.insights && ad.insights.videoCompletions}</h3>
                </Col>
                <Col span={4}>
                    <label>videoFirstQuartileCompletions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.videoFirstQuartileCompletions}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>videoMidpointCompletions</label>
                    <h3>
                        {ad.insights && ad.insights.videoMidpointCompletions}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>videoStarts</label>
                    <h3>{ad.insights && ad.insights.videoStarts}</h3>
                </Col>
                <Col span={4}>
                    <label>videoThirdQuartileCompletions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.videoThirdQuartileCompletions}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>videoViews</label>
                    <h3>{ad.insights && ad.insights.videoViews}</h3>
                </Col>
            </Row>
        </Fragment>
    );

    const adsViralPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={4}>
                    <label>viralClicks</label>
                    <h3>{ad.insights && ad.insights.viralClicks}</h3>
                </Col>
                <Col span={4}>
                    <label>viralCommentLikes</label>
                    <h3>{ad.insights && ad.insights.viralCommentLikes}</h3>
                </Col>
                <Col span={4}>
                    <label>viralComments</label>
                    <h3>{ad.insights && ad.insights.viralComments}</h3>
                </Col>
                <Col span={4}>
                    <label>viralCompanyPageClicks</label>
                    <h3>{ad.insights && ad.insights.viralCompanyPageClicks}</h3>
                </Col>
                <Col span={4}>
                    <label>viralExternalWebsiteConversions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.viralExternalWebsiteConversions}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>viralExternalWebsitePostClickConversions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights
                                .viralExternalWebsitePostClickConversions}
                    </h3>
                </Col>
            </Row>

            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={4}>
                    <label>viralExternalWebsitePostViewConversions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.viralExternalWebsitePostViewConversions}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>viralFollows</label>
                    <h3>{ad.insights && ad.insights.viralFollows}</h3>
                </Col>
                <Col span={4}>
                    <label>viralFullScreenPlays</label>
                    <h3>{ad.insights && ad.insights.viralFullScreenPlays}</h3>
                </Col>
                <Col span={4}>
                    <label>viralImpressions</label>
                    <h3>{ad.insights && ad.insights.viralImpressions}</h3>
                </Col>
                <Col span={4}>
                    <label>viralLandingPageClicks</label>
                    <h3>{ad.insights && ad.insights.viralLandingPageClicks}</h3>
                </Col>
                <Col span={4}>
                    <label>viralLikes</label>
                    <h3>{ad.insights && ad.insights.viralLikes}</h3>
                </Col>
            </Row>

            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={4}>
                    <label>viralOneClickLeadFormOpens</label>
                    <h3>
                        {ad.insights && ad.insights.viralOneClickLeadFormOpens}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>viralOneClickLeads</label>
                    <h3>{ad.insights && ad.insights.viralOneClickLeads}</h3>
                </Col>
                <Col span={4}>
                    <label>viralOtherEngagements</label>
                    <h3>{ad.insights && ad.insights.viralOtherEngagements}</h3>
                </Col>
                <Col span={4}>
                    <label>viralReactions</label>
                    <h3>{ad.insights && ad.insights.viralReactions}</h3>
                </Col>
                <Col span={4}>
                    <label>viralShares</label>
                    <h3>{ad.insights && ad.insights.viralShares}</h3>
                </Col>
                <Col span={4}>
                    <label>viralTotalEngagements</label>
                    <h3>{ad.insights && ad.insights.viralTotalEngagements}</h3>
                </Col>
            </Row>

            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={4}>
                    <label>viralVideoCompletions</label>
                    <h3>{ad.insights && ad.insights.viralVideoCompletions}</h3>
                </Col>
                <Col span={4}>
                    <label>viralVideoFirstQuartileCompletions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.viralVideoFirstQuartileCompletions}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>viralVideoMidpointCompletions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.viralVideoMidpointCompletions}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>viralVideoStarts</label>
                    <h3>{ad.insights && ad.insights.viralVideoStarts}</h3>
                </Col>
                <Col span={4}>
                    <label>viralVideoThirdQuartileCompletions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.viralVideoThirdQuartileCompletions}
                    </h3>
                </Col>
                <Col span={4}>
                    <label>viralVideoViews</label>
                    <h3>{ad.insights && ad.insights.viralVideoViews}</h3>
                </Col>
            </Row>
        </Fragment>
    );

    // const adsUniquePanel = (
    //     <Fragment>
    //         <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
    //             <Col span={24}>
    //                 <label>Unique actions</label>
    //                 <h3>
    //                     {ad.insights &&
    //                         ad.insights.unique_actions &&
    //                         ad.insights.unique_actions.map((el, elIndex) => {
    //                             return (
    //                                 <div
    //                                     key={elIndex}
    //                                     style={{
    //                                         display: "block",
    //                                         fontSize: 13,
    //                                     }}
    //                                 >
    //                                     {el.action_type} - {el.value}
    //                                 </div>
    //                             );
    //                         })}
    //                 </h3>
    //             </Col>
    //         </Row>
    //         <br />
    //         <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
    //             <Col span={5}>
    //                 <label>Clicks</label>
    //                 <h3>{ad.insights && ad.insights.unique_clicks}</h3>
    //             </Col>
    //             <Col span={4}>
    //                 <label>CTR</label>
    //                 <h3>{ad.insights && ad.insights.unique_ctr}</h3>
    //             </Col>
    //             <Col span={4} style={{ textAlign: "right" }}>
    //                 <label>Inline link click ctr</label>
    //                 <h3>
    //                     {ad.insights &&
    //                         ad.insights.unique_inline_link_click_ctr}
    //                 </h3>
    //             </Col>
    //             <Col span={4} style={{ textAlign: "right" }}>
    //                 <label>Inline link clicks</label>
    //                 <h3>
    //                     {ad.insights && ad.insights.unique_inline_link_clicks}
    //                 </h3>
    //             </Col>
    //             <Col span={4} style={{ textAlign: "right" }}>
    //                 <label>Link clicks CTR</label>
    //                 <h3>{ad.insights && ad.insights.unique_link_clicks_ctr}</h3>
    //             </Col>
    //         </Row>
    //     </Fragment>
    // );

    // All values ----------------------
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Value",
            dataIndex: "value",
        },
        {
            title: "Description",
            dataIndex: "description",
        },
    ];
    const fields = [
        {
            key: "actionClicks",
            description:
                "The count of clicks on the action button of the Sponsored InMail. Sponsored InMail only.",
        },
        {
            key: "adUnitClicks",
            description:
                "The count of clicks on the ad unit displayed alongside the Sponsored InMail. Sponsored InMail only.",
        },
        {
            key: "clicks",
            description:
                "The count of chargeable clicks. Despite not charging for clicks for CPM campaigns, this field still represents those clicks for which we would otherwise charge advertisers (for example, clicks to view the landing page or company page).",
        },
        {
            key: "comments",
            description: "The count of comments. Sponsored Updates only.",
        },
        {
            key: "companyPageClicks",
            description: "The count of clicks to view the company page.",
        },
        {
            key: "conversionValueInLocalCurrency",
            description:
                "Non-demographic pivots only. Value of the conversions in the account's local currency based on rules defined by the advertiser. Conversion value is set by the advertiser at a per conversion level, and aggregated across the query time range.",
        },
        {
            key: "costInLocalCurrency",
            description:
                "Cost in the account's local currency based on the pivot and timeGranularity. For example, this would be spend by member company size per month if the pivot is MEMBER_COMPANY_SIZE and timeGranularity is MONTHLY. Cost is not adjusted for overdelivery when a member demographic pivot is specified in the request.",
        },
        {
            key: "costInUsd",
            description:
                "Cost in USD based on the pivot and timeGranularity. For example, this would be spend by campaign on a given day if the pivot is CAMPAIGN and timeGranularity is DAILY. Cost is not adjusted for overdelivery when a member demographic pivot is specified in the request.",
        },
        {
            key: "dateRange",
            description:
                "Date range covered by the report data point. Date is specified in UTC. Start and end date are inclusive. Start date is required. End date is optional and defaults to today.",
        },
        {
            key: "externalWebsiteConversions",
            description:
                "The count of conversions indicated by pixel loads on an external advertiser website.",
        },
        {
            key: "externalWebsitePostClickConversions",
            description:
                "The count of post-click conversions indicated by pixel loads on an external advertiser website.",
        },
        {
            key: "externalWebsitePostViewConversions",
            description:
                "The count of post-view conversions indicated by pixel loads on an external advertiser website.",
        },
        {
            key: "follows",
            description: "The count of follows. Sponsored Updates only.",
        },
        {
            key: "fullScreenPlays",
            description:
                "The count of taps on the video, going into video view mode.",
        },
        {
            key: "impressions",
            description:
                "This is the count of 'impressions' for Direct Ads and Sponsored Updates and 'sends' for InMails.",
        },
        {
            key: "landingPageClicks",
            description:
                "The count of clicks which take the user to the creative landing page.",
        },
        {
            key: "leadGenerationMailContactInfoShares",
            description:
                "The number of times users shared contact info through the One Click Lead Gen for Sponsored InMail. Sponsored InMail only.",
        },
        {
            key: "leadGenerationMailInterestedClicks",
            description:
                "The count of InMail recipients who clicked to demonstrate interest. Sponsored InMail only.",
        },
        {
            key: "likes",
            description: "The count of likes. Sponsored Updates only.",
        },
        {
            key: "oneClickLeadFormOpens",
            description:
                "The count of times users opened the lead form for a One Click Lead Gen campaign.",
        },
        {
            key: "oneClickLeads",
            description:
                "The count of leads generated through One Click Lead Gen.",
        },
        {
            key: "opens",
            description:
                "The count of opens of Sponsored InMail. Sponsored InMail only.",
        },
        {
            key: "otherEngagements",
            description:
                "The count of user interactions with the ad unit that do not fit into any other more specific category.",
        },
        { key: "pivot", description: "" },
        { key: "pivotValue", description: "" },
        {
            key: "pivotValues",
            description:
                "The value of the pivots for a specific record returned. For example, supplying pivots of CREATIVE and CONVERSION results in a list of records, one for each creative/conversion combination. The pivotValues contain serialized URNs for the specific creative and conversion for a record.",
        },
        {
            key: "reactions",
            description:
                "The count of positive reactions on Sponsored Updates which can capture, like, interest, praise, and other responses.",
        },
        {
            key: "sends",
            description:
                "The count of sends of Sponsored InMail. Sponsored InMail only.",
        },
        {
            key: "shares",
            description: "The count of shares. Sponsored Updates only.",
        },
        {
            key: "textUrlClicks",
            description:
                "The count of clicks on any links (anchor tags) that were included in the body of the Sponsored InMail. Sponsored InMail only.",
        },
        {
            key: "totalEngagements",
            description: "The count of all user interactions with the ad unit.",
        },
        {
            key: "videoCompletions",
            description:
                "The count of video ads that played 97-100% of the video. This includes watches that skipped to this point if the serving location is ON_SITE.",
        },
        {
            key: "videoFirstQuartileCompletions",
            description:
                "The count of video ads that played through the first quartile of the video. This includes watches that skipped to this point if the serving location is ON_SITE.",
        },
        {
            key: "videoMidpointCompletions",
            description:
                "The count of video ads that played through the midpoint of the video. This includes watches that skipped to this point if the serving location is ON_SITE.",
        },
        {
            key: "videoStarts",
            description: "The count of video ads that were started by users.",
        },
        {
            key: "videoThirdQuartileCompletions",
            description:
                "The count of video ads that played through the third quartile of the video. This includes watches that skipped to this point if the serving location is ON_SITE.",
        },
        {
            key: "videoViews",
            description:
                "	A video ad playing for at least 2 continuous seconds 50% in-view, or a click on the CTA, whichever comes first. An interaction with the video (like going to fullscreen mode) does not count as a view.",
        },
        {
            key: "viralClicks",
            description:
                "Non-demographic pivots only. The number of viralClicks for each card of a carousel ad. The first viralCardClick of the carousel ad results in an immediate viralCardClick and viralClick, whereas scrolling to other cards and clicking will count as additional viralCardClick.",
        },
        {
            key: "viralCommentLikes",
            description:
                "Non-demographic pivots only. The number of viralImpressions shown for each card of a carousel ad. The first card of the carousel ad results in an immediate viralCardImpression and viralImpression, whereas scrolling to other cards will count as additional viralCardImpressions.",
        },
        {
            key: "viralComments",
            description:
                "The count of comments from viral impressions for this activity. See viral impressions definition. Sponsored Updates only.",
        },
        {
            key: "viralCompanyPageClicks",
            description:
                "The count of clicks to view the company page from viral impressions for this activity. See viral impressions definition. Sponsored Updates only.",
        },
        {
            key: "viralExternalWebsiteConversions",
            description:
                "The count of conversions indicated by pixel loads on an external advertiser website driven by a viral event. See viral impressions definition.",
        },
        {
            key: "viralExternalWebsitePostClickConversions",
            description:
                "The count of post-click conversions indicated by pixel loads on an external advertiser website driven by a viral click. See viral impressions definition.",
        },
        {
            key: "viralExternalWebsitePostViewConversions",
            description:
                "The count of post-view conversions indicated by pixel loads on an external advertiser website driven by a viral impression. See viral impressions definition.",
        },
        {
            key: "viralFollows",
            description:
                "The count of follows from viral impressions for this activity. See viral impressions definition. Sponsored Updates only.",
        },
        {
            key: "viralFullScreenPlays",
            description:
                "The count of taps on the video, going into video view mode. See viralImpressions definition.",
        },
        {
            key: "viralImpressions",
            description:
                "The count of viral impressions for this activity. Viral impressions are those resulting from users sharing a sponsored update to their own network of connections. Viral impressions are not counted as regular impressions. Sponsored Updates only.",
        },
        {
            key: "viralLandingPageClicks",
            description:
                "The count of clicks on viral impressions to take the user to the creative landing page. See viral impressions definition. Sponsored Updates only.",
        },
        {
            key: "viralLikes",
            description:
                "The count of likes from viral impressions for this activity. See viral impressions definition. Sponsored Updates only.",
        },
        {
            key: "viralOneClickLeadFormOpens",
            description:
                "The count of times users opened the lead form for viral impressions from a Lead Gen campaign. See viral impressions definition.",
        },
        {
            key: "viralOneClickLeads",
            description:
                "The count of leads generated through One Click Lead Gen from viral impressions for this activity. See viral impressions definition.",
        },
        {
            key: "viralOtherEngagements",
            description:
                "The count of user interactions with viral impressions that do not fit into any other more specific category. See viral impressions definition. Sponsored Updates only.",
        },
        {
            key: "viralReactions",
            description:
                "The count of positive reactions on viral Sponsored Updates which can capture like, interest, praise, and other responses. See viral impressions definition for details on viral engagements.",
        },
        {
            key: "viralShares",
            description:
                "The count of shares from viral impressions for this activity. See viral impressions definition. Sponsored Updates only.",
        },
        {
            key: "viralTotalEngagements",
            description:
                "The count of all user interactions with a viral ad unit. See viral impressions definition. Sponsored Updates only.",
        },
        {
            key: "viralVideoCompletions",
            description:
                "The count of viral video ads that played 97-100% of the video. This includes watches that skipped to this point. See viralImpressions definition.",
        },
        {
            key: "viralVideoFirstQuartileCompletions",
            description:
                "The count of viral video ads that played through the first quartile of the video. This includes watches that skipped to this point. See viralImpressions definition.",
        },
        {
            key: "viralVideoMidpointCompletions",
            description:
                "The count of viral video ads that played through the midpoint of the video. This includes watches that skipped to this point. See viralImpressions definition.",
        },
        {
            key: "viralVideoStarts",
            description:
                "The count of viral video ads that were started by users. See viralImpressions definition. Since viral videos are automatically played for ON_SITE, this will be the same as viralImpressions if the servingLocation is ON_SITE.",
        },
        {
            key: "viralVideoThirdQuartileCompletions",
            description:
                "The count of viral video ads that played through the third quartile of the video. This includes watches that skipped to this point. See viralImpressions definition",
        },
        {
            key: "viralVideoViews",
            description:
                "A viral video ad playing for at least 2 continuous seconds 50% in-view, or a click on the CTA, whichever comes first. An interaction with the video (like going to full screen mode) does not count as a view. See viralImpressions definition.",
        },
    ];

    const data = [];
    if (ad.insights && Object.keys(ad.insights).length > 0) {
        fields.map((f, fIndex) => {
            //console.log(`f ${f.key} -> typeof ` + typeof ad.insights[f.key]);
            if (typeof ad.insights[f.key] !== "undefined") {
                if (typeof ad.insights[f.key] !== "object") {
                    data.push({
                        key: fIndex,
                        name: f.key,
                        value: <strong>{ad.insights[f.key]}</strong>,
                        description: f.description,
                    });
                } else {
                    // array of elements
                    const value =
                        ad.insights[f.key].length > 1 &&
                        ad.insights[f.key].map((v, vIndex) => {
                            return (
                                <div
                                    key={vIndex}
                                    style={{ whiteSpace: "break-spaces" }}
                                >
                                    <strong>
                                        {v["action_type"] + " - " + v["value"]}
                                    </strong>
                                </div>
                            );
                        });
                    data.push({
                        key: fIndex,
                        name: f.key,
                        value: value,
                        description: f.description,
                    });
                }
            }
            return false;
        });
    }

    const adsAllValuesPanel = (
        <Fragment>
            <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
            />
        </Fragment>
    );

    // -------------------------

    const body = props.loading ? (
        <Fragment>
            {/* <pre>{JSON.stringify(props.report_ad, null, 2)}</pre> */}
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            <Collapse
                bordered={false}
                defaultActiveKey={["1", "2", "3", "4", "5", "6", "7"]}
            >
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_overview") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Ads Overview
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_overview"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_overview",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="1"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_overview") !== -1 &&
                            adsOverviewPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("targeting") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Targeting
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "targeting"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "targeting",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="2"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("targeting") !== -1 &&
                            targetingPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_basic") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Insights Basic
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_basic"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_basic",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="3"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_basic") !== -1 &&
                            adsBasicPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_clicks") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Insights Clicks
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_clicks"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_clicks",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="4"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_clicks") !== -1 &&
                            adsClicksPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_video") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Video Insights
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_video"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_video",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="5"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_video") !== -1 &&
                            adsVideoPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_viral") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Viral Insights
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_viral"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_viral",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="6"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_viral") !== -1 &&
                            adsViralPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_allvalues") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Insights All
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_allvalues"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_allvalues",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="7"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_allvalues") !==
                            -1 && adsAllValuesPanel}
                    </Panel>
                ) : null}
            </Collapse>
        </Fragment>
    );

    return body;
};

export default ReportResultAdMonthlyLinkedin;
