import React, { Component, Fragment } from "react";

import moment from "moment";

import { observer } from "mobx-react";

import {
    Form,
    Modal,
    Divider,
    Button,
    Icon,
    Row,
    Col,
    Table,
    Input,
} from "antd";
import Draggable from "react-draggable";
import names from "./../../names";

const OFFSETX = 290;
const OFFSETY = 190;

/* Modal Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: false,
                instagram_tags: props.data.instagram_tags
                    ? props.data.instagram_tags
                    : [],
                imgWidth: 0,
                imgHeight: 0,
            };

            this.imgField = React.createRef();
        }

        // componentDidMount = async () => {
        //     setTimeout(
        //         function (that) {
        //             that.setState({
        //                 loading: false,
        //             });
        //         },
        //         1000,
        //         this
        //     );
        // };

        imageIsLoaded = () => {
            const imgField = this.imgField.current;
            console.log("imageIsLoaded imgField = ", imgField);

            this.setState({
                loading: false,
                imgWidth: imgField.width,
                imgHeight: imgField.height,
            });
        };

        eventControl = (event, info, tagIndex) => {
            //console.log("Event name: ", event.type);
            //console.log(event, info, tagIndex);

            // const imgField = this.imgField.current;
            // console.info("imgField", imgField);
            // const imgWidth = imgField.width;
            // const imgHeight = imgField.height;

            const { instagram_tags, imgWidth, imgHeight } = this.state;
            instagram_tags[tagIndex].x = Number(
                (event.clientX - 303) / imgWidth
            ).toFixed(4);
            instagram_tags[tagIndex].y = Number(
                (event.clientY - 208) / imgHeight
            ).toFixed(4);
            this.setState({ instagram_tags });
        };

        changeTagUsername = (_tagIndex, _username) => {
            const { instagram_tags } = this.state;
            instagram_tags[_tagIndex].username = _username;
            this.setState({ instagram_tags });
        };

        removeTag = (_tagIndex) => {
            const { instagram_tags } = this.state;
            instagram_tags.splice(_tagIndex, 1);
            this.setState({ instagram_tags });
        };

        addTag = () => {
            const { instagram_tags } = this.state;
            instagram_tags.push({
                x: 0,
                y: 0,
                username: "NewUsername",
            });
            this.setState({ instagram_tags });
        };

        render() {
            const { data } = this.props;
            const { imgWidth, imgHeight } = this.state;

            const antIcon = (
                <Icon type="loading" style={{ fontSize: 24 }} spin />
            );

            const columns = [
                {
                    title: "Position",
                    dataIndex: "position",
                    key: "position",
                    //   render: (text) => <a>{text}</a>,
                },
                {
                    title: "Username",
                    dataIndex: "username",
                    key: "username",
                },
                {
                    title: "Actions",
                    dataIndex: "actions",
                    key: "actions",
                },
            ];
            const dataSource = this.state.instagram_tags.map(
                (tag, tagIndex) => {
                    return {
                        key: tagIndex,
                        position:
                            Number(tag.x * 100).toFixed(0) +
                            "%, " +
                            Number(tag.y * 100).toFixed(0) +
                            "%",
                        username: (
                            <Fragment>
                                <span style={{ float: "left", margin: 4 }}>
                                    @
                                </span>
                                <Input
                                    value={tag.username}
                                    onChange={(e) =>
                                        this.changeTagUsername(
                                            tagIndex,
                                            e.target.value
                                        )
                                    }
                                    style={{ width: "calc(100% - 30px)" }}
                                />
                            </Fragment>
                        ),
                        actions: (
                            <Button onClick={(e) => this.removeTag(tagIndex)}>
                                X
                            </Button>
                        ),
                    };
                }
            );

            return this.state.loading ? (
                antIcon
            ) : (
                <Fragment>
                    <Row gutter={0}>
                        <Col
                            span={12}
                            className="social_ImageTagging"
                            style={{ position: "relative", overflow: "auto" }}
                        >
                            <img
                                src={data && data.previewURL}
                                ref={this.imgField}
                                onLoad={this.imageIsLoaded}
                            />
                            {this.state.instagram_tags.map((tag, tagIndex) => (
                                <Draggable
                                    key={tagIndex}
                                    onDrag={(e, info) =>
                                        this.eventControl(e, info, tagIndex)
                                    }
                                    defaultPosition={{
                                        x: Number(tag.x * imgWidth),
                                        y: Number(tag.y * imgHeight),
                                    }}
                                    //   onStart={(e,info) => this.eventControl(e,info,tagIndex)}
                                    //   onStop={(e,info) => this.eventControl(e,info,tagIndex)}
                                    //   onMouseDown={(e,info) => this.eventControl(e,info,tagIndex)}
                                    //   onMouseUp={(e,info) => this.eventControl(e,info,tagIndex)}
                                    //   onTouchStart={(e,info) => this.eventControl(e,info,tagIndex)}
                                    //   onTouchEnd={(e,info) => this.eventControl(e,info,tagIndex)}
                                >
                                    <span>{tag.username}</span>
                                </Draggable>
                            ))}
                        </Col>
                        <Col span={12} style={{ paddingLeft: 20 }}>
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                            />
                            <br />
                            <Button onClick={(e) => this.addTag(e)}>
                                Add a tag
                            </Button>
                        </Col>
                    </Row>
                </Fragment>
            );
        }
    }
);

/* Wrapper component */
class MasterpostsListModalInstagramTagging extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "masterposts_modal_instagramtagging",
            (_action, _payload) => {
                if (_action === "show") {
                    console.info("_payload", _payload);
                    const { curTarget, _tIndex } = _payload;
                    console.info("curTarget", curTarget, "_tIndex", _tIndex);
                    this.setState({ target: curTarget, tIndex: _tIndex });
                }
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener(
            "masterposts_modal_instagramtagging"
        );
    };

    onModalEdit = () => {
        console.log("onModalCreate this.onModalEdit", this.formRef);

        if (this.formRef) {
            let formState = Object.assign({}, this.formRef.state);
            console.log("formState", formState);

            formState.tIndex = this.state.tIndex;

            if (
                this.props.handleTaggingUpdate &&
                typeof this.props.handleTaggingUpdate === "function"
            ) {
                this.props.handleTaggingUpdate(formState);
                this.onModalCancel(); // close the popup
            }
        }

        //   const { form } = this.formRef.props;
        //   form.validateFields((err, values) => {
        //       if (err) {
        //           return;
        //       }
        //       if (values.scheduled_date)
        //           values.scheduled_date = moment(values.scheduled_date).valueOf();
        //       console.log("Received values of form: ", values);
        //       console.log("masterpost key: ", this.props.data.key);
        //       this.props.store.updateMasterpost(this.props.data.key, values);
        //       form.resetFields();
        //       this.setState({ modal_visible: false });
        //   });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "masterposts_modal_instagramtagging",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data, store } = this.props;
        const { target } = this.state;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={names.get("MASTERPOST") + " Instagram Tagging"}
                        okText={"Save"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalEdit} //onApply
                        maskClosable={false}
                        // footer={[
                        //     <Button
                        //         key="submit"
                        //         type="primary"
                        //         onClick={this.onModalCancel}
                        //     >
                        //         Close
                        //     </Button>,
                        // ]}
                    >
                        <ModalForm
                            store={store}
                            data={target}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(MasterpostsListModalInstagramTagging);
