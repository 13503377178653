import React, { Component, Fragment } from "react";
import {
    Form,
    Modal,
    Tooltip,
    Button,
    Icon,
    Input,
    Row,
    Col,
    Checkbox,
    Upload,
    Progress,
    Alert,
} from "antd";
import moment from "moment";
import { observer } from "mobx-react";
import { firebase } from "../../base";
import SocialPreview from "./../SocialPreview";
import SocialSeparator from "./../SocialSeparator";
import LibraryPickup from "./../Library/LibraryPickup";
import { social_notification } from "./../../functions";

const { TextArea } = Input;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                previewURL: "",
                keypressTimer: null,
                library_file: null,
            };
        }

        componentDidMount = async () => {
            this.setState({ post: this.props.post });
        };

        handleTemplateTextChange = (_evt) => {
            console.info("handleTemplateTextChange", _evt);
            // TODO: Fix this solution so it's not linked to the ID of the textarea

            let value = _evt.target.value;
            console.info("handleTemplateTextChange value", value);
            this.setState({ template_text: value });

            this.props.form.setFieldsValue({
                template_text: value,
            });

            //var stateKeypressTimer = { ...this.state.keypressTimer };
            clearTimeout(this.state.keypressTimer);
            this.setState({
                keypressTimer: setTimeout(this.parseTemplateText, 2000),
            });
        };

        parseTemplateText = () => {
            console.log("parseTemplateText");
            this.setState({ parsing: true });
            this.props.store
                .getParsedPost(this.props.account.key, this.state.template_text)
                .then((parsedPost) => {
                    var statePost = { ...this.state.post };
                    statePost.post_text_parsed = parsedPost.post_text_parsed;
                    this.setState({ post: statePost, parsing: false });
                })
                .catch((err) => console.error(err));
        };

        handleFileChange = (info) => {
            console.info("handleFileChange info", info);
            //console.info("handleUpload this.props", this.props);
            this.props.form.setFieldsValue({
                post_file: info.file,
                changed_post_file: true,
            });

            var statePost = { ...this.state.post };
            statePost.post_file = info.file;
            this.setState({ post: statePost, changed_post_file: true });

            this.setState({ previewURL: "" });

            if (info.fileList.length === 1) {
                let reader = new FileReader();
                let preview_file = info.file;
                console.info("handleFileChange preview_file", preview_file);
                reader.onloadend = () => {
                    this.setState({ previewURL: reader.result });
                };
                reader.readAsDataURL(preview_file);
            } else {
                // clear the current file
                //delete this.props.data.post_file;
                this.setState({ post: statePost, changed_post_file: false });
            }
            // return false;
        };

        handleChangeLibrarySelected = (_selected) => {
            console.info("handleChangeLibrarySelected _selected", _selected);

            if (_selected.length > 0) {
                this.setState({
                    library_file: _selected[0],
                    previewURL: _selected[0] ? _selected[0].downloadURL : "",
                });
                this.props.post.library_file = _selected[0];
                this.props.post["changed_template_file"] = true;
                // curTarget.library_file = _selected[0];
                // curTarget.previewURL = _selected[0]
                //     ? _selected[0].downloadURL
                //     : "";
            } else {
                this.setState({
                    library_file: null,
                    previewURL: "",
                });
                this.props.post.library_file = null;
                this.props.post["changed_template_file"] = false;
                // curTarget.previewURL = "";
                // delete curTarget.library_file;
            }

            // this.setState({
            //     library_file: _selected,
            //     previewURL: _selected ? _selected.downloadURL : ""
            // });
            // this.props.post.library_file = _selected;
            // this.props.post["changed_template_file"] = true;
        };

        render() {
            const { form, upload_percentage, store } = this.props;
            const { getFieldDecorator } = form;

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };

            return (
                <Fragment>
                    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                    {/* state
                    <br /> */}
                    {/* <pre>
                        {this.state.post &&
                            JSON.stringify(this.state.post, null, 2)}
                    </pre> */}
                    {this.state.post && this.state.post.edited_at ? (
                        <Alert
                            showIcon
                            message={
                                "This post was edited at " +
                                moment(this.state.post.edited_at)
                                    .local()
                                    .format("LLLL") +
                                " and therefore detached from the original masterpost."
                            }
                            type="warning"
                        />
                    ) : (
                        <Alert
                            showIcon
                            message="Editing the post, will detach it from the masterpost. All changes to the masterpost made afterwards will not apply to this post."
                            type="warning"
                        />
                    )}
                    <br />
                    <br />
                    <Row gutter={48}>
                        <Col span={10}>
                            <Form {...formItemLayout}>
                                <Form.Item label="Text">
                                    <Tooltip
                                        title={`
                                        [*name] - the name of the Account;
                                        [*email] - the website of the Account;
                                        [*phone] - the phone of the Account;
                                        [*website] - the website of the Account;
                                        [*location] - the location of the Account;
                                        [*appendix] - the appendix of the Account;`}
                                    >
                                        {getFieldDecorator("post_text", {
                                            initialValue:
                                                this.state.post &&
                                                this.state.post.post_text,
                                            onChange: this
                                                .handleTemplateTextChange,
                                        })(
                                            <TextArea
                                                rows={8}
                                                id="form_in_modal_template_text"
                                            />
                                        )}
                                    </Tooltip>
                                </Form.Item>
                                <Form.Item label="File">
                                    <Row>
                                        <Col>
                                            {getFieldDecorator("post_file", {
                                                initialValue:
                                                    this.state.post &&
                                                    this.state.post.post_file,
                                            })(
                                                <Upload
                                                    name="template_file"
                                                    beforeUpload={(file) =>
                                                        false
                                                    }
                                                    showUploadList={true}
                                                    onChange={
                                                        this.handleFileChange
                                                    }
                                                    multiple={false}
                                                    accept="image/jpeg,image/png,image/gif,video/mp4"
                                                >
                                                    <Button
                                                        disabled={
                                                            (this.state.post &&
                                                                typeof this
                                                                    .state.post
                                                                    .post_file !=
                                                                    "undefined" &&
                                                                this.state.post
                                                                    .post_file
                                                                    .name &&
                                                                this.state
                                                                    .changed_post_file) ||
                                                            this.state
                                                                .library_file
                                                        }
                                                        style={{
                                                            width: 160,
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <Icon
                                                            type="upload"
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                        />{" "}
                                                        Upload File ...
                                                    </Button>
                                                </Upload>
                                            )}
                                            {getFieldDecorator(
                                                "changed_post_file",
                                                {
                                                    initialValue: this.state
                                                        .changed_post_file,
                                                }
                                            )(<Input type="hidden" />)}
                                        </Col>
                                        <Col>
                                            <Progress
                                                className={
                                                    !upload_percentage ||
                                                    upload_percentage === 0
                                                        ? "hidden"
                                                        : ""
                                                }
                                                percent={upload_percentage}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {/* {this.state.post &&
                                            typeof this.state.post.post_file !=
                                                "undefined" &&
                                            this.state.post.post_file.name &&
                                            !this.state.post
                                                .changed_post_file ? (
                                                <div>
                                                    <span>
                                                        {
                                                            this.state.post
                                                                .post_file.name
                                                        }
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )} */}
                                            {this.state.post &&
                                            typeof this.state.post.post_file !=
                                                "undefined" &&
                                            this.state.post.post_file.name &&
                                            this.state.changed_post_file ? (
                                                <div>
                                                    {this.state.previewURL
                                                        .length > 0 &&
                                                    typeof this.state.post
                                                        .post_file
                                                        .library_file ===
                                                        "undefined" ? (
                                                        <img
                                                            src={
                                                                this.state
                                                                    .previewURL
                                                            }
                                                            style={{
                                                                height: 100,
                                                                padding: 5,
                                                                marginTop: 5,
                                                                border:
                                                                    "1px solid #ccc",
                                                                borderRadius: 5,
                                                            }}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    {this.state.previewURL
                                                        .length > 0 &&
                                                    this.state.post &&
                                                    this.state.post.post_file &&
                                                    typeof this.state.post
                                                        .post_file
                                                        .library_file !=
                                                        "boolean" ? (
                                                        <img
                                                            src={
                                                                this.state
                                                                    .previewURL
                                                            }
                                                            style={{
                                                                height: 100,
                                                                padding: 5,
                                                                marginTop: 5,
                                                                border:
                                                                    "1px solid #ccc",
                                                                borderRadius: 5,
                                                            }}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {this.state.post &&
                                            typeof this.state.post.post_file !=
                                                "undefined" &&
                                            this.state.post.post_file.name &&
                                            !this.state.changed_post_file
                                                ? getFieldDecorator(
                                                      "delete_post_file"
                                                  )(
                                                      <Checkbox
                                                          disabled={
                                                              this.state
                                                                  .library_file
                                                                  ? true
                                                                  : false
                                                          }
                                                      >
                                                          Delete file
                                                      </Checkbox>
                                                  )
                                                : ""}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <LibraryPickup
                                                store={store}
                                                disabled={
                                                    this.state.post &&
                                                    typeof this.state.post
                                                        .post_file !=
                                                        "undefined" &&
                                                    typeof this.state.post
                                                        .post_file
                                                        .library_file !==
                                                        "boolean" &&
                                                    this.state.post.post_file
                                                        .status !== "removed"
                                                }
                                                onSelectedChange={
                                                    this
                                                        .handleChangeLibrarySelected
                                                }
                                                selected={[
                                                    this.state.library_file,
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </Col>
                        {/* <Col span={4}>&nbsp;</Col> */}
                        <Col
                            span={14}
                            style={{ maxWidth: 550, float: "right" }}
                        >
                            <SocialSeparator>Preview</SocialSeparator>
                            <SocialPreview
                                body={
                                    this.state.parsing ? (
                                        <Icon type="loading" />
                                    ) : (
                                        this.state.post &&
                                        this.state.post.post_text_parsed
                                    )
                                }
                                image_url={this.state.previewURL}
                                hideTabs={true}
                                defaultTab={"facebook"}
                            ></SocialPreview>
                        </Col>
                    </Row>
                </Fragment>
            );
        }
    }
);

/* Wrapper component */
class AccountsQueueEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            confirmLoading: false,
        };
    }

    componentDidMount = async () => {
        this.props.store.registerModalListener(
            "accounts_queue_edit",
            (_action) => {
                this.loadInformation();
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    loadInformation = async () => {
        console.info("async loadInformation this.props", this.props);
        this.setState({
            post: this.props.post,
            queue_index: this.props.queue_index,
        });

        await this.props.store
            .getTargetByQueuePost(this.props.post)
            .then((target_data) => {
                this.setState({
                    target: target_data,
                    account: this.props.account,
                });

                console.info(
                    "componentDidMount this.props.post",
                    this.props.post
                );
                if (
                    this.props.post.post_file &&
                    !this.props.post.post_file.library_file
                ) {
                    this.setState({
                        previewURL: this.props.post.post_file.url,
                    });
                }

                if (
                    this.props.post.post_file &&
                    this.props.post.post_file.library_file
                ) {
                    let library_file = this.props.post.post_file;
                    library_file.downloadURL = this.props.post.post_file.url;
                    console.info("library_file", library_file);

                    this.setState({
                        previewURL: this.props.post.post_file.url,
                        library_file: library_file,
                    });
                }

                // Chain the promise
                return this.props.store.getParsedPost(
                    this.props.account.key,
                    this.props.post.post_text
                );
            })
            .then((parsedPost) => {
                var statePost = { ...this.state.post };
                statePost.post_text_parsed = parsedPost.post_text_parsed;
                this.setState({ post: statePost });
            })
            .catch((err) => console.error(err));
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("accounts_queue_edit");
    };

    onModalCancel = () => {
        this.setState({ confirmLoading: false });
        this.props.store.hideModalByKey("accounts_queue_edit",this.formRef ? this.formRef.props.form.isFieldsTouched() : false);
    };

    onModalEdit = () => {
        //this.setState({ edit_visible: false });
        this.setState({ confirmLoading: true });

        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }
            console.log(
                "Received values of this.state.post: ",
                this.state.post
            );

            if (
                typeof values.post_file != "undefined" &&
                values.changed_post_file &&
                !values.post_file.library_file
            ) {
                console.log("NOT A LIBRARY FILE");
            } else {
                console.log("DO WE HAVE A LIBRARY FILE?");
                if (this.state.post.library_file) {
                    console.log("WE DOOO!!!!");
                } else {
                    console.log("WE DON'T :(");
                }
            }

            if (
                typeof values.post_file != "undefined" &&
                values.changed_post_file &&
                !values.post_file.library_file
            ) {
                const file = values.post_file.file.originFileObj
                    ? values.post_file.file.originFileObj
                    : values.post_file.file;
                console.info("FILE", file);
                const storageRef = firebase.storage().ref();
                console.info("storageRef", storageRef);

                let now = Date.now();
                let fileparts = file.name.split(".");
                let fileext = fileparts.pop();
                const mainImage = storageRef.child(
                    fileparts.join(".") + "_" + now.valueOf() + "." + fileext
                );
                console.info("mainImage", mainImage);
                let uploadTask = mainImage.put(file);
                let that = this;

                uploadTask.on(
                    "state_changed",
                    function (snapshot) {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        var progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100;
                        console.log("Upload is " + progress + "% done");
                        that.setState({
                            upload_percentage: Math.round(progress),
                        });
                    },
                    function (error) {
                        // Handle unsuccessful uploads
                        console.error("unsuccessful upload: " + error);
                    },
                    function () {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(function (downloadURL) {
                                console.log("File available at", downloadURL);

                                let post_file = {
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    url: downloadURL,
                                    meta: uploadTask.snapshot.metadata,
                                };

                                // update the post
                                that.actualUpdate(values.post_text, post_file);
                            });
                    }
                );
            } else {
                let post_file = null;

                // Overwrite the library file
                if (this.state.post.library_file) {
                    post_file = {
                        name: this.state.post.library_file.file.name,
                        size: this.state.post.library_file.file.size,
                        type: this.state.post.library_file.file.type,
                        url: this.state.post.library_file.downloadURL,
                        library_file: true,
                        library_key: this.state.post.library_file.key,
                    };

                    // Lock the library file
                    this.props.store.toggleLibraryItemLock(
                        { key: this.state.post.library_file.key },
                        true
                    );

                    delete this.state.post.library_file;
                }

                this.actualUpdate(
                    values.post_text,
                    post_file,
                    values.delete_post_file
                );
            }
        });

        // setTimeout(() => {
        //     this.setState({
        //         edit_visible: false,
        //         edit_confirmLoading: false
        //     });
        // }, 2000);
    };

    actualUpdate = (_post_text, _post_file, _delete_post_file) => {
        console.info(
            "actualUpdate",
            "_post_text",
            _post_text,
            "_post_file",
            _post_file,
            "_delete_post_file",
            _delete_post_file
        );
        console.info(
            "actualUpdate _account_key",
            this.state.account.key,
            "_queue_index",
            this.state.queue_index
        );

        // update the post
        this.props.store
            .updateAccountQueuePost(
                this.state.account.key,
                this.state.queue_index,
                _post_text,
                _post_file,
                _delete_post_file
            )
            .then((updateData) => {
                console.info("updateData", updateData);

                social_notification("update_account_queue_post", "success");

                this.onModalCancel();
            })
            .catch((err) => {
                console.error(err);
                social_notification("update_account_queue_post", "error", {
                    description: err,
                });
                this.onModalCancel();
            });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Edit post"}
                        okText={"Save"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalEdit} //onApply
                        maskClosable={false}
                        confirmLoading={this.state.confirmLoading}
                    >
                        <ModalForm
                            store={this.props.store}
                            post={this.state.post}
                            account={this.props.account}
                            upload_percentage={this.state.upload_percentage}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AccountsQueueEdit);
