import React, { Component, Fragment } from "react";
import { Modal, Icon, Alert } from "antd";
import { observer } from "mobx-react";
import { social_setCookie } from "../../functions";

/* Wrapper component */
class AccountsChannelsGoogleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    async componentDidMount() {
        this.props.store.registerModalListener(
            "accounts_channels_google_modal",
            (_action) => {
                console.log(
                    "accounts_channels_twitter_modal _action=" + _action
                );
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
                if (_action === "show") {
                    console.info("this.props.account", this.props.account);
                    //   this.props.store.setTempData({
                    //       link_google_account_key: this.props.account.key,
                    //   });
                    social_setCookie(
                        "link_google_account_key",
                        this.props.account.key,
                        7
                    );
                }
                if (_action === "hide") {
                    if (typeof this.props.callback !== "undefined") {
                        this.props.callback();
                    }
                }
            }
        );
    }

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener(
            "accounts_channels_google_modal"
        );
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "accounts_channels_google_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    render() {
        const { account } = this.props;
        console.info("account", account);
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Link Google profile"}
                        onCancel={this.onModalCancel}
                        maskClosable={false}
                        footer={null}
                        centered={true}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <iframe
                            title="googlehandler"
                            frameBorder={0}
                            width="100%"
                            height="500"
                            src={
                                window.location.origin +
                                "/googlehandler?account_key=" +
                                account.key
                            }
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AccountsChannelsGoogleModal);
