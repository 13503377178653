import React, { Component, Fragment } from "react";

import config from "./../../config";
import tooltips from "../../tooltips";

import { observer } from "mobx-react";
import moment from "moment";

import {
    List,
    Button,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tabs,
    DatePicker,
    Checkbox,
    Icon,
    Tooltip,
} from "antd";

import AdsListModal from "../Ads/AdsListModal";
//import CompetitorsListModal from "./CompetitorsListModal";
import Bulk from "./../Bulk";
import names from "./../../names";

const { Title } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

class InsightsList extends Component {
    listBulk = null;
    listBulk2 = null;

    constructor(props) {
        super(props);
        this.state = {
            masterposts_filter: "",
            posts_filter: "",
            //edit_data: {},
            pagination_currentPage: 1,
            sortby: "masterpost_name",
            sortasc: true,
            processing: false,
            tabsKey: "masterposts",
        };

        this.adsModal = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("insights");

        // Breadcrumbs
        // this.props.store.setBreadcrumbs([
        //     { route: `/dashboard/insights`, title: "Insights" },
        // ]);
    }
    async componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);

        //await this.loadInformation();

        this.listBulk = new Bulk({
            name: "insightsMasterpostsList",
            list: this.props.store.getAllMasterposts.map(
                (element) => element.key
            ),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        //await this.props.store.deleteCategory(_key);
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                    combined: true,
                },
            ],
            callback: () => {
                console.log("READY");
            },
        });

        this.listBulk2 = new Bulk({
            name: "insightsPostsList",
            list:
                this.state.result_posts &&
                this.state.result_posts.map((element) => element.key),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        //await this.props.store.deleteCategory(_key);
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                    combined: true,
                },
            ],
            callback: () => {
                console.log("READY");
            },
        });

        this.setState({ processing: true });
        setTimeout(
            function (that) {
                that.loadInformation();
            },
            100,
            this
        );
    }

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("insights_modal_add");
    }

    handleReload = async (e) => {
        this.setState({ processing: true });
        setTimeout(
            function (that) {
                that.loadInformation();
            },
            100,
            this
        );
    };
    loadInformation = async () => {
        // ListBulk for Posts
        // let result_posts = [];
        // this.props.store.getAllMasterposts.map((m) => {
        //     const posts = this.props.store.getAllPostsForMasterpost(m);
        //     result_posts = result_posts.concat(posts);
        //     return false;
        // });

        // this.setState({ result_posts });

        // ---- Masterposts
        // let masterposts = this.props.store.getAllMasterposts;
        // this.setState({ masterposts });
        const insights_params = [
            "post_impressions",
            "post_engaged_users",
            "post_negative_feedback",
            "post_engaged_fan",
            "post_clicks",
            "post_reactions_like_total",
            "post_reactions_love_total",
            "post_reactions_wow_total",
            "post_reactions_haha_total",
            "post_reactions_sorry_total",
            "post_reactions_anger_total",
            "post_video_avg_time_watched",
            "post_video_views",
        ];

        // --- Masterposts & Posts
        let result_posts = [],
            result_masterposts = [];
        await this.props.store.getAllMasterposts.map(async (m) => {
            //console.info("m", m);
            let masterpost_insights = [];
            // let insights_masterpost_impressions = 0,
            //     insights_masterpost_clicks = 0;
            let posts = await this.props.store.getAllPostsForMasterpost(m);
            //console.info("posts for m", posts);
            posts = posts.filter(
                (post) =>
                    post.status === "posted" &&
                    post.results &&
                    post.results[0].insights
            );
            posts.map((post) => {
                insights_params.map((param) => {
                    post["insights_" + param] =
                        post.results[0].insights.data.filter(
                            (d) => d.period === "lifetime" && d.name === param
                        )[0].values[0].value;

                    if (masterpost_insights[param]) {
                        masterpost_insights[param] += post["insights_" + param];
                    } else {
                        masterpost_insights[param] = post["insights_" + param];
                    }
                    return false;
                });

                // insights_masterpost_impressions =
                //     insights_masterpost_impressions +
                //     post.insights_post_impressions;
                // insights_masterpost_clicks =
                //     insights_masterpost_clicks + post.insights_post_clicks;
                return false;
            });

            result_posts = result_posts.concat(posts);

            if (posts.length > 0) {
                // m.insights_masterpost_impressions = insights_masterpost_impressions;
                // m.insights_masterpost_clicks = insights_masterpost_clicks;
                m.insights = masterpost_insights;
                result_masterposts.push(m);
            }
            // return (
            //     m.name
            //         .toUpperCase()
            //         .indexOf(this.state.masterposts_filter.toUpperCase()) !== -1
            // );

            return false;
        });
        this.setState({
            posts: result_posts,
            masterposts: result_masterposts,
            processing: false,
        });
    };

    showModal = () => {
        console.log("showModal");
        this.setState({
            edit_data: {},
        });

        this.props.store.showModalByKey("insights_modal_add");
    };
    showAdsModal = (_evt, _item) => {
        console.info("showAdsModal _evt", _evt, "_item", _item);
        this.adsModal.current.registerModal();
        this.props.store.showModalByKey("ads_modal", {
            type: "fb_boost",
            masterpost_key: _item.key,
        });
    };

    onMasterpostsSearchChange = (_search) => {
        console.info("onMasterpostsSearchChange _search", _search);
        this.setState({ masterposts_filter: _search });
    };
    onPostsSearchChange = (_search) => {
        console.info("onPostsSearchChange _search", _search);
        this.setState({ posts_filter: _search });
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        console.info("sortby", sortby, "sortasc", sortasc);
        this.setState({ sortby: _sorting, sortasc });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    handleTabsChange = (key) => {
        this.setState({ tabsKey: key });
    };
    handleJumpToPosts = (_item) => {
        //console.info("handleJumpToPosts _item", _item);
        this.setState({ tabsKey: "posts", posts_filter: _item.name });
    };

    getMasterpostsListingDataSource = () => {
        if (this.state.masterposts) {
            let results = this.state.masterposts.filter((c) => {
                return (
                    c.name
                        .toUpperCase()
                        .indexOf(
                            this.state.masterposts_filter.toUpperCase()
                        ) !== -1
                );
            });

            // --- sorting ---

            results.sort((a, b) => {
                //console.info("results.sort (this.state.sortby)", this.state.sortby);

                const nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase(),
                    postImpressionsA = Number(a.insights.post_impressions),
                    postImpressionsB = Number(b.insights.post_impressions),
                    postClicksA = Number(a.insights.post_clicks),
                    postClicksB = Number(b.insights.post_clicks),
                    postEngagedUsersA = Number(a.insights.post_engaged_users),
                    postEngagedUsersB = Number(b.insights.post_engaged_users),
                    postEngagedFansA = Number(a.insights.post_engaged_fan),
                    postEngagedFansB = Number(b.insights.post_engaged_fan),
                    postReactionsLikeA = Number(
                        a.insights.post_reactions_like_total
                    ),
                    postReactionsLikeB = Number(
                        b.insights.post_reactions_like_total
                    ),
                    postNegativeFeedbackA = Number(
                        a.insights.post_negative_feedback
                    ),
                    postNegativeFeedbackB = Number(
                        b.insights.post_negative_feedback
                    );
                // <div>{item.insights["post_impressions"]}</div>
                // <div>{item.insights["post_clicks"]}</div>
                // <div>{item.insights["post_engaged_users"]}</div>
                // <div>{item.insights["post_engaged_fan"]}</div>
                // <div>{item.insights["post_reactions_like_total"]}</div>
                // <div>{item.insights["post_negative_feedback"]}</div>

                switch (this.state.sortby) {
                    case "masterpost_name":
                        if (this.state.sortasc === true) {
                            if (nameA < nameB) return -1;
                        } else {
                            if (nameA > nameB) return 1;
                        }
                        break;
                    case "masterpost_impressions":
                        if (this.state.sortasc === true) {
                            if (postImpressionsA <= postImpressionsB) return -1;
                            if (postImpressionsA > postImpressionsB) return 1;
                        } else {
                            if (postImpressionsA <= postImpressionsB) return 1;
                            if (postImpressionsA > postImpressionsB) return -1;
                        }
                        break;
                    case "masterpost_clicks":
                        if (this.state.sortasc === true) {
                            if (postClicksA <= postClicksB) return -1;
                            if (postClicksA > postClicksB) return 1;
                        } else {
                            if (postClicksA <= postClicksB) return 1;
                            if (postClicksA > postClicksB) return -1;
                        }
                        break;
                    case "masterpost_engaged_users":
                        if (this.state.sortasc === true) {
                            if (postEngagedUsersA <= postEngagedUsersB)
                                return -1;
                            if (postEngagedUsersA > postEngagedUsersB) return 1;
                        } else {
                            if (postEngagedUsersA <= postEngagedUsersB)
                                return 1;
                            if (postEngagedUsersA > postEngagedUsersB)
                                return -1;
                        }
                        break;
                    case "masterpost_engaged_fan":
                        if (this.state.sortasc === true) {
                            if (postEngagedFansA <= postEngagedFansB) return -1;
                            if (postEngagedFansA > postEngagedFansB) return 1;
                        } else {
                            if (postEngagedFansA <= postEngagedFansB) return 1;
                            if (postEngagedFansA > postEngagedFansB) return -1;
                        }
                        break;
                    case "masterpost_reactions_like_total":
                        if (this.state.sortasc === true) {
                            if (postReactionsLikeA <= postReactionsLikeB)
                                return -1;
                            if (postReactionsLikeA > postReactionsLikeB)
                                return 1;
                        } else {
                            if (postReactionsLikeA <= postReactionsLikeB)
                                return 1;
                            if (postReactionsLikeA > postReactionsLikeB)
                                return -1;
                        }
                        break;
                    case "masterpost_negative_feedback":
                        if (this.state.sortasc === true) {
                            if (postNegativeFeedbackA <= postNegativeFeedbackB)
                                return -1;
                            if (postNegativeFeedbackA > postNegativeFeedbackB)
                                return 1;
                        } else {
                            if (postNegativeFeedbackA <= postNegativeFeedbackB)
                                return 1;
                            if (postNegativeFeedbackA > postNegativeFeedbackB)
                                return -1;
                        }
                        break;

                    default:
                        return 0;
                }
                console.log("return 0");
                return 0;
            });
            // --- end of sorting

            return results.slice(
                (this.state.pagination_currentPage - 1) *
                    config.PAGINATION_PERPAGE,
                this.state.pagination_currentPage * config.PAGINATION_PERPAGE
            );
        } else {
            return [];
        }
    };

    getPostsListingDataSource = () => {
        let result_posts = [];
        if (this.state.posts) {
            console.info("this.state.posts", this.state.posts);
            let results = this.state.posts.filter((p) => {
                return (
                    p.post_text
                        .toUpperCase()
                        .indexOf(this.state.posts_filter.toUpperCase()) !==
                        -1 ||
                    p.masterpost.name
                        .toUpperCase()
                        .indexOf(this.state.posts_filter.toUpperCase()) !==
                        -1 ||
                    p.account.name
                        .toUpperCase()
                        .indexOf(this.state.posts_filter.toUpperCase()) !== -1
                );
            });
            result_posts = results;
        }

        // --- sorting ---
        result_posts.sort((a, b) => {
            //console.info("results.sort (this.state.sortby)", this.state.sortby);

            var accountnameA = a.account.name && a.account.name.toLowerCase(),
                accountnameB = b.account.name && b.account.name.toLowerCase();
            var masterpostnameA =
                    a.masterpost.name && a.masterpost.name.toLowerCase(),
                masterpostnameB =
                    b.masterpost.name && b.masterpost.name.toLowerCase();

            switch (this.state.sortby) {
                case "posts_account":
                    if (this.state.sortasc === true) {
                        if (accountnameA < accountnameB) return -1;
                    } else {
                        if (accountnameA > accountnameB) return 1;
                    }
                    break;
                case "posts_masterpost":
                    if (this.state.sortasc === true) {
                        if (masterpostnameA < masterpostnameB) return -1;
                    } else {
                        if (masterpostnameA > masterpostnameB) return 1;
                    }
                    break;

                // case "level":
                //     if (this.state.sortasc === true) {
                //         if (levelA < levelB) return -1;
                //     } else {
                //         if (levelA > levelB) return 1;
                //     }
                //     break;
                // case "disabled":
                //     if (this.state.sortasc === true) {
                //         if (disabledA < disabledB) return -1;
                //     } else {
                //         if (disabledA > disabledB) return 1;
                //     }
                //     break;
                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting

        return result_posts.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    onRangeChange = (date, dateString) => {
        console.info("onRangeChange date", date, "dateString", dateString);
    };

    render() {
        //console.log("pagesCount = " + pagesCount);

        let header = (
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 10 }}
                className="InsightsList"
            >
                <Col style={{ width: "100%" }}>
                    <Title level={3} style={{ float: "left", paddingRight: 5 }}>
                        Insights
                    </Title>
                    <Button
                        type="primary"
                        icon={this.state.processing ? "loading" : "reload"}
                        style={{
                            bottom: -45,
                            position: "absolute",
                            right: 0,
                            cursor: "pointer",
                            zIndex: 2,
                        }}
                        onClick={(e) => this.handleReload()}
                    >
                        {this.state.processing ? "Processing ..." : "Reload"}
                    </Button>
                </Col>
            </Row>
        );

        const masterposts = this.getMasterpostsListingDataSource();
        const masterpostsLength = masterposts.length;
        const masterpostsPagesCount =
            masterpostsLength / config.PAGINATION_PERPAGE;
        console.info("masterposts", masterposts);
        let masterpostsList = (
            <List
                size="small"
                className="InsightsList_Masterposts"
                header={
                    <Fragment>
                        <Row type="flex" justify="space-between">
                            <Col
                                style={{
                                    minWidth: 25,
                                    lineHeight: 1.2,
                                }}
                            >
                                <Checkbox
                                    // onChange={e => this.handleBulkAll(e)}
                                    onChange={(e) =>
                                        this.listBulk.handleAll(
                                            e.target.checked
                                        )
                                    }
                                    style={{ marginRight: 10 }}
                                />
                                {this.listBulk &&
                                    this.listBulk.renderOptionsAsList()}
                            </Col>
                            <Col>
                                {/* {this.listBulk && this.listBulk.renderOptions()} */}
                                <Search
                                    placeholder="filter"
                                    onSearch={this.onMasterpostsSearchChange}
                                    onChange={(e) =>
                                        this.onMasterpostsSearchChange(
                                            e.target.value
                                        )
                                    }
                                    style={{ width: 200 }}
                                />
                            </Col>
                            <Col>
                                <RangePicker
                                    onChange={this.onRangeChange}
                                    defaultValue={[
                                        moment().subtract(3, "weeks"),
                                        moment(),
                                    ]}
                                    format="MM/DD/YYYY"
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: 10,
                                fontSize: 12,
                                display: "flex",
                                flex: "1 1",
                                flexAlign: "start",
                                alignItems: "flex-start",
                            }}
                        >
                            <Col
                                style={{
                                    width: 25,
                                    lineHeight: 1.2,
                                }}
                            >
                                {/* <Checkbox
                                    // onChange={e => this.handleBulkAll(e)}
                                    onChange={(e) =>
                                        this.listBulk.handleAll(
                                            e.target.checked
                                        )
                                    }
                                /> */}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting("masterpost_name")
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "masterpost_name"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    Name
                                </span>
                                {this.state.sortby === "masterpost_name" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting(
                                            "masterpost_impressions"
                                        )
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "masterpost_impressions"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    Impr.
                                </span>
                                {this.state.sortby ===
                                "masterpost_impressions" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting("masterpost_clicks")
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "masterpost_clicks"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    Clicks
                                </span>
                                {this.state.sortby === "masterpost_clicks" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting(
                                            "masterpost_engaged_users"
                                        )
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "masterpost_engaged_users"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    eng. users
                                </span>
                                {this.state.sortby ===
                                "masterpost_engaged_users" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting(
                                            "masterpost_engaged_fan"
                                        )
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "masterpost_engaged_fan"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    eng. fan
                                </span>
                                {this.state.sortby ===
                                "masterpost_engaged_fan" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting(
                                            "masterpost_reactions_like_total"
                                        )
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "masterpost_reactions_like_total"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    likes
                                </span>
                                {this.state.sortby ===
                                "masterpost_reactions_like_total" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting(
                                            "masterpost_negative_feedback"
                                        )
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "masterpost_negative_feedback"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    neg.
                                </span>
                                {this.state.sortby ===
                                "masterpost_negative_feedback" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>

                            <Col>Tools</Col>
                        </Row>
                    </Fragment>
                }
                footer={
                    masterpostsPagesCount > 1 &&
                    this.state.filter.length === 0 ? (
                        <div style={{ textAlign: "right" }}>
                            <Pagination
                                size="small"
                                defaultCurrent={1}
                                total={masterpostsLength}
                                pageSize={config.PAGINATION_PERPAGE}
                                current={this.state.pagination_currentPage}
                                onChange={this.paginationSetPage}
                            />
                        </div>
                    ) : null
                }
                bordered
                dataSource={masterposts}
                renderItem={(item, itemIndex) => (
                    <List.Item
                        key={itemIndex}
                        className={
                            this.listBulk && this.listBulk.isChecked(item.key)
                                ? this.listBulk.getCheckedLength() === 1
                                    ? "social_Selected_withTools"
                                    : "social_Selected"
                                : null
                        }
                        actions={[
                            <Tooltip
                                key="posts"
                                title={tooltips.INSIGHTS.LIST_POSTS}
                                mouseEnterDelay={
                                    tooltips.SETTINGS.mouseEnterDelay
                                }
                            >
                                <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    onClick={(_evt) => {
                                        this.handleJumpToPosts(item);
                                    }}
                                >
                                    <Icon type="pic-left" />
                                </Button>
                            </Tooltip>,
                            <Tooltip
                                key="boost"
                                title={tooltips.INSIGHTS.LIST_BOOST}
                                mouseEnterDelay={
                                    tooltips.SETTINGS.mouseEnterDelay
                                }
                            >
                                <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    onClick={(_evt) => {
                                        this.showAdsModal(_evt, item);
                                    }}
                                >
                                    <Icon type="line-chart" />
                                </Button>
                            </Tooltip>,
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <Fragment>
                                    <Checkbox
                                        onChange={(e) =>
                                            this.listBulk.handleCheck(item.key)
                                        }
                                        checked={
                                            this.listBulk
                                                ? this.listBulk.isChecked(
                                                      item.key
                                                  )
                                                : false
                                        }
                                        style={{
                                            marginRight: 10,
                                        }}
                                    />
                                    {item.name}
                                </Fragment>
                            }
                        />
                        <div>{item.insights["post_impressions"]}</div>
                        <div>{item.insights["post_clicks"]}</div>
                        <div>{item.insights["post_engaged_users"]}</div>
                        <div>{item.insights["post_engaged_fan"]}</div>
                        <div>{item.insights["post_reactions_like_total"]}</div>
                        <div>{item.insights["post_negative_feedback"]}</div>
                    </List.Item>
                )}
            />
        );

        const posts = this.getPostsListingDataSource();
        const postsLength = posts.length;
        const postsPagesCount = postsLength / config.PAGINATION_PERPAGE;
        console.info("posts", posts);
        let postsList = (
            <List
                size="small"
                className="InsightsList_Posts"
                header={
                    <Fragment>
                        <Row type="flex" justify="space-between">
                            <Col
                                style={{
                                    minWidth: 25,
                                    lineHeight: 1.2,
                                }}
                            >
                                <Checkbox
                                    // onChange={e => this.handleBulkAll(e)}
                                    onChange={(e) =>
                                        this.listBulk2.handleAll(
                                            e.target.checked
                                        )
                                    }
                                    style={{ marginRight: 10 }}
                                />
                                {this.listBulk &&
                                    this.listBulk.renderOptionsAsList()}
                            </Col>
                            <Col>
                                {/* {this.listBulk2 &&
                                    this.listBulk2.renderOptions()} */}
                                <Search
                                    placeholder="filter"
                                    onSearch={this.onPostsSearchChange}
                                    onChange={(e) =>
                                        this.onPostsSearchChange(e.target.value)
                                    }
                                    value={this.state.posts_filter}
                                    style={{ width: 200 }}
                                />
                            </Col>
                            <Col>
                                <RangePicker
                                    onChange={this.onRangeChange}
                                    defaultValue={[
                                        moment().subtract(3, "weeks"),
                                        moment(),
                                    ]}
                                    format="MM/DD/YYYY"
                                />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: 10,
                                fontSize: 12,
                                display: "flex",
                                flex: "1 1",
                                flexAlign: "start",
                                alignItems: "flex-start",
                            }}
                        >
                            <Col>
                                {/* <Checkbox
                                    // onChange={e => this.handleBulkAll(e)}
                                    onChange={(e) =>
                                        this.listBulk2.handleAll(
                                            e.target.checked
                                        )
                                    }
                                /> */}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting("posts_account")
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "posts_account"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    Account
                                </span>
                                {this.state.sortby === "posts_account" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting("posts_masterpost")
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "posts_masterpost"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    Masterpost
                                </span>
                                {this.state.sortby === "posts_masterpost" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting("posts_impressions")
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "posts_impressions"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    Impr.
                                </span>
                                {this.state.sortby === "posts_impressions" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting("posts_clicks")
                                    }
                                    style={{
                                        color:
                                            this.state.sortby === "posts_clicks"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    Clicks
                                </span>
                                {this.state.sortby === "posts_clicks" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting("posts_engaged_users")
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "posts_engaged_users"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    eng. users
                                </span>
                                {this.state.sortby === "posts_engaged_users" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting("posts_engaged_fan")
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "posts_engaged_fan"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    eng. fan
                                </span>
                                {this.state.sortby === "posts_engaged_fan" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting(
                                            "posts_reactions_like_total"
                                        )
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "posts_reactions_like_total"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    likes
                                </span>
                                {this.state.sortby ===
                                "posts_reactions_like_total" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <span
                                    className="social_sortlink"
                                    onClick={(e) =>
                                        this.setSorting(
                                            "posts_negative_feedback"
                                        )
                                    }
                                    style={{
                                        color:
                                            this.state.sortby ===
                                            "posts_negative_feedback"
                                                ? "green"
                                                : null,
                                    }}
                                >
                                    neg.
                                </span>
                                {this.state.sortby ===
                                "posts_negative_feedback" ? (
                                    <Icon
                                        type={
                                            this.state.sortasc === true
                                                ? "arrow-down"
                                                : "arrow-up"
                                        }
                                        style={{
                                            marginLeft: 5,
                                            color: "green",
                                        }}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </Fragment>
                }
                footer={
                    postsPagesCount > 1 && this.state.filter.length === 0 ? (
                        <div style={{ textAlign: "right" }}>
                            <Pagination
                                size="small"
                                defaultCurrent={1}
                                total={postsLength}
                                pageSize={config.PAGINATION_PERPAGE}
                                current={this.state.pagination_currentPage}
                                onChange={this.paginationSetPage}
                            />
                        </div>
                    ) : null
                }
                bordered
                dataSource={posts}
                renderItem={(item, itemIndex) => (
                    <List.Item
                        key={itemIndex}
                        className={
                            this.listBulk2 && this.listBulk2.isChecked(item.key)
                                ? this.listBulk2.getCheckedLength() === 1
                                    ? "social_Selected_withTools"
                                    : "social_Selected"
                                : null
                        }
                    >
                        <List.Item.Meta
                            title={
                                <Fragment>
                                    <Checkbox
                                        onChange={(e) =>
                                            this.listBulk2.handleCheck(item.key)
                                        }
                                        checked={
                                            this.listBulk2
                                                ? this.listBulk2.isChecked(
                                                      item.key
                                                  )
                                                : false
                                        }
                                        style={{
                                            marginRight: 10,
                                        }}
                                    />
                                    {item.account.name}
                                    {/* <br />({item.account.email}) */}
                                </Fragment>
                            }
                        />
                        <div
                            style={{
                                width: "40%",
                                textAling: "right",
                            }}
                        >
                            {item.masterpost.name}
                        </div>
                        <div>{item.insights_post_impressions}</div>
                        <div>{item.insights_post_clicks}</div>
                        <div>{item.insights_post_engaged_users}</div>
                        <div>{item.insights_post_engaged_fan}</div>
                        <div>{item.insights_post_reactions_like_total}</div>
                        <div>{item.insights_post_negative_feedback}</div>
                    </List.Item>
                )}
            />
        );

        let body = (
            <Fragment>
                <Tabs
                    defaultActiveKey="1"
                    activeKey={this.state.tabsKey}
                    onChange={this.handleTabsChange}
                >
                    <TabPane
                        tab={names.get("MASTERPOST", true, true)}
                        key="masterposts"
                    >
                        {masterpostsList}
                    </TabPane>
                    <TabPane tab="Posts" key="posts">
                        {postsList}
                    </TabPane>
                </Tabs>
            </Fragment>
        );

        return (
            <Fragment>
                {header}
                {body}
                {/* <CompetitorsListModal
                    data={this.state.edit_data}
                    store={this.props.store}
                /> */}
                <AdsListModal
                    // data={this.state.modal_ad_data}
                    store={this.props.store}
                    ref={this.adsModal}
                />
            </Fragment>
        );
    }
}

export default observer(InsightsList);
