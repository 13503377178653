import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AccountsList from "./AccountsList";
import AccountsReport from "./AccountsReport";
import AccountsQueue from "./AccountsQueue";
import AccountsChannelsList from "./AccountsChannelsList";
import AccountsChannel from "./AccountsChannel";

class Accounts extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.store.setCurrentSection("accounts");
        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Accounts" }]);
    }

    render() {
        return (
            <Switch>
                <Route
                    path="/dashboard/accounts/:id/report"
                    render={props => (
                        <AccountsReport store={this.props.store} {...props} />
                    )}
                />
                <Route
                    path="/dashboard/accounts/:id/queue"
                    render={props => (
                        <AccountsQueue store={this.props.store} {...props} />
                    )}
                />
                <Route
                    path="/dashboard/accounts/:id/channels"
                    render={props => (
                        <AccountsChannelsList
                            store={this.props.store}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/dashboard/accounts/:id/channel/:provider/:channel_id"
                    render={props => (
                        <AccountsChannel store={this.props.store} {...props} />
                    )}
                />
                <Route
                    path="/dashboard/accounts/filter/:filter"
                    render={props => (
                        <AccountsList store={this.props.store} {...props} />
                    )}
                />
                <Route
                    render={props => (
                        <AccountsList store={this.props.store} {...props} />
                    )}
                />
            </Switch>
        );
    }
}

export default Accounts;
