import React, { Fragment, Component } from "react";
import AccountsChannelsModal from "./../Accounts/AccountsChannelsModal";
import { social_notification } from "./../../functions";
import { Badge, Icon, Tooltip, Modal, Alert } from "antd";

class WarningMessagesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urgentMessages: [],
            modal_visible: false,
        };
    }
    async componentDidMount() {
        let that = this;
        if (this.props.store.accounts.length === 0) {
            this.props.store.callbacks.push({
                event: "accounts_loaded",
                function() {
                    that.loadUrgentMessages();
                },
            });
        } else {
            // load the procedure
            this.loadUrgentMessages();
        }

        this.props.store.callbacks.push({
            event: "hide_modal",
            function(_payload) {
                console.log("hide modal _payload", _payload);
                if (_payload === "accounts_channels_modal")
                    that.loadUrgentMessages();
            },
        });
    }

    async loadUrgentMessages() {
        console.log("loadUrgentMessages");
        let urgentMessages = await this.props.store.getUrgentMessages();
        this.setState({ urgentMessages });
        console.info("urgentMessages", urgentMessages);
    }

    clickOnIcon = async (_e) => {
        this.setState({ modal_visible: !this.state.modal_visible });
    };

    clickOnMessage = async (_e, _message) => {
        console.info("clickOnMessage _message", _message);

        switch (_message.action.type) {
            case "open_channel":
                // load the channel's information
                await this.props.store
                    .getAccount(_message.action.payload.account_key)
                    .then((accountData) => {
                        this.setState({
                            account: accountData,
                        });

                        return this.props.store.getAccountChannels(
                            _message.action.payload.account_key
                        );
                    })
                    .then((channels) => {
                        console.info("AccountsChannels channels", channels);
                        const channel = channels.filter(
                            (channel) =>
                                channel.id ===
                                _message.action.payload.channel_id
                        )[0];
                        this.setState({
                            selected_channel: channel,
                        });
                    })
                    .catch((error) => {
                        social_notification(
                            "account_reload_channels",
                            "error",
                            {
                                description: error,
                            }
                        );
                    });

                // this.setState({
                //     account: { key: _message.action.payload.account_key },
                //     selected_channel: {
                //         id: _message.action.payload.channel_id,
                //     },
                // });
                // alert("Here");
                this.props.store.showModalByKey("accounts_channels_modal");
                break;
            default:
                break;
        }
    };
    closeOnMessage = async (_e, _index) => {
        if (_e) {
            _e.preventDefault();
            _e.stopPropagation();
        }

        let { urgentMessages } = this.state;
        urgentMessages.splice(_index, 1);
        this.setState({ urgentMessages });
    };

    render() {
        const warningIcon =
            this.state.urgentMessages.length > 0 ? (
                <Tooltip
                    title={`There are ${
                        this.state.urgentMessages.length
                    } warning message${
                        this.state.urgentMessages.length !== 1 ? "s" : null
                    }`}
                    onClick={(e) => this.clickOnIcon(e)}
                >
                    <Badge
                        count={this.state.urgentMessages.length}
                        style={{
                            right: 7,
                            transform: "scale(0.8)",
                            cursor: "pointer",
                        }}
                    >
                        <Icon
                            style={{
                                marginRight: 15,
                                color: "#ffa900",
                                cursor: "pointer",
                                fontSize: 28,
                                marginTop: 2,
                            }}
                            type="exclamation-circle"
                        />
                    </Badge>
                </Tooltip>
            ) : (
                <Tooltip title="There are no warning messages. Yay !!">
                    <Icon
                        style={{
                            fontSize: "1.3rem",
                            marginRight: 15,
                            marginTop: 6,
                            color: "#e0e0e0",
                            cursor: "pointer",
                        }}
                        type="exclamation-circle"
                    />
                </Tooltip>
            );

        return (
            <Fragment>
                {warningIcon}
                {this.state.modal_visible ? (
                    <Modal
                        width={"70vw"}
                        visible={true}
                        title={"Warning Messages"}
                        footer={null}
                        // okText={data && !data.email ? null : "Save"}
                        onCancel={(e) => {
                            this.setState({ modal_visible: false });
                        }}
                        // onOk={
                        //     data && !data.email
                        //         ? null //this.onModalCreate
                        //         : this.onModalEdit
                        // }
                        // okButtonProps={
                        //     data && !data.email
                        //         ? { style: { display: "none" } }
                        //         : null
                        // }
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <div className={"social_Dashboard_urgentMessagesList"}>
                            {this.state.urgentMessages.map(
                                (uMessage, uMessageIndex) => {
                                    const className = `social_${uMessage.type}`;
                                    return (
                                        <div
                                            key={uMessageIndex}
                                            onClick={(e) =>
                                                this.clickOnMessage(e, uMessage)
                                            }
                                            className={className}
                                        >
                                            {uMessage.text}
                                            <div>Click to open</div>
                                            <button
                                                onClick={(e) => {
                                                    this.closeOnMessage(
                                                        e,
                                                        uMessageIndex
                                                    );
                                                }}
                                            >
                                                X
                                            </button>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </Modal>
                ) : null}

                <AccountsChannelsModal
                    store={this.props.store}
                    account={this.state.account}
                    data={this.state.selected_channel}
                    closeCallback={this.onModalCallback}
                />
            </Fragment>
        );
    }
}

export default WarningMessagesList;
