import React, { Component, Fragment } from "react";
import { Button, Form, Modal, Divider, Icon, Alert } from "antd";
import { observer } from "mobx-react";
import names from "./../../names";

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        async componentDidMount() {
            // To disable submit button at the beginning.

            const { campaign } = this.props.data;
            const availableMasterposts = this.props.store.getAllMasterposts;
            const availableMasterads = this.props.store.getAllMasterads;

            let filteredMasterpostsKeys = availableMasterposts
                ? availableMasterposts
                      .filter(
                          (m) =>
                              m.campaigns &&
                              m.campaigns.indexOf(campaign) !== -1
                      )
                      .map((m) => m.key)
                : [];
            let filteredMasteradsKeys = availableMasterads
                ? availableMasterads
                      .filter(
                          (a) =>
                              a.campaigns &&
                              a.campaigns.indexOf(campaign) !== -1
                      )
                      .map((a) => a.key)
                : [];
            // console.info(
            //     "componentDidMount filteredMasterpostsKeys",
            //     filteredMasterpostsKeys
            // );
            // console.info(
            //     "componentDidMount filteredMasteradsKeys",
            //     filteredMasteradsKeys
            // );

            // Rollover masterposts

            let total_posts = 0,
                total_post_impressions = 0,
                total_post_clicks = 0,
                total_post_reactions_like = 0,
                total_post_reactions_love = 0,
                total_post_reactions_wow = 0,
                total_post_reactions_haha = 0,
                total_post_reactions_sorry = 0,
                total_post_reactions_anger = 0,
                total_post_comments = 0;

            await filteredMasterpostsKeys.map(async (mp_key) => {
                const masterpostStatus =
                    await this.props.store.getMasterpostStatus(mp_key);
                //console.info("masterpostStatus", masterpostStatus);
                total_posts = total_posts + masterpostStatus.posts.length;

                await masterpostStatus.posts.map(async (post) => {
                    post.results &&
                        post.results.map(async (result) => {
                            if (result.insights) {
                                //console.info("result", result);
                                result.insights.data.map((data) => {
                                    // console.log(
                                    //     "data.name = " +
                                    //         data.name +
                                    //         " > " +
                                    //         data.values[0].value
                                    // );
                                    switch (data.name) {
                                        case "post_impressions":
                                            total_post_impressions =
                                                total_post_impressions +
                                                data.values[0].value;
                                            break;
                                        case "post_clicks":
                                            total_post_clicks =
                                                total_post_clicks +
                                                data.values[0].value;
                                            break;
                                        case "post_reactions_like_total":
                                            total_post_reactions_like =
                                                total_post_reactions_like +
                                                data.values[0].value;
                                            break;
                                        case "post_reactions_love_total":
                                            total_post_reactions_love =
                                                total_post_reactions_love +
                                                data.values[0].value;
                                            break;
                                        case "post_reactions_wow_total":
                                            total_post_reactions_wow =
                                                total_post_reactions_wow +
                                                data.values[0].value;
                                            break;
                                        case "post_reactions_haha_total":
                                            total_post_reactions_haha =
                                                total_post_reactions_haha +
                                                data.values[0].value;
                                            break;
                                        case "post_reactions_sorry_total":
                                            total_post_reactions_sorry =
                                                total_post_reactions_sorry +
                                                data.values[0].value;
                                            break;
                                        case "post_reactions_anger_total":
                                            total_post_reactions_anger =
                                                total_post_reactions_anger +
                                                data.values[0].value;
                                            break;
                                        default:
                                            break;
                                    }
                                    return false;
                                });
                            }
                            // comments
                            if (result.comments) {
                                total_post_comments =
                                    total_post_comments +
                                    result.comments.data.length;
                            }
                        });
                });
            });

            const stats = {
                total_posts,
                total_post_impressions,
                total_post_clicks,
                total_post_reactions_like,
                total_post_reactions_love,
                total_post_reactions_wow,
                total_post_reactions_haha,
                total_post_reactions_sorry,
                total_post_reactions_anger,
                total_post_reactions_total:
                    total_post_clicks +
                    total_post_reactions_like +
                    total_post_reactions_love +
                    total_post_reactions_wow +
                    total_post_reactions_haha +
                    total_post_reactions_sorry +
                    total_post_reactions_anger,
                total_post_comments,
            };
            console.info("stats", stats);

            this.setState({
                campaign,
                masterposts: filteredMasterpostsKeys,
                masterads: filteredMasteradsKeys,
                stats,
            });
        }

        render() {
            // const {
            //     getFieldDecorator,
            //     // getFieldsError,
            //     // getFieldError,
            //     // isFieldTouched
            // } = this.props.form;

            // const { data } = this.props;
            // Only show error after a field is touched.
            // const usernameError =
            //     isFieldTouched("username") && getFieldError("username");
            // const passwordError =
            //     isFieldTouched("password") && getFieldError("password");

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            // console.info("RENDER availableMasterposts", availableMasterposts);

            return (
                <Form {...formItemLayout}>
                    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                    {/* <ReactJson
                        src={this.state.stats}
                        displayDataTypes={false}
                    /> */}
                    <Form.Item label="Name">
                        <h2
                            style={{
                                backgroundColor:
                                    this.props.store.getTagColorForType(
                                        "campaign"
                                    ),
                                padding: "0px 15px",
                                color: "white",
                            }}
                        >
                            {this.state.campaign}
                        </h2>
                    </Form.Item>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total {names.get("MASTERPOST", true, true)}
                    </Divider>
                    <h1>
                        {this.state.masterposts &&
                            this.state.masterposts.length}
                    </h1>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Posts
                    </Divider>
                    <h1>{this.state.stats && this.state.stats.total_posts}</h1>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Impressions
                    </Divider>
                    <h1>
                        {this.state.stats &&
                            this.state.stats.total_post_impressions}
                    </h1>
                    {/* 
                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Audience Growth
                    </Divider>
                    <h1>N/A</h1> */}

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Engagement
                    </Divider>
                    <h1>
                        {this.state.stats &&
                            this.state.stats.total_post_reactions_total}
                    </h1>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Messages
                    </Divider>
                    <h1>
                        {this.state.stats &&
                            this.state.stats.total_post_comments}
                    </h1>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Master ads
                    </Divider>
                    <h1>
                        {this.state.masterads && this.state.masterads.length}
                    </h1>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class CampaignsListModalPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "campaigns_modal_performance",
            (_action, _payload) => {
                // console.info(
                //     "CAMPAIGNS_MODAL _action",
                //     _action,
                //     "_payload",
                //     _payload
                // );
                if (_payload) {
                    this.setState({ data: _payload });
                } else {
                    this.setState({ data: {} });
                }
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("campaigns_modal_performance");
    };

    onModalApply = async () => {
        const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("onModalApply formState: ", formState);

        form.validateFields(async (err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                // social_notification("validation_error", "error", {
                //     description: err,
                // });
                console.warn("ERROR: ", err);
                return;
            }

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            // everything is fine
            console.log("everything is fine");

            if (
                JSON.stringify(formState.original_masterposts) !==
                    JSON.stringify(formState.masterposts) ||
                formState.original_campaign !== formState.campaign
            ) {
                // There is change on the masterposts lists
                // remove the old campaign from the original masterposts
                await this.props.store.removeCampaignsToMasterposts({
                    masterposts: JSON.parse(
                        JSON.stringify(formState.original_masterposts)
                    ),
                    campaigns: [formState.original_campaign],
                });
                console.log("DONE REMOVING OLD CAMPAIGN");

                await this.props.store.applyCampaignsToMasterposts({
                    masterposts: JSON.parse(
                        JSON.stringify(formState.masterposts)
                    ),
                    campaigns: [formState.campaign],
                });
                console.log("DONE ADDING THE NEW CAMPAIGN");
            }

            if (
                JSON.stringify(formState.original_masterads) !==
                    JSON.stringify(formState.masterads) ||
                formState.original_campaign !== formState.campaign
            ) {
                console.info("WE HAVE TO UPDATE THE ADS !!!!");
                // There is change on the masterads lists
                // remove the old campaign from the original masterads
                const removeStatus =
                    await this.props.store.removeCampaignsToMasterads({
                        masterads: JSON.parse(
                            JSON.stringify(formState.original_masterads)
                        ),
                        campaigns: [formState.original_campaign],
                    });
                console.log(
                    "DONE REMOVING OLD CAMPAIGN removeStatus=" + removeStatus
                );

                const applyStatus =
                    await this.props.store.applyCampaignsToMasterads({
                        masterads: JSON.parse(
                            JSON.stringify(formState.masterads)
                        ),
                        campaigns: [formState.campaign],
                    });
                console.log(
                    "DONE ADDING THE NEW CAMPAIGN applyStatus=" + applyStatus
                );
            }

            form.resetFields();
            this.onModalCancel(); // hide the modal
        }).catch((error) => {
            console.warn(error);
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "campaigns_modal_performance",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Campaign performance"}
                        //okText={"Apply"}
                        onCancel={this.onModalCancel}
                        //onOk={this.onModalApply} //onApply
                        maskClosable={false}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={this.onModalCancel}
                            >
                                Close
                            </Button>,
                        ]}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={this.props.data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(CampaignsListModalPerformance);
