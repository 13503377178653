import React, { Component, Fragment } from "react";

// import welcome from "./../welcome.png";

import { Button, Modal } from "antd";

class TermsModal extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store &&
            this.props.store.registerModalListener("terms_modal", (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            });
    };

    componentWillUnmount = () => {
        this.props.store &&
            this.props.store.unregisterModalListener("terms_modal");
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store &&
            this.props.store.hideModalByKey(
                "terms_modal",
                this.formRef ? this.formRef.props.form.isFieldsTouched() : false
            );
    };

    showModal = () => {
        console.log("showModal");

        this.props.store && this.props.store.showModalByKey("terms_modal");
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        width={"80vw"}
                        height={"80vh"}
                        title={"Terms and conditions"}
                        maskClosable={false}
                        onCancel={this.onModalCancel}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={this.onModalCancel}
                            >
                                Close
                            </Button>,
                        ]}
                    >
                        <div
                            style={{
                                height: "50vh",
                                overflowX: "hidden",
                                overflowY: "auto",
                            }}
                        >
                            <h6>Version Date: October 06, 2019</h6>
                            <h3>TERMS OF USE AGREEMENT</h3>
                            <p>
                                <p>
                                    This Terms of Use Agreement ("Agreement")
                                    constitutes a legally binding agreement made
                                    between you, whether personally or on behalf
                                    of an entity ("user" or "you") and Picklings
                                    Corporation and its affiliated companies
                                    (collectively, "Company" or "we" or "us" or
                                    "our"), concerning your access to and use of
                                    the uhub.app website as well as any other
                                    media form, media channel, mobile website or
                                    mobile application related or connected
                                    thereto (collectively, the "Website"). The
                                    Website provides the following service:
                                    Social media services ("Company Services").
                                    Supplemental terms and conditions or
                                    documents that may be posted on the Website
                                    from time to time, are hereby expressly
                                    incorporated into this Agreement by
                                    reference.
                                </p>
                                <p>
                                    Company makes no representation that the
                                    Website is appropriate or available in other
                                    locations other than where it is operated by
                                    Company. The information provided on the
                                    Website is not intended for distribution to
                                    or use by any person or entity in any
                                    jurisdiction or country where such
                                    distribution or use would be contrary to law
                                    or regulation or which would subject Company
                                    to any registration requirement within such
                                    jurisdiction or country. Accordingly, those
                                    persons who choose to access the Website
                                    from other locations do so on their own
                                    initiative and are solely responsible for
                                    compliance with local laws, if and to the
                                    extent local laws are applicable.
                                </p>
                                <p>
                                    All users who are minors in the jurisdiction
                                    in which they reside (generally under the
                                    age of 18) are not permitted to register for
                                    the Website or use the Company Services.
                                </p>
                                <p>
                                    YOU ACCEPT AND AGREE TO BE BOUND BY THIS
                                    AGREEMENT BY ACKNOWLEDGING SUCH ACCEPTANCE
                                    DURING THE REGISTRATION PROCESS (IF
                                    APPLICABLE) AND ALSO BY CONTINUING TO USE
                                    THE WEBSITE. IF YOU DO NOT AGREE TO ABIDE BY
                                    THIS AGREEMENT, OR TO MODIFICATIONS THAT
                                    COMPANY MAY MAKE TO THIS AGREEMENT IN THE
                                    FUTURE, DO NOT USE OR ACCESS OR CONTINUE TO
                                    USE OR ACCESS THE COMPANY SERVICES OR THE
                                    WEBSITE.
                                </p>
                                <h2>PURCHASES; PAYMENT</h2>
                                <p>
                                    Company bills you through an online billing
                                    account for purchases of products and/or
                                    services. You agree to pay Company all
                                    charges at the prices then in effect for the
                                    products you or other persons using your
                                    billing account may purchase, and you
                                    authorize Company to charge your chosen
                                    payment provider for any such purchases. You
                                    agree to make payment using that selected
                                    payment method. If you have ordered a
                                    product or service that is subject to
                                    recurring charges then you consent to our
                                    charging your payment method on a recurring
                                    basis, without requiring your prior approval
                                    from you for each recurring charge until
                                    such time as you cancel the applicable
                                    product or service. Company reserves the
                                    right to correct any errors or mistakes in
                                    pricing that it makes even if it has already
                                    requested or received payment. Sales tax
                                    will be added to the sales price of
                                    purchases as deemed required by Company.
                                    Company may change prices at any time. All
                                    payments shall be in U.S. dollars.
                                </p>
                                <h2>REFUND POLICY</h2>
                                <p>
                                    All sales are final and no refunds shall be
                                    issued.
                                </p>
                                <p>
                                    <strong>USER REPRESENTATIONS</strong>
                                </p>
                                <p>
                                    <strong>
                                        <em>Regarding Your Registration</em>
                                    </strong>
                                </p>
                                <p>
                                    By using the Company Services, you represent
                                    and warrant that:
                                </p>
                                <p>
                                    A. all registration information you submit
                                    is truthful and accurate;
                                </p>
                                <p>
                                    B. you will maintain the accuracy of such
                                    information;
                                </p>
                                <p>
                                    C. you will keep your password confidential
                                    and will be responsible for all use of your
                                    password and account;
                                </p>
                                <p>
                                    D. you are not a minor in the jurisdiction
                                    in which you reside, or if a minor, you have
                                    received parental permission to use this
                                    Website; and
                                </p>
                                <p>
                                    E. your use of the Company Services does not
                                    violate any applicable law or regulation.
                                </p>
                                <p>
                                    You also agree to: (a) provide true,
                                    accurate, current and complete information
                                    about yourself as prompted by the Website's
                                    registration form and (b) maintain and
                                    promptly update registration data to keep it
                                    true, accurate, current and complete. If you
                                    provide any information that is untrue,
                                    inaccurate, not current or incomplete, or
                                    Company has reasonable grounds to suspect
                                    that such information is untrue, inaccurate,
                                    not current or incomplete, Company has the
                                    right to suspend or terminate your account
                                    and refuse any and all current or future use
                                    of the Website (or any portion thereof).
                                </p>
                                <p>
                                    <strong>
                                        <em>Regarding Content You Provide</em>
                                    </strong>
                                </p>
                                <p>
                                    The Website may invite you to chat or
                                    participate in blogs, message boards, online
                                    forums and other functionality and may
                                    provide you with the opportunity to create,
                                    submit, post, display, transmit, perform,
                                    publish, distribute or broadcast content and
                                    materials to Company and/or to or via the
                                    Website, including, without limitation,
                                    text, writings, video, audio, photographs,
                                    graphics, comments, suggestions or
                                    personally identifiable information or other
                                    material (collectively "Contributions"). Any
                                    Contributions you transmit to Company will
                                    be treated as non-confidential and
                                    non-proprietary. When you create or make
                                    available a Contribution, you thereby
                                    represent and warrant that:
                                </p>
                                <p>
                                    A. the creation, distribution, transmission,
                                    public display and performance, accessing,
                                    downloading and copying of your Contribution
                                    does not and will not infringe the
                                    proprietary rights, including but not
                                    limited to the copyright, patent, trademark,
                                    trade secret or moral rights of any third
                                    party;
                                </p>
                                <p>
                                    B. you are the creator and owner of or have
                                    the necessary licenses, rights, consents,
                                    releases and permissions to use and to
                                    authorize Company and the Website users to
                                    use your Contributions as necessary to
                                    exercise the licenses granted by you under
                                    this Agreement;
                                </p>
                                <p>
                                    C. you have the written consent, release,
                                    and/or permission of each and every
                                    identifiable individual person in the
                                    Contribution to use the name or likeness of
                                    each and every such identifiable individual
                                    person to enable inclusion and use of the
                                    Contribution in the manner contemplated by
                                    this Website;
                                </p>
                                <p>
                                    D. your Contribution is not obscene, lewd,
                                    lascivious, filthy, violent, harassing or
                                    otherwise objectionable (as determined by
                                    Company), libelous or slanderous, does not
                                    ridicule, mock, disparage, intimidate or
                                    abuse anyone, does not advocate the violent
                                    overthrow of any government, does not
                                    incite, encourage or threaten physical harm
                                    against another, does not violate any
                                    applicable law, regulation, or rule, and
                                    does not violate the privacy or publicity
                                    rights of any third party;
                                </p>
                                <p>
                                    E. your Contribution does not contain
                                    material that solicits personal information
                                    from anyone under 18 or exploit people under
                                    the age of 18 in a sexual or violent manner,
                                    and does not violate any federal or state
                                    law concerning child pornography or
                                    otherwise intended to protect the health or
                                    well-being of minors;
                                </p>
                                <p>
                                    F. your Contribution does not include any
                                    offensive comments that are connected to
                                    race, national origin, gender, sexual
                                    preference or physical handicap;
                                </p>
                                <p>
                                    G. your Contribution does not otherwise
                                    violate, or link to material that violates,
                                    any provision of this Agreement or any
                                    applicable law or regulation.
                                </p>
                                <p>
                                    <strong>CONTRIBUTION LICENSE</strong>
                                </p>
                                <p>
                                    By posting Contributions to any part of the
                                    Website, or making them accessible to the
                                    Website by linking your account to any of
                                    your social network accounts, you
                                    automatically grant, and you represent and
                                    warrant that you have the right to grant, to
                                    Company an unrestricted, unconditional,
                                    unlimited, irrevocable, perpetual,
                                    non-exclusive, transferable, royalty-free,
                                    fully-paid, worldwide right and license to
                                    host, use, copy, reproduce, disclose, sell,
                                    resell, publish, broadcast, retitle,
                                    archive, store, cache, publicly perform,
                                    publicly display, reformat, translate,
                                    transmit, excerpt (in whole or in part) and
                                    distribute such Contributions (including,
                                    without limitation, your image and voice)
                                    for any purpose, commercial, advertising, or
                                    otherwise, to prepare derivative works of,
                                    or incorporate into other works, such
                                    Contributions, and to grant and authorize
                                    sublicenses of the foregoing. The use and
                                    distribution may occur in any media formats
                                    and through any media channels. Such use and
                                    distribution license will apply to any form,
                                    media, or technology now known or hereafter
                                    developed, and includes our use of your
                                    name, company name, and franchise name, as
                                    applicable, and any of the trademarks,
                                    service marks, trade names and logos,
                                    personal and commercial images you provide.
                                    Company does not assert any ownership over
                                    your Contributions; rather, as between us
                                    and you, subject to the rights granted to us
                                    in this Agreement, you retain full ownership
                                    of all of your Contributions and any
                                    intellectual property rights or other
                                    proprietary rights associated with your
                                    Contributions.
                                </p>
                                <p>
                                    Company has the right, in our sole and
                                    absolute discretion, to (i) edit, redact or
                                    otherwise change any Contributions, (ii)
                                    re-categorize any Contributions to place
                                    them in more appropriate locations or (iii)
                                    pre-screen or delete any Contributions that
                                    are determined to be inappropriate or
                                    otherwise in violation of this Agreement.
                                </p>
                                <p>
                                    By uploading your Contributions to the
                                    Website, you hereby authorize Company to
                                    grant to each end user a personal, limited,
                                    no-transferable, perpetual, non-exclusive,
                                    royalty-free, fully-paid license to access,
                                    download, print and otherwise use your
                                    Contributions for t heir internal purposes
                                    and not for distribution, transfer, sale or
                                    commercial exploitation of any kind.
                                </p>
                                <h2></h2>
                                <p>
                                    <strong>
                                        GUIDELINES FOR REVIEWS
                                        <br />
                                        <br />
                                    </strong>
                                </p>
                                <p>
                                    Company may accept, reject or remove reviews
                                    in its sole discretion. Company has
                                    absolutely no obligation to screen reviews
                                    or to delete reviews, even if anyone
                                    considers reviews objectionable or
                                    inaccurate. Those persons posting reviews
                                    should comply with the following criteria:
                                    (1) reviewers should have firsthand
                                    experience with the person/entity being
                                    reviewed; (2) reviews should not contain:
                                    offensive language, profanity, or abusive,
                                    racist, or hate language; discriminatory
                                    references based on religion, race, gender,
                                    national origin, age, marital status, sexual
                                    orientation or disability; or references to
                                    illegal activity; (3) reviewers should not
                                    be affiliated with competitors if posting
                                    negative reviews; (4) reviewers should not
                                    make any conclusions as to the legality of
                                    conduct; and (5) reviewers may not post any
                                    false statements or organize a campaign
                                    encouraging others to post reviews, whether
                                    positive or negative. Reviews are not
                                    endorsed by Company, and do not represent
                                    the views of Company or of any affiliate or
                                    partner of Company. Company does not assume
                                    liability for any review or for any claims,
                                    liabilities or losses resulting from any
                                    review. By posting a review, the reviewer
                                    hereby grants to Company a perpetual,
                                    non-exclusive, worldwide, royalty-free,
                                    fully-paid, assignable and sublicensable
                                    license to Company to reproduce, modify,
                                    translate, transmit by any means, display,
                                    perform and/or distribute all content
                                    relating to reviews.
                                </p>
                                <p>
                                    <strong>MOBILE APPLICATION LICENSE</strong>
                                </p>
                                <p>
                                    <strong>
                                        <em>Use License</em>
                                    </strong>
                                </p>
                                <p>
                                    If you are accessing the Company Services
                                    via a mobile application, then Company
                                    grants you a revocable, non-exclusive,
                                    non-transferable, limited right to install
                                    and use the application on wireless handsets
                                    owned and controlled by you, and to access
                                    and use the application on such devices
                                    strictly in accordance with the terms and
                                    conditions of this license. You shall use
                                    the application strictly in accordance with
                                    the terms of this license and shall not: (a)
                                    decompile, reverse engineer, disassemble,
                                    attempt to derive the source code of, or
                                    decrypt the application; (b) make any
                                    modification, adaptation, improvement,
                                    enhancement, translation or derivative work
                                    from the application; (c) violate any
                                    applicable laws, rules or regulations in
                                    connection with your access or use of the
                                    application; (d) remove, alter or obscure
                                    any proprietary notice (including any notice
                                    of copyright or trademark) of Company or its
                                    affiliates, partners, suppliers or the
                                    licensors of the application; (e) use the
                                    application for any revenue generating
                                    endeavor, commercial enterprise, or other
                                    purpose for which it is not designed or
                                    intended; (f) make the application available
                                    over a network or other environment
                                    permitting access or use by multiple devices
                                    or users at the same time; (g) use the
                                    application for creating a product, service
                                    or software that is, directly or indirectly,
                                    competitive with or in any way a substitute
                                    for the application; (h) use the application
                                    to send automated queries to any website or
                                    to send any unsolicited commercial e-mail;
                                    or (i) use any proprietary information or
                                    interfaces of Company or other intellectual
                                    property of Company in the design,
                                    development, manufacture, licensing or
                                    distribution of any applications,
                                    accessories or devices for use with the
                                    application.
                                </p>
                                <p>
                                    <strong>
                                        <em>
                                            Terms Applicable to Apple and
                                            Android Devices
                                        </em>
                                    </strong>
                                </p>
                                <p>
                                    The following terms apply when you use a
                                    mobile application obtained from either the
                                    Apple Store or Google Play to access the
                                    Company Services. You acknowledge that this
                                    Agreement is concluded between you and
                                    Company only, and not with Apple Inc. or
                                    Google, Inc. (each an "App Distributor"),
                                    and Company, not an App Distributor, is
                                    solely responsible for the Company
                                    application and the content thereof. (1)
                                    SCOPE OF LICENSE: The license granted to you
                                    for the Company application is limited to a
                                    non-transferable license to use the Company
                                    application on a device that utilizes the
                                    Apple iOS or Android operating system, as
                                    applicable, and in accordance with the usage
                                    rules set forth in the applicable App
                                    Distributor terms of service. (2)
                                    MAINTENANCE AND SUPPORT: Company is solely
                                    responsible for providing any maintenance
                                    and support services with respect to the
                                    Company application, as specified in this
                                    Agreement, or as required under applicable
                                    law. You acknowledge that each App
                                    Distributor has no obligation whatsoever to
                                    furnish any maintenance and support services
                                    with respect to the Company application. (3)
                                    WARRANTY: Company is solely responsible for
                                    any product warranties, whether express or
                                    implied by law, to the extent not
                                    effectively disclaimed. In the event of any
                                    failure of the Company application to
                                    conform to any applicable warranty, you may
                                    notify an App Distributor, and the App
                                    Distributor, in accordance with its terms
                                    and policies, may refund the purchase price,
                                    if any, paid for the Company application,
                                    and to the maximum extent permitted by
                                    applicable law, an App Distributor will have
                                    no other warranty obligation whatsoever with
                                    respect to the Company application, and any
                                    other claims, losses, liabilities, damages,
                                    costs or expenses attributable to any
                                    failure to conform to any warranty will be
                                    Company's sole responsibility. (4) PRODUCT
                                    CLAIMS: You acknowledge that Company, not an
                                    App Distributor, is responsible for
                                    addressing any claims of yours or any third
                                    party relating to the Company application or
                                    your possession and/or use of the Company
                                    application, including, but not limited to:
                                    (i) product liability claims; (ii) any claim
                                    that the Company application fails to
                                    conform to any applicable legal or
                                    regulatory requirement; and (iii) claims
                                    arising under consumer protection or similar
                                    legislation. (5) INTELLECTUAL PROPERTY
                                    RIGHTS: You acknowledge that, in the event
                                    of any third party claim that the Company
                                    application or your possession and use of
                                    the Company application infringes a third
                                    party's intellectual property rights, the
                                    App Distributor will not be responsible for
                                    the investigation, defense, settlement and
                                    discharge of any such intellectual property
                                    infringement claim. (6) LEGAL COMPLIANCE:
                                    You represent and warrant that (i) you are
                                    not located in a country that is subject to
                                    a U.S. government embargo, or that has been
                                    designated by the U.S. government as a
                                    "terrorist supporting" country; and (ii) you
                                    are not listed on any U.S. government list
                                    of prohibited or restricted parties. (7)
                                    THIRD PARTY TERMS OF AGREEMENT: You must
                                    comply with applicable third party terms of
                                    agreement when using the Company
                                    application, e.g., if you have a VoIP
                                    application, then you must not be in
                                    violation of their wireless data service
                                    agreement when using the Company
                                    application. (8) THIRD PARTY BENEFICIARY:
                                    Company and you acknowledge and agree that
                                    the App Distributors, and their
                                    subsidiaries, are third party beneficiaries
                                    of this Agreement, and that, upon your
                                    acceptance of the terms and conditions of
                                    this Agreement, each App Distributor will
                                    have the right (and will be deemed to have
                                    accepted the right) to enforce this
                                    Agreement against you as a third party
                                    beneficiary thereof.
                                </p>
                                <h2>SOCIAL MEDIA</h2>
                                <p>
                                    As part of the functionality of the Website,
                                    you may link your account with online
                                    accounts you may have with third party
                                    service providers (each such account, a
                                    "Third Party Account") by either: (i)
                                    providing your Third Party Account login
                                    information through the Website; or (ii)
                                    allowing Company to access your Third Party
                                    Account, as is permitted under the
                                    applicable terms and conditions that govern
                                    your use of each Third Party Account. You
                                    represent that you are entitled to disclose
                                    your Third Party Account login information
                                    to Company and/or grant Company access to
                                    your Third Party Account (including, but not
                                    limited to, for use for the purposes
                                    described herein), without breach by you of
                                    any of the terms and conditions that govern
                                    your use of the applicable Third Party
                                    Account and without obligating Company to
                                    pay any fees or making Company subject to
                                    any usage limitations imposed by such third
                                    party service providers. By granting Company
                                    access to any Third Party Accounts, you
                                    understand that (i) Company may access, make
                                    available and store (if applicable) any
                                    content that you have provided to and stored
                                    in your Third Party Account (the "Social
                                    Network Content") so that it is available on
                                    and through the Website via your account,
                                    including without limitation any friend
                                    lists, and (ii) Company may submit and
                                    receive additional information to your Third
                                    Party Account to the extent you are notified
                                    when you link your account with the Third
                                    Party Account. Depending on the Third Party
                                    Accounts you choose and subject to the
                                    privacy settings that you have set in such
                                    Third Party Accounts, personally
                                    identifiable information that you post to
                                    your Third Party Accounts may be available
                                    on and through your account on the Website.
                                    Please note that if a Third Party Account or
                                    associated service becomes unavailable or
                                    Company's access to such Third Party Account
                                    is terminated by the third party service
                                    provider, then Social Network Content may no
                                    longer be available on and through the
                                    Website. You will have the ability to
                                    disable the connection between your account
                                    on the Website and your Third Party Accounts
                                    at any time. PLEASE NOTE THAT YOUR
                                    RELATIONSHIP WITH THE THIRD PARTY SERVICE
                                    PROVIDERS ASSOCIATED WITH YOUR THIRD PARTY
                                    ACCOUNTS IS GOVERNED SOLELY BY YOUR
                                    AGREEMENT(S) WITH SUCH THIRD PARTY SERVICE
                                    PROVIDERS. Company makes no effort to review
                                    any Social Network Content for any purpose,
                                    including but not limited to, for accuracy,
                                    legality or non-infringement, and Company is
                                    not responsible for any Social Network
                                    Content. You acknowledge and agree that
                                    Company may access your e-mail address book
                                    associated with a Third Party Account and
                                    your contacts list stored on your mobile
                                    device or tablet computer solely for the
                                    purposes of identifying and informing you of
                                    those contacts who have also registered to
                                    use the Website. At your request made via
                                    email to{" "}
                                    <a href="https://www.taskrabbit.com/privacy@taskrabbit.com">
                                        our
                                    </a>{" "}
                                    email address listed below, or through your
                                    account settings (if applicable), Company
                                    will deactivate the connection between the
                                    Website and your Third Party Account and
                                    delete any information stored on Company's
                                    servers that was obtained through such Third
                                    Party Account, except the username and
                                    profile picture that become associated with
                                    your account.
                                </p>
                                <h2></h2>
                                <h2>SUBMISSIONS</h2>
                                <p>
                                    You acknowledge and agree that any
                                    questions, comments, suggestions, ideas,
                                    feedback or other information about the
                                    Website or the Company Services
                                    ("Submissions") provided by you to Company
                                    are non-confidential and Company (as well as
                                    any designee of Company) shall be entitled
                                    to the unrestricted use and dissemination of
                                    these Submissions for any purpose,
                                    commercial or otherwise, without
                                    acknowledgment or compensation to you.
                                </p>
                                <h2></h2>
                                <p>
                                    <strong>PROHIBITED ACTIVITIES</strong>
                                </p>
                                <p>
                                    You may not access or use the Website for
                                    any other purpose other than that for which
                                    Company makes it available. The Website may
                                    not be used in connection with any
                                    commercial endeavors except those that are
                                    specifically endorsed or approved by
                                    Company. Prohibited activity includes, but
                                    is not limited to:
                                </p>
                                <p>
                                    A. attempting to bypass any measures of the
                                    Website designed to prevent or restrict
                                    access to the Website, or any portion of the
                                    Website
                                </p>
                                <p>
                                    B. attempting to impersonate another user or
                                    person or using the username of another user
                                </p>
                                <p>C. criminal or tortious activity</p>
                                <p>
                                    D. deciphering, decompiling, disassembling
                                    or reverse engineering any of the software
                                    comprising or in any way making up a part of
                                    the Website
                                </p>
                                <p>
                                    E. deleting the copyright or other
                                    proprietary rights notice from any Website
                                    content
                                </p>
                                <p>
                                    F. engaging in any automated use of the
                                    system, such as using any data mining,
                                    robots or similar data gathering and
                                    extraction tools
                                </p>
                                <p>
                                    G. except as may be the result of standard
                                    search engine or Internet browser usage,
                                    using or launching, developing or
                                    distributing any automated system,
                                    including, without limitation, any spider,
                                    robot (or "bot"), cheat utility, scraper or
                                    offline reader that accesses the Website, or
                                    using or launching any unauthorized script
                                    or other software
                                </p>
                                <p>
                                    H. harassing, annoying, intimidating or
                                    threatening any Company employees or agents
                                    engaged in providing any portion of the
                                    Company Services to you
                                </p>
                                <p>
                                    I. interfering with, disrupting, or creating
                                    an undue burden on the Website or the
                                    networks or services connected to the
                                    Website
                                </p>
                                <p>
                                    J. making any unauthorized use of the
                                    Company Services, including collecting
                                    usernames and/or email addresses of users by
                                    electronic or other means for the purpose of
                                    sending unsolicited email, or creating user
                                    accounts by automated means or under false
                                    pretenses
                                </p>
                                <p>
                                    K. selling or otherwise transferring your
                                    profile
                                </p>
                                <p>
                                    L. systematic retrieval of data or other
                                    content from the Website to create or
                                    compile, directly or indirectly, a
                                    collection, compilation, database or
                                    directory without written permission from
                                    Company
                                </p>
                                <p>
                                    M. tricking, defrauding or misleading
                                    Company and other users, especially in any
                                    attempt to learn sensitive account
                                    information such as passwords
                                </p>
                                <p>
                                    N. using any information obtained from the
                                    Website in order to harass, abuse, or harm
                                    another person
                                </p>
                                <p>
                                    O. using the Company Services as part of any
                                    effort to compete with Company or to provide
                                    services as a service bureau
                                </p>
                                <p>
                                    P. using the Website in a manner
                                    inconsistent with any and all applicable
                                    laws and regulations
                                </p>
                                <p>
                                    <strong>
                                        INTELLECTUAL PROPERTY RIGHTS
                                    </strong>
                                </p>
                                <p>
                                    The content on the Website ("Company
                                    Content") and the trademarks, service marks
                                    and logos contained therein ("Marks") are
                                    owned by or licensed to Company, and are
                                    subject to copyright and other intellectual
                                    property rights under United States and
                                    foreign laws and international conventions.
                                    Company Content, includes, without
                                    limitation, all source code, databases,
                                    functionality, software, website designs,
                                    audio, video, text, photographs and
                                    graphics. All Company graphics, logos,
                                    designs, page headers, button icons, scripts
                                    and service names are registered trademarks,
                                    common law trademarks or trade dress of
                                    Company in the United States and/or other
                                    countries. Company's trademarks and trade
                                    dress may not be used, including as part of
                                    trademarks and/or as part of domain names,
                                    in connection with any product or service in
                                    any manner that is likely to cause confusion
                                    and may not be copied, imitated, or used, in
                                    whole or in part, without the prior written
                                    permission of the Company.
                                </p>
                                <p>
                                    Company Content on the Website is provided
                                    to you "AS IS" for your information and
                                    personal use only and may not be used,
                                    copied, reproduced, aggregated, distributed,
                                    transmitted, broadcast, displayed, sold,
                                    licensed, or otherwise exploited for any
                                    other purposes whatsoever without the prior
                                    written consent of the respective owners.
                                    Provided that you are eligible to use the
                                    Website, you are granted a limited license
                                    to access and use the Website and the
                                    Company Content and to download or print a
                                    copy of any portion of the Company Content
                                    to which you have properly gained access
                                    solely for your personal, non-commercial
                                    use. Company reserves all rights not
                                    expressly granted to you in and to the
                                    Website and Company Content and Marks.
                                </p>
                                <h2>THIRD PARTY WEBSITES AND CONTENT</h2>
                                <p>
                                    The Website contains (or you may be sent
                                    through the Website or the Company Services)
                                    links to other websites ("Third Party
                                    Websites") as well as articles, photographs,
                                    text, graphics, pictures, designs, music,
                                    sound, video, information, applications,
                                    software and other content or items
                                    belonging to or originating from third
                                    parties (the "Third Party Content"). Such
                                    Third Party Websites and Third Party Content
                                    are not investigated, monitored or checked
                                    for accuracy, appropriateness, or
                                    completeness by us, and we are not
                                    responsible for any Third Party Websites
                                    accessed through the Website or any Third
                                    Party Content posted on, available through
                                    or installed from the Website, including the
                                    content, accuracy, offensiveness, opinions,
                                    reliability, privacy practices or other
                                    policies of or contained in the Third Party
                                    Websites or the Third Party Content.
                                    Inclusion of, linking to or permitting the
                                    use or installation of any Third Party
                                    Website or any Third Party Content does not
                                    imply approval or endorsement thereof by us.
                                    If you decide to leave the Website and
                                    access the Third Party Websites or to use or
                                    install any Third Party Content, you do so
                                    at your own risk and you should be aware
                                    that our terms and policies no longer
                                    govern. You should review the applicable
                                    terms and policies, including privacy and
                                    data gathering practices, of any website to
                                    which you navigate from the Website or
                                    relating to any applications you use or
                                    install from the Website. Any purchases you
                                    make through Third Party Websites will be
                                    through other websites and from other
                                    companies, and Company takes no
                                    responsibility whatsoever in relation to
                                    such purchases which are exclusively between
                                    you and the applicable third party.
                                </p>
                                <p>
                                    <strong>SITE MANAGEMENT</strong>
                                </p>
                                <p>
                                    Company reserves the right but does not have
                                    the obligation to:
                                </p>
                                <p>
                                    A. monitor the Website for violations of
                                    this Agreement;
                                </p>
                                <p>
                                    B. take appropriate legal action against
                                    anyone who, in Company's sole discretion,
                                    violates this Agreement, including without
                                    limitation, reporting such user to law
                                    enforcement authorities;
                                </p>
                                <p>
                                    C. in Company's sole discretion and without
                                    limitation, refuse, restrict access to or
                                    availability of, or disable (to the extent
                                    technologically feasible) any user's
                                    contribution or any portion thereof that may
                                    violate this Agreement or any Company
                                    policy;
                                </p>
                                <p>
                                    D. in Company's sole discretion and without
                                    limitation, notice or liability to remove
                                    from the Website or otherwise disable all
                                    files and content that are excessive in size
                                    or are in any way burdensome to Company's
                                    systems;
                                </p>
                                <p>
                                    E. otherwise manage the Website in a manner
                                    designed to protect the rights and property
                                    of Company and others and to facilitate the
                                    proper functioning of the Website.
                                </p>
                                <p>
                                    <strong>PRIVACY POLICY</strong>
                                </p>
                                <p>
                                    We care about the privacy of our users.
                                    Please review the Company Privacy Policy. By
                                    using the Website or Company Services, you
                                    are consenting to have your personal data
                                    transferred to and processed in the United
                                    States. By using the Website or the Company
                                    Services, you are consenting to the terms of
                                    our Privacy Policy.
                                </p>
                                <p>
                                    <strong>TERM AND TERMINATION</strong>
                                </p>
                                <p>
                                    This Agreement shall remain in full force
                                    and effect while you use the Website or are
                                    otherwise a user or member of the Website,
                                    as applicable. You may terminate your use or
                                    participation at any time, for any reason,
                                    by following the instructions for
                                    terminating user accounts in your account
                                    settings, if available, or by contacting us
                                    using the contact information below.
                                </p>
                                <p>
                                    WITHOUT LIMITING ANY OTHER PROVISION OF THIS
                                    AGREEMENT, COMPANY RESERVES THE RIGHT TO, IN
                                    COMPANY'S SOLE DISCRETION AND WITHOUT NOTICE
                                    OR LIABILITY, DENY ACCESS TO AND USE OF THE
                                    WEBSITE AND THE COMPANY SERVICES, TO ANY
                                    PERSON FOR ANY REASON OR FOR NO REASON AT
                                    ALL, INCLUDING WITHOUT LIMITATION FOR BREACH
                                    OF ANY REPRESENTATION, WARRANTY OR COVENANT
                                    CONTAINED IN THIS AGREEMENT, OR OF ANY
                                    APPLICABLE LAW OR REGULATION, AND COMPANY
                                    MAY TERMINATE YOUR USE OR PARTICIPATION IN
                                    THE WEBSITE AND THE COMPANY SERVICES, DELETE
                                    YOUR PROFILE AND ANY CONTENT OR INFORMATION
                                    THAT YOU HAVE POSTED AT ANY TIME, WITHOUT
                                    WARNING, IN COMPANY'S SOLE DISCRETION.
                                </p>
                                <p>
                                    In order to protect the integrity of the
                                    Website and Company Services, Company
                                    reserves the right at any time in its sole
                                    discretion to block certain IP addresses
                                    from accessing the Website and Company
                                    Services.
                                </p>
                                <p>
                                    Any provisions of this Agreement that, in
                                    order to fulfill the purposes of such
                                    provisions, need to survive the termination
                                    or expiration of this Agreement, shall be
                                    deemed to survive for as long as necessary
                                    to fulfill such purposes.
                                </p>
                                <p>
                                    YOU UNDERSTAND THAT CERTAIN STATES ALLOW YOU
                                    TO CANCEL THIS AGREEMENT, WITHOUT ANY
                                    PENALTY OR OBLIGATION, AT ANY TIME PRIOR TO
                                    MIDNIGHT OF COMPANY'S THIRD BUSINESS DAY
                                    FOLLOWING THE DATE OF THIS AGREEMENT,
                                    EXCLUDING SUNDAYS AND HOLIDAYS. TO CANCEL,
                                    CALL A COMPANY CUSTOMER CARE REPRESENTATIVE
                                    DURING NORMAL BUSINESS HOURS USING THE
                                    CONTACT INFORMATION LISTING BELOW IN THIS
                                    AGREEMENT OR BY ACCESSING YOUR ACCOUNT
                                    SETTINGS. THIS SECTION APPLIES ONLY TO
                                    INDIVIDUALS RESIDING IN STATES WITH SUCH
                                    LAWS.
                                </p>
                                <p>
                                    If Company terminates or suspends your
                                    account for any reason, you are prohibited
                                    from registering and creating a new account
                                    under your name, a fake or borrowed name, or
                                    the name of any third party, even if you may
                                    be acting on behalf of the third party. In
                                    addition to terminating or suspending your
                                    account, Company reserves the right to take
                                    appropriate legal action, including without
                                    limitation pursuing civil, criminal, and
                                    injunctive redress.
                                </p>
                                <p>
                                    <strong>MODIFICATIONS</strong>
                                </p>
                                <p>
                                    <strong>
                                        <em>To Agreement</em>
                                    </strong>
                                </p>
                                <p>
                                    Company may modify this Agreement from time
                                    to time. Any and all changes to this
                                    Agreement will be posted on the Website and
                                    revisions will be indicated by date. You
                                    agree to be bound to any changes to this
                                    Agreement when you use the Company Services
                                    after any such modification becomes
                                    effective. Company may also, in its
                                    discretion, choose to alert all users with
                                    whom it maintains email information of such
                                    modifications by means of an email to their
                                    most recently provided email address. It is
                                    therefore important that you regularly
                                    review this Agreement and keep your contact
                                    information current in your account settings
                                    to ensure you are informed of changes. You
                                    agree that you will periodically check the
                                    Website for updates to this Agreement and
                                    you will read the messages we send you to
                                    inform you of any changes. Modifications to
                                    this Agreement shall be effective after
                                    posting.
                                </p>
                                <p>
                                    <strong>
                                        <em>To Services</em>
                                    </strong>
                                </p>
                                <p>
                                    Company reserves the right at any time to
                                    modify or discontinue, temporarily or
                                    permanently, the Company Services (or any
                                    part thereof) with or without notice. You
                                    agree that Company shall not be liable to
                                    you or to any third party for any
                                    modification, suspension or discontinuance
                                    of the Company Services.
                                </p>
                                <p>
                                    <strong>DISPUTES</strong>
                                </p>
                                <p>
                                    <strong>
                                        <em>Between Users</em>
                                    </strong>
                                </p>
                                <p>
                                    If there is a dispute between users of the
                                    Website, or between users and any third
                                    party, you understand and agree that Company
                                    is under no obligation to become involved.
                                    In the event that you have a dispute with
                                    one or more other users, you hereby release
                                    Company, its officers, employees, agents and
                                    successors in rights from claims, demands
                                    and damages (actual and consequential) of
                                    every kind or nature, known or unknown,
                                    suspected and unsuspected, disclosed and
                                    undisclosed, arising out of or in any way
                                    related to such disputes and/or the Company
                                    Services.
                                </p>
                                <p>
                                    <strong>
                                        <em>With Company</em>
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        A. Governing Law; Jurisdiction.{" "}
                                    </strong>
                                    This Agreement and all aspects of the
                                    Website and Company Services shall be
                                    governed by and construed in accordance with
                                    the internal laws of the State of
                                    California, without regard to conflict of
                                    law provisions. With respect to any disputes
                                    or claims not subject to informal dispute
                                    resolution or arbitration (as set forth
                                    below), you agree not to commence or
                                    prosecute any action in connection therewith
                                    other than in the state and federal courts
                                    located in Los Angeles County, State of
                                    California, and you hereby consent to, and
                                    waive all defenses of lack of personal
                                    jurisdiction and forum non conveniens with
                                    respect to, venue and jurisdiction in such
                                    state and federal courts. Application of the
                                    United Nations Convention on Contracts for
                                    the International Sale of Goods is excluded
                                    from this Agreement. Additionally,
                                    application of the Uniform Computer
                                    Information Transaction Act (UCITA) is
                                    excluded from this Agreement. In no event
                                    shall any claim, action or proceeding by you
                                    related in any way to the Website or Company
                                    Services be instituted more than two (2)
                                    years after the cause of action arose.
                                </p>
                                <p>
                                    <strong>B. Informal Resolution. </strong>
                                    To expedite resolution and control the cost
                                    of any dispute, controversy or claim related
                                    to this Agreement ("Dispute"), you and
                                    Company agree to first attempt to negotiate
                                    any Dispute (except those Disputes expressly
                                    provided below) informally for at least
                                    __________ days before initiating any
                                    arbitration or court proceeding. Such
                                    informal negotiations commence upon written
                                    notice from one person to the other.
                                </p>
                                <p>
                                    <strong>C. Binding Arbitration.</strong>
                                    If you and Company are unable to resolve a
                                    Dispute through informal negotiations,
                                    either you or Company may elect to have the
                                    Dispute (except those Disputes expressly
                                    excluded below) finally and exclusively
                                    resolved by binding arbitration. Any
                                    election to arbitrate by one party shall be
                                    final and binding on the other. YOU
                                    UNDERSTAND THAT ABSENT THIS PROVISION, YOU
                                    WOULD HAVE THE RIGHT TO SUE IN COURT AND
                                    HAVE A JURY TRIAL. The arbitration shall be
                                    commenced and conducted under the Commercial
                                    Arbitration Rules of the American
                                    Arbitration Association ("AAA") and, where
                                    appropriate, the AAA's Supplementary
                                    Procedures for Consumer Related Disputes
                                    ("AAA Consumer Rules"), both of which are
                                    available at the AAA website www.adr.org.
                                    The determination of whether a Dispute is
                                    subject to arbitration shall be governed by
                                    the Federal Arbitration Act and determined
                                    by a court rather than an arbitrator. Your
                                    arbitration fees and your share of
                                    arbitrator compensation shall be governed by
                                    the AAA Consumer Rules and, where
                                    appropriate, limited by the AAA Consumer
                                    Rules. If such costs are determined by the
                                    arbitrator to be excessive, Company will pay
                                    all arbitration fees and expenses. The
                                    arbitration may be conducted in person,
                                    through the submission of documents, by
                                    phone or online. The arbitrator will make a
                                    decision in writing, but need not provide a
                                    statement of reasons unless requested by a
                                    party. The arbitrator must follow applicable
                                    law, and any award may be challenged if the
                                    arbitrator fails to do so. Except where
                                    otherwise required by the applicable AAA
                                    rules or applicable law, the arbitration
                                    will take place in United States of America
                                    County, State of California. Except as
                                    otherwise provided in this Agreement, you
                                    and Company may litigate in court to compel
                                    arbitration, stay proceedings pending
                                    arbitration, or to confirm, modify, vacate
                                    or enter judgment on the award entered by
                                    the arbitrator.
                                </p>
                                <p>
                                    <strong>D. Restrictions. </strong>
                                    You and Company agree that any arbitration
                                    shall be limited to the Dispute between
                                    Company and you individually. To the full
                                    extent permitted by law, (1) no arbitration
                                    shall be joined with any other; (2) there is
                                    no right or authority for any Dispute to be
                                    arbitrated on a class-action basis or to
                                    utilize class action procedures; and (3)
                                    there is no right or authority for any
                                    Dispute to be brought in a purported
                                    representative capacity on behalf of the
                                    general public or any other persons.
                                </p>
                                <p>
                                    <strong>
                                        E. Exceptions to Informal Negotiations
                                        and Arbitration.
                                    </strong>
                                    You and Company agree that the following
                                    Disputes are not subject to the above
                                    provisions concerning informal negotiations
                                    and binding arbitration: (1) any Disputes
                                    seeking to enforce or protect, or concerning
                                    the validity of any of your or Company's
                                    intellectual property rights; (2) any
                                    Dispute related to, or arising from,
                                    allegations of theft, piracy, invasion of
                                    privacy or unauthorized use; and (3) any
                                    claim for injunctive relief. If this Section
                                    is found to be illegal or unenforceable then
                                    neither you nor Company will elect to
                                    arbitrate any Dispute falling within that
                                    portion of this Section found to be illegal
                                    or unenforceable and such Dispute shall be
                                    decided by a court of competent jurisdiction
                                    within the courts listed for jurisdiction
                                    above, and you and Company agree to submit
                                    to the personal jurisdiction of that court.
                                </p>
                                <p>
                                    <strong>CORRECTIONS</strong>
                                </p>
                                <p>
                                    Occasionally there may be information on the
                                    Website that contains typographical errors,
                                    inaccuracies or omissions that may relate to
                                    service descriptions, pricing, availability,
                                    and various other information. Company
                                    reserves the right to correct any errors,
                                    inaccuracies or omissions and to change or
                                    update the information at any time, without
                                    prior notice.
                                </p>
                                <p>
                                    <strong>DISCLAIMERS</strong>
                                </p>
                                <p>
                                    Company cannot control the nature of all of
                                    the content available on the Website. By
                                    operating the Website, Company does not
                                    represent or imply that Company endorses any
                                    blogs, contributions or other content
                                    available on or linked to by the Website,
                                    including without limitation content hosted
                                    on third party websites or provided by third
                                    party applications, or that Company believes
                                    contributions, blogs or other content to be
                                    accurate, useful or non-harmful. We do not
                                    control and are not responsible for unlawful
                                    or otherwise objectionable content you may
                                    encounter on the Website or in connection
                                    with any contributions. The Company is not
                                    responsible for the conduct, whether online
                                    or offline, of any user of the Website or
                                    Company Services.
                                </p>
                                <p>
                                    YOU AGREE THAT YOUR USE OF THE WEBSITE AND
                                    COMPANY SERVICES WILL BE AT YOUR SOLE RISK.
                                    TO THE FULLEST EXTENT PERMITTED BY LAW,
                                    COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES,
                                    AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS
                                    OR IMPLIED, IN CONNECTION WITH THE WEBSITE
                                    AND THE COMPANY SERVICES AND YOUR USE
                                    THEREOF, INCLUDING, WITHOUT LIMITATION, THE
                                    IMPLIED WARRANTIES OF MERCHANTABILITY,
                                    FITNESS FOR A PARTICULAR PURPOSE AND
                                    NON-INFRINGEMENT. COMPANY MAKES NO
                                    WARRANTIES OR REPRESENTATIONS ABOUT THE
                                    ACCURACY OR COMPLETENESS OF THE WEBSITE'S
                                    CONTENT OR THE CONTENT OF ANY WEBSITES
                                    LINKED TO THIS WEBSITE AND ASSUMES NO
                                    LIABILITY OR RESPONSIBILITY FOR ANY (A)
                                    ERRORS, MISTAKES, OR INACCURACIES OF CONTENT
                                    AND MATERIALS, (B) PERSONAL INJURY OR
                                    PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
                                    RESULTING FROM YOUR ACCESS TO AND USE OF OUR
                                    WEBSITE, (C) ANY UNAUTHORIZED ACCESS TO OR
                                    USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                                    PERSONAL INFORMATION AND/OR FINANCIAL
                                    INFORMATION STORED THEREIN, (D) ANY
                                    INTERRUPTION OR CESSATION OF TRANSMISSION TO
                                    OR FROM THE WEBSITE OR COMPANY SERVICES, (E)
                                    ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
                                    LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH
                                    THE WEBSITE BY ANY THIRD PARTY, AND/OR (F)
                                    ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
                                    MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                                    KIND INCURRED AS A RESULT OF THE USE OF ANY
                                    CONTENT POSTED, TRANSMITTED, OR OTHERWISE
                                    MADE AVAILABLE VIA THE WEBSITE. COMPANY DOES
                                    NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                                    RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                                    ADVERTISED OR OFFERED BY A THIRD PARTY
                                    THROUGH THE WEBSITE OR ANY HYPERLINKED
                                    WEBSITE OR FEATURED IN ANY BANNER OR OTHER
                                    ADVERTISING, AND COMPANY WILL NOT BE A PARTY
                                    TO OR IN ANY WAY BE RESPONSIBLE FOR
                                    MONITORING ANY TRANSACTION BETWEEN YOU AND
                                    THIRD-PARTY PROVIDERS OF PRODUCTS OR
                                    SERVICES. AS WITH THE PURCHASE OF A PRODUCT
                                    OR SERVICE THROUGH ANY MEDIUM OR IN ANY
                                    ENVIRONMENT, YOU SHOULD USE YOUR BEST
                                    JUDGMENT AND EXERCISE CAUTION WHERE
                                    APPROPRIATE.
                                </p>
                                <p>
                                    <strong>LIMITATIONS OF LIABILITY</strong>
                                </p>
                                <p>
                                    IN NO EVENT SHALL COMPANY OR ITS DIRECTORS,
                                    EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY
                                    THIRD PARTY FOR ANY DIRECT, INDIRECT,
                                    CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
                                    SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST
                                    PROFIT, LOST REVENUE, LOSS OF DATA OR OTHER
                                    DAMAGES ARISING FROM YOUR USE OF THE WEBSITE
                                    OR COMPANY SERVICES, EVEN IF COMPANY HAS
                                    BEEN ADVISED OF THE POSSIBILITY OF SUCH
                                    DAMAGES. NOTWITHSTANDING ANYTHING TO THE
                                    CONTRARY CONTAINED HEREIN, COMPANY'S
                                    LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER
                                    AND REGARDLESS OF THE FORM OF THE ACTION,
                                    WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
                                    PAID, IF ANY, BY YOU TO COMPANY FOR THE
                                    COMPANY SERVICES DURING THE PERIOD OF
                                    __________ PRIOR TO ANY CAUSE OF ACTION
                                    ARISING.
                                </p>
                                <p>
                                    CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS
                                    ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                                    LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
                                    APPLY TO YOU, SOME OR ALL OF THE ABOVE
                                    DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
                                    YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                                </p>
                                <p>
                                    IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE
                                    CALIFORNIA CIVIL CODE SECTION 1542, WHICH
                                    SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO
                                    CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
                                    SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF
                                    EXECUTING THE RELEASE, WHICH, IF KNOWN BY
                                    HIM MUST HAVE MATERIALLY AFFECTED HIS
                                    SETTLEMENT WITH THE DEBTOR."
                                </p>
                                <p>
                                    <strong>INDEMNITY</strong>
                                </p>
                                <p>
                                    You agree to defend, indemnify and hold
                                    Company, its subsidiaries, and affiliates,
                                    and their respective officers, agents,
                                    partners and employees, harmless from and
                                    against, any loss, damage, liability, claim,
                                    or demand, including reasonable attorneys'
                                    fees and expenses, made by any third party
                                    due to or arising out of your contributed
                                    content, use of the Company Services, and/or
                                    arising from a breach of this Agreement
                                    and/or any breach of your representations
                                    and warranties set forth above.
                                    Notwithstanding the foregoing, Company
                                    reserves the right, at your expense, to
                                    assume the exclusive defense and control of
                                    any matter for which you are required to
                                    indemnify Company, and you agree to
                                    cooperate, at your expense, with Company's
                                    defense of such claims. Company will use
                                    reasonable efforts to notify you of any such
                                    claim, action, or proceeding which is
                                    subject to this indemnification upon
                                    becoming aware of it.
                                </p>
                                <p>
                                    <strong>NOTICES</strong>
                                </p>
                                <p>
                                    Except as explicitly stated otherwise, any
                                    notices given to Company shall be given by
                                    email to the address listed in the contact
                                    information below. Any notices given to you
                                    shall be given to the email address you
                                    provided during the registration process, or
                                    such other address as each party may
                                    specify. Notice shall be deemed to be given
                                    twenty-four (24) hours after the email is
                                    sent, unless the sending party is notified
                                    that the email address is invalid. We may
                                    also choose to send notices by regular mail.
                                </p>
                                <p>
                                    <strong>USER DATA</strong>
                                </p>
                                <p>
                                    Our Website will maintain certain data that
                                    you transfer to the Website for the purpose
                                    of the performance of the Company Services,
                                    as well as data relating to your use of the
                                    Company Services. Although we perform
                                    regular routine backups of data, you are
                                    primarily responsible for all data that you
                                    have transferred or that relates to any
                                    activity you have undertaken using the
                                    Company Services. You agree that Company
                                    shall have no liability to you for any loss
                                    or corruption of any such data, and you
                                    hereby waive any right of action against
                                    Company arising from any such loss or
                                    corruption of such data.
                                </p>
                                <p>
                                    <strong>ELECTRONIC CONTRACTING</strong>
                                </p>
                                <p>
                                    Your use of the Company Services includes
                                    the ability to enter into agreements and/or
                                    to make transactions electronically. YOU
                                    ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS
                                    CONSTITUTE YOUR AGREEMENT AND INTENT TO BE
                                    BOUND BY AND TO PAY FOR SUCH AGREEMENTS AND
                                    TRANSACTIONS. YOUR AGREEMENT AND INTENT TO
                                    BE BOUND BY ELECTRONIC SUBMISSIONS APPLIES
                                    TO ALL RECORDS RELATING TO ALL TRANSACTIONS
                                    YOU ENTER INTO RELATING TO THE COMPANY
                                    SERVICES, INCLUDING NOTICES OF CANCELLATION,
                                    POLICIES, CONTRACTS, AND APPLICATIONS. In
                                    order to access and retain your electronic
                                    records, you may be required to have certain
                                    hardware and software, which are your sole
                                    responsibility.
                                </p>
                                <p>
                                    <strong>ELECTRONIC SIGNATURES</strong>
                                </p>
                                <p>
                                    Users are allowed on uhub.app to transmit
                                    and receive valid electronic signatures in
                                    the United States under the Electronic
                                    Signatures in Global and National Commerce
                                    Act (E-Sign Act) of 2000 and the Uniform
                                    Electronic Transactions Act (UETA) of 1999
                                    as adopted by individual states. Users'
                                    signatures and identities are not
                                    authenticated on uhub.app.
                                </p>
                                <p>
                                    <strong>MISCELLANEOUS</strong>
                                </p>
                                <p>
                                    This Agreement constitutes the entire
                                    agreement between you and Company regarding
                                    the use of the Company Services. The failure
                                    of Company to exercise or enforce any right
                                    or provision of this Agreement shall not
                                    operate as a waiver of such right or
                                    provision. The section titles in this
                                    Agreement are for convenience only and have
                                    no legal or contractual effect. This
                                    Agreement operates to the fullest extent
                                    permissible by law. This Agreement and your
                                    account may not be assigned by you without
                                    our express written consent. Company may
                                    assign any or all of its rights and
                                    obligations to others at any time. Company
                                    shall not be responsible or liable for any
                                    loss, damage, delay or failure to act caused
                                    by any cause beyond Company's reasonable
                                    control. If any provision or part of a
                                    provision of this Agreement is unlawful,
                                    void or unenforceable, that provision or
                                    part of the provision is deemed severable
                                    from this Agreement and does not affect the
                                    validity and enforceability of any remaining
                                    provisions. There is no joint venture,
                                    partnership, employment or agency
                                    relationship created between you and Company
                                    as a result of this Agreement or use of the
                                    Website and Company Services. Upon Company's
                                    request, you will furnish Company any
                                    documentation, substantiation or releases
                                    necessary to verify your compliance with
                                    this Agreement. You agree that this
                                    Agreement will not be construed against
                                    Company by virtue of having drafted them.
                                    You hereby waive any and all defenses you
                                    may have based on the electronic form of
                                    this Agreement and the lack of signing by
                                    the parties hereto to execute this
                                    Agreement.
                                </p>
                                <p>
                                    <strong>CONTACT US</strong>
                                </p>
                                <p>
                                    In order to resolve a complaint regarding
                                    the Company Services or to receive further
                                    information regarding use of the Company
                                    Services, please contact Company as set
                                    forth below or, if any complaint with us is
                                    not satisfactorily resolved, and you are a
                                    California resident, you can contact the
                                    Complaint Assistance Unit of the Division of
                                    Consumer Services of the Department of
                                    Consumer Affairs in writing at 400 "R"
                                    Street, Sacramento, California 95814 or by
                                    telephone at 1-916-445-1254.
                                </p>
                                <p>Picklings Corporation</p>
                                <p>1723 Cloverfield Blvd.</p>
                                <p>Santa Monica, CA 90404</p>
                                <p>Email: rib@picklings.com</p>
                                <p>Phone: 3107850925</p>
                            </p>
                        </div>
                    </Modal>
                ) : null}

                <a
                    type="link"
                    onClick={this.showModal}
                    href="javascript:void(0)"
                >
                    Terms
                </a>
            </Fragment>
        );
    }
}

export default TermsModal;
