import React, { Component, Fragment } from "react";
import {
    Form,
    Modal,
    Button,
    Icon,
    Tabs,
    Input,
    Checkbox,
    Row,
    Col,
    Alert,
    Tag,
} from "antd";

import FilePicker from "../FilePicker";
import AdsPreview from "./AdsPreview";
import moment from "moment";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";

const { TabPane } = Tabs;
const { TextArea } = Input;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        typeTimer = null;

        constructor(props) {
            super(props);

            // grab the data props and store it as a state
            this.state = props.data;
        }

        async componentDidMount() {
            // get the account info
            const account = this.props.store.getAllAccounts.filter(
                (account) => account.key === this.props.data.account_key
            )[0];
            const account_name = account.name;
            //console.info('account', account);
            const channels = await this.props.store.getAccountChannels(
                this.props.data.account_key
            );
            let channel_name = "";
            if (this.props.data.platform === "facebook") {
                channel_name = channels.filter(
                    (channel) => channel.id === this.props.data.channel_id
                )[0].name;
            }
            if (this.props.data.platform === "linkedin") {
                channel_name =
                    account.linkedin.profile.localizedFirstName +
                    " " +
                    account.linkedin.profile.localizedLastName;
            }

            const allMasterads = this.props.store.getAllMasterads;
            const masterad_name = allMasterads.filter(
                (masterad) => masterad.key === this.props.data.masterad_key
            )[0].name;
            //console.info('channel', channel);
            const image_bytes = this.props.data.masterad.ad_model.image_bytes
                ? this.props.data.masterad.ad_model.image_bytes
                : null;
            // let ads_networks = this.props.data.ads.map(ad=>ad.network).filter((value, index, self) => {
            //       return self.indexOf(value) === index;
            //     });

            this.setState({
                account_name,
                channel_name,
                masterad_name,
                image_bytes,
                changed_image: false,
                unlocked: false,
                leave_original_running: true,
            });
        }

        handleCampaignsChange = (_campaigns) => {
            this.setState({ campaigns: _campaigns });
        };

        handleFilePickerChange = (_info) => {
            console.info("handleFilePickerChange _info", _info);
            const image_bytes = _info.image_data
                ? _info.image_data.split(",")[1]
                : _info.previewURL;
            this.setState({
                image_bytes,
                changed_ad: true,
                changed_image: true,
            });
        };

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;

            const infoPanel = (
                <Fragment>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col
                            span={7}
                            style={{
                                backgroundColor: "#f5f5f5",
                                padding: "10px 15px",
                            }}
                        >
                            <strong>Master Ad</strong>
                            <br />
                            {this.state.masterad_name}
                        </Col>
                        <Col span={1} style={{ textAlign: "center" }}>
                            <Icon type="caret-right" />
                        </Col>
                        <Col
                            span={7}
                            style={{
                                backgroundColor: "#f5f5f5",
                                padding: "10px 15px",
                            }}
                        >
                            <strong>Account</strong>
                            <br />
                            {this.state.account_name}
                        </Col>
                        <Col span={1} style={{ textAlign: "center" }}>
                            <Icon type="caret-right" />
                        </Col>
                        <Col
                            span={8}
                            style={{
                                backgroundColor: "#f5f5f5",
                                padding: "10px 15px",
                            }}
                        >
                            <strong>Channel</strong>
                            <br />
                            <Icon
                                type={this.state.platform}
                                theme={"filled"}
                                style={{
                                    marginRight: 5,
                                    color: this.props.store.getTagColorForType(
                                        this.state.platform
                                    ),
                                }}
                            />
                            {this.state.channel_name}
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />

                    {/* <Form.Item label="Master Ad name">
                        <div style={{ marginTop: -10 }}>
                            {this.state.masterad_name}
                        </div>
                    </Form.Item>
                    <Form.Item label="Account name">
                        <div style={{ marginTop: -10 }}>
                            {this.state.account_name}
                        </div>
                    </Form.Item>
                    <Form.Item label="Channel name">
                        <div style={{ marginTop: -10 }}>
                            {this.state.channel_name}
                        </div>
                    </Form.Item> */}
                    <Form.Item label="Platform">
                        <div style={{ marginTop: -10 }}>
                            <Icon
                                type={this.state.platform}
                                theme={"filled"}
                                style={{
                                    marginRight: 5,
                                    color: this.props.store.getTagColorForType(
                                        this.state.platform
                                    ),
                                }}
                            />
                            {this.state.platform}
                        </div>
                    </Form.Item>
                    <Form.Item label="Start/End time">
                        <div style={{ marginTop: -10 }}>
                            {this.state.start_time &&
                                moment(this.state.start_time).format("lll") +
                                    " - " +
                                    moment(this.state.end_time).format("lll")}
                        </div>
                    </Form.Item>
                    <Form.Item label="Status">
                        <div style={{ marginTop: -10 }}>
                            <Tag
                                color={
                                    this.state.status === "PAUSED"
                                        ? "blue"
                                        : "#52c41a"
                                }
                            >
                                {this.state.status}
                            </Tag>
                        </div>
                    </Form.Item>
                    <Form.Item label="Targeting">
                        <div style={{ whiteSpace: "pre", lineHeight: 1.7 }}>
                            {this.state.targeting_text.join("\n")}
                        </div>
                    </Form.Item>
                </Fragment>
            );

            const adPanel = (
                <Fragment>
                    {!this.state.unlocked ? (
                        <div
                            style={{
                                width: "90%",
                                left: "5%",
                                textAlign: "center",
                                position: "absolute",
                                zIndex: 1,
                                padding: 20,
                                background: "#c2e3fe",
                                boxShadow: "0 10px 35px rgba(0,0,0,0.3)",
                            }}
                        >
                            <h4>Warning</h4>
                            <p
                                style={{
                                    padding: "0 20%",
                                    textAlign: "justify",
                                }}
                            >
                                This is a sample Lorem ipsum dolor sit amet
                                consectetur adipisicing elit. Ad laudantium
                                neque deleniti molestias dolorem architecto, in
                                rem illum deserunt veniam cupiditate qui nobis
                                rerum atque id maiores hic sequi reiciendis?
                            </p>
                            <Checkbox
                                checked={this.state.leave_original_running}
                                onChange={(e) => {
                                    this.setState({
                                        leave_original_running:
                                            e.target.checked,
                                    });
                                }}
                            >
                                Leave the original ad running
                            </Checkbox>

                            <br />
                            <br />
                            <Button
                                type="primary"
                                onClick={(e) =>
                                    this.setState({ unlocked: true })
                                }
                            >
                                Unlock
                            </Button>
                        </div>
                    ) : null}
                    <div
                        style={{
                            minHeight: 352,
                            ...(this.state.unlocked === false
                                ? {
                                      opacity: 0.2,
                                      pointerEvents: "none",
                                  }
                                : null),
                        }}
                    >
                        <Row gutter={20}>
                            <Col span={9}>
                                <Form.Item label="Title">
                                    {getFieldDecorator("title", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please input the title of the ad!",
                                                type: "string",
                                            },
                                        ],
                                        initialValue: this.state.title,
                                        onChange: (e) => {
                                            this.setState({
                                                title: e.target.value,
                                                changed_ad: true,
                                            });
                                        },
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Body">
                                    {getFieldDecorator("body", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please input the body of the ad!",
                                                type: "string",
                                            },
                                        ],
                                        initialValue: this.state.body,
                                        onChange: (e) => {
                                            this.setState({
                                                body: e.target.value,
                                                changed_ad: true,
                                            });
                                        },
                                    })(<TextArea />)}
                                </Form.Item>
                                <Form.Item label="URL">
                                    {getFieldDecorator("url", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "Please input the url of the master ad!",
                                                type: "string",
                                            },
                                        ],
                                        initialValue: this.state.object_url,
                                        onChange: (e) => {
                                            this.setState({
                                                object_url: e.target.value,
                                                changed_ad: true,
                                            });
                                        },
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Image">
                                    <FilePicker
                                        store={this.props.store}
                                        // restrictions={{ width: 110, height: 90 }}
                                        // nopreview
                                        onChange={this.handleFilePickerChange}
                                        selected={
                                            this.state.image && this.state.image
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={15} style={{ paddingLeft: 30 }}>
                                <AdsPreview
                                    networks={{
                                        facebook:
                                            this.state.platform &&
                                            this.state.platform === "facebook",
                                        linkedin:
                                            this.state.platform &&
                                            this.state.platform === "linkedin",
                                    }}
                                    store={this.props.store}
                                    link_url={this.state.url}
                                    title={this.state.title}
                                    description={this.state.body}
                                    image_url={
                                        this.state.image_bytes
                                            ? "data:image/jpeg;base64," +
                                              this.state.image_bytes
                                            : null
                                    }
                                    defaultTab="facebook"
                                    type={"singleimagead"}
                                    cta_type={"SHOP_NOW"}
                                    linkedin_newad_type={"SPONSORED_UPDATES"}
                                />
                            </Col>
                        </Row>
                    </div>
                </Fragment>
            );

            return (
                <Form>
                    <div
                        style={{
                            height: "50vh",
                            maxHeight: "50vh",
                            overflow: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        <Tabs defaultActiveKey="info">
                            <TabPane tab={"Info"} key="info">
                                <div
                                    style={{
                                        paddingTop: 20,
                                        maxWidth: 950,
                                        margin: "0 auto",
                                    }}
                                >
                                    <Fragment>{infoPanel}</Fragment>
                                </div>
                            </TabPane>

                            <TabPane tab={"Edit Ad"} key="ad">
                                <div
                                    style={{
                                        paddingTop: 20,
                                        maxWidth: 950,
                                        margin: "0 auto",
                                    }}
                                >
                                    <Fragment>{adPanel}</Fragment>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                    {/* <hr />
                    <pre>{JSON.stringify(this.props.data, null, 2)}</pre> */}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class AdsListModalAdEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "ads_modal_ad_edit",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("ads_modal_ad_edit");
    };

    onModalEdit = async () => {
        const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("formState", formState);
        // return false;
        if (formState.changed_ad) {
            try {
                // Leave the old one running?
                if (formState.leave_original_running === false) {
                    // Stopping the original ad

                    const leaveStatus = await this.props.store.updateAdStatus(
                        formState.key,
                        "PAUSED"
                    );
                    console.info("leaveStatus", leaveStatus);
                }
                let newad = null;
                // Form new ad
                if (formState.platform === "facebook") {
                    if (formState.type === "singleimagead") {
                        newad = {
                            network: "facebook",
                            type: formState.type,
                            account_key: formState.account_key,
                            channel_id: formState.channel_id,
                            campaign_name: formState.name,
                            adset_name: formState.name,
                            ad_name: formState.name,
                            status: formState.status,
                            start_time: formState.start_time,
                            end_time: formState.end_time,
                            brand: formState.brand,
                            campaigns: formState.campaigns,
                            targeting: formState.targeting,
                            targeting_info: formState.targeting_info,
                            image_bytes: formState.image_bytes,
                            object_url: formState.object_url,
                            title: formState.title,
                            body: formState.body,
                            cta_type: formState.cta_type,
                            ad_scope: formState.ad_scope,
                            masterad_key: formState.masterad_key,
                            objective: formState.objective,
                            optimization_goal: formState.optimization_goal,
                            billing_event: formState.billing_event,
                            bid_amount: formState.bid_amount,
                            daily_budget: formState.daily_budget,
                        };
                    }

                    if (formState.type === "carouselad") {
                        newad = {
                            network: "facebook",
                            type: formState.type,
                            account_key: formState.account_key,
                            channel_id: formState.channel_id,
                            campaign_name: formState.name,
                            adset_name: formState.name,
                            ad_name: formState.name,
                            objective: formState.objective,
                            status: formState.status,
                            optimization_goal: formState.optimization_goal,
                            billing_event: formState.billing_event,
                            bid_amount: formState.bid_amount,
                            daily_budget: formState.daily_budget,
                            start_time: formState.start_time,
                            end_time: formState.end_time,
                            brand: formState.brand,
                            campaigns: formState.campaigns,
                            targeting: formState.targeting,
                            targeting_info: formState.targeting_info,
                            ad_scope: formState.ad_scope,
                            carousel: formState.carousel,
                        };
                    }
                    // newad = {
                    //     network: "facebook",
                    //     url: formState.object_url,
                    //     title: formState.title,
                    //     body: formState.body,
                    //     targeting: formState.targeting,
                    //     account_key: formState.account_key,
                    //     channel_id: formState.channel_id,
                    //     type: formState.type,
                    //     calltoactiontype: formState.calltoactiontype,
                    //     // previewURL: formState.previewURL,
                    //     image_bytes: formState.image_bytes,
                    //     ad_scope: formState.ad_scope,
                    // };
                }

                if (formState.platform === "linkedin") {
                }

                console.info("newad", newad);

                const adresult = await this.props.store.addAd(newad);

                console.info("adresult", adresult);

                social_notification("publish_ad", "success");

                //form.resetFields();
                this.onModalCancel(true); // force close the modal
            } catch (err) {
                console.error(err);
                social_notification("publish_ad", "success", {
                    description: err,
                });
            }
        } else {
            form.resetFields();
            this.onModalCancel(); // hide the modal
        }
    };

    onModalCancel = (_force_close) => {
        this.setState({ processing: false });
        this.props.store.hideModalByKey(
            "ads_modal_ad_edit",
            this.formRef && !_force_close
                ? this.formRef.props.form.isFieldsTouched()
                : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        width={950}
                        centered
                        icon="form"
                        title={"Ad Edit"}
                        okText={"Save"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalEdit} //onApply
                        maskClosable={false}
                        // footer={
                        //     <Fragment>
                        //         <Button
                        //             type="primary"
                        //             onClick={this.onModalCancel}
                        //         >
                        //             Close
                        //         </Button>
                        //     </Fragment>
                        // }
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AdsListModalAdEdit);
