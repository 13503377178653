import React, { Component, Fragment } from "react";
import {
    Empty,
    Row,
    Col,
    Typography,
    Input,
    Badge,
    Tooltip,
    Button,
    Tag,
    Icon,
    Card,
    Menu,
    Dropdown,
    Pagination,
    Radio,
} from "antd";

import config from "./../../config";
import tooltips from "../../tooltips";

import { observer } from "mobx-react";

import { social_notification } from "./../../functions";

import LibraryModal from "./LibraryModal";
import LibraryModalEffects from "./LibraryModalEffects";
import SocialTag from "./../SocialTag";

const { Text, Title } = Typography;
const { Search } = Input;

const LIST_LARGE_STYLE = {
    width: 300,
    borderRadius: 5,
    // float: "left",
    overflow: "hidden",
    marginRight: 15,
    marginBottom: 15,
};

const LIST_SMALL_STYLE = {
    // border: "1px solid red",
    width: 100,
    height: 100,
    borderRadius: 5,
    // float: "left",
    overflow: "hidden",
    marginRight: 15,
    marginBottom: 15,
};

const LIST_SMALL_DOTS_STYLE = {
    position: "absolute",
    bottom: 9,
    right: 6,
    background: "white",
    padding: 4,
    borderRadius: "100%",
};
class Library extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            modal_visible: false,
            edit_data: {},
            selectedLibraryItem: null,
            pagination_currentPage: 1,
            list_style: "small",
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("library");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Library" }]);
    }

    componentWillUnmount() {
        //this.props.store.unbindLibraryCollection();
        this.props.store.unregisterModalListener("library_modal");
    }

    showModal = () => {
        console.log("showModal");
        this.setState({
            //modal_visible: true,
            edit_data: {},
        });

        this.props.store.showModalByKey("library_modal");
    };
    showModalForEdit = (_evt, _item) => {
        console.info("showModalForEdit _evt", _evt, "_item", _item);

        this.setState({
            //modal_visible: true,
            edit_data: _item,
        });

        this.props.store.showModalByKey("library_modal");
    };

    showModalEffects = (_evt, _item) => {
        console.info("showModalEffects _evt", _evt, "_item", _item);

        this.setState({
            //modal_visible: true,
            edit_data: _item,
        });

        this.props.store.showModalByKey("library_modal_effects");
    };

    handleModalCancel = () => {
        this.setState({ modal_visible: false });
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
    };

    handleMenuClick = (_evt, _item) => {
        console.log("handleMenuClick _evt", _evt, "_item", _item);
        switch (_evt.key) {
            case "edit":
                this.showModalForEdit(_evt, _item);
                break;
            case "delete":
                this.handleDelete(_item);
                break;
            case "toggle_lock":
                this.props.store.toggleLibraryItemLock(_item);
                break;
            case "effects":
                this.showModalEffects(_evt, _item);
                break;
            default:
                break;
        }
    };

    handleModalAdd = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            form.resetFields();
            this.setState({ modal_visible: false });

            let files = values.fileList.fileList;
            files.map((file) => {
                this.props.store
                    .addLibraryFile({ file, ...values })
                    .then((result) => {
                        console.info("result", result);
                    })
                    .catch((error) => {
                        console.warn(error);
                        social_notification("add_library_file", "error", {
                            description: error,
                        });
                    });
                return false;
            });
        });
    };

    handleModalSave = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            console.log(
                "Received values this.state.edit_data: ",
                this.state.edit_data
            );

            if (err) {
                return;
            }

            this.props.store
                .updateLibraryFile(this.state.edit_data.key, values.labels)
                .then((result) => {
                    form.resetFields();
                    this.setState({ modal_visible: false });
                })
                .catch((error) => {
                    social_notification("update_library_file", "error", {
                        description: error,
                    });
                });
        });
    };

    handleDelete = (_item) => {
        this.props.store
            .deleteLibraryItem(_item)
            .then((result) => {
                console.log("removed");
            })
            .catch((error) => {
                social_notification("delete_library_file", "error", {
                    description: error,
                });
            });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    getListingDataSource = () => {
        let results = this.props.store.getAllLibrary.filter((l) => {
            //console.info("labels", l.labels);
            return (
                l.file.name
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1 ||
                l.downloadURL
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1 ||
                (l.labels &&
                    JSON.parse(JSON.stringify(l.labels))
                        .map((l) => l.toUpperCase())
                        .indexOf(this.state.filter.toUpperCase()) !== -1)
            );
        });
        console.info("LIBRARY", results);
        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    handleListStyleChange = (_evt) => {
        console.info("handleListStyleChange _evt", _evt);
    };

    render() {
        let header = (
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 10 }}
                className="LibraryHeaader"
            >
                <Col>
                    <Title level={3} style={{ float: "left", paddingRight: 5 }}>
                        Library
                    </Title>
                    {/* <Badge
            count={this.props.store.getAllLibraryLength}
            style={{ backgroundColor: "#1890ff" }}
          /> */}
                    <Search
                        placeholder="input search text"
                        onSearch={this.onSearchChange}
                        onChange={(e) => this.onSearchChange(e.target.value)}
                        style={{ width: 200, marginLeft: 20 }}
                    />
                    <Radio.Group
                        onChange={(e) =>
                            this.setState({ list_style: e.target.value })
                        }
                        defaultValue={this.state.list_style}
                        style={{ marginLeft: 20 }}
                    >
                        <Radio.Button value="large">
                            <Icon type="picture" />
                        </Radio.Button>
                        <Radio.Button value="small">
                            <Icon type="appstore" />
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col>
                    {
                        <Tooltip
                            title={tooltips.LIBRARY.NEWBUTTON}
                            mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                        >
                            <Button
                                type="primary"
                                // icon="plus"
                                size="small"
                                onClick={this.showModal}
                                style={{
                                    marginLeft: 20,
                                    marginTop: 5,
                                    fontSize: 12,
                                }}
                            >
                                New file
                            </Button>
                        </Tooltip>
                    }
                </Col>
            </Row>
        );

        const libraryLength = this.props.store.getAllLibraryLength;

        const pagesCount = libraryLength / config.PAGINATION_PERPAGE;

        let body =
            libraryLength === 0 ? (
                <Empty />
            ) : (
                <div
                    style={{ display: "flex", flexFlow: "wrap" }}
                    className="LibraryList"
                >
                    {this.getListingDataSource().map((l) => {
                        const dotsDropdown = (
                            <Dropdown
                                overlay={
                                    <Menu
                                        onClick={(_evt) =>
                                            this.handleMenuClick(_evt, l)
                                        }
                                    >
                                        <Menu.Item key="effects">
                                            <Icon type="picture" />
                                            Effects
                                        </Menu.Item>

                                        <Menu.Item key="edit">
                                            <Icon type="edit" />
                                            Attributes
                                        </Menu.Item>

                                        <Menu.Item key="toggle_lock">
                                            <Icon type="lock" />
                                            Lock/Unlock
                                        </Menu.Item>

                                        <Menu.Item
                                            key="delete"
                                            disabled={l.locked && l.locked}
                                        >
                                            <Icon
                                                type="delete"
                                                style={
                                                    !l.locked
                                                        ? {
                                                              color: "red",
                                                          }
                                                        : null
                                                }
                                            />
                                            <Text
                                                style={
                                                    !l.locked
                                                        ? {
                                                              color: "red",
                                                          }
                                                        : null
                                                }
                                                disabled={l.locked}
                                            >
                                                Delete
                                            </Text>
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Icon
                                    type="more"
                                    style={
                                        this.state.list_style === "small"
                                            ? LIST_SMALL_DOTS_STYLE
                                            : null
                                    }
                                />
                            </Dropdown>
                        );

                        return (
                            <Card
                                key={l.key}
                                hoverable
                                size={this.state.list_style}
                                style={
                                    this.state.list_style === "small"
                                        ? LIST_SMALL_STYLE
                                        : LIST_LARGE_STYLE
                                }
                                cover={
                                    <div
                                        style={{
                                            height:
                                                this.state.list_style ===
                                                "large"
                                                    ? 200
                                                    : 100,
                                            overflow: "hidden",
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundImage: `url(${
                                                l.thumbnailURL
                                                    ? l.thumbnailURL
                                                    : l.downloadURL
                                            })`,
                                        }}
                                    >
                                        {/* <img
                                            alt="example"
                                            width="100%"
                                            src={l.downloadURL}
                                        /> */}
                                        {this.state.list_style === "large" ? (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 5,
                                                    left: 5,
                                                }}
                                            >
                                                {l.labels &&
                                                    l.labels.map(
                                                        (label, labelIndex) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        labelIndex
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                        marginBottom: 5,
                                                                    }}
                                                                >
                                                                    <SocialTag
                                                                        key={
                                                                            label
                                                                        }
                                                                        store={
                                                                            this
                                                                                .props
                                                                                .store
                                                                        }
                                                                        color="neutral"
                                                                    >
                                                                        {label}
                                                                    </SocialTag>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        ) : null}

                                        {l.locked ? (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 5,
                                                    right: 5,
                                                }}
                                                onClick={(_evt) =>
                                                    this.props.store.toggleLibraryItemLock(
                                                        l
                                                    )
                                                }
                                            >
                                                <Icon
                                                    type="lock"
                                                    style={{
                                                        background: "white",
                                                        borderRadius: "100%",
                                                        padding: 5,
                                                    }}
                                                />
                                            </div>
                                        ) : null}

                                        {l.file.type
                                            .toString()
                                            .indexOf("video") !== -1 ? (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    left: 5,
                                                }}
                                            >
                                                <Icon
                                                    type="caret-right"
                                                    style={{
                                                        background: "white",
                                                        borderRadius: "100%",
                                                        padding: 5,
                                                    }}
                                                />
                                            </div>
                                        ) : null}

                                        {this.state.list_style === "small"
                                            ? dotsDropdown
                                            : null}
                                    </div>
                                }
                            >
                                {this.state.list_style === "large" ? (
                                    <Row>
                                        <Col
                                            span={15}
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <strong>{l.file.name}</strong>
                                        </Col>

                                        <Col
                                            span={7}
                                            style={{ textAlign: "right" }}
                                        >
                                            {this.props.store.humanFileSize(
                                                l.file.size
                                            )}
                                        </Col>

                                        <Col
                                            span={2}
                                            style={{ textAlign: "right" }}
                                        >
                                            {dotsDropdown}
                                        </Col>
                                    </Row>
                                ) : null}
                            </Card>
                        );
                    })}
                </div>
            );

        return (
            <Fragment>
                {header}
                {body}
                {pagesCount > 1 && this.state.filter.length === 0 ? (
                    <div style={{ textAlign: "right" }}>
                        <Pagination
                            size="small"
                            defaultCurrent={1}
                            total={libraryLength}
                            pageSize={config.PAGINATION_PERPAGE}
                            current={this.state.pagination_currentPage}
                            onChange={this.paginationSetPage}
                        />
                    </div>
                ) : null}
                <LibraryModal
                    // onCancel={this.handleModalCancel}
                    // onAdd={this.handleModalAdd}
                    // onSave={this.handleModalSave}
                    data={this.state.edit_data}
                    store={this.props.store}
                />
                <LibraryModalEffects
                    // onCancel={this.handleModalCancel}
                    // onAdd={this.handleModalAdd}
                    // onSave={this.handleModalSave}
                    data={this.state.edit_data}
                    store={this.props.store}
                />
            </Fragment>
        );
    }
}

export default observer(Library);
