import React from "react";

import { Dropdown, Icon, Menu, Modal, Button } from "antd";
const { confirm } = Modal;
const ButtonGroup = Button.Group;

class Bulk {
    options = {};
    name = null;
    elements = [];
    checked = [];
    component = null;
    actions = [];
    callback = null;

    constructor(_options) {
        this.options = _options;
        console.info("Bulk constructor - _options", _options);

        // creating the list
        this.name = _options.name;
        this.elements = _options.list;
        this.component = _options.component;
        this.actions = _options.actions;
        this.callback = _options.callback;
    }

    handleAll(_check) {
        if (_check) {
            this.checked = Object.assign([], this.elements);
        } else {
            this.checked = [];
        }
        // rerender component
        if (this.component) this.component.forceUpdate();
    }
    handleCheck(_key) {
        //   console.log("handleCheck _key=" + _key);

        if (this.checked.indexOf(_key) === -1) {
            // adding it
            this.checked.push(_key);
        } else {
            // removing it
            this.checked = this.checked.filter((key) => key !== _key);
        }
        // rerender component
        if (this.component) this.component.forceUpdate();

        //   console.info("Bulk handleCheck this.checked", this.checked);
    }
    handleActionsClick = async (_e) => {
        console.info("handleActionsClick _e", _e);

        const action = this.actions.filter(
            (action) => action.key === _e.key
        )[0];
        console.info("handleActionsClick action", action);

        if (action.confirm && action.confirm) {
            confirm({
                title: action.confirm,
                okText: "Yes",
                cancelText: "No",
                okType: "danger",
                onOk: async () => {
                    await this.executeAction(action);
                },
                onCancel() {
                    return false;
                },
            });
        } else {
            await this.executeAction(action);
        }
    };

    executeAction = async (action) => {
        const that = this;

        if (action.combined) {
            return await new Promise(async (resolve, reject) => {
                if (action.function) {
                    resolve(await action.function(this.checked));
                }
            })
                .then(function () {
                    that.checked = [];
                    if (that.callback) that.callback();
                })
                .catch((error) => console.warn(`Error in promises ${error}`));
        } else {
            await Promise.all(
                this.checked.map((key) => {
                    if (action.function) action.function(key);
                    return false;
                })
            )
                .then(function () {
                    that.checked = [];
                    if (that.callback) that.callback();
                })
                .catch((error) => console.warn(`Error in promises ${error}`));
        }
    };

    isChecked(_key) {
        //   console.info("Bulk isChecked _key", _key);
        return this.checked.indexOf(_key) !== -1;
    }
    getCheckedLength() {
        return this.checked.length;
    }
    renderOptions() {
        const result = this.actions ? (
            <Dropdown
                disabled={this.getCheckedLength() === 0}
                overlay={
                    <Menu onClick={this.handleActionsClick}>
                        {this.actions.map((action) => (
                            <Menu.Item key={action.key}>
                                {action.icon && action.icon}
                                {action.title}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
            >
                <Icon
                    type="more"
                    style={{
                        float: "left",
                        padding: 9,
                        marginLeft: -8,
                        color: this.getCheckedLength() === 0 ? "#ccc" : null,
                    }}
                />
            </Dropdown>
        ) : (
            <h1>test</h1>
        );

        return result;
    }
    renderOptionsAsList() {
        const result = this.actions ? (
            <ButtonGroup>
                {this.getCheckedLength() < 2 ? (
                    <Button disabled={true} size={"small"} type={"link"}>
                        Select All
                    </Button>
                ) : (
                    this.actions.map((action) => (
                        <Button
                            onClick={(e) =>
                                this.handleActionsClick({ key: action.key })
                            }
                            key={action.key}
                            disabled={this.getCheckedLength() < 2}
                            size={"small"}
                            type={"link"}
                        >
                            {action.icon ? action.icon : null}
                            {action.title}
                        </Button>
                    ))
                )}
            </ButtonGroup>
        ) : (
            <p>Select All</p>
        );

        return result;
    }
}

export default Bulk;
