import React, { Fragment } from "react";
import { Row, Col, Table, Divider } from "antd";
import ReportSingleValue from "../Reports/ReportSingleValue";
import ReactJson from "react-json-view";

const GRID_GUTTER = 40;

const AccontsChannelInsightsYoutube = ({ channel_insights }) => {
    if (
        typeof channel_insights === "undefined" ||
        typeof channel_insights.rows === "undefined"
    )
        return null;

    // helper functions
    const sum = (array) => array.reduce((a, b) => a + b, 0);
    const avg = (array) => sum(array) / array.length || 0;

    const totalStatistics = {
        annotationClickThroughRate: channel_insights.rows
            ? avg(channel_insights.rows.map((r) => r[1]))
            : 0,
        annotationCloseRate: channel_insights.rows
            ? avg(channel_insights.rows.map((r) => r[2]))
            : 0,
        averageViewDuration: channel_insights.rows
            ? avg(channel_insights.rows.map((r) => r[3]))
            : 0,
        comments: channel_insights.rows
            ? sum(channel_insights.rows.map((r) => r[4]))
            : 0,
        dislikes: channel_insights.rows
            ? sum(channel_insights.rows.map((r) => r[5]))
            : 0,
        estimatedMinutesWatched: channel_insights.rows
            ? sum(channel_insights.rows.map((r) => r[6]))
            : 0,
        likes: channel_insights.rows
            ? sum(channel_insights.rows.map((r) => r[7]))
            : 0,
        shares: channel_insights.rows
            ? sum(channel_insights.rows.map((r) => r[8]))
            : 0,
        subscribersGained: channel_insights.rows
            ? sum(channel_insights.rows.map((r) => r[9]))
            : 0,
        subscribersLost: channel_insights.rows
            ? sum(channel_insights.rows.map((r) => r[10]))
            : 0,
        views: channel_insights.rows
            ? sum(channel_insights.rows.map((r) => r[11]))
            : 0,
    };
    const totalStatsPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="annotationClickThroughRate"
                        value={
                            totalStatistics &&
                            totalStatistics.annotationClickThroughRate
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="annotationCloseRate"
                        value={
                            totalStatistics &&
                            totalStatistics.annotationCloseRate
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="averageViewDuration"
                        value={
                            totalStatistics &&
                            totalStatistics.averageViewDuration
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="comments"
                        value={totalStatistics && totalStatistics.comments}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="dislikes"
                        value={totalStatistics && totalStatistics.dislikes}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="estimatedMinutesWatched"
                        value={
                            totalStatistics &&
                            totalStatistics.estimatedMinutesWatched
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="likes"
                        value={totalStatistics && totalStatistics.likes}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="shares"
                        value={totalStatistics && totalStatistics.shares}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="subscribersGained"
                        value={
                            totalStatistics && totalStatistics.subscribersGained
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="subscribersLost"
                        value={
                            totalStatistics && totalStatistics.subscribersLost
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="views"
                        value={totalStatistics && totalStatistics.views}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>&nbsp;</Col>
            </Row>
        </Fragment>
    );

    const { columnHeaders, rows } = channel_insights;
    let dataSource = [];
    const columns = columnHeaders.map((header, hIndex) => {
        return {
            title: header.name.substring(0, 3) + "..",
            dataIndex: hIndex,
            key: hIndex,
            sorter:
                header.dataType === "FLOAT" || header.dataType === "INTEGER"
                    ? (a, b) => Number(a[hIndex]) - Number(b[hIndex])
                    : null,
        };
    });
    dataSource = rows;

    const videosTable = (
        <Fragment>
            <h3>Videos</h3>
            <Table
                dataSource={dataSource}
                columns={columns}
                size={"small"}
                pagination={false}
            />
        </Fragment>
    );

    return (
        <Fragment>
            {totalStatsPanel}
            {/* <pre>{JSON.stringify(totalStatistics, null, 2)}</pre> */}
            <Divider />
            {videosTable}
            {/* {/* <ReactJson src={dataSource} /> */}
            <Divider />
            {/* <ReactJson src={channel_insights} /> */}
            {/* <pre>{JSON.stringify(stats, null, 2)}</pre> */}
        </Fragment>
    );
};

export default AccontsChannelInsightsYoutube;
