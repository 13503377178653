import React, { Component, Fragment } from "react";

import { Form, Modal, Upload, Button, Icon, Alert } from "antd";
import { social_notification } from "./../../functions";
import EditableTagGroup from "./../EditableTagGroup";
import { observer } from "mobx-react";

/* Add/Edit Form */
const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        handleLabelsChange = (_labels) => {
            console.info("handleLabelsChange", _labels);
            //this.props.data = _tags;
            this.props.form.setFieldsValue({ labels: _labels });
        };

        handleFileChange = (info) => {
            console.info("handleFileChange info", info);
            //console.info("handleUpload this.props", this.props);

            // this.props.form.setFieldsValue({ fileList: info.fileList });
            // this.props.data.fileList = info.fileList;
            // this.setState({ fileList: info.fileList });
        };

        render() {
            const { form, data } = this.props;
            const { getFieldDecorator } = form;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            return (
                <Form {...formItemLayout}>
                    {!data.labels ? (
                        <Form.Item label="File">
                            {getFieldDecorator("fileList", {
                                initialValue: data.template_file,
                            })(
                                <Upload
                                    name="fileList"
                                    beforeUpload={(file) => false}
                                    showUploadList={true}
                                    onChange={this.handleFileChange}
                                    multiple={true}
                                    accept="image/jpeg,image/png,image/gif,video/mp4,video/mov"
                                >
                                    <Button
                                        style={{
                                            width: 160,
                                            textAlign: "left",
                                        }}
                                    >
                                        <Icon
                                            type="upload"
                                            style={{ marginRight: 8 }}
                                        />{" "}
                                        Upload File ...
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>
                    ) : null}

                    {/* <Form.Item label="Remote URL">
                            {getFieldDecorator("remote_url", {
                                rules: [
                                    {
                                        message:
                                            "Please input the remote file URL!",
                                        type: "url"
                                    }
                                ],
                                initialValue: data.website
                            })(<Input />)}
                        </Form.Item> */}
                    <Form.Item label="Labels">
                        {getFieldDecorator("labels", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.labels ? data.labels : [],
                            onChange: this.handleLabelsChange,
                        })(
                            <EditableTagGroup
                                element_title="Label"
                                tag_color={this.props.store.getTagColorForType(
                                    "neutral"
                                )}
                            />
                        )}
                    </Form.Item>

                    {/* <Form.Item label="Extras">
                              {getFieldDecorator("allow_access_to_app", {
                                  initialValue: data.allow_access_to_app
                              })(
                                  <Checkbox
                                      onChange={this.handleAllowAccessToAppChange}
                                      checked={data.allow_access_to_app}
                                  >
                                      Allow access to the app
                                  </Checkbox>
                              )}
                          </Form.Item> */}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class LibraryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener("library_modal", (_action) => {
            this.setState({
                modal_visible: _action === "show" ? true : false,
            });
        });
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("library_modal");
    };

    onModalAdd = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            form.resetFields();

            let files = values.fileList.fileList;
            files.map((file) => {
                this.props.store
                    .addLibraryFile({ file, ...values })
                    .then((result) => {
                        console.info("result", result);
                        this.onModalCancel();
                    })
                    .catch((error) => {
                        console.warn(error);
                        social_notification("add_library_file", "error", {
                            description: error,
                        });
                    });
                return false;
            });
        });
    };
    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            console.log("Received values this.props.data: ", this.props.data);

            if (err) {
                return;
            }

            this.props.store
                .updateLibraryFile(this.props.data.key, values.labels)
                .then((result) => {
                    form.resetFields();
                    this.onModalCancel();
                })
                .catch((error) => {
                    social_notification("update_library_file", "error", {
                        description: error,
                    });
                });
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "library_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={!data.labels ? "Add a new item" : "Edit an item"}
                        okText={!data.labels ? "Add" : "Save"}
                        onCancel={this.onModalCancel}
                        onOk={!data.labels ? this.onModalAdd : this.onModalEdit} //onApply
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            data={this.props.data}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(LibraryModal);
