import React, { Component, Fragment } from "react";
import {
    Button,
    Form,
    Modal,
    Typography,
    Tooltip,
    Row,
    Col,
    Collapse,
    Input,
    Upload,
    Icon,
    Progress,
    Checkbox,
    Select,
    DatePicker,
    Radio,
    InputNumber,
    Cascader,
    Badge,
    Tree,
    Tabs,
    Divider,
    Alert,
    Dropdown,
    Menu,
} from "antd";
import SocialPreview from "./SocialPreview";
import EditableTagGroup from "./EditableTagGroup";
import LibraryPickup from "./Library/LibraryPickup";
import MasterpostsListModalNew from "./Masterposts/MasterpostsListModalNew";
import AccountsListModal from "./Accounts/AccountsListModal";
import TagsListModal from "./Tags/TagsListModal";
import CategoriesListModal from "./Categories/CategoriesListModal";
import CampaignsListModalNew from "./Campaigns/CampaignsListModalNew";
import CampaignsListModal from "./Campaigns/CampaignsListModal";
import CompetitorsListModal from "./Competitors/CompetitorsListModal";
import AdsListModal from "./Ads/AdsListModal";
import LibraryModal from "./Library/LibraryModal";
import UsersListModal from "./Users/UsersListModal";

import { social_notification } from "./../functions";
import moment from "moment";
import { observer } from "mobx-react";
import shortid from "shortid";
import { base, firebase } from "../base";
import names from "./../names";

const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
const { TreeNode } = Tree;
const { TabPane } = Tabs;

/* Compose Form */
const ComposeForm = Form.create({ name: "form_in_modal" })(
    // eslint-disable-next-line
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                accounts_affected: [],
                library_file: null,
                checkedKeys: [],
                rightTabsOpenKey: "preview",
            };
        }

        handleCategoriesChange = (_categories) => {
            console.info("handleCategoriesChange", _categories);
            //this.props.data = _categories;
            this.props.form.setFieldsValue({
                masterpost_categories: _categories,
            });
        };

        handleEveryNumberChange = (_value) => {
            this.props.data.masterpost_every_number = _value;
        };

        handleEveryDropdownChange = (_value) => {
            this.props.data.masterpost_every_dropdown = _value;
        };

        handleScheduledDateChange = (_value) => {
            this.props.data.masterpost_scheduled_date = _value;
        };

        handlePublishLocalTimeChange = (_evt) => {
            this.props.data.masterpost_publish_local_time = _evt.target.checked;
        };

        onClose = (e) => {
            console.log(e, "I was closed.");
        };

        handleTagsChange = (_tags) => {
            console.info("handleTagsChange", _tags);
            //this.props.data = tags;
            this.props.form.setFieldsValue({ target_tags: _tags });

            this.props.data.target_tags = _tags;

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleRuleEnable = (_evt, _extra) => {
            console.info("handleRuleEnable", _evt, "_extra", _extra);
            //this.props.data = tags;
            let fieldname = `rules_${_extra}`;
            console.info("handleRuleEnable fieldname", fieldname);
            this.props.form.setFieldsValue({ fieldname: _evt.target.checked });
            //this.props.data["fieldname"] = _evt.target.checked;

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };
        handleAddLocation = (evt) => {
            //this.props.data.location.push(new Array("New York", "New York"));
            //console.table(this.props.data.location);

            let curLocation = this.props.data.target_location;
            const newLocation = ["New York", "New York"];
            curLocation.push(newLocation);
            //this.setState({ target_location: curLocation });
            this.props.form.setFieldsValue({ target_location: curLocation });
            this.props.form.setFieldsValue({
                target_location_stringified: JSON.stringify(curLocation),
            });
            this.props.data.target_location = curLocation;
            this.props.data.target_location_stringified =
                JSON.stringify(curLocation);

            // Force a render with a simulated state change
            //this.setState({ state: this.state });
            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };
        handleLocationChange = (_location, _lIndex) => {
            console.info("handleLocationChange", _location, "_lIndex", _lIndex);
            //this.props.data = tags;
            let curLocation = this.props.data.target_location;
            console.info(
                "handleLocationChange curLocation BEFORE",
                curLocation
            );
            curLocation[_lIndex] = _location;
            //this.setState({ target_location: curLocation });
            console.info("handleLocationChange curLocation AFTER", curLocation);
            this.props.data.target_location = curLocation;
            this.props.form.setFieldsValue({
                target_location_stringified: JSON.stringify(curLocation),
            });
            this.props.data.target_location_stringified =
                JSON.stringify(curLocation);

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };
        handleLocationDelete = (evt, _lIndex) => {
            console.log("handleLocationDelete _lIndex=" + _lIndex);

            let curLocation = this.props.data.target_location;
            console.info(
                "handleLocationChange curLocation BEFORE",
                curLocation
            );
            curLocation.splice(_lIndex, 1);
            //this.setState({ target_location: curLocation });
            console.info("handleLocationChange curLocation AFTER", curLocation);
            this.props.form.setFieldsValue({
                target_location_stringified: JSON.stringify(curLocation),
            });
            this.props.data.target_location_stringified =
                JSON.stringify(curLocation);

            if (this.props.data.location)
                this.props.data.location.splice(_lIndex, 1);
            // Force a render with a simulated state change
            //this.setState({ state: this.state });
            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleUpload = (info) => {
            console.info("handleUpload info", info);
            //console.info("handleUpload this.props", this.props);
            this.props.form.setFieldsValue({ template_file: info.file });
            this.props.data.template_file = info.file;
            this.props.data["changed_template_file"] = true;
            this.setState({ previewURL: "" });

            if (info.fileList.length === 1) {
                let reader = new FileReader();
                let preview_file = info.file;
                console.info("handleUpload preview_file", preview_file);
                reader.onloadend = () => {
                    this.setState({ previewURL: reader.result });
                };
                reader.readAsDataURL(preview_file);
            } else {
                // clear the current file
                delete this.props.data.template_file;
                this.props.data["changed_template_file"] = false;
            }
            // return false;
        };

        handleTemplateTextChange = (evt) => {
            // console.info(
            //     "handleTemplateTextChange evt.target",
            //     evt.target.value
            // );
            this.props.data.template_text = evt.target.value;
        };

        recalculateAccountsAffected = (extra) => {
            let rules = {
                rules_location: this.props.data.target_rules_location,
                rules_tags: this.props.data.target_rules_tags,
                rules_custom: this.props.data.target_rules_custom,
                tags: this.props.data.target_tags,
                location: this.props.data.target_location,
                exclude: this.props.data.target_exclude,
                include: this.props.data.target_include,
                custom: extra && extra.custom ? extra.custom : [],
            };

            let filtered_accounts = this.props.store.getAccountsByRules(rules);
            console.info("filtered_accounts", filtered_accounts);
            this.setState({ accounts_affected: filtered_accounts });

            // data.masterpost_name
            if (filtered_accounts.length === 1) {
                this.props.data.masterpost_name =
                    filtered_accounts[0].name + " - New masterpost";
            }

            // Save the custom keys
            this.setState({
                checkedKeys: filtered_accounts.map((fa) => {
                    return fa.key;
                }),
            });
        };

        handleExcludeChange = (_exclude) => {
            console.info("handleExcludeChange", _exclude);

            this.props.data.target_exclude = _exclude;
            //this.props.data = _exclude;
            this.props.form.setFieldsValue({ target_exclude: _exclude });

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleIncludeChange = (_include) => {
            console.info("handleIncludeChange", _include);

            this.props.data.target_include = _include;
            //this.props.data = _exclude;
            this.props.form.setFieldsValue({ target_exclude: _include });

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleChangeLibrarySelected = (_selected) => {
            console.info("handleChangeLibrarySelected _selected", _selected);

            this.setState({
                library_file: _selected,
                previewURL: _selected ? _selected.downloadURL : "",
            });
            this.props.data.library_file = _selected;
            this.props.data["changed_template_file"] = true;
        };

        renderTreeNodes = (data) =>
            data.map((item) => {
                if (item.children) {
                    return (
                        <TreeNode
                            title={item.title}
                            key={item.key}
                            dataRef={item}
                        >
                            {this.renderTreeNodes(item.children)}
                        </TreeNode>
                    );
                } else {
                    return (
                        <TreeNode
                            title={
                                <Fragment>
                                    <Icon
                                        type="facebook"
                                        theme="filled"
                                        style={{ marginRight: 5 }}
                                    />
                                    {item.title}
                                </Fragment>
                            }
                            key={item.key}
                            dataRef={item}
                        ></TreeNode>
                    );
                }
                //return <TreeNode key={item.key} {...item} />;
            });

        onCheck = (checkedKeys) => {
            //console.log("onCheck", checkedKeys);
            if (this.props.data.target_rules_custom) {
                this.setState({ checkedKeys });

                this.props.form.setFieldsValue({ custom: checkedKeys });

                this.recalculateAccountsAffected({ custom: checkedKeys });
            }
        };

        rightTabsCallback = (key) => {
            console.log(key);
            this.setState({ rightTabsOpenKey: key });
        };

        toggleChannel = (channel) => {
            console.info("toggleChannel channel", channel);
            switch (channel) {
                case "facebook":
                    this.props.data.target_social_type_facebook =
                        !this.props.data.target_social_type_facebook;
                    break;
                case "instagram":
                    this.props.data.target_social_type_instagram =
                        !this.props.data.target_social_type_instagram;
                    break;
                case "twitter":
                    this.props.data.target_social_type_twitter =
                        !this.props.data.target_social_type_twitter;
                    break;
                default:
                    break;
            }

            // force the rerender
            this.setState({});
        };

        collapseChange = (openKeys) => {
            console.info("collapseChange openKeys", openKeys);

            if (openKeys.length === 1 && openKeys[0] === "post") {
                // post
                this.setState({ rightTabsOpenKey: "preview" });
            }
            if (openKeys.length === 1 && openKeys[0] === "target") {
                // target
                this.setState({ rightTabsOpenKey: "accounts" });
            }
        };

        render() {
            const {
                visible,
                onCancel,
                onPublish,
                //onEdit,
                form,
                data,
                locations,
                tags,
                processing,
                upload_percentage,
                store,
            } = this.props;
            const { getFieldDecorator } = form;

            const tagsChildren = [];
            tags.map((i) => {
                tagsChildren.push(<Option key={i.tag}>{i.tag}</Option>);
                return true;
            });

            let excludeChildren = [],
                includeChildren = [];
            store.getAllAccounts.map((a) => {
                excludeChildren.push(<Option key={a.key}>{a.email}</Option>);
                includeChildren.push(<Option key={a.key}>{a.email}</Option>);
                return true;
            });

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };

            const allAccounts = store.getAllAccounts.map((a) => {
                let accountData = {
                    title: `${a.name} (${a.email})`,
                    key: a.key,
                };
                if (
                    a.facebook &&
                    a.facebook.data &&
                    a.facebook.data.length > 0
                ) {
                    accountData.children = a.facebook.data.map((d) => {
                        return {
                            title: d.name,
                            key: a.key + "|" + d.id,
                        };
                    });
                }
                return accountData;
            });
            console.info("allAccounts", allAccounts);

            const PostPanel = (
                <Panel
                    header="Post"
                    key="post"
                    style={{
                        background: "#f5f5f5",
                        border: 0,
                    }}
                >
                    <Form.Item label="Post Text">
                        <Tooltip
                            title={`
                            [*name] - the name of the Account;
                            [*email] - the website of the Account;
                            [*phone] - the phone of the Account;
                            [*website] - the website of the Account;
                            [*location] - the location of the Account;
                            [*appendix] - the appendix of the Account;`}
                        >
                            {getFieldDecorator("template_text", {
                                initialValue: data.template_text,
                                onChange: this.handleTemplateTextChange,
                            })(<TextArea />)}
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="Post File">
                        <Row>
                            <Col span={12}>
                                {getFieldDecorator("template_file", {
                                    initialValue: data.template_file,
                                })(
                                    <Upload
                                        name="template_file"
                                        beforeUpload={(file) => false}
                                        showUploadList={true}
                                        onChange={this.handleUpload}
                                        multiple={false}
                                        accept="image/jpeg,image/png,image/gif,video/mp4"
                                        disabled={
                                            this.state.library_file
                                                ? true
                                                : false
                                        }
                                    >
                                        <Button
                                            disabled={
                                                (typeof data.template_file !=
                                                    "undefined" &&
                                                    data.template_file.name &&
                                                    data.changed_template_file) ||
                                                (this.state.library_file
                                                    ? true
                                                    : false)
                                            }
                                            style={{
                                                width: 160,
                                                textAlign: "left",
                                            }}
                                        >
                                            <Icon
                                                type="upload"
                                                style={{
                                                    marginRight: 8,
                                                }}
                                            />{" "}
                                            Upload File ...
                                        </Button>
                                    </Upload>
                                )}
                            </Col>
                            <Col span={12}>
                                <Progress
                                    className={!processing ? "hidden" : ""}
                                    percent={upload_percentage}
                                    size="small"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {typeof data.template_file != "undefined" &&
                                data.template_file.name &&
                                !data.changed_template_file ? (
                                    <div>
                                        <span>{data.template_file.name}</span>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {typeof data.template_file != "undefined" &&
                                data.template_file.name &&
                                data.changed_template_file ? (
                                    <div>
                                        {this.state.previewURL.length > 0 ? (
                                            <img
                                                src={this.state.previewURL}
                                                style={{
                                                    height: 100,
                                                    padding: 5,
                                                    marginTop: 5,
                                                    border: "1px solid #ccc",
                                                    borderRadius: 5,
                                                }}
                                                alt={""}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {typeof data.template_file != "undefined" &&
                                data.template_file.name &&
                                !data.changed_template_file
                                    ? getFieldDecorator(
                                          "delete_template_file",
                                          {
                                              initialValue:
                                                  data.delete_template_file,
                                          }
                                      )(
                                          <Checkbox
                                              defaultChecked={
                                                  data.delete_template_file
                                              }
                                          >
                                              Delete file
                                          </Checkbox>
                                      )
                                    : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LibraryPickup
                                    store={store}
                                    disabled={
                                        typeof data.template_file !=
                                            "undefined" &&
                                        data.template_file.name
                                    }
                                    multiple={true}
                                    onSelectedChange={
                                        this.handleChangeLibrarySelected
                                    }
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Panel>
            );
            const masterpostPanel = (
                <Panel
                    header={names.get("MASTERPOST", false, true)}
                    key="masterpost"
                    style={{
                        background: "#f5f5f5",
                        border: 0,
                        marginTop: 5,
                    }}
                >
                    <Form.Item
                        label={names.get("MASTERPOST", false, true) + " Name"}
                    >
                        {getFieldDecorator("masterpost_name", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the name of the target!",
                                    type: "string",
                                },
                            ],
                            initialValue: data.masterpost_name,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={names.get("MASTERPOST", false, true) + " Note"}
                    >
                        {getFieldDecorator("masterpost_note", {
                            initialValue: data.masterpost_note,
                        })(<TextArea />)}
                    </Form.Item>
                    <Form.Item
                        className="collection-create-form_last-form-item"
                        label="Publish"
                    >
                        {getFieldDecorator("masterpost_publish_type", {
                            initialValue: data.masterpost_publish_type
                                ? data.masterpost_publish_type
                                : "immediately",
                        })(
                            <Radio.Group>
                                <Radio value="immediately">Immediately</Radio>
                                <br />
                                <Radio value="regularly">Regularly</Radio>
                                <div
                                    style={{
                                        border: "1px solid #ebebeb",
                                        padding: 5,
                                        backgroundColor: "#fafafa",
                                        borderRadius: 5,
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <span
                                                style={{
                                                    float: "left",
                                                    marginRight: 5,
                                                    lineHeight: 2.3,
                                                }}
                                            >
                                                every
                                            </span>
                                            {getFieldDecorator(
                                                "masterpost_every_number",
                                                {
                                                    initialValue:
                                                        data.masterpost_every_number,
                                                }
                                            )(
                                                <InputNumber
                                                    min={1}
                                                    max={100}
                                                    style={{
                                                        width: 60,
                                                        float: "left",
                                                        marginRight: 5,
                                                    }}
                                                    onChange={
                                                        this
                                                            .handleEveryNumberChange
                                                    }
                                                    disabled={
                                                        form.getFieldValue(
                                                            "masterpost_publish_type"
                                                        ) !== "regularly"
                                                    }
                                                />
                                            )}
                                            {getFieldDecorator(
                                                "masterpost_every_dropdown",
                                                {
                                                    initialValue:
                                                        data.masterpost_every_dropdown,
                                                }
                                            )(
                                                <Select
                                                    style={{
                                                        width: 100,
                                                        float: "left",
                                                    }}
                                                    onChange={
                                                        this
                                                            .handleEveryDropdownChange
                                                    }
                                                    disabled={
                                                        form.getFieldValue(
                                                            "masterpost_publish_type"
                                                        ) !== "regularly"
                                                    }
                                                >
                                                    <Option value="days">
                                                        Days
                                                    </Option>
                                                    <Option value="weeks">
                                                        Weeks
                                                    </Option>
                                                    <Option value="months">
                                                        Months
                                                    </Option>
                                                    <Option value="years">
                                                        Years
                                                    </Option>
                                                </Select>
                                            )}
                                        </Col>
                                    </Row>
                                </div>

                                <Radio value="scheduled">Scheduled</Radio>
                                <div
                                    style={{
                                        border: "1px solid #ebebeb",
                                        padding: 5,
                                        backgroundColor: "#fafafa",
                                        borderRadius: 5,
                                    }}
                                >
                                    {getFieldDecorator(
                                        "masterpost_scheduled_date",
                                        {
                                            initialValue: moment(
                                                data.masterpost_scheduled_date
                                            ),
                                        }
                                    )(
                                        <DatePicker
                                            disabled={
                                                form.getFieldValue(
                                                    "masterpost_publish_type"
                                                ) !== "scheduled"
                                            }
                                            showTime={true}
                                            onChange={
                                                this.handleScheduledDateChange
                                            }
                                        />
                                    )}
                                </div>
                            </Radio.Group>
                        )}
                        <br />
                        {getFieldDecorator("masterpost_publish_local_time", {
                            initialValue: data.masterpost_publish_local_time,
                        })(
                            <Checkbox
                                onChange={this.handlePublishLocalTimeChange}
                                checked={data.masterpost_publish_local_time}
                            >
                                Publish in local time
                            </Checkbox>
                        )}
                    </Form.Item>
                    <Form.Item label="Categories">
                        {getFieldDecorator("masterpost_categories", {
                            initialValue: data.masterpost_categories
                                ? data.masterpost_categories
                                : [],
                            onChange: this.handleCategoriesChange,
                        })(
                            <EditableTagGroup
                                element_title="Category"
                                tag_color={this.props.store.getTagColorForType(
                                    "category"
                                )}
                                options={this.props.store.getAllCategories.map(
                                    (category) => category.category
                                )}
                            />
                        )}
                    </Form.Item>
                </Panel>
            );
            const TargetPanel = (
                <Panel
                    header={
                        <Row>
                            <Col span={12}>Target</Col>
                            <Col
                                span={12}
                                style={{
                                    textAlign: "right",
                                }}
                            >
                                {data.target_rules_location ? (
                                    <Text>By Location</Text>
                                ) : null}
                                {data.target_rules_location &&
                                data.target_rules_tags
                                    ? ", "
                                    : null}
                                {data.target_rules_tags ? (
                                    <Text>By Tags</Text>
                                ) : null}
                            </Col>
                        </Row>
                    }
                    key="target"
                    style={{
                        background: "#f5f5f5",
                        border: 0,
                        marginTop: 5,
                    }}
                >
                    <Form.Item label="Channels">
                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) => this.toggleChannel("facebook")}
                        >
                            <Icon
                                type="facebook"
                                theme="filled"
                                style={{
                                    fontSize: 25,
                                    color: data.target_social_type_facebook
                                        ? store.getTagColorForType("facebook")
                                        : "#ccc",
                                    marginLeft: 5,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) => this.toggleChannel("instagram")}
                        >
                            <Icon
                                type="instagram"
                                theme="filled"
                                style={{
                                    fontSize: 25,
                                    color: data.target_social_type_instagram
                                        ? store.getTagColorForType("instagram")
                                        : "#ccc",
                                    marginLeft: 5,
                                }}
                            />
                        </div>

                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) => this.toggleChannel("twitter")}
                        >
                            <Icon
                                type="twitter"
                                style={{
                                    fontSize: 25,
                                    color: data.target_social_type_twitter
                                        ? store.getTagColorForType("twitter")
                                        : "#ccc",
                                    marginLeft: 5,
                                }}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item label="Location">
                        {getFieldDecorator("target_rules_location", {
                            initialValue: data.target_rules_location,
                            onChange: (e) => {
                                data.target_rules_location = e.target.checked;
                                this.recalculateAccountsAffected();
                            },
                        })(
                            <Checkbox
                                defaultChecked={data.target_rules_location}
                            >
                                Enable
                            </Checkbox>
                        )}
                        {data.target_rules_location &&
                            data.target_location.map((location, lIndex) => {
                                return getFieldDecorator("location", {
                                    initialValue: location,
                                    onChange: (e) =>
                                        this.handleLocationChange(e, lIndex),
                                    defaultValue: location,
                                })(
                                    <Row key={lIndex}>
                                        <Col span={22}>
                                            <Cascader
                                                key={lIndex}
                                                options={locations}
                                                placeholder="Please select"
                                                changeOnSelect
                                                onChange={(e) =>
                                                    this.handleLocationChange(
                                                        e,
                                                        lIndex
                                                    )
                                                }
                                                value={location}
                                                defaultValue={location}
                                                style={{
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                }}
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <Button
                                                key={lIndex}
                                                type="danger"
                                                shape="circle"
                                                icon="minus"
                                                size="small"
                                                onClick={(e) =>
                                                    this.handleLocationDelete(
                                                        e,
                                                        lIndex
                                                    )
                                                }
                                                style={{
                                                    marginLeft: 10,
                                                    transform: "scale(0.8)",
                                                    fontSize: "1rem",
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        {data.target_rules_location ? (
                            <div style={{ display: "block" }}>
                                <Button
                                    type="normal"
                                    icon="plus"
                                    size="small"
                                    onClick={this.handleAddLocation}
                                >
                                    Add New
                                </Button>
                            </div>
                        ) : null}
                    </Form.Item>
                    <Form.Item label="Tags">
                        {getFieldDecorator("target_rules_tags", {
                            initialValue: data.target_rules_tags,
                            onChange: (e) => {
                                data.target_rules_tags = e.target.checked;
                                this.recalculateAccountsAffected();
                            },
                        })(
                            <Checkbox defaultChecked={data.target_rules_tags}>
                                Enable
                            </Checkbox>
                        )}
                        {getFieldDecorator("target_tags", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.target_tags
                                ? data.target_tags
                                : [],
                            onChange: this.handleTagsChange,
                        })(
                            <div>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Please select"
                                    onChange={this.handleTagsChange}
                                    defaultValue={
                                        data.target_tags ? data.target_tags : []
                                    }
                                >
                                    {tagsChildren}
                                </Select>
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item label="Exclude Accounts">
                        {getFieldDecorator("target_exclude", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.target_exclude
                                ? data.target_exclude
                                : [],
                            onChange: this.handleExcludeChange,
                        })(
                            <div>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Please select"
                                    onChange={this.handleExcludeChange}
                                    defaultValue={
                                        data.target_exclude
                                            ? data.target_exclude
                                            : []
                                    }
                                >
                                    {excludeChildren}
                                </Select>
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item label="Include Accounts">
                        {getFieldDecorator("target_include", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.target_include
                                ? data.target_include
                                : [],
                            onChange: this.handleIncludeChange,
                        })(
                            <div>
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Please select"
                                    onChange={this.handleIncludeChange}
                                    defaultValue={
                                        data.target_include
                                            ? data.target_include
                                            : []
                                    }
                                >
                                    {includeChildren}
                                </Select>
                            </div>
                        )}
                    </Form.Item>
                </Panel>
            );
            const AccountsAffected = (
                <Fragment>
                    <Alert
                        banner
                        message={
                            <Fragment>
                                <Row>
                                    <Col span={10}>
                                        {getFieldDecorator("custom")(
                                            <Input type="hidden" />
                                        )}

                                        {getFieldDecorator(
                                            "target_rules_custom",
                                            {
                                                initialValue:
                                                    data.target_rules_custom,
                                                onChange: (e) => {
                                                    data.target_rules_custom =
                                                        e.target.checked;
                                                    this.recalculateAccountsAffected();
                                                },
                                            }
                                        )(
                                            <Checkbox
                                                defaultChecked={
                                                    data.target_rules_custom
                                                }
                                            >
                                                <b>Custom selection</b>
                                            </Checkbox>
                                        )}
                                    </Col>
                                    <Col
                                        span={14}
                                        style={{ textAlign: "right" }}
                                    >
                                        <Text type="secondary">
                                            (Allows you to mix the selected
                                            channels)
                                        </Text>
                                    </Col>
                                </Row>
                            </Fragment>
                        }
                        type="info"
                        showIcon={false}
                    />

                    <Divider dashed />

                    {/* {this.state.accounts_affected.map(
                        (aa, aaIndex) => {
                            return (
                                <Fragment
                                    key={aaIndex}
                                >
                                    <strong>
                                        {aa.name}
                                    </strong>{" "}
                                    ({aa.email})
                                    <br />
                                </Fragment>
                            );
                        }
                    )} */}

                    <Tree
                        checkable
                        selectable={false}
                        // onExpand={this.onExpand}
                        // expandedKeys={this.state.expandedKeys}
                        // autoExpandParent={this.state.autoExpandParent}
                        onCheck={this.onCheck}
                        checkedKeys={this.state.checkedKeys}
                        // onSelect={this.onSelect}
                        // selectedKeys={this.state.selectedKeys}
                        style={{ marginTop: -15 }}
                    >
                        {this.renderTreeNodes(allAccounts)}
                    </Tree>
                </Fragment>
            );

            return (
                <Modal
                    visible={visible}
                    centered
                    width={"80%"}
                    icon="form"
                    title={"New Post"}
                    okText={"Publish"}
                    cancelText={"Close"}
                    onCancel={onCancel}
                    onOk={onPublish}
                    maskClosable={false}
                    confirmLoading={processing}
                >
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                    <Form {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Collapse
                                    bordered={false}
                                    defaultActiveKey={["post"]}
                                    onChange={this.collapseChange}
                                >
                                    {PostPanel}
                                    {TargetPanel}
                                    {masterpostPanel}
                                </Collapse>
                            </Col>
                            <Col span={12}>
                                <Tabs
                                    activeKey={this.state.rightTabsOpenKey}
                                    onChange={this.rightTabsCallback}
                                >
                                    <TabPane
                                        tab={
                                            <Fragment>
                                                Accounts Affected{" "}
                                                <Badge
                                                    count={
                                                        this.state
                                                            .accounts_affected
                                                            .length
                                                    }
                                                    showZero
                                                    style={{
                                                        backgroundColor:
                                                            "#1890ff",
                                                        marginLeft: 10,
                                                    }}
                                                ></Badge>
                                            </Fragment>
                                        }
                                        key="accounts"
                                    >
                                        {AccountsAffected}
                                    </TabPane>
                                    <TabPane tab="Preview" key="preview">
                                        <SocialPreview
                                            body={data.template_text}
                                            image_url={
                                                data.changed_template_file
                                                    ? this.state.previewURL
                                                    : null
                                            }
                                        ></SocialPreview>
                                    </TabPane>
                                </Tabs>

                                {/* <pre>
                                    {JSON.stringify(
                                        this.state.checkedKeys,
                                        null,
                                        2
                                    )}
                                </pre> */}
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            );
        }
    }
);

class Compose extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            compose_data: {
                target_location: [],
                target_social_type_facebook: true,
                target_social_type_instagram: true,
                target_social_type_twitter: true,
            },
            modal_processing: false,
            upload_percentage: 0,
            modal_account_data: {
                auth: null, // empty auth data
            },
            modal_competitor_data: {},
            modal_ad_data: {},
            modal_library_data: {},
        };

        this.accountModal = React.createRef();
        this.tagsModal = React.createRef();
        this.categoriesModal = React.createRef();
        this.campaignsModal = React.createRef();
        this.campaignsModalApply = React.createRef();
        this.competitorsModal = React.createRef();
        this.adsModal = React.createRef();
        this.libraryModal = React.createRef();
        this.usersModal = React.createRef();
        this.masterpostsModal = React.createRef();
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.text !== this.props.text) {
        //   this.updateAndNotify();
        // }
        if (
            JSON.stringify(prevProps.editData) !==
            JSON.stringify(this.props.editData)
        )
            this.editModal(this.props.editData);
    }

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("masterposts_modal_new");
    }

    editModal = (_editData) => {
        console.info("editModal _editData", _editData);
        // alert('editModal = '+JSON.stringify(_editData));
        this.showModal();
    };

    showModal = () => {
        console.log("showModal");
        this.masterpostsModal.current.registerModal();
        this.props.store.showModalByKey("masterposts_modal_new");
        // this.setState({
        //     modal_visible: true
        //     //edit_data: {}
        // });
    };

    closeModal = () => {
        this.setState({
            modal_visible: false,
            //edit_data: {}
        });
    };

    onPublish = () => {
        this.setState({ modal_processing: true });
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            console.log(
                "Received values of this.state.compose_data: ",
                this.state.compose_data
            );

            if (err) {
                social_notification("compose_publish", "error");
                return;
            }

            let masterpost_data = {
                categories: values.masterpost_categories,
                every_dropdown: values.masterpost_every_dropdown,
                every_number: values.masterpost_every_number,
                name: values.masterpost_name,
                note: values.masterpost_note,
                publish_local_time: values.masterpost_publish_local_time,
                publish_type: values.masterpost_publish_type,
                scheduled_date: values.masterpost_scheduled_date,
            };

            let target_data = {
                template_text: values.template_text,
                template_file: values.template_file,
                library_file: this.state.compose_data.library_file,
                rules_location: values.target_rules_location,
                rules_tags: values.target_rules_tags,
                rules_custom: values.target_rules_custom,
                tags: values.target_tags,
                location: this.state.compose_data.target_location,
                name: "New Post Target",
                note: "New Post Target Note",
                exclude: values.target_exclude,
                include: values.target_include,
                // custom: values.custom.filter(c => {
                //     if (c.indexOf("|")!==-1) return c;
                // }),
                custom: values.custom,
                social_type_facebook: values.target_social_type_facebook,
                social_type_instagram: values.target_social_type_instagram,
                social_type_twitter: values.target_social_type_twitter,
            };

            // generate shortid of the target
            target_data["shortid"] = shortid.generate();

            // overwrite location
            if (
                typeof this.state.compose_data.target_location_stringified !==
                "undefined"
            ) {
                target_data.location = JSON.parse(
                    this.state.compose_data.target_location_stringified
                );
            }
            if (target_data.location.length === 0) {
                delete target_data["location"];
            }

            let that = this;
            // Adding the masterpost
            this.props.store
                .addMasterpost(masterpost_data)
                .then((newmasterpostObj) => {
                    console.info(
                        "addMasterpost.then newmasterpostObj",
                        newmasterpostObj
                    );

                    base.fetch(`masterposts/${newmasterpostObj.key}`, {
                        context: this,
                        // asArray: true
                    })
                        .then((newmasterpost) => {
                            console.info("newmasterpost", newmasterpost);
                            //delete newmasterpost["key"];
                            // Anything to upload
                            newmasterpost.key = newmasterpostObj.key;

                            if (
                                typeof target_data.template_file !=
                                    "undefined" &&
                                this.state.compose_data.changed_template_file
                            ) {
                                const file = target_data.template_file.file
                                    .originFileObj
                                    ? target_data.template_file.file
                                          .originFileObj
                                    : target_data.template_file.file;
                                console.info("FILE", file);
                                const storageRef = firebase.storage().ref();
                                console.info("storageRef", storageRef);

                                let now = Date.now();
                                let fileparts = file.name.split(".");
                                let fileext = fileparts.pop();
                                const mainImage = storageRef.child(
                                    fileparts.join(".") +
                                        "_" +
                                        now.valueOf() +
                                        "." +
                                        fileext
                                );
                                console.info("mainImage", mainImage);
                                let uploadTask = mainImage.put(file);
                                let that = this;

                                uploadTask.on(
                                    "state_changed",
                                    function (snapshot) {
                                        // Observe state change events such as progress, pause, and resume
                                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                        var progress =
                                            (snapshot.bytesTransferred /
                                                snapshot.totalBytes) *
                                            100;
                                        console.log(
                                            "Upload is " + progress + "% done"
                                        );
                                        that.setState({
                                            upload_percentage:
                                                Math.round(progress),
                                        });
                                    },
                                    function (error) {
                                        // Handle unsuccessful uploads
                                        console.error(
                                            "unsuccessful upload: " + error
                                        );
                                    },
                                    function () {
                                        // Handle successful uploads on complete
                                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                                        uploadTask.snapshot.ref
                                            .getDownloadURL()
                                            .then(function (downloadURL) {
                                                console.log(
                                                    "File available at",
                                                    downloadURL
                                                );

                                                target_data.template_file = {
                                                    name: file.name,
                                                    size: file.size,
                                                    type: file.type,
                                                    url: downloadURL,
                                                    meta: uploadTask.snapshot
                                                        .metadata,
                                                };

                                                newmasterpost.targets = [
                                                    target_data,
                                                ];
                                                console.info(
                                                    "that.props",
                                                    that.props
                                                );
                                                that.props.store.updateMasterpost(
                                                    newmasterpost.key,
                                                    newmasterpost
                                                );
                                                that.closeAfterPublish();
                                            });
                                    }
                                );
                            } else {
                                // actual create // and use the library file

                                // Overwrite the library file
                                if (target_data.library_file) {
                                    target_data.template_file = {
                                        name: target_data.library_file.file
                                            .name,
                                        size: target_data.library_file.file
                                            .size,
                                        type: target_data.library_file.file
                                            .type,
                                        url: target_data.library_file
                                            .downloadURL,
                                        library_file: true,
                                        library_key:
                                            target_data.library_file.key,
                                    };

                                    // Lock the library file
                                    that.props.store.toggleLibraryItemLock(
                                        { key: target_data.library_file.key },
                                        true
                                    );

                                    delete target_data.library_file;
                                }

                                newmasterpost.targets = [target_data];
                                that.props.store.updateMasterpost(
                                    newmasterpost.key,
                                    newmasterpost
                                );
                                that.closeAfterPublish();
                            }
                            // https://firebase.google.com/docs/storage/web/upload-files
                        })
                        .catch((error) => {
                            social_notification("compose_publish", "error", {
                                description: error,
                            });
                        });

                    // Adding the target // upload the image
                })
                .catch((error) => {
                    social_notification("compose_publish", "error", {
                        description: error,
                    });
                });
        });
    };

    closeAfterPublish = () => {
        const { form } = this.formRef.props;

        social_notification("compose_publish", "info");

        form.resetFields();
        this.setState({ modal_visible: false, modal_processing: false });
    };
    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    handleMenuClick = (_evt) => {
        console.info("handleMenuClick _evt", _evt);
        switch (_evt.key) {
            case "new_account":
                this.accountModal.current.registerModal();
                this.props.store.showModalByKey("accounts_modal");
                break;
            case "new_tag":
                this.tagsModal.current.registerModal();
                this.props.store.showModalByKey("tags_modal_add");
                break;
            case "new_category":
                this.categoriesModal.current.registerModal();
                this.props.store.showModalByKey("categories_modal_add");
                break;
            case "new_campaign":
                this.campaignsModal.current.registerModal();
                this.props.store.showModalByKey("campaigns_modal_new");
                break;
            case "new_competitor":
                this.competitorsModal.current.registerModal();
                this.props.store.showModalByKey("competitors_modal_add");
                break;
            case "new_ad":
                this.adsModal.current.registerModal();
                this.props.store.showModalByKey("ads_modal", { type: "ad" });
                break;
            case "new_library":
                this.libraryModal.current.registerModal();
                this.props.store.showModalByKey("library_modal");
                break;
            case "new_user":
                this.usersModal.current.registerModal();
                this.props.store.showModalByKey("users_modal");
                break;
            case "new_masterpost":
                this.masterpostsModal.current.registerModal();
                this.props.store.showModalByKey("masterposts_modal_new");
                break;
            default:
                break;
        }
    };
    render() {
        const currentSection = this.props.store.getCurrentSection;

        const menu = (
            <Menu
                onClick={this.handleMenuClick}
                className="social_topRight_menu"
            >
                <Menu.Item key="new_masterpost">
                    <Icon type="container" />
                    New {names.get("MASTERPOST")}
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="new_account">
                    <Icon type="team" />
                    New account
                </Menu.Item>
                <Menu.Item key="new_tag">
                    <Icon type="tags" />
                    New tag
                </Menu.Item>
                <Menu.Item key="new_category">
                    <Icon type="tags" />
                    New category
                </Menu.Item>
                <Menu.Item key="new_campaign">
                    <Icon type="cluster" />
                    New campaign
                </Menu.Item>
                <Menu.Item key="new_competitor">
                    <Icon type="block" />
                    New competitor
                </Menu.Item>
                <Menu.Item key="new_ad">
                    <Icon type="shop" />
                    New ad
                </Menu.Item>
                <Menu.Item key="new_library">
                    <Icon type="hdd" />
                    New library file
                </Menu.Item>
                <Menu.Item key="new_user">
                    <Icon type="usergroup-add" />
                    New user
                </Menu.Item>
            </Menu>
        );

        return (
            <Fragment>
                {!this.props.hideButton ? (
                    <Fragment>
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <Button
                                type={
                                    currentSection === "dashboard" ||
                                    currentSection === ""
                                        ? "primary"
                                        : "default"
                                }
                                style={{
                                    marginRight: 15,
                                }}
                                // icon="plus-circle"
                                // onClick={this.showModal}
                                className="social_joyride_step2"
                            >
                                New <Icon type="plus" />
                            </Button>
                        </Dropdown>

                        <MasterpostsListModalNew
                            //data={this.state.new_data}
                            store={this.props.store}
                            //onCancel={this.handleModalNewCancel}
                            //onCreate={this.handleModalNewCreate}
                            //onAddTarget={this.handleModalNewAddTarget}
                            //onDeleteTarget={this.handleModalNewDeleteTarget}
                            //handleTargetChange={this.handleTargetChange}
                            ref={this.masterpostsModal}
                        />
                        <AccountsListModal
                            store={this.props.store}
                            data={this.state.modal_account_data}
                            ref={this.accountModal}
                        />
                        <TagsListModal
                            store={this.props.store}
                            ref={this.tagsModal}
                        />
                        <CategoriesListModal
                            store={this.props.store}
                            ref={this.categoriesModal}
                        />
                        <CampaignsListModalNew
                            store={this.props.store}
                            ref={this.campaignsModal}
                        />
                        <CampaignsListModal
                            store={this.props.store}
                            ref={this.campaignsModalApply}
                        />
                        <CompetitorsListModal
                            data={this.state.modal_competitor_data}
                            store={this.props.store}
                            ref={this.competitorsModal}
                        />
                        <AdsListModal
                            data={this.state.modal_ad_data}
                            store={this.props.store}
                            ref={this.adsModal}
                        />
                        <LibraryModal
                            data={this.state.modal_library_data}
                            store={this.props.store}
                            ref={this.libraryModal}
                        />
                        <UsersListModal
                            store={this.props.store}
                            ref={this.usersModal}
                        />
                    </Fragment>
                ) : null}

                {this.state.modal_visible ? (
                    <ComposeForm
                        wrappedComponentRef={this.saveFormRef}
                        visible={this.state.modal_visible}
                        onCancel={this.closeModal}
                        onPublish={this.onPublish}
                        data={this.state.compose_data}
                        locations={this.props.store.getLocations()}
                        tags={this.props.store.getAllTags}
                        store={this.props.store}
                        processing={this.state.modal_processing}
                        upload_percentage={this.state.upload_percentage}
                        modal_file_update={this.state.modal_file_update}
                    />
                ) : null}
            </Fragment>
        );
    }
}

export default observer(Compose);
