import React, { Component, Fragment } from "react";

import config from "./../../config";
import tooltips from "./../../tooltips";

import { observer } from "mobx-react";

import {
    Empty,
    List,
    Button,
    Tag,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Modal,
    Checkbox,
    Icon,
} from "antd";
import scss_variables from "./../../_variables.scss";
import TagsListModal from "./TagsListModal";
import SocialTag from "./../SocialTag";
import Bulk from "./../Bulk";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class TagsList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            //edit_data: {},
            pagination_currentPage: 1,
            sortby: "name",
            sortasc: true,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("tags");
    }
    componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);
        this.listBulk = new Bulk({
            name: "tagsList",
            list: this.props.store.getAllTags.map((element) => element.tag),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        await this.props.store.deleteTag(_key);
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                },
            ],
            callback: () => {
                console.log("READY");
                this.setState({ tags: this.props.store.getAllTags });
            },
        });
    }

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("tags_modal_add");
    }

    showModal = () => {
        console.log("showModal");
        this.setState({ selected_tag: {} });
        this.props.store.showModalByKey("tags_modal_add");
    };

    handleDeleteTag = (_evt, _item) => {
        console.info("handleDeleteTag _evt", _evt, "_item", _item);
        let that = this;
        confirm({
            title: "Do you Want to delete these items?",
            content: "Some descriptions",
            okType: "danger",
            async onOk() {
                await that.props.store.deleteTag(_item.tag).then(() => {
                    that.setState({ tags: that.props.store.getAllTags });
                });
            },
        });
    };

    handleEdit = async (_evt, _tag) => {
        console.info("_tag.tag", _tag.tag);
        let channels = [];
        this.props.store.getAllAccounts.filter((account) => {
            const accountChannels =
                this.props.store.getAccountChannelsFromAccountData(account);
            console.info("accountChannels", accountChannels);
            const filtered_channels =
                accountChannels &&
                accountChannels.filter(
                    (channel) =>
                        channel.tags && channel.tags.indexOf(_tag.tag) !== -1
                );
            console.info("filtered_channels", filtered_channels);
            if (filtered_channels.length > 0) {
                channels = channels.concat(filtered_channels);
            }
            return false;
        });
        console.info("channels", channels);
        _tag.channels = channels;
        console.info("_tag", _tag);

        this.setState({ selected_tag: _tag });
        this.props.store.showModalByKey("tags_modal_add");
    };

    // handleEdit = async (_evt, _tag) => {
    //     console.info("_tag", _tag);

    //     const old_tag = _tag.tag;

    //     try {
    //         const new_tag = await prompt({
    //             title: "Edit Tag",
    //             placeholder: "Tag name",
    //             rules: [
    //                 // check this link for more help: https://ant.design/components/form/#Validation-Rules
    //                 {
    //                     required: true,
    //                     message: "You must enter name",
    //                 },
    //             ],
    //             initialValue: _tag.tag,
    //             modalProps: {
    //                 width: "50%",
    //             },
    //         });
    //         this.props.store
    //             .editTag(old_tag, new_tag)
    //             .then(() => {
    //                 // update the tags in this state
    //                 this.setState({
    //                     tags: this.props.store.getAllTags,
    //                 });
    //             })
    //             .catch((err) => {
    //                 console.error(err);
    //             });
    //         //alert("old_category=" + old_category + ",new_name=" + new_name);
    //     } catch (e) {
    //         console.warn(e);
    //         //message.error("Please enter name");
    //     }
    // };

    showReport = (_evt, _item) => {
        console.info("showReport _evt", _evt, "_item", _item);

        this.props.history.push(`/dashboard/tags/${_item.tag}/report`);
    };

    showAccounts = (_evt, _item) => {
        console.info("showReport _evt", _evt, "_item", _item);

        //this.props.history.push(`/dashboard/tags/accounts/${_item.tag}`);

        this.props.history.push(`/dashboard/accounts/filter/${_item.tag}`);
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        this.setState({ sortby: _sorting, sortasc });
    };
    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    getListingDataSource = () => {
        let results = this.props.store.getAllTags.filter((t) => {
            return (
                t.tag.toUpperCase().indexOf(this.state.filter.toUpperCase()) !==
                -1
            );
        });

        // --- sorting ---
        results.sort((a, b) => {
            //console.info("results.sort (this.state.sortby)", this.state.sortby);

            var nameA = a.tag.toLowerCase(),
                nameB = b.tag.toLowerCase();
            var channelsA = a.count,
                channelsB = b.count;

            switch (this.state.sortby) {
                case "name":
                    if (this.state.sortasc === true) {
                        if (nameA <= nameB) return -1;
                        if (nameA > nameB) return 1;
                    } else {
                        if (nameA <= nameB) return 1;
                        if (nameA > nameB) return -1;
                    }
                    break;
                case "channels":
                    if (this.state.sortasc === true) {
                        if (channelsA <= channelsB) return -1;
                        if (channelsA > channelsB) return 1;
                    } else {
                        if (channelsA <= channelsB) return 1;
                        if (channelsA > channelsB) return -1;
                    }
                    break;
                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting

        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    render() {
        //console.info("TagsList Render", this.props.store.getAllTags);

        const pagesCount =
            this.props.store.getAllTags.length / config.PAGINATION_PERPAGE;
        // console.log("pagesCount = " + pagesCount);
        const header = (
            <SocialListHeader
                title="Tags"
                newButtonClickHandle={this.showModal}
                newButtonText="New tag"
                newButtonTooltip={tooltips.TAGS.NEWBUTTON}
                bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );
        // let header = (
        //     <Row
        //         type="flex"
        //         justify="space-between"
        //         style={{ marginBottom: 10 }}
        //     >
        //         <Col>
        //             <Title level={3} style={{ float: "left", paddingRight: 5 }}>
        //                 Tags
        //             </Title>
        //             {/* <Badge
        //                 count={this.props.store.getAllTags.length}
        //                 style={{ backgroundColor: "#1890ff" }}
        //             /> */}
        //         </Col>
        //         <Col>
        //             {
        //                 <Tooltip
        //                     title={tooltips.TAGS.NEWBUTTON}
        //                     mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
        //                 >
        //                     <Button
        //                         type="primary"
        //                         // icon="plus"
        //                         size="small"
        //                         onClick={this.showModal}
        //                         style={{
        //                             marginLeft: 20,
        //                             marginTop: 5,
        //                             fontSize: 12,
        //                         }}
        //                     >
        //                         New tag
        //                     </Button>
        //                 </Tooltip>
        //             }
        //         </Col>
        //     </Row>
        // );

        let body =
            this.props.store.getAllTags.length === 0 ? (
                <Empty />
            ) : (
                <List
                    size="small"
                    className="TagsList"
                    header={
                        <Fragment>
                            {/* <Row type="flex" justify="space-between">
                                <Col
                                    style={{
                                        minWidth: 25,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                        style={{ marginRight: 10 }}
                                    />
                                    {this.listBulk &&
                                        this.listBulk.renderOptionsAsList()}
                                </Col>
                                <Col>
                           
                                    <Search
                                        placeholder="filter"
                                        onSearch={this.onSearchChange}
                                        onChange={(e) =>
                                            this.onSearchChange(e.target.value)
                                        }
                                        style={{ width: 200 }}
                                    />
                                </Col>
                            </Row> */}
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Col style={{ width: 25, lineHeight: 1.2 }}>
                                    {/* <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                    /> */}
                                </Col>
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Tag name
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="channels"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Channels
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ width: 180 }}>Tools</Col>
                            </Row>
                        </Fragment>
                    }
                    footer={
                        pagesCount > 1 && this.state.filter.length === 0 ? (
                            <div style={{ textAlign: "right" }}>
                                <Pagination
                                    size="small"
                                    defaultCurrent={1}
                                    total={this.props.store.getAllTags.length}
                                    pageSize={config.PAGINATION_PERPAGE}
                                    current={this.state.pagination_currentPage}
                                    onChange={this.paginationSetPage}
                                />
                            </div>
                        ) : null
                    }
                    bordered
                    dataSource={this.getListingDataSource()}
                    renderItem={(item) => {
                        return (
                            <List.Item
                                key={item.tag}
                                className={
                                    this.listBulk &&
                                    this.listBulk.isChecked(item.tag)
                                        ? this.listBulk.getCheckedLength() === 1
                                            ? "social_Selected_withTools"
                                            : "social_Selected"
                                        : null
                                }
                                actions={[
                                    <Tooltip
                                        key="edit"
                                        title={tooltips.TAGS.LIST_EDIT}
                                        mouseEnterDelay={
                                            tooltips.SETTINGS.mouseEnterDelay
                                        }
                                    >
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                            onClick={(_evt) => {
                                                this.handleEdit(_evt, item);
                                            }}
                                        >
                                            <Icon type="edit" />
                                        </Button>
                                    </Tooltip>,
                                    <Tooltip
                                        key="delete"
                                        title={tooltips.TAGS.LIST_DELETE}
                                        mouseEnterDelay={
                                            tooltips.SETTINGS.mouseEnterDelay
                                        }
                                    >
                                        {/* <Text type="danger">delete</Text> */}
                                        <Icon
                                            onClick={(evt) => {
                                                this.handleDeleteTag(evt, item);
                                            }}
                                            type="delete"
                                            style={{
                                                color: scss_variables.scss_color_pallete_error,
                                            }}
                                        />
                                    </Tooltip>,
                                    // <Button
                                    //     key="report"
                                    //     type="link"
                                    //     style={{ padding: 0 }}
                                    //     onClick={(_evt) => {
                                    //         this.showReport(_evt, item);
                                    //     }}
                                    // >
                                    //     report
                                    // </Button>,
                                    // <Tooltip title="Accounts" key="accounts">
                                    //     <Button
                                    //         type="link"
                                    //         style={{ padding: 0 }}
                                    //         onClick={(_evt) => {
                                    //             this.showAccounts(_evt, item);
                                    //         }}
                                    //     >
                                    //         <Icon type="team" />
                                    //     </Button>
                                    // </Tooltip>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={
                                        <Fragment>
                                            <Checkbox
                                                onChange={(e) =>
                                                    this.listBulk.handleCheck(
                                                        item.tag
                                                    )
                                                }
                                                checked={
                                                    this.listBulk
                                                        ? this.listBulk.isChecked(
                                                              item.tag
                                                          )
                                                        : false
                                                }
                                                style={{ marginRight: 10 }}
                                            />
                                            <SocialTag
                                                store={this.props.store}
                                                key={item.tag}
                                                color="tag"
                                            >
                                                {item.tag}
                                            </SocialTag>
                                        </Fragment>
                                    }
                                />
                                <div style={{ flexGrow: 1, textAling: "left" }}>
                                    {item.count} channels
                                </div>
                            </List.Item>
                        );
                    }}
                />
            );

        return (
            <Fragment>
                {header}
                {body}
                <TagsListModal
                    store={this.props.store}
                    data={this.state.selected_tag}
                />
            </Fragment>
        );
    }
}

export default observer(TagsList);
