import React, { Component, Fragment } from "react";
import { Row, Col, Divider } from "antd";

import scss_variables from "./../../_variables.scss";
import SocialDataPresentor from "./../SocialDataPresentor";
import AccountsChannelInsightsLinkedin from "./AccountsChannelInsightsLinkedin";
import AccountsChannelInsightsTwitter from "./AccountsChannelInsightsTwitter";
import AccountsChannelInsightsFacebook from "./AccountsChannelInsightsFacebook";
import AccountsChannelInsightsInstagram from "./AccountsChannelInsightsInstagram";
import AccountsChannelInsightsYoutube from "./AccountsChannelInsightsYoutube";

const GRID_GUTTER = 40;

class AccontsChannelInsights extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_impressions: null,
            page_fans: null,
            page_engaged_users: null,
            channel_insights: {},
        };
    }

    UNSAFE_componentWillMount() {
        //console.info("AccontsChannelInsights this.props", this.props);
    }

    componentDidMount() {
        // --- Values to extract ---
        // -- KEY MEtrIX --
        // TOTAL FANS
        // POSTS PUBLISHED
        // TOTAL ENGAGEMENT
        // REPLIES
        // AWARENESS
        // IMPRESSIONS
        // CLICKS
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.channel_insights) {
            // Save the channel insights
            this.setState({ channel_insights: nextProps.channel_insights });
        }
    }

    getInsightsData = (_data, _key) => {
        return (
            _data &&
            _data.filter &&
            _data.filter((d) => {
                if (d.name === _key) return d;
                return false;
            })
        );
    };

    render() {
        // const { myValue } = this.state;

        // let channel_insights_tab = (
        //     <Spin
        //         size="large"
        //         tip="Loading..."
        //         indicator={
        //             <Icon
        //                 type="loading"
        //                 style={{ fontSize: 24, margin: 20 }}
        //                 spin
        //             />
        //         }
        //     />
        // );
        // if (
        //     this.props.channel_insights &&
        //     this.props.channel_insights.length > 0
        // ) {
        //     channel_insights_tab = (
        //         <Fragment>
        //             insights{" "}
        //             <pre>
        //                 {JSON.stringify(this.props.channel_insights, null, 2)}
        //             </pre>
        //         </Fragment>
        //     );
        // }

        const facebookInsights = (
            <AccountsChannelInsightsFacebook
                channel_insights={this.state.channel_insights}
            />
        );

        const instagramInsights = (
            <AccountsChannelInsightsInstagram
                channel_insights={this.state.channel_insights}
            />
        );

        const twitterInsights = (
            <AccountsChannelInsightsTwitter
                channel_insights={this.state.channel_insights}
            />
        );

        const linkedinInsights = (
            <AccountsChannelInsightsLinkedin
                channel_insights={this.state.channel_insights}
            />
        );

        const youtubeInsights = (
            <AccountsChannelInsightsYoutube
                channel_insights={this.state.channel_insights}
            />
        );

        return (
            <Fragment>
                <br />
                <br />
                {this.props.provider === "facebook" ? facebookInsights : null}
                {this.props.provider === "instagram" ? instagramInsights : null}
                {this.props.provider === "twitter" ? twitterInsights : null}
                {this.props.provider === "linkedin" ? linkedinInsights : null}
                {this.props.provider === "youtube" ? youtubeInsights : null}
                {/* <hr />
                {channel_insights_tab} */}
                {/* this.props.provider = {this.props.provider}
                <br />
                <pre>
                    {JSON.stringify(this.props.channel_insights, null, 2)}
                </pre> */}
            </Fragment>
        );
    }
}

export default AccontsChannelInsights;
