import React, { Component, Fragment } from "react";
import {
    Form,
    Modal,
    Button,
    Icon,
    AutoComplete,
    Tabs,
    Input,
    Checkbox,
    Slider,
    Spin,
    Select,
    DatePicker,
    Tree,
    Radio,
    InputNumber,
    Row,
    Col,
    Dropdown,
    Menu,
    Alert,
} from "antd";

import EditableTagGroup from "../EditableTagGroup";
import FilePicker from "../FilePicker";
import AdsPreview from "./AdsPreview";
import config from "../../config";
import moment from "moment";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const { TreeNode } = Tree;
const { RangePicker } = DatePicker;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        typeTimer = null;

        constructor(props) {
            super(props);

            this.state = {
                name: props.data.parent.name,
                campaigns: props.data.parent.campaigns,
                brand: props.data.parent.brand,
                facebook_targeting: {
                    countries: [],
                    regions: [],
                    cities: [],
                    zips: [],
                    languages: [],
                    interests: [],
                    custom_audiences: [],
                    //
                    selected_gender: "all",
                    selected_age: [30, 50],
                    selected_country: [],
                    selected_region: [],
                    selected_city: [],
                    selected_zip: [],
                    selected_language: [],
                    selected_interest: [],
                },
                facebook_details: {
                    objective: "REACH",
                    optimization_goal: "REACH",
                    billing_event: "IMPRESSIONS",
                    bid_amount: 2,
                    daily_budget: 1000,
                    status: "PAUSED",
                },
            };
        }

        componentDidMount() {
            // get the targeting info
            let targeting = null;
            switch (this.props.data.parent_type) {
                case "masterpost":
                    targeting = JSON.parse(
                        JSON.stringify(
                            this.props.data.children[0].targeting_info
                        )
                    );
                    break;
                case "masterad":
                    targeting = JSON.parse(
                        JSON.stringify(
                            this.props.data.children[0].targeting_info
                        )
                    );
                    break;
                default:
                    break;
            }

            console.info("targeting", targeting);
            let { facebook_targeting } = this.state;

            if (targeting.gender) {
                facebook_targeting.selected_gender = targeting.gender;
                facebook_targeting.selected_overwrite_gender = true;
            }
            if (targeting.age) {
                facebook_targeting.selected_age = targeting.age;
                facebook_targeting.selected_overwrite_age = true;
            }
            if (targeting.country) {
                facebook_targeting.selected_country = targeting.country;
                facebook_targeting.selected_overwrite_country = true;
            }
            if (targeting.region) {
                facebook_targeting.selected_region = targeting.region;
                facebook_targeting.selected_overwrite_region = true;
            }
            if (targeting.city) {
                facebook_targeting.selected_city = targeting.city;
                facebook_targeting.selected_overwrite_city = true;
            }
            if (targeting.zip) {
                facebook_targeting.selected_zip = targeting.zip;
                facebook_targeting.selected_overwrite_zip = true;
            }
            if (targeting.language) {
                facebook_targeting.selected_language = targeting.language;
                facebook_targeting.selected_overwrite_language = true;
            }
            if (targeting.interest) {
                facebook_targeting.selected_interest = targeting.interest;
                facebook_targeting.selected_overwrite_interest = true;
            }

            let { details } = this.props.data.parent;
            let { facebook_details } = this.state;
            facebook_details = { ...facebook_details, ...details };
            console.info(
                "details",
                details,
                "facebook_details",
                facebook_details
            );
            // facebook_targeting = Object.assign(facebook_targeting, targeting);
            // To disable submit button at the beginning.
            // this.fetchInsights(this.props.data.key);
            let { ad } = this.props.data.parent;
            console.info("---ad---", ad);

            this.setState({
                name: this.props.data.parent.name,
                campaigns: this.props.data.parent.campaigns,
                brand: this.props.data.parent.brand,
                facebook_targeting,
                facebook_details,
                ad: { ...ad },
                previewURL:
                    this.props.data.parent_type === "masterad" &&
                    ad.type === "singleimagead"
                        ? ad.image.image_data
                        : null,
            });
        }

        handleCampaignsChange = (_campaigns) => {
            this.setState({ campaigns: _campaigns });
        };

        fetchCountry = (value) => {
            // console.log("fetchCountry value", value);
            const { facebook_targeting } = this.state;
            facebook_targeting.countries = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(
                        value,
                        "adgeolocation",
                        `location_types=["country"]`
                    )
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.countries = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchRegion = (value) => {
            // console.log("fetchRegion value", value);
            if (value.length < 2) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.regions = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(
                        value,
                        "adgeolocation",
                        `location_types=["region"]`
                    )
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.regions = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchCity = (value) => {
            // console.log("fetchCity value", value);
            if (value.length < 3) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.cities = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(
                        value,
                        "adgeolocation",
                        `location_types=["city"]`
                    )
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.cities = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchZip = (value) => {
            // console.log("fetchZip value", value);
            if (value.length < 2) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.zips = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(
                        value,
                        "adgeolocation",
                        `location_types=["zip"]`
                    )
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.zips = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchLanguage = (value) => {
            // console.log("fetchLanguage value", value);
            if (value.length < 3) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.languages = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(value, "adlocale")
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.languages = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchInterest = (value) => {
            // console.log("fetchInterest value", value);
            if (value.length < 3) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.interests = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(value, "adinterest")
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.interests = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };

        selectFacebookTargetParam = (_param, _value) => {
            // console.info(
            //     "selectFacebookTargetParam _param",
            //     _param,
            //     "_value",
            //     _value
            // );
            let { facebook_targeting } = this.state;
            facebook_targeting["selected_" + _param] = _value;
            this.setState({ facebook_targeting, changed: true });
        };

        renderAccountsTreeNodes = (data) =>
            data.map((item) => {
                if (item.children) {
                    return (
                        <TreeNode
                            title={item.title}
                            key={item.key}
                            dataRef={item}
                        >
                            {this.renderAccountsTreeNodes(item.children)}
                        </TreeNode>
                    );
                } else {
                    return (
                        <TreeNode
                            title={
                                <Fragment>
                                    <Icon
                                        type="facebook"
                                        theme="filled"
                                        style={{ marginRight: 5 }}
                                    />
                                    {item.title}
                                </Fragment>
                            }
                            key={item.key}
                            dataRef={item}
                        ></TreeNode>
                    );
                }
                //return <TreeNode key={item.key} {...item} />;
            });

        handleFilePickerChange = (_info) => {
            console.info("handleFilePickerChange _info", _info);
        };

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            const { facebook_targeting, fetching } = this.state;

            // const loadingIcon = fetching ? (
            //     <Icon type="loading" style={{ fontSize: 24 }} spin />
            // ) : null;

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };

            const allAccounts = this.props.store.getAllAccounts.map((a) => {
                let accountData = {
                    title: `${a.name}`,
                    key: a.key,
                };
                if (
                    a.facebook &&
                    a.facebook.data &&
                    a.facebook.data.length > 0
                ) {
                    accountData.children = a.facebook.data.map((d) => {
                        return {
                            title: d.name,
                            key: a.key + "|" + d.id,
                        };
                    });
                }
                return accountData;
            });
            console.info(
                "allAccounts",
                allAccounts,
                "this.state.ad",
                this.state.ad
            );

            const singleImageAdFields = (
                <Fragment>
                    <Form.Item label="Title">
                        {getFieldDecorator("ad_title", {
                            initialValue:
                                this.state.ad && this.state.ad.title
                                    ? this.state.ad.title
                                    : null,
                            onChange: (e) => {
                                let { ad } = this.state;
                                ad.title = e.target.value;
                                this.setState({
                                    ad,
                                });
                            },
                        })(<Input maxLength={25} />)}
                    </Form.Item>
                    <Form.Item label="Body">
                        {getFieldDecorator("ad_body", {
                            initialValue:
                                this.state.ad && this.state.ad.body
                                    ? this.state.ad.body
                                    : null,
                            onChange: (e) => {
                                let { ad } = this.state;
                                ad.body = e.target.value;
                                this.setState({
                                    ad,
                                });
                            },
                        })(<TextArea rows={2} />)}
                    </Form.Item>
                    <Form.Item label="URL">
                        {getFieldDecorator("ad_url", {
                            initialValue:
                                this.state.ad && this.state.ad.url
                                    ? this.state.ad.url
                                    : null,
                            onChange: (e) => {
                                let { ad } = this.state;
                                ad.url = e.target.value;
                                this.setState({
                                    ad,
                                });
                            },
                        })(<Input maxLength={1024} />)}
                    </Form.Item>
                    <Form.Item label="Call To Action">
                        {getFieldDecorator("newad_calltoactiontype", {
                            initialValue:
                                this.state.ad && this.state.ad.calltoactiontype
                                    ? this.state.ad.calltoactiontype
                                    : null,
                            onChange: (e) => {
                                let { ad } = this.state;
                                ad.calltoactiontype = e;
                                this.setState({
                                    ad,
                                });
                            },
                        })(
                            <Select>
                                {config.FACEBOOK_ADS_CALLTOACTION_TYPES.map(
                                    (option, optionIndex) => {
                                        return (
                                            <Option
                                                key={optionIndex}
                                                value={option}
                                            >
                                                {option}
                                            </Option>
                                        );
                                    }
                                )}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Ad Image">
                        <FilePicker
                            store={this.props.store}
                            restrictions={{ width: 110, height: 90 }}
                            // nopreview
                            onChange={this.handleFilePickerChange}
                            selected={this.state.ad && this.state.ad.image}
                        />
                    </Form.Item>
                </Fragment>
            );

            const carouselAdFields = (_index) => {
                return (
                    <Fragment>
                        <Form.Item label="Title">
                            {getFieldDecorator(
                                "carousel_" + _index + "_title",
                                {
                                    initialValue: this.state.ad.carousel[_index]
                                        .title
                                        ? this.state.ad.carousel[_index].title
                                        : null,
                                    onChange: (e) => {
                                        let { ad } = this.state;
                                        ad.carousel[_index].title =
                                            e.target.value;
                                        this.setState({
                                            ad,
                                        });
                                    },
                                }
                            )(<Input maxLength={25} />)}
                        </Form.Item>
                        <Form.Item label="Body">
                            {getFieldDecorator("carousel_" + _index + "_body", {
                                initialValue: this.state.ad.carousel[_index]
                                    .body
                                    ? this.state.ad.carousel[_index].body
                                    : null,
                                onChange: (e) => {
                                    let { ad } = this.state;
                                    ad.carousel[_index].body = e.target.value;
                                    this.setState({
                                        ad,
                                    });
                                },
                            })(<TextArea rows={2} />)}
                        </Form.Item>
                        <Form.Item label="URL">
                            {getFieldDecorator("carousel_" + _index + "_url", {
                                initialValue: this.state.ad.carousel[_index].url
                                    ? this.state.ad.carousel[_index].url
                                    : null,
                                onChange: (e) => {
                                    let { ad } = this.state;
                                    ad.carousel[_index].url = e.target.value;
                                    this.setState({
                                        ad,
                                    });
                                },
                            })(<Input maxLength={1024} />)}
                        </Form.Item>
                        <Form.Item label="Ad Image">
                            <FilePicker
                                store={this.props.store}
                                restrictions={{ width: 110, height: 90 }}
                                // nopreview
                                onChange={(file) =>
                                    this.handleFilePickerChange(file, _index)
                                }
                                selected={
                                    this.state.ad &&
                                    this.state.ad.carousel[_index].image
                                }
                            />
                        </Form.Item>
                    </Fragment>
                );
            };

            const infoPanel = (
                <Fragment>
                    <Form.Item label="Ad Name">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message: "Please input the name of the ad!",
                                    type: "string",
                                },
                            ],
                            initialValue: this.state.name,
                            onChange: (e) =>
                                this.setState({ name: e.target.value }),
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Campaigns">
                        {getFieldDecorator("campaigns", {
                            initialValue: this.state.campaigns
                                ? this.state.campaigns
                                : [],
                            onChange: this.handleCampaignsChange,
                        })(
                            <EditableTagGroup
                                element_title="Campaigns"
                                tag_color={this.props.store.getTagColorForType(
                                    "campaign"
                                )}
                                options={this.props.store.getAllCampaigns.map(
                                    (campaign) => campaign.campaign
                                )}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Brand">
                        {getFieldDecorator("brand", {
                            initialValue: this.state.brand,
                            onChange: (e) => this.setState({ brand: e }),
                        })(
                            <AutoComplete
                                dataSource={this.props.store.getAllBrands}
                                // style={{ width: 200 }}
                                placeholder="input here"
                            />
                        )}
                    </Form.Item>
                </Fragment>
            );
            const adPanel = (
                <Fragment>
                    <div style={{ minHeight: 352 }}>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item label="Type">
                                    {getFieldDecorator("ad_type", {
                                        initialValue:
                                            this.state.ad && this.state.ad.type
                                                ? this.state.ad.type
                                                : null,
                                        onChange: (e) => {
                                            let { ad } = this.state;
                                            ad.type = e;
                                            this.setState({
                                                ad,
                                            });
                                        },
                                    })(
                                        <Select>
                                            <Option value="singleimagead">
                                                Single Image
                                            </Option>
                                            <Option value="carouselad">
                                                Carousel
                                            </Option>
                                            <Option
                                                disabled
                                                value="collectionad"
                                            >
                                                Collection (upcoming)
                                            </Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                {this.state.ad &&
                                this.state.ad.type === "singleimagead" ? (
                                    <Tabs
                                        // defaultActiveKey="1"
                                        onChange={this.callback}
                                    >
                                        <TabPane
                                            tab={"Ad Settings"}
                                            key="single"
                                        >
                                            {singleImageAdFields}
                                        </TabPane>
                                    </Tabs>
                                ) : null}
                                {this.state.ad &&
                                this.state.ad.type === "carouselad" ? (
                                    <div style={{ position: "relative" }}>
                                        <Tabs
                                            // defaultActiveKey="1"
                                            onChange={this.callback}
                                            style={
                                                {
                                                    // width:
                                                    //     "calc(100% - 120px)"
                                                }
                                            }
                                        >
                                            {this.state.ad.carousel.map(
                                                (carousel, carouselIndex) => {
                                                    return (
                                                        <TabPane
                                                            tab={
                                                                <div
                                                                    key={
                                                                        carouselIndex
                                                                    }
                                                                    style={{
                                                                        paddingRight: 5,
                                                                    }}
                                                                >
                                                                    {"Slide " +
                                                                        Number(
                                                                            carouselIndex +
                                                                                1
                                                                        )}
                                                                    <Dropdown
                                                                        overlay={
                                                                            <Menu
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    console.info(
                                                                                        "e",
                                                                                        e
                                                                                    );
                                                                                    switch (
                                                                                        e.key
                                                                                    ) {
                                                                                        case "duplicate":
                                                                                            this.duplicateCarouselSlide(
                                                                                                e,
                                                                                                carouselIndex
                                                                                            );
                                                                                            break;
                                                                                        case "delete":
                                                                                            this.deleteCarouselSlide(
                                                                                                e,
                                                                                                carouselIndex
                                                                                            );
                                                                                            break;
                                                                                        default:
                                                                                            break;
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <Menu.Item key="duplicate">
                                                                                    <Icon type="copy" />
                                                                                    Duplicate
                                                                                </Menu.Item>
                                                                                <Menu.Item key="delete">
                                                                                    <Icon type="delete" />
                                                                                    Delete
                                                                                </Menu.Item>
                                                                            </Menu>
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            type="more"
                                                                            style={{
                                                                                position:
                                                                                    "absolute",
                                                                                padding: 3,
                                                                                right: -6,
                                                                                bottom: 12,
                                                                            }}
                                                                        />
                                                                    </Dropdown>
                                                                </div>
                                                            }
                                                            key={carouselIndex}
                                                        >
                                                            {carouselAdFields(
                                                                carouselIndex
                                                            )}
                                                        </TabPane>
                                                    );
                                                }
                                            )}
                                        </Tabs>
                                        <Button
                                            size="small"
                                            icon="plus"
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                top: 10,
                                            }}
                                            onClick={this.addCarouselSlide}
                                        >
                                            {/* <Icon type="plus" /> New
                                                    Slide */}
                                        </Button>
                                    </div>
                                ) : null}

                                <Form.Item label="Accounts">
                                    <Tree
                                        checkable
                                        selectable={false}
                                        // onExpand={this.onExpand}
                                        // expandedKeys={this.state.expandedKeys}
                                        // autoExpandParent={this.state.autoExpandParent}
                                        onCheck={this.onNewAdCheck}
                                        checkedKeys={
                                            this.state.ad &&
                                            this.state.ad.accounts
                                        }
                                        // onSelect={this.onSelect}
                                        // selectedKeys={this.state.selectedKeys}
                                        style={{
                                            marginTop: 2,
                                            marginLeft: -26,
                                        }}
                                    >
                                        {this.renderAccountsTreeNodes(
                                            allAccounts
                                        )}
                                    </Tree>
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 50 }}>
                                <AdsPreview
                                    type={this.state.ad && this.state.ad.type}
                                    carousel={
                                        this.state.ad && this.state.ad.carousel
                                    }
                                    defaultTab="facebook"
                                    image_url={this.state.previewURL}
                                    cta_type={
                                        this.state.ad &&
                                        this.state.ad.calltoactiontype
                                    }
                                    link_url={
                                        this.state.ad && this.state.ad.url
                                    }
                                    title={this.state.ad && this.state.ad.title}
                                    description={
                                        this.state.ad && this.state.ad.body
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                </Fragment>
            );
            const facebookTargeting = (
                <Fragment>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    this.state.facebook_targeting
                                        .selected_overwrite_gender
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "overwrite_gender",
                                        e.target.checked
                                    )
                                }
                            >
                                Gender
                            </Checkbox>
                        }
                    >
                        <Radio.Group
                            value={
                                this.state.facebook_targeting.selected_gender
                            }
                            onChange={(e) =>
                                this.selectFacebookTargetParam(
                                    "gender",
                                    e.target.value
                                )
                            }
                        >
                            <Radio.Button value="all">All</Radio.Button>
                            <Radio.Button value="1">Male</Radio.Button>
                            <Radio.Button value="2">Female</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    this.state.facebook_targeting
                                        .selected_overwrite_age
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "overwrite_age",
                                        e.target.checked
                                    )
                                }
                            >
                                Age
                            </Checkbox>
                        }
                    >
                        <Slider
                            range
                            value={[
                                this.state.facebook_targeting.selected_age[0],
                                this.state.facebook_targeting.selected_age[1],
                            ]}
                            min={13}
                            max={100}
                            // tooltipVisible={true}
                            onChange={(e) =>
                                this.selectFacebookTargetParam("age", e)
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    this.state.facebook_targeting
                                        .selected_overwrite_country
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "overwrite_country",
                                        e.target.checked
                                    )
                                }
                            >
                                Country
                            </Checkbox>
                        }
                    >
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select countries"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchCountry}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("country", e);
                            }}
                            style={{ width: "100%" }}
                            value={
                                this.state.facebook_targeting.selected_country
                            }
                        >
                            {facebook_targeting.countries.map((c) => (
                                <Option key={c.key}>{c.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    this.state.facebook_targeting
                                        .selected_overwrite_region
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "overwrite_region",
                                        e.target.checked
                                    )
                                }
                            >
                                Region
                            </Checkbox>
                        }
                    >
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select regions"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchRegion}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("region", e);
                            }}
                            style={{ width: "100%" }}
                            value={
                                this.state.facebook_targeting.selected_region
                            }
                        >
                            {facebook_targeting.regions.map((r) => (
                                <Option key={r.key}>{r.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    this.state.facebook_targeting
                                        .selected_overwrite_city
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "overwrite_city",
                                        e.target.checked
                                    )
                                }
                            >
                                City
                            </Checkbox>
                        }
                    >
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select cities"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchCity}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("city", e);
                            }}
                            style={{ width: "100%" }}
                            value={this.state.facebook_targeting.selected_city}
                        >
                            {facebook_targeting.cities.map((c) => (
                                <Option key={c.key}>{c.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    this.state.facebook_targeting
                                        .selected_overwrite_zip
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "overwrite_zip",
                                        e.target.checked
                                    )
                                }
                            >
                                ZIP
                            </Checkbox>
                        }
                    >
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select zip codes"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchZip}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("zip", e);
                            }}
                            style={{ width: "100%" }}
                            value={this.state.facebook_targeting.selected_zip}
                        >
                            {facebook_targeting.zips.map((z) => (
                                <Option key={z.key}>
                                    {z.country_name} / {z.region} /{" "}
                                    {z.primary_city} - {z.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    this.state.facebook_targeting
                                        .selected_overwrite_language
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "overwrite_language",
                                        e.target.checked
                                    )
                                }
                            >
                                Language
                            </Checkbox>
                        }
                    >
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select languages"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchLanguage}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("language", e);
                            }}
                            style={{ width: "100%" }}
                            value={
                                this.state.facebook_targeting.selected_language
                            }
                        >
                            {facebook_targeting.languages.map((l) => (
                                <Option key={l.key}>{l.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Checkbox
                                checked={
                                    this.state.facebook_targeting
                                        .selected_overwrite_interest
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "overwrite_interest",
                                        e.target.checked
                                    )
                                }
                            >
                                Interest
                            </Checkbox>
                        }
                    >
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select interests"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchInterest}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("interest", e);
                            }}
                            style={{ width: "100%" }}
                            value={
                                this.state.facebook_targeting.selected_interest
                            }
                        >
                            {facebook_targeting.interests.map((i) => (
                                <Option key={i.id}>{i.path.join("/")}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Fragment>
            );

            const detailsPanel = (
                <Fragment>
                    <Form.Item label={"Objective"}>
                        <Select
                            // labelInValue={true}
                            //     value={value}
                            placeholder="Select objective"
                            filterOption={false}
                            onChange={(e) => {
                                let { facebook_details } = this.state;
                                facebook_details.objective = e;
                                this.setState({ facebook_details });
                                console.info("e", e);
                            }}
                            value={this.state.facebook_details.objective}
                        >
                            {config.FACEBOOK_ADS_OBJECTIVE_OPTIONS.map(
                                (option) => (
                                    <Option key={option}>{option}</Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"Optimization goal"}>
                        <Select
                            // labelInValue={true}
                            //     value={value}
                            placeholder="Select optimization goal"
                            filterOption={false}
                            onChange={(e) => {
                                let { facebook_details } = this.state;
                                facebook_details.optimization_goal = e;
                                this.setState({ facebook_details });
                                console.info("e", e);
                            }}
                            value={
                                this.state.facebook_details.optimization_goal
                            }
                        >
                            {config.FACEBOOK_ADS_OPTIMIZATION_GOAL_OPTIONS.map(
                                (option) => (
                                    <Option key={option}>{option}</Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"Billing Event"}>
                        <Select
                            // labelInValue={true}
                            //     value={value}
                            placeholder="Select billing event"
                            filterOption={false}
                            onChange={(e) => {
                                let { facebook_details } = this.state;
                                facebook_details.billing_event = e;
                                this.setState({ facebook_details });
                                console.info("e", e);
                            }}
                            value={this.state.facebook_details.billing_event}
                        >
                            {config.FACEBOOK_ADS_BILLING_EVENTS_OPTIONS.map(
                                (option) => (
                                    <Option key={option}>{option}</Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"Bid Amount"}>
                        <InputNumber
                            defaultValue={
                                this.state.facebook_details.bid_amount
                            }
                            min={0}
                            // max={100}
                            formatter={(value) => `$ ${value / 100}`}
                            parser={(value) =>
                                Number(value.replace("$", "")) * 100
                            }
                            onChange={(e) => {
                                let { facebook_details } = this.state;
                                facebook_details.bid_amount = e;
                                this.setState({ facebook_details });
                                console.info("e", e);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={"Daily budget"}>
                        <InputNumber
                            defaultValue={
                                this.state.facebook_details.daily_budget
                            }
                            min={0}
                            // max={100}
                            step={100}
                            formatter={(value) => `$ ${value / 100}`}
                            parser={(value) =>
                                Number(value.replace("$", "")) * 100
                            }
                            onChange={(e) => {
                                let { facebook_details } = this.state;
                                facebook_details.daily_budget = e;
                                this.setState({ facebook_details });
                                console.info("e", e);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={"Start/End date"}>
                        <RangePicker
                            format="MM/DD YYYY HH:mm"
                            style={{ width: "100%" }}
                            defaultValue={[
                                moment(this.state.facebook_details.start_time),
                                moment(this.state.facebook_details.end_time),
                            ]}
                            onChange={(e) => {
                                console.info("e", e);
                                let { facebook_details } = this.state;
                                facebook_details.start_time = e[0].format();
                                facebook_details.end_time = e[1].format();
                                this.setState({ facebook_details });
                            }}
                            disabledDate={(current) =>
                                current && current < moment().endOf("day")
                            }
                            showTime={{
                                hideDisabledOptions: true,
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={"Status"}>
                        <Select
                            placeholder="Select status"
                            filterOption={false}
                            onChange={(e) => {
                                let { facebook_details } = this.state;
                                facebook_details.status = e;
                                this.setState({ facebook_details });
                                console.info("e", e);
                            }}
                            value={this.state.facebook_details.status}
                        >
                            {config.FACEBOOK_ADS_STATUS_OPTIONS.map(
                                (option) => (
                                    <Option key={option}>{option}</Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                </Fragment>
            );

            return (
                <Form {...formItemLayout}>
                    <div
                        style={{
                            height: "50vh",
                            maxHeight: "50vh",
                            overflow: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        <Tabs defaultActiveKey="info">
                            <TabPane tab={"Info"} key="info">
                                <div
                                    style={{
                                        paddingTop: 20,
                                        maxWidth: 560,
                                        margin: "0 auto",
                                    }}
                                >
                                    <Fragment>{infoPanel}</Fragment>
                                </div>
                            </TabPane>
                            {this.props.data.parent_type === "masterad" ? (
                                <TabPane tab={"Ad"} key="ad">
                                    <div style={{ paddingTop: 20 }}>
                                        <Fragment>{adPanel}</Fragment>
                                    </div>
                                </TabPane>
                            ) : null}

                            <TabPane tab={"Targeting"} key="targeting">
                                <div
                                    style={{
                                        paddingTop: 20,
                                        maxWidth: 560,
                                        margin: "0 auto",
                                    }}
                                >
                                    {facebookTargeting}
                                </div>
                            </TabPane>
                            <TabPane tab={"Details"} key="addetails">
                                <div
                                    style={{
                                        paddingTop: 20,
                                        maxWidth: 560,
                                        margin: "0 auto",
                                    }}
                                >
                                    {detailsPanel}
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    {/* <pre>{JSON.stringify(this.state.ad, null, 2)}</pre> */}
                    {/* <hr />
                    <pre>{JSON.stringify(this.props.data, null, 2)}</pre> */}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class AdsListModalEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener("ads_modal_edit", (_action) => {
            this.setState({
                modal_visible: _action === "show" ? true : false,
            });
        });
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("ads_modal_edit");
    };

    onModalCreate = async () => {
        //   const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("formState", formState);

        if (typeof formState.brand === "undefined") formState.brand = "";
        if (typeof formState.campaigns === "undefined")
            formState.campaigns = [];

        let ads = [];

        // targeting
        let targeting = {};
        let targeting_info = {};

        if (formState.facebook_targeting.selected_overwrite_gender) {
            targeting.gender = formState.facebook_targeting.selected_gender;
            targeting_info.gender =
                formState.facebook_targeting.selected_gender;
        }
        if (formState.facebook_targeting.selected_overwrite_age) {
            targeting.age = formState.facebook_targeting.selected_age;
            targeting_info.age = formState.facebook_targeting.selected_age;
        }
        if (formState.facebook_targeting.selected_overwrite_country) {
            targeting.country = formState.facebook_targeting.selected_country.map(
                (country) => country.key
            );
            targeting_info.country =
                formState.facebook_targeting.selected_country;
        }
        if (formState.facebook_targeting.selected_overwrite_region) {
            targeting.region = formState.facebook_targeting.selected_region.map(
                (region) => region.key
            );
            targeting_info.region =
                formState.facebook_targeting.selected_region;
        }
        if (formState.facebook_targeting.selected_overwrite_city) {
            targeting.city = formState.facebook_targeting.selected_city.map(
                (city) => city.key
            );
            targeting_info.city = formState.facebook_targeting.selected_city;
        }
        if (formState.facebook_targeting.selected_overwrite_zip) {
            targeting.zip = formState.facebook_targeting.selected_zip.map(
                (zip) => zip.key
            );
            targeting_info.zip = formState.facebook_targeting.selected_zip;
        }
        if (formState.facebook_targeting.selected_overwrite_language) {
            targeting.language = formState.facebook_targeting.selected_language.map(
                (language) => language.key
            );
            targeting_info.language =
                formState.facebook_targeting.selected_language;
        }
        if (formState.facebook_targeting.selected_overwrite_interest) {
            targeting.interest = formState.facebook_targeting.selected_interest;
            targeting_info.interest =
                formState.facebook_targeting.selected_interest;
        }
        console.info("targeting", targeting, "targeting_info", targeting_info);

        const all_masterposts = this.formRef.getTreeData();
        if (!all_masterposts) return;
        console.info("all_masterposts", all_masterposts);
        all_masterposts.map((masterpost) => {
            masterpost.children.map((child) => {
                if (formState.masterposts.indexOf(child.key) !== -1) {
                    let ad_object = {
                        account_key: child.account_key,
                        post_id: child.key,
                        masterpost_key: masterpost.key,
                        campaign_name: formState.ad_name,
                        adset_name: formState.ad_name,
                        ad_name: formState.ad_name,
                        objective: formState.facebook_details.objective,
                        status: formState.facebook_details.status,
                        optimization_goal:
                            formState.facebook_details.optimization_goal,
                        billing_event: formState.facebook_details.billing_event,
                        bid_amount: formState.facebook_details.bid_amount,
                        daily_budget: formState.facebook_details.daily_budget,
                        start_time: formState.facebook_details.start_time,
                        end_time: formState.facebook_details.end_time,
                        brand: formState.brand,
                        campaigns: formState.campaigns,
                        targeting: targeting,
                        targeting_info: targeting_info,
                    };
                    ads.push(ad_object);
                }
                return false;
            });

            return false;
        });

        console.info("ads", ads);

        this.setState({ processing: true });

        try {
            let result = await Promise.all(
                ads.map((ad) => this.props.store.addAd(ad))
            );
            console.info("result", result);
            social_notification("publish_ad", "success");

            //form.resetFields();
            this.onModalCancel();
        } catch (err) {
            console.error(err);
            social_notification("publish_ad", "success", {
                description: err,
            });
        }
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.props.data.key);

            this.props.store.updateCompetitor(this.props.data.key, values);

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        this.setState({ processing: false });
        this.props.store.hideModalByKey(
            "ads_modal_edit",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        width={data.parent_type === "masterpost" ? 600 : 1200}
                        centered
                        icon="form"
                        title={"Ad Edit"}
                        okText={"Save"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalCancel} //onApply
                        maskClosable={false}
                        // footer={
                        //     <Fragment>
                        //         <Button
                        //             type="primary"
                        //             onClick={this.onModalCancel}
                        //         >
                        //             Close
                        //         </Button>
                        //     </Fragment>
                        // }
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AdsListModalEdit);
