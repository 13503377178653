import React, { Component, Fragment } from "react";
// import { Button } from "antd";
import TwitterLogin from "react-twitter-login";
import { social_getCookie } from "../functions";
import { Icon, Button } from "antd";
import { firebase, googleProvider } from "../base";
import config from "../config";

// import PropTypes from "prop-types";

// const socButton = ({ children, triggerlogin, ...props }) => (
//     <Button
//         onClick={triggerlogin}
//         {...props}
//         style={
//             props.style
//                 ? props.style
//                 : { background: "#4267b2", borderColor: "#4267b2" }
//         }
//         type="primary"
//         icon={props.provider}
//     >
//         {children}
//     </Button>
// );

class socButtonGoogle extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        const link_google_account_key = social_getCookie(
            "link_google_account_key"
        );
        this.setState({ link_google_account_key });
        console.info("config", config);

        const firstJS = document.getElementsByTagName("script")[0];
        const js = document.createElement("script");

        js.src = "https://apis.google.com/js/api.js";
        js.id = "gapi-client";

        js.onload = () => {
            console.log("google js.onload");
            window.gapi.load("auth2", () => {
                if (!window.gapi.auth2.getAuthInstance()) {
                    window.gapi.auth2
                        .init({
                            client_id: config.google.client_id,
                            fetchBasicProfile: true,
                            scope: "profile email",
                        })
                        .then(
                            (result) => {
                                console.log("google result", result);
                            },
                            (err) => {
                                console.error(err);
                            }
                        );
                } else {
                    console.log("google else");
                }
            });
        };

        if (!firstJS) {
            document.appendChild(js);
        } else {
            firstJS.parentNode.appendChild(js);
        }
    };

    // authHandler = (err, data) => {
    //     console.info("authHandler err", err, "data", data);
    //     this.setState({ oauth_data: data });

    //     this.props.store
    //         .authAccount("twitter", this.state.link_twitter_account_key, data)
    //         .then((res) => {})
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // };

    loginWithGoogle = () => {
        const GoogleAuth = window.gapi.auth2.getAuthInstance();
        console.log("google GoogleAuth", GoogleAuth);
        let that = this;
        GoogleAuth.grantOfflineAccess().then((result) => {
            console.log("google result", result);
            that.parseGoogleCode(result.code);
        });
    };

    parseGoogleCode = (_code) => {
        console.info("parseGoogleCode _code", _code);
        let that = this;
        this.props.store
            .accountParseGoogleCode(this.state.link_google_account_key, _code)
            .then((result) => {
                console.info("parseGoogleCode result", result);
                return that.props.store.pullAccountYoutubeChannels(
                    that.state.link_google_account_key
                );
            })
            .then((result) => {
                that.setState({ oauth_data: true });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    // loginWithGoogle() {
    //     const provider = googleProvider;
    //     provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    //     firebase
    //         .auth()
    //         .signInWithPopup(provider)
    //         .then(function (result) {
    //             // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
    //             // You can use these server side with your app's credentials to access the Twitter API.
    //             //var token = result.credential.accessToken;
    //             //var secret = result.credential.secret;
    //             // The signed-in user info.
    //             //var user = result.user;
    //             // ...
    //             console.info("result", result);
    //         })
    //         .catch(function (error) {
    //             // Handle Errors here.
    //             //var errorCode = error.code;
    //             //var errorMessage = error.message;
    //             // The email of the user's account used.
    //             //var email = error.email;
    //             // The firebase.auth.AuthCredential type that was used.
    //             //var credential = error.credential;
    //             // ...
    //             console.error(error);
    //         });
    // }

    // openAuthPopupForTwitter = () => {
    //     const url = config.api.URLprefix+"auth/twitter?account_key="+this.props.account_key;

    //     //alert('URL = '+config.api.URLprefix+"auth/twitter?account_key="+this.props.account_key);
    //     //alert('account_key = '+this.props.account_key);
    //     const left = Number(window.screen.availWidth)/2 - 250;
    //     window.open(url, 'authTwitterPopup', 'height=500,width=400,status=no,resizable=no,location=no,top=200,left='+left);

    // }

    render() {
        //const {  } = this.props;

        return (
            <Fragment>
                {/* <pre>state={JSON.stringify(this.state, null, 2)}</pre> */}
                <div>
                    {!this.state.oauth_data ? (
                        <Fragment>
                            {/* <TwitterLogin
                                authCallback={this.authHandler}
                                consumerKey={config.twitter.customerKey}
                                consumerSecret={config.twitter.customerSecret}
                                // callbackUrl={window.location.href}
                                children={
                                    <div className={"TwitterButton"}>
                                        <button style={{ padding: "6px 0" }}>
                                            <Icon
                                                type="twitter"
                                                style={{ marginRight: 5 }}
                                            />
                                            Authorize Twitter
                                        </button>
                                    </div>
                                }
                            /> */}
                            {/* <Button
                                onClick={this.loginWithTwitter}
                                type="primary"
                                style={{
                                    background: this.props.store.getTagColorForType(
                                        "twitter"
                                    ),
                                }}
                            >
                                <Icon
                                    type="twitter"
                                    style={{ color: "white", marginRight: 5 }}
                                />
                                Authorize with Twitter
                            </Button> */}
                            <Button
                                onClick={this.loginWithGoogle}
                                type="primary"
                                style={{
                                    ...this.props.style,
                                    background:
                                        this.props.store.getTagColorForType(
                                            "youtube"
                                        ),
                                }}
                            >
                                <Icon
                                    type="youtube"
                                    style={{ color: "white", marginRight: 5 }}
                                />
                                Authorize with Google/YouTube
                            </Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Icon
                                type="check-circle"
                                style={{
                                    color: "#52c41a",
                                    fontSize: "4rem",
                                    marginBottom: 20,
                                }}
                            />
                            <h2>Thank you.</h2>
                            <p>You can close that window now.</p>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default socButtonGoogle;
