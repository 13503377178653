import React, { Component, Fragment } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Joyride from "react-joyride";
import { isMobile } from "react-device-detect";

//import logo from './logo.svg';
import logoImage from "../../assets/images/sidebar_logo.svg";

import { observer } from "mobx-react";
import scss_variables from "./../../_variables.scss";

import Accounts from "../Accounts/Accounts";
import Masterposts from "../Masterposts/Masterposts";
import Tags from "../Tags/Tags";
import Categories from "../Categories/Categories";
import Campaigns from "../Campaigns/Campaigns";
import Competitors from "../Competitors/Competitors";
import Ads from "../Ads/Ads";
import Schedule from "../Schedule/Schedule";
import Reports from "../Reports/Reports";
import Settings from "../Settings/Settings";
import Inbox from "../Inbox/Inbox";
import Auditors from "../Auditors/Auditors";
import Library from "../Library/Library";
import EmptyDashboard from "./EmptyDashboard";
import ExtraDashboardHeader from "./ExtraDashboardHeader";
import Users from "../Users/Users";
import Insights from "../Insights/Insights";
import Notifications from "../Notifications/Notifications";
import System from "../System/System";
import PrivacyModal from "./../PrivacyModal";
import TermsModal from "./../TermsModal";

import { social_isFirefox } from "./../../functions";
import names from "./../../names";
import { Layout, Menu, Icon, PageHeader, Alert } from "antd";
import LoadingProgress from "./LoadingProgress";

const { Content, Footer, Sider } = Layout;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gotologout: false,
            gotologin: false,
            compose_visible: false,
            steps: [
                {
                    target: ".social_joyride_step1",
                    content: "This is my awesome feature!",
                },
                {
                    target: ".social_joyride_step2",
                    content: "This another awesome feature!",
                },
            ],
            mobile_nav_expanded: false,
        };
    }

    async componentDidMount() {
        // Reset the section
        this.props.store.setCurrentSection("dashboard");
        this.props.store.setBreadcrumbs([]); // no extra paths
    }

    onCollapse = (collapsed) => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    onButtonClick = (_evt) => {
        this.props.store.addPerson("test");
    };

    onLogout = (_evt) => {
        this.setState({ gotologout: true });
    };

    handleMenuSelect = (_evt) => {
        //console.info("handleMenuSelect _evt", _evt);
        //this.props.store.setCurrentSection(_evt.key);
        //return <Redirect to={"/dashboard/" + _evt.key} />;
        this.props.history.push(`/dashboard/${_evt.key}`);

        // Hide navigation
        this.setState({ mobile_nav_expanded: false });
    };

    goToDashboard = () => {
        this.props.history.push(`/dashboard/`);
    };

    toggleMobileNavigation = (e) => {
        this.setState({ mobile_nav_expanded: !this.state.mobile_nav_expanded });
    };

    render() {
        const loggedUser = this.props.store.getLoggedUser;
        const currentSection = this.props.store.getCurrentSection;
        // const currentSectionTranslated = this.props.store
        //     .getCurrentSectionTranslated;
        const breadcrumbs = this.props.store.getBreadcrumbsParsed;

        const newNotifications = this.props.store.getAllNotifications.filter(
            (n) => {
                if (n.status === "new") return n;
                return false;
            }
        );

        const { steps } = this.state;

        if (this.state.gotologout === true) {
            return <Redirect to="/logout" />;
        }

        if (this.state.gotologin === true) {
            return <Redirect to="/login" />;
        }

        let menuItems =
            loggedUser.level === "admin" ? (
                <Menu
                    theme="dark"
                    selectedKeys={[currentSection]}
                    mode="inline"
                    onSelect={this.handleMenuSelect}
                >
                    {/* <SubMenu
                        key="accounts_main"
                        title={
                            <span>
                                <Icon type="team" />
                                <span>Accounts</span>
                            </span>
                        }
                    >
                        <Menu.Item key="accounts">
                            <Icon type="team" />
                            Accounts ({this.props.store.getAllAccountsLength})
                        </Menu.Item>
                        <Menu.Item key="tags">
                            <Icon type="tags" />
                            Tags ({this.props.store.getAllTagsLength})
                        </Menu.Item>
                    </SubMenu> */}
                    <Menu.Item key="">
                        <Icon type="desktop" />
                        <span>Dashboard</span>
                    </Menu.Item>
                    <Menu.Item key="accounts">
                        <Icon type="team" />
                        <span>
                            Accounts ({this.props.store.getAllAccountsLength})
                        </span>
                    </Menu.Item>
                    <Menu.Item key="tags">
                        <div
                            style={{ marginLeft: 24 }}
                            className="socialNavSubMenu"
                        >
                            <Icon type="tags" />
                            <span>
                                Tags ({this.props.store.getAllTagsLength})
                            </span>
                        </div>
                    </Menu.Item>

                    {/* <SubMenu
                        key="masterposts_main"
                        title={
                            <span>
                                <Icon type="container" />
                                <span>masterposts</span>
                            </span>
                        }
                    >
                        <Menu.Item key="masterposts">
                            <Icon type="container" />
                            masterposts ({this.props.store.getAllmasterpostsLength})
                        </Menu.Item>
                        <Menu.Item key="categories">
                            <Icon type="tags" />
                            Categories (
                            {this.props.store.getAllCategoriesLength})
                        </Menu.Item>
                    </SubMenu> */}

                    <Menu.Item key="masterposts">
                        <Icon type="crown" />
                        {names.get("MASTERPOST", true, true)} (
                        {this.props.store.getAllMasterpostsLength})
                    </Menu.Item>
                    <Menu.Item key="categories">
                        <div
                            style={{ marginLeft: 24 }}
                            className="socialNavSubMenu"
                        >
                            <Icon type="tags" />
                            Categories (
                            {this.props.store.getAllCategoriesLength})
                        </div>
                    </Menu.Item>

                    <Menu.Item key="campaigns">
                        <Icon type="rocket" />
                        Campaigns ({this.props.store.getAllCampaignsLength})
                    </Menu.Item>
                    <Menu.Item key="competitors">
                        <Icon type="block" />
                        Competitors ({this.props.store.getAllCompetitorsLength})
                    </Menu.Item>
                    {/* <Menu.Item key="ads">
                        <Icon type="shop" />
                        Paid Units ({this.props.store.getAllMasteradsLength})
                    </Menu.Item> */}
                    {/* <hr
                        size="1"
                        noshade="noshade"
                        style={{ borderColor: "#2c455a" }}
                    /> */}
                    <Menu.Item key="schedule/month">
                        <Icon type="calendar" />
                        <span>Calendar</span>
                    </Menu.Item>
                    <Menu.Item key="schedule/list">
                        <Icon type="unordered-list" />
                        <span>Schedule List</span>
                    </Menu.Item>
                    <hr
                        size="1"
                        noshade="noshade"
                        style={{ borderColor: "#2c455a" }}
                    />
                    <Menu.Item key="inbox">
                        <Icon type="mail" />
                        <span>Inbox</span>
                    </Menu.Item>
                    <Menu.Item key="notifications">
                        <Icon type="issues-close" />
                        <span>Notifications ({newNotifications.length})</span>
                    </Menu.Item>
                    <Menu.Item key="listening">
                        <Icon type="exception" />
                        <span>
                            Listening ({this.props.store.getAllAuditorsLength})
                        </span>
                    </Menu.Item>
                    {/* <Menu.Item key="insights">
                        <Icon type="monitor" />
                        <span>Insights</span>
                    </Menu.Item> */}
                    <Menu.Item key="library">
                        <Icon type="hdd" />
                        <span>
                            Library ({this.props.store.getAllLibraryLength})
                        </span>
                    </Menu.Item>
                    <hr
                        size="1"
                        noshade="noshade"
                        style={{ borderColor: "#2c455a" }}
                    />
                    <Menu.Item key="reports">
                        <Icon type="pie-chart" />
                        <span>
                            Reports ({this.props.store.getAllReportsLength})
                        </span>
                    </Menu.Item>
                    <Menu.Item key="users">
                        <Icon type="usergroup-add" />
                        <span>
                            Users ({this.props.store.getAllUsersLength})
                        </span>
                    </Menu.Item>
                    <Menu.Item key="settings">
                        <Icon type="setting" />
                        <span>Settings</span>
                    </Menu.Item>
                    <Menu.Item key="system">
                        <Icon type="control" />
                        <span>System</span>
                    </Menu.Item>
                </Menu>
            ) : (
                <Menu
                    theme="dark"
                    selectedKeys={[currentSection]}
                    mode="inline"
                    onSelect={this.handleMenuSelect}
                >
                    <Menu.Item key="">
                        <Icon type="desktop" />
                        <span>Dashboard</span>
                    </Menu.Item>
                    <Menu.Item key="accounts">
                        <Icon type="team" />
                        <span>Account</span>
                    </Menu.Item>
                    <Menu.Item key="masterposts">
                        <Icon type="crown" />
                        {names.get("MASTERPOST", true, true)} (
                        {this.props.store.getAllMasterpostsLength})
                    </Menu.Item>
                    {/* <Menu.Item key="campaigns">
                        <Icon type="rocket" />
                        Campaigns ({this.props.store.getAllCampaigns.length})
                    </Menu.Item> */}
                    {/* <Menu.Item key="ads">
                        <Icon type="shop" />
                        Paid Units ({this.props.store.getAllMasteradsLength})
                    </Menu.Item> */}
                    <Menu.Item key="competitors">
                        <Icon type="block" />
                        Competitors ({this.props.store.getAllCompetitorsLength})
                    </Menu.Item>
                    <hr
                        size="1"
                        noshade="noshade"
                        style={{ borderColor: "#2c455a" }}
                    />
                    <Menu.Item key="schedule/month">
                        <Icon type="calendar" />
                        <span>Calendar</span>
                    </Menu.Item>
                    <Menu.Item key="schedule/list">
                        <Icon type="unordered-list" />
                        <span>Schedule List</span>
                    </Menu.Item>
                    <hr
                        size="1"
                        noshade="noshade"
                        style={{ borderColor: "#2c455a" }}
                    />
                    <Menu.Item key="inbox">
                        <Icon type="mail" />
                        <span>Inbox</span>
                    </Menu.Item>
                    <Menu.Item key="notifications">
                        <Icon type="issues-close" />
                        <span>Notifications ({newNotifications.length})</span>
                    </Menu.Item>
                    <Menu.Item key="listening">
                        <Icon type="exception" />
                        <span>
                            Listening ({this.props.store.getAllAuditorsLength})
                        </span>
                    </Menu.Item>
                    {/* <Menu.Item key="insights">
                        <Icon type="monitor" />
                        <span>Insights</span>
                    </Menu.Item> */}
                    <Menu.Item key="library">
                        <Icon type="hdd" />
                        <span>
                            Library ({this.props.store.getAllLibraryLength})
                        </span>
                    </Menu.Item>
                    <hr
                        size="1"
                        noshade="noshade"
                        style={{ borderColor: "#2c455a" }}
                    />
                    <Menu.Item key="reports">
                        <Icon type="pie-chart" />
                        <span>
                            Reports ({this.props.store.getAllReportsLength})
                        </span>
                    </Menu.Item>
                    <Menu.Item key="settings">
                        <Icon type="setting" />
                        <span>Settings</span>
                    </Menu.Item>
                </Menu>
            );

        const missingAccountForLoggedUser =
            this.props.store.getAllAccounts.filter(
                (account) => account.email === loggedUser.email
            ).length === 0 && loggedUser.level === "user"
                ? true
                : false;

        if (missingAccountForLoggedUser) {
            // no accounts for the logged user
            menuItems = (
                <Menu
                    theme="dark"
                    selectedKeys={[currentSection]}
                    mode="inline"
                    onSelect={this.handleMenuSelect}
                ></Menu>
            );
        }

        return (
            <div
                className={
                    this.state.mobile_nav_expanded
                        ? "social_mobile_nav_expanded"
                        : ""
                }
            >
                {social_isFirefox ? (
                    <Alert
                        message={
                            <Fragment>
                                It appears that you&apos;re using{" "}
                                <strong>Firefox</strong> browser. We recommend
                                using Chrome until the application is fully
                                developped.
                            </Fragment>
                        }
                        banner
                        closable
                    />
                ) : null}
                <Joyride
                    steps={steps}
                    showProgress={true}
                    showSkipButton={true}
                    continuous={true}
                    styles={{
                        options: {
                            primaryColor: scss_variables.scss_color_primary,
                            textColor: "#333",
                        },
                        buttonNext: {
                            fontSize: 14,
                            padding: "8px 19px",
                        },
                        buttonBack: {
                            fontSize: 14,
                            padding: "8px 19px",
                        },
                        buttonClose: {
                            fontSize: 14,
                            padding: "8px 19px",
                        },
                    }}
                />
                {isMobile ? (
                    <Icon
                        type={
                            this.state.mobile_nav_expanded
                                ? "arrow-left"
                                : "menu"
                        }
                        className="social_mobile_menu"
                        onClick={this.toggleMobileNavigation}
                    />
                ) : null}
                <Layout
                    style={{ minHeight: "100vh" }}
                    className={
                        this.state.mobile_nav_expanded
                            ? "social_mobile_expanded"
                            : ""
                    }
                >
                    <Sider
                        collapsible
                        collapsed={this.state.collapsed}
                        onCollapse={this.onCollapse}
                    >
                        <div
                            className="logo social_joyride_step1"
                            onClick={(e) => this.goToDashboard()}
                        >
                            <img
                                src={logoImage}
                                alt="logo"
                                style={{ display: "none" }}
                            />
                        </div>

                        {menuItems}
                    </Sider>
                    <Layout>
                        <LoadingProgress />

                        <PageHeader
                            onBack={() => {
                                this.props.history.goBack();
                            }}
                            //title={currentSectionTranslated.toUpperCase()}
                            // breadcrumb={tempBreadchurmbs}
                            subTitle={breadcrumbs}
                            // tags={<Tag color={this.props.store.getTagColorForType("warning")}>Warning</Tag>}
                            extra={
                                <ExtraDashboardHeader
                                    store={this.props.store}
                                    loggedUser={loggedUser}
                                    onLogout={this.onLogout}
                                    onSettings={(e) =>
                                        this.handleMenuSelect({
                                            key: "settings",
                                        })
                                    }
                                />
                            }
                        />

                        <Content style={{ margin: 16 }}>
                            <div
                                style={{
                                    padding: 24,
                                    background: "#fff",
                                    height: "100%",
                                    position: "relative",
                                }}
                            >
                                <span className="social_worldName">
                                    {loggedUser.world && loggedUser.world.name}
                                </span>
                                <Switch>
                                    <Route
                                        path="/dashboard/accounts"
                                        render={(props) => (
                                            <Accounts
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/masterposts"
                                        render={(props) => (
                                            <Masterposts
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/tags"
                                        render={(props) => (
                                            <Tags
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/categories"
                                        render={(props) => (
                                            <Categories
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/campaigns"
                                        render={(props) => (
                                            <Campaigns
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/competitors"
                                        render={(props) => (
                                            <Competitors
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/ads"
                                        render={(props) => (
                                            <Ads
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/schedule"
                                        render={(props) => (
                                            <Schedule
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/reports"
                                        render={(props) => (
                                            <Reports
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/settings"
                                        render={(props) => (
                                            <Settings
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/inbox"
                                        render={(props) => (
                                            <Inbox
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/notifications"
                                        render={(props) => (
                                            <Notifications
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/listening"
                                        render={(props) => (
                                            <Auditors
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/library"
                                        render={(props) => (
                                            <Library
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/dashboard/users"
                                        render={(props) => (
                                            <Users
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    {/* <Route
                                        path="/dashboard/insights"
                                        render={(props) => (
                                            <Insights
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    /> */}
                                    <Route
                                        path="/dashboard/system"
                                        render={(props) => (
                                            <System
                                                store={this.props.store}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        render={(props) => (
                                            <EmptyDashboard
                                                store={this.props.store}
                                                {...props}
                                                type={
                                                    missingAccountForLoggedUser
                                                        ? "missingAccountForLoggedUser"
                                                        : null
                                                }
                                            />
                                        )}
                                    />
                                </Switch>
                            </div>
                        </Content>
                        <Footer style={{ textAlign: "center" }}>
                            <span>
                                Developed ©{new Date().getFullYear()} by
                                Picklings Corp.
                            </span>
                            <PrivacyModal store={this.props.store} />
                            <TermsModal store={this.props.store} />
                        </Footer>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

export default observer(Dashboard);
