import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";

import { Button, Icon, Tooltip, Input, Form, Modal, Divider, Card } from "antd";

//const { TextArea } = Input;
const { Search } = Input;

const BUTTON_STYLE = {
    width: 190,
    textAlign: "left",
};

const PREVIEW_IMAGE_STYLE = {
    float: "left",
    width: 30,
    height: 30,
    marginRight: 10,
    backgroundSize: "cover",
};

// const LIST_TEXT_STYLE = {
//     width: 250,
//     textOverflow: "ellipsis",
//     overflow: "hidden",
//     display: "block",
//     lineHeight: 1,
//     color: "#111",
//     fontSize: 13,
// };

// const LIST_TAGS_CONTAINER_STYLE = {
//     marginTop: 0,
// };
// const LIST_TAG_STYLE = {
//     lineHeight: 1,
//     padding: "0px 3px 2px 3px",
//     fontSize: 10,
//     marginTop: 3,
//     marginRight: 3,
// };

// const OVERLAY_STYLE = {
//     background: "white",
//     border: "1px solid #d9d9d9",
//     maxHeight: 400,
//     overflowX: "hidden",
//     overflowY: "auto",
//     borderRadius: 4,
//     boxShadow: "1px 1px 4px rgba(0,0,0,0.1)",
// };

// const SEARCH_STYLE = {
//     padding: 0,
//     marginLeft: -16,
//     marginTop: -1,
//     width: 160,
// };

const LIST_SMALL_STYLE = {
    width: 100,
    height: 100,
    borderRadius: 10,
    float: "left",
    overflow: "hidden",
    marginRight: 15,
    marginBottom: 15,
    background: "white",
    padding: 4,
    border: "none",
};

const LIST_SMALL_STYLE_SELECTED = {
    width: 100,
    height: 100,
    borderRadius: 10,
    float: "left",
    overflow: "hidden",
    marginRight: 15,
    marginBottom: 15,
    background: "#1890ff",
    padding: 4,
    border: "none",
};

const LIST_SMALL_COVER = {
    height: "calc(100px - 8px)",
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
};

const LIST_SMALL_CHECKBOX = {
    width: 24,
    height: 24,
    position: "absolute",
    top: 1,
    right: 1,
    background: "#1890ff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0px 0px 0px 10px",
    color: "white",
};
const LIST_SMALL_CHECKBOX_NUMBER = {
    width: 24,
    height: 24,
    position: "absolute",
    top: 1,
    left: 1,
    background: "#1890ff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0px 0px 10px 0px",
    color: "white",
};

/* Add/Edit Form */
const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                filter: "",
                library: [],
                selected: [],
            };
        }

        componentDidMount = () => {
            const libraryElements = this.props.store.getAllLibrary;
            this.setState({
                library: libraryElements,
            });
            //console.info("libraryElements", libraryElements);
            console.info("ModalForm this.props.selected", this.props.selected);

            const propSelected =
                this.props.selected && this.props.selected !== null
                    ? JSON.parse(JSON.stringify(this.props.selected))
                    : [];

            if (propSelected.length > 0 && propSelected[0].library_key)
                propSelected[0].key = propSelected[0].library_key;

            console.info("ModalForm propSelected", propSelected);

            console.info("ModalForm this.props", this.props);
            if (propSelected.length > 0) {
                this.setState({ selected: propSelected });
            }
        };

        handleSearch = (_filter) => {
            //console.info("handleSearch _filter", _filter);
            this.setState({ filter: _filter });
        };

        handleSelect = (_le) => {
            //console.info("handleSelect _le", _le);
            let { selected } = this.state;
            const found =
                selected.filter((f) => f.key === _le.key).length > 0
                    ? true
                    : false;
            //console.log("found=" + found);

            if (this.props.multiple) {
                // Multiple selection
                if (!found) {
                    selected.push(_le);
                } else {
                    selected = selected.filter((f) => f.key !== _le.key);
                }
            } else {
                // single selection
                selected = [_le];
            }

            this.setState({ selected });
        };

        handleUnselectAll = (_evt) => {
            this.setState({ selected: [] });
        };

        render() {
            const elements =
                this.state.library &&
                this.state.library
                    .filter((le) => {
                        if (this.state.filter.length > 0) {
                            if (
                                le.file &&
                                le.file.name
                                    .toUpperCase()
                                    .indexOf(
                                        this.state.filter.toUpperCase()
                                    ) !== -1
                            ) {
                                return true;
                            }
                            if (
                                le.labels &&
                                le.labels
                                    .map((l) => l.toUpperCase())
                                    .join(",")
                                    .indexOf(
                                        this.state.filter.toUpperCase()
                                    ) !== -1
                            ) {
                                return true;
                            }
                            return false;
                        } else {
                            return true;
                        }
                    })
                    .map((le, leIndex) => {
                        if (le.file) {
                            // let leStyle = {
                            //     ...PREVIEW_IMAGE_STYLE,
                            //     backgroundImage: `url(${
                            //         le.thumbnail ? le.thumbnail : le.downloadURL
                            //     })`,
                            // };

                            const leSelected =
                                this.state.selected.filter(
                                    (s) => s.key === le.key
                                ).length > 0;

                            let leSelectedIndex = null;
                            this.state.selected.map((s, sIndex) => {
                                if (s.key === le.key) leSelectedIndex = sIndex;
                                return false;
                            });

                            const leTooltipFilename =
                                le.file.name.length > 20
                                    ? le.file.name.substring(0, 20) + "..."
                                    : le.file.name;
                            return (
                                <Tooltip
                                    key={le.key}
                                    mouseEnterDelay={2}
                                    overlayStyle={{
                                        whiteSpace: "pre",
                                    }}
                                    title={
                                        leTooltipFilename +
                                        "\nSize: " +
                                        this.props.store.humanFileSize(
                                            le.file.size
                                        ) +
                                        (le.labels && le.labels.length > 0
                                            ? "\nLabels: " +
                                              le.labels.join(", ")
                                            : "")
                                    }
                                >
                                    <Card
                                        hoverable
                                        size="small"
                                        style={
                                            leSelected
                                                ? LIST_SMALL_STYLE_SELECTED
                                                : LIST_SMALL_STYLE
                                        }
                                        cover={
                                            <Fragment>
                                                <div
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleSelect(le);
                                                    }}
                                                    style={{
                                                        ...LIST_SMALL_COVER,
                                                        backgroundImage: `url(${
                                                            le.thumbnail
                                                                ? le.thumbnailURL
                                                                : le.downloadURL
                                                        })`,
                                                    }}
                                                ></div>
                                                {leSelected ? (
                                                    <Fragment>
                                                        <div
                                                            style={
                                                                LIST_SMALL_CHECKBOX
                                                            }
                                                        >
                                                            <Icon type="check" />
                                                        </div>
                                                        {this.props.multiple ? (
                                                            <div
                                                                style={
                                                                    LIST_SMALL_CHECKBOX_NUMBER
                                                                }
                                                            >
                                                                {Number(
                                                                    leSelectedIndex +
                                                                        1
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </Fragment>
                                                ) : null}
                                            </Fragment>
                                        }
                                    ></Card>
                                </Tooltip>
                            );
                        }
                        return false;
                    });

            return (
                <div style={{ padding: "0 20px" }}>
                    <Search
                        placeholder="input search text"
                        onSearch={this.handleSearch}
                        onChange={(e) => this.handleSearch(e.target.value)}
                        ref={this.searchRef}
                        style={{ width: "calc(100% - 140px)" }}
                    />
                    <Button
                        style={{ marginLeft: 10, width: 130 }}
                        icon="check"
                        onClick={this.handleUnselectAll}
                    >
                        Unselect All
                    </Button>
                    <Divider />
                    <div
                        style={{
                            maxHeight: 400,
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        {elements}
                    </div>
                    {/* <Form {...formItemLayout}>
                        <Form.Item label="Labels">
                            {getFieldDecorator("labels", {
                                //getValueFromEvent: handleGetTags
                                // initialValue: data.labels ? data.labels : []
                                //onChange: this.handleLabelsChange
                            })(<Input />)}
                        </Form.Item>
                    </Form> */}
                </div>
            );
        }
    }
);

class LibraryPickup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "libraryPickup_modal",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );

        //console.info("ModalForm this.props=", this.props);
        if (this.props.selected) {
            this.setState({ selected: this.props.selected });
        }
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("libraryPickup_modal");
    };

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):

        //console.info("componentDidUpdate this.props", this.props);

        if (
            this.props.selected !== prevProps.selected &&
            this.props.selected !== null &&
            this.props.selected.length > 0
        ) {
            console.log("SELECTED IS UPDATED");
            this.setState({ selected: this.props.selected });
        }
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    handleButtonClick = (_evt) => {
        this.props.store.showModalByKey("libraryPickup_modal");
    };

    handleEraseClick = (_evt) => {
        this.setState({
            selected: [],
        });
        if (this.props.onSelectedChange) this.props.onSelectedChange([]);
    };

    onModalChoose = () => {
        let formState = Object.assign({}, this.formRef.state);
        //console.info("formState", formState);
        // const selected = this.props.multiple
        //     ? formState.selected
        //     : formState.selected[0];
        const selected = formState.selected;

        this.setState({ selected: selected });
        this.props.store.hideModalByKey("libraryPickup_modal");

        if (this.props.onSelectedChange) {
            this.props.onSelectedChange(selected);
        }
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "libraryPickup_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    render() {
        // console.info(
        //     "LibraryPickup render this.props.selected",
        //     this.props.selected
        // );
        // console.info(
        //     "LibraryPickup render this.state.selected",
        //     this.state.selected
        // );

        let previewUrl = "";
        if (this.state.selected[0]) {
            previewUrl = this.state.selected[0].library_file
                ? this.state.selected[0].url
                : this.state.selected[0].thumbnail
                ? this.state.selected[0].thumbnailURL
                : this.state.selected[0].downloadURL;
        }

        //console.log("previewUrl = " + previewUrl);
        const buttonBody =
            this.state.selected.length === 1 ? (
                <Fragment>
                    <div
                        style={{
                            ...PREVIEW_IMAGE_STYLE,
                            backgroundImage: `url(${previewUrl})`,
                            ...{ height: 20, width: 20 },
                        }}
                        alt=""
                    />
                    From Library ...
                </Fragment>
            ) : this.state.selected.length > 1 ? (
                <Fragment>
                    {this.state.selected.length <= 3
                        ? this.state.selected.map((s, sIndex) => {
                              const previewUrl = s.library_file
                                  ? s.url
                                  : s.thumbnail
                                  ? s.thumbnailURL
                                  : s.downloadURL;
                              return (
                                  <div
                                      key={sIndex}
                                      style={{
                                          ...PREVIEW_IMAGE_STYLE,
                                          backgroundImage: `url(${previewUrl})`,
                                          ...{
                                              height: 20,
                                              width: 20,
                                              marginRight: 2,
                                          },
                                      }}
                                      alt=""
                                  />
                              );
                          })
                        : null}
                    <span style={{ marginLeft: 5 }}>
                        {this.state.selected.length} images
                    </span>
                </Fragment>
            ) : (
                <Fragment>
                    <Icon type="hdd" style={{ marginRight: 8 }} />
                    From Library ...
                </Fragment>
            );

        const { multiple } = this.props;
        //console.info("libraryPickup props", this.props);

        // if (!selected.length) selected = [selected];

        // patch selected

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        width={"50%"}
                        height={"90%"}
                        visible={true}
                        title={"Library Pickup"}
                        okText={"Choose"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalChoose} //onApply
                        maskClosable={false}
                    >
                        <ModalForm
                            store={this.props.store}
                            multiple={multiple}
                            selected={this.state.selected}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
                <Tooltip title="Choose a file from the library">
                    <Button
                        style={BUTTON_STYLE}
                        disabled={this.props.disabled}
                        onClick={this.handleButtonClick}
                    >
                        {buttonBody}
                    </Button>
                </Tooltip>
                {this.state.selected.length > 0 ? (
                    <Tooltip title="Forget the selection">
                        <Icon
                            type="close"
                            style={{ marginLeft: 10, color: "red" }}
                            onClick={this.handleEraseClick}
                        />
                    </Tooltip>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(LibraryPickup);
