import React, { Fragment } from "react";
import { Row, Col, Table, Divider } from "antd";
import ReportSingleValue from "./../Reports/ReportSingleValue";
import ReactJson from "react-json-view";

import scss_variables from "./../../_variables.scss";
import SocialDataPresentor from "./../SocialDataPresentor";

const GRID_GUTTER = 40;

const AccountsChannelInsightsFacebook = ({ channel_insights }) => {
    if (Object.keys(channel_insights).length === 0) return null;

    const getInsightsData = (_data, _key) => {
        return (
            _data &&
            _data.filter &&
            _data.filter((d) => {
                if (d.name === _key) return d;
                return false;
            })
        );
    };

    // TOTAL FANS
    const page_fans = getInsightsData(channel_insights, "page_fans")[0];

    // IMPRESSIONS
    const page_impressions = getInsightsData(
        channel_insights,
        "page_impressions"
    )[0];

    const page_engaged_users = getInsightsData(
        channel_insights,
        "page_engaged_users"
    )[0];

    const page_content_activity = getInsightsData(
        channel_insights,
        "page_content_activity"
    )[0];

    const page_post_engagements = getInsightsData(
        channel_insights,
        "page_post_engagements"
    )[0];

    const page_consumptions = getInsightsData(
        channel_insights,
        "page_consumptions"
    )[0];

    const page_negative_feedback = getInsightsData(
        channel_insights,
        "page_negative_feedback"
    )[0];

    const page_total_actions = getInsightsData(
        channel_insights,
        "page_total_actions"
    )[0];

    const page_fan_adds = getInsightsData(channel_insights, "page_fan_adds")[0];

    const page_fan_removes = getInsightsData(
        channel_insights,
        "page_fan_removes"
    )[0];

    const page_views_total = getInsightsData(
        channel_insights,
        "page_views_total"
    )[0];

    const page_video_views = getInsightsData(
        channel_insights,
        "page_video_views"
    )[0];

    const sharesStatsPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER}>
                <Col span={8}>
                    <SocialDataPresentor
                        title={(page_fans && page_fans.title) || "TOTAL FANS"}
                        description={page_fans && page_fans.description}
                        value={page_fans && page_fans.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_fans"
                        link="http://www.cnn.com"
                    />
                </Col>
                <Col span={8}>
                    {/* <SocialDataPresentor
              title={"POST PUBLISHED ???"}
              description={
                  "Just a small description that will be displayed as a popup"
              }
              value={12345}
          /> */}
                    <SocialDataPresentor
                        title={
                            (page_impressions && page_impressions.title) ||
                            "TOTAL IMPRESSIONS"
                        }
                        description={
                            page_impressions && page_impressions.description
                        }
                        value={page_impressions && page_impressions.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_impressions"
                    />
                    {/* <pre>{JSON.stringify(this.state.page_impressions && this.state.page_impressions.values, null, 2)}</pre> */}
                </Col>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_engaged_users && page_engaged_users.title) ||
                            "TOTAL ENGAGEMENT"
                        }
                        description={
                            page_engaged_users && page_engaged_users.description
                        }
                        value={page_engaged_users && page_engaged_users.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_engaged_users"
                    />
                </Col>
            </Row>
            <Divider dashed />

            <Row gutter={GRID_GUTTER}>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_content_activity &&
                                page_content_activity.title) ||
                            "page_content_activity"
                        }
                        description={
                            page_content_activity &&
                            page_content_activity.description
                        }
                        value={
                            page_content_activity &&
                            page_content_activity.values
                        }
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_content_activity"
                    />
                </Col>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_post_engagements &&
                                page_post_engagements.title) ||
                            "page_post_engagements"
                        }
                        description={
                            page_post_engagements &&
                            page_post_engagements.description
                        }
                        value={
                            page_post_engagements &&
                            page_post_engagements.values
                        }
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_post_engagements"
                    />
                </Col>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_consumptions && page_consumptions.title) ||
                            "page_consumptions"
                        }
                        description={
                            page_consumptions && page_consumptions.description
                        }
                        value={page_consumptions && page_consumptions.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_consumptions"
                    />
                </Col>
            </Row>
            <Divider dashed />

            <Row gutter={GRID_GUTTER}>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_total_actions && page_total_actions.title) ||
                            "page_total_actions"
                        }
                        description={
                            page_total_actions && page_total_actions.description
                        }
                        value={page_total_actions && page_total_actions.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_total_actions"
                    />
                </Col>

                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_views_total && page_views_total.title) ||
                            "page_views_total"
                        }
                        description={
                            page_views_total && page_views_total.description
                        }
                        value={page_views_total && page_views_total.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_views_total"
                    />
                </Col>

                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_video_views && page_video_views.title) ||
                            "page_video_views"
                        }
                        description={
                            page_video_views && page_video_views.description
                        }
                        value={page_video_views && page_video_views.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_video_views"
                    />
                </Col>
            </Row>
            <Divider dashed />

            <Row gutter={GRID_GUTTER}>
                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_fan_adds && page_fan_adds.title) ||
                            "page_fan_adds"
                        }
                        description={page_fan_adds && page_fan_adds.description}
                        value={page_fan_adds && page_fan_adds.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_fan_adds"
                    />
                </Col>

                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_fan_removes && page_fan_removes.title) ||
                            "page_fan_removes"
                        }
                        description={
                            page_fan_removes && page_fan_removes.description
                        }
                        value={page_fan_removes && page_fan_removes.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_fan_removes"
                    />
                </Col>

                <Col span={8}>
                    <SocialDataPresentor
                        title={
                            (page_negative_feedback &&
                                page_negative_feedback.title) ||
                            "page_negative_feedback"
                        }
                        description={
                            page_negative_feedback &&
                            page_negative_feedback.description
                        }
                        value={
                            page_negative_feedback &&
                            page_negative_feedback.values
                        }
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_negative_feedback"
                        color={
                            scss_variables.scss_color_dataPresentorFacebookStrokeNegative
                        }
                    />
                </Col>
            </Row>
            <Divider dashed />

            <Row gutter={GRID_GUTTER}>
                <Col span={24}>
                    <SocialDataPresentor
                        title={(page_fans && page_fans.title) || "page_fans"}
                        description={page_fans && page_fans.description}
                        value={page_fans && page_fans.values}
                        value_name="value"
                        key_name="end_time"
                        // metric_name="page_fans"
                    />
                </Col>
            </Row>
        </Fragment>
    );

    return (
        <Fragment>
            {sharesStatsPanel}
            {/* <pre>{JSON.stringify(totalShareStatistics, null, 2)}</pre> */}
            <Divider />

            {/* <ReactJson src={totalStatistics} /> */}
        </Fragment>
    );
};

export default AccountsChannelInsightsFacebook;
