import React, { Component, Fragment } from "react";
//import { Icon, Divider } from "antd";
import moment from "moment";

const linkedinStyles = {
    background: "#f3f2ef",
    padding: 10,
    //     border: "1px solid red",
};
const linkedinStylesCard = {
    //     marginBottom: 60,
    marginLeft: -1,
    marginRight: -1,
    position: "relative",
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    boxSizing: "border-box",

    overflow: "hidden",
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "1px solid #e0dfdc",
    borderRadius: 10,
    maxWidth: 550,
    padding: "20px 20px 11.6px 20px",
};
const linkedinCardHeader = {
    height: 60,
    padding: 16,
    flexAlign: "center",
    alignItems: "center",
};
const linkedinCardHeaderAvatar = {
    width: 48,
    height: 48,
    top: 19,
    left: 19,
    position: "absolute",
    backgroundColor: "#ddd",
    borderRadius: "100%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
const linkedinCardHeaderAuthor = {
    top: 18,
    left: 90,
    position: "absolute",
    color: "#262626",
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 16,
};
const linkedinCardHeaderAuthorEmail = {
    top: 36,
    left: 90,
    position: "absolute",
    color: "#697882",
    fontWeight: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 14,
    width: 240,
};
const linkedinCardImage = {
    borderRadius: 0,
    width: "calc(100% + 40px)",
    marginBottom: 10,
    marginLeft: -20,
};
const linkedinCardHeaderDateTime = {
    position: "absolute",
    top: 52,
    left: 90,
    color: "#999",
    fontWeight: 100,
    textAlign: "left",
    fontSize: "0.8rem",
    width: 240,
};
const linkedinCardCaption = {
    padding: "10px 0px",
    color: "#1c2022",
    marginTop: -10,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 16,
};
const linkedinCardImageTitle = {
    color: "#1c2022",
    fontWeight: "bold",
    fontSize: 17,
};

class SocialLinkedinPost extends Component {
    state = {
        post: null,
        author: "",
        avatar: "",
        insights: null,
        comments: [],
    };

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.setState({
            post: this.props.post,
            author: this.props.author,
            avatar: this.props.avatar,
            headline: this.props.author_headline,
        });
    }

    render() {
        const mediaUrl =
            this.state.post &&
            this.state.post.specificContent &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"] &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"]
                .media &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"]
                .media.length > 0 &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"]
                .media[0].thumbnails &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"]
                .media[0].thumbnails.length > 0 &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"]
                .media[0].thumbnails[0].url
                ? this.state.post.specificContent[
                      "com.linkedin.ugc.ShareContent"
                  ].media[0].thumbnails[0].url
                : null;
        const mediaTitle =
            this.state.post &&
            this.state.post.specificContent &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"] &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"]
                .media &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"]
                .media.length > 0 &&
            this.state.post.specificContent["com.linkedin.ugc.ShareContent"]
                .media[0].title
                ? this.state.post.specificContent[
                      "com.linkedin.ugc.ShareContent"
                  ].media[0].title.text
                : null;
        return (
            <Fragment>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    //   href={
                    //       this.state.post &&
                    //       `https://twitter.com/${this.state.post.user.screen_name}/status/${this.state.post.id_str}`
                    //   }
                >
                    <div style={linkedinStyles}>
                        <div style={linkedinStylesCard}>
                            <div style={linkedinCardHeader}>
                                <div
                                    style={{
                                        ...linkedinCardHeaderAvatar,
                                        backgroundImage: `url(${this.state.avatar})`,
                                    }}
                                />
                                <div style={linkedinCardHeaderAuthor}>
                                    {this.state.author}
                                </div>
                                <div style={linkedinCardHeaderAuthorEmail}>
                                    {this.state.headline}
                                </div>
                                <div style={linkedinCardHeaderDateTime}>
                                    {this.state.post
                                        ? moment(this.state.post.created.time)
                                              .utc()
                                              .local()
                                              .format("LLLL")
                                        : null}
                                </div>
                            </div>
                            <div style={linkedinCardCaption}>
                                {this.state.post
                                    ? this.state.post.specificContent[
                                          "com.linkedin.ugc.ShareContent"
                                      ].shareCommentary.text
                                    : null}
                            </div>
                            {mediaUrl ? (
                                <div>
                                    <img
                                        style={linkedinCardImage}
                                        src={mediaUrl}
                                        alt=""
                                    />
                                    <div style={linkedinCardImageTitle}>
                                        {mediaTitle}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {/* <pre>
                            this.state.post.specificContent[com.linkedin.ugc.ShareContent]
                            {this.state.post &&
                            this.state.post.specificContent[
                                "com.linkedin.ugc.ShareContent"
                            ]
                                ? JSON.stringify(
                                      this.state.post.specificContent[
                                          "com.linkedin.ugc.ShareContent"
                                      ],
                                      null,
                                      2
                                  )
                                : null}
                        </pre> */}
                        {/* {this.props.children} */}
                    </div>
                </a>
            </Fragment>
        );
    }
}

export default SocialLinkedinPost;
