import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import CryptoJS from "crypto-js";
import config from "../config";

// import lorem from "../lorem-lorem.svg";
import PrivacyModal from "./PrivacyModal";
import TermsModal from "./TermsModal";
import { social_reset_css, social_load_css } from "./../functions";

import RegisterForm from "./RegisterForm";
import { app } from "./../base";

import { Button, Divider } from "antd";

class Register extends Component {
    state = {};

    constructor(props) {
        super(props);
        //   this.authWithFacebook = this.authWithFacebook.bind(this);

        this.state = {
            redirect: null,
        };
    }

    componentDidMount() {
        // console.info("REGISTER componentDidMouth this.props ", this.props);

        // reseting any skin/theme from the app
        social_reset_css();
        // load the default theme
        //social_load_css("themes/default.css");

        if (this.props.match && this.props.match.params.world) {
            var bytes = CryptoJS.AES.decrypt(
                decodeURIComponent(this.props.match.params.world.toString()),
                config.cryptoJsSecret
            );
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            console.log("decrypted text", plaintext);

            this.setState({ world_key: plaintext });
        }
    }

    handleLogin = () => {
        this.setState({
            redirect:
                "/login" +
                (this.props.match.params.world
                    ? "/" + this.props.match.params.world
                    : ""),
        });
    };

    handleDashboard = () => {
        this.setState({ redirect: "/dashboard" });
    };

    render() {
        // console.info(
        //     "REGISTER RENDER this.state.redirect",
        //     this.state.redirect
        // );

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        //const loggedUser = this.props.store.getLoggedUser;
        // console.info("loggedUser", loggedUser);
        //this.state.authenticated
        // console.log("Register render");

        let body = (
            <Fragment>
                <div className={"Register"}>
                    <div className={"Header"}>
                        <div className={"Logo"}></div>
                        <div>
                            <Button className="login-form-button social_Selected">
                                Sign up
                            </Button>
                            <Button
                                className="login-form-button"
                                onClick={() => {
                                    this.handleLogin();
                                }}
                            >
                                Log-in
                            </Button>
                        </div>
                    </div>

                    <div className={"Container"}>
                        <div className={"Limiter"}>
                            <div className={"Box"}>
                                {this.state.world_key ? (
                                    <RegisterForm
                                        store={this.props.store}
                                        world_key={this.state.world_key}
                                    />
                                ) : (
                                    <Fragment>
                                        <h2>Missing world data!</h2>
                                        <p>
                                            Please check the URL you are using.
                                            It is missing vital data and we
                                            cannot proceed further with the
                                            registration.
                                        </p>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={"Footer"}>
                        <div className={"Limiter"}>
                            <PrivacyModal store={this.props.store} />
                            <TermsModal store={this.props.store} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // if (app.auth().currentUser) {
        //     body = (
        //         <Fragment>
        //             <div className={"Register"}>
        //                 <div className={"Header"}>
        //                     <div className={"Limiter"}>
        //                         <div className={"Logo"}></div>
        //                         <Button className="ghost-button">
        //                             Sign up
        //                         </Button>
        //                         <Button
        //                             className="login-form-button"
        //                             onClick={() => {
        //                                 this.handleLogin();
        //                             }}
        //                         >
        //                             Log-in
        //                         </Button>
        //                     </div>
        //                 </div>

        //                 <div className={"Container"}>
        //                     <div className={"Limiter"}>
        //                         <div className={"Box"}>
        //                             <h2>You are logged in.</h2>
        //                             You have to logout in order to access the
        //                             Register section.
        //                             <Divider />
        //                             <Button
        //                                 type={"primary"}
        //                                 // style={{ padding: 0 }}
        //                                 onClick={() => {
        //                                     this.handleDashboard();
        //                                 }}
        //                             >
        //                                 Please click here to go back to the
        //                                 Dashboard
        //                             </Button>
        //                         </div>
        //                     </div>
        //                 </div>

        //                 <div className={"Footer"}>
        //                     <div className={"Limiter"}>
        //                         <PrivacyModal store={this.props.store} />
        //                         <TermsModal store={this.props.store} />
        //                     </div>
        //                 </div>
        //             </div>
        //         </Fragment>
        //     );
        // }
        return body;
    }
}

export default Register;
