import React, { Component, Fragment } from "react";

import config from "./../../config";
import tooltips from "../../tooltips";

import { observer } from "mobx-react";

import {
    Empty,
    List,
    Button,
    Tag,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Modal,
    Icon,
    Checkbox,
} from "antd";

import CategoriesListModal from "./CategoriesListModal";
import Bulk from "./../Bulk";
import scss_variables from "./../../_variables.scss";
import SocialTag from "./../SocialTag";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";
import names from "./../../names";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class CategoriesList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            //edit_data: {},
            pagination_currentPage: 1,
            sortby: "name",
            sortasc: true,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("categories");

        // // Breadcrumbs
        // this.props.store.setBreadcrumbs([
        //     { route: `/dashboard/categories`, title: "Categories" },
        // ]);
    }
    componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);
        this.listBulk = new Bulk({
            name: "cateoriesList",
            list: this.props.store.getAllCategories.map(
                (element) => element.category
            ),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        await this.props.store.deleteCategory(_key);
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                },
            ],
            callback: () => {
                console.log("READY");
                this.setState({
                    categories: this.props.store.getAllCategories,
                });
            },
        });
    }

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("categories_modal_add");
    }

    showModal = () => {
        console.log("showModal");
        this.setState({ selected_category: {} });
        this.props.store.showModalByKey("categories_modal_add");
    };

    handleDeleteCategory = (_evt, _item) => {
        console.info("handleDeleteCategory _evt", _evt, "_item", _item);

        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk() {
                that.props.store.deleteCategory(_item.category).then(() => {
                    that.setState({
                        categories: that.props.store.getAllCategories,
                    });
                });
            },
        });
    };

    handleEdit = async (_evt, _category) => {
        console.info("handleEdit _category", _category);

        console.info("_category.category", _category.category);
        _category.masterposts = this.props.store.getAllMasterposts.filter(
            (masterpost) =>
                masterpost.categories &&
                masterpost.categories.indexOf(_category.category) !== -1
        );

        this.setState({ selected_category: _category });
        this.props.store.showModalByKey("categories_modal_add");
        // const old_category = _category.category;

        // try {
        //     const new_category = await prompt({
        //         title: "Edit Category",
        //         placeholder: "Category name",
        //         rules: [
        //             // check this link for more help: https://ant.design/components/form/#Validation-Rules
        //             {
        //                 required: true,
        //                 message: "You must enter name",
        //             },
        //         ],
        //         initialValue: _category.category,
        //         modalProps: {
        //             width: "50%",
        //         },
        //     });
        //     this.props.store
        //         .editCategory(old_category, new_category)
        //         .then(() => {
        //             // update the categories in this state
        //             this.setState({
        //                 categories: this.props.store.getAllCategories,
        //             });
        //         })
        //         .catch((err) => {
        //             console.error(err);
        //         });
        //     //alert("old_category=" + old_category + ",new_name=" + new_name);
        // } catch (e) {
        //     console.warn(e);
        //     //message.error("Please enter name");
        // }
    };

    showReport = (_evt, _item) => {
        console.info("showReport _evt", _evt, "_item", _item);

        this.props.history.push(
            `/dashboard/categories/${_item.category}/report`
        );
    };

    showmasterposts = (_evt, _item) => {
        console.info("showmasterposts _evt", _evt, "_item", _item);

        //this.props.history.push(`/dashboard/tags/accounts/${_item.tag}`);

        this.props.history.push(
            `/dashboard/masterposts/filter/${_item.category}`
        );
    };

    onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        this.setState({ sortby: _sorting, sortasc });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    getListingDataSource = () => {
        let results = this.props.store.getAllCategories.filter((c) => {
            return (
                c.category
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1
            );
        });

        // --- sorting ---
        results.sort((a, b) => {
            //console.info("results.sort (this.state.sortby)", this.state.sortby);

            var nameA = a.category.toLowerCase(),
                nameB = b.category.toLowerCase();
            var masterpostsA = a.count,
                masterpostsB = b.count;

            switch (this.state.sortby) {
                case "name":
                    if (this.state.sortasc === true) {
                        if (nameA <= nameB) return -1;
                        if (nameA > nameB) return 1;
                    } else {
                        if (nameA <= nameB) return 1;
                        if (nameA > nameB) return -1;
                    }
                    break;
                case "masterposts":
                    if (this.state.sortasc === true) {
                        if (masterpostsA <= masterpostsB) return -1;
                        if (masterpostsA > masterpostsB) return 1;
                    } else {
                        if (masterpostsA <= masterpostsB) return 1;
                        if (masterpostsA > masterpostsB) return -1;
                    }
                    break;
                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting

        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    render() {
        //console.info("TagsList Render", this.state.tags);

        const categories = this.props.store.getAllCategories;

        const pagesCount = categories.length / config.PAGINATION_PERPAGE;
        //console.log("pagesCount = " + pagesCount);
        const header = (
            <SocialListHeader
                title="Categories"
                newButtonClickHandle={this.showModal}
                newButtonText="New category"
                newButtonTooltip={tooltips.CATEGORIES.NEWBUTTON}
                bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );

        // let header = (
        //     <Row
        //         type="flex"
        //         justify="space-between"
        //         style={{ marginBottom: 10 }}
        //     >
        //         <Col>
        //             <Title level={3} style={{ float: "left", paddingRight: 5 }}>
        //                 Categories
        //             </Title>
        //             {/* <Badge
        //                 count={categories.length}
        //                 style={{ backgroundColor: "#1890ff" }}
        //             /> */}
        //         </Col>
        //         <Col>
        //             {
        //                 <Tooltip
        //                     title={tooltips.CATEGORIES.NEWBUTTON}
        //                     mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
        //                 >
        //                     <Button
        //                         type="primary"
        //                         // icon="plus"
        //                         size="small"
        //                         onClick={this.showModal}
        //                         style={{
        //                             marginLeft: 20,
        //                             marginTop: 5,
        //                             fontSize: 12,
        //                         }}
        //                     >
        //                         New category
        //                     </Button>
        //                 </Tooltip>
        //             }
        //         </Col>
        //     </Row>
        // );

        let body =
            categories.length === 0 ? (
                <Empty />
            ) : (
                <List
                    size="small"
                    className="CategoriesList"
                    header={
                        <Fragment>
                            {/* <Row type="flex" justify="space-between">
                                <Col
                                    style={{
                                        minWidth: 25,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                        style={{ marginRight: 10 }}
                                    />
                                    {this.listBulk &&
                                        this.listBulk.renderOptionsAsList()}
                                </Col>
                                <Col>
                                    
                                    <Search
                                        placeholder="filter"
                                        onSearch={this.onSearchChange}
                                        onChange={(e) =>
                                            this.onSearchChange(e.target.value)
                                        }
                                        style={{ width: 200 }}
                                    />
                                </Col>
                            </Row> */}
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Col style={{ width: 25, lineHeight: 1.2 }}>
                                    {/* <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                    /> */}
                                </Col>
                                <Col style={{ flex: "1 1", marginRight: -60 }}>
                                    <SocialListColumn
                                        sortkey="name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Category name
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="masterposts"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        {names.get("MASTERPOST", true, true)}
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ width: 190 }}>Tools</Col>
                            </Row>
                        </Fragment>
                    }
                    footer={
                        pagesCount > 1 && this.state.filter.length === 0 ? (
                            <div style={{ textAlign: "right" }}>
                                <Pagination
                                    size="small"
                                    defaultCurrent={1}
                                    total={categories.length}
                                    pageSize={config.PAGINATION_PERPAGE}
                                    current={this.state.pagination_currentPage}
                                    onChange={this.paginationSetPage}
                                />
                            </div>
                        ) : null
                    }
                    bordered
                    dataSource={this.getListingDataSource()}
                    renderItem={(item) => (
                        <List.Item
                            key={item.category}
                            className={
                                this.listBulk &&
                                this.listBulk.isChecked(item.category)
                                    ? this.listBulk.getCheckedLength() === 1
                                        ? "social_Selected_withTools"
                                        : "social_Selected"
                                    : null
                            }
                            actions={[
                                <Tooltip
                                    key="edit"
                                    title={tooltips.CATEGORIES.LIST_EDIT}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.handleEdit(_evt, item);
                                        }}
                                    >
                                        <Icon type="edit" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="delete"
                                    title={tooltips.CATEGORIES.LIST_DELETE}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Icon
                                        type="delete"
                                        onClick={(evt) => {
                                            this.handleDeleteCategory(
                                                evt,
                                                item
                                            );
                                        }}
                                        style={{
                                            color: scss_variables.scss_color_pallete_error,
                                        }}
                                    />
                                </Tooltip>,
                                // <Button
                                //     type="link"
                                //     style={{ padding: 0 }}
                                //     onClick={(_evt) => {
                                //         this.showReport(_evt, item);
                                //     }}
                                // >
                                //     report
                                // </Button>,
                                <Tooltip
                                    key="masterposts"
                                    title={tooltips.CATEGORIES.LIST_MASTERPOSTS}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showmasterposts(_evt, item);
                                        }}
                                    >
                                        <Icon type="crown" />
                                    </Button>
                                </Tooltip>,
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <Fragment>
                                        <Checkbox
                                            onChange={(e) =>
                                                this.listBulk.handleCheck(
                                                    item.category
                                                )
                                            }
                                            checked={
                                                this.listBulk
                                                    ? this.listBulk.isChecked(
                                                          item.category
                                                      )
                                                    : false
                                            }
                                            style={{ marginRight: 10 }}
                                        />
                                        <SocialTag
                                            store={this.props.store}
                                            key={item.tag}
                                            color="category"
                                        >
                                            {item.category}
                                        </SocialTag>
                                    </Fragment>
                                }
                            />
                            <div style={{ flexGrow: 1, textAling: "left" }}>
                                {item.count}{" "}
                                {names.get("MASTERPOST", true, true, true)}
                            </div>
                        </List.Item>
                    )}
                />
            );

        return (
            <Fragment>
                {header}
                {body}
                <CategoriesListModal
                    store={this.props.store}
                    data={this.state.selected_category}
                />
            </Fragment>
        );
    }
}

export default observer(CategoriesList);
