import React, { Component, Fragment } from "react";
import {
    Tag,
    Form,
    Modal,
    Row,
    Col,
    Steps,
    Button,
    Icon,
    Checkbox,
    Timeline,
    Typography,
    Alert,
    Divider,
} from "antd";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";
import SocialPreview from "./../SocialPreview";

const { Step } = Steps;
const { Title } = Typography;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        tabsCallback(key) {
            console.info("tabsCallback key", key);
        }

        render() {
            const { step, post, store, channels, published } = this.props;

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };

            const that = this;
            const post_channel = channels.filter(
                (c) => c.id === post.channel_id
            )[0];
            // console.info("CHANNELS", channels);

            // console.info("POST_CHANNEL", post_channel);

            return (
                <Fragment>
                    <Steps
                        size="small"
                        current={step}
                        style={{ marginBottom: 50 }}
                    >
                        <Step title="Preview" />
                        {/* <Step title="Channels" /> */}
                        <Step title="Result" />
                    </Steps>

                    <Form {...formItemLayout} style={{ minHeight: "50vh" }}>
                        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                        {/* state
                        <br />*/}
                        {/* <pre>
                            {JSON.stringify(this.state.channels, null, 2)}
                        </pre> */}
                        {/* <pre>
                            this.state.queue_index =
                            {JSON.stringify(this.state.queue_index, null, 2)}
                        </pre> */}
                        {step === 0 ? (
                            <Fragment>
                                <Title level={4} style={{ marginBottom: 20 }}>
                                    Information
                                </Title>
                                <strong>Channel</strong>
                                <br />
                                {post_channel && post_channel.account_name}
                                <Icon
                                    type="arrow-right"
                                    style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                    }}
                                />
                                {post_channel ? (
                                    <div
                                        className={
                                            "social_iconCircle_" +
                                            post_channel.type
                                        }
                                        style={{
                                            marginRight: 5,
                                            width: 13,
                                            height: 13,
                                            display: "inline-block",
                                            marginBottom: -2,
                                        }}
                                    />
                                ) : null}
                                {post_channel && post_channel.name}
                                <br />
                                <br />
                                <strong>Scheduled Date</strong>
                                <br />
                                {post && post.schedule_datetime_formated}

                                <br />
                                <br />
                                <br />
                                {/* <pre>
                                    {JSON.stringify(post_channel, null, 2)}
                                </pre> */}
                                {/* <pre>{JSON.stringify(post, null, 2)}</pre> */}
                                <Title level={4} style={{ marginBottom: 20 }}>
                                    Preview
                                </Title>
                                <div style={{ margin: -10 }}>
                                    <SocialPreview
                                        body={post && post.post_text_parsed}
                                        image_url={
                                            post &&
                                            post.post_file &&
                                            post.post_file.url
                                        }
                                        hideTabs={true}
                                        defaultTab={
                                            post_channel && post_channel.type
                                        }
                                    />
                                </div>
                            </Fragment>
                        ) : null}

                        {step === 1 ? (
                            <Fragment>
                                <Title level={4} style={{ marginBottom: 20 }}>
                                    Posting to channels
                                </Title>
                                <Timeline
                                    pending={
                                        published === false
                                            ? "Publishing..."
                                            : null
                                    }
                                >
                                    <Timeline.Item
                                        key={post_channel && post_channel.id}
                                        //     dot={
                                        //         <Icon
                                        //             type="loading-3-quarters"
                                        //             spin
                                        //             style={{ fontSize: "14px" }}
                                        //         />
                                        //     }
                                    >
                                        {post_channel &&
                                        post_channel.type === "facebook" ? (
                                            <Icon
                                                type={post_channel.type}
                                                theme="filled"
                                                style={{
                                                    marginRight: 10,
                                                    color: store.getTagColorForType(
                                                        post_channel.type
                                                    ),
                                                }}
                                            />
                                        ) : null}
                                        {post_channel && post_channel.name}
                                    </Timeline.Item>
                                </Timeline>
                                {published && published === true ? (
                                    <Alert
                                        message="Posting Finsihed."
                                        description="Uhub has published the post into the selected channel."
                                        type="success"
                                        showIcon
                                    />
                                ) : null}
                            </Fragment>
                        ) : null}

                        <br />
                        <br />
                    </Form>
                </Fragment>
            );
        }
    }
);

/* Wrapper component */
class AccountsQueueSendnow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            confirmLoading: false,
            step: 0,
            channels: [],
            published: false,
        };
    }

    componentDidMount = async () => {
        this.props.store.registerModalListener(
            "accounts_queue_sendnow",
            (_action) => {
                this.loadInformation();
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    loadInformation = async () => {
        console.info("async loadInformation this.props", this.props);
        this.setState({
            post: this.props.post,
            queue_index: this.props.queue_index,
        });

        await this.props.store
            .getTargetByQueuePost(this.props.post)
            .then((target_data) => {
                this.setState({
                    target: target_data,
                    account: this.props.account,
                });
                // Chain the promise
                return this.props.store.getParsedPost(
                    this.props.account.key,
                    this.props.post.post_text,
                    this.props.post.channel_id
                );
            })
            .then((parsedPost) => {
                var statePost = { ...this.state.post };
                statePost.post_text_parsed = parsedPost.post_text_parsed;
                this.setState({ post: statePost });
            })
            .catch((err) => console.error(err));

        // Collecting the channels
        await this.props.store
            .getAccountChannels(this.props.account.key)
            .then((channels) => {
                channels.map((c) => {
                    c.checked = true;
                    return false;
                });
                this.setState({ channels });
            })
            .catch((err) => console.error(err));
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("accounts_queue_sendnow");
    };

    onModalCancel = () => {
        this.setState({ confirmLoading: false });
        this.props.store.hideModalByKey(
            "accounts_queue_sendnow",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    actualSendNow = () => {
        console.log("actualSendNow this.state.post", this.state.post);

        this.setState({ step: 1 });

        let that = this;

        this.props.store
            .postQueuePostByIdAndChannelIds(
                this.props.account.key,
                this.state.queue_index,
                [this.state.post.channel_id]
            )
            .then((postedData) => {
                console.info("postedData", postedData);
                that.setState({
                    published: true,
                });
                social_notification(
                    "post_queue_post_by_id_and_channels_channels",
                    "success"
                );

                if (typeof this.props.sendnow_callback !== "undefined")
                    this.props.sendnow_callback();
            })
            .catch((error) => {
                console.warn("ERROR !!!!", error);
                that.setState({
                    published: false,
                    step: 0,
                });
                // social_notification(
                //     "post_queue_post_by_id_and_channels_channels",
                //     "error",
                //     {
                //         description: error.description,
                //     }
                // );
            });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Send Now Confirmation"}
                        onCancel={this.onModalCancel}
                        maskClosable={false}
                        confirmLoading={this.state.confirmLoading}
                        footer={
                            <Row>
                                <Col span={12} style={{ textAlign: "left" }}>
                                    <Button onClick={this.onModalCancel}>
                                        Close
                                    </Button>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <Button.Group>
                                        <Button
                                            type="primary"
                                            onClick={this.actualSendNow}
                                            disabled={this.state.step === 1}
                                        >
                                            Send
                                        </Button>
                                        {/* <Button
                                            type="primary"
                                            disabled={this.state.step === 1}
                                            onClick={this.handleClickPrev}
                                        >
                                            <Icon type="left" />
                                            Backward
                                        </Button> */}
                                    </Button.Group>
                                </Col>
                            </Row>
                        }
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            post={this.state.post}
                            account={this.props.account}
                            step={this.state.step}
                            channels={this.state.channels}
                            published={this.state.published}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AccountsQueueSendnow);
