import React, { Fragment } from "react";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Table,
    Button,
    Divider,
    Typography,
} from "antd";
import scss_variables from "./../../_variables.scss";

const redColor = "#cf1322";
const greenColor = "#3f8600";

const ReportSingleValue = (props) => {
    //console.info("ReportSingleValue props", props);

    const suffix =
        props.oldValue && typeof props.oldValue !== "undefined" ? (
            <Fragment>
                %
                <span
                    style={{ color: scss_variables.scss_color_reportPrevColor }}
                >
                    {" "}
                    ({props.oldValue.toString()})
                </span>
            </Fragment>
        ) : (
            "%"
        );

    const ReportElementDescription = {
        color: "#999",
        fontSize: 11,
        lineHeight: "0.9rem",
        height:
            props.shallowDescription && props.shallowDescription === true
                ? "auto !important"
                : "3.6rem",
    };

    return (
        <Fragment>
            {/* <h1>ReportSingleValue</h1> */}
            <Statistic
                title={
                    <Fragment>
                        {props.title && props.title}
                        {/* <Tooltip title={props.description && props.description}>
                            <Icon type="info-circle" />
                        </Tooltip> */}
                    </Fragment>
                }
                value={props.value && props.value}
                prefix={props.prefix && props.prefix}
            />

            <div style={ReportElementDescription}>
                {props.description && props.description}
            </div>
            {props.diff &&
            props.diff !== Infinity &&
            props.diff !== "Infinity" &&
            !isNaN(props.diff) ? (
                <Statistic
                    value={props.diff && props.diff}
                    precision={1}
                    valueStyle={{
                        color: props.diff > 0 ? greenColor : redColor,
                        fontSize: "1.5rem",
                    }}
                    prefix={
                        <Icon
                            type={props.diff > 0 ? "arrow-up" : "arrow-down"}
                        />
                    }
                    suffix={suffix}
                />
            ) : null}
        </Fragment>
    );
};

export default ReportSingleValue;
