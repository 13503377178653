import Rebase from "re-base";
import firebase from "firebase";
import config from "./config";

const app = firebase.initializeApp(config.firebase);
const base = Rebase.createClass(app.database());
const firebase_messaging =
    firebase.messaging && firebase.messaging.isSupported()
        ? app.messaging()
        : null;
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();

const auth = firebase.auth();

//auth.useEmulator("http://localhost:9099");

export {
    base,
    app,
    facebookProvider,
    twitterProvider,
    googleProvider,
    firebase,
    firebase_messaging,
    auth,
};
