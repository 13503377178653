import React, { Component, Fragment } from "react";
import moment from "moment";
import {
    CardElement,
    injectStripe,
    StripeProvider,
    Elements,
} from "react-stripe-elements";
import config from "../../config";

import {
    Form,
    Modal,
    Row,
    Col,
    Icon,
    Divider,
    Select,
    Radio,
    Switch,
    Alert,
} from "antd";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";

const { Option } = Select;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                changed: false,
                subscriptions: [],
                selected_subscription: null,
                additional_feature_conversion_tracking: false,
                additional_feature_conversion_tracking_amount: 20,
                additional_feature_ad_budget: false,
                additional_feature_ad_budget_amount: 60,
                current_amount: 0,
            };
        }

        componentDidMount() {
            //this.setState(this.props.data);
            console.info("PaymentForm props", this.props);

            const lastSubscription =
                this.props.account.payment_history &&
                this.props.account.payment_history
                    .filter((ph) => {
                        if (ph.subscription) {
                            return true;
                        }
                        return false;
                    })
                    .pop();
            console.info("lastSubscription", lastSubscription);

            if (!lastSubscription) {
                return false;
            }

            const lastPlanAmount = Number(
                lastSubscription.stripe_data.planData.amount / 100
            );
            console.info("lastPlanAmount", lastPlanAmount);

            const diffInDays = moment(this.props.account.paid_until)
                .utc()
                .diff(moment(), "days"); // =1
            console.info("diffInDays", diffInDays);

            // amountPerDay
            const couponAmount = Number(
                (lastPlanAmount / 30) * diffInDays
            ).toFixed(2);
            console.info("couponAmount", couponAmount);

            this.setState({
                stripe: this.props.stripe,
                account: this.props.account,
                coupon_amount: couponAmount,
            });
            // load subscriptions
            let that = this;
            this.props.store
                .loadSubscriptions()
                .then((subscriptionsData) => {
                    console.info("subscriptionsData", subscriptionsData);
                    that.setState({
                        subscriptions: subscriptionsData,
                        selected_subscription: subscriptionsData[0],
                        total: Number(subscriptionsData[0].amount),
                        current_amount: Number(subscriptionsData[0].amount),
                    });

                    setTimeout(function () {
                        that.handleCalculation();
                    }, 100);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }

        // componentDidMount() {
        //     // To disable submit button at the beginning.
        //     this.props.form.validateFields();
        // }

        handleCalculation = () => {
            console.info("handleCalculation this.state", this.state);

            let total = 0,
                channels_amount = 0;

            const channelsLength =
                (this.state.account.facebook &&
                    this.state.account.facebook.data.length) +
                (this.state.account.instagram &&
                    this.state.account.instagram.data.length);

            console.log("--- channelsLength=" + channelsLength);

            if (this.state.selected_subscription) {
                console.log("WE HAVE this.state.selected_subscription");
                total = total + Number(this.state.selected_subscription.amount);
            }

            // channels
            if (this.state.selected_subscription.free_channels) {
                console.log(
                    "WE HAVE this.state.selected_subscription.free_channels"
                );

                channels_amount = Number(
                    (channelsLength -
                        this.state.selected_subscription.free_channels) *
                        this.state.selected_subscription.amount_per_channel
                );
                total = total + Number(channels_amount);
                console.log("WE HAVE channels_amount=" + channels_amount);
            }

            if (this.state.additional_feature_conversion_tracking === true) {
                console.log(
                    "WE HAVE this.state.additional_feature_conversion_tracking"
                );

                total =
                    total +
                    Number(
                        this.state
                            .additional_feature_conversion_tracking_amount *
                            channelsLength
                    );
            }

            if (this.state.additional_feature_ad_budget === true) {
                total =
                    total +
                    Number(this.state.additional_feature_ad_budget_amount);
            }
            console.log("total = " + total);

            this.setState({ total, channels_amount });
            return total;
        };

        handleAdditionalFeature = (_type, _e) => {
            console.info("handleAdditionalFeature _type", _type, "_e", _e);
            switch (_type) {
                case "conversion_tracking":
                    this.setState({
                        additional_feature_conversion_tracking: _e,
                    });
                    break;
                case "ad_budget":
                    this.setState({
                        additional_feature_ad_budget: _e,
                    });
                    break;
                default:
                    break;
            }

            if (typeof this.props.handleUpdate === "function")
                this.props.handleUpdate(this.state);

            let that = this;
            setTimeout(function () {
                that.handleCalculation();
            }, 100);
        };

        handleSubscriptionSelection = (e) => {
            console.info("handleSubscriptionSelection", e);
            let foundSubscription = this.state.subscriptions.filter(
                (plan) => plan.key === e.target.value
            )[0];
            this.setState({
                selected_subscription: foundSubscription,
            });
            console.info("foundSubscription", foundSubscription);

            // recalculate
            let that = this;
            setTimeout(function () {
                that.handleCalculation();
            }, 100);

            if (typeof this.props.handleUpdate === "function")
                this.props.handleUpdate(this.state);
        };

        render() {
            // const {
            //     getFieldDecorator,
            //     // getFieldsError,
            //     // getFieldError,
            //     // isFieldTouched
            // } = this.props.form;

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };

            return (
                <Form {...formItemLayout}>
                    Account email:
                    <h2 style={{ marginTop: -5 }}>
                        {this.state.account && this.state.account.email}
                    </h2>
                    Account channels: <br />
                    {this.state.account &&
                        this.state.account.facebook &&
                        this.state.account.facebook.data.map(
                            (fbChannel, fbChannelIndex) => {
                                return (
                                    <div
                                        key={fbChannelIndex}
                                        style={{
                                            display: "block",
                                            lineHeight: 1.5,
                                        }}
                                    >
                                        <Icon
                                            type="facebook"
                                            theme="filled"
                                            style={{
                                                color: this.props.store.getTagColorForType(
                                                    "facebook"
                                                ),
                                                marginRight: 10,
                                                fontSize: 17,
                                            }}
                                        />
                                        <strong>{fbChannel.name}</strong>
                                    </div>
                                );
                            }
                        )}
                    {this.state.account &&
                        this.state.account.instagram &&
                        this.state.account.instagram.data.map(
                            (igAccount, igAccountIndex) => {
                                return (
                                    <div
                                        key={igAccountIndex}
                                        style={{
                                            display: "block",
                                            lineHeight: 1.5,
                                        }}
                                    >
                                        <Icon
                                            type="instagram"
                                            theme="filled"
                                            style={{
                                                color: this.props.store.getTagColorForType(
                                                    "instagram"
                                                ),
                                                marginRight: 10,
                                                fontSize: 17,
                                            }}
                                        />
                                        <strong>
                                            {igAccount.meta.name} (
                                            {igAccount.meta.username})
                                        </strong>
                                    </div>
                                );
                            }
                        )}
                    <Divider />
                    <Alert
                        message={
                            <Fragment>
                                Coupon:{" "}
                                <strong>${this.state.coupon_amount}</strong>
                            </Fragment>
                        }
                        description={
                            <Fragment>
                                Your subscription cycle is not yet completed.{" "}
                                <br />
                                So the amount that is still to be consumed will
                                be used as a coupon on your next invoice.
                            </Fragment>
                        }
                        type="info"
                    />
                    <br />
                    <Form.Item label="Subscription">
                        <Radio.Group
                            value={
                                this.state.selected_subscription &&
                                this.state.selected_subscription.key
                            }
                            onChange={this.handleSubscriptionSelection}
                            style={{ marginBottom: 10, marginTop: -20 }}
                        >
                            {this.state.subscriptions.map((plan) => {
                                return (
                                    <Radio.Button
                                        value={plan.key}
                                        key={plan.key}
                                    >
                                        {plan.name}
                                    </Radio.Button>
                                );
                            })}
                        </Radio.Group>
                        <br />

                        <div style={{ lineHeight: 1.5 }}>
                            Amount: $
                            <strong>
                                {this.state.selected_subscription &&
                                    this.state.selected_subscription.amount}
                            </strong>
                            <br />
                            Desciption:{" "}
                            <strong>
                                {this.state.selected_subscription &&
                                    this.state.selected_subscription
                                        .description}
                            </strong>
                            <br />
                            Details:{" "}
                            <strong>
                                {this.state.selected_subscription &&
                                    this.state.selected_subscription.details}
                            </strong>
                            <br />
                            Free channels:{" "}
                            <strong>
                                {this.state.selected_subscription &&
                                    this.state.selected_subscription
                                        .free_channels}
                            </strong>
                        </div>
                    </Form.Item>
                    <br />
                    <Form.Item label="Features">
                        <div style={{ paddingTop: 10 }}>
                            <Divider style={{ margin: "10px 0" }} />
                            <Row
                                type="flex"
                                justify="center"
                                align="top"
                                style={{ lineHeight: 1.8 }}
                            >
                                <Col span={12}>
                                    <strong>Channels</strong>
                                </Col>

                                <Col
                                    span={8}
                                    style={{
                                        padding: "3px 11px",
                                    }}
                                >
                                    ${this.state.channels_amount}
                                </Col>
                                <Col
                                    span={4}
                                    style={{
                                        textAlign: "right",
                                    }}
                                ></Col>
                            </Row>
                            <Divider style={{ margin: "10px 0" }} />
                            <Row
                                type="flex"
                                justify="center"
                                align="top"
                                style={{ lineHeight: 1.8 }}
                            >
                                <Col span={12}>
                                    <strong>Conversion Tracking</strong>
                                </Col>

                                <Col
                                    span={8}
                                    style={{
                                        padding: "3px 11px",
                                    }}
                                >
                                    $20{" "}
                                    <span style={{ fontSize: 10 }}>
                                        (per channel)
                                    </span>
                                </Col>
                                <Col
                                    span={4}
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <Switch
                                        checkedChildren={<Icon type="check" />}
                                        unCheckedChildren={
                                            <Icon type="close" />
                                        }
                                        onChange={(e) =>
                                            this.handleAdditionalFeature(
                                                "conversion_tracking",
                                                e
                                            )
                                        }
                                        checked={
                                            this.state
                                                .additional_feature_conversion_tracking
                                        }
                                        style={{
                                            display: "inline-block",
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Divider style={{ margin: "10px 0" }} />

                            <Row
                                type="flex"
                                justify="center"
                                align="top"
                                style={{ lineHeight: 1.8 }}
                            >
                                <Col span={12}>
                                    <strong>Add Ad budget</strong>
                                </Col>

                                <Col span={8}>
                                    <Select
                                        value={
                                            this.state
                                                .additional_feature_ad_budget_amount
                                        }
                                        style={{ width: 80 }}
                                        onChange={(e) => {
                                            this.setState({
                                                additional_feature_ad_budget_amount: e,
                                            });
                                            let that = this;
                                            setTimeout(function () {
                                                that.handleCalculation();
                                            }, 100);
                                        }}
                                    >
                                        <Option value="60">$60</Option>
                                        <Option value="100">$100</Option>
                                        <Option value="150">$150</Option>
                                        <Option value="200">$200</Option>
                                        <Option value="300">$300</Option>
                                    </Select>
                                </Col>
                                <Col
                                    span={4}
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <Switch
                                        checkedChildren={<Icon type="check" />}
                                        unCheckedChildren={
                                            <Icon type="close" />
                                        }
                                        onChange={(e) =>
                                            this.handleAdditionalFeature(
                                                "ad_budget",
                                                e
                                            )
                                        }
                                        checked={
                                            this.state
                                                .additional_feature_ad_budget
                                        }
                                        style={{
                                            display: "inline-block",
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Divider style={{ margin: "10px 0" }} />
                            <Row>
                                <Col style={{ textAlign: "right" }}>
                                    Total: <strong>${this.state.total}</strong>
                                </Col>
                            </Row>
                        </div>
                    </Form.Item>
                    <br />
                    <Form.Item label="Card">
                        <div style={{ paddingTop: 12 }}>
                            <CardElement />
                        </div>
                    </Form.Item>
                </Form>
            );
        }
    }
);
const StripeForm = injectStripe(ModalForm);

/* Wrapper component */
class AccountsListModalPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            loading: false,
        };
    }

    componentDidMount = () => {
        console.info("AccountsListModalPlan props", this.props);
        this.props.store.registerModalListener("plan_modal", (_action) => {
            this.setState({
                modal_visible: _action === "show" ? true : false,
                changed: false,
            });
        });
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("plan_modal");
    };

    onModalApply = () => {
        const { form } = this.formRef.props;
        form.validateFields(async (err, values) => {
            let formState = Object.assign({}, this.formRef.state);
            console.info("formState", formState);

            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            try {
                // stripe token
                let token = await formState.stripe.createToken({
                    name: formState.account.email,
                });
                console.info("token", token);
                if (token.error) {
                    social_notification("validation_error", "error", {
                        description: token.error.message,
                    });
                    return false;
                }

                // Start loading spinning
                this.setState({ loading: true });

                // finding previous plan data
                let stripe_plan_id,
                    stripe_customer_data = null;
                if (formState.account.payment_history) {
                    const payment_history = formState.account.payment_history;
                    payment_history.map((ph) => {
                        if (ph.stripe_data.planData) {
                            stripe_plan_id = ph.stripe_data.planData.id;
                        }
                        if (ph.stripe_data.customerData) {
                            stripe_customer_data = JSON.parse(
                                JSON.stringify(ph.stripe_data.customerData)
                            );
                        }
                        return false;
                    });
                }

                console.info("stripe_plan_id = " + stripe_plan_id);
                console.info("stripe_customer_data = ", stripe_customer_data);

                // process the payment
                let payResult = null;
                if (stripe_plan_id && stripe_customer_data) {
                    payResult = await this.props.store.updateSubscription({
                        token: token.token,
                        stripe_plan_id: stripe_plan_id,
                        stripe_customerData: stripe_customer_data,
                        amount: formState.total,
                        coupon_amount: formState.coupon_amount,
                        payment_data: {
                            account_key: formState.account.key,
                            account_email: formState.account.email,
                            subscription: formState.selected_subscription,
                            additional: {
                                conversion_tracking: {
                                    status:
                                        formState.additional_feature_conversion_tracking,
                                    amount:
                                        formState.additional_feature_conversion_tracking_amount,
                                },
                                ad_budget: {
                                    status:
                                        formState.additional_feature_ad_budget,
                                    amount:
                                        formState.additional_feature_ad_budget_amount,
                                },
                            },
                        },
                    });
                } else {
                    // Add Subscription
                    payResult = await this.props.store.paySubscription({
                        token: token.token,
                        amount: formState.total,
                        payment_data: {
                            account_key: formState.account.key,
                            account_email: formState.account.email,
                            subscription: formState.selected_subscription,
                            additional: {
                                conversion_tracking: {
                                    status:
                                        formState.additional_feature_conversion_tracking,
                                    amount:
                                        formState.additional_feature_conversion_tracking_amount,
                                },
                                ad_budget: {
                                    status:
                                        formState.additional_feature_ad_budget,
                                    amount:
                                        formState.additional_feature_ad_budget_amount,
                                },
                            },
                        },
                    });
                }
                console.info("payResult", payResult);

                //return payResult;
                social_notification("account_subscription_update", "success");

                form.resetFields();
                this.onModalCancel(); // hide the modal
            } catch (e) {
                console.warn(e);
                social_notification("account_subscription_update", "error", {
                    description: e,
                });
                return false;
            }
        });
    };

    onModalCancel = () => {
        this.props.store.hideModalByKey(
            "plan_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    handleUpdate = () => {
        console.log("handleUpdate");
        this.setState({ changed: true });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        width={"50vw"}
                        title={"Update subscription"}
                        okText={"Update subscription"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalApply} //onApply
                        maskClosable={false}
                        okButtonProps={{ disabled: !this.state.changed }}
                        confirmLoading={this.state.loading}
                    >
                        <StripeProvider apiKey={config.stripe.apiKey}>
                            <Elements>
                                <StripeForm
                                    store={this.props.store}
                                    wrappedComponentRef={this.saveFormRef}
                                    account={this.props.account}
                                    handleUpdate={this.handleUpdate}
                                />
                            </Elements>
                        </StripeProvider>
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AccountsListModalPlan);
