import React, { Component, Fragment } from "react";

import moment from "moment";

import EditableTagGroup from "../EditableTagGroup";

import { observer } from "mobx-react";

import {
    Input,
    Form,
    Radio,
    Modal,
    DatePicker,
    Checkbox,
    AutoComplete,
} from "antd";
import names from "./../../names";

const { TextArea } = Input;

/* Modal Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        handleCategoriesChange = (_categories) => {
            console.info("handleCategoriesChange", _categories);
            //this.props.data = _categories;
            this.props.form.setFieldsValue({ categories: _categories });
        };
        handleCampaignsChange = (_campaigns) => {
            console.info("handleCampaignsChange", _campaigns);
            //this.props.data = _campaigns;
            this.props.form.setFieldsValue({ campaigns: _campaigns });
        };

        handleEveryNumberChange = (_value) => {
            this.props.data.every_number = _value;
        };

        handleEveryDropdownChange = (_value) => {
            this.props.data.every_dropdown = _value;
        };

        handleScheduledDateChange = (_value) => {
            this.props.data.scheduled_date = _value;
        };

        handlePublishLocalTimeChange = (_evt) => {
            this.props.data.publish_local_time = _evt.target.checked;
        };

        render() {
            const { form, data } = this.props;
            const { getFieldDecorator } = form;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 }
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 }
            //     }
            // };
            const formItemLayout = null;

            return (
                <Form {...formItemLayout}>
                    <Form.Item label="Campaigns">
                        {getFieldDecorator("campaigns", {
                            initialValue: data.campaigns ? data.campaigns : [],
                            onChange: this.handleCampaignsChange,
                        })(
                            <EditableTagGroup
                                element_title="Campaign"
                                tag_color={this.props.store.getTagColorForType(
                                    "campaign"
                                )}
                                options={this.props.store.getAllCampaigns.map(
                                    (campaign) => campaign.campaign
                                )}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message: `Please input the name of the ${names.get(
                                        "MASTERPOST"
                                    )}!`,
                                    type: "string",
                                },
                            ],
                            initialValue: data.name,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Note">
                        {getFieldDecorator("note", {
                            initialValue: data.note,
                        })(<TextArea />)}
                    </Form.Item>
                    <Form.Item
                        className="collection-create-form_last-form-item"
                        label="Publishing"
                    >
                        {getFieldDecorator("publish_type", {
                            initialValue: data.publish_type
                                ? data.publish_type
                                : "immediately",
                        })(
                            <Radio.Group>
                                <Radio value="immediately">Immediately</Radio>
                                <br />
                                {/* <Radio value="regularly">Regularly</Radio>
                                <div
                                    style={{
                                        border: "1px solid #ebebeb",
                                        padding: 5,
                                        backgroundColor: "#fafafa",
                                        borderRadius: 5,
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <span
                                                style={{
                                                    float: "left",
                                                    marginRight: 5,
                                                    lineHeight: 2.3,
                                                }}
                                            >
                                                every
                                            </span>
                                            {getFieldDecorator("every_number", {
                                                initialValue: data.every_number,
                                            })(
                                                <InputNumber
                                                    min={1}
                                                    max={100}
                                                    style={{
                                                        width: 60,
                                                        float: "left",
                                                        marginRight: 5,
                                                    }}
                                                    onChange={
                                                        this
                                                            .handleEveryNumberChange
                                                    }
                                                    disabled={
                                                        form.getFieldValue(
                                                            "publish_type"
                                                        ) !== "regularly"
                                                    }
                                                />
                                            )}
                                            {getFieldDecorator(
                                                "every_dropdown",
                                                {
                                                    initialValue:
                                                        data.every_dropdown,
                                                }
                                            )(
                                                <Select
                                                    style={{
                                                        width: 100,
                                                        float: "left",
                                                    }}
                                                    onChange={
                                                        this
                                                            .handleEveryDropdownChange
                                                    }
                                                    disabled={
                                                        form.getFieldValue(
                                                            "publish_type"
                                                        ) !== "regularly"
                                                    }
                                                >
                                                    <Option value="days">
                                                        Days
                                                    </Option>
                                                    <Option value="weeks">
                                                        Weeks
                                                    </Option>
                                                    <Option value="months">
                                                        Months
                                                    </Option>
                                                    <Option value="years">
                                                        Years
                                                    </Option>
                                                </Select>
                                            )}
                                        </Col>
                                    </Row>
                                </div> */}

                                <Radio value="scheduled">Scheduled</Radio>
                                <div
                                    style={{
                                        border: "1px solid #ebebeb",
                                        padding: 5,
                                        backgroundColor: "#fafafa",
                                        borderRadius: 5,
                                    }}
                                >
                                    {getFieldDecorator("scheduled_date", {
                                        initialValue: moment(
                                            data.scheduled_date
                                        ),
                                    })(
                                        <DatePicker
                                            disabled={
                                                form.getFieldValue(
                                                    "publish_type"
                                                ) !== "scheduled"
                                            }
                                            onChange={
                                                this.handleScheduledDateChange
                                            }
                                            showTime={true}
                                        />
                                    )}
                                </div>
                            </Radio.Group>
                        )}

                        {getFieldDecorator("publish_local_time", {
                            initialValue: data.publish_local_time,
                        })(
                            <Checkbox
                                onChange={this.handlePublishLocalTimeChange}
                                checked={data.publish_local_time}
                            >
                                Publish in local time
                            </Checkbox>
                        )}
                    </Form.Item>

                    <Form.Item label="Categories">
                        {getFieldDecorator("categories", {
                            initialValue: data.categories
                                ? data.categories
                                : [],
                            onChange: this.handleCategoriesChange,
                        })(
                            <EditableTagGroup
                                element_title="Category"
                                tag_color={this.props.store.getTagColorForType(
                                    "category"
                                )}
                                options={this.props.store.getAllCategories.map(
                                    (category) => category.category
                                )}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Brand">
                        {getFieldDecorator("brand", {
                            initialValue: data.brand,
                        })(
                            <AutoComplete
                                dataSource={this.props.store.getAllBrands}
                                style={{ width: 200 }}
                                placeholder="input here"
                            />
                        )}
                    </Form.Item>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class MasterpostsListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "masterposts_modal",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("masterposts_modal");
    };

    onModalCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            if (values.scheduled_date)
                values.scheduled_date = moment()
                    .utc(values.scheduled_date)
                    .valueOf();
            this.props.store.addMasterpost({
                ...values,
                ...this.state.edit_data,
            });

            form.resetFields();
            this.onModalCancel();
        });
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            if (values.scheduled_date)
                values.scheduled_date = moment(values.scheduled_date).valueOf();

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.props.data.key);

            this.props.store.updateMasterpost(this.props.data.key, values);

            form.resetFields();
            this.setState({ modal_visible: false });
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "masterposts_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data, store } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={
                            !data.name
                                ? `Create new ${names.get("MASTERPOST")}`
                                : `Edit a ${names.get("MASTERPOST")}`
                        }
                        okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        onOk={
                            !data.name ? this.onModalCreate : this.onModalEdit
                        } //onApply
                        maskClosable={false}
                    >
                        <ModalForm
                            store={store}
                            data={data}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(MasterpostsListModal);
