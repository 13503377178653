import React, { Component, Fragment } from "react";
// import { Button } from "antd";
import TwitterLogin from "react-twitter-login";
import { social_getCookie } from "../functions";
import { Icon, Button } from "antd";
import { firebase, twitterProvider } from "../base";
import config from "../config";

// import PropTypes from "prop-types";

// const socButton = ({ children, triggerlogin, ...props }) => (
//     <Button
//         onClick={triggerlogin}
//         {...props}
//         style={
//             props.style
//                 ? props.style
//                 : { background: "#4267b2", borderColor: "#4267b2" }
//         }
//         type="primary"
//         icon={props.provider}
//     >
//         {children}
//     </Button>
// );

class socButtonTwitter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        const link_twitter_account_key = social_getCookie(
            "link_twitter_account_key"
        );
        this.setState({ link_twitter_account_key });
        console.info('config', config);
    };

    authHandler = (err, data) => {
        console.info("authHandler err", err, "data", data);
        this.setState({ oauth_data: data });

        this.props.store
            .authAccount("twitter", this.state.link_twitter_account_key, data)
            .then((res) => {})
            .catch((err) => {
                console.error(err);
            });
    };

    // redirectToTwitter() {
    //     let newURL = "https://api.twitter.com/oauth/request_token";
    //     //window.location.replace(newURL);   

    //     let headerObj = {
    //         oauth_nonce: "K7ny27JTpKVsTgdyLdDfmQQWVLERj2zAK5BslRsqyw",
    //         oauth_callback: "http%3A%2F%2Fmyapp.com%3A3005%2Ftwitter%2Fprocess_callback",
    //         oauth_signature_method: "HMAC-SHA1",
    //         oauth_timestamp: "1300228849",
    //         oauth_consumer_key: "OqEqJeafRSF11jBMStrZz",
    //         oauth_signature: "Pc%2BMLdv028fxCErFyi8KXFM%2BddU%3D",
    //         oauth_version: "1.0"
    //     };
    //     const qs = Object.keys(headerObj)
    //     .map(key => `${key}=${headerObj[key]}`)
    //     .join('&');

    //     fetch(newURL, { 
    //         method: 'post', 
    //         headers: new Headers({
    //           'Authorization': 'OAuth', 
    //           'Content-Type': 'application/x-www-form-urlencoded'
    //         }), 
    //         body: qs
    //       });
    // }

    // loginWithTwitter() {
    //     const provider = twitterProvider;
    //     firebase
    //         .auth()
    //         .signInWithPopup(provider)
    //         .then(function (result) {
    //             // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
    //             // You can use these server side with your app's credentials to access the Twitter API.
    //             //var token = result.credential.accessToken;
    //             //var secret = result.credential.secret;
    //             // The signed-in user info.
    //             //var user = result.user;
    //             // ...
    //             console.info("result", result);
    //         })
    //         .catch(function (error) {
    //             // Handle Errors here.
    //             //var errorCode = error.code;
    //             //var errorMessage = error.message;
    //             // The email of the user's account used.
    //             //var email = error.email;
    //             // The firebase.auth.AuthCredential type that was used.
    //             //var credential = error.credential;
    //             // ...
    //             console.error(error);
    //         });
    // }

    openAuthPopupForTwitter = () => {
        const url = config.api.URLprefix+"auth/twitter?account_key="+this.props.account_key;

        //alert('URL = '+config.api.URLprefix+"auth/twitter?account_key="+this.props.account_key);
        //alert('account_key = '+this.props.account_key);
        const left = Number(window.screen.availWidth)/2 - 250;
        window.open(url, 'authTwitterPopup', 'height=500,width=400,status=no,resizable=no,location=no,top=200,left='+left);

    }

    render() {
        //const {  } = this.props;

        return (
            <Fragment>
                {/* <pre>state={JSON.stringify(this.state, null, 2)}</pre> */}
                <div >
                    {!this.state.oauth_data ? (
                        <Fragment>
                            {/* <TwitterLogin
                                authCallback={this.authHandler}
                                consumerKey={config.twitter.customerKey}
                                consumerSecret={config.twitter.customerSecret}
                                // callbackUrl={window.location.href}
                                children={
                                    <div className={"TwitterButton"}>
                                        <button style={{ padding: "6px 0" }}>
                                            <Icon
                                                type="twitter"
                                                style={{ marginRight: 5 }}
                                            />
                                            Authorize Twitter
                                        </button>
                                    </div>
                                }
                            /> */}
                            {/* <Button
                                onClick={this.loginWithTwitter}
                                type="primary"
                                style={{
                                    background: this.props.store.getTagColorForType(
                                        "twitter"
                                    ),
                                }}
                            >
                                <Icon
                                    type="twitter"
                                    style={{ color: "white", marginRight: 5 }}
                                />
                                Authorize with Twitter
                            </Button> */}
                            <Button
                                onClick={this.openAuthPopupForTwitter}
                                type="primary"
                                style={{...this.props.style,
                                    background: this.props.store.getTagColorForType(
                                        "twitter"
                                    ),
                                }}
                            >
                                <Icon
                                    type="twitter"
                                    style={{ color: "white", marginRight: 5 }}
                                />
                                Authorize with Twitter
                            </Button>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Icon
                                type="check-circle"
                                style={{
                                    color: "#52c41a",
                                    fontSize: "4rem",
                                    marginBottom: 20,
                                }}
                            />
                            <h2>Thank you.</h2>
                            <p>You can close that window now.</p>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default socButtonTwitter;
