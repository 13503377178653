import React from "react";
import scss_variables from "./../../_variables.scss";

const loadingStyle = {
    background: scss_variables.scss_color_pallete_primary,
    height: 3,
    opacity: 0.2,
    position: "absolute",
    top: 0,
    zIndex: 1000,
    fontSize: 0,
};

const LoadingProgress = (props) => {
    const width = "1vw";
    const currentStyle = { ...loadingStyle, ...{ width } };
    return <div style={currentStyle}></div>;
};

export default LoadingProgress;
