import React, { Fragment } from "react";
import { Icon, Button } from "antd";
import moment from "moment";

const style_notPublished = {
    color: "#282827",
    fontSize: 12,
    textAlign: "right",
    marginBottom: 10,
};
const style_twitterPost_container = {
    backgroundColor: "#F2F2F2",
    paddingTop: 65,
    paddingRight: 190,
    paddingBottom: 40,
    paddingLeft: 50,
    position: "relative",
    fontSize: 17,
};
const style_twitterPost_icon = {
    color: "white",
    background: "#1da1f2",
    borderRadius: "100%",
    padding: 3,
    fontSize: 11,
    position: "absolute",
    top: 38,
    //     left: 25,
    width: 17,
    height: 17,
    marginLeft: -25,
};
const style_twitterPost_handle = {
    fontSize: 14,
    color: "#282827",
    position: "absolute",
    fontWeight: "bold",
    top: 35,
    //     left: 50,
};
const style_twitterPost_datetime = {
    fontSize: 13,
    color: "#9D9D9C",
    position: "absolute",
    top: 35,
    right: 40,
    letterSpacing: "-0.02rem",
    textAlign: "right",
    lineHeight: 1.2,
};
const style_twitterPost_openButton = {
    backgroundColor: "#1da1f2",
    marginTop: 20,
    border: 0,
};

// Facebook -------------------------------
const style_facebookPost_container = {
    backgroundColor: "#F2F2F2",
    padding: "65px 190px 40px 50px",
    position: "relative",
    fontSize: 17,
};

const style_facebookPost_icon = {
    backgroundColor: "#4167b2",
    marginRight: 5,
    borderRadius: 17,
    position: "absolute",
    top: 38,
    //     left: 25,
    width: 17,
    height: 17,
    marginLeft: -25,
    backgroundSize: 13,
};
const style_facebookPost_handle = {
    fontSize: 14,
    color: "#282827",
    position: "absolute",
    fontWeight: "bold",
    top: 35,
    //     left: 50,
};
const style_facebookPost_datetime = {
    fontSize: 13,
    color: "#9D9D9C",
    position: "absolute",
    top: 35,
    right: 40,
    letterSpacing: "-0.02rem",
    textAlign: "right",
    lineHeight: 1.2,
};
const style_facebookPost_openButton = {
    backgroundColor: "#4167b2",
    marginTop: 20,
    border: 0,
    color: "white",
    paddingRight: 40,
};

const style_facebookPost_Image = {
    border: "6px solid white",
    width: 260,
    position: "absolute",
    top: 10,
    left: 10,
};

// Instagram -------------------------------
const style_instagramPost_container = {
    backgroundColor: "#F2F2F2",
    //     paddingTop: 65,
    //     paddingRight: 190,
    //     paddingBottom: 40,
    //     paddingLeft: 50,
    position: "relative",
    fontSize: 17,

    display: "flex",
    flexDirection: "row",
    padding: 10,
};

const style_instagramPost_icon = {
    backgroundColor: "#e4405f",
    marginRight: 5,
    borderRadius: 17,
    //     position: "absolute",
    //     top: 38,
    //     left: 25,
    width: 17,
    height: 17,
    marginLeft: -25,
    backgroundSize: 13,
    color: "white",
    fontSize: 9,
    lineHeight: 2.2,
    float: "left",
};
const style_instagramPost_handle = {
    fontSize: 14,
    color: "#282827",
    //     position: "absolute",
    fontWeight: "bold",
    // top: 35,
    //     left: 50,
};
const style_instagramPost_datetime = {
    fontSize: 13,
    color: "#9D9D9C",
    position: "absolute",
    top: 35,
    right: 40,
    letterSpacing: "-0.02rem",
    textAlign: "right",
    lineHeight: 1.2,
};
const style_instagramPost_openButton = {
    backgroundColor: "#e4405f",
    marginTop: 20,
    border: 0,
    color: "white",
};

const style_instagramPost_Image = {
    border: "6px solid white",
    width: 260,
    //     position: "absolute",
    //     top: 10,
    //     left: 10,
};

const InboxChainPost = (props) => {
    const chain = props.chain;

    const notPostedBySocial = (
        <div style={style_notPublished}>
            <Icon
                type="exclamation"
                style={{
                    color: "black",
                    backgroundColor: "#bcbcbc",
                    marginRight: 5,
                    borderRadius: 16,
                    width: 16,
                    height: 16,
                    paddingTop: 2,
                }}
            />
            Post not published by UHUB
        </div>
    );

    console.info("props", props);
    let postBody = "";
    if (chain.platform === "twitter" && chain.type === "post_comment") {
        postBody = (
            <div style={style_twitterPost_container}>
                <Icon type="twitter" style={style_twitterPost_icon} />
                <div style={style_twitterPost_handle}>
                    {chain.ourUser.screen_name}
                </div>
                {chain.extra && chain.extra.data && chain.extra.data[0].text}
                <div style={style_twitterPost_datetime}>
                    {moment(
                        chain.extra &&
                            chain.extra.data &&
                            chain.extra.data[0].created_at
                    ).format("lll")}
                    <br />
                    {moment(
                        chain.extra &&
                            chain.extra.data &&
                            chain.extra.data[0].created_at
                    )
                        .local()
                        .fromNow()}
                </div>
                <br />
                <a
                    href={`https://twitter.com/${
                        chain.ourUser.screen_name
                    }/status/${
                        chain.extra &&
                        chain.extra.data &&
                        chain.extra.data[0].id
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button
                        type="primary"
                        icon="twitter"
                        style={style_twitterPost_openButton}
                    >
                        Open in Twitter
                    </Button>
                </a>
            </div>
        );
    }

    if (chain.platform === "facebook" && chain.type === "post_comment") {
        let extraStyle = null,
            aspectRatio = 1;
        if (chain.extra.post_attachments_result) {
            let width =
                chain.extra.post_attachments_result.data[0].media.image.width;
            let height =
                chain.extra.post_attachments_result.data[0].media.image.height;
            aspectRatio = width / height;
            extraStyle = {
                paddingLeft: 310,
                minHeight: Number(260 / aspectRatio) + 10,
            };
        }

        postBody = (
            <div style={{ ...style_facebookPost_container, ...extraStyle }}>
                {chain.extra.post_attachments_result ? (
                    <img
                        src={
                            chain.extra.post_attachments_result.data[0].media
                                .image.src
                        }
                        style={style_facebookPost_Image}
                        alt="Attachment"
                    />
                ) : null}

                <div
                    className="social_iconFacebook"
                    style={style_facebookPost_icon}
                />

                <div style={style_facebookPost_handle}>
                    {chain.extra.page.name}
                </div>

                {chain.extra.post_result.message}
                <div style={style_facebookPost_datetime}>
                    {moment(chain.extra.post_result.created_time).format("lll")}
                    <br />
                    {moment(chain.extra.post_result.created_time)
                        .local()
                        .fromNow()}
                </div>
                <br />
                <a
                    href={chain.extra.post.permalink_url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button style={style_facebookPost_openButton}>
                        <div
                            className="social_iconFacebook"
                            style={{
                                width: 17,
                                height: 17,
                                float: "left",
                                marginRight: 5,
                                backgroundSize: 16,
                                marginTop: 1,
                            }}
                        />
                        Open in Facebook
                    </Button>
                </a>
            </div>
        );
    }

    if (chain.platform === "instagram" && chain.type === "post_comment") {
        postBody = (
            <div style={style_instagramPost_container}>
                <div>
                    {chain.extra.media ? (
                        <img
                            src={chain.extra.media.media_url}
                            style={style_instagramPost_Image}
                            alt="media"
                        />
                    ) : null}
                </div>
                <div style={{ paddingLeft: 40, paddingTop: 23 }}>
                    <Icon
                        type="instagram"
                        theme="filled"
                        style={style_instagramPost_icon}
                    />

                    <div style={style_instagramPost_handle}>
                        {chain.ourUser.instagram.username}
                    </div>

                    {chain.extra.comment.text}
                    <div style={style_instagramPost_datetime}>
                        {moment(chain.extra.media.timestamp).format("lll")}
                        <br />
                        {moment(chain.extra.media.timestamp).local().fromNow()}
                    </div>
                    <br />
                    <a
                        href={chain.extra.media.permalink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button style={style_instagramPost_openButton}>
                            <Icon
                                type="instagram"
                                theme="filled"
                                style={{
                                    width: 17,
                                    height: 17,
                                    float: "left",
                                    marginRight: 5,
                                    backgroundSize: 16,
                                    marginTop: 3,
                                    color: "white",
                                }}
                            />
                            Open in Instagram
                        </Button>
                    </a>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {/* <h3>InboxChainPost</h3> */}
            {notPostedBySocial}
            {postBody}
        </Fragment>
    );
};

export default InboxChainPost;
