import React, { Component, Fragment } from "react";
import {
    Empty,
    Row,
    Col,
    List,
    Typography,
    Button,
    Menu,
    Dropdown,
    Icon,
    Checkbox,
    Tooltip,
    Divider,
    Modal,
    Comment,
    Input,
    Badge,
    notification,
} from "antd";
import moment from "moment";
import { observer } from "mobx-react";
// import SocialPreview from "./../SocialPreview";
// import SocialSeparator from "./../SocialSeparator";
import NotificationEdit from "./NotificationEdit";
import NotificationInfo from "./NotificationInfo";
import SocialTag from "./../SocialTag";
import MasterpostsListModalNew from "./../Masterposts/MasterpostsListModalNew";

import { social_notification, social_sleep } from "../../functions";

const { Text } = Typography;
const { TextArea, Search } = Input;
const { confirm } = Modal;

class Notifications extends Component {
    markChainAsReadTimeout = null;

    constructor(props) {
        super(props);
        this.state = {
            selected_notifications: [],
            selected: null,
            edit_confirmLoading: false,
            edit_data: {},
            addNoteText: "",
            filter: "",
            currentMasterpost: {},
            ready: false,
            notifications: [],
        };

        this.masterpostsModal = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("notifications");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Notifications" }]);
    }

    componentDidMount = async () => {
        await social_sleep(100);
        this.loadInformation();
    };

    loadInformation = () => {
        let notifications = this.getNotifications();
        this.setState({
            notifications,
            ready: true,
        });
    };

    componentWillUnmount() {
        //base.removeBinding(this.infoRef);
        this.props.store.unregisterModalListener("inbox_modal");
    }

    getNotifications = () => {
        const notifications = this.props.store.getAllNotifications.filter(
            (n) => {
                //if (n.status == "new") return n;
                return n;
            }
        );
        console.info("getNotifications notifications", notifications);
        return notifications;
    };
    getTagColorForNotificationType = (_notification_type) => {
        let answer = "blue";
        switch (_notification_type) {
            case "error":
                answer = this.props.store.getTagColorForType("error");
                break;
            case "message":
                answer = this.props.store.getTagColorForType("default");
                break;
            case "approval":
                answer = this.props.store.getTagColorForType("warning");
                break;
            default:
                answer = this.props.store.getTagColorForType("default");
                break;
        }
        return answer;
    };
    onCheckChange = (_key, _evt) => {
        console.log(`onCheckChange checked = ${_evt.target.checked}`);
        console.info(`onCheckChange _key`, _key);

        let selected = this.state.selected_notifications;
        if (_evt.target.checked) {
            // add to the selected
            selected.push(_key);
        } else {
            // remove
            selected = selected.filter((s) => s !== _key);
        }
        this.setState({ selected_notifications: selected });
    };
    handleSelectAll = () => {
        const notifications = this.getNotifications();
        let selected_notifications = [];
        notifications.map((n) => {
            selected_notifications.push(n.key);
            return false;
        });
        this.setState({ selected_notifications });
    };
    handleDiscardSelection = () => {
        this.setState({ selected_notifications: [] });
    };
    handleMenuClick = (_evt, _notification_key) => {
        console.log(
            "handleMenuClick _evt",
            _evt,
            "_notification_key",
            _notification_key
        );
        if (_evt.key === "select_all") this.handleSelectAll();
        if (_evt.key === "discard_selection") this.handleDiscardSelection();
        if (_evt.key === "delete") this.handleDelete(_notification_key);
        if (_evt.key === "mark_as_read")
            this.handleChangeStatusTo(_notification_key, "read");
        if (_evt.key === "mark_as_new")
            this.handleChangeStatusTo(_notification_key, "new");
        if (_evt.key === "edit") this.handleEdit(_notification_key);
        if (_evt.key === "delete_selection") this.handleDeleteSelection();
    };
    handleDeleteSelection = async () => {
        console.info(
            "handleDeleteSelection selected_notifications",
            this.state.selected_notifications
        );
        const keysToDelete = JSON.parse(
            JSON.stringify(this.state.selected_notifications)
        );
        console.info("handleDeleteSelection keysToDelete", keysToDelete);

        const key = "progress_notification";

        social_notification({
            type: "info",
            key,
            duration: 0,
            title: "Deleting ...",
            description: "Please wait while we delete the notifications",
            icon: <Icon type="loading" />,
        });

        await keysToDelete.reduce(async (previousPromise, nextKey) => {
            await previousPromise;
            return this.props.store.deleteNotification(nextKey);
        }, Promise.resolve());

        // Close the notification
        notification.close(key);

        this.setState({ selected_notifications: [], selected: null });
    };
    handleDelete = async (_key) => {
        console.info("handleDelete _key", _key);

        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk: async () => {
                await that.props.store.deleteNotification(_key);
                that.setState({ selected: null });
            },
        });
    };
    handleEdit = (_key) => {
        console.info("handleEdit _key", _key);

        let curNotification = this.props.store.getAllNotifications.filter(
            (n) => {
                if (n.key === _key) return n;
                return false;
            }
        )[0];

        if (curNotification.related_to) {
            console.log("WE HAVE RELATED NOTIFICATION");
            this.props.store
                .getNotificationRelatedElement(curNotification.key)
                .then((relatedInformation) => {
                    curNotification.related_element =
                        relatedInformation.related_object;
                    this.setState({ edit_data: curNotification });
                    this.showEdit();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.info(
                "NOOOOO RELATED NOTIFICATION curNotification",
                curNotification
            );
            this.setState({ edit_data: curNotification });
            this.showEdit();
        }
    };
    handleChangeStatusTo = (_key, _new_status) => {
        let curNotification = this.props.store.getAllNotifications.filter(
            (n) => {
                if (n.key === _key) return n;
                return false;
            }
        )[0];
        curNotification.status = _new_status;
        curNotification.status_changed_on = moment().utc();
        this.props.store.updateNotification(_key, curNotification);
    };
    handleClickOnNotifications = (_notification) => {
        console.info("handleClickOnNotifications _notification", _notification);
        // get related information
        if (
            _notification.related_to &&
            this.state.selected_notifications.length < 2
        ) {
            this.props.store
                .getNotificationRelatedElement(_notification.key)
                .then((relatedInformation) => {
                    console.info("relatedInformation", relatedInformation);
                    let selected = this.state.selected;
                    selected.related_element =
                        relatedInformation.related_object;
                    this.setState({ selected });

                    // return this.props.store.addNoteNotification(
                    //     _notification.key
                    // );
                })
                .then((notificationNotes) => {
                    // something
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        clearTimeout(this.markChainAsReadTimeout);
        this.markChainAsReadTimeout = setTimeout(
            function (that) {
                that.handleMenuClick(
                    { key: "mark_as_read" },
                    _notification.key
                );
            },
            5000,
            this
        );

        // Anything else
        this.setState({ selected: _notification });
    };
    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };
    showEdit = () => {
        console.log("showEdit");
        this.setState({
            //edit_visible: true,
            //edit_confirmLoading: false
        });

        this.props.store.showModalByKey("inbox_modal");
    };
    showRelatedMasterpost = (_key) => {
        this.setState({
            currentMasterpost: { key: _key },
        });

        this.props.store.showModalByKey("masterposts_modal_new", {
            // target_shortid: _target.shortid,
            // target_index: _index,
        });
    };
    handleEditCancel = () => {
        this.setState({ edit_visible: false });
    };
    handleAddNote = () => {
        console.info(
            "handleAddNote this.state.addNoteText",
            this.state.addNoteText
        );
        if (this.state.addNoteText.length > 0) {
            this.props.store
                .addNoteNotification(
                    this.state.selected.key,
                    this.state.addNoteText
                )
                .then((updatedNotificationData) => {
                    // something
                    console.info(
                        "updatedNotificationData",
                        updatedNotificationData
                    );
                    this.setState({
                        selected: updatedNotificationData,
                        addNoteText: "",
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };
    handleApprove = (item) => {
        console.info("handleApprove item", item);

        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk: async () => {
                if (that.state.selected.type === "approval") {
                    that.props.store
                        .approveAccountQueuePost(
                            that.state.selected.related_to.account_key,
                            that.state.selected.related_to.queue_index
                        )
                        .then((result) => {
                            console.info("result", result);
                            notification.success({
                                message: "Approved",
                                description:
                                    "The post was approved and will be published accordingly.",
                            });
                        });
                }
            },
        });
    };

    onInboxSearchChange = (_filter) => {
        //console.info("onAccountsSearchChange _filter", _filter);
        this.setState({ filter: _filter });
    };

    render() {
        // const loggedUser = this.props.store.getLoggedUser;
        //console.info("loggedUser", loggedUser);

        // if (this.state.selected) {
        //     console.info(
        //         "this.state.selected.notes",
        //         this.state.selected.notes
        //     );
        // }

        let notifications = this.state.notifications.filter((n) => {
            if (this.state.filter.length > 0) {
                if (
                    n.status
                        .toUpperCase()
                        .indexOf(this.state.filter.toUpperCase()) !== -1 ||
                    n.description
                        .toUpperCase()
                        .indexOf(this.state.filter.toUpperCase()) !== -1 ||
                    n.title
                        .toUpperCase()
                        .indexOf(this.state.filter.toUpperCase()) !== -1 ||
                    n.type
                        .toUpperCase()
                        .indexOf(this.state.filter.toUpperCase()) !== -1
                ) {
                    return n;
                }
            } else {
                return n;
            }
            return false;
        });
        // .filter(
        //     (n) =>
        //         n.recipients &&
        //         (n.recipients.indexOf("all") !== -1 ||
        //             n.recipients === loggedUser.uid)
        // );

        if (this.state.selectedChainKey === "notifications_error")
            notifications = notifications.filter(
                (item) => item.type === "error"
            );
        if (this.state.selectedChainKey === "notifications_normal")
            notifications = notifications.filter(
                (item) => item.type !== "error"
            );
        notifications = notifications.sort((a, b) =>
            a.datetime < b.datetime ? 1 : -1
        );

        console.info("notifications", notifications);

        const menu = (_notification) => {
            return (
                <Menu
                    onClick={(e) => this.handleMenuClick(e, _notification.key)}
                >
                    {_notification.status === "new" ? (
                        <Menu.Item key="mark_as_read">
                            <Icon type="check" /> Mark as read
                        </Menu.Item>
                    ) : (
                        <Menu.Item key="mark_as_new">
                            <Icon type="check" /> Mark as new
                        </Menu.Item>
                    )}
                    <Menu.Item key="edit">
                        <Icon type="edit" /> Edit
                    </Menu.Item>
                    <Menu.Item key="delete">
                        <Icon type="delete" /> Delete
                    </Menu.Item>
                </Menu>
            );
        };
        const menu_selection = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="discard_selection">
                    <Icon type="check" /> Discard Selection
                </Menu.Item>
                <Menu.Item key="delete_selection">
                    <Icon type="delete" /> Delete Selection
                </Menu.Item>
            </Menu>
        );
        const menu_noselection = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="select_all">
                    <Icon type="check" /> Select all
                </Menu.Item>
            </Menu>
        );

        const notificationNotes = this.state.selected ? (
            <Fragment>
                <TextArea
                    rows={3}
                    value={this.state.addNoteText}
                    onChange={(e) =>
                        this.setState({
                            addNoteText: e.target.value,
                        })
                    }
                    onPressEnter={(e) =>
                        this.setState({
                            addNoteText: e.target.value,
                        })
                    }
                />
                <Button
                    style={{
                        float: "right",
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                    type="primary"
                    ghost
                    disabled={this.state.addNoteText.length < 2}
                    onClick={(e) => this.handleAddNote()}
                >
                    Add Note
                </Button>
                <div style={{ clear: "both" }}></div>
                {this.state.selected.notes &&
                    this.state.selected.notes.length > 0 &&
                    this.state.selected.notes
                        // .sort((a, b) => (a.datetime < b.datetime ? 1 : -1))
                        .map((note, noteIndex) => {
                            if (note && note.note && note.note.length > 0) {
                                return (
                                    <Comment
                                        key={noteIndex}
                                        author={note.author.displayName}
                                        content={
                                            <div
                                                style={{
                                                    whiteSpace: "break-spaces",
                                                }}
                                            >
                                                {note.note}
                                            </div>
                                        }
                                        datetime={
                                            <Tooltip
                                                title={moment(note.datetime)
                                                    .utc()
                                                    .local()
                                                    .format("LLLL")}
                                            >
                                                <span>
                                                    {moment(note.datetime)
                                                        .utc()
                                                        .local()
                                                        .fromNow()}
                                                </span>
                                            </Tooltip>
                                        }
                                    />
                                );
                            } else {
                                return false;
                            }
                        })}
            </Fragment>
        ) : null;

        const notificationsList = (
            <Fragment>
                <Search
                    placeholder="filter"
                    onSearch={this.onInboxSearchChange}
                    onChange={(e) => this.onInboxSearchChange(e.target.value)}
                />
                {notifications.length === 0 ? (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No mathching data"
                    />
                ) : (
                    <List
                        style={{
                            borderBottom: "1px solid #e8e8e8",
                        }}
                        className={"social_NotificationsList"}
                        header={
                            this.state.selected_notifications.length > 0 ? (
                                <Fragment>
                                    {this.state.selected_notifications.length}{" "}
                                    notification(s)
                                    <Dropdown overlay={menu_selection}>
                                        <Icon
                                            type="more"
                                            style={{
                                                float: "right",
                                                padding: 3,
                                            }}
                                        />
                                    </Dropdown>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    no selection
                                    <Dropdown overlay={menu_noselection}>
                                        <Icon
                                            type="more"
                                            style={{
                                                float: "right",
                                                padding: 3,
                                                color: "#333",
                                            }}
                                        />
                                    </Dropdown>
                                </Fragment>
                            )
                        }
                        // footer={<div>Footer</div>}
                        size="small"
                        dataSource={notifications}
                        renderItem={(item, itemIndex) => (
                            <List.Item
                                key={itemIndex}
                                onClick={(e) =>
                                    this.handleClickOnNotifications(item)
                                }
                                className={
                                    this.state.selected &&
                                    item.key === this.state.selected.key
                                        ? "social_selected"
                                        : null
                                }
                            >
                                <div>
                                    <div>
                                        <div
                                            style={{
                                                color:
                                                    item.status === "new"
                                                        ? "#333"
                                                        : null,
                                                fontWeight:
                                                    item.status === "new"
                                                        ? "bold"
                                                        : null,
                                            }}
                                        >
                                            <Checkbox
                                                onChange={(e) =>
                                                    this.onCheckChange(
                                                        item.key,
                                                        e
                                                    )
                                                }
                                                style={{
                                                    marginRight: 10,
                                                }}
                                                checked={
                                                    this.state.selected_notifications.indexOf(
                                                        item.key
                                                    ) !== -1
                                                }
                                            ></Checkbox>
                                            {item.title}
                                        </div>
                                        <div>
                                            <Dropdown overlay={menu(item)}>
                                                <Icon
                                                    type="more"
                                                    style={{
                                                        float: "right",
                                                        padding: 3,
                                                    }}
                                                />
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                {/* <div
                                    style={{
                                        width: "100%",
                                        paddingTop: 4,
                                    }}
                                >
                                    <div
                                        style={{
                                            maxWidth: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            color: "#999",
                                            display: "inline-block",
                                        }}
                                    >
                                        {item.description}
                                    </div>
                                </div> */}
                                <div style={{ paddingTop: 4 }}>
                                    <SocialTag
                                        key={item.type}
                                        store={this.props.store}
                                        color={item.type}
                                    >
                                        {item.type}
                                    </SocialTag>

                                    {item.related_to ? (
                                        <Icon
                                            type="file-text"
                                            style={{
                                                color: "#999",
                                                marginRight: 10,
                                            }}
                                        />
                                    ) : null}
                                    {item.status === "read" ? (
                                        <Tooltip
                                            title={
                                                "Read on " +
                                                moment()
                                                    .utc(item.status_changed_on)
                                                    .format("LLLL")
                                            }
                                        >
                                            <Icon
                                                type="check-circle"
                                                style={{
                                                    color: "#999",
                                                }}
                                            />
                                        </Tooltip>
                                    ) : null}
                                    <div>
                                        {moment(item.datetime)
                                            .local()
                                            .format("lll")}
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                )}
            </Fragment>
        );

        const content = (
            <Fragment>
                {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                <Row gutter={44} style={{ marginLeft: -40 }}>
                    <Col
                        span={8}
                        style={{
                            height: "calc(100vh - 210px)",
                            overflowY: "auto",
                        }}
                    >
                        {notificationsList}
                    </Col>

                    <Col
                        span={16}
                        style={{
                            textAlign: "left",
                            height: "calc(100vh - 210px)",
                            borderLeft: "1px solid #ccc",
                            overflowY: "auto",
                        }}
                    >
                        <Fragment>
                            {this.state.selected &&
                            this.state.selected &&
                            this.state.selected_notifications.length < 2 ? (
                                <Fragment>
                                    <NotificationInfo
                                        store={this.props.store}
                                        notification={this.state.selected}
                                        tag_color={this.getTagColorForNotificationType(
                                            this.state.selected.type
                                        )}
                                        handleOpenRelated={
                                            this.showRelatedMasterpost
                                        }
                                    />

                                    <Row
                                        style={{
                                            paddingTop: 10,
                                        }}
                                    >
                                        <Col>
                                            {this.state.selected.type !==
                                            "approval" ? (
                                                <Tooltip title="Edit the notification">
                                                    <Button
                                                        style={{
                                                            marginLeft: 10,
                                                            float: "right",
                                                        }}
                                                        icon="edit"
                                                        // size="small"
                                                        onClick={(e) =>
                                                            this.handleEdit(
                                                                this.state
                                                                    .selected
                                                                    .key
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                </Tooltip>
                                            ) : null}
                                        </Col>
                                        <Col>
                                            <Tooltip title="Delete the notification">
                                                <Button
                                                    style={{
                                                        marginLeft: 10,
                                                        float: "right",
                                                    }}
                                                    onClick={(e) => {
                                                        this.handleDelete(
                                                            this.state.selected
                                                                .key
                                                        );
                                                    }}
                                                    type="danger"
                                                    ghost
                                                    // size="small"
                                                    icon="delete"
                                                >
                                                    Delete
                                                </Button>
                                            </Tooltip>

                                            {this.state.selected.type ===
                                            "approval" ? (
                                                <Button
                                                    style={{
                                                        float: "left",
                                                    }}
                                                    // size="small"
                                                    type="primary"
                                                    icon="check"
                                                    onClick={(e) =>
                                                        this.handleApprove(
                                                            this.state.selected
                                                        )
                                                    }
                                                >
                                                    Approve
                                                </Button>
                                            ) : null}
                                        </Col>
                                    </Row>

                                    {/*                                     
                                    {notificationInfo} */}
                                    <br />

                                    <Text
                                        style={{
                                            marginBottom: 5,
                                            display: "block",
                                        }}
                                    >
                                        Notes&nbsp;
                                        <Badge
                                            count={
                                                this.state.selected.notes
                                                    ? this.state.selected.notes
                                                          .length
                                                    : 0
                                            }
                                            showZero
                                            style={{
                                                backgroundColor: "#1890ff",
                                            }}
                                        />
                                    </Text>

                                    {notificationNotes}
                                </Fragment>
                            ) : this.state.selected_notifications.length <=
                              1 ? (
                                <Empty description="Select Notification message on the left" />
                            ) : (
                                <Fragment>
                                    <h1>
                                        Multiple selections (
                                        {
                                            this.state.selected_notifications
                                                .length
                                        }
                                        )
                                    </h1>
                                    <Divider />
                                    <Button style={{ marginRight: 10 }}>
                                        Multiple Delete
                                    </Button>
                                    {/* <Button style={{ marginRight: 10 }}>
                Multiple Action 2
            </Button> */}
                                </Fragment>
                            )}
                        </Fragment>
                    </Col>
                </Row>

                <NotificationEdit
                    //onCancel={this.handleEditCancel}
                    // onCreate={this.handleModalCreate}
                    //onEdit={this.handleEditEdit}
                    store={this.props.store}
                    data={this.state.edit_data}
                    account={this.state.account}
                    //confirmLoading={this.state.edit_confirmLoading}
                />

                <MasterpostsListModalNew
                    store={this.props.store}
                    data={this.state.currentMasterpost}
                    ref={this.masterpostsModal}
                />
            </Fragment>
        );

        return this.state.ready === false ? (
            <div
                style={{
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Icon
                    type="loading"
                    style={{ fontSize: "3rem", margin: 10 }}
                    spin
                />
                Processing ...
            </div>
        ) : (
            content
        );
    }
}

export default observer(Notifications);
