import React, { Component, Fragment } from "react";
import { Modal, Button, Alert, Icon } from "antd";
import { observer } from "mobx-react";

import { social_notification } from "./../../functions";

import ModalForm from "./AdsListModalForm";

/* Wrapper component */
class AdsListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            step: 0,
            // result
            resultStatus: "info",
            resultIcon: <Icon type="loading" />,
            resultText: "Processing now ..., please wait.",
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "ads_modal",
            (_action, _payload) => {
                if (!_payload) _payload = { type: "ad" };
                if (_payload) {
                    this.setState({ data: _payload });
                } else {
                    this.setState({ data: {} });
                }
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                    step: 0,
                });
                this.parseOpenInformation(_payload);
                if (typeof this.props.callback === "function")
                    this.props.callback(_action);
            }
        );
    };

    parseOpenInformation = (_data) => {
        console.info("parseOpenInformation > DATA", _data);

        if (_data && _data.type === "ad") {
            this.setState({
                steps: [
                    { key: "start", title: "Start" },
                    { key: "ad_networks", title: "Networks" },
                    { key: "ad_basic", title: "Parameters" },
                    { key: "ad_confirm", title: "Confirm" },
                    { key: "result", title: "Result" },
                ],
            });
        }
        if (_data && _data.type === "fb_boost") {
            this.setState({
                steps: [
                    { key: "start", title: "Start" },
                    { key: "fb_boost_basic", title: "Parameters" },
                    { key: "fb_boost_targeting", title: "Targeting" },
                    { key: "fb_boost_details", title: "Details" },
                    { key: "fb_boost_confirm", title: "Confirm" },
                    { key: "result", title: "Result" },
                ],
            });
        }
    };
    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("ads_modal");
    };

    toDataURL = async (url) => {
        return new Promise(async (resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    //callback(reader.result);
                    var base64result = reader.result.split(",")[1];
                    resolve(base64result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        });
    };

    onModalCreate = async () => {
        //const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("formState", formState);

        if (typeof formState.brand === "undefined") formState.brand = "";
        if (typeof formState.campaigns === "undefined")
            formState.campaigns = [];

        //remove selection of options in linkedin_targeting
        Object.keys(formState.linkedin_targeting)
            .filter((key) => key.indexOf("selected_") === -1)
            .map((key) => {
                delete formState.linkedin_targeting[key];
                return true;
            });

        let ads = [];

        // targeting
        let targeting = {};
        let targeting_info = {};

        if (formState.facebook_targeting.selected_overwrite_gender) {
            targeting.gender = formState.facebook_targeting.selected_gender;
            targeting_info.gender =
                formState.facebook_targeting.selected_gender;
        }
        if (formState.facebook_targeting.selected_overwrite_age) {
            targeting.age = formState.facebook_targeting.selected_age;
            targeting_info.age = formState.facebook_targeting.selected_age;
        }
        if (formState.facebook_targeting.selected_overwrite_country) {
            targeting.country = formState.facebook_targeting.selected_country.map(
                (country) => country.key
            );
            targeting_info.country =
                formState.facebook_targeting.selected_country;
        }
        if (formState.facebook_targeting.selected_overwrite_region) {
            targeting.region = formState.facebook_targeting.selected_region.map(
                (region) => region.key
            );
            targeting_info.region =
                formState.facebook_targeting.selected_region;
        }
        if (formState.facebook_targeting.selected_overwrite_city) {
            targeting.city = formState.facebook_targeting.selected_city.map(
                (city) => city.key
            );
            targeting_info.city = formState.facebook_targeting.selected_city;
        }
        if (formState.facebook_targeting.selected_overwrite_zip) {
            targeting.zip = formState.facebook_targeting.selected_zip.map(
                (zip) => zip.key
            );
            targeting_info.zip = formState.facebook_targeting.selected_zip;
        }
        if (formState.facebook_targeting.selected_overwrite_language) {
            targeting.language = formState.facebook_targeting.selected_language.map(
                (language) => language.key
            );
            targeting_info.language =
                formState.facebook_targeting.selected_language;
        }
        if (formState.facebook_targeting.selected_overwrite_interest) {
            targeting.interest = formState.facebook_targeting.selected_interest;
            targeting_info.interest =
                formState.facebook_targeting.selected_interest;
        }
        console.info("targeting", targeting, "targeting_info", targeting_info);

        console.log("formState.ad_scope = " + formState.ad_scope);

        // Facebook Boost
        if (
            formState.networks_facebook === true &&
            formState.ad_scope === "existing"
        ) {
            const all_masterposts = this.formRef.getTreeData();
            if (!all_masterposts) return;
            console.info("all_masterposts", all_masterposts);

            if (!formState.masterposts) {
                social_notification("validation_error", "error", {
                    type: "warning",
                    description: "Please check at least one post to boost.",
                });
                this.setState({ step: 1 });
                // ----------------------------------------------
                //return false;

                this.setState({
                    processing: false,
                    resultStatus: "error",
                    resultIcon: null,
                    resultText: "Please check at least one post to boost.",
                });
                return false;
            }

            all_masterposts.map((masterpost) => {
                masterpost.children.map((child) => {
                    if (formState.masterposts.indexOf(child.key) !== -1) {
                        let ad_object = {
                            account_key: child.account_key,
                            post_id: child.key,
                            masterpost_key: masterpost.key,
                            campaign_name: formState.ad_name,
                            adset_name: formState.ad_name,
                            ad_name: formState.ad_name,
                            objective: formState.facebook_details.objective,
                            status: formState.facebook_details.status,
                            optimization_goal:
                                formState.facebook_details.optimization_goal,
                            billing_event:
                                formState.facebook_details.billing_event,
                            bid_amount: formState.facebook_details.bid_amount,
                            daily_budget:
                                formState.facebook_details.daily_budget,
                            start_time: formState.facebook_details.start_time,
                            end_time: formState.facebook_details.end_time,
                            brand: formState.brand,
                            campaigns: formState.campaigns,
                            targeting: targeting,
                            targeting_info: targeting_info,
                            ad_scope: formState.ad_scope,
                        };
                        ads.push(ad_object);
                    }
                    return false;
                });

                return false;
            });
        }

        // Facebook Ad
        if (
            formState.networks_facebook === true &&
            formState.ad_scope === "ad"
        ) {
            console.info("newad_accounts", formState.newad_accounts);
            if (!formState.newad_accounts) {
                social_notification("validation_error", "error", {
                    type: "warning",
                    description:
                        "Please check at least one account to post the ad from.",
                });
                this.setState({ step: 1 });
                // ----------------------------------------------
                return false;

                // this.setState({
                //     processing: false,
                //     resultStatus: "error",
                //     resultIcon: null,
                //     resultText:
                //         "Please check at least one account to post the ad from.",
                // });
                // return false;
            }

            formState.newad_accounts
                .filter((key) => key.indexOf("|") !== -1)
                .map((ad_accounts_key) => {
                    var [ad_account_key, ad_channel_id] = ad_accounts_key.split(
                        "|"
                    );

                    let ad_object = {};

                    switch (formState.newad_type) {
                        case "singleimagead":
                            ad_object = {
                                network: "facebook",
                                type: formState.newad_type,
                                account_key: ad_account_key,
                                channel_id: ad_channel_id,
                                campaign_name: formState.ad_name,
                                adset_name: formState.ad_name,
                                ad_name: formState.ad_name,
                                objective: formState.facebook_details.objective,
                                status: formState.facebook_details.status,
                                optimization_goal:
                                    formState.facebook_details
                                        .optimization_goal,
                                billing_event:
                                    formState.facebook_details.billing_event,
                                bid_amount:
                                    formState.facebook_details.bid_amount,
                                daily_budget:
                                    formState.facebook_details.daily_budget,
                                start_time:
                                    formState.facebook_details.start_time,
                                end_time: formState.facebook_details.end_time,
                                brand: formState.brand,
                                campaigns: formState.campaigns,
                                targeting: targeting,
                                targeting_info: targeting_info,
                                image: formState.newad_image,
                                object_url: formState.newad_url,
                                title: formState.newad_title,
                                body: formState.newad_body,
                                cta_type: formState.newad_calltoactiontype,
                                ad_scope: formState.ad_scope,
                            };
                            break;

                        case "carouselad":
                            ad_object = {
                                network: "facebook",
                                type: formState.newad_type,
                                account_key: ad_account_key,
                                channel_id: ad_channel_id,
                                campaign_name: formState.ad_name,
                                adset_name: formState.ad_name,
                                ad_name: formState.ad_name,
                                objective: formState.facebook_details.objective,
                                status: formState.facebook_details.status,
                                optimization_goal:
                                    formState.facebook_details
                                        .optimization_goal,
                                billing_event:
                                    formState.facebook_details.billing_event,
                                bid_amount:
                                    formState.facebook_details.bid_amount,
                                daily_budget:
                                    formState.facebook_details.daily_budget,
                                start_time:
                                    formState.facebook_details.start_time,
                                end_time: formState.facebook_details.end_time,
                                brand: formState.brand,
                                campaigns: formState.campaigns,
                                targeting: targeting,
                                targeting_info: targeting_info,
                                ad_scope: formState.ad_scope,
                                carousel: formState.carousel,
                            };
                            break;
                        default:
                            break;
                    }

                    ads.push(ad_object);
                    return false;
                });

            // // Parse images and get their data
            // await Promise.all(
            //     ads.map(async (ad) => {
            //         if (ad.type === "singleimagead") {
            //             ad.image_bytes = ad.image_data.split(",")[1];
            //             // delete image data (no need to send to the backed)
            //             delete ad.image_data;
            //         }
            //         if (ad.type === "carouselad") {
            //             ad.carousel.map((adCarousel) => {
            //                 adCarousel.image_bytes = adCarousel.image_data.split(
            //                     ","
            //                 )[1];
            //                 delete adCarousel.image_data;
            //                 delete adCarousel.previewURL;
            //                 return false;
            //             });
            //         }

            //         //console.info('image bytes ad.image_bytes',  ad.image_bytes);
            //     })
            // );
            // // End of parsing
        }

        // Linkedin Ad
        if (formState.networks_linkedin === true) {
            formState.newad_linkedin_accounts.map((account_key) => {
                let ad_object = {
                    network: "linkedin",
                    type: formState.linkedin_newad_type,
                    costtype: formState.linkedin_newad_costtype,
                    totalbudget: formState.linkedin_newad_totalbudget,
                    overwrite_dailybudget:
                        formState.linkedin_newad_overwrite_dailybudget,
                    dailybudget: formState.linkedin_newad_dailybudget,
                    start_time: formState.linkedin_newad_start_time,
                    end_time: formState.linkedin_newad_end_time,
                    objectivetype: formState.linkedin_newad_objectivetype,
                    unitcostamount: formState.linkedin_newad_unitCostamount,
                    status: formState.linkedin_newad_status,

                    targeting: formState.linkedin_targeting,
                    ad_name: formState.ad_name,
                    body: formState.newad_body,
                    title: formState.newad_title,
                    url: formState.newad_url,
                    account_key: account_key,
                    // previewURL: formState.previewURL,
                    image: formState.newad_image,
                };

                ads.push(ad_object);
                return false;
            });
        }

        // Twitter Ad
        if (formState.networks_twitter === true) {
            formState.newad_twitter_accounts.map((account_key) => {
                console.info("FORMSTATE", formState);
                let ad_object = {
                    network: "twitter",
                    ad_name: formState.ad_name,
                    // type: formState.linkedin_newad_type,
                    // costtype: formState.linkedin_newad_costtype,
                    // totalbudget: formState.linkedin_newad_totalbudget,
                    // overwrite_dailybudget:
                    //     formState.linkedin_newad_overwrite_dailybudget,
                    dailybudget: formState.twitter_newad_dailybudget,
                    start_time: formState.twitter_newad_start_time,
                    end_time: formState.twitter_newad_end_time,
                    objectivetype: formState.twitter_newad_objectivetype,
                    placements: formState.twitter_newad_placements,
                    producttype: formState.twitter_newad_producttype,
                    bidamount: formState.twitter_newad_bidamount,
                    // objectivetype: formState.linkedin_newad_objectivetype,
                    // unitcostamount: formState.linkedin_newad_unitCostamount,
                    // status: formState.linkedin_newad_status,

                    // targeting: formState.linkedin_targeting,
                    // ad_name: formState.ad_name,
                    // body: formState.newad_body,
                    // title: formState.newad_title,
                    // url: formState.newad_url,
                    account_key: account_key,
                    // previewURL: formState.previewURL,
                    image: formState.newad_image,
                };

                ads.push(ad_object);
                return false;
            });
        }

        // Parse images and get their data
        await Promise.all(
            ads.map(async (ad) => {
                if (ad.network === "facebook" && ad.type === "singleimagead") {
                    ad.image_bytes = ad.image.image_data.split(",")[1];
                    // delete image data (no need to send to the backed)
                    delete ad.image_data;
                }
                if (ad.network === "facebook" && ad.type === "carouselad") {
                    ad.carousel.map((adCarousel) => {
                        adCarousel.image_bytes = adCarousel.image_data.split(
                            ","
                        )[1];
                        delete adCarousel.image_data;
                        delete adCarousel.previewURL;
                        return false;
                    });
                }
                if (ad.network === "linkedin") {
                    console.info("Linkedin ad", ad);
                    if (ad.image) {
                        ad.image_bytes = ad.image.image_data.split(",")[1];
                        // delete image data (no need to send to the backed)
                        delete ad.image;
                    }
                }
                if (ad.network === "twitter") {
                    console.info("Twitter ad", ad);
                    if (ad.image) {
                        ad.image_bytes = ad.image.image_data.split(",")[1];
                        // delete image data (no need to send to the backed)
                        delete ad.image;
                    }
                }
                //console.info('image bytes ad.image_bytes',  ad.image_bytes);
            })
        );
        // End of parsing

        console.info("ads", ads);

        if (ads.length === 0) {
            this.setState({
                processing: false,
                resultStatus: "error",
                resultIcon: null,
                resultText:
                    "No ads were generated. Please check the settings and try again.",
            });
            return false;
        }

        this.setState({
            // processing: true,
            resultIcon: <Icon type="loading" />,
            resultStatus: "info",
            resultText: "Processing now ..., please wait.",
        });

        let stateToDuplicate = Object.assign({}, formState);
        delete stateToDuplicate.data;
        delete stateToDuplicate.newad_image; // TODO: Check to see if we can leave the image information in the stateToDuplicate

        try {
            let masterad = {
                name: formState.ad_name,
                campaigns: formState.campaigns,
                brand: formState.brand,
                ad_model: {
                    title: formState.newad_title,
                    url: formState.newad_url,
                    body: formState.newad_body,
                    image_bytes: formState.newad_image
                        ? formState.newad_image.image_data.split(",")[1]
                        : null,
                },
                state_to_duplicate: stateToDuplicate,
                ads: [],
                /*
                targeting: targeting,
                targeting_info: targeting_info,
                details: {
                    objective: formState.facebook_details.objective,
                    optimization_goal:
                        formState.facebook_details.optimization_goal,
                    billing_event: formState.facebook_details.billing_event,
                    bid_amount: formState.facebook_details.bid_amount,
                    daily_budget: formState.facebook_details.daily_budget,
                    start_time: formState.facebook_details.start_time,
                    end_time: formState.facebook_details.end_time,
                },
                */
            };

            // adding the ad object for Facebook
            if (
                formState.networks_facebook === true &&
                formState.ad_scope === "ad"
            ) {
                switch (formState.newad_type) {
                    case "singleimagead":
                        masterad.ads.push({
                            network: "facebook",
                            url: formState.newad_url,
                            title: formState.newad_title,
                            body: formState.newad_body,
                            targeting: targeting,
                            accounts: formState.newad_accounts.filter(
                                (key) => key.indexOf("|") !== -1
                            ),
                            type: formState.newad_type,
                            calltoactiontype: formState.newad_calltoactiontype,
                            // previewURL: formState.previewURL,
                            image_bytes: formState.newad_image.image_data.split(
                                ","
                            )[1],
                        });
                        break;
                    case "carouselad":
                        masterad.ads.push({
                            targeting: targeting,
                            network: "facebook",
                            accounts: formState.newad_accounts.filter(
                                (key) => key.indexOf("|") !== -1
                            ),
                            type: formState.newad_type,
                            carousel: formState.carousel,
                        });
                        break;
                    default:
                        break;
                }
            }

            // adding the ad object for Linkedin
            if (formState.networks_linkedin === true) {
                // newad_body: "Some description"
                // newad_calltoactiontype: "SHOP_NOW"
                // newad_linkedin_accounts: (2) ["-MCQlu4Iv_40UKeyf4Ht", "-M10UUvPfTJchqvmr0vx"]
                // newad_title: "Just a title"
                // newad_type: "singleimagead"
                // newad_url: "http://www.uhub.app"

                masterad.ads.push({
                    network: "linkedin",
                    type: formState.linkedin_newad_type,
                    costtype: formState.linkedin_newad_costtype,
                    totalbudget: formState.linkedin_newad_totalbudget,
                    overwrite_dailybudget:
                        formState.linkedin_newad_overwrite_dailybudget,
                    dailybudget: formState.linkedin_newad_dailybudget,
                    start_time: formState.linkedin_newad_start_time,
                    end_time: formState.linkedin_newad_end_time,
                    objectivetype: formState.linkedin_newad_objectivetype,
                    status: formState.linkedin_newad_status,
                    targeting: formState.linkedin_targeting,
                    body: formState.newad_body,
                    title: formState.newad_title,
                    url: formState.newad_url,
                    accounts: formState.newad_linkedin_accounts,
                    // previewURL: formState.previewURL,
                    image_bytes: formState.newad_image.image_data.split(",")[1],
                });
            }

            if (formState.networks_twitter === true) {
                // newad_body: "Some description"
                // newad_calltoactiontype: "SHOP_NOW"
                // newad_linkedin_accounts: (2) ["-MCQlu4Iv_40UKeyf4Ht", "-M10UUvPfTJchqvmr0vx"]
                // newad_title: "Just a title"
                // newad_type: "singleimagead"
                // newad_url: "http://www.uhub.app"

                formState.newad_twitter_accounts.map((_account_key) => {
                    masterad.ads.push({
                        network: "twitter",
                        ad_name: formState.ad_name,
                        // type: formState.linkedin_newad_type,
                        // costtype: formState.linkedin_newad_costtype,
                        // totalbudget: formState.linkedin_newad_totalbudget,
                        // overwrite_dailybudget:
                        //     formState.linkedin_newad_overwrite_dailybudget,
                        dailybudget: formState.twitter_newad_dailybudget,
                        start_time: formState.twitter_newad_start_time,
                        end_time: formState.twitter_newad_end_time,
                        objectivetype: formState.twitter_newad_objectivetype,
                        placements: formState.twitter_newad_placements,
                        producttype: formState.twitter_newad_producttype,
                        bidamount: formState.twitter_newad_bidamount,
                        // status: formState.linkedin_newad_status,
                        // targeting: formState.linkedin_targeting,
                        // body: formState.newad_body,
                        // title: formState.newad_title,
                        // url: formState.newad_url,
                        account: _account_key,
                        targeting: formState.twitter_targeting,
                        // previewURL: formState.previewURL,
                        image_bytes: formState.newad_image.image_data.split(
                            ","
                        )[1],
                    });
                    return true;
                });
            }

            // console.info("formState", formState);
            console.info("masterad", masterad);
            // return;

            let masterad_result = await this.props.store.addMasterad(masterad);
            let masterad_key = masterad_result.results;
            console.info("masterad_key", masterad_key);

            // apply masterad_key to all upcoming ads
            ads.map((ad) => (ad.masterad_key = masterad_key));

            let result = await Promise.all(
                ads.map((ad) => this.props.store.addAd(ad))
            );
            console.info("result", result);
            social_notification("publish_ad", "success");

            this.setState({
                resultStatus: "success",
                resultIcon: null,
                resultText: "The account was successfully created.",
            });
            //form.resetFields();
            //this.onModalCancel();
        } catch (err) {
            console.error(err);
            console.warn(err);

            this.setState({
                processing: false,
                resultStatus: "error",
                resultIcon: null,
                resultText: err.message ? err.message : "Something went wrong.",
            });
        }
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.props.data.key);

            this.props.store.updateCompetitor(this.props.data.key, values);

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        this.setState({ processing: false });
        this.props.store.hideModalByKey(
            "ads_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    onModalBack = () => {
        let { step } = this.state;
        step = step > 0 ? step - 1 : 0;
        this.setState({ step });
    };
    onModalNext = () => {
        // Make validation
        if (!this.validateForm()) return false;

        let { step } = this.state;
        step =
            step < this.state.steps.length ? step + 1 : this.state.steps.length;
        this.setState({ step });

        if (step === this.state.steps.length - 1) {
            this.onModalCreate();
        }
    };

    validateForm = () => {
        let formState = Object.assign({}, this.formRef.state);
        console.info("validateForm formState", formState);

        const currentStepKey = this.state.steps[this.state.step].key;

        // Ad Name
        if (this.state.step === 0 && formState.ad_name.length === 0) {
            social_notification("validation_error", "error", {
                type: "warning",
                description: "Please fill the Ad name field.",
            });
            this.setState({ step: 0 });
            return false;
        }

        // Step 2 --- Networks -------------------------------
        if (currentStepKey === "ad_networks") {
            if (
                !formState.networks_facebook &&
                !formState.networks_linkedin &&
                !formState.networks_twitter
            ) {
                social_notification("validation_error", "error", {
                    type: "warning",
                    description: "Please check at least one network.",
                });
                this.setState({ step: 1 });
                // ----------------------------------------------
                return false;
            }
        }

        /*
        // Step 3 --------------------------------------------
        if (this.state.step === 2 && formState.ad_scope === "existing") {
            const all_masterposts = this.formRef.getTreeData();
            if (!all_masterposts) return false;
            console.info("all_masterposts", all_masterposts);

            if (!formState.masterposts) {
                social_notification("validation_error", "error", {
                    type: "warning",
                    description: "Please check at least one post to boost.",
                });
                this.setState({ step: 2 });
                // ----------------------------------------------
                return false;
            }
        }

        if (this.state.step === 2 && formState.ad_scope === "ad") {
            if (!formState.newad_accounts) {
                social_notification("validation_error", "error", {
                    type: "warning",
                    description:
                        "Please check at least one account to post the ad from.",
                });
                this.setState({ step: 2 });
                // ----------------------------------------------
                return false;
            }
        }

        // Step 4 ------------------------------------------------
        if (
            this.state.step === 3 &&
            (formState.facebook_targeting.selected_country.length === 0 ||
                (!formState.facebook_targeting.selected_overwrite_country &&
                    formState.facebook_targeting.selected_overwrite_country ===
                        false))
        ) {
            social_notification("validation_error", "error", {
                type: "warning",
                description:
                    "Please at least one country in the location settings and check the requested fields",
            });
            this.setState({ step: 3 });
            // ----------------------------------------------
            return false;
        }
        */

        // If no errors return true
        return true;
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        let { data } = this.state;

        console.info("AdsListModal this.props", this.props);
        // console.info("AdsListModal this.state", this.state);
        // console.info("AdsListModal data", data);
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        centered
                        icon="form"
                        title={!data.name ? "Create new ad" : "Edit ad"}
                        okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        onOk={
                            !data.name ? this.onModalCreate : this.onModalEdit
                        } //onApply
                        maskClosable={false}
                        confirmLoading={this.state.processing}
                        // width="80vw"
                        style={{ minWidth: 1200 }}
                        footer={[
                            // <Fragment key="temp">
                            //     Step: {this.state.step}&nbsp;&nbsp;&nbsp;
                            // </Fragment>,
                            <Button key="cancel" onClick={this.onModalCancel}>
                                Close
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                icon="right"
                                style={{ float: "right", margin: "0 10px" }}
                                // disabled={
                                //     this.state.step ===
                                //     this.state.steps.length - 1
                                // }
                                // loading={this.state.processing}
                                onClick={this.onModalNext}
                            >
                                {this.state.step === this.state.steps.length - 2
                                    ? "Generate"
                                    : "Next"}
                            </Button>,
                            <Button
                                key="back"
                                onClick={this.onModalBack}
                                style={{ float: "right", margin: "0 10px" }}
                                icon="left"
                                // disabled={
                                //     this.state.step === 0 ||
                                //     (this.state.step ===
                                //         this.state.steps.length - 1 &&
                                //         this.state.processing)
                                // }
                            >
                                Back
                            </Button>,
                        ]}
                        className="AdsModal"
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={data}
                            step={this.state.step}
                            steps={this.state.steps}
                            type={this.state.data.type}
                            resultStatus={this.state.resultStatus}
                            resultIcon={this.state.resultIcon}
                            resultText={this.state.resultText}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AdsListModal);
