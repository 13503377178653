import React, { Component, Fragment } from "react";

import config from "./../../config";
import tooltips from "../../tooltips";

import { observer } from "mobx-react";
import prompt from "./../antd-prompt";

import {
    Empty,
    List,
    Button,
    Tag,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Modal,
    Icon,
    Checkbox,
} from "antd";

import CampaignsListModal from "./CampaignsListModal";
import CampaignsListModalNew from "./CampaignsListModalNew";
import CampaignsListModalPerformance from "./CampaignsListModalPerformance";
import scss_variables from "./../../_variables.scss";
import SocialTag from "./../SocialTag";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";
import names from "./../../names";

import Bulk from "./../Bulk";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class CampaignsList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            //edit_data: {},
            pagination_currentPage: 1,
            sortby: "name",
            sortasc: true,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("campaigns");

        // Breadcrumbs
        // this.props.store.setBreadcrumbs([
        //     { route: `/dashboard/campaigns`, title: "Campaigns" },
        // ]);
    }
    componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);
        this.listBulk = new Bulk({
            name: "campaignsList",
            list: this.props.store.getAllCampaigns.map(
                (element) => element.campaign
            ),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        await this.props.store.deleteCampaign(_key);
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                },
            ],
            callback: () => {
                console.log("READY");
                this.setState({
                    campaigns: this.props.store.getAllCampaigns,
                });
            },
        });
    }

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("campaigns_modal");
        this.props.store.unregisterModalListener("campaigns_modal_new");
    }

    showModalEdit = (_evt, _item) => {
        console.info("showModalEdit _item", _item);
        this.setState({ selected_campaign: _item });
        this.props.store.showModalByKey("campaigns_modal");
    };
    showModalPerformance = (_evt, _item) => {
        console.info("showModalPerformance _item", _item);
        this.setState({ selected_campaign: _item });
        this.props.store.showModalByKey("campaigns_modal_performance");
    };

    showModalNew = () => {
        console.log("showModalNew");
        this.props.store.showModalByKey("campaigns_modal_new");
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        this.setState({ sortby: _sorting, sortasc });
    };

    handleDeleteCampaign = (_evt, _item) => {
        console.info("handleDeleteCampaign _evt", _evt, "_item", _item);

        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk() {
                that.props.store.deleteCampaign(_item.campaign).then(() => {
                    that.setState({
                        campaigns: that.props.store.getAllCampaigns,
                    });
                });
            },
        });
    };

    handleEdit = async (_evt, _campaign) => {
        console.info("_campaign", _campaign);

        const old_campaign = _campaign.campaign;

        try {
            const new_campaign = await prompt({
                title: "Edit Campaign",
                placeholder: "Campaign name",
                rules: [
                    // check this link for more help: https://ant.design/components/form/#Validation-Rules
                    {
                        required: true,
                        message: "You must enter name",
                    },
                ],
                initialValue: _campaign.campaign,
                modalProps: {
                    width: "50%",
                },
            });
            this.props.store
                .editCampaign(old_campaign, new_campaign)
                .then(() => {
                    // update the campaigns in this state
                    this.setState({
                        campaigns: this.props.store.getAllCampaigns,
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
            //alert("old_category=" + old_category + ",new_name=" + new_name);
        } catch (e) {
            console.warn(e);
            //message.error("Please enter name");
        }
    };

    showReport = (_evt, _item) => {
        console.info("showReport _evt", _evt, "_item", _item);

        this.props.history.push(
            `/dashboard/campaigns/${_item.campaign}/report`
        );
    };

    showMasterposts = (_evt, _item) => {
        console.info("showMasterposts _evt", _evt, "_item", _item);

        //this.props.history.push(`/dashboard/tags/accounts/${_item.tag}`);

        this.props.history.push(
            `/dashboard/masterposts/${_item.campaign}/filter`
        );
    };

    onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    getListingDataSource = () => {
        let results = this.props.store.getAllCampaigns.filter((c) => {
            return (
                c.campaign
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1
            );
        });

        // --- sorting ---
        results.sort((a, b) => {
            //console.info("results.sort (this.state.sortby)", this.state.sortby);

            var nameA = a.campaign.toLowerCase(),
                nameB = b.campaign.toLowerCase();
            var masterpostsA = a.count,
                masterpostsB = b.count;

            switch (this.state.sortby) {
                case "name":
                    if (this.state.sortasc === true) {
                        if (nameA <= nameB) return -1;
                        if (nameA > nameB) return 1;
                    } else {
                        if (nameA <= nameB) return 1;
                        if (nameA > nameB) return -1;
                    }
                    break;
                case "masterposts":
                    if (this.state.sortasc === true) {
                        if (masterpostsA <= masterpostsB) return -1;
                        if (masterpostsA > masterpostsB) return 1;
                    } else {
                        if (masterpostsA <= masterpostsB) return 1;
                        if (masterpostsA > masterpostsB) return -1;
                    }
                    break;
                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting

        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    render() {
        const campaigns = this.props.store.getAllCampaigns;

        const pagesCount = campaigns.length / config.PAGINATION_PERPAGE;
        //console.log("pagesCount = " + pagesCount);

        const header = (
            <SocialListHeader
                title="Campaigns"
                newButtonClickHandle={this.showModal}
                newButtonText="New campaign"
                newButtonTooltip={tooltips.CAMPAIGNS.NEWBUTTON}
                bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );

        // let header = (
        //     <Row
        //         type="flex"
        //         justify="space-between"
        //         style={{ marginBottom: 10 }}
        //     >
        //         <Col>
        //             <Title level={3} style={{ float: "left", paddingRight: 5 }}>
        //                 Campaigns
        //             </Title>
        //             {/* <Badge
        //     count={campaigns.length}
        //     style={{ backgroundColor: "#1890ff" }}
        //   /> */}
        //         </Col>
        //         <Col>
        //             {
        //                 <Tooltip
        //                     title={tooltips.CAMPAIGNS.NEWBUTTON}
        //                     mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
        //                 >
        //                     <Button
        //                         type="primary"
        //                         // icon="plus"
        //                         size="small"
        //                         onClick={this.showModalNew}
        //                         style={{
        //                             marginLeft: 20,
        //                             marginTop: 5,
        //                             fontSize: 12,
        //                         }}
        //                     >
        //                         New campaign
        //                     </Button>
        //                 </Tooltip>
        //             }
        //         </Col>
        //     </Row>
        // );

        let body =
            campaigns.length === 0 ? (
                <Empty />
            ) : (
                <List
                    size="small"
                    className="CampaignsList"
                    header={
                        <Fragment>
                            {/* <Row type="flex" justify="space-between">
                                <Col
                                    style={{
                                        minWidth: 25,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                        style={{ marginRight: 10 }}
                                    />
                                    {this.listBulk &&
                                        this.listBulk.renderOptionsAsList()}
                                </Col>
                                <Col>
                                  
                                    <Search
                                        placeholder="filter"
                                        onSearch={this.onSearchChange}
                                        onChange={(e) =>
                                            this.onSearchChange(e.target.value)
                                        }
                                        style={{ width: 200 }}
                                    />
                                </Col>
                            </Row> */}
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Col style={{ width: 25, lineHeight: 1.2 }}>
                                    {/* <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                    /> */}
                                </Col>
                                <Col style={{ flex: "1 1", marginRight: -60 }}>
                                    <SocialListColumn
                                        sortkey="name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Campaign name
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1 }}>
                                    <SocialListColumn
                                        sortkey="masterposts"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        {names.get("MASTERPOST", true)}
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ width: 190 }}>Tools</Col>
                            </Row>
                        </Fragment>
                    }
                    footer={
                        pagesCount > 1 && this.state.filter.length === 0 ? (
                            <div style={{ textAlign: "right" }}>
                                <Pagination
                                    size="small"
                                    defaultCurrent={1}
                                    total={campaigns.length}
                                    pageSize={config.PAGINATION_PERPAGE}
                                    current={this.state.pagination_currentPage}
                                    onChange={this.paginationSetPage}
                                />
                            </div>
                        ) : null
                    }
                    bordered
                    dataSource={this.getListingDataSource()}
                    renderItem={(item) => (
                        <List.Item
                            key={item.campaign}
                            className={
                                this.listBulk &&
                                this.listBulk.isChecked(item.campaign)
                                    ? this.listBulk.getCheckedLength() === 1
                                        ? "social_Selected_withTools"
                                        : "social_Selected"
                                    : null
                            }
                            actions={[
                                <Tooltip
                                    key="edit"
                                    title={tooltips.CAMPAIGNS.LIST_EDIT}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            //this.handleEdit(_evt, item);
                                            this.showModalEdit(_evt, item);
                                        }}
                                    >
                                        <Icon type="edit" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="delete"
                                    title={tooltips.CAMPAIGNS.LIST_DELETE}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Icon
                                        type="delete"
                                        onClick={(evt) => {
                                            this.handleDeleteCampaign(
                                                evt,
                                                item
                                            );
                                        }}
                                        style={{
                                            color: scss_variables.scss_color_pallete_error,
                                        }}
                                    />
                                </Tooltip>,
                                <Tooltip
                                    key="performance"
                                    title={tooltips.CAMPAIGNS.LIST_PERFORMANCE}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            //this.handleEdit(_evt, item);
                                            this.showModalPerformance(
                                                _evt,
                                                item
                                            );
                                        }}
                                    >
                                        <Icon type="trophy" />
                                    </Button>
                                </Tooltip>,
                                // <Button
                                //     type="link"
                                //     style={{ padding: 0 }}
                                //     onClick={(_evt) => {
                                //         this.showReport(_evt, item);
                                //     }}
                                // >
                                //     report
                                // </Button>,
                                // <Button
                                //     type="link"
                                //     style={{ padding: 0 }}
                                //     onClick={_evt => {
                                //         this.showMasterposts(_evt, item);
                                //     }}
                                // >
                                //     primary posts
                                // </Button>
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <Fragment>
                                        <Checkbox
                                            onChange={(e) =>
                                                this.listBulk.handleCheck(
                                                    item.campaign
                                                )
                                            }
                                            checked={
                                                this.listBulk
                                                    ? this.listBulk.isChecked(
                                                          item.campaign
                                                      )
                                                    : false
                                            }
                                            style={{ marginRight: 10 }}
                                        />
                                        <SocialTag
                                            store={this.props.store}
                                            key={item.campaign}
                                            color="campaign"
                                        >
                                            {item.campaign}
                                        </SocialTag>
                                    </Fragment>
                                }
                            />
                            <div style={{ flexGrow: 1, textAling: "left" }}>
                                {item.count}{" "}
                                {names.get("MASTERPOST", true, true, true)}
                            </div>
                        </List.Item>
                    )}
                />
            );

        return (
            <Fragment>
                {header}
                {body}
                <CampaignsListModal
                    store={this.props.store}
                    data={this.state.selected_campaign}
                />
                <CampaignsListModalNew store={this.props.store} />
                <CampaignsListModalPerformance
                    store={this.props.store}
                    data={this.state.selected_campaign}
                />
            </Fragment>
        );
    }
}

export default observer(CampaignsList);
