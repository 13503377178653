import React, { Component } from "react";
import { Empty } from "antd";

class AccountReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: null
        };
    }

    UNSAFE_componentWillMount() {
        //this.props.store.setCurrentSection("settings");
        //var path = this.props.params.splat;
        //console.info("AccountsReport splat/path (passed parameter) = ", path);
        console.info("AccountsReport this.props ", this.props);
        this.setState({ accountId: this.props.match.params.id });
        //console.info("AccountsReport this.props ", this.props);

        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            { route: `/dashboard/accounts`, title: "Accounts" },
            { title: "Report" }
        ]);
    }

    render() {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`No Account to Report for accountId: ${this.state.accountId}`}
            />
        );
    }
}

export default AccountReport;
