import React, { Component, Fragment } from "react";

import { Radio, Form, Select, Spin, Checkbox } from "antd";

const { Option } = Select;

class TwitterTargeting extends Component {
    state = {};
    timer = null;

    constructor(props) {
        super(props);

        this.state = {
            // countries: [],
            // regions: [],
            // cities: [],
            // zips: [],
            // languages: [],
            // interests: [],
            // custom_audiences: [],
            // //
            // selected_gender: "all",
            // selected_age: [30, 50],
            // selected_country: [],
            // selected_region: [],
            // selected_city: [],
            // selected_zip: [],
            // selected_language: [],
            // selected_interest: [],

            // twitter_newad_type: "TEXT_AD",
            twitter_targeting: {
                selected_gender: "all",
                selected_age: "13+",
            },
        };
    }

    componentDidMount = async () => {
        console.info("componentDidMount this.props", this.props);
        if (this.props.targeting)
            this.setState({ twitter_targeting: this.props.targeting });
    };

    fetchTwitterOptions = (_criteria, _field, _skipDelay) => {
        console.log(
            "fetchTwitterOptions _criteria",
            _criteria,
            "_field",
            _field
        );
        console.info("fetchTwitterOptions this.props", this.props);

        const { twitter_targeting } = this.state;
        if (
            !twitter_targeting[_field] ||
            (twitter_targeting[_field] &&
                twitter_targeting[_field].length === 0)
        ) {
            twitter_targeting[_field] = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(
                function () {
                    that.setState({ twitter_targeting, fetching: true });
                    that.props.store
                        .getTwitterTargetOptions(
                            that.props.account_key,
                            _criteria
                        )
                        .then((data) => {
                            const { twitter_targeting } = that.state;
                            twitter_targeting[_field] = data.data;
                            that.setState({
                                twitter_targeting,
                                fetching: false,
                            });
                        })
                        .catch((error) => {
                            console.info("WE HAVE ERROR", error);
                        });
                },
                !_skipDelay ? 750 : 0
            );
        }
    };

    selectTwitterTargetParam = (_param, _value) => {
        console.info(
            "selectTwitterTargetParam _param",
            _param,
            "_value",
            _value
        );
        let { twitter_targeting } = this.state;
        twitter_targeting["selected_" + _param] = _value;
        this.setState({ twitter_targeting, changed: true });

        // update the audienceCount
        //   this.getLinkedinAudienceCount(_param);
        this.buble_Targeting();
    };

    //     getLinkedinAudienceCount = (_criteria, _skipDelay) => {
    //         //console.log("getLinkedinAudienceCount _criteria", _criteria);

    //         const { linkedin_targeting } = this.state;
    //         // console.log(
    //         //     "getLinkedinAudienceCount linkedin_targeting",
    //         //     linkedin_targeting
    //         // );

    //         let resultCriteria = {};
    //         Object.keys(linkedin_targeting).forEach((element) => {
    //             if (element.indexOf("selected") !== -1) {
    //                 console.log(element);
    //                 resultCriteria[element] = linkedin_targeting[element];
    //             }
    //         });
    //         // console.info(
    //         //     "getLinkedinAudienceCount resultCriteria",
    //         //     resultCriteria
    //         // );

    //         //linkedin_targeting[_facet] = [];
    //         const that = this;
    //         if (this.audienceCountTimer) clearTimeout(this.audienceCountTimer);
    //         this.audienceCountTimer = setTimeout(
    //             function () {
    //                 that.setState({ fetching: true });
    //                 that.props.store
    //                     .getLinkedinAudienceCount(
    //                         that.props.account_key,
    //                         resultCriteria
    //                     )
    //                     .then((data) => {
    //                         console.info("data", data);
    //                         //alert("total:" + data.total);
    //                         const { linkedin_targeting } = that.state;
    //                         linkedin_targeting["audienceCount"] = data.total;
    //                         that.setState({
    //                             linkedin_targeting,
    //                             fetching: false,
    //                         });
    //                     })
    //                     .catch((error) => {
    //                         console.info("WE HAVE ERROR", error);
    //                     });
    //             },
    //             !_skipDelay ? 1500 : 0
    //         );
    //     };

    buble_Targeting = () => {
        console.info("buble_Targeting");
        clearTimeout(this.timer);
        this.timer = setTimeout(
            function (that) {
                if (
                    that.props.updateTargeting &&
                    typeof that.props.updateTargeting === "function"
                ) {
                    const twitter_targeting = Object.assign(
                        {},
                        that.state.twitter_targeting
                    );

                    Object.keys(twitter_targeting).map((key) => {
                        if (key.indexOf("selected_") === -1)
                            delete twitter_targeting[key];
                        return true;
                    });

                    that.props.updateTargeting(
                        twitter_targeting
                        // {
                        // gender: curState.selected_gender,
                        // age: curState.selected_age,
                        // country: curState.selected_country,
                        // region: curState.selected_region,
                        // city: curState.selected_city,
                        // zip: curState.selected_zip,
                        // language: curState.selected_language,
                        // interest: curState.selected_interest,
                        // }
                    );
                }
            },
            1000,
            this
        );
    };

    render() {
        const { fetching } = this.state;

        const body = (
            <Fragment>
                <div>
                    <Form layout="horizontal">
                        {/* <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.twitter_targeting
                                            .selected_overwrite_app_store_categories
                                    }
                                    onChange={(e) =>
                                        this.selectTwitterTargetParam(
                                            "overwrite_app_store_categories",
                                            e.target.checked
                                        )
                                    }
                                >
                                    app_store_categories
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select app_store_categories"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchTwitterOptions(
                                        "app_store_categories?q=music&os_type=IOS",
                                        "app_store_categories"
                                    )
                                }
                                onFocus={(e) =>
                                    this.fetchTwitterOptions(
                                        "app_store_categories?q=music&os_type=IOS",
                                        "app_store_categories",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectTwitterTargetParam(
                                        "app_store_categories",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.twitter_targeting
                                        .selected_overwrite_app_store_categories
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.twitter_targeting
                                        .selected_app_store_categories
                                }
                            >
                                {this.state.twitter_targeting
                                    .app_store_categories &&
                                    this.state.twitter_targeting.app_store_categories.map(
                                        (e) => (
                                            <Option key={e.targeting_value}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item> */}

                        <Form.Item label="Gender">
                            <Radio.Group
                                value={
                                    this.state.twitter_targeting.selected_gender
                                }
                                onChange={(e) =>
                                    this.selectTwitterTargetParam(
                                        "gender",
                                        e.target.value
                                    )
                                }
                            >
                                <Radio.Button value="all">All</Radio.Button>
                                <Radio.Button value="1">Male</Radio.Button>
                                <Radio.Button value="2">Female</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {/* <Form.Item
                            label={
                                "Age: " +
                                this.state.twitter_targeting.selected_age[0] +
                                " - " +
                                this.state.twitter_targeting.selected_age[1]
                            }
                        >
                            <Slider
                                range
                                value={[
                                    this.state.twitter_targeting
                                        .selected_age[0],
                                    this.state.twitter_targeting
                                        .selected_age[1],
                                ]}
                                min={13}
                                max={100}
                                tooltipVisible={false}
                                tooltipPlacement="bottom"
                                onChange={(e) =>
                                    this.selectTwitterTargetParam("age", e)
                                }
                            />
                        </Form.Item> */}

                        <Form.Item label="Age">
                            <Select
                                style={{ width: 200 }}
                                defaultValue={
                                    this.state.twitter_targeting.selected_age
                                }
                                onChange={(e) => {
                                    console.info(e);
                                    this.selectTwitterTargetParam(
                                        "age",
                                        e.toString()
                                    );
                                }}
                            >
                                <Option value="13-24">13-24</Option>
                                <Option value="13-34">13-34</Option>
                                <Option value="13-49">13-49</Option>
                                <Option value="13-54">13-54</Option>
                                <Option value="13+">13+</Option>
                                <Option value="18-24">18-24</Option>
                                <Option value="18-34">18-34</Option>
                                <Option value="18-49">18-49</Option>
                                <Option value="18-54">18-54</Option>
                                <Option value="18+">18+</Option>
                                <Option value="21-34">21-34</Option>
                                <Option value="21-49">21-49</Option>
                                <Option value="21-54">21-54</Option>
                                <Option value="21+">21+</Option>
                                <Option value="25-49">25-49</Option>
                                <Option value="25-54">25-54</Option>
                                <Option value="25+">25+</Option>
                                <Option value="35-49">35-49</Option>
                                <Option value="35-54">35-54</Option>
                                <Option value="35+">35+</Option>
                                <Option value="50+">50+</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.twitter_targeting
                                            .selected_overwrite_devices
                                    }
                                    onChange={(e) =>
                                        this.selectTwitterTargetParam(
                                            "overwrite_devices",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Devices
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select devices"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchTwitterOptions(
                                        "devices",
                                        "devices"
                                    )
                                }
                                onFocus={(e) =>
                                    this.fetchTwitterOptions(
                                        "devices",
                                        "devices",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectTwitterTargetParam("devices", e);
                                }}
                                disabled={
                                    !this.state.twitter_targeting
                                        .selected_overwrite_devices
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.twitter_targeting
                                        .selected_devices
                                }
                            >
                                {this.state.twitter_targeting.devices &&
                                    this.state.twitter_targeting.devices.map(
                                        (e) => (
                                            <Option key={e.targeting_value}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.twitter_targeting
                                            .selected_overwrite_events
                                    }
                                    onChange={(e) =>
                                        this.selectTwitterTargetParam(
                                            "overwrite_events",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Events
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select events"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchTwitterOptions("events", "events")
                                }
                                onFocus={(e) =>
                                    this.fetchTwitterOptions(
                                        "events",
                                        "events",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectTwitterTargetParam("events", e);
                                }}
                                disabled={
                                    !this.state.twitter_targeting
                                        .selected_overwrite_events
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.twitter_targeting.selected_events
                                }
                            >
                                {this.state.twitter_targeting.events &&
                                    this.state.twitter_targeting.events.map(
                                        (e) => (
                                            <Option key={e.targeting_value}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.twitter_targeting
                                            .selected_overwrite_interests
                                    }
                                    onChange={(e) =>
                                        this.selectTwitterTargetParam(
                                            "overwrite_interests",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Interests
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select interests"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchTwitterOptions(
                                        "interests",
                                        "interests"
                                    )
                                }
                                onFocus={(e) =>
                                    this.fetchTwitterOptions(
                                        "interests",
                                        "interests",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectTwitterTargetParam(
                                        "interests",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.twitter_targeting
                                        .selected_overwrite_interests
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.twitter_targeting
                                        .selected_interests
                                }
                            >
                                {this.state.twitter_targeting.interests &&
                                    this.state.twitter_targeting.interests.map(
                                        (e) => (
                                            <Option key={e.targeting_value}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.twitter_targeting
                                            .selected_overwrite_languages
                                    }
                                    onChange={(e) =>
                                        this.selectTwitterTargetParam(
                                            "overwrite_languages",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Languages
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select languages"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchTwitterOptions(
                                        "languages",
                                        "languages"
                                    )
                                }
                                onFocus={(e) =>
                                    this.fetchTwitterOptions(
                                        "languages",
                                        "languages",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectTwitterTargetParam(
                                        "languages",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.twitter_targeting
                                        .selected_overwrite_languages
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.twitter_targeting
                                        .selected_languages
                                }
                            >
                                {this.state.twitter_targeting.languages &&
                                    this.state.twitter_targeting.languages.map(
                                        (e) => (
                                            <Option key={e.targeting_value}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.twitter_targeting
                                            .selected_overwrite_locations
                                    }
                                    onChange={(e) =>
                                        this.selectTwitterTargetParam(
                                            "overwrite_locations",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Locations
                                </Checkbox>
                            }
                        >
                            {/* TODO: Probably add location_type??? https://developer.twitter.com/en/docs/twitter-ads-api/campaign-management/api-reference/targeting-options */}
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select locations"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchTwitterOptions(
                                        "locations?count=1000",
                                        "locations"
                                    )
                                }
                                onFocus={(e) =>
                                    this.fetchTwitterOptions(
                                        "locations?count=1000",
                                        "locations",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectTwitterTargetParam(
                                        "locations",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.twitter_targeting
                                        .selected_overwrite_locations
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.twitter_targeting
                                        .selected_locations
                                }
                            >
                                {this.state.twitter_targeting.locations &&
                                    this.state.twitter_targeting.locations.map(
                                        (e) => (
                                            <Option key={e.targeting_value}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.twitter_targeting
                                            .selected_overwrite_network_operators
                                    }
                                    onChange={(e) =>
                                        this.selectTwitterTargetParam(
                                            "overwrite_network_operators",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Network operators
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select network operators"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchTwitterOptions(
                                        "network_operators?count=1000",
                                        "network_operators"
                                    )
                                }
                                onFocus={(e) =>
                                    this.fetchTwitterOptions(
                                        "network_operators?count=1000",
                                        "network_operators",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectTwitterTargetParam(
                                        "network_operators",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.twitter_targeting
                                        .selected_overwrite_network_operators
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.twitter_targeting
                                        .selected_network_operators
                                }
                            >
                                {this.state.twitter_targeting
                                    .network_operators &&
                                    this.state.twitter_targeting.network_operators.map(
                                        (e) => (
                                            <Option key={e.targeting_value}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.twitter_targeting
                                            .selected_overwrite_platform_versions
                                    }
                                    onChange={(e) =>
                                        this.selectTwitterTargetParam(
                                            "overwrite_platform_versions",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Platform versions
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select platform versions"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={(e) =>
                                    this.fetchTwitterOptions(
                                        "platform_versions?count=1000",
                                        "platform_versions"
                                    )
                                }
                                onFocus={(e) =>
                                    this.fetchTwitterOptions(
                                        "platform_versions?count=1000",
                                        "platform_versions",
                                        true
                                    )
                                }
                                onChange={(e) => {
                                    this.selectTwitterTargetParam(
                                        "platform_versions",
                                        e
                                    );
                                }}
                                disabled={
                                    !this.state.twitter_targeting
                                        .selected_overwrite_platform_versions
                                }
                                style={{ width: "100%" }}
                                value={
                                    this.state.twitter_targeting
                                        .selected_platform_versions
                                }
                            >
                                {this.state.twitter_targeting
                                    .platform_versions &&
                                    this.state.twitter_targeting.platform_versions.map(
                                        (e) => (
                                            <Option key={e.targeting_value}>
                                                {e.name}
                                            </Option>
                                        )
                                    )}
                            </Select>
                        </Form.Item>
                    </Form>
                    {/* <pre>
                        {JSON.stringify(this.state.twitter_targeting, null, 2)}
                    </pre> */}
                </div>
            </Fragment>
        );

        //   let body = (
        //       <Fragment>
        //           Linkedin
        //           <Form layout="horizontal">
        //               <Form.Item label="Gender">
        //                   <Radio.Group
        //                       value={this.state.selected_gender}
        //                       onChange={(e) =>
        //                           this.selectFacebookTargetParam(
        //                               "gender",
        //                               e.target.value
        //                           )
        //                       }
        //                   >
        //                       <Radio.Button value="all">All</Radio.Button>
        //                       <Radio.Button value="1">Male</Radio.Button>
        //                       <Radio.Button value="2">Female</Radio.Button>
        //                   </Radio.Group>
        //               </Form.Item>
        //               <Form.Item
        //                   label={
        //                       "Age: " +
        //                       this.state.selected_age[0] +
        //                       " - " +
        //                       this.state.selected_age[1]
        //                   }
        //               >
        //                   <Slider
        //                       range
        //                       value={[
        //                           this.state.selected_age[0],
        //                           this.state.selected_age[1],
        //                       ]}
        //                       min={13}
        //                       max={100}
        //                       tooltipVisible={false}
        //                       tooltipPlacement="bottom"
        //                       onChange={(e) =>
        //                           this.selectFacebookTargetParam("age", e)
        //                       }
        //                   />
        //               </Form.Item>
        //               <Form.Item label="Country">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select countries"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchCountry}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("country", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_country}
        //                   >
        //                       {this.state.countries.map((c) => (
        //                           <Option key={c.key}>{c.name}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="Region">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select regions"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchRegion}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("region", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_region}
        //                   >
        //                       {this.state.regions.map((r) => (
        //                           <Option key={r.key}>{r.name}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="City">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select cities"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchCity}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("city", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_city}
        //                   >
        //                       {this.state.cities.map((c) => (
        //                           <Option key={c.key}>{c.name}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="ZIP">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select zip codes"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchZip}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("zip", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_zip}
        //                   >
        //                       {this.state.zips.map((z) => (
        //                           <Option key={z.key}>
        //                               {z.country_name} / {z.region} /{" "}
        //                               {z.primary_city} - {z.name}
        //                           </Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="Language">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select languages"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchLanguage}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("language", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_language}
        //                   >
        //                       {this.state.languages.map((l) => (
        //                           <Option key={l.key}>{l.name}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //               <Form.Item label="Interest">
        //                   <Select
        //                       mode="multiple"
        //                       labelInValue
        //                       //     value={value}
        //                       placeholder="Select interests"
        //                       notFoundContent={
        //                           fetching ? <Spin size="small" /> : null
        //                       }
        //                       filterOption={false}
        //                       onSearch={this.fetchInterest}
        //                       onChange={(e) => {
        //                           this.selectFacebookTargetParam("interest", e);
        //                       }}
        //                       style={{ width: "100%" }}
        //                       value={this.state.selected_interest}
        //                   >
        //                       {this.state.interests.map((i) => (
        //                           <Option key={i.id}>{i.path.join("/")}</Option>
        //                       ))}
        //                   </Select>
        //               </Form.Item>
        //           </Form>
        //           {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
        //       </Fragment>
        //   );

        return body;
    }
}

export default TwitterTargeting;
