import React, { Component, Fragment } from "react";
import SocialButtonTwitter from "../components/SocialButtonTwitter";
import { observer } from "mobx-react";
import SocialSeparator from "../components/SocialSeparator";

class TwitterHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const queryString = window.location.search;
        console.log("queryString=" + queryString);
        const urlParams = new URLSearchParams(queryString);
        const account_key = urlParams.has("account_key")
            ? urlParams.get("account_key")
            : null;
        console.log("account_key=" + account_key);

        let temp_data = this.props.store.getTempData();
        console.log(" ----- temp_data=", temp_data);

        return (
            <Fragment>
                <h3>Link Twitter profile for TwitterHandler</h3>
                <div>
                    Some explanation about how to link the Twitter profile with
                    the current account. <br />
                    <br />
                    <SocialSeparator />
                    Please click the button below in order to authorize Uhub to
                    access the Twitter profile.
                </div>
                <br />
                <br />
                <SocialButtonTwitter store={this.props.store} account_key={account_key} />
            </Fragment>
        );
    }
}

export default observer(TwitterHandler);
