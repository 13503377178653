import React from "react";

const SocialSeparator = (props) => {
    const style = {
        ...{
            borderTop: "1px solid #ddd",
            position: "relative",
            marginBottom: "calc(1rem + 5px)",
        },
        ...props.style,
    };

    let spanStyle = {
        background: "white",
        padding: "0px 10px",
        position: "absolute",
        top: "calc(-0.5rem - 4px)",
        left: 20,
        color: "#999",
    };
    if (props.centered === true) {
        spanStyle = {
            ...spanStyle,
            ...{ left: "50%", transform: "translateX(-50%)" },
        };
    }

    return (
        <div style={style}>
            <span style={spanStyle}>{props.children}</span>
        </div>
    );
};

export default SocialSeparator;
