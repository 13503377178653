import React, { Component, Fragment } from "react";
import {
    Form,
    Modal,
    Button,
    Icon,
    Divider,
    Tabs,
    Row,
    Col,
    Alert,
} from "antd";
import { observer } from "mobx-react";

import moment from "moment";

const { TabPane } = Tabs;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                insights: {},
                fetching: false,
            };
        }

        componentDidMount() {
            // To disable submit button at the beginning.
            this.fetchInsights(this.props.data.key);
        }

        fetchInsights = (_key) => {
            console.log("fetchInsights _key", _key);
            this.setState({ insights: {}, fetching: true });
            this.props.store
                .getAdInsights(_key)
                .then((result) => {
                    console.info("getAdInsights result", result);
                    this.setState({
                        insights: result.data[0],
                        fetching: false,
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        };

        render() {
            const { data } = this.props;
            // const { getFieldDecorator } = form;
            const { fetching } = this.state;

            const loadingIcon = fetching ? (
                <Fragment>
                    <Icon
                        type="loading"
                        style={{ fontSize: 24, marginRight: 20 }}
                        spin
                    />{" "}
                    Loading ...
                </Fragment>
            ) : null;

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };

            const insightsPanel = (
                <Fragment>
                    <Row>
                        <Col span={12}>
                            <label>Impressions</label>
                            <h2>{this.state.insights.impressions}</h2>
                        </Col>
                        <Col span={12}>
                            <label>Clicks</label>
                            <h2>{this.state.insights.clicks}</h2>
                        </Col>
                    </Row>
                    <Divider style={{ margin: "10px 0" }} />
                    <Row>
                        <Col span={12}>
                            <label>Reach</label>
                            <h2>{this.state.insights.reach}</h2>
                        </Col>
                        <Col span={12}>
                            <label>Frequency</label>
                            <h2>{this.state.insights.frequency}</h2>
                        </Col>
                    </Row>
                    <Divider style={{ margin: "10px 0" }} />
                    <Row>
                        <Col span={12}>
                            <label>CPC</label>
                            <h2>{this.state.insights.cpc}</h2>
                        </Col>
                        <Col span={12}>
                            <label>CPM</label>
                            <h2>{this.state.insights.cpm}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <label>CPP</label>
                            <h2>{this.state.insights.cpp}</h2>
                        </Col>
                        <Col span={12}>
                            <label>CTR</label>
                            <h2>{this.state.insights.ctr}</h2>
                        </Col>
                    </Row>
                    <Divider style={{ margin: "10px 0" }} />
                    <Row>
                        <Col span={24}>
                            <label>Spend</label>
                            <h2>
                                {this.state.insights.spend}{" "}
                                {this.state.insights.account_currency}
                            </h2>
                        </Col>
                    </Row>
                    {/* <div>
                        Inisghts:
                        <br />
                        <pre>
                            {JSON.stringify(this.state.insights, null, 2)}
                        </pre>
                    </div> */}
                </Fragment>
            );
            const informationPanel = (
                <Fragment>
                    <label>Name</label>
                    <h2>{data.name}</h2>
                    <Divider style={{ margin: "10px 0" }} />

                    <label>Start time</label>
                    <h2>{moment(data.start_time).format("l")}</h2>
                    <Divider style={{ margin: "10px 0" }} />

                    <label>End time</label>
                    <h2>{moment(data.end_time).format("l")}</h2>
                    <Divider style={{ margin: "10px 0" }} />

                    <label>Platform</label>
                    <h2>
                        {data.platform.toString()[0].toUpperCase() +
                            data.platform.toString().slice(1)}
                    </h2>
                    <Divider style={{ margin: "10px 0" }} />

                    <label>Targeting</label>
                    <h2>
                        {data.targeting_text.toString().split(",").join(", ")}
                    </h2>
                    <Divider style={{ margin: "10px 0" }} />
                </Fragment>
            );
            const adDetailsPanel = (
                <Fragment>
                    <label>Campaign name</label>
                    <h2>{data.ad_data.campaign_name}</h2>
                    <Divider style={{ margin: "10px 0" }} />
                    <label>Ad Set Name</label>
                    <h2>{data.ad_data.adset_name}</h2>
                    <Divider style={{ margin: "10px 0" }} />
                    <label>Ad Set Id</label>
                    <h2>{data.ad_data.adset.id}</h2>
                    <Divider style={{ margin: "10px 0" }} />
                    <label>Ad Creative Id</label>
                    <h2>{data.ad_data.adcreative.id}</h2>
                    <Divider style={{ margin: "10px 0" }} />
                    <label>Ad Id</label>
                    <h2>{data.ad_data.ad.id}</h2>
                </Fragment>
            );

            return (
                <Form {...formItemLayout}>
                    <div
                        style={{
                            height: "50vh",
                            maxHeight: "50vh",
                            overflow: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={"Insights"} key="insights">
                                <Fragment>
                                    {Object.keys(this.state.insights).length ===
                                    0
                                        ? loadingIcon
                                        : insightsPanel}
                                </Fragment>
                            </TabPane>
                            <TabPane tab={"Information"} key="information">
                                {informationPanel}
                            </TabPane>
                            <TabPane tab={"Details"} key="addetails">
                                {adDetailsPanel}
                            </TabPane>
                        </Tabs>
                    </div>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class AdsListModalInsights extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "ads_modal_insights",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("ads_modal_insights");
    };

    onModalCreate = async () => {
        //   const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("formState", formState);
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.props.data.key);

            this.props.store.updateCompetitor(this.props.data.key, values);

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        this.setState({ processing: false });
        this.props.store.hideModalByKey(
            "ads_modal_insights",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        centered
                        icon="form"
                        title={"Ad Insights"}
                        //okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        // onOk={this.onModalCancel} //onApply
                        maskClosable={false}
                        footer={
                            <Fragment>
                                <Button
                                    type="primary"
                                    onClick={this.onModalCancel}
                                >
                                    Close
                                </Button>
                            </Fragment>
                        }
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AdsListModalInsights);
