import React, { Component, Fragment } from "react";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import {
    Form,
    Modal,
    DatePicker,
    InputNumber,
    Tooltip,
    Steps,
    Icon,
    Tree,
    Button,
    message,
    Select,
    Alert,
    Tag,
} from "antd";
import prompt from "./../antd-prompt";
import { observer } from "mobx-react";
import export_logo from "./../../assets/images/export_logo.png";
import scss_variables from "./../../_variables.scss";

import ReportResultChannelMonthlyFacebook from "./ReportResultChannelMonthlyFacebook";
import ReportResultChannelMonthlyInstagram from "./ReportResultChannelMonthlyInstagram";
import ReportResultMasterpostMonthlyBasic from "./ReportResultMasterpostMonthlyBasic";
import ReportResultAdMonthlyFacebook from "./ReportResultAdMonthlyFacebook";
import ReportResultAdMonthlyLinkedin from "./ReportResultAdMonthlyLinkedin";
import ReportResultChannelMonthlyTwitter from "./ReportResultChannelMonthlyTwitter";
import ReportResultChannelMonthlyLinkedin from "./ReportResultChannelMonthlyLinkedin";
import ReportResultBrandBasic from "./ReportResultBrandBasic";
import ReportResultChannelMonthlyYoutube from "./ReportResultChannelMonthlyYoutube";

import { social_reportsMergeValues } from "./../../functions";

const { RangePicker } = DatePicker;
const { Step } = Steps;
const { TreeNode } = Tree;
const { Option } = Select;
const { confirm } = Modal;

const DATE_FORMAT = "MM/DD/YYYY";
const PREDEFINED_RANGES = {
    Today: [moment(), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Previous Month": [
        moment().subtract(1, "months").startOf("month"),
        moment().subtract(1, "months").endOf("month"),
    ],
};

const combineInsightsData = [
    "page_impressions_by_city_unique",
    "page_impressions_by_country_unique",
    "page_impressions_by_locale_unique",
    "page_impressions_by_age_gender_unique",
];

/* Add/Edit Form */
const ModalForm = Form.create({ name: "form_in_modal" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                allAccounts: [],
                allMasterposts: [],
                allAds: [],
                allBrands: [],
                combined_results: {},
                loading: false,
                custom_sections: [],
                custom_report_flag: false,
                report: null,
                errors: [],
            };
        }

        componentDidMount() {
            let reportAccountKeys = [];
            const base_report = this.props.report.original_report
                ? this.props.report.original_report
                : this.props.report;
            console.info("base_report", base_report);

            const allAccounts = this.props.store.getAllAccounts.map((a) => {
                let accountData = {
                    title: `${a.name} (${a.email})`,
                    key: a.key,
                    children: [],
                };
                if (
                    this.props.report_account &&
                    this.props.report_account.key === a.key
                ) {
                    reportAccountKeys.push(a.key);
                }

                if (
                    base_report.channels_filter.includes("facebook") &&
                    a.facebook &&
                    a.facebook.data &&
                    a.facebook.data.length > 0
                ) {
                    let facebookChannels = a.facebook.data.map((d) => {
                        return {
                            title: d.name,
                            type: "facebook",
                            key:
                                a.key +
                                "|facebook|" +
                                d.id +
                                "|" +
                                a.name +
                                "|" +
                                d.name,
                        };
                    });
                    if (
                        base_report.channels_filter.indexOf("facebook") !== -1
                    ) {
                        accountData.children =
                            accountData.children.concat(facebookChannels);
                    }
                    if (
                        base_report.channels_filter.indexOf("facebook") !==
                            -1 &&
                        this.props.report_account &&
                        this.props.report_account.key === a.key
                    ) {
                        reportAccountKeys = reportAccountKeys.concat(
                            facebookChannels.map((channel) => channel.key)
                        );
                    }
                }
                if (
                    base_report.channels_filter.includes("instagram") &&
                    a.instagram &&
                    a.instagram.data.length > 0
                ) {
                    let instagramChannels = a.instagram.data.map((d) => {
                        return {
                            title: d.meta.name,
                            type: "instagram",
                            key:
                                a.key +
                                "|instagram|" +
                                d.instagram_account_id +
                                "|" +
                                a.name +
                                "|" +
                                d.meta.name,
                        };
                    });
                    if (
                        base_report.channels_filter.includes("instagram") !== -1
                    ) {
                        accountData.children =
                            accountData.children.concat(instagramChannels);
                    }
                    if (
                        base_report.channels_filter.indexOf("instagram") !==
                            -1 &&
                        this.props.report_account &&
                        this.props.report_account.key === a.key
                    ) {
                        reportAccountKeys = reportAccountKeys.concat(
                            instagramChannels.map((channel) => channel.key)
                        );
                    }
                }

                // Twitter
                if (
                    base_report.channels_filter.includes("twitter") &&
                    a.twitter &&
                    a.twitter.auth
                ) {
                    console.info("WE HAVE TWITTER DATA", a);
                    let twitterChannel = {
                        title: a.twitter.auth.screen_name,
                        type: "twitter",
                        key: a.key + "|twitter|" + a.twitter.auth.user_id,
                    };
                    accountData.children.push(twitterChannel);

                    if (
                        base_report.channels_filter.includes("twitter") &&
                        this.props.report_account &&
                        this.props.report_account.key === a.key
                    ) {
                        reportAccountKeys = reportAccountKeys.concat(
                            twitterChannel.key
                        );
                    }
                }

                // Linkedin
                if (
                    base_report.channels_filter.includes("linkedin") &&
                    a.linkedin &&
                    a.linkedin.auth
                ) {
                    console.info("WE HAVE LINKEDIN DATA", a);
                    // Roll over company pages only

                    if (a.linkedin && a.linkedin.auth && a.linkedin.data) {
                        a.linkedin.data.map((ld) => {
                            accountData.children.push({
                                title: ld.localizedName,
                                type: "linkedin",
                                key: a.key + "|linkedin|" + ld.id,
                            });

                            if (
                                base_report.channels_filter.includes(
                                    "linkedin"
                                ) &&
                                this.props.report_account &&
                                this.props.report_account.key === a.key
                            ) {
                                reportAccountKeys = reportAccountKeys.concat(
                                    ld.id
                                );
                            }

                            return true;
                        });
                    }

                    // let linkedinChannel = {
                    //     title:
                    //         a.linkedin.profile.localizedFirstName +
                    //         " " +
                    //         a.linkedin.profile.localizedLastName,
                    //     type: "linkedin",
                    //     key: a.key + "|linkedin|" + a.linkedin.profile.id,
                    // };
                    // accountData.children.push(linkedinChannel);

                    // if (
                    //     base_report.channels_filter.includes("linkedin") &&
                    //     this.props.report_account &&
                    //     this.props.report_account.key === a.key
                    // ) {
                    //     reportAccountKeys = reportAccountKeys.concat(
                    //         linkedinChannel.key
                    //     );
                    // }
                }

                // Youtube
                if (
                    base_report.channels_filter.includes("youtube") &&
                    a.google &&
                    a.google.auth
                ) {
                    console.info("WE HAVE YOUTUBE DATA", a);
                    // Roll over company pages only

                    if (a.google && a.google.auth && a.google.data) {
                        a.google.data.map((yc) => {
                            accountData.children.push({
                                title: yc.snippet.title,
                                type: "youtube",
                                key:
                                    a.key +
                                    "|youtube|" +
                                    yc.id +
                                    "|" +
                                    a.name +
                                    "|" +
                                    yc.snippet.title,
                            });

                            if (
                                base_report.channels_filter.includes(
                                    "youtube"
                                ) &&
                                this.props.report_account &&
                                this.props.report_account.key === a.key
                            ) {
                                reportAccountKeys = reportAccountKeys.concat(
                                    yc.id
                                );
                            }

                            return true;
                        });
                    }

                    // let linkedinChannel = {
                    //     title:
                    //         a.linkedin.profile.localizedFirstName +
                    //         " " +
                    //         a.linkedin.profile.localizedLastName,
                    //     type: "linkedin",
                    //     key: a.key + "|linkedin|" + a.linkedin.profile.id,
                    // };
                    // accountData.children.push(linkedinChannel);

                    // if (
                    //     base_report.channels_filter.includes("linkedin") &&
                    //     this.props.report_account &&
                    //     this.props.report_account.key === a.key
                    // ) {
                    //     reportAccountKeys = reportAccountKeys.concat(
                    //         linkedinChannel.key
                    //     );
                    // }
                }

                return accountData;
            });
            console.info("allAccounts", allAccounts);
            console.info("base_report", base_report);

            const is_custom_report = this.props.report.original_report
                ? true
                : false;

            const allMasterposts = this.props.store.getAllMasterposts;

            const allAds = this.props.store.getAllAds;

            const allBrands = this.props.store.getAllBrands;

            let checkedChannel = null;
            if (
                this.props.report_channel &&
                this.props.report_channel.type === "instagram"
            ) {
                checkedChannel =
                    this.props.report_channel.account_key +
                    "|instagram|" +
                    this.props.report_channel.id +
                    "|" +
                    this.props.report_channel.account_name +
                    "|" +
                    this.props.report_channel.meta.name;
            }
            if (
                this.props.report_channel &&
                this.props.report_channel.type === "facebook"
            ) {
                checkedChannel =
                    this.props.report_channel.account_key +
                    "|facebook|" +
                    this.props.report_channel.id +
                    "|" +
                    this.props.report_channel.account_name +
                    "|" +
                    this.props.report_channel.name;
            }
            if (
                this.props.report_channel &&
                this.props.report_channel.type === "twitter"
            ) {
                checkedChannel =
                    this.props.report_channel.account_key +
                    "|twitter|" +
                    this.props.report_channel.id;
            }

            if (
                this.props.report_channel &&
                this.props.report_channel.type === "linkedin"
            ) {
                checkedChannel =
                    this.props.report_channel.account_key +
                    "|linkedin|" +
                    this.props.report_channel.id;
            }

            if (
                this.props.report_channel &&
                this.props.report_channel.type === "youtube"
            ) {
                checkedChannel =
                    this.props.report_channel.account_key +
                    "|youtube|" +
                    this.props.report_channel.id;
            }

            console.log("checkedChannel = " + checkedChannel);

            this.setState({
                allAccounts,
                allMasterposts,
                allAds,
                allBrands,
                custom_sections: this.props.report.original_report
                    ? this.props.report.original_report.custom_sections
                    : this.props.report.custom_sections,
                custom_report_flag: is_custom_report,
                checkedKeys: this.props.report.original_report
                    ? this.props.report.prefilled_fields.channels
                    : this.props.report_account
                    ? reportAccountKeys
                    : this.props.report_channel
                    ? [checkedChannel]
                    : [],
                report_ad: this.props.report.original_report
                    ? this.props.report.prefilled_fields.ads
                    : this.props.report_ad
                    ? this.props.report_ad
                    : [],
                base_report,
            });

            console.info("this.props", this.props);
        }
        onForward = async () => {
            console.info("onForward");
            //this.setState({ currentStep: 1 });

            const base_report = this.props.report.original_report
                ? this.props.report.original_report
                : this.props.report;
            console.info("base_report", base_report);

            // form generating
            console.info("this.props", this.props);

            const { form } = this.props;
            form.validateFields(async (err, values) => {
                // Adding checked keys
                values = { ...values, channels: this.state.checkedKeys };
                console.info(
                    "!!!!!Received values of form: ",
                    values,
                    "err",
                    err
                );

                if (err) {
                    return;
                }

                // Set loading flag
                this.setState({
                    loading: true,
                });

                let results = null;
                let channels = [];

                // Date Range ---------
                if (values.date_range) {
                    const since = values.date_range[0].utc();
                    const until = values.date_range[1].utc();
                    console.info("since", since);
                    console.info("until", until);

                    let prevPeriodSince = null;
                    let prevPeriodUntil = null;
                    var periodDuration = moment.duration(
                        moment(until).utc().diff(moment(since).utc())
                    );
                    var periodDays = Number(periodDuration.asDays());
                    // var startdate = moment();
                    // startdate = startdate.subtract(1, "days");
                    // startdate = startdate.format("DD-MM-YYYY");
                    let periodSince = moment(since).utc();
                    prevPeriodSince = moment(periodSince.valueOf()).subtract(
                        periodDays,
                        "days"
                    );
                    prevPeriodUntil = moment(since);

                    // console.info(
                    //     "PrevPeriod > periodDays",
                    //     periodDays,
                    //     "prevPeriodSince",
                    //     prevPeriodSince.format("YYYY-MM-DD"),
                    //     "prevPeriodUntil",
                    //     prevPeriodUntil.format("YYYY-MM-DD")
                    // );

                    this.setState({
                        results_since: since,
                        results_until: until,
                        results_prevsince: prevPeriodSince,
                        results_prevuntil: prevPeriodUntil,
                    });
                }

                console.info("values.channels", values.channels);
                console.info("values", values);
                // Channels ----
                if (values.channels && values.channels.length > 0) {
                    // filter channels
                    channels = values.channels.filter(
                        (c) => c.indexOf("|") !== -1
                    );
                    this.setState({ results_channels: channels });
                    console.info("channels (after filter)", channels);

                    const since = values.date_range[0].utc();
                    const until = values.date_range[1].utc();

                    results = await Promise.all(
                        channels.map((ci) => {
                            const channelInfo = ci.split("|");
                            return this.props.store.getAccountChannelInsights(
                                channelInfo[0],
                                channelInfo[1],
                                channelInfo[2],
                                since.format(),
                                until.format()
                            );
                        })
                    ).catch((err) => {
                        console.warn(err);
                        this.setState({
                            //currentStep: 0,
                            loading: false,
                        });
                        return;
                    });
                }

                // if (values.masterposts) {
                //     results = await this.props.store.getmasterpostStatus(
                //         values.masterposts
                //     );
                // }

                let insights = null;
                // Additional procedures
                switch (this.props.report.key) {
                    case "masterpost_basic":
                        function timeout(ms) {
                            return new Promise((resolve) =>
                                setTimeout(resolve, ms)
                            );
                        }

                        await timeout(2000);

                        results = await this.props.store.getMasterpostStatus(
                            values.masterposts
                        );

                        let posts = results.posts.filter(
                            (post) => post.status === "posted"
                        );

                        let allAccounts = this.props.store.getAllAccounts;
                        console.info("POSTS", posts, "ACCOUNTS", allAccounts);

                        let facebook_posts = [];

                        posts.map((post) => {
                            const account = allAccounts.filter(
                                (account) =>
                                    account.email === post.account.email
                            )[0];
                            post.account_key = account.key;
                            post.results.map((result) => {
                                let found_channel = null;
                                found_channel = account.facebook.data.filter(
                                    (fb_channel) =>
                                        fb_channel.id === result.channel_id
                                );
                                if (found_channel && found_channel.length > 0) {
                                    result.channel_access_token =
                                        found_channel[0].access_token;
                                    facebook_posts.push({
                                        id: result.post_id,
                                        channel_access_token:
                                            result.channel_access_token,
                                    });
                                }
                                // result.channel_access_token = account.facebook.data.filter(
                                //     (fb_channel) =>
                                //         fb_channel.id === result.channel_id
                                // )[0].access_token;
                                // facebook_posts.push({
                                //     id: result.post_id,
                                //     channel_access_token:
                                //         result.channel_access_token,
                                // });
                                return false;
                            });
                            return false;
                        });
                        console.info("FACEBOOK POSTS", facebook_posts);

                        const posts_insights =
                            await this.props.store.getFacebookPostsInsights(
                                facebook_posts
                            );

                        // Attach
                        posts.map((post) => {
                            post.results.map((result) => {
                                result.insights = posts_insights.filter(
                                    (post) => post.id === result.post_id
                                )[0];
                                return false;
                            });
                            return false;
                        });

                        results.posts = JSON.parse(JSON.stringify(posts));

                        console.info("FACEBOOK POSTS results", results);

                        break;
                    case "ad_facebook_monthly":
                        insights = await this.props.store.getAdInsights(
                            values.ads,
                            "facebook"
                        );
                        results = { insights: insights.data[0] };

                        break;
                    case "ad_linkedin_monthly":
                        insights = await this.props.store.getAdInsights(
                            values.ads,
                            "linkedin"
                        );
                        results = { insights: insights.data[0] };

                        break;
                    case "channel_monthly_twitter":
                        let sumUserProfileClicks = 0,
                            sumImpressions = 0,
                            sumReply = 0,
                            sumRetweet = 0,
                            sumLike = 0,
                            sumQuote = 0;

                        console.info(
                            "channel_monthly_twitter RESULTS",
                            results
                        );

                        if (results && results[0].data) {
                            results[0].data.map((dataElm) => {
                                sumUserProfileClicks +=
                                    dataElm.organic_metrics.user_profile_clicks;
                                sumImpressions +=
                                    dataElm.organic_metrics.impression_count;
                                sumReply += dataElm.organic_metrics.reply_count;
                                sumRetweet +=
                                    dataElm.organic_metrics.retweet_count;
                                sumLike += dataElm.organic_metrics.like_count;
                                sumQuote += dataElm.public_metrics.quote_count;
                                return true;
                            });
                            results[0].sum = {
                                sumUserProfileClicks,
                                sumImpressions,
                                sumReply,
                                sumRetweet,
                                sumLike,
                                sumQuote,
                            };
                        }

                        break;
                    case "channel_monthly_linkedin":
                        let lsumUserProfileClicks = 0,
                            lsumImpressions = 0,
                            lsumReply = 0,
                            lsumRetweet = 0,
                            lsumLike = 0,
                            lsumQuote = 0;

                        console.info(
                            "channel_monthly_linkedin RESULTS",
                            results
                        );

                        // if (results && results[0].data) {
                        //     results[0].data.map((dataElm) => {
                        //         sumUserProfileClicks +=
                        //             dataElm.organic_metrics.user_profile_clicks;
                        //         sumImpressions +=
                        //             dataElm.organic_metrics.impression_count;
                        //         sumReply += dataElm.organic_metrics.reply_count;
                        //         sumRetweet +=
                        //             dataElm.organic_metrics.retweet_count;
                        //         sumLike += dataElm.organic_metrics.like_count;
                        //         sumQuote += dataElm.public_metrics.quote_count;
                        //         return true;
                        //     });
                        //     results[0].sum = {
                        //         sumUserProfileClicks,
                        //         sumImpressions,
                        //         sumReply,
                        //         sumRetweet,
                        //         sumLike,
                        //         sumQuote,
                        //     };
                        // }

                        break;

                    case "brand_basic":
                        const all_results =
                            await this.props.store.getBrandInsights(
                                values.brand,
                                values.date_range
                            );
                        console.info("all_results", all_results);
                        results = all_results;
                        break;
                    default:
                        break;
                }

                // stop if something went wrong
                if (!results) return;
                console.info("RESULTS", results, "VALUES", values);
                if (values.channels && values.channels.length > 0) {
                    results.map((r) => {
                        if (
                            base_report.channels_filter.includes("facebook") ||
                            base_report.channels_filter.includes("instagram")
                        ) {
                            if (r.fbRes) r.type = "facebook";
                            if (r.igRes) r.type = "instagram";
                            r.name = channels
                                .filter((ci) => {
                                    const channelInfo = ci.split("|");
                                    const channelId = channelInfo[2];
                                    if (r.info.channelId === channelId) {
                                        return channelInfo[4];
                                    }
                                    return false;
                                })[0]
                                .split("|")[4];

                            if (r.fbRes) {
                                r.fbRes.data.map((d) => {
                                    let sum = 0;
                                    d.values.forEach((v) => {
                                        if (!isNaN(v.value)) {
                                            sum = sum + Number(v.value);
                                        }
                                    });
                                    //console.info("sum = " + sum);
                                    d["sum"] = sum;
                                    return false;
                                });
                            }

                            if (r.prevPeriod) {
                                r.prevPeriod.data.map((d) => {
                                    let sum = 0;
                                    d.values.forEach((v) => {
                                        if (!isNaN(v.value)) {
                                            sum = sum + Number(v.value);
                                        }
                                    });
                                    //console.info("sum = " + sum);
                                    d["sum"] = sum;
                                    return false;
                                });
                            }
                            return false;
                        }

                        // Twitter report
                        if (base_report.channels_filter.includes("instagram")) {
                            // something???
                        }
                        return false;
                    });
                }

                // if (values.masterposts) {
                //     // masterpost
                // }

                this.setState({ results_separated: results, loading: false });
                console.info("results_separated", results);

                this.combineResults(results);
            });
        };

        combineResults = (results) => {
            let combined_results = [];
            let combined_prev_results = [];
            let temp_posts_prev_simplified = {};
            let temp_posts_simplified = {};

            if (results.length) {
                results.map((res) => {
                    // Facebook
                    if (res.fbRes) {
                        res.fbRes.data.map((fbResData) => {
                            // if (fbResData.name === "page_impressions") {
                            if (
                                typeof combined_results[fbResData.name] ===
                                "undefined"
                            ) {
                                combined_results[fbResData.name] = JSON.parse(
                                    JSON.stringify(fbResData)
                                );
                                let sum = 0;
                                fbResData.values.map((v) => {
                                    if (
                                        !isNaN(v.value) &&
                                        typeof v.value === "number"
                                    ) {
                                        sum = sum + Number(v.value);
                                    } else {
                                        //
                                        let tempSum = 0;
                                        if (v.value.like)
                                            tempSum =
                                                tempSum + Number(v.value.like);
                                        if (v.value.love)
                                            tempSum =
                                                tempSum + Number(v.value.love);
                                        if (v.value.wow)
                                            tempSum =
                                                tempSum + Number(v.value.wow);
                                        if (v.value.haha)
                                            tempSum =
                                                tempSum + Number(v.value.haha);
                                        if (v.value.sorry)
                                            tempSum =
                                                tempSum + Number(v.value.sorry);
                                        if (v.value.anger)
                                            tempSum =
                                                tempSum + Number(v.value.anger);

                                        sum = sum + Number(tempSum);
                                    }
                                    return false;
                                });
                                combined_results[fbResData.name]["sum"] = sum;
                            } else {
                                // Existing data (combine them)

                                fbResData.values.map((newValue) => {
                                    //found existing data
                                    // console.info("fbResData.name", fbResData.name);

                                    combined_results[fbResData.name].values.map(
                                        (existingValue, evIndex) => {
                                            if (
                                                existingValue.end_time ===
                                                newValue.end_time
                                            ) {
                                                if (!isNaN(newValue.value)) {
                                                    // console.info(
                                                    //     "Sum on existing value",
                                                    //     existingValue,
                                                    //     "existingValue.value",
                                                    //     existingValue.value,
                                                    //     "newValue.value",
                                                    //     newValue.value
                                                    // );
                                                    let newExistingValue =
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                existingValue
                                                            )
                                                        );

                                                    newExistingValue.value +=
                                                        Number(newValue.value);

                                                    combined_results[
                                                        fbResData.name
                                                    ].values[evIndex] =
                                                        newExistingValue;

                                                    // console.log(
                                                    //     "new existing value = " +
                                                    //         existingValue.value
                                                    // );
                                                } else {
                                                    // Object

                                                    let newExistingValue =
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                existingValue
                                                            )
                                                        );

                                                    let tempSum = 0;
                                                    if (newValue.value.like)
                                                        tempSum += Number(
                                                            newValue.value.like
                                                        );
                                                    if (newValue.value.love)
                                                        tempSum += Number(
                                                            newValue.value.love
                                                        );
                                                    if (newValue.value.wow)
                                                        tempSum += Number(
                                                            newValue.value.wow
                                                        );
                                                    if (newValue.value.haha)
                                                        tempSum += Number(
                                                            newValue.value.haha
                                                        );
                                                    if (newValue.value.sorry)
                                                        tempSum += Number(
                                                            newValue.value.sorry
                                                        );
                                                    if (newValue.value.anger)
                                                        tempSum += Number(
                                                            newValue.value.anger
                                                        );
                                                    console.log(
                                                        "NO SINGLE VALUE > tempSum=" +
                                                            tempSum
                                                    );

                                                    newExistingValue.value +=
                                                        Number(tempSum);

                                                    combined_results[
                                                        fbResData.name
                                                    ].values[evIndex] =
                                                        newExistingValue;
                                                }
                                            }
                                            return false;
                                        }
                                    );
                                    return false;
                                });
                            }
                            // } // for temp
                            return false;
                        });
                        //return false;
                    }

                    if (res.igRes) {
                        res.igRes.data.map((igResData) => {
                            // if (igResData.name === "page_impressions") {
                            if (
                                typeof combined_results[igResData.name] ===
                                "undefined"
                            ) {
                                combined_results[igResData.name] = JSON.parse(
                                    JSON.stringify(igResData)
                                );
                                let sum = 0;
                                igResData.values.map((v) => {
                                    if (!isNaN(v.value)) {
                                        sum = sum + Number(v.value);
                                    }
                                    return false;
                                });
                                combined_results[igResData.name]["sum"] = sum;
                            } else {
                                // Existing data (combine them)

                                igResData.values.map((newValue) => {
                                    //found existing data
                                    // console.info("igResData.name", igResData.name);

                                    combined_results[igResData.name].values.map(
                                        (existingValue, evIndex) => {
                                            if (
                                                existingValue.end_time ===
                                                newValue.end_time
                                            ) {
                                                if (!isNaN(newValue.value)) {
                                                    // console.info(
                                                    //     "Sum on existing value",
                                                    //     existingValue,
                                                    //     "existingValue.value",
                                                    //     existingValue.value,
                                                    //     "newValue.value",
                                                    //     newValue.value
                                                    // );
                                                    let newExistingValue =
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                existingValue
                                                            )
                                                        );

                                                    newExistingValue.value +=
                                                        Number(newValue.value);

                                                    combined_results[
                                                        igResData.name
                                                    ].values[evIndex] =
                                                        newExistingValue;

                                                    // console.log(
                                                    //     "new existing value = " +
                                                    //         existingValue.value
                                                    // );
                                                }
                                            }
                                            return false;
                                        }
                                    );
                                    return false;
                                });
                            }
                            // } // for temp
                            return false;
                        });
                    }

                    if (res.prevPeriod) {
                        res.prevPeriod.data.map((prevResData) => {
                            if (
                                typeof combined_prev_results[
                                    prevResData.name
                                ] === "undefined"
                            ) {
                                combined_prev_results[prevResData.name] =
                                    JSON.parse(JSON.stringify(prevResData));
                                let sum = 0;
                                prevResData.values.map((v) => {
                                    if (!isNaN(v.value)) {
                                        sum = sum + Number(v.value);
                                    }
                                    return false;
                                });
                                combined_prev_results[prevResData.name]["sum"] =
                                    sum;
                            } else {
                                // Existing data (combine them)

                                prevResData.values.map((newValue) => {
                                    //found existing data
                                    // console.info("prevResData.name", prevResData.name);

                                    combined_prev_results[
                                        prevResData.name
                                    ].values.map((existingValue, evIndex) => {
                                        if (
                                            existingValue.end_time ===
                                            newValue.end_time
                                        ) {
                                            if (!isNaN(newValue.value)) {
                                                // console.info(
                                                //     "Sum on existing value",
                                                //     existingValue,
                                                //     "existingValue.value",
                                                //     existingValue.value,
                                                //     "newValue.value",
                                                //     newValue.value
                                                // );
                                                let newExistingValue =
                                                    JSON.parse(
                                                        JSON.stringify(
                                                            existingValue
                                                        )
                                                    );

                                                newExistingValue.value +=
                                                    Number(newValue.value);

                                                combined_prev_results[
                                                    prevResData.name
                                                ].values[evIndex] =
                                                    newExistingValue;

                                                // console.log(
                                                //     "new existing value = " +
                                                //         existingValue.value
                                                // );
                                            }
                                        }
                                        return false;
                                    });
                                    return false;
                                });
                            }
                            // } // for temp
                            return false;
                        });
                    }

                    // posts Simplified totalCount
                    if (res.postsSimplified) {
                        if (
                            typeof combined_results[
                                "page_posts_simplified_summary_totalCount"
                            ] === "undefined"
                        ) {
                            combined_results[
                                "page_posts_simplified_summary_totalCount"
                            ] = Number(res.postsSimplified.summary.total_count);
                        } else {
                            let existingValue =
                                combined_results[
                                    "page_posts_simplified_summary_totalCount"
                                ];
                            existingValue += Number(
                                res.postsSimplified.summary.total_count
                            );
                            combined_results[
                                "page_posts_simplified_summary_totalCount"
                            ] = existingValue;
                        }
                    }

                    // posts simplified prevPeriod
                    if (res.prevPeriod && res.prevPeriod.postsSimplified) {
                        if (
                            typeof combined_prev_results[
                                "page_posts_simplified_summary_totalCount"
                            ] === "undefined"
                        ) {
                            combined_prev_results[
                                "page_posts_simplified_summary_totalCount"
                            ] = Number(
                                res.prevPeriod.postsSimplified.summary
                                    .total_count
                            );
                        } else {
                            let existingValue =
                                combined_prev_results[
                                    "page_posts_simplified_summary_totalCount"
                                ];
                            existingValue += Number(
                                res.prevPeriod.postsSimplified.summary
                                    .total_count
                            );
                            combined_prev_results[
                                "page_posts_simplified_summary_totalCount"
                            ] = existingValue;
                        }
                    }

                    // combine postsSimplified
                    // console.log("postsSimplified for name = " + res.name);
                    if (res.postsSimplified) {
                        res.postsSimplified.data.map((psd) => {
                            // console.info("postsSimplified psd", psd);
                            const psd_date = moment(psd.created_time).format(
                                "L"
                            );
                            if (
                                typeof temp_posts_simplified[psd_date] ===
                                "undefined"
                            )
                                temp_posts_simplified[psd_date] = [];
                            temp_posts_simplified[psd_date].push(psd);
                            return false;
                        });
                    }

                    // combine postsSimplified

                    if (res.prevPeriod && res.prevPeriod.postsSimplified) {
                        res.prevPeriod.postsSimplified.data.map((psd) => {
                            const psd_date = moment(psd.created_time).format(
                                "L"
                            );
                            if (
                                typeof temp_posts_prev_simplified[psd_date] ===
                                "undefined"
                            )
                                temp_posts_prev_simplified[psd_date] = [];
                            temp_posts_prev_simplified[psd_date].push(psd);
                            return false;
                        });
                    }
                    return false;
                });
            }

            let k;
            for (k in combined_results) {
                if (combined_results.hasOwnProperty(k)) {
                    let sum = 0;
                    if (combined_results[k].hasOwnProperty("values")) {
                        combined_results[k].values.map((v) => {
                            if (typeof v.value === "number") {
                                sum = sum + v.value;
                            } else {
                                // object
                                let tempSum = 0;
                                if (v.value.like)
                                    tempSum = tempSum + Number(v.value.like);
                                if (v.value.love)
                                    tempSum = tempSum + Number(v.value.love);
                                if (v.value.wow)
                                    tempSum = tempSum + Number(v.value.wow);
                                if (v.value.haha)
                                    tempSum = tempSum + Number(v.value.haha);
                                if (v.value.sorry)
                                    tempSum = tempSum + Number(v.value.sorry);
                                if (v.value.anger)
                                    tempSum = tempSum + Number(v.value.anger);

                                sum = sum + tempSum;
                            }

                            return false;
                        });

                        combined_results[k]["sum"] = combined_results[k][
                            "sum"
                        ] = sum;
                    }
                }
            }

            for (k in combined_prev_results) {
                if (combined_prev_results.hasOwnProperty(k)) {
                    let sum = 0;
                    if (combined_prev_results[k].hasOwnProperty("values")) {
                        combined_prev_results[k].values.map((v) => {
                            sum = sum + v.value;
                            return false;
                        });

                        combined_prev_results[k]["sum"] = combined_prev_results[
                            k
                        ]["sum"] = sum;
                    }
                }
            }

            if (typeof combined_results["posts_simplified"] === "undefined")
                combined_results["posts_simplified"] = [];
            Object.keys(temp_posts_simplified).forEach((item) => {
                //console.log(item);
                combined_results["posts_simplified"].push({
                    date: item,
                    value: temp_posts_simplified[item].length,
                });
            });

            if (
                typeof combined_prev_results["posts_simplified"] === "undefined"
            )
                combined_prev_results["posts_simplified"] = [];
            Object.keys(temp_posts_prev_simplified).forEach((item) => {
                console.log(item);
                combined_prev_results["posts_simplified"].push({
                    date: item,
                    value: temp_posts_prev_simplified[item].length,
                });
            });

            // combined_results.data.map(d => {
            //     let sum = 0;
            //     d.values.forEach(v => {
            //         if (!isNaN(v.value)) {
            //             sum = sum + Number(v.value);
            //         }
            //     });
            //     //console.info("sum = " + sum);
            //     d["sum"] = sum;
            // });

            // combineInsightsData (demographics)
            // Custom combineReports
            // clear the combined_reposts for the special combine reports
            if (results.length) {
                results.map((res) => {
                    combineInsightsData.map((ci) => {
                        delete combined_results[ci];
                        delete combined_prev_results[ci];
                        return false;
                    });

                    if (res.fbRes) {
                        res.fbRes.data.map((fbResData) => {
                            if (
                                combineInsightsData.indexOf(fbResData.name) !==
                                -1
                            ) {
                                // CombineInsights data

                                let data = JSON.parse(
                                    JSON.stringify(fbResData)
                                );
                                console.info(
                                    "combined_results > CombineInsights > " +
                                        fbResData.name,
                                    "data",
                                    data
                                );

                                combined_results[fbResData.name] = JSON.parse(
                                    JSON.stringify(fbResData)
                                );
                                let sumArrays = [];

                                fbResData.values.map((v) => {
                                    //const currentValuesObject = v.value;
                                    sumArrays.push(v.value);
                                    // if (!isNaN(v.value)) {
                                    //     sum = sum + Number(v.value);
                                    // }
                                    return false;
                                });

                                const mergeResult =
                                    social_reportsMergeValues(sumArrays);
                                console.info(
                                    "combined_results > sumArrays",
                                    sumArrays,
                                    "mergeResult",
                                    mergeResult
                                );
                                //social_reportsMergeArrays
                                combined_results[fbResData.name]["sum"] =
                                    mergeResult;
                            }
                            return false;
                        });
                    }
                    if (res.prevPeriod) {
                        res.prevPeriod.data.map((fbResData) => {
                            if (
                                combineInsightsData.indexOf(fbResData.name) !==
                                -1
                            ) {
                                // CombineInsights data

                                let data = JSON.parse(
                                    JSON.stringify(fbResData)
                                );
                                console.info(
                                    "combined_results > CombineInsights > " +
                                        fbResData.name,
                                    "data",
                                    data
                                );

                                combined_prev_results[fbResData.name] =
                                    JSON.parse(JSON.stringify(fbResData));
                                let sumArrays = [];

                                fbResData.values.map((v) => {
                                    //const currentValuesObject = v.value;
                                    sumArrays.push(v.value);
                                    // if (!isNaN(v.value)) {
                                    //     sum = sum + Number(v.value);
                                    // }
                                    return false;
                                });

                                const mergeResult =
                                    social_reportsMergeValues(sumArrays);
                                console.info(
                                    "combined_results > sumArrays",
                                    sumArrays,
                                    "mergeResult",
                                    mergeResult
                                );
                                //social_reportsMergeArrays
                                combined_prev_results[fbResData.name]["sum"] =
                                    mergeResult;
                            }
                            return false;
                        });
                    }
                    return false;
                });
            }
            // End of Demographics reports

            console.info("combined_results", combined_results);
            console.info("combined_prev_results", combined_prev_results);
            this.setState({
                combined_results,
                combined_prev_results,
                loading: false,
            });
        };

        onSelect = (selectedKeys, info) => {
            console.log("selected", selectedKeys, info);
        };

        renderChannelsNodes = (data) =>
            data.map((item) => {
                if (item.children) {
                    return (
                        <TreeNode
                            title={item.title}
                            key={item.key}
                            dataRef={item}
                        >
                            {this.renderChannelsNodes(item.children)}
                        </TreeNode>
                    );
                } else {
                    return (
                        <TreeNode
                            title={
                                <Fragment>
                                    <Icon
                                        type={item.type}
                                        theme={
                                            item.type !== "twitter"
                                                ? "filled"
                                                : null
                                        }
                                        style={{ marginRight: 5 }}
                                    />
                                    {item.title}
                                </Fragment>
                            }
                            key={item.key}
                            dataRef={item}
                        ></TreeNode>
                    );
                }
                //return <TreeNode key={item.key} {...item} />;
            });

        renderAdsNodes = (data) =>
            data.map((ad) => {
                return (
                    <TreeNode
                        title={
                            <Fragment>
                                <Icon
                                    type={ad.platform}
                                    theme="filled"
                                    style={{ marginRight: 5 }}
                                />
                                {ad.name}
                            </Fragment>
                        }
                        key={ad.key}
                        dataRef={ad}
                    ></TreeNode>
                );
                //return <TreeNode key={item.key} {...item} />;
            });

        onCheck = (checkedKeys) => {
            console.log("onCheck", checkedKeys);
            this.setState({ checkedKeys });
        };

        onAdsChange = (ad_key) => {
            console.log("onAdsChange", ad_key);
            const selectedAd = this.state.allAds.filter(
                (ad) => ad.key === ad_key
            )[0];
            console.log("selectedAd", selectedAd);
            this.setState({ report_ad: selectedAd });
        };

        onBrandChange = (brand) => {
            console.log("onBrandChange", brand);
            this.setState({ report_brand: brand });
        };

        prepareRenderElements = (report) => {
            console.info("prepareRenderElements report", report);
            let reportToReturn = null;

            if (report) {
                switch (report.renderKey) {
                    case "channel_monthly_facebook":
                        reportToReturn = (
                            <ReportResultChannelMonthlyFacebook
                                loading={this.state.loading}
                                results_channels={this.state.results_channels}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                combined_prev_results={
                                    this.state.combined_prev_results
                                }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;
                    case "channel_monthly_instagram":
                        reportToReturn = (
                            <ReportResultChannelMonthlyInstagram
                                loading={this.state.loading}
                                results_channels={this.state.results_channels}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                combined_prev_results={
                                    this.state.combined_prev_results
                                }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;
                    case "masterpost_basic_facebook":
                        reportToReturn = (
                            <ReportResultMasterpostMonthlyBasic
                                loading={this.state.loading}
                                // results_channels={this.state.results_channels}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                // combined_prev_results={
                                //     this.state.combined_prev_results
                                // }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;
                    case "ad_facebook_monthly":
                        reportToReturn = (
                            <ReportResultAdMonthlyFacebook
                                loading={this.state.loading}
                                report_ad={this.state.report_ad}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                // combined_prev_results={
                                //     this.state.combined_prev_results
                                // }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;
                    case "ad_linkedin_monthly":
                        reportToReturn = (
                            <ReportResultAdMonthlyLinkedin
                                loading={this.state.loading}
                                report_ad={this.state.report_ad}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                // combined_prev_results={
                                //     this.state.combined_prev_results
                                // }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;
                    case "channel_monthly_twitter":
                        reportToReturn = (
                            <ReportResultChannelMonthlyTwitter
                                loading={this.state.loading}
                                results_channels={this.state.results_channels}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                combined_prev_results={
                                    this.state.combined_prev_results
                                }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;
                    case "channel_monthly_linkedin":
                        reportToReturn = (
                            <ReportResultChannelMonthlyLinkedin
                                loading={this.state.loading}
                                results_channels={this.state.results_channels}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                combined_prev_results={
                                    this.state.combined_prev_results
                                }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;

                    case "brand_basic":
                        reportToReturn = (
                            <ReportResultBrandBasic
                                loading={this.state.loading}
                                // results_channels={this.state.results_channels}
                                report_brand={this.state.report_brand}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                // combined_prev_results={
                                //     this.state.combined_prev_results
                                // }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;
                    case "channel_monthly_youtube":
                        reportToReturn = (
                            <ReportResultChannelMonthlyYoutube
                                loading={this.state.loading}
                                results_channels={this.state.results_channels}
                                store={this.props.store}
                                combined_results={this.state.combined_results}
                                results_separated={this.state.results_separated}
                                combined_prev_results={
                                    this.state.combined_prev_results
                                }
                                toggleCustomReportSection={
                                    this.toggleCustomReportSection
                                }
                                custom_sections={this.state.custom_sections}
                                custom_report_flag={
                                    this.state.custom_report_flag
                                }
                            />
                        );
                        break;
                    default:
                        break;
                }
            }
            return reportToReturn;
        };

        toggleCustomReportSection = (_section) => {
            let { custom_sections } = this.state;
            if (custom_sections.indexOf(_section) === -1) {
                custom_sections.push(_section);
            } else {
                custom_sections.splice(custom_sections.indexOf(_section), 1);
            }
            this.setState({ custom_sections });
        };

        daterangeHandleOnChange = (dates, _fieldName) => {
            console.info("daterangeHandleOnChange dates", dates);
            const diffInDays = Math.abs(dates[0].diff(dates[1], "days"));
            console.info(
                "daterangeHandleOnChange diffInDays",
                diffInDays,
                "_fieldName",
                _fieldName
            );
            this.setState({
                daterangeValidateStatus: "error",
            });
            if (typeof this.props.handle_errors === "function") {
                if (diffInDays >= 30) {
                    this.props.handle_errors([
                        "Time interval should be less than 30 days.",
                    ]);
                } else {
                    // reset errors
                    this.props.handle_errors([]);
                }
            }
        };

        daterangeDisabledDate = (dates, partial) => {
            console.info(
                "daterangeDisabledDate dates",
                dates,
                "partial",
                partial
            );
            // Can not select days before today and today
            const [start, end] = dates;

            if (
                this.state.base_report.channels_filter.includes("twitter") ||
                this.state.base_report.channels_filter.includes("instagram")
            ) {
                return (
                    start && start < moment().subtract(30, "days").endOf("day")
                );
            } else {
                return null;
            }
        };

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;

            const base_report = this.props.report.original_report
                ? this.props.report.original_report
                : this.props.report;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            const reportToRender = this.prepareRenderElements(base_report);

            const resultsStep = (
                <div id="reportContainer">
                    <img
                        src={export_logo}
                        width="100"
                        height="42"
                        style={{ marginLeft: 10, float: "left" }}
                    />
                    {this.state.results_since ? (
                        <h3 style={{ textAlign: "right" }}>
                            Report range:
                            <br />
                            {this.state.results_since &&
                                this.state.results_since.format(
                                    "ddd, MMM Do YYYY"
                                )}{" "}
                            -{" "}
                            {this.state.results_until &&
                                this.state.results_until.format(
                                    "ddd, MMM Do YYYY"
                                )}
                        </h3>
                    ) : null}

                    {this.state.results_prevsince ? (
                        <h3
                            style={{
                                textAlign: "right",
                                color: scss_variables.scss_color_reportPrevColor,
                            }}
                        >
                            Report comparison range:
                            <br />
                            {this.state.results_prevsince &&
                                this.state.results_prevsince.format(
                                    "ddd, MMM Do YYYY"
                                )}{" "}
                            -{" "}
                            {this.state.results_prevuntil &&
                                this.state.results_prevuntil.format(
                                    "ddd, MMM Do YYYY"
                                )}
                        </h3>
                    ) : null}

                    {/* scss_color_reportPrevColor */}

                    {reportToRender}

                    {/* <pre>
                        {JSON.stringify(this.state.results_channels, null, 2)}
                    </pre> */}
                    {/* <pre>{JSON.stringify(this.state.results, null, 2)}</pre> */}
                </div>
            );

            const treeNodes = this.renderChannelsNodes(
                this.state.allAccounts.filter((aa) => {
                    if (aa.children.length > 0) return aa;
                    return false;
                })
            );

            // const treeAdsNodes = this.renderAdsNodes(this.state.allAds);
            console.info("this.state.allAccounts", this.state.allAccounts);

            const report_fields = this.props.report.original_report
                ? this.props.report.original_report.fields
                : this.props.report.fields;

            return (
                <div className="social_ReportModal">
                    <Steps
                        current={this.props.currentStep}
                        style={{ marginBottom: 40 }}
                    >
                        <Step
                            title="Parameters"
                            description="Fill the parameters"
                        />
                        <Step
                            title="Report"
                            // description="Generating the report"
                        />
                    </Steps>

                    {/* <pre>report = {JSON.stringify(report, null, 2)}</pre> */}

                    {this.props.currentStep === 0 ? (
                        <Form {...formItemLayout}>
                            {report_fields.map((field, fieldIndex) => {
                                let element = <Fragment>element</Fragment>;
                                switch (field.type) {
                                    case "daterange":
                                        element = (
                                            <Form.Item label={field.title}>
                                                {getFieldDecorator(field.name, {
                                                    rules: [
                                                        {
                                                            required:
                                                                field.required
                                                                    ? field.required
                                                                    : false,
                                                            message:
                                                                field.validation_message
                                                                    ? field.validation_message
                                                                    : null,
                                                        },
                                                    ],
                                                    validateStatus: this.state
                                                        .daterangeValidateStatus
                                                        ? this.state
                                                              .daterangeValidateStatus
                                                        : null,
                                                    //initialValue: data.billing
                                                })(
                                                    <RangePicker
                                                        //     onChange={onChange}
                                                        format={DATE_FORMAT}
                                                        ranges={
                                                            this.state
                                                                .base_report &&
                                                            !this.state.base_report.channels_filter.includes(
                                                                "twitter"
                                                            )
                                                                ? PREDEFINED_RANGES
                                                                : null
                                                        }
                                                        disabledTime={
                                                            this
                                                                .daterangeDisabledDate
                                                        }
                                                        onChange={(dates) => {
                                                            this.daterangeHandleOnChange(
                                                                dates,
                                                                field.name
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Form.Item>
                                        );
                                        break;

                                    case "date":
                                        element = (
                                            <Form.Item label={field.title}>
                                                {getFieldDecorator(field.name, {
                                                    rules: [
                                                        {
                                                            required:
                                                                field.required
                                                                    ? field.required
                                                                    : false,
                                                            message:
                                                                field.validation_message
                                                                    ? field.validation_message
                                                                    : null,
                                                        },
                                                    ],
                                                    //initialValue: data.billing
                                                })(
                                                    <DatePicker
                                                        //     onChange={onChange}
                                                        format={DATE_FORMAT}
                                                    />
                                                )}
                                            </Form.Item>
                                        );
                                        break;
                                    case "number":
                                        element = (
                                            <Form.Item label={field.title}>
                                                {getFieldDecorator(field.name, {
                                                    rules: [
                                                        {
                                                            required:
                                                                field.required
                                                                    ? field.required
                                                                    : false,
                                                            message:
                                                                field.validation_message
                                                                    ? field.validation_message
                                                                    : null,
                                                        },
                                                    ],
                                                    //initialValue: data.billing
                                                })(
                                                    <InputNumber
                                                        min={
                                                            field.min &&
                                                            field.min
                                                        }
                                                        max={
                                                            field.max &&
                                                            field.max
                                                        }
                                                    />
                                                )}
                                            </Form.Item>
                                        );
                                        break;

                                    case "channels":
                                        element = (
                                            <Form.Item label={field.title}>
                                                {getFieldDecorator(field.name, {
                                                    rules: [
                                                        {
                                                            // required: field.required
                                                            //     ? field.required
                                                            //     : false,
                                                            // message: field.validation_message
                                                            //     ? field.validation_message
                                                            //     : null
                                                        },
                                                        {
                                                            validator: (
                                                                rule,
                                                                value,
                                                                callback
                                                            ) => {
                                                                if (
                                                                    this.state
                                                                        .checkedKeys
                                                                        .length >
                                                                    0
                                                                ) {
                                                                    // callback(
                                                                    //     []
                                                                    // );
                                                                    return true;
                                                                } else {
                                                                    callback(
                                                                        field.validation_message
                                                                    );
                                                                }
                                                            },
                                                        },
                                                    ],
                                                    //initialValue: data.billing
                                                    //valuePropName: "checkedKeys"
                                                })(
                                                    <Tree
                                                        checkable
                                                        selectable={false}
                                                        // onExpand={this.onExpand}
                                                        // expandedKeys={this.state.expandedKeys}
                                                        // autoExpandParent={this.state.autoExpandParent}

                                                        onCheck={this.onCheck}
                                                        checkedKeys={
                                                            this.state
                                                                .checkedKeys
                                                        }
                                                        // onSelect={this.onSelect}
                                                        // selectedKeys={this.state.selectedKeys}
                                                        // style={{
                                                        //     marginTop: -15
                                                        // }}
                                                    >
                                                        {treeNodes}
                                                    </Tree>
                                                )}
                                            </Form.Item>
                                        );
                                        break;
                                    case "masterposts":
                                        element = (
                                            <Form.Item label={field.title}>
                                                {getFieldDecorator(field.name, {
                                                    rules: [
                                                        {
                                                            required:
                                                                field.required
                                                                    ? field.required
                                                                    : false,
                                                            message:
                                                                field.validation_message
                                                                    ? field.validation_message
                                                                    : null,
                                                        },
                                                    ],
                                                    initialValue: this.props
                                                        .report_masterpost
                                                        ? this.props
                                                              .report_masterpost
                                                              .key
                                                        : null,

                                                    //valuePropName: "checkedKeys"
                                                })(
                                                    <Select
                                                        showSearch
                                                        style={{ width: 200 }}
                                                        placeholder={
                                                            "Select " +
                                                            field.title
                                                        }
                                                        optionFilterProp="children"
                                                        // onChange={onChange}
                                                        // onFocus={onFocus}
                                                        // onBlur={onBlur}
                                                        // onSearch={onSearch}
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            option.props
                                                                .children &&
                                                            option.props.children
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    input.toLowerCase()
                                                                ) >= 0
                                                        }
                                                    >
                                                        {this.state.allMasterposts.map(
                                                            (masterpost) => {
                                                                return (
                                                                    <Option
                                                                        key={
                                                                            masterpost.key
                                                                        }
                                                                        value={
                                                                            masterpost.key
                                                                        }
                                                                    >
                                                                        {
                                                                            masterpost.name
                                                                        }
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        );
                                        break;
                                    case "ads":
                                        element = (
                                            <Form.Item label={field.title}>
                                                {getFieldDecorator(field.name, {
                                                    rules: [
                                                        {
                                                            required:
                                                                field.required
                                                                    ? field.required
                                                                    : false,
                                                            message:
                                                                field.validation_message
                                                                    ? field.validation_message
                                                                    : null,
                                                        },
                                                    ],
                                                    initialValue: this.props
                                                        .report_ad
                                                        ? this.props.report_ad
                                                              .key
                                                        : null,

                                                    //valuePropName: "checkedKeys"
                                                })(
                                                    <Select
                                                        showSearch
                                                        style={{ width: 400 }}
                                                        placeholder={
                                                            "Select " +
                                                            field.title
                                                        }
                                                        optionFilterProp="children"
                                                        onChange={
                                                            this.onAdsChange
                                                        }
                                                        // onFocus={onFocus}
                                                        // onBlur={onBlur}
                                                        // onSearch={onSearch}
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            option.props.children
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    input.toLowerCase()
                                                                ) >= 0
                                                        }
                                                    >
                                                        {this.state.allAds.map(
                                                            (ad) => {
                                                                return (
                                                                    <Option
                                                                        key={
                                                                            ad.key
                                                                        }
                                                                        value={
                                                                            ad.key
                                                                        }
                                                                    >
                                                                        {
                                                                            ad.name
                                                                        }
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        );
                                        break;
                                    case "brand":
                                        element = (
                                            <Form.Item label={field.title}>
                                                {getFieldDecorator(field.name, {
                                                    rules: [
                                                        {
                                                            required:
                                                                field.required
                                                                    ? field.required
                                                                    : false,
                                                            message:
                                                                field.validation_message
                                                                    ? field.validation_message
                                                                    : null,
                                                        },
                                                    ],
                                                    initialValue: this.props
                                                        .report_brand
                                                        ? this.props
                                                              .report_brand
                                                        : null,

                                                    //valuePropName: "checkedKeys"
                                                })(
                                                    <Select
                                                        showSearch
                                                        style={{
                                                            width: 460,
                                                        }}
                                                        placeholder={
                                                            "Select " +
                                                            field.title
                                                        }
                                                        optionFilterProp="children"
                                                        onChange={
                                                            this.onBrandChange
                                                        }
                                                        // onFocus={onFocus}
                                                        // onBlur={onBlur}
                                                        // onSearch={onSearch}
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            option.props.children
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    input.toLowerCase()
                                                                ) >= 0
                                                        }
                                                    >
                                                        {this.state.allBrands.map(
                                                            (
                                                                brand,
                                                                brandIndex
                                                            ) => {
                                                                return (
                                                                    <Option
                                                                        key={
                                                                            brandIndex
                                                                        }
                                                                        value={
                                                                            brand
                                                                        }
                                                                    >
                                                                        {brand}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        );
                                        break;
                                    default:
                                        break;
                                }

                                return (
                                    <Tooltip
                                        key={fieldIndex}
                                        title={
                                            field.description &&
                                            field.description
                                        }
                                    >
                                        {element}
                                    </Tooltip>
                                );
                            })}
                        </Form>
                    ) : null}

                    {this.props.currentStep === 1 ? resultsStep : null}
                </div>
            );
        }
    }
);

//export default ReportsModal;

/* Add/Edit Form */
class ReportsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            currentStep: 0,
            custom_report_flag: false,
            confirmLoading: false,
            exportingFlag: false,
            errors: [],
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener("reports_modal", (_action) => {
            this.setState({
                modal_visible: _action === "show" ? true : false,
                currentStep: _action === "show" ? 0 : this.state.currentStep,
            });
        });
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("reports_modal");
    };

    onModalApply = () => {
        // apply
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "reports_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    onExportReportToPDF = async () => {
        //alert("onExportReportToPDF");

        const { report } = this.props;
        //console.info("onExportReportToPDF this.props", this.props);

        let that = this;

        confirm({
            title: "Do you want to export this report?",
            content: "This process can take some time",
            onOk() {
                //console.log("OK");

                const PADDING = 20;
                // const doc = new jsPDF();
                // doc.text("Hello world!", 10, 10);
                // doc.save("a4.pdf");

                // let exportLogoImg = document.createElement("img");
                // exportLogoImg.width = 100;
                // exportLogoImg.height = 42;
                // exportLogoImg.src = EXPORT_SVG_LOGO_DATA_URL;
                // console.info("exportLogoImg", exportLogoImg);

                that.setState({ exportingFlag: true });
                html2canvas(document.querySelector("#reportContainer"), {
                    scale: "2",
                    // logging: false,
                }).then((canvas) => {
                    document.body.appendChild(canvas); // if you want see your screenshot in body.
                    const imgData = canvas.toDataURL("image/png");

                    const { width, height } = canvas.getBoundingClientRect();
                    //console.log("width = " + width + ", height = " + height);

                    const pdf = new jsPDF({
                        orientation: "portrait",
                        unit: "px",
                        format: [
                            Number(width + 2 * PADDING),
                            Number(height + 2 * PADDING),
                        ],
                    });
                    pdf.scaleFactor = 2;

                    pdf.addImage(
                        imgData,
                        "PNG",
                        PADDING,
                        PADDING,
                        width,
                        height
                    );

                    pdf.save(
                        report.name.toString().split(" ").join("_") +
                            "_" +
                            moment().format("YYYY-MM-DD") +
                            "_report.pdf"
                    );
                    that.setState({ exportingFlag: false });
                });
            },
        });
    };

    onSaveCustomReport = async () => {
        const formState = this.formRef.state;

        try {
            const name = await prompt({
                title: "Save custom report",
                placeholder: "Custom report name",
                rules: [
                    // check this link for more help: https://ant.design/components/form/#Validation-Rules
                    {
                        required: true,
                        message: "You must enter name",
                    },
                ],
                modalProps: {
                    width: "50%",
                },
            });
            const loggedUser = this.props.store.getLoggedUser;
            console.info("loggedUser", loggedUser);

            const { form } = this.formRef.props;
            console.info("formState", formState, "form", form);

            form.validateFields(async (err, values) => {
                // Adding checked keys
                values = { ...values, channels: formState.checkedKeys };

                await this.props.store.addCustomReport({
                    user: {
                        uid: loggedUser.uid,
                        //email: loggedUser.email
                    },
                    name: name,
                    original_report: this.props.report,
                    custom_sections: formState.custom_sections,
                    prefilled_fields: values,
                });

                this.props.store.displaySuccessNotification(
                    "Reports",
                    "Your custom report has been saved"
                );

                this.onModalCancel();
            });
        } catch (e) {
            message.error("Please enter name");
        }
    };

    handleOnForward = () => {
        console.info("onForward");

        this.formRef.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
                this.setState({ currentStep: 1 });

                // call the form method
                this.formRef.onForward();
            }
        });
    };

    displayErrors = () => {
        // console.info("displayErrors");
        Modal.error({
            title: "Errors List",
            content: (
                <div>
                    {this.state.errors &&
                        this.state.errors.map((warning, warningIndex) => (
                            <p key={warningIndex} style={{ margin: 0 }}>
                                - {warning}
                            </p>
                        ))}
                </div>
            ),
            onOk() {},
        });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        let base_report = null;
        if (this.state.modal_visible) {
            base_report = this.props.report.original_report
                ? this.props.report.original_report
                : this.props.report
                ? this.props.report
                : null;
        }

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        width={"90vw"}
                        title={
                            this.state.custom_report_flag
                                ? "CUSTOM REPORT: " + base_report.name
                                : base_report.name
                        }
                        okText={
                            this.state.currentStep === 0 ? (
                                <Fragment>
                                    Next <Icon type="right" />
                                </Fragment>
                            ) : (
                                "Close"
                            )
                        }
                        onCancel={this.onModalCancel}
                        onOk={
                            this.state.currentStep === 0
                                ? this.handleOnForward
                                : this.onModalCancel
                        }
                        footer={[
                            this.state.currentStep === 1 &&
                            !this.state.custom_report_flag ? (
                                <Button
                                    key="saveascustom"
                                    icon="save"
                                    onClick={this.onSaveCustomReport}
                                    style={{ float: "left" }}
                                >
                                    Save as custom ...
                                </Button>
                            ) : null,

                            this.state.currentStep === 1 ? (
                                <Button
                                    key="export"
                                    icon={
                                        this.state.exportingFlag === false
                                            ? "file-pdf"
                                            : "loading"
                                    }
                                    onClick={this.onExportReportToPDF}
                                    style={{ float: "left" }}
                                >
                                    Export to PDF...
                                </Button>
                            ) : null,

                            <Button key="close" onClick={this.onModalCancel}>
                                Close
                            </Button>,
                            <Fragment key="forward">
                                {this.state.errors &&
                                this.state.errors.length > 0 ? (
                                    <Tag
                                        color="red"
                                        style={{
                                            marginRight: 15,
                                            marginTop: 7,
                                            border: "none",
                                            cursor: "pointer",
                                        }}
                                        onClick={(e) => this.displayErrors()}
                                    >
                                        <Icon
                                            type="exclamation-circle" //<Icon type="check-circle" exclamation-circle />
                                            style={{
                                                marginRight: 7,
                                                color: "#f5222d",
                                            }}
                                        />
                                        {this.state.errors &&
                                        this.state.errors.length !== 1 ? (
                                            <span>
                                                There are{" "}
                                                {this.state.errors.length}{" "}
                                                warnings.&nbsp;
                                            </span>
                                        ) : (
                                            <span>
                                                There is one warning.&nbsp;
                                            </span>
                                        )}
                                        Click to see
                                    </Tag>
                                ) : null}
                                <Button
                                    type="primary"
                                    disabled={this.state.errors.length > 0}
                                    onClick={
                                        this.state.currentStep === 0
                                            ? this.handleOnForward
                                            : this.onModalCancel
                                    }
                                >
                                    Next
                                </Button>
                            </Fragment>,
                        ]}
                        maskClosable={false}
                        confirmLoading={this.state.confirmLoading}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            report={this.props.report}
                            report_account={this.props.report_account}
                            report_masterpost={this.props.report_masterpost}
                            report_ad={this.props.report_ad}
                            report_channel={this.props.report_channel}
                            report_brand={this.props.report_brand}
                            currentStep={this.state.currentStep}
                            handle_errors={(errors) => {
                                if (errors.length > 0) {
                                    this.setState({
                                        errors: [
                                            ...this.state.errors,
                                            ...errors,
                                        ],
                                    });
                                } else {
                                    this.setState({ errors: [] });
                                }
                            }}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(ReportsModal);
