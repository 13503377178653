import React, { Fragment } from "react";
import { Spin, Icon, Collapse, Row, Col, Table, Button } from "antd";

import scss_variables from "./../../_variables.scss";
// import SocialDataPresentor from "../SocialDataPresentor";
import moment from "moment";

// import { social_LANGUAGE_BY_LOCALE } from "../../functions";

const { Panel } = Collapse;

const GRID_GUTTER = 40;

const ReportResultAdMonthlyFacebook = (props) => {
    if (!props.report_ad) return <Fragment>Processing ...</Fragment>;

    let ad = Object.assign({}, props.report_ad);
    console.info("ad before", ad);

    ad.targeting_text = [];
    // targeting text

    if (ad.targeting_info.age) {
        ad.targeting_text.push(
            "Age: " + ad.targeting_info.age[0] + "-" + ad.targeting_info.age[1]
        );
    }
    if (ad.targeting_info.country) {
        ad.targeting_text.push(
            "Countries: " +
                ad.targeting_info.country
                    .map((country) => country.label)
                    .join(",")
        );
    }
    if (ad.targeting_info.gender) {
        ad.targeting_text.push(
            "Gender: " + ad.targeting_info.gender === "all"
                ? "All"
                : ad.targeting_info.gender === 1
                ? "Gender: Men"
                : "Gender: Women"
        );
    }
    if (ad.targeting_info.region) {
        ad.targeting_text.push(
            "Region: " +
                ad.targeting_info.region.map((region) => region.label).join(",")
        );
    }
    if (ad.targeting_info.city) {
        ad.targeting_text.push(
            "City: " +
                ad.targeting_info.city.map((city) => city.label).join(",")
        );
    }
    if (ad.targeting_info.language) {
        ad.targeting_text.push(
            "Languages: " +
                props.report_ad.targeting_info.language
                    .map((language) => language.label)
                    .join(",")
        );
    }

    // Insights
    ad.insights = props.results_separated && props.results_separated.insights;

    const adsOverviewPanel_platform = ad.platform;

    const adsOverviewPanel_name = ad.name;

    const adsOverviewPanel_starttime = moment(ad.start_time).format("l");
    const adsOverviewPanel_endtime = moment(ad.end_time).format("l");

    console.info("ad after", ad);
    console.info("ADMONTHLY props", props);

    const adsOverviewPanel = (
        <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
            <Col span={6}>
                <label>Platform</label>
                <h3>
                    <Icon
                        type={adsOverviewPanel_platform}
                        style={{ marginRight: 5 }}
                    />
                    {adsOverviewPanel_platform}
                </h3>
            </Col>
            <Col span={6}>
                <label>Name</label>
                <h3>{adsOverviewPanel_name}</h3>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
                <label>Start date</label>
                <h3>{adsOverviewPanel_starttime}</h3>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
                <label>End date</label>
                <h3>{adsOverviewPanel_endtime}</h3>
            </Col>
        </Row>
    );

    // -----------------------------------------

    const targetingPanel_text = ad.targeting_text && ad.targeting_text;

    const targetingPanel = (
        <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
            <Col span={24}>
                {targetingPanel_text.map((targetLine, targetLineIndex) => {
                    return (
                        <Fragment key={targetLineIndex}>
                            {targetLine}
                            <br />
                        </Fragment>
                    );
                })}
            </Col>
        </Row>
    );

    // -----------------------------------------

    const adsBasicPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={5}>
                    <label>Impressions</label>
                    <h3>{ad.insights && ad.insights.impressions}</h3>
                </Col>
                <Col span={5}>
                    <label>Reach</label>
                    <h3>{ad.insights && ad.insights.reach}</h3>
                </Col>
                <Col span={5}>
                    <label>Clicks</label>
                    <h3>{ad.insights && ad.insights.clicks}</h3>
                </Col>
                <Col span={5} style={{ textAlign: "right" }}>
                    <label>Spend</label>
                    <h3>
                        {ad.insights && ad.insights.spend}{" "}
                        {ad.insights && ad.insights.account_currency}{" "}
                    </h3>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                    <label>Frequency</label>
                    <h3>{ad.insights && ad.insights.frequency}</h3>
                </Col>
            </Row>
        </Fragment>
    );
    const adsCPCPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={6}>
                    <label>CPC</label>
                    <h3>{ad.insights && ad.insights.cpc}</h3>
                </Col>
                <Col span={6}>
                    <label>CPM</label>
                    <h3>{ad.insights && ad.insights.cpm}</h3>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <label>CPP</label>
                    <h3>{ad.insights && ad.insights.cpp}</h3>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <label>CTR</label>
                    <h3>{ad.insights && ad.insights.ctr}</h3>
                </Col>
            </Row>
        </Fragment>
    );
    const adsCostsPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={24}>
                    <label>Cost per action type</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.cost_per_action_type.map(
                                (el, elIndex) => {
                                    return (
                                        <div
                                            key={elIndex}
                                            style={{
                                                display: "block",
                                                fontSize: 13,
                                            }}
                                        >
                                            {el.action_type} - {el.value}
                                        </div>
                                    );
                                }
                            )}
                    </h3>
                </Col>
            </Row>
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={6}>
                    <label>Cost per inline link click</label>
                    <h3>
                        {ad.insights && ad.insights.cost_per_inline_link_click}
                    </h3>
                </Col>
                <Col span={6}>
                    <label>Cost per inline post engagement</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.cost_per_inline_post_engagement}
                    </h3>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <label>Cost per unique click</label>
                    <h3>{ad.insights && ad.insights.cost_per_unique_click}</h3>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    <label>Cost per unique inline link click</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.cost_per_unique_inline_link_click}
                    </h3>
                </Col>
            </Row>
        </Fragment>
    );

    const adsUniquePanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={24}>
                    <label>Unique actions</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.unique_actions.map((el, elIndex) => {
                                return (
                                    <div
                                        key={elIndex}
                                        style={{
                                            display: "block",
                                            fontSize: 13,
                                        }}
                                    >
                                        {el.action_type} - {el.value}
                                    </div>
                                );
                            })}
                    </h3>
                </Col>
            </Row>
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={5}>
                    <label>Clicks</label>
                    <h3>{ad.insights && ad.insights.unique_clicks}</h3>
                </Col>
                <Col span={4}>
                    <label>CTR</label>
                    <h3>{ad.insights && ad.insights.unique_ctr}</h3>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                    <label>Inline link click ctr</label>
                    <h3>
                        {ad.insights &&
                            ad.insights.unique_inline_link_click_ctr}
                    </h3>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                    <label>Inline link clicks</label>
                    <h3>
                        {ad.insights && ad.insights.unique_inline_link_clicks}
                    </h3>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                    <label>Link clicks CTR</label>
                    <h3>{ad.insights && ad.insights.unique_link_clicks_ctr}</h3>
                </Col>
            </Row>
        </Fragment>
    );

    // All values ----------------------
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Value",
            dataIndex: "value",
        },
        {
            title: "Description",
            dataIndex: "description",
        },
    ];
    const fields = [
        {
            key: "account_currency",
            description: "Currency that is used by your ad account.",
        },
        {
            key: "account_id",
            description:
                "The ID number of your ad account, which groups your advertising activity. Your ad account includes your campaigns, ads and billing.",
        },
        {
            key: "account_name",
            description:
                "The name of your ad account, which groups your advertising activity. Your ad account includes your campaigns, ads and billing.",
        },
        {
            key: "action_values",
            description:
                "The total value of all conversions attributed to your ads.",
        },
        {
            key: "actions",
            description:
                "The total number of actions people took that are attributed to your ads. Actions may include engagement, clicks or conversions.",
        },
        {
            key: "ad_id",
            description: "The unique ID of the ad you're viewing in reporting.",
        },
        {
            key: "ad_name",
            description: "The name of the ad you're viewing in reporting.",
        },
        {
            key: "adset_id",
            description:
                "The unique ID of the ad set you're viewing in reporting. An ad set is a group of ads that share the same budget, schedule, delivery optimization and targeting.",
        },
        {
            key: "adset_name",
            description:
                "The name of the ad set you're viewing in reporting. An ad set is a group of ads that share the same budget, schedule, delivery optimization and targeting.",
        },
        {
            key: "buying_type",
            description:
                "The method by which you pay for and target ads in your campaigns: through dynamic auction bidding, fixed-price bidding, or reach and frequency buying. This field is currently only visible at the campaign level.",
        },
        {
            key: "campaign_id",
            description:
                "The unique ID number of the ad campaign you're viewing in reporting. Your campaign contains ad sets and ads.",
        },
        {
            key: "campaign_name",
            description:
                "The name of the ad campaign you're viewing in reporting. Your campaign contains ad sets and ads.",
        },
        {
            key: "canvas_avg_view_percent",
            description:
                "The average percentage of the Instant Experience that people saw. An Instant Experience is a screen that opens after someone interacts with your ad on a mobile device. It may include a series of interactive or multimedia components, including video, images product catalog and more.",
        },
        {
            key: "canvas_avg_view_time",
            description:
                "The average total time, in seconds, that people spent viewing an Instant Experience. An Instant Experience is a screen that opens after someone interacts with your ad on a mobile device. It may include a series of interactive or multimedia components, including video, images product catalog and more.",
        },
        {
            key: "catalog_segment_value",
            description:
                "The total value of all conversions from your catalog segment attributed to your ads.",
        },
        { key: "clicks", description: "The number of clicks on your ads." },
        {
            key: "conversion_values",
            description:
                "The total value of all conversions attributed to your ads, including contact, customize_product, donate, find_location, schedule, start_trial, submit_application, subscribe.",
        },
        {
            key: "conversions",
            description:
                "The total number of conversions attributed to your ads, including contact, customize_product, donate, find_location, schedule, start_trial, submit_application, subscribe.",
        },
        {
            key: "converted_product_quantity",
            description:
                "The number of products purchased which are recorded by your merchant partner's pixel or app SDK for a given product ID and driven by your ads. Has to be used together with converted product ID breakdown.",
        },
        {
            key: "converted_product_value",
            description:
                "The value of purchases recorded by your merchant partner's pixel or app SDK for a given product ID and driven by your ads. Has to be used together with converted product ID breakdown.",
        },
        {
            key: "cost_per_action_type",
            description: "The average cost of a relevant action.",
        },
        {
            key: "cost_per_conversion",
            description:
                "The average cost of a conversion across all channels (i.e. website, mobile app, offline, and on-facebook). Conversions include contact, customize_product, donate, find_location, schedule, start_trial, submit_application, subscribe.",
        },
        {
            key: "cost_per_estimated_ad_recallers",
            description:
                "The average cost for each estimated ad recall lift. This metric is only available for assets in the Brand awareness, Post engagement and Video views Objectives. This metric is estimated and in development.",
        },
        {
            key: "cost_per_inline_link_click",
            description: "The average cost of each inline link click.",
        },
        {
            key: "cost_per_inline_post_engagement",
            description: "The average cost of each inline post engagement.",
        },
        {
            key: "cost_per_outbound_click",
            description: "The average cost for each outbound click.",
        },
        {
            key: "cost_per_thruplay",
            description:
                "The average cost for each ThruPlay. This metric is in development.",
        },
        {
            key: "cost_per_unique_action_type",
            description:
                "The average cost of each unique action. This metric is estimated.",
        },
        {
            key: "cost_per_unique_click",
            description:
                "The average cost for each unique click (all). This metric is estimated.",
        },
        {
            key: "cost_per_unique_inline_link_click",
            description:
                "The average cost of each unique inline link click. This metric is estimated.",
        },
        {
            key: "cost_per_unique_outbound_click",
            description:
                "The average cost for each unique outbound click. This metric is estimated.",
        },
        { key: "cost_per_store_visit_action", description: "" },
        { key: "cpc", description: "The average cost for each click (all)." },
        { key: "cpm", description: "The average cost for 1,000 impressions." },
        {
            key: "cpp",
            description:
                "The average cost to reach 1,000 people. This metric is estimated.",
        },
        {
            key: "ctr",
            description:
                "The percentage of times people saw your ad and performed a click (all).",
        },
        {
            key: "date_start",
            description:
                "The start date for your data. This is controlled by the date range you've selected for your reporting view.",
        },
        {
            key: "date_stop",
            description:
                "The end date for your data. This is controlled by the date range you've selected for your reporting view.",
        },
        {
            key: "estimated_ad_recall_rate",
            description:
                "The rate at which an estimated number of additional people, when asked, would remember seeing your ads within 2 days. This metric is only available for assets in the Brand awareness, Post engagement and Video views Objectives. This metric is estimated and in development.",
        },
        {
            key: "estimated_ad_recallers",
            description:
                "An estimate of the number of additional people who may remember seeing your ads, if asked, within 2 days. This metric is only available for assets in the Brand awareness, Post engagement and Video views Objectives. This metric is estimated and in development.",
        },
        {
            key: "frequency",
            description:
                "The average number of times each person saw your ad. This metric is estimated.",
        },
        {
            key: "full_view_impressions",
            description:
                "The number of Full Views on your Page's posts as a result of your ad.",
        },
        {
            key: "full_view_reach",
            description:
                "The number of people who performed a Full View on your Page's post as a result of your ad.",
        },
        {
            key: "impressions",
            description: "The number of times your ads were on screen.",
        },
        {
            key: "inline_link_click_ctr",
            description:
                "The percentage of time people saw your ads and performed an inline link click.",
        },
        {
            key: "inline_link_clicks",
            description:
                "The number of clicks on links to select destinations or experiences, on or off Facebook-owned properties. Inline link clicks use a fixed 1-day-click attribution window.",
        },
        {
            key: "inline_post_engagement",
            description:
                "The total number of actions that people take involving your ads. Inline post engagements use a fixed 1-day-click attribution window.",
        },
        {
            key: "instant_experience_clicks_to_open",
            description:
                "The number of clicks on your ad that open an Instant Experience. This metric is in development.",
        },
        {
            key: "instant_experience_clicks_to_start",
            description:
                "The number of times an interactive component in an Instant Experience starts. This metric is in development.",
        },
        {
            key: "instant_experience_outbound_clicks",
            description:
                "The number of clicks on links in an Instant Experience that take people off Facebook-owned properties. This metric is in development.",
        },
        {
            key: "mobile_app_purchase_roas",
            description:
                "The total return on ad spend (ROAS) from mobile app purchases. This is based on the value that you assigned when you set up the app event.",
        },
        {
            key: "objective",
            description:
                "The objective reflecting the goal you want to achieve with your advertising. It may be different from the selected objective of the campaign in some cases.",
        },
        {
            key: "outbound_clicks",
            description:
                "The number of clicks on links that take people off Facebook-owned properties.",
        },
        {
            key: "outbound_clicks_ctr",
            description:
                "The percentage of times people saw your ad and performed an outbound click.",
        },
        {
            key: "place_page_name",
            description:
                "The name of the place page involved in impression or click. Has to be used together with Business Locations breakdown.",
        },
        {
            key: "purchase_roas",
            description:
                "The total return on ad spend (ROAS) from purchases. This is based on information received from one or more of your connected Facebook Business Tools and attributed to your ads.",
        },
        {
            key: "qualifying_question_qualify_answer_rate",
            description: "qualifying_question_qualify_answer_rate",
        },
        {
            key: "reach",
            description:
                "The number of people who saw your ads at least once. Reach is different from impressions, which may include multiple views of your ads by the same people. This metric is estimated.",
        },
        {
            key: "social_spend",
            description:
                "The total amount you've spent so far for your ads showed with social information. (ex: Jane Doe likes this).",
        },
        {
            key: "spend",
            description:
                "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule. This metric is estimated.",
        },
        {
            key: "unique_actions",
            description:
                "The number of people who took an action that was attributed to your ads. This metric is estimated.",
        },
        {
            key: "unique_clicks",
            description:
                "The number of people who performed a click (all). This metric is estimated.",
        },
        {
            key: "unique_ctr",
            description:
                "The percentage of people who saw your ad and performed a unique click (all). This metric is estimated.",
        },
        {
            key: "unique_inline_link_click_ctr",
            description:
                "The percentage of times people saw your ad and performed a link click. Inline click-through rate uses a fixed 1-day-click attribution window. This metric is estimated.",
        },
        {
            key: "unique_inline_link_clicks",
            description:
                "The number of people who performed an inline link click. This metric is estimated.",
        },
        {
            key: "unique_link_clicks_ctr",
            description:
                "The percentage of people who saw your ad and performed a link click. This metric is estimated.",
        },
        {
            key: "unique_outbound_clicks",
            description:
                "The number of people who performed an outbound click. This metric is estimated.",
        },
        {
            key: "unique_outbound_clicks_ctr",
            description:
                "The percentage of people who saw your ad and performed an outbound click. This metric is estimated.",
        },
        {
            key: "video_30_sec_watched_actions",
            description:
                "The number of times your video played for at least 30 seconds, or for nearly its total length if it's shorter than 30 seconds. For each impression of a video, we'll count video views separately and exclude any time spent replaying the video.",
        },
        {
            key: "video_avg_time_watched_actions",
            description:
                "The average time a video was played, including any time spent replaying the video for a single impression.",
        },
        {
            key: "video_p100_watched_actions",
            description:
                "The number of times your video was played at 100% of its length, including plays that skipped to this point.",
        },
        {
            key: "video_p25_watched_actions",
            description:
                "The number of times your video was played at 25% of its length, including plays that skipped to this point.",
        },
        {
            key: "video_p50_watched_actions",
            description:
                "The number of times your video was played at 50% of its length, including plays that skipped to this point.",
        },
        {
            key: "video_p75_watched_actions",
            description:
                "The number of times your video was played at 75% of its length, including plays that skipped to this point.",
        },
        {
            key: "video_p95_watched_actions",
            description:
                "The number of times your video was played at 95% of its length, including plays that skipped to this point.",
        },
        {
            key: "video_play_actions",
            description:
                "The number of times your video starts to play. This is counted for each impression of a video, and excludes replays. This metric is in development.",
        },
        {
            key: "video_play_curve_actions",
            description:
                "A video-play based curve graph that illustrates the percentage of video plays that reached a given second. Entries 0 to 14 represent seconds 0 thru 14. Entries 15 to 17 represent second ranges [15 to 20), [20 to 25), and [25 to 30). Entries 18 to 20 represent second ranges [30 to 40), [40 to 50), and [50 to 60). Entry 21 represents plays over 60 seconds.",
        },
        {
            key: "website_ctr",
            description:
                "The percentage of times people saw your ad and performed a link click.",
        },
        {
            key: "website_purchase_roas",
            description:
                "The total return on ad spend (ROAS) from website purchases. This is based on the value of all conversions recorded by the Facebook pixel on your website and attributed to your ads.",
        },
    ];

    const data = [];
    if (ad.insights && Object.keys(ad.insights).length > 0) {
        fields.map((f, fIndex) => {
            //console.log(`f ${f.key} -> typeof ` + typeof ad.insights[f.key]);
            if (typeof ad.insights[f.key] !== "undefined") {
                if (typeof ad.insights[f.key] !== "object") {
                    data.push({
                        key: fIndex,
                        name: f.key,
                        value: <strong>{ad.insights[f.key]}</strong>,
                        description: f.description,
                    });
                } else {
                    // array of elements
                    const value = ad.insights[f.key].map((v, vIndex) => {
                        return (
                            <div
                                key={vIndex}
                                style={{ whiteSpace: "break-spaces" }}
                            >
                                <strong>
                                    {v["action_type"] + " - " + v["value"]}
                                </strong>
                            </div>
                        );
                    });
                    data.push({
                        key: fIndex,
                        name: f.key,
                        value: value,
                        description: f.description,
                    });
                }
            }
            return false;
        });
    }

    const adsAllValuesPanel = (
        <Fragment>
            <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
            />
        </Fragment>
    );

    // -------------------------

    const body = props.loading ? (
        <Fragment>
            {/* <pre>{JSON.stringify(props.report_ad, null, 2)}</pre> */}
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            <Collapse
                bordered={false}
                defaultActiveKey={["1", "2", "3", "4", "5", "6", "7"]}
            >
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_overview") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Ads Overview
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_overview"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_overview",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="1"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_overview") !== -1 &&
                            adsOverviewPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("targeting") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Targeting
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "targeting"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "targeting",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="2"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("targeting") !== -1 &&
                            targetingPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_basic") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Insights Basic
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_basic"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_basic",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="3"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_basic") !== -1 &&
                            adsBasicPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_cpc") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Insights CPC
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_cpc"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_cpc",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="4"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_cpc") !== -1 &&
                            adsCPCPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_costs") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Insights Costs
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_costs"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_costs",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="5"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_costs") !== -1 &&
                            adsCostsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_unique") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Insights Unique
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_unique"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_unique",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="6"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_unique") !== -1 &&
                            adsUniquePanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("ads_allvalues") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Insights All
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "ads_allvalues"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "ads_allvalues",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="7"
                        style={{ backgroundColor: "white" }}
                    >
                        {props.custom_sections.indexOf("ads_allvalues") !==
                            -1 && adsAllValuesPanel}
                    </Panel>
                ) : null}
            </Collapse>
        </Fragment>
    );

    return body;
};

export default ReportResultAdMonthlyFacebook;
