import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import MasterpostsList from "./MasterpostsList";
import MasterpostsStatus from "./MasterpostsStatus";
import MasterpostsTargets from "./MasterpostsTargets";
import names from "./../../names";

class Masterposts extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("masterposts");
        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            { title: names.get("MASTERPOST", true, true) },
        ]);
    }

    render() {
        return (
            <Switch>
                <Route
                    path="/dashboard/masterposts/:id/status"
                    render={(props) => (
                        <MasterpostsStatus
                            store={this.props.store}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/dashboard/masterposts/:id/targets"
                    render={(props) => (
                        <MasterpostsTargets
                            store={this.props.store}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/dashboard/masterposts/filter/:filter"
                    render={(props) => (
                        <MasterpostsList store={this.props.store} {...props} />
                    )}
                />
                <Route
                    render={(props) => (
                        <MasterpostsList store={this.props.store} {...props} />
                    )}
                />
            </Switch>
        );
    }
}

export default Masterposts;
