import React, { Component, Fragment } from "react";

import config from "./../../config";
import tooltips from "../../tooltips";

import { observer } from "mobx-react";
import moment from "moment";
import {
    Empty,
    List,
    Button,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Badge,
    Icon,
    Tag,
    Dropdown,
    Menu,
    Modal,
    Checkbox,
} from "antd";
import { social_notification } from "./../../functions";
import AdsListModal from "./AdsListModal";
import AdsListModalInsights from "./AdsListModalInsights";
import AdsListModalEdit from "./AdsListModalEdit";
import AdsListModalMasterEdit from "./AdsListModalMasterEdit";
import AdsListModalAdEdit from "./AdsListModalAdEdit";
import ReportsModal from "./../Reports/ReportsModal";
import prompt from "./../antd-prompt";
import SocialTag from "./../SocialTag";

import Bulk from "./../Bulk";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class AdsList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            //edit_data: {},
            pagination_currentPage: 1,
            openParents: [],
            list: [],
            masteradsWithAds: [],
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("ads");

        // Breadcrumbs
        // this.props.store.setBreadcrumbs([
        //     { route: `/dashboard/ads`, title: "Ads" },
        // ]);
    }
    componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);
        let that = this;
        if (this.props.store.masterads.length === 0) {
            this.props.store.callbacks.push({
                event: "masterads_updated",
                function() {
                    that.loadInformation();
                },
            });
        } else {
            // load the procedure
            this.loadInformation();
        }

        this.listBulk = new Bulk({
            name: "adsList",
            list: this.props.store.getAllAds.map((element) => element.key),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        //await this.props.store.deleteCategory(_key);
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                    combined: true,
                },
            ],
            callback: () => {
                console.log("READY");
                this.loadInformation();
            },
        });
    }

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("ads_modal");
        this.props.store.unregisterModalListener("reports_modal");
    }

    loadInformation = async () => {
        const allMasterposts = this.props.store.getAllMasterposts;
        const allMasterads = this.props.store.getAllMasterads;
        let results = [];

        const ads = this.props.store.getAllAds.filter((c) => {
            return (
                c.platform
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1
            );
        });

        console.info("ads", ads);
        ads.map((ad) => {
            let ad_obj = Object.assign({}, ad);
            // targeting text
            ad_obj.targeting_text = [];
            if (ad.targeting_info) {
                if (ad.targeting_info.age) {
                    ad_obj.targeting_text.push(
                        "Age: " +
                            ad.targeting_info.age[0] +
                            "-" +
                            ad.targeting_info.age[1]
                    );
                }
                if (ad.targeting_info.country) {
                    ad_obj.targeting_text.push(
                        "Countries: " +
                            ad.targeting_info.country
                                .map((country) => country.label)
                                .join(",")
                    );
                }
                if (ad.targeting_info.gender) {
                    ad_obj.targeting_text.push(
                        "Gender: " + ad.targeting_info.gender === "all"
                            ? "All"
                            : ad.targeting_info.gender === 1
                            ? "Gender: Men"
                            : "Gender: Women"
                    );
                }
                if (ad.targeting_info.region) {
                    ad_obj.targeting_text.push(
                        "Region: " +
                            ad.targeting_info.region
                                .map((region) => region.label)
                                .join(",")
                    );
                }
                if (ad.targeting_info.city) {
                    ad_obj.targeting_text.push(
                        "City: " +
                            ad.targeting_info.city
                                .map((city) => city.label)
                                .join(",")
                    );
                }
                if (ad.targeting_info.language) {
                    ad_obj.targeting_text.push(
                        "Languages: " +
                            ad.targeting_info.language
                                .map((language) => language.label)
                                .join(",")
                    );
                }
            }

            // TODO: We have to check !!!! the logic here
            if (ad.masterpost_key && !ad.masterad_key) {
                const masterpost = allMasterposts.filter(
                    (masterpost) => masterpost.key === ad.masterpost_key
                )[0];
                if (masterpost && masterpost.ad_model) {
                    // TODO: Having ad_model for masterpost object?? Not sure... check how it was done when making an ad from a masterpost
                    let found_result =
                        masterpost &&
                        results.filter(
                            (res) =>
                                res.parent && res.parent.key === masterpost.key
                        );
                    console.info("found_result", found_result);
                    if (found_result && found_result.length > 0) {
                        if (!found_result[0].children)
                            found_result[0].children = [];
                        found_result[0].children.push(ad_obj);
                    } else {
                        results.push({
                            ...masterpost,
                            type: "masterpost",
                            children: [ad_obj],
                        });
                    }
                }
            }

            // Masterad key of the ad
            if (ad.masterad_key) {
                // -----------------------------------
                const masterad = allMasterads.filter(
                    (masterad) => masterad.key === ad.masterad_key
                )[0];
                if (masterad && masterad.ad_model) {
                    let found_result =
                        masterad &&
                        results.filter((res) => res.key === masterad.key);
                    console.info("AdsList found_result", found_result);
                    if (found_result && found_result.length > 0) {
                        if (!found_result[0].children)
                            found_result[0].children = [];
                        found_result[0].children.push(ad_obj);
                    } else {
                        results.push({
                            ...masterad,
                            type: "masterad",
                            children: [ad_obj],
                        });
                    }
                }
            }
            // -------------------------------------------------------

            return false;
        });

        console.info("results", results);
        const masteradsWithAds = allMasterads.filter(
            (masterad) => masterad.ads && masterad.ads.length > 0
        );
        console.info("masteradsWithAds", masteradsWithAds);
        this.setState({ list: results, masteradsWithAds });
    };

    showModal = (_type, _state_to_duplicate) => {
        console.log("showModal");
        this.setState({
            edit_data: {},
        });

        this.props.store.showModalByKey("ads_modal", {
            type: _type,
            state_to_duplicate: _state_to_duplicate,
        });
    };
    // showModalForEdit = (_evt, _ad) => {
    //     console.info("showModalForEdit _evt", _evt, "_ad", _ad);

    //     this.setState({
    //         edit_data: _ad,
    //     });

    //     this.props.store.showModalByKey("ads_modal");
    // };

    handleDeleteAd = async (_evt, _ad) => {
        console.info("handleDeleteAd _evt", _evt, "_ad", _ad);
        let that = this;

        try {
            const ad_name = await prompt({
                title: "Delete ad",
                placeholder: "ad name",
                rules: [
                    // check this link for more help: https://ant.design/components/form/#Validation-Rules
                    {
                        required: true,
                        message: "You must enter the name of the ad",
                        type: "string",
                    },
                ],
                label: "Please enter ad's name",
                initialValue: "",
                modalProps: {
                    width: "50%",
                },
            });

            console.info("ad_name", ad_name);
            if (ad_name === _ad.name) {
                _ad.processing = true;
                that.setState({}); // Update the state for some reason
                let deleteAdResult = await that.props.store.deleteAd(_ad.key);
                console.info("deleteAdResult", deleteAdResult);

                if (
                    deleteAdResult.delete_ad_result.success === true &&
                    deleteAdResult.delete_adcreative_result.success === true &&
                    deleteAdResult.delete_adset_result.success === true &&
                    deleteAdResult.delete_campaign_result.success === true
                ) {
                    social_notification("delete_ad", "success");
                } else {
                    social_notification("delete_ad", "error");
                }
                _ad.processing = false;
                that.setState({}); // Update the state for some reason

                that.loadInformation();
            } else {
                social_notification("validation_error", "error", {
                    description: "Names do not match. Please try again.",
                });
                _ad.processing = false;
            }
        } catch (e) {
            console.warn(e);
            _ad.processing = false;
        }
    };

    handleDeleteMasterAd = async (_evt, _ad) => {
        console.info("handleDeleteMasterAd _evt", _evt, "_ad", _ad);
        let that = this;

        try {
            const ad_name = await prompt({
                title: "Delete Master ad",
                placeholder: "ad name",
                rules: [
                    // check this link for more help: https://ant.design/components/form/#Validation-Rules
                    {
                        required: true,
                        message: "You must enter the name of the ad",
                        type: "string",
                    },
                ],
                label: "Please enter master ad's name",
                initialValue: "",
                modalProps: {
                    width: "50%",
                },
            });

            console.info("ad_name", ad_name);
            if (ad_name === _ad.parent.name) {
                _ad.processing = true;
                let allAdsForThisMasterAd = this.props.store.getAllAds.filter(
                    (ad) => ad.masterad_key === _ad.parent.key
                );
                console.info("allAdsForThisMasterAd", allAdsForThisMasterAd);

                return await Promise.all(
                    allAdsForThisMasterAd.map((adToDelete) => {
                        // if (a.tags) {
                        //     a.tags = a.tags.filter((t) => t !== _tag);
                        //     this.asyncDeleteTag(a.key, a);
                        // }
                        console.info("adToDelete", adToDelete);
                        return this.props.store.deleteAd(adToDelete.key);
                    })
                )
                    .then(function (result) {
                        //store.updateAllTags();
                        let successFlag = true;
                        result.map((res) => {
                            if (
                                !res.delete_ad_result ||
                                !res.delete_adcreative_result ||
                                !res.delete_adset_result ||
                                !res.delete_campaign_result
                            ) {
                                successFlag = false;
                            }
                            if (
                                (res.delete_ad_result &&
                                    res.delete_ad_result.success !== true) ||
                                (res.delete_adcreative_result &&
                                    res.delete_adcreative_result.success !==
                                        true) ||
                                (res.delete_adset_result &&
                                    res.delete_adset_result.success !== true) ||
                                (res.delete_campaign_result &&
                                    res.delete_campaign_result.success !== true)
                            ) {
                                successFlag = false;
                            }
                            return false;
                        });
                        console.info(
                            "ALL DONE !!! - result",
                            result,
                            "successFlag",
                            successFlag
                        );

                        if (successFlag === false) {
                            social_notification("delete_ad", "error");
                        } else {
                            social_notification("delete_ad", "success");
                        }

                        _ad.processing = false;
                        that.setState({}); // Update the state for some reason

                        that.loadInformation();
                    })
                    .catch((error) => {
                        console.warn(error);
                        _ad.processing = false;
                    });

                // this.setState({}); // Update the state for some reason
                // let deleteAdResult = await this.props.store.deleteAd(_ad.key);
                // console.info("deleteAdResult", deleteAdResult);
                // if (
                //     deleteAdResult.delete_ad_result.success === true &&
                //     deleteAdResult.delete_adcreative_result.success === true &&
                //     deleteAdResult.delete_adset_result.success === true &&
                //     deleteAdResult.delete_campaign_result.success === true
                // ) {
                //     social_notification("delete_ad", "success");
                // } else {
                //     social_notification("delete_ad", "error");
                // }
                // _ad.processing = false;
                // this.setState({}); // Update the state for some reason
            } else {
                social_notification("validation_error", "error", {
                    description: "Names do not match. Please try again.",
                });
                _ad.processing = false;
            }
        } catch (e) {
            console.warn(e);
            _ad.processing = false;
        }
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    callback = (key) => {
        console.log("callback key=" + key);
    };

    handleMenuClick = async (_evt, _item, _parent_item) => {
        console.info(
            "handleMenuClick _evt.key",
            _evt.key,
            "_item",
            _item,
            "_parent_item",
            _parent_item
        );
        let that = this;
        // Masterad

        switch (_evt.key) {
            case "insights":
                this.showModalForInsights(_evt, _item);
                break;
            case "resume":
            case "pause":
                confirm({
                    title: "Do you want to pause/resume this ad?",
                    content:
                        "This may result in charging the ad account associated with that ad.",
                    okText: "Yes",
                    cancelText: "No",
                    onOk() {
                        console.log("OK");
                        _item.processing = true;
                        that.updateStateAd(_item);
                        that.updateAdStatus(
                            _item,
                            _item.status === "PAUSED" ? "ACTIVE" : "PAUSED"
                        );
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
                break;
            case "report":
                this.showModalReport(_item);
                break;
            case "masterad_edit":
                this.showModalForMasterEdit(_evt, _item);
                break;
            case "masterad_duplicate":
                if (_item.state_to_duplicate) {
                    this.showModal("ad", _item.state_to_duplicate);
                } else {
                    Modal.info({
                        title: "Not capable to be duplicated",
                        content:
                            "This master ad is not capable of being duplicated.",
                    });
                }
                //this.showModalForMasterEdit(_evt, _item);
                break;
            case "ad_edit":
                this.showModalForAdEdit(_evt, _item, _parent_item);
                break;

            case "delete":
                if (_item.parent_type) {
                    // parent
                    //this.showModalForEdit(_evt, _item);
                    await confirm({
                        title: "Do you want to delete this master ad?",
                        content: "You cannot undo this action.",
                        okText: "Yes",
                        cancelText: "No",
                        async onOk() {
                            console.log("OK");
                            await that.handleDeleteMasterAd(_evt, _item);
                        },
                        onCancel() {
                            console.log("Cancel");
                        },
                    });
                } else {
                    // ad itself
                    let that = this;

                    await confirm({
                        title: "Do you want to delete this ad?",
                        content: "You cannot undo this action.",
                        okText: "Yes",
                        cancelText: "No",
                        async onOk() {
                            console.log("OK");
                            await that.handleDeleteAd(_evt, _item);
                        },
                        onCancel() {
                            console.log("Cancel");
                        },
                    });
                }
                break;
            default:
                break;
        }
    };

    showModalForInsights = (_evt, _ad) => {
        //console.log("showModalForInsights _evt", _evt, "_ad", _ad);
        this.setState({
            edit_data: _ad,
        });

        this.props.store.showModalByKey("ads_modal_insights");
    };

    showModalForMasterEdit = (_evt, _item) => {
        console.info("showModalForMasterEdit _evt", _evt, "_item", _item);

        this.setState({
            edit_masterad_data: _item,
        });

        this.props.store.showModalByKey("ads_modal_master_edit");
    };
    showModalForAdEdit = (_evt, _item, _parent_item) => {
        console.info(
            "showModalForAdEdit _evt",
            _evt,
            "_item",
            _item,
            "_parent_item",
            _parent_item
        );

        this.setState({
            edit_ad_data: { ..._item, masterad: _parent_item },
        });

        this.props.store.showModalByKey("ads_modal_ad_edit");
    };

    showModalReport = (_ad) => {
        console.info("showModalReport _ad", _ad);
        let report = null;
        // facebook ad
        if (_ad.platform === "facebook") {
            report = this.props.store.getAllReports.filter(
                (report) => report.key === "ad_facebook_monthly"
            )[0];
        }
        // linkedin ad
        if (_ad.platform === "linkedin") {
            report = this.props.store.getAllReports.filter(
                (report) => report.key === "ad_linkedin_monthly"
            )[0];
        }
        this.setState({
            selected_report: report,
            report_ad: _ad,
        });

        this.props.store.showModalByKey("reports_modal");
        // this.props.store.registerModalListener("reports_modal", (_action) => {
        //     console.info("handleModalListener _action", _action);
        //     //if (_action === "hide") this.loadInformation();
        // });
    };

    updateAdStatus = (_ad, _status) => {
        let that = this;
        this.props.store.updateAdStatus(_ad.key, _status).then((results) => {
            console.info("updateAdStatus results", results);
            _ad.processing = false;
            _ad.status = _status;
            that.updateStateAd(_ad);
        });
    };

    updateStateAd = (_ad) => {
        let { masterposts } = this.state;

        masterposts &&
            masterposts.map((masterpost) => {
                masterpost.children.map((ad) => {
                    if (ad.key === _ad.key) {
                        ad = Object.assign({}, _ad);
                        ad.processing = false;
                    }
                    return false;
                });
                return false;
            });
        this.setState({ masterposts });
    };

    getListingDataSource = () => {
        console.info("this.state.list", this.state.list);

        const results = this.state.list;
        // const results = social_filterList(this.state.masteradsWithAds, this.state.filter, {
        //     type: "adslists",
        //     fields: [
        //         //{ name: "parent.name", type: "string" },
        //         // { name: "note", type: "string" },
        //         // { name: "categories", type: "array" },
        //         // { name: "campaigns", type: "array" },
        //         // { name: "publish_type", type: "string" },
        //         // {
        //         //     name: "children",
        //         //     type: "arrayOfObjects",
        //         //     arrayFieldName: "name",
        //         // },
        //         // {
        //         //     name: "children",
        //         //     type: "arrayOfObjects",
        //         //     arrayFieldName: "brand",
        //         // },
        //         // {
        //         //     name: "targets.location",
        //         //     type: "array"
        //         // }
        //     ],
        // });

        console.info("results", results);

        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    toggleOpenParents = (e, item) => {
        console.info("toggleOpenParents e item", e, item);
        let { openParents } = this.state;
        const key = item.parent ? item.parent.key : item.key;

        console.info("toggleOpenParents openParents", openParents);
        if (openParents.indexOf(key) === -1) {
            openParents.push(key);
        } else {
            openParents = openParents.filter((parent_key) => !key);
        }
        this.setState({ openParents });
    };

    adModalCallback = (_action) => {
        console.log("adModalCallback _action=" + _action);
        if (_action === "hide") {
            this.setState({});
            this.loadInformation();
        }
    };

    render() {
        const adsLength = this.props.store.getAllAdsLength;

        const pagesCount = adsLength / config.PAGINATION_PERPAGE;
        //console.log("pagesCount = " + pagesCount);

        const header = (
            <SocialListHeader
                title="Paid units"
                newButtonClickHandle={(e) => this.showModal("fb_boost")}
                newButtonText="Boost"
                newButtonTooltip={tooltips.ADS.NEWBUTTON}
                extraButtonClickHandle={(e) => this.showModal("ad")}
                extraButtonText="Ad"
                extraButtonTooltip={tooltips.ADS.NEWBUTTON}
                bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );

        // let header = (
        //     <Row
        //         type="flex"
        //         justify="space-between"
        //         style={{ marginBottom: 10 }}
        //     >
        //         <Col>
        //             <Title level={3} style={{ float: "left", paddingRight: 5 }}>
        //                 Paid units
        //             </Title>
        //             {/* <Badge
        //                 count={adsLength}
        //                 style={{ backgroundColor: "#1890ff" }}
        //             /> */}
        //         </Col>
        //         <Col>
        //             <Fragment>
        //                 <Tooltip
        //                     title={tooltips.ADS.NEWBUTTON}
        //                     mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
        //                 >
        //                     <Button
        //                         type="primary"
        //                         // icon="plus"
        //                         size="small"
        //                         onClick={(e) => this.showModal("fb_boost")}
        //                         style={{
        //                             marginLeft: 20,
        //                             marginTop: 5,
        //                             fontSize: 12,
        //                         }}
        //                     >
        //                         Boost{" "}
        //                         <Icon
        //                             type="facebook"
        //                             theme="filled"
        //                             style={{
        //                                 color: "white",
        //                             }}
        //                         />
        //                     </Button>
        //                 </Tooltip>

        //                 <Tooltip
        //                     title={tooltips.ADS.NEWBUTTON}
        //                     mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
        //                 >
        //                     <Button
        //                         type="primary"
        //                         // icon="plus"
        //                         size="small"
        //                         onClick={(e) => this.showModal("ad")}
        //                         style={{
        //                             marginLeft: 20,
        //                             marginTop: 5,
        //                             fontSize: 12,
        //                         }}
        //                     >
        //                         Ad
        //                     </Button>
        //                 </Tooltip>
        //             </Fragment>
        //         </Col>
        //     </Row>
        // );

        let body =
            adsLength === 0 ? (
                <Empty />
            ) : (
                <Fragment>
                    {/* {topList} */}
                    <List
                        size="small"
                        className="AdsList"
                        header={
                            <Fragment>
                                {/* <Row type="flex" justify="space-between">
                                    <Col
                                        style={{
                                            minWidth: 25,
                                            lineHeight: 1.2,
                                        }}
                                    >
                                        <Checkbox
                                            // onChange={e => this.handleBulkAll(e)}
                                            onChange={(e) =>
                                                this.listBulk.handleAll(
                                                    e.target.checked
                                                )
                                            }
                                            style={{ marginRight: 10 }}
                                        />
                                        {this.listBulk &&
                                            this.listBulk.renderOptionsAsList()}
                                    </Col>
                                    <Col>
                               
                                        <Search
                                            placeholder="filter"
                                            onSearch={this.onSearchChange}
                                            onChange={(e) =>
                                                this.onSearchChange(
                                                    e.target.value
                                                )
                                            }
                                            style={{ width: 200 }}
                                        />
                                    </Col>
                                </Row> */}
                                <Row
                                    style={{
                                        marginTop: 10,
                                        fontSize: 12,
                                        display: "flex",
                                        flex: "1 1",
                                        flexAlign: "start",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Col
                                        style={{
                                            width: 25,
                                            lineHeight: 1.2,
                                        }}
                                    >
                                        {/* <Checkbox
                                            // onChange={e => this.handleBulkAll(e)}
                                            onChange={(e) =>
                                                this.listBulk.handleAll(
                                                    e.target.checked
                                                )
                                            }
                                        /> */}
                                    </Col>
                                    <Col
                                        style={{
                                            marginRight: 20,
                                            flexGrow: 1,
                                            width: "10%",
                                        }}
                                    >
                                        Name
                                    </Col>
                                    <Col
                                        style={{
                                            flexGrow: 1,
                                            width: "10%",
                                        }}
                                    >
                                        Type
                                    </Col>
                                    <Col
                                        style={{
                                            flexGrow: 1,
                                            width: "10%",
                                        }}
                                    >
                                        Brand
                                    </Col>
                                    <Col
                                        style={{
                                            flexGrow: 1,
                                            width: "10%",
                                        }}
                                    >
                                        Campaigns
                                    </Col>
                                    <Col
                                        style={{
                                            flexGrow: 1,
                                            width: "10%",
                                        }}
                                    >
                                        Duration
                                    </Col>
                                    <Col
                                        style={{
                                            flexGrow: 1,
                                            width: "10%",
                                        }}
                                    >
                                        Status
                                    </Col>
                                    <Col style={{ width: 90 }}>Tools</Col>
                                </Row>
                            </Fragment>
                        }
                        footer={
                            pagesCount > 1 && this.state.filter.length === 0 ? (
                                <div style={{ textAlign: "right" }}>
                                    <Pagination
                                        size="small"
                                        defaultCurrent={1}
                                        total={adsLength}
                                        pageSize={config.PAGINATION_PERPAGE}
                                        current={
                                            this.state.pagination_currentPage
                                        }
                                        onChange={this.paginationSetPage}
                                    />
                                </div>
                            ) : null
                        }
                        bordered
                        dataSource={this.getListingDataSource()}
                        renderItem={(item, itemIndex) => (
                            <List.Item key={itemIndex}>
                                {/* <List.Item.Meta
                                    title={

                                    }
                                /> */}
                                <div
                                    style={{
                                        width: "100%",
                                        display: "grid",
                                    }}
                                    className={"social_AdsMasteradRow"}
                                >
                                    <div>
                                        <div
                                            onClick={(e) =>
                                                this.toggleOpenParents(e, item)
                                            }
                                        >
                                            <Icon
                                                type={
                                                    this.state.openParents.indexOf(
                                                        item.key
                                                    ) !== -1
                                                        ? "caret-down"
                                                        : "caret-right"
                                                }
                                                style={{
                                                    marginRight: 5,
                                                    cursor: "pointer",
                                                    float: "left",
                                                    marginTop: 4,
                                                }}
                                            />{" "}
                                            <strong>{item.name}</strong>
                                        </div>
                                        <div
                                            onClick={(e) =>
                                                this.toggleOpenParents(e, item)
                                            }
                                        >
                                            {item.brand}
                                        </div>
                                        <div
                                            onClick={(e) =>
                                                this.toggleOpenParents(e, item)
                                            }
                                        >
                                            {item.campaigns &&
                                                item.campaigns.map(
                                                    (campaign) => (
                                                        <SocialTag
                                                            key={campaign}
                                                            store={
                                                                this.props.store
                                                            }
                                                            color="campaign"
                                                        >
                                                            {campaign}
                                                        </SocialTag>
                                                    )
                                                )}
                                        </div>
                                        <div>
                                            {item.processing ? (
                                                <Icon type="loading" />
                                            ) : null}

                                            <Dropdown
                                                overlay={
                                                    <Menu
                                                        onClick={(e) =>
                                                            this.handleMenuClick(
                                                                e,
                                                                item
                                                            )
                                                        }
                                                    >
                                                        <Menu.Item key="masterad_duplicate">
                                                            <Icon type="copy" />
                                                            Duplicate
                                                        </Menu.Item>
                                                        <Menu.Item key="masterad_edit">
                                                            <Icon type="edit" />
                                                            Edit
                                                        </Menu.Item>
                                                        {item.status ===
                                                        "PAUSED" ? (
                                                            <Menu.Item
                                                                key="masterad_resume"
                                                                disabled
                                                            >
                                                                <Icon type="caret-right" />
                                                                Resume
                                                            </Menu.Item>
                                                        ) : (
                                                            <Menu.Item
                                                                key="masterad_pause"
                                                                disabled
                                                            >
                                                                <Icon type="pause" />
                                                                Pause
                                                            </Menu.Item>
                                                        )}

                                                        <Menu.Item
                                                            key="masterad_report"
                                                            disabled
                                                        >
                                                            <Icon type="pie-chart" />
                                                            Report
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            key="masterad_delete"
                                                            // disabled
                                                        >
                                                            <Icon type="delete" />
                                                            Delete
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                            >
                                                <Icon
                                                    type="more"
                                                    style={{
                                                        float: "right",
                                                        padding: 3,
                                                    }}
                                                />
                                            </Dropdown>
                                        </div>
                                    </div>
                                    {this.state.openParents.indexOf(
                                        item.key
                                    ) !== -1 ? (
                                        <div
                                            style={{
                                                display: "block",
                                                width: "100%",
                                                // border: "1px solid",
                                            }}
                                        >
                                            {this.state.openParents.indexOf(
                                                item.key
                                            ) !== -1 &&
                                                item.children.map(
                                                    (ad, adIndex) => (
                                                        <div
                                                            key={adIndex}
                                                            style={{
                                                                margin: "5px 0",
                                                                padding:
                                                                    "5px 0 0 0",
                                                                borderTop:
                                                                    "1px solid #f0f0f0",
                                                            }}
                                                        >
                                                            {/* <pre>
                                                            {JSON.stringify(
                                                                ad,
                                                                null,
                                                                2
                                                            )}
                                                        </pre> */}
                                                            <div
                                                                style={{
                                                                    textAling:
                                                                        "left",
                                                                    paddingLeft: 0,
                                                                    overflow:
                                                                        "hidden",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.listBulk.handleCheck(
                                                                            ad.key
                                                                        )
                                                                    }
                                                                    checked={
                                                                        this
                                                                            .listBulk
                                                                            ? this.listBulk.isChecked(
                                                                                  ad.key
                                                                              )
                                                                            : false
                                                                    }
                                                                    style={{
                                                                        marginRight: 10,
                                                                    }}
                                                                />
                                                                <Tooltip
                                                                    overlayStyle={{
                                                                        whiteSpace:
                                                                            "pre",
                                                                    }}
                                                                    // title={
                                                                    //     "Targeting:" +
                                                                    //     "\n" +
                                                                    //     ad.targeting_text.join(
                                                                    //         "\n"
                                                                    //     )
                                                                    // }
                                                                >
                                                                    <Icon
                                                                        type="info-circle"
                                                                        theme="filled"
                                                                        style={{
                                                                            marginRight: 5,
                                                                            cursor: "pointer",
                                                                            color: this.props.store.getTagColorForType(
                                                                                "info"
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                <div
                                                                    className={
                                                                        "social_iconCircle_" +
                                                                        ad.platform
                                                                    }
                                                                    style={{
                                                                        width: 16,
                                                                        height: 10,
                                                                        marginRight: 10,
                                                                        display:
                                                                            "inline-block",
                                                                        marginBottom:
                                                                            -1,
                                                                    }}
                                                                />
                                                                {/* <Icon
                                                            type={ad.platform}
                                                            theme={"filled"}
                                                            style={{
                                                                marginRight: 5,
                                                                color: this.props.store.getTagColorForType(
                                                                    ad.platform
                                                                ),
                                                            }}
                                                        /> */}
                                                                {ad.title}
                                                                {/* {ad.image_bytes ? (
                                                                <div
                                                                    className={
                                                                        "social_Ads_AdImage"
                                                                    }
                                                                    style={{
                                                                        backgroundImage:
                                                                            "url(data:image/jpeg;base64," +
                                                                            ad.image_bytes +
                                                                            ")",
                                                                    }}
                                                                />
                                                            ) : null} */}
                                                            </div>
                                                            <div>
                                                                {ad.platform ===
                                                                    "facebook" &&
                                                                ad.type ===
                                                                    "singleimagead"
                                                                    ? "Facebook Single Image Ad"
                                                                    : null}
                                                                {ad.platform ===
                                                                    "facebook" &&
                                                                ad.type ===
                                                                    "carouselad"
                                                                    ? "Facebook Carousel Ad"
                                                                    : null}
                                                                {ad.platform ===
                                                                "linkedin"
                                                                    ? "Linkedin Ad"
                                                                    : null}
                                                            </div>

                                                            <div>
                                                                {moment(
                                                                    ad.start_time
                                                                ).format("l")}
                                                                -
                                                                {moment(
                                                                    ad.end_time
                                                                ).format("l")}
                                                            </div>
                                                            <div>
                                                                <Tag
                                                                    color={
                                                                        ad.status ===
                                                                        "PAUSED"
                                                                            ? "blue"
                                                                            : "#52c41a"
                                                                    }
                                                                >
                                                                    {ad.status}
                                                                </Tag>
                                                            </div>
                                                            <div>
                                                                {ad.processing ? (
                                                                    <Icon type="loading" />
                                                                ) : null}

                                                                <Dropdown
                                                                    overlay={
                                                                        <Menu
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                this.handleMenuClick(
                                                                                    e,
                                                                                    ad,
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            <Menu.Item
                                                                                key="ad_edit"
                                                                                // disabled
                                                                            >
                                                                                <Icon type="edit" />
                                                                                Edit
                                                                            </Menu.Item>
                                                                            {ad.status ===
                                                                            "PAUSED" ? (
                                                                                <Menu.Item key="resume">
                                                                                    <Icon type="caret-right" />
                                                                                    Resume
                                                                                </Menu.Item>
                                                                            ) : (
                                                                                <Menu.Item key="pause">
                                                                                    <Icon type="pause" />
                                                                                    Pause
                                                                                </Menu.Item>
                                                                            )}

                                                                            <Menu.Item key="insights">
                                                                                <Icon type="line-chart" />
                                                                                Insights
                                                                            </Menu.Item>
                                                                            <Menu.Item key="report">
                                                                                <Icon type="pie-chart" />
                                                                                Report
                                                                            </Menu.Item>
                                                                            <Menu.Item
                                                                                key="delete"
                                                                                // disabled
                                                                            >
                                                                                <Icon type="delete" />
                                                                                Delete
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    }
                                                                >
                                                                    <Icon
                                                                        type="more"
                                                                        style={{
                                                                            float: "right",
                                                                            padding: 3,
                                                                        }}
                                                                    />
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    ) : null}
                                </div>
                            </List.Item>
                        )}
                    />
                </Fragment>
            );

        return (
            <Fragment>
                {header}
                {body}
                <AdsListModal
                    data={this.state.edit_data}
                    store={this.props.store}
                    callback={this.adModalCallback}
                />
                <AdsListModalInsights
                    data={this.state.edit_data}
                    store={this.props.store}
                />
                <AdsListModalEdit
                    data={this.state.edit_data}
                    store={this.props.store}
                />
                <AdsListModalMasterEdit
                    data={this.state.edit_masterad_data}
                    store={this.props.store}
                />
                <AdsListModalAdEdit
                    data={this.state.edit_ad_data}
                    store={this.props.store}
                />
                <ReportsModal
                    report={this.state.selected_report}
                    report_ad={this.state.report_ad}
                    store={this.props.store}
                />
            </Fragment>
        );
    }
}

export default observer(AdsList);
