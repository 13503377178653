import React, { Component } from "react";
import { DatePicker, Row, Col, Divider, Button, Input, Empty } from "antd";
import InboxChainSingleChain from "./InboxChainSingleChain";
import { social_sleep } from "./../../functions";
import ReactJson from "react-json-view";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Search } = Input;

class InboxOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unread_posts: [],
            chains: [],
            original_range_start:
                props.overview_filter &&
                props.overview_filter.original_range_start
                    ? props.overview_filter.original_range_start
                    : null,
            original_range_end:
                props.overview_filter &&
                props.overview_filter.original_range_end
                    ? props.overview_filter.original_range_end
                    : moment().valueOf(),
            range_start:
                props.overview_filter && props.overview_filter.range_start
                    ? props.overview_filter.range_start
                    : null,
            range_end:
                props.overview_filter && props.overview_filter.range_end
                    ? props.overview_filter.range_end
                    : moment().valueOf(),
            range_date_format: "DD/MM/YYYY",
            filter_keyword:
                props.overview_filter && props.overview_filter.filter_keyword
                    ? props.overview_filter.filter_keyword
                    : "",
        };
    }

    componentDidMount = async () => {
        if (this.props.chains) {
            this.setState({ chains: this.props.chains });
            this.parseInfo();
        }
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.chains !== this.props.chains) {
            this.setState({ chains: this.props.chains });
            this.parseInfo();
        }
    };

    parseInfo = async () => {
        await social_sleep(100);
        let unread_posts = [];
        this.state.chains
            // .filter((chain) => chain.read === false) // filter only the unread chains
            .map((chain) => {
                let found = false;
                let curChain = chain.chain;
                let tempChain = Object.assign({}, chain);
                delete tempChain.chain;

                Object.keys(curChain)
                    .sort()
                    .reverse()
                    .forEach((key) => {
                        //console.log("key=" + key);
                        let post = curChain[key];

                        // chain settings
                        if (
                            !chain.min_timestamp ||
                            chain.min_timestamp > post.timestamp
                        ) {
                            chain.min_timestamp = post.timestamp;
                        }
                        if (
                            !chain.max_timestamp ||
                            chain.max_timestamp < post.timestamp
                        ) {
                            chain.max_timestamp = post.timestamp;
                        }

                        if (
                            !this.state.range_start ||
                            this.state.range_start > post.timestamp
                        )
                            this.setState({
                                range_start: post.timestamp,
                                original_range_start: post.timestamp,
                            });

                        if (
                            !this.state.range_end ||
                            this.state.range_end < post.timestamp
                        )
                            this.setState({
                                range_end: post.timestamp,
                                original_range_end: post.timestamp,
                            });

                        //console.log("TIMESTAMP> " + post.timestamp);

                        //console.log("BODY> " + post.body);
                        if (found === false && chain.read === false) {
                            if (!post.readStamp) {
                                //console.log("Winner !!!");
                                let mainChain = Object.assign({}, chain);
                                delete mainChain.chain;
                                if (!tempChain.unread_posts)
                                    tempChain.unread_posts = [];
                                tempChain.unread_posts.push(post);
                                //unread_posts.push({post, chain: mainChain});
                            } else {
                                found = true; // Break out
                            }
                        }
                    });

                if (
                    tempChain.unread_posts &&
                    tempChain.unread_posts.length > 0
                ) {
                    unread_posts.push(tempChain);
                }
            });
        //console.info("unread_posts", unread_posts);
        this.setState({ unread_posts });
    };

    onChangeFilter = (_value) => {
        this.setState({
            filter_keyword: _value,
        });
        if (this.props.handleUpdateOverviewFilter) {
            this.props.handleUpdateOverviewFilter({
                filter_keyword: _value,
            });
        }
    };

    onChange = (dates, datesString) => {
        const [rangeStart, rangeEnd] = dates;
        //   console.log(dates, datesString);
        this.setState({
            range_start: rangeStart.valueOf(),
            range_end: rangeEnd.valueOf(),
        });
        if (this.props.handleUpdateOverviewFilter) {
            this.props.handleUpdateOverviewFilter({
                range_start: rangeStart.valueOf(),
                range_end: rangeEnd.valueOf(),
            });
        }
    };

    onReset = () => {
        this.setState({
            range_start: this.state.original_range_start,
            range_end: this.state.original_range_end,
            filter_keyword: "",
        });
    };

    getUnreadPosts = (_chains) => {
        let unread_posts = [];
        _chains
            // .filter((chain) => chain.read === false) // filter only the unread chains
            .map((chain) => {
                let found = false;
                let curChain = chain.chain;
                let tempChain = Object.assign({}, chain);
                delete tempChain.chain;

                Object.keys(curChain)
                    .sort()
                    .reverse()
                    .forEach((key) => {
                        //console.log("key=" + key);
                        let post = curChain[key];
                        if (found === false && chain.read === false) {
                            if (!post.readStamp) {
                                //console.log("Winner !!!");
                                let mainChain = Object.assign({}, chain);
                                delete mainChain.chain;
                                if (!tempChain.unread_posts)
                                    tempChain.unread_posts = [];
                                tempChain.unread_posts.push(post);
                                //unread_posts.push({post, chain: mainChain});
                            } else {
                                found = true; // Break out
                            }
                        }
                    });

                if (
                    tempChain.unread_posts &&
                    tempChain.unread_posts.length > 0
                ) {
                    unread_posts.push(tempChain);
                }
            });
        //console.info("unread_posts", unread_posts);
        return unread_posts;
    };

    getFilteredChains = () => {
        const { range_start, range_end, filter_keyword, chains } = this.state;
        let filtered_chains = JSON.parse(JSON.stringify(chains));

        const filter_keywords =
            filter_keyword.indexOf(",") !== -1
                ? filter_keyword.split(",")
                : filter_keyword.indexOf(" ") !== -1
                ? filter_keyword.split(" ")
                : [filter_keyword];
        //console.info('filter_keywords', filter_keywords);

        // filter for range
        filtered_chains = filtered_chains.filter(
            (chain) =>
                chain.max_timestamp <= range_end &&
                chain.min_timestamp >= range_start
        );

        if (filter_keyword && filter_keyword.length > 0) {
            filtered_chains = filtered_chains.filter((chain) => {
                let return_value = false;

                // chain our user
                if (chain.ourUser && chain.ourUser.name) {
                    filter_keywords.map((keyword) => {
                        // console.log('keyword='+keyword);
                        // console.log('chain.ourUser.name='+chain.ourUser.name);
                        if (
                            chain.ourUser.name
                                .toUpperCase()
                                .indexOf(keyword.toUpperCase()) != -1
                        ) {
                            return_value = true;
                        }
                    });
                }

                // chain other user
                if (chain.oppositeUser && chain.oppositeUser.name) {
                    filter_keywords.map((keyword) => {
                        // console.log('keyword='+keyword);
                        // console.log('chain.ourUser.name='+chain.ourUser.name);
                        if (
                            chain.oppositeUser.name
                                .toUpperCase()
                                .indexOf(keyword.toUpperCase()) != -1
                        ) {
                            return_value = true;
                        }
                    });
                }

                //console.info('chain', chain);

                chain.chain.map((post) => {
                    filter_keywords.filter((keyword) => {
                        // console.log('keyword='+keyword);
                        // console.log('chain.ourUser.name='+chain.ourUser.name);
                        if (
                            post.body
                                .toUpperCase()
                                .indexOf(keyword.toUpperCase()) != -1
                        ) {
                            return_value = true;
                        }
                    });
                });

                //console.log('return_value = '+return_value);
                return return_value;
            });
        }
        //   console.info("getFilteredChains return -> ", filtered_chains);
        return filtered_chains;
    };

    render() {
        let range_start = this.state.range_start
            ? moment(this.state.range_start).format(
                  this.state.range_date_format
              )
            : null;
        let range_end = this.state.range_end
            ? moment(this.state.range_end).format(this.state.range_date_format)
            : null;

        let filtered_chains = this.getFilteredChains();
        //console.info("!!filtered_chains", filtered_chains);

        let unread_posts = this.getUnreadPosts(filtered_chains);
        //console.info("!!unread_posts", unread_posts);

        return (
            <div>
                <Row gutter={10} className="social_InboxOverviewFilter">
                    <Col span={10}>
                        <RangePicker
                            onChange={this.onChange}
                            value={
                                this.state.range_start && this.state.range_end
                                    ? [
                                          moment(
                                              range_start,
                                              this.state.range_date_format
                                          ),
                                          moment(
                                              range_end,
                                              this.state.range_date_format
                                          ),
                                      ]
                                    : null
                            }
                            format={this.state.range_date_format}
                        />
                    </Col>
                    <Col span={8}>
                        <Search
                            placeholder="Filter by keyword"
                            onSearch={(value) => this.onChangeFilter(value)}
                            onChange={(e) =>
                                this.onChangeFilter(e.target.value)
                            }
                            allowClear={true}
                        />

                        {/* <Input
                            placeholder="Filter by keyword"
                            onChange={(e) =>
                                this.onChangeFilter(e.target.value)
                            }
                            value={this.state.filter_keyword}
                        /> */}
                    </Col>
                    <Col span={6} style={{ textAlign: "right" }}>
                        <Button icon="close" onClick={(e) => this.onReset()}>
                            Reset
                        </Button>
                    </Col>
                </Row>
                {/* <Divider /> */}
                {/* <ReactJson
                    src={this.props.overview_filter}
                    collapsed={false}
                    displayDataTypes={false}
                /> */}

                {/* <ReactJson
                    src={filtered_chains}
                    collapsed={true}
                    displayDataTypes={false}
                /> */}
                {/* <
                <pre>
                    filter_keyword ={" "}
                    {JSON.stringify(this.state.filter_keyword, null, 2)}
                </pre> */}
                <br />
                <br />
                {unread_posts.map((uchain, uchainIndex) => (
                    <InboxChainSingleChain
                        key={uchainIndex}
                        store={this.props.store}
                        postToChain={this.props.postToChain}
                        clickOnChain={(e) =>
                            this.props.clickOnChain(uchain.key)
                        }
                        markChainAsRead={(e) =>
                            this.props.markChainAsRead(uchain.key)
                        }
                        chain={uchain}
                    />
                ))}

                {filtered_chains && filtered_chains.length > 0 ? (
                    <Divider>&nbsp;Read Conversations&nbsp;</Divider>
                ) : null}

                {filtered_chains &&
                    filtered_chains
                        .filter((chain) => chain.read === true)
                        .sort((a, b) => (a.updated < b.updated ? 1 : -1))
                        .map((chain) => (
                            <div
                                key={chain.key}
                                className={"social_InboxReadChainElement"}
                                onClick={(e) =>
                                    this.props.clickOnChain(chain.key, e)
                                }
                            >
                                <div
                                    style={{
                                        float: "left",
                                    }}
                                >
                                    {/* Channel:{" "} */}
                                    <div
                                        className={
                                            chain.platform === "facebook"
                                                ? "social_iconFacebook"
                                                : chain.platform === "twitter"
                                                ? "social_iconTwitter"
                                                : chain.platform === "instagram"
                                                ? "social_iconInstagram"
                                                : chain.platform === "linkedin"
                                                ? "social_iconLinkedin"
                                                : null
                                        }
                                        style={{
                                            backgroundColor:
                                                this.props.store.getTagColorForType(
                                                    chain.platform
                                                ),
                                            borderRadius: "100%",
                                            width: 17,
                                            height: 17,
                                            backgroundSize: 13,
                                            marginLeft: 0,
                                            marginBottom: 10,
                                            marginRight: 10,
                                            marginTop: 2,
                                            float: "left",
                                        }}
                                    />
                                    <strong>
                                        {chain.ourUser.name}{" "}
                                        {chain.ourUser.brand
                                            ? "(" + chain.ourUser.brand + ")"
                                            : null}{" "}
                                    </strong>
                                    <br />({chain.oppositeUser.name})
                                </div>
                                <div style={{ float: "right" }}>
                                    Latest:
                                    <br />
                                    {moment(chain.updated).format("llll")}
                                </div>
                            </div>
                        ))}

                {filtered_chains && filtered_chains.length === 0 ? (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No mathching data for the filters."
                    />
                ) : null}
            </div>
        );
    }
}

export default InboxOverview;
