import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import {
    StripeProvider,
    Elements,
    CardElement,
    injectStripe,
} from "react-stripe-elements";
import CryptoJS from "crypto-js";
import {
    social_notification,
    social_sleep,
    social_setCookie,
} from "./../functions";
import config from "../config";

// import lorem from "../lorem-lorem.svg";
import PrivacyModal from "./PrivacyModal";
import TermsModal from "./TermsModal";

import { app } from "../base";

import { Button, Divider, Form, Input, Icon, Radio, Tag, Row, Col } from "antd";

const WorldForm = Form.create({ name: "form_in_modal" })(
    injectStripe(
        // eslint-disable-next-line
        class inlineForm extends Component {
            constructor(props) {
                super(props);
                this.state = {};
            }

            render() {
                const { stripe, form } = this.props;
                const { getFieldDecorator } = form;

                return (
                    <div className={"Register"}>
                        <div className={"Header"}>
                            <div className={"Logo"}></div>
                            <div>
                                {/* <Button
                                    className="login-form-button"
                                    onClick={() => {
                                        this.props.handleRegister();
                                    }}
                                >
                                    Sign up
                                </Button> */}
                                <Button
                                    className="login-form-button"
                                    onClick={() => {
                                        this.props.handleLogin();
                                    }}
                                >
                                    Log-in
                                </Button>
                            </div>
                        </div>

                        <div className={"Container"}>
                            <div className={"Limiter"}>
                                <div className={"Box"}>
                                    <h1>Registering new world</h1>
                                    Please enter the name of the new world.
                                    <Divider />
                                    {/* <pre>
                                    world_name ={" "}
                                    {JSON.stringify(this.state, null, 2)}
                                </pre> */}
                                    <Form.Item label="World Name">
                                        {getFieldDecorator("world_name", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        "Please input the name of the world",
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Enter world name"
                                                onChange={(e) => {
                                                    this.props.handleWorldNameChange(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Administrator Email">
                                        {getFieldDecorator("email", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        "Please input the email of the account!",
                                                    type: "email",
                                                },
                                            ],
                                        })(
                                            <Input
                                                onChange={(e) => {
                                                    this.props.handleWorldEmailChange(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        )}

                                        {/* <Input
                                        placeholder="Email"
                                        onChange={(e) => {
                                            this.props.handleWorldEmailChange(
                                                e.target.value
                                            );
                                        }}
                                        type="email"
                                    /> */}
                                    </Form.Item>
                                    <Form.Item label="Plan">
                                        <Radio.Group
                                            onChange={this.props.onPlanChange}
                                            defaultValue={
                                                config.worldPlans[0].key
                                            }
                                        >
                                            {config.worldPlans.map(
                                                (wPlan, wPlanIndex) => (
                                                    <Radio.Button
                                                        key={wPlanIndex}
                                                        value={wPlan.key}
                                                    >
                                                        {wPlan.name}
                                                    </Radio.Button>
                                                )
                                            )}
                                        </Radio.Group>
                                        <br />
                                        Max Accounts:{" "}
                                        {this.props.plan.maxAccounts}
                                        <br />
                                        <strong>
                                            Price: ${this.props.plan.amount}
                                        </strong>
                                    </Form.Item>
                                    <Divider />
                                    <Form.Item label="Payment">
                                        <br />
                                        <CardElement />
                                    </Form.Item>
                                    <br />
                                    <br />
                                    <br />
                                    <Row>
                                        <Col
                                            span={24}
                                            style={{ textAlign: "right" }}
                                        >
                                            {this.props.fetching &&
                                            this.props.world_name.length > 0 &&
                                            this.props.email.length > 0 ? (
                                                <Tag
                                                    color="blue"
                                                    style={{ marginRight: 30 }}
                                                >
                                                    Checking ...
                                                </Tag>
                                            ) : null}

                                            {!this.props.fetching &&
                                            this.props.valid &&
                                            this.props.world_name.length > 0 &&
                                            this.props.email.length > 0 ? (
                                                <Tag
                                                    color="green"
                                                    style={{ marginRight: 30 }}
                                                >
                                                    This world is available for
                                                    registration
                                                </Tag>
                                            ) : null}

                                            {!this.props.fetching &&
                                            this.props.world_name.length > 0 &&
                                            this.props.email.length > 0 &&
                                            !this.props.valid ? (
                                                <Tag
                                                    color="red"
                                                    style={{ marginRight: 30 }}
                                                >
                                                    The name or the email is
                                                    already used
                                                </Tag>
                                            ) : null}
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="register-form-button"
                                                style={{
                                                    width: "unset",
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    margin: 0,
                                                }}
                                                onClick={() => {
                                                    this.props.handleRegister(
                                                        this.props.stripe
                                                    );
                                                }}
                                                disabled={
                                                    !this.props.valid ||
                                                    this.props.valid ===
                                                        false ||
                                                    (this.props.processing &&
                                                        this.props
                                                            .processing ===
                                                            true)
                                                }
                                            >
                                                {this.props.processing &&
                                                this.props.processing ===
                                                    true ? (
                                                    <Icon
                                                        type="loading"
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                    />
                                                ) : null}
                                                Register new world
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div className={"Footer"}>
                            <div className={"Limiter"}>
                                <PrivacyModal store={this.props.store} />
                                <TermsModal store={this.props.store} />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);

class World extends Component {
    state = {};
    typeTimer = null;

    constructor(props) {
        super(props);
        //   this.authWithFacebook = this.authWithFacebook.bind(this);

        this.state = {
            redirect: false,
            valid: null,
            plan: config.worldPlans[0],
            email: "",
            processing: false,
            world_name: "",
        };
    }

    handleLogin = () => {
        this.setState({ redirect: "/login" });
    };

    handleRegister = async (stripe) => {
        console.info("handleRegister stripe", stripe, "state", this.state);

        const { email, world_name, plan } = this.state;

        if (email.length === 0) {
            social_notification("validation_error", "error", {
                title: "Verification error",
                description: "Please enter a valid email address",
            });
            return false;
        }

        this.setState({ processing: true });

        try {
            // stripe token
            let token = await stripe.createToken({
                name: email,
            });
            console.info("token", token);
            if (token.error) {
                social_notification("validation_error", "error", {
                    title: "Verification error",
                    description: token.error.message,
                });
                return false;
            }

            // creating the world
            const worldData = await this.props.store.worldsRegisterNew({
                name: world_name,
                email: email,
                plan_key: plan.key,
                max_accounts: plan.maxAccounts,
            });
            console.info("worldData", worldData);

            // Pay subscription to the world
            let payWorldSubscriptionResult =
                await this.props.store.payWorldSubscription({
                    token: token.token,
                    amount: plan.amount,
                    payment_data: {
                        world_key: worldData.key,
                        world_email: email,
                        plan: plan,
                    },
                });
            console.info(
                "payWorldSubscriptionResult",
                payWorldSubscriptionResult
            );

            // Redirect to the new world's register page
            var ciphertext = CryptoJS.AES.encrypt(
                worldData.key,
                config.cryptoJsSecret
            );
            console.log("encrypted text", ciphertext.toString());
            var bytes = CryptoJS.AES.decrypt(
                ciphertext.toString(),
                config.cryptoJsSecret
            );
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            console.log("decrypted text", plaintext);

            this.setState({
                redirect:
                    "/register/" + encodeURIComponent(ciphertext.toString()),
            });
        } catch (error) {
            console.warn(error);
        }
    };

    handleWorldNameChange = (_name) => {
        console.log("handleWorldNameChange _name=" + _name);
        this.setState({ world_name: _name });
        this.checkValidation();
    };

    handleWorldEmailChange = (_email) => {
        console.log("handleWorldEmailChange _email=" + _email);
        this.setState({ email: _email });
        this.checkValidation();
    };

    checkValidation = () => {
        const that = this;
        if (this.typeTimer) clearTimeout(this.typeTimer);
        this.typeTimer = setTimeout(function () {
            that.setState({ fetching: true });
            const { world_name, email } = that.state;

            if (world_name.length === 0 || email.length === 0) {
                that.setState({ valid: false });
            } else {
                console.info(
                    "that.props",
                    that.props,
                    "world_name",
                    world_name
                );
                that.props.store
                    .worldsCheckByNameAndEmail(world_name, email)
                    .then((data) => {
                        console.info("data", data);
                        const valid =
                            data.count === 0 && world_name.length > 0
                                ? true
                                : false;
                        that.setState({ fetching: false, valid });
                    });
            }
        }, 750);
    };

    onPlanChange = (e) => {
        this.setState({
            plan: config.worldPlans.filter((w) => w.key === e.target.value)[0],
        });
    };

    onSubmit = (e) => {};

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirect} />;
        }
        //console.info("app.auth().currentUser", app.auth().currentUser);

        //const loggedUser = this.props.store.getLoggedUser;
        // console.info("loggedUser", loggedUser);
        //this.state.authenticated

        let body = (
            <Fragment>
                <StripeProvider apiKey={config.stripe.apiKey}>
                    <Elements>
                        <WorldForm
                            store={this.props.store}
                            handleLogin={this.handleLogin}
                            handleRegister={this.handleRegister}
                            handleWorldNameChange={this.handleWorldNameChange}
                            onPlanChange={this.onPlanChange}
                            handleWorldEmailChange={this.handleWorldEmailChange}
                            plan={this.state.plan}
                            valid={this.state.valid}
                            fetching={this.state.fetching}
                            processing={this.state.processing}
                            world_name={this.state.world_name}
                            email={this.state.email}
                        />
                    </Elements>
                </StripeProvider>
            </Fragment>
        );

        if (app.auth().currentUser) {
            body = (
                <Fragment>
                    <div className={"Register"}>
                        <div className={"Header"}>
                            <div className={"Limiter"}>
                                <div className={"Logo"}></div>
                                <Button
                                    className="login-form-button"
                                    onClick={() => {
                                        this.handleLogin();
                                    }}
                                >
                                    Back
                                </Button>
                            </div>
                        </div>

                        <div className={"Container"}>
                            <div className={"Limiter"}>
                                <div className={"Box"}>
                                    <h2>You are logged in.</h2>
                                    You have to logout in order to access the
                                    Register section.
                                    <Divider />
                                    <Button
                                        type={"primary"}
                                        // style={{ padding: 0 }}
                                        onClick={() => {
                                            this.handleDashboard();
                                        }}
                                    >
                                        Please click here to go back to the
                                        Dashboard
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className={"Footer"}>
                            <div className={"Limiter"}>
                                <PrivacyModal store={this.props.store} />
                                <TermsModal store={this.props.store} />
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
        return body;
    }
}

export default World;
