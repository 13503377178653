import React, { Component, Fragment } from "react";

import moment from "moment";

import { observer } from "mobx-react";
import AccountsQueueModal from "./../Accounts/AccountsQueueModal";
import {
    Form,
    Modal,
    Divider,
    Button,
    Icon,
    Tabs,
    Empty,
    Menu,
    Row,
    Col,
    Dropdown,
    Badge,
    Typography,
    List,
    Tag,
    Tooltip,
    Timeline,
} from "antd";
import SocialTag from "./../SocialTag";
import SocialErrorSymbol from "./../SocialErrorSymbol";
import names from "./../../names";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

/* Modal Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
                filter_status: "all",
                filter_sort: "datetime",
                filter_sort_label: "Date/Time",
            };
        }

        componentDidMount = async () => {
            const masterpost = this.props.store.getAllMasterposts.filter(
                (m) => m.key === this.props.data.key
            )[0];

            const masterpostStatus = this.props.store.getMasterpostStatus(
                this.props.data.key
            );

            // Get channels affected
            await masterpost.targets.map(async (target) => {
                target.channels_affected = await this.getTargetChannelsAffected(
                    target
                );
            });

            // get accounts channels information
            await masterpostStatus.posts.map(async (post, pIndex) => {
                post.account_channel =
                    await this.props.store.getAccountChannels(post.account.key);

                if (post.channels_ids) {
                    post.account_channel = post.account_channel.filter(
                        (channel) => post.channels_ids.includes(channel.id)
                    )[0];
                }
                if (post.channel_id) {
                    post.account_channel = post.account_channel.filter(
                        (channel) => post.channel_id === channel.id
                    )[0];
                }

                //temp
                // if (pIndex === 0) {
                //     post.errors = [
                //         "Just a simple error",
                //         "Another simple error",
                //     ];
                // }
            });

            console.info("masterpostStatus", masterpostStatus);
            this.setState({
                masterpost,
                masterpost_posts: masterpostStatus.posts,
            });

            let total_post_impressions = 0,
                total_post_clicks = 0,
                total_post_reactions_like = 0,
                total_post_reactions_love = 0,
                total_post_reactions_wow = 0,
                total_post_reactions_haha = 0,
                total_post_reactions_sorry = 0,
                total_post_reactions_anger = 0,
                total_post_comments = 0;
            masterpostStatus.posts.map((post) => {
                if (post.results && post.results[0].insights) {
                    post.results[0].insights.data.map((data) => {
                        console.log("data.name = " + data.name);
                        switch (data.name) {
                            case "post_impressions":
                                total_post_impressions =
                                    total_post_impressions +
                                    data.values[0].value;
                                break;
                            case "post_clicks":
                                total_post_clicks =
                                    total_post_clicks + data.values[0].value;
                                break;
                            case "post_reactions_like_total":
                                total_post_reactions_like =
                                    total_post_reactions_like +
                                    data.values[0].value;
                                break;
                            case "post_reactions_love_total":
                                total_post_reactions_love =
                                    total_post_reactions_love +
                                    data.values[0].value;
                                break;
                            case "post_reactions_wow_total":
                                total_post_reactions_wow =
                                    total_post_reactions_wow +
                                    data.values[0].value;
                                break;
                            case "post_reactions_haha_total":
                                total_post_reactions_haha =
                                    total_post_reactions_haha +
                                    data.values[0].value;
                                break;
                            case "post_reactions_sorry_total":
                                total_post_reactions_sorry =
                                    total_post_reactions_sorry +
                                    data.values[0].value;
                                break;
                            case "post_reactions_anger_total":
                                total_post_reactions_anger =
                                    total_post_reactions_anger +
                                    data.values[0].value;
                                break;
                            default:
                                break;
                        }
                        return false;
                    });
                }
                // comments
                if (post.results && post.results[0].comments) {
                    total_post_comments =
                        total_post_comments +
                        post.results[0].comments.data.length;
                }
                return false;
            });

            const stats = {
                total_post_impressions,
                total_post_clicks,
                total_post_reactions_like,
                total_post_reactions_love,
                total_post_reactions_wow,
                total_post_reactions_haha,
                total_post_reactions_sorry,
                total_post_reactions_anger,
                total_post_reactions_total:
                    total_post_clicks +
                    total_post_reactions_like +
                    total_post_reactions_love +
                    total_post_reactions_wow +
                    total_post_reactions_haha +
                    total_post_reactions_sorry +
                    total_post_reactions_anger,
                total_post_comments,
            };
            this.setState({ stats });

            console.log("total_post_impressions = " + total_post_impressions);

            setTimeout(
                function (that) {
                    that.setState({ loading: false });
                },
                500,
                this
            );
        };

        posts_getFilteredList = (_posts) => {
            let result = _posts.filter((post) => {
                if (this.state.filter_status !== "all") {
                    if (post.status === this.state.filter_status) return post;
                } else {
                    return post;
                }
                return false;
            });

            // sort
            switch (this.state.filter_sort) {
                case "datetime":
                    result = result.sort((a, b) =>
                        a.scheduled_date > b.scheduled_date ? 1 : -1
                    );
                    break;
                case "status":
                    result = result.sort((a, b) =>
                        a.status > b.status ? 1 : -1
                    );
                    break;
                case "account_name":
                    result = result.sort((a, b) =>
                        a.account.name > b.account.name ? 1 : -1
                    );
                    break;
                case "account_location":
                    result = result.sort((a, b) =>
                        a.account.location[0] > b.account.location[0] ? 1 : -1
                    );
                    break;
                default:
                    break;
            }

            return result;
        };

        posts_handleFilterMenuClick(e) {
            console.info("handleFilterMenuClick", e);
            this.setState({ filter_status: e.key });
        }
        posts_handleSortMenuClick(e) {
            console.info("handleSortMenuClick", e);
            let filter_sort_label = "Date/Time";
            switch (e.key) {
                case "datetime":
                    filter_sort_label = "Date/Time";
                    break;
                case "status":
                    filter_sort_label = "Status";
                    break;
                case "account_name":
                    filter_sort_label = "Account name";
                    break;
                case "account_location":
                    filter_sort_label = "Account location";
                    break;
                default:
                    break;
            }
            this.setState({ filter_sort: e.key, filter_sort_label });
        }

        getTargetChannelsAffected = async (_target) => {
            let rules = {
                social_type_facebook: _target.social_type_facebook,
                social_type_instagram: _target.social_type_instagram,
                social_type_twitter: _target.social_type_twitter,
                social_type_linkedin: _target.social_type_linkedin,
                rules_location: _target.rules_location,
                rules_tags: _target.rules_tags,
                rules_custom: _target.rules_custom,
                tags: _target.tags,
                location: _target.location,
                exclude: _target.exclude,
                include: _target.include,
                custom: _target.custom ? _target.custom : [],
            };

            let results = await this.props.store.getChannelsByRules(rules);
            return results;
        };

        posts_handleMenuClick(_e, _post) {
            console.info("posts_handleMenuClick _e", _e, "_post", _post);
            const account = this.props.store.getAccount(_post.account.key);
            this.setState({ account });

            switch (_e.key) {
                case "open":
                    this.setState({ post: _post });
                    setTimeout(
                        function (that) {
                            that.props.store.showModalByKey(
                                "accounts_queue_modal"
                            );
                        },
                        100,
                        this
                    );
                    break;
                case "openqueue":
                    this.props.history.push(
                        `/dashboard/accounts/${_post.account.key}/queue`
                    );
                    break;
                default:
                    break;
            }
        }

        renderPostChannel = (_post) => {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    {_post.account_channel &&
                        _post.account_channel.type === "facebook" && (
                            <Fragment>
                                <a
                                    href={_post.account_channel.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Tag
                                        color={this.props.store.getTagColorForType(
                                            "facebook"
                                        )}
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Icon
                                            type="facebook"
                                            theme="filled"
                                            style={{
                                                marginRight: 5,
                                            }}
                                        />
                                        {_post.account_channel.name}
                                    </Tag>
                                </a>{" "}
                                - {_post.account.name}{" "}
                            </Fragment>
                        )}
                    {_post.account_channel &&
                        _post.account_channel.type === "instagram" && (
                            <Fragment>
                                <a
                                    href={_post.account_channel.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Tag
                                        color={this.props.store.getTagColorForType(
                                            "instagram"
                                        )}
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Icon
                                            type="instagram"
                                            theme="filled"
                                            style={{
                                                marginRight: 5,
                                            }}
                                        />
                                        {_post.account_channel.name}
                                    </Tag>
                                </a>{" "}
                                - {_post.account.name}{" "}
                            </Fragment>
                        )}

                    {_post.account_channel &&
                    _post.account_channel.linked_to &&
                    _post.account_channel.type === "instagram" ? (
                        <Fragment>
                            <Icon type="link" />
                            <Tag
                                style={{
                                    marginLeft: 5,
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                                color={this.props.store.getTagColorForType(
                                    _post.account_channel.linked_to.type
                                )}
                            >
                                <Icon
                                    type={_post.account_channel.linked_to.type}
                                    theme="filled"
                                    style={{
                                        marginRight: 5,
                                    }}
                                />
                                {_post.account_channel.linked_to.name}
                            </Tag>{" "}
                            - {_post.account.name}{" "}
                        </Fragment>
                    ) : null}

                    {_post.account_channel &&
                        _post.account_channel.type === "twitter" && (
                            <Fragment>
                                <a
                                    href={_post.account_channel.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Tag
                                        color={this.props.store.getTagColorForType(
                                            "twitter"
                                        )}
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Icon
                                            type="twitter"
                                            style={{
                                                marginRight: 5,
                                            }}
                                        />
                                        {_post.account_channel.name}
                                    </Tag>
                                </a>{" "}
                                - {_post.account.name}
                            </Fragment>
                        )}

                    {_post.account_channel &&
                        _post.account_channel.type === "linkedin" && (
                            <Fragment>
                                <a
                                    href={_post.account_channel.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Tag
                                        color={this.props.store.getTagColorForType(
                                            "linkedin"
                                        )}
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Icon
                                            type="linkedin"
                                            theme="filled"
                                            style={{
                                                marginRight: 5,
                                            }}
                                        />
                                        {_post.account_channel.name}
                                    </Tag>
                                </a>{" "}
                                - {_post.account.name}{" "}
                            </Fragment>
                        )}
                </div>
            );
        };

        render() {
            //const { data } = this.props;

            const antIcon = (
                <Icon type="loading" style={{ fontSize: 24 }} spin />
            );

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 }
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 }
            //     }
            // };
            const formItemLayout = null;

            let masterposts_posts_statuses = null;
            masterposts_posts_statuses =
                this.state.masterpost_posts &&
                this.state.masterpost_posts.map((mp) =>
                    mp.status.trim().replace(/^\w/, (c) => c.toUpperCase())
                );
            masterposts_posts_statuses =
                masterposts_posts_statuses &&
                masterposts_posts_statuses.filter(
                    (e, i) => masterposts_posts_statuses.indexOf(e) === i
                );

            const tabInformation = this.state.masterpost ? (
                <Fragment>
                    <Row>
                        <Col span={12}>
                            {/* <Text type="secondary">Name:</Text>
                            <Title style={{ marginTop: 0 }} level={3}>
                                {this.state.masterpost.name}
                            </Title> */}

                            <Text type="secondary">Campaigns:</Text>
                            <Title style={{ marginTop: -5 }} level={3}>
                                {this.state.masterpost.campaigns
                                    ? this.state.masterpost.campaigns.map(
                                          (campaign, cIndex) => (
                                              <SocialTag
                                                  key={cIndex}
                                                  store={this.props.store}
                                                  color="campaign"
                                              >
                                                  {campaign}
                                              </SocialTag>
                                          )
                                      )
                                    : ""}
                            </Title>

                            <Text type="secondary">Categories:</Text>
                            <Title style={{ marginTop: -5 }} level={3}>
                                {this.state.masterpost.categories
                                    ? this.state.masterpost.categories.map(
                                          (category, cIndex) => (
                                              <SocialTag
                                                  key={cIndex}
                                                  store={this.props.store}
                                                  color="category"
                                              >
                                                  {category}
                                              </SocialTag>
                                          )
                                      )
                                    : ""}
                            </Title>

                            <Text type="secondary">Publish:</Text>
                            <Title
                                style={{
                                    margin: 0,
                                    textTransform: "capitalize",
                                }}
                                level={3}
                            >
                                {this.state.masterpost.publish_type}
                            </Title>
                            <span>
                                {moment(this.state.masterpost.scheduled_date)
                                    .local()
                                    .format("llll")}
                            </span>
                            <br />
                            <br />
                            <Text type="secondary">Status:</Text>
                            <Title style={{ margin: 0 }} level={3}>
                                {masterposts_posts_statuses.join(", ")}
                            </Title>
                            {/* <Title style={{ margin: 0 }} level={3}>
                                {moment().diff(
                                    moment(this.state.masterpost.scheduled_date)
                                ) > 0
                                    ? "Posted"
                                    : "Upcoming"}
                            </Title> */}
                        </Col>
                        <Col span={12}>
                            <Text type="secondary">Targets:</Text>
                            <Tabs animated={false}>
                                {this.state.masterpost.targets.map(
                                    (target, tIndex) => {
                                        return (
                                            <TabPane
                                                tab={target.name}
                                                key={tIndex}
                                            >
                                                <Text type="secondary">
                                                    Name:
                                                </Text>
                                                <Title
                                                    style={{ marginTop: 0 }}
                                                    level={3}
                                                >
                                                    {target.name}
                                                </Title>

                                                <Text type="secondary">
                                                    Text:
                                                </Text>
                                                <br />
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {target.template_text}
                                                </span>
                                                <br />
                                                <br />

                                                {target.previewURL ? (
                                                    <Fragment>
                                                        <Text type="secondary">
                                                            Image:
                                                        </Text>
                                                        <Title
                                                            style={{
                                                                marginTop: 0,
                                                            }}
                                                            level={3}
                                                        >
                                                            <img
                                                                src={
                                                                    target.previewURL
                                                                }
                                                                alt=""
                                                                height={200}
                                                            />
                                                        </Title>
                                                    </Fragment>
                                                ) : target.template_file ? (
                                                    <Fragment>
                                                        <Text type="secondary">
                                                            Media:
                                                        </Text>
                                                        <Title
                                                            style={{
                                                                marginTop: 0,
                                                            }}
                                                            level={3}
                                                        >
                                                            {(!Array.isArray(
                                                                target.template_file
                                                            ) &&
                                                                target.template_file.type.indexOf(
                                                                    "image"
                                                                ) !== -1) ||
                                                            (Array.isArray(
                                                                target.template_file
                                                            ) &&
                                                                target.template_file[0].type.indexOf(
                                                                    "image"
                                                                ) !== -1) ? (
                                                                <img
                                                                    src={
                                                                        !Array.isArray(
                                                                            target.template_file
                                                                        )
                                                                            ? target
                                                                                  .template_file
                                                                                  .url
                                                                            : target
                                                                                  .template_file[0]
                                                                                  .url
                                                                    }
                                                                    alt=""
                                                                    height={200}
                                                                />
                                                            ) : null}
                                                            {(!Array.isArray(
                                                                target.template_file
                                                            ) &&
                                                                target.template_file.type.indexOf(
                                                                    "video"
                                                                ) !== -1) ||
                                                            (Array.isArray(
                                                                target.template_file
                                                            ) &&
                                                                target.template_file[0].type.indexOf(
                                                                    "video"
                                                                ) !== -1) ? (
                                                                <video
                                                                    width="100%"
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={
                                                                            !Array.isArray(
                                                                                target.template_file
                                                                            )
                                                                                ? target
                                                                                      .template_file
                                                                                      .url
                                                                                : target
                                                                                      .template_file[0]
                                                                                      .url
                                                                        }
                                                                        type="video/mp4"
                                                                    />
                                                                    Your browser
                                                                    does not
                                                                    support the
                                                                    video tag.
                                                                </video>
                                                            ) : null}
                                                        </Title>
                                                    </Fragment>
                                                ) : null}

                                                <Text type="secondary">
                                                    Channels selection:
                                                </Text>
                                                <Title
                                                    style={{ marginTop: 0 }}
                                                    level={3}
                                                >
                                                    {target.rules_custom ===
                                                    true
                                                        ? "Manual"
                                                        : "By Rules"}
                                                </Title>

                                                {!target.rules_custom ||
                                                target.rules_custom ===
                                                    false ? (
                                                    <Fragment>
                                                        <Text type="secondary">
                                                            Rules:
                                                        </Text>
                                                        <br />
                                                        <Icon
                                                            type="facebook"
                                                            theme="filled"
                                                            style={{
                                                                fontSize: 25,
                                                                marginRight: 5,
                                                                color: target.social_type_facebook
                                                                    ? this.props.store.getTagColorForType(
                                                                          "facebook"
                                                                      )
                                                                    : "#ccc",
                                                            }}
                                                        />

                                                        <Icon
                                                            type="instagram"
                                                            theme="filled"
                                                            style={{
                                                                fontSize: 25,
                                                                marginRight: 5,
                                                                color: target.social_type_instagram
                                                                    ? this.props.store.getTagColorForType(
                                                                          "instagram"
                                                                      )
                                                                    : "#ccc",
                                                            }}
                                                        />

                                                        <Icon
                                                            type="twitter"
                                                            style={{
                                                                fontSize: 25,
                                                                marginRight: 5,
                                                                color: target.social_type_instagram
                                                                    ? this.props.store.getTagColorForType(
                                                                          "twitter"
                                                                      )
                                                                    : "#ccc",
                                                            }}
                                                        />

                                                        <Icon
                                                            type="linkedin"
                                                            style={{
                                                                fontSize: 25,
                                                                marginRight: 5,
                                                                color: target.social_type_linkedin
                                                                    ? this.props.store.getTagColorForType(
                                                                          "linkedin"
                                                                      )
                                                                    : "#ccc",
                                                            }}
                                                        />
                                                        <br />
                                                        {target.include ? (
                                                            <Fragment>
                                                                Include:
                                                                <br />
                                                                {target.include.map(
                                                                    (
                                                                        include,
                                                                        iIndex
                                                                    ) => {
                                                                        return (
                                                                            <span
                                                                                key={
                                                                                    iIndex
                                                                                }
                                                                            >
                                                                                {
                                                                                    include
                                                                                }
                                                                            </span>
                                                                        );
                                                                    }
                                                                )}
                                                            </Fragment>
                                                        ) : null}
                                                        {target.exclude ? (
                                                            <Fragment>
                                                                Exclude:
                                                                <br />
                                                                {target.exclude.map(
                                                                    (
                                                                        exclude,
                                                                        eIndex
                                                                    ) => {
                                                                        return (
                                                                            <span
                                                                                key={
                                                                                    eIndex
                                                                                }
                                                                            >
                                                                                {
                                                                                    exclude
                                                                                }
                                                                            </span>
                                                                        );
                                                                    }
                                                                )}
                                                            </Fragment>
                                                        ) : null}
                                                        <br />
                                                        <br />
                                                    </Fragment>
                                                ) : null}

                                                <Text type="secondary">
                                                    Channels:
                                                </Text>
                                                <br />
                                                <Fragment>
                                                    {target.channels_affected &&
                                                        target.channels_affected.map(
                                                            (item, cIndex) => {
                                                                return (
                                                                    <Fragment
                                                                        key={
                                                                            cIndex
                                                                        }
                                                                    >
                                                                        {item.type ===
                                                                            "facebook" && (
                                                                            <Fragment>
                                                                                <a
                                                                                    href={
                                                                                        item.link
                                                                                    }
                                                                                    target="_blank"
                                                                                    key={
                                                                                        cIndex
                                                                                    }
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <Tag
                                                                                        color={this.props.store.getTagColorForType(
                                                                                            "facebook"
                                                                                        )}
                                                                                    >
                                                                                        <Icon
                                                                                            type="facebook"
                                                                                            theme="filled"
                                                                                            style={{
                                                                                                marginRight: 5,
                                                                                            }}
                                                                                        />
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </Tag>
                                                                                </a>{" "}
                                                                                -{" "}
                                                                                {
                                                                                    item.account_name
                                                                                }{" "}
                                                                            </Fragment>
                                                                        )}
                                                                        {item.type ===
                                                                            "instagram" && (
                                                                            <Fragment>
                                                                                <a
                                                                                    href={
                                                                                        item.link
                                                                                    }
                                                                                    target="_blank"
                                                                                    key={
                                                                                        cIndex
                                                                                    }
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <Tag
                                                                                        color={this.props.store.getTagColorForType(
                                                                                            "instagram"
                                                                                        )}
                                                                                    >
                                                                                        <Icon
                                                                                            type="instagram"
                                                                                            theme="filled"
                                                                                            style={{
                                                                                                marginRight: 5,
                                                                                            }}
                                                                                        />
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </Tag>
                                                                                </a>{" "}
                                                                                -{" "}
                                                                                {
                                                                                    item.account_name
                                                                                }{" "}
                                                                            </Fragment>
                                                                        )}

                                                                        {item.linked_to &&
                                                                        item.type ===
                                                                            "instagram" ? (
                                                                            <Fragment>
                                                                                <Icon type="link" />
                                                                                <Tag
                                                                                    style={{
                                                                                        marginLeft: 5,
                                                                                    }}
                                                                                    color={this.props.store.getTagColorForType(
                                                                                        item
                                                                                            .linked_to
                                                                                            .type
                                                                                    )}
                                                                                >
                                                                                    <Icon
                                                                                        type={
                                                                                            item
                                                                                                .linked_to
                                                                                                .type
                                                                                        }
                                                                                        theme="filled"
                                                                                        style={{
                                                                                            marginRight: 5,
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        item
                                                                                            .linked_to
                                                                                            .name
                                                                                    }
                                                                                </Tag>{" "}
                                                                                -{" "}
                                                                                {
                                                                                    item.account_name
                                                                                }{" "}
                                                                            </Fragment>
                                                                        ) : null}

                                                                        {item.type ===
                                                                            "twitter" && (
                                                                            <Fragment>
                                                                                <a
                                                                                    href={
                                                                                        item.link
                                                                                    }
                                                                                    target="_blank"
                                                                                    key={
                                                                                        cIndex
                                                                                    }
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <Tag
                                                                                        color={this.props.store.getTagColorForType(
                                                                                            "twitter"
                                                                                        )}
                                                                                    >
                                                                                        <Icon
                                                                                            type="twitter"
                                                                                            style={{
                                                                                                marginRight: 5,
                                                                                            }}
                                                                                        />
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </Tag>
                                                                                </a>{" "}
                                                                                -{" "}
                                                                                {
                                                                                    item.account_name
                                                                                }{" "}
                                                                                <br />
                                                                            </Fragment>
                                                                        )}

                                                                        {item.type ===
                                                                            "linkedin" && (
                                                                            <Fragment>
                                                                                <a
                                                                                    href={
                                                                                        item.link
                                                                                    }
                                                                                    target="_blank"
                                                                                    key={
                                                                                        cIndex
                                                                                    }
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <Tag
                                                                                        color={this.props.store.getTagColorForType(
                                                                                            "linkedin"
                                                                                        )}
                                                                                    >
                                                                                        <Icon
                                                                                            type="linkedin"
                                                                                            theme="filled"
                                                                                            style={{
                                                                                                marginRight: 5,
                                                                                            }}
                                                                                        />
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </Tag>
                                                                                </a>{" "}
                                                                                -{" "}
                                                                                {
                                                                                    item.account_name
                                                                                }{" "}
                                                                            </Fragment>
                                                                        )}

                                                                        <br />
                                                                    </Fragment>
                                                                );
                                                            }
                                                        )}
                                                </Fragment>

                                                {/* <pre>
                                                    {JSON.stringify(
                                                        target,
                                                        null,
                                                        2
                                                    )}
                                                </pre> */}
                                            </TabPane>
                                        );
                                    }
                                )}
                            </Tabs>
                        </Col>
                    </Row>
                </Fragment>
            ) : null;

            // ----------------------------
            const posts_orderMenu = (
                <Menu onClick={(e) => this.posts_handleSortMenuClick(e)}>
                    <Menu.Item key={"datetime"}>Date/Time</Menu.Item>
                    <Menu.Item key={"status"}>Status</Menu.Item>
                    <Menu.Item key={"account_name"}>Account name</Menu.Item>
                    <Menu.Item key={"account_location"}>
                        Account location
                    </Menu.Item>
                </Menu>
            );

            const posts_includeMenu = (
                <Menu onClick={(e) => this.posts_handleFilterMenuClick(e)}>
                    <Menu.Item key={"all"}>All</Menu.Item>
                    <Menu.Item key={"upcoming"}>Upcoming</Menu.Item>
                    <Menu.Item key={"processing"}>Processing</Menu.Item>
                    <Menu.Item key={"posted"}>Posted</Menu.Item>
                    <Menu.Item key={"error"}>Error</Menu.Item>
                </Menu>
            );

            let posts_header = (
                <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: 10 }}
                >
                    <Col>
                        <Title
                            level={4}
                            style={{
                                float: "left",
                                marginBottom: 0,
                                marginRight: 10,
                            }}
                        >
                            Posts
                        </Title>
                        <Badge
                            count={
                                this.state.masterpost_posts &&
                                this.state.masterpost_posts.length
                            }
                            style={{ backgroundColor: "#1890ff" }}
                        />
                    </Col>
                    <Col>
                        <Dropdown overlay={posts_includeMenu}>
                            <Button
                                type="link"
                                className="ant-dropdown-link"
                                style={{ marginRight: 20 }}
                            >
                                {this.state.filter_status
                                    .toString()
                                    .charAt(0)
                                    .toUpperCase() +
                                    this.state.filter_status
                                        .toString()
                                        .slice(1)}{" "}
                                <Icon type="down" />
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={posts_orderMenu}>
                            <Button type="link" className="ant-dropdown-link">
                                {this.state.filter_sort_label}{" "}
                                <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            );

            let tabPosts = this.state.masterpost_posts ? (
                <Fragment>
                    <br />
                    <br />
                    {/* <Divider orientation="left">Posts</Divider> */}
                    {/* <pre>
                        {JSON.stringify(this.state.masterpost_posts, null, 2)}
                    </pre> */}
                    <List
                        size="small"
                        className="social_MasterpostsStatus"
                        header={posts_header}
                        footer={null}
                        bordered
                        dataSource={this.posts_getFilteredList(
                            this.state.masterpost_posts
                        )}
                        renderItem={(item, itemIndex) => (
                            <List.Item
                                key={itemIndex}
                                style={{ position: "relative" }}
                                actions={[]}
                            >
                                <List.Item.Meta
                                    description={
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "50%",
                                                }}
                                            >
                                                {this.renderPostChannel(item)}
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "50%",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                {item.errors &&
                                                item.errors.length > 0 ? (
                                                    <SocialErrorSymbol
                                                        errors={item.errors}
                                                        store={this.props.store}
                                                        title={true}
                                                    />
                                                ) : null}
                                                {item.status === "upcoming" ? (
                                                    <Icon
                                                        type="clock-circle"
                                                        title="Upcoming"
                                                    />
                                                ) : null}
                                                {item.status === "posted" ? (
                                                    <Icon
                                                        type="check"
                                                        title="Posted"
                                                    />
                                                ) : null}
                                                {/* {item.status === "error" ? (
                                                    <Icon
                                                        type="warning"
                                                        title="Error"
                                                        style={{
                                                            color: this.props.store.getTagColorForType(
                                                                "error"
                                                            ),
                                                        }}
                                                    />
                                                ) : null} */}

                                                {/* <Tag
                                                    color={this.props.store.getTagColorForType(
                                                        item.status
                                                    )}
                                                >
                                                    {item.status ===
                                                    "upcoming" ? (
                                                        <Icon type="clock-circle" />
                                                    ) : null}
                                                    {item.status ===
                                                    "posted" ? (
                                                        <Icon type="check-circle" />
                                                    ) : null}
                                                    {item.status === "error" ? (
                                                        <Icon type="warning" />
                                                    ) : null}
                                                </Tag> */}
                                                <Dropdown
                                                    overlay={
                                                        <Menu
                                                            onClick={(e) =>
                                                                this.posts_handleMenuClick(
                                                                    e,
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            <Menu.Item key="open">
                                                                <Icon type="container" />
                                                                Open Post
                                                            </Menu.Item>

                                                            <Menu.Item key="openqueue">
                                                                <Icon type="arrow-right" />
                                                                Open account
                                                                queue
                                                            </Menu.Item>
                                                        </Menu>
                                                    }
                                                >
                                                    <Icon
                                                        type="more"
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                    />
                                                </Dropdown>
                                            </div>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                    <AccountsQueueModal
                        store={this.props.store}
                        post={this.state.post}
                        account={this.state.account}
                    />
                </Fragment>
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={`No ${names.get("MASTERPOST")} to status`}
                />
            );

            const tabPerformance = (
                <Form {...formItemLayout}>
                    {/* <ReactJson src={this.state} displayDataTypes={false} /> */}

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Posts
                    </Divider>
                    <h1>
                        {this.state.masterpost_posts &&
                            this.state.masterpost_posts.length}
                    </h1>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Impressions
                    </Divider>
                    <h1>
                        {this.state.stats &&
                            this.state.stats.total_post_impressions}
                    </h1>

                    {/* <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Audience Growth
                    </Divider>
                    <h1>N/A</h1> */}

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Engagement
                    </Divider>
                    <h1>
                        {this.state.stats &&
                            this.state.stats.total_post_reactions_total}
                    </h1>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Messages
                    </Divider>
                    <h1>
                        {this.state.stats &&
                            this.state.stats.total_post_comments}
                    </h1>
                </Form>
            );

            const tabStatus = (
                <Fragment>
                    {/* <pre>{JSON.stringify(this.state.channels, null, 2)}</pre> */}
                    <Timeline
                        //mode="alternate"
                        style={{
                            marginTop: 25,
                            lineHeight: 1.7,
                            cursor: "pointer",
                            paddingLeft: 25,
                        }}
                    >
                        {this.state.masterpost_posts &&
                            this.state.masterpost_posts.map((post, pIndex) => {
                                return (
                                    <Timeline.Item
                                        key={pIndex}
                                        color={
                                            post.errors &&
                                            post.errors.length > 0
                                                ? "red"
                                                : this.props.store.getTagColorForType(
                                                      "primary"
                                                  )
                                        }
                                        dot={
                                            post.errors &&
                                            post.errors.length > 0 ? (
                                                <Icon
                                                    type="exclamation-circle"
                                                    style={{
                                                        fontSize: "16px",
                                                    }}
                                                />
                                            ) : post.status === "posted" ? (
                                                <Icon
                                                    type="check-circle"
                                                    style={{
                                                        fontSize: "16px",
                                                    }}
                                                />
                                            ) : null
                                        }
                                    >
                                        <strong>
                                            {moment(post.schedule_datetime)
                                                .utc()
                                                .format("lll")}
                                        </strong>
                                        <br />
                                        {post && this.renderPostChannel(post)}
                                        <br />
                                        {post.errors &&
                                        post.errors.length > 0 ? (
                                            <div style={{ display: "block" }}>
                                                <SocialErrorSymbol
                                                    store={this.props.store}
                                                    errors={post.errors}
                                                    title={true}
                                                />
                                            </div>
                                        ) : null}
                                        <Tag
                                            color={this.props.store.getTagColorForType(
                                                post.status
                                            )}
                                        >
                                            {post.status}
                                        </Tag>
                                        {/* <pre>
                                            {JSON.stringify(post, null, 2)}
                                        </pre> */}
                                    </Timeline.Item>
                                );
                            })}
                    </Timeline>
                </Fragment>
            );

            return this.state.loading ? (
                antIcon
            ) : (
                <Fragment>
                    <Row>
                        <Col span={12}>
                            <Text type="secondary">
                                {names.get("MASTERPOST", false, true)} name:
                            </Text>
                            <Title style={{ marginTop: 0 }} level={3}>
                                {this.state.masterpost.name}
                            </Title>
                        </Col>
                        <Col>
                            <Text type="secondary">
                                {names.get("MASTERPOST", false, true)} time:
                            </Text>
                            <Title style={{ marginTop: 0 }} level={3}>
                                {moment(this.state.masterpost.scheduled_date)
                                    .local()
                                    .format("llll")}
                            </Title>
                        </Col>
                    </Row>
                    <br />
                    <Tabs style={{ minHeight: "50vh" }} animated={false}>
                        <TabPane tab={"Information"} key="1">
                            {tabInformation}
                        </TabPane>
                        <TabPane tab={"Posts"} key="2">
                            {tabPosts}
                        </TabPane>
                        <TabPane tab={"Status"} key="3">
                            {tabStatus}
                        </TabPane>
                        <TabPane tab={"Performance"} key="4">
                            {tabPerformance}
                        </TabPane>
                    </Tabs>
                </Fragment>
            );
        }
    }
);

/* Wrapper component */
class MasterpostsListModalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "masterposts_modal_info",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("masterposts_modal_info");
    };

    onModalCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            if (values.scheduled_date)
                values.scheduled_date = moment()
                    .utc(values.scheduled_date)
                    .valueOf();
            this.props.store.addMasterpost({
                ...values,
                ...this.state.edit_data,
            });

            form.resetFields();
            this.onModalCancel();
        });
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            if (values.scheduled_date)
                values.scheduled_date = moment(values.scheduled_date).valueOf();

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.props.data.key);

            this.props.store.updateMasterpost(this.props.data.key, values);

            form.resetFields();
            this.setState({ modal_visible: false });
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "masterposts_modal_info",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data, store } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        width={"70vw"}
                        title={
                            names.get("MASTERPOST", false, true) +
                            " Information"
                        }
                        //okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        // onOk={
                        //     !data.name ? this.onModalCreate : this.onModalEdit
                        // } //onApply
                        maskClosable={false}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={this.onModalCancel}
                            >
                                Close
                            </Button>,
                        ]}
                    >
                        <ModalForm
                            store={store}
                            data={data}
                            wrappedComponentRef={this.saveFormRef}
                            history={this.props.history}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(MasterpostsListModalInfo);
