import React, { Component, Fragment } from "react";
import {
    Form,
    Modal,
    Input,
    AutoComplete,
    Icon,
    Alert,
    Checkbox,
    Spin,
    Statistic,
    Row,
    Col,
    Button,
    Divider,
    DatePicker,
    Progress,
    Table,
} from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import { social_sleep } from "../../functions";
import AuditorsListModalMonitor_Tweet from "./AuditorsListModalMonitor_Tweet";
import AuditorsListModalMonitor_FacebookPost from "./AuditorsListModalMonitor_FacebookPost";
import AuditorsListModalMonitor_InstagramPost from "./AuditorsListModalMonitor_InstagramPost";
import AuditorsListModalMonitor_LinkedinPost from "./AuditorsListModalMonitor_LinkedinPost";

import scss_variables from "./../../_variables.scss";
import SocialDataPresentor from "./../SocialDataPresentor";

const { RangePicker } = DatePicker;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        async componentDidMount() {
            // To disable submit button at the beginning.
            const { form, data, store } = this.props;
            if (data.facebook_page) {
                const [facebook_id, account_key] =
                    data.facebook_page.split("|");
                const account = store.getAllAccounts.filter(
                    (a) => a.key === account_key
                )[0];
                const accountChannels = await store.getAccountChannels(
                    account_key
                );
                const accountChannel = accountChannels.filter(
                    (c) => c.id === facebook_id
                )[0];
                console.info("accountChannels", accountChannels);
                console.info("accountChannel", accountChannel);
                this.setState({
                    facebookAccount: account,
                    facebookChannel: accountChannel,
                });
            }
            if (data.instagram_account) {
                const [instagram_id, account_key] =
                    data.instagram_account.split("|");
                const account = store.getAllAccounts.filter(
                    (a) => a.key === account_key
                )[0];
                const accountChannels = await store.getAccountChannels(
                    account_key
                );
                const accountChannel = accountChannels.filter(
                    (c) => c.id === instagram_id
                )[0];
                console.info("accountChannels", accountChannels);
                console.info("instagram accountChannel", accountChannel);
                this.setState({
                    instagramAccount: account,
                    instagramChannel: accountChannel,
                });
            }
            if (data.twitter_account) {
                const [twitter_id, account_key] =
                    data.twitter_account.split("|");
                const account = store.getAllAccounts.filter(
                    (a) => a.key === account_key
                )[0];
                const accountChannels = await store.getAccountChannels(
                    account_key
                );
                const accountChannel = accountChannels.filter(
                    (c) => c.id === twitter_id
                )[0];
                console.info("accountChannels", accountChannels);
                console.info("twitter accountChannel", accountChannel);
                this.setState({
                    twitterAccount: account,
                    twitterChannel: accountChannel,
                });
            }
            if (data.linkedin_account) {
                console.log("data.linkedin_account = " + data.linkedin_account);
                const [linkedin_id, account_key] =
                    data.linkedin_account.split("|");
                const account = store.getAllAccounts.filter(
                    (a) => a.key === account_key
                )[0];
                const accountChannels = await store.getAccountChannels(
                    account_key
                );
                const accountChannel = accountChannels.filter(
                    (c) => Number(c.id) === Number(linkedin_id)
                )[0];
                console.info("accountChannels", accountChannels);
                console.info("linkedin accountChannel", accountChannel);
                this.setState({
                    linkedinAccount: account,
                    linkedinChannel: accountChannel,
                });
            }
        }

        //   handleSubmit = e => {
        //       e.preventDefault();
        //       this.props.form.validateFields((err, values) => {
        //           if (!err) {
        //               console.log("Received values of form: ", values);
        //           }
        //       });
        //   };

        render() {
            const { form, data, store } = this.props;
            const { getFieldDecorator } = form;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            let facebookSocial = null,
                instagramSocial = null,
                twitterSocial = null,
                linkedinSocial = null;
            if (this.state.facebookAccount) {
                facebookSocial = (
                    <div style={{ display: "block", lineHeight: 1.5 }}>
                        {this.state.facebookAccount.name}&nbsp;&nbsp;
                        <Icon type="arrow-right" />
                        &nbsp;&nbsp;
                        <div
                            className={`social_iconCircle_facebook`}
                            style={{
                                marginRight: 5,
                                width: 13,
                                height: 13,
                                display: "inline-block",
                                marginBottom: -2,
                            }}
                        />{" "}
                        {this.state.facebookChannel &&
                            this.state.facebookChannel.name}
                    </div>
                );
            }
            if (this.state.instagramAccount) {
                instagramSocial = (
                    <div style={{ display: "block", lineHeight: 1.5 }}>
                        {this.state.instagramAccount &&
                            this.state.instagramAccount.name}
                        &nbsp;&nbsp;
                        <Icon type="arrow-right" />
                        &nbsp;&nbsp;
                        <div
                            className={`social_iconCircle_instagram`}
                            style={{
                                marginRight: 5,
                                width: 13,
                                height: 13,
                                display: "inline-block",
                                marginBottom: -2,
                            }}
                        />{" "}
                        {this.state.instagramChannel &&
                            this.state.instagramChannel.name}
                    </div>
                );
            }
            if (this.state.twitterAccount) {
                twitterSocial = (
                    <div style={{ display: "block", lineHeight: 1.5 }}>
                        {this.state.twitterAccount &&
                            this.state.twitterAccount.name}
                        &nbsp;&nbsp;
                        <Icon type="arrow-right" />
                        &nbsp;&nbsp;
                        <div
                            className={`social_iconCircle_twitter`}
                            style={{
                                marginRight: 5,
                                width: 13,
                                height: 13,
                                display: "inline-block",
                                marginBottom: -2,
                            }}
                        />{" "}
                        {this.state.twitterChannel &&
                            this.state.twitterChannel.name}
                    </div>
                );
            }
            if (this.state.linkedinAccount) {
                linkedinSocial = (
                    <div style={{ display: "block", lineHeight: 1.5 }}>
                        {this.state.linkedinAccount &&
                            this.state.linkedinAccount.name}
                        &nbsp;&nbsp;
                        <Icon type="arrow-right" />
                        &nbsp;&nbsp;
                        <div
                            className={`social_iconCircle_linkedin`}
                            style={{
                                marginRight: 5,
                                width: 13,
                                height: 13,
                                display: "inline-block",
                                marginBottom: -2,
                            }}
                        />{" "}
                        {this.state.linkedinChannel &&
                            this.state.linkedinChannel.name}
                    </div>
                );
            }

            let sentimentDataSource = [],
                sentimentKey = 0;
            data.summary.sentiment.records.slice(0, 10).map((record) => {
                console.info("sentiment record", record);
                record.sentiments &&
                    record.sentiments
                        .sort((a, b) => Number(b.score) - Number(a.score))
                        .map((sentiment) => {
                            // console.info("Sentiment", sentiment);
                            sentimentDataSource.push({
                                key: ++sentimentKey,
                                positive: sentiment.positive.join(", "),
                                negative: sentiment.negative.join(", "),
                                tokens: sentiment.tokens.join(", "),
                                score: Number(sentiment.score),
                                //count: data.summary.words_count[]
                            });
                        });
            });

            console.info("sentimentDataSource", sentimentDataSource);

            const sentimentColumns = [
                {
                    title: "Score",
                    dataIndex: "score",
                    key: "score",
                    // sorter: true,
                    sorter: (a, b) => a.score - b.score,
                },
                {
                    title: "Positive",
                    dataIndex: "positive",
                    key: "positive",
                    // sorter: true,
                },
                {
                    title: "Negative",
                    dataIndex: "negative",
                    key: "negative",
                    // sorter: true,
                },
                {
                    title: "Tokens",
                    dataIndex: "tokens",
                    key: "tokens",
                },
                // {
                //     title: "Count",
                //     dataIndex: "count",
                //     key: "count",
                //     sorter: (a, b) => a.count - b.count,
                // },
            ];

            // ---- History -----
            const historyColumns = [
                {
                    title: "Social",
                    dataIndex: "social",
                    key: "social",
                    sorter: true,
                },
                {
                    title: "Date",
                    dataIndex: "datetime",
                    key: "datetime",
                    sorter: true,
                },
                {
                    title: "Message",
                    dataIndex: "message",
                    key: "message",
                },
                {
                    title: "Sentiment",
                    dataIndex: "sentiment",
                    key: "sentiment",
                    sorter: (a, b) => a.sentiment - b.sentiment,
                },
                {
                    title: "Link",
                    dataIndex: "link",
                    key: "link",
                },
                {
                    title: "Shares",
                    dataIndex: "shares",
                    key: "shares",
                    sorter: (a, b) => a.shares - b.shares,
                },
                {
                    title: "Likes",
                    dataIndex: "likes",
                    key: "likes",
                    sorter: (a, b) => a.likes - b.likes,
                },
                {
                    title: "Comments",
                    dataIndex: "comments",
                    key: "comments",
                    sorter: (a, b) => a.comments - b.comments,
                },
            ];
            const historyList = [];
            let historyKeysIndex = 0;

            console.info("data.history", data.history);
            data.history &&
                data.history.map((historyDay, historyDayIndex) => {
                    const date = moment(historyDay.datetime).utc();

                    //facebook
                    historyDay.results &&
                        this.props.filter_facebook === true &&
                        historyDay.results &&
                        historyDay.results.facebook &&
                        historyDay.results.facebook.map((fbPost) => {
                            console.info("fbPost", fbPost);
                            const [fbPostAuthorId, fbPostUniquePostId] =
                                fbPost.id.split("_");

                            historyList.push({
                                key: ++historyKeysIndex,
                                social: "facebook",
                                datetime: moment(fbPost.created_time)
                                    .utc()
                                    .format("lll"),
                                message: fbPost.message,
                                sentiment: fbPost.sentiment
                                    ? Number(fbPost.sentiment.score)
                                    : 0,
                                link: fbPost.permalink_url,
                                shares:
                                    fbPost.shares && fbPost.shares.count
                                        ? Number(fbPost.shares.count)
                                        : 0,
                                likes: 0,
                                comments: 0,
                            });
                        });

                    //twitter
                    historyDay.results &&
                        this.props.filter_twitter === true &&
                        historyDay.results &&
                        historyDay.results.twitter &&
                        historyDay.results.twitter.map((twPost) => {
                            console.info("twPost", twPost);

                            historyList.push({
                                key: ++historyKeysIndex,
                                social: "twitter",
                                datetime: moment(twPost.created_at)
                                    .utc()
                                    .format("lll"),
                                message: twPost.text,
                                sentiment: twPost.sentiment
                                    ? Number(twPost.sentiment.score)
                                    : 0,
                                link: (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://twitter.com/i/web/status/${twPost.id}`}
                                    >
                                        Link
                                    </a>
                                ),
                                shares:
                                    twPost.public_metrics &&
                                    twPost.public_metrics.retweet_count
                                        ? Number(
                                              twPost.public_metrics
                                                  .retweet_count
                                          )
                                        : 0,
                                likes:
                                    twPost.public_metrics &&
                                    twPost.public_metrics.like_count
                                        ? Number(
                                              twPost.public_metrics.like_count
                                          )
                                        : 0,
                                comments:
                                    twPost.public_metrics &&
                                    twPost.public_metrics.retweet_count
                                        ? Number(
                                              twPost.public_metrics
                                                  .retweet_count
                                          )
                                        : 0,
                            });
                        });

                    //instagram
                    historyDay.results &&
                        this.props.filter_instagram === true &&
                        historyDay.results &&
                        historyDay.results.instagram &&
                        historyDay.results.instagram.map((igPost) => {
                            console.info("igPost", igPost);

                            historyList.push({
                                key: ++historyKeysIndex,
                                social: "instagram",
                                datetime: moment(igPost.timestamp)
                                    .utc()
                                    .format("lll"),
                                message: igPost.caption,
                                sentiment: igPost.sentiment
                                    ? Number(igPost.sentiment.score)
                                    : 0,
                                link: (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={igPost.media_url}
                                    >
                                        Link
                                    </a>
                                ),
                                shares: 0,
                                likes: igPost.like_count
                                    ? igPost.like_count
                                    : 0,
                                comments: 0,
                            });
                        });

                    //linkedin
                    historyDay.results &&
                        this.props.filter_linkedin === true &&
                        historyDay.results &&
                        historyDay.results.linkedin_posts &&
                        historyDay.results.linkedin_posts.map((liPost) => {
                            console.info("liPost", liPost);

                            historyList.push({
                                key: ++historyKeysIndex,
                                social: "linkedin",
                                datetime: moment(liPost.created.time)
                                    .utc()
                                    .format("lll"),
                                message:
                                    liPost.specificContent[
                                        "com.linkedin.ugc.ShareContent"
                                    ].shareCommentary.text,
                                sentiment: liPost.sentiment
                                    ? Number(liPost.sentiment.score)
                                    : 0,
                                link: (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={liPost.id}
                                    >
                                        Link
                                    </a>
                                ),
                                shares: liPost.shareStats.shareCount
                                    ? liPost.shareStats.shareCount
                                    : 0,
                                likes: liPost.shareStats.likeCount
                                    ? liPost.shareStats.likeCount
                                    : 0,
                                comments: liPost.shareStats.commentCount
                                    ? liPost.shareStats.commentCount
                                    : 0,
                            });
                        });
                });
            console.info("historyList", historyList);
            console.log(
                "this.props.filter_instagram",
                this.props.filter_instagram
            );

            // ---- Top Words -----
            const wordsColumns = [
                {
                    title: "Word",
                    dataIndex: "word",
                    key: "word",
                    // sorter: true,
                },
                {
                    title: "Count",
                    dataIndex: "count",
                    key: "count",
                    sorter: (a, b) => a.count - b.count,
                },
            ];
            const wordsList = Object.keys(data.summary.words_count)
                .slice(0, 15)
                .map(function (key, keyIndex) {
                    return {
                        key: keyIndex,
                        word: key.toString(),
                        count: Number(data.summary.words_count[key]),
                    };
                })
                .sort((a, b) => (a.count < b.count ? 1 : -1));
            console.info("wordsList", wordsList);

            // ---- Top Mentioned Users -----
            const mentionedUsersColumns = [
                {
                    title: "Handle",
                    dataIndex: "handle",
                    key: "handle",
                    // sorter: true,
                },
                {
                    title: "Count",
                    dataIndex: "count",
                    key: "count",
                    sorter: true,
                },
            ];
            const mentionedUsersList = Object.keys(data.summary.words_count)
                .filter((key) => key.indexOf("@") !== -1)
                .map(function (key, keyIndex) {
                    return {
                        key: keyIndex,
                        handle: key.toString(),
                        count: Number(data.summary.words_count[key]),
                    };
                })
                .sort((a, b) => (a.count < b.count ? 1 : -1));
            console.info("mentionedUsersList", wordsList);

            // ---- Top Hashtags -----
            const hashtagsColumns = [
                {
                    title: "Tag",
                    dataIndex: "tag",
                    key: "tag",
                    // sorter: true,
                },
                {
                    title: "Count",
                    dataIndex: "count",
                    key: "count",
                    sorter: true,
                },
            ];
            const hashtagsList = Object.keys(data.summary.words_count)
                .filter((key) => key.indexOf("#") !== -1)
                .map(function (key, keyIndex) {
                    return {
                        key: keyIndex,
                        tag: key.toString(),
                        count: Number(data.summary.words_count[key]),
                    };
                })
                .sort((a, b) => (a.count < b.count ? 1 : -1));
            console.info("hashtagsList", wordsList);

            // ---- Top Authors -----
            const authorsColumns = [
                {
                    title: "Author",
                    dataIndex: "author",
                    key: "author",
                    sorter: true,
                },
                {
                    title: "Posts",
                    dataIndex: "posts",
                    key: "posts",
                    sorter: true,
                },
            ];
            let authors_count_result = {};
            data.summary.authors &&
                data.summary.authors.all &&
                data.summary.authors.all
                    .filter((a) => a.length > 1)
                    .forEach(function (a) {
                        authors_count_result[a] =
                            (authors_count_result[a] || 0) + 1;
                    });

            const authorsList = Object.keys(authors_count_result)
                .map(function (key, keyIndex) {
                    const author_social = key.toString().split("|")
                        ? key.toString().split("|")[0]
                        : "N/A";
                    const author_handle = key.toString().split("|")
                        ? key.toString().split("|")[1]
                        : "N/A";
                    let author_name = data.summary.authors.all_detailed[key]
                        ? data.summary.authors.all_detailed[key].info.name
                        : author_handle
                        ? author_handle
                        : "N/A";
                    let author_link = "#";
                    switch (author_social) {
                        case "twitter":
                            author_link = `https://twitter.com/${data.summary.authors.all_detailed[key].info.username}`;
                            break;
                        case "facebook":
                            if (
                                data.summary.authors.all_detailed &&
                                data.summary.authors.all_detailed[key]
                            ) {
                                author_link = `https://www.facebook.com/profile.php?id=${data.summary.authors.all_detailed[key].info.id}`;
                                //author_link = `https://facebook.com/${data.summary.authors.all_detailed[key].info.id}`;
                            }
                            break;
                        case "instagram":
                            author_link = `https://www.instagram.com/${author_handle}`;
                            break;
                        case "linkedin":
                            //author_link = `https://www.linkedin.com/in/${data.summary.authors.all_detailed[key].info.vanityName}`;
                            author_link =
                                data.summary.authors.all_detailed[key] &&
                                data.summary.authors.all_detailed[key].info
                                    .localizedWebsite;
                            author_name =
                                data.summary.authors.all_detailed[key] &&
                                data.summary.authors.all_detailed[key].info
                                    .localizedName;
                            break;
                        default:
                            break;
                    }

                    return {
                        key: keyIndex,
                        author: (
                            <Fragment>
                                <Icon
                                    type={author_social}
                                    theme={
                                        author_social !== "twitter"
                                            ? "filled"
                                            : null
                                    }
                                    style={{
                                        marginRight: 4,
                                        marginTop: 4,
                                        color: store.getTagColorForType(
                                            author_social
                                        ),
                                        float: "left",
                                    }}
                                />
                                <a
                                    href={author_link}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "underline" }}
                                >
                                    {author_name}
                                </a>
                            </Fragment>
                        ),
                        posts: Number(authors_count_result[key]),
                    };
                })
                .sort((a, b) => (a.count < b.count ? 1 : -1));
            console.info("hashtagsList", wordsList);

            const panel = (
                <Fragment>
                    {/* <pre>{JSON.stringify(this.props, null, 2)}</pre> */}
                    <Form.Item label="Name">{data.name}</Form.Item>
                    <Form.Item label="Last update">
                        {moment(data.updated).utc().format("llll")}
                    </Form.Item>
                    <Form.Item label="Social">
                        {facebookSocial}

                        {instagramSocial}

                        {twitterSocial}

                        {linkedinSocial}
                    </Form.Item>
                    <br />
                    <Divider orientation="left">Summary</Divider>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Statistic
                                title="Volume"
                                value={data.summary.volume.count}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Engagement"
                                value={data.summary.engagement.count}
                            />
                        </Col>

                        <Col span={8}>
                            <Statistic
                                title="Engagements per message"
                                value={
                                    data.summary.volume.count > 0 &&
                                    data.summary.engagement.count > 0
                                        ? Number(
                                              data.summary.engagement.count /
                                                  data.summary.volume.count
                                          )
                                        : 0
                                }
                                precision={2}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                        <Col span={8}>
                            <Statistic
                                title="Impressions"
                                value={data.summary.impressions.count}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Authors"
                                value={data.summary.authors.count}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Positive Sentiment"
                                value={data.summary.sentiment.score}
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <br />
                    <br />
                    <SocialDataPresentor
                        title={"Volume"}
                        description={"Just a description for the volume data"}
                        value={data.summary.volume.records}
                        value_name="count"
                        key_name="datetime"
                        type="AreaChart"
                        datasetStyle={{
                            borderWidth: 0,
                            borderColor: "transparent",
                            pointRadius: 2,
                        }}
                        // metric_name="page_fans"
                        // link="http://www.cnn.com"
                    />
                    <Divider />
                    <br />
                    <br />
                    <SocialDataPresentor
                        title={"Engagement"}
                        description={
                            "Just a description for the engagement data"
                        }
                        value={data.summary.engagement.records}
                        value_name="count"
                        key_name="datetime"
                        type="AreaChart"
                        // metric_name="page_fans"
                        // link="http://www.cnn.com"
                    />
                    <Divider />
                    <br />
                    <br />
                    <h2>Sentiment</h2>
                    <Row>
                        <Col span={8}>
                            <Progress
                                type="dashboard"
                                percent={Math.round(
                                    Number(
                                        ((data.summary.sentiment.score + 5) /
                                            10) *
                                            100
                                    )
                                    // Number(
                                    //     data.summary.sentiment.score
                                    // ).toFixed(2)
                                )}
                                showInfo={false}
                                strokeColor={
                                    data.summary.sentiment.score < -2
                                        ? "red"
                                        : data.summary.sentiment.score > -2 &&
                                          data.summary.sentiment.score < 2
                                        ? "orange"
                                        : "green"
                                }
                                strokeWidth={12}
                                gapDegree={120}
                            />
                            <div
                                style={{
                                    fontSize: 24,
                                    marginTop: -80,
                                    textAlign: "center",
                                    width: 120,
                                    fontWeight: "bold",
                                    letterSpacing: "-0.1rem",
                                }}
                            >
                                {Number(data.summary.sentiment.score).toFixed(
                                    2
                                )}
                            </div>
                        </Col>
                        <Col span={16}>
                            <SocialDataPresentor
                                // title={"Sentiment"}
                                // description={
                                //     "Just a description for the sentinent data"
                                // }
                                value={data.summary.sentiment.records}
                                value_name="score"
                                key_name="datetime"
                                hideTotal
                                type="AreaChart"
                                // metric_name="page_fans"
                                // link="http://www.cnn.com"
                            />
                        </Col>
                    </Row>

                    <Divider />

                    <Table
                        dataSource={sentimentDataSource}
                        columns={sentimentColumns}
                        pagination={false}
                        size="small"
                    />
                    <Divider />
                    <br />
                    <br />
                    <Divider />
                    <br />
                    <br />
                    <h2>Messages</h2>
                    <Table
                        dataSource={historyList}
                        columns={historyColumns}
                        pagination={false}
                    />

                    <Divider />
                    <br />
                    <br />
                    <h2>Top Words</h2>
                    <Table
                        dataSource={wordsList}
                        columns={wordsColumns}
                        pagination={false}
                    />

                    <Divider />
                    <br />
                    <br />
                    <h2>Top Mentioned Users</h2>
                    <Table
                        dataSource={mentionedUsersList}
                        columns={mentionedUsersColumns}
                        pagination={false}
                    />

                    <Divider />
                    <br />
                    <br />
                    <h2>Top Hashtags</h2>
                    <Table
                        dataSource={hashtagsList}
                        columns={hashtagsColumns}
                        pagination={false}
                    />

                    <Divider />
                    <br />
                    <br />
                    <h2>Top Authors</h2>
                    <Table
                        dataSource={authorsList}
                        columns={authorsColumns}
                        pagination={false}
                    />

                    {/* <pre>{JSON.stringify(data.summary, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(data.history, null, 2)}</pre> */}
                </Fragment>
            );

            return panel;
        }
    }
);

/* Wrapper component */
class AuditorsListModalMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            loading: true,
            results: {},
            // filter_range_from: props.data.created
            //     ? moment(props.data.created).valueOf()
            //     : moment().subtract(1, "month").valueOf(),
            // filter_range_to: moment().valueOf(),
            filter_facebook: true,
            filter_instagram: true,
            filter_twitter: true,
            filter_linkedin: true,
            filter_engagement_likes: true,
            filter_engagement_shares: true,
            filter_engagement_comments: true,
            filter_sentiment_positive: true,
            filter_sentiment_negative: true,
            filter_sentiment_neutral: true,
            initial_range_from: null,
            initial_range_to: null,
            auditor_key: null,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        let that = this;
        this.props.store.registerModalListener(
            "auditors_modal_monitor",
            async (_action, _payload) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
                if (_action === "show") {
                    that.setState({ auditor_key: _payload });
                    await this.loadInformation(_payload);
                }
            }
        );
    };

    loadInformation = async (_payload, _filter) => {
        console.info("loadInformation _payload", _payload, "_filter", _filter);
        this.setState({ loading: true, results: {} }); // reset
        if (_payload) {
            const results = await this.props.store.overviewAuditor(
                _payload,
                _filter
            );
            console.info("overviewAuditor results", results);
            this.setState({ loading: false, results });

            console.log(
                "loadInformation > this.state.filter_range_from = " +
                    this.state.filter_range_from
            );
            console.log(
                "loadInformation > this.state.filter_range_to = " +
                    this.state.filter_range_to
            );

            if (
                typeof this.state.filter_range_from === "undefined" &&
                typeof this.state.filter_range_to === "undefined"
            ) {
                console.info("UPDATE THE FILTERS !!! results", results);
                this.setState({
                    filter_range_from: results.summary.minDatetime
                        ? results.summary.minDatetime
                        : moment().subtract(1, "month").valueOf(),
                    filter_range_to: results.summary.maxDatetime
                        ? results.summary.maxDatetime
                        : moment().valueOf(),
                    initial_range_from: results.summary.minDatetime
                        ? results.summary.minDatetime
                        : moment().subtract(1, "month").valueOf(),
                    initial_range_to: results.summary.maxDatetime
                        ? results.summary.maxDatetime
                        : moment().valueOf(),
                });
            }
        }
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("auditors_modal_monitor");
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "auditors_modal_monitor",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    handleCheckboxChange = (_name, _evt) => {
        this.props.data[_name] = _evt.target.checked;
    };

    onRangeChange = async (date, dateString) => {
        console.log(date, dateString);
        this.setState({
            filter_range_from: date[0].valueOf(),
            filter_range_to: date[1].valueOf(),
        });
        this.onFiltersChange();
    };

    disabledDateFunction(current, range_from, range_to) {
        // Can not select days before today and today
        return current.valueOf() < range_from || current.valueOf() > range_to;
    }

    onFiltersChange = async () => {
        await social_sleep(500);
        console.log("onFiltersChange this.state", this.state);
        const filterObj = {
            range_from: moment(this.state.filter_range_from).format(
                "YYYY-MM-DD"
            ),
            range_to: moment(this.state.filter_range_to).format("YYYY-MM-DD"),
            facebook: this.state.filter_facebook,
            instagram: this.state.filter_instagram,
            twitter: this.state.filter_twitter,
            linkedin: this.state.filter_linkedin,
            engagement_likes: this.state.filter_engagement_likes,
            engagement_shares: this.state.filter_engagement_shares,
            engagement_comments: this.state.filter_engagement_comments,
            sentiment_positive: this.state.filter_sentiment_positive,
            sentiment_negative: this.state.filter_sentiment_negative,
            sentiment_neutral: this.state.filter_sentiment_neutral,
        };

        if (this.loadInformation) {
            this.loadInformation(this.props.data.key, filterObj);
        }
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;

        const filterPanel = (
            <Fragment>
                {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                {/* filter_range_from:
                <br />
                {this.state.filter_range_from &&
                    this.state.filter_range_from}{" "}
                <br />
                {this.state.filter_range_from &&
                    moment(this.state.filter_range_from).format("lll")}{" "}
                <br />
                filter_range_to:
                <br />
                {this.state.filter_range_to && this.state.filter_range_to}{" "}
                <br />
                {this.state.filter_range_to &&
                    moment(this.state.filter_range_to).format("lll")}{" "}
                <br /> */}
                <div className="social_AuditorsMonitorForm">
                    <Form.Item label="Period">
                        <RangePicker
                            onChange={this.onRangeChange}
                            disabledDate={(current) =>
                                this.disabledDateFunction(
                                    current,
                                    this.state.initial_range_from,
                                    this.state.initial_range_to
                                )
                            }
                            value={
                                this.state.filter_range_from
                                    ? [
                                          moment(this.state.filter_range_from),
                                          moment(this.state.filter_range_to),
                                      ]
                                    : null
                            }
                            format="MM/DD YY"
                        />
                    </Form.Item>
                    <br />
                    <Form.Item label="Networks">
                        <Checkbox
                            checked={this.state.filter_facebook}
                            onChange={(e) => {
                                this.setState({
                                    filter_facebook: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Facebook
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={this.state.filter_instagram}
                            onChange={(e) => {
                                this.setState({
                                    filter_instagram: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Instagram
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={this.state.filter_twitter}
                            onChange={(e) => {
                                this.setState({
                                    filter_twitter: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Twitter
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={this.state.filter_linkedin}
                            onChange={(e) => {
                                this.setState({
                                    filter_linkedin: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            LinkedIn
                        </Checkbox>
                    </Form.Item>
                    <br />
                    <Form.Item label="Engagement">
                        <Checkbox
                            checked={this.state.filter_engagement_likes}
                            onChange={(e) => {
                                this.setState({
                                    filter_engagement_likes: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Likes
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={this.state.filter_engagement_shares}
                            onChange={(e) => {
                                this.setState({
                                    filter_engagement_shares: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Shares
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={this.state.filter_engagement_comments}
                            onChange={(e) => {
                                this.setState({
                                    filter_engagement_comments:
                                        e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Comments
                        </Checkbox>
                    </Form.Item>
                    <br />
                    <Form.Item label="Sentiment">
                        <Checkbox
                            checked={this.state.filter_sentiment_positive}
                            onChange={(e) => {
                                this.setState({
                                    filter_sentiment_positive: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Positive
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={this.state.filter_sentiment_negative}
                            onChange={(e) => {
                                this.setState({
                                    filter_sentiment_negative: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Negative
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={this.state.filter_sentiment_neutral}
                            onChange={(e) => {
                                this.setState({
                                    filter_sentiment_neutral: e.target.checked,
                                });
                                this.onFiltersChange();
                            }}
                        >
                            Neutral
                        </Checkbox>
                    </Form.Item>
                </div>
            </Fragment>
        );

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Monitor listener"}
                        // okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalCancel}
                        // onOk={
                        //     !data.name ? this.onModalCreate : this.onModalEdit
                        // } //onApply
                        cancelButtonProps={{ style: { display: "none" } }}
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <Row>
                            <Col
                                span={18}
                                style={{
                                    // border: "1px solid red",
                                    height: 545,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    paddingRight: 20,
                                }}
                            >
                                {this.state.loading === true ? (
                                    <Spin
                                        indicator={
                                            <Icon
                                                type="loading"
                                                style={{
                                                    fontSize: 48,
                                                    marginBottom: 10,
                                                    color: this.props.store.getTagColorForType(
                                                        "campaign"
                                                    ),
                                                }}
                                                spin
                                            />
                                        }
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    />
                                ) : (
                                    <ModalForm
                                        store={this.props.store}
                                        wrappedComponentRef={this.saveFormRef}
                                        data={this.state.results}
                                        filter_facebook={
                                            this.state.filter_facebook
                                        }
                                        filter_instagram={
                                            this.state.filter_instagram
                                        }
                                        filter_twitter={
                                            this.state.filter_twitter
                                        }
                                        filter_linkedin={
                                            this.state.filter_linkedin
                                        }
                                        filter_engagement_likes={
                                            this.state.filter_engagement_likes
                                        }
                                        filter_engagement_shares={
                                            this.state.filter_engagement_shares
                                        }
                                        filter_engagement_comments={
                                            this.state
                                                .filter_engagement_comments
                                        }
                                        filter_sentiment_positive={
                                            this.state.filter_sentiment_positive
                                        }
                                        filter_sentiment_negative={
                                            this.state.filter_sentiment_negative
                                        }
                                        filter_sentiment_neutral={
                                            this.state.filter_sentiment_neutral
                                        }
                                        //term={this.props.data.term}
                                    />
                                )}
                            </Col>
                            <Col
                                span={6}
                                style={{
                                    leftBorder: "1px solid #ccc",
                                    height: 545,
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    paddingLeft: 20,
                                }}
                            >
                                {filterPanel}
                            </Col>
                        </Row>
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AuditorsListModalMonitor);
