import React, { Fragment } from "react";
import ReactJson from "react-json-view";
import { Typography, Divider } from "antd";
import moment from "moment";
const { Title, Text } = Typography;

const AccountsChannelInfoYoutube = ({ channel_info }) => {
    const link = `https://studio.youtube.com/channel/${channel_info?.id}`;

    return (
        <Fragment>
            {/* <ReactJson src={channel_info} /> */}
            {/* <Divider /> */}
            <Text>Name</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.name}
            </Title>

            <Text>Description</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.snippet.description}
            </Title>

            <Text>Started</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {moment(channel_info.snippet.publishedAt).format("lll")}
            </Title>

            <Text>Thumbnail</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                <img src={channel_info.snippet.thumbnails.default.url} />
            </Title>

            <Text>Link</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                <Fragment>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                    </a>
                </Fragment>
            </Title>
        </Fragment>
    );
};

export default AccountsChannelInfoYoutube;
