import React, { Component, Fragment } from "react";
import {
    Form,
    Modal,
    Input,
    AutoComplete,
    Row,
    Col,
    Select,
    Tree,
    Button,
    Alert,
    Icon,
    Divider,
    Tooltip,
    Slider,
    Radio,
    Spin,
    Checkbox,
    InputNumber,
    Steps,
    DatePicker,
    // Result,
    Collapse,
} from "antd";
import config from "../../config";
import moment from "moment";
import EditableTagGroup from "../EditableTagGroup";
import AdsPreview from "./AdsPreview";
import AdsListModalFormPanelBasic from "./AdsListModalFormPanelBasic";
import AdsListModalFormPanelFacebookInstagram from "./AdsListModalFormPanelFacebookInstagram";
import AdsListModalFormPanelLinkedin from "./AdsListModalFormPanelLinkedin";
import AdsListModalFormPanelTwitter from "./AdsListModalFormPanelTwitter";
import AdsListModalFormPanelConfirm from "./AdsListModalFormPanelConfirm";
import names from "./../../names";

const { TreeNode } = Tree;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const { Step } = Steps;
const { Panel } = Collapse;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        typeTimer = null;
        audienceCountTimer = null;

        constructor(props) {
            super(props);
            this.state = {
                checkedKeys: [],
                masterpost_filter: "",
                campaign: null,
                ad_name: "",
                facebook_targeting: {
                    countries: [],
                    regions: [],
                    cities: [],
                    zips: [],
                    languages: [],
                    interests: [],
                    custom_audiences: [],
                    //
                    selected_gender: "all",
                    selected_age: [30, 50],
                    selected_country: [],
                    selected_region: [],
                    selected_city: [],
                    selected_zip: [],
                    selected_language: [],
                    selected_interest: [],
                },
                facebook_details: {
                    objective: "REACH",
                    optimization_goal: "REACH",
                    billing_event: "IMPRESSIONS",
                    bid_amount: 2,
                    daily_budget: 1000,
                    status: "PAUSED",
                },
                fetching: false,
                ad_scope: "ad",
                newad_type: "singleimagead",
                newad_calltoactiontype: "SHOP_NOW",
                newad_url: "http://www.uhub.app",
                newad_title: "Just a title",
                newad_body: "Some description",
                newad_networks_facebook: true,
                newad_networks_instagram: true,
                data: {},
                carousel: [{}],
                networks_facebook: true,
                networks_linkedin: true,
                networks_twitter: true,
                linkedin_newad_type: "TEXT_AD",
                linkedin_targeting: {},
                twitter_targeting: {},
                twitter_newad_objectivetype: "ENGAGEMENTS",
                twitter_newad_placements: "ALL_ON_TWITTER",
                twitter_newad_producttype: "PROMOTED_TWEETS",
                twitter_newad_bidamount: 10000,
            };
        }

        componentDidMount() {
            // To disable submit button at the beginning.
            let data = this.props.data;
            this.setState({ data });

            console.info("componentDidMount this.props.data", this.props.data);

            if (this.props.data.post_id) {
                console.info(
                    "componentDidMount this.props.data.post_id",
                    this.props.data.post_id
                );
                this.onCheck([this.props.data.post_id]);
            }

            if (this.props.data.post_text) {
                this.setState({ ad_name: `${this.props.data.post_text} - Ad` });
            }

            if (this.props.data.masterpost_key) {
                this.onCheck([this.props.data.masterpost_key]);
            }

            if (this.props.data.state_to_duplicate) {
                let { facebook_targeting } = this.state;
                let { state_to_duplicate } = this.props.data;
                facebook_targeting = {
                    ...facebook_targeting,
                    ...state_to_duplicate.facebook_targeting,
                };
                state_to_duplicate.facebook_targeting = facebook_targeting;
                this.setState({
                    ...state_to_duplicate,
                });
            }
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            const currentStepKey = this.props.steps[nextProps.step].key;
            if (currentStepKey === "fb_boost_basic" && !this.state.treeData)
                this.loadTreeNodes();
        }
        //   handleSubmit = e => {
        //       e.preventDefault();
        //       this.props.form.validateFields((err, values) => {
        //           if (!err) {
        //               console.log("Received values of form: ", values);
        //           }
        //       });
        //   };

        loadTreeNodes = () => {
            //getting the tree
            this.updateTreeNodes();
        };

        updateTreeNodes = () => {
            let treeData = this.getTreeData();

            const filtered_treeNodes =
                treeData && this.renderTreeNodes(treeData);
            this.setState({ filtered_treeNodes, treeData });
        };
        getSelectedTreeNodes = () => {
            let treeData = this.getSelectedTreeData();
            //console.info("getSelectedTreeNodes treeData", treeData);
            const filtered_treeNodes =
                treeData && this.renderTreeNodes(treeData);
            return filtered_treeNodes;
        };

        getSelectedNewAdAccountsTreeNodes = () => {
            let treeData = this.getSelectedNewAccountsTreeData();
            //console.info("getSelectedTreeNodes treeData", treeData);
            const filtered_treeNodes =
                treeData && this.renderAccountsTreeNodes(treeData);
            return filtered_treeNodes;
        };
        getSelectedNewAdLinkedinAccountsTreeNodes = () => {
            let treeData = this.getSelectedNewLinkedinAccountsTreeData();
            //console.info("getSelectedTreeNodes treeData", treeData);
            const filtered_treeNodes =
                treeData && this.renderAccountsTreeNodesForLinkedin(treeData);
            return filtered_treeNodes;
        };
        getSelectedNewAdTwitterAccountsTreeNodes = () => {
            let treeData = this.getSelectedNewTwitterAccountsTreeData();
            //console.info("getSelectedTreeNodes treeData", treeData);
            const filtered_treeNodes =
                treeData && this.renderAccountsTreeNodesForTwitter(treeData);
            return filtered_treeNodes;
        };
        onMasterpostsSearchChange = (_filter) => {
            console.info("onmasterpostsSearchChange _filter", _filter);
            let treeData = this.getTreeData(_filter);

            const filtered_treeNodes =
                treeData && this.renderTreeNodes(treeData);

            console.info("filtered_treeNodes", filtered_treeNodes);
            this.setState({ masterpost_filter: _filter, filtered_treeNodes });
        };

        handleAddAllMasterposts = () => {
            console.log(`handleAddAllMasterposts`);
            let onlyKeys = [];
            const treeMasterposts = this.getTreeData();
            treeMasterposts.map((a) => {
                onlyKeys.push(a.key);
                return false;
            });
            console.info(`handleAddAllMasterposts onlyKeys`, onlyKeys);

            //this.props.data.accounts = onlyKeys;

            this.props.form.setFieldsValue({
                masterposts: onlyKeys,
            });

            this.setState({ masterposts: onlyKeys });
        };

        handlemasterpostsChange = (value) => {
            console.log(`handlemasterpostsChange ${value}`);

            this.props.form.setFieldsValue({ masterposts: value });
        };

        handleCampaignsChange = (_campaigns) => {
            this.setState({ campaigns: _campaigns });
        };

        // renderTreeNodes = data => {
        //     console.log("renderTreeNodes filter=" + this.state.filter);
        //     let nodes = data.map(item => {
        //         if (this.state.filter) {
        //             console.log("we have filter");
        //             if (
        //                 item.name
        //                     .toUpperCase()
        //                     .indexOf(this.state.filter.toUpperCase()) !== -1
        //             ) {
        //                 return <TreeNode key={item.key} title={item.name} />;
        //             }
        //         } else {
        //             return <TreeNode key={item.key} title={item.name} />;
        //         }
        //         return false;
        //     });
        //     nodes = nodes.filter(n => (typeof n !== "undefined" ? n : false));
        //     console.info("renderTreeNodes nodes", nodes);

        //     return nodes.length > 0 ? nodes : [];
        // };
        fetchLinkedinFacet = (_facet, _skipDelay) => {
            console.log("fetchLinkedinFacet _facet", _facet);

            if (
                !this.state.newad_linkedin_accounts ||
                this.state.newad_linkedin_accounts.length === 0
            ) {
                return;
            }

            const { linkedin_targeting } = this.state;
            if (
                !linkedin_targeting[_facet] ||
                (linkedin_targeting[_facet] &&
                    linkedin_targeting[_facet].length === 0)
            ) {
                linkedin_targeting[_facet] = [];
                const that = this;
                if (this.typeTimer) clearTimeout(this.typeTimer);
                this.typeTimer = setTimeout(
                    function () {
                        that.setState({ linkedin_targeting, fetching: true });
                        that.props.store
                            .getLinkedinFacet(
                                that.state.newad_linkedin_accounts[0],
                                _facet
                            )
                            .then((data) => {
                                const { linkedin_targeting } = that.state;
                                linkedin_targeting[_facet] = data;
                                that.setState({
                                    linkedin_targeting,
                                    fetching: false,
                                });
                            })
                            .catch((error) => {
                                console.info("WE HAVE ERROR", error);
                            });
                    },
                    !_skipDelay ? 750 : 0
                );
            }
        };
        fetchCountry = (value) => {
            console.log("fetchCountry value", value);
            const { facebook_targeting } = this.state;
            facebook_targeting.countries = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(
                        value,
                        "adgeolocation",
                        `location_types=["country"]`
                    )
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.countries = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchRegion = (value) => {
            console.log("fetchRegion value", value);
            if (value.length < 2) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.regions = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(
                        value,
                        "adgeolocation",
                        `location_types=["region"]`
                    )
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.regions = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchCity = (value) => {
            console.log("fetchCity value", value);
            if (value.length < 3) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.cities = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(
                        value,
                        "adgeolocation",
                        `location_types=["city"]`
                    )
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.cities = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchZip = (value) => {
            console.log("fetchZip value", value);
            if (value.length < 2) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.zips = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(
                        value,
                        "adgeolocation",
                        `location_types=["zip"]`
                    )
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.zips = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchLanguage = (value) => {
            console.log("fetchLanguage value", value);
            if (value.length < 3) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.languages = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(value, "adlocale")
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.languages = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchInterest = (value) => {
            console.log("fetchInterest value", value);
            if (value.length < 3) return;
            const { facebook_targeting } = this.state;
            facebook_targeting.interests = [];
            const that = this;
            if (this.typeTimer) clearTimeout(this.typeTimer);
            this.typeTimer = setTimeout(function () {
                that.setState({ facebook_targeting, fetching: true });
                that.props.store
                    .getFacebookTarget(value, "adinterest")
                    .then((data) => {
                        const { facebook_targeting } = that.state;
                        facebook_targeting.interests = data.data;
                        that.setState({ facebook_targeting, fetching: false });
                    });
            }, 750);
        };
        fetchCustomAudiences = () => {
            // check for facebook ad account
            const account = this.props.store.getAllAccounts.filter(
                (account) => account.key === this.props.accountId
            )[0];
            if (!account.facebook_ad_account) {
                Modal.warning({
                    title: "Missing Facebook Ad Account",
                    content:
                        "Please make sure that a Facebook Ad Account is set for the current account.",
                });
                return;
            }

            this.setState({ fetching_custom_audiences: true });
            this.props.store
                .getAccountCustomAudience(
                    this.props.accountId,
                    this.props.provider
                )
                .then((data) => {
                    const audiences = data.fbRes.data;
                    // const audiences = [];
                    if (audiences.length === 0) {
                        Modal.warning({
                            title: "No custom audiences assinged to the Facebook Ad Account",
                            content: (
                                <Fragment>
                                    In order to setup custom audiences in your
                                    Facebook Ad Account please follow this link:
                                    <Divider style={{ marginBottom: 20 }} />
                                    <a
                                        rel="noopener noreferrer"
                                        href={`https://business.facebook.com/settings/ad-accounts?business_id=${this.props.channel_id}`}
                                        target="_blank"
                                    >
                                        Facebook Ad Accounts
                                    </a>
                                </Fragment>
                            ),
                        });

                        this.setState({ fetching_custom_audiences: false });
                        return;
                    }

                    // Don;t have properly named audiences
                    console.info(
                        "account.name.toUpperCase()=",
                        account.name.toUpperCase()
                    );
                    const filtered_audiences = audiences.filter(
                        (audience) =>
                            audience.name
                                .toUpperCase()
                                .indexOf(account.name.toUpperCase()) !== -1
                    );
                    if (filtered_audiences.length === 0) {
                        Modal.warning({
                            title: "No custom audiences named accodrding to our naming convention",
                            content: (
                                <Fragment>
                                    In order to setup/rename custom audiences in
                                    your Facebook Ad Account please follow this
                                    link:
                                    <Divider style={{ marginBottom: 20 }} />
                                    <a
                                        rel="noopener noreferrer"
                                        href={`https://business.facebook.com/settings/ad-accounts?business_id=${this.props.channel_id}`}
                                        target="_blank"
                                    >
                                        Facebook Ad Accounts
                                    </a>
                                </Fragment>
                            ),
                        });
                        this.setState({ fetching_custom_audiences: false });
                        return;
                    }

                    // https://business.facebook.com/settings/ad-accounts?business_id=485219712344613

                    console.info(
                        "getAccountCustomAudience audiences",
                        audiences
                    );
                    let { facebook_targeting } = this.state;
                    facebook_targeting.custom_audiences = data.fbRes.data;
                    this.setState({
                        facebook_targeting,
                        fetching_custom_audiences: false,
                    });
                })
                .catch((error) => {
                    console.warn(error);
                });
        };
        selectFacebookTargetParam = (_param, _value) => {
            console.info(
                "selectFacebookTargetParam _param",
                _param,
                "_value",
                _value
            );
            let { facebook_targeting } = this.state;
            facebook_targeting["selected_" + _param] = _value;
            this.setState({ facebook_targeting, changed: true });
        };

        selectLinkedinTargetParam = (_param, _value) => {
            console.info(
                "selectLinkedinTargetParam _param",
                _param,
                "_value",
                _value
            );
            let { linkedin_targeting } = this.state;
            linkedin_targeting["selected_" + _param] = _value;
            this.setState({ linkedin_targeting, changed: true });

            // update the audienceCount
            this.getLinkedinAudienceCount(_param); // TODO: Add account key here
        };
        selectTwitterTargetParam = (_param, _value) => {
            console.info(
                "selectTwitterTargetParam _param",
                _param,
                "_value",
                _value
            );
            let { twitter_targeting } = this.state;
            twitter_targeting["selected_" + _param] = _value;
            this.setState({ twitter_targeting, changed: true });

            // update the audienceCount
            //this.getLinkedinAudienceCount(_param); // TODO: Add account key here
        };
        updateTwitterTargeting = (_targeting) => {
            console.info("updateTwitterTargeting _targeting", _targeting);
            let { twitter_targeting } = this.state;
            twitter_targeting = { ...twitter_targeting, ..._targeting };
            this.setState({ twitter_targeting, changed: true });

            // update the audienceCount
            //this.getLinkedinAudienceCount(_param); // TODO: Add account key here
        };
        fetchTwitterCriteries = (_criteria, _skipDelay) => {
            console.log("fetchTwitterCriteries _criteria", _criteria);

            const { twitter_targeting } = this.state;
            if (
                !twitter_targeting[_criteria] ||
                (twitter_targeting[_criteria] &&
                    twitter_targeting[_criteria].length === 0)
            ) {
                twitter_targeting[_criteria] = [];
                const that = this;
                if (this.typeTimer) clearTimeout(this.typeTimer);
                this.typeTimer = setTimeout(
                    function () {
                        that.setState({ twitter_targeting, fetching: true });
                        that.props.store
                            .getTwitterCriteria(_criteria)
                            .then((data) => {
                                const { twitter_targeting } = that.state;
                                twitter_targeting[_criteria] = data;
                                that.setState({
                                    twitter_targeting,
                                    fetching: false,
                                });
                            })
                            .catch((error) => {
                                console.info("WE HAVE ERROR", error);
                            });
                    },
                    !_skipDelay ? 750 : 0
                );
            }
        };

        renderTreeNodes = (data) =>
            data.map((item, itemIndex) => {
                if (item.children) {
                    return (
                        <TreeNode
                            title={item.title}
                            key={item.key ? item.key : itemIndex}
                            dataRef={item}
                        >
                            {this.renderTreeNodes(item.children)}
                        </TreeNode>
                    );
                } else {
                    return (
                        <TreeNode
                            key={item.key ? item.key : itemIndex}
                            title={
                                <Fragment>
                                    {item.type ? (
                                        <Tooltip
                                            title={
                                                item.disabled
                                                    ? item.disabled_hint
                                                    : null
                                            }
                                        >
                                            <Icon
                                                type={
                                                    item.disabled
                                                        ? "exclamation-circle"
                                                        : item.type
                                                }
                                                theme={
                                                    !item.disabled
                                                        ? "filled"
                                                        : null
                                                }
                                                style={{
                                                    marginRight: 5,
                                                    color: this.props.store.getTagColorForType(
                                                        item.type
                                                    ),
                                                }}
                                            />
                                        </Tooltip>
                                    ) : null}
                                    {item.title}
                                </Fragment>
                            }
                            disabled={item.disabled}
                            dataRef={item}
                        ></TreeNode>
                    );
                }
                //return <TreeNode key={item.key} {...item} />;
            });

        onCheck = (checkedKeys) => {
            console.log("onCheck", checkedKeys);
            this.setState({ masterposts: checkedKeys });

            this.props.form.setFieldsValue({
                masterposts: checkedKeys,
            });
        };

        getSelectedTreeData = () => {
            const availableMasterPosts =
                this.state.campaign === null
                    ? this.props.store.getAllMasterposts
                    : this.props.store.getAllMasterposts.filter(
                          (masterpost) => {
                              if (
                                  masterpost.campaigns &&
                                  masterpost.campaigns.indexOf(
                                      this.state.campaign
                                  ) !== -1
                              )
                                  return masterpost;
                              return false;
                          }
                      );
            //console.info("availableMasterPosts", availableMasterPosts);

            const all_masterposts = availableMasterPosts.filter(
                (masterpost) =>
                    this.state.masterposts &&
                    this.state.masterposts.includes(masterpost.key)
            );
            // console.info(
            //     "getSelectedTreeData all_masterposts",
            //     all_masterposts
            // );
            // get posts for masterpost
            all_masterposts.map((masterpost) => {
                masterpost.title = masterpost.name;
                let children = [];
                this.props.store
                    .getAllPostsForMasterpost(masterpost)
                    .map((post) => {
                        post.results &&
                            post.results.map((result) => {
                                if (
                                    this.state.masterposts &&
                                    this.state.masterposts.includes(
                                        result.post_id
                                    )
                                ) {
                                    children.push({
                                        account_key: post.account.key,
                                        title:
                                            post.account.name +
                                            " (" +
                                            result.channel_name +
                                            ")",
                                        key: result.post_id,
                                        type: result.type,
                                        disabled:
                                            post.account.facebook_ad_account &&
                                            post.account.facebook_ad_account
                                                .length > 1
                                                ? false
                                                : true,
                                        disabled_hint:
                                            !post.account.facebook_ad_account ||
                                            post.account.facebook_ad_account
                                                .length === 1
                                                ? "Missing Facebook Ad Account"
                                                : "",
                                    });
                                }
                                return false;
                            });

                        return false;
                    });
                masterpost.children = children;
                return false;
            });
            //console.info("getTreeData all_masterposts", all_masterposts);
            return all_masterposts;
        };

        getSelectedNewAccountsTreeData = () => {
            const allAccounts = this.props.store.getAllAccounts
                .filter(
                    (a) =>
                        this.state.newad_accounts &&
                        this.state.newad_accounts.includes(a.key)
                )
                .map((a) => {
                    let accountData = {
                        title: `${a.name}`,
                        key: a.key,
                    };
                    if (
                        a.facebook &&
                        a.facebook.data &&
                        a.facebook.data.length > 0
                    ) {
                        accountData.children = a.facebook.data.map((d) => {
                            return {
                                title: d.name,
                                key: a.key + "|" + d.id,
                            };
                        });
                    }
                    return accountData;
                });

            return allAccounts;
        };
        getSelectedNewLinkedinAccountsTreeData = () => {
            const allAccounts = this.props.store.getAllAccounts
                .filter(
                    (a) =>
                        a.linkedin &&
                        this.state.newad_linkedin_accounts &&
                        this.state.newad_linkedin_accounts.includes(a.key)
                )
                .map((a) => {
                    let accountData = {
                        title: `${a.name}`,
                        key: a.key,
                    };
                    return accountData;
                });

            return allAccounts;
        };
        getSelectedNewTwitterAccountsTreeData = () => {
            const allAccounts = this.props.store.getAllAccounts
                .filter(
                    (a) =>
                        a.linkedin &&
                        this.state.newad_twitter_accounts &&
                        this.state.newad_twitter_accounts.includes(a.key)
                )
                .map((a) => {
                    let accountData = {
                        title: `${a.name}`,
                        key: a.key,
                    };
                    return accountData;
                });

            return allAccounts;
        };

        getTreeData = (_forced_filter) => {
            const filter = !_forced_filter
                ? this.state.masterpost_filter
                : _forced_filter;
            const availableMasterPosts =
                this.state.campaign === null
                    ? this.props.store.getAllMasterposts
                    : this.props.store.getAllMasterposts.filter(
                          (masterpost) => {
                              if (
                                  masterpost.campaigns &&
                                  masterpost.campaigns.indexOf(
                                      this.state.campaign
                                  ) !== -1
                              )
                                  return masterpost;
                              return false;
                          }
                      );
            //console.info("availableMasterPosts", availableMasterPosts);
            const all_masterposts =
                filter.length === 0
                    ? availableMasterPosts
                    : availableMasterPosts.filter(
                          (masterpost) =>
                              masterpost.name
                                  .toUpperCase()
                                  .indexOf(filter.toUpperCase()) !== -1
                      );

            // get posts for masterpost
            all_masterposts.map((masterpost) => {
                masterpost.title = masterpost.name;
                let children = [];
                this.props.store
                    .getAllPostsForMasterpost(masterpost)
                    .map((post) => {
                        post.results &&
                            post.results.map((result) => {
                                //if (post.account.name.toUpperCase().indexOf(filter.toUpperCase())!==-1) {
                                children.push({
                                    account_key: post.account.key,
                                    title:
                                        post.account.name +
                                        " (" +
                                        result.channel_name +
                                        ")",
                                    key: result.post_id,
                                    type: result.type,
                                    disabled:
                                        post.account.facebook_ad_account &&
                                        post.account.facebook_ad_account
                                            .length > 1
                                            ? false
                                            : true,
                                    disabled_hint:
                                        !post.account.facebook_ad_account ||
                                        post.account.facebook_ad_account
                                            .length === 1
                                            ? "Missing Facebook Ad Account"
                                            : "",
                                });
                                //}
                                return false;
                            });
                        return false;
                    });
                masterpost.children = children;
                return false;
            });
            //console.info("getTreeData all_masterposts", all_masterposts);
            return all_masterposts;
        };

        handleChangeLibrarySelected = (_selected, _cIndex) => {
            console.info(
                "handleChangeLibrarySelected _selected",
                _selected,
                "_cIndex",
                _cIndex
            );

            if (typeof _cIndex === "undefined") {
                this.setState({
                    library_file: _selected[0],
                    previewURL: _selected[0] ? _selected[0].downloadURL : "",
                    changed_newad_image: true,
                });
            } else {
                // carousel (slides)
                console.log("handleChangeLibrarySelected IS NOT UNDEFINED");
                let { carousel } = this.state;
                let curCarousel = carousel[_cIndex];
                delete curCarousel.image;
                delete curCarousel.fileList;
                curCarousel.changed_image = false;
                curCarousel.library_file = _selected[0];
                curCarousel.previewURL = _selected[0]
                    ? _selected[0].thumbnail
                        ? _selected[0].thumbnail
                        : _selected[0].downloadURL
                    : "";
                this.setState({ carousel });
            }
        };

        handleUpload = (_evt, _cIndex) => {
            console.info("handleUpload _evt", _evt, "_cIndex", _cIndex);
            //console.info("handleUpload this.props", this.props);

            if (_cIndex) {
                let { carousel } = this.state;
                let curCarousel = carousel[_cIndex];
                //console.info('handleTargetChange curTarget',curTarget);
                curCarousel.image = _evt.file;
                curCarousel.fileList = _evt.fileList;
                curCarousel.changed_image = true;
                curCarousel.previewURL = "";

                if (_evt.fileList.length === 1) {
                    let reader = new FileReader();
                    let preview_file = _evt.file;
                    console.info("handleUpload preview_file", preview_file);
                    reader.onloadend = () => {
                        curCarousel.previewURL = reader.result;
                        this.setState({ carousel });
                    };
                    reader.readAsDataURL(preview_file);
                } else {
                    // clear the current file
                    delete curCarousel.image;
                    delete curCarousel.fileList;
                    curCarousel.changed_image = false;
                }

                console.info("handleUpload curTarget", curCarousel);
                this.setState({ carousel });
            } else {
                // single image
                //console.info('handleTargetChange curTarget',curTarget);
                this.setState({
                    newad_image: _evt.file,
                    newad_fileList: _evt.fileList,
                    newad_changed_image_file: true,
                    previewURL: "",
                });

                if (_evt.fileList.length === 1) {
                    let reader = new FileReader();
                    let preview_file = _evt.file;
                    console.info("handleUpload preview_file", preview_file);
                    reader.onloadend = () => {
                        this.setState({ previewURL: reader.result });
                    };
                    reader.readAsDataURL(preview_file);
                } else {
                    // clear the current file
                    this.setState({
                        newad_image: null,
                        newad_fileList: null,
                        newad_changed_image_file: false,
                    });
                }
            }
        };

        deleteCarouselSlide = (_e, _index) => {
            let { carousel } = this.state;
            carousel.splice(_index, 1);
            this.setState({ carousel });
        };

        addCarouselSlide = (_e) => {
            let { carousel } = this.state;
            carousel.push({});
            this.setState({ carousel });
        };

        onNewAdCheck = (_checked) => {
            this.setState({ newad_accounts: _checked });
        };
        onNewAdLinkedinCheck = (_checked) => {
            this.setState({ newad_linkedin_accounts: _checked });
        };
        onNewAdTwitterCheck = (_checked) => {
            this.setState({ newad_twitter_accounts: _checked });
        };

        renderAccountsTreeNodes = (data) =>
            data.map((item) => {
                if (item.children) {
                    return (
                        <TreeNode
                            title={item.title}
                            key={item.key}
                            dataRef={item}
                        >
                            {this.renderAccountsTreeNodes(item.children)}
                        </TreeNode>
                    );
                } else {
                    return (
                        <TreeNode
                            title={
                                <Fragment>
                                    <Icon
                                        type="facebook"
                                        theme="filled"
                                        style={{
                                            marginRight: 5,
                                            color: this.props.store.getTagColorForType(
                                                "facebook"
                                            ),
                                        }}
                                    />
                                    {item.title}
                                </Fragment>
                            }
                            key={item.key}
                            dataRef={item}
                        ></TreeNode>
                    );
                }
                //return <TreeNode key={item.key} {...item} />;
            });

        renderAccountsTreeNodesForLinkedin = (data) =>
            data.map((item) => {
                return (
                    <TreeNode
                        title={
                            <Fragment>
                                <Icon
                                    type="linkedin"
                                    theme="filled"
                                    style={{
                                        marginRight: 5,
                                        color: this.props.store.getTagColorForType(
                                            "linkedin"
                                        ),
                                    }}
                                />
                                {item.title}
                            </Fragment>
                        }
                        key={item.key}
                        dataRef={item}
                    ></TreeNode>
                );
                //return <TreeNode key={item.key} {...item} />;
            });

        renderAccountsTreeNodesForTwitter = (data) =>
            data.map((item) => {
                return (
                    <TreeNode
                        title={
                            <Fragment>
                                <Icon
                                    type="twitter"
                                    style={{
                                        marginRight: 5,
                                        color: this.props.store.getTagColorForType(
                                            "twitter"
                                        ),
                                    }}
                                />
                                {item.title}
                            </Fragment>
                        }
                        key={item.key}
                        dataRef={item}
                    ></TreeNode>
                );
                //return <TreeNode key={item.key} {...item} />;
            });

        handleFilePickerChange = (_type, _file, _carouselIndex) => {
            console.info(
                "handleFilePickerChange _type",
                _type,
                "_file",
                _file,
                "_carouselIndex",
                _carouselIndex
            );
            switch (_type) {
                case "ad_basic_image":
                    this.setState({ newad_basic_image: _file });
                    break;
                case "singlead":
                    this.setState({ newad_image: _file });
                    break;
                case "carousel":
                    let { carousel } = this.state;
                    let curCarousel = carousel[_carouselIndex];
                    curCarousel.image = _file;
                    curCarousel.previewURL = _file.image_data; // using the image data for the previews
                    curCarousel.image_data = _file.image_data;
                    // if (_file.fileList.length === 1) {
                    //     let reader = new FileReader();
                    //     let preview_file = _file.file;
                    //     console.info(
                    //         "handleFilePickerChange preview_file",
                    //         preview_file
                    //     );
                    //     reader.onloadend = () => {
                    //         curCarousel.previewURL = reader.result;
                    //         this.setState({ carousel });
                    //     };
                    //     reader.readAsDataURL(preview_file);
                    // } else {
                    //     // clear the current file
                    //     delete curCarousel.image;
                    //     delete curCarousel.fileList;
                    //     curCarousel.changed_image = false;
                    // }

                    console.info("handleUpload curTarget", curCarousel);
                    this.setState({ carousel });

                    break;
                default:
                    break;
            }
        };

        handleUpload = (_evt, _cIndex) => {
            console.info("handleUpload _evt", _evt, "_cIndex", _cIndex);
            //console.info("handleUpload this.props", this.props);

            if (_cIndex) {
                let { carousel } = this.state;
                let curCarousel = carousel[_cIndex];
                //console.info('handleTargetChange curTarget',curTarget);
                curCarousel.image = _evt.file;
                curCarousel.fileList = _evt.fileList;
                curCarousel.changed_image = true;
                curCarousel.previewURL = "";

                if (_evt.fileList.length === 1) {
                    let reader = new FileReader();
                    let preview_file = _evt.file;
                    console.info("handleUpload preview_file", preview_file);
                    reader.onloadend = () => {
                        curCarousel.previewURL = reader.result;
                        this.setState({ carousel });
                    };
                    reader.readAsDataURL(preview_file);
                } else {
                    // clear the current file
                    delete curCarousel.image;
                    delete curCarousel.fileList;
                    curCarousel.changed_image = false;
                }

                console.info("handleUpload curTarget", curCarousel);
                this.setState({ carousel });
            } else {
                // single image
                //console.info('handleTargetChange curTarget',curTarget);
                this.setState({
                    newad_image: _evt.file,
                    newad_fileList: _evt.fileList,
                    newad_changed_image_file: true,
                    previewURL: "",
                });

                if (_evt.fileList.length === 1) {
                    let reader = new FileReader();
                    let preview_file = _evt.file;
                    console.info("handleUpload preview_file", preview_file);
                    reader.onloadend = () => {
                        this.setState({ previewURL: reader.result });
                    };
                    reader.readAsDataURL(preview_file);
                } else {
                    // clear the current file
                    this.setState({
                        newad_image: null,
                        newad_fileList: null,
                        newad_changed_image_file: false,
                    });
                }
            }
        };

        renderFacebookTargetingResume = (_facebook_targeting) => {
            const country_list = (
                <Fragment>
                    {_facebook_targeting.selected_country
                        .map((country) => country.label + " - " + country.key)
                        .join(", ")}
                </Fragment>
            );

            const region_list = (
                <Fragment>
                    {_facebook_targeting.selected_region
                        .map((region) => region.label + " - " + region.key)
                        .join(", ")}
                </Fragment>
            );
            const city_list = (
                <Fragment>
                    {_facebook_targeting.selected_city
                        .map((city) => city.label + " - " + city.key)
                        .join(", ")}
                </Fragment>
            );
            const zip_list = (
                <Fragment>
                    {_facebook_targeting.selected_zip
                        .map((zip) => zip.label.join() + " - " + zip.key)
                        .join(", ")}
                </Fragment>
            );
            const interest_list = (
                <Fragment>
                    {_facebook_targeting.selected_interest
                        .map((interest) => interest.label)
                        .join(", ")}
                </Fragment>
            );

            return (
                <Fragment>
                    {_facebook_targeting.selected_overwrite_gender === true ? (
                        <Fragment>
                            <br />
                            <strong>Gender:</strong>
                            <br />
                            {_facebook_targeting.selected_gender === "all"
                                ? "All"
                                : _facebook_targeting.selected_gender === "1"
                                ? "Male"
                                : "Female"}
                        </Fragment>
                    ) : null}
                    {_facebook_targeting.selected_overwrite_age === true ? (
                        <Fragment>
                            <br />
                            <strong>Age:</strong>
                            <br />
                            {_facebook_targeting.selected_age[0]} -{" "}
                            {_facebook_targeting.selected_age[1]}
                        </Fragment>
                    ) : null}
                    {_facebook_targeting.selected_overwrite_country === true ? (
                        <Fragment>
                            <br />
                            <strong>Country:</strong>
                            <br />
                            {country_list}
                        </Fragment>
                    ) : null}
                    {_facebook_targeting.selected_overwrite_region === true ? (
                        <Fragment>
                            <br />
                            <strong>Region:</strong>
                            <br />
                            {region_list}
                        </Fragment>
                    ) : null}
                    {_facebook_targeting.selected_overwrite_city === true ? (
                        <Fragment>
                            <br />
                            <strong>City:</strong>
                            <br />
                            {city_list}
                        </Fragment>
                    ) : null}
                    {_facebook_targeting.selected_overwrite_zip === true ? (
                        <Fragment>
                            <br />
                            <strong>ZIP:</strong>
                            <br />
                            {zip_list}
                        </Fragment>
                    ) : null}
                    {_facebook_targeting.selected_overwrite_interest ===
                    true ? (
                        <Fragment>
                            <br />
                            <strong>Interest:</strong>
                            <br />
                            {interest_list}
                        </Fragment>
                    ) : null}
                </Fragment>
            );
        };

        getLinkedinAudienceCount = (_criteria, _skipDelay) => {
            //console.log("getLinkedinAudienceCount _criteria", _criteria);

            if (
                !this.state.newad_linkedin_accounts ||
                this.state.newad_linkedin_accounts.length === 0
            )
                return;

            const { linkedin_targeting } = this.state;
            // console.log(
            //     "getLinkedinAudienceCount linkedin_targeting",
            //     linkedin_targeting
            // );

            let resultCriteria = {};
            Object.keys(linkedin_targeting).forEach((element) => {
                if (element.indexOf("selected") !== -1) {
                    console.log(element);
                    resultCriteria[element] = linkedin_targeting[element];
                }
            });
            // console.info(
            //     "getLinkedinAudienceCount resultCriteria",
            //     resultCriteria
            // );

            //linkedin_targeting[_facet] = [];
            const that = this;
            if (this.audienceCountTimer) clearTimeout(this.audienceCountTimer);
            this.audienceCountTimer = setTimeout(
                function () {
                    that.setState({ fetching: true });
                    that.props.store
                        .getLinkedinAudienceCount(
                            that.state.newad_linkedin_accounts[0],
                            resultCriteria
                        )
                        .then((data) => {
                            console.info("data", data);
                            //alert("total:" + data.total);
                            const { linkedin_targeting } = that.state;
                            linkedin_targeting["audienceCount"] = data.total;
                            that.setState({
                                linkedin_targeting,
                                fetching: false,
                            });
                        })
                        .catch((error) => {
                            console.info("WE HAVE ERROR", error);
                        });
                },
                !_skipDelay ? 1500 : 0
            );
        };

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            const { facebook_targeting, fetching } = this.state;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 4 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;
            // console.time("AdsListModal MODAL");
            // console.info("AdsListModal MODAL Start this.state", this.state);

            // let treeData = this.getTreeData();

            // const filtered_treeNodes =
            //     treeData && this.renderTreeNodes(treeData);

            const currentStepKey = this.props.steps[this.props.step].key;

            // const allAccounts = this.props.store.getAllAccounts.map((a) => {
            //     let accountData = {
            //         title: `${a.name}`,
            //         key: a.key,
            //     };
            //     if (
            //         a.facebook &&
            //         a.facebook.data &&
            //         a.facebook.data.length > 0
            //     ) {
            //         accountData.children = a.facebook.data.map((d) => {
            //             return {
            //                 title: d.name,
            //                 key: a.key + "|" + d.id,
            //             };
            //         });
            //     }
            //     return accountData;
            // });

            // const allAccountsList = this.props.store.getAllAccounts;
            //console.info("AdsListModal allAccountsList", allAccountsList);
            // const allLinkedinAccounts = allAccountsList
            //     .filter((a) => {
            //         return a.linkedin ? true : false;
            //     })
            //     .map((a) => ({
            //         title: `${a.name}`,
            //         key: a.key,
            //     }));
            // const allTwitterAccounts = allAccountsList
            //     .filter((a) => {
            //         return a.twitter ? true : false;
            //     })
            //     .map((a) => ({
            //         title: `${a.twitter.auth.screen_name}`,
            //         key: a.key,
            //     }));

            const filtered_treeNodes = this.state.filtered_treeNodes
                ? this.state.filtered_treeNodes
                : [];
            //console.info("AdsListModal allLinkedinAccounts", allLinkedinAccounts);

            // console.info("AdsListModal FORM this.props", this.props);
            //console.info("AdsListModal MODAL this.state", this.state);
            //console.timeEnd("AdsListModal MODAL");
            const detailsPanel = (
                <Fragment>
                    <div>
                        <Form.Item label={"Objective"}>
                            <Select
                                // labelInValue={true}
                                //     value={value}
                                placeholder="Select objective"
                                filterOption={false}
                                onChange={(e) => {
                                    let { facebook_details } = this.state;
                                    facebook_details.objective = e;
                                    this.setState({ facebook_details });
                                    console.info("e", e);
                                }}
                                value={this.state.facebook_details.objective}
                            >
                                {config.FACEBOOK_ADS_OBJECTIVE_OPTIONS.map(
                                    (option) => (
                                        <Option key={option}>{option}</Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item label={"Optimization goal"}>
                            <Select
                                // labelInValue={true}
                                //     value={value}
                                placeholder="Select optimization goal"
                                filterOption={false}
                                onChange={(e) => {
                                    let { facebook_details } = this.state;
                                    facebook_details.optimization_goal = e;
                                    this.setState({ facebook_details });
                                    console.info("e", e);
                                }}
                                value={
                                    this.state.facebook_details
                                        .optimization_goal
                                }
                            >
                                {config.FACEBOOK_ADS_OPTIMIZATION_GOAL_OPTIONS.map(
                                    (option) => (
                                        <Option key={option}>{option}</Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item label={"Billing Event"}>
                            <Select
                                // labelInValue={true}
                                //     value={value}
                                placeholder="Select billing event"
                                filterOption={false}
                                onChange={(e) => {
                                    let { facebook_details } = this.state;
                                    facebook_details.billing_event = e;
                                    this.setState({ facebook_details });
                                    console.info("e", e);
                                }}
                                value={
                                    this.state.facebook_details.billing_event
                                }
                            >
                                {config.FACEBOOK_ADS_BILLING_EVENTS_OPTIONS.map(
                                    (option) => (
                                        <Option key={option}>{option}</Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item label={"Bid Amount"}>
                            <InputNumber
                                defaultValue={
                                    this.state.facebook_details.bid_amount
                                }
                                min={0}
                                // max={100}
                                formatter={(value) => `$ ${value / 100}`}
                                parser={(value) =>
                                    Number(value.replace("$", "")) * 100
                                }
                                onChange={(e) => {
                                    let { facebook_details } = this.state;
                                    facebook_details.bid_amount = e;
                                    this.setState({ facebook_details });
                                    console.info("e", e);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_daily_budget
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_daily_budget",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Daily budget
                                </Checkbox>
                            }
                        >
                            <InputNumber
                                defaultValue={
                                    this.state.facebook_details.daily_budget
                                }
                                min={0}
                                // max={100}
                                step={100}
                                formatter={(value) => `$ ${value / 100}`}
                                parser={(value) =>
                                    Number(value.replace("$", "")) * 100
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_daily_budget
                                }
                                onChange={(e) => {
                                    let { facebook_details } = this.state;
                                    facebook_details.daily_budget = e;
                                    this.setState({ facebook_details });
                                    console.info("e", e);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label={"Start/End date"}>
                            <RangePicker
                                format="MM/DD YYYY HH:mm"
                                defaultValue={[
                                    moment(
                                        this.state.facebook_details.start_time
                                    ),
                                    moment(
                                        this.state.facebook_details.end_time
                                    ),
                                ]}
                                onChange={(e) => {
                                    console.info("e", e);
                                    let { facebook_details } = this.state;
                                    facebook_details.start_time = e[0].format();
                                    facebook_details.end_time = e[1].format();
                                    this.setState({ facebook_details });
                                }}
                                disabledDate={(current) =>
                                    current && current < moment().endOf("day")
                                }
                                showTime={{
                                    hideDisabledOptions: true,
                                }}
                            />
                        </Form.Item>
                        <Form.Item label={"Status"}>
                            <Select
                                placeholder="Select status"
                                filterOption={false}
                                onChange={(e) => {
                                    let { facebook_details } = this.state;
                                    facebook_details.status = e;
                                    this.setState({ facebook_details });
                                    console.info("e", e);
                                }}
                                value={this.state.facebook_details.status}
                            >
                                {config.FACEBOOK_ADS_STATUS_OPTIONS.map(
                                    (option) => (
                                        <Option key={option}>{option}</Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                    </div>
                </Fragment>
            );

            const startPanel = (
                <Fragment>
                    <div>
                        {/* type = {this.state.data.type} */}
                        <Form.Item
                            label={
                                this.state.data.type === "ad"
                                    ? "Ad Name"
                                    : "Boost name"
                            }
                            required
                        >
                            {getFieldDecorator("name", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input the name",
                                        type: "string",
                                    },
                                ],
                                initialValue: this.state.ad_name,
                                onChange: (e) =>
                                    this.setState({ ad_name: e.target.value }),
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Campaigns">
                            {getFieldDecorator("campaigns", {
                                initialValue: this.state.campaigns
                                    ? this.state.campaigns
                                    : this.props.data.campaigns
                                    ? this.props.data.campaigns
                                    : [],
                                onChange: this.handleCampaignsChange,
                            })(
                                <EditableTagGroup
                                    element_title="Campaigns"
                                    tag_color={this.props.store.getTagColorForType(
                                        "campaign"
                                    )}
                                    options={this.props.store.getAllCampaigns.map(
                                        (campaign) => campaign.campaign
                                    )}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Brand">
                            {getFieldDecorator("brand", {
                                initialValue: this.state.brand,
                                onChange: (e) => this.setState({ brand: e }),
                            })(
                                <AutoComplete
                                    dataSource={this.props.store.getAllBrands}
                                    // style={{ width: 200 }}
                                    placeholder="input here"
                                    filterOption={true}
                                />
                            )}
                        </Form.Item>
                    </div>
                </Fragment>
            );

            const networkPanel = (
                <Fragment>
                    <div>
                        <Alert
                            message="Choose network"
                            description="Please choose the network you want the ad to be published to by checking it from the list below"
                            type="info"
                            banner
                            showIcon={false}
                        />
                        <br />
                        <br />
                        <Form.Item label="Networks">
                            <Row>
                                <Col>
                                    <Checkbox
                                        onChange={(e) =>
                                            this.setState({
                                                networks_facebook:
                                                    e.target.checked,
                                            })
                                        }
                                        checked={this.state.networks_facebook}
                                    >
                                        Facebook/Instagram
                                    </Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Checkbox
                                        onChange={(e) =>
                                            this.setState({
                                                networks_linkedin:
                                                    e.target.checked,
                                            })
                                        }
                                        checked={this.state.networks_linkedin}
                                    >
                                        Linkedin
                                    </Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Checkbox
                                        onChange={(e) =>
                                            this.setState({
                                                networks_twitter:
                                                    e.target.checked,
                                            })
                                        }
                                        checked={this.state.networks_twitter}
                                    >
                                        Twitter
                                    </Checkbox>
                                </Col>
                            </Row>
                        </Form.Item>
                    </div>
                </Fragment>
            );

            const basicPanel = (
                <AdsListModalFormPanelBasic
                    form={form}
                    state={this.state}
                    store={this.props.store}
                    onSetState={(e) => this.setState(e)}
                    onHandleFilePickerChange={this.handleFilePickerChange}
                />
            );

            const facebookBoostTargeting = (
                <Fragment>
                    <div style={{ width: "50%", margin: "0 auto" }}>
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_gender
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_gender",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Gender
                                </Checkbox>
                            }
                        >
                            <Radio.Group
                                value={
                                    this.state.facebook_targeting
                                        .selected_gender
                                }
                                onChange={(e) =>
                                    this.selectFacebookTargetParam(
                                        "gender",
                                        e.target.value
                                    )
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_gender
                                }
                            >
                                <Radio.Button value="all">All</Radio.Button>
                                <Radio.Button value="1">Male</Radio.Button>
                                <Radio.Button value="2">Female</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_age
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_age",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Age
                                </Checkbox>
                            }
                        >
                            <Slider
                                range
                                value={[
                                    this.state.facebook_targeting
                                        .selected_age[0],
                                    this.state.facebook_targeting
                                        .selected_age[1],
                                ]}
                                min={13}
                                max={100}
                                // tooltipVisible={true}
                                onChange={(e) =>
                                    this.selectFacebookTargetParam("age", e)
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_age
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            required
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_country
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_country",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Country
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select countries"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={this.fetchCountry}
                                onChange={(e) => {
                                    this.selectFacebookTargetParam(
                                        "country",
                                        e
                                    );
                                }}
                                style={{ width: "100%" }}
                                value={
                                    this.state.facebook_targeting
                                        .selected_country
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_country
                                }
                            >
                                {facebook_targeting.countries.map((c) => (
                                    <Option key={c.key}>{c.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_region
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_region",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Region
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select regions"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={this.fetchRegion}
                                onChange={(e) => {
                                    this.selectFacebookTargetParam("region", e);
                                }}
                                style={{ width: "100%" }}
                                value={
                                    this.state.facebook_targeting
                                        .selected_region
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_region
                                }
                            >
                                {facebook_targeting.regions.map((r) => (
                                    <Option key={r.key}>{r.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_city
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_city",
                                            e.target.checked
                                        )
                                    }
                                >
                                    City
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select cities"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={this.fetchCity}
                                onChange={(e) => {
                                    this.selectFacebookTargetParam("city", e);
                                }}
                                style={{ width: "100%" }}
                                value={
                                    this.state.facebook_targeting.selected_city
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_city
                                }
                            >
                                {facebook_targeting.cities.map((c) => (
                                    <Option key={c.key}>{c.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_zip
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_zip",
                                            e.target.checked
                                        )
                                    }
                                >
                                    ZIP
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select zip codes"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={this.fetchZip}
                                onChange={(e) => {
                                    this.selectFacebookTargetParam("zip", e);
                                }}
                                style={{ width: "100%" }}
                                value={
                                    this.state.facebook_targeting.selected_zip
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_zip
                                }
                            >
                                {facebook_targeting.zips.map((z) => (
                                    <Option key={z.key}>
                                        {z.country_name} / {z.region} /{" "}
                                        {z.primary_city} - {z.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_language
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_language",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Language
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select languages"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={this.fetchLanguage}
                                onChange={(e) => {
                                    this.selectFacebookTargetParam(
                                        "language",
                                        e
                                    );
                                }}
                                style={{ width: "100%" }}
                                value={
                                    this.state.facebook_targeting
                                        .selected_language
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_language
                                }
                            >
                                {facebook_targeting.languages.map((l) => (
                                    <Option key={l.key}>{l.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={
                                <Checkbox
                                    checked={
                                        this.state.facebook_targeting
                                            .selected_overwrite_interest
                                    }
                                    onChange={(e) =>
                                        this.selectFacebookTargetParam(
                                            "overwrite_interest",
                                            e.target.checked
                                        )
                                    }
                                >
                                    Interest
                                </Checkbox>
                            }
                        >
                            <Select
                                mode="multiple"
                                labelInValue
                                //     value={value}
                                placeholder="Select interests"
                                notFoundContent={
                                    fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                onSearch={this.fetchInterest}
                                onChange={(e) => {
                                    this.selectFacebookTargetParam(
                                        "interest",
                                        e
                                    );
                                }}
                                style={{ width: "100%" }}
                                value={
                                    this.state.facebook_targeting
                                        .selected_interest
                                }
                                disabled={
                                    !this.state.facebook_targeting
                                        .selected_overwrite_interest
                                }
                            >
                                {facebook_targeting.interests.map((i) => (
                                    <Option key={i.id}>
                                        {i.path.join("/")}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </Fragment>
            );

            const facebookBoostPanel = (
                <Fragment>
                    <div>
                        <Form.Item label="Campaign">
                            {getFieldDecorator("campaign", {
                                defaultValue: this.state.campaign
                                    ? this.state.campaign
                                    : null,
                                onChange: (e) => this.setState({ campaign: e }),
                            })(
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ minWidth: 200 }}
                                    placeholder="Select a campaign"
                                >
                                    {this.props.store.getAllCampaigns.map(
                                        (campaign, campaignIndex) => (
                                            <Option
                                                key={campaignIndex}
                                                value={campaign.campaign}
                                            >
                                                {campaign.campaign}
                                            </Option>
                                        )
                                    )}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={names.get("MASTERPOST", true, true)}
                            required
                        >
                            {getFieldDecorator(
                                "masterposts",
                                {}
                            )(
                                <div>
                                    <Row type="flex" justify="space-between">
                                        <Col>
                                            <Search
                                                placeholder="filter"
                                                onSearch={
                                                    this
                                                        .onMasterpostsSearchChange
                                                }
                                                onChange={(e) =>
                                                    this.onMasterpostsSearchChange(
                                                        e.target.value
                                                    )
                                                }
                                                style={{ width: 200 }}
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                onClick={
                                                    this.handleAddAllMasterposts
                                                }
                                            >
                                                Check all{" "}
                                                {names.get("MASTERPOST", true)}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Tree
                                        checkable
                                        selectable={false}
                                        defaultExpandAll={true}
                                        // onExpand={this.onExpand}
                                        // expandedKeys={this.state.expandedKeys}
                                        // autoExpandParent={this.state.autoExpandParent}
                                        onCheck={this.onCheck}
                                        checkedKeys={this.state.masterposts}
                                        // onSelect={this.onSelect}
                                        // selectedKeys={this.state.selectedKeys}
                                        style={{ marginLeft: -26 }}
                                    >
                                        {filtered_treeNodes}
                                    </Tree>
                                    {filtered_treeNodes.length === 0 ? (
                                        <Alert
                                            message="- No masterposts -"
                                            type="warning"
                                            style={{
                                                width: 200,
                                                fontSize: 12,
                                            }}
                                        />
                                    ) : null}
                                </div>
                            )}
                        </Form.Item>
                    </div>
                </Fragment>
            );

            const facebookPanel = (
                <AdsListModalFormPanelFacebookInstagram
                    state={this.state}
                    form={form}
                    store={this.props.store}
                    onSetState={(e) => this.setState(e)}
                    onNewAdCheck={this.onNewAdCheck}
                    onSelectFacebookTargetParam={this.selectFacebookTargetParam}
                    onfetchCountry={this.fetchCountry}
                    onfetchRegion={this.fetchRegion}
                    onfetchCity={this.fetchCity}
                    onfetchZip={this.fetchZip}
                    onfetchLanguage={this.fetchLanguage}
                    onfetchInterest={this.fetchInterest}
                />
            );

            const linkedinPanel = (
                <AdsListModalFormPanelLinkedin
                    state={this.state}
                    form={form}
                    store={this.props.store}
                    onSetState={(e) => this.setState(e)}
                    ononNewAdLinkedinCheck={this.onNewAdLinkedinCheck}
                    ongetLinkedinAudienceCount={this.getLinkedinAudienceCount}
                    onselectLinkedinTargetParam={this.selectLinkedinTargetParam}
                    onfetchLinkedinFacet={this.fetchLinkedinFacet}
                />
            );

            const twitterPanel = (
                <AdsListModalFormPanelTwitter
                    state={this.state}
                    form={form}
                    store={this.props.store}
                    onSetState={(e) => this.setState(e)}
                    ononNewAdTwitterCheck={this.onNewAdTwitterCheck}
                    onupdateTwitterTargeting={this.updateTwitterTargeting}
                />
            );

            const confirmPanel = (
                <AdsListModalFormPanelConfirm
                    state={this.state}
                    store={this.props.store}
                    steps={this.props.steps}
                    step={this.props.step}
                    ongetSelectedTreeData={this.getSelectedTreeData}
                    onrenderTreeNodes={this.renderTreeNodes}
                    onrenderFacebookTargetingResume={
                        this.renderFacebookTargetingResume
                    }
                    ongetSelectedNewAccountsTreeData={
                        this.getSelectedNewAccountsTreeData
                    }
                    onrenderAccountsTreeNodes={this.renderAccountsTreeNodes}
                    ongetSelectedNewLinkedinAccountsTreeData={
                        this.getSelectedNewLinkedinAccountsTreeData
                    }
                    onrenderAccountsTreeNodesForLinkedin={
                        this.renderAccountsTreeNodesForLinkedin
                    }
                    ongetSelectedNewTwitterAccountsTreeData={
                        this.getSelectedNewTwitterAccountsTreeData
                    }
                    onrenderAccountsTreeNodesForTwitter={
                        this.renderAccountsTreeNodesForTwitter
                    }
                    // onrenderFacebookTargetingResume={
                    //     this.renderFacebookTargetingResume
                    // }
                />
            );

            // const resultsPanel = (
            //     <Fragment>
            //         <Result
            //             icon={this.props.resultIcon}
            //             status={this.props.resultStatus}
            //             title="Creating Ad"
            //             subTitle={this.props.resultText}
            //             // extra={[
            //             //     <Button
            //             //         type="primary"
            //             //         key="dashboard"
            //             //         disabled={
            //             //             this.state.resultStatus !== "success"
            //             //         }
            //             //         onClick={this.handleBackToDashboard}
            //             //     >
            //             //         Dashboard
            //             //     </Button>
            //             // ]}
            //         />
            //     </Fragment>
            // );

            return (
                <Form {...formItemLayout}>
                    <div
                        style={{
                            height: "80vh",
                            maxHeight: "80vh",
                            overflow: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        <Steps
                            current={this.props.step}
                            style={{ marginBottom: 50, fontSize: 14 }}
                        >
                            {this.props.steps &&
                                this.props.steps.map((step) => (
                                    <Step
                                        key={step.key}
                                        title={step.title}
                                        // description="Fill in basic information"
                                    />
                                ))}
                        </Steps>
                        <div
                            style={{
                                height: "calc(100% - 85px)",
                                overflowY: "auto",
                            }}
                        >
                            {/* currentStepKey = {currentStepKey} */}
                            {currentStepKey === "start" ? startPanel : null}
                            {currentStepKey === "ad_networks"
                                ? networkPanel
                                : null}
                            {currentStepKey === "ad_basic" ? (
                                <Row
                                    gutter={[20]}
                                    style={{
                                        height: "calc(100%)",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Col
                                        span={12}
                                        style={{
                                            height: "100%",
                                            overflowY: "auto",
                                        }}
                                    >
                                        <Collapse
                                            defaultActiveKey={["general"]}
                                            fffonChange={this.callback}
                                            className={
                                                "social_AccordeonWithShade"
                                            }
                                        >
                                            {currentStepKey === "ad_basic" ? (
                                                <Fragment>
                                                    <Panel
                                                        header="Basic"
                                                        key="general"
                                                    >
                                                        {basicPanel}
                                                    </Panel>

                                                    {this.state
                                                        .networks_facebook ===
                                                    true ? (
                                                        <Panel
                                                            header={
                                                                <Fragment>
                                                                    <div
                                                                        className="social_iconCircle_facebook"
                                                                        style={{
                                                                            width: 18,
                                                                            height: 15,
                                                                            float: "left",
                                                                            marginRight: 8,
                                                                            marginTop: 3,
                                                                        }}
                                                                    />
                                                                    {/* <Icon
                                                                        type="facebook"
                                                                        theme="filled"
                                                                        style={{
                                                                            marginRight: 5,
                                                                            fontSize: 18,
                                                                            color: this.props.store.getTagColorForType(
                                                                                "facebook"
                                                                            ),
                                                                        }}
                                                                    /> */}
                                                                    Facebook&nbsp;/&nbsp;
                                                                    <div
                                                                        className="social_iconCircle_instagram"
                                                                        style={{
                                                                            width: 18,
                                                                            height: 15,
                                                                            float: "left",
                                                                            marginRight: 8,
                                                                            marginTop: 3,
                                                                        }}
                                                                    />
                                                                    {/* <Icon
                                                                        type="instagram"
                                                                        theme="filled"
                                                                        style={{
                                                                            marginRight: 5,
                                                                            fontSize: 18,
                                                                            color: this.props.store.getTagColorForType(
                                                                                "instagram"
                                                                            ),
                                                                        }}
                                                                    /> */}
                                                                    Instagram
                                                                </Fragment>
                                                            }
                                                            key="facebook_details"
                                                        >
                                                            {facebookPanel}
                                                        </Panel>
                                                    ) : null}

                                                    {this.state
                                                        .networks_linkedin ===
                                                    true ? (
                                                        <Panel
                                                            header={
                                                                <Fragment>
                                                                    <div
                                                                        className="social_iconCircle_linkedin"
                                                                        style={{
                                                                            width: 18,
                                                                            height: 15,
                                                                            float: "left",
                                                                            marginRight: 8,
                                                                            marginTop: 3,
                                                                        }}
                                                                    />
                                                                    {/* <Icon
                                                                        type="linkedin"
                                                                        theme="filled"
                                                                        style={{
                                                                            marginRight: 5,
                                                                            fontSize: 18,
                                                                            color: this.props.store.getTagColorForType(
                                                                                "linkedin"
                                                                            ),
                                                                        }}
                                                                    /> */}
                                                                    Linkedin
                                                                </Fragment>
                                                            }
                                                            key="linkedin_details"
                                                        >
                                                            {linkedinPanel}
                                                        </Panel>
                                                    ) : null}

                                                    {this.state
                                                        .networks_twitter ===
                                                    true ? (
                                                        <Panel
                                                            header={
                                                                <Fragment>
                                                                    <div
                                                                        className="social_iconCircle_twitter"
                                                                        style={{
                                                                            width: 18,
                                                                            height: 15,
                                                                            float: "left",
                                                                            marginRight: 8,
                                                                            marginTop: 3,
                                                                        }}
                                                                    />
                                                                    {/* <Icon
                                                                        type="twitter"
                                                                        style={{
                                                                            marginRight: 5,
                                                                            fontSize: 18,
                                                                            color: this.props.store.getTagColorForType(
                                                                                "twitter"
                                                                            ),
                                                                        }}
                                                                    /> */}
                                                                    Twitter
                                                                </Fragment>
                                                            }
                                                            key="twitter_details"
                                                        >
                                                            {twitterPanel}
                                                        </Panel>
                                                    ) : null}
                                                </Fragment>
                                            ) : null}
                                        </Collapse>
                                    </Col>
                                    <Col
                                        span={12}
                                        style={{
                                            position: "relative",
                                            height: "calc(100vh - 370px)",
                                            overflowY: "auto",
                                        }}
                                    >
                                        <AdsPreview
                                            type={this.state.newad_type}
                                            carousel={this.state.carousel}
                                            defaultTab="facebook"
                                            image_url={
                                                this.state.newad_type ===
                                                "singleimagead"
                                                    ? this.state.newad_image &&
                                                      this.state.newad_image
                                                          .image_data
                                                    : null
                                            }
                                            cta_type={
                                                this.state
                                                    .newad_calltoactiontype
                                            }
                                            networks={{
                                                facebook:
                                                    this.state
                                                        .networks_facebook,
                                                linkedin:
                                                    this.state
                                                        .networks_linkedin,
                                                twitter:
                                                    this.state.networks_twitter,
                                            }}
                                            link_url={this.state.newad_url}
                                            title={this.state.newad_title}
                                            description={this.state.newad_body}
                                            store={this.props.store}
                                            linkedin_newad_type={
                                                this.state.linkedin_newad_type
                                            }
                                            // style={{
                                            //     position: "absolute",
                                            //     top: -10,
                                            //     right: 0,
                                            // }}
                                        />
                                    </Col>
                                </Row>
                            ) : null}
                            {currentStepKey === "fb_boost_basic"
                                ? facebookBoostPanel
                                : null}
                            {currentStepKey === "fb_boost_targeting"
                                ? facebookBoostTargeting
                                : null}
                            {currentStepKey === "fb_boost_details" ? (
                                <Fragment>
                                    <div
                                        style={{
                                            margin: "0 auto",
                                        }}
                                    >
                                        {detailsPanel}
                                    </div>
                                </Fragment>
                            ) : null}
                            {currentStepKey === "fb_boost_confirm" ||
                            currentStepKey === "ad_confirm"
                                ? confirmPanel
                                : null}

                            {/* {currentStepKey === "details" ? detailsPanel : null} */}
                            {/* {this.props.step === 2 ? scopePanel : null}
                          {this.props.step === 3 ? targetPanel : null}
                          {this.props.step === 4 ? detailsPanel : null}
                          {this.props.step === 5 ? resultsPanel : null} */}
                        </div>
                    </div>
                    {/* <pre>
                          {JSON.stringify(this.state.linkedin_targeting, null, 2)}
                      </pre> */}
                    {/* <pre>{JSON.stringify(this.state.carousel, null, 2)}</pre>
                      <pre>{JSON.stringify(this.state.checkedKeys, null, 2)}</pre> */}
                </Form>
            );
        }
    }
);

export default ModalForm;
