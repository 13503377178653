import React, { Component, Fragment } from "react";
import {
    Form,
    Modal,
    Alert,
    Divider,
    Icon,
    TimePicker,
    DatePicker,
} from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import names from "./../../names";

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            // To disable submit button at the beginning.
            //this.props.form.validateFields();
            //const { campaign } = this.props.data;
        }

        onChange = (time, timeString) => {
            console.log(time, timeString);
        };

        render() {
            const {
                getFieldDecorator,
                // getFieldsError,
                // getFieldError,
                // isFieldTouched
            } = this.props.form;

            const { dropDate, dropMasterpost } = this.props;
            // Only show error after a field is touched.
            // const usernameError =
            //     isFieldTouched("username") && getFieldError("username");
            // const passwordError =
            //     isFieldTouched("password") && getFieldError("password");

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;
            const new_time_text =
                moment(dropDate).format("YYYY-MM-DD") +
                " " +
                moment(dropMasterpost.scheduled_date).format("hh:mm:ss");
            console.log("new_time_text = ", new_time_text);
            const new_time = moment(new_time_text, "YYYY-MM-DD hh:mm:ss").utc();
            console.info("new_time", new_time);

            return (
                <Form {...formItemLayout}>
                    {/* <pre>dropDate={JSON.stringify(dropDate, null, 2)}</pre>
                    <pre>dropKey={JSON.stringify(dropKey, null, 2)}</pre> */}
                    {/* <pre>
                        dropMasterpost={JSON.stringify(dropMasterpost, null, 2)}
                    </pre> */}
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}

                    <Divider orientation="left">
                        {names.get("MASTERPOST")}
                    </Divider>
                    <Form.Item label="Name">
                        <h3>{dropMasterpost.name}</h3>
                    </Form.Item>
                    <Form.Item label="Scheduled date">
                        <h3>
                            {moment(dropMasterpost.scheduled_date).format(
                                "llll"
                            )}
                        </h3>
                    </Form.Item>

                    <Divider orientation="left">
                        New Scheduled Date/Time
                    </Divider>
                    <Form.Item label="New scheduled date">
                        <h3>{new_time.format("ll")}</h3>
                    </Form.Item>

                    <Form.Item label="New scheduled time">
                        {getFieldDecorator("new_scheduled_time", {
                            //getValueFromEvent: handleGetTags
                            rules: [
                                {
                                    required: true,
                                    message: `Please input the scheduled date to be applied to the ${names.get(
                                        "MASTERPOST"
                                    )}!`,
                                },
                            ],
                            initialValue: new_time
                                ? moment(new_time_text)
                                : moment().utc(),
                            //onChange: (value) => this.onChange(value),
                        })(dropDate ? <TimePicker /> : <DatePicker showTime />)}
                    </Form.Item>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class ScheduleMove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "schedulemove_modal",
            (_action, _payload) => {
                // console.info(
                //     "CAMPAIGNS_MODAL _action",
                //     _action,
                //     "_payload",
                //     _payload
                // );
                if (_payload) {
                    this.setState({ data: _payload });
                } else {
                    this.setState({ data: {} });
                }
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("schedulemove_modal");
    };

    onModalApply = async () => {
        const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("onModalApply formState: ", formState);

        form.validateFields(async (err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                // social_notification("validation_error", "error", {
                //     description: err,
                // });
                console.warn("ERROR: ", err);
                return;
            }

            // const dropDate = this.props.dropDate;
            //const newTime = values["new_scheduled_time"];
            const dropKey = this.props.dropKey;

            const newTime_text = moment(values["new_scheduled_time"])
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
            console.log("newTime_text = ", newTime_text);
            const newTime = moment(newTime_text, "YYYY-MM-DD HH:mm:ss").utc();
            console.info("newTime", newTime);
            console.log("newTime.valueOf()=", newTime.valueOf());
            console.log(
                "newTime.format()=",
                moment(newTime.valueOf()).format("llll")
            );

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            // everything is fine
            //console.info("dropKey", dropKey);

            //return;
            this.props.store.updateMasterpost(dropKey, {
                scheduled_date: newTime.valueOf(),
            });

            form.resetFields();
            this.onModalCancel(); // hide the modal
        }).catch((error) => {
            console.warn(error);
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "schedulemove_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Move to date"}
                        okText={"Move"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalApply} //onApply
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            dropDate={
                                this.props.dropDate
                                    ? this.props.dropDate
                                    : this.state.dropDate
                            }
                            dropKey={
                                this.props.dropKey
                                    ? this.props.dropKey
                                    : this.state.dropKey
                            }
                            dropMasterpost={
                                this.props.dropMasterpost
                                    ? this.props.dropMasterpost
                                    : this.state.dropMasterpost
                            }
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(ScheduleMove);
// dropKey="-MCnGVAsgNFT89vZpjtt"
