import React, { Component, Fragment } from "react";

import { Radio, Form, Slider, Select, Spin } from "antd";

const { Option } = Select;

class FacebookTargeting extends Component {
    state = {};
    timer = null;

    constructor(props) {
        super(props);

        this.state = {
            countries: [],
            regions: [],
            cities: [],
            zips: [],
            languages: [],
            interests: [],
            custom_audiences: [],
            //
            selected_gender: "all",
            selected_age: [30, 50],
            selected_country: [],
            selected_region: [],
            selected_city: [],
            selected_zip: [],
            selected_language: [],
            selected_interest: [],
        };
    }

    componentDidMount = async () => {
        console.info("componentDidMount this.props", this.props);
        if (this.props.targeting && this.props.targeting.gender)
            this.setState({ selected_gender: this.props.targeting.gender });
        if (this.props.targeting && this.props.targeting.age)
            this.setState({ selected_age: this.props.targeting.age });
        if (this.props.targeting && this.props.targeting.country)
            this.setState({ selected_country: this.props.targeting.country });
        if (this.props.targeting && this.props.targeting.region)
            this.setState({ selected_region: this.props.targeting.region });
        if (this.props.targeting && this.props.targeting.city)
            this.setState({ selected_city: this.props.targeting.city });
        if (this.props.targeting && this.props.targeting.zip)
            this.setState({ selected_zip: this.props.targeting.zip });
        if (this.props.targeting && this.props.targeting.language)
            this.setState({ selected_language: this.props.targeting.language });
        if (this.props.targeting && this.props.targeting.interest)
            this.setState({ selected_interest: this.props.targeting.interest });
    };
    selectFacebookTargetParam = (_param, _value) => {
        //   console.info(
        //       "selectFacebookTargetParam _param",
        //       _param,
        //       "_value",
        //       _value
        //   );
        let stateObj = { changed: true };
        stateObj["selected_" + _param] = _value;
        this.setState(stateObj);
        this.buble_Targeting();
    };

    fetchCountry = (value) => {
        console.log("fetchCountry value", value);
        let countries = [];
        this.setState({ fetching: true });
        this.props.store
            .getFacebookTarget(
                value,
                "adgeolocation",
                `location_types=["country"]`
            )
            .then((data) => {
                countries = data.data;
                this.setState({ countries, fetching: false });
            });
    };

    fetchRegion = (value) => {
        console.log("fetchRegion value", value);
        if (value.length < 2) return;
        let regions = [];
        this.setState({ fetching: true });
        this.props.store
            .getFacebookTarget(
                value,
                "adgeolocation",
                `location_types=["region"]`
            )
            .then((data) => {
                regions = data.data;
                this.setState({ regions, fetching: false });
            });
    };
    fetchCity = (value) => {
        console.log("fetchCity value", value);
        if (value.length < 3) return;
        let cities = [];
        this.setState({ fetching: true });
        this.props.store
            .getFacebookTarget(
                value,
                "adgeolocation",
                `location_types=["city"]`
            )
            .then((data) => {
                cities = data.data;
                this.setState({ cities, fetching: false });
            });
    };

    fetchZip = (value) => {
        console.log("fetchZip value", value);
        if (value.length < 2) return;
        let zips = [];
        this.setState({ fetching: true });
        this.props.store
            .getFacebookTarget(value, "adgeolocation", `location_types=["zip"]`)
            .then((data) => {
                zips = data.data;
                this.setState({ zips, fetching: false });
            });
    };
    fetchLanguage = (value) => {
        console.log("fetchLanguage value", value);
        if (value.length < 3) return;

        let languages = [];
        this.setState({ fetching: true });
        this.props.store.getFacebookTarget(value, "adlocale").then((data) => {
            languages = data.data;
            this.setState({ languages, fetching: false });
        });
    };
    fetchInterest = (value) => {
        console.log("fetchInterest value", value);
        if (value.length < 3) return;
        let interests = [];
        this.setState({ fetching: true });
        this.props.store.getFacebookTarget(value, "adinterest").then((data) => {
            interests = data.data;
            this.setState({ interests, fetching: false });
        });
    };

    buble_Targeting = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(
            function (that) {
                if (
                    that.props.updateTargeting &&
                    typeof that.props.updateTargeting === "function"
                ) {
                    const curState = Object.assign({}, that.state);
                    that.props.updateTargeting({
                        gender: curState.selected_gender,
                        age: curState.selected_age,
                        country: curState.selected_country,
                        region: curState.selected_region,
                        city: curState.selected_city,
                        zip: curState.selected_zip,
                        language: curState.selected_language,
                        interest: curState.selected_interest,
                    });
                }
            },
            1000,
            this
        );
    };

    render() {
        const { fetching } = this.state;

        let body = (
            <Fragment>
                <Form layout="horizontal">
                    <Form.Item label="Gender">
                        <Radio.Group
                            value={this.state.selected_gender}
                            onChange={(e) =>
                                this.selectFacebookTargetParam(
                                    "gender",
                                    e.target.value
                                )
                            }
                        >
                            <Radio.Button value="all">All</Radio.Button>
                            <Radio.Button value="1">Male</Radio.Button>
                            <Radio.Button value="2">Female</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={
                            "Age: " +
                            this.state.selected_age[0] +
                            " - " +
                            this.state.selected_age[1]
                        }
                    >
                        <Slider
                            range
                            value={[
                                this.state.selected_age[0],
                                this.state.selected_age[1],
                            ]}
                            min={13}
                            max={100}
                            tooltipVisible={false}
                            tooltipPlacement="bottom"
                            onChange={(e) =>
                                this.selectFacebookTargetParam("age", e)
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Country">
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select countries"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchCountry}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("country", e);
                            }}
                            style={{ width: "100%" }}
                            value={this.state.selected_country}
                        >
                            {this.state.countries.map((c) => (
                                <Option key={c.key}>{c.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Region">
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select regions"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchRegion}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("region", e);
                            }}
                            style={{ width: "100%" }}
                            value={this.state.selected_region}
                        >
                            {this.state.regions.map((r) => (
                                <Option key={r.key}>{r.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="City">
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select cities"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchCity}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("city", e);
                            }}
                            style={{ width: "100%" }}
                            value={this.state.selected_city}
                        >
                            {this.state.cities.map((c) => (
                                <Option key={c.key}>{c.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="ZIP">
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select zip codes"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchZip}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("zip", e);
                            }}
                            style={{ width: "100%" }}
                            value={this.state.selected_zip}
                        >
                            {this.state.zips.map((z) => (
                                <Option key={z.key}>
                                    {z.country_name} / {z.region} /{" "}
                                    {z.primary_city} - {z.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Language">
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select languages"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchLanguage}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("language", e);
                            }}
                            style={{ width: "100%" }}
                            value={this.state.selected_language}
                        >
                            {this.state.languages.map((l) => (
                                <Option key={l.key}>{l.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Interest">
                        <Select
                            mode="multiple"
                            labelInValue
                            //     value={value}
                            placeholder="Select interests"
                            notFoundContent={
                                fetching ? <Spin size="small" /> : null
                            }
                            filterOption={false}
                            onSearch={this.fetchInterest}
                            onChange={(e) => {
                                this.selectFacebookTargetParam("interest", e);
                            }}
                            style={{ width: "100%" }}
                            value={this.state.selected_interest}
                        >
                            {this.state.interests.map((i) => (
                                <Option key={i.id}>{i.path.join("/")}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
                {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
            </Fragment>
        );

        return body;
    }
}

export default FacebookTargeting;
