import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import CompetitorsList from "./CompetitorsList";

import { observer } from "mobx-react";

class Competitors extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("competitors");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Competitors" }]);
    }

    render() {
        return (
            <Switch>
                {/* <Route
                    path="/dashboard/competitors/:id/report"
                    //   render={props => (
                    //       // <CompetitorsReport
                    //       //     store={this.props.store}
                    //       //     {...props}
                    //       // />
                    //   )}
                /> */}
                <Route
                    render={props => (
                        <CompetitorsList store={this.props.store} {...props} />
                    )}
                />
            </Switch>
        );
    }
}

export default observer(Competitors);
