import React, { Fragment } from "react";
import moment from "moment";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Table,
    Button,
    Divider,
    Typography,
} from "antd";

import scss_variables from "./../../_variables.scss";
import SocialDataPresentor from "../SocialDataPresentor";
import ReportSingleValue from "./ReportSingleValue";
import ReportGraph from "./ReportGraph";

import { social_LANGUAGE_BY_LOCALE } from "../../functions";
import ReactJson from "react-json-view";

const { Panel } = Collapse;
const { Text } = Typography;

const GRID_GUTTER = 40;

const ReportResultChannelMonthlyYoutube = (props) => {
    console.info("PROPS", props);
    if (typeof props.results_separated === "undefined") return null;

    const { columnHeaders, rows } = props.results_separated[0];
    let dataSource = [];
    const columns = columnHeaders.map((header, hIndex) => {
        return {
            title: header.name.substring(0, 3) + "...",
            dataIndex: hIndex,
            key: hIndex,
            sorter:
                header.dataType === "FLOAT" || header.dataType === "INTEGER"
                    ? (a, b) => Number(a[hIndex]) - Number(b[hIndex])
                    : null,
        };
    });
    dataSource = rows;

    const tableInfoPanel = (
        <Table
            dataSource={dataSource}
            columns={columns}
            size={"small"}
            pagination={false}
        />
    );

    // -----------------------------------------

    // helper functions
    const sum = (array) => array.reduce((a, b) => a + b, 0);
    const avg = (array) => sum(array) / array.length || 0;

    const { rows: reportVideos } = props.results_separated[0];
    const totalStatistics = {
        annotationClickThroughRate: reportVideos
            ? avg(reportVideos.map((r) => r[1]))
            : 0,
        annotationCloseRate: reportVideos
            ? avg(reportVideos.map((r) => r[2]))
            : 0,
        averageViewDuration: reportVideos
            ? avg(reportVideos.map((r) => r[3]))
            : 0,
        comments: reportVideos ? sum(reportVideos.map((r) => r[4])) : 0,
        dislikes: reportVideos ? sum(reportVideos.map((r) => r[5])) : 0,
        estimatedMinutesWatched: reportVideos
            ? sum(reportVideos.map((r) => r[6]))
            : 0,
        likes: reportVideos ? sum(reportVideos.map((r) => r[7])) : 0,
        shares: reportVideos ? sum(reportVideos.map((r) => r[8])) : 0,
        subscribersGained: reportVideos
            ? sum(reportVideos.map((r) => r[9]))
            : 0,
        subscribersLost: reportVideos ? sum(reportVideos.map((r) => r[10])) : 0,
        views: reportVideos ? sum(reportVideos.map((r) => r[11])) : 0,
    };
    const totalStatsPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="annotationClickThroughRate"
                        value={
                            totalStatistics &&
                            totalStatistics.annotationClickThroughRate
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="annotationCloseRate"
                        value={
                            totalStatistics &&
                            totalStatistics.annotationCloseRate
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="averageViewDuration"
                        value={
                            totalStatistics &&
                            totalStatistics.averageViewDuration
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="comments"
                        value={totalStatistics && totalStatistics.comments}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="dislikes"
                        value={totalStatistics && totalStatistics.dislikes}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="estimatedMinutesWatched"
                        value={
                            totalStatistics &&
                            totalStatistics.estimatedMinutesWatched
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="likes"
                        value={totalStatistics && totalStatistics.likes}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="shares"
                        value={totalStatistics && totalStatistics.shares}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="subscribersGained"
                        value={
                            totalStatistics && totalStatistics.subscribersGained
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="subscribersLost"
                        value={
                            totalStatistics && totalStatistics.subscribersLost
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="views"
                        value={totalStatistics && totalStatistics.views}
                        // prefix={<Icon type="eye" />}
                        description={
                            "Description of some sort on few lines and on some more if it is needed that will explain what this value is"
                        }
                    />
                </Col>
                <Col span={8}>&nbsp;</Col>
            </Row>
        </Fragment>
    );

    // -----------------------------------------

    const body = props.loading ? (
        <Fragment>
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            {/* <ReactJson src={props.results_separated[0]} /> */}
            {/* <ReactJson src={columns} />
            <ReactJson src={rows} /> */}
            <Collapse bordered={false} defaultActiveKey={["1", "2", "3"]}>
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("channels") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Included in this Report
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "channels"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "channels",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="1"
                    >
                        {props.custom_sections.indexOf("channels") !== -1 &&
                            props.results_channels &&
                            props.results_channels.map((rc, rcIndex) => {
                                const resultChannel = rc.split("|");
                                return (
                                    <div key={rcIndex}>
                                        <Icon
                                            type={resultChannel[1]}
                                            theme="filled"
                                            style={{
                                                color: props.store.getTagColorForType(
                                                    resultChannel[1]
                                                ),
                                                marginRight: 10,
                                            }}
                                        />
                                        {resultChannel[3]} (
                                        <strong>{resultChannel[4]}</strong>)
                                    </div>
                                );
                            })}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("activity_overview") !==
                        -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Activity Overview
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "activity_overview"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "activity_overview",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="2"
                    >
                        {props.custom_sections.indexOf("activity_overview") !==
                            -1 && totalStatsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("videos") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Videos
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "videos"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "videos",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="3"
                    >
                        {props.custom_sections.indexOf("videos") !== -1 &&
                            tableInfoPanel}
                    </Panel>
                ) : null}
            </Collapse>
        </Fragment>
    );

    return body;
};

export default ReportResultChannelMonthlyYoutube;
