import React, { Fragment } from "react";
import moment from "moment";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Table,
    Button,
    Divider,
    Typography,
} from "antd";

import scss_variables from "./../../_variables.scss";
import SocialDataPresentor from "../SocialDataPresentor";
import ReportSingleValue from "./ReportSingleValue";
import ReportGraph from "./ReportGraph";

import { social_LANGUAGE_BY_LOCALE } from "../../functions";

const { Panel } = Collapse;
const { Text } = Typography;

const GRID_GUTTER = 40;

const ReportResultChannelMonthlyFacebook = (props) => {
    console.info("PROPS", props);

    const activityOverviewPanel_pageImpressionsDiff =
        props.combined_results.page_impressions &&
        props.combined_prev_results.page_impressions
            ? (
                  (props.combined_results.page_impressions.sum /
                      props.combined_prev_results.page_impressions.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    const activityOverviewPanel_pageImpressionsOld =
        props.combined_prev_results &&
        props.combined_prev_results.page_impressions.sum;

    const activityOverviewPanel_pagePostEngagementsDiff =
        props.combined_results.page_post_engagements &&
        props.combined_prev_results.page_post_engagements
            ? (
                  (props.combined_results.page_post_engagements.sum /
                      props.combined_prev_results.page_post_engagements.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;

    const activityOverviewPanel_pagePostEngagementsOld =
        props.combined_prev_results &&
        props.combined_prev_results.page_post_engagements.sum;

    const activityOverviewPanel_pageConsumptionsDiff =
        props.combined_results.page_consumptions &&
        props.combined_prev_results.page_consumptions
            ? (
                  (props.combined_results.page_consumptions.sum /
                      props.combined_prev_results.page_consumptions.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;

    const activityOverviewPanel_pagePostConsumptionsOld =
        props.combined_prev_results &&
        props.combined_prev_results.page_consumptions.sum;

    // Comments, Likes and Shares
    let activityOverviewPanel_comments = 0,
        activityOverviewPanel_likes = 0,
        activityOverviewPanel_shares = 0,
        activityOverviewPanel_prevcomments = 0,
        activityOverviewPanel_prevlikes = 0,
        activityOverviewPanel_prevshares = 0,
        commentsPanel_comments = {};

    props.results_separated &&
        props.results_separated.map((r, rIndex) => {
            r.postsSimplified.data
                .filter((p) => p.comments && p.comments.data)
                .map((p) => {
                    p.comments.data.map((comment) => {
                        //console.info("comment", comment);
                        if (!commentsPanel_comments[comment.created_time]) {
                            commentsPanel_comments[comment.created_time] = 1;
                        } else {
                            commentsPanel_comments[comment.created_time] += 1;
                        }
                    });
                    return false;
                });

            //console.info("commentsPanel_comments", commentsPanel_comments);

            activityOverviewPanel_comments += r.postsSimplified.data
                .filter((p) => p.comments && p.comments.data)
                .reduce(
                    (acc, currentValue) =>
                        acc + currentValue.comments.data.length,
                    0
                );
            activityOverviewPanel_prevcomments +=
                r.prevPeriod.postsSimplified.data
                    .filter((p) => p.comments)
                    .reduce(
                        (acc, currentValue) =>
                            acc + currentValue.comments.data.length,
                        0
                    );
            activityOverviewPanel_likes += r.postsSimplified.data
                .filter((p) => p.likes)
                .reduce(
                    (acc, currentValue) => acc + currentValue.likes.data.length,
                    0
                );
            activityOverviewPanel_prevlikes += r.prevPeriod.postsSimplified.data
                .filter((p) => p.likes)
                .reduce(
                    (acc, currentValue) => acc + currentValue.likes.data.length,
                    0
                );
            activityOverviewPanel_shares += r.postsSimplified.data
                .filter((p) => p.shares)
                .reduce(
                    (acc, currentValue) => acc + currentValue.shares.count,
                    0
                );
            activityOverviewPanel_prevshares +=
                r.prevPeriod.postsSimplified.data
                    .filter((p) => p.shares)
                    .reduce(
                        (acc, currentValue) => acc + currentValue.shares.count,
                        0
                    );
        });

    var commentsPanel_commentsArray = Object.keys(commentsPanel_comments).map(
        function (key) {
            // Using Number() to convert key to number type
            // Using obj[key] to retrieve key value
            return {
                current_time: moment(key).format("MM/DD"),
                value: commentsPanel_comments[key],
            };
        }
    );
    // console.info("commentsPanel_commentsArray", commentsPanel_commentsArray);

    const activityOverviewPanel_commentsDiff =
        activityOverviewPanel_comments &&
        activityOverviewPanel_prevcomments !== 0 &&
        activityOverviewPanel_prevcomments
            ? (
                  (activityOverviewPanel_comments /
                      activityOverviewPanel_prevcomments) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    const activityOverviewPanel_likesDiff =
        activityOverviewPanel_likes &&
        activityOverviewPanel_prevlikes !== 0 &&
        activityOverviewPanel_prevlikes
            ? (
                  (activityOverviewPanel_likes /
                      activityOverviewPanel_prevlikes) *
                      100 -
                  100
              ).toFixed(2)
            : 0;

    const activityOverviewPanel_sharesDiff =
        activityOverviewPanel_shares &&
        activityOverviewPanel_prevshares !== 0 &&
        activityOverviewPanel_prevshares
            ? (
                  (activityOverviewPanel_shares /
                      activityOverviewPanel_prevshares) *
                      100 -
                  100
              ).toFixed(2)
            : 0;

    console.log(
        "activityOverviewPanel_commentsDiff = " +
            activityOverviewPanel_commentsDiff
    );

    const activityOverviewPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Impressions"
                        value={
                            props.combined_results.page_impressions &&
                            props.combined_results.page_impressions.sum
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            props.combined_results.page_impressions &&
                            props.combined_results.page_impressions.description
                        }
                        diff={activityOverviewPanel_pageImpressionsDiff}
                        oldValue={activityOverviewPanel_pageImpressionsOld}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Engagements"
                        value={
                            props.combined_results.page_post_engagements &&
                            props.combined_results.page_post_engagements.sum
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            props.combined_results.page_post_engagements &&
                            props.combined_results.page_post_engagements
                                .description
                        }
                        diff={activityOverviewPanel_pagePostEngagementsDiff}
                        oldValue={activityOverviewPanel_pagePostEngagementsOld}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Clicks"
                        value={
                            props.combined_results.page_consumptions &&
                            props.combined_results.page_consumptions.sum
                        }
                        // prefix={<Icon type="eye" />}
                        description={
                            props.combined_results.page_consumptions &&
                            props.combined_results.page_consumptions.description
                        }
                        diff={activityOverviewPanel_pageConsumptionsDiff}
                        oldValue={activityOverviewPanel_pagePostConsumptionsOld}
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Comments"
                        value={activityOverviewPanel_comments}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of comments on any of your content"
                        }
                        diff={activityOverviewPanel_commentsDiff}
                        oldValue={activityOverviewPanel_prevcomments}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Likes"
                        value={activityOverviewPanel_likes}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of likes on any of your content"
                        }
                        diff={activityOverviewPanel_likesDiff}
                        oldValue={activityOverviewPanel_prevlikes}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Shares"
                        value={activityOverviewPanel_shares}
                        // prefix={<Icon type="eye" />}
                        description={
                            "The number of shares on any of your content"
                        }
                        diff={activityOverviewPanel_sharesDiff}
                        oldValue={activityOverviewPanel_prevshares}
                    />
                </Col>
            </Row>
        </Fragment>
    );

    // -----------------------------------------

    const audienceGrowthPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER}>
                <Col span={18}>
                    {props.combined_results.page_fan_adds ? (
                        <ReportGraph
                            title={
                                "New Fans"
                                // (props.combined_results.page_fan_adds &&
                                //     props.combined_results.page_fan_adds
                                //         .title) ||
                                // "page_fan_adds"
                            }
                            description={
                                props.combined_results.page_fan_adds &&
                                props.combined_results.page_fan_adds.description
                            }
                            value={
                                props.combined_results.page_fan_adds &&
                                props.combined_results.page_fan_adds.values.map(
                                    (val) => ({
                                        value: val.value,
                                        end_time: moment(val.end_time).format(
                                            "MM/DD"
                                        ),
                                    })
                                )
                            }
                            value_name="value"
                            key_name="end_time"
                            hideTotal
                            //   metric_name="page_fan_adds"
                            type="ColumnChart"
                        />
                    ) : null}
                </Col>
                <Col span={6}>
                    <ReportSingleValue
                        title="Total New Fans"
                        shallowDescription={true}
                        value={
                            props.combined_results.page_fan_adds &&
                            props.combined_results.page_fan_adds.sum
                        }
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_adds &&
                        //     props.combined_results.page_fan_adds.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                </Col>
            </Row>
            <Divider />
            <Row gutter={GRID_GUTTER}>
                <Col span={18}>
                    {props.combined_results.page_fan_removes ? (
                        <ReportGraph
                            title={
                                // (props.combined_results.page_fan_removes &&
                                //     props.combined_results.page_fan_removes
                                //         .title) ||
                                // "page_fan_removes"
                                "Lost Fans"
                            }
                            description={
                                props.combined_results.page_fan_removes &&
                                props.combined_results.page_fan_removes
                                    .description
                            }
                            value={
                                props.combined_results.page_fan_removes &&
                                props.combined_results.page_fan_removes.values.map(
                                    (val) => ({
                                        value: val.value,
                                        end_time: moment(val.end_time).format(
                                            "MM/DD"
                                        ),
                                    })
                                )
                            }
                            value_name="value"
                            key_name="end_time"
                            hideTotal
                            //   metric_name="page_fan_adds"
                            type="ColumnChart"
                        />
                    ) : null}
                </Col>
                <Col span={6}>
                    <ReportSingleValue
                        title="Total Lost Fans"
                        shallowDescription={true}
                        value={
                            props.combined_results.page_fan_removes &&
                            props.combined_results.page_fan_removes.sum
                        }
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     props.combined_results.page_fan_removes &&
                        //     props.combined_results.page_fan_removes.description
                        // }
                        //diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        // oldValue={
                        //     activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        // }
                    />
                </Col>
            </Row>
        </Fragment>
    );

    // -----------------------------------

    const postsPanel_pagePostsSimplifiedTotalCountDiff =
        props.combined_results.page_posts_simplified_summary_totalCount &&
        props.combined_prev_results.page_posts_simplified_summary_totalCount
            ? (
                  (props.combined_results
                      .page_posts_simplified_summary_totalCount /
                      props.combined_prev_results
                          .page_posts_simplified_summary_totalCount) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    const activityOverviewPanel_pagePostsSimplifiedTotalCountOld =
        props.combined_prev_results &&
        props.combined_prev_results.page_posts_simplified_summary_totalCount;

    const postsPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER}>
                <Col span={18}>
                    {props.combined_results.posts_simplified ? (
                        <ReportGraph
                            title={"Published Posts"}
                            description={
                                "Published Facebook Page Posts during the selected period"
                            }
                            value={
                                props.combined_results &&
                                props.combined_results.posts_simplified
                            }
                            value_name="value"
                            key_name="date"
                            hideTotal
                            //   metric_name="page_fan_adds"
                            type="ColumnChart"
                        />
                    ) : null}
                </Col>
                <Col span={6}>
                    <ReportSingleValue
                        title="Total Published Posts"
                        shallowDescription={true}
                        value={
                            props.combined_results &&
                            props.combined_results
                                .page_posts_simplified_summary_totalCount
                        }
                        // prefix={<Icon type="eye" />}
                        // description={
                        //     "Published Facebook Page Posts during the selected period"
                        // }
                        diff={postsPanel_pagePostsSimplifiedTotalCountDiff}
                        oldValue={
                            activityOverviewPanel_pagePostsSimplifiedTotalCountOld
                        }
                    />
                </Col>
            </Row>
        </Fragment>
    );

    // --------------------------------

    const impressionsPanel_pageImpressionsDiff =
        props.combined_results.page_impressions &&
        props.combined_prev_results.page_impressions
            ? (
                  (props.combined_results.page_impressions.sum /
                      props.combined_prev_results.page_impressions.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    // const activityOverviewPanel_pageImpressionsOld =
    //     props.combined_prev_results &&
    //     props.combined_prev_results.page_impressions.sum;

    const impressionsPanel = (
        <Row gutter={GRID_GUTTER}>
            <Col span={18}>
                {props.combined_results.page_impressions ? (
                    <ReportGraph
                        title={
                            // (props.combined_results.page_impressions &&
                            //     props.combined_results.page_impressions
                            //         .title) ||
                            // "page_impressions"
                            "Total Impressions"
                        }
                        description={
                            props.combined_results.page_impressions &&
                            props.combined_results.page_impressions.description
                        }
                        value={
                            props.combined_results.page_impressions &&
                            props.combined_results.page_impressions.values.map(
                                (val) => ({
                                    value: val.value,
                                    end_time: moment(val.end_time).format(
                                        "MM/DD"
                                    ),
                                })
                            )
                        }
                        value_name="value"
                        key_name="end_time"
                        hideTotal
                        //   metric_name="page_fan_adds"
                        type="ColumnChart"
                    />
                ) : null}
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Total Impressions"
                    shallowDescription={true}
                    value={
                        props.combined_results.page_impressions &&
                        props.combined_results.page_impressions.sum
                    }
                    // prefix={<Icon type="eye" />}
                    // description={
                    //     props.combined_results.page_impressions &&
                    //     props.combined_results.page_impressions.description
                    // }
                    diff={impressionsPanel_pageImpressionsDiff}
                    oldValue={activityOverviewPanel_pageImpressionsOld}
                />
            </Col>
        </Row>
    );

    // -------------------------------

    const engagementPanel_pagePostEngagementsDiff =
        props.combined_results.page_post_engagements &&
        props.combined_prev_results.page_post_engagements
            ? (
                  (props.combined_results.page_post_engagements.sum /
                      props.combined_prev_results.page_post_engagements.sum) *
                      100 -
                  100
              ).toFixed(2)
            : 0;
    console.log(
        "engagementPanel_pagePostEngagementsDiff = " +
            engagementPanel_pagePostEngagementsDiff
    );

    const engagementPanel_pagePostEngagementsOld =
        props.combined_prev_results &&
        props.combined_prev_results.page_post_engagements.sum;

    const engagementPanel = (
        <Row gutter={GRID_GUTTER}>
            <Col span={18}>
                {props.combined_results.page_post_engagements ? (
                    <ReportGraph
                        title={
                            // (props.combined_results.page_post_engagements &&
                            //     props.combined_results.page_post_engagements
                            //         .title) ||
                            // "page_post_engagements"
                            "Post Engagement"
                        }
                        description={
                            props.combined_results.page_post_engagements &&
                            props.combined_results.page_post_engagements
                                .description
                        }
                        value={
                            props.combined_results.page_post_engagements &&
                            props.combined_results.page_post_engagements.values
                        }
                        value_name="value"
                        key_name="end_time"
                        hideTotal
                        //   metric_name="page_fan_adds"
                        type="AreaChart"
                    />
                ) : null}
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Total Posts Engagement"
                    shallowDescription={true}
                    value={
                        props.combined_results.page_post_engagements &&
                        props.combined_results.page_post_engagements.sum
                    }
                    // prefix={<Icon type="eye" />}
                    // description={
                    //     props.combined_results.page_post_engagements &&
                    //     props.combined_results.page_post_engagements.description
                    // }
                    diff={engagementPanel_pagePostEngagementsDiff}
                    oldValue={engagementPanel_pagePostEngagementsOld}
                />
            </Col>
        </Row>
    );

    // ---------------------------
    const dataSource = props.results_separated
        ? props.results_separated.map((r, rIndex) => {
              const impressions = r.fbRes.data.filter((d) => {
                  if (d.name === "page_impressions") return d;
                  return false;
              })[0].sum;
              const engagements = r.fbRes.data.filter((d) => {
                  if (d.name === "page_post_engagements") return d;
                  return false;
              })[0].sum;

              return {
                  key: rIndex,
                  name: (
                      <Fragment>
                          {r.snapshot.name}
                          <br />
                          <Icon
                              type={r.type}
                              theme="filled"
                              style={{
                                  color: props.store.getTagColorForType(r.type),
                                  marginRight: 5,
                              }}
                          />
                          <strong>{r.name}</strong>
                      </Fragment>
                  ),
                  page_fan_adds: r.fbRes.data.filter((d) => {
                      if (d.name === "page_fan_adds") return d;
                      return false;
                  })[0].sum,
                  page_impressions: impressions,
                  page_post_engagements: engagements,
                  page_consumptions: r.fbRes.data.filter((d) => {
                      if (d.name === "page_consumptions") return d;
                      return false;
                  })[0].sum,
                  page_posts: r.postsSimplified.summary.total_count,
                  impressions_per_post:
                      r.postsSimplified.summary.total_count > 0
                          ? (
                                impressions /
                                r.postsSimplified.summary.total_count
                            ).toFixed(2)
                          : "N/A",
                  engagements_per_post:
                      r.postsSimplified.summary.total_count > 0
                          ? (
                                engagements /
                                r.postsSimplified.summary.total_count
                            ).toFixed(2)
                          : "N/A",
                  page_comments: r.postsSimplified.data
                      .filter((p) => p.comments)
                      .reduce(
                          (acc, currentValue) =>
                              acc + currentValue.comments.data.length,
                          0
                      ),
                  page_likes: r.postsSimplified.data
                      .filter((p) => p.likes)
                      .reduce(
                          (acc, currentValue) =>
                              acc + currentValue.likes.data.length,
                          0
                      ),
                  page_shares: r.postsSimplified.data
                      .filter((p) => p.shares)
                      .reduce(
                          (acc, currentValue) =>
                              acc + currentValue.shares.count,
                          0
                      ),
              };
          })
        : [];

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Total fans",
            dataIndex: "page_fan_adds",
            key: "page_fan_adds",
        },
        {
            title: "Impressions",
            dataIndex: "page_impressions",
            key: "page_impressions",
        },
        {
            title: "Engagements",
            dataIndex: "page_post_engagements",
            key: "page_post_engagements",
        },
        {
            title: "Link Clicks",
            dataIndex: "page_consumptions",
            key: "page_consumptions",
        },
        {
            title: "Posts",
            dataIndex: "page_posts",
            key: "page_posts",
        },
        {
            title: "Comments",
            dataIndex: "page_comments",
            key: "page_comments",
        },
        {
            title: "Likes",
            dataIndex: "page_likes",
            key: "page_likes",
        },
        {
            title: "Shares",
            dataIndex: "page_shares",
            key: "page_shares",
        },
        {
            title: "Impr./Post",
            dataIndex: "impressions_per_post",
            key: "impressions_per_post",
        },
        {
            title: "Engag./Post",
            dataIndex: "engagements_per_post",
            key: "engagements_per_post",
        },
    ];

    const pagestatsPanel = (
        <Table dataSource={dataSource} columns={columns} pagination={false} />
    );

    // audienceInfo
    const audienceInfoPanel_GenderSeparated = [
        {
            key: "Male",
            value:
                props.combined_results.page_impressions_by_age_gender_unique &&
                props.combined_results.page_impressions_by_age_gender_unique.sum
                    .filter((m) => m.key.indexOf("M") !== -1)
                    .reduce((a, b) => {
                        return { value: Number(a.value) + Number(b.value) };
                    }).value,
        },
        {
            key: "Female",
            value:
                props.combined_results.page_impressions_by_age_gender_unique &&
                props.combined_results.page_impressions_by_age_gender_unique.sum
                    .filter((m) => m.key.indexOf("F") !== -1)
                    .reduce((a, b) => {
                        return { value: Number(a.value) + Number(b.value) };
                    }).value,
        },
    ];
    // Sorting
    audienceInfoPanel_GenderSeparated.sort((a, b) => b.value - a.value);

    console.info(
        "audienceInfoPanel_GenderSeparated",
        audienceInfoPanel_GenderSeparated
    );

    const audienceInfoPanel = (
        <Fragment>
            <Row gutter={GRID_GUTTER * 2}>
                <Col span={8}>
                    {props.combined_results
                        .page_impressions_by_country_unique ? (
                        <Fragment>
                            <Row>
                                <Col span={24}>
                                    <Text
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Country
                                    </Text>
                                </Col>
                            </Row>
                            {props.combined_results.page_impressions_by_country_unique.sum.map(
                                (country, countryIndex) => {
                                    return (
                                        <Row
                                            key={countryIndex}
                                            style={
                                                countryIndex === 0
                                                    ? {
                                                          fontSize: 15,
                                                          borderBottom:
                                                              "1px dotted #ccc",
                                                          paddingBottom: 5,
                                                          marginBottom: 5,
                                                      }
                                                    : null
                                            }
                                        >
                                            <Col span={12}>
                                                <Text
                                                    strong={countryIndex === 0}
                                                >
                                                    {country.key}
                                                </Text>
                                            </Col>
                                            <Col
                                                span={12}
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Text
                                                    strong={countryIndex === 0}
                                                >
                                                    {country.value}
                                                </Text>
                                            </Col>
                                        </Row>
                                    );
                                }
                            )}
                        </Fragment>
                    ) : null}
                </Col>
                <Col span={8}>
                    {props.combined_results.page_impressions_by_city_unique ? (
                        <Fragment>
                            <Row>
                                <Col span={24}>
                                    <Text
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        City
                                    </Text>
                                </Col>
                            </Row>
                            {props.combined_results.page_impressions_by_city_unique.sum
                                .filter((i, iIndex) => iIndex < 10)
                                .map((city, cityIndex) => {
                                    return (
                                        <Row
                                            key={cityIndex}
                                            style={
                                                cityIndex === 0
                                                    ? {
                                                          fontSize: 15,
                                                          borderBottom:
                                                              "1px dotted #ccc",
                                                          paddingBottom: 5,
                                                          marginBottom: 5,
                                                      }
                                                    : null
                                            }
                                        >
                                            <Col span={12}>
                                                <Text strong={cityIndex === 0}>
                                                    {city.key}
                                                </Text>
                                            </Col>
                                            <Col
                                                span={12}
                                                style={{ textAlign: "right" }}
                                            >
                                                <Text strong={cityIndex === 0}>
                                                    {city.value}
                                                </Text>
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </Fragment>
                    ) : null}
                </Col>
                <Col span={8}>
                    {props.combined_results
                        .page_impressions_by_locale_unique ? (
                        <Fragment>
                            <Row>
                                <Col span={24}>
                                    <Text
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Language
                                    </Text>
                                </Col>
                            </Row>
                            {props.combined_results.page_impressions_by_locale_unique.sum
                                .filter((l, lIndex) => lIndex < 10)
                                .map((locale, localeIndex) => {
                                    return (
                                        <Row
                                            key={localeIndex}
                                            style={
                                                localeIndex === 0
                                                    ? {
                                                          fontSize: 15,
                                                          borderBottom:
                                                              "1px dotted #ccc",
                                                          paddingBottom: 5,
                                                          marginBottom: 5,
                                                      }
                                                    : null
                                            }
                                        >
                                            <Col span={12}>
                                                <Text
                                                    strong={localeIndex === 0}
                                                >
                                                    {
                                                        social_LANGUAGE_BY_LOCALE[
                                                            locale.key
                                                        ]
                                                    }
                                                </Text>
                                            </Col>
                                            <Col
                                                span={12}
                                                style={{ textAlign: "right" }}
                                            >
                                                <Text
                                                    strong={localeIndex === 0}
                                                >
                                                    {locale.value}
                                                </Text>
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </Fragment>
                    ) : null}
                </Col>
            </Row>
            <Row gutter={GRID_GUTTER * 2}>
                <Col span={24}>
                    <Divider />
                </Col>
            </Row>
            <Row gutter={GRID_GUTTER * 2}>
                <Col span={12}>
                    {props.combined_results
                        .page_impressions_by_age_gender_unique ? (
                        <SocialDataPresentor
                            title={
                                (props.combined_results
                                    .page_impressions_by_age_gender_unique &&
                                    props.combined_results
                                        .page_impressions_by_age_gender_unique
                                        .title) ||
                                "page_impressions_by_age_gender_unique"
                            }
                            description={
                                props.combined_results
                                    .page_impressions_by_age_gender_unique &&
                                props.combined_results
                                    .page_impressions_by_age_gender_unique
                                    .description
                            }
                            value={
                                props.combined_results
                                    .page_impressions_by_age_gender_unique &&
                                props.combined_results
                                    .page_impressions_by_age_gender_unique.sum
                            }
                            value_name="value"
                            key_name="key"
                            // metric_name="page_impressions_by_age_gender_unique"
                            link="http://www.cnn.com"
                            type="BarChart"
                        />
                    ) : null}
                </Col>
                <Col span={12} style={{ textAlign: "center" }}>
                    {props.combined_results
                        .page_impressions_by_age_gender_unique ? (
                        <SocialDataPresentor
                            title={"Audience by gender"}
                            chartStyle={{
                                margin: "0 auto",
                                width: 300,
                                paddingTop: 70,
                            }}
                            description={
                                props.combined_results
                                    .page_impressions_by_age_gender_unique &&
                                props.combined_results
                                    .page_impressions_by_age_gender_unique
                                    .description
                            }
                            value={
                                props.combined_results
                                    .page_impressions_by_age_gender_unique &&
                                audienceInfoPanel_GenderSeparated
                            }
                            value_name="value"
                            key_name="key"
                            // metric_name="page_impressions_by_age_gender_unique"
                            link="http://www.cnn.com"
                            type="PieChart"
                            hideTotal={true}
                        />
                    ) : null}
                </Col>
            </Row>
        </Fragment>
        // {props.combined_results.page_post_engagements ? (
        //     <SocialDataPresentor
        //         title={
        //             (props.combined_results.page_post_engagements &&
        //                 props.combined_results.page_post_engagements
        //                     .title) ||
        //             "page_post_engagements"
        //         }
        //         description={
        //             props.combined_results.page_post_engagements &&
        //             props.combined_results.page_post_engagements
        //                 .description
        //         }
        //         value={
        //             props.combined_results.page_post_engagements &&
        //             props.combined_results.page_post_engagements.values
        //         }
        //         value_name="value"
        //         key_name="end_time"
        //         // metric_name="page_post_engagements"
        //         link="http://www.cnn.com"
        //         type="AreaChart"
        //     />
        // ) : null}
    );

    // -------------------------

    /*
    Average Reach per Post: The average number of unique users to whom your post was displayed during its lifetime.

    Engagement Rate (per Impression): The number of times that users engaged with your post or Instagram story as a percentage of impressions during its lifetime.

    Engagements: The number of times that users engaged with your post during its lifetime.

    Reactions: The number of users who liked or reacted to your post during its lifetime.

    NOT -> Comments: The number of comments on your post or messages received on your Instagram story during the post or Instagram story’s lifetime.

    ??? Shares: The number of users that shared your post.
    Post Link Clicks: The number of times that users clicked on the link in your post during its lifetime.

    ???Other Post Clicks: The number of times that users clicked anywhere in your post, excluding the link, without reacting to, liking, commenting on, or sharing your post, during its lifetime.
    Video Views: The number of times that your video was viewed during the post’s lifetime.

    ???Additional Post Options: Easily resend or remove the post by clicking either Send Post Again or Remove from Facebook. You can also Boost Post from this drop-down.
    */

    const additionalPanel_averageReachPerPost =
        props.combined_results &&
        props.combined_results.page_impressions &&
        props.combined_results.page_impressions.sum &&
        props.combined_results.page_posts_simplified_summary_totalCount
            ? Number(
                  props.combined_results.page_impressions.sum /
                      props.combined_results
                          .page_posts_simplified_summary_totalCount
              ).toFixed(2)
            : 0;

    const additionalPanel_engagementRate =
        props.combined_results &&
        props.combined_results.page_impressions &&
        props.combined_results.page_impressions.sum &&
        props.combined_results.page_post_engagements.sum
            ? Number(
                  props.combined_results.page_impressions.sum /
                      props.combined_results.page_post_engagements.sum
              ).toFixed(2)
            : 0;

    const additionalPanel_reactions =
        props.combined_results &&
        props.combined_results.page_actions_post_reactions_total
            ? props.combined_results.page_actions_post_reactions_total.sum
            : 0;

    const additionalPanel_videoviews =
        props.combined_results && props.combined_results.page_video_views
            ? props.combined_results.page_video_views.sum
            : 0;

    // const additionalPanel_pageImpressionsOld =
    //     props.combined_results && props.combined_results.page_impressions.sum;

    const additionalPanel = (
        <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
            <Col span={6}>
                <ReportSingleValue
                    title="Average Reach per Post"
                    value={additionalPanel_averageReachPerPost}
                    // prefix={<Icon type="eye" />}
                    description={
                        "The average number of unique users to whom your post was displayed during its lifetime."
                    }
                    //diff={activityOverviewPanel_pageImpressionsDiff}
                    //oldValue={activityOverviewPanel_pageImpressionsOld}
                />
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Engagement Rate (per Impression)"
                    value={additionalPanel_engagementRate}
                    // prefix={<Icon type="eye" />}
                    description={
                        "The number of times that users engaged with your post or Instagram story as a percentage of impressions during its lifetime."
                    }
                    //diff={activityOverviewPanel_pagePostEngagementsDiff}
                    //oldValue={activityOverviewPanel_pagePostEngagementsOld}
                />
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Reactions"
                    value={additionalPanel_reactions}
                    // prefix={<Icon type="eye" />}
                    description={
                        "The number of users who liked or reacted to your post during its lifetime."
                    }
                    //diff={activityOverviewPanel_pageConsumptionsDiff}
                    //oldValue={activityOverviewPanel_pagePostConsumptionsOld}
                />
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Video Views"
                    value={additionalPanel_videoviews}
                    // prefix={<Icon type="eye" />}
                    description={
                        props.combined_results.page_video_views &&
                        props.combined_results.page_video_views.description
                    }
                    //diff={activityOverviewPanel_pageConsumptionsDiff}
                    //oldValue={activityOverviewPanel_pagePostConsumptionsOld}
                />
            </Col>
        </Row>
    );

    // const activityOverviewPanel_commentsDiff =
    //     activityOverviewPanel_comments &&
    //     activityOverviewPanel_prevcomments !== 0 &&
    //     activityOverviewPanel_prevcomments
    //         ? (
    //               (activityOverviewPanel_comments /
    //                   activityOverviewPanel_prevcomments) *
    //                   100 -
    //               100
    //           ).toFixed(2)
    //         : 0;

    console.info("commentsPanel_commentsArray", commentsPanel_commentsArray);
    const commentsPanel = (
        <Row gutter={GRID_GUTTER}>
            <Col span={18}>
                <ReportGraph
                    title={
                        // (props.combined_results.page_post_engagements &&
                        //     props.combined_results.page_post_engagements
                        //         .title) ||
                        // "page_post_engagements"
                        "Post Comments"
                    }
                    // description={
                    //     props.combined_results.page_post_engagements &&
                    //     props.combined_results.page_post_engagements
                    //         .description
                    // }
                    value={commentsPanel_commentsArray}
                    value_name="value"
                    key_name="current_time"
                    hideTotal
                    //   metric_name="page_fan_adds"
                    type="ColumnChart"
                />
            </Col>
            <Col span={6}>
                <ReportSingleValue
                    title="Total Posts Comments"
                    shallowDescription={true}
                    value={
                        activityOverviewPanel_comments &&
                        activityOverviewPanel_comments
                    }
                    // prefix={<Icon type="eye" />}
                    // description={
                    //     props.combined_results.page_post_engagements &&
                    //     props.combined_results.page_post_engagements.description
                    // }
                    diff={activityOverviewPanel_commentsDiff}
                    oldValue={activityOverviewPanel_prevcomments}
                />
            </Col>
        </Row>
    );

    let topposts = [];
    props.results_separated &&
        props.results_separated[0].postsSimplified.data.map((post) => {
            topposts.push({
                created_time: moment(post.created_time).valueOf(),
                message: post.message,
                id: post.id,
                preview: post.attachments?.data[0].media.image.src,
                likes: post.likes ? post.likes.data.length : 0,
                comments: post.comments ? post.comments.data.length : 0,
            });
        });

    let topposts_columns = [
        {
            title: "Date/Time",
            dataIndex: "created_time",
            key: "created_time",
            width: 180,
            sorter: (a, b) => a.created_time - b.created_time,
            render: (created_time) => moment(created_time).format("lll"),
        },
        {
            title: "Preview",
            dataIndex: "preview",
            key: "preview",
            width: 100,
            render: (preview_url) => (
                <img width="100" alt="preview" src={preview_url} />
            ),
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Likes",
            dataIndex: "likes",
            key: "likes",
            sorter: (a, b) => a.likes - b.likes,
        },
        {
            title: "Comments",
            dataIndex: "comments",
            key: "comments",
            sorter: (a, b) => a.comments - b.comments,
        },
    ];
    topposts = topposts.sort((a, b) => Number(b.likes) - Number(a.likes));
    console.info("topposts", topposts);
    const toppostsPanel = (
        <div>
            <Table
                dataSource={topposts}
                columns={topposts_columns}
                size="small"
                pagination={false}
            />
            {/* <pre>{JSON.stringify(topposts, null, 2)}</pre> */}
        </div>
    );

    // -----------------------------------------

    const body = props.loading ? (
        <Fragment>
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            <Collapse
                bordered={false}
                defaultActiveKey={[
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                ]}
            >
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("channels") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Included in this Report
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "channels"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "channels",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="1"
                    >
                        {props.custom_sections.indexOf("channels") !== -1 &&
                            props.results_channels &&
                            props.results_channels.map((rc, rcIndex) => {
                                const resultChannel = rc.split("|");
                                return (
                                    <div key={rcIndex}>
                                        <Icon
                                            type={resultChannel[1]}
                                            theme="filled"
                                            style={{
                                                color: props.store.getTagColorForType(
                                                    resultChannel[1]
                                                ),
                                                marginRight: 10,
                                            }}
                                        />
                                        {resultChannel[3]} (
                                        <strong>{resultChannel[4]}</strong>)
                                    </div>
                                );
                            })}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("activity_overview") !==
                        -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Activity Overview
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "activity_overview"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "activity_overview",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="2"
                    >
                        {props.custom_sections.indexOf("activity_overview") !==
                            -1 && activityOverviewPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("audience_growth") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Audience Growth
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "audience_growth"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "audience_growth",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="3"
                    >
                        {props.custom_sections.indexOf("audience_growth") !==
                            -1 && audienceGrowthPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("audience_info") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Audience Information
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "audience_info"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "audience_info",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="4"
                    >
                        {props.custom_sections.indexOf("audience_info") !==
                            -1 && audienceInfoPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("messages") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Posts
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "messages"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "messages",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="5"
                    >
                        {props.custom_sections.indexOf("messages") !== -1 &&
                            postsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("impressions") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Impressions
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "impressions"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "impressions",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="6"
                    >
                        {props.custom_sections.indexOf("impressions") !== -1 &&
                            impressionsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("engagement") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Engagement
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "engagement"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "engagement",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="7"
                    >
                        {props.custom_sections.indexOf("engagement") !== -1 &&
                            engagementPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("comments") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Comments
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "comments"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "comments",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="8"
                    >
                        {props.custom_sections.indexOf("comments") !== -1 &&
                            commentsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("statsbypage") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Stats by Profile/Page
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "statsbypage"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "statsbypage",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="9"
                    >
                        {props.custom_sections.indexOf("statsbypage") !== -1 &&
                            pagestatsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("additional") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Additional Data
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "additional"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "additional",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="10"
                    >
                        {props.custom_sections.indexOf("additional") !== -1 &&
                            additionalPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("topposts") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Top Posts
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "topposts"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "topposts",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        key="11"
                    >
                        {props.custom_sections.indexOf("topposts") !== -1 &&
                            toppostsPanel}
                    </Panel>
                ) : null}
            </Collapse>
        </Fragment>
    );

    return body;
};

export default ReportResultChannelMonthlyFacebook;
