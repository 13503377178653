import React, { Fragment } from "react";
import moment from "moment";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Table,
    Button,
    Empty,
} from "antd";

import scss_variables from "./../../_variables.scss";
import ReactJson from "react-json-view";
import ReportSingleValue from "./ReportSingleValue";

const { Panel } = Collapse;

const GRID_GUTTER = 40;

const ReportResultChannelMonthlyLinkedin = (props) => {
    console.info("ReportResultChannelMonthlyLinkedin props", props);

    const res = props.results_separated && props.results_separated;

    console.info("Linkedin res", res);

    // Prepare users object for easy access
    const included_channels = res ? res.map((res) => res.info.name) : [];
    const share_stats = {
        uniqueImpressionsCount: 0,
        shareCount: 0,
        shareMentionsCount: 0,
        engagement: 0,
        clickCount: 0,
        likeCount: 0,
        impressionCount: 0,
        commentMentionsCount: 0,
        commentCount: 0,
    };
    res &&
        res.map((res) => {
            const st = res.shares_stats[0].totalShareStatistics;
            share_stats.uniqueImpressionsCount += st.uniqueImpressionsCount;
            share_stats.shareCount += st.shareCount;
            share_stats.shareMentionsCount += st.shareMentionsCount;
            share_stats.engagement += st.engagement;
            share_stats.clickCount += st.clickCount;
            share_stats.likeCount += st.likeCount;
            share_stats.impressionCount += st.impressionCount;
            share_stats.commentMentionsCount += st.commentMentionsCount;
            share_stats.commentCount += st.commentCount;
        });

    const summaryOverviewPanel = res ? (
        <Fragment>
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Unique Impressions"
                        value={
                            share_stats && share_stats.uniqueImpressionsCount
                        }
                        description={"Description"}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Shares Count"
                        value={share_stats && share_stats.shareCount}
                        description={"Description"}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Share Mentions Count"
                        value={share_stats && share_stats.shareMentionsCount}
                        description={"Description"}
                    />
                </Col>
            </Row>
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Engagement"
                        value={share_stats && share_stats.engagement.toFixed(2)}
                        description={"Description"}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Click Count"
                        value={share_stats && share_stats.clickCount}
                        description={"Description"}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Likes Count"
                        value={share_stats && share_stats.likeCount}
                        description={"Description"}
                    />
                </Col>
            </Row>
            <br />
            <Row gutter={GRID_GUTTER} type="flex" justify="space-between">
                <Col span={8}>
                    <ReportSingleValue
                        title="Impressions Count"
                        value={share_stats && share_stats.impressionCount}
                        description={"Description"}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Comment Mentions Count"
                        value={share_stats && share_stats.commentMentionsCount}
                        description={"Description"}
                    />
                </Col>
                <Col span={8}>
                    <ReportSingleValue
                        title="Comments Count"
                        value={share_stats && share_stats.commentCount}
                        description={"Description"}
                    />
                </Col>
            </Row>
        </Fragment>
    ) : null;

    // // // -----------------------------------------

    let pageViews = [];
    res &&
        res[0].stats.map((stat) => {
            // pageStatisticsByCountry
            stat.pageStatisticsByCountry &&
                stat.pageStatisticsByCountry.map((record) => {
                    pageViews.push({
                        key: record.country,
                        aboutPageViews:
                            record.pageStatistics.views.aboutPageViews
                                .pageViews,
                        careersPageViews:
                            record.pageStatistics.views.careersPageViews
                                .pageViews,
                        insightsPageViews:
                            record.pageStatistics.views.insightsPageViews
                                .pageViews,
                        jobsPageViews:
                            record.pageStatistics.views.jobsPageViews.pageViews,
                        lifeAtPageViews:
                            record.pageStatistics.views.lifeAtPageViews
                                .pageViews,
                        overviewPageViews:
                            record.pageStatistics.views.overviewPageViews
                                .pageViews,
                        peoplePageViews:
                            record.pageStatistics.views.peoplePageViews
                                .pageViews,
                        productsPageViews:
                            record.pageStatistics.views.productsPageViews
                                .pageViews,
                    });
                });

            // pageStatisticsByFunction
            stat.pageStatisticsByFunction &&
                stat.pageStatisticsByFunction.map((record) => {
                    pageViews.push({
                        key: record.function,
                        aboutPageViews:
                            record.pageStatistics.views.aboutPageViews
                                .pageViews,
                        careersPageViews:
                            record.pageStatistics.views.careersPageViews
                                .pageViews,
                        insightsPageViews:
                            record.pageStatistics.views.insightsPageViews
                                .pageViews,
                        jobsPageViews:
                            record.pageStatistics.views.jobsPageViews.pageViews,
                        lifeAtPageViews:
                            record.pageStatistics.views.lifeAtPageViews
                                .pageViews,
                        overviewPageViews:
                            record.pageStatistics.views.overviewPageViews
                                .pageViews,
                        peoplePageViews:
                            record.pageStatistics.views.peoplePageViews
                                .pageViews,
                        productsPageViews:
                            record.pageStatistics.views.productsPageViews
                                .pageViews,
                    });
                });

            // pageStatisticsByIndustry
            stat.pageStatisticsByIndustry &&
                stat.pageStatisticsByIndustry.map((record) => {
                    pageViews.push({
                        key: record.industry,
                        aboutPageViews:
                            record.pageStatistics.views.aboutPageViews
                                .pageViews,
                        careersPageViews:
                            record.pageStatistics.views.careersPageViews
                                .pageViews,
                        insightsPageViews:
                            record.pageStatistics.views.insightsPageViews
                                .pageViews,
                        jobsPageViews:
                            record.pageStatistics.views.jobsPageViews.pageViews,
                        lifeAtPageViews:
                            record.pageStatistics.views.lifeAtPageViews
                                .pageViews,
                        overviewPageViews:
                            record.pageStatistics.views.overviewPageViews
                                .pageViews,
                        peoplePageViews:
                            record.pageStatistics.views.peoplePageViews
                                .pageViews,
                        productsPageViews:
                            record.pageStatistics.views.productsPageViews
                                .pageViews,
                    });
                });

            // pageStatisticsByRegion
            stat.pageStatisticsByRegion &&
                stat.pageStatisticsByRegion.map((record) => {
                    pageViews.push({
                        key: record.region,
                        aboutPageViews:
                            record.pageStatistics.views.aboutPageViews
                                .pageViews,
                        careersPageViews:
                            record.pageStatistics.views.careersPageViews
                                .pageViews,
                        insightsPageViews:
                            record.pageStatistics.views.insightsPageViews
                                .pageViews,
                        jobsPageViews:
                            record.pageStatistics.views.jobsPageViews.pageViews,
                        lifeAtPageViews:
                            record.pageStatistics.views.lifeAtPageViews
                                .pageViews,
                        overviewPageViews:
                            record.pageStatistics.views.overviewPageViews
                                .pageViews,
                        peoplePageViews:
                            record.pageStatistics.views.peoplePageViews
                                .pageViews,
                        productsPageViews:
                            record.pageStatistics.views.productsPageViews
                                .pageViews,
                    });
                });

            // pageStatisticsBySeniority
            stat.pageStatisticsBySeniority &&
                stat.pageStatisticsBySeniority.map((record) => {
                    pageViews.push({
                        key: record.seniority,
                        aboutPageViews:
                            record.pageStatistics.views.aboutPageViews
                                .pageViews,
                        careersPageViews:
                            record.pageStatistics.views.careersPageViews
                                .pageViews,
                        insightsPageViews:
                            record.pageStatistics.views.insightsPageViews
                                .pageViews,
                        jobsPageViews:
                            record.pageStatistics.views.jobsPageViews.pageViews,
                        lifeAtPageViews:
                            record.pageStatistics.views.lifeAtPageViews
                                .pageViews,
                        overviewPageViews:
                            record.pageStatistics.views.overviewPageViews
                                .pageViews,
                        peoplePageViews:
                            record.pageStatistics.views.peoplePageViews
                                .pageViews,
                        productsPageViews:
                            record.pageStatistics.views.productsPageViews
                                .pageViews,
                    });
                });

            // pageStatisticsByStaffCountRange
            stat.pageStatisticsByStaffCountRange &&
                stat.pageStatisticsByStaffCountRange.map((record) => {
                    pageViews.push({
                        key: record.staffcountrange,
                        aboutPageViews:
                            record.pageStatistics.views.aboutPageViews
                                .pageViews,
                        careersPageViews:
                            record.pageStatistics.views.careersPageViews
                                .pageViews,
                        insightsPageViews:
                            record.pageStatistics.views.insightsPageViews
                                .pageViews,
                        jobsPageViews:
                            record.pageStatistics.views.jobsPageViews.pageViews,
                        lifeAtPageViews:
                            record.pageStatistics.views.lifeAtPageViews
                                .pageViews,
                        overviewPageViews:
                            record.pageStatistics.views.overviewPageViews
                                .pageViews,
                        peoplePageViews:
                            record.pageStatistics.views.peoplePageViews
                                .pageViews,
                        productsPageViews:
                            record.pageStatistics.views.productsPageViews
                                .pageViews,
                    });
                });
        });

    let pageViews_columns = [
        {
            title: "Key",
            dataIndex: "key",
            key: "key",
            width: 180,
            // sorter: (a, b) => a.created_time - b.created_time,
            // render: (created_time) => moment(created_time).format("lll"),
        },
        {
            title: "about",
            dataIndex: "aboutPageViews",
            key: "aboutPageViews",
            sorter: (a, b) => a.aboutPageViews - b.aboutPageViews,
            // width: 100,
            // render: (preview_url) => (
            //     <img width="100" alt="preview" src={preview_url} />
            // ),
        },
        {
            title: "careers",
            dataIndex: "careersPageViews",
            key: "careersPageViews",
            sorter: (a, b) => a.careersPageViews - b.careersPageViews,
        },
        {
            title: "insights",
            dataIndex: "insightsPageViews",
            key: "insightsPageViews",
            sorter: (a, b) => a.insightsPageViews - b.insightsPageViews,
        },
        {
            title: "jobs",
            dataIndex: "jobsPageViews",
            key: "jobsPageViews",
            sorter: (a, b) => a.jobsPageViews - b.jobsPageViews,
        },
        {
            title: "lifeAt",
            dataIndex: "lifeAtPageViews",
            key: "lifeAtPageViews",
            sorter: (a, b) => a.lifeAtPageViews - b.lifeAtPageViews,
        },
        {
            title: "overview",
            dataIndex: "overviewPageViews",
            key: "overviewPageViews",
            sorter: (a, b) => a.overviewPageViews - b.overviewPageViews,
        },
        {
            title: "people",
            dataIndex: "peoplePageViews",
            key: "peoplePageViews",
            sorter: (a, b) => a.peoplePageViews - b.peoplePageViews,
        },
        {
            title: "products",
            dataIndex: "productsPageViews",
            key: "productsPageViews",
            sorter: (a, b) => a.productsPageViews - b.productsPageViews,
        },
    ];
    // pageViews = pageViews.sort((a, b) => Number(b.likes) - Number(a.likes));
    // console.info("pageViews", pageViews);
    const pageViewsPanel = (
        <div>
            <Table
                dataSource={pageViews}
                columns={pageViews_columns}
                size="small"
                pagination={false}
            />
            {/* <pre>{JSON.stringify(pageViews, null, 2)}</pre> */}
        </div>
    );

    // -------------------------

    const body = props.loading ? (
        <Fragment>
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            {/* <ReactJson src={props.results_separated} /> */}
            {/* <pre>
                {props.results_separated
                    ? JSON.stringify(props.results_separated[0], null, 2)
                    : "N/A"}
            </pre> */}

            {props.results_separated[0].length === 0 ? (
                <Empty description={<span>Missing report information.</span>} />
            ) : (
                <Collapse
                    bordered={false}
                    defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
                >
                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("channels") !== -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Included in this Report
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "channels"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "channels",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="1"
                        >
                            {props.custom_sections.indexOf("channels") !== -1 &&
                                included_channels.map((rc, rcIndex) => {
                                    return (
                                        <div key={rcIndex}>
                                            <Icon
                                                type="linkedin"
                                                theme="filled"
                                                style={{ marginRight: 5 }}
                                            />
                                            {rc}
                                        </div>
                                    );
                                })}
                        </Panel>
                    ) : null}

                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("summary_overview") !==
                            -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Summary Overview
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "summary_overview"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "summary_overview",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="2"
                        >
                            {props.custom_sections.indexOf(
                                "summary_overview"
                            ) !== -1 && summaryOverviewPanel}
                        </Panel>
                    ) : null}

                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("pageviews") !== -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Page Views
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "pageviews"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "pageviews",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="3"
                        >
                            {props.custom_sections.indexOf("pageviews") !==
                                -1 && pageViewsPanel}
                        </Panel>
                    ) : null}

                    {/* 
                    {!props.custom_report_flag ||
                    (props.custom_report_flag &&
                        props.custom_sections.indexOf("table_info") !== -1) ? (
                        <Panel
                            header={
                                <Fragment>
                                    Tweets List
                                    {!props.custom_report_flag ? (
                                        <div style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                icon="carry-out"
                                                style={{
                                                    marginTop: -5,
                                                    marginRight: -15,
                                                    padding: 0,
                                                    color:
                                                        props.custom_sections.indexOf(
                                                            "table_info"
                                                        ) !== -1
                                                            ? scss_variables.scss_color_primary
                                                            : "#ccc",
                                                }}
                                                onClick={(e) => {
                                                    props.toggleCustomReportSection(
                                                        "table_info",
                                                        e
                                                    );
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    ) : null}
                                </Fragment>
                            }
                            key="7"
                        >
                            {props.custom_sections.indexOf("table_info") !== -1 &&
                                tableInfoPanel}
                        </Panel>
                    ) : null} */}
                </Collapse>
            )}
        </Fragment>
    );

    return body;
};

export default ReportResultChannelMonthlyLinkedin;
