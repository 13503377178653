import React, { Component, Fragment } from "react";
import {
    Form,
    Modal,
    Input,
    AutoComplete,
    Icon,
    Alert,
    Row,
    Col,
    Button,
} from "antd";
import { observer } from "mobx-react";
import { social_notification } from "../../functions";

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount = async () => {
            // To disable submit button at the beginning.
            this.props.form.validateFields();
            const allChannels = await this.props.store.getAllChannels();
            console.info("allChannels", allChannels);
            this.setState({ allChannels });
        };

        //   handleSubmit = e => {
        //       e.preventDefault();
        //       this.props.form.validateFields((err, values) => {
        //           if (!err) {
        //               console.log("Received values of form: ", values);
        //           }
        //       });
        //   };

        handleCheckboxChange = (_name, _evt) => {
            this.props.data[_name] = _evt.target.checked;
        };

        copyToHashtag = (_e, _target_value, _selected_channel) => {
            console.log("copyToHashtag -> " + _selected_channel);
            const { form } = this.props;

            console.info("copyToHashtag this.props.data", this.props.data);

            let selectedChannelId = null,
                selectedChannelStr = null;
            if (_selected_channel === "instagram_account") {
                selectedChannelStr = form.getFieldValue("instagram_account");
                console.log(
                    "copyToHashtag selectedChannelStr => " + selectedChannelStr
                );
                selectedChannelId = selectedChannelStr.split("|")[0];
            }
            if (_selected_channel === "twitter_account") {
                selectedChannelStr = form.getFieldValue("twitter_account");
                console.log(
                    "copyToHashtag selectedChannelStr => " + selectedChannelStr
                );
                selectedChannelId = selectedChannelStr.split("|")[0];
            }

            const selectedChannel =
                this.state.allChannels &&
                this.state.allChannels.filter(
                    (c) => c.id === selectedChannelId
                )[0];
            console.info("copyToHashtag selectedChannel -> ", selectedChannel);
            const selectedChannelNameStripped = selectedChannel.name
                .replace(" ", "")
                .replace(".", "")
                .replace("#", "");

            let fieldObj = {};
            switch (_target_value) {
                case "instagram_hashtag":
                    fieldObj = {
                        instagram_hashtag: "#" + selectedChannelNameStripped,
                    };
                    break;
                case "twitter_hashtag":
                    fieldObj = {
                        twitter_hashtag: "#" + selectedChannelNameStripped,
                    };
                    break;
                default:
                    break;
            }
            this.props.form.setFieldsValue(fieldObj);
        };

        render() {
            const { form, data } = this.props;
            const { getFieldDecorator } = form;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            return (
                <Form {...formItemLayout}>
                    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the name of the listener!",
                                    type: "string",
                                },
                            ],
                            initialValue: data.name,
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Brand">
                        {getFieldDecorator("brand", {
                            initialValue: data.brand,
                        })(
                            <AutoComplete
                                dataSource={this.props.store.getAllBrands}
                                style={{ width: 200 }}
                                placeholder="input here"
                            />
                        )}
                    </Form.Item>
                    {/* <Form.Item label="Term">
                        {getFieldDecorator("term", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the term of the listener!",
                                    type: "string",
                                },
                            ],
                            initialValue: data.term,
                        })(<Input />)}
                    </Form.Item> */}

                    <Form.Item label="Facebook Page">
                        {getFieldDecorator("facebook_page", {
                            initialValue: data.facebook_page,
                        })(
                            <AutoComplete
                                dataSource={
                                    this.state.allChannels &&
                                    this.state.allChannels
                                        .filter((c) => c.type === "facebook")
                                        .map((c) => ({
                                            value: c.id + "|" + c.account_key,
                                            text: c.name,
                                        }))
                                }
                                style={{ width: 200 }}
                                placeholder="input here"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Instagram Account">
                        <Row style={{ width: 500 }}>
                            <Col span={11}>
                                {getFieldDecorator("instagram_account", {
                                    initialValue: data.instagram_account,
                                })(
                                    <AutoComplete
                                        dataSource={
                                            this.state.allChannels &&
                                            this.state.allChannels
                                                .filter(
                                                    (c) =>
                                                        c.type === "instagram"
                                                )
                                                .map((c) => ({
                                                    value:
                                                        c.id +
                                                        "|" +
                                                        c.account_key,
                                                    text: c.name,
                                                }))
                                        }
                                        style={{ width: 200 }}
                                        placeholder="input here"
                                    />
                                )}
                            </Col>
                            <Col span={1}>
                                <Button
                                    icon="caret-right"
                                    style={{
                                        paddingTop: "5px !important",
                                        marginTop: 4,
                                        marginLeft: -20,
                                    }}
                                    onClick={(e) =>
                                        this.copyToHashtag(
                                            e,
                                            "instagram_hashtag",
                                            "instagram_account"
                                        )
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                {getFieldDecorator("instagram_hashtag", {
                                    rules: [
                                        {
                                            message:
                                                "Please input the instagram #hashtag of the listener!",
                                            type: "string",
                                        },
                                    ],
                                    initialValue: data.instagram_hashtag,
                                })(
                                    <Input
                                        placeholder="Instagram #hashtag"
                                        style={{ width: 200 }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item label="Twitter Account">
                        <Row style={{ width: 500 }}>
                            <Col span={11}>
                                {getFieldDecorator("twitter_account", {
                                    initialValue: data.twitter_account,
                                })(
                                    <AutoComplete
                                        dataSource={
                                            this.state.allChannels &&
                                            this.state.allChannels
                                                .filter(
                                                    (c) => c.type === "twitter"
                                                )
                                                .map((c) => ({
                                                    value:
                                                        c.id +
                                                        "|" +
                                                        c.account_key,
                                                    text: c.name,
                                                }))
                                        }
                                        style={{ width: 200 }}
                                        placeholder="input here"
                                    />
                                )}
                            </Col>
                            <Col span={1}>
                                <Button
                                    icon="caret-right"
                                    style={{
                                        paddingTop: "5px !important",
                                        marginTop: 4,
                                        marginLeft: -20,
                                    }}
                                    onClick={(e) =>
                                        this.copyToHashtag(
                                            e,
                                            "twitter_hashtag",
                                            "twitter_account"
                                        )
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                {getFieldDecorator("twitter_hashtag", {
                                    rules: [
                                        {
                                            message:
                                                "Please input the twitter #hashtag of the listener!",
                                            type: "string",
                                        },
                                    ],
                                    initialValue: data.twitter_hashtag,
                                })(
                                    <Input
                                        placeholder="Twitter #hashtag"
                                        style={{ width: 200 }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item label="Linkedin Account">
                        {getFieldDecorator("linkedin_account", {
                            initialValue: data.linkedin_account,
                        })(
                            <AutoComplete
                                dataSource={
                                    this.state.allChannels &&
                                    this.state.allChannels
                                        .filter((c) => c.type === "linkedin")
                                        .map((c) => ({
                                            value: c.id + "|" + c.account_key,
                                            text: c.name,
                                        }))
                                }
                                style={{ width: 200 }}
                                placeholder="input here"
                            />
                        )}
                    </Form.Item>

                    {/* <Form.Item label="Places">
                              {getFieldDecorator("place_facebook", {
                                  initialValue: data.place_facebook
                              })(
                                  <Checkbox
                                        onChange={(e)=>this.handleCheckboxChange('place_facebook', e)}
                                      checked={data.place_facebook}
                                  >
                                      Facebook
                                  </Checkbox>
                              )}<br />
                              {getFieldDecorator("place_instagram", {
                                  initialValue: data.place_instagram
                              })(
                                  <Checkbox
                                        onChange={(e)=>this.handleCheckboxChange('place_instagram', e)}
                                      checked={data.place_instagram}
                                  >
                                      Instagram
                                  </Checkbox>
                              )}<br />
                              {getFieldDecorator("place_twitter", {
                                  initialValue: data.place_twitter
                              })(
                                  <Checkbox
                                  onChange={(e)=>this.handleCheckboxChange('place_twitter', e)}
                                      checked={data.place_twitter}
                                  >
                                      Twitter
                                  </Checkbox>
                              )}<br />
                              {getFieldDecorator("place_linkedin", {
                                  initialValue: data.place_linkedin
                              })(
                                  <Checkbox
                                  onChange={(e)=>this.handleCheckboxChange('place_linkedin', e)}
                                      checked={data.place_linkedin}
                                  >
                                      LinkedIn
                                  </Checkbox>
                              )}
                          </Form.Item> */}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class AuditorsListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "auditors_modal_add",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("auditors_modal_add");
    };

    onModalCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields(async (err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                social_notification("validation_error", "error");
                return;
            }

            // Patch the location
            /*
          values["location"] = `${values["location"][0]}/${
              values["location"][1]
          }`;
          */

            await this.props.store.addAuditor({
                ...values,
            });

            form.resetFields();
            this.onModalCancel();
        });
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            console.log("Received values of form: ", values);
            console.log("auditor key: ", this.props.data.key);

            await this.props.store.updateAuditor(this.props.data.key, values);

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "auditors_modal_add",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={
                            !data.name ? "Create new listener" : "Edit listener"
                        }
                        okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        onOk={
                            !data.name ? this.onModalCreate : this.onModalEdit
                        } //onApply
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AuditorsListModal);
