import React, { Component, Fragment } from "react";
import { Form, Modal, Input, Alert, Divider, Row, Col, Card, Icon } from "antd";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";
import names from "./../../names";

//import CampaignsListModal from "./CampaignsListModal";

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                name: "",
            };
        }

        //   componentDidMount() {
        //       // To disable submit button at the beginning.
        //       this.props.form.validateFields();
        //   }

        //   handleSubmit = e => {
        //       e.preventDefault();
        //       this.props.form.validateFields((err, values) => {
        //           if (!err) {
        //               console.log("Received values of form: ", values);
        //           }
        //       });
        //   };

        clickOnCard = async (_type, _evt) => {
            console.info("clickOnCard _type", _type);
            switch (_type) {
                case "existing_masterposts":
                    // open new campaign popup
                    this.props.store.showModalByKey("campaigns_modal", {
                        campaign: this.state.name,
                    });
                    // Hide current popup

                    this.props.store.hideModalByKey("campaigns_modal_new");

                    break;
                case "new_masterposts":
                    // open new campaign popup
                    this.props.store.showModalByKey("masterposts_modal_new", {
                        campaigns: [this.state.name],
                    });
                    // Hide current popup
                    this.props.store.hideModalByKey("campaigns_modal_new");

                    break;

                case "new_masterads":
                    // open new campaign popup
                    this.props.store.showModalByKey("ads_modal", {
                        campaigns: [this.state.name],
                    });
                    // Hide current popup
                    this.props.store.hideModalByKey("campaigns_modal_new");

                    break;

                default:
                    break;
            }
        };

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            return (
                <Form {...formItemLayout}>
                    <Alert
                        message={`Please enter the name of the new campaign and choose whether you want to create new ${names.get(
                            "MASTERPOST",
                            true
                        )} or master ads in it.`}
                        type="info"
                        style={{ border: 0 }}
                        showIcon
                    ></Alert>
                    <br />
                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the name of the campaign!",
                                    type: "string",
                                },
                            ],
                        })(
                            <Input
                                onChange={(e) =>
                                    this.setState({ name: e.target.value })
                                }
                            />
                        )}
                    </Form.Item>
                    <br />
                    <Form.Item
                        label={names.get("MASTERPOST", true)}
                    ></Form.Item>
                    <Row
                        gutter={20}
                        justify="center"
                        align="stretch"
                        type="flex"
                    >
                        <Col
                            style={{
                                cursor:
                                    this.state.name.length === 0
                                        ? "not-allowed"
                                        : null,
                            }}
                            span={12}
                        >
                            <Card
                                onClick={(e) =>
                                    this.clickOnCard("existing_masterposts", e)
                                }
                                disabled={this.state.name.length === 0}
                                hoverable
                                style={{
                                    textAlign: "left",
                                    backgroundColor:
                                        this.props.store.getTagColorForType(
                                            "campaign"
                                        ),
                                    color: "white",
                                    position: "relative",
                                    height: "100%",
                                }}
                            >
                                {/* <Icon
                                    type="container"
                                    style={{
                                        fontSize: "2.0em",
                                        marginBottom: 10,
                                        position: "absolute",
                                        left: 20,
                                        top: 30,
                                    }}
                                /> */}
                                <h3
                                    style={{
                                        color: "white",
                                        margin: 0,
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Existing {names.get("MASTERPOST", true)}
                                </h3>
                                <p style={{ margin: 0 }}>
                                    Click here if you want to create campaign
                                    with existing{" "}
                                    {names.get("MASTERPOST", true)}
                                </p>
                            </Card>
                        </Col>
                        <Col
                            span={12}
                            style={{
                                cursor:
                                    this.state.name.length === 0
                                        ? "not-allowed"
                                        : null,
                            }}
                        >
                            <Card
                                onClick={(e) =>
                                    this.clickOnCard("new_masterposts", e)
                                }
                                hoverable
                                disabled={this.state.name.length === 0}
                                style={{
                                    textAlign: "left",
                                    backgroundColor:
                                        this.props.store.getTagColorForType(
                                            "campaign"
                                        ),
                                    color: "white",
                                    position: "relative",
                                    height: "100%",
                                }}
                            >
                                {/* <Icon
                                    type="container"
                                    style={{
                                        fontSize: "2.0em",
                                        marginBottom: 10,
                                        position: "absolute",
                                        left: 20,
                                        top: 30,
                                    }}
                                /> */}
                                <h3
                                    style={{
                                        color: "white",
                                        margin: 0,
                                        textTransform: "uppercase",
                                    }}
                                >
                                    New {names.get("MASTERPOST", true)}
                                </h3>
                                <p style={{ margin: 0 }}>
                                    Click here if you want to create campaign
                                    and add new {names.get("MASTERPOST", true)}{" "}
                                    in it
                                </p>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Form.Item label="Master ads"></Form.Item>

                    <Row gutter={20}>
                        <Col
                            span={24}
                            style={{
                                cursor:
                                    this.state.name.length === 0
                                        ? "not-allowed"
                                        : null,
                            }}
                        >
                            <Card
                                hoverable
                                onClick={(e) =>
                                    this.clickOnCard("new_masterads", e)
                                }
                                disabled={this.state.name.length === 0}
                                style={{
                                    textAlign: "left",
                                    backgroundColor:
                                        this.props.store.getTagColorForType(
                                            "campaign"
                                        ),
                                    color: "white",
                                    position: "relative",
                                    height: "100%",
                                }}
                            >
                                {/* <Icon
                                    type="shop"
                                    style={{
                                        fontSize: "2.0em",
                                        marginBottom: 10,
                                        position: "absolute",
                                        left: 20,
                                        top: 30,
                                    }}
                                /> */}
                                <h3
                                    style={{
                                        color: "white",
                                        margin: 0,
                                        textTransform: "uppercase",
                                    }}
                                >
                                    New master ad
                                </h3>
                                <p style={{ margin: 0 }}>
                                    Click here if you want to create campaign
                                    and add new master ad in it
                                </p>
                            </Card>
                        </Col>
                    </Row>

                    {/* <Form.Item label="Brand">
                        {getFieldDecorator("brand", {
                            initialValue: data.brand,
                        })(
                            <AutoComplete
                                dataSource={this.props.store.getAllBrands}
                                style={{ width: 200 }}
                                placeholder="input here"
                            />
                        )}
                    </Form.Item> */}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class CampaignsListModalNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "campaigns_modal_new",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("campaigns_modal_new");
    };

    onModalCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                social_notification("validation_error", "error");
                return;
            }

            // Patch the location
            /*
          values["location"] = `${values["location"][0]}/${
              values["location"][1]
          }`;
          */

            this.props.store.addCompetitor({
                ...values,
                ...this.state.edit_data,
            });

            form.resetFields();
            this.onModalCancel();
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "campaigns_modal_new",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        //const { data } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Create new campaign"}
                        // okText={"Create"}
                        onCancel={this.onModalCancel}
                        //onOk={this.onModalCreate} //onApply
                        footer={null}
                        maskClosable={false}
                        width={550}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(CampaignsListModalNew);
