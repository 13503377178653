import React, { Fragment } from "react";
import { Icon } from "antd";
// import tooltips from "./../tooltips";

const SocialListColumn = ({
    sortkey,
    sortby,
    sortasc,
    handleSorting,
    children,
}) => {
    return (
        <Fragment>
            <span
                className={
                    sortby === sortkey
                        ? "social_sortlink social_selected"
                        : "social_sortlink"
                }
                onClick={(e) => handleSorting(sortkey)}
                style={{
                    color: sortby === sortkey ? "green" : "grey",
                }}
            >
                {children}
            </span>
            {/* {sortby === sortkey ? ( */}
            <Icon
                type={
                    sortby === sortkey
                        ? sortasc === true
                            ? "down"
                            : "up"
                        : "down"
                }
                style={{
                    marginLeft: 5,
                    color: sortby === sortkey ? "green" : "#ccc",
                    fontSize: 12,
                }}
            />
            {/* ) : null} */}
        </Fragment>
    );
};

export default SocialListColumn;
