import React, { Component, Fragment } from "react";
import {
    Typography,
    Row,
    Col,
    Icon,
    Tag,
    Tabs,
    Spin,
    Divider,
    Tooltip,
    Button,
} from "antd";

import { social_notification } from "./../../functions";
import tooltips from "../../tooltips";

import AccontsChannelInsights from "./AccountsChannelInsights";
import AccontsChannelPosts from "./AccountsChannelPosts";
// import AccontsChannelTargeting from "./AccountsChannelTargeting";
import AccountsChannelInfoFacebook from "./AccountsChannelInfoFacebook";
import AccountsChannelInfoInstagram from "./AccountsChannelInfoInstagram";
import AccountsChannelInfoTwitter from "./AccountsChannelInfoTwitter";
import AccountsChannelInfoLinkedin from "./AccountsChannelInfoLinkedin";
import AccountsChannelInfoYoutube from "./AccountsChannelInfoYoutube";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

class AccountChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountId: null,
            account: null,
            provider: null,
            channel_id: null,
            channel_info: null,
            channel_posts: null,
            channel_insights: null,
            currentTabKey: "info",
            refreshing: false,
            disabled_insights_tab: false,
            channel_open_link: null,
        };
    }

    UNSAFE_componentWillMount() {
        //this.props.store.setCurrentSection("settings");
        //var path = this.props.params.splat;
        //console.info("AccountsReport splat/path (passed parameter) = ", path);
        console.info("AccountChannel this.props ", this.props);
        this.setState({ accountId: this.props.match.params.id });
        this.setState({ provider: this.props.match.params.provider });
        this.setState({ channel_id: this.props.match.params.channel_id });
        //console.info("AccountsReport this.props ", this.props);

        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            { route: `/dashboard/accounts`, title: "Accounts" },
            {
                route: `/dashboard/accounts/${this.props.match.params.id}/channels`,
                title: "Channels",
            },
            { title: "Channel" },
        ]);
    }

    async componentDidMount() {
        //Loading the account
        console.info("componentDidMount this.state ", this.state);
        this.setState({ refreshing: true });

        await this.props.store
            .getAccount(this.props.match.params.id)
            .then((_account) => {
                this.setState({
                    account: _account,
                });
            });

        await this.loadChannelContents();
    }

    async loadChannelContents() {
        console.info("loadChannelContents");
        this.setState({ refreshing: true });

        await this.props.store
            .getAccountChannelContents(
                this.state.accountId,
                this.state.provider,
                this.state.channel_id
            )
            .then((_channelInfoData) => {
                let _channelInfo = {};
                if (this.state.provider === "facebook") {
                    _channelInfo = _channelInfoData.fbRes;
                    _channelInfo.avatar = _channelInfo.picture.data.url;
                    //_channelPosts = _channelInfoData.fbPagePosts;
                }
                if (this.state.provider === "instagram") {
                    _channelInfo = _channelInfoData;
                    _channelInfo.name = _channelInfoData.meta.name;
                    _channelInfo.avatar = _channelInfoData.meta
                        .profile_picture_url
                        ? _channelInfoData.meta.profile_picture_url
                        : "";
                    //_channelPosts = _channelInfoData.fbPagePosts;
                }
                if (this.state.provider === "twitter") {
                    _channelInfo = _channelInfoData.twitterUser;
                    _channelInfo.name =
                        _channelInfoData.twitterUser.screen_name +
                        ` (` +
                        _channelInfoData.twitterUser.name +
                        `)`;
                    _channelInfo.avatar =
                        _channelInfoData.twitterUser.profile_image_url.substring(
                            5
                        );
                }
                if (this.state.provider === "linkedin") {
                    _channelInfo = _channelInfoData;
                    _channelInfo.name = _channelInfoData.localizedFirstName
                        ? _channelInfoData.localizedFirstName +
                          " " +
                          _channelInfoData.localizedLastName
                        : _channelInfoData.localizedName;

                    _channelInfo.headline = _channelInfoData.localizedHeadline
                        ? _channelInfoData.localizedHeadline
                        : _channelInfoData.localizedWebsite;
                    _channelInfo.avatar = _channelInfo.profilePicture
                        ? _channelInfo.profilePicture["displayImage~"]
                              .elements[0].identifiers[0].identifier
                        : null;
                    //_channelPosts = _channelInfoData.fbPagePosts;
                }
                if (this.state.provider === "youtube") {
                    _channelInfo = _channelInfoData;
                    _channelInfo.name =
                        _channelInfoData.snippet.localized.title;
                    // _channelInfo.name = _channelInfoData.localizedFirstName
                    //     ? _channelInfoData.localizedFirstName +
                    //       " " +
                    //       _channelInfoData.localizedLastName
                    //     : _channelInfoData.localizedName;
                    //_channelPosts = _channelInfoData.fbPagePosts;
                }

                console.info("_channelInfo", _channelInfo);

                // Channel Open Link
                let channel_open_link;
                switch (this.state.provider) {
                    case "facebook":
                        channel_open_link = _channelInfo?.link;
                        break;
                    case "instagram":
                        channel_open_link = `https://www.instagram.com/${_channelInfo?.meta?.username}`;
                        break;
                    case "twitter":
                        channel_open_link = `https://twitter.com/${_channelInfo?.screen_name}`;
                        break;
                    case "linkedin":
                        if (!_channelInfo.localizedFirstName) {
                            channel_open_link = `https://www.linkedin.com/company/${_channelInfo?.id}/admin/`;
                        } else {
                            channel_open_link = `https://www.linkedin.com/in/${_channelInfo?.vanityName}/`;
                        }
                        break;
                    case "youtube":
                        channel_open_link = `https://studio.youtube.com/channel/${_channelInfo?.id}`;
                        break;
                    default:
                        break;
                }

                this.setState({
                    channel_info: _channelInfo,
                    //channel_posts: _channelPosts,
                    disabled_insights_tab: _channelInfo.localizedFirstName
                        ? true
                        : false,
                    refreshing: false,
                    channel_open_link,
                });
            })
            .catch((error) => {
                console.warn("ERROR !!! ", error);
                social_notification("get_account_channel_contents", "error", {
                    description: error.description,
                });
                this.setState({
                    channel_info: {
                        name: error.description,
                        id: this.state.channel_id,
                    },
                    refreshing: false,
                });
            });
    }

    async loadChannelPosts() {
        console.info("loadChannelPosts");
        this.setState({ refreshing: true });

        await this.props.store
            .getAccountChannelPosts(
                this.state.accountId,
                this.state.provider,
                this.state.channel_id
            )
            .then((_channelInfoData) => {
                console.info("_channelInfoData", _channelInfoData);
                let channelPosts = [];
                if (this.state.provider === "facebook") {
                    channelPosts = _channelInfoData.fbPagePosts;
                }
                if (this.state.provider === "instagram") {
                    channelPosts = _channelInfoData.igPosts;
                }
                if (this.state.provider === "twitter") {
                    channelPosts = _channelInfoData.twitterPosts;
                }
                if (this.state.provider === "linkedin") {
                    channelPosts = _channelInfoData.linkedinPosts.elements;
                }
                if (this.state.provider === "youtube") {
                    channelPosts = _channelInfoData.youtubeVideos;
                }

                console.info("channelPosts", channelPosts);

                this.setState({
                    channel_posts: channelPosts,
                    refreshing: false,
                });
            })
            .catch((error) => {
                console.warn(error);
                social_notification("get_account_channel_posts", "error", {
                    description: error.description,
                });
                this.setState({ channel_posts: [], refreshing: false });
            });
    }

    async loadChannelInsights() {
        console.info("loadChannelInsights");
        // if (this.state.provider === "twitter") return;
        this.setState({ refreshing: true });
        await this.props.store
            .getAccountChannelInsights(
                this.state.accountId,
                this.state.provider,
                this.state.channel_id
            )
            .then((_channelInsightsData) => {
                console.info("_channelInsightsData", _channelInsightsData);

                let channelInsights = [];
                if (this.state.provider === "facebook") {
                    channelInsights = _channelInsightsData.fbRes.data;
                }
                if (this.state.provider === "instagram") {
                    channelInsights = _channelInsightsData.igRes.data;
                }
                if (this.state.provider === "twitter") {
                    channelInsights = {
                        data: _channelInsightsData.data,
                        includes: _channelInsightsData.includes,
                    };
                }
                if (this.state.provider === "linkedin") {
                    channelInsights = {
                        shares_stats: _channelInsightsData.shares_stats,
                        stats: _channelInsightsData.stats,
                    };
                }
                if (this.state.provider === "youtube") {
                    channelInsights = _channelInsightsData;
                }
                console.info("channelInsights", channelInsights);

                this.setState({
                    channel_insights: channelInsights,
                    refreshing: false,
                });
            })
            .catch((error) => {
                console.warn(error);
                social_notification("get_account_channel_insights", "error", {
                    description: error.description,
                });
                this.setState({
                    // channel_insights: { id: this.state.channel_id },
                    refreshing: false,
                    currentTabKey: "info", // return to the info tab
                });
            });
    }

    callback = (key) => {
        console.log("callback key=" + key);
        switch (key) {
            case "info":
                if (!this.state.channel_info) this.loadChannelContents();
                break;
            case "posts":
                if (!this.state.channel_posts) this.loadChannelPosts();
                break;
            case "insights":
                if (!this.state.channel_insights) this.loadChannelInsights();
                break;
            default:
                break;
        }
        this.setState({ currentTabKey: key });
    };

    handleReload = (_evt) => {
        switch (this.state.currentTabKey) {
            case "info":
                this.setState({
                    channel_info: null,
                    refreshing: true,
                });
                this.loadChannelContents();
                break;
            case "posts":
                this.setState({
                    channel_posts: null,
                    refreshing: true,
                });
                this.loadChannelPosts();
                break;
            case "insights":
                this.setState({
                    channel_insights: null,
                    refreshing: true,
                });
                this.loadChannelInsights();
                break;
            default:
                break;
        }
    };

    handleOpenChannel = (_evt) => {
        this.state.channel_open_link &&
            window.open(this.state.channel_open_link);
    };

    render() {
        let channel_info_tab = (
            <Spin
                size="large"
                tip="Loading..."
                indicator={
                    <Icon
                        type="loading"
                        style={{ fontSize: 24, margin: 20 }}
                        spin
                    />
                }
            />
        );
        if (this.state.channel_info != null) {
            if (this.state.provider === "facebook") {
                channel_info_tab = (
                    <AccountsChannelInfoFacebook
                        channel_info={this.state.channel_info}
                    />
                );
            }
            if (this.state.provider === "instagram") {
                channel_info_tab = (
                    <AccountsChannelInfoInstagram
                        channel_info={this.state.channel_info}
                    />
                );
            }
            if (this.state.provider === "twitter") {
                channel_info_tab = (
                    <AccountsChannelInfoTwitter
                        channel_info={this.state.channel_info}
                    />
                );
            }
            if (this.state.provider === "linkedin") {
                channel_info_tab = (
                    <AccountsChannelInfoLinkedin
                        channel_info={this.state.channel_info}
                    />
                );
            }
            if (this.state.provider === "youtube") {
                channel_info_tab = (
                    <AccountsChannelInfoYoutube
                        channel_info={this.state.channel_info}
                    />
                );
            }
        }

        let header = (
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 10 }}
            >
                <Col>
                    <Title level={3} style={{ float: "left", paddingRight: 5 }}>
                        <Tag
                            color={this.props.store.getTagColorForType(
                                this.state.provider
                            )}
                        >
                            <Icon
                                type={this.state.provider}
                                theme={
                                    this.state.provider !== "twitter"
                                        ? "filled"
                                        : null
                                }
                                style={{
                                    marginRight: 5,
                                }}
                            />
                            {this.state.channel_info != null &&
                                this.state.channel_info.name}
                            {!this.state.channel_info && "Loading..."}
                        </Tag>
                    </Title>

                    {/* <Badge
                        count={12}
                        showZero
                        style={{ backgroundColor: "#1890ff" }}
                    /> */}
                </Col>
                <Col>
                    <Button
                        type="default"
                        size="small"
                        icon="select"
                        style={{
                            marginRight: 10,
                        }}
                        disabled={!this.state.channel_open_link}
                        onClick={this.handleOpenChannel}
                    >
                        Open Channel
                    </Button>
                    <Tooltip
                        title={tooltips.ACCOUNTS.CHANNEL_REFRESHBUTTON}
                        mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                    >
                        <Button
                            type="primary"
                            icon={this.state.refreshing ? "loading" : "sync"}
                            disabled={this.state.refreshing}
                            onClick={this.handleReload}
                        >
                            Refresh
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
        );

        return (
            <Fragment>
                {header}
                <Tabs
                    defaultActiveKey="info"
                    activeKey={this.state.currentTabKey}
                    onChange={this.callback}
                >
                    <TabPane
                        tab="Info"
                        key="info"
                        style={{
                            height: "calc(100vh - 320px)",
                            overflowY: "auto",
                        }}
                    >
                        {channel_info_tab}
                    </TabPane>
                    <TabPane
                        tab="Posts"
                        key="posts"
                        style={{
                            height: "calc(100vh - 320px)",
                            overflowY: "auto",
                        }}
                    >
                        <AccontsChannelPosts
                            {...this.props}
                            channel_posts={this.state.channel_posts}
                            channel_avatar={
                                this.state.channel_info &&
                                this.state.channel_info.avatar
                            }
                            channel_name={
                                this.state.channel_info &&
                                this.state.channel_info.name
                            }
                            channel_id={
                                this.state.channel_info &&
                                this.state.channel_info.id
                                    ? this.state.channel_info.id
                                    : this.state.channel_id
                            }
                            channel_headline={
                                this.state.channel_info &&
                                this.state.channel_info.headline
                                    ? this.state.channel_info.headline
                                    : null
                            }
                            provider={this.state.provider}
                            accountId={this.state.accountId}
                        />
                    </TabPane>
                    <TabPane
                        tab="Insights"
                        key="insights"
                        disabled={this.state.disabled_insights_tab}
                    >
                        <AccontsChannelInsights
                            {...this.props}
                            channel_insights={this.state.channel_insights}
                            provider={this.state.provider}
                        />
                    </TabPane>
                    {/* <TabPane tab="Targeting" key="targeting">
                        <AccontsChannelTargeting
                            {...this.props}
                            provider={this.state.provider}
                            accountId={this.state.accountId}
                            channel_id={this.state.channel_id}
                        />
                    </TabPane> */}
                </Tabs>
            </Fragment>
        );
    }
}

export default AccountChannel;
