/**
 * Config file for storing URLs and other settings the application needs
 *
 * @version 0.0.1
 * @author [Martin D.](https://github.com/marDonchev)
 *
 * @class config
 * * @visibleName Simple config class
 */

const dev = {
    // Section for backend locale information
    api: {
        URLprefix:
            "http://localhost:5000/social-6ce6c/us-central1/webApi/api/v1/",
        unauthorizedURL: "//localhost:3000/logout",
    },
    facebook: {
        client_id: "327714811504706", //"1109630732573481",
        client_secret: "5b1c31737e263d87b5ba96c3e9c46ff4", //"8c1fa98431b3487bf042d82322e8ffe0"
        scope: "manage_pages,publish_pages,read_insights,instagram_basic,instagram_manage_comments,pages_show_list,ads_management,pages_read_engagement,pages_messaging,instagram_manage_insights,ads_read,instagram_content_publish", //instagram_graph_user_media
    },
    google: {
        client_id:
            "480412764246-31gmm9hojlhvaa68pskfomru93ktr1uh.apps.googleusercontent.com",
        client_secret: "clHMeqBDGbf7nOjeNaxJK7uT",
        scope: [
            "https://www.googleapis.com/auth/userinfo.email",
            "https://www.googleapis.com/auth/userinfo.profile",
            "https://www.googleapis.com/auth/youtube",
        ],
    },
    firebase: {
        apiKey: "AIzaSyAjFWswQYS4GaRtigP-3xFo1KP5-DGVUuw",
        authDomain: "social-6ce6c.firebaseapp.com",
        databaseURL: "http://localhost:9000/?ns=social-6ce6c",
        projectId: "social-6ce6c",
        storageBucket: "social-6ce6c.appspot.com",
        messagingSenderId: "480412764246",
        appId: "1:480412764246:web:ad0c952eb262c582",
    },
    linkedin: {
        clientId: "77p0n0wcvgehoz",
        clientSecret: "zpOAQfDxzpe29hBy",
        scopes: [
            "r_liteprofile",
            "r_emailaddress",
            "w_member_social",
            "rw_organization_admin",
            "w_organization_social",
            "r_organization_social",
            "rw_ads",
            "r_ads",
            "r_ads_reporting",
            "r_1st_connections_size",
            "r_basicprofile",
        ],
    },
    youtube: {
        apiKey: "AIzaSyDJTMokuaR1nOFfFFMvZ1qS95FFt42xRrQ",
    },
    cryptoJsSecret: "ThisIsMyCryptoJsSecret",
};

const prod = {
    // Section for backend locale information
    api: {
        URLprefix:
            "https://us-central1-social-6ce6c.cloudfunctions.net/webApi/api/v1/",
        unauthorizedURL: "//social-6ce6c.firebaseapp.com/logout",
    },
    facebook: {
        client_id: "327714811504706", //"1109630732573481",
        client_secret: "5b1c31737e263d87b5ba96c3e9c46ff4", //"8c1fa98431b3487bf042d82322e8ffe0"
        scope:
            // "manage_pages,publish_pages,read_insights,instagram_basic,instagram_manage_comments,pages_show_list,ads_management,pages_read_engagement,pages_messaging,instagram_manage_insights,ads_read,instagram_content_publish,instagram_graph_user_media",
            "manage_pages,publish_pages,read_insights,instagram_basic,instagram_manage_comments,pages_show_list,ads_management,pages_read_engagement,pages_messaging,instagram_manage_insights,ads_read,instagram_content_publish",
    },
    // google: {
    //     client_id:
    //         "933923983594-l9bcjt1gvnor36kbnegecd61if9lqeli.apps.googleusercontent.com",
    //     client_secret: "e2fce988f8cd29d62896ba9f685aeb0d",
    // },
    google: {
        client_id:
            "480412764246-31gmm9hojlhvaa68pskfomru93ktr1uh.apps.googleusercontent.com",
        client_secret: "clHMeqBDGbf7nOjeNaxJK7uT",
        scope: [
            "https://www.googleapis.com/auth/userinfo.email",
            "https://www.googleapis.com/auth/userinfo.profile",
            "https://www.googleapis.com/auth/youtube",
        ],
    },
    firebase: {
        apiKey: "AIzaSyAjFWswQYS4GaRtigP-3xFo1KP5-DGVUuw",
        authDomain: "social-6ce6c.firebaseapp.com",
        databaseURL: "https://social-6ce6c.firebaseio.com",
        projectId: "social-6ce6c",
        storageBucket: "social-6ce6c.appspot.com",
        messagingSenderId: "480412764246",
        appId: "1:480412764246:web:ad0c952eb262c582",
    },
    linkedin: {
        clientId: "77p0n0wcvgehoz",
        clientSecret: "zpOAQfDxzpe29hBy",
        scopes: [
            "r_liteprofile",
            "r_emailaddress",
            "w_member_social",
            "rw_organization_admin",
            "w_organization_social",
            "r_organization_social",
            "rw_ads",
            "r_ads",
            "r_ads_reporting",
            "r_1st_connections_size",
            "r_basicprofile",
            // "r_member_social",
            //"r_fullprofile"
        ],
    },
    cryptoJsSecret: "ThisIsMyCryptoJsSecret",
};

// Usage of the proper config object based on the env variable (REACT_APP_STAGE)
const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
    // Common settings for both dev and prod configs

    // Delay before updating the device after the ArcSlider has been used
    sliderUpdateDelay: 1500,

    PAGINATION_PERPAGE: 100,

    notifications: {
        types: {
            error: "error",
            message: "message",
        },
        recipients: {
            all: "@all",
            admin: "@admin",
        },
        events: [
            {
                name: "account_created",
                type: "success", // error/warning
                title: "Done",
                description: "This is a notification.",
            },
            {
                name: "account_created",
                type: "error", // error/warning
                title: "Error",
                description: "Something went wrong.",
            },
            // real events
            {
                name: "validation_error",
                type: "error",
                title: "Validation Error",
                description: "Please fix the validation errors and try again.",
            },
            {
                name: "account_subscription_update",
                type: "success", // error/warning
                title: "Done",
                description: "Your subscription was properly updated.",
            },
            {
                name: "account_subscription_update",
                type: "error", // error/warning
                title: "Error",
                description: "Something went wrong updating your subscription.",
            },
            {
                name: "account_get_channels",
                type: "error", // error/warning
                title: "Error",
                description: "Something went wrong gathering your channels.",
            },
            {
                name: "account_reload_channels",
                type: "error", // error/warning
                title: "Error",
                description: "Something went wrong gathering your channels.",
            },
            {
                name: "account_unlink_channels",
                type: "error", // error/warning
                title: "Error",
                description: "Something went wrong unlinking your channels.",
            },
            {
                name: "account_test_channel",
                type: "success", // error/warning
                title: "Done",
                description: "The test went successfully.",
            },
            {
                name: "account_add",
                type: "error", // error/warning
                title: "Error",
                description: "Something went wrong unlinking your channels.",
            },
            {
                name: "account_add",
                type: "success", // error/warning
                title: "Successful import",
                description:
                    "Account and Facebook Pages were properly imported",
            },
            {
                name: "compose_publish",
                type: "error", // error/warning
                title: "Error",
                description:
                    "Please fix the errors on the form and then click the `Publish` button",
            },
            {
                name: "compose_publish",
                type: "info", // error/warning
                title: "Publish Progress",
                description:
                    "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
            },
            {
                name: "publish_comment_on_channel_post",
                type: "error", // error/warning
                title: "Error",
                description: "Error posting the comment",
            },
            {
                name: "get_account_channel_contents",
                type: "error", // error/warning
                title: "Error",
                description: "Error fetching the information",
            },
            {
                name: "get_account_channel_posts",
                type: "error", // error/warning
                title: "Error",
                description: "Error fetching the information",
            },
            {
                name: "get_account_channel_insights",
                type: "error", // error/warning
                title: "Error",
                description: "Error fetching the information",
            },
            {
                name: "update_account_queue_post",
                type: "error", // error/warning
                title: "Error",
                description: "Error updating the post information",
            },
            {
                name: "update_account_queue_post",
                type: "success", // error/warning
                title: "Done",
                description: "The post was updated successfully",
            },
            {
                name: "approve_account_queue_post",
                type: "success", // error/warning
                title: "Done",
                description:
                    "The post was approved and will be published accordingly.",
            },
            {
                name: "post_queue_post_by_id_and_channels_channels",
                type: "success", // error/warning
                title: "Done",
                description: "The post has been published successfully",
            },
            {
                name: "post_queue_post_by_id_and_channels_channels",
                type: "error", // error/warning
                title: "Error",
                description: "Error publishing the post",
            },
            {
                name: "publish_ad",
                type: "success", // error/warning
                title: "Done",
                description: "You have created the ad successfully.",
            },
            {
                name: "add_library_file",
                type: "error", // error/warning
                title: "Error",
                description: "Error fetching the information.",
            },
            {
                name: "update_library_file",
                type: "error", // error/warning
                title: "Error",
                description: "Error fetching the information.",
            },
            {
                name: "delete_library_file",
                type: "error", // error/warning
                title: "Error",
                description: "Error fetching the information.",
            },
            {
                name: "batch_create_masterpost_with_targets",
                type: "success", // error/warning
                title: "Done",
                description:
                    "The primary post and the targets are created successfully.",
            },
            {
                name: "batch_create_masterpost_with_targets",
                type: "error", // error/warning
                title: "Error",
                description: "Error creating the primary posts with targets.",
            },
            {
                name: "batch_update_masterpost_with_targets",
                type: "success", // error/warning
                title: "Done",
                description:
                    "The primary post and the targets are updated successfully.",
            },
            {
                name: "batch_update_masterpost_with_targets",
                type: "error", // error/warning
                title: "Error",
                description: "Error updating the primary posts with targets.",
            },
            {
                name: "delete_ad",
                type: "error", // error/warning
                title: "Error",
                description: "Error deleting the ad.",
            },
            {
                name: "delete_ad",
                type: "success", // error/warning
                title: "Success",
                description: "The ad was successfully deleted.",
            },
        ],
    },

    FACEBOOK_ADS_OBJECTIVE_OPTIONS: [
        "APP_INSTALLS",
        "BRAND_AWARENESS",
        "CONVERSIONS",
        "EVENT_RESPONSES",
        "LEAD_GENERATION",
        "LINK_CLICKS",
        "LOCAL_AWARENESS",
        "MESSAGES",
        "OFFER_CLAIMS",
        "PAGE_LIKES",
        "POST_ENGAGEMENT",
        "PRODUCT_CATALOG_SALES",
        "REACH",
        "VIDEO_VIEWS",
    ],
    FACEBOOK_ADS_OPTIMIZATION_GOAL_OPTIONS: [
        "APP_INSTALLS",
        "AD_RECALL_LIFT",
        "ENGAGED_USERS",
        "EVENT_RESPONSES",
        "IMPRESSIONS",
        "LEAD_GENERATION",
        "LINK_CLICKS",
        "OFFSITE_CONVERSIONS",
        "PAGE_LIKES",
        "POST_ENGAGEMENT",
        "REACH",
        "REPLIES",
        "SOCIAL_IMPRESSIONS",
        "THRUPLAY",
        "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS",
        "VALUE",
        "LANDING_PAGE_VIEWS",
    ],
    FACEBOOK_ADS_BILLING_EVENTS_OPTIONS: [
        "IMPRESSIONS",
        "LINK_CLICKS",
        "APP_INSTALLS",
        "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS",
    ],

    FACEBOOK_ADS_CALLTOACTION_TYPES: [
        "OPEN_LINK",
        "LIKE_PAGE",
        "SHOP_NOW",
        "PLAY_GAME",
        "INSTALL_APP",
        "USE_APP",
        "CALL",
        "CALL_ME",
        "INSTALL_MOBILE_APP",
        "USE_MOBILE_APP",
        "MOBILE_DOWNLOAD",
        "BOOK_TRAVEL",
        "LISTEN_MUSIC",
        "WATCH_VIDEO",
        "LEARN_MORE",
        "SIGN_UP",
        "DOWNLOAD",
        "WATCH_MORE",
        "NO_BUTTON",
        "VISIT_PAGES_FEED",
        "APPLY_NOW",
        "CONTACT",
        "BUY_NOW",
        "GET_OFFER",
        "GET_OFFER_VIEW",
        "BUY_TICKETS",
        "UPDATE_APP",
        "GET_DIRECTIONS",
        "BUY",
        "MESSAGE_PAGE",
        "DONATE",
        "SUBSCRIBE",
        "SAY_THANKS",
        "SELL_NOW",
        "SHARE",
        "DONATE_NOW",
        "GET_QUOTE",
        "CONTACT_US",
        "ORDER_NOW",
        "ADD_TO_CART",
        "VIDEO_ANNOTATION",
        "MOMENTS",
        "RECORD_NOW",
        "GET_SHOWTIMES",
        "LISTEN_NOW",
        "WOODHENGE_SUPPORT",
        "SOTTO_SUBSCRIBE",
        "EVENT_RSVP",
        "WHATSAPP_MESSAGE",
        "FOLLOW_NEWS_STORYLINE",
        "SEE_MORE",
        "FIND_A_GROUP",
        "FIND_YOUR_GROUPS",
    ],
    FACEBOOK_ADS_STATUS_OPTIONS: ["ACTIVE", "PAUSED"],
    // GENERAL CONFIGS
    twitter: {
        customerKey: "77mQwQ65y38lm84yCbw7MDNML",
        customerSecret: "FqYeDpEhUEKokKlF7uk1pBuMHaoYCpPDEq0yFINHUsb1t5I8GL",
    },
    stripe: {
        apiKey: "pk_test_O2VDsJEKRisM1LMATtAbQ5WL00y5dOu5Ix",
    },
    worldPlans: [
        { key: "wp_Basic", name: "Basic", maxAccounts: 5, amount: 5 },
        { key: "wp_Advanced", name: "Advanced", maxAccounts: 10, amount: 10 },
        { key: "wp_Max", name: "Max", maxAccounts: 50, amount: 50 },
    ],

    ...config,
};
// TODO: Adding some constants here (like statuses = upcoming,processing,posted etc.)
