import React, { Fragment } from "react";
import { Form, Input } from "antd";
import FilePicker from "../FilePicker";
const { TextArea } = Input;

// class AdsListModalFormPanelBasic extends Component {
//     constructor(props) {
//         super(props);
//         this.state = { ...props.state };
//     }
//     render() {
//         return <pre>{JSON.stringify(this.state, null, 2)}</pre>;
//     }
// }

// export default AdsListModalFormPanelBasic;
const AdsListModalFormPanelBasic = (props, context) => {
    const { form, state } = props;
    const { getFieldDecorator } = form;

    return (
        <Fragment>
            <div>
                <Form.Item label="Ad Title" required>
                    {getFieldDecorator("newad_title", {
                        initialValue: state.newad_title
                            ? state.newad_title
                            : null,
                        onChange: (e) =>
                            props.onSetState({
                                newad_title: e.target.value,
                            }),
                    })(<Input maxLength={25} />)}
                </Form.Item>
                <Form.Item label="Ad Copy" required>
                    {getFieldDecorator("newad_body", {
                        rules: [
                            {
                                required: true,
                                message: "Please input the copy of the ad!",
                                type: "string",
                            },
                        ],
                        initialValue: state.newad_body,
                        onChange: (e) =>
                            props.onSetState({
                                newad_body: e.target.value,
                            }),
                    })(<TextArea />)}
                </Form.Item>

                <Form.Item label="Ad URL" required>
                    {getFieldDecorator("newad_url", {
                        initialValue: state.newad_url ? state.newad_url : null,
                        onChange: (e) =>
                            props.onSetState({
                                newad_url: e.target.value,
                            }),
                    })(<Input maxLength={1024} />)}
                </Form.Item>
                <Form.Item label="Ad Image" required>
                    <FilePicker
                        store={props.store}
                        restrictions={{ width: 1080, height: 1080 }}
                        // nopreview
                        onChange={(file) =>
                            props.onHandleFilePickerChange("singlead", file)
                        }
                    />
                </Form.Item>
            </div>
        </Fragment>
    );
};

export default AdsListModalFormPanelBasic;
