import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// import welcome from "./../welcome.png";
import PrivacyModal from "./PrivacyModal";
import TermsModal from "./TermsModal";
import { social_reset_css } from "./../functions";

import { Button } from "antd";

class Welcome extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            error_message: null,
        };
    }

    componentDidMount() {
        // reseting any skin/theme from the app
        social_reset_css();
    }

    handleLogin = () => {
        this.setState({ redirect: "/login" });
    };
    handleRegister = () => {
        this.setState({ redirect: "/register" });
    };

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirect} />;
        }

        return (
            <div className={"Welcome"}>
                <div className={"Header"}>
                    <div className={"Logo"}></div>
                    <div>
                        <Button
                            className="login-form-button"
                            onClick={() => {
                                this.handleRegister();
                            }}
                        >
                            Sign up
                        </Button>
                        <Button
                            className="login-form-button"
                            onClick={() => {
                                this.handleLogin();
                            }}
                        >
                            Log-in
                        </Button>
                    </div>
                </div>

                <div className={"Container"}>
                    <div className={"Limiter"}>
                        <div className={"Box"}>
                            <ul>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                            </ul>
                            <h1>
                                Welcome to Uhub.app
                                <br />
                                Social Media Program!
                            </h1>
                            <p>
                                Join now to turbocharge your social media
                                stream! Leverage BioTE’s expertise-driven
                                content focused on consumer education, personal
                                experiences, and services and product
                                information. Drive increased awareness, reach
                                and engagement and build your practice’s local
                                audience.
                                <br />
                                <br />
                                BioTE delivers a strong strategy and brand
                                platform to help you reach users interested in
                                the hormone replacement therapy services offered
                                by your practice. BioTE derived content will be
                                customized appropriately for your social feed to
                                provide regular, relevant and visually
                                consistent messaging stream. By providing
                                relevant information and educating those who may
                                benefit from hormone replacement therapy, the
                                program will help enhance your digital footprint
                                and increase your regional market visibility.
                                All BioTE-provided content is vetted for HIPPA
                                compliance.
                                <br />
                                <br />
                                You will have access to monthly reports with key
                                metrics.
                                <br />
                                <br />
                                Click on the Enroll button to choose the plan
                                which best suits your marketing strategy.
                                <br />
                                <Button
                                    className="register-form-button"
                                    onClick={() => {
                                        this.handleRegister();
                                    }}
                                >
                                    Sign up
                                </Button>
                            </p>
                        </div>
                    </div>
                </div>

                <div className={"Footer"}>
                    <div className={"Limiter"}>
                        <PrivacyModal store={this.props.store} />
                        <TermsModal store={this.props.store} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Welcome;
