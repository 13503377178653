import React, { Component, Fragment } from "react";
import { Icon, Divider, Row, Col, Input, Button } from "antd";
import moment from "moment";
import { social_notification } from "./../functions";
const { TextArea } = Input;
const instagramStyles = {
    background: "#e9ebee",
    padding: 10,
    //     border: "1px solid red"
};
const instagramStylesCard = {
    background: "#fff",
    border: "1px solid #e6e6e6",
    borderRadius: 3,
    marginBottom: 60,
    marginLeft: -1,
    marginRight: -1,
    position: "relative",
    maxWidth: 500,
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    boxSizing: "border-box",
};
const instagramCardHeader = {
    height: 60,
    padding: 16,
    flexAlign: "center",
    alignItems: "center",
};
const instagramCardHeaderAvatar = {
    width: 42,
    height: 42,
    top: 9,
    left: 9,
    position: "absolute",
    backgroundColor: "#ddd",
    borderRadius: "100%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
const instagramCardHeaderAuthor = {
    top: 18,
    left: 63,
    position: "absolute",
    color: "#262626",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
};
const instagramCardBody = {};
const instagramCardHeaderDateTime = {
    position: "absolute",
    top: 11,
    right: 9,
    color: "#999",
    fontWeight: 100,
    width: 170,
    textAlign: "right",
    fontSize: "0.8rem",
};
const instagramCardInfo = {
    lineHeight: 1.5,
    margin: 10,
    color: "#262625",
    position: "relative",
    paddingLeft: 30,
};
const instagramCommentsHeader = {
    padding: 10,
    color: "#999",
    borderTop: "1px solid #ccc",
    marginTop: 3,
};
const instagramComments = {
    padding: 10,
    color: "#262625",
};
const instagramCommentAuthor = {
    color: "#666",
    fontWeight: 600,
};
const instagramCommentDate = {
    color: "#999",
    fontWeight: 100,
};
const instagramCommentText = {
    color: "#262626",
    fontWeight: 600,
};
const instagramCardCaption = {
    padding: 10,
    color: "white",
    marginTop: -41,
    background: "rgba(0,0,0,0.5)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    //     borderTop: "1px solid #ccc",
};
// Inspiring crates delivered every month for kids of all
//                     ages✨. Get 50% off your first month with promo code:
//                     Weekend50

class SocialInstagramPost extends Component {
    state = {
        post: null,
        author: "",
        avatar: "",
        insights: null,
        comments: [],
    };

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.setState({
            post: this.props.post,
            author: this.props.author,
            avatar: this.props.avatar,
        });
        //this.parseInsights();
        this.parseComments();
    }

    parseComments = (_new_comments) => {
        console.info("parseComments _new_comments", _new_comments);
        let comments = [];

        const comments_source = _new_comments
            ? _new_comments
            : this.props.post.comments && this.props.post.comments.data
            ? this.props.post.comments.data
            : [];

        const sorted_comments = comments_source
            .sort((a, b) => {
                return (
                    new Date(b.created_time).getTime() -
                    new Date(a.created_time).getTime()
                );
            })
            .reverse();
        //console.info("parseComments sorted_comments", sorted_comments);
        sorted_comments.map((c) => {
            comments.push({
                created_time: c.timestamp,
                from: c.id,
                message: c.text,
            });
            return false;
        });
        console.info("parseComments comments", comments);
        this.setState({ comments });
    };

    postComment = () => {
        this.setState({ posting: true });

        //console.info("this.props", this.props);

        this.props.store
            .publishCommentOnChannelPost(
                this.props.accountId,
                this.props.provider,
                this.props.channel_id,
                this.props.post.id,
                this.state.new_comment
            )
            .then((postResults) => {
                console.info("postResults", postResults);
                // update the comments

                this.parseComments(postResults.comments.data);

                this.setState({ posting: false, new_comment: "" });
            })
            .catch((error) => {
                console.warn("ERROR !!!!");
                social_notification(
                    "publish_comment_on_channel_post",
                    "error",
                    {
                        description: error,
                    }
                );
            });
    };

    render() {
        //const content = this.parseContent();
        //console.info("Instagram this.props", this.props);
        return (
            <Fragment>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.post && this.state.post.permalink_url}
                >
                    <div style={instagramStyles}>
                        <div style={instagramStylesCard}>
                            <div style={instagramCardHeader}>
                                <div
                                    style={{
                                        ...instagramCardHeaderAvatar,
                                        backgroundImage: `url(${this.state.avatar})`,
                                    }}
                                />
                                <div style={instagramCardHeaderAuthor}>
                                    {this.props.author}
                                </div>
                                <div style={instagramCardHeaderDateTime}>
                                    {this.state.post
                                        ? moment(this.state.post.timestamp)
                                              .utc()
                                              .local()
                                              .format("LLLL")
                                        : null}
                                </div>
                            </div>
                            <div style={instagramCardBody}>
                                <img
                                    style={{ width: "100%" }}
                                    src={
                                        this.state.post &&
                                        this.state.post.media_url
                                    }
                                    alt=""
                                />
                            </div>
                            <div style={instagramCardCaption}>
                                {this.state.post
                                    ? this.state.post.caption
                                    : null}
                            </div>
                            <div style={instagramCardInfo}>
                                <Icon
                                    type="heart"
                                    theme="filled"
                                    style={{
                                        fontSize: "1.5rem",
                                        position: "absolute",
                                        left: 0,
                                    }}
                                />
                                {this.state.post
                                    ? this.state.post.like_count
                                    : 0}{" "}
                                likes
                            </div>
                            <div style={instagramCommentsHeader}>
                                Comments{" "}
                                {this.state.post
                                    ? "(" + this.state.comments.length + ")"
                                    : null}
                            </div>
                            <div style={instagramComments}>
                                {this.state.post
                                    ? this.state.comments.map((c, cIndex) => {
                                          return (
                                              <Fragment key={cIndex}>
                                                  <div
                                                      style={
                                                          instagramCommentAuthor
                                                      }
                                                  >
                                                      {c.from}
                                                  </div>
                                                  <div
                                                      style={
                                                          instagramCommentDate
                                                      }
                                                  >
                                                      {moment(c.created_time)
                                                          .utc()
                                                          .local()
                                                          .format("LLLL")}
                                                  </div>
                                                  <div
                                                      style={
                                                          instagramCommentText
                                                      }
                                                  >
                                                      {c.message}
                                                  </div>

                                                  <Divider />
                                              </Fragment>
                                          );
                                      })
                                    : null}
                            </div>
                            <Row
                                type="flex"
                                justify="space-around"
                                style={{
                                    borderTop: "1px solid #eee",
                                    // marginTop: 10,
                                    // paddingTop: 10,
                                    padding: 10,
                                }}
                            >
                                <Col span={19}>
                                    <TextArea
                                        disabled={this.state.posting}
                                        placeholder="Your comment"
                                        value={this.state.new_comment}
                                        autoSize
                                        onChange={(e) =>
                                            this.setState({
                                                new_comment: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col span={5} style={{ textAlign: "right" }}>
                                    <Button
                                        onClick={this.postComment}
                                        loading={this.state.posting}
                                        disabled={this.state.posting}
                                    >
                                        Post
                                    </Button>
                                </Col>
                            </Row>
                            {/* <pre>
                                this.state.post
                                {JSON.stringify(this.state.post, null, 2)}
                            </pre> */}
                            {this.props.children}
                        </div>
                    </div>
                </a>
            </Fragment>
        );
    }
}

export default SocialInstagramPost;
