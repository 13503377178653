import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import CategoriesList from "./CategoriesList";
import CategoriesReport from "./CategoriesReport";

import { observer } from "mobx-react";

class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("categories");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Categories" }]);
    }

    render() {
        return (
            <Switch>
                <Route
                    path="/dashboard/categories/:id/report"
                    render={props => (
                        <CategoriesReport store={this.props.store} {...props} />
                    )}
                />
                <Route
                    render={props => (
                        <CategoriesList store={this.props.store} {...props} />
                    )}
                />
            </Switch>
        );
    }
}

export default observer(Categories);
