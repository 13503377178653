import React, { Component, Fragment } from "react";

import { Spin, Icon } from "antd";

import SocialFacebookPost from "./../SocialFacebookPost";
import SocialInstagramPost from "./../SocialInstagramPost";
import SocialTwitterPost from "./../SocialTwitterPost";
import SocialLinkedinPost from "./../SocialLinkedinPost";
import SocialYoutubePost from "./../SocialYoutubePost";

class AccountsChannelPosts extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        console.info("AccontsChannelPosts this.props", this.props);
        return (
            <Fragment>
                {!this.props.channel_posts ? (
                    <Spin
                        size="large"
                        tip="Loading..."
                        indicator={
                            <Icon
                                type="loading"
                                style={{ fontSize: 24, margin: 20 }}
                                spin
                            />
                        }
                    />
                ) : (
                    this.props.channel_posts.map((p, pindex) => {
                        return (
                            <Fragment key={pindex}>
                                {this.props.provider === "facebook" &&
                                (p.message || p.story) ? (
                                    <SocialFacebookPost
                                        avatar={this.props.channel_avatar}
                                        author={this.props.channel_name}
                                        post={p}
                                        store={this.props.store}
                                        provider={this.props.provider}
                                        channel_id={this.props.channel_id}
                                        accountId={this.props.accountId}
                                    />
                                ) : null}

                                {this.props.provider === "instagram" ? (
                                    <SocialInstagramPost
                                        avatar={this.props.channel_avatar}
                                        author={this.props.channel_name}
                                        provider={this.props.provider}
                                        channel_id={this.props.channel_id}
                                        accountId={this.props.accountId}
                                        post={p}
                                        store={this.props.store}
                                    />
                                ) : null}

                                {this.props.provider === "twitter" ? (
                                    <SocialTwitterPost
                                        avatar={this.props.channel_avatar}
                                        author={this.props.channel_name}
                                        author_email={this.props.channel_email}
                                        post={p}
                                        store={this.props.store}
                                    />
                                ) : null}
                                {this.props.provider === "linkedin" ? (
                                    <SocialLinkedinPost
                                        avatar={this.props.channel_avatar}
                                        author={this.props.channel_name}
                                        author_email={this.props.channel_email}
                                        author_headline={
                                            this.props.channel_headline
                                        }
                                        post={p}
                                        store={this.props.store}
                                    />
                                ) : null}
                                {this.props.provider === "youtube" ? (
                                    <SocialYoutubePost
                                        avatar={this.props.channel_avatar}
                                        author={this.props.channel_name}
                                        author_email={this.props.channel_email}
                                        author_headline={
                                            this.props.channel_headline
                                        }
                                        post={p}
                                        store={this.props.store}
                                    />
                                ) : null}

                                {/* <pre>{JSON.stringify(p, null, 2)}</pre> */}
                                {/* <Divider /> */}
                            </Fragment>
                        );
                    })
                )}
            </Fragment>
        );
    }
}

export default AccountsChannelPosts;
