import React, { Fragment } from "react";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Button,
    Divider,
    Typography,
    Card,
    Tag,
    Table,
    Menu,
    Dropdown,
} from "antd";

import scss_variables from "./../../_variables.scss";
import moment from "moment";
import {
    social_calculateChannelInsights,
    social_getChannelDataForGraph,
    social_getChannelDataForMedia,
    social_getChannelPosts,
} from "./../../functions";
import ReportResultCompetitorInstagram from "./ReportResultCompetitorInstagram";
import ReportGraph from "./ReportGraph";

const { Panel } = Collapse;
const { Title, Text } = Typography;

const GRID_GUTTER = 40;

const ReportResultBrandBasic = (props) => {
    // -------------------------

    console.info("ReportResult_BrandBasic props", props);

    const brand_channels =
        props.results_separated && props.results_separated.results;
    const brand = props.report_brand;
    const brand_competitors =
        props.results_separated && props.results_separated.competitors;

    console.info("ReportResult_BrandBasic brand_channels", brand_channels);
    console.info(
        "ReportResult_BrandBasic brand_competitors",
        brand_competitors
    );
    //const masterpost = props.results_separated;

    let overallImpressionsGraphs = [],
        comparisonGraph = [],
        overallMediaInfo = [];

    const channelsPanel =
        brand_channels &&
        brand_channels.map((channel, channelIndex) => {
            const insightsData = social_calculateChannelInsights(
                channel.type,
                channel.insights
            );
            const postsData = social_getChannelPosts(channel.type, channel);
            console.info("postsData", postsData);

            console.info("channel", channel);
            console.info("insightsData", insightsData);

            const impressionsDelta = Number(
                (insightsData.total_impressions /
                    insightsData.total_prev_impressions) *
                    100 -
                    100
            ).toFixed(2);
            const audienceDelta = Number(
                (insightsData.total_audience /
                    insightsData.total_prev_audience) *
                    100 -
                    100
            ).toFixed(2);
            const activityDelta = Number(
                (insightsData.total_activity /
                    insightsData.total_prev_activity) *
                    100 -
                    100
            ).toFixed(2);
            const engagementsDelta = Number(
                (insightsData.total_engagements /
                    insightsData.total_prev_engagements) *
                    100 -
                    100
            ).toFixed(2);

            let mediaData = {
                images: [],
                videos: [],
            };
            let channelPosts = [];

            // GraphData
            let graphData = [];
            if (channel && channel.type && channel.type === "facebook") {
                graphData =
                    channel.insights && channel.insights.fbRes
                        ? social_getChannelDataForGraph(
                              channel.type,
                              channel.insights.fbRes.data,
                              "page_impressions"
                          )
                        : [];
                // MeidaData
                mediaData = social_getChannelDataForMedia(
                    channel.type,
                    channel.insights
                );
                // Channel Posts
                channelPosts =
                    channel.insights.postsSimplified &&
                    channel.insights.postsSimplified.data.map((post) => {
                        return {
                            id: post.id,
                            date: moment(post.created_time).format("MM/DD"),
                            message: post.message ? post.message : "N/A",
                            likes:
                                post.likes && post.likes.data
                                    ? post.likes.data.length
                                    : 0,
                            engagement:
                                post.shares && post.shares.data
                                    ? post.shares.data.length + post.comments &&
                                      post.comments.data
                                        ? post.comments.data.length
                                        : 0
                                    : 0,
                        };
                    });
            }
            if (channel && channel.type && channel.type === "twitter") {
                graphData =
                    channel.insights && channel.insights.data
                        ? social_getChannelDataForGraph(
                              channel.type,
                              channel.insights.data,
                              "organic_metrics.impression_count"
                          )
                        : [];
                // MeidaData
                mediaData = social_getChannelDataForMedia(
                    channel.type,
                    channel.insights
                );
                // Channel Posts
                channelPosts =
                    channel.insights &&
                    channel.insights.data &&
                    channel.insights.data.map((post) => {
                        return {
                            id: post.id,
                            date: moment(post.created_at).format("MM/DD"),
                            message: post.text ? post.text : "N/A",
                            likes: post.organic_metrics
                                ? post.organic_metrics.like_count
                                : 0,
                            engagement: post.organic_metrics
                                ? post.organic_metrics.like_count +
                                  post.organic_metrics.reply_count +
                                  post.organic_metrics.retweet_count
                                : 0,
                        };
                    });
            }
            if (channel && channel.type && channel.type === "instagram") {
                graphData =
                    channel.insights && channel.insights.igRes
                        ? social_getChannelDataForGraph(
                              channel.type,
                              channel.insights.igRes.data,
                              "impressions"
                          )
                        : [];

                // MeidaData
                mediaData = social_getChannelDataForMedia(
                    channel.type,
                    channel.insights
                );
            }

            console.info("mediaData", mediaData);
            overallMediaInfo.push({
                name: channel.name
                    ? channel.name + " (" + channel.type + ")"
                    : channel.account_name + " (" + channel.type + ")",
                data: mediaData,
            });
            console.info("overallMediaInfo", overallMediaInfo);

            let graphDataAsObjects = {};
            graphData.map((r) => {
                graphDataAsObjects[moment(r.date).format("YYYY-MM-DD")] =
                    r.value;
            });

            overallImpressionsGraphs.push({
                name: channel.name
                    ? channel.name + " (" + channel.type + ")"
                    : channel.account_name + " (" + channel.type + ")",
                data: graphDataAsObjects,
            });

            let combinedPostsAsObjects = {},
                combinedLikesAsObjects = {},
                combinedSharesAsObjects = {},
                combinedCommentsAsObjects = {};
            Object.keys(postsData).map((date) => {
                combinedPostsAsObjects[moment(date).format("YYYY-MM-DD")] =
                    postsData[date].posts;
                combinedLikesAsObjects[moment(date).format("YYYY-MM-DD")] =
                    postsData[date].likes;
                combinedSharesAsObjects[moment(date).format("YYYY-MM-DD")] =
                    postsData[date].shares;
                combinedCommentsAsObjects[moment(date).format("YYYY-MM-DD")] =
                    postsData[date].comments;
            });
            const combinedChannelGraph = [
                { name: "Posts", data: combinedPostsAsObjects },
                { name: "Likes", data: combinedLikesAsObjects },
                { name: "Shares", data: combinedSharesAsObjects },
                { name: "Comments", data: combinedCommentsAsObjects },
            ];

            console.info("channelPosts", channelPosts);

            return (
                <Fragment key={channelIndex}>
                    <Row
                        gutter={GRID_GUTTER}
                        type="flex"
                        justify="space-between"
                    >
                        <Col span={24} style={{ textAlign: "left" }}>
                            <Icon
                                type={channel.type}
                                theme={
                                    channel.type !== "twitter" ? "filled" : null
                                }
                                style={{
                                    fontSize: 15,
                                    color: props.store.getTagColorForType(
                                        channel.type
                                    ),
                                    marginRight: 5,
                                    display: "inline-block",
                                }}
                            />
                            <strong>{channel.name}</strong>&nbsp; &nbsp;(
                            {channel.account_name})
                        </Col>
                    </Row>
                    <Row
                        gutter={GRID_GUTTER}
                        type="flex"
                        justify="space-between"
                    >
                        <Col span={6} style={{ textAlign: "left" }}>
                            <Statistic
                                title="Impressions"
                                value={insightsData.total_impressions}
                                suffix={
                                    <div
                                        style={{
                                            color:
                                                impressionsDelta > 0
                                                    ? "#3f8600"
                                                    : "#cf1322",
                                        }}
                                    >
                                        <Icon
                                            type={
                                                impressionsDelta <= 0
                                                    ? "arrow-down"
                                                    : "arrow-up"
                                            }
                                        />
                                        {!isNaN(impressionsDelta) &&
                                        impressionsDelta != Infinity
                                            ? Math.abs(impressionsDelta) + "%"
                                            : null}{" "}
                                        ({insightsData.total_prev_impressions})
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={6} style={{ textAlign: "left" }}>
                            <Statistic
                                title="Audience"
                                value={insightsData.total_audience}
                                suffix={
                                    <div
                                        style={{
                                            color:
                                                audienceDelta > 0
                                                    ? "#3f8600"
                                                    : "#cf1322",
                                        }}
                                    >
                                        <Icon
                                            type={
                                                audienceDelta <= 0
                                                    ? "arrow-down"
                                                    : "arrow-up"
                                            }
                                        />
                                        {!isNaN(audienceDelta) &&
                                        audienceDelta != Infinity
                                            ? Math.abs(audienceDelta) + "%"
                                            : null}{" "}
                                        ({insightsData.total_prev_audience})
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={6} style={{ textAlign: "left" }}>
                            <Statistic
                                title="Activity"
                                value={insightsData.total_activity}
                                suffix={
                                    <div
                                        style={{
                                            color:
                                                activityDelta > 0
                                                    ? "#3f8600"
                                                    : "#cf1322",
                                        }}
                                    >
                                        <Icon
                                            type={
                                                activityDelta <= 0
                                                    ? "arrow-down"
                                                    : "arrow-up"
                                            }
                                        />
                                        {!isNaN(activityDelta) &&
                                        activityDelta != Infinity
                                            ? Math.abs(activityDelta) + "%"
                                            : null}{" "}
                                        ({insightsData.total_prev_activity})
                                    </div>
                                }
                            />
                        </Col>
                        <Col span={6} style={{ textAlign: "left" }}>
                            <Statistic
                                title="Engagement"
                                value={insightsData.total_engagements}
                                suffix={
                                    <div
                                        style={{
                                            color:
                                                engagementsDelta > 0
                                                    ? "#3f8600"
                                                    : "#cf1322",
                                        }}
                                    >
                                        <Icon
                                            type={
                                                engagementsDelta <= 0
                                                    ? "arrow-down"
                                                    : "arrow-up"
                                            }
                                        />
                                        {!isNaN(engagementsDelta) &&
                                        engagementsDelta != Infinity
                                            ? Math.abs(engagementsDelta) + "%"
                                            : null}{" "}
                                        ({insightsData.total_prev_engagements})
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                    {graphData && graphData.length > 0 ? (
                        <Row>
                            <Col span={10} style={{ marginLeft: -29 }}>
                                <br />
                                <br />
                                <ReportGraph
                                    title={"Impressions"}
                                    description={
                                        "Just a description of the graph in few words or more"
                                    }
                                    value={graphData}
                                    value_name="value"
                                    key_name="date"
                                    hideTotal
                                    height="200px"
                                    color={
                                        channel &&
                                        props.store.getTagColorForType(
                                            channel.type
                                        )
                                    }
                                    //   metric_name="page_fan_adds"
                                    type="ColumnChart"
                                />
                                <br />
                                <br />
                                <ReportGraph
                                    title={"Posts/Likes/Share/Comments"}
                                    description={
                                        "Just a description of the graph in few words or more"
                                    }
                                    height={"200px"}
                                    value={combinedChannelGraph}
                                    dontParseData
                                    hideTotal
                                    // color={
                                    //     channel &&
                                    //     props.store.getTagColorForType(
                                    //         channel.type
                                    //     )
                                    // }
                                    //   metric_name="page_fan_adds"
                                    type="LineChart"
                                />
                            </Col>
                            <Col span={2}></Col>
                            <Col span={10}>
                                <Title
                                    level={4}
                                    style={{ fontSize: 20, marginTop: 40 }}
                                >
                                    Top 5 Messages
                                </Title>
                                <Table
                                    style={{ height: 500 }}
                                    dataSource={channelPosts
                                        .sort(
                                            (a, b) =>
                                                a.engagement - b.engagement
                                        )
                                        .slice(0, 5)
                                        .map((post) => ({
                                            key: post.id,
                                            date: post.date,
                                            message:
                                                post.message.length > 30
                                                    ? post.message.substring(
                                                          0,
                                                          30
                                                      ) + "..."
                                                    : post.message,
                                            likes: Number(post.likes),
                                            engagement: Number(post.engagement),
                                        }))}
                                    columns={[
                                        {
                                            title: "Date/Time",
                                            dataIndex: "date",
                                            key: "date",
                                            sorter: (a, b) => a.date < b.date,
                                        },
                                        {
                                            title: "Message",
                                            dataIndex: "message",
                                            key: "message",
                                        },
                                        {
                                            title: "Likes",
                                            dataIndex: "likes",
                                            key: "likes",
                                            sorter: (a, b) => a.likes < b.likes,
                                        },
                                        {
                                            title: "Eng.",
                                            dataIndex: "engagement",
                                            key: "engagement",
                                            sorter: (a, b) =>
                                                a.engagement < b.engagement,
                                        },
                                    ]}
                                    pagination={false}
                                    style={{ marginTop: 10, marginBottom: 20 }}
                                />
                            </Col>
                        </Row>
                    ) : null}
                    {/* <Button style={{ float: "right", marginBottom: 20 }} onClick={(e) => {

                    }}>
                        Run Channel Report &gt;
                    </Button> */}
                    <Divider style={{ marginTop: 15, marginBottom: 10 }} />
                </Fragment>
            );
        });

    // --------------------------------------

    const competitorsPanel = (
        <Fragment>
            {brand_competitors &&
                brand_competitors.map((competitor, competitorIndex) => {
                    console.info("COMPETITOR", competitor);

                    const twitter_public_metrics =
                        competitor.social_twitter_results &&
                        competitor.social_twitter_results.user &&
                        competitor.social_twitter_results.user.data &&
                        competitor.social_twitter_results.user.data[0]
                            .public_metrics;

                    // Tweets table if there is any
                    const twitter_insights =
                        competitor.social_twitter_results &&
                        competitor.social_twitter_results.user_insights;

                    const twitter_dataSource =
                        twitter_insights && twitter_insights.data
                            ? twitter_insights.data.map((r, rIndex) => {
                                  return {
                                      key: rIndex,
                                      datetime: moment(r.created_at).format(
                                          "lll"
                                      ),
                                      text: (
                                          <Row gutter={5}>
                                              <Col span={24}>{r.text}</Col>
                                          </Row>
                                      ),
                                      quote_count: r.public_metrics.quote_count,
                                      reply_count: r.public_metrics.reply_count,
                                      like_count: r.public_metrics.like_count,
                                      retweet_count:
                                          r.public_metrics.retweet_count,
                                  };
                              })
                            : [];

                    const columns = [
                        {
                            title: "Date/Time",
                            dataIndex: "datetime",
                            key: "datetime",
                            width: 170,
                        },
                        {
                            title: "",
                            dataIndex: "text",
                            key: "text",
                        },
                        {
                            title: "Quotes",
                            dataIndex: "quote_count",
                            key: "quote_count",
                        },
                        {
                            title: "Replies",
                            dataIndex: "reply_count",
                            key: "reply_count",
                        },
                        {
                            title: "Likes",
                            dataIndex: "like_count",
                            key: "like_count",
                        },
                        {
                            title: "Retweets",
                            dataIndex: "retweet_count",
                            key: "retweet_count",
                        },
                    ];

                    const tableInfoPanel =
                        twitter_dataSource.length > 0 ? (
                            <Table
                                dataSource={twitter_dataSource}
                                columns={columns}
                                pagination={false}
                                style={{ marginTop: 10 }}
                            />
                        ) : null;

                    const linkedin_followers =
                        competitor.social_linkedin &&
                        competitor.social_linkedin_results.followers &&
                        competitor.social_linkedin_results.followers
                            .firstDegreeSize;

                    // Comparismen data -- Instagram
                    let competitorGraphData = [],
                        competitorGraphName = "";
                    if (competitor && competitor.social_facebook_results) {
                        console.info("FACEBOOK COMPETITOR");
                    }
                    if (competitor && competitor.social_instagram_results) {
                        competitorGraphData =
                            competitor.social_instagram_results.user_posts &&
                            competitor.social_instagram_results.user_posts
                                ? social_getChannelDataForGraph(
                                      "instagram",
                                      competitor.social_instagram_results
                                          .user_posts,
                                      "like_count,comments_count"
                                  )
                                : [];
                        console.info(
                            "INSTAGRAM competitorGraphData",
                            competitorGraphData
                        );
                        competitorGraphName = competitor.name + " (instagram)";
                    }
                    if (competitor && competitor.social_twitter_results) {
                        competitorGraphData =
                            competitor.social_twitter_results.user_insights &&
                            competitor.social_twitter_results.user_insights.data
                                ? social_getChannelDataForGraph(
                                      "twitter",
                                      competitor.social_twitter_results
                                          .user_insights.data,
                                      "public_metrics.like_count,public_metrics.quote_count,public_metrics.reply_count,public_metrics.retweet_count"
                                  )
                                : [];
                        console.info(
                            "TWITTER competitorGraphData",
                            competitorGraphData
                        );
                        competitorGraphName = competitor.name + " (twitter)";
                    }

                    let graphDataAsObjects = {};
                    competitorGraphData.map((r) => {
                        let oldValue = graphDataAsObjects[
                            moment(r.date).format("YYYY-MM-DD")
                        ]
                            ? graphDataAsObjects[
                                  moment(r.date).format("YYYY-MM-DD")
                              ]
                            : 0;
                        graphDataAsObjects[
                            moment(r.date).format("YYYY-MM-DD")
                        ] = oldValue + r.value;
                    });
                    if (competitorGraphData.length > 0) {
                        comparisonGraph.push({
                            name: competitorGraphName,
                            data: graphDataAsObjects,
                        });
                    }

                    return (
                        <Fragment key={competitorIndex}>
                            {competitor.social_instagram ? (
                                <ReportResultCompetitorInstagram
                                    competitor={competitor}
                                    store={props.store}
                                />
                            ) : null}
                            <Row>
                                <Col span={8}>
                                    <h3>{competitor.name}</h3>
                                    {competitor.social_twitter ? (
                                        <Fragment>
                                            <Icon
                                                type="twitter"
                                                style={{ marginRight: 5 }}
                                            />
                                            @{competitor.social_twitter}
                                        </Fragment>
                                    ) : null}
                                    {competitor.social_linkedin ? (
                                        <Fragment>
                                            <Icon
                                                type="linkedin"
                                                theme="filled"
                                                style={{ marginRight: 5 }}
                                            />
                                            {competitor.social_linkedin}
                                        </Fragment>
                                    ) : null}
                                </Col>
                                <Col span={8}>
                                    Followers count:
                                    <br />
                                    <h3>
                                        {competitor.social_twitter
                                            ? twitter_public_metrics &&
                                              Number(
                                                  twitter_public_metrics.followers_count
                                              ).toLocaleString()
                                            : null}

                                        {competitor.social_linkedin
                                            ? linkedin_followers &&
                                              Number(
                                                  linkedin_followers
                                              ).toLocaleString()
                                            : null}
                                    </h3>
                                </Col>
                                <Col span={8}>
                                    {competitor.social_twitter ? (
                                        <Fragment>
                                            Tweets count: <br />
                                            <h3>
                                                {twitter_public_metrics &&
                                                    Number(
                                                        twitter_public_metrics.tweet_count
                                                    ).toLocaleString()}
                                            </h3>
                                        </Fragment>
                                    ) : null}
                                </Col>
                            </Row>
                            {tableInfoPanel}
                            <br />
                            <br />
                        </Fragment>
                    );
                })}
        </Fragment>
    );

    console.info("overallImpressionsGraphs", overallImpressionsGraphs);

    comparisonGraph = comparisonGraph.concat(overallImpressionsGraphs);
    console.info("comparisonGraph", comparisonGraph);

    console.info("overallMediaInfo", overallMediaInfo);
    // --------------------------------------

    const body = props.loading ? (
        <Fragment>
            <Spin
                indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                style={{ marginRight: 10 }}
            />
            Processing ...
        </Fragment>
    ) : (
        <Fragment>
            {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
            <br />
            <Row gutter={20}>
                <Col span={12} style={{ paddingLeft: 20 }}>
                    Brand:
                    <Title level={4} style={{ marginBottom: 0 }}>
                        {brand}
                    </Title>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                    Channels:
                    <Title level={4} style={{ marginBottom: 0 }}>
                        {brand_channels.length}
                    </Title>
                </Col>
            </Row>
            <Divider />
            <Collapse
                bordered={false}
                defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
            >
                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("channels_overview") !==
                        -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Brand Channels
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                // marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "channels_overview"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "channels_overview",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        style={{ background: "white" }}
                        key="1"
                    >
                        {channelsPanel}

                        {/* <Divider style={{ marginTop: 15, marginBottom: 10 }} /> */}
                        {overallImpressionsGraphs &&
                        overallImpressionsGraphs.length > 0 ? (
                            <Row>
                                <Col span={24}>
                                    <ReportGraph
                                        title={"All Impressions"}
                                        description={
                                            "Just a description of the graph in few words or more"
                                        }
                                        value={overallImpressionsGraphs}
                                        dontParseData
                                        hideTotal
                                        // color={
                                        //     channel &&
                                        //     props.store.getTagColorForType(
                                        //         channel.type
                                        //     )
                                        // }
                                        //   metric_name="page_fan_adds"
                                        type="LineChart"
                                    />
                                </Col>
                            </Row>
                        ) : null}

                        <Divider style={{ marginTop: 15, marginBottom: 10 }} />
                        <h1>Media Comparison</h1>
                        {/* <pre>{JSON.stringify(overallMediaInfo, null, 2)}</pre> */}
                        {overallMediaInfo && overallMediaInfo.length > 0 ? (
                            <Row>
                                <Col span={24}>
                                    {overallMediaInfo.map(
                                        (channel, channelIndex) => (
                                            <Fragment key={channelIndex}>
                                                <h2>{channel.name}</h2>
                                                <Row>
                                                    <Col span={8}>
                                                        <strong>Image</strong>
                                                        <br />
                                                        Count:{" "}
                                                        {
                                                            channel.data.image
                                                                .count
                                                        }
                                                        <br />
                                                        Likes:{" "}
                                                        {
                                                            channel.data.image
                                                                .likes
                                                        }
                                                        <br />
                                                        Engagement:{" "}
                                                        {
                                                            channel.data.image
                                                                .engagement
                                                        }
                                                    </Col>
                                                    <Col span={8}>
                                                        <strong>Video</strong>
                                                        <br />
                                                        Count:{" "}
                                                        {
                                                            channel.data.video
                                                                .count
                                                        }
                                                        <br />
                                                        Likes:{" "}
                                                        {
                                                            channel.data.video
                                                                .likes
                                                        }
                                                        <br />
                                                        Engagement:{" "}
                                                        {
                                                            channel.data.video
                                                                .engagement
                                                        }
                                                    </Col>
                                                    <Col span={8}>
                                                        <strong>
                                                            Animated GIF
                                                        </strong>
                                                        <br />
                                                        Count:{" "}
                                                        {
                                                            channel.data
                                                                .animated_gif
                                                                .count
                                                        }
                                                        <br />
                                                        Likes:{" "}
                                                        {
                                                            channel.data
                                                                .animated_gif
                                                                .likes
                                                        }
                                                        <br />
                                                        Engagement:{" "}
                                                        {
                                                            channel.data
                                                                .animated_gif
                                                                .engagement
                                                        }
                                                    </Col>
                                                </Row>

                                                <Divider />
                                            </Fragment>
                                        )
                                    )}

                                    {/* <ReportGraph
                                        title={"Media Information"}
                                        description={
                                            "Just a description of the graph in few words or more"
                                        }
                                        value={overallMediaInfo}
                                        dontParseData
                                        hideTotal
                                        // color={
                                        //     channel &&
                                        //     props.store.getTagColorForType(
                                        //         channel.type
                                        //     )
                                        // }
                                        //   metric_name="page_fan_adds"
                                        type="LineChart"
                                    /> */}
                                </Col>
                            </Row>
                        ) : null}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("competitors") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Competitors
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                // marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "competitors"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "competitors",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        style={{ background: "white" }}
                        key="2"
                    >
                        {competitorsPanel}
                    </Panel>
                ) : null}

                {!props.custom_report_flag ||
                (props.custom_report_flag &&
                    props.custom_sections.indexOf("comparison") !== -1) ? (
                    <Panel
                        header={
                            <Fragment>
                                Comparison
                                {!props.custom_report_flag ? (
                                    <div style={{ float: "right" }}>
                                        <Button
                                            type="link"
                                            icon="carry-out"
                                            style={{
                                                marginTop: -5,
                                                // marginRight: -15,
                                                padding: 0,
                                                color:
                                                    props.custom_sections.indexOf(
                                                        "comparison"
                                                    ) !== -1
                                                        ? scss_variables.scss_color_primary
                                                        : "#ccc",
                                            }}
                                            onClick={(e) => {
                                                props.toggleCustomReportSection(
                                                    "comparison",
                                                    e
                                                );
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                ) : null}
                            </Fragment>
                        }
                        style={{ background: "white" }}
                        key="3"
                    >
                        {/* {competitorsPanel} */}
                        <ReportGraph
                            title={"Compare Engagement"}
                            description={
                                "Just a description of the graph in few words or more"
                            }
                            height={"600px"}
                            value={comparisonGraph}
                            dontParseData
                            hideTotal
                            // color={
                            //     channel &&
                            //     props.store.getTagColorForType(
                            //         channel.type
                            //     )
                            // }
                            //   metric_name="page_fan_adds"
                            type="LineChart"
                        />
                    </Panel>
                ) : null}
            </Collapse>
        </Fragment>
    );

    return <Fragment>{body}</Fragment>;
};

export default ReportResultBrandBasic;
