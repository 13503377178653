import React, { Fragment } from "react";
import {
    Spin,
    Icon,
    Collapse,
    Row,
    Col,
    Statistic,
    Tooltip,
    Button,
    Divider,
    Typography,
    Card,
    Tag,
    Table,
} from "antd";

import scss_variables from "./../../_variables.scss";
import moment from "moment";
import { social_calculateChannelInsights } from "./../../functions";

const { Panel } = Collapse;
const { Title, Text } = Typography;

const GRID_GUTTER = 40;

const ReportResultCompetitorInstagram = (props) => {
    // -------------------------

    console.info("ReportResultCompetitorInstagram props", props);

    const { competitor } = props;
    const { social_instagram_results } = competitor;

    // in case information is missing
    if (!social_instagram_results) return "";

    const { user, user_posts } = social_instagram_results;

    console.info("ReportResultCompetitor competitor", competitor);
    //const masterpost = props.results_separated;

    // --------------------------------------

    // --------------------------------------

    const body = (
        <Fragment>
            <Row>
                <Col span={8}>
                    <h3>{competitor.name}</h3>
                    {competitor.social_instagram ? (
                        <Fragment>
                            {user && user.profile_picture_url ? (
                                <img
                                    src={user.profile_picture_url}
                                    style={{ height: 50 }}
                                />
                            ) : null}
                            <br />
                            <Icon
                                type="instagram"
                                theme="filled"
                                style={{
                                    marginRight: 5,
                                    color: props.store.getTagColorForType(
                                        "instagram"
                                    ),
                                }}
                            />
                            @{competitor.social_instagram}
                        </Fragment>
                    ) : null}
                </Col>
                <Col span={8}>
                    Followers count:
                    <br />
                    <h3>
                        {user
                            ? user.followers_count &&
                              Number(user.followers_count).toLocaleString()
                            : null}
                    </h3>
                </Col>
                <Col span={8}>
                    Media count:
                    <br />
                    <h3>
                        {user
                            ? user.media_count &&
                              Number(user.media_count).toLocaleString()
                            : null}
                    </h3>
                </Col>
            </Row>
            <br />
            {user_posts.map((post, postIndex) => (
                <Fragment key={postIndex}>
                    <Row
                        gutter={GRID_GUTTER}
                        type="flex"
                        justify="space-between"
                    >
                        <Col span={6} style={{ textAlign: "left" }}>
                            <strong>
                                {moment(post.timestamp).format("lll")}
                            </strong>
                        </Col>
                        <Col span={6}>
                            {/* <pre>{JSON.stringify(post, null, 2)}</pre>
                             */}
                            <div className={"social_3LinesEllipsis"}>
                                {post.caption}
                            </div>
                        </Col>
                        <Col span={6} style={{ textAlign: "left" }}>
                            <Statistic
                                title="Comments"
                                value={post.comments_count}
                            />
                        </Col>
                        <Col span={6} style={{ textAlign: "left" }}>
                            <Statistic title="Likes" value={post.like_count} />
                        </Col>
                    </Row>
                    <Divider style={{ marginTop: 15, marginBottom: 10 }} />
                </Fragment>
            ))}
            <Divider />
        </Fragment>
    );

    return body;
};

export default ReportResultCompetitorInstagram;
