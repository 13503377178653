import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { StripeProvider, Elements } from "react-stripe-elements";
import { social_notification, social_sleep, social_setCookie } from "./../functions";
import SocialButton from "./SocialButton";
import RegisterFormStripe from "./RegisterFormStripe";
import SocialButtonTwitter from "../components/SocialButtonTwitter";
import config from "../config";
// import lorem from "../lorem-lorem.svg";

import {
	Form,
	Button,
	Typography,
	Steps,
	Input,
	notification,
	Result,
	Icon,
	Col,
	Row,
	Select,
	Radio,
} from "antd";
const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

/* Add/Edit Form */
const ModalForm = Form.create({ name: "form_in_modal" })(
	// eslint-disable-next-line
	class inlineForm extends Component {
		constructor(props) {
			super(props);
			this.state = {};
		}

		componentDidMount() {
			this.setState(this.props.data);
		}

		render() {
			const { form } = this.props;
			const { getFieldDecorator } = form;

			return (
				<Form
					// {...formItemLayout}
					style={{ width: "50%", margin: "0 auto" }}
					onSubmit={(e) => e.preventDefault()}
				>
					{/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
					{/* <Form.Item label="Email">
                        {getFieldDecorator("email", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the email of the account!",
                                    type: "email"
                                }
                            ]
                        })(<Input />)}
                    </Form.Item> */}

					<Form.Item label="Email">
						{getFieldDecorator("email", {
							initialValue: this.state.email,
							onChange: (e) =>
								this.setState({
									email: e.target.value,
									template_email: e.target.value,
								}),
						})(<Input placeholder="Enter email" />)}
					</Form.Item>
					<br />
					<br />
					<Form.Item label="Name">
						{getFieldDecorator("name", {
							initialValue: this.state.name,
							onChange: (e) =>
								this.setState({
									name: e.target.value,
									template_name: e.target.value,
								}),
						})(<Input placeholder="Enter name" />)}
					</Form.Item>
					<br />
					<br />
					<Form.Item label="Phone">
						{getFieldDecorator("phone", {
							initialValue: this.state.phone,
							onChange: (e) =>
								this.setState({
									phone: e.target.value,
									template_phone: e.target.value,
								}),
						})(<Input placeholder="Enter phone" />)}
					</Form.Item>
				</Form>
			);
		}
	}
);

/* Survey Form */
const SurveyForm = Form.create({ name: "form_in_modal" })(
	// eslint-disable-next-line
	class inlineForm extends Component {
		constructor(props) {
			super(props);
			this.state = {
				provider_firstname: "",
				provider_lastname: "",
				provider_practicename: "",
				provider_email: "",
				provider_address: "",
				provider_phone: "",
				provider_primarycontact_name: "",
				provider_primarycontact_phone: "",
				provider_type: "OBGYN",

				facebook_doyouhave: "no",
				facebook_username: "",
				facebook_password: "",
				facebook_name: "",
				facebook_businesspage: "",
				facebook_name2: "",
				facebook_businesspage2: "",

				instagram_doyouhave: "no",
				instagram_username: "",
				instagram_password: "",
				instagram_name: "",
				instagram_businesspage: "",
				instagram_name2: "",
				instagram_businesspage2: "",

				background_age: "",
				background_gender: "",
				background_householdincome: "",
				background_ethnicity: "",

				background_age2: "",
				background_gender2: "",
				background_householdincome2: "",
				background_ethnicity2: "",

				background_marketingfocus: "",
				background_tracking: "",
				background_commondriver: "",

				signature_name: "",
				signature_businessname: "",
				signature_signature: "",
				signature_date: "",
			};
		}

		render() {
			return (
				<Fragment>
					<div style={{ textAlign: "left" }}>
						<h2 style={{ textAlign: "left" }}>PROVIDER INFORMATION</h2>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Input
									placeholder="First name"
									onChange={(e) =>
										this.setState({
											provider_firstname: e.target.value,
										})
									}
								/>
							</Col>
							<Col span={12}>
								<Input
									placeholder="Last name"
									onChange={(e) =>
										this.setState({
											provider_lastname: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={16}>
								<Input
									placeholder="Practice name"
									onChange={(e) =>
										this.setState({
											provider_practicename: e.target.value,
										})
									}
								/>
							</Col>
							<Col span={8}>
								<Input
									placeholder="Email"
									onChange={(e) =>
										this.setState({
											provider_email: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={16}>
								<Input
									placeholder="Address"
									onChange={(e) =>
										this.setState({
											provider_address: e.target.value,
										})
									}
								/>
							</Col>
							<Col span={8}>
								<Input
									placeholder="Phone"
									onChange={(e) =>
										this.setState({
											provider_phone: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={16}>
								<Input
									placeholder="Name of Primary Contact"
									onChange={(e) =>
										this.setState({
											provider_primarycontact_name: e.target.value,
										})
									}
								/>
							</Col>
							<Col span={8}>
								<Input
									placeholder="Phone of Primary Contact"
									onChange={(e) =>
										this.setState({
											provider_primarycontact_phone: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col>
								<Select
									defaultValue="OBGYN"
									style={{ width: "100%" }}
									onChange={(e) =>
										this.setState({
											provider_type: e,
										})
									}
								>
									<Option value="OBGYN">OBGYN</Option>
									<Option value="General Practice">General Practice</Option>
									<Option value="Cardiologyst">Cardiologyst</Option>
									<Option value="Urologyst">Urologyst</Option>
									<Option value="Endocrinologyst">Endocrinologyst</Option>
									<Option value="Other">Other</Option>
								</Select>
							</Col>
						</Row>
						<br />
						<br />
						<h2>FACEBOOK INFORMATION AND AUTHORIZATION</h2>
						<Row gutter={[16, 16]}>
							<Col span={16}>
								Do you currently have a business Facebook page?
							</Col>
							<Col span={8} style={{ textAlign: "right" }}>
								<Radio.Group
									onChange={(e) =>
										this.setState({
											facebook_doyouhave: e.target.value,
										})
									}
									value={this.state.facebook_doyouhave}
								>
									<Radio value={"yes"}>Yes</Radio>
									<Radio value={"no"}>No</Radio>
								</Radio.Group>
							</Col>
						</Row>

						{/* <Row gutter={[16, 16]}>
                        <Col>
                            <em>
                                If you do, please add social@uhub.app as Editor
                                or Administrator and authorize us to post on
                                your behalf. Alternatively, you can provide us
                                with your login information below.
                            </em>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Input
                                placeholder="Facebook Username"
                                onChange={(e) =>
                                    this.setState({
                                        facebook_username: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Input
                                placeholder="Facebook Password"
                                onChange={(e) =>
                                    this.setState({
                                        facebook_password: e.target.value,
                                    })
                                }
                            />
                        </Col>
                    </Row> */}
						<Row gutter={[16, 16]}>
							<Col>
								I,{" "}
								<Input
									style={{
										width: 160,
										display: "inline-block",
									}}
									onChange={(e) =>
										this.setState({
											facebook_name: e.target.value,
										})
									}
								/>{" "}
								authorize Picklings Corporation to post on and edit the Facebook
								business page for{" "}
								<Input
									style={{
										width: 180,
										display: "inline-block",
									}}
									onChange={(e) =>
										this.setState({
											facebook_businesspage: e.target.value,
										})
									}
								/>
								<br />
								<br />
								If you do not have a Facebook business page, please authorize
								Picklings Corporation below to create one on your behalf.
								<br />
								<br />
								I,{" "}
								<Input
									style={{
										width: 160,
										display: "inline-block",
									}}
									onChange={(e) =>
										this.setState({
											facebook_name2: e.target.value,
										})
									}
								/>{" "}
								authorize Picklings Corporation to create a Facebook business
								page for{" "}
								<Input
									style={{
										width: 180,
										display: "inline-block",
									}}
									onChange={(e) =>
										this.setState({
											facebook_businesspage2: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<br />
						<br />
						<h2 style={{ textAlign: "left" }}>
							INSTAGRAM INFORMATION AND AUTHORIZATION
						</h2>
						<Row gutter={[16, 16]}>
							<Col span={16}>
								Do you currently have a business Instagram page?
							</Col>
							<Col span={8} style={{ textAlign: "right" }}>
								<Radio.Group
									onChange={(e) =>
										this.setState({
											instagram_doyouhave: e.target.value,
										})
									}
									value={this.state.instagram_doyouhave}
								>
									<Radio value={"yes"}>Yes</Radio>
									<Radio value={"no"}>No</Radio>
								</Radio.Group>
							</Col>
						</Row>
						{/* <Row gutter={[16, 16]}>
                        <Col>
                            <em>
                                If you do, please add social@uhub.app as Editor
                                or Administrator and authorize us to post on
                                your behalf. Alternatively, you can provide us
                                with your login information below.
                            </em>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Input
                                placeholder="Instagram Username"
                                onChange={(e) =>
                                    this.setState({
                                        instagram_username: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Input
                                placeholder="Instagram Password"
                                onChange={(e) =>
                                    this.setState({
                                        instagram_password: e.target.value,
                                    })
                                }
                            />
                        </Col>
                    </Row> */}
						<Row gutter={[16, 16]}>
							<Col>
								I,{" "}
								<Input
									style={{
										width: 160,
										display: "inline-block",
									}}
									onChange={(e) =>
										this.setState({
											instagram_name: e.target.value,
										})
									}
								/>{" "}
								authorize Picklings Corporation to post on and edit the
								Instagram business page for{" "}
								<Input
									style={{
										width: 180,
										display: "inline-block",
									}}
									onChange={(e) =>
										this.setState({
											instagram_businessname: e.target.value,
										})
									}
								/>
								<br />
								<br />
								If you do not have a Instagram business page, please authorize
								Picklings Corporation below to create one on your behalf.
								<br />
								<br />
								I,{" "}
								<Input
									style={{
										width: 160,
										display: "inline-block",
									}}
									onChange={(e) =>
										this.setState({
											instagram_name2: e.target.value,
										})
									}
								/>{" "}
								authorize Picklings Corporation to create a Instagram business
								page for{" "}
								<Input
									style={{
										width: 180,
										display: "inline-block",
									}}
									onChange={(e) =>
										this.setState({
											instagram_businessname2: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<br />
						<br />
						<h2 style={{ textAlign: "left" }}>BACKGROUND INFORMATION</h2>
						<Row gutter={[16, 16]}>
							<Col>
								(1). Tell us about the predominant demographics of your current
								hormone replacement therapy patients, such as age range, gender
								split, socio-economics and ethnicity.
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Select
									placeholder="Age range"
									style={{ width: "100%" }}
									onChange={(e) => this.setState({ background_age: e })}
									value={this.state.background_age}
								>
									<Option value="25-34">25-34</Option>
									<Option value="35-49">35-49</Option>
									<Option value="50-64">50-64</Option>
									<Option value="65+">65+</Option>
								</Select>
							</Col>
							<Col span={12}>
								<Select
									placeholder="Gender"
									style={{ width: "100%" }}
									onChange={(e) => this.setState({ background_gender: e })}
									value={this.state.background_gender}
								>
									<Option value="Female">Female</Option>
									<Option value="Male">Male</Option>
								</Select>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Select
									placeholder="Household income"
									style={{ width: "100%" }}
									onChange={(e) =>
										this.setState({
											background_householdincome: e,
										})
									}
									value={this.state.background_householdincome}
								>
									<Option value="0-50000">$0-$50,000</Option>
									<Option value="50000-100000">$50,000-$100,000</Option>
									<Option value="100000+">$100,000+</Option>
								</Select>
							</Col>
							<Col span={12}>
								<Select
									placeholder="Ethnicity"
									style={{ width: "100%" }}
									onChange={(e) =>
										this.setState({
											background_ethnicity: e,
										})
									}
									value={this.state.background_ethnicity}
								>
									<Option value="African-American">African-American</Option>
									<Option value="Caucasian">Caucasian</Option>
									<Option value="Latino">Latino</Option>
									<Option value="SE Asian">SE Asian</Option>
									<Option value="Other Asian">Other Asian</Option>
								</Select>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col>
								(2). What market segments in your area do you feel may be in
								need of hormone therapy, but are currently untapped (and why do
								you believe they are)?
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Select
									placeholder="Age range"
									style={{ width: "100%" }}
									onChange={(e) => this.setState({ background_age2: e })}
									value={this.state.background_age2}
								>
									<Option value="25-34">25-34</Option>
									<Option value="35-49">35-49</Option>
									<Option value="50-64">50-64</Option>
									<Option value="65+">65+</Option>
								</Select>
							</Col>
							<Col span={12}>
								<Select
									placeholder="Gender"
									style={{ width: "100%" }}
									onChange={(e) => this.setState({ background_gender2: e })}
									value={this.state.background_gender2}
								>
									<Option value="Female">Female</Option>
									<Option value="Male">Male</Option>
								</Select>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Select
									placeholder="Household income"
									style={{ width: "100%" }}
									onChange={(e) =>
										this.setState({
											background_householdincome2: e,
										})
									}
									value={this.state.background_householdincome2}
								>
									<Option value="0-50000">$0-$50,000</Option>
									<Option value="50000-100000">$50,000-$100,000</Option>
									<Option value="100000+">$100,000+</Option>
								</Select>
							</Col>
							<Col span={12}>
								<Select
									placeholder="Ethnicity"
									style={{ width: "100%" }}
									onChange={(e) =>
										this.setState({
											background_ethnicity2: e,
										})
									}
									value={this.state.background_ethnicity2}
								>
									<Option value="African-American">African-American</Option>
									<Option value="Caucasian">Caucasian</Option>
									<Option value="Latino">Latino</Option>
									<Option value="SE Asian">SE Asian</Option>
									<Option value="Other Asian">Other Asian</Option>
								</Select>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col>
								(3). List the top five marketing efforts you have engaged in
								over the past year, in order of expenditure.
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col>
								<Select
									placeholder="Marketing Focus"
									style={{ width: "100%" }}
									onChange={(e) =>
										this.setState({
											background_marketingfocus: e,
										})
									}
									value={this.state.background_marketingfocus}
								>
									<Option value="Print">Print</Option>
									<Option value="Online Ads">Online Ads</Option>
									<Option value="Social Media">Social Media</Option>
									<Option value="TV">TV</Option>
									<Option value="Radio">Radio</Option>
								</Select>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col>
								(4). List the top five marketing efforts you have engaged in
								over the past year, in order of success rate.
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col>
								<Select
									placeholder="Tracking"
									style={{ width: "100%" }}
									onChange={(e) =>
										this.setState({
											background_tracking: e,
										})
									}
									value={this.state.background_tracking}
								>
									<Option value="Yes">Yes</Option>
									<Option value="No">No</Option>
								</Select>
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col>
								(5). Do you currently track why customers chose you to be their
								provider and how they became aware of your services, and if so,
								how?
							</Col>
						</Row>

						<Row gutter={[16, 16]}>
							<Col>
								<Select
									placeholder="Common Driver"
									style={{ width: "100%" }}
									onChange={(e) =>
										this.setState({
											background_commondriver: e,
										})
									}
									value={this.state.background_commondriver}
								>
									<Option value="" disabled selected>
										Common Driver
									</Option>
									<Option value="Print">Print</Option>
									<Option value="Online Ads">Online Ads</Option>
									<Option value="Social Media">Social Media</Option>
									<Option value="TV">TV</Option>
									<Option value="Radio">Radio</Option>
									<Option value="Referrals">Referrals</Option>
									<Option value="Patient Seminars">Patient Seminars</Option>
									<Option value="BioTE Website">BioTE Website</Option>
								</Select>
							</Col>
						</Row>

						<br />
						<br />
						<h2 style={{ textAlign: "left" }}>SIGNATURE AND DATE</h2>
						<Row gutter={[16, 16]}>
							<Col>
								I represent and warrant that I am duly authorized and have legal
								capacity to provide the information above and to execute and
								deliver this agreement. I represent and warrant that the exe-
								cution and delivery of this agreement, and the provision of all
								information hereunder, have been duly authorized.
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Input
									placeholder="Name"
									onChange={(e) =>
										this.setState({
											signature_name: e.target.value,
										})
									}
								/>
							</Col>
							<Col span={12}>
								<Input
									placeholder="Business Name"
									onChange={(e) =>
										this.setState({
											signature_businessname: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Input
									placeholder="Signature"
									onChange={(e) =>
										this.setState({
											signature_signature: e.target.value,
										})
									}
								/>
							</Col>
							<Col span={12}>
								<Input
									placeholder="Date"
									onChange={(e) =>
										this.setState({
											signature_date: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
					</div>
				</Fragment>
				// <Form
				//     {...formItemLayout}
				//     style={{ width: "50%", margin: "0 auto" }}
				// >
				//     {/* <Form.Item label="Email">
				//         {getFieldDecorator("email", {
				//             rules: [
				//                 {
				//                     required: true,
				//                     message:
				//                         "Please input the email of the account!",
				//                     type: "email"
				//                 }
				//             ]
				//         })(<Input />)}
				//     </Form.Item> */}

				//     <Form.Item label="Survey Question">
				//         {getFieldDecorator("q1", {
				//             initialValue: data.q1,
				//         })(<TextArea placeholder="Answer" />)}
				//     </Form.Item>
				// </Form>
			);
		}
	}
);

// class StripeFormElements extends Component {
//     render() {
//         return <h1>form</h1>;
//     }
// }
// const StripeForm = injectStripe(StripeFormElements);

class RegisterForm extends Component {
	state = {};

	constructor(props) {
		super(props);
		//   this.authWithFacebook = this.authWithFacebook.bind(this);

		this.state = {
			redirect: false,
			error_message: null,
			facebook_authloading: false,
			step: 0,
			socialData: {},
			surveyData: {},
			resultStatus: "info",
			resultIcon: null,
			resultText: "Processing now ..., please wait.",
		};
	}

	componentDidMount() {
		//console.info("componentDidMount this.props", this.props);

		if (typeof this.props.removeAuthListener === "function") {
			//console.log("WE HAVE REMOVE listener");
			this.props.removeAuthListener();
		}
	}

	handleBackToLogin = () => {
		this.setState({ redirect: "/login" });
	};
	handleBackToRegister = () => {
		this.setState({
			redirect:
				"/register" +
				(this.props.match.params.world
					? "/" + this.props.match.params.world
					: ""),
		});
	};
	// handleBackToDashboard = () => {
	//     this.setState({ redirect: "/dashboard" });
	// };

	handleSocialLoginFailure = (err) => {
		//console.error(err);
	};

	handleSocialLogin = async (user) => {
		console.info("handleSocialLogin user", user);

		// make the button spinning
		this.setState({ facebook_authloading: true, socialLoginUser: user });

		notification.open({
			key: "getFacebookChannelsKey",
			message: "Processing...",
			description: "Gathering channels information... Please wait.",
			type: "info",
			duration: 0,
		});

		console.log("get facebook channels");
		let channels_info = await this.props.store.getFacebookChannels(
			user._profile.id,
			user._token.accessToken
		);
		console.info("channels_info", channels_info);

		notification.open({
			key: "getFacebookChannelsKey",
			message: "Completed",
			description: "Channels information is stored.",
			type: "success",
		});

		let accountData = {
			email: user._profile.email,
			name: user._profile.name,
			phone: user._profile.phone,
			template_appendix: "",
			template_email: user._profile.email,
			template_firstcomment: "",
			template_location: "",
			template_name: user._profile.name,
			template_phone: user._profile.phone,
			template_website: "",
			channels: channels_info,
		};
		console.info("handleSocialLogin accountData", accountData);

		this.setState({ step: this.state.step + 1, socialData: accountData });
		return;
	};

	handleNext = async () => {
		this.setState({ step: this.state.step + 1 });

		let { socialData } = this.state;

		if (this.formRef) {
			let formState = Object.assign({}, this.formRef.state);
			console.info("formState", formState);
			this.setState({ socialData: formState });
		}

		if (this.surveyformRef) {
			let surveyFormState = Object.assign({}, this.surveyformRef.state);
			socialData.surveryData = JSON.parse(JSON.stringify(surveyFormState));
			this.setState({ socialData });
			console.info("surveyFormState", surveyFormState);
		}

		console.info("state", this.state);
	};

	handlePaymentformUpdate = (_info) => {
		console.info("handlePaymentformUpdate _info", _info);
	};
	handlePaymentSubmit = async (_info) => {
		console.info("handlePaymentSubmit _info", _info);

		console.info("this.state", this.state);
		let { socialData, socialLoginUser } = this.state;

		// validate the payment form
		try {
			// // stripe token
			// let token = await _info.stripe.createToken({
			// 	name: _info.account_email,
			// });
			// console.info("token", token);
			// if (token.error) {
			// 	social_notification("validation_error", "error", {
			// 		title: "Verification error",
			// 		description: token.error.message,
			// 	});
			// 	return false;
			// }

			// Proceeding
			this.setState({
				step: this.state.step + 1,
				resultIcon: <Icon type="loading" />,
			});

			// // adding ad budget if selected
			// if (_info.additional_feature_ad_budget === true) {
			// 	socialData["ad_budget"] = Number(
			// 		_info.additional_feature_ad_budget_amount
			// 	);
			// }

			// Assigning the world_key
			socialData["world_key"] = this.props.world_key;

			//console.info("socialData", socialData);

			// adding the account
			this.props.store
				.addAccount(socialData)
				.then((socialData) => {
					console.info("STEP1 socialData", socialData);
                    this.setState({account_key: socialData.key});
					this.props.store
						.preAuthAccount(
							socialLoginUser._provider,
							socialData.key,
							socialLoginUser
						)
						.then((fbRes) => {
							console.info("STEP2 fbRes", fbRes);
							// update the user token
							socialLoginUser._token = fbRes;

							return this.props.store.authAccount(
								socialLoginUser._provider,
								socialData.key,
								socialLoginUser
							);
						})
						.then(() => {
							console.info("STEP3");
							return this.props.store.getAccountAuthData(
								socialData.key,
								"facebook" // TODO: We need to make this not static
							);
						})
						.then(async (authData) => {
							console.info("STEP4 authData", authData);

							// return this.props.store.saveAuthDataAccount(
							//     "facebook",
							//     accountData.key,
							//     authData.fbPages.data
							// );

							if (authData.igAccounts.length > 0) {
								// we have instagram data
								await this.props.store.saveAuthDataAccount(
									"instagram",
									socialData.key,
									authData.igAccounts
								);
							}

							return this.props.store.saveAuthDataAccount(
								"facebook",
								socialData.key,
								authData.fbPages.data
							);
						})
						// .then(async (updatedUserData) => {
						// 	console.info("STEP5 updatedUserData", updatedUserData);
						// 	// Payment
						// 	// process the payment
						// 	let payResult = await this.props.store.paySubscription({
						// 		token: token.token,
						// 		amount: _info.total,
						// 		payment_data: {
						// 			account_key: socialData.key,
						// 			account_email: updatedUserData.email,
						// 			subscription: _info.selected_subscription,
						// 			additional: {
						// 				conversion_tracking: {
						// 					status: _info.additional_feature_conversion_tracking,
						// 					amount:
						// 						_info.additional_feature_conversion_tracking_amount,
						// 				},
						// 				ad_budget: {
						// 					status: _info.additional_feature_ad_budget,
						// 					amount: _info.additional_feature_ad_budget_amount,
						// 				},
						// 			},
						// 		},
						// 	});
						// 	console.info("payResult", payResult);

						// 	return payResult;
						// })
						.then(async (finalData) => {
							//updatedUserData.key = this.state.accountId.toString();

							console.info("finalData", finalData);

							// await this.props.store
							//     .setUserWorld(
							//         socialData.email,
							//         this.props.world_key
							//     )
							//     .catch((err) => {
							//         console.error("setUserWorld err", err);
							//     });

							this.setState({
								facebook_authloading: false,
								resultStatus: "success",
								resultIcon: null,
								resultText: "The account was successfully created.",
								step: 4,
							});
							social_notification("account_add", "success");
						})
						.catch((error) => {
							console.warn(error);
							social_notification("account_add", "error", {
								description: error,
							});
						});
				})
				.catch((error) => {
					console.warn(error);
					social_notification("account_add", "error", {
						description: error,
					});
				});
		} catch (e) {
			throw e;
		}

		// collecting all information

		return false;
	};

    handleAddTwitter = async (e) => {
        alert('add twitter');
    }
    handleAddLinkedin = async (e) => {
        alert('add linkedin');
        social_setCookie("link_linkedin_account_key", this.state.account_key, 7);
        //social_setCookie("link_linkedin_bearer", loggedUser.idToken, 7);
        //this.props.store.showModalByKey("accounts_channels_linkedin_modal");
        window.open(window.location.origin + `/linkedinhandler`);
    }

	saveFormRef = (formRef) => {
		this.formRef = formRef;
	};
	saveSurveyFormRef = (formRef) => {
		this.surveyformRef = formRef;
	};
	savePaymentFormRef = (formRef) => {
		this.paymentformRef = formRef;
	};

	render() {
		if (this.state.redirect !== false) {
			return <Redirect to={this.state.redirect} />;
		}

		const facebookButton = (
			<div className="FacebookButton">
				{/* <Button
                    type="primary"
                    icon="facebook"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ background: "#4267b2" }}
                    onClick={() => {
                        this.authWithFacebook();
                    }}
                >
                    Log in with Facebook
                </Button> */}
				<SocialButton
					provider="facebook"
					//icon="facebook"
					scope={config.facebook.scope}
					appId={config.facebook.client_id}
					onLoginSuccess={this.handleSocialLogin}
					onLoginFailure={this.handleSocialLoginFailure}
					//loading={this.state.facebook_authloading}
					disabled={this.state.facebook_authloading}
					className={"FacebookButton"}
					// style={{
					//     textAlign: "center",
					//     color: "white",
					//     backgroundColor: this.props.store.getTagColorForType(
					//         "facebook"
					//     ),
					//     padding: "5px 15px",
					//     border: 0,
					//     borderRadius: 4,
					//     fontSize: 14,
					// }}
				>
					Register Facebook Pages
				</SocialButton>
			</div>
		);

		return (
			<Fragment>
				<Steps
					current={this.state.step}
					size="small"
					style={{ marginBottom: 30 }}
				>
					<Step title="Add Social Channels" />
					<Step title="Account Information" />
					<Step title="Practice Information" />
					<Step title="Subscription Information" />
					<Step title="Additional Channels" />
				</Steps>
				{/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
				{this.state.step === 0 ? (
					<Fragment>
						<div
							style={{
								textAlign: "center",
								width: "50%",
								margin: "0 auto",
							}}
						>
							<Title level={3}>Add Social Channels</Title>
							<p>
								Click on the appropriate button below to register a new account
								with BHRT Social and add at least one new social channel. <br />
								You will be asked to log in to your social media account and
								give us premission to post and manage it.
								<br />
								<br />
								If you already have a BHRT Social account, please click on the
								Log-in button above to enter.
							</p>
							<br />
							<br />
							{facebookButton}
						</div>
					</Fragment>
				) : null}
				{this.state.step === 1 ? (
					<Fragment>
						<div>
							<Title level={3} style={{ textAlign: "center" }}>
								Account Information
							</Title>
							<ModalForm
								store={this.props.store}
								wrappedComponentRef={this.saveFormRef}
								data={this.state.socialData}
							/>
							<br />
							<br />
							<br />

							<Button
								onClick={this.handleNext}
								type="primary"
								className="register-form-button"
								style={{
									display: "block",
									margin: "0 auto",
									// width: 200,
								}}
							>
								Confirm
							</Button>
						</div>
					</Fragment>
				) : null}
				{this.state.step === 2 ? (
					<Fragment>
						<div style={{ textAlign: "center" }}>
							<Title level={3}>Practice Information</Title>
							<p>
								Please provide the information and required authorization below
								to allow us to customize and post content for your practice.
							</p>
							<br />
							<br />
							<SurveyForm
								store={this.props.store}
								wrappedComponentRef={this.saveSurveyFormRef}
								data={this.state.surveyData}
							/>
							<br />
							<br />
							<br />

							<Button
								onClick={this.handleNext}
								className="register-form-button"
								type="primary"
								style={{
									display: "block",
									margin: "0 auto",
									width: 200,
								}}
							>
								Confirm
							</Button>
						</div>
					</Fragment>
				) : null}
				{this.state.step === 3 ? (
					<Fragment>
						<div>
							<Title level={3} style={{ textAlign: "center" }}>
								Subscription Information
							</Title>
							<StripeProvider apiKey={config.stripe.apiKey}>
								<Elements>
									<RegisterFormStripe
										store={this.props.store}
										wrappedComponentRef={this.savePaymentFormRef}
										data={{
											account_email: "mdonchev@msn.com",
											socialData: this.state.socialData,
										}}
										world_key={this.props.world_key}
										handleUpdate={this.handlePaymentformUpdate}
										handleSubmit={this.handlePaymentSubmit}
									/>
								</Elements>
							</StripeProvider>
						</div>
					</Fragment>
				) : null}
				{this.state.step === 4 ? (
					<Fragment>
						<Result
							icon={this.state.resultIcon}
							status={this.state.resultStatus}
							title="Registering account"
							subTitle={this.state.resultText}
						/>
						<Row>
							<Col span={12}>
								<Button
									type="primary"
									className="register-form-button"
									key="backtologin"
									disabled={this.state.resultStatus !== "success"}
									onClick={this.handleBackToRegister}
								>
									Register Another
								</Button>
							</Col>
							<Col span={12} style={{ textAlign: "right" }}>
								{/* <Button
									type="primary"
									icon="twitter"
									key="addtwitter"
                                    style={{backgroundColor: this.props.store.getTagColorForType('twitter')}}
									// disabled={this.state.resultStatus !== "success" || !this.state.socialData}
									onClick={this.handleAddTwitter}
								>
									Add Twitter Channel
								</Button> */}

                                <SocialButtonTwitter store={this.props.store} account_key={this.state.account_key}/>

								<br />

								<Button
									type="primary"
									icon="linkedin"
									theme="filled"
									key="addlinkedin"
                                    style={{backgroundColor: this.props.store.getTagColorForType('linkedin')}}
									// disabled={this.state.resultStatus !== "success" || !this.state.socialData}
									onClick={this.handleAddLinkedin}
								>
									Authorize with Linkedin
								</Button>
							</Col>
						</Row>
					</Fragment>
				) : null}
				{/* <Divider />
                <Button
                    className="register-form-button"
                    style={{ display: "block", margin: "0 auto" }}
                    onClick={() => {
                        this.handleBackToLogin();
                    }}
                >
                    Back to Log-in
                </Button> */}
			</Fragment>
		);
	}
}

export default RegisterForm;
