import React, { Component, Fragment } from "react";
// import { base, app, facebookProvider } from "./../../base";
import config from "./../../config";
import tooltips from "./../../tooltips";

import { social_filterList, social_notification } from "./../../functions";
import moment from "moment";
import { observer } from "mobx-react";
import prompt from "./../antd-prompt";
import { app } from "../../base";
import { Redirect } from "react-router-dom";
import scss_variables from "./../../_variables.scss";

import {
    Empty,
    List,
    Button,
    Icon,
    Tag,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Modal,
    Menu,
    Dropdown,
    Checkbox,
} from "antd";

import AccountsListModal from "./AccountsListModal";
import ReportsModal from "./../Reports/ReportsModal";
import SocialChannel from "./../SocialChannel";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";
import Bulk from "./../Bulk";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class AccountsList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            edit_data: {},
            testing: false,
            filter: "",
            filter_facebook: true,
            filter_instagram: true,
            filter_twitter: true,
            filter_linkedin: true,
            filter_youtube: true,
            facebook_authloading: false,
            pagination_currentPage: 1,
            sortby: "name",
            sortasc: true,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("accounts");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Accounts" }]);

        //this.props.store.setCurrentSection("settings");
        //var path = this.props.params.splat;
        //console.info("AccountsReport splat/path (passed parameter) = ", path);
        console.info("AccountsList this.props ", this.props);
        if (this.props.match.params.filter) {
            this.setState({ filter: this.props.match.params.filter });
        }
    }
    async componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);
        await this.loadInformation();

        // Bulk listing
        this.listBulk = new Bulk({
            name: "accountsList",
            list: this.props.store.getAllAccounts.map((element) => element.key),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                },
            ],
            callback: () => {
                console.log("READY");
                this.loadInformation();
            },
        });
    }

    componentWillUnmount() {
        this.props.store.unregisterModalListener("accounts_modal");
        this.props.store.unregisterModalListener("reports_modal");
    }

    loadInformation = async () => {
        const loggedUser = this.props.store.getLoggedUser;
        if (loggedUser.level !== "admin")
            this.setState({ filter: loggedUser.email });

        let allReports = this.props.store.getAllReports;
        this.setState({ reports: allReports });
        this.props.store
            .getAllCustomReports()
            .then((customReports) => {
                console.info("customReports", customReports);
                this.setState({ custom_reports: customReports });
            })
            .catch((err) => {
                console.warn(err);
            });

        // loading accounts
        let allAccounts = this.props.store.getAllAccounts;
        await allAccounts.map(async (account) => {
            account.channels = await this.props.store.getAccountChannels(
                account.key
            );
        });
        console.info("allAccounts", allAccounts);
        this.setState({ accounts: allAccounts });
    };

    showModal = () => {
        console.log("showModal");
        this.setState({
            edit_data: {
                auth: null, // empty auth data
            },
        });

        console.log("showModal");
        this.props.store.showModalByKey("accounts_modal");
    };
    showModalForEdit = (_evt, _account) => {
        console.info("showModalForEdit _evt", _evt, "_account", _account);

        this.setState({
            edit_data: _account,
        });

        this.props.store.showModalByKey("accounts_modal");
    };
    showReport = (_evt, _account) => {
        console.info("showReport _evt", _evt, "_account", _account);

        this.props.history.push(`/dashboard/accounts/${_account.key}/report`);
    };

    showQueue = (_evt, _account) => {
        console.info("showQueue _evt", _evt, "_account", _account);

        this.props.history.push(`/dashboard/accounts/${_account.key}/queue`);
    };

    showChannels = (_evt, _account) => {
        console.info("showChannels _evt", _evt, "_account", _account);

        this.props.history.push(`/dashboard/accounts/${_account.key}/channels`);
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        this.setState({ sortby: _sorting, sortasc });
    };

    handleDeleteAccount = async (_evt, _account) => {
        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk: async () => {
                console.info(
                    "handleDeleteAccount _evt",
                    _evt,
                    "_account",
                    _account
                );

                let stripeData = null;
                if (_account.payment_history) {
                    let index = 0;
                    while (stripeData === null) {
                        if (_account.payment_history[index].stripe_data) {
                            stripeData = JSON.parse(
                                JSON.stringify(
                                    _account.payment_history[index].stripe_data
                                )
                            );
                        }
                        index++;
                    }
                }
                console.info("stripeData", stripeData);

                try {
                    const account_email = await prompt({
                        title: "Delete account",
                        placeholder: "Account email",
                        rules: [
                            // check this link for more help: https://ant.design/components/form/#Validation-Rules
                            {
                                required: true,
                                message: "You must enter the email",
                                type: "email",
                            },
                        ],
                        label: "Please enter account's email",
                        initialValue: "",
                        modalProps: {
                            width: "50%",
                        },
                    });

                    if (account_email === _account.email) {
                        let stripeUnsubscribeData = null;
                        if (
                            stripeData &&
                            stripeData.subscriptionData &&
                            stripeData.subscriptionData.id
                        ) {
                            stripeUnsubscribeData =
                                await that.props.store.unsubscribeSubscription(
                                    stripeData.subscriptionData.id
                                );
                            console.info(
                                "stripeUnsubscribeData",
                                stripeUnsubscribeData
                            );
                        }

                        if (
                            stripeUnsubscribeData &&
                            stripeUnsubscribeData.status === "canceled"
                        ) {
                            that.props.store.deleteAccount(_account);
                        } else {
                            that.props.store.deleteAccount(_account);
                        }

                        // Update the list
                        that.loadInformation();

                        // logout the current user
                        const loggedUser = that.props.store.getLoggedUser;
                        if (_account.email === loggedUser.email) {
                            app.auth()
                                .signOut()
                                .then((user) => {
                                    that.props.store.resetLoggedUser();
                                    that.setState({
                                        redirect: true,
                                    });
                                });
                        }
                    } else {
                        social_notification("validation_error", "error", {
                            description:
                                "Email do not match. Please try again.",
                        });
                    }
                } catch (e) {
                    console.warn(e);
                }
            },
        });
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    getListingDataSource = () => {
        if (!this.state.accounts) return [];

        let results = social_filterList(
            this.state.accounts,
            this.state.filter,
            {
                type: "accounts",
                fields: [
                    { name: "email", type: "string" },
                    { name: "name", type: "string" },
                    { name: "tags", type: "array" },
                    { name: "location", type: "array" },
                    {
                        name: `facebook.data`,
                        type: "arrayOfObjects",
                        arrayFieldName: "name",
                    },
                ],
            }
        );
        //console.info("results", results);

        // results.map(async (res) => {
        //     console.info("res", res);
        //     //getAccountChannels
        // });

        // --- sorting ---
        results.sort((a, b) => {
            //console.info("results.sort (this.state.sortby)", this.state.sortby);

            var nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();
            var brandA = a.brand ? a.brand.toLowerCase() : "",
                brandB = b.brand ? b.brand.toLowerCase() : "";

            switch (this.state.sortby) {
                case "name":
                    if (this.state.sortasc === true) {
                        if (nameA <= nameB) return -1;
                        if (nameA > nameB) return 1;
                    } else {
                        if (nameA <= nameB) return 1;
                        if (nameA > nameB) return -1;
                    }
                    break;
                case "brand":
                    if (this.state.sortasc === true) {
                        if (brandA <= brandB) return -1;
                        if (brandA > brandB) return 1;
                    } else {
                        if (brandA <= brandB) return 1;
                        if (brandA > brandB) return -1;
                    }
                    break;
                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting

        return this.state.filter.length === 0
            ? results.slice(
                  (this.state.pagination_currentPage - 1) *
                      config.PAGINATION_PERPAGE,
                  this.state.pagination_currentPage * config.PAGINATION_PERPAGE
              )
            : results;
    };

    handleMenuClick = (_evt, _account) => {
        console.info("handleMenuClick _evt", _evt, " _account", _account);
        const keyParts = _evt.key.split("|");

        console.info("keyParts", keyParts);
        let report = null;

        if (keyParts[0] === "normal") {
            report = this.state.reports.filter(
                (report) => report.key === keyParts[1]
            )[0];
        }
        if (keyParts[0] === "custom") {
            report = this.state.custom_reports.filter(
                (creport) => creport.key === keyParts[1]
            )[0];
        }

        console.info("handleModalClick _evt", _evt, "report", report);
        this.setState({ selected_report: report, report_account: _account });

        this.props.store.showModalByKey("reports_modal");
        // this.props.store.registerModalListener("reports_modal", (_action) => {
        //     console.info("handleModalListener _action", _action);
        //     if (_action === "hide") this.loadInformation();
        // });
    };

    handleEditCallback = async (_action, _account_key) => {
        console.info(
            "handleEditCallback _action",
            _action,
            "_account_key",
            _account_key
        );
        if (_action === "hide") {
            //this.loadInformation();
            let { accounts } = this.state;
            const updatedAccount = await this.props.store.getAccount(
                _account_key
            );
            //console.info("updatedAccount", updatedAccount);
            let aIndexToUpdate = null;
            accounts.map((a, aIndex) => {
                if (a.key === _account_key) {
                    //console.log("Winner for update");
                    aIndexToUpdate = aIndex;
                }
                return false;
            });
            if (aIndexToUpdate !== null) {
                accounts[aIndexToUpdate] = Object.assign({}, updatedAccount);
            }

            //console.info("accounts", accounts);
            this.setState({ accounts });
        }
    };
    render() {
        const loggedUser = this.props.store.getLoggedUser;

        const pagesCount =
            this.props.store.getAllAccountsLength / config.PAGINATION_PERPAGE;
        // console.log("pagesCount = " + pagesCount);

        const menu = (_account) =>
            this.state.reports && this.state.custom_reports ? (
                <Menu onClick={(e) => this.handleMenuClick(e, _account)}>
                    {this.state.reports
                        .filter((r) => r.key.indexOf("channel") !== -1)
                        .map((report, reportIndex) => {
                            return (
                                <Menu.Item key={`normal|${report.key}`}>
                                    {report.name}
                                </Menu.Item>
                            );
                        })}
                    <Menu.Divider />
                    {this.state.custom_reports
                        .filter(
                            (cr) =>
                                cr.original_report.key.indexOf("channel") !== -1
                        )
                        .map((creport, creportIndex) => {
                            return (
                                <Menu.Item
                                    key={`custom|${creport.key}`}
                                    style={{ color: "#888" }}
                                >
                                    {creport.name}
                                </Menu.Item>
                            );
                        })}
                </Menu>
            ) : null;

        const header = (
            <SocialListHeader
                title="Accounts"
                newButtonClickHandle={this.showModal}
                newButtonText={
                    loggedUser.level === "admin" ? "New account" : null
                }
                newButtonTooltip={tooltips.ACCOUNTS.NEWBUTTON}
                bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
                searchClass="social_filterChannelContainer"
                searchExtra={
                    <Fragment>
                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) =>
                                this.setState({
                                    filter_facebook:
                                        !this.state.filter_facebook,
                                })
                            }
                        >
                            <div
                                className="social_iconCircle_facebook"
                                style={{
                                    width: 18,
                                    height: 15,
                                    margin: 5,
                                    opacity:
                                        this.state.filter_facebook === true
                                            ? 1
                                            : 0.3,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) =>
                                this.setState({
                                    filter_instagram:
                                        !this.state.filter_instagram,
                                })
                            }
                        >
                            <div
                                className="social_iconCircle_instagram"
                                style={{
                                    width: 18,
                                    height: 15,
                                    margin: 5,
                                    opacity:
                                        this.state.filter_instagram === true
                                            ? 1
                                            : 0.3,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) =>
                                this.setState({
                                    filter_twitter: !this.state.filter_twitter,
                                })
                            }
                        >
                            <div
                                className="social_iconCircle_twitter"
                                style={{
                                    width: 18,
                                    height: 15,
                                    margin: 5,
                                    opacity:
                                        this.state.filter_twitter === true
                                            ? 1
                                            : 0.3,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) =>
                                this.setState({
                                    filter_linkedin:
                                        !this.state.filter_linkedin,
                                })
                            }
                        >
                            <div
                                className="social_iconCircle_linkedin"
                                style={{
                                    width: 18,
                                    height: 15,
                                    margin: 5,
                                    opacity:
                                        this.state.filter_linkedin === true
                                            ? 1
                                            : 0.3,
                                }}
                            />
                        </div>
                    </Fragment>
                }
            />
        );

        let body =
            this.props.store.getAllAccountsLength === 0 ? (
                <Empty />
            ) : (
                <List
                    size="small"
                    className="AccountsList"
                    header={
                        <Fragment>
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Col style={{ width: 25, lineHeight: 1.2 }}>
                                    {/* <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                    /> */}
                                </Col>
                                <Col style={{ width: 195 }}>
                                    <SocialListColumn
                                        sortkey="name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Account
                                    </SocialListColumn>
                                </Col>
                                <Col
                                    style={{
                                        width: 80,
                                    }}
                                >
                                    &nbsp;Channel
                                </Col>

                                <Col>
                                    <SocialListColumn
                                        sortkey="brand"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Brand
                                    </SocialListColumn>
                                </Col>
                                <Col>Tools</Col>
                            </Row>
                        </Fragment>
                    }
                    footer={
                        loggedUser.level === "admin" &&
                        pagesCount > 1 &&
                        this.state.filter.length === 0 ? (
                            <div style={{ textAlign: "right" }}>
                                <Pagination
                                    size="small"
                                    defaultCurrent={1}
                                    total={
                                        this.props.store.getAllAccountsLength
                                    }
                                    pageSize={config.PAGINATION_PERPAGE}
                                    current={this.state.pagination_currentPage}
                                    onChange={this.paginationSetPage}
                                />
                            </div>
                        ) : null
                    }
                    bordered
                    dataSource={this.getListingDataSource()}
                    renderItem={(item) => (
                        <List.Item
                            key={item.id}
                            className={
                                this.listBulk &&
                                this.listBulk.isChecked(item.key)
                                    ? this.listBulk.getCheckedLength() === 1
                                        ? "social_Selected_withTools"
                                        : "social_Selected"
                                    : null
                            }
                            actions={[
                                <Tooltip
                                    key="1"
                                    title={tooltips.ACCOUNTS.LIST_EDIT}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showModalForEdit(_evt, item);
                                        }}
                                    >
                                        <Icon type="edit" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="2"
                                    title={tooltips.ACCOUNTS.LIST_DELETE}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Icon
                                        type="delete"
                                        onClick={(evt) => {
                                            this.handleDeleteAccount(evt, item);
                                        }}
                                        style={{
                                            color: scss_variables.scss_color_pallete_error,
                                        }}
                                    />
                                </Tooltip>,
                                <Tooltip
                                    key="3"
                                    title={tooltips.ACCOUNTS.LIST_CHANNELS}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showChannels(_evt, item);
                                        }}
                                    >
                                        {/* <Icon
                                        type="notification"
                                        style={{ marginRight: 5 }}
                                    /> */}
                                        <Icon type="cluster" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="4"
                                    title={tooltips.ACCOUNTS.LIST_REPORT}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Dropdown overlay={menu(item)}>
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                        >
                                            <Icon
                                                type="pie-chart"
                                                style={{ marginRight: 5 }}
                                            />
                                            <Icon type="down" />
                                        </Button>
                                    </Dropdown>
                                </Tooltip>,
                                <Tooltip
                                    key="5"
                                    title={tooltips.ACCOUNTS.LIST_QUEUE}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showQueue(_evt, item);
                                        }}
                                    >
                                        {/* <Icon
                                        type="bars"
                                        style={{ marginRight: 5 }}
                                    /> */}
                                        <Icon type="hourglass" />
                                        {/* (
                                    {typeof item.queue !== "undefined" &&
                                    item.queue.length === 0
                                        ? "0"
                                        : typeof item.queue !== "undefined" &&
                                          item.queue.length !== 0
                                        ? item.queue.length
                                        : "0"}
                                    ) */}
                                    </Button>
                                </Tooltip>,
                            ]}
                        >
                            <List.Item.Meta
                                style={{ width: 220, flex: "none" }}
                                title={
                                    <Fragment>
                                        <Checkbox
                                            onChange={(e) =>
                                                this.listBulk.handleCheck(
                                                    item.key
                                                )
                                            }
                                            checked={
                                                this.listBulk
                                                    ? this.listBulk.isChecked(
                                                          item.key
                                                      )
                                                    : false
                                            }
                                            style={{ marginRight: 10 }}
                                        />

                                        <div>
                                            {item.name}{" "}
                                            {/* <br/> ({item.email}) */}
                                            {item.brand
                                                ? `(${item.brand})`
                                                : null}
                                        </div>
                                    </Fragment>
                                }
                                // description={
                                //     <Fragment>
                                //         {item.location &&
                                //         item.location.length > 0 ? (
                                //             <Fragment>
                                //                 <Breadcrumb>
                                //                     {item.location.map(
                                //                         (loc) => (
                                //                             <Breadcrumb.Item
                                //                                 key={loc}
                                //                             >
                                //                                 <span>
                                //                                     {loc}
                                //                                 </span>
                                //                             </Breadcrumb.Item>
                                //                         )
                                //                     )}
                                //                 </Breadcrumb>
                                //             </Fragment>
                                //         ) : null}

                                //         {/* TODO: Add Twitter here
                                //          */}
                                //     </Fragment>
                                // }
                            />
                            <div style={{ width: 80, marginBottom: 0 }}>
                                {item.channels &&
                                    item.channels.map &&
                                    item.channels
                                        .filter((channel) => {
                                            if (
                                                this.state.filter_facebook ===
                                                    true &&
                                                channel.type === "facebook"
                                            )
                                                return true;
                                            if (
                                                this.state.filter_instagram ===
                                                    true &&
                                                channel.type === "instagram"
                                            )
                                                return true;
                                            if (
                                                this.state.filter_twitter ===
                                                    true &&
                                                channel.type === "twitter"
                                            )
                                                return true;
                                            if (
                                                this.state.filter_linkedin ===
                                                    true &&
                                                channel.type === "linkedin"
                                            )
                                                return true;
                                            if (
                                                this.state.filter_youtube ===
                                                    true &&
                                                channel.type === "youtube"
                                            )
                                                return true;
                                            return false;
                                        })
                                        .map((channel, cIndex) => {
                                            return (
                                                <Fragment key={cIndex}>
                                                    <SocialChannel
                                                        store={this.props.store}
                                                        // type="filled"
                                                        icon
                                                        channel={channel}
                                                    />
                                                    <br />
                                                </Fragment>
                                            );
                                        })}
                            </div>
                            {/* <div
                                style={{
                                    flexGrow: 1,
                                    textAlign: "right",
                                }}
                            >
                                {!item.paid_until ? (
                                    <Fragment>
                                        <Tag
                                            color={this.props.store.getTagColorForType(
                                                "neutral"
                                            )}
                                        >
                                            Unpaid
                                        </Tag>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <span
                                            style={
                                                {
                                                    //color: this.props.store.getTagColorForType("success"),
                                                }
                                            }
                                        >
                                            <strong>Active</strong>
                                            <br />
                                            through{" "}
                                            {moment(item.paid_until)
                                                .utc()
                                                .format("l")}
                                        </span>
                                    </Fragment>
                                )}
                            </div> */}
                            <div>{item.brand}</div>
                        </List.Item>
                    )}
                />
            );

        if (this.state.redirect === true) {
            return <Redirect to="/login" />;
        }

        return (
            <Fragment>
                {header}
                {body}

                <AccountsListModal
                    data={this.state.edit_data}
                    store={this.props.store}
                    callback={this.handleEditCallback}
                />
                <ReportsModal
                    report={this.state.selected_report}
                    report_account={this.state.report_account}
                    store={this.props.store}
                />
            </Fragment>
        );
    }
}

export default observer(AccountsList);
