import React, { Component, Fragment } from "react";
import {
    Empty,
    List,
    Button,
    Tag,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Modal,
    Checkbox,
    Icon,
} from "antd";
import tooltips from "./../tooltips";

const { Title } = Typography;
const { Search } = Input;

const SocialListHeader = ({
    title,
    newButtonClickHandle,
    newButtonText,
    newButtonTooltip,
    extraButtonClickHandle,
    extraButtonText,
    extraButtonTooltip,
    titleExt,
    bulk,
    searchChangeHandle,
    searchClass,
    searchExtra,
}) => {
    return (
        <Row type="flex" justify="space-between" className="social_ListHeader">
            <Col>
                {bulk ? (
                    <div>
                        <Checkbox
                            // onChange={e => this.handleBulkAll(e)}
                            onChange={(e) => bulk.handleAll(e.target.checked)}
                            style={{ marginRight: 10 }}
                        />
                        {bulk && bulk.renderOptionsAsList()}
                    </div>
                ) : null}

                {/* <Badge
                count={this.props.store.getAllTags.length}
                style={{ backgroundColor: "#1890ff" }}
            /> */}
            </Col>
            <Col>
                <Title level={3} style={{ paddingRight: 5 }}>
                    {title}
                </Title>
            </Col>
            <Col>
                {searchClass ? (
                    <div className={searchClass ? searchClass : null}>
                        <Search
                            placeholder="filter"
                            onSearch={searchChangeHandle}
                            onChange={(e) => searchChangeHandle(e.target.value)}
                            style={{ width: 200 }}
                        />
                        {searchExtra ? searchExtra : null}
                    </div>
                ) : (
                    <Search
                        placeholder="filter"
                        onSearch={searchChangeHandle}
                        onChange={(e) => searchChangeHandle(e.target.value)}
                        style={{ width: 200 }}
                    />
                )}

                {newButtonText ? (
                    <Tooltip
                        title={newButtonTooltip}
                        mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                    >
                        <Button
                            type="primary"
                            // icon="plus"
                            size="small"
                            onClick={newButtonClickHandle}
                            style={{
                                marginLeft: 10,
                                marginTop: 5,
                                fontSize: 12,
                            }}
                        >
                            {newButtonText}
                        </Button>
                    </Tooltip>
                ) : null}
                {extraButtonText ? (
                    <Tooltip
                        title={newButtonTooltip}
                        mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                    >
                        <Button
                            type="primary"
                            // icon="plus"
                            size="small"
                            onClick={extraButtonClickHandle}
                            style={{
                                marginLeft: 10,
                                marginTop: 5,
                                fontSize: 12,
                            }}
                        >
                            {extraButtonText}
                        </Button>
                    </Tooltip>
                ) : null}
            </Col>
        </Row>
    );
};

export default SocialListHeader;
