import React, { Component, Fragment } from "react";
import EditableTagGroup from "./../EditableTagGroup";
import { social_notification } from "../../functions";
import {
    Button,
    Input,
    Form,
    Modal,
    Row,
    Col,
    Tree,
    Alert,
    Tag,
    Icon,
} from "antd";
import { observer } from "mobx-react";
import SocialChannel from "./../SocialChannel";

const { Search } = Input;
const { TreeNode } = Tree;

/* Add/Edit Form */
const ModalForm = Form.create({ name: "form_in_modal" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                checkedKeys: [],
                filter: "",
            };
        }

        componentDidMount = () => {
            let selected_channels =
                this.props.data && this.props.data.channels
                    ? this.props.data.channels.map((channel) => channel.id)
                    : [];
            console.info(
                "componentDidMount selected_channels",
                selected_channels,
                "this.props.data.tags",
                this.props.data && this.props.data.tags
            );

            let all_channels = [];
            this.props.store.getAllAccounts.filter((account) => {
                let accountChannels =
                    this.props.store.getAccountChannelsFromAccountData(account);

                if (accountChannels.length > 0) {
                    all_channels = all_channels.concat(accountChannels);
                }
                return false;
            });

            console.info(
                "all_channels",
                all_channels.map((c) => c.name)
            );

            this.props.form.setFieldsValue({
                channels: selected_channels,
            });

            this.setState({
                channels: selected_channels,
                all_channels,
                tags:
                    this.props.data && this.props.data.tag
                        ? [this.props.data.tag]
                        : [],
            });
        };

        handleTagsChange = (_tags) => {
            console.info("handleTagsChange", _tags);
            //this.props.data = tags;
            this.setState({ tags: _tags });

            try {
                this.props.form.setFieldsValue({ tags: _tags });
            } catch (error) {}
        };

        handleAddAllChannels = () => {
            console.log(`handleAddAllChannels`);
            let onlyKeys = [];
            this.state.all_channels.map((c) => {
                onlyKeys.push(c.id);
                return true;
            });
            console.info(`handleAddAllChannels onlyKeys`, onlyKeys);

            //this.props.data.accounts = onlyKeys;

            this.setState({ channels: onlyKeys });

            this.props.form.setFieldsValue({
                channels: onlyKeys,
            });
        };

        renderTreeNodes = (data) => {
            //console.log("renderTreeNodes filter=" + this.state.filter);
            let nodes = data.map((item) => {
                if (this.state.filter) {
                    //console.log("we have filter");
                    //console.info("renderTreeNodes item", item);
                    if (
                        item.name
                            .toUpperCase()
                            .indexOf(this.state.filter.toUpperCase()) !== -1 ||
                        item.account_name
                            .toUpperCase()
                            .indexOf(this.state.filter.toUpperCase()) !== -1
                    ) {
                        return (
                            <TreeNode
                                key={item.id}
                                title={
                                    <div
                                        style={{
                                            display: "block",
                                            width: "100%",
                                        }}
                                    >
                                        <SocialChannel
                                            store={this.props.store}
                                            //type="filled"
                                            icon
                                            channel={item}
                                            extra={item.account_name}
                                        />
                                        {/* <Tag
                                            color={this.props.store.getTagColorForType(
                                                item.type
                                            )}
                                            style={{
                                                maxWidth: 220,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                marginTop: 2,
                                                marginBottom: -5,
                                            }}
                                        >
                                            <Icon
                                                type={item.type}
                                                theme={
                                                    item.type === "facebook" ||
                                                    item.type === "instagram" ||
                                                    item.type === "linkedin"
                                                        ? "filled"
                                                        : null
                                                }
                                                style={{
                                                    marginRight: 5,
                                                }}
                                            />
                                            {item.name}
                                        </Tag>
                                        <span>&nbsp;- {item.account_name}</span> */}
                                    </div>
                                }
                            />
                        );
                    }
                } else {
                    return (
                        <TreeNode
                            key={item.id}
                            title={
                                <div>
                                    <SocialChannel
                                        store={this.props.store}
                                        // type="filled"
                                        icon
                                        channel={item}
                                        extra={item.account_name}
                                    />

                                    {/* <Tag
                                        color={this.props.store.getTagColorForType(
                                            item.type
                                        )}
                                        style={{
                                            maxWidth: 220,
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            marginTop: 2,
                                            marginBottom: -5,
                                        }}
                                    >
                                        <Icon
                                            type={item.type}
                                            theme={
                                                item.type === "facebook" ||
                                                item.type === "instagram" ||
                                                item.type === "linkedin"
                                                    ? "filled"
                                                    : null
                                            }
                                            style={{
                                                marginRight: 5,
                                            }}
                                        />
                                        {item.name}
                                    </Tag>
                                    <span>&nbsp;- {item.account_name}</span> */}
                                </div>
                            }
                        />
                    );
                }
                return true;
            });
            nodes = nodes.filter((n) => (typeof n !== "undefined" ? n : false));
            // console.info("renderTreeNodes nodes", nodes);

            return nodes.length > 0 ? nodes : [];
        };

        onCheck = (checkedKeys) => {
            //console.log("onCheck", checkedKeys);
            this.setState({ channels: checkedKeys });

            this.props.form.setFieldsValue({
                channels: checkedKeys,
            });
        };

        onTagsSearchChange = (_filter) => {
            console.info("onTagsSearchChange _filter", _filter);
            this.setState({ filter: _filter });
        };

        render() {
            let allChannels = this.state.all_channels;

            const { form, data } = this.props;
            const { getFieldDecorator } = form;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            console.info("allChannels", allChannels);

            const filtered_treeNodes =
                allChannels && allChannels.length > 0
                    ? this.renderTreeNodes(allChannels)
                    : [];

            return (
                <Form {...formItemLayout}>
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                    <Form.Item label="Tags">
                        {getFieldDecorator("tags", {
                            //getValueFromEvent: handleGetTags
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the tags to be applied to the accounts!",
                                },
                            ],
                            initialValue: data && data.tag ? [data.tag] : [],
                            onChange: this.handleTagsChange,
                        })(
                            <EditableTagGroup
                                options={this.props.store.getAllTags.map(
                                    (tag) => tag.tag
                                )}
                                tag_color={this.props.store.getTagColorForType(
                                    "tag"
                                )}
                            />
                        )}
                    </Form.Item>
                    {/* <pre>tags: {JSON.stringify(this.state.tags, null, 2)}</pre> */}
                    {this.state.tags && this.state.tags.length > 0 ? (
                        <Form.Item label="Channels">
                            {getFieldDecorator("channels", {
                                rules: [
                                    {
                                        required: true,
                                        message:
                                            "Please select at least one channel the tags to be applied to!",
                                    },
                                ],
                            })(
                                <div>
                                    <Row type="flex" justify="space-between">
                                        <Col>
                                            <Search
                                                placeholder="filter"
                                                onSearch={
                                                    this.onTagsSearchChange
                                                }
                                                onChange={(e) =>
                                                    this.onTagsSearchChange(
                                                        e.target.value
                                                    )
                                                }
                                                style={{ width: 200 }}
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                onClick={
                                                    this.handleAddAllChannels
                                                }
                                            >
                                                Check all
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Tree
                                        checkable
                                        selectable={false}
                                        // onExpand={this.onExpand}
                                        // expandedKeys={this.state.expandedKeys}
                                        // autoExpandParent={this.state.autoExpandParent}

                                        onCheck={this.onCheck}
                                        checkedKeys={this.state.channels}
                                        // onSelect={this.onSelect}
                                        // selectedKeys={this.state.selectedKeys}
                                        style={{
                                            marginLeft: -27,
                                            width: "60%",
                                        }}
                                    >
                                        {filtered_treeNodes}
                                    </Tree>
                                    {filtered_treeNodes.length === 0 ? (
                                        <Alert
                                            message="- No channels -"
                                            type="warning"
                                            style={{ width: 200, fontSize: 12 }}
                                        />
                                    ) : null}
                                </div>
                            )}
                        </Form.Item>
                    ) : null}
                </Form>
            );
        }
    }
);

/* Add/Edit Form */
class TagsListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener("tags_modal_add", (_action) => {
            this.setState({
                modal_visible: _action === "show" ? true : false,
            });
        });
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("tags_modal_add");
    };

    onModalApply = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                social_notification("validation_error", "error");
                return;
            }

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            this.props.store.applyTagsToChannels({
                ...values,
                //...this.state.edit_data
            });

            form.resetFields();
            this.onModalCancel();
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "tags_modal_add",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={
                            !this.props.data ||
                            (this.props.data && !this.props.data.tag)
                                ? "Create new tags"
                                : "Apply new tags"
                        }
                        okText={
                            !this.props.data ||
                            (this.props.data && !this.props.data.tag)
                                ? "Create"
                                : "Apply"
                        }
                        onCancel={this.onModalCancel}
                        onOk={this.onModalApply} //onApply
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={this.props.data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(TagsListModal);
