import React, { Component, Fragment } from "react";

import { observer } from "mobx-react";
import tooltips from "../../tooltips";

import {
    Button,
    Tooltip,
    Tree,
    Tabs,
    Icon,
    Checkbox,
    Tag,
    Input,
    Empty,
    Badge,
} from "antd";
import SocialChannel from "./../SocialChannel";

const { TabPane } = Tabs;
const { TreeNode } = Tree;
const { Search } = Input;

const styleCollapsed = {};
const styleExpanded = {
    width: 227,
    paddingLeft: 30,
    paddingRight: 30,
};
const styleExpandedButton = {
    border: "1px solid green",
};
const styleCollapsedButton = {
    top: 0,
    right: 0,
    position: "absolute",
};
class ScheduleFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            accountsKeys: [],
            filter_tags: "",
            filter_accounts: "",
            filter_masterposts: "",
            filter_categories: "",
        };
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.text !== this.props.text) {
        //   this.updateAndNotify();
        // }
        console.info("ScheduleFilter componentDidUpdate");
        if (
            prevProps.accountsList.length === 0 &&
            this.props.accountsList.length > 0
        )
            this.parseAccountsList();
    }

    parseAccountsList = () => {
        let stateAccountsKeys = this.state.accountsKeys;

        console.info(
            "parseAccountsList this.props.accountsList",
            this.props.accountsList
        );
        const allAccounts = this.props.accountsList.map((a) => {
            let accountData = {
                title: `${a.name}`,
                key: a.key,
                children: [],
            };

            stateAccountsKeys.push(a.key);
            if (a.facebook && a.facebook.data && a.facebook.data.length > 0) {
                let facebookChannels = a.facebook.data.map((d) => {
                    const obj = {
                        title: d.name,
                        type: "facebook",
                        key:
                            a.key +
                            "|facebook|" +
                            d.id +
                            "|" +
                            a.name +
                            "|" +
                            d.name,
                    };

                    stateAccountsKeys.push(obj.key);

                    return obj;
                });

                accountData.children = accountData.children.concat(
                    facebookChannels
                );
            }
            if (a.instagram && a.instagram.data.length > 0) {
                let instagramChannels = a.instagram.data.map((d) => {
                    const obj = {
                        title: d.meta.name,
                        type: "instagram",
                        key:
                            a.key +
                            "|instagram|" +
                            d.instagram_account_id +
                            "|" +
                            a.name +
                            "|" +
                            d.meta.name,
                    };
                    stateAccountsKeys.push(obj.key);
                    return obj;
                });

                accountData.children = accountData.children.concat(
                    instagramChannels
                );
            }
            return accountData;
        });

        this.setState({
            allAccounts: allAccounts,
            accountsKeys: stateAccountsKeys,
        });
    };

    showModal = () => {
        console.log("showModal");

        this.setState({
            modal_visible: true,
            //edit_data: {}
        });
    };
    handleExpandToggle = () => {
        console.log("handleExpandToggle expanded=" + this.state.expanded);
        let { expanded } = this.state;
        this.setState({ expanded: !expanded });
    };
    tabsCallback = (key) => {
        console.log(key);
    };

    renderChannelsNodes = (data) =>
        data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {this.renderChannelsNodes(item.children)}
                    </TreeNode>
                );
            } else {
                return (
                    <TreeNode
                        title={
                            <Fragment>
                                <Icon
                                    type={item.type}
                                    theme="filled"
                                    style={{ marginRight: 5 }}
                                />
                                {item.title}
                            </Fragment>
                        }
                        key={item.key}
                        dataRef={item}
                    ></TreeNode>
                );
            }
            //return <TreeNode key={item.key} {...item} />;
        });

    onSearchChange = (_search, _type) => {
        console.info("onSearchChange _search", _search, "_type", _type);
        //this.setState({ filter: _search });
        switch (_type) {
            case "tags":
                this.setState({ filter_tags: _search });
                break;
            case "accounts":
                this.setState({ filter_accounts: _search });
                break;
            case "masterposts":
                this.setState({ filter_masterposts: _search });
                break;
            case "categories":
                this.setState({ filter_categories: _search });
                break;
            default:
                break;
        }
    };

    render() {
        console.info("ScheduleFilter Render");

        const filtered_categories_list = this.props.categoriesList.filter(
            (c) => {
                let flag = true;
                if (
                    this.state.filter_categories.length > 0 &&
                    c.category
                        .toUpperCase()
                        .indexOf(this.state.filter_categories.toUpperCase()) ===
                        -1
                ) {
                    flag = false;
                }
                if (flag === true) return c;
                return false;
            }
        );

        const filtered_masterposts_list =
            this.props.masterpostsList &&
            this.props.masterpostsList.filter((c) => {
                let flag = true;
                if (
                    this.state.filter_masterposts.length > 0 &&
                    c.name &&
                    c.name
                        .toUpperCase()
                        .indexOf(
                            this.state.filter_masterposts.toUpperCase()
                        ) === -1
                ) {
                    flag = false;
                }
                if (flag === true) return c;
                return false;
            });

        const filtered_tags_list = this.props.tagsList.filter((t) => {
            let flag = true;
            if (
                this.state.filter_tags.length > 0 &&
                t.tag
                    .toUpperCase()
                    .indexOf(this.state.filter_tags.toUpperCase()) === -1
            ) {
                flag = false;
            }
            if (flag === true) return t;
            return false;
        });

        const filtered_social_list = this.props.tagsList.filter((t) => {
            let flag = true;
            if (
                this.state.filter_tags.length > 0 &&
                t.tag
                    .toUpperCase()
                    .indexOf(this.state.filter_tags.toUpperCase()) === -1
            ) {
                flag = false;
            }
            if (flag === true) return t;
            return false;
        });

        // const {
        //     filter_facebook,
        //     filter_instagram,
        //     filter_twitter,
        //     toggle_filter_facebook,
        //     toggle_filter_instagram,
        //     toggle_filter_twitter
        // } = this.props;

        return (
            <div className="social_ScheduleFilterContainer">
                <div
                    style={this.state.expanded ? styleExpanded : styleCollapsed}
                >
                    <Button
                        style={
                            this.state.expaded
                                ? styleExpandedButton
                                : styleCollapsedButton
                        }
                        icon={this.state.expanded ? "right" : "filter"}
                        onClick={this.handleExpandToggle}
                    />
                    {this.state.expanded ? (
                        <Tabs
                            style={{ marginTop: 30 }}
                            defaultActiveKey="1"
                            onChange={this.tabsCallback}
                            className="social_TabsOnlyIcons"
                        >
                            {/* <TabPane
                                tab={
                                    <Tooltip title="Accounts">
                                        <Badge
                                            dot={
                                                filtered_accounts_with_channels_length !==
                                                this.props.checkedAccounts
                                                    .length
                                            }
                                        >
                                            <Icon type="team" />
                                        </Badge>
                                    </Tooltip>
                                }
                                key="1"
                            >
                                
                                <Row type="flex" justify="space-between">
                                    <Col span={14}>
                                        <Search
                                            placeholder="filter"
                                            onSearch={e =>
                                                this.onSearchChange(
                                                    e,
                                                    "accounts"
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col
                                        span={10}
                                        style={{
                                            textAlign: "right",
                                            paddingTop: 3
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "inline-block",
                                                cursor: "pointer"
                                            }}
                                            onClick={e =>
                                                this.props.toggle_filter_channel(
                                                    "facebook"
                                                )
                                            }
                                        >
                                            <Icon
                                                type="facebook"
                                                theme="filled"
                                                style={{
                                                    fontSize: 25,
                                                    color: filter_facebook
                                                        ? this.props.store.getTagColorForType(
                                                              "facebook"
                                                          )
                                                        : "#ccc",
                                                    marginLeft: 5
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "inline-block",
                                                cursor: "pointer"
                                            }}
                                            onClick={e =>
                                                this.props.toggle_filter_channel(
                                                    "instagram"
                                                )
                                            }
                                        >
                                            <Icon
                                                type="instagram"
                                                theme="filled"
                                                style={{
                                                    fontSize: 25,
                                                    color: filter_instagram
                                                        ? this.props.store.getTagColorForType(
                                                              "instagram"
                                                          )
                                                        : "#ccc",
                                                    marginLeft: 5
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "inline-block",
                                                cursor: "pointer"
                                            }}
                                            onClick={e =>
                                                this.props.toggle_filter_channel(
                                                    "twitter"
                                                )
                                            }
                                        >
                                            <Icon
                                                type="twitter"
                                                style={{
                                                    fontSize: 25,
                                                    color: filter_twitter
                                                        ? this.props.store.getTagColorForType(
                                                              "twitter"
                                                          )
                                                        : "#ccc",
                                                    marginLeft: 5
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Tree
                                    checkable
                                    selectable={false}
                                    // onExpand={this.onExpand}
                                    // expandedKeys={this.state.expandedKeys}
                                    // autoExpandParent={this.state.autoExpandParent}

                                    onCheck={this.props.handleAccountsCheck}
                                    checkedKeys={this.props.checkedAccounts}
                                    // onSelect={this.onSelect}
                                    //selectedKeys={this.state.accountsKeys}
                                >
                                    {treeNodes}
                                </Tree>
                            </TabPane> */}
                            <TabPane
                                tab={
                                    <Tooltip
                                        title={tooltips.SCHEDULE.FILTER_SOCIAL}
                                        mouseEnterDelay={
                                            tooltips.SETTINGS.mouseEnterDelay
                                        }
                                    >
                                        <Badge
                                            dot={
                                                filtered_social_list.filter(
                                                    (t) => t.checked
                                                ).length !==
                                                this.props.tagsList.length
                                            }
                                        >
                                            <Icon type="facebook" />
                                        </Badge>
                                    </Tooltip>
                                }
                                key="1"
                            >
                                {/* filter = {this.state.filter_tags} */}
                                <Checkbox
                                    style={{
                                        display: "block",
                                        margin: "5px 0",
                                    }}
                                    onChange={(e) =>
                                        this.props.toggle_filter_channel(
                                            "facebook"
                                        )
                                    }
                                    checked={this.props.filter_facebook}
                                >
                                    <Icon
                                        type="facebook"
                                        theme="filled"
                                        style={{ marginRight: 5 }}
                                    />
                                    Facebook
                                </Checkbox>

                                <Checkbox
                                    style={{
                                        display: "block",
                                        margin: "5px 0",
                                    }}
                                    onChange={(e) =>
                                        this.props.toggle_filter_channel(
                                            "instagram"
                                        )
                                    }
                                    checked={this.props.filter_instagram}
                                >
                                    <Icon
                                        type="instagram"
                                        theme="filled"
                                        style={{ marginRight: 5 }}
                                    />
                                    Instagram
                                </Checkbox>

                                <Checkbox
                                    style={{
                                        display: "block",
                                        margin: "5px 0",
                                    }}
                                    onChange={(e) =>
                                        this.props.toggle_filter_channel(
                                            "twitter"
                                        )
                                    }
                                    checked={this.props.filter_twitter}
                                >
                                    <Icon
                                        type="twitter"
                                        style={{ marginRight: 5 }}
                                    />
                                    Twitter
                                </Checkbox>
                                <Checkbox
                                    style={{
                                        display: "block",
                                        margin: "5px 0",
                                    }}
                                    onChange={(e) =>
                                        this.props.toggle_filter_channel(
                                            "linkedin"
                                        )
                                    }
                                    checked={this.props.filter_linkedin}
                                >
                                    <Icon
                                        type="linkedin"
                                        theme="filled"
                                        style={{ marginRight: 5 }}
                                    />
                                    Linkedin
                                </Checkbox>
                                {/* <pre>{JSON.stringify(this.props, null, 2)}</pre> */}
                            </TabPane>
                            {/* <TabPane
                                tab={
                                    <Tooltip
                                        title={tooltips.SCHEDULE.FILTER_TAGS}
                                        mouseEnterDelay={
                                            tooltips.SETTINGS.mouseEnterDelay
                                        }
                                    >
                                        <Badge
                                            dot={
                                                filtered_tags_list.filter(
                                                    (t) => t.checked
                                                ).length !==
                                                this.props.tagsList.length
                                            }
                                        >
                                            <Icon type="tags" />
                                        </Badge>
                                    </Tooltip>
                                }
                                key="2"
                            >
                                <Search
                                    placeholder="filter"
                                    onSearch={(e) =>
                                        this.onSearchChange(e, "tags")
                                    }
                                    onChange={(e) =>
                                        this.onSearchChange(
                                            e.target.value,
                                            "tags"
                                        )
                                    }
                                    style={{
                                        marginBottom: 7,
                                    }}
                                />
                                {filtered_tags_list.length > 0 ? (
                                    <Fragment>
                                        {filtered_tags_list.map(
                                            (tag, tagIndex) => {
                                                return (
                                                    <Checkbox
                                                        key={tagIndex}
                                                        style={{
                                                            display: "block",
                                                            margin: "5px 0",
                                                        }}
                                                        onChange={(e) =>
                                                            this.props.handleTagsChange(
                                                                e,
                                                                tag.tag
                                                            )
                                                        }
                                                        checked={tag.checked}
                                                    >
                                                        <Tag
                                                            color={this.props.store.getTagColorForType(
                                                                "tag"
                                                            )}
                                                            key={tag.tag}
                                                        >
                                                            {tag.tag}
                                                        </Tag>
                                                    </Checkbox>
                                                );
                                            }
                                        )}
                                    </Fragment>
                                ) : (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No matching tags"
                                    />
                                )}
                            </TabPane> */}
                            <TabPane
                                tab={
                                    <Tooltip
                                        title={
                                            tooltips.SCHEDULE.FILTER_MASTERPOSTS
                                        }
                                        mouseEnterDelay={
                                            tooltips.SETTINGS.mouseEnterDelay
                                        }
                                    >
                                        <Badge
                                            dot={
                                                filtered_masterposts_list.filter(
                                                    (c) => c.checked
                                                ).length !==
                                                this.props.masterpostsList
                                                    .length
                                            }
                                        >
                                            <Icon type="container" />
                                        </Badge>
                                    </Tooltip>
                                }
                                key="3"
                            >
                                {/* filter = {this.state.filter_masterposts} */}
                                <Search
                                    placeholder="filter"
                                    onSearch={(e) =>
                                        this.onSearchChange(e, "masterposts")
                                    }
                                    onChange={(e) =>
                                        this.onSearchChange(
                                            e.target.value,
                                            "masterposts"
                                        )
                                    }
                                    style={{
                                        marginBottom: 7,
                                    }}
                                />
                                <Button
                                    type="default"
                                    style={{
                                        fontSize: "0.8rem !important",
                                        padding: "3px 10px",
                                        height: "unset",
                                    }}
                                    onClick={(e) => {
                                        this.setState({});
                                        this.props.handleDiselectMasterposts();
                                    }}
                                >
                                    Unselect All
                                </Button>
                                {filtered_masterposts_list.length > 0 ? (
                                    <Fragment>
                                        {filtered_masterposts_list.map(
                                            (masterpost, masterpostIndex) => {
                                                return (
                                                    <Checkbox
                                                        key={masterpostIndex}
                                                        style={{
                                                            display: "block",
                                                            margin: "5px 0",
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({});
                                                            this.props.handleMasterpostsChange(
                                                                e,
                                                                masterpost.key
                                                            );
                                                        }}
                                                        checked={
                                                            masterpost.checked
                                                        }
                                                    >
                                                        {masterpost.name}
                                                    </Checkbox>
                                                );
                                            }
                                        )}
                                    </Fragment>
                                ) : (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No matching masterposts"
                                    />
                                )}
                                {/* <pre>
                                    {JSON.stringify(
                                        this.props.masterpostsList,
                                        null,
                                        2
                                    )}
                                </pre> */}
                            </TabPane>
                            <TabPane
                                tab={
                                    <Tooltip
                                        title={
                                            tooltips.SCHEDULE.FILTER_CATEGORIES
                                        }
                                        mouseEnterDelay={
                                            tooltips.SETTINGS.mouseEnterDelay
                                        }
                                    >
                                        <Badge
                                            dot={
                                                filtered_categories_list.filter(
                                                    (c) => c.checked
                                                ).length !==
                                                this.props.categoriesList.length
                                            }
                                        >
                                            <Icon type="tags" />
                                        </Badge>
                                    </Tooltip>
                                }
                                key="4"
                            >
                                {/* filter = {this.state.filter_categories} */}
                                <Search
                                    placeholder="filter"
                                    onSearch={(e) =>
                                        this.onSearchChange(e, "categories")
                                    }
                                    onChange={(e) =>
                                        this.onSearchChange(
                                            e.target.value,
                                            "categories"
                                        )
                                    }
                                    style={{
                                        marginBottom: 7,
                                    }}
                                />
                                {filtered_categories_list.length > 0 ? (
                                    <Fragment>
                                        {filtered_categories_list.map(
                                            (category, categoryIndex) => {
                                                return (
                                                    <Checkbox
                                                        key={categoryIndex}
                                                        style={{
                                                            display: "block",
                                                            margin: "5px 0",
                                                        }}
                                                        onChange={(e) =>
                                                            this.props.handleCategoriesChange(
                                                                e,
                                                                category.category
                                                            )
                                                        }
                                                        checked={
                                                            category.checked
                                                        }
                                                    >
                                                        <Tag
                                                            color={this.props.store.getTagColorForType(
                                                                "category"
                                                            )}
                                                            key={
                                                                category.category
                                                            }
                                                        >
                                                            {category.category}
                                                        </Tag>
                                                    </Checkbox>
                                                );
                                            }
                                        )}
                                    </Fragment>
                                ) : (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No matching categories"
                                    />
                                )}
                                {/* <pre>
                                    {JSON.stringify(
                                        this.props.categoriesList,
                                        null,
                                        2
                                    )}
                                </pre> */}
                            </TabPane>
                        </Tabs>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default observer(ScheduleFilter);
