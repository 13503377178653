import React, { Fragment } from "react";
import { Divider, Icon, Tree, Alert, Tag } from "antd";

const AdsListModalFormPanelConfirm = (props, context) => {
    const { state, store } = props;
    // const { fetching } = state;

    const currentStepKey = props.steps[props.step].key;

    const getSelectedTreeNodes = () => {
        let treeData = props.ongetSelectedTreeData();
        //console.info("getSelectedTreeNodes treeData", treeData);
        const filtered_treeNodes =
            treeData && props.onrenderTreeNodes(treeData);
        return filtered_treeNodes;
    };

    const confirmPanel_selectedMasterposts = (
        <Tree
            selectable={false}
            defaultExpandAll={true}
            // onExpand={this.onExpand}
            // expandedKeys={this.state.expandedKeys}
            // autoExpandParent={this.state.autoExpandParent}
            //     onCheck={this.onCheck}
            checkedKeys={state.masterposts}
            // onSelect={this.onSelect}
            // selectedKeys={this.state.selectedKeys}
            style={{ marginLeft: -26 }}
        >
            {getSelectedTreeNodes()}
        </Tree>
    );

    const getSelectedNewAdAccountsTreeNodes = () => {
        let treeData = props.ongetSelectedNewAccountsTreeData();
        //console.info("getSelectedTreeNodes treeData", treeData);
        const filtered_treeNodes =
            treeData && props.onrenderAccountsTreeNodes(treeData);
        return filtered_treeNodes;
    };

    const confirmPanel_selectedChannels = (
        <Tree
            selectable={false}
            defaultExpandAll={true}
            // onExpand={this.onExpand}
            // expandedKeys={this.state.expandedKeys}
            // autoExpandParent={this.state.autoExpandParent}
            //onCheck={this.onCheck}
            checkedKeys={state.masterposts}
            // onSelect={this.onSelect}
            // selectedKeys={this.state.selectedKeys}
            style={{ marginLeft: -26 }}
        >
            {getSelectedNewAdAccountsTreeNodes()}
        </Tree>
    );

    const getSelectedNewAdLinkedinAccountsTreeNodes = () => {
        let treeData = props.ongetSelectedNewLinkedinAccountsTreeData();
        //console.info("getSelectedTreeNodes treeData", treeData);
        const filtered_treeNodes =
            treeData && props.onrenderAccountsTreeNodesForLinkedin(treeData);
        return filtered_treeNodes;
    };

    const confirmPanel_selectedLinkedinAccounts = (
        <Tree
            selectable={false}
            defaultExpandAll={true}
            // onExpand={this.onExpand}
            // expandedKeys={this.state.expandedKeys}
            // autoExpandParent={this.state.autoExpandParent}
            //onCheck={this.onCheck}
            checkedKeys={state.newad_linkedin_accounts}
            // onSelect={this.onSelect}
            // selectedKeys={this.state.selectedKeys}
            style={{ marginLeft: -26 }}
        >
            {getSelectedNewAdLinkedinAccountsTreeNodes()}
        </Tree>
    );
    const getSelectedNewAdTwitterAccountsTreeNodes = () => {
        let treeData = props.ongetSelectedNewTwitterAccountsTreeData();
        //console.info("getSelectedTreeNodes treeData", treeData);
        const filtered_treeNodes =
            treeData && props.onrenderAccountsTreeNodesForTwitter(treeData);
        return filtered_treeNodes;
    };

    const confirmPanel_selectedTwitterAccounts = (
        <Tree
            selectable={false}
            defaultExpandAll={true}
            // onExpand={this.onExpand}
            // expandedKeys={this.state.expandedKeys}
            // autoExpandParent={this.state.autoExpandParent}
            //onCheck={this.onCheck}
            checkedKeys={state.newad_twitter_accounts}
            // onSelect={this.onSelect}
            // selectedKeys={this.state.selectedKeys}
            style={{ marginLeft: -26 }}
        >
            {getSelectedNewAdTwitterAccountsTreeNodes()}
        </Tree>
    );

    return (
        <div>
            <Alert
                message="Confirm settings"
                description="Please review the settings below and click `Generate` button to create the paid units"
                type="info"
                banner
                showIcon={true}
            />
            <div
                style={{
                    height: "calc(100vh - 480px)",
                    overflowY: "auto",
                }}
            >
                {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                <br />
                <br />
                <strong>Paid unit type</strong>
                <br />
                {state.data.type === "ad" ? "Ad" : null}
                {state.data.type === "fb_boost" ? "Facebook Boost post" : null}
                <br />
                <br />

                <strong>Name</strong>
                <br />
                {state.ad_name}
                <br />
                <br />
                <strong>Campaigns</strong>
                <br />
                {state.campaigns &&
                    state.campaigns.map((campaign) => {
                        return (
                            <Tag
                                key={campaign}
                                color={store.getTagColorForType("campaign")}
                            >
                                {campaign}
                            </Tag>
                        );
                    })}
                <br />
                <br />
                <strong>Brand</strong>
                <br />
                {state.brand}
                <Divider orientation={"left"}>Details</Divider>

                {currentStepKey === "fb_boost_confirm" ? (
                    <Fragment>
                        <strong>Posts to Boost</strong>
                        <br />
                        {confirmPanel_selectedMasterposts}
                        <br />

                        <strong>Forced Targeting</strong>
                        {props.onrenderFacebookTargetingResume(
                            state.facebook_targeting
                        )}
                    </Fragment>
                ) : null}
                {currentStepKey === "ad_confirm" ? (
                    <Fragment>
                        <strong>Ad Networks</strong>
                        <br />
                        {state.networks_facebook === true ? (
                            <Fragment>
                                <Icon
                                    type="facebook"
                                    theme="filled"
                                    style={{
                                        color: store.getTagColorForType(
                                            "facebook"
                                        ),
                                        marginRight: 5,
                                    }}
                                />
                                Facebook
                                <br />
                            </Fragment>
                        ) : null}
                        {state.networks_linkedin === true ? (
                            <Fragment>
                                <Icon
                                    type="linkedin"
                                    theme="filled"
                                    style={{
                                        color: store.getTagColorForType(
                                            "linkedin"
                                        ),
                                        marginRight: 5,
                                    }}
                                />
                                Linkedin
                                <br />
                            </Fragment>
                        ) : null}
                        {state.networks_twitter === true ? (
                            <Fragment>
                                <Icon
                                    type="twitter"
                                    style={{
                                        color: store.getTagColorForType(
                                            "twitter"
                                        ),
                                        marginRight: 5,
                                    }}
                                />
                                Twitter
                                <br />
                            </Fragment>
                        ) : null}
                        <br />
                        <br />
                        <strong>Selected channels</strong>
                        <br />
                        {state.networks_facebook === true ? (
                            <Fragment>{confirmPanel_selectedChannels}</Fragment>
                        ) : null}
                        {state.networks_linkedin === true ? (
                            <Fragment>
                                {confirmPanel_selectedLinkedinAccounts}
                            </Fragment>
                        ) : null}
                        {state.networks_twitter === true ? (
                            <Fragment>
                                {confirmPanel_selectedTwitterAccounts}
                            </Fragment>
                        ) : null}
                        {/* <pre>
                        {JSON.stringify(
                            state.newad_accounts,
                            null,
                            2
                        )}
                    </pre> */}
                        <br />

                        <strong>Forced Targeting</strong>
                        {props.onrenderFacebookTargetingResume(
                            state.facebook_targeting
                        )}
                    </Fragment>
                ) : null}
            </div>
        </div>
    );
};

export default AdsListModalFormPanelConfirm;
