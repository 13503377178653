import React, { Component } from "react";
import { Tag, Input, Tooltip, Icon, AutoComplete } from "antd";
import scss_variables from "./../_variables.scss";

/* Edit tags */
class EditableTagGroup extends Component {
    state = {
        inputVisible: false,
        inputValue: "",
        options: [],
    };

    changed = false;

    constructor(props) {
        super(props);
        this.state = {
            tags: this.props.value ? this.props.value : [],
            element_title: this.props.element_title
                ? this.props.element_title
                : "Tag",
            tag_color: this.props.tag_color ? this.props.tag_color : "blue",
            options: this.props.options ? this.props.options : [],
        };
        //console.info("EditableTagGroup constructor state", this.state);
        //console.info("EditableTagGroup constructor props", this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.info(
        //     "EditableTagGroup componentDidUpdate prevProps",
        //     prevProps,
        //     "prevState",
        //     prevState,
        //     "this.props",
        //     this.props
        // );
        if (prevProps.value.length === 0 && this.props.value.length > 0)
            this.setState({ tags: this.props.value });
    }

    handleClose = (removedTag) => {
        const tags = this.state.tags.filter((tag) => tag !== removedTag);
        //console.log(tags);
        this.setState({ tags });
        this.props.onChange(tags);
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = (e) => {
        //console.info("handleInputChange e", e);
        this.setState({ inputValue: e });
        this.changed = true;
    };

    handleInputConfirm = (e) => {
        // console.info("handleInputConfirm e", e, "this.changed=" + this.changed);
        const inputValue = typeof e === "undefined" ? this.state.inputValue : e;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        //console.log(tags);
        this.setState({
            tags,
            inputVisible: false,
            inputValue: "",
        });
        this.props.onChange(tags);
        this.changed = false;
    };

    handleInputSelect = (e) => {
        //console.info("handleInputSelect e", e, "this.changed=" + this.changed);
        //if (!this.changed) {
        this.setState({ inputValue: e });
        this.handleInputConfirm(e);
        //}
    };
    handleInputBlur = (e) => {
        //console.info("handleInputBlur e", e, "this.changed", this.changed);
        if (this.changed) {
            this.setState({
                inputVisible: false,
                inputValue: "",
            });
            this.handleInputConfirm(e);
            this.changed = false;
        }
    };
    handleInputKeyDown = (e) => {
        //console.info("handleInputKeyDown e", e);
    };
    handlePressEnter = (e) => {
        // console.log(
        //     "handlePressEnter e",
        //     e,
        //     "inputValue",
        //     this.state.inputValue
        // );
        if (e.keyCode === 13) {
            this.handleInputConfirm();
        }
        if (e) {
            e.preventDefault();
        }
    };

    saveInputRef = (input) => (this.input = input);

    render() {
        const { tags, inputVisible, inputValue } = this.state;

        return (
            <div>
                {tags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag
                            key={tag}
                            // closable={index !== 0}
                            closable={true}
                            color={this.state.tag_color}
                            onClose={() => this.handleClose(tag)}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
                {inputVisible && (
                    // <Input
                    //     ref={this.saveInputRef}
                    //     type="text"
                    //     size="small"
                    //     style={{ width: 78 }}
                    //     value={inputValue}
                    //     onChange={this.handleInputChange}
                    //     onBlur={this.handleInputConfirm}
                    //     onPressEnter={this.handleInputConfirm}
                    // />

                    <AutoComplete
                        //value={this.state.inputValue}
                        dataSource={this.state.options.filter((o) => {
                            const option = o.toUpperCase();
                            const inputValue = this.state.inputValue
                                ? this.state.inputValue.toUpperCase()
                                : "";
                            if (inputValue.length === 0) return true;

                            return option.indexOf(inputValue) !== -1;
                        })}
                        //style={{ width: 78 }}
                        onSelect={this.handleInputSelect}
                        onChange={this.handleInputChange}
                        // onSearch={this.handleInputSearch}
                        placeholder="input here"
                        onBlur={this.handleInputBlur}
                        //onKeyDown={this.handleInputKeyDown}
                    >
                        <Input
                            ref={this.saveInputRef}
                            type="text"
                            size="small"
                            value={inputValue}
                            style={{ width: 78 }}
                            //onChange={this.handleInputChange}
                            onPressEnter={this.handlePressEnter}
                        />
                    </AutoComplete>
                )}
                {!inputVisible && (
                    <Tag
                        onClick={this.showInput}
                        style={{
                            background: scss_variables.scss_color_primary,
                            color: "#fff",
                            borderColor: scss_variables.scss_color_primary,
                        }}
                    >
                        <Icon type="plus" /> New {this.state.element_title}
                    </Tag>
                )}
            </div>
        );
    }
}

export default EditableTagGroup;
