import React, { Component, Fragment } from "react";

import moment from "moment";

import { observer } from "mobx-react";
import names from "./../../names";
import { Form, Modal, Divider, Button, Icon } from "antd";

/* Modal Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        componentDidMount = async () => {
            const masterpost = this.props.store.getAllMasterposts.filter(
                (m) => m.key === this.props.data.key
            )[0];

            const masterpostStatus = this.props.store.getMasterpostStatus(
                this.props.data.key
            );
            console.info("masterpostStatus", masterpostStatus);
            this.setState({
                masterpost,
                masterpost_posts: masterpostStatus.posts,
            });

            let total_post_impressions = 0,
                total_post_clicks = 0,
                total_post_reactions_like = 0,
                total_post_reactions_love = 0,
                total_post_reactions_wow = 0,
                total_post_reactions_haha = 0,
                total_post_reactions_sorry = 0,
                total_post_reactions_anger = 0,
                total_post_comments = 0;
            masterpostStatus.posts.map((post) => {
                if (post.results && post.results[0].insights) {
                    post.results[0].insights.data.map((data) => {
                        console.log("data.name = " + data.name);
                        switch (data.name) {
                            case "post_impressions":
                                total_post_impressions =
                                    total_post_impressions +
                                    data.values[0].value;
                                break;
                            case "post_clicks":
                                total_post_clicks =
                                    total_post_clicks + data.values[0].value;
                                break;
                            case "post_reactions_like_total":
                                total_post_reactions_like =
                                    total_post_reactions_like +
                                    data.values[0].value;
                                break;
                            case "post_reactions_love_total":
                                total_post_reactions_love =
                                    total_post_reactions_love +
                                    data.values[0].value;
                                break;
                            case "post_reactions_wow_total":
                                total_post_reactions_wow =
                                    total_post_reactions_wow +
                                    data.values[0].value;
                                break;
                            case "post_reactions_haha_total":
                                total_post_reactions_haha =
                                    total_post_reactions_haha +
                                    data.values[0].value;
                                break;
                            case "post_reactions_sorry_total":
                                total_post_reactions_sorry =
                                    total_post_reactions_sorry +
                                    data.values[0].value;
                                break;
                            case "post_reactions_anger_total":
                                total_post_reactions_anger =
                                    total_post_reactions_anger +
                                    data.values[0].value;
                                break;
                            default:
                                break;
                        }
                        return false;
                    });
                }
                // comments
                if (post.results && post.results[0].comments) {
                    total_post_comments =
                        total_post_comments +
                        post.results[0].comments.data.length;
                }
                return false;
            });

            const stats = {
                total_post_impressions,
                total_post_clicks,
                total_post_reactions_like,
                total_post_reactions_love,
                total_post_reactions_wow,
                total_post_reactions_haha,
                total_post_reactions_sorry,
                total_post_reactions_anger,
                total_post_reactions_total:
                    total_post_clicks +
                    total_post_reactions_like +
                    total_post_reactions_love +
                    total_post_reactions_wow +
                    total_post_reactions_haha +
                    total_post_reactions_sorry +
                    total_post_reactions_anger,
                total_post_comments,
            };
            this.setState({ stats });

            console.log("total_post_impressions = " + total_post_impressions);

            setTimeout(
                function (that) {
                    that.setState({ loading: false });
                },
                500,
                this
            );
        };

        handleCategoriesChange = (_categories) => {
            console.info("handleCategoriesChange", _categories);
            //this.props.data = _categories;
            this.props.form.setFieldsValue({ categories: _categories });
        };
        handleCampaignsChange = (_campaigns) => {
            console.info("handleCampaignsChange", _campaigns);
            //this.props.data = _campaigns;
            this.props.form.setFieldsValue({ campaigns: _campaigns });
        };

        handleEveryNumberChange = (_value) => {
            this.props.data.every_number = _value;
        };

        handleEveryDropdownChange = (_value) => {
            this.props.data.every_dropdown = _value;
        };

        handleScheduledDateChange = (_value) => {
            this.props.data.scheduled_date = _value;
        };

        handlePublishLocalTimeChange = (_evt) => {
            this.props.data.publish_local_time = _evt.target.checked;
        };

        render() {
            const { data } = this.props;

            const antIcon = (
                <Icon type="loading" style={{ fontSize: 24 }} spin />
            );

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 }
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 }
            //     }
            // };
            const formItemLayout = null;

            return this.state.loading ? (
                antIcon
            ) : (
                <Form {...formItemLayout}>
                    {/* <ReactJson src={this.state} displayDataTypes={false} /> */}

                    <Form.Item label="Name">
                        <h2>{data.name}</h2>
                    </Form.Item>
                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Posts
                    </Divider>
                    <h1>{this.state.masterpost_posts.length}</h1>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Impressions
                    </Divider>
                    <h1>{this.state.stats.total_post_impressions}</h1>

                    {/* <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Audience Growth
                    </Divider>
                    <h1>N/A</h1> */}

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Engagement
                    </Divider>
                    <h1>{this.state.stats.total_post_reactions_total}</h1>

                    <Divider orientation="left" style={{ marginBottom: 0 }}>
                        Total Messages
                    </Divider>
                    <h1>{this.state.stats.total_post_comments}</h1>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class MasterpostsListModalPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "masterposts_modal_performance",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener(
            "masterposts_modal_performance"
        );
    };

    onModalCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            if (values.scheduled_date)
                values.scheduled_date = moment()
                    .utc(values.scheduled_date)
                    .valueOf();
            this.props.store.addMasterpost({
                ...values,
                ...this.state.edit_data,
            });

            form.resetFields();
            this.onModalCancel();
        });
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            if (values.scheduled_date)
                values.scheduled_date = moment(values.scheduled_date).valueOf();

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.props.data.key);

            this.props.store.updateMasterpost(this.props.data.key, values);

            form.resetFields();
            this.setState({ modal_visible: false });
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "masterposts_modal_performance",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data, store } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={names.get("MASTERPOST") + " performance"}
                        //okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        // onOk={
                        //     !data.name ? this.onModalCreate : this.onModalEdit
                        // } //onApply
                        maskClosable={false}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={this.onModalCancel}
                            >
                                Close
                            </Button>,
                        ]}
                    >
                        <ModalForm
                            store={store}
                            data={data}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(MasterpostsListModalPerformance);
