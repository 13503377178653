import React, { Component, Fragment } from "react";
import {
    Form,
    Button,
    List,
    Typography,
    Row,
    Col,
    Calendar,
    Tabs,
    Badge,
    Tag,
    Icon,
    Dropdown,
    Menu,
    Modal,
    Tooltip,
} from "antd";
import moment from "moment";

import MasterpostsListModal from "./../Masterposts/MasterpostsListModal";
import MasterpostsListModalNew from "./../Masterposts/MasterpostsListModalNew";

import ScheduleFilter from "./ScheduleFilter";
import ScheduleMove from "./ScheduleMove";
import AdsListModal from "../Ads/AdsListModal";

import { observer } from "mobx-react";
import { social_sleep } from "../../functions";

// Update the weekdaysLabels
// moment.updateLocale("en", {
//     weekdaysMin: [
//         "Sunday",
//         "Monday",
//         "Tuesday",
//         "Wednesday",
//         "Thursday",
//         "Friday",
//         "Saturday",
//     ],
// });

const { Text } = Typography;
const { TabPane } = Tabs;
const ButtonGroup = Button.Group;
const { confirm } = Modal;

class ScheduleContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            day: {
                date: moment(),
                title: "Today",
            },
            schedule: this.props.store.getAllSchedule,
            currentTab: "schedule",
            filter_accountsList: [],
            filter_checkedAccountsKeys: [],
            filter_tags: [],
            filter_masterposts: [],
            filter_categories: [],
            filter_facebook: true,
            filter_instagram: true,
            filter_twitter: true,
            filter_linkedin: true,
            filter_youtube: true,
            calendarDate: moment(),
            calendarMode: "month",
            editData: null,
            masterpost_data: null,
            dropDate: null,
            dropKey: null,
        };

        this.masterpostsModal = React.createRef();
        this.adsModal = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("schedule");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Calendar" }]);

        this.props.store.unregisterModalListener("schedulemove_modal");
        this.props.store.unregisterModalListener("masterposts_modal_new");
    }

    componentDidMount = async () => {
        console.info("SCHEDULE componentDidMount");
        const allAccounts = this.props.store.getAllAccounts;
        let checked_accounts_keys = [];
        allAccounts.map((a) => {
            checked_accounts_keys.push(a.key);
            if (a.facebook && a.facebook.data && a.facebook.data.length > 0) {
                a.facebook.data.map((d) => {
                    checked_accounts_keys.push(
                        a.key +
                            "|facebook|" +
                            d.id +
                            "|" +
                            a.name +
                            "|" +
                            d.name
                    );
                    return false;
                });
            }
            if (a.instagram && a.instagram.data.length > 0) {
                a.instagram.data.map((d) => {
                    checked_accounts_keys.push(
                        a.key +
                            "|instagram|" +
                            d.instagram_account_id +
                            "|" +
                            a.name +
                            "|" +
                            d.meta.name
                    );
                    return false;
                });
            }
            if (a.google && a.google.data && a.google.data.length > 0) {
                a.google.data.map((d) => {
                    checked_accounts_keys.push(
                        a.key +
                            "|youtube|" +
                            d.id +
                            "|" +
                            a.name +
                            "|" +
                            d.snippet.title
                    );
                    return false;
                });
            }
            return false;
        });

        // tags
        const allTags = this.props.store.getAllTags.map((tag) => {
            tag.checked = true;
            return tag;
        });

        // masterposts
        const allMasterposts = this.props.store.getAllMasterposts.map(
            (masterpost) => {
                masterpost.checked = true;
                return masterpost;
            }
        );
        console.info("allMasterposts", allMasterposts);

        // categories
        const allCategories = this.props.store.getAllCategories.map(
            (category) => {
                category.checked = true;
                return category;
            }
        );
        console.info("allCategories", allCategories);

        const filteredMasterposts = await this.filterMasterposts();
        console.info("filteredMasterposts", filteredMasterposts);

        let value = null,
            stateDay = null;
        if (this.props.date) {
            value = moment(this.props.match.params.date, "YYYY-MM-DD");
            stateDay = { date: value, title: value.format("ll") };
            console.log("DID MOUNT > value = " + value.format("YYYY-MM-DD"));
        } else {
            // default date
            value = moment();
            stateDay = { date: value, title: value.format("ll") };
        }
        this.setState({
            filter_accountsList: allAccounts,
            filter_checkedAccountsKeys: checked_accounts_keys,
            filter_tags: allTags,
            filter_masterposts: allMasterposts,
            filter_categories: allCategories,
            filtered_masterposts: filteredMasterposts,
            day: stateDay,
            calendarDate: value,
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        //call your API and update state with new props

        //console.log("UNSAFE_componentWillReceiveProps");

        if (nextProps.date && this.state.currentTab === "schedule") {
            const value = moment(
                nextProps.match.params.date.toString(),
                "YYYY-MM-DD",
                true
            );
            // console.log(
            //     "componentWillReceiveProps -> value",
            //     value,
            //     "format>",
            //     value.format("YYYY-MM-DD")
            // );
            const stateDay = { date: value, title: value.format("ll") };
            this.setState({
                day: stateDay,
                currentTab: "day",
                calendarDate: value,
            });
        }
        if (nextProps.date && this.state.currentTab === "day") {
            // console.log(
            //     "WINNER!!!! nextProps.match.params.date > " +
            //         nextProps.match.params.date
            // );
            const value = moment(
                nextProps.match.params.date.toString(),
                "YYYY-MM-DD",
                true
            );

            // console.info("WINNER!!!! value > ", value);
            const stateDay = { date: value, title: value.format("ll") };
            // console.log("WINNER!!! stateDay", stateDay);
            this.setState({
                day: stateDay,
                currentTab: "day",
                // calendarDate: moment(nextProps.match.params.date),
                calendarDate: value,
            });
        }
        if (nextProps.month) {
            let stateDay = { ...this.state.day };
            stateDay.date = moment();
            stateDay.title = "Today";
            this.setState({
                calendarDate: moment(),
                day: stateDay,
                currentTab: "schedule",
            });
        }
    }

    filterMasterposts = async () => {
        console.log(
            "filteredMasterposts this.state.calendarDate = " +
                this.state.calendarDate.format("YYYY-MM-DD")
        );
        // pause for 100ms
        await social_sleep(100);

        const filteredResults = await this.props.store.getAllMasterposts.filter(
            (s) => {
                //console.info("getScheduleForDate date", date);
                // Matching the month we are looking for
                let validFlag = true;

                if (!s.scheduled_date) return false; // nothing goes if it is not scheduled

                // if (
                //     moment(s.scheduled_date).valueOf() >
                //         this.state.calendarDate.startOf("month").valueOf() &&
                //     moment(s.scheduled_date).valueOf() <
                //         this.state.calendarDate.endOf("month").valueOf()
                // ) {
                //     validFlag = true;
                //     // console.log('moent = '+moment(s.scheduled_date).format('llll'));
                // } else {
                //     return false;
                // }

                // Categores
                let valid_categories = [];
                if (
                    this.state.filter_categories &&
                    this.state.filter_categories.length > 0
                ) {
                    valid_categories = this.state.filter_categories
                        .filter((c) => c.checked)
                        .map((c) => c.category);
                } else {
                    valid_categories = this.props.store.getAllCategories.map(
                        (category) => {
                            return category.category;
                        }
                    );
                }

                if (!s.categories || s.categories.length === 0) {
                    validFlag = true;
                } else {
                    if (
                        s.categories.filter((element) =>
                            valid_categories.includes(element)
                        ).length > 0
                    ) {
                        //validFlag = true;
                    } else {
                        validFlag = false;
                    }
                }

                // Tags
                // let valid_tags = [];
                // if (
                //     this.state.filter_tags &&
                //     this.state.filter_tags.length > 0
                // ) {
                //     valid_tags = this.state.filter_tags
                //         .filter((c) => c.checked)
                //         .map((c) => c.tag);
                // } else {
                //     valid_tags = this.props.store.getAllTags.map((tag) => {
                //         return tag.tag;
                //     });
                // }

                console.info("-s-", s);
                console.log(
                    "this.state.filter_facebook = " + this.state.filter_facebook
                );
                console.log(
                    "this.state.filter_instagram = " +
                        this.state.filter_instagram
                );
                console.log(
                    "this.state.filter_twitter = " + this.state.filter_twitter
                );
                console.log(
                    "this.state.filter_linkedin = " + this.state.filter_linkedin
                );

                let validTargets = s.targets.filter((t) => {
                    if (
                        t.social_type_facebook === true &&
                        this.state.filter_facebook === true
                    )
                        return t;
                    if (
                        t.social_type_instagram === true &&
                        this.state.filter_instagram === true
                    )
                        return t;
                    if (
                        t.social_type_twitter === true &&
                        this.state.filter_twitter === true
                    )
                        return t;
                    if (
                        t.social_type_linkedin === true &&
                        this.state.filter_linkedin === true
                    )
                        return t;
                    if (
                        t.social_type_youtube === true &&
                        this.state.filter_youtube === true
                    )
                        return t;
                });
                if (validTargets.length === 0) {
                    validFlag = false;
                }
                console.info(
                    "-validTargets-",
                    validTargets,
                    "validFlag = " + validFlag
                );

                // let valid_filtered_masterposts = this.state.filter_masterposts
                //     .filter((m) => {
                //         if (
                //             moment(m.scheduled_date).valueOf() >
                //                 this.state.calendarDate
                //                     .startOf("month")
                //                     .valueOf() &&
                //             moment(m.scheduled_date).valueOf() <
                //                 this.state.calendarDate
                //                     .endOf("month")
                //                     .valueOf() &&
                //             m.name &&
                //             m.scheduled_date &&
                //             m.checked === true
                //         )
                //             return true;
                //     })
                //     .map((m) => m.key);

                // console.info(
                //     "-valid_filtered_masterposts-",
                //     valid_filtered_masterposts
                // );
                // if (
                //     valid_filtered_masterposts.length > 0 &&
                //     !valid_filtered_masterposts.includes(s.key)
                // )
                //     validFlag = false;

                // console.log('s.name = '+s.name+' > validFlag = '+validFlag);
                return validFlag;
            }
        );
        this.setState({ filtered_masterposts: filteredResults });
        return filteredResults;
    };

    onSelect = (value) => {
        // console.info("SCHEDULE onSelect value", value);
        let stateDay = { ...this.state.day };
        stateDay.date = value;
        stateDay.title = value.format("ll");
        // console.info("SCHEDULE onSelect stateDay", stateDay);
        let calendarDate = moment(value.format("YYYY-MM-DD"));
        // console.info(
        //     "SCHEDULE onSelect calendarDate",
        //     calendarDate,
        //     " FORMAT -> " + calendarDate.format("YYYY-MM-DD")
        // );
        this.setState({
            day: stateDay,
            currentTab: "day",
            calendarDate: calendarDate,
        });

        // add to the history of the browser
        this.props.history.push(
            `/dashboard/schedule/${value.format("YYYY-MM-DD")}`
        );
    };

    onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };

    onPanelChange = (value, mode) => {
        console.log("onPanelChange value=" + value + ",mode=" + mode);
        //this.setState({ calendarDate: value, calendarMode: mode });
        if (mode === "schedule") {
            this.props.history.push(`/dashboard/schedule/month`);
        }
        if (mode === "day") {
            this.props.history.push(
                `/dashboard/schedule/${moment().format("YYYY-MM-DD")}`
            );
        }
    };

    handleDragStart = (e, item) => {
        e.dataTransfer.setData("key", item.key);
    };

    handleDragOver = (e, value) => {
        e.preventDefault();
        let elm = document.getElementById(
            "social_ScheduleDate_" + value.format("YYYYMMDD")
        );
        if (!elm.classList.contains("social_dragOver"))
            elm.classList.add("social_dragOver");
    };

    handleDragLeave = (e, value) => {
        e.preventDefault();
        let elm = document.getElementById(
            "social_ScheduleDate_" + value.format("YYYYMMDD")
        );
        if (elm.classList.contains("social_dragOver"))
            elm.classList.remove("social_dragOver");
    };

    handleDrop = (e, value) => {
        e.preventDefault();
        let elm = document.getElementById(
            "social_ScheduleDate_" + value.format("YYYYMMDD")
        );
        if (elm.classList.contains("social_dragOver"))
            elm.classList.remove("social_dragOver");

        const drop_key = e.dataTransfer.getData("key");
        //console.log('drop_key = '+drop_key+", date="+value.format('llll'));

        // masterposts
        const droppedMasterpost = this.props.store.getAllMasterposts.filter(
            (mp) => mp.key === drop_key
        )[0];

        this.setState({
            dropDate: value,
            dropKey: drop_key,
            dropMasterpost: droppedMasterpost,
        });

        this.props.store.showModalByKey("schedulemove_modal");
    };

    dateCellRender = (value) => {
        const listData = this.getScheduleForDate(value);
        //console.info('dateCellRender listData', listData);

        if (!listData) return false;
        //const dateTitle = value.format("ll");
        const errorsCount = listData.filter((ld) => {
            if (ld.status === "error") return ld;
            return false;
        }).length;

        //console.info("dateCellRender for ", value, "listData", listData);
        // console.log("dateCellRender errorsCount = " + errorsCount);
        //const curClass = this.state.dragOverDate == value.valueOf()?"social_ScheduleDate social_dragOver":"social_ScheduleDate";
        return (
            <div
                className={"social_ScheduleDate"}
                onDragOver={(e) => this.handleDragOver(e, value)}
                id={"social_ScheduleDate_" + value.format("YYYYMMDD")}
                onDragLeave={(e) => this.handleDragLeave(e, value)}
                onDrop={(e) => this.handleDrop(e, value)}
            >
                <div
                    style={{
                        position: "absolute",
                        overflow: "hidden",
                        width: "calc(100% - 20px)",
                        fontSize: 12,
                        height: "calc(100% - 40px)",
                    }}
                >
                    <ul style={{ margin: 0, padding: 0 }}>
                        {listData.slice(0, 2).map((item, itemIndex) => {
                            return (
                                <Tooltip
                                    key={itemIndex}
                                    mouseEnterDelay={2}
                                    title={
                                        <div
                                            className={
                                                "social_ScheduleDate_Tooltip"
                                            }
                                        >
                                            <div
                                                className={
                                                    "social_ScheduleDate_Tooltip_Thumbnails"
                                                }
                                            >
                                                {item.targets
                                                    .slice(0, 3)
                                                    .map((target, tIndex) => {
                                                        let thumbUrl =
                                                            target.template_file
                                                                ? !Array.isArray(
                                                                      target.template_file
                                                                  )
                                                                    ? target
                                                                          .template_file
                                                                          .url
                                                                    : target
                                                                          .template_file[0]
                                                                          .url
                                                                : null;
                                                        return target.template_file ? (
                                                            <div
                                                                key={tIndex}
                                                                className={
                                                                    "social_Thumb"
                                                                }
                                                                style={{
                                                                    backgroundImage:
                                                                        "url(" +
                                                                        thumbUrl +
                                                                        ")",
                                                                }}
                                                            />
                                                        ) : null;
                                                    })}
                                            </div>
                                            <b>{item.name}</b>
                                            <br />
                                            {item.targets.length}{" "}
                                            {item.targets.length === 1
                                                ? "target"
                                                : "targets"}
                                            <br />
                                            {moment(item.scheduled_date).format(
                                                "h:mm a"
                                            )}
                                        </div>
                                    }
                                >
                                    <li
                                        className={"social_grabbable"}
                                        //draggable={value>moment()}
                                        draggable
                                        onDragStart={(e) =>
                                            this.handleDragStart(e, item)
                                        }
                                    >
                                        <div
                                            className={
                                                "social_ScheduleDate_Thumbnails"
                                            }
                                        >
                                            {item.targets
                                                .slice(0, 3)
                                                .map((target, tIndex) => {
                                                    let thumbUrl =
                                                        target.template_file
                                                            ? !Array.isArray(
                                                                  target.template_file
                                                              )
                                                                ? target
                                                                      .template_file
                                                                      .url
                                                                : target
                                                                      .template_file[0]
                                                                      .url
                                                            : null;

                                                    return target.template_file ? (
                                                        <div>
                                                            <div
                                                                key={tIndex}
                                                                className={
                                                                    "social_Thumb"
                                                                }
                                                                style={{
                                                                    backgroundImage:
                                                                        "url(" +
                                                                        thumbUrl +
                                                                        ")",
                                                                }}
                                                            />
                                                        </div>
                                                    ) : null;
                                                })}
                                        </div>
                                        <div
                                            className={
                                                "social_ScheduleDate_Holder"
                                            }
                                        >
                                            <span>{item.name}</span>
                                            <div>
                                                {moment(
                                                    item.scheduled_date
                                                ).format("h:mm a")}
                                            </div>
                                        </div>
                                    </li>
                                </Tooltip>
                            );
                        })}
                        {listData.length > 2 ? (
                            <li style={{ listStyle: "none" }}>
                                + {`${Number(listData.length - 2)}`}
                            </li>
                        ) : null}
                    </ul>
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: 8,
                        left: 8,
                        transform: "scale(0.8)",
                        opacity: 0.3,
                    }}
                >
                    {errorsCount > 0 ? <Badge count={listData.length} /> : null}
                </div>
            </div>

            // <ul className="events">
            //     {listData.map(item => (
            //         <li key={item.content}>
            //             <Badge status={item.type} text={item.content} />
            //         </li>
            //     ))}
            // </ul>
        );
    };

    getScheduleForDate = (date) => {
        return (
            this.state.filtered_masterposts &&
            this.state.filtered_masterposts.filter((s) => {
                // console.info("getScheduleForDate date", date);

                let validFlag = false;

                //console.log('date ('+date.format('llll')+') === s.scheduled_date ('+moment(s.scheduled_date).format('llll')+')');
                if (
                    moment(s.scheduled_date).valueOf() >
                        date.startOf("day").valueOf() &&
                    moment(s.scheduled_date).valueOf() <
                        date.endOf("day").valueOf()
                ) {
                    validFlag = true;
                    //console.log('WINNER');
                }

                //console.info("s", s);
                // filter_accountsList: [],
                // filter_checkedAccountsKeys: [],
                // filter_tags: [],
                // filter_masterposts: [],
                // filter_categories: [],
                // filter_facebook: true,
                // filter_instagram: true,
                // filter_twitter: true,

                /*
            checked: true
            deletable: true
            key: "-MOAw6Tt4BYThSJxrBpi"
            name: "Martin Test Dec2020"
            targets: Array(1)
                0:
                    changed_template_file: true
                    custom: ["-MLOiFr9oE1miw1O2uWX|112580093877472"]
                    name: "Target"
                    note: "Target Note"
                    rules_custom: true
                    selection_type: "manual"
                    shortid: "giUqkjM_e"
                    social_type_facebook: true
                    social_type_instagram: false
                    social_type_linkedin: false
                    social_type_twitter: false
                    template_file: {library_file: true, library_key: "-MMzmOjWD9f8gBowwtq5", name: "CPI.png", size: 11002, type: "image/png", …}
                    template_text: "Just a test of the target"
                __proto__: Object
                length: 1
            __proto__: Array(0)
            world_key: "-MGIAmuA8kFcJSMXcAWU"
            
            */

                // // Facebook
                // if (
                //     this.state.filter_facebook === true && s.targets.filter(t=>t.social_type_facebook===true).length>0
                // ) {
                //     validFlag = true;
                // }

                // // Instagram
                // if (
                //     this.state.filter_instagram === true && s.targets.filter(t=>t.social_type_instagram===true).length>0
                // ) {
                //     validFlag = true;
                // }

                // // Twitter
                // if (
                //     this.state.filter_twitter === true && s.targets.filter(t=>t.social_type_twitter===true).length>0
                // ) {
                //     validFlag = true;
                // }

                // // Linkedin
                // if (
                //     this.state.filter_linkedin === true && s.targets.filter(t=>t.social_type_linkedin===true).length>0
                // ) {
                //     validFlag = true;
                // }

                // console.log(
                //     "getScheduleForDate " +
                //         date.startOf("day").format("LLLL") +
                //         " < " +
                //         moment(s.scheduled_date).format("LLLL") +
                //         " < " +
                //         date.endOf("day").format("LLLL")
                // );

                // console.log('s.name = '+s.name+' > validFlag = '+validFlag);
                return validFlag;
            })
        );
    };

    callback = (key) => {
        console.log("callback key=" + key);
        //let stateDay = { ...this.state.day };
        switch (key) {
            case "day":
                //stateDay.list = this.getTodaySchedule(stateDay.day);
                //this.setState({ day: stateDay });
                this.props.history.push(
                    `/dashboard/schedule/${moment().format("YYYY-MM-DD")}`
                );
                break;
            case "schedule":
                // let stateDay = { ...this.state.day };
                // stateDay.date = moment();
                // stateDay.title = "Today";
                // this.setState({ day: stateDay });
                this.props.history.push(`/dashboard/schedule/month`);
                break;
            default:
                break;
        }
        this.setState({ currentTab: key });
    };

    handle_filter_AccountsCheck = (checkedKeys, info) => {
        console.info("handle_filter_AccountsCheck");
        this.setState({ filter_checkedAccountsKeys: checkedKeys });
    };
    handle_filter_TagsChange = (_evt, _tag) => {
        console.info(
            `handle_filter_TagsChange target`,
            _evt.target,
            `tag`,
            _tag
        );
        const stateTags = this.state.filter_tags;
        stateTags.map((t) => {
            if (t.tag === _tag) t.checked = !t.checked;
            return t;
        });
        this.setState({ filter_tags: stateTags });
        this.filterMasterposts();
    };

    handle_diselect_Masterposts = (_evt) => {
        //alert("test");
        const statemasterposts = this.state.filter_masterposts;
        statemasterposts.map((c) => {
            c.checked = false;
            return c;
        });
        console.info("statemasterposts", statemasterposts);
        this.setState({ filter_masterposts: statemasterposts });
    };
    handle_filter_MasterpostsChange = (_evt, _key) => {
        console.info(
            `handle_filter_masterpostsChange target`,
            _evt.target,
            `_key`,
            _key
        );
        const statemasterposts = this.state.filter_masterposts;
        statemasterposts.map((c) => {
            if (c.key === _key) c.checked = !c.checked;
            return c;
        });
        console.info("statemasterposts", statemasterposts);
        this.setState({ filter_masterposts: statemasterposts });
        this.filterMasterposts();
    };

    handle_filter_CategoriesChange = (_evt, _category) => {
        console.info(
            `handle_filter_CategoriesChange target`,
            _evt.target,
            `_category`,
            _category
        );
        const stateCategories = this.state.filter_categories;
        stateCategories.map((c) => {
            if (c.category === _category) c.checked = !c.checked;
            return c;
        });
        this.setState({ filter_categories: stateCategories });
        this.filterMasterposts();
    };

    toggleFilterChannel = async (_channel) => {
        console.log("toggleChannel _channel=" + _channel);

        const {
            filter_facebook,
            filter_instagram,
            filter_twitter,
            filter_linkedin,
        } = this.state;
        let new_filter_facebook = filter_facebook;
        let new_filter_instagram = filter_instagram;
        let new_filter_twitter = filter_twitter;
        let new_filter_linkedin = filter_linkedin;

        switch (_channel) {
            case "facebook":
                new_filter_facebook = !new_filter_facebook;
                this.setState({
                    filter_facebook: new_filter_facebook,
                });
                break;
            case "instagram":
                new_filter_instagram = !new_filter_instagram;
                this.setState({
                    filter_instagram: new_filter_instagram,
                });
                break;
            case "twitter":
                new_filter_twitter = !new_filter_twitter;
                this.setState({
                    filter_twitter: new_filter_twitter,
                });
                break;
            case "linkedin":
                new_filter_linkedin = !new_filter_linkedin;
                this.setState({
                    filter_linkedin: new_filter_linkedin,
                });
                break;
            default:
                break;
        }

        await social_sleep(100);

        const allAccounts = this.props.store.getAllAccounts;
        let checked_accounts_keys = [];
        allAccounts.map((a) => {
            checked_accounts_keys.push(a.key);
            if (
                new_filter_facebook === true &&
                a.facebook &&
                a.facebook.data.length > 0
            ) {
                a.facebook.data.map((d) => {
                    checked_accounts_keys.push(
                        a.key +
                            "|facebook|" +
                            d.id +
                            "|" +
                            a.name +
                            "|" +
                            d.name
                    );
                    return false;
                });
            }
            //console.log("new_filter_instagram=" + new_filter_instagram);
            if (
                new_filter_instagram === true &&
                a.instagram &&
                a.instagram.data.length > 0
            ) {
                a.instagram.data.map((d) => {
                    checked_accounts_keys.push(
                        a.key +
                            "|instagram|" +
                            d.instagram_account_id +
                            "|" +
                            a.name +
                            "|" +
                            d.meta.name
                    );
                    return false;
                });
            }
            return false;
        });
        //console.info("checked_accounts_keys", checked_accounts_keys);
        this.setState({ filter_checkedAccountsKeys: checked_accounts_keys });
        this.filterMasterposts();
    };

    setDateTo = (_type, _daily) => {
        // console.info("setDateTo _type=", _type, "_daily=", _daily);
        // console.log(
        //     "setDateTo typeof this.state.calendarDate",
        //     typeof this.state.calendarDate
        // );
        // console.log(
        //     "setDateTo this.state.calendarDate = " +
        //         this.state.calendarDate.format("YYYY-MM-DD")
        // );

        let new_date = null;
        switch (_type) {
            case "today":
                new_date = moment();
                break;
            case "next":
                console.log(
                    "NEXT this.state.calendarDate = " +
                        this.state.calendarDate.format("YYYY-MM-DD")
                );
                new_date = this.state.calendarDate.add(
                    1,
                    _daily === true
                        ? "days"
                        : this.state.calendarMode === "month"
                        ? "months"
                        : "years"
                );
                break;
            case "prev":
                console.log(
                    "PREV this.state.calendarDate = " +
                        this.state.calendarDate.format("YYYY-MM-DD")
                );
                new_date = this.state.calendarDate.subtract(
                    1,
                    _daily === true
                        ? "days"
                        : this.state.calendarMode === "month"
                        ? "months"
                        : "years"
                );
                break;
            default:
                break;
        }

        // console.info("new_date > ", new_date.format("YYYY-MM-DD"));

        // let stateDay = { ...this.state.day };

        this.setState({
            calendarDate: new_date,
        });
        // add to the history of the browser

        // this.setState({ calendarDate: new_date, day:  });

        if (_daily) {
            this.props.history.push(
                `/dashboard/schedule/${new_date.format("YYYY-MM-DD")}`
            );
            // select the date
            this.onSelect(new_date);
        }
    };

    editMasterpost = (_evt, _masterpost) => {
        //console.info("editmasterpost _evt", _evt, "_masterpost", _masterpost);
        this.setState({ editData: _masterpost });
    };

    handleMenuClick = (_evt, _masterpost) => {
        console.log("handleMenuClick _evt", _evt, "_masterpost", _masterpost);

        _evt.domEvent ? _evt.domEvent.preventDefault() : _evt.preventDefault();

        if (_evt.key === "targets") {
            //https://localhost:3000/dashboard/masterposts/-Lurp3AvXcGc9kEV0tYL/targets
            // this.props.history.push(
            //     `/dashboard/masterposts/${_masterpost.key}/targets`
            // );

            this.setState({
                masterpost_data: _masterpost,
            });
            this.props.store.showModalByKey("masterposts_modal_new");
        }
        if (_evt.key === "status") {
            //https://localhost:3000/dashboard/masterposts/-Lurp3AvXcGc9kEV0tYL/status
            this.props.history.push(
                `/dashboard/masterposts/${_masterpost.key}/status`
            );
        }
        if (_evt.key === "edit") {
            this.setState({
                masterpost_data: _masterpost,
            });

            //this.props.store.showModalByKey("masterposts_modal");
            this.props.store.showModalByKey("masterposts_modal_new");
        }

        if (_evt.key === "move") {
            this.setState({
                dropDate: null,
                dropKey: _masterpost.key,
                dropMasterpost: _masterpost,
            });

            this.props.store.showModalByKey("schedulemove_modal");
        }

        if (_evt.key === "delete") {
            confirm({
                title: "Do you Want to delete this masterpost?",
                content: "The masterpost will be deleted permanently",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                    console.log("OK");
                    //this.props.store.deletemasterpost(_masterpost);
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        }

        if (_evt.key === "boost") {
            console.info("boost _evt", _evt, "_masterpost", _masterpost);
            this.adsModal.current.registerModal();
            this.props.store.showModalByKey("ads_modal", {
                type: "fb_boost",
                masterpost_key: _masterpost.key,
            });
        }

        // if (_evt.key === "discard_selection") this.handleDiscardSelection();
        // if (_evt.key === "delete") this.handleDelete(_notification_key);
        // if (_evt.key === "mark_as_read")
        //     this.handleChangeStatusTo(_notification_key, "read");
        // if (_evt.key === "mark_as_new")
        //     this.handleChangeStatusTo(_notification_key, "new");
        // if (_evt.key === "edit") this.handleEdit(_notification_key);
    };

    render() {
        // const {
        //     //getFieldDecorator,
        //     getFieldsError
        //     //getFieldError,
        //     //isFieldTouched
        // } = this.props.form;

        const dayList = this.getScheduleForDate(this.state.day.date);
        //console.info("dayList for ", this.state.day.date, dayList);

        // console.info(
        //     "RENDER filtered_masterposts ",
        //     this.state.filtered_masterposts
        // );

        const menu_masterpost = (_masterpost) => {
            return (
                <Menu onClick={(e) => this.handleMenuClick(e, _masterpost)}>
                    <Menu.Item key="targets">
                        <Icon type="cluster" /> Targets (
                        {_masterpost.targets.length})
                    </Menu.Item>
                    <Menu.Item key="edit">
                        <Icon type="edit" /> Edit
                    </Menu.Item>

                    <Menu.Item key="move">
                        <Icon type="swap" /> Move
                    </Menu.Item>
                    <Menu.Item key="boost">
                        <Icon type="line-chart" /> Boost
                    </Menu.Item>
                    <Menu.Item key="status">
                        <Icon type="issues-close" /> Status
                    </Menu.Item>
                    <Menu.Item key="duplicate">
                        <Icon type="block" /> Duplicate
                    </Menu.Item>
                    <Menu.Item key="delete">
                        <Icon type="delete" /> Delete
                    </Menu.Item>
                </Menu>
            );
        };

        return (
            <Fragment>
                {/* <Compose
                    store={this.props.store}
                    hideButton
                    editData={this.state.editData}
                /> */}
                <Row
                    type="flex"
                    justify="start"
                    style={{ flexFlow: "row" }}
                    className={"social_Schedule"}
                >
                    <Col>
                        <Tabs
                            activeKey={this.state.currentTab}
                            onChange={this.callback}
                        >
                            <TabPane tab="Calendar" key="schedule">
                                <div
                                    style={{
                                        position: "absolute",
                                        marginTop: 11,
                                        //right: 360,
                                    }}
                                >
                                    <Row type="flex" justify="start">
                                        {/* <Col>
                                            <Title
                                                style={{
                                                    float: "left",
                                                    marginLeft: 20
                                                }}
                                                level={4}
                                            >
                                                {this.state.calendarDate.format(
                                                    "ll"
                                                )}
                                            </Title>
                                        </Col> */}
                                        <Col>
                                            <ButtonGroup>
                                                <Button
                                                    icon="left"
                                                    onClick={(e) =>
                                                        this.setDateTo("prev")
                                                    }
                                                />
                                                <Button
                                                    onClick={(e) =>
                                                        this.setDateTo("today")
                                                    }
                                                >
                                                    {this.state.calendarDate.format(
                                                        "MMMM"
                                                    )}
                                                </Button>
                                                <Button
                                                    icon="right"
                                                    onClick={(e) =>
                                                        this.setDateTo("next")
                                                    }
                                                />
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <Calendar
                                    onPanelChange={this.onPanelChange}
                                    onSelect={this.onSelect}
                                    dateCellRender={this.dateCellRender}
                                    value={this.state.calendarDate}
                                    mode={this.state.calendarMode}
                                />
                            </TabPane>
                            <TabPane tab={this.state.day.title} key="day">
                                <Row>
                                    <Col
                                        style={{
                                            // float: "right",
                                            marginBottom: 20,
                                            marginTop: 12,
                                        }}
                                    >
                                        <ButtonGroup>
                                            <Button
                                                icon="left"
                                                onClick={(e) =>
                                                    this.setDateTo("prev", true)
                                                }
                                            />
                                            <Button
                                                onClick={(e) =>
                                                    this.setDateTo("today")
                                                }
                                            >
                                                {this.state.calendarDate.format(
                                                    "LL"
                                                )}
                                            </Button>
                                            <Button
                                                icon="right"
                                                onClick={(e) =>
                                                    this.setDateTo("next", true)
                                                }
                                            />
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <List
                                    size="large"
                                    bordered
                                    className={"social_ScheduleDate_Listing"}
                                    dataSource={dayList}
                                    renderItem={(item) => (
                                        <List.Item
                                            style={{
                                                cursor: "pointer",
                                                position: "relative",
                                            }}
                                            onClick={(e) =>
                                                this.editMasterpost(e, item)
                                            }
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Row
                                                    type="flex"
                                                    justify="space-between"
                                                    align="top"
                                                    onClick={(e) => {
                                                        e.key = "edit";
                                                        this.handleMenuClick(
                                                            e,
                                                            item
                                                        );
                                                    }}
                                                >
                                                    <Col span={12}>
                                                        <Text
                                                            strong
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Text>
                                                        {item.categories &&
                                                            item.categories.map(
                                                                (cat) => {
                                                                    return (
                                                                        <Tag
                                                                            color={this.props.store.getTagColorForType(
                                                                                "category"
                                                                            )}
                                                                            key={
                                                                                cat
                                                                            }
                                                                        >
                                                                            {
                                                                                cat
                                                                            }
                                                                        </Tag>
                                                                    );
                                                                }
                                                            )}
                                                        {/* <Text disabled>
                                                            {item.account_name}
                                                        </Text> */}
                                                        <Text
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {moment(
                                                                item.scheduled_date
                                                            )
                                                                .local()
                                                                .format("LT")}
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={12}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                "social_ScheduleDate_Thumbnails"
                                                            }
                                                        >
                                                            {item.targets.map(
                                                                (
                                                                    target,
                                                                    tIndex
                                                                ) => {
                                                                    let thumbUrl =
                                                                        target.template_file
                                                                            ? !Array.isArray(
                                                                                  target.template_file
                                                                              )
                                                                                ? target
                                                                                      .template_file
                                                                                      .url
                                                                                : target
                                                                                      .template_file[0]
                                                                                      .url
                                                                            : null;
                                                                    return target.template_file ? (
                                                                        <Fragment
                                                                            key={
                                                                                tIndex
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    "social_Thumb"
                                                                                }
                                                                                style={{
                                                                                    backgroundImage:
                                                                                        "url(" +
                                                                                        thumbUrl +
                                                                                        ")",
                                                                                }}
                                                                            />
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                }}
                                                                            >
                                                                                {target.social_type_facebook ===
                                                                                true ? (
                                                                                    <div
                                                                                        className="social_iconCircle_facebook"
                                                                                        style={{
                                                                                            width: 18,
                                                                                            height: 15,
                                                                                            float: "left",
                                                                                        }}
                                                                                    />
                                                                                ) : // <Icon
                                                                                //     type={
                                                                                //         "facebook"
                                                                                //     }
                                                                                //     theme={
                                                                                //         "filled"
                                                                                //     }
                                                                                //     style={{
                                                                                //         marginRight: 5,
                                                                                //         fontSize: 17,
                                                                                //         color: this.props.store.getTagColorForType(
                                                                                //             "facebook"
                                                                                //         ),
                                                                                //     }}
                                                                                // />
                                                                                null}
                                                                                {target.social_type_twitter ===
                                                                                true ? (
                                                                                    <div
                                                                                        className="social_iconCircle_twitter"
                                                                                        style={{
                                                                                            width: 18,
                                                                                            height: 15,
                                                                                            float: "left",
                                                                                        }}
                                                                                    />
                                                                                ) : // <Icon
                                                                                //     type={
                                                                                //         "twitter"
                                                                                //     }
                                                                                //     style={{
                                                                                //         marginRight: 5,
                                                                                //         fontSize: 17,
                                                                                //         color: this.props.store.getTagColorForType(
                                                                                //             "twitter"
                                                                                //         ),
                                                                                //     }}
                                                                                // />
                                                                                null}
                                                                                {target.social_type_instagram ===
                                                                                true ? (
                                                                                    <div
                                                                                        className="social_iconCircle_instagram"
                                                                                        style={{
                                                                                            width: 18,
                                                                                            height: 15,
                                                                                            float: "left",
                                                                                        }}
                                                                                    />
                                                                                ) : // <Icon
                                                                                //     type={
                                                                                //         "instagram"
                                                                                //     }
                                                                                //     theme={
                                                                                //         "filled"
                                                                                //     }
                                                                                //     style={{
                                                                                //         marginRight: 5,
                                                                                //         fontSize: 17,
                                                                                //         color: this.props.store.getTagColorForType(
                                                                                //             "instagram"
                                                                                //         ),
                                                                                //     }}
                                                                                // />
                                                                                null}
                                                                                {target.social_type_linkedin ===
                                                                                true ? (
                                                                                    <div
                                                                                        className="social_iconCircle_linkedin"
                                                                                        style={{
                                                                                            width: 18,
                                                                                            height: 15,
                                                                                            float: "left",
                                                                                        }}
                                                                                    />
                                                                                ) : // <Icon
                                                                                //     type={
                                                                                //         "linkedin"
                                                                                //     }
                                                                                //     theme={
                                                                                //         "filled"
                                                                                //     }
                                                                                //     style={{
                                                                                //         marginRight: 5,
                                                                                //         fontSize: 17,
                                                                                //         color: this.props.store.getTagColorForType(
                                                                                //             "linkedin"
                                                                                //         ),
                                                                                //     }}
                                                                                // />
                                                                                null}
                                                                            </div>
                                                                            <span>
                                                                                {
                                                                                    target.template_text
                                                                                }
                                                                            </span>
                                                                        </Fragment>
                                                                    ) : null;
                                                                }
                                                            )}
                                                        </div>
                                                    </Col>
                                                    {/* <Col
                                                        span={4}
                                                        style={{
                                                            textAlign: "right"
                                                        }}
                                                    >
                                                        {item.status ? (
                                                            <Tag
                                                                style={{
                                                                    marginTop: 5,
                                                                    marginRight: 0
                                                                }}
                                                                color={this.props.store.getTagColorForType(
                                                                    item.status
                                                                )}
                                                            >
                                                                {item.status}
                                                            </Tag>
                                                        ) : null}
                                                        {item.errors &&
                                                        item.errors.length >
                                                            0 ? (
                                                            <Tooltip
                                                                title={item.errors.join(
                                                                    ","
                                                                )}
                                                            >
                                                                {item.errors.map(
                                                                    (
                                                                        err,
                                                                        errIndex
                                                                    ) => {
                                                                        return (
                                                                            <Fragment
                                                                                key={
                                                                                    errIndex
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        color:
                                                                                            scss_variables.scss_color_pallete_error
                                                                                    }}
                                                                                >
                                                                                    <Icon
                                                                                        style={{
                                                                                            color:
                                                                                                scss_variables.scss_color_pallete_error,
                                                                                            marginRight: 5
                                                                                        }}
                                                                                        type="exclamation-circle"
                                                                                    />
                                                                                    {
                                                                                        err
                                                                                    }
                                                                                </div>
                                                                            </Fragment>
                                                                        );
                                                                    }
                                                                )}
                                                            </Tooltip>
                                                        ) : null}
                                                    </Col> */}
                                                </Row>
                                            </div>
                                            <Dropdown
                                                overlay={menu_masterpost(item)}
                                                style={{}}
                                            >
                                                <Icon
                                                    type="more"
                                                    style={{
                                                        padding: 3,
                                                        position: "absolute",
                                                        right: 10,
                                                        top: 10,
                                                    }}
                                                />
                                            </Dropdown>
                                        </List.Item>
                                    )}
                                />
                            </TabPane>
                        </Tabs>
                    </Col>
                    <Col>
                        <ScheduleFilter
                            store={this.props.store}
                            accountsList={this.state.filter_accountsList}
                            checkedAccounts={
                                this.state.filter_checkedAccountsKeys
                            }
                            handleAccountsCheck={
                                this.handle_filter_AccountsCheck
                            }
                            tagsList={this.state.filter_tags}
                            handleTagsChange={this.handle_filter_TagsChange}
                            // masterpostsList={this.state.filter_masterposts}
                            masterpostsList={this.state.filter_masterposts.filter(
                                (m) => {
                                    const dateClone = moment(
                                        this.state.day.date
                                    );
                                    const startofmonth = Number(
                                        dateClone.startOf("month").valueOf()
                                    );
                                    const endofmonth = Number(
                                        dateClone.endOf("month").valueOf()
                                    );
                                    const mdate = Number(
                                        moment(m.scheduled_date).valueOf()
                                    );
                                    if (
                                        mdate > startofmonth &&
                                        mdate < endofmonth &&
                                        m.name &&
                                        m.scheduled_date
                                    )
                                        return true;
                                }
                            )}
                            handleMasterpostsChange={
                                this.handle_filter_MasterpostsChange
                            }
                            handleDiselectMasterposts={
                                this.handle_diselect_Masterposts
                            }
                            categoriesList={this.state.filter_categories}
                            handleCategoriesChange={
                                this.handle_filter_CategoriesChange
                            }
                            filter_facebook={this.state.filter_facebook}
                            filter_instagram={this.state.filter_instagram}
                            filter_twitter={this.state.filter_twitter}
                            filter_linkedin={this.state.filter_linkedin}
                            toggle_filter_channel={this.toggleFilterChannel}
                        />
                        {/* this.state.filter_checkedAccountsKeys.length ={" "}
                        {this.state.filter_checkedAccountsKeys.length}
                        <pre style={{ maxWidth: 200 }}>
                            {JSON.stringify(
                                this.state.filter_checkedAccountsKeys,
                                null,
                                2
                            )}
                        </pre> */}
                    </Col>
                </Row>
                <MasterpostsListModal
                    data={this.state.masterpost_data}
                    store={this.props.store}
                />
                <MasterpostsListModalNew
                    store={this.props.store}
                    data={this.state.masterpost_data}
                    ref={this.masterpostsModal}
                />
                <AdsListModal
                    // data={this.state.modal_ad_data}
                    store={this.props.store}
                    ref={this.adsModal}
                />
                <ScheduleMove
                    dropDate={this.state.dropDate}
                    dropKey={this.state.dropKey}
                    dropMasterpost={this.state.dropMasterpost}
                    store={this.props.store}
                />
            </Fragment>
        );
    }
}

const WrappedHorizontalForm = Form.create({ name: "horizontal_login" })(
    observer(ScheduleContainer)
);

export default WrappedHorizontalForm;
