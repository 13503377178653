import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import CryptoJS from "crypto-js";
import config from "../config";
import { app, facebookProvider, twitterProvider } from "../base";

// import lorem from "../lorem-lorem.svg";
import PrivacyModal from "./PrivacyModal";
import TermsModal from "./TermsModal";
import { social_reset_css } from "./../functions";

import {
    Form,
    Icon,
    Input,
    Button,
    Alert,
    message,
    Divider,
    Row,
    Col,
} from "antd";

class Login extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.authWithFacebook = this.authWithFacebook.bind(this);
        this.authWithEmailPassword = this.authWithEmailPassword.bind(this);
        this.state = {
            redirect: false,
            error_message: null,
        };
    }

    componentDidMount() {
        // reseting any skin/theme from the app
        social_reset_css();

        if (this.props.match && this.props.match.params.world) {
            var bytes = CryptoJS.AES.decrypt(
                decodeURIComponent(this.props.match.params.world.toString()),
                config.cryptoJsSecret
            );
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            console.log("decrypted text", plaintext);

            this.setState({ world_key: plaintext });
        }
    }

    authWithFacebook() {
        console.log("authed with facebook");
        app.auth()
            .signInWithPopup(facebookProvider)
            .then((result, error) => {
                if (error) {
                    alert("ERROR: " + error);
                } else {
                    console.info("result", result);

                    /*
					this.props.store.saveLoggedUser({
						email: result.user.email,
						displayName:
							result.user.displayName !== null
								? result.user.displayName
								: result.user.email,
						emailVerified: result.user.emailVerified,
						photoURL: result.user.photoURL,
						uid: result.user.uid
					});
					*/

                    this.setState({ redirect: "/dashboard" });
                }
            })
            .catch((error) => {
                message.error(error.message);
            });
    }

    authWithTwitter() {
        console.log("authed with twitter");
        app.auth()
            .signInWithPopup(twitterProvider)
            .then((result, error) => {
                if (error) {
                    alert("ERROR: " + error);
                } else {
                    console.info("result", result);

                    /*
					this.props.store.saveLoggedUser({
						email: result.user.email,
						displayName:
							result.user.displayName !== null
								? result.user.displayName
								: result.user.email,
						emailVerified: result.user.emailVerified,
						photoURL: result.user.photoURL,
						uid: result.user.uid
					});
					*/

                    this.setState({ redirect: "/dashboard" });
                }
            })
            .catch((error) => {
                message.error(error.message);
            });
    }

    authWithEmailPassword(event) {
        event.preventDefault();
        console.log("authed with email & password");
        console.info("this.emailInput", this.emailInput);
        console.info("this.passwordInput", this.passwordInput);

        console.table([
            {
                email: this.emailInput.state.value,
                password: this.passwordInput.state.value,
            },
        ]);

        // Remove error messaging
        this.setState({ error_message: null });

        const email = this.emailInput.state.value;
        const password = this.passwordInput.state.value;

        app.auth()
            .fetchSignInMethodsForEmail(email)
            //.fetchProvidersForEmail(email)
            .then((providers) => {
                console.info("providers", providers);
                if (providers.length === 0) {
                    // create user
                    //return app.auth().createUserWithEmailAndPassword(email, password);
                    message.warning(
                        "We are not creating new users with email/password yet"
                    );
                } else if (providers.indexOf("password") === -1) {
                    // they used facebook
                    //this.loginForm.reset();
                    this.props.form.resetFields();
                    alert("Try an alternative login");
                } else {
                    // sign user in
                    return app
                        .auth()
                        .signInWithEmailAndPassword(email, password);
                }
            })
            .then((user_info) => {
                console.info("then user_info", user_info);
                if (user_info && user_info.user && user_info.user.email) {
                    /*
					this.props.store.saveLoggedUser({
						email: user_info.user.email,
						displayName:
							user_info.user.displayName !== null
								? user_info.user.displayName
								: user_info.user.email,
						emailVerified: user_info.user.emailVerified,
						photoURL: user_info.user.photoURL,
						uid: user_info.user.uid
					});
					*/
                    //this.loginForm.reset();
                    this.props.form.resetFields();
                    this.setState({ redirect: "/dashboard" });
                }
            })
            .catch((error) => {
                //alert("Error: " + error.message);
                this.setState({ error_message: error.message });
            });
    }

    handleRegister = () => {
        this.setState({
            redirect:
                "/register" +
                (this.props.match.params.world
                    ? "/" + this.props.match.params.world
                    : ""),
        });
    };

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirect} />;
        }

        //console.info("this.loginForm", this.loginForm);

        const { getFieldDecorator } = this.props.form;

        const facebookButton = (
            <div
                style={{ textAlign: "center", maxWidth: 310, margin: "0 auto" }}
                className={"FacebookButton"}
            >
                <Button
                    type="primary"
                    icon="facebook"
                    htmlType="submit"
                    className="login-form-button"
                    // style={{ background: "#4267b2" }}
                    onClick={() => {
                        this.authWithFacebook();
                    }}
                >
                    Log-in with Facebook
                </Button>
            </div>
        );

        // const twitterButton = (
        //     <div style={{ textAlign: "center" }} className={"TwitterButton"}>
        //         <Button
        //             type="primary"
        //             icon="twitter"
        //             htmlType="submit"
        //             className="login-form-button"
        //             // style={{ background: "#4267b2" }}
        //             onClick={() => {
        //                 this.authWithTwitter();
        //             }}
        //         >
        //             Log-in with Twitter
        //         </Button>
        //     </div>
        // );

        return (
            <Fragment>
                <div className={"Login"}>
                    <div className={"Header"}>
                        <div className={"Logo"}></div>
                        <div>
                            <Button
                                className="login-form-button"
                                onClick={() => {
                                    this.handleRegister();
                                }}
                            >
                                Sign up
                            </Button>
                            <Button className="login-form-button social_Selected">
                                Log-in
                            </Button>
                        </div>
                    </div>

                    <div className={"Container"}>
                        <div className={"Limiter"}>
                            <div className={"Box"}>
                                <Row gutter={40}>
                                    <Col
                                        span={12}
                                        style={{
                                            borderRight: "1px solid #eee",
                                        }}
                                    >
                                        <Form
                                            onSubmit={(event) =>
                                                this.authWithEmailPassword(
                                                    event
                                                )
                                            }
                                            className="login-form"
                                            ref={(form) => {
                                                this.loginForm = form;
                                            }}
                                        >
                                            <Form.Item label="Email">
                                                {getFieldDecorator("email", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                "Please input your email!",
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        prefix={
                                                            <Icon
                                                                type="user"
                                                                style={{
                                                                    color: "rgba(0,0,0,.25)",
                                                                }}
                                                            />
                                                        }
                                                        placeholder="Email"
                                                        ref={(input) => {
                                                            this.emailInput =
                                                                input;
                                                        }}
                                                    />
                                                )}
                                            </Form.Item>
                                            <br />

                                            <Form.Item label="Password">
                                                {getFieldDecorator("password", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                "Please input your Password!",
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        prefix={
                                                            <Icon
                                                                type="lock"
                                                                style={{
                                                                    color: "rgba(0,0,0,.25)",
                                                                }}
                                                            />
                                                        }
                                                        type="password"
                                                        placeholder="Password"
                                                        ref={(input) => {
                                                            this.passwordInput =
                                                                input;
                                                        }}
                                                    />
                                                )}
                                            </Form.Item>
                                            {this.state.error_message ? (
                                                <Alert
                                                    message="Error"
                                                    description={
                                                        this.state.error_message
                                                    }
                                                    type="error"
                                                    showIcon
                                                />
                                            ) : (
                                                ""
                                            )}
                                            <Form.Item
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="register-form-button"
                                                >
                                                    Log in
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                    <Col
                                        span={12}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <h3>Login using other providers</h3>

                                        <Divider />
                                        {facebookButton}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className={"Footer"}>
                        <div className={"Limiter"}>
                            <PrivacyModal store={this.props.store} />
                            <TermsModal store={this.props.store} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Form.create({ name: "normal_login" })(Login);
