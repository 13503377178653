import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { app } from "../base";

class Logout extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  UNSAFE_componentWillMount() {
    app
      .auth()
      .signOut()
      .then(user => {
        this.props.store.resetLoggedUser();
        this.setState({
          redirect: true
        });
      });
  }

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/login" />;
    }

    return <div>Loading ...</div>;
  }
}

export default Logout;
