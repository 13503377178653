import React, { Component } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./App.scss";

import "./customize.scss"; // customization file

import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";

import { app, firebase_messaging, auth } from "./base";

import store from "./AppStore";

import { Spin, Icon, Row, Col, Typography, Card } from "antd";

import Welcome from "./components/Welcome";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard/Dashboard";
import TwitterHandler from "./components/TwitterHandler";
import LinkedinHandler from "./components/LinkedinHandler";
import GoogleHandler from "./components/GoogleHandler";
import World from "./components/World";
import NonExistingAccount from "./components/NonExistingAccount";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";

const { Title } = Typography;

firebase_messaging &&
    firebase_messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
        // ...
    });

class App extends Component {
    removeAuthListener = null;

    state = {
        ready: false,
        authenticated: false,
        authUser: {},
        noExistingAccount: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            loadingText: "Loading ...",
            unauthPaths: [
                "/register",
                "/login",
                "/twitterhandler",
                "/linkedinhandler",
                "/googlehandler",
                "/world",
                "/noexistingaccount",
                "/privacypolicy",
                "/termsandconditions",
            ],
            authPaths: [
                "/register",
                "/twitterhandler",
                "/googlehandler",
                "/linkedinhandler",
                "/dashboard",
            ],
        };
    }

    componentDidMount() {
        this.removeAuthListener = auth.onAuthStateChanged(async (user) => {
            console.info("onAuthStateChanged user", user);
            if (user) {
                let user_level = "user";
                await auth.currentUser
                    .getIdTokenResult(true) // 1
                    .then(async (idTokenResult) => {
                        if (idTokenResult.claims.level) {
                            user_level = idTokenResult.claims.level;
                        }
                        let world_key,
                            world = null;
                        if (idTokenResult.claims.world_key) {
                            world_key = idTokenResult.claims.world_key;
                        }

                        console.info("claims", idTokenResult.claims);

                        let idToken = await auth.currentUser.getIdToken();
                        console.info("idToken", idToken);
                        // set API headers
                        store.appApi.setHeaders({
                            Authorization: `Bearer ${idToken}`,
                        });

                        let existingAccount = true;
                        existingAccount = await store
                            .getAccountByEmail(user.email)
                            .catch((error) => {
                                console.warn("ERROR", error);
                                console.log("user_level = " + user_level);
                                if (
                                    error.code === "NO_EXISTING_ACCOUNT" &&
                                    user_level === "user"
                                ) {
                                    this.redirectNonExistingAccount();
                                }
                            });

                        // if (user_level === "user") {
                        // }
                        console.info("existingAccount", existingAccount);
                        console.info("user_level", user_level);

                        // Kick if needed
                        if (
                            (existingAccount !== false &&
                                user_level === "user") ||
                            user_level === "admin"
                        ) {
                            // EVERYTHING IS FINE (user with exisitngAccount or admin)
                            // World

                            if (!world_key) {
                                console.log("No World!, Get out!");
                                this.setState({
                                    ready: false,
                                    loadingText: "Assigning...",
                                });
                                //user.email
                                const account = JSON.parse(
                                    JSON.stringify(
                                        await store.getAccountByEmail(
                                            user.email
                                        )
                                    )
                                );

                                world_key = account.world_key;

                                console.info("account", account);
                                await store.setUserWorld(
                                    user.email,
                                    account.world_key
                                );

                                this.setState({
                                    ready: true,
                                    //loadingText: "Assigning...",
                                });
                            }

                            if (world_key) {
                                store.appApi.setPersistantData("post", {
                                    world_key,
                                });
                                world = await store.worldsGet(world_key);
                            }
                            console.info(
                                "User world_key",
                                world_key,
                                "world",
                                world
                            );

                            this.setState({
                                authenticated: true,
                                authUser: user,
                            });

                            let userObj = {
                                email: user.email,
                                displayName: user.displayName
                                    ? user.displayName
                                    : user.email,
                                emailVerified: user.emailVerified,
                                photoURL: user.photoURL,
                                uid: user.uid,
                                level: user_level,
                                idToken: idToken,
                                key:
                                    existingAccount && user_level === "user"
                                        ? existingAccount.key
                                        : null,
                            };
                            if (world_key !== null && world !== null)
                                userObj["world"] = world;

                            store.saveLoggedUser(userObj);

                            // Load settings
                            await store.applyUserSettings();

                            setTimeout(
                                function (that, user) {
                                    that.setState({
                                        ready: true,
                                    });
                                },
                                500,
                                this,
                                user
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.setState({
                    authenticated: false,
                    authUser: {},
                    noExistingAccount: false,
                    ready: true,
                });

                store.resetLoggedUser();
            }
        });
        /*
		this.infoRef = base.syncState("items", {
			context: this,
			state: "info"
		});
        */
    }

    UNSAFE_componentWillUnmount() {
        this.removeAuthListener();
        //base.removeBinding(this.infoRef);
    }

    redirectNonExistingAccount() {
        console.log("redirectNonExistingAccount");
        //alert("we have to kick you out !!!");
        this.setState({
            noExistingAccount: true,
            ready: true,
            authenticated: false,
            authUser: {},
        });
        //window.location = "/noexistingaccount";
        // this.props.history && this.props.history.push("/noexistingaccount");
    }

    // componentDidUpdate(prevProps, prevState) {
    //     console.log(
    //         "componentDidUpdate prevProps",
    //         prevProps,
    //         "prevState",
    //         prevState
    //     );
    //     if (this.state.noExistingAccount === true) {
    //         //window.location.reload();
    //     }
    // }

    render() {
        var currentLocation = window.location;
        //const locationHref = currentLocation.href;

        console.info("App.js currentLocation", currentLocation);
        // console.info("App.js locationHref", locationHref);

        let existsInUnauth = false;
        this.state.unauthPaths.map((path) => {
            if (currentLocation.pathname.indexOf(path) !== -1)
                existsInUnauth = true;
            return false;
        });

        let existsInAuth = false;
        this.state.authPaths.map((path) => {
            if (currentLocation.pathname.indexOf(path) !== -1)
                existsInAuth = true;
            return false;
        });

        console.log("currentLocation.pathname = ", currentLocation.pathname);
        console.log("existsInUnauth = ", existsInUnauth);
        console.log("existsInAuth = ", existsInAuth);

        let body = null,
            redirect_url = null;
        // not ready = loading
        if (!this.state.ready) {
            const antIcon = (
                <Icon type="loading" style={{ fontSize: 24 }} spin />
            );
            body = (
                <div
                    style={{
                        height: "100vh",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Card
                        style={{
                            width: 300,
                            boxShadow: "0px 0px 30px rgba(0,0,0,0.15)",
                            border: "3px solid rgba(0,0,0,0.25)",
                            borderRadius: 5,
                        }}
                        hoverable
                    >
                        <Row>
                            <Col>
                                <Spin indicator={antIcon} />
                                <br />
                                <br />
                                <Title level={4}>
                                    {this.state.loadingText}
                                </Title>
                            </Col>
                        </Row>
                    </Card>
                </div>
            );
        }

        // ready
        if (this.state.ready === true) {
            if (
                !this.state.authenticated &&
                this.state.noExistingAccount === false
            ) {
                // not logged in
                if (existsInUnauth === false) redirect_url = "/welcome";
            }

            if (
                this.state.authenticated &&
                this.state.noExistingAccount === false
            ) {
                // logged in
                if (existsInAuth === false) redirect_url = "/dashboard";
            }

            // Fallback to dashboard
            if (!existsInUnauth && !existsInAuth && redirect_url === null) {
                redirect_url = "/dashbaord";
            }

            console.info("APP READY redirect_url", redirect_url);

            body = (
                <BrowserRouter>
                    {redirect_url ? <Redirect to={redirect_url} /> : null}
                    <Route
                        exact
                        path="/welcome"
                        render={(props) => <Welcome store={store} {...props} />}
                    />
                    <Route
                        exact
                        path="/login/:world"
                        render={(props) => <Login store={store} {...props} />}
                    />
                    <Route
                        exact
                        path="/login"
                        render={(props) => <Login store={store} {...props} />}
                    />
                    <Route
                        exact
                        path="/logout"
                        render={(props) => <Logout store={store} {...props} />}
                    />
                    {this.state.authenticated ? (
                        <Route
                            path="/dashboard"
                            render={(props) => (
                                <Dashboard store={store} {...props} />
                            )}
                        />
                    ) : null}

                    <Route
                        exact
                        path="/register/:world"
                        render={(props) => (
                            <Register store={store} {...props} />
                        )}
                    />
                    <Route
                        exact
                        path="/register/"
                        render={(props) => (
                            <Register store={store} {...props} />
                        )}
                    />
                    <Route
                        exact
                        path="/world"
                        render={(props) => <World store={store} {...props} />}
                    />
                    <Route
                        exact
                        path="/twitterhandler"
                        render={(props) => (
                            <TwitterHandler store={store} {...props} />
                        )}
                    />
                    <Route
                        exact
                        path="/linkedinhandler"
                        render={(props) => (
                            <LinkedinHandler store={store} {...props} />
                        )}
                    />
                    <Route
                        exact
                        path="/googlehandler"
                        render={(props) => (
                            <GoogleHandler store={store} {...props} />
                        )}
                    />
                    <Route
                        exact
                        path="/privacypolicy"
                        render={(props) => (
                            <PrivacyPolicy store={store} {...props} />
                        )}
                    />
                    <Route
                        exact
                        path="/termsandconditions"
                        render={(props) => (
                            <TermsAndConditions store={store} {...props} />
                        )}
                    />
                    <Route exact path="/">
                        <Redirect to="/dashboard" />
                    </Route>
                    {/* <Route
                        exact
                        path={["/noexistingaccount"]}
                        render={(props) => (
                            <NonExistingAccount store={store} {...props} />
                        )}
                    /> */}
                </BrowserRouter>
            );

            // Non existing
            if (this.state.noExistingAccount === true) {
                body = <NonExistingAccount store={store} {...this.props} />;
            }
        }

        return body;
    }
}

export default App;
