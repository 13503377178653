import React, { Fragment } from "react";
import ReactJson from "react-json-view";
import { Typography, Divider } from "antd";

const { Title, Text } = Typography;

const AccountsChannelInfoInstagram = ({ channel_info }) => {
    return (
        <Fragment>
            {/* <ReactJson src={channel_info} /> */}
            {/* <Divider /> */}
            <Text>Name</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.name}
            </Title>

            <Text>ID</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.meta.id}
            </Title>

            <Text>Username</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.meta.username}
            </Title>

            <Text>Media count</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.meta.media_count}
            </Title>

            <Text>Follows count</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.meta.follows_count}
            </Title>

            <Text>Followers count</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.meta.followers_count}
            </Title>
        </Fragment>
    );
};

export default AccountsChannelInfoInstagram;
