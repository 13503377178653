import React, { Component, Fragment } from "react";

import { observer } from "mobx-react";
import tooltips from "../../tooltips";

import {
    Empty,
    List,
    Button,
    Icon,
    Tag,
    Typography,
    Input,
    Row,
    Col,
    Tooltip,
    Modal,
    Badge,
    Spin,
} from "antd";

import scss_variables from "./../../_variables.scss";
import MasterpostsTargetsModal from "./MasterpostsTargetsModal";
import MasterpostsListModalNew from "./MasterpostsListModalNew";
import names from "./../../names";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const loadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class MasterpostsTargets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentId: null,
            currentMasterpost: {},
            filter: "",
            edit_data: {},
            modal_visible: false,
            modal_processing: false,
            upload_percentage: 0,
        };

        this.masterpostsModal = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("masterposts");

        console.info("masterpostsTargets this.props ", this.props);
        this.setState({ currentId: this.props.match.params.id });

        //let allmasterposts = this.props.store.getAllmasterposts;
        //console.info("masterpostsTargets allmasterposts ", allmasterposts);

        this.setState({
            currentMasterpost: this.props.store.getAllMasterposts.filter(
                (c) => c.key === this.props.match.params.id
            )[0],
        });

        // Breadcrumbs
        this.props.store.setBreadcrumbs([
            {
                route: `/dashboard/masterposts`,
                title: names.get("MASTERPOST", true, true),
            },
            { title: "Targets" },
        ]);
    }

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("masterposts_targets_modal");
    };

    showModal = () => {
        console.log("showModal");
        this.setState({
            modal_visible: true,
            modal_processing: false,
            edit_data: {
                //auth: null // empty auth data
                social_type_facebook: true,
                social_type_instagram: true,
                social_type_twitter: true,
            },
        });

        this.props.store.showModalByKey("masterposts_targets_modal");
    };
    showModalForEdit = (_evt, _target, _index) => {
        console.info(
            "showModalForEdit _evt",
            _evt,
            "_target",
            _target,
            "_index",
            _index
        );

        // patching
        _target.location_stringified = JSON.stringify(_target.location);

        this.setState({
            modal_visible: true,
            modal_processing: false,
            edit_data: _target,
            index: _index,
        });

        this.props.store.showModalByKey("masterposts_modal_new", {
            target_shortid: _target.shortid,
            target_index: _index,
        });
        // this.props.store.showModalByKey("campaigns_modal", {
        //     campaigns: [this.state.name],
        // });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    handleDeleteTarget = (_evt, _target, _index) => {
        console.info(
            "handleDeleteTarget _evt",
            _evt,
            "_target",
            _target,
            "_index",
            _index
        );

        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk() {
                let currentMasterpost = that.state.currentMasterpost;
                currentMasterpost.targets.splice(_index, 1);

                that.props.store.updateMasterpost(
                    currentMasterpost.key,
                    currentMasterpost
                );

                that.setState({ currentMasterpost });
            },
        });

        //this.props.store.deleteAccount(_account);
    };

    onShowSizeChange = (current, pageSize) => {
        console.log(current, pageSize);
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    render() {
        // Getting the currentMasterpost
        const currentMasterpost = this.props.store.getAllMasterposts.filter(
            (c) => c.key === this.props.match.params.id
        )[0];

        if (typeof currentMasterpost === "undefined") {
            //return <Redirect to="/dashboard" />;
            return <Spin indicator={loadingIcon} />;
        }

        // console.info(
        //     "masterpostsTargets Render typeof this.state.currentMasterpost.targets",
        //     typeof this.state.currentMasterpost.targets,
        //     "this.state.currentMasterpost.targets.length",
        //     this.state.currentMasterpost.targets.length,
        //     "this.state.currentMasterpost.targets",
        //     this.state.currentMasterpost.targets
        // );

        let header = (
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 10 }}
            >
                <Col>
                    <Title level={3} style={{ float: "left", paddingRight: 5 }}>
                        {currentMasterpost.name} / Targets
                    </Title>
                    <Badge
                        count={
                            typeof currentMasterpost.targets != "undefined"
                                ? currentMasterpost.targets.length
                                : 0
                        }
                        style={{ backgroundColor: "#1890ff" }}
                    />
                </Col>
                <Col>
                    {
                        <Tooltip
                            title={tooltips.MASTERPOSTS.TARGETS_NEWBUTTON}
                            mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
                        >
                            <Button
                                type="ghost"
                                icon="plus"
                                size="small"
                                onClick={this.showModal}
                            >
                                New target
                            </Button>
                        </Tooltip>
                    }
                </Col>
            </Row>
        );

        // Body ---

        let body = (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`No masterpost Targets for ${currentMasterpost.name}`}
            />
        );

        if (
            typeof currentMasterpost.targets !== "undefined" &&
            currentMasterpost.targets.length > 0
        ) {
            body =
                currentMasterpost.targets.length === 0 ? (
                    <Empty />
                ) : (
                    <List
                        size="small"
                        className="MasterpostsTargetsList"
                        header={
                            <Fragment>
                                <Row type="flex" justify="end">
                                    <Col>
                                        <Search
                                            placeholder="filter"
                                            onSearch={this.onSearchChange}
                                            style={{
                                                width: 200,
                                                float: "right",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: 10,
                                        fontSize: 12,
                                        display: "flex",
                                        flex: "1 1",
                                        flexAlign: "start",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Col style={{ flexGrow: 1 }}>
                                        Target name
                                    </Col>
                                    <Col style={{ width: 90 }}>Tools</Col>
                                </Row>
                            </Fragment>
                        }
                        // footer={
                        //     <div style={{ textAlign: "center" }}>
                        //         <Pagination
                        //             showSizeChanger
                        //             onShowSizeChange={this.onShowSizeChange}
                        //             defaultCurrent={3}
                        //             total={500}
                        //         />
                        //     </div>
                        // }
                        bordered
                        dataSource={this.state.currentMasterpost.targets.filter(
                            (t) => {
                                return (
                                    t.name
                                        .toUpperCase()
                                        .indexOf(
                                            this.state.filter.toUpperCase()
                                        ) !== -1 ||
                                    t.note
                                        .toUpperCase()
                                        .indexOf(
                                            this.state.filter.toUpperCase()
                                        ) !== -1 ||
                                    t.tags.filter((t) => {
                                        return (
                                            t
                                                .toUpperCase()
                                                .indexOf(
                                                    this.state.filter.toUpperCase()
                                                ) !== -1
                                        );
                                    }).length > 0
                                );
                            }
                        )}
                        renderItem={(item, iIndex) => (
                            <List.Item
                                key={iIndex}
                                actions={[
                                    <Tooltip
                                        key="edit"
                                        title={
                                            tooltips.MASTERPOSTS
                                                .TARGETS_LIST_EDIT
                                        }
                                        mouseEnterDelay={
                                            tooltips.SETTINGS.mouseEnterDelay
                                        }
                                    >
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                            onClick={(_evt) => {
                                                this.showModalForEdit(
                                                    _evt,
                                                    item,
                                                    iIndex
                                                );
                                            }}
                                        >
                                            <Icon type="edit" />
                                        </Button>
                                    </Tooltip>,
                                    <Tooltip
                                        key="delete"
                                        title={
                                            tooltips.MASTERPOSTS
                                                .TARGETS_LIST_DELETE
                                        }
                                        mouseEnterDelay={
                                            tooltips.SETTINGS.mouseEnterDelay
                                        }
                                    >
                                        <Icon
                                            type="delete"
                                            onClick={(evt) => {
                                                this.handleDeleteTarget(
                                                    evt,
                                                    item,
                                                    iIndex
                                                );
                                            }}
                                            style={{
                                                color: scss_variables.scss_color_pallete_error,
                                            }}
                                        />
                                    </Tooltip>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={
                                        <div key={item.name}>{item.name}</div>
                                    }
                                    description={item.note}
                                />
                                <div>
                                    {item.template_file &&
                                    (item.template_file.type === "image/png" ||
                                        item.template_file.type ===
                                            "image/jpeg" ||
                                        item.template_file.type ===
                                            "image/gif" ||
                                        item.template_file.type ===
                                            "image/jpg") ? (
                                        <a
                                            href={item.template_file.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div
                                                style={{
                                                    backgroundImage:
                                                        "url(" +
                                                        item.template_file.url +
                                                        ")",
                                                    backgroundSize: "cover",
                                                    backgroundRepeat:
                                                        "no-repeat",
                                                    backgroundPosition:
                                                        "center",
                                                    height: 48,
                                                    width: 80,
                                                }}
                                            />
                                        </a>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div>
                                    {item.social_type_facebook && (
                                        <Icon
                                            type="facebook"
                                            theme="filled"
                                            style={{
                                                fontSize: 20,
                                                color: this.props.store.getTagColorForType(
                                                    "facebook"
                                                ),
                                            }}
                                        />
                                    )}
                                    {item.social_type_instagram && (
                                        <Icon
                                            type="instagram"
                                            theme="filled"
                                            style={{
                                                fontSize: 20,
                                                color: this.props.store.getTagColorForType(
                                                    "instagram"
                                                ),
                                            }}
                                        />
                                    )}
                                    {item.social_type_twitter && (
                                        <Icon
                                            type="twitter"
                                            style={{
                                                fontSize: 19,
                                                color: this.props.store.getTagColorForType(
                                                    "twitter"
                                                ),
                                            }}
                                        />
                                    )}
                                    {item.social_type_linkedin && (
                                        <Icon
                                            type="linkedin"
                                            theme="filled"
                                            style={{
                                                fontSize: 19,
                                                color: this.props.store.getTagColorForType(
                                                    "linkedin"
                                                ),
                                            }}
                                        />
                                    )}
                                    {item.tags
                                        ? item.tags.map((tag) => (
                                              <Tag
                                                  color={this.props.store.getTagColorForType(
                                                      "tag"
                                                  )}
                                                  key={tag}
                                              >
                                                  {tag}
                                              </Tag>
                                          ))
                                        : ""}

                                    {item.exclude && item.exclude.length > 0 ? (
                                        <Tooltip
                                            title={
                                                tooltips.MASTERPOSTS
                                                    .TARGETS_LIST_EXCLUDE
                                            }
                                            mouseEnterDelay={
                                                tooltips.SETTINGS
                                                    .mouseEnterDelay
                                            }
                                        >
                                            <br />
                                            <Tag
                                                color={this.props.store.getTagColorForType(
                                                    "default"
                                                )}
                                            >
                                                <Icon
                                                    type="exclamation-circle"
                                                    theme="filled"
                                                    style={{
                                                        marginRight: 5,
                                                    }}
                                                />
                                                exclusion
                                            </Tag>
                                        </Tooltip>
                                    ) : null}
                                    {item.include && item.include.length > 0 ? (
                                        <Tooltip
                                            title={
                                                tooltips.MASTERPOSTS
                                                    .TARGETS_LIST_INCLUDE
                                            }
                                            mouseEnterDelay={
                                                tooltips.SETTINGS
                                                    .mouseEnterDelay
                                            }
                                        >
                                            <br />
                                            <Tag
                                                color={this.props.store.getTagColorForType(
                                                    "default"
                                                )}
                                            >
                                                <Icon
                                                    type="exclamation-circle"
                                                    theme="filled"
                                                    style={{
                                                        marginRight: 5,
                                                    }}
                                                />
                                                inclusion
                                            </Tag>
                                        </Tooltip>
                                    ) : null}
                                    {item.custom && item.custom.length > 0 ? (
                                        <Tooltip
                                            title={
                                                tooltips.MASTERPOSTS
                                                    .TARGETS_LIST_CUSTOM
                                            }
                                            mouseEnterDelay={
                                                tooltips.SETTINGS
                                                    .mouseEnterDelay
                                            }
                                        >
                                            <br />
                                            <Tag
                                                color={this.props.store.getTagColorForType(
                                                    "default"
                                                )}
                                            >
                                                <Icon
                                                    type="exclamation-circle"
                                                    theme="filled"
                                                    style={{
                                                        marginRight: 5,
                                                    }}
                                                />
                                                custom
                                            </Tag>
                                        </Tooltip>
                                    ) : null}
                                </div>
                            </List.Item>
                        )}
                    />
                );
        }

        return (
            <Fragment>
                {header}
                {body}

                <MasterpostsTargetsModal
                    // onCancel={this.handleModalCancel}
                    // onCreate={this.handleModalCreate}
                    // onEdit={this.handleModalEdit}
                    data={this.state.edit_data}
                    store={this.props.store}
                    currentMasterpost={currentMasterpost}
                    editIndex={this.state.index}
                />
                <MasterpostsListModalNew
                    store={this.props.store}
                    data={this.state.currentMasterpost}
                    ref={this.masterpostsModal}
                />
            </Fragment>
        );
    }
}

export default observer(MasterpostsTargets);
