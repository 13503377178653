import React, { Component, Fragment } from "react";
import { Tag, Form, Modal, Input, Collapse, Divider, Mentions } from "antd";

import { observer } from "mobx-react";
import SocialPreview from "./../SocialPreview";
import { social_notification } from "./../../functions";

const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Mentions;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        getTagColorForNotificationType = (_notification_type) => {
            let retValue = this.props.store.getTagColorForType("default");
            switch (_notification_type) {
                case "error":
                    retValue = this.props.store.getTagColorForType("error");
                    break;
                case "message":
                    retValue = this.props.store.getTagColorForType("default");
                    break;
                default:
                    retValue = this.props.store.getTagColorForType("warning");
                    break;
            }
            return retValue;
        };

        render() {
            const { form, data, store } = this.props;
            const { getFieldDecorator } = form;

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };

            const availableRecepients = ["all", "admins", "users"];
            store.getAllAccounts.map((acc) => {
                availableRecepients.push(acc.email);
                return false;
            });

            return (
                <Form {...formItemLayout}>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={["info", "related_to"]}
                    >
                        <Panel header="Notification" key="info">
                            <Form.Item label="Type">
                                <Tag
                                    color={this.getTagColorForNotificationType(
                                        data.type
                                    )}
                                >
                                    {data.type}
                                </Tag>
                            </Form.Item>
                            <Form.Item label="Recipients">
                                {getFieldDecorator("recipients", {
                                    initialValue: data.recipients,
                                })(
                                    <Mentions
                                        rows="3"
                                        placeholder="You can use @ to ref user here"
                                    >
                                        {availableRecepients.map(
                                            (ar, arIndex) => {
                                                return (
                                                    <Option
                                                        value={ar}
                                                        key={arIndex}
                                                    >
                                                        {ar}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Mentions>
                                )}
                            </Form.Item>
                            <Divider />
                            <Form.Item label="Name">
                                {getFieldDecorator("title", {
                                    initialValue: data.title,
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item label="Description">
                                {getFieldDecorator("description", {
                                    initialValue: data.description,
                                })(<TextArea />)}
                            </Form.Item>
                        </Panel>
                        <Panel header="Related to" key="related_to">
                            {data.related_element ? (
                                <SocialPreview
                                    body={
                                        data.related_element
                                            ? data.related_element.post_text
                                            : null
                                    }
                                    image_url={
                                        data.related_element &&
                                        data.related_element.post_file &&
                                        data.related_element.post_file.url
                                    }
                                    hideTabs={true}
                                    defaultTab={"facebook"}
                                ></SocialPreview>
                            ) : (
                                <h4 style={{ textAlign: "center" }}>
                                    --- Not related ---
                                </h4>
                            )}
                        </Panel>
                    </Collapse>

                    {/* <Form.Item label="Extras">
                            {getFieldDecorator("allow_access_to_app", {
                                initialValue: data.allow_access_to_app
                            })(
                                <Checkbox
                                    onChange={this.handleAllowAccessToAppChange}
                                    checked={data.allow_access_to_app}
                                >
                                    Allow access to the app
                                </Checkbox>
                            )}
                        </Form.Item> */}
                </Form>
            );
        }
    }
);

/* Wrapper component */
class InboxEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener("inbox_modal", (_action) => {
            this.setState({
                modal_visible: _action === "show" ? true : false,
            });
        });
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("inbox_modal");
    };

    onModalApply = () => {
        social_notification({
            type: "info",
            title: "Needs implementation",
            description: "Add this to your TODO list",
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "inbox_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Edit notification"}
                        okText={"Save"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalApply} //onApply
                        maskClosable={false}
                    >
                        <ModalForm
                            store={this.props.store}
                            data={this.props.data}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(InboxEdit);
