import React, { Component, Fragment } from "react";
import { Form, Modal, Input, AutoComplete, Icon, Alert, Divider } from "antd";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        //   componentDidMount() {
        //       // To disable submit button at the beginning.
        //       this.props.form.validateFields();
        //   }

        //   handleSubmit = e => {
        //       e.preventDefault();
        //       this.props.form.validateFields((err, values) => {
        //           if (!err) {
        //               console.log("Received values of form: ", values);
        //           }
        //       });
        //   };

        checkCompetitor = async (_type) => {
            const { form, data } = this.props;
            const { getFieldValue } = form;

            let value = "";

            switch (_type) {
                case "twitter":
                    value = getFieldValue("social_twitter");
                    break;
                default:
                    break;
            }
            console.info("checkCompetitor _type=", _type, "value=", value);
            const result = await this.props.store.checkCompetitorSocialHandle(
                _type,
                value
            );
            console.info("checkCompetitor result", result);
            let body = "...";
            switch (_type) {
                case "twitter":
                    body = (
                        <Fragment>
                            username:{" "}
                            <strong>
                                {result.user_search_results.data[0].username}
                            </strong>
                            <Divider style={{ margin: "10px 0" }} />
                            name: {result.user_search_results.data[0].name}
                            <br />
                            id: {result.user_search_results.data[0].id}
                        </Fragment>
                    );
                    break;
                default:
                    break;
            }

            Modal.info({
                title: "Social Handle Information",
                content: (
                    <div style={{ fontSize: 18 }}>
                        <p>Social Type: {_type}</p>
                        <p>{body}</p>
                    </div>
                ),
                onOk() {},
            });
        };

        render() {
            const { form, data } = this.props;
            const { getFieldDecorator } = form;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            return (
                <Form {...formItemLayout}>
                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the email of the competitor!",
                                    type: "string",
                                },
                            ],
                            initialValue: data.name,
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Brand">
                        {getFieldDecorator("brand", {
                            initialValue: data.brand,
                        })(
                            <AutoComplete
                                dataSource={this.props.store.getAllBrands}
                                style={{ width: 200 }}
                                placeholder="input here"
                            />
                        )}
                    </Form.Item>

                    <Divider orientation="left">Social Cannels </Divider>
                    <Form.Item label="Facebook">
                        {getFieldDecorator("social_facebook", {
                            rules: [
                                {
                                    message:
                                        "Please input the url of the Facebook Page of the competitor!",
                                    type: "url",
                                },
                            ],
                            initialValue: data.social_facebook,
                        })(<Input placeholder="Facebook Page URL" />)}
                    </Form.Item>
                    <Form.Item label="Instagram">
                        {getFieldDecorator("social_instagram", {
                            // rules: [
                            //     {
                            //         message:
                            //             "Please input the url of the Instagram Page of the competitor!",
                            //         type: "url",
                            //     },
                            // ],
                            initialValue: data.social_instagram,
                        })(<Input placeholder="Instagram Page URL" />)}
                    </Form.Item>
                    <Form.Item label="Twitter">
                        {getFieldDecorator("social_twitter", {
                            // rules: [
                            //     {
                            //         message:
                            //             "Please input the url of the Twitter Page of the competitor!",
                            //         type: "url",
                            //     },
                            // ],
                            initialValue: data.social_twitter,
                        })(
                            <Input
                                placeholder="Twitter Handle"
                                addonAfter={
                                    <Icon
                                        type="question-circle"
                                        onClick={(e) =>
                                            this.checkCompetitor("twitter")
                                        }
                                    />
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="LinkedIn">
                        {getFieldDecorator("social_linkedin", {
                            // rules: [
                            //     {
                            //         message:
                            //             "Please input the url of the Linkedin Page of the competitor!",
                            //         type: "url",
                            //     },
                            // ],
                            initialValue: data.social_linkedin,
                        })(<Input placeholder="Linkedin Page URL" />)}
                    </Form.Item>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class CompetitorsListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "competitors_modal_add",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("competitors_modal_add");
    };

    onModalCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                social_notification("validation_error", "error");
                return;
            }

            // Patch the location
            /*
          values["location"] = `${values["location"][0]}/${
              values["location"][1]
          }`;
          */

            this.props.store.addCompetitor({
                ...values,
                ...this.state.edit_data,
            });

            form.resetFields();
            this.onModalCancel();
        });
    };

    onModalEdit = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log("Received values of form: ", values);
            console.log("masterpost key: ", this.props.data.key);

            this.props.store.updateCompetitor(this.props.data.key, values);

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "competitors_modal_add",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={
                            !data.name
                                ? "Create new competitor"
                                : "Edit competitor"
                        }
                        okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        onOk={
                            !data.name ? this.onModalCreate : this.onModalEdit
                        } //onApply
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={data}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(CompetitorsListModal);
