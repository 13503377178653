import React, { Component } from "react";
import { Empty } from "antd";

class TagsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagToReport: null
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("tags");

        console.info("TagsReport this.props ", this.props);
        this.setState({ tagToReport: this.props.match.params.id });
        //console.info("TagsReport this.props ", this.props);
    }

    render() {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`No Tag to Report for tagToReport: ${
                    this.state.tagToReport
                }`}
            />
        );
    }
}

export default TagsReport;
