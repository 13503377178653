import React, { Component, Fragment } from "react";
import {
    Icon,
    Collapse,
    Input,
    Form,
    Tree,
    Select,
    Tooltip,
    Row,
    Col,
    Upload,
    Button,
    Progress,
    Checkbox,
    Cascader,
    Alert,
    Typography,
    Divider,
    Modal,
    Tabs,
    Badge,
} from "antd";
import LibraryPickup from "../Library/LibraryPickup";
import SocialPreview from "../SocialPreview";
import { firebase } from "../../base";
import shortid from "shortid";

import { observer } from "mobx-react";

const { Text } = Typography;
const { Panel } = Collapse;
const { TreeNode } = Tree;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

/* Modal Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        // eslint-disable-next-line
        constructor(props) {
            super(props);
            this.state = {
                previewURL: "",
                location: [],
                library_file: null,
                //
                accounts_affected: [],
                checkedKeys: [],
                rightTabsOpenKey: "preview",
            };
        }

        UNSAFE_componentWillMount() {
            // console.info(
            //     "UNSAFE_componentWillMount(); this.props.data.location",
            //     this.props.data.location
            // );
            // make location an array if it is not already one
            if (typeof this.props.data.location !== "undefined") {
                this.setState({
                    location: JSON.parse(
                        JSON.stringify(this.props.data.location)
                    ),
                });
                this.props.data.location_stringified = JSON.stringify(
                    this.props.data.location
                );
            }

            if (typeof this.props.data.template_text !== "undefined") {
                this.setState({ template_text: this.props.data.template_text });
            }

            //
            if (
                this.props.data.template_file &&
                this.props.data.template_file.library_file === true
            ) {
                //
                this.props.data.library_file = this.props.data.template_file;
                this.props.data.library_file.downloadURL = this.props.data.template_file.url;
                this.setState({ library_file: this.props.data.template_file });
                delete this.props.data.template_file;
            }

            console.info(
                "UNSAFE_componentWillMount this.props.data",
                this.props.data
            );

            // force state update
            this.setState({});
        }

        componentDidMount() {
            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        }

        handleTagsChange = (_tags) => {
            console.info("handleTagsChange", _tags);
            //this.props.data = tags;
            this.props.form.setFieldsValue({ tags: _tags });

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleRuleEnable = (_evt, _extra) => {
            console.info("handleRuleEnable", _evt, "_extra", _extra);
            //this.props.data = tags;
            let fieldname = `rules_${_extra}`;
            console.info("handleRuleEnable fieldname", fieldname);
            this.props.form.setFieldsValue({ fieldname: _evt.target.checked });
            //this.props.data["fieldname"] = _evt.target.checked;

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleUpload = (info) => {
            console.info("handleUpload info", info);
            //console.info("handleUpload this.props", this.props);
            this.props.form.setFieldsValue({ template_file: info.file });
            this.props.data.template_file = info.file;
            this.props.data["changed_template_file"] = true;
            this.setState({ previewURL: "" });

            if (info.fileList.length === 1) {
                let reader = new FileReader();
                let preview_file = info.file;
                console.info("handleUpload preview_file", preview_file);
                reader.onloadend = () => {
                    this.setState({ previewURL: reader.result });
                };
                reader.readAsDataURL(preview_file);
            } else {
                // clear the current file
                delete this.props.data.template_file;
                this.props.data["changed_template_file"] = false;
            }
            // return false;
        };

        handleAddLocation = (evt) => {
            //this.props.data.location.push(new Array("New York", "New York"));
            //console.table(this.props.data.location);

            let curLocation = this.state.location;
            const newLocation = ["New York", "New York"];
            curLocation.push(newLocation);
            this.setState({ location: curLocation });

            this.props.form.setFieldsValue({
                location_stringified: JSON.stringify(curLocation),
            });
            this.props.data.location_stringified = JSON.stringify(curLocation);

            // Force a render with a simulated state change
            //this.setState({ state: this.state });

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };
        handleLocationChange = (_location, _lIndex) => {
            console.info("handleLocationChange", _location, "_lIndex", _lIndex);
            //this.props.data = tags;
            let curLocation = this.state.location;
            console.info(
                "handleLocationChange curLocation BEFORE",
                curLocation
            );
            curLocation[_lIndex] = _location;
            this.setState({ location: curLocation });
            console.info("handleLocationChange curLocation AFTER", curLocation);
            this.props.form.setFieldsValue({
                location_stringified: JSON.stringify(curLocation),
            });
            this.props.data.location_stringified = JSON.stringify(curLocation);

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };
        handleLocationDelete = (evt, _lIndex) => {
            console.log("handleLocationDelete _lIndex=" + _lIndex);
            //this.props.data.location.splice(_lIndex, 1);

            let curLocation = this.state.location;
            console.info(
                "handleLocationChange curLocation BEFORE",
                curLocation
            );
            curLocation.splice(_lIndex, 1);
            this.setState({ location: curLocation });
            console.info("handleLocationChange curLocation AFTER", curLocation);
            this.props.form.setFieldsValue({
                location_stringified: JSON.stringify(curLocation),
            });
            this.props.data.location_stringified = JSON.stringify(curLocation);

            // Force a render with a simulated state change
            //this.setState({ state: this.state });

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleTemplateTextChange = (_evt) => {
            // console.info(
            //     "handleTemplateTextChange evt.nativeEvent",
            //     _evt.nativeEvent
            // );
            // TODO: Fix this solution so it's not linked to the ID of the textarea
            // let value = document.getElementById("form_in_modal_template_text")
            //     .value;
            let value = _evt.nativeEvent.data;
            // console.info("handleTemplateTextChange value", value);
            this.setState({ template_text: value });

            this.props.form.setFieldsValue({
                template_text: value,
            });
        };

        handleExcludeChange = (_exclude) => {
            console.info("handleExcludeChange", _exclude);
            //this.props.data = _exclude;
            this.props.form.setFieldsValue({ exclude: _exclude });

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleIncludeChange = (_include) => {
            console.info("handleIncludeChange", _include);
            //this.props.data = _include;
            this.props.form.setFieldsValue({ include: _include });

            // Recalculate Accounts Affected
            this.recalculateAccountsAffected();
        };

        handleChangeLibrarySelected = (_selected) => {
            console.info(
                "handleChangeLibrarySelected _selected[0]",
                _selected[0]
            );

            this.setState({
                library_file: _selected[0],
                previewURL: _selected[0] ? _selected[0].downloadURL : "",
            });
            this.props.data.library_file = _selected[0];
            this.props.data["changed_template_file"] = true;
        };

        handleCustomChange = (e) => {
            this.props.form.setFieldsValue({ rules_custom: e.target.checked });
            this.recalculateAccountsAffected();
        };

        recalculateAccountsAffected = (extra) => {
            console.info("recalculateAccountsAffected extra", extra);
            const { getFieldValue } = this.props.form;

            const custom =
                extra && extra.custom
                    ? extra.custom
                    : getFieldValue("custom")
                    ? getFieldValue("custom")
                    : this.props.data.custom
                    ? this.props.data.custom
                    : [];
            console.info("recalculateAccountsAffected custom", custom);

            let rules = {
                rules_location: getFieldValue("rules_location"),
                rules_tags: getFieldValue("rules_tags"),
                rules_custom: getFieldValue("rules_custom"),
                tags: getFieldValue("tags"),
                location: this.transformLocation(this.state.location),
                exclude: getFieldValue("exclude"),
                include: getFieldValue("include"),
                // custom: custom.filter(c => {
                //     if (c.indexOf("|") !== -1) return c;
                // })
                custom: custom,
            };

            console.info("recalculateAccountsAffected rules", rules);
            //console.info("recalculateAccountsAffected this.state", this.state);

            let filtered_accounts = this.props.store.getAccountsByRules(rules);
            console.info("filtered_accounts", filtered_accounts);
            this.setState({ accounts_affected: filtered_accounts });

            // Save the custom keys
            this.setState({
                checkedKeys: filtered_accounts.map((fa) => {
                    return fa.key;
                }),
            });
        };

        transformLocation = (_location) => {
            console.info("transformLocation _location", _location);
            return _location;
        };

        renderTreeNodes = (data) =>
            data.map((item) => {
                if (item.children) {
                    return (
                        <TreeNode
                            title={item.title}
                            key={item.key}
                            dataRef={item}
                        >
                            {this.renderTreeNodes(item.children)}
                        </TreeNode>
                    );
                } else {
                    return (
                        <TreeNode
                            title={
                                <Fragment>
                                    <Icon
                                        type="facebook"
                                        theme="filled"
                                        style={{ marginRight: 5 }}
                                    />
                                    {item.title}
                                </Fragment>
                            }
                            key={item.key}
                            dataRef={item}
                        ></TreeNode>
                    );
                }
                //return <TreeNode key={item.key} {...item} />;
            });

        onCheck = (checkedKeys) => {
            console.log("onCheck", checkedKeys);
            const { getFieldValue } = this.props.form;

            if (getFieldValue("rules_custom")) {
                this.setState({ checkedKeys });

                this.props.form.setFieldsValue({ custom: checkedKeys });

                this.recalculateAccountsAffected({ custom: checkedKeys });
            }
        };

        rightTabsCallback = (key) => {
            console.log(key);
            this.setState({ rightTabsOpenKey: key });
        };

        toggleChannel = (channel) => {
            console.info("toggleChannel channel", channel);

            switch (channel) {
                case "facebook":
                    this.props.data.social_type_facebook = !this.props.data
                        .social_type_facebook;
                    this.props.form.setFieldsValue({
                        social_type_facebook: this.props.data
                            .social_type_facebook,
                    });
                    break;
                case "instagram":
                    this.props.data.social_type_instagram = !this.props.data
                        .social_type_instagram;
                    this.props.form.setFieldsValue({
                        social_type_instagram: this.props.data
                            .social_type_instagram,
                    });
                    break;
                case "twitter":
                    this.props.data.social_type_twitter = !this.props.data
                        .social_type_twitter;
                    this.props.form.setFieldsValue({
                        social_type_twitter: this.props.data
                            .social_type_twitter,
                    });
                    break;
                case "linkedin":
                    this.props.data.social_type_linkedin = !this.props.data
                        .social_type_linkedin;
                    this.props.form.setFieldsValue({
                        social_type_linkedin: this.props.data
                            .social_type_linkedin,
                    });
                    break;
                default:
                    break;
            }

            // force the rerender
            this.setState({});
        };

        render() {
            const {
                form,
                data,
                processing,
                upload_percentage,
                store,
            } = this.props;
            const { getFieldDecorator } = form;

            const locations = store.getLocations();
            const tags = store.getAllTags;
            const accounts = store.getAllAccounts;

            const tagsChildren = [];
            tags.map((i) => {
                tagsChildren.push(<Option key={i.tag}>{i.tag}</Option>);
                return false;
            });

            let excludeChildren = [],
                includeChildren = [];
            accounts.map((a) => {
                excludeChildren.push(<Option key={a.key}>{a.email}</Option>);
                includeChildren.push(<Option key={a.key}>{a.email}</Option>);
                return false;
            });

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            };

            const allAccounts = store.getAllAccounts.map((a) => {
                let accountData = {
                    title: `${a.name} (${a.email})`,
                    key: a.key,
                };
                if (
                    a.facebook &&
                    a.facebook.data &&
                    a.facebook.data.length > 0
                ) {
                    accountData.children = a.facebook.data.map((d) => {
                        return {
                            title: d.name,
                            key: a.key + "|" + d.id,
                        };
                    });
                }
                return accountData;
            });
            //console.info("allAccounts", allAccounts);

            // console.log("RENDER data.location");
            // console.table(data.location);

            const InfoPanel = (
                <Panel
                    header="Target"
                    key="info"
                    style={{
                        background: "#f5f5f5",
                        border: 0,
                    }}
                >
                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the name of the target!",
                                    type: "string",
                                },
                            ],
                            initialValue: data.name,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Note">
                        {getFieldDecorator("note", {
                            initialValue: data.note,
                        })(<TextArea />)}
                    </Form.Item>
                </Panel>
            );
            const TemplatePanel = (
                <Panel
                    header="Template"
                    key="template"
                    style={{
                        background: "#f5f5f5",
                        border: 0,
                        marginTop: 5,
                    }}
                >
                    <Form.Item label="Text">
                        <Tooltip
                            title="[author-name] - the name of the Account;
                  [author-website] - the website of the Account;
                  [author-phone] - the phone of the Account"
                        >
                            {getFieldDecorator("template_text", {
                                initialValue: this.state.template_text,
                                onChange: this.handleTemplateTextChange,
                            })(<TextArea id="form_in_modal_template_text" />)}
                        </Tooltip>
                    </Form.Item>

                    <Form.Item label="File">
                        <Row>
                            <Col span={12}>
                                {getFieldDecorator("template_file", {
                                    initialValue: data.template_file,
                                })(
                                    <Upload
                                        name="template_file"
                                        beforeUpload={(file) => false}
                                        showUploadList={true}
                                        onChange={this.handleUpload}
                                        multiple={false}
                                        accept="image/jpeg,image/png,image/gif,video/mp4"
                                    >
                                        <Button
                                            disabled={
                                                (typeof data.template_file !=
                                                    "undefined" &&
                                                    data.template_file.name &&
                                                    data.changed_template_file) ||
                                                (this.state.library_file
                                                    ? true
                                                    : false)
                                            }
                                            style={{
                                                width: 160,
                                                textAlign: "left",
                                            }}
                                        >
                                            <Icon
                                                type="upload"
                                                style={{
                                                    marginRight: 8,
                                                }}
                                            />{" "}
                                            Upload File ...
                                        </Button>
                                    </Upload>
                                )}
                            </Col>
                            <Col span={12}>
                                <Progress
                                    className={!processing ? "hidden" : ""}
                                    percent={upload_percentage}
                                    size="small"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {typeof data.template_file != "undefined" &&
                                data.template_file.name &&
                                !data.changed_template_file ? (
                                    <div>
                                        <span>{data.template_file.name}</span>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {typeof data.template_file != "undefined" &&
                                data.template_file.name &&
                                data.changed_template_file ? (
                                    <div>
                                        {this.state.previewURL.length > 0 ? (
                                            <img
                                                src={this.state.previewURL}
                                                style={{
                                                    height: 100,
                                                    padding: 5,
                                                    marginTop: 5,
                                                    border: "1px solid #ccc",
                                                    borderRadius: 5,
                                                }}
                                                alt=""
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {typeof data.template_file != "undefined" &&
                                data.template_file.name &&
                                !data.changed_template_file
                                    ? getFieldDecorator(
                                          "delete_template_file",
                                          {
                                              initialValue:
                                                  data.delete_template_file,
                                          }
                                      )(
                                          <Checkbox
                                              defaultChecked={
                                                  data.delete_template_file
                                              }
                                          >
                                              Delete file
                                          </Checkbox>
                                      )
                                    : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LibraryPickup
                                    store={store}
                                    disabled={
                                        typeof data.template_file !=
                                            "undefined" &&
                                        data.template_file.name
                                    }
                                    onSelectedChange={
                                        this.handleChangeLibrarySelected
                                    }
                                    selected={
                                        this.state.library_file
                                            ? [this.state.library_file]
                                            : null
                                    }
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                </Panel>
            );
            const RulesPanel = (
                <Panel
                    header="Rules"
                    key="rules"
                    style={{
                        background: "#f5f5f5",
                        border: 0,
                        marginTop: 5,
                    }}
                >
                    <Form.Item label="Channels">
                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) => this.toggleChannel("facebook")}
                        >
                            {getFieldDecorator("social_type_facebook", {
                                initialValue: data.social_type_facebook,
                            })(<Input type="hidden" />)}
                            <Icon
                                type="facebook"
                                theme="filled"
                                style={{
                                    fontSize: 25,
                                    color: data.social_type_facebook
                                        ? store.getTagColorForType("facebook")
                                        : "#ccc",
                                    marginLeft: 5,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) => this.toggleChannel("instagram")}
                        >
                            {getFieldDecorator("social_type_instagram", {
                                initialValue: data.social_type_instagram,
                            })(<Input type="hidden" />)}
                            <Icon
                                type="instagram"
                                theme="filled"
                                style={{
                                    fontSize: 25,
                                    color: data.social_type_instagram
                                        ? store.getTagColorForType("instagram")
                                        : "#ccc",
                                    marginLeft: 5,
                                }}
                            />
                        </div>

                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) => this.toggleChannel("twitter")}
                        >
                            {getFieldDecorator("social_type_twitter", {
                                initialValue: data.social_type_twitter,
                            })(<Input type="hidden" />)}
                            <Icon
                                type="twitter"
                                style={{
                                    fontSize: 25,
                                    color: data.social_type_twitter
                                        ? store.getTagColorForType("twitter")
                                        : "#ccc",
                                    marginLeft: 5,
                                }}
                            />
                        </div>

                        <div
                            style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                            onClick={(e) => this.toggleChannel("linkedin")}
                        >
                            {getFieldDecorator("social_type_linkedin", {
                                initialValue: data.social_type_linkedin,
                            })(<Input type="hidden" />)}
                            <Icon
                                type="linkedin"
                                theme="filled"
                                style={{
                                    fontSize: 25,
                                    color: data.social_type_linkedin
                                        ? store.getTagColorForType("linkedin")
                                        : "#ccc",
                                    marginLeft: 5,
                                }}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item label="Location">
                        {getFieldDecorator("rules_location", {
                            initialValue: data.rules_location,
                        })(
                            <Checkbox checked={data.rules_location}>
                                Enable
                            </Checkbox>
                        )}
                        {this.state.location.map((location, lIndex) => {
                            return getFieldDecorator("location", {
                                initialValue: location,
                                onChange: (e) =>
                                    this.handleLocationChange(e, lIndex),
                                defaultValue: location,
                            })(
                                <Row key={lIndex}>
                                    <Col span={22}>
                                        <Cascader
                                            key={lIndex}
                                            options={locations}
                                            placeholder="Please select"
                                            changeOnSelect
                                            onChange={(e) =>
                                                this.handleLocationChange(
                                                    e,
                                                    lIndex
                                                )
                                            }
                                            value={location}
                                            defaultValue={location}
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                            }}
                                        />
                                    </Col>
                                    <Col span={2}>
                                        <Button
                                            key={lIndex}
                                            type="danger"
                                            shape="circle"
                                            icon="minus"
                                            size="small"
                                            onClick={(e) =>
                                                this.handleLocationDelete(
                                                    e,
                                                    lIndex
                                                )
                                            }
                                            style={{
                                                marginLeft: 10,
                                                transform: "scale(0.8)",
                                                fontSize: "1rem",
                                            }}
                                        />
                                    </Col>
                                </Row>
                            );
                        })}
                        <div style={{ display: "block" }}>
                            <Button
                                type="normal"
                                icon="plus"
                                size="small"
                                onClick={this.handleAddLocation}
                            >
                                Add New
                            </Button>
                        </div>
                    </Form.Item>
                    <Form.Item label="Tags">
                        {getFieldDecorator("rules_tags", {
                            initialValue: data.rules_tags,
                        })(
                            <Checkbox checked={data.rules_tags}>
                                Enable
                            </Checkbox>
                        )}
                        {getFieldDecorator("tags", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.tags ? data.tags : [],
                            onChange: this.handleTagsChange,
                        })(
                            <div>
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Please select"
                                    onChange={this.handleTagsChange}
                                    defaultValue={data.tags ? data.tags : []}
                                >
                                    {tagsChildren}
                                </Select>
                            </div>
                        )}
                    </Form.Item>

                    <Form.Item label="Exclude">
                        {getFieldDecorator("exclude", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.exclude ? data.exclude : [],
                            onChange: this.handleExcludeChange,
                        })(
                            <div>
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Please select"
                                    onChange={this.handleExcludeChange}
                                    defaultValue={
                                        data.exclude ? data.exclude : []
                                    }
                                >
                                    {excludeChildren}
                                </Select>
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item label="Include">
                        {getFieldDecorator("include", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.include ? data.include : [],
                            onChange: this.handleIncludeChange,
                        })(
                            <div>
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Please select"
                                    onChange={this.handleIncludeChange}
                                    defaultValue={
                                        data.include ? data.include : []
                                    }
                                >
                                    {includeChildren}
                                </Select>
                            </div>
                        )}
                    </Form.Item>
                </Panel>
            );

            const PreviewPanel = (
                <SocialPreview
                    body={this.state.template_text}
                    image_url={
                        //this.state.previewURL
                        data.template_file
                            ? data.template_file.url
                            : data.library_file
                            ? data.library_file.url
                            : null
                    }
                ></SocialPreview>
            );

            const AccountsAffected = (
                <Fragment>
                    <Alert
                        banner
                        message={
                            <Fragment>
                                <Row>
                                    <Col span={10}>
                                        {getFieldDecorator("custom")(
                                            <Input type="hidden" />
                                        )}

                                        {getFieldDecorator("rules_custom", {
                                            initialValue: data.rules_custom,
                                            onChange: this.handleCustomChange,
                                        })(
                                            <Checkbox
                                                defaultChecked={
                                                    data.rules_custom
                                                }
                                            >
                                                <b>Custom selection</b>
                                            </Checkbox>
                                        )}
                                    </Col>
                                    <Col
                                        span={14}
                                        style={{ textAlign: "right" }}
                                    >
                                        <Text type="secondary">
                                            (Allows you to mix the selected
                                            channels)
                                        </Text>
                                    </Col>
                                </Row>
                            </Fragment>
                        }
                        type="info"
                        showIcon={false}
                    />

                    <Divider dashed />

                    {/* {this.state.accounts_affected.map(
                          (aa, aaIndex) => {
                              return (
                                  <Fragment
                                      key={aaIndex}
                                  >
                                      <strong>
                                          {aa.name}
                                      </strong>{" "}
                                      ({aa.email})
                                      <br />
                                  </Fragment>
                              );
                          }
                      )} */}

                    <Tree
                        checkable
                        selectable={false}
                        // onExpand={this.onExpand}
                        // expandedKeys={this.state.expandedKeys}
                        // autoExpandParent={this.state.autoExpandParent}
                        onCheck={this.onCheck}
                        checkedKeys={this.state.checkedKeys}
                        // onSelect={this.onSelect}
                        // selectedKeys={this.state.selectedKeys}
                        style={{ marginTop: -15 }}
                    >
                        {this.renderTreeNodes(allAccounts)}
                    </Tree>
                </Fragment>
            );

            return (
                <Form {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={["info"]}
                            >
                                {InfoPanel}
                                {TemplatePanel}
                                {RulesPanel}
                            </Collapse>
                        </Col>
                        <Col span={12}>
                            <Tabs
                            // activeKey={this.state.rightTabsOpenKey}
                            // onChange={this.rightTabsCallback}
                            >
                                <TabPane
                                    tab={
                                        <Fragment>
                                            Accounts Affected{" "}
                                            <Badge
                                                count={
                                                    this.state.accounts_affected
                                                        .length
                                                }
                                                showZero
                                                style={{
                                                    backgroundColor: "#1890ff",
                                                    marginLeft: 10,
                                                }}
                                            ></Badge>
                                        </Fragment>
                                    }
                                    key="accounts"
                                >
                                    {AccountsAffected}
                                </TabPane>
                                <TabPane tab="Preview" key="preview">
                                    {PreviewPanel}
                                </TabPane>
                            </Tabs>

                            {/* <pre>
                                      {JSON.stringify(
                                          this.state.checkedKeys,
                                          null,
                                          2
                                      )}
                                  </pre> */}
                        </Col>
                    </Row>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class MasterpostsTargetsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            confirmLoading: false,
        };
    }

    componentDidMount = () => {
        this.props.store.registerModalListener(
            "masterposts_targets_modal",
            (_action) => {
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
                if (_action === "show") {
                    this.setState({
                        currentMasterpost: this.props.currentMasterpost,
                    });
                }
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("masterposts_targets_modal");
    };

    onModalCreate = () => {
        console.log("onModalCreate");
        const { form } = this.formRef.props;
        //const formState = this.formRef.state;

        form.validateFields((err, values) => {
            // overwrite location
            if (typeof this.props.data.location_stringified !== "undefined") {
                values.location = JSON.parse(
                    this.props.data.location_stringified
                );
            }
            if (values.location && values.location.length === 0) {
                delete values["location"];
            }

            // generate shortid of the target
            values["shortid"] = shortid.generate();

            console.log("Received values of form: ", values, "err", err);

            if (err) {
                return;
            }

            // Making the form disabled
            this.setState({ confirmLoading: true });

            // Anything to upload
            if (
                typeof values.template_file != "undefined" &&
                this.props.data.changed_template_file
            ) {
                const file = values.template_file.file.originFileObj
                    ? values.template_file.file.originFileObj
                    : values.template_file.file;
                console.info("FILE", file);
                const storageRef = firebase.storage().ref();
                console.info("storageRef", storageRef);

                let now = Date.now();
                let fileparts = file.name.split(".");
                let fileext = fileparts.pop();
                const mainImage = storageRef.child(
                    fileparts.join(".") + "_" + now.valueOf() + "." + fileext
                );
                console.info("mainImage", mainImage);
                let uploadTask = mainImage.put(file);
                let that = this;

                uploadTask.on(
                    "state_changed",
                    function (snapshot) {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        var progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100;
                        //console.log("Upload is " + progress + "% done");
                        that.setState({
                            upload_percentage: Math.round(progress),
                        });
                    },
                    function (error) {
                        // Handle unsuccessful uploads
                        console.error("unsuccessful upload: " + error);
                    },
                    function () {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(function (downloadURL) {
                                console.log("File available at", downloadURL);

                                values.template_file = {
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    url: downloadURL,
                                    meta: uploadTask.snapshot.metadata,
                                };

                                // actual create
                                that.handleActualCreate(values, form);
                            });
                    }
                );
            } else {
                // actual create

                // Overwrite the library file
                if (this.props.data.library_file) {
                    values.template_file = {
                        name: this.props.data.library_file.file.name,
                        size: this.props.data.library_file.file.size,
                        type: this.props.data.library_file.file.type,
                        url: this.props.data.library_file.downloadURL,
                        library_file: true,
                        library_key: this.props.data.library_file.key,
                    };

                    // Lock the library file
                    this.props.store.toggleLibraryItemLock(
                        { key: this.props.data.library_file.key },
                        true
                    );

                    delete this.props.data.library_file;
                }

                this.handleActualCreate(values, form);
            }
            // https://firebase.google.com/docs/storage/web/upload-files
        });
    };

    handleActualCreate = (values, form) => {
        console.info("handleActualCreate values", values, "form", form);

        let currentMasterpost = JSON.parse(
            JSON.stringify(this.state.currentMasterpost)
        );
        if (typeof currentMasterpost.targets === "undefined") {
            currentMasterpost.targets = [];
        }

        // overwrite custom
        if (!values.custom && this.state.custom) {
            values.custom = this.state.custom;
        }

        currentMasterpost.targets.push(values);
        this.setState({ currentMasterpost });
        //this.props.currentMasterpost = currentMasterpost;

        //this.props.store.addmasterpostTarget(this.state.currentMasterpost.key, { ...values, ...this.state.edit_data });
        console.info("currentMasterpost: ", currentMasterpost);

        this.props.store.updateMasterpost(
            currentMasterpost.key,
            currentMasterpost
        );

        this.setState({ confirmLoading: false });
        form.resetFields();
        this.onModalCancel();
    };

    onModalEdit = () => {
        console.log("onModalEdit");
        const { form } = this.formRef.props;
        //const formState = this.formRef.state;

        form.validateFields((err, values) => {
            // overwrite location
            if (typeof this.props.data.location_stringified !== "undefined") {
                values.location = JSON.parse(
                    this.props.data.location_stringified
                );
            }
            if (values.location && values.location.length === 0) {
                delete values["location"];
            }

            if (err) {
                console.error(err);
                return;
            }

            console.info(
                "handleModalEdit values",
                values,
                "this.props.data",
                this.props.data
            );

            // Making the form disabled
            this.setState({ modal_processing: true });

            // patch to forget the template_file
            if (values.delete_template_file) {
                delete values.template_file;
            }

            // Anything to upload
            if (
                typeof values.template_file != "undefined" &&
                this.props.data.changed_template_file
            ) {
                const file = values.template_file.file.originFileObj
                    ? values.template_file.file.originFileObj
                    : values.template_file.file;
                console.info("FILE", file);
                const storageRef = firebase.storage().ref();
                console.info("storageRef", storageRef);

                let now = Date.now();
                let fileparts = file.name.split(".");
                let fileext = fileparts.pop();
                const mainImage = storageRef.child(
                    fileparts.join(".") + "_" + now.valueOf() + "." + fileext
                );
                console.info("mainImage", mainImage);
                let uploadTask = mainImage.put(file);
                let that = this;

                uploadTask.on(
                    "state_changed",
                    function (snapshot) {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        var progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100;
                        //console.log("Upload is " + progress + "% done");
                        that.setState({
                            upload_percentage: Math.round(progress),
                        });
                    },
                    function (error) {
                        // Handle unsuccessful uploads
                    },
                    function () {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(function (downloadURL) {
                                console.log("File available at", downloadURL);

                                values.template_file = {
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    url: downloadURL,
                                    meta: uploadTask.snapshot.metadata,
                                };

                                // actual create
                                that.handleActualEdit(values, form);
                            });
                    }
                );
            } else {
                // actual create
                console.info(
                    "actual this.state.edit_data.library_file= ",
                    this.props.data.library_file
                );
                // Overwrite the library file
                if (this.props.data.library_file) {
                    console.log("Inside IF");
                    if (this.props.data.library_file.file) {
                        values.template_file = {
                            name: this.props.data.library_file.file.name,
                            size: this.props.data.library_file.file.size,
                            type: this.props.data.library_file.file.type,
                            url: this.props.data.library_file.downloadURL,
                            library_file: true,
                            library_key: this.props.data.library_file.key,
                        };
                    } else {
                        // no file for library
                        values.template_file = this.props.data.library_file;
                    }

                    // remove if there
                    delete values.template_file.downloadURL;

                    console.info(
                        "After values.template_file",
                        values.template_file
                    );

                    // Lock the library file
                    this.props.store.toggleLibraryItemLock(
                        { key: this.props.data.library_file.key },
                        true
                    );

                    delete this.props.data.library_file;
                }

                this.handleActualEdit(values, form);
            }
            // https://firebase.google.com/docs/storage/web/upload-files
        });
    };

    handleActualEdit = (values, form) => {
        console.info("handleActualEdit Received values of form: ", values);
        const formState = this.formRef.state;
        console.info("tformState: ", formState);

        // overwrite custom
        if (!values.custom && formState.custom) {
            values.custom = formState.custom;
        }

        let currentMasterpost = JSON.parse(
            JSON.stringify(this.props.currentMasterpost)
        );
        currentMasterpost.targets[this.props.editIndex] = {
            ...values,
            shortid: this.props.data.shortid,
        };
        this.setState({ currentMasterpost });
        //this.props.store.addmasterpostTarget(this.state.currentMasterpost.key, { ...values, ...this.state.edit_data });
        console.info("currentMasterpost: ", currentMasterpost);

        this.props.store.updateMasterpost(
            currentMasterpost.key,
            currentMasterpost
        );

        form.resetFields();
        this.onModalCancel();
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "masterposts_targets_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data, store } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        width={"80%"}
                        title={!data.name ? "Create new target" : "Edit target"}
                        okText={!data.name ? "Create" : "Save"}
                        onCancel={this.onModalCancel}
                        onOk={
                            !data.name ? this.onModalCreate : this.onModalEdit
                        } //onApply
                        maskClosable={false}
                        confirmLoading={this.state.confirmLoading}
                    >
                        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={store}
                            data={data}
                            upload_percentage={this.state.upload_percentage}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(MasterpostsTargetsModal);
