import React, { Fragment } from "react";
import {
    Form,
    Row,
    Col,
    Checkbox,
    Select,
    Divider,
    Icon,
    Button,
    Tree,
    Spin,
    InputNumber,
    DatePicker,
} from "antd";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TreeNode } = Tree;

const AdsListModalFormPanelLinkedin = (props, context) => {
    const { form, state, store } = props;
    const { getFieldDecorator } = form;
    const { fetching } = state;

    const allLinkedinAccounts = store.getAllAccounts
        .filter((a) => {
            return a.linkedin ? true : false;
        })
        .map((a) => ({
            title: `${a.name}`,
            key: a.key,
        }));

    const renderAccountsTreeNodesForLinkedin = (data) =>
        data.map((item) => {
            return (
                <TreeNode
                    title={
                        <Fragment>
                            <Icon
                                type="linkedin"
                                theme="filled"
                                style={{
                                    marginRight: 5,
                                    color: store.getTagColorForType("linkedin"),
                                }}
                            />
                            {item.title}
                        </Fragment>
                    }
                    key={item.key}
                    dataRef={item}
                ></TreeNode>
            );
            //return <TreeNode key={item.key} {...item} />;
        });

    const linkedinTargeting = (
        <Fragment>
            <div>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_companyCategory
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_companyCategory",
                                    e.target.checked
                                )
                            }
                        >
                            Company Category
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select company category"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("companyCategory")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("companyCategory", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam(
                                "companyCategory",
                                e
                            );
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_companyCategory
                        }
                        style={{ width: "100%" }}
                        value={
                            state.linkedin_targeting.selected_companyCategory
                        }
                    >
                        {state.linkedin_targeting.companyCategory &&
                            state.linkedin_targeting.companyCategory.map(
                                (e) => <Option key={e.key}>{e.name}</Option>
                            )}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_memberBehaviors
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_memberBehaviors",
                                    e.target.checked
                                )
                            }
                        >
                            Member Behaviors
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select member behaviors"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("memberBehaviors")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("memberBehaviors", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam(
                                "memberBehaviors",
                                e
                            );
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_memberBehaviors
                        }
                        style={{ width: "100%" }}
                        value={
                            state.linkedin_targeting.selected_memberBehaviors
                        }
                    >
                        {state.linkedin_targeting.memberBehaviors &&
                            state.linkedin_targeting.memberBehaviors.map(
                                (e) => <Option key={e.key}>{e.name}</Option>
                            )}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_growthRate
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_growthRate",
                                    e.target.checked
                                )
                            }
                        >
                            Growth Rate
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select growth rate"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("growthRate")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("growthRate", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam("growthRate", e);
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_growthRate
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_growthRate}
                    >
                        {state.linkedin_targeting.growthRate &&
                            state.linkedin_targeting.growthRate.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_interfaceLocales
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_interfaceLocales",
                                    e.target.checked
                                )
                            }
                        >
                            Interface Locales
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select interface locales"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("interfaceLocales")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("interfaceLocales", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam(
                                "interfaceLocales",
                                e
                            );
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_interfaceLocales
                        }
                        style={{ width: "100%" }}
                        value={
                            state.linkedin_targeting.selected_interfaceLocales
                        }
                    >
                        {state.linkedin_targeting.interfaceLocales &&
                            state.linkedin_targeting.interfaceLocales.map(
                                (e) => <Option key={e.key}>{e.name}</Option>
                            )}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_seniorities
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_seniorities",
                                    e.target.checked
                                )
                            }
                        >
                            Seniorities
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select seniorities"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("seniorities")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("seniorities", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam("seniorities", e);
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_seniorities
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_seniorities}
                    >
                        {state.linkedin_targeting.seniorities &&
                            state.linkedin_targeting.seniorities.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_locations
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_locations",
                                    e.target.checked
                                )
                            }
                        >
                            Locations
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select locations"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("locations")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("locations", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam("locations", e);
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_locations
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_locations}
                    >
                        {state.linkedin_targeting.locations &&
                            state.linkedin_targeting.locations.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_staffCountRanges
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_staffCountRanges",
                                    e.target.checked
                                )
                            }
                        >
                            Staff Count Ranges
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select staff count ranges"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("staffCountRanges")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("staffCountRanges", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam(
                                "staffCountRanges",
                                e
                            );
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_staffCountRanges
                        }
                        style={{ width: "100%" }}
                        value={
                            state.linkedin_targeting.selected_staffCountRanges
                        }
                    >
                        {state.linkedin_targeting.staffCountRanges &&
                            state.linkedin_targeting.staffCountRanges.map(
                                (e) => <Option key={e.key}>{e.name}</Option>
                            )}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_fieldsOfStudy
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_fieldsOfStudy",
                                    e.target.checked
                                )
                            }
                        >
                            Fields of study
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select fields of study"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("fieldsOfStudy")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("fieldsOfStudy", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam(
                                "fieldsOfStudy",
                                e
                            );
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_fieldsOfStudy
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_fieldsOfStudy}
                    >
                        {state.linkedin_targeting.fieldsOfStudy &&
                            state.linkedin_targeting.fieldsOfStudy.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_degrees
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_degrees",
                                    e.target.checked
                                )
                            }
                        >
                            Degrees
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select degrees"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) => props.onfetchLinkedinFacet("degrees")}
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("degrees", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam("degrees", e);
                        }}
                        disabled={
                            !state.linkedin_targeting.selected_overwrite_degrees
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_degrees}
                    >
                        {state.linkedin_targeting.degrees &&
                            state.linkedin_targeting.degrees.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_yearsOfExperienceRanges
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_yearsOfExperienceRanges",
                                    e.target.checked
                                )
                            }
                        >
                            Years of experience ranges
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select years of experience ranges"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet(
                                "yearsOfExperienceRanges"
                            )
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet(
                                "yearsOfExperienceRanges",
                                true
                            )
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam(
                                "yearsOfExperienceRanges",
                                e
                            );
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_yearsOfExperienceRanges
                        }
                        style={{ width: "100%" }}
                        value={
                            state.linkedin_targeting
                                .selected_yearsOfExperienceRanges
                        }
                    >
                        {state.linkedin_targeting.yearsOfExperienceRanges &&
                            state.linkedin_targeting.yearsOfExperienceRanges.map(
                                (e) => <Option key={e.key}>{e.name}</Option>
                            )}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_jobFunctions
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_jobFunctions",
                                    e.target.checked
                                )
                            }
                        >
                            Job functions
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select job functions"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("jobFunctions")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("jobFunctions", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam(
                                "jobFunctions",
                                e
                            );
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_jobFunctions
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_jobFunctions}
                    >
                        {state.linkedin_targeting.jobFunctions &&
                            state.linkedin_targeting.jobFunctions.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_ageRanges
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_ageRanges",
                                    e.target.checked
                                )
                            }
                        >
                            Age ranges
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select age ranges"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("ageRanges")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("ageRanges", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam("ageRanges", e);
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_ageRanges
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_ageRanges}
                    >
                        {state.linkedin_targeting.ageRanges &&
                            state.linkedin_targeting.ageRanges.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_profileLocations
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_profileLocations",
                                    e.target.checked
                                )
                            }
                        >
                            Profile locations
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select profile locations"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("profileLocations")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("profileLocations", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam(
                                "profileLocations",
                                e
                            );
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_profileLocations
                        }
                        style={{ width: "100%" }}
                        value={
                            state.linkedin_targeting.selected_profileLocations
                        }
                    >
                        {state.linkedin_targeting.profileLocations &&
                            state.linkedin_targeting.profileLocations.map(
                                (e) => <Option key={e.key}>{e.name}</Option>
                            )}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_genders
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_genders",
                                    e.target.checked
                                )
                            }
                        >
                            Genders
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select genders"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) => props.onfetchLinkedinFacet("genders")}
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("genders", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam("genders", e);
                        }}
                        disabled={
                            !state.linkedin_targeting.selected_overwrite_genders
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_genders}
                    >
                        {state.linkedin_targeting.genders &&
                            state.linkedin_targeting.genders.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.linkedin_targeting
                                    .selected_overwrite_industries
                            }
                            onChange={(e) =>
                                props.onselectLinkedinTargetParam(
                                    "overwrite_industries",
                                    e.target.checked
                                )
                            }
                        >
                            Industries
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select industries"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={(e) =>
                            props.onfetchLinkedinFacet("industries")
                        }
                        onFocus={(e) =>
                            props.onfetchLinkedinFacet("industries", true)
                        }
                        onChange={(e) => {
                            props.onselectLinkedinTargetParam("industries", e);
                        }}
                        disabled={
                            !state.linkedin_targeting
                                .selected_overwrite_industries
                        }
                        style={{ width: "100%" }}
                        value={state.linkedin_targeting.selected_industries}
                    >
                        {state.linkedin_targeting.industries &&
                            state.linkedin_targeting.industries.map((e) => (
                                <Option key={e.key}>{e.name}</Option>
                            ))}
                    </Select>
                </Form.Item>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            <div style={{ padding: 20 }}>
                <div style={{}}>
                    <Row>
                        <Col>
                            {/* <Form.Item label="Type">
                            {getFieldDecorator(
                                "linkedin_newad_type",
                                {
                                    initialValue: this.state
                                        .linkedin_newad_type
                                        ? this.state
                                            .linkedin_newad_type
                                        : null,
                                    onChange: (e) =>
                                        props.onSetState({
                                            linkedin_newad_type: e,
                                        }),
                                }
                            )(
                                <Select style={{ width: 200 }}>
                                    <Option value="textads">
                                        Text Ads
                                    </Option>
                                    <Option value="sponsoredcontent">
                                        Sponsored Content
                                    </Option>
                                    <Option value="messagead">
                                        Message Ads
                                    </Option>
                                    <Option value="conversationad">
                                        Conversation Ads
                                    </Option>
                                    <Option value="sponsoredvideo">
                                        Sponsored Video
                                    </Option>
                                    <Option value="sponsoredcarousel">
                                        Sponsored Carousel Ad
                                    </Option>
                                    <Option value="dynamicad">
                                        Dynamic Ads
                                    </Option>
                                </Select>
                            )}
                        </Form.Item> */}

                            <Form.Item label="costType">
                                {getFieldDecorator("linkedin_newad_costtype", {
                                    initialValue: state.linkedin_newad_costtype
                                        ? state.linkedin_newad_costtype
                                        : null,
                                    onChange: (e) =>
                                        props.onSetState({
                                            linkedin_newad_costtype: e,
                                        }),
                                })(
                                    <Select style={{ width: 200 }}>
                                        <Option value="CPM">CPM</Option>
                                        <Option value="CPC">CPC</Option>
                                        <Option value="CPV">CPV</Option>
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item label="totalBudget">
                                <InputNumber
                                    defaultValue={
                                        state.linkedin_newad_totalbudget
                                    }
                                    min={0}
                                    // max={100}
                                    formatter={(value) => `$ ${value / 100}`}
                                    parser={(value) =>
                                        Number(value.replace("$", "")) * 100
                                    }
                                    onChange={(e) => {
                                        props.onSetState({
                                            linkedin_newad_totalbudget: e,
                                        });
                                        console.info("e", e);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={
                                    <Checkbox
                                        checked={
                                            state.linkedin_newad_overwrite_dailybudget
                                        }
                                        onChange={(e) =>
                                            props.onSetState({
                                                linkedin_newad_overwrite_dailybudget:
                                                    e.target.checked,
                                            })
                                        }
                                    >
                                        Daily budget
                                    </Checkbox>
                                }
                            >
                                <InputNumber
                                    disabled={
                                        !state.linkedin_newad_overwrite_dailybudget
                                    }
                                    defaultValue={
                                        state.linkedin_newad_dailybudget
                                    }
                                    min={0}
                                    // max={100}
                                    formatter={(value) => `$ ${value / 100}`}
                                    parser={(value) =>
                                        Number(value.replace("$", "")) * 100
                                    }
                                    onChange={(e) => {
                                        props.onSetState({
                                            linkedin_newad_dailybudget: e,
                                        });
                                        console.info("e", e);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label="objectiveType">
                                {getFieldDecorator(
                                    "linkedin_newad_objectivetype",
                                    {
                                        initialValue: state.linkedin_newad_objectivetype
                                            ? state.linkedin_newad_objectivetype
                                            : null,
                                        onChange: (e) =>
                                            props.onSetState({
                                                linkedin_newad_objectivetype: e,
                                            }),
                                    }
                                )(
                                    <Select style={{ width: 200 }}>
                                        <Option value="BRAND_AWARENESS">
                                            BRAND_AWARENESS
                                        </Option>
                                        <Option value="ENGAGEMENT">
                                            ENGAGEMENT
                                        </Option>
                                        <Option value="JOB_APPLICANT">
                                            JOB_APPLICANT
                                        </Option>
                                        <Option value="LEAD_GENERATION">
                                            LEAD_GENERATION
                                        </Option>
                                        <Option value="VIDEO_VIEW">
                                            VIDEO_VIEW
                                        </Option>
                                        <Option value="WEBSITE_CONVERSION">
                                            WEBSITE_CONVERSION
                                        </Option>
                                        <Option value="WEBSITE_VISIT">
                                            WEBSITE_VISIT
                                        </Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label={"Start/End date"}>
                                <RangePicker
                                    format="MM/DD YYYY HH:mm"
                                    defaultValue={[
                                        moment(state.linkedin_newad_start_time),
                                        moment(state.linkedin_newad_end_time),
                                    ]}
                                    onChange={(e) => {
                                        console.info("e", e);
                                        const linkedin_newad_start_time = e[0].format();
                                        const linkedin_newad_end_time = e[1].format();
                                        props.onSetState({
                                            linkedin_newad_start_time,
                                            linkedin_newad_end_time,
                                        });
                                    }}
                                    disabledDate={(current) =>
                                        current &&
                                        current < moment().endOf("day")
                                    }
                                    showTime={{
                                        hideDisabledOptions: true,
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label="type">
                                {getFieldDecorator("linkedin_newad_type", {
                                    initialValue: state.linkedin_newad_type
                                        ? state.linkedin_newad_type
                                        : null,
                                    onChange: (e) =>
                                        props.onSetState({
                                            linkedin_newad_type: e,
                                        }),
                                })(
                                    <Select style={{ width: 200 }}>
                                        <Option value="TEXT_AD">TEXT_AD</Option>
                                        <Option value="SPONSORED_UPDATES">
                                            SPONSORED_UPDATES
                                        </Option>
                                        <Option value="SPONSORED_INMAILS">
                                            SPONSORED_INMAILS
                                        </Option>
                                        <Option value="DYNAMIC">DYNAMIC</Option>
                                    </Select>
                                )}
                            </Form.Item>

                            <Form.Item label="unitCost.amount">
                                <InputNumber
                                    defaultValue={
                                        state.linkedin_newad_unitCostamount
                                    }
                                    min={0}
                                    // max={100}
                                    formatter={(value) => `$ ${value / 100}`}
                                    parser={(value) =>
                                        Number(value.replace("$", "")) * 100
                                    }
                                    onChange={(e) => {
                                        props.onSetState({
                                            linkedin_newad_unitCostamount: e,
                                        });
                                        console.info("e", e);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="status">
                                {getFieldDecorator("linkedin_newad_status", {
                                    initialValue: state.linkedin_newad_status
                                        ? state.linkedin_newad_status
                                        : null,
                                    onChange: (e) =>
                                        props.onSetState({
                                            linkedin_newad_status: e,
                                        }),
                                })(
                                    <Select style={{ width: 200 }}>
                                        <Option value="ACTIVE">ACTIVE</Option>
                                        <Option value="PAUSED">PAUSED</Option>
                                        <Option value="ARCHIVED">
                                            ARCHIVED
                                        </Option>
                                        <Option value="COMPLETED">
                                            COMPLETED
                                        </Option>
                                        <Option value="CANCELED">
                                            CANCELED
                                        </Option>
                                        <Option value="DRAFT">DRAFT</Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Divider
                                orientation="left"
                                style={{
                                    marginLeft: 20,
                                }}
                            >
                                Accounts
                            </Divider>
                            <Form.Item label="Accounts" required>
                                <Tree
                                    checkable
                                    selectable={false}
                                    // onExpand={this.onExpand}
                                    // expandedKeys={state.expandedKeys}
                                    // autoExpandParent={state.autoExpandParent}
                                    onCheck={props.ononNewAdLinkedinCheck}
                                    checkedKeys={state.newad_linkedin_accounts}
                                    // onSelect={this.onSelect}
                                    // selectedKeys={state.selectedKeys}
                                    style={{
                                        marginTop: 2,
                                        marginLeft: -26,
                                    }}
                                >
                                    {renderAccountsTreeNodesForLinkedin(
                                        allLinkedinAccounts
                                    )}
                                </Tree>
                            </Form.Item>

                            {state.newad_linkedin_accounts &&
                            state.newad_linkedin_accounts.length > 0 ? (
                                <Fragment>
                                    <Divider orientation="left">
                                        Targeting
                                        <div
                                            style={{
                                                fontSize: "0.9rem",
                                                fontWeight: 400,
                                                position: "absolute",
                                                right: 10,
                                                background: "white",
                                                zIndex: 1,
                                                padding: "0 10px",
                                                top: 1,
                                            }}
                                        >
                                            Audience Count:{" "}
                                            <strong>
                                                {fetching ? (
                                                    <Icon
                                                        type="loading"
                                                        style={{
                                                            margin: "0 10px",
                                                        }}
                                                    />
                                                ) : (
                                                    state.linkedin_targeting
                                                        .audienceCount &&
                                                    state.linkedin_targeting.audienceCount.toLocaleString(
                                                        undefined, // leave undefined to use the browser's locale,
                                                        // or use a string like 'en-US' to override it.
                                                        {
                                                            minimumFractionDigits: 0,
                                                        }
                                                    )
                                                )}
                                            </strong>
                                        </div>
                                    </Divider>
                                    <Button
                                        size="small"
                                        loading={fetching}
                                        onClick={
                                            (e) =>
                                                props.ongetLinkedinAudienceCount(
                                                    "",
                                                    true
                                                ) // TODO: Add account key here
                                        }
                                        style={{
                                            float: "right",
                                            marginRight: 20,
                                        }}
                                    >
                                        Get audience count
                                    </Button>
                                    {linkedinTargeting}
                                </Fragment>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default AdsListModalFormPanelLinkedin;
