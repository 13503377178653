import React, { Component, Fragment } from "react";
import { firebase_messaging } from "./../../base";

import {
    Form,
    Switch,
    Row,
    Col,
    Divider,
    Dropdown,
    Menu,
    Button,
    Icon,
    Typography,
} from "antd";

const skins = [
    { key: "default", name: "Default" },
    { key: "square", name: "Square" },
];

const { Title } = Typography;

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
        };
    }

    async componentDidMount() {
        this.props.store.setCurrentSection("settings");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "Settings" }]);

        await this.loadInformation();
    }

    async loadInformation() {
        const isPushMessagingSubscribed =
            this.props.store.isPushMessagingSubscribed;
        console.log("isPushMessagingSubscribed = " + isPushMessagingSubscribed);
        this.setState({
            push_notification_subscribed: isPushMessagingSubscribed,
        });

        const loggedUser = this.props.store.getLoggedUser;
        console.info("Settings loggedUser", loggedUser);
        const user = await this.props.store.getAllUsers.filter(
            (u) => u.email === loggedUser.email
        )[0];
        const user_skin =
            user && user.skin
                ? skins.filter((skin) => skin.key === user.skin)[0]
                : skins.filter((skin) => skin.key === "default")[0];

        let email_notifications =
            user && user.email_notifications ? user.email_notifications : false;
        if (user && !user.email_notifications) email_notifications = true;
        this.setState({
            email_notifications,
        });
        console.info("user = ", user);

        console.log("email_notifications = " + email_notifications);

        this.setState({ user, user_skin });
    }

    onPushNotificationsChange = (checked) => {
        console.info(`onPushNotificationsChange checked = ${checked}`);

        if (checked === true) {
            // subscribe
            this.setState({ push_notification_subscribed: checked });
            firebase_messaging
                .requestPermission()
                .then(function () {
                    console.log("We have permission");
                    return firebase_messaging.getToken();
                })
                .then((token) => {
                    // mark them as subscribed
                    this.props.store.push_notification_token = token;
                    this.props.store.pushMessagingSubscribe(
                        this.state.user,
                        token
                    );
                })
                .catch((error) => {
                    console.info(error);
                    console.log("message=" + error.message);
                    this.props.store.displayErrorNotification({
                        description: error.message,
                    });
                    this.setState({ push_notification_subscribed: false });
                    this.props.store.push_notification_subscribed = false;
                });

            //this.props.store.pushMessagingSubscribe(this.state.user);
        } else {
            // Unsubscribe
            console.log("remove");
            this.props.store.pushMessagingUnsubscribe(this.state.user);
            this.setState({ push_notification_subscribed: checked });
        }
    };

    onEmailNotificationsChange = async (_checked) => {
        console.info("onEmailNotificationsChange _checked=" + _checked);

        await this.props.store.updateUser(this.state.user.key, {
            email_notifications: _checked,
        });
        await this.props.store.applyUserSettings();

        this.loadInformation();
    };

    onSkinChange = async (_e) => {
        console.info("onSkinChange _e.key=" + _e.key);

        await this.props.store.updateUser(this.state.user.key, {
            skin: _e.key,
        });
        await this.props.store.applyUserSettings();

        this.loadInformation();
    };

    render() {
        return (
            <Fragment>
                <div className={"Settings"}>
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                    <Title level={3} style={{ margin: "16px 0" }}>
                        Basic Settings
                    </Title>
                    <Divider />
                    <Row>
                        <Col span={20}>
                            <h4 style={{ margin: 0 }}>Push notifications</h4>
                            <p>
                                Receiving push notifications on this
                                device/browser.
                            </p>
                        </Col>
                        <Col span={4}>
                            <Switch
                                style={{ float: "right" }}
                                checked={
                                    this.state.push_notification_subscribed
                                }
                                onChange={(e) =>
                                    this.onPushNotificationsChange(e)
                                }
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={20} className={"social_SettingsSkin"}>
                            <h4 style={{ margin: 0 }}>Skin/Theme</h4>
                            <p>Change the way Uhub looks.</p>
                        </Col>
                        <Col
                            span={4}
                            style={{ textAlign: "right", marginTop: 5 }}
                        >
                            {/* <pre>{JSON.stringify(skins, null, 2)}</pre> */}
                            <Dropdown
                                overlay={
                                    <Menu onClick={this.onSkinChange}>
                                        {skins &&
                                            skins.map((skin) => (
                                                <Menu.Item key={skin.key}>
                                                    {skin.name}
                                                </Menu.Item>
                                            ))}
                                    </Menu>
                                }
                            >
                                <Button>
                                    {this.state.user_skin &&
                                        this.state.user_skin.name}{" "}
                                    <Icon type="down" />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Col span={20}>
                            <h4 style={{ margin: 0 }}>Email notifications</h4>
                            <p>
                                Receiving email notifications on{" "}
                                {this.state.user && this.state.user.email}.
                            </p>
                        </Col>
                        <Col span={4}>
                            <Switch
                                style={{ float: "right" }}
                                checked={this.state.email_notifications}
                                onChange={(e) =>
                                    this.onEmailNotificationsChange(e)
                                }
                            />
                        </Col>
                    </Row>
                    <Divider />
                </div>

                {/* <br />
                <Form layout="inline" onSubmit={this.handleSubmit}>
                    <Form.Item style={{ display: "flex", alignItems: "end" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={hasErrors(getFieldsError())}
                        >
                            Save Settings
                        </Button>
                    </Form.Item>
                </Form> */}
            </Fragment>
        );
    }
}

const WrappedHorizontalForm = Form.create({ name: "horizontal_login" })(
    Settings
);

export default WrappedHorizontalForm;
