class Api {
    prefix = "";
    error = null;
    loading = false;
    loaded = false;
    headers = {};
    unauthorized_callback = null;
    persistantData = {
        post: {},
        put: {},
    };

    constructor(settings) {
        if (settings.prefix) this.prefix = settings.prefix;
        if (settings.unauthorized_callback)
            this.unauthorized_callback = settings.unauthorized_callback;
        //console.log("Api Constructor settings", settings);
    }

    setHeaders(_headers) {
        //console.log("setHeaders", _headers);
        this.headers = _headers;
    }

    setPersistantData(_type, _data) {
        //console.log("setPersistantData _type", _type, "_data", _data);
        this.persistantData[_type] = {
            ...this.persistantData[_type],
            ..._data,
        };
    }

    get(_command, _body, _successCallback, _errorCallback) {
        //console.log("API get _command", _command);
        // Adding persistant data
        if (Object.keys(this.persistantData.post).length > 0) {
            _body = { ..._body, ...this.persistantData.post };
        }

        let settingsObject = {
            method: "get",
            headers: { "Content-Type": "application/json" },
        };

        // Authorization headers??
        if (Object.keys(this.headers).length > 0) {
            let that = this;
            settingsObject.headers = {};
            Object.keys(this.headers).forEach(function (key) {
                settingsObject.headers[key] = that.headers[key];
            });
        }

        //console.log("API get settingsObject", settingsObject);
        let resStatus = 0;
        fetch(
            this.prefix + _command + "?" + this.serialize(_body),
            settingsObject
        )
            .then((res) => {
                console.info("res", res);
                resStatus = res.status;
                return res.json();
            })
            .then((res) => {
                console.info("second resStatus", resStatus);
                switch (resStatus) {
                    case 500:
                        console.log("resStatus IS 500");
                        if (typeof _errorCallback === "function") {
                            console.info(
                                "_errorCallback IS function",
                                _errorCallback
                            );
                            _errorCallback(res.error);
                        }

                        break;
                    default:
                        console.log("resStatus IS default");
                        if (typeof _successCallback === "function")
                            _successCallback(res);
                        break;
                }
            })
            .catch((e) => {
                if (
                    resStatus === 403 &&
                    typeof this.unauthorized_callback === "function"
                ) {
                    this.unauthorized_callback(resStatus);
                } else {
                    if (typeof _errorCallback === "function") _errorCallback(e);
                }
            });
    }

    post(_command, _body, _successCallback, _errorCallback) {
        //console.log("API Post _command", _command, "_body", _body);
        // Adding persistant data
        if (
            this.persistantData.post &&
            Object.keys(this.persistantData.post).length > 0
        ) {
            _body = { ..._body, ...this.persistantData.post };
        }

        let settingsObject = {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(_body),
        };

        // Authorization headers??
        if (Object.keys(this.headers).length > 0) {
            let that = this;
            Object.keys(this.headers).forEach(function (key) {
                settingsObject.headers[key] = that.headers[key];
            });
        }

        //console.log("API Post settingsObject", settingsObject);
        let resStatus = 0;
        fetch(this.prefix + _command, settingsObject)
            .then((res) => {
                resStatus = res.status;
                return res.json();
            })
            .then((res) => {
                switch (resStatus) {
                    case 500:
                        if (typeof _errorCallback === "function")
                            _errorCallback(res.error);
                        break;
                    default:
                        if (typeof _successCallback === "function")
                            _successCallback(res);
                        break;
                }
            })
            .catch((e) => {
                if (
                    resStatus === 403 &&
                    typeof this.unauthorized_callback === "function"
                ) {
                    this.unauthorized_callback(resStatus);
                } else {
                    if (typeof _errorCallback === "function") _errorCallback(e);
                }
            });
    }

    put(_command, _body, _successCallback, _errorCallback) {
        //console.log("API Post _command", _command, "_body", _body);

        // Adding persistant data
        if (
            this.persistantData.put &&
            Object.keys(this.persistantData.put).length > 0
        ) {
            _body = { ..._body, ...this.persistantData.put };
        }

        let settingsObject = {
            method: "put",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(_body),
        };

        // Authorization headers??
        if (Object.keys(this.headers).length > 0) {
            let that = this;
            Object.keys(this.headers).forEach(function (key) {
                settingsObject.headers[key] = that.headers[key];
            });
        }

        //console.log("API Post settingsObject", settingsObject);
        let resStatus = 0;
        fetch(this.prefix + _command, settingsObject)
            .then((res) => {
                resStatus = res.status;
                return res.json();
            })
            .then((res) => {
                switch (resStatus) {
                    case 500:
                        if (typeof _errorCallback === "function")
                            _errorCallback(res.error);
                        break;
                    default:
                        if (typeof _successCallback === "function")
                            _successCallback(res);
                        break;
                }
            })
            .catch((e) => {
                if (
                    resStatus === 403 &&
                    typeof this.unauthorized_callback === "function"
                ) {
                    this.unauthorized_callback(resStatus);
                } else {
                    if (typeof _errorCallback === "function") _errorCallback(e);
                }
            });
    }

    delete(_command, _successCallback, _errorCallback) {
        //console.log("API Delete _command", _command);
        let settingsObject = {
            method: "delete",
        };

        // Authorization headers??
        if (Object.keys(this.headers).length > 0) {
            let that = this;
            Object.keys(this.headers).forEach(function (key) {
                settingsObject.headers[key] = that.headers[key];
            });
        }

        //console.log("API Delete settingsObject", settingsObject);
        let resStatus = 0;
        fetch(this.prefix + _command, settingsObject)
            .then((res) => {
                resStatus = res.status;
                return res.json();
            })
            .then((res) => {
                switch (resStatus) {
                    case 500:
                        if (typeof _errorCallback === "function")
                            _errorCallback(res.error);
                        break;
                    default:
                        if (typeof _successCallback === "function")
                            _successCallback(res);
                        break;
                }
            })
            .catch((e) => {
                if (
                    resStatus === 403 &&
                    typeof this.unauthorized_callback === "function"
                ) {
                    this.unauthorized_callback(resStatus);
                } else {
                    if (typeof _errorCallback === "function") _errorCallback(e);
                }
            });
    }

    serialize = function (obj, prefix) {
        var str = [],
            p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];
                str.push(
                    v !== null && typeof v === "object"
                        ? this.serialize(v, k)
                        : encodeURIComponent(k) + "=" + encodeURIComponent(v)
                );
            }
        }
        return str.join("&");
    };
}

export default Api;
