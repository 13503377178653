import React, { Component, Fragment } from "react";
import { Input, Form, Modal, Alert, Divider, Icon } from "antd";
import { social_notification } from "../../functions";
import { observer } from "mobx-react";

/* Add/Edit Form */
const ModalForm = Form.create({ name: "form_in_modal" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                checkedKeys: [],
                filter: "",
            };
        }

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            return (
                <Form {...formItemLayout}>
                    <Form.Item label="Email">
                        {getFieldDecorator("email", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the email of the account!",
                                    type: "email",
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Password">
                        {getFieldDecorator("password", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the password of the account!",
                                    type: "string",
                                },
                            ],
                        })(<Input.Password placeholder="Enter password" />)}
                    </Form.Item>
                    <Form.Item label="Name">
                        {getFieldDecorator(
                            "name",
                            {}
                        )(<Input placeholder="Enter name" />)}
                    </Form.Item>
                </Form>
            );
        }
    }
);

/* Add/Edit Form */
class UsersListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener("users_modal", (_action) => {
            this.setState({
                modal_visible: _action === "show" ? true : false,
            });
        });
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("users_modal");
    };

    onModalApply = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                social_notification("validation_error", "error");

                return;
            }

            this.props.store
                .createUser(values.email, values.password, values.name, "admin") //create new admin user
                .then((newUser) => {
                    console.info("newUser", newUser);
                    Modal.success({
                        title: "Successful Registration",
                        content: "The new user was created and is now enabled.",
                    });
                    form.resetFields();
                    this.onModalCancel();
                })
                .catch((error) => {
                    console.warn(error);
                });

            // app.auth()
            //     .createUserWithEmailAndPassword(values.email, values.password)
            //     .then(info => {
            //         console.info("INFO", info);
            //         form.resetFields();
            //         this.onModalCancel();
            //     })
            //     .catch(function(error) {
            //         // Handle Errors here.
            //         // var errorCode = error.code;
            //         var errorMessage = error.message;

            //         console.log(error);

            //         Modal.error({
            //             title: "Registration Error",
            //             content: errorMessage
            //         });
            //     });

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            // this.props.store.applyTagsToAccounts({
            //     ...values
            //     //...this.state.edit_data
            // });
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "users_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Create new user"}
                        okText={"Apply"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalApply} //onApply
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <Alert
                            message="Creating users"
                            banner
                            description={
                                <Fragment>
                                    Regular users cannot be created using this
                                    form.
                                    <br />
                                    You have to use the{" "}
                                    <a
                                        href="http://www.cnn.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        register
                                    </a>{" "}
                                    page instead.
                                </Fragment>
                            }
                            type="info"
                            showIcon
                        />
                        <br />
                        <Divider orientation="left">
                            New Administrator user
                        </Divider>
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(UsersListModal);
