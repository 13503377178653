import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { social_notification } from "./../../functions";
import config from "./../../config";
import CryptoJS from "crypto-js";
import moment from "moment";
import {
    Input,
    Form,
    Modal,
    AutoComplete,
    Select,
    Tabs,
    Timeline,
    Icon,
    Button,
    Alert,
} from "antd";

import AccountsListModalPlan from "./AccountsListModalPlan";
import FacebookTargeting from "./FacebookTargeting";
import LinkedinTargeting from "./LinkedinTargeting";
import TwitterTargeting from "./TwitterTargeting";

const { TextArea } = Input;
const { Option, OptGroup } = Select;
const { TabPane } = Tabs;

/* Add/Edit Form */
const ModalForm = Form.create({ name: "modal_form" })(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                checkedKeys: [],
                filter: "",
            };
        }

        componentDidMount = async () => {
            const loggedUser = this.props.store.getLoggedUser;
            console.info("loggedUser", loggedUser);

            let mappedChannels = null;

            var ciphertext = CryptoJS.AES.encrypt(
                loggedUser.world.key,
                config.cryptoJsSecret
            );
            console.log("ciphertext = " + ciphertext);
            const world_encrypt = encodeURIComponent(ciphertext);
            console.log("world_encrypt = " + world_encrypt);

            if (this.props.data.key) {
                const accountChannels =
                    await this.props.store.getAccountChannels(
                        this.props.data.key
                    );
                mappedChannels = accountChannels.map((channel) => ({
                    id: channel.id,
                    type: channel.type,
                    name: channel.name,
                    targeting: channel.targeting ? channel.targeting : null,
                }));
            }

            console.info("mappedChannels", mappedChannels);
            this.setState({ world_encrypt: world_encrypt, mappedChannels });
        };

        handleTagsChange = (_tags) => {
            console.info("handleTagsChange", _tags);
            //this.props.data = tags;
            this.props.form.setFieldsValue({ tags: _tags });
        };

        handleChange = (value) => {
            console.log(`selected ${value}`);
        };

        showPlanModal = () => {
            console.log("showPlanModal");
            this.props.store.showModalByKey("plan_modal");
        };

        updateTargeting = async (_provider, _level, _targeting, _channel) => {
            console.info(
                "updateTargeting _provider",
                _provider,
                "_level",
                _level,
                "_targeting",
                _targeting,
                "_channel",
                _channel
            );
            await this.props.store
                .saveAccountTargeting(
                    this.props.data.key,
                    _provider,
                    _level,
                    _targeting,
                    _channel ? _channel.id : null
                )
                .then((results) => {
                    console.info("results", results);
                });
        };

        render() {
            const { form, data } = this.props;
            const { getFieldDecorator } = form;

            //const locations = this.props.store.getLocations();

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            //const formItemLayout = null;

            const adminUsers = this.props.store.getAllUsers.filter(
                (user) => user.level === "admin"
            );
            const accountUser = this.props.store.getAllUsers.filter(
                (user) => user.email === data.email
            )[0];
            //console.info("adminUsers", adminUsers, "accountUser", accountUser);
            const clientRegisterURL =
                window.location.protocol +
                "//" +
                window.location.host +
                "/register/" +
                this.state.world_encrypt;

            const pane_Basic = (
                <TabPane tab={"Basic"} key="single" style={{ minHeight: 400 }}>
                    <div style={{ margin: "0 auto" }}>
                        <Form.Item label="Name">
                            {getFieldDecorator("name", {
                                initialValue: data.name,
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Email">
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        required: true,
                                        message:
                                            "Please input the email of the account!",
                                        type: "email",
                                    },
                                ],
                                initialValue: data.email,
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Phone">
                            {getFieldDecorator("phone", {
                                initialValue: data.phone,
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Website">
                            {getFieldDecorator("website", {
                                rules: [
                                    {
                                        message:
                                            "Please input the website of the account!",
                                        type: "url",
                                    },
                                ],
                                initialValue: data.website,
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Address">
                            {getFieldDecorator("address", {
                                initialValue: data.address,
                            })(<TextArea />)}
                        </Form.Item>
                        <Form.Item label="Billing">
                            {getFieldDecorator("billing", {
                                initialValue: data.billing,
                            })(<TextArea />)}
                        </Form.Item>
                        <Form.Item label="Notes">
                            {getFieldDecorator("notes", {
                                initialValue: data.notes,
                            })(<TextArea />)}
                        </Form.Item>
                        {/* <Form.Item label="Location">
                        {getFieldDecorator("location", {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please selected the location of the account!",
                                },
                            ],
                            initialValue: data.location,
                        })(
                            <Cascader
                                options={locations}
                                placeholder="Please select"
                            />
                        )}
                    </Form.Item> */}
                    </div>
                </TabPane>
            );

            const pane_Extras = (
                <TabPane tab={"Extras"} key="s" style={{ minHeight: 400 }}>
                    <div style={{ margin: "0 auto" }}>
                        {/* <Form.Item label="Tags">
                        {getFieldDecorator("tags", {
                            //getValueFromEvent: handleGetTags
                            initialValue: data.tags ? data.tags : [],
                            onChange: this.handleTagsChange,
                        })(
                            <EditableTagGroup
                                options={this.props.store.getAllTags.map(
                                    (tag) => tag.tag
                                )}
                            />
                        )}
                    </Form.Item> */}
                        <Form.Item label="Brand">
                            {getFieldDecorator("brand", {
                                initialValue: data.brand,
                            })(
                                <AutoComplete
                                    dataSource={this.props.store.getAllBrands}
                                    // style={{ width: 200 }}
                                    placeholder="input here"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Approval">
                            {getFieldDecorator("approval", {
                                initialValue: data.approval
                                    ? data.approval
                                    : "no",
                            })(
                                <Select
                                    // defaultValue="no"
                                    // style={{ width: 120 }}
                                    onChange={this.handleChange}
                                >
                                    <Option value="no">
                                        No approval needed
                                    </Option>
                                    <Option
                                        value={accountUser && accountUser.uid}
                                    >
                                        Account should approve
                                    </Option>
                                    <OptGroup label="--- Administrators ---">
                                        {adminUsers.map((admin) => (
                                            <Option
                                                key={admin.uid}
                                                value={admin.uid}
                                            >
                                                {admin.name
                                                    ? admin.name
                                                    : admin.email}
                                            </Option>
                                        ))}
                                    </OptGroup>
                                </Select>
                            )}
                        </Form.Item>
                        {/* <Form.Item label="Facebook Ad Account">
                            {getFieldDecorator("facebook_ad_account", {
                                rules: [
                                    {
                                        message:
                                            "Please input the facebook ad account!",
                                        type: "string",
                                    },
                                ],
                                initialValue: data.facebook_ad_account,
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Linkedin Ad Account">
                            {getFieldDecorator("linkedin_ad_account", {
                                rules: [
                                    {
                                        message:
                                            "Please input the linkedin ad account!",
                                        type: "string",
                                    },
                                ],
                                initialValue: data.linkedin_ad_account,
                            })(<Input />)}
                        </Form.Item>
                        <Divider>Ads settings</Divider>
                        <Form.Item label="Ad/Boost Budget">
                            {getFieldDecorator("ad_budget", {
                                rules: [
                                    {
                                        message: "Please input the ad budget!",
                                        type: "number",
                                    },
                                ],
                                initialValue: data.ad_budget,
                            })(
                                <Slider
                                    max={300}
                                    // disabled={
                                    //     !data.facebook_ad_account ||
                                    //     data.facebook_ad_account === ""
                                    // }
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={"Cost per ad/boost"}>
                            <InputNumber
                                defaultValue={data.ad_cost}
                                min={0}
                                // max={100}
                                step={100}
                                formatter={(value) => `$ ${value / 100}`}
                                parser={(value) =>
                                    Number(value.replace("$", "")) * 100
                                }
                                onChange={(e) => {
                                    data.ad_cost = e;
                                    // console.info("e", e);
                                }}
                            />
                        </Form.Item> */}
                    </div>
                </TabPane>
            );

            // const pane_Template = (
            //     <TabPane tab={"Template"} key="f" style={{ minHeight: 400 }}>
            //         <Form.Item label="*name">
            //             {getFieldDecorator("template_name", {
            //                 initialValue: data.template_name,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*email">
            //             {getFieldDecorator("template_email", {
            //                 initialValue: data.template_email,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*phone">
            //             {getFieldDecorator("template_phone", {
            //                 initialValue: data.template_phone,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*website">
            //             {getFieldDecorator("template_website", {
            //                 initialValue: data.template_website,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*location">
            //             {getFieldDecorator("template_location", {
            //                 initialValue: data.template_location,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*appendix">
            //             {getFieldDecorator("template_appendix", {
            //                 initialValue: data.template_appendix,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*firstcomment">
            //             {getFieldDecorator("template_firstcomment", {
            //                 initialValue: data.template_firstcomment,
            //             })(<Input />)}
            //         </Form.Item>
            //     </TabPane>
            // );

            const pane_Payment = (
                <TabPane
                    tab={
                        data.paid_until ? (
                            "Payment"
                        ) : (
                            <span>
                                <Icon type="warning" />
                                Payment
                            </span>
                        )
                    }
                    disabled={!data.email}
                    key="p"
                    style={{ minHeight: 400 }}
                >
                    <div>
                        <div
                            style={{
                                color: !data.paid_until ? "#fa541c" : "#1890ff",
                                background: !data.paid_until
                                    ? "#fff2e8"
                                    : "#e6f7ff",
                                borderRadius: 6,
                                padding: "15px 20px 5px 20px",
                                marginBottom: 20,
                                position: "relative",
                            }}
                        >
                            {data.paid_until ? "Paid until" : "Paid status"}:{" "}
                            <h2
                                style={{
                                    color: !data.paid_until
                                        ? "#fa541c"
                                        : "#1890ff",
                                }}
                            >
                                {!data.paid_until
                                    ? "Unpaid"
                                    : moment(data.paid_until)
                                          .utc()
                                          .format("llll")}
                            </h2>
                            <Button
                                // type="primary"
                                style={{
                                    position: "absolute",
                                    top: 25,
                                    right: 20,
                                }}
                                onClick={this.showPlanModal}
                            >
                                Change
                            </Button>
                        </div>

                        {data.payment_history ? (
                            <Form.Item label="Payment History">
                                <Timeline style={{ marginTop: 15 }}>
                                    {data.payment_history.map(
                                        (paymentRecord, paymentRecordIndex) => {
                                            return (
                                                <Timeline.Item
                                                    key={paymentRecordIndex}
                                                >
                                                    <strong>
                                                        {moment(
                                                            paymentRecord.datetime
                                                        )
                                                            .utc()
                                                            .format("lll")}
                                                    </strong>
                                                    <br />
                                                    Total amount: $
                                                    <strong>
                                                        {paymentRecord
                                                            .stripe_data
                                                            .planData &&
                                                            Number(
                                                                paymentRecord
                                                                    .stripe_data
                                                                    .planData
                                                                    .amount /
                                                                    100
                                                            )}
                                                    </strong>
                                                    <br />
                                                    Subscription:{" "}
                                                    <strong>
                                                        {paymentRecord.subscription &&
                                                            paymentRecord
                                                                .subscription
                                                                .name}{" "}
                                                        ($
                                                        {paymentRecord.subscription &&
                                                            paymentRecord
                                                                .subscription
                                                                .amount}
                                                        )
                                                    </strong>
                                                    <br />
                                                    Additional:{" "}
                                                    {paymentRecord.additional &&
                                                    paymentRecord.additional
                                                        .conversion_tracking &&
                                                    paymentRecord.additional
                                                        .conversion_tracking
                                                        .status === true ? (
                                                        <strong>
                                                            Conversion tracking
                                                            ($
                                                            {
                                                                paymentRecord
                                                                    .additional
                                                                    .conversion_tracking
                                                                    .amount
                                                            }
                                                            )
                                                        </strong>
                                                    ) : null}
                                                    {paymentRecord.additional &&
                                                    paymentRecord.additional &&
                                                    paymentRecord.additional
                                                        .ad_budget &&
                                                    paymentRecord.additional
                                                        .ad_budget.status ===
                                                        true ? (
                                                        <strong>
                                                            Ad budget ($
                                                            {
                                                                paymentRecord
                                                                    .additional
                                                                    .ad_budget
                                                                    .amount
                                                            }
                                                            )
                                                        </strong>
                                                    ) : null}
                                                </Timeline.Item>
                                            );
                                        }
                                    )}
                                    <Timeline.Item key={"start"}>
                                        Start
                                    </Timeline.Item>
                                </Timeline>
                            </Form.Item>
                        ) : null}
                    </div>
                </TabPane>
            );

            // const pane_Template = (
            //     <TabPane tab={"Template"} key="f" style={{ minHeight: 400 }}>
            //         <Form.Item label="*name">
            //             {getFieldDecorator("template_name", {
            //                 initialValue: data.template_name,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*email">
            //             {getFieldDecorator("template_email", {
            //                 initialValue: data.template_email,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*phone">
            //             {getFieldDecorator("template_phone", {
            //                 initialValue: data.template_phone,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*website">
            //             {getFieldDecorator("template_website", {
            //                 initialValue: data.template_website,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*location">
            //             {getFieldDecorator("template_location", {
            //                 initialValue: data.template_location,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*appendix">
            //             {getFieldDecorator("template_appendix", {
            //                 initialValue: data.template_appendix,
            //             })(<Input />)}
            //         </Form.Item>
            //         <Form.Item label="*firstcomment">
            //             {getFieldDecorator("template_firstcomment", {
            //                 initialValue: data.template_firstcomment,
            //             })(<Input />)}
            //         </Form.Item>
            //     </TabPane>
            // );

            const pane_Targeting = (
                <TabPane
                    tab={"Paid Units Targeting"}
                    key="t"
                    style={{ minHeight: 400 }}
                >
                    <div style={{ margin: "0 auto" }}>
                        <Tabs
                            tabPosition={"left"}
                            className={"social_TabsLeft"}
                        >
                            {/* <TabPane
                                tab={
                                    <Fragment>
                                        <Icon
                                            type="facebook"
                                            theme="filled"
                                            style={{
                                                color: this.props.store.getTagColorForType(
                                                    "facebook"
                                                ),
                                            }}
                                        />
                                        Facebook
                                    </Fragment>
                                }
                                key="fb_general"
                            >
                                <div style={{ width: "50%", margin: "0 auto" }}>
                                    <FacebookTargeting
                                        store={this.props.store}
                                        targeting={data.facebook.targeting}
                                        updateTargeting={(targeting) =>
                                            this.updateTargeting(
                                                "facebook",
                                                "basic",
                                                targeting
                                            )
                                        }
                                    />
                                </div>
                            </TabPane> */}
                            {this.state.mappedChannels &&
                                this.state.mappedChannels
                                    .filter(
                                        (channel) => channel.type === "facebook"
                                    )
                                    .map((channel) => (
                                        <TabPane
                                            tab={
                                                <Fragment>
                                                    <Icon
                                                        type={channel.type}
                                                        theme={
                                                            channel.type !==
                                                            "twitter"
                                                                ? "filled"
                                                                : null
                                                        }
                                                        style={{
                                                            color: this.props.store.getTagColorForType(
                                                                channel.type
                                                            ),
                                                        }}
                                                    />
                                                    {channel.name}
                                                </Fragment>
                                            }
                                            key={channel.id}
                                        >
                                            <div
                                                style={{
                                                    width: "50%",
                                                    margin: "0 auto",
                                                    height: 550,
                                                    overflowY: "auto",
                                                }}
                                            >
                                                <FacebookTargeting
                                                    store={this.props.store}
                                                    targeting={
                                                        channel.targeting
                                                            ? channel.targeting
                                                            : null
                                                    }
                                                    updateTargeting={(
                                                        targeting
                                                    ) =>
                                                        this.updateTargeting(
                                                            "facebook",
                                                            "channel",
                                                            targeting,
                                                            channel
                                                        )
                                                    }
                                                />
                                            </div>
                                        </TabPane>
                                    ))}

                            {this.state.mappedChannels &&
                                this.state.mappedChannels
                                    .filter(
                                        (channel) => channel.type === "linkedin"
                                    )
                                    .map((channel) => (
                                        <TabPane
                                            tab={
                                                <Fragment>
                                                    <Icon
                                                        type={channel.type}
                                                        theme={
                                                            channel.type !==
                                                            "twitter"
                                                                ? "filled"
                                                                : null
                                                        }
                                                        style={{
                                                            color: this.props.store.getTagColorForType(
                                                                channel.type
                                                            ),
                                                        }}
                                                    />
                                                    {channel.name}
                                                </Fragment>
                                            }
                                            key={channel.id}
                                        >
                                            <div
                                                style={{
                                                    width: "50%",
                                                    margin: "0 auto",
                                                    height: 550,
                                                    overflowY: "auto",
                                                }}
                                            >
                                                <LinkedinTargeting
                                                    store={this.props.store}
                                                    targeting={
                                                        channel.targeting
                                                            ? channel.targeting
                                                            : null
                                                    }
                                                    account_key={
                                                        this.props.data.key
                                                    }
                                                    updateTargeting={(
                                                        targeting
                                                    ) =>
                                                        this.updateTargeting(
                                                            "linkedin",
                                                            "basic",
                                                            targeting,
                                                            channel
                                                        )
                                                    }
                                                />
                                            </div>
                                        </TabPane>
                                    ))}

                            {this.state.mappedChannels &&
                                this.state.mappedChannels
                                    .filter(
                                        (channel) => channel.type === "twitter"
                                    )
                                    .map((channel) => (
                                        <TabPane
                                            tab={
                                                <Fragment>
                                                    <Icon
                                                        type={channel.type}
                                                        theme={
                                                            channel.type !==
                                                            "twitter"
                                                                ? "filled"
                                                                : null
                                                        }
                                                        style={{
                                                            color: this.props.store.getTagColorForType(
                                                                channel.type
                                                            ),
                                                        }}
                                                    />
                                                    {channel.name}
                                                </Fragment>
                                            }
                                            key={channel.id}
                                        >
                                            <div
                                                style={{
                                                    width: "50%",
                                                    margin: "0 auto",
                                                    height: 550,
                                                    overflowY: "auto",
                                                }}
                                            >
                                                <TwitterTargeting
                                                    store={this.props.store}
                                                    targeting={
                                                        channel.targeting
                                                            ? channel.targeting
                                                            : null
                                                    }
                                                    account_key={
                                                        this.props.data.key
                                                    }
                                                    updateTargeting={(
                                                        targeting
                                                    ) =>
                                                        this.updateTargeting(
                                                            "twitter",
                                                            "basic",
                                                            targeting,
                                                            channel
                                                        )
                                                    }
                                                />
                                            </div>
                                        </TabPane>
                                    ))}
                        </Tabs>
                    </div>
                </TabPane>
            );

            return (
                <Fragment>
                    {!this.props.data.key ? (
                        <Fragment>
                            <Alert
                                message="New Account URL"
                                description={
                                    <Fragment>
                                        If you want to share an URL where new
                                        account can be registered, please use
                                        the following one:
                                        <br />
                                        <br />
                                        <a
                                            style={{ fontWeight: "bold" }}
                                            href={clientRegisterURL}
                                            target={"_blank"}
                                            rel="noopener noreferrer"
                                        >
                                            {clientRegisterURL}
                                        </a>
                                    </Fragment>
                                }
                                type="info"
                                // showIcon
                                style={{
                                    border: 0,
                                    whiteSpace: "pre-line",
                                }}
                            />
                            <br />
                        </Fragment>
                    ) : null}
                    {this.props.data.key ? (
                        <Tabs
                        // defaultActiveKey="1"
                        >
                            {pane_Basic}
                            {pane_Extras}
                            {/* {pane_Template} */}
                            {pane_Targeting}
                            {/* {pane_Payment} */}
                        </Tabs>
                    ) : null}
                    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                </Fragment>
            );
        }
    }
);

/* Wrapper component */
class AccountsListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
            errors_text: "",
        };
    }

    componentDidMount = () => {
        // this.props.store.registerModalListener("accounts_modal", (_action) => {
        //     this.setState({
        //         modal_visible: _action === "show" ? true : false,
        //     });
        // });
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener("accounts_modal", (_action) => {
            this.setState({
                modal_visible: _action === "show" ? true : false,
            });
            if (
                this.props.callback &&
                typeof this.props.callback === "function"
            )
                this.props.callback(_action, this.props.data.key);
        });
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("accounts_modal");
        this.props.store.unregisterModalListener("plan_modal");
    };

    onModalCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                // let errors = [];
                // Object.keys(err).map((field) => {
                //     errors.push(
                //         `${
                //             field.toString().substr(0, 1).toUpperCase() +
                //             field.toString().substr(1)
                //         } - ${err[field].errors
                //             .map((e) => e.message)
                //             .join(", ")}`
                //     );
                // });
                // //this.setState({ errors_text: errors.join("\r\n") });
                // notification["error"]({
                //     message: "Validation errors",
                //     description: errors.join("\r\n"),
                //     style: { whiteSpace: "pre-line" },
                // });
                social_notification("validation_error", "error");
                return;
            }

            // // Patch the location
            // values["location_string"] = `${values["location"][0]}/${values["location"][1]}`;
            // // Patch the tags
            // values["tags_string"] = values["tags"].join('/');

            this.props.store.addAccount({ ...values });

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalEdit = async () => {
        const { form } = this.formRef.props;
        form.validateFields(async (err, values) => {
            if (err) {
                social_notification("account_created", "error");
                return;
            }

            // // Patch the location
            // values["location_string"] = `${values["location"][0]}/${values["location"][1]}`;
            // // Patch the tags
            // values["tags_string"] = values["tags"].join('/');

            console.log("Received values of form: ", values);
            console.log("account key: ", this.props.data.key);

            await this.props.store.updateAccount(this.props.data.key, values);

            form.resetFields();
            this.onModalCancel(); // hide the modal
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "accounts_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        const { data } = this.props;

        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Fragment>
                        <Modal
                            width={"70vw"}
                            visible={true}
                            title={
                                data && !data.email
                                    ? "Create new account"
                                    : "Edit account"
                            }
                            okText={data && !data.email ? null : "Save"}
                            onCancel={this.onModalCancel}
                            onOk={
                                data && !data.email
                                    ? null //this.onModalCreate
                                    : this.onModalEdit
                            }
                            okButtonProps={
                                data && !data.email
                                    ? { style: { display: "none" } }
                                    : null
                            }
                            maskClosable={false}
                        >
                            <Icon
                                type="info-circle"
                                className={"social_modalHintToggle"}
                                style={{
                                    color: this.state.showHint
                                        ? this.props.store.getTagColorForType(
                                              "campaign"
                                          )
                                        : null,
                                }}
                                onClick={(e) =>
                                    this.setState({
                                        showHint: !this.state.showHint,
                                    })
                                }
                            />
                            {this.state.showHint ? (
                                <Alert
                                    type="info"
                                    message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                    banner
                                    //closable
                                    showIcon={false}
                                    onClose={(e) =>
                                        this.setState({ showHint: false })
                                    }
                                    className={"social_modalHint"}
                                />
                            ) : null}
                            <ModalForm
                                store={this.props.store}
                                wrappedComponentRef={this.saveFormRef}
                                data={data}
                            />
                            {this.state.errors_text.length > 0 ? (
                                <Alert
                                    message="Errors"
                                    description={this.state.errors_text}
                                    type="error"
                                    showIcon
                                    style={{
                                        border: 0,
                                        whiteSpace: "pre-line",
                                    }}
                                />
                            ) : null}
                        </Modal>
                        <AccountsListModalPlan
                            store={this.props.store}
                            account={data}
                        />
                    </Fragment>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(AccountsListModal);
