import React, { Component, Fragment } from "react";
//import { Icon, Divider } from "antd";
import moment from "moment";

const twitterStyles = {
    background: "#e9ebee",
    padding: 10,
    //     border: "1px solid red",
};
const twitterStylesCard = {
    //     marginBottom: 60,
    marginLeft: -1,
    marginRight: -1,
    position: "relative",
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    boxSizing: "border-box",

    overflow: "hidden",
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "1px solid #e1e8ed",
    borderRadius: 5,
    maxWidth: 550,
    padding: "20px 20px 11.6px 20px",
};
const twitterCardHeader = {
    height: 60,
    padding: 16,
    flexAlign: "center",
    alignItems: "center",
};
const twitterCardHeaderAvatar = {
    width: 36,
    height: 36,
    top: 19,
    left: 19,
    position: "absolute",
    backgroundColor: "#ddd",
    borderRadius: "100%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
const twitterCardHeaderAuthor = {
    top: 18,
    left: 70,
    position: "absolute",
    color: "#262626",
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 16,
};
const twitterCardHeaderAuthorEmail = {
    top: 36,
    left: 70,
    position: "absolute",
    color: "#697882",
    fontWeight: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 14,
};
const twitterCardImage = {
    borderRadius: 5,
    width: "100%",
    marginBottom: 5,
};
const twitterCardHeaderDateTime = {
    position: "absolute",
    top: 20,
    right: 20,
    color: "#999",
    fontWeight: 100,
    width: 170,
    textAlign: "right",
    fontSize: "0.8rem",
};
// const twitterCardInfo = {
//     lineHeight: 1.5,
//     margin: 10,
//     color: "#262625",
//     position: "relative",
//     paddingLeft: 30,
// };
// const twitterCommentsHeader = {
//     padding: 10,
//     color: "#999",
//     borderTop: "1px solid #ccc",
//     marginTop: 3,
// };
// const twitterComments = {
//     padding: 10,
//     color: "#262625",
// };
// const twitterCommentAuthor = {
//     color: "#666",
//     fontWeight: 600,
// };
// const twitterCommentDate = {
//     color: "#999",
//     fontWeight: 100,
// };
// const twitterCommentText = {
//     color: "#262626",
//     fontWeight: 600,
// };
const twitterCardCaption = {
    padding: "10px 0px",
    color: "#1c2022",
    marginTop: -10,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginBottom: 10,

    //     borderTop: "1px solid #ccc",
};
// Inspiring crates delivered every month for kids of all
//                     ages✨. Get 50% off your first month with promo code:
//                     Weekend50

class SocialTwitterPost extends Component {
    state = {
        post: null,
        author: "",
        avatar: "",
        insights: null,
        comments: [],
    };

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        this.setState({
            post: this.props.post,
            author: this.props.post.user.screen_name,
            avatar: this.props.avatar,
            email: this.props.post.user.name,
        });
        //this.parseInsights();
        //this.parseComments();
    }

    render() {
        //const content = this.parseContent();

        return (
            <Fragment>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                        this.state.post &&
                        `https://twitter.com/${this.state.post.user.screen_name}/status/${this.state.post.id_str}`
                    }
                >
                    <div style={twitterStyles}>
                        <div style={twitterStylesCard}>
                            <div style={twitterCardHeader}>
                                <div
                                    style={{
                                        ...twitterCardHeaderAvatar,
                                        backgroundImage: `url(${this.state.avatar})`,
                                    }}
                                />
                                <div style={twitterCardHeaderAuthor}>
                                    {this.state.author}
                                </div>
                                <div style={twitterCardHeaderAuthorEmail}>
                                    {this.state.email}
                                </div>
                                <div style={twitterCardHeaderDateTime}>
                                    {this.state.post
                                        ? moment(this.state.post.created_at)
                                              .utc()
                                              .local()
                                              .format("LLLL")
                                        : null}
                                </div>
                            </div>
                            <div style={twitterCardCaption}>
                                {this.state.post ? this.state.post.text : null}
                            </div>
                            {this.state.post &&
                            this.state.post.entities &&
                            this.state.post.entities.media &&
                            this.state.post.entities.media.length > 0 ? (
                                <div>
                                    <img
                                        style={twitterCardImage}
                                        src={this.state.post.entities.media[0].media_url.substring(
                                            5
                                        )}
                                        alt=""
                                    />
                                </div>
                            ) : null}

                            {/* <div style={twitterCardInfo}>
                                <Icon
                                    type="heart"
                                    theme="filled"
                                    style={{
                                        fontSize: "1.5rem",
                                        position: "absolute",
                                        left: 0,
                                    }}
                                />
                                {this.state.post
                                    ? this.state.post.like_count
                                    : 0}{" "}
                                likes
                            </div> */}
                            {/* <div style={twitterCommentsHeader}>
                                Comments{" "}
                                {this.state.post
                                    ? "(" + this.state.post.comments_count + ")"
                                    : null}
                            </div> */}
                            {/* <div style={twitterComments}>
                                {this.state.post
                                    ? this.state.post.comments.data.map(
                                          (c, cIndex) => {
                                              return (
                                                  <Fragment key={cIndex}>
                                                      <div
                                                          style={
                                                              twitterCommentAuthor
                                                          }
                                                      >
                                                          {c.id}
                                                      </div>
                                                      <div
                                                          style={
                                                              twitterCommentDate
                                                          }
                                                      >
                                                          {moment(c.timestamp)
                                                              .utc()
                                                              .local()
                                                              .format("LLLL")}
                                                      </div>
                                                      <div
                                                          style={
                                                              twitterCommentText
                                                          }
                                                      >
                                                          {c.text}
                                                      </div>

                                                      <Divider />
                                                  </Fragment>
                                              );
                                          }
                                      )
                                    : null}
                            </div>  */}
                        </div>
                        {/* <pre>
                        this.state.post
                        {JSON.stringify(this.state.post, null, 2)}
                    </pre>
                    {this.props.children} */}
                    </div>
                </a>
            </Fragment>
        );
    }
}

export default SocialTwitterPost;
