import React, { Component, Fragment } from "react";

import config from "../../config";
import tooltips from "../../tooltips";

import { observer } from "mobx-react";

import {
    Empty,
    List,
    Button,
    Typography,
    Input,
    Pagination,
    Row,
    Col,
    Tooltip,
    Modal,
    Badge,
    Icon,
    Checkbox,
} from "antd";

import AuditorsListModal from "./AuditorsListModal";
import AuditorsListModalMonitor from "./AuditorsListModalMonitor";
import Bulk from "../Bulk";
import scss_variables from "./../../_variables.scss";
import SocialListHeader from "../SocialListHeader";
import SocialListColumn from "../SocialListColumn";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

class AuditorsList extends Component {
    listBulk = null;

    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            //edit_data: {},
            pagination_currentPage: 1,
            sortby: "name",
            sortasc: true,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.store.setCurrentSection("listening");

        // Breadcrumbs
        // this.props.store.setBreadcrumbs([
        //     { route: `/dashboard/competitors`, title: "Competitors" },
        // ]);
    }
    componentDidMount() {
        //console.info("componentDidMount this.state.info", this.state.info);
        // Bulk listing
        this.listBulk = new Bulk({
            name: "auditorsList",
            list: this.props.store.getAllAuditors.map((element) => element.key),
            component: this,
            actions: [
                {
                    key: "delete",
                    title: "Delete selected",
                    icon: <Icon type="delete" />,
                    function: async (_key) => {
                        console.log("Action _key=" + _key);
                        //await this.props.store.deleteCompetitor({ key: _key });
                    },
                    confirm: "Do you want to delete the selected elements?",
                },
                {
                    key: "report",
                    title: "Report selected",
                    icon: <Icon type="pie-chart" />,
                    function: async (_key) => {
                        console.log("Report _key=" + _key);
                        //await this.props.store.deleteTag(_key);
                    },
                    combined: true,
                },
            ],
            callback: () => {
                console.log("READY");
            },
        });
    }

    componentWillUnmount() {
        //this.props.store.unbindAccountsCollection();
        this.props.store.unregisterModalListener("auditors_modal_add");
    }

    showModal = () => {
        console.log("showModal");
        this.setState({
            edit_data: {},
        });

        this.props.store.showModalByKey("auditors_modal_add");
    };
    showModalForEdit = (_evt, _auditor) => {
        console.info("showModalForEdit _evt", _evt, "_auditor", _auditor);

        this.setState({
            edit_data: _auditor,
        });

        this.props.store.showModalByKey("auditors_modal_add");
    };
    showModalForMonitor = (_evt, _auditor) => {
        console.info("showModalForMonitor _evt", _evt, "_auditor", _auditor);

        this.setState({
            edit_data: _auditor,
        });

        this.props.store.showModalByKey("auditors_modal_monitor", _auditor.key);
    };

    setSorting = (_sorting) => {
        console.info("setSorting _sorting", _sorting);
        let { sortby, sortasc } = this.state;

        if (sortby === _sorting) {
            sortasc = !sortasc;
        } else {
            sortasc = true;
        }

        console.info("sortby", sortby, "sortasc", sortasc);
        this.setState({ sortby: _sorting, sortasc });
    };

    handleDeleteAuditor = (_evt, _item) => {
        console.info("handleDeleteAuditor _evt", _evt, "_item", _item);

        let that = this;
        confirm({
            title: "Are you sure you want to delete this?",
            okType: "danger",
            onOk() {
                that.props.store.deleteAuditor(_item);
            },
        });
    };

    onSearchChange = (_search) => {
        console.info("onSearchChange _search", _search);
        this.setState({ filter: _search });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    paginationSetPage = (_page) => {
        console.info("paginationSetPage _page", _page);
        this.setState({ pagination_currentPage: _page });
    };

    getListingDataSource = () => {
        let results = this.props.store.getAllAuditors.filter((c) => {
            return (
                c.name
                    .toUpperCase()
                    .indexOf(this.state.filter.toUpperCase()) !== -1
            );
        });

        // --- sorting ---
        results.sort((a, b) => {
            //console.info("results.sort (this.state.sortby)", this.state.sortby);

            var nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();

            switch (this.state.sortby) {
                case "name":
                    if (this.state.sortasc === true) {
                        if (nameA <= nameB) return -1;
                        if (nameA > nameB) return 1;
                    } else {
                        if (nameA <= nameB) return 1;
                        if (nameA > nameB) return -1;
                    }
                    break;

                default:
                    return 0;
            }
            return 0;
        });
        // --- end of sorting

        return results.slice(
            (this.state.pagination_currentPage - 1) * config.PAGINATION_PERPAGE,
            this.state.pagination_currentPage * config.PAGINATION_PERPAGE
        );
    };

    render() {
        const auditorsLength = this.props.store.getAllAuditorsLength;

        const pagesCount = auditorsLength / config.PAGINATION_PERPAGE;
        //console.log("pagesCount = " + pagesCount);

        const header = (
            <SocialListHeader
                title="Listeners"
                newButtonClickHandle={this.showModal}
                newButtonText="New listener"
                newButtonTooltip={tooltips.AUDITORS.NEWBUTTON}
                bulk={this.listBulk}
                searchChangeHandle={this.onSearchChange}
            />
        );

        // let header = (
        //     <Row
        //         type="flex"
        //         justify="space-between"
        //         style={{ marginBottom: 10 }}
        //     >
        //         <Col>
        //             <Title level={3} style={{ float: "left", paddingRight: 5 }}>
        //                 Listeners
        //             </Title>
        //             <Badge
        //                 count={auditorsLength}
        //                 style={{ backgroundColor: "#1890ff" }}
        //             />
        //         </Col>
        //         <Col>
        //             {
        //                 <Tooltip
        //                     title={tooltips.AUDITORS.NEWBUTTON}
        //                     mouseEnterDelay={tooltips.SETTINGS.mouseEnterDelay}
        //                 >
        //                     <Button
        //                         type="primary"
        //                         // icon="plus"
        //                         size="small"
        //                         onClick={this.showModal}
        //                         style={{
        //                             marginLeft: 20,
        //                             marginTop: 5,
        //                             fontSize: 12,
        //                         }}
        //                     >
        //                         New listener
        //                     </Button>
        //                 </Tooltip>
        //             }
        //         </Col>
        //     </Row>
        // );

        let body =
            auditorsLength === 0 ? (
                <Empty />
            ) : (
                <List
                    size="small"
                    className="AuditorsList"
                    header={
                        <Fragment>
                            {/* <Row type="flex" justify="space-between">
                                <Col
                                    style={{
                                        minWidth: 25,
                                        lineHeight: 1.2,
                                    }}
                                >
                                    <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                        style={{ marginRight: 10 }}
                                    />
                                    {this.listBulk &&
                                        this.listBulk.renderOptionsAsList()}
                                </Col>
                                <Col>
                                
                                    <Search
                                        placeholder="filter"
                                        onSearch={this.onSearchChange}
                                        onChange={(e) =>
                                            this.onSearchChange(e.target.value)
                                        }
                                        style={{ width: 200 }}
                                    />
                                </Col>
                            </Row> */}
                            <Row
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    display: "flex",
                                    flex: "1 1",
                                    flexAlign: "start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Col style={{ width: 25, lineHeight: 1.2 }}>
                                    {/* <Checkbox
                                        // onChange={e => this.handleBulkAll(e)}
                                        onChange={(e) =>
                                            this.listBulk.handleAll(
                                                e.target.checked
                                            )
                                        }
                                    /> */}
                                </Col>
                                <Col style={{ flex: "1 1", marginRight: -30 }}>
                                    <SocialListColumn
                                        sortkey="name"
                                        sortby={this.state.sortby}
                                        sortasc={this.state.sortasc}
                                        handleSorting={this.setSorting}
                                    >
                                        Listener name
                                    </SocialListColumn>
                                </Col>
                                <Col style={{ flexGrow: 1 }}>Platforms</Col>
                                <Col style={{ width: 90 }}>Tools</Col>
                            </Row>
                        </Fragment>
                    }
                    footer={
                        pagesCount > 1 && this.state.filter.length === 0 ? (
                            <div style={{ textAlign: "right" }}>
                                <Pagination
                                    size="small"
                                    defaultCurrent={1}
                                    total={auditorsLength}
                                    pageSize={config.PAGINATION_PERPAGE}
                                    current={this.state.pagination_currentPage}
                                    onChange={this.paginationSetPage}
                                />
                            </div>
                        ) : null
                    }
                    bordered
                    dataSource={this.getListingDataSource()}
                    renderItem={(item, itemIndex) => (
                        <List.Item
                            key={itemIndex}
                            className={
                                this.listBulk &&
                                this.listBulk.isChecked(item.key)
                                    ? this.listBulk.getCheckedLength() === 1
                                        ? "social_Selected_withTools"
                                        : "social_Selected"
                                    : null
                            }
                            actions={[
                                <Tooltip
                                    key="monitor"
                                    title={tooltips.AUDITORS.LIST_MONITOR}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showModalForMonitor(
                                                _evt,
                                                item
                                            );
                                        }}
                                    >
                                        <Icon type="stock" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="edit"
                                    title={tooltips.AUDITORS.LIST_EDIT}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={(_evt) => {
                                            this.showModalForEdit(_evt, item);
                                        }}
                                    >
                                        <Icon type="edit" />
                                    </Button>
                                </Tooltip>,
                                <Tooltip
                                    key="delete"
                                    title={tooltips.AUDITORS.LIST_DELETE}
                                    mouseEnterDelay={
                                        tooltips.SETTINGS.mouseEnterDelay
                                    }
                                >
                                    {/* <Text type="danger">delete</Text> */}
                                    <Icon
                                        type="delete"
                                        onClick={(evt) => {
                                            this.handleDeleteAuditor(evt, item);
                                        }}
                                        style={{
                                            color: scss_variables.scss_color_pallete_error,
                                        }}
                                    />
                                </Tooltip>,
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <div key={item.name}>
                                        <Checkbox
                                            onChange={(e) =>
                                                this.listBulk.handleCheck(
                                                    item.key
                                                )
                                            }
                                            checked={
                                                this.listBulk
                                                    ? this.listBulk.isChecked(
                                                          item.key
                                                      )
                                                    : false
                                            }
                                            style={{ marginRight: 10 }}
                                        />
                                        {item.name}
                                    </div>
                                }
                            />
                            <div style={{ flexGrow: 1, textAling: "right" }}>
                                <div
                                    style={{ flexGrow: 1, textAling: "right" }}
                                >
                                    {item.facebook_page ? (
                                        <div
                                            className={`social_iconCircle_facebook`}
                                            style={{
                                                marginRight: 5,
                                                width: 13,
                                                height: 13,
                                                display: "inline-block",
                                                marginBottom: -2,
                                            }}
                                        />
                                    ) : null}
                                    {item.instagram_account ? (
                                        <div
                                            className={`social_iconCircle_instagram`}
                                            style={{
                                                marginRight: 5,
                                                width: 13,
                                                height: 13,
                                                display: "inline-block",
                                                marginBottom: -2,
                                            }}
                                        />
                                    ) : null}
                                    {item.twitter_account ? (
                                        <div
                                            className={`social_iconCircle_twitter`}
                                            style={{
                                                marginRight: 5,
                                                width: 13,
                                                height: 13,
                                                display: "inline-block",
                                                marginBottom: -2,
                                            }}
                                        />
                                    ) : null}
                                    {item.linkedin_account ? (
                                        <div
                                            className={`social_iconCircle_linkedin`}
                                            style={{
                                                marginRight: 5,
                                                width: 13,
                                                height: 13,
                                                display: "inline-block",
                                                marginBottom: -2,
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
            );

        return (
            <Fragment>
                {header}
                {body}
                <AuditorsListModal
                    data={this.state.edit_data}
                    store={this.props.store}
                />
                <AuditorsListModalMonitor
                    data={this.state.edit_data}
                    store={this.props.store}
                />
            </Fragment>
        );
    }
}

export default observer(AuditorsList);
