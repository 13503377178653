import React, { Component, Fragment } from "react";
import { firebase_messaging } from "./../../base";
import config from "./../../config";
import moment from "moment";
import { Timeline } from "antd";
import {
    StripeProvider,
    Elements,
    CardElement,
    injectStripe,
} from "react-stripe-elements";
import {
    Form,
    Switch,
    Row,
    Col,
    Divider,
    Dropdown,
    Menu,
    Button,
    Icon,
    Progress,
    Radio,
    Typography,
} from "antd";
import { social_notification } from "./../../functions";

const skins = [
    { key: "default", name: "Default" },
    { key: "square", name: "Square" },
];
const { Title } = Typography;

const UpdateForm = injectStripe(
    // eslint-disable-next-line
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        render() {
            const {
                stripe,
                worldPlan,
                newPlan,
                onPlanChange,
                onUpgradeConfirm,
            } = this.props;

            return (
                <div
                    style={{
                        background:
                            "linear-gradient(0deg, rgba(244,244,244,1) 0%, rgba(244,244,244,0) 100%)",
                        padding: 20,
                        paddingBottom: 30,
                    }}
                >
                    <br />
                    <br />
                    <Radio.Group
                        onChange={onPlanChange}
                        defaultValue={worldPlan.key}
                    >
                        {config.worldPlans.map((wPlan, wPlanIndex) => (
                            <Radio.Button
                                key={wPlanIndex}
                                value={wPlan.key}
                                disabled={wPlan.key === worldPlan.key}
                            >
                                {wPlan.name}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                    <br />
                    <br />
                    Max Accounts: {newPlan && newPlan.maxAccounts}
                    <br />
                    <strong>Price: ${newPlan && newPlan.amount}</strong>
                    <br />
                    <br />
                    <CardElement />
                    <br />
                    <br />
                    <Button
                        style={{ float: "right", marginBottom: 20 }}
                        onClick={(e) => onUpgradeConfirm(this.props.stripe)}
                        type="primary"
                        disabled={worldPlan.key === newPlan.key}
                    >
                        Confirm Upgrade
                    </Button>
                    <br />
                </div>
            );
        }
    }
);

class System extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            updateOpen: false,
            upgradePlan: null,
        };
    }

    async componentDidMount() {
        this.props.store.setCurrentSection("system");

        // Breadcrumbs
        this.props.store.setBreadcrumbs([{ title: "System" }]);

        await this.loadInformation();
    }

    async loadInformation() {
        // const isPushMessagingSubscribed =
        //     this.props.store.isPushMessagingSubscribed;
        // console.log("isPushMessagingSubscribed = " + isPushMessagingSubscribed);
        // this.setState({
        //     push_notification_subscribed: isPushMessagingSubscribed,
        // });

        const loggedUser = this.props.store.getLoggedUser;
        console.info("Settings loggedUser", loggedUser);
        const world = await this.props.store.worldsGet(loggedUser.world.key);
        console.info("Settings world", world);

        const copyOfWorld = JSON.parse(JSON.stringify(world));
        const lastPlan =
            copyOfWorld.payment_history &&
            copyOfWorld.payment_history.pop().plan;

        this.setState({ world, upgradePlan: lastPlan });
    }

    onUpdatePlanChange = async (_e) => {
        console.info("onUpdatePlanChange _e", _e);
        const newPlan = config.worldPlans.filter(
            (wp) => wp.key === _e.target.value
        )[0];
        console.info("onUpdatePlanChange newPlan", newPlan);
        this.setState({ upgradePlan: newPlan });
    };

    onUpgradeConfirm = async (_stripe) => {
        const { upgradePlan, world } = this.state;
        console.info(
            "onUpgradeConfirm _stripe",
            _stripe,
            "upgradePlan",
            upgradePlan
        );

        try {
            // stripe token
            let token = await _stripe.createToken({
                name: world.email,
            });
            console.info("token", token);
            if (token.error) {
                social_notification("validation_error", "error", {
                    title: "Verification error",
                    description: token.error.message,
                });
                return false;
            }

            // Pay subscription to the world
            let payWorldSubscriptionResult =
                await this.props.store.payWorldSubscription({
                    token: token.token,
                    amount: upgradePlan.amount,
                    payment_data: {
                        world_key: world.key,
                        world_email: world.email,
                        plan: upgradePlan,
                    },
                });
            console.info(
                "payWorldSubscriptionResult",
                payWorldSubscriptionResult
            );

            social_notification({
                type: "success",
                title: "Upgrading",
                description: "The upgrade went OK",
            });
        } catch (error) {
            console.warn(error);
        }
    };

    render() {
        const { world, upgradePlan } = this.state;
        const world_plan =
            world &&
            config.worldPlans.filter((wp) => wp.key === world.plan_key)[0];
        const accountsCount = this.props.store.getAllAccountsLength;
        // console.info("accountsCount", accountsCount);
        const planUsageInPercents =
            world && (accountsCount / world_plan.maxAccounts) * 100;
        // console.info("planUsageInPercents", planUsageInPercents);

        return (
            <StripeProvider apiKey={config.stripe.apiKey}>
                <Elements>
                    <Fragment>
                        <div className={"System"}>
                            <Title level={3} style={{ margin: "16px 0" }}>
                                System Information
                            </Title>
                            <Divider />
                            <Row>
                                <Col span={12}>
                                    <h4 style={{ margin: 0 }}>World name</h4>
                                    <p>{world && world.name}</p>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <h4 style={{ margin: 0 }}>
                                        Administrator email
                                    </h4>
                                    <p>{world && world.email}</p>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={6}>
                                    <h4 style={{ margin: 0 }}>Plan Name</h4>
                                    <p>{world && world_plan.name}</p>
                                </Col>
                                <Col span={8}>
                                    <h4 style={{ margin: 0 }}>
                                        Plan Max Accounts
                                    </h4>
                                    <p>{world && world_plan.maxAccounts}</p>
                                </Col>
                                <Col span={6}>
                                    <h4 style={{ margin: 0 }}>Plan Amount</h4>
                                    <p>${world && world_plan.amount}</p>
                                </Col>
                                <Col span={4} style={{ textAlign: "right" }}>
                                    <h4 style={{ margin: 0 }}>
                                        Plan Usage ({accountsCount} of{" "}
                                        {world && world_plan.maxAccounts})
                                    </h4>
                                    <p>
                                        <Progress
                                            percent={planUsageInPercents}
                                            showInfo={false}
                                        />
                                    </p>
                                </Col>
                            </Row>

                            <Button
                                style={{ float: "right", marginTop: -5 }}
                                onClick={(e) =>
                                    this.setState({
                                        updateOpen: !this.state.updateOpen,
                                    })
                                }
                            >
                                Upgrade{" "}
                                <Icon
                                    type={this.state.updateOpen ? "up" : "down"}
                                />
                            </Button>
                            {this.state.updateOpen &&
                            this.state.updateOpen === true ? (
                                <UpdateForm
                                    worldPlan={world_plan && world_plan}
                                    newPlan={upgradePlan}
                                    onPlanChange={this.onUpdatePlanChange}
                                    onUpgradeConfirm={this.onUpgradeConfirm}
                                />
                            ) : null}
                            <br />
                            <Divider />

                            <Row>
                                <Col span={12}>
                                    <h4 style={{ margin: 0 }}>Paid until</h4>
                                    <p>
                                        {world &&
                                            moment(world.paid_until).format(
                                                "llll"
                                            )}
                                    </p>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <h4 style={{ margin: 0 }}>Expiration</h4>
                                    <p>
                                        {world &&
                                            moment(world.paid_until).fromNow()}
                                    </p>
                                </Col>
                            </Row>
                        </div>

                        <Divider>
                            &nbsp;&nbsp;Payment history&nbsp;&nbsp;
                        </Divider>
                        {/* <pre>
                            {world &&
                                JSON.stringify(world.payment_history, null, 2)}
                        </pre> */}

                        {world && world.payment_history ? (
                            <Timeline style={{ marginTop: 15 }}>
                                {world.payment_history
                                    .reverse()
                                    .map(
                                        (paymentRecord, paymentRecordIndex) => {
                                            return (
                                                <Timeline.Item
                                                    key={paymentRecordIndex}
                                                >
                                                    <strong>
                                                        {moment(
                                                            paymentRecord.datetime
                                                        )
                                                            .utc()
                                                            .format("lll")}
                                                    </strong>
                                                    <br />
                                                    Amount:{" "}
                                                    <strong>
                                                        {paymentRecord
                                                            .stripe_data.plan &&
                                                            Number(
                                                                paymentRecord
                                                                    .stripe_data
                                                                    .plan
                                                                    .amount /
                                                                    100
                                                            )}{" "}
                                                        {paymentRecord
                                                            .stripe_data.plan &&
                                                            paymentRecord.stripe_data.plan.currency.toUpperCase()}
                                                    </strong>
                                                    <br />
                                                    Interval:{" "}
                                                    <strong>
                                                        {paymentRecord
                                                            .stripe_data.plan &&
                                                            paymentRecord
                                                                .stripe_data
                                                                .plan
                                                                .interval_count}{" "}
                                                        {paymentRecord
                                                            .stripe_data.plan &&
                                                            paymentRecord
                                                                .stripe_data
                                                                .plan.interval}
                                                    </strong>
                                                    <br />
                                                    Plan:{" "}
                                                    <strong>
                                                        {paymentRecord.plan &&
                                                            paymentRecord.plan
                                                                .name}
                                                    </strong>
                                                    {" / Max Accounts: "}
                                                    <strong>
                                                        {paymentRecord.plan &&
                                                            paymentRecord.plan
                                                                .maxAccounts}
                                                    </strong>
                                                </Timeline.Item>
                                            );
                                        }
                                    )}
                                <Timeline.Item key={"start"}>
                                    Start
                                </Timeline.Item>
                            </Timeline>
                        ) : null}
                    </Fragment>
                </Elements>
            </StripeProvider>
        );
    }
}

const WrappedHorizontalForm = Form.create({ name: "horizontal_login" })(System);

export default WrappedHorizontalForm;
