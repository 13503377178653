import React, { Fragment } from "react";
import ReactJson from "react-json-view";
import { Typography, Divider } from "antd";

const { Title, Text } = Typography;

const AccountsChannelInfoTwitter = ({ channel_info }) => {
    return (
        <Fragment>
            {/* <ReactJson src={channel_info} /> */}
            {/* <Divider /> */}
            <Text>Name</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.name}
            </Title>

            <Text>ID</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.id}
            </Title>

            <Text>Screen name</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.screen_name}
            </Title>

            <Text>Followers count</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.followers_count}
            </Title>

            <Text>Verified</Text>
            <Title level={4} style={{ margin: "0 0 20px 0" }}>
                {channel_info.verified ? "Yes" : "No"}
            </Title>
        </Fragment>
    );
};

export default AccountsChannelInfoTwitter;
