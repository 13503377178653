import React, { Fragment } from "react";
import {
    Form,
    Input,
    Row,
    Col,
    Checkbox,
    Select,
    Divider,
    Tabs,
    Menu,
    Icon,
    Dropdown,
    Button,
    Tree,
    Radio,
    Slider,
    Spin,
    InputNumber,
    DatePicker,
} from "antd";
import moment from "moment";
import config from "../../config";
import FilePicker from "../FilePicker";

const { Option } = Select;
const { TabPane } = Tabs;
const { TreeNode } = Tree;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const AdsListModalFormPanelFacebookInstagram = (props, context) => {
    const { form, state, store } = props;
    const { getFieldDecorator } = form;
    const { facebook_targeting, fetching, facebook_details } = state;
    // allAccounts
    const allAccounts = store.getAllAccounts.map((a) => {
        let accountData = {
            title: `${a.name}`,
            key: a.key,
        };
        if (a.facebook && a.facebook.data && a.facebook.data.length > 0) {
            accountData.children = a.facebook.data.map((d) => {
                return {
                    title: d.name,
                    key: a.key + "|" + d.id,
                };
            });
        }
        return accountData;
    });
    console.info("FACEBOOK PANEL allAccounts", allAccounts);

    const singleImageAdFields = (
        <Fragment>
            <div>
                <Form.Item label="Call To Action">
                    {getFieldDecorator("newad_calltoactiontype", {
                        initialValue: state.newad_calltoactiontype
                            ? state.newad_calltoactiontype
                            : null,
                        onChange: (e) =>
                            props.onSetState({
                                newad_calltoactiontype: e,
                            }),
                    })(
                        <Select>
                            {config.FACEBOOK_ADS_CALLTOACTION_TYPES.map(
                                (option, optionIndex) => {
                                    return (
                                        <Option
                                            key={optionIndex}
                                            value={option}
                                        >
                                            {option}
                                        </Option>
                                    );
                                }
                            )}
                        </Select>
                    )}
                </Form.Item>

                {/* <Form.Item label="FilePicker">
                    <FilePicker
                        store={this.props.store}
                        restrictions={{ width: 1080, height: 1080 }}
                        // nopreview
                        onChange={(file) =>
                            this.handleFilePickerChange(
                                "singlead",
                                file
                            )
                        }
                    />
                </Form.Item> */}
            </div>
        </Fragment>
    );

    const carouselAdFields = (_index) => {
        return (
            <Fragment>
                <div>
                    <Form.Item label="Title">
                        {getFieldDecorator("carousel_" + _index + "_title", {
                            initialValue: state.carousel[_index].title
                                ? state.carousel[_index].title
                                : null,
                            onChange: (e) => {
                                let { carousel } = state;
                                carousel[_index].title = e.target.value;
                                props.onSetState({
                                    carousel,
                                });
                            },
                        })(<Input maxLength={25} />)}
                    </Form.Item>
                    <Form.Item label="Body">
                        {getFieldDecorator("carousel_" + _index + "_body", {
                            initialValue: state.carousel[_index].body
                                ? state.carousel[_index].body
                                : null,
                            onChange: (e) => {
                                let { carousel } = state;
                                carousel[_index].body = e.target.value;
                                props.onSetState({
                                    carousel,
                                });
                            },
                        })(<TextArea rows={2} />)}
                    </Form.Item>
                    <Form.Item label="URL">
                        {getFieldDecorator("carousel_" + _index + "_url", {
                            initialValue: state.carousel[_index].url
                                ? state.carousel[_index].url
                                : null,
                            onChange: (e) => {
                                let { carousel } = state;
                                carousel[_index].url = e.target.value;
                                props.onSetState({
                                    carousel,
                                });
                            },
                        })(<Input maxLength={1024} />)}
                    </Form.Item>
                    <Form.Item label="FilePicker">
                        <FilePicker
                            store={store}
                            restrictions={{ width: 1080, height: 1080 }}
                            // nopreview
                            onChange={(file) =>
                                this.handleFilePickerChange(
                                    "carousel",
                                    file,
                                    _index
                                )
                            }
                        />
                    </Form.Item>
                </div>
            </Fragment>
        );
    };

    // functions
    const renderAccountsTreeNodes = (data) => {
        return data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {renderAccountsTreeNodes(item.children)}
                    </TreeNode>
                );
            } else {
                return (
                    <TreeNode
                        title={
                            <Fragment>
                                <Icon
                                    type="facebook"
                                    theme="filled"
                                    style={{
                                        marginRight: 5,
                                        color: store.getTagColorForType(
                                            "facebook"
                                        ),
                                    }}
                                />
                                {item.title}
                            </Fragment>
                        }
                        key={item.key}
                        dataRef={item}
                    ></TreeNode>
                );
            }
            //return <TreeNode key={item.key} {...item} />;
        });
    };

    // Targeting
    const facebookTargeting = (
        <Fragment>
            <div>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.facebook_targeting
                                    .selected_overwrite_gender
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_gender",
                                    e.target.checked
                                )
                            }
                        >
                            Gender
                        </Checkbox>
                    }
                >
                    <Radio.Group
                        value={state.facebook_targeting.selected_gender}
                        disabled={
                            !state.facebook_targeting.selected_overwrite_gender
                        }
                        onChange={(e) =>
                            props.onSelectFacebookTargetParam(
                                "gender",
                                e.target.value
                            )
                        }
                    >
                        <Radio.Button value="all">All</Radio.Button>
                        <Radio.Button value="1">Male</Radio.Button>
                        <Radio.Button value="2">Female</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.facebook_targeting.selected_overwrite_age
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_age",
                                    e.target.checked
                                )
                            }
                        >
                            Age
                        </Checkbox>
                    }
                >
                    <Slider
                        range
                        value={[
                            state.facebook_targeting.selected_age[0],
                            state.facebook_targeting.selected_age[1],
                        ]}
                        disabled={
                            !state.facebook_targeting.selected_overwrite_age
                        }
                        min={13}
                        max={100}
                        // tooltipVisible={true}
                        onChange={(e) =>
                            props.onSelectFacebookTargetParam("age", e)
                        }
                    />
                </Form.Item>
                <Form.Item
                    required
                    label={
                        <Checkbox
                            checked={
                                state.facebook_targeting
                                    .selected_overwrite_country
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_country",
                                    e.target.checked
                                )
                            }
                        >
                            Country
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select countries"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={props.onfetchCountry}
                        onChange={(e) => {
                            props.onSelectFacebookTargetParam("country", e);
                        }}
                        disabled={
                            !state.facebook_targeting.selected_overwrite_country
                        }
                        style={{ width: "100%" }}
                        value={state.facebook_targeting.selected_country}
                    >
                        {facebook_targeting.countries.map((c) => (
                            <Option key={c.key}>{c.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.facebook_targeting
                                    .selected_overwrite_region
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_region",
                                    e.target.checked
                                )
                            }
                        >
                            Region
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select regions"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={props.onfetchRegion}
                        onChange={(e) => {
                            props.onSelectFacebookTargetParam("region", e);
                        }}
                        disabled={
                            !state.facebook_targeting.selected_overwrite_region
                        }
                        style={{ width: "100%" }}
                        value={state.facebook_targeting.selected_region}
                    >
                        {facebook_targeting.regions.map((r) => (
                            <Option key={r.key}>{r.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.facebook_targeting.selected_overwrite_city
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_city",
                                    e.target.checked
                                )
                            }
                        >
                            City
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select cities"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={props.onfetchCity}
                        onChange={(e) => {
                            props.onSelectFacebookTargetParam("city", e);
                        }}
                        style={{ width: "100%" }}
                        value={state.facebook_targeting.selected_city}
                        disabled={
                            !state.facebook_targeting.selected_overwrite_city
                        }
                    >
                        {facebook_targeting.cities.map((c) => (
                            <Option key={c.key}>{c.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.facebook_targeting.selected_overwrite_zip
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_zip",
                                    e.target.checked
                                )
                            }
                        >
                            ZIP
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select zip codes"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={props.onfetchZip}
                        onChange={(e) => {
                            props.onSelectFacebookTargetParam("zip", e);
                        }}
                        style={{ width: "100%" }}
                        value={state.facebook_targeting.selected_zip}
                        disabled={
                            !state.facebook_targeting.selected_overwrite_zip
                        }
                    >
                        {facebook_targeting.zips.map((z) => (
                            <Option key={z.key}>
                                {z.country_name} / {z.region} / {z.primary_city}{" "}
                                - {z.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.facebook_targeting
                                    .selected_overwrite_language
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_language",
                                    e.target.checked
                                )
                            }
                        >
                            Language
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select languages"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={props.onfetchLanguage}
                        onChange={(e) => {
                            props.onSelectFacebookTargetParam("language", e);
                        }}
                        style={{ width: "100%" }}
                        value={state.facebook_targeting.selected_language}
                        disabled={
                            !state.facebook_targeting
                                .selected_overwrite_language
                        }
                    >
                        {facebook_targeting.languages.map((l) => (
                            <Option key={l.key}>{l.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                state.facebook_targeting
                                    .selected_overwrite_interest
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_interest",
                                    e.target.checked
                                )
                            }
                        >
                            Interest
                        </Checkbox>
                    }
                >
                    <Select
                        mode="multiple"
                        labelInValue
                        //     value={value}
                        placeholder="Select interests"
                        notFoundContent={
                            fetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={props.onfetchInterest}
                        onChange={(e) => {
                            props.onSelectFacebookTargetParam("interest", e);
                        }}
                        style={{ width: "100%" }}
                        value={state.facebook_targeting.selected_interest}
                        disabled={
                            !state.facebook_targeting
                                .selected_overwrite_interest
                        }
                    >
                        {facebook_targeting.interests.map((i) => (
                            <Option key={i.id}>{i.path.join("/")}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        </Fragment>
    );

    // Details
    const detailsPanel = (
        <Fragment>
            <div>
                <Form.Item label={"Objective"}>
                    <Select
                        // labelInValue={true}
                        //     value={value}
                        placeholder="Select objective"
                        filterOption={false}
                        onChange={(e) => {
                            facebook_details.objective = e;
                            props.onSetState({ facebook_details });
                            console.info("e", e);
                        }}
                        value={facebook_details.objective}
                    >
                        {config.FACEBOOK_ADS_OBJECTIVE_OPTIONS.map((option) => (
                            <Option key={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label={"Optimization goal"}>
                    <Select
                        // labelInValue={true}
                        //     value={value}
                        placeholder="Select optimization goal"
                        filterOption={false}
                        onChange={(e) => {
                            facebook_details.optimization_goal = e;
                            props.onSetState({ facebook_details });
                            console.info("e", e);
                        }}
                        value={facebook_details.optimization_goal}
                    >
                        {config.FACEBOOK_ADS_OPTIMIZATION_GOAL_OPTIONS.map(
                            (option) => (
                                <Option key={option}>{option}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label={"Billing Event"}>
                    <Select
                        // labelInValue={true}
                        //     value={value}
                        placeholder="Select billing event"
                        filterOption={false}
                        onChange={(e) => {
                            facebook_details.billing_event = e;
                            props.onSetState({ facebook_details });
                            console.info("e", e);
                        }}
                        value={facebook_details.billing_event}
                    >
                        {config.FACEBOOK_ADS_BILLING_EVENTS_OPTIONS.map(
                            (option) => (
                                <Option key={option}>{option}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label={"Bid Amount"}>
                    <InputNumber
                        defaultValue={facebook_details.bid_amount}
                        min={0}
                        // max={100}
                        formatter={(value) => `$ ${value / 100}`}
                        parser={(value) => Number(value.replace("$", "")) * 100}
                        onChange={(e) => {
                            facebook_details.bid_amount = e;
                            props.onSetState({ facebook_details });
                            console.info("e", e);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <Checkbox
                            checked={
                                facebook_targeting.selected_overwrite_daily_budget
                            }
                            onChange={(e) =>
                                props.onSelectFacebookTargetParam(
                                    "overwrite_daily_budget",
                                    e.target.checked
                                )
                            }
                        >
                            Daily budget
                        </Checkbox>
                    }
                >
                    <InputNumber
                        defaultValue={facebook_details.daily_budget}
                        min={0}
                        // max={100}
                        step={100}
                        formatter={(value) => `$ ${value / 100}`}
                        parser={(value) => Number(value.replace("$", "")) * 100}
                        disabled={
                            !facebook_targeting.selected_overwrite_daily_budget
                        }
                        onChange={(e) => {
                            facebook_details.daily_budget = e;
                            props.onSetState({ facebook_details });
                            console.info("e", e);
                        }}
                    />
                </Form.Item>
                <Form.Item label={"Start/End date"}>
                    <RangePicker
                        format="MM/DD YYYY HH:mm"
                        defaultValue={[
                            moment(facebook_details.start_time),
                            moment(facebook_details.end_time),
                        ]}
                        onChange={(e) => {
                            console.info("e", e);

                            facebook_details.start_time = e[0].format();
                            facebook_details.end_time = e[1].format();
                            props.onSetState({ facebook_details });
                        }}
                        disabledDate={(current) =>
                            current && current < moment().endOf("day")
                        }
                        showTime={{
                            hideDisabledOptions: true,
                        }}
                    />
                </Form.Item>
                <Form.Item label={"Status"}>
                    <Select
                        placeholder="Select status"
                        filterOption={false}
                        onChange={(e) => {
                            facebook_details.status = e;
                            props.onSetState({ facebook_details });
                            console.info("e", e);
                        }}
                        value={facebook_details.status}
                    >
                        {config.FACEBOOK_ADS_STATUS_OPTIONS.map((option) => (
                            <Option key={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            <div>
                <div style={{ minHeight: 352 }}>
                    <Row>
                        <Col>
                            <Form.Item label="Networks">
                                <Row>
                                    <Col span={12}>
                                        {getFieldDecorator(
                                            "newad_networks_facebook",
                                            {
                                                initialValue:
                                                    state.newad_networks_facebook
                                                        ? state.newad_networks_facebook
                                                        : null,
                                                onChange: (e) =>
                                                    props.onSetState({
                                                        newad_networks_facebook:
                                                            e.target.checked,
                                                    }),
                                            }
                                        )(<Checkbox>Facebook</Checkbox>)}
                                    </Col>
                                    <Col span={12}>
                                        {getFieldDecorator(
                                            "newad_networks_instagram",
                                            {
                                                initialValue:
                                                    state.newad_networks_instagram
                                                        ? state.newad_networks_instagram
                                                        : null,
                                                onChange: (e) =>
                                                    props.onSetState({
                                                        newad_networks_instagram:
                                                            e.target.checked,
                                                    }),
                                            }
                                        )(<Checkbox>Instagram</Checkbox>)}
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item label="Type">
                                {getFieldDecorator("newad_type", {
                                    initialValue: state.newad_type
                                        ? state.newad_type
                                        : null,
                                    onChange: (e) =>
                                        props.onSetState({
                                            newad_type: e,
                                        }),
                                })(
                                    <Select>
                                        <Option value="singleimagead">
                                            Single Image
                                        </Option>
                                        <Option value="carouselad">
                                            Carousel
                                        </Option>
                                        <Option disabled value="collectionad">
                                            Collection (upcoming)
                                        </Option>
                                    </Select>
                                )}
                            </Form.Item>
                            {state.newad_type === "singleimagead" ? (
                                <Fragment>
                                    <Divider orientation="left">
                                        Ad Settings
                                    </Divider>
                                    {singleImageAdFields}
                                </Fragment>
                            ) : null}
                            {state.newad_type === "carouselad" ? (
                                <div
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <Tabs
                                        // defaultActiveKey="1"
                                        // onChange={this.callback}
                                        style={
                                            {
                                                // width:
                                                //     "calc(100% - 120px)"
                                            }
                                        }
                                    >
                                        {state.carousel.map(
                                            (carousel, carouselIndex) => {
                                                return (
                                                    <TabPane
                                                        tab={
                                                            <div
                                                                key={
                                                                    carouselIndex
                                                                }
                                                                style={{
                                                                    paddingRight: 5,
                                                                }}
                                                            >
                                                                {"Slide " +
                                                                    Number(
                                                                        carouselIndex +
                                                                            1
                                                                    )}
                                                                <Dropdown
                                                                    overlay={
                                                                        <Menu
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                console.info(
                                                                                    "e",
                                                                                    e
                                                                                );
                                                                                switch (
                                                                                    e.key
                                                                                ) {
                                                                                    case "duplicate":
                                                                                        this.duplicateCarouselSlide(
                                                                                            e,
                                                                                            carouselIndex
                                                                                        );
                                                                                        break;
                                                                                    case "delete":
                                                                                        this.deleteCarouselSlide(
                                                                                            e,
                                                                                            carouselIndex
                                                                                        );
                                                                                        break;
                                                                                    default:
                                                                                        break;
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Menu.Item key="duplicate">
                                                                                <Icon type="copy" />
                                                                                Duplicate
                                                                            </Menu.Item>
                                                                            <Menu.Item key="delete">
                                                                                <Icon type="delete" />
                                                                                Delete
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    }
                                                                >
                                                                    <Icon
                                                                        type="more"
                                                                        style={{
                                                                            position:
                                                                                "absolute",
                                                                            padding: 3,
                                                                            right: -6,
                                                                            bottom: 12,
                                                                        }}
                                                                    />
                                                                </Dropdown>
                                                            </div>
                                                        }
                                                        key={carouselIndex}
                                                    >
                                                        {carouselAdFields(
                                                            carouselIndex
                                                        )}
                                                    </TabPane>
                                                );
                                            }
                                        )}
                                    </Tabs>
                                    <Button
                                        size="small"
                                        icon="plus"
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            top: 10,
                                        }}
                                        // onClick={this.addCarouselSlide}
                                    >
                                        {/* <Icon type="plus" /> New
                                        Slide */}
                                    </Button>
                                </div>
                            ) : null}

                            <Form.Item label="Accounts" required>
                                <Tree
                                    checkable
                                    selectable={false}
                                    defaultExpandAll={true}
                                    // onExpand={this.onExpand}
                                    // expandedKeys={state.expandedKeys}
                                    // autoExpandParent={state.autoExpandParent}
                                    onCheck={props.onNewAdCheck}
                                    checkedKeys={state.newad_accounts}
                                    // onSelect={this.onSelect}
                                    // selectedKeys={state.selectedKeys}
                                    style={{
                                        marginTop: 2,
                                        marginLeft: -26,
                                    }}
                                >
                                    {renderAccountsTreeNodes(allAccounts)}
                                </Tree>
                            </Form.Item>
                            {/*                                             
                                <Divider orientation="left">
                                    Preview
                                </Divider>
                                <AdsPreview
                                    type={state.newad_type}
                                    carousel={state.carousel}
                                    defaultTab="facebook"
                                    image_url={
                                        state.newad_type ===
                                        "singleimagead"
                                            ? this.state
                                                  .newad_image &&
                                              state.newad_image
                                                  .image_data
                                            : null
                                    }
                                    cta_type={
                                        this.state
                                            .newad_calltoactiontype
                                    }
                                    link_url={state.newad_url}
                                    title={state.newad_title}
                                    description={
                                        state.newad_body
                                    }
                                /> */}
                        </Col>
                    </Row>
                    <Divider
                        orientation="left"
                        style={{
                            marginLeft: 20,
                        }}
                    >
                        Targeting
                    </Divider>
                    {facebookTargeting}
                    <Divider
                        orientation="left"
                        style={{
                            marginLeft: 20,
                        }}
                    >
                        Details
                    </Divider>
                    {detailsPanel}
                </div>
            </div>
        </Fragment>
    );
};

export default AdsListModalFormPanelFacebookInstagram;
