import React, { Component, Fragment } from "react";
import {
    Button,
    Form,
    Modal,
    Row,
    Col,
    Tree,
    Alert,
    Input,
    Divider,
    Icon,
} from "antd";
import { observer } from "mobx-react";
import names from "./../../names";

const { Search } = Input;
const { TreeNode } = Tree;

const ModalForm = Form.create({ name: "modal_form" })(
    class inlineForm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                checkedKeys: [],
                filter: "",
            };
        }

        componentDidMount() {
            // To disable submit button at the beginning.
            this.props.form.validateFields();

            const { campaign } = this.props.data;
            const availableMasterposts = this.props.store.getAllMasterposts;
            const availableMasterads = this.props.store.getAllMasterads;

            //if (!availableMasterposts || !availableMasterads) {
            // console.info(
            //     "componentDidMount availableMasterposts",
            //     availableMasterposts
            // );
            // console.info(
            //     "componentDidMount availableMasterads",
            //     availableMasterposts
            // );
            // console.info("componentDidMount this.props.data", this.props.data);
            //return;
            //}

            let filteredMasterpostsKeys = availableMasterposts
                ? availableMasterposts
                      .filter(
                          (m) =>
                              m.campaigns &&
                              m.campaigns.indexOf(campaign) !== -1
                      )
                      .map((m) => m.key)
                : [];
            let filteredMasteradsKeys = availableMasterads
                ? availableMasterads
                      .filter(
                          (a) =>
                              a.campaigns &&
                              a.campaigns.indexOf(campaign) !== -1
                      )
                      .map((a) => a.key)
                : [];
            // console.info(
            //     "componentDidMount filteredMasterpostsKeys",
            //     filteredMasterpostsKeys
            // );
            // console.info(
            //     "componentDidMount filteredMasteradsKeys",
            //     filteredMasteradsKeys
            // );
            this.setState({
                campaign,
                masterposts: filteredMasterpostsKeys,
                masterads: filteredMasteradsKeys,
                original_campaign: JSON.parse(JSON.stringify(campaign)),
                original_masterposts: JSON.parse(
                    JSON.stringify(filteredMasterpostsKeys)
                ),
                original_masterads: JSON.parse(
                    JSON.stringify(filteredMasteradsKeys)
                ),
            });
        }

        handleSubmit = (e) => {
            e.preventDefault();
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    console.log("Received values of form: ", values);
                }
            });
        };

        handleCampaignChange = (_campaign) => {
            console.info("handleCategoriesChange", _campaign);
            //this.props.data = _categories;
            this.props.form.setFieldsValue({ campaign: _campaign });
            this.setState({ campaign: _campaign });
        };

        onmasterpostsSearchChange = (_filter) => {
            console.info("onmasterpostsSearchChange _filter", _filter);
            this.setState({ filter: _filter });
        };
        onmasteradsSearchChange = (_filter) => {
            console.info("onadsSearchChange _filter", _filter);
            this.setState({ masterads_filter: _filter });
        };

        handleAddAllMasterposts = () => {
            console.log(`handleAddAllmasterposts`);
            let onlyKeys = [];
            this.props.store.getAllMasterposts.map((a) => {
                onlyKeys.push(a.key);
                return false;
            });
            console.info(`handleAddAllMasterposts onlyKeys`, onlyKeys);

            //this.props.data.accounts = onlyKeys;

            this.props.form.setFieldsValue({
                masterposts: onlyKeys,
            });

            this.setState({ masterposts: onlyKeys });
        };
        handleAddAllMasterads = () => {
            console.log(`handleAddAllMasterads`);
            let onlyKeys = [];
            this.props.store.getAllMasterads.map((a) => {
                onlyKeys.push(a.key);
                return false;
            });
            console.info(`handleAddAllMasterads onlyKeys`, onlyKeys);

            //this.props.data.accounts = onlyKeys;

            this.props.form.setFieldsValue({
                masterads: onlyKeys,
            });

            this.setState({ masterads: onlyKeys });
        };

        handlemasterpostsChange = (value) => {
            console.log(`handlemasterpostsChange ${value}`);

            this.props.form.setFieldsValue({ masterposts: value });
        };
        handlemasteradsChange = (value) => {
            console.log(`handlemasteradsChange ${value}`);

            this.props.form.setFieldsValue({ masterads: value });
        };

        renderMasterpostsTreeNodes = (data) => {
            console.log(
                "renderMasterpostsTreeNodes filter=" + this.state.filter
            );

            const style = {
                padding: 0,
            };

            let nodes = data.map((item) => {
                if (this.state.filter) {
                    console.log("we have filter");
                    if (
                        item.name
                            .toUpperCase()
                            .indexOf(this.state.filter.toUpperCase()) !== -1
                    ) {
                        return (
                            <TreeNode
                                key={item.key}
                                title={item.name}
                                style={style}
                            />
                        );
                    }
                } else {
                    return (
                        <TreeNode
                            key={item.key}
                            title={item.name}
                            style={style}
                        />
                    );
                }
                return false;
            });
            nodes = nodes.filter((n) => (typeof n !== "undefined" ? n : false));
            console.info("renderMasterpostsTreeNodes nodes", nodes);

            return nodes.length > 0 ? nodes : [];
        };

        renderMasteradsTreeNodes = (data) => {
            console.log(
                "renderMasteradsTreeNodes masterads_filter=" +
                    this.state.masterads_filter
            );

            const style = {
                padding: 0,
            };

            let nodes = data.map((item) => {
                if (this.state.masterads_filter) {
                    console.log("we have masterads_filter");
                    if (
                        item.name
                            .toUpperCase()
                            .indexOf(
                                this.state.masterads_filter.toUpperCase()
                            ) !== -1
                    ) {
                        return (
                            <TreeNode
                                key={item.key}
                                title={item.name}
                                style={style}
                            />
                        );
                    }
                } else {
                    return (
                        <TreeNode
                            key={item.key}
                            title={item.name}
                            style={style}
                        />
                    );
                }
                return false;
            });
            nodes = nodes.filter((n) => (typeof n !== "undefined" ? n : false));
            console.info("renderMasteradsTreeNodes nodes", nodes);

            return nodes.length > 0 ? nodes : [];
        };

        onMasterpostsCheck = (checkedKeys) => {
            //console.log("onCheck", checkedKeys);
            this.setState({ masterposts: checkedKeys });

            this.props.form.setFieldsValue({
                masterposts: checkedKeys,
            });
        };
        onMasteradsCheck = (checkedKeys) => {
            //console.log("onCheck", checkedKeys);
            this.setState({ masterads: checkedKeys });

            this.props.form.setFieldsValue({
                masterads: checkedKeys,
            });
        };

        render() {
            const {
                getFieldDecorator,
                // getFieldsError,
                // getFieldError,
                // isFieldTouched
            } = this.props.form;

            const { data } = this.props;
            // Only show error after a field is touched.
            // const usernameError =
            //     isFieldTouched("username") && getFieldError("username");
            // const passwordError =
            //     isFieldTouched("password") && getFieldError("password");

            // const formItemLayout = {
            //     labelCol: {
            //         xs: { span: 24 },
            //         sm: { span: 8 },
            //     },
            //     wrapperCol: {
            //         xs: { span: 24 },
            //         sm: { span: 16 },
            //     },
            // };
            const formItemLayout = null;

            let availableMasterposts = this.props.store.getAllMasterposts;
            let availableMasterads = this.props.store.getAllMasterads;

            // console.info("RENDER availableMasterposts", availableMasterposts);
            // console.info("RENDER availableMasterads", availableMasterads);

            if (!availableMasterads || !availableMasterposts)
                return <Icon type="loading" style={{ fontSize: 24 }} spin />;

            //console.info("RENDER availableMasterads", availableMasterads);
            const filtered_MasterpostsTreeNodes =
                availableMasterposts &&
                this.renderMasterpostsTreeNodes(availableMasterposts);

            const filtered_MasteradsTreeNodes =
                availableMasterads &&
                this.renderMasteradsTreeNodes(availableMasterads);

            return (
                <Form {...formItemLayout}>
                    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
                    <Form.Item label="Campaign">
                        {/* {getFieldDecorator("campaigns", {
                            //getValueFromEvent: handleGetTags
                            rules: [
                                {
                                    required: true,
                                    message:
                                        "Please input the campaigns to be applied to the primary posts!",
                                },
                            ],
                            initialValue: data.campaigns ? data.campaigns : [],
                            onChange: this.handleCampaignsChange,
                        })(
                            <EditableTagGroup
                                element_title="Campaign"
                                tag_color={this.props.store.getTagColorForType(
                                    "campaign"
                                )}
                                options={this.props.store.getAllCampaigns.map(
                                    (campaign) => campaign.campaign
                                )}
                            />
                        )} */}
                        {getFieldDecorator("campaign", {
                            //getValueFromEvent: handleGetTags
                            rules: [
                                {
                                    required: true,
                                    message: `Please input the campaigns to be applied to the ${names.get(
                                        "MASTERPOST",
                                        true
                                    )}!`,
                                },
                            ],
                            initialValue: data.campaign ? data.campaign : "",
                            onChange: (e) =>
                                this.handleCampaignChange(e.target.value),
                        })(<Input placeholder="Campaign name" />)}
                    </Form.Item>
                    <Divider orientation="left">
                        {names.get("MASTERPOST", true)}
                    </Divider>
                    <Form.Item>
                        {getFieldDecorator("masterposts", {
                            rules: [
                                // {
                                //     required: true,
                                //     message:
                                //         "Please select at least one primary posts the categories to be applied to!",
                                // },
                            ],
                        })(
                            <div>
                                <Row type="flex" justify="space-between">
                                    <Col>
                                        <Search
                                            placeholder="filter"
                                            onSearch={
                                                this.onmasterpostsSearchChange
                                            }
                                            onChange={(e) =>
                                                this.onmasterpostsSearchChange(
                                                    e.target.value
                                                )
                                            }
                                            style={{ width: 200 }}
                                        />
                                    </Col>
                                    <Col>
                                        <Button
                                            onClick={
                                                this.handleAddAllMasterposts
                                            }
                                        >
                                            Check all
                                        </Button>
                                    </Col>
                                </Row>
                                <Tree
                                    checkable
                                    selectable={false}
                                    // onExpand={this.onExpand}
                                    // expandedKeys={this.state.expandedKeys}
                                    // autoExpandParent={this.state.autoExpandParent}

                                    onCheck={this.onMasterpostsCheck}
                                    checkedKeys={this.state.masterposts}
                                    // onSelect={this.onSelect}
                                    // selectedKeys={this.state.selectedKeys}
                                    style={{ marginLeft: -27 }}
                                >
                                    {filtered_MasterpostsTreeNodes}
                                </Tree>
                                {filtered_MasterpostsTreeNodes.length === 0 ? (
                                    <Alert
                                        message="- No masterposts -"
                                        type="warning"
                                        style={{ width: 200, fontSize: 12 }}
                                    />
                                ) : null}
                            </div>
                        )}
                    </Form.Item>
                    <Divider orientation="left">Master Ads</Divider>
                    <Form.Item>
                        {getFieldDecorator("masterads", {
                            rules: [
                                // {
                                //     required: true,
                                //     message:
                                //         "Please select at least one primary post the categories to be applied to!",
                                // },
                            ],
                        })(
                            <div>
                                <Row type="flex" justify="space-between">
                                    <Col>
                                        <Search
                                            placeholder="filter"
                                            onSearch={
                                                this.onmasteradsSearchChange
                                            }
                                            onChange={(e) =>
                                                this.onmasteradsSearchChange(
                                                    e.target.value
                                                )
                                            }
                                            style={{ width: 200 }}
                                        />
                                    </Col>
                                    <Col>
                                        <Button
                                            onClick={this.handleAddAllMasterads}
                                        >
                                            Check all
                                        </Button>
                                    </Col>
                                </Row>
                                <Tree
                                    checkable
                                    selectable={false}
                                    // onExpand={this.onExpand}
                                    // expandedKeys={this.state.expandedKeys}
                                    // autoExpandParent={this.state.autoExpandParent}

                                    onCheck={this.onMasteradsCheck}
                                    checkedKeys={this.state.masterads}
                                    // onSelect={this.onSelect}
                                    // selectedKeys={this.state.selectedKeys}
                                    style={{ marginLeft: -27 }}
                                >
                                    {filtered_MasteradsTreeNodes}
                                </Tree>
                                {filtered_MasteradsTreeNodes.length === 0 ? (
                                    <Alert
                                        message="- No master ads -"
                                        type="warning"
                                        style={{ width: 200, fontSize: 12 }}
                                    />
                                ) : null}
                            </div>
                        )}
                    </Form.Item>
                </Form>
            );
        }
    }
);

/* Wrapper component */
class CampaignsListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_visible: false,
        };
    }

    componentDidMount = () => {
        this.registerModal();
    };

    registerModal = () => {
        this.props.store.registerModalListener(
            "campaigns_modal",
            (_action, _payload) => {
                // console.info(
                //     "CAMPAIGNS_MODAL _action",
                //     _action,
                //     "_payload",
                //     _payload
                // );
                if (_payload) {
                    this.setState({ data: _payload });
                } else {
                    this.setState({ data: {} });
                }
                this.setState({
                    modal_visible: _action === "show" ? true : false,
                });
            }
        );
    };

    componentWillUnmount = () => {
        this.props.store.unregisterModalListener("campaigns_modal");
    };

    onModalApply = async () => {
        const { form } = this.formRef.props;
        let formState = Object.assign({}, this.formRef.state);
        console.info("onModalApply formState: ", formState);

        form.validateFields(async (err, values) => {
            console.log("Received values of form: ", values, "err", err);

            if (err) {
                // social_notification("validation_error", "error", {
                //     description: err,
                // });
                console.warn("ERROR: ", err);
                return;
            }

            // Patch the location
            /*
            values["location"] = `${values["location"][0]}/${
                values["location"][1]
            }`;
            */

            // everything is fine
            console.log("everything is fine");

            if (
                JSON.stringify(formState.original_masterposts) !==
                    JSON.stringify(formState.masterposts) ||
                formState.original_campaign !== formState.campaign
            ) {
                // There is change on the masterposts lists
                // remove the old campaign from the original masterposts
                await this.props.store.removeCampaignsToMasterposts({
                    masterposts: JSON.parse(
                        JSON.stringify(formState.original_masterposts)
                    ),
                    campaigns: [formState.original_campaign],
                });
                console.log("DONE REMOVING OLD CAMPAIGN");

                await this.props.store.applyCampaignsToMasterposts({
                    masterposts: JSON.parse(
                        JSON.stringify(formState.masterposts)
                    ),
                    campaigns: [formState.campaign],
                });
                console.log("DONE ADDING THE NEW CAMPAIGN");
            }

            if (
                JSON.stringify(formState.original_masterads) !==
                    JSON.stringify(formState.masterads) ||
                formState.original_campaign !== formState.campaign
            ) {
                console.info("WE HAVE TO UPDATE THE ADS !!!!");
                // There is change on the masterads lists
                // remove the old campaign from the original masterads
                const removeStatus =
                    await this.props.store.removeCampaignsToMasterads({
                        masterads: JSON.parse(
                            JSON.stringify(formState.original_masterads)
                        ),
                        campaigns: [formState.original_campaign],
                    });
                console.log(
                    "DONE REMOVING OLD CAMPAIGN removeStatus=" + removeStatus
                );

                const applyStatus =
                    await this.props.store.applyCampaignsToMasterads({
                        masterads: JSON.parse(
                            JSON.stringify(formState.masterads)
                        ),
                        campaigns: [formState.campaign],
                    });
                console.log(
                    "DONE ADDING THE NEW CAMPAIGN applyStatus=" + applyStatus
                );
            }

            form.resetFields();
            this.onModalCancel(); // hide the modal
        }).catch((error) => {
            console.warn(error);
        });
    };

    onModalCancel = () => {
        //this.setState({ modal_visible: false });
        this.props.store.hideModalByKey(
            "campaigns_modal",
            this.formRef ? this.formRef.props.form.isFieldsTouched() : false
        );
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <Fragment>
                {this.state.modal_visible ? (
                    <Modal
                        visible={true}
                        title={"Edit a campaign"}
                        okText={"Apply"}
                        onCancel={this.onModalCancel}
                        onOk={this.onModalApply} //onApply
                        maskClosable={false}
                    >
                        <Icon
                            type="info-circle"
                            className={"social_modalHintToggle"}
                            style={{
                                color: this.state.showHint
                                    ? this.props.store.getTagColorForType(
                                          "campaign"
                                      )
                                    : null,
                            }}
                            onClick={(e) =>
                                this.setState({
                                    showHint: !this.state.showHint,
                                })
                            }
                        />
                        {this.state.showHint ? (
                            <Alert
                                type="info"
                                message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                banner
                                //closable
                                showIcon={false}
                                onClose={(e) =>
                                    this.setState({ showHint: false })
                                }
                                className={"social_modalHint"}
                            />
                        ) : null}
                        <ModalForm
                            store={this.props.store}
                            wrappedComponentRef={this.saveFormRef}
                            data={
                                this.props.data
                                    ? this.props.data
                                    : this.state.data
                            }
                        />
                    </Modal>
                ) : null}
            </Fragment>
        );
    }
}

export default observer(CampaignsListModal);
